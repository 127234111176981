import React from 'react';

import { Button } from '~/shared/ui/kit/button';

export const PaginationPageButton: React.FC<{
  page: number;
  onClick: (page: number) => void;
  active: boolean;
}> = ({ page, onClick, active }) => {
  const handleClick = () => {
    if (!active) {
      onClick(page);
    }
  };

  return (
    <Button size="sm" variant={active ? 'primary' : 'tertiary'} onClick={handleClick}>
      {page}
    </Button>
  );
};
