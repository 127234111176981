import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { ColumnDef } from '~/shared/ui/components/data-grid';
import { Tag } from '~/shared/ui/kit/tag';

export const MultiSelectRecords: React.FC<{
  value: string[];
  disabled?: boolean;
  cellTemplateContext?: ColumnDef['cellTemplateContext'];
  className?: string;
}> = ({ value, disabled, cellTemplateContext, className }) => (
  <div className={cn('flex gap-2', className)}>
    {value.map((item) => (
      <Tag key={item} disabled={disabled} {...cellTemplateContext?.props}>
        {item}
      </Tag>
    ))}
  </div>
);
