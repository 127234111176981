import type ng from 'angular';

import template from './shipment-schedule-position-table.html?raw';

export const ShipmentSchedulePositionTable = {
  bindings: {
    positions: '=',
  },
  template,
  controller: [
    'gettext',
    'ShipmentScheduleService',
    class {
      ShipmentScheduleService: any;
      gettext: ng.gettext.gettextFunction;
      positions: any;
      rollingStockTypeOptions: any;
      theadConfig: any;
      totalAmount: any;
      constructor(gettext: ng.gettext.gettextFunction, ShipmentScheduleService: any) {
        this.gettext = gettext;
        this.ShipmentScheduleService = ShipmentScheduleService;

        this.positions = this.positions || [];
        this.totalAmount = 0;
        this.rollingStockTypeOptions = ['ctl', 'own', 'leased', 'other'];
      }

      $onInit() {
        this.positions = this.positions || [];
        this.theadConfig = this.getTheadConfig();
      }

      updateTotals() {
        this.totalAmount = 0;
      }

      addPosition() {
        const newPosition = { rolling_stock_type: 'own' };
        this.positions.unshift(newPosition);
      }

      clone(position: any) {
        const newPosition = { ...position };
        delete newPosition.id;
        this.positions.unshift(newPosition);
      }

      destroy(position: any) {
        if (!confirm(this.gettext('Are you sure that you want delete Position?'))) {
          return;
        }
        if (position.id) {
          this.ShipmentScheduleService.deletePosition({ id: position.id });
        }
        this.positions.splice(this.positions.indexOf(position), 1);
      }

      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          {
            columnName: 'number',
            title: this.gettext('#'),
          },
          {
            columnName: 'exporter',
            title: this.gettext('Exporter'),
          },
          {
            columnName: 'warehouse',
            title: this.gettext('Warehouse'),
          },
          {
            columnName: 'deliverer',
            title: this.gettext('Deliverer'),
          },
          {
            columnName: 'station',
            title: this.gettext('Departure station'),
          },
          {
            columnName: 'shipper',
            title: this.gettext('Cargo shipper'),
          },
          {
            columnName: 'stock',
            title: this.gettext('Rolling stock'),
          },
          {
            columnName: 'na',
            title: this.gettext(''),
          },
        ];

        return config;
      }
    },
  ],
};
