import ng from 'angular';

import { ClientLogisticPaymentReconciliationReportContainer } from './client-logistic-payment-reconciliation-report-container/client-logistic-payment-reconciliation-report-container.component';
import { ClientLogisticPaymentReconciliationReportPage } from './client-logistic-payment-reconciliation-report-page/client-logistic-payment-reconciliation-report-page.container';
import { ClientLogisticPaymentReconciliationReportService } from './client-logistic-payment-reconciliation-report.service';

const reportConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('reports.clientLogisticPaymentReconciliationReport', {
      url: '/client-logistic-payment-reconciliation-report',
      component: 'clientLogisticPaymentReconciliationReportPage',
      data: { pageTitle: gettext('Client Reconciliation by logistic') },
    });
  },
];

export const clientLogisticPaymentReconciliationReport = ng
  .module('reports.clientLogisticPaymentReconciliationReport', ['finances.shared'])
  .service(
    'ClientLogisticPaymentReconciliationReportService',
    ClientLogisticPaymentReconciliationReportService,
  )
  .component(
    'clientLogisticPaymentReconciliationReportPage',
    ClientLogisticPaymentReconciliationReportPage,
  )
  .component(
    'clientLogisticPaymentReconciliationReportContainer',
    ClientLogisticPaymentReconciliationReportContainer,
  )
  .config(reportConfig).name;
