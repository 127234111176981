import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/deals/services/direct-contract/$contractId/documents',
)({
  component: () => (
    <div>Hello /_auth/_sidebar/deals/services/_direct-contract/$contractId/documents!</div>
  ),
});
