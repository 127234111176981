import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './quantitative-metrics-page.html?raw';

export const QuantitativeMetricsPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      PageService: PageService;
      ReportsService: any;
      constructor(PageService: PageService, ReportsService: any, $rootScope: GtRootScopeService) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.$rootScope = $rootScope;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {},
        };
      }
    },
  ],
};
