import React from 'react';

import { cn } from '~/shared/lib/utils';
import { CheckIcon, useIcon } from '~/shared/ui/icons';
import { CommandItem } from '~/shared/ui/kit/command';

import type { SortingOption, SortingValue } from '../../types';

export const FiltersSortItem: React.FC<{
  selectedOption?: SortingValue;
  option: SortingOption;
  selectHandler: (value: string) => void;
}> = ({ selectedOption, option, selectHandler }) => {
  const Icon = useIcon(option.icon);

  return (
    <CommandItem
      key={option.argument}
      value={option.title}
      onSelect={selectHandler}
      className={cn(
        'flex cursor-pointer items-center justify-between p-1 transition duration-300 ease-in-out hover:bg-transparent-light',
        option.argument === selectedOption?.argument && 'bg-transparent-light',
      )}
    >
      <div className="flex items-center gap-2">
        <Icon size={14} className="text-stroke-main-stronger" />
        <p className="text-xs text-text-main-primary">{option.title}</p>
      </div>
      {option.argument === selectedOption?.argument && (
        <CheckIcon size={14} className="text-text-main-secondary" />
      )}
    </CommandItem>
  );
};
