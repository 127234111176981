import type React from 'react';

import { CardViewSettings } from '../card-view-settings';
import type { ViewVariant } from '../data-set-view';
import { ListViewSettings } from '../list-view-settings';
import { SheetViewSettings } from '../sheet-view-settings/sheet-view-settings';
import { TableViewSettings } from '../table-view-settings';

export const viewSettingsMap: Record<ViewVariant, React.ElementType> = {
  table: TableViewSettings,
  card: CardViewSettings,
  list: ListViewSettings,
  sheet: SheetViewSettings,
};
