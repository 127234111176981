import { BehaviorSubject, Subject, map, merge, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { ApiError } from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { notifyError } from '~/shared/lib/notify';
import { AbilityBuilder, createMongoAbility } from '~/shared/lib/perms';

import { AuthRepository } from './auth.repository';
import { type AuthCredentials, type CurrentUser } from '../lib';

const guestUser: CurrentUser = {
  id: '',
  name: 'Guest',
  email: '',
  avatar: '',
  perms: [],
  settings: {
    biEnabled: false,
    biApiUrl: '',
  },
};

@singleton()
export class AuthStore {
  private userChangedSubj = new BehaviorSubject(false);
  private loginRequestedSubj = new Subject<AuthCredentials>();
  private logoutRequestedSubj = new Subject<void>();
  private loadingSubj = new BehaviorSubject(true);

  readonly ability = createMongoAbility();

  constructor(private authRepo: AuthRepository) {}

  private userLoggedIn$ = this.loginRequestedSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((credentials) =>
      this.authRepo
        .login(credentials)
        .catch((err: ApiError) => notifyError('Failed to login: ' + err.message)),
    ),
  );

  private userLoaded$ = merge(this.userLoggedIn$, this.userChangedSubj).pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap(() => this.authRepo.getCurrentUser().catch(() => guestUser)),
  );

  private userLoggedOut$ = this.logoutRequestedSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap(() =>
      this.authRepo
        .logout()
        .catch((err: ApiError) => notifyError('Failed to logout: ' + err.message)),
    ),
    map(() => guestUser),
  );

  readonly currentUser$ = merge(this.userLoaded$, this.userLoggedOut$).pipe(
    startWith({ ...guestUser, id: 'init' }),
    tap((user) => this.updateAbility(user)),
    tap(() => this.loadingSubj.next(false)),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly isAuthenticated$ = this.currentUser$.pipe(
    map((user) => !!user.id && user.id !== 'init'),
    startWith(false),
  );

  readonly loading$ = this.loadingSubj.asObservable();
  readonly initialized$ = this.currentUser$.pipe(map((u) => u.id !== 'init'));

  public loginRequested(credentials: AuthCredentials) {
    this.loginRequestedSubj.next(credentials);
  }

  public logoutRequested() {
    this.logoutRequestedSubj.next();
  }

  public userChanged() {
    this.userChangedSubj.next(true);
  }

  private updateAbility(user: CurrentUser) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    user.perms
      .filter((perm) => perm.includes('_'))
      .map((perm) => [perm.split('_')[0], perm.split('_').slice(1).join('_')])
      .forEach(([action, subject]) => can(action, subject));
    this.ability.update(rules);
  }
}
