import { container } from '~/shared/lib/di';
import { useObservableEagerState } from '~/shared/lib/state';

import { MenuStore } from '../services';

export const useSidebar = () => {
  const menuStore = container.resolve(MenuStore);
  const sidebarOpen = useObservableEagerState(menuStore.isSidebarOpen$);

  return {
    sidebarOpen,
    sidebarToggled: (value: boolean) => menuStore.sidebarToggled(value),
  };
};
