import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './consolidated-contract-pnl-container.html?raw';

export const ConsolidatedContractPnlContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$q',
    '$rootScope',
    '$scope',
    'gtFilterService',
    'GtUtils',
    'ConsolidatedContractPnlService',
    'gettext',
    'CoreService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ConsolidatedContractPnlService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      cancelRequest: any;
      cancelRequests: any;
      chartConfigList: any;
      chosedNestedMultiSelects: any;
      contractStatuses: any;
      data: any;
      filterLevel = 'consolidated-contract-pnl-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: any;
      monthList: any;
      nestedMultiSelect: any;
      queryParams: any;
      rowTypeList: any;
      savedFilterChoices: any;
      showAllDetails: any;
      showCell: any;
      showFiltersPerset: any;
      showMtm: any;
      showVolume: any;
      showVolumeInternal: any;
      title_list: any;
      constructor(
        $q: ng.IQService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        ConsolidatedContractPnlService: any,
        gettext: ng.gettext.gettextFunction,
        CoreService: CoreService,
      ) {
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.ConsolidatedContractPnlService = ConsolidatedContractPnlService;
        this.gettext = gettext;
        this.CoreService = CoreService;

        this.showCell = {};
        this.chartConfigList = [];
        this.savedFilterChoices = [];
        this.showVolume = false;
        this.showVolumeInternal = false;
        this.showFiltersPerset = false;
        this.showMtm = true;
        this.cancelRequests = undefined;
        this.chosedNestedMultiSelects = {
          report_year_list: [
            (this.$rootScope.user.settings.DEFAULT_VALUES.crop_year - 1).toString(),
            this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
            {
              id: (this.$rootScope.user.settings.DEFAULT_VALUES.crop_year - 1).toString(),
              title: (this.$rootScope.user.settings.DEFAULT_VALUES.crop_year - 1).toString(),
            },
            {
              id: this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
              title: this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
            },
          ],
        };

        this.nestedMultiSelect = {
          argument: 'report_year_list',
          placeholder: this.gettext('Year'),
          items: this.GtUtils.getYearList(),
        };
        this.contractStatuses = [
          { id: 'washout', title: this.gettext('Washout') },
          { id: 'new', title: this.gettext('New') },
          { id: 'delivery_period', title: this.gettext('Delivery period') },
          { id: 'shipment', title: this.gettext('Shipment') },
          { id: 'cargo_executed', title: this.gettext('Cargo executed') },
          { id: 'executed', title: this.gettext('Executed') },
          { id: 'approved', title: this.gettext('Approved') },
          { id: 'cancelled', title: this.gettext('Cancelled') },
        ];
        this.rowTypeList = [
          { key: 3, title: 'Contracts', icon: 'fa fa-file-text' },
          { key: 1, title: 'Services', icon: 'fa fa-sack-dollar' },
          { key: 4, title: 'Spec. Accounts operations', icon: 'fa fa-bolt' },
        ];
        this.initQueryParams = {
          contract_type: 'sale',
          commodity_level: '0',
          charge_level: '0',
          row_type_list: [3],
          margin_selector: this.$rootScope.user.settings.DEFAULT_CONTRACT_PNL_MARGIN || 'default',
        };
      }

      $onInit() {
        const defaultCropYear = this.$rootScope.user.settings.DEFAULT_VALUES.crop_year;

        if (this.$rootScope.user.settings.DEFAULT_SHOW_PNL_PASSPORTS) {
          this.showAllDetails = true;
        } else {
          this.showAllDetails = false;
        }
        this.filterLevel = this.filterLevel || 'consolidated-contract-pnl-container';
        this.queryParams = { ...this.initQueryParams };

        this.queryParams.group_by = this.$rootScope.user.settings.DEFAULT_PNL_DETALIZATION;
        if (this.$rootScope.user.settings.DEFAULT_RECENT_PNL_YEAR) {
          this.queryParams.report_year_list = [defaultCropYear];
          this.chosedNestedMultiSelects.report_year_list = [
            { id: defaultCropYear, title: defaultCropYear },
          ];
        } else {
          this.queryParams.report_year_list = [(defaultCropYear - 1).toString(), defaultCropYear];
        }

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      getContractResource() {
        return (
          (
            {
              sale: 'contracts.salecontract',
              purchase: 'contracts.purchasecontract',
            } as any
          )[this.queryParams.contract_type] || 'contracts.contractbase'
        );
      }

      updateData() {
        this.GtUtils.overlay('show');
        if (this.cancelRequest) {
          this.cancelRequest.resolve({ isAborted: true });
        }

        this.cancelRequest = this.$q.defer();

        const c = this.ConsolidatedContractPnlService.getData(
          this.queryParams,
          this.cancelRequest.promise,
        )
          .then((data: any) => {
            this.data = data;
            this.title_list =
              data.title_list?.filter(this.ConsolidatedContractPnlService.onlyUnique) || [];
            this.title_list.sort();
            this.monthList = this.ConsolidatedContractPnlService.getDateRangeByYear(2020);

            this.chartConfigList = [];
            this.data.data.forEach((year: any) => {
              const monthDataList: any = [];
              year.data.forEach((quarter: any) => {
                monthDataList.push(...quarter.data);
              });
              const chartConfig = this.makePnlChartConfig(monthDataList);
              chartConfig.yearKey = year.year_key;
              this.chartConfigList.push(chartConfig);
            });
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });

        return this.GtUtils.addConnection(c).$promise;
      }

      applyFilters() {
        if (
          this.queryParams.voyage_vessel_size_list?.length ||
          this.queryParams.logistic_type_list?.length
        ) {
          this.queryParams.row_type_list = [1];
        }
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      makePnlChartConfig(data: any) {
        const pnlChartConfig: any = { data: [], labels: [], series: [], options: {}, count: 0 };
        pnlChartConfig.labels = this.monthList.map((month: any) => month.title);
        pnlChartConfig.count = pnlChartConfig.labels.length;
        pnlChartConfig.series = this.title_list;
        pnlChartConfig.series.forEach((title: string, index: number) => {
          pnlChartConfig.data.push(new Array(pnlChartConfig.labels.length).fill(0));
          data.forEach((item: any) => {
            item.data.forEach((el: any) => {
              if (el.title === title) {
                pnlChartConfig.data[index].splice(
                  parseInt(el.period_month, 10) - 1,
                  1,
                  el.margin_sum.toFixed(2),
                );
              }
            });
          });
        });
        pnlChartConfig.options = {
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        };
        return pnlChartConfig;
      }

      setNestedMultiSelect(argument: any, nestedItem: any) {
        if (
          this.idInRange(argument, nestedItem) &&
          this.chosedNestedMultiSelects[argument] &&
          this.queryParams[argument] &&
          nestedItem
        ) {
          this.chosedNestedMultiSelects[argument] = this.chosedNestedMultiSelects[argument].filter(
            function (option: any) {
              return option.id !== nestedItem.id;
            },
          );
          this.queryParams[argument] = this.queryParams[argument].filter(function (option: any) {
            return option !== nestedItem.id;
          });
          if (
            this.queryParams[argument].length == 0 &&
            this.chosedNestedMultiSelects[argument].length == 0
          ) {
            delete this.chosedNestedMultiSelects[argument];
            this.queryParams[argument] = undefined;
          }
        } else if (nestedItem) {
          if (this.chosedNestedMultiSelects[argument]?.length) {
            this.chosedNestedMultiSelects[argument].push(nestedItem);
          } else {
            this.chosedNestedMultiSelects[argument] = [nestedItem];
          }
          if (this.queryParams[argument]?.length) {
            this.queryParams[argument].push(nestedItem.id);
          } else {
            this.queryParams[argument] = [nestedItem.id];
          }
        } else {
          delete this.chosedNestedMultiSelects[argument];
          this.queryParams[argument] = undefined;
        }
      }

      toggleRowType(rowType: any) {
        if (this.queryParams.row_type_list.includes(rowType)) {
          this.queryParams.row_type_list = this.queryParams.row_type_list.filter(
            (v: any) => v !== rowType,
          );
        } else {
          this.queryParams.row_type_list.push(rowType);
        }
        this.applyFilters();
      }

      idInRange(nestedMultiSelectArgument: any, nestedItem: any) {
        let result = false;
        if (!Object.keys(this.chosedNestedMultiSelects).length) {
          return result;
        }

        this.chosedNestedMultiSelects[nestedMultiSelectArgument]?.forEach(function (option: any) {
          if (nestedItem === undefined || option.id === nestedItem.id) {
            result = true;
          }
        });
        return result;
      }
    },
  ],
};
