export class CustomsCargoDeclarationInvoicePositionService {
  $resource: ng.resource.IResourceService;
  customsCargoDeclarationInvoicePositionService: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;

    this.customsCargoDeclarationInvoicePositionService = $resource(
      '/api/logistics/customs-declaration-invoice-position/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  save(item: any) {
    return this.customsCargoDeclarationInvoicePositionService.save(item).$promise;
  }

  update(item: any) {
    return this.customsCargoDeclarationInvoicePositionService.update(item).$promise;
  }

  delete(item: any) {
    return this.customsCargoDeclarationInvoicePositionService.delete(item).$promise;
  }

  getItem(customsCargoDeclarationInvoicePositionID: any) {
    return this.customsCargoDeclarationInvoicePositionService
      .get({ id: customsCargoDeclarationInvoicePositionID })
      .$promise.then((data: any) => data);
  }

  getData(params: any) {
    return this.customsCargoDeclarationInvoicePositionService
      .query(params)
      .$promise.then((data: any) => {
        return { data: data };
      });
  }
}
CustomsCargoDeclarationInvoicePositionService.$inject = ['$resource'];
