import { singleton } from '~/shared/lib/di';

import { AuthRepository } from './auth.repository';
import { type AuthCredentials, type CurrentUser } from '../lib';

@singleton()
export class AuthMockRepository implements InstanceType<typeof AuthRepository> {
  login(credentials: AuthCredentials): Promise<boolean> {
    return Promise.resolve(!!credentials);
  }

  logout(): Promise<void> {
    return Promise.resolve();
  }

  async getCurrentUser(): Promise<CurrentUser> {
    return Promise.resolve({
      id: '123',
      name: 'Test',
      email: 'test@mail.com',
      avatar: '',
      perms: ['read_test'],
      settings: {
        biApiUrl: 'test_url',
        biEnabled: false,
      },
    });
  }
}
