import React from 'react';

import type { RenderArgs } from '~/core/page-views/components/data-set-view';
import type { CellDataType, ColumnDef } from '~/shared/ui/components/data-grid';

export const counterpartyColumns: {
  key: string;
  dataType: CellDataType;
  sourceKey?: string;
  cellTemplateContext?: ColumnDef['cellTemplateContext'];
  size?: number;
  render?: (args: RenderArgs) => JSX.Element;
}[] = [
  { key: 'title', dataType: 'text', sourceKey: 'name' },
  { key: 'updateTime', dataType: 'date' },
  // author_first_name - future creator
  { key: 'authorFirstName', dataType: 'text' },
  // editor_first_name - future editor
  { key: 'editorFirstName', dataType: 'text' },
  // status - future complianceStatus
  {
    key: 'status',
    dataType: 'select',
    cellTemplateContext: {
      options: [
        { value: 'Approved', props: { variant: 'green' } },
        { value: 'Pending', props: { variant: 'yellow' } },
        { value: 'Re-Approval', props: { variant: 'yellow' } },
        { value: 'Rejected', props: { variant: 'red' } },
        { value: 'Approved_condition' },
        { value: 'New', props: { variant: 'blue' } },
      ],
    },
  },
  {
    key: 'role',
    sourceKey: 'role',
    dataType: 'multiselect',
    cellTemplateContext: {
      props: { rounded: 'full' },
      options: [
        { value: 'broker', props: { rounded: 'full' } },
        { value: 'deliverer', props: { rounded: 'full' } },
        { value: 'insurer', props: { rounded: 'full' } },
        { value: 'exporter', props: { rounded: 'full' } },
        { value: 'customs_broker', props: { rounded: 'full' } },
        { value: 'owner', props: { rounded: 'full' } },
        { value: 'buyer', props: { rounded: 'full' } },
        { value: 'other', props: { rounded: 'full' } },
        { value: 'surveyor', props: { rounded: 'full' } },
        { value: 'farm', props: { rounded: 'full' } },
        { value: 'elevator', props: { rounded: 'full' } },
        { value: 'bank', props: { rounded: 'full' } },
        { value: 'supplier', props: { rounded: 'full' } },
      ],
    },
    size: 210,
  },
  // approval_status
  {
    key: 'approvalStatus',
    dataType: 'select',
    cellTemplateContext: {
      options: [
        { value: 'wait', props: { variant: 'yellow' } },
        { value: 'rejected', props: { variant: 'red' } },
        { value: 'approved', props: { variant: 'green' } },
      ],
    },
  },
  // address
  { key: 'address', dataType: 'text' },
  // regular_status - future status
  {
    key: 'regularStatus',
    dataType: 'select',
    cellTemplateContext: {
      options: [
        { value: 'active', props: { variant: 'yellow' } },
        { value: 'potencial' },
        { value: 'inactive', props: { variant: 'green' } },
      ],
    },
  },
  // documents_count
  { key: 'documentsCount', dataType: 'number' },
  // abbreviation
  { key: 'abbreviation', dataType: 'text' },
  // contracts_count
  { key: 'contractsCount', dataType: 'number' },
  // phone
  { key: 'phone', dataType: 'phone' },
  // logistics_count
  { key: 'logisticsCount', dataType: 'text' },
  // phone_additional
  { key: 'phoneAdditional', dataType: 'phone' },
  // region_titles - future region
  { key: 'region_titles', dataType: 'text' },
  // district_title - future title
  { key: 'district_title', dataType: 'text' },
  // city
  { key: 'city', dataType: 'text' },
  // current_address
  { key: 'currentAddress', dataType: 'text' },
  // email
  { key: 'email', dataType: 'email' },
  // website
  { key: 'website', dataType: 'url' },
  // persons_count - future stuff
  { key: 'personsCount', dataType: 'number' },
  // updates_count
  { key: 'updatesCount', dataType: 'number' },
  // relations
  {
    key: 'relations',
    sourceKey: 'relations',
    dataType: 'select',
    cellTemplateContext: {
      options: [
        { value: 'Hot', props: { variant: 'red' } },
        { value: 'Neutral', props: { variant: 'gray' } },
        { value: 'Warm', props: { variant: 'yellow' } },
      ],
    },
  },
  // person
  { key: 'person', dataType: 'text' },
  // invoices_count
  { key: 'invoices', dataType: 'text' },
  // additional_info
  { key: 'additionalInfo', dataType: 'text' },
  // create_time - future created
  { key: 'createTime', dataType: 'date' },
  // update_time
  { key: 'updateTime', dataType: 'date' },
  // company_code
  { key: 'companyCode', dataType: 'number' },
  // affiliated - supplier_names and exporter_names
  { key: 'supplierNames', dataType: 'text' },
  { key: 'exporterNames', dataType: 'text' },
  // sap_vendor_number
  { key: 'sapVendorNumber', dataType: 'number' },
  // related_counterparties
  {
    key: 'relatedCounterparties',
    dataType: 'template',
    render: ({ value }) => {
      if (Array.isArray(value)) {
        return <div className="px-2">{value.map((item: { name: string }) => item.name)}</div>;
      }

      return <div />;
    },
  },
  // voyages - do_num_of_voyages and ho_num_of_voyage
  { key: 'doNumOfVoyages', dataType: 'text' },
  { key: 'hooNumOfVoyages', dataType: 'text' },
];
