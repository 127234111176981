import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('warehouseTradeContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
    },
    template: require('./warehouse-trade-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'gtFilterService', 'ReportsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    ReportsService: any,
  ) {
    const vm = this;
    vm.result = {};
    vm.count = 0;
    vm.updateReport = updateReport;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'warehouse-trade-container';
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateReport();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    ////////////////

    function updateReport() {
      GtUtils.overlay('show');
      ReportsService.WarehouseTrade.query(vm.queryParams, function (data: any) {
        vm.result = data;
        GtUtils.overlay('hide');
      });
    }
  }
})();
