import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

export class PassportFilterConfigService {
  $rootScope: GtRootScopeService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;
  constructor(
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$rootScope = $rootScope;
    this.GtUtils = GtUtils;
    this.gtFilterService = gtFilterService;
    this.gettext = gettext;
  }

  getFilterConfig(filterLevel: string) {
    return {
      filterLevel: filterLevel,
      clicked: false,
      search: true,
      dates: true,
      useFlexDateRange: true,
      invert_filters: true,
      dateSelects: [
        {
          argument: 'create_time',
          placeholder: this.gettext('Create date'),
        },
        {
          argument: 'conclusion_date',
          placeholder: this.gettext('Conclusion date'),
        },
        {
          argument: 'business_date',
          placeholder: this.gettext('Business date'),
        },
      ],
      ordering: [
        {
          title: this.gettext('RECENTLY UPDATED'),
          predicate: 'update_time',
        },
      ],
      selects: [],
      multiSelects: [
        {
          argument: 'id_list',
          placeholder: this.gettext('passport number'),
          resource: 'passports.Passport',
          related_fk: 'id',
          related_model: 'passports.Passport',
        },
        {
          argument: 'buyer_list',
          placeholder: this.gettext('buyer'),
          resource: 'clients.buyer',
          related_fk: 'sale_facts__contract__buyer',
          related_model: 'passports.Passport',
        },
        {
          argument: 'supplier_list',
          placeholder: this.gettext('supplier'),
          resource: 'clients.supplier',
          related_fk: 'purchase_facts__contract__supplier',
          related_model: 'passports.Passport',
        },
        {
          argument: 'owner_list',
          placeholder: this.gettext('owner'),
          resource: 'clients.owner',
          related_fk: 'owner_list',
          related_model: 'passports.Passport',
        },
        {
          argument: 'vessel_list',
          placeholder: this.gettext('vessel'),
          resource: 'logistics.vessel',
          related_fk: 'voyage__vessel',
          related_model: 'passports.Passport',
        },
        {
          argument: 'country_of_destination_list',
          placeholder: this.gettext('destination country'),
          resource: 'location.Country',
          related_fk: 'destination_of_crop',
          related_model: 'passports.Passport',
        },
        {
          argument: 'country_of_origin_list',
          placeholder: this.gettext('origin country'),
          resource: 'location.Country',
          related_fk: 'origin_of_crop',
          related_model: 'passports.Passport',
        },
        {
          argument: 'custom_status_list',
          placeholder: this.gettext('custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'custom_status',
          related_model: 'passports.Passport',
          queryParams: {
            content_type__model: 'passport',
          },
        },
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
          getQueryParams: this.GtUtils.getBUQueryParams,
        },
        {
          argument: 'responsible_list',
          placeholder: this.gettext('responsible'),
          resource: 'auth.user',
          related_fk: 'responsible',
          related_model: 'passports.Passport',
        },
        {
          argument: 'contract_season_list',
          placeholder: this.gettext('season'),
          resource: 'core.Season',
          related_fk: 'season_list',
          related_model: 'passports.Passport',
        },
        {
          argument: 'cargo_list',
          placeholder: this.gettext('passport commodity'),
          resource: 'crops.Crop',
        },
        {
          argument: 'contract_cargo_list',
          placeholder: this.gettext('contract commodity'),
          resource: 'crops.Crop',
        },
        {
          argument: 'multipassport_list',
          placeholder: this.gettext('multipassport'),
          resource: 'passports.multipassport',
        },
        {
          argument: 'saporder_list',
          placeholder: this.gettext('sap order'),
          resource: 'contracts.saporder',
        },
        {
          argument: 'logistic_type_list',
          placeholder: this.gettext('Logistic type'),
          resource: 'logistics.logistictype',
        },
        {
          argument: 'voyage_vessel_size_list',
          placeholder: this.gettext('Vessel size'),
          resource: 'logistics.vesselsize',
        },
      ],
      nestedSelects: [
        this.gtFilterService.getBoolFilter('with_sale_facts', this.gettext('Has sale facts')),
        this.gtFilterService.getBoolFilter(
          'with_purchase_facts',
          this.gettext('Has purchase facts'),
        ),
        this.gtFilterService.getBoolFilter('with_export_facts', this.gettext('Has export facts')),
        this.gtFilterService.getBoolFilter(
          'internal_chain_facts',
          this.gettext('Internal chain contracts'),
        ),
        // FIX: uncomment when passport purchase value is calculated on sql
        this.gtFilterService.getBoolFilter(
          'with_completed_net_cost',
          this.gettext('Cost of goods sold'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_origin_country',
          this.gettext('With origin country'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_destination_country',
          this.gettext('With destination country'),
        ),
        this.gtFilterService.getBoolFilter('with_business_unit', this.gettext('Has Business Unit')),
        this.gtFilterService.getBoolFilter(
          'has_derivative_operations',
          this.gettext('Has derivative operations'),
        ),
        this.gtFilterService.getBoolFilter('with_voyage', this.gettext('Has Voyage')),
        this.gtFilterService.getBoolFilter('with_billoflading', this.gettext('Vessels are loaded')),
        {
          argument: 'wait_my_approval',
          class: '',
          items: [{ id: this.$rootScope.user.id, title: this.gettext('Yes') }],
          placeholder: this.gettext('Waiting my approval'),
        },
        this.gtFilterService.getBoolFilter('with_responsible', this.gettext('Has responsible')),
        this.gtFilterService.getBoolFilter('with_request_b2b', this.gettext('B2B')),
      ],
      nestedMultiSelects: [
        this.gtFilterService.getPassportStatusFilter('status_list'),
        {
          argument: 'approval_status_list',
          placeholder: this.gettext('Approval status'),
          items: [
            { id: 'wait', title: this.gettext('Waiting for approval') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        {
          argument: 'has_margin_diff_by_type',
          placeholder: this.gettext('Has margin diff by type'),
          items: [
            { id: 'ticket_margin', title: 'Ticket' },
            { id: 'contract_margin', title: 'Contract' },
            { id: 'contract_analytical_margin', title: 'Contract analytical' },
            { id: 'execution_margin', title: 'Execution' },
            { id: 'invoice_margin', title: 'Invoice' },
            { id: 'payment_margin', title: 'Payment' },
          ],
        },
        {
          argument: 'contract_crop_year_list',
          placeholder: this.gettext('Contract crop year'),
          items: this.GtUtils.getYearList(),
        },
      ],
    };
  }
}
PassportFilterConfigService.$inject = ['$rootScope', 'GtUtils', 'gtFilterService', 'gettext'];
