import ng from 'angular';

import { requestsContainerComponent } from './components/requests-container/requests-container.component';
import { requestDetails } from './details/request-details.module';
import { requestsLegacyModule } from './legacy';
import { RequestFormFieldsService } from './request-form-field.service';

export const requests = ng
  .module('deals.requests', [requestsLegacyModule, requestDetails])
  .component('requestsContainer', requestsContainerComponent)
  .service('RequestFormFieldsService', RequestFormFieldsService).name;
