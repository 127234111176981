import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { DisbursementBLSql } from '~/shared/api';

import type { DBLInvoicePosition, QueryParams } from '../types';

class DBLInvoicePositionListContainerController implements ng.IController {
  filterLevel?: string;
  queryParams: QueryParams = {
    has_free_dbl_amount: 1,
    page_size: 25,
  };
  dblInvoicePositionList?: DBLInvoicePosition[];
  purchaseContract?: number;
  saleContract?: number;
  dataCount?: number;
  useList?: string[];
  disbursementbl?: DisbursementBLSql;
  batchSaving?: boolean;
  onUpdate?: (args: { data: { dblInvoicePositionList: DBLInvoicePosition[] } }) => void;

  static readonly $inject = ['GtUtils', 'gtFilterService'];

  constructor(
    private GtUtils: GtUtilsService,
    private gtFilterService: GtFilterService,
  ) {}

  $onInit() {
    this.filterLevel = this.filterLevel ?? 'disburementdbl-invoice-position-page-view';
    this.setQueryParams();
  }

  $onChanges(changes: ng.IOnChangesObject) {
    if (changes.dblInvoicePositionList || changes.purchaseContract || changes.saleContract) {
      this.updateData();
    }
  }

  updateData() {
    this.dataCount = this.dblInvoicePositionList?.length;
    this.setQueryParams();
  }

  setQueryParams() {
    this.queryParams = {
      contract_list: [this.purchaseContract ?? 0, this.saleContract ?? 0],
      use_list: this.useList,
      disbursement_bl: this?.disbursementbl?.id,
      has_free_dbl_amount: 1,
      page_size: 25,
    };
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
  }

  _error(data: any) {
    this.GtUtils.errorClb(data);
    this.GtUtils.overlay('hide');
    this.batchSaving = false;
  }
}

export const dblInvoicePositionListContainer: ng.IComponentOptions = {
  bindings: {
    dblInvoicePositionList: '<?',
    filterLevel: '<?',
    purchaseContract: '<?',
    saleContract: '<?',
    disbursementbl: '<?',
    useList: '<',
    onUpdate: '<',
  },
  template: require('./dbl-invoice-position-list-container.html?raw'),
  controller: DBLInvoicePositionListContainerController,
};
