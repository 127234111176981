import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './bill-of-lading-modal.html?raw';

export const BillOfLadingModal = {
  bindings: {
    modalInstance: '<',
    billOfLading: '<?',
    extra: '<?',
  },
  template,
  controller: [
    '$q',
    '$window',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'ClientsService',
    'BillOfLadingService',
    'LogisticsService',
    '$rootScope',
    '$scope',
    'CustomValuesService',
    '$timeout',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      $window: ng.IWindowService;
      BillOfLadingService: any;
      ClientsService: any;
      CustomValuesService: any;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      billOfLading: any;
      customValues: any;
      extra: any;
      fields: any;
      fieldsTemplateOptions: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      requiredFieldsInHeader: any;
      tab: any;
      updating: any;
      constructor(
        $q: ng.IQService,
        $window: ng.IWindowService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        ClientsService: any,
        BillOfLadingService: any,
        LogisticsService: any,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        CustomValuesService: any,
        $timeout: ng.ITimeoutService,
      ) {
        this.$q = $q;
        this.$window = $window;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.ClientsService = ClientsService;
        this.BillOfLadingService = BillOfLadingService;
        this.LogisticsService = LogisticsService;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.CustomValuesService = CustomValuesService;
        this.$timeout = $timeout;
        this.tab = 'edit';

        this.form = undefined;
        this.fields = [];
        this.fieldsTemplateOptions = [];
        this.updating = false;
      }

      $onInit() {
        this.billOfLading = this.billOfLading || {};
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';

        if (this.billOfLading.id) {
          this.updateData();
        } else {
          this.updateFields();
        }

        this.$scope.$on('custom-values-updated__billoflading', (event: any, data: any) => {
          this.customValues = data;
        });

        this.$scope.$watchGroup(
          [
            () => this.billOfLading.sale_contract,
            () => this.billOfLading.purchase_contract,
            () => this.billOfLading.export_contract,
            () => this.billOfLading.number,
            () => this.billOfLading.volume,
          ],
          (newValues: any) => {
            const anyContractSelected = newValues.slice(0, 3).some(Boolean);

            this.fieldsTemplateOptions.forEach((field: any) => {
              if (
                field.key === 'sale_contract' ||
                field.key === 'purchase_contract' ||
                field.key === 'export_contract'
              ) {
                if (!field.templateOptions) {
                  field.templateOptions = {};
                }
                field.templateOptions.required = !anyContractSelected;
              }
            });

            this.updateHeader();
          },
        );

        this.$timeout(() => {
          this.fieldsTemplateOptions = this.getFlatFields(this.fields);
          this.updateRequiredFields();
          this.updateHeader();
        }, 0);
      }

      updateFields() {
        this.updating = true;
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            if (!this.fields.length) {
              this.fields = fields;
            } else {
              const oldLength = this.fields.length;
              this.fields.push(...fields);
              this.$timeout(() => {
                this.fields.splice(0, oldLength);
              }, 100);
              this.$timeout(() => (this.updating = false), 4000);
            }

            this.fieldsTemplateOptions = this.getFlatFields(fields);
            this.updateRequiredFields();
          })
          .catch(this.GtUtils.errorClb);
      }

      getFlatFields(fields: any) {
        const flatFields = this.FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map(
          (field: any) => ({
            ...field.templateOptions,
            key: field.key,
          }),
        );
        return flatFields;
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.BillOfLadingService.getBillOfLading(this.billOfLading.id).then((data: any) => {
          this.billOfLading = data;
          this.updateFields();
          this.GtUtils.overlay('hide');
        });
      }

      updateRequiredFields() {
        const anyContractSelected =
          this.billOfLading.sale_contract ||
          this.billOfLading.purchase_contract ||
          this.billOfLading.export_contract;

        this.fieldsTemplateOptions.forEach((field: any) => {
          if (['sale_contract', 'purchase_contract', 'export_contract'].includes(field.key)) {
            if (!field.templateOptions) {
              field.templateOptions = {};
            }
            field.templateOptions.required = !anyContractSelected;
          }
        });

        this.$timeout(() => {
          this.updateHeader();
        }, 0);
      }

      updateHeader() {
        const isAnyContractSelected =
          this.billOfLading.sale_contract ||
          this.billOfLading.purchase_contract ||
          this.billOfLading.export_contract;

        const requiredFields = this.fieldsTemplateOptions
          .filter(
            (field: any) =>
              field.required &&
              (!['sale_contract', 'purchase_contract', 'export_contract'].includes(field.key) ||
                !isAnyContractSelected) &&
              !this.billOfLading[field.key] &&
              this.billOfLading[field.key] !== 0,
          )
          .map((field: any) => field.label);

        this.requiredFieldsInHeader = requiredFields;
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure that you want delete bill of lading?'))) {
          return;
        }
        return this.BillOfLadingService.deleteBillOfLading(this.billOfLading).then(() => {
          this.GtUtils.notify(this.gettext('Bill of lading removed'));
          this.close('delete', true);
        });
      }

      save(intNew: any) {
        let resultPromise;

        if (this.billOfLading.id) {
          resultPromise = this.BillOfLadingService.updateBillOfLading(this.billOfLading);
        } else {
          resultPromise = this.checkContractsVolumeLimit(this.billOfLading).then((result: any) => {
            if (result) {
              return this.BillOfLadingService.saveBillOfLading(this.billOfLading);
            } else {
              this.GtUtils.notify(
                this.gettext(
                  'Your request to generate a bill of lading has been declined. The volume exceeds' +
                    'the permissible limits. Please verify and enter the correct volume, taking into' +
                    'account the tolerance.',
                ),
                'error',
              );
              return Promise.reject(new Error('Volume limit exceeded'));
            }
          });
        }

        return resultPromise
          .then((data: any) => {
            if (data) {
              this.CustomValuesService.createCustomValues(data.id, this.customValues);

              if (this.extra.logisticBillofladingData) {
                return this.BillOfLadingService.connectToLogistics(
                  data.id,
                  this.extra.logisticBillofladingData,
                ).then(() => {
                  this.GtUtils.notify(
                    this.gettext('Bill of lading saved and connected to logistics'),
                  );
                  if (intNew) {
                    this.initNewBillOfLading();
                  } else {
                    this.close(data, true);
                  }
                });
              } else {
                this.GtUtils.notify(this.gettext('Bill of lading saved'));
                if (intNew) {
                  this.initNewBillOfLading();
                } else {
                  this.close(data, true);
                }
              }
            }
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error);
          });
      }

      checkContractsVolumeLimit(bl: any) {
        this.GtUtils.overlay('show');
        return this.BillOfLadingService.checkContractsVolumeLimit(
          [bl.sale_contract, bl.purchase_contract],
          bl.volume,
        )
          .then(
            (warnings: any) => !warnings.length,
            (error: any) => {
              this.GtUtils.errorClb(error);
            },
          )
          .finally(() => this.GtUtils.overlay('hide'));
      }

      initNewBillOfLading() {
        this.billOfLading = {};
        this.updateFields();
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-xs-12 col-md-6',
          fieldGroup: [] as any,
        };
        const col2: any = {
          className: 'form-group-container col-xs-12 col-md-6',
          fieldGroup: [] as any,
        };
        col1.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'INFO',
            },
            fieldGroup: [
              {
                key: 'number',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Bill of lading number'),
                  type: 'text',
                  required: true,
                },
              },
              {
                key: 'date',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('Shipped on board date'),
                  placeholder: this.gettext('date'),
                },
              },
              {
                key: 'volume',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Volume'),
                  type: 'number',
                  addon: this.gettext('mt'),
                  required: true,
                },
              },
              {
                key: 'unloading_volume',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Unloading volume'),
                  type: 'number',
                  addon: this.gettext('mt'),
                },
              },
              {
                key: 'container_number',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Number of containers'),
                  type: 'text',
                  disabled: true,
                  help: this.gettext('this field is calculated automatically'),
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'NOTIFY',
            },
            fieldGroup: [
              {
                key: 'notify_party',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Notify party'),
                  placeholder: this.gettext('Notify party'),
                  resource: 'clients.clientrole',
                  addFunc: () => {
                    return this.ClientsService.roleModal({ model_name: 'Other' });
                  },

                  addIcon: this.GtUtils.getIcon('clients.clientrole'),
                  addPerms: ['add_other'],
                },
              },
              {
                key: 'notify_party_second',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Notify party 2'),
                  placeholder: this.gettext('Notify party 2'),
                  resource: 'clients.clientrole',
                  help: this.gettext('if there is more than one notify party'),
                  addFunc: () => {
                    return this.ClientsService.roleModal({ model_name: 'Other' });
                  },

                  addIcon: this.GtUtils.getIcon('clients.clientrole'),
                  addPerms: ['add_other'],
                },
                hideExpression: () => !this.billOfLading.notify_party,
              },
              {
                key: 'notify_party_third',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Notify party 3'),
                  placeholder: this.gettext('Notify party 3'),
                  resource: 'clients.clientrole',
                  help: this.gettext('if there is more than two notify party'),
                  addFunc: () => {
                    return this.ClientsService.roleModal({ model_name: 'Other' });
                  },

                  addIcon: this.GtUtils.getIcon('clients.clientrole'),
                  addPerms: ['add_other'],
                },
                hideExpression: () => !this.billOfLading.notify_party_second,
              },
              {
                key: 'consignee',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Consignee'),
                  placeholder: this.gettext('Consignee'),
                  resource: 'clients.clientrole',
                  addFunc: () => {
                    return this.ClientsService.roleModal({ model_name: 'Other' });
                  },

                  addIcon: this.GtUtils.getIcon('clients.clientrole'),
                  addPerms: ['add_other'],
                },
              },

              {
                key: 'carrier',
                type: 'gt-textarea',
                templateOptions: {
                  label: this.gettext('Carrier'),
                  placeholder: this.gettext('Carrier'),
                },
              },
              {
                key: 'is_loi',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('LOI'),
                  hint: this.gettext('Check this if LOI was issued'),
                },
              },
            ],
          },
        );
        col2.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
            },
            fieldGroup: [
              {
                key: 'voyage',
                type: 'gt-ui-select',
                defaultValue: this.extra.voyage,
                templateOptions: {
                  label: this.gettext('Voyage name'),
                  placeholder: this.gettext('Voyage name'),
                  resource: 'logistics.Voyage',
                  addFunc: () => {
                    return this.LogisticsService.voyageModal();
                  },
                  addPerms: ['add_voyage'],
                  addIcon: 'fa fa-ship',
                  hint: this.gettext('Voyage'),
                  help: this.gettext('Pick a voyage to which this bill of lading is related too'),
                },
              },
              {
                key: 'passport',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
                  placeholder: this.gettext('passport'),
                  resource: 'passports.Passport',
                },
              },
              {
                key: 'sale_contract',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Sale Contract'),
                  placeholder: this.gettext('sale contract'),
                  resource: 'contracts.SaleContract',
                  queryParams: () => ({
                    with_passport: this.billOfLading.passport,
                    multicontract: this.billOfLading.sale_multicontract,
                  }),
                  required: true,
                },
                expressionProperties: {
                  'templateOptions.required': (viewValue: any, modelValue: any, scope: any) => {
                    return !scope.model.purchase_contract && !scope.model.export_contract;
                  },
                },
              },
              {
                key: 'purchase_contract',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Purchase Contract'),
                  placeholder: this.gettext('purchase contract'),
                  resource: 'contracts.PurchaseContract',
                  queryParams: () => ({
                    with_passport: this.billOfLading.passport,
                    multicontract: this.billOfLading.purchase_multicontract,
                  }),
                  required: true,
                },
                expressionProperties: {
                  'templateOptions.required': (viewValue: any, modelValue: any, scope: any) => {
                    return !scope.model.sale_contract && !scope.model.export_contract;
                  },
                },
              },
              {
                key: 'export_contract',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Export Contract'),
                  placeholder: this.gettext('export contract'),
                  resource: 'contracts.ExportContract',
                  queryParams: () => ({ sale_contract_list: this.billOfLading.sale_contract }),
                  required: true,
                },
                expressionProperties: {
                  'templateOptions.required': (viewValue: any, modelValue: any, scope: any) => {
                    return !scope.model.sale_contract && !scope.model.purchase_contract;
                  },
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'NOTE',
            },
            fieldGroup: [
              {
                key: 'additional_info',
                type: 'gt-textarea',
                templateOptions: {
                  label: this.gettext('Additional info'),
                  placeholder: this.gettext('Additional info'),
                  className: 'additional-info',
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {},
            fieldGroup: [
              {
                template: /* html */ `
                  <custom-values-container
                    filter-level="'billOfLading-custom-values-container'"
                    init-query-params="{
                      object_id: model.id,
                      purpose: model.content_type,
                      purpose_model: 'billoflading',
                    }"
                    mode="model.id ? 'edit' : 'create'"
                    object-id="model.id"
                  ></custom-values-container>
                  `,
              },
            ],
          },
        );

        return {
          formName: 'bill-of-lading-edit-modal',
          fieldsDef: [col1, col2],
        };
      }
    },
  ],
};
