import template from './reservations-list-table.html?raw';

export const ReservationsListTable = {
  template,
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
    items: '<',
    count: '<',
    applyFilters: '&',
    openModal: '&',
    clone: '&',
  },
  controller: [
    'gettext',
    class {
      applyFilters: any;
      clone: any;
      count: number;
      filterLevel = 'reservations-list-table';
      gettext: ng.gettext.gettextFunction;
      items: any;
      openModal: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
        this.items = [];
        this.count = 0;
      }
      $onInit() {
        this.tableName = this.tableName || this.filterLevel;
        this.tableOptions = this.getTableOptions();
      }
      $onChanges(changes: any) {
        if (changes.items || changes.count) {
          this.tableData = { rows: this.items, count: this.count };
        }
      }
      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          configurable: true,
          changePageSize: true,
          showMore: true,
          templateArgs: {
            openModal: (item: any) => this.openModal({ item: item }),
            clone: (item: any) => this.clone({ item: item }),
          },
          tabs: [],
          columnDefs: [
            {
              columnName: 'title',
              class: 'td-left-align',
              title: this.gettext('Title'),
              cellTemplate: /*html*/ `
                <div class="space-between">
                  <div class="pull-left">
                    <i class="fa fa-arrow-down-up-lock"></i>
                    <span>{[{item.title}]}</span>
                  </div>
                  <ul
                    class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                    ng-mouseenter="args.setHovering(true)"
                    ng-mouseleave="args.setHovering(false)"
                  >
                    <li>
                      <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                        <i class="fa fa-ellipsis hide-on-hover"></i>
                        <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="btn btn-xs col-xs-12" ui-sref="logistics.reservationDetails({id: item.pk })">
                            <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'change_stockreserveunit'"
                            class="btn btn-xs aligned-btn"
                            ng-click="args.openModal({id: item.pk})"
                          >
                            <i class="fa fa-pencil"></i> <translate>Edit</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'change_stockreserveunit'"
                            ng-click="args.clone(item)"
                            class="btn btn-xs aligned-btn"
                          >
                            <i class="fa fa-files-o"></i> <translate>Clone</translate>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              `,
            },
            {
              columnName: 'date',
              class: 'td-left-align',
              title: this.gettext('Date'),
              cellTemplate: /*html*/ `
                <i class="fa fa-calendar"></i>
                <span>{[{ item.date | date:'dd.MM.yy' }]}</span>
              `,
            },
            {
              columnName: 'status',
              class: 'td-left-align',
              title: this.gettext('Status'),
              cellTemplate: /*html*/ `
                <span ng-if="item.status === 'new'" class="label label_default">
                  <translate>New</translate>
                </span>
                <span ng-if="item.status === 'processed'" class="label label_success">
                  <translate>Processed</translate>
                </span>
                <span ng-if="item.status === 'cancelled'" class="label label_danger">
                  <translate>Cancelled</translate>
                </span>
              `,
            },
            {
              columnName: 'commodity',
              class: 'td-left-align',
              title: this.gettext('commodity'),
              cellTemplate: /*html*/ `
                <i class="fa fa-wheat-alt }]}"></i>
                <span>{[{ item.commodity.title}]}</span>
             `,
            },
            {
              columnName: 'warehouse',
              class: 'td-left-align',
              title: this.gettext('warehouse'),
              cellTemplate: /*html*/ `
                <span ng-if="item.warehouse.pk">
                  <i
                    class="fa {[{ $root.gtUtils.getIcon('warehouses.' + item.warehouse.warehouse_type) }]}"
                  ></i>
                  <a ui-sref="logistics.warehouseDetails({id: item.warehouse.pk })">
                    {[{ item.warehouse.title }]}
                  </a>
                </span>
             `,
            },
            {
              columnName: 'businessUnit',
              class: 'td-left-align',
              title: this.gettext('Business unit'),
              cellTemplate: /*html*/ `
                <span>{[{ item.business_unit.title}]}</span>
             `,
            },
            {
              columnName: 'producer',
              class: 'td-left-align',
              title: this.gettext('Producer'),
              cellTemplate: /*html*/ `
                <span ng-if="item.producer.pk">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.client({id: item.producer.pk })">{[{ item.producer.title }]}</a>
                </span>
                `,
            },
            {
              columnName: 'receiver',
              class: 'td-left-align',
              title: this.gettext('Receiver'),
              cellTemplate: /*html*/ `
                <span ng-if="item.receiver.pk">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.client({id: item.receiver.pk })">{[{ item.receiver.title }]}</a>
                </span>
                `,
            },
            {
              columnName: 'owner',
              class: 'td-left-align',
              title: this.gettext('owner'),
              cellTemplate: /*html*/ `
                <span ng-if="item.owner.pk">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.client({id: item.owner.pk })">{[{ item.owner.title }]}</a>
                </span>
                `,
            },
            {
              columnName: 'purchaseContract',
              class: 'td-left-align',
              title: this.gettext('Purchase Contract'),
              cellTemplate: /*html*/ `
                <span ng-if="item.contract_position.pk">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.contract({ id: item.contract_position.pk})">
                    {[{ item.contract_position.title || ''}]}
                  </a>
                   
                </span>
                `,
            },
            {
              columnName: 'fromStockReserveUnit',
              class: 'td-left-align',
              title: this.gettext('From Stock Reserve Unit'),
              cellTemplate: /*html*/ `
                <span ng-if="item.from_stock_reserve_unit.pk">
                  <i class="fa fa-lock"></i>
                  <a ui-sref="logistics.stockReserveUnit({ id: item.from_stock_reserve_unit.pk})">{[{ item.from_stock_reserve_unit.title }]}</a>
                  <span
                    class="label label-default smaller-label"
                    ng-if="item.from_stock_reserve_unit.business_unit.title"
                  >
                    {[{item.from_stock_reserve_unit.business_unit.title}]}
                  </span>
                </span>
                `,
            },
            {
              columnName: 'toStockReserveUnit',
              class: 'td-left-align',
              title: this.gettext('To Stock Reserve Unit'),
              cellTemplate: /*html*/ `
                <span ng-if="item.to_stock_reserve_unit.pk">
                  <i class="fa fa-lock"></i>
                  <a ui-sref="logistics.stockReserveUnit({ id: item.to_stock_reserve_unit.pk})">{[{ item.to_stock_reserve_unit.title }]}</a>
                  <span
                    class="label label-default smaller-label"
                    ng-if="item.to_stock_reserve_unit.business_unit.title"
                  >
                    {[{item.to_stock_reserve_unit.business_unit.title}]}
                  </span>
                </span>
                `,
            },
            {
              columnName: 'volume',
              class: 'td-left-align',
              title: this.gettext('Volume'),
              cellTemplate: /*html*/ `
                <span ng-if="item.volume">{[{ item.volume }]}</span>
                `,
            },
            {
              columnName: 'description',
              class: 'td-left-align',
              title: this.gettext('Description'),
              cellTemplate: /*html*/ `
                <span ng-if="item.description">{[{ item.description }]}</span>
                `,
            },
          ],
        };
      }
    },
  ],
};
