import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtSpinner', {
    bindings: {},
    template: require('./gt-spinner.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope'];

  function Controller(this: any, $scope: ng.IScope) {
    const vm = this;
    vm.show = false;

    vm.$onInit = function () {
      $scope.$on('spinner-toggled', function (event: any, data: any) {
        vm.show = data;
      });
    };

    ////////////////
  }
})();
