import React from 'react';

import { dateFormat } from '~/shared/lib/date';

export const GreetingCard: React.FC<{ name: string }> = ({ name }) => {
  return (
    <section className="w-[240px] rounded-md border border-stroke-main-medium bg-background-main-primary p-6">
      <div className="text-xs text-text-main-tertiary" data-testid="greeting-date">
        {dateFormat(new Date(), 'dd MMMM yyyy')}
      </div>
      <div className="mt-6 flex flex-col">
        <h2 className="text-xl font-semibold">Hello, {name}!</h2>
        <p className="text-xs text-text-main-secondary">Check out what’s new!</p>
      </div>
    </section>
  );
};
