import type ng from 'angular';

import { type CoreService } from '~/app/core/core.service';
import { type GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import { type LogisticsService } from '~/app/execution/legacy/logistics.srv';

import template from './logistics-table.html?raw';
import { type LogisticFormFieldsService } from '../../logistic-form-fields.service';

type LogisticsQueryParams = QueryParams & {
  serializer: string;
};

class LogisticsTableController implements ng.IController {
  activeReportConfig: any = null;
  activeTab: any;
  allowRefreshCheckboxes = false;
  amountsData: any = [];
  applyCheckLimit: any;
  applyFilters: any;
  applyQualityDiscount: any;
  certificateTabTitles: any;
  charge_list: any;
  columnConfigs: any = undefined;
  columnVisibility: any = {};
  columnVisibilityCount = 0;
  connectToDocument = false;
  connectingDoc = false;
  connectingToBl = false;
  connectingToConsignment = false;
  connectingToDbl = false;
  connectingToExportContract = false;
  contractType: 'sale' | 'purchase' | 'none' = 'purchase';
  costsDistribution = false;
  count = 0;
  defaultLogisticsExportConfig = 'logistics-export-config';
  deleteEventSource: any;
  deletingLogistics = false;
  documentWatcher: any = null;
  edit = false;
  editMode: any = false;
  editModes: any = [
    'edit',
    'connectToDocument',
    'connectingToDbl',
    'connectingToConsignment',
    'connectingToExportContract',
    'invoicing',
    'connectingToBl',
    'deletingLogistics',
    'deletingLogistics',
    'costsDistribution',
  ];
  exportResourceClass: any = undefined;
  exportResourceClassConfig: Record<string, string> = {
    table_certs: 'table_certs',
    table_qualities: 'table_qualities',
  };
  filterLevel = 'logistics-table';
  getSelectedTotals: any;
  hovering = false;
  initQueryParams: Partial<LogisticsQueryParams> = {};
  intermediateTotals: any = {};
  invoiceCondition = 'prepay';
  invoiceGroupByTerminal = false;
  invoicePurpose: any = null;
  invoicignByInvoicePurposeConfig: any = {
    incoming: {
      allowedSerializers: ['table_supplier', 'table_qualities', 'table_certs'],
      default: 'table_supplier',
      role: 'supplier',
      contractType: 'purchase',
    },
    outgoing: {
      allowedSerializers: ['table_buyer', 'table_qualities'],
      default: 'table_buyer',
      role: 'buyer',
      contractType: 'sale',
    },
    export: {
      allowedSerializers: ['table_export', 'table_qualities'],
      default: 'table_export',
      role: 'export',
    },
    consolidated_costs: {
      allowedSerializers: ['table_costs'],
      default: 'table_costs',
      role: 'supplier',
    },
    costs: {
      allowedSerializers: ['table_buyer', 'table_supplier', 'table_qualities'],
      default: 'table_supplier',
      role: 'supplier',
    },
  };
  invoicing = false;
  invoicingContractType: any;
  invoicingVolume = 'volume_departed';
  logistics: any = [];
  logisticsExportConfig = 'logistics-export-config';
  mainTotals = {};
  newFinance = {};
  newLogistic = {};
  newLogisticTemplate: any;
  onConnectionToBl: any;
  onConnectionToDbl: any;
  onUpdate: any;
  openFilters = false;
  queryParams: LogisticsQueryParams = { serializer: 'table_info' };
  quickAdd = false;
  readonly = false;
  reportConfig: any = null;
  saveLogistic: any;
  savedFilterChoices: any;
  savedReportConfigs: any;
  selectAllChecked = false;
  selectedLogistics: any = [];
  sertDate = false;
  sertDocument: any = undefined;
  sertType = 'railway';
  setPurchasePrice: any;
  setTrue = false;
  showAllLogisticsOnObjectsCreate = false;
  showAllOnInvoicing?: boolean;
  showTotals: boolean;
  tableData: any = { rows: [], count: 0, total: {}, mainTotals: {}, intermediateTotals: {} };
  tableName?: string;
  tableOptions: any = {};
  total = {};
  uncheckEvent: any = null;
  view = 'table';
  contractId?: number;

  static readonly $inject = [
    '$rootScope',
    '$scope',
    'LogisticsService',
    'AccountsService',
    'gtFilterService',
    'GtUtils',
    'CoreService',
    'gettext',
    'CropsService',
    'DocumentsService',
    'LogisticFormFieldsService',
  ];

  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $scope: ng.IScope,
    private readonly LogisticsService: LogisticsService,
    private readonly AccountsService: any,
    private readonly gtFilterService: GtFilterService,
    private readonly GtUtils: GtUtilsService,
    private readonly CoreService: CoreService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly CropsService: any,
    private readonly DocumentsService: any,
    private readonly LogisticFormFieldsService: LogisticFormFieldsService,
  ) {
    this.activeTab = this.$rootScope.user.settings.DEFAULT_LOGISTICS_WITH_QUALITY
      ? 'table_qualities'
      : 'table_info';
    this.showTotals = this.$rootScope.user.settings.SHOW_TABLES_TOTAL;
  }

  $onInit() {
    this.view = this.view || 'table';
    this.tableName = this.tableName ?? this.filterLevel;
    this.sertDocument = this.sertDocument || false;
    this.defaultLogisticsExportConfig = 'logistics-export-config';
    this.logisticsExportConfig =
      this.logisticsExportConfig ||
      `${this.defaultLogisticsExportConfig}-${this.queryParams.serializer ?? 'table_info'}`;
    this.queryParams = { ...this.initQueryParams, ...this.queryParams };

    this.CoreService.getSavedFilterChoices(this.filterLevel)
      .then((data) => {
        this.savedFilterChoices = data;
      })
      .catch(this.GtUtils.errorClb);

    this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);

    this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
      this.queryParams = data;
      this.logisticsExportConfig = [
        this.defaultLogisticsExportConfig,
        this.queryParams.serializer,
      ].join('-');

      if (this.queryParams.serializer in this.exportResourceClassConfig) {
        this.exportResourceClass = this.exportResourceClassConfig[this.queryParams.serializer];
      } else {
        this.exportResourceClass = undefined;
      }

      this.gtFilterService.setQueryParams(this.queryParams, this.logisticsExportConfig);
    });
    this.$scope.$watch(
      () => this.edit,
      () => this.logistics?.forEach((row: any) => (row.$_edit = this.edit)),
    );

    this.$rootScope.$on(
      `gt-report-config-created_${this.filterLevel}`,
      (event: any, config: any) => {
        this.savedReportConfigs = [...this.savedReportConfigs, config];
      },
    );
    this.$rootScope.$on(
      `gt-report-config-updated_${this.filterLevel}`,
      (event: any, config: any) => {
        const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
        this.savedReportConfigs[index] = config;
      },
    );

    this.$rootScope.$on('invoicingVolume-changed', (ev: any, data: any) => {
      this.invoicingVolume = data;
      this.updateLogisticVolumes();
    });

    this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
    this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
    this.setTableOptions();

    if (this.$rootScope.user.settings.SHOW_TABLES_TOTAL) {
      this.showTotals = true;
    }
    this.$scope.$watch(
      () => this.extraEditModes(),
      () => this.updateEditMode(),
      true,
    );
    this.$scope.$watch(
      () => this.charge_list,
      () => {
        if (this.charge_list?.length > 0) {
          this.queryParams.charge_list = this.charge_list;
          this.applyFilters();
        }
      },
      true,
    );
    this.contractId =
      ((this.initQueryParams.export_contract ??
        this.initQueryParams.supplier_contract ??
        this.initQueryParams.buyer_contract ??
        this.initQueryParams.intermediate_contract) as number) ?? undefined;
  }

  updateTableData() {
    this.tableData = {
      rows: this.logistics,
      count: this.count,
      total: this.total,
      mainTotals: this.mainTotals,
      intermediateTotals: this.intermediateTotals,
    };
  }

  $onChanges(changes: any) {
    if (changes.activeReportConfig) {
      this.setTableOptions();
    }
    if (
      changes.total ||
      changes.logistics ||
      changes.count ||
      changes.mainTotals ||
      changes.intermediateTotals
    ) {
      this.updateTableData();
    }
    if (changes.allowRefreshCheckboxes && this.allowRefreshCheckboxes) {
      this.refreshCheckboxes(undefined);
    }
    if (!this.connectToDocument && changes.logistics && !this.deletingLogistics) {
      this.newLogisticTemplate = this.initNewLogisticTemplate(this.newLogistic);
      this.selectAllChecked = false;
      this.showTotals = true;
      if (this.quickAdd) {
        this.newLogistic = { ...this.newLogisticTemplate };
      }
      if (this.connectToDocument) {
        this.showCheckboxes(true);
        this.documentWatcher?.();
        this.documentWatcher = this.$scope.$watch(
          this.getLogisticCheckboxes(),
          () => this.broadcastLogisticCheckboxes(),
          true,
        );
        this.uncheckEvent?.();
        this.uncheckEvent = this.$scope.$on(
          'logistics__uncheck-bad',
          (ev: any, certificateTypes: any) => {
            this.logistics.forEach((logistic: any) => {
              certificateTypes.forEach((certificateType: any) => {
                if (logistic[certificateType]) {
                  logistic._selected = false;
                }
              });
            });
          },
        );
      }
    }
  }

  onApplyQualityDiscount() {
    this.applyQualityDiscount();
  }

  onApplyCheckLimit() {
    this.applyCheckLimit();
  }

  onSetPurchasePrice() {
    this.setPurchasePrice();
  }

  initNewLogisticTemplate(origin: any) {
    origin = origin || {};
    return {
      ...{ $_edit: true, vehicle_type: 'car' },
      ...origin,
      ...this.queryParams,
    };
  }

  toggleInlineAdd() {
    if (this.$rootScope.user.settings.ENABLE_INLINE_ADD) {
      if (!this.quickAdd) {
        this.newLogistic = { ...this.initNewLogisticTemplate(this.newLogistic) };
      }
      this.logistics = [...this.logistics];
    }
    this.quickAdd = !this.quickAdd;
  }

  getLogisticCheckboxes() {
    return this.logistics.map((logistic: any) => {
      return logistic._selected;
    });
  }

  broadcastLogisticCheckboxes() {
    this.$scope.$parent.$broadcast(
      'logistics__checkboxes-update',
      this.logistics
        .filter((logistic: any) => {
          return logistic._selected;
        })
        .map((logistic: any) => {
          return {
            id: logistic.id,
          };
        }),
      this.logistics
        .filter((logistic: any) => {
          return (
            logistic._selected &&
            (logistic.railway ||
              logistic.vet ||
              logistic.quarantine ||
              logistic.quality ||
              logistic.declaration ||
              logistic.eur_one ||
              logistic.broker_confirmation ||
              logistic.additional_document_one ||
              logistic.additional_document_two ||
              logistic.additional_document_three)
          );
        })
        .map((logistic: any) => {
          return {
            id: logistic.id,
            vehicle_number: logistic.vehicle_number,
            railway: logistic.railway,
            vet: logistic.vet,
            quarantine: logistic.quarantine,
            quality: logistic.quality,
            declaration: logistic.declaration,
            eur_one: logistic.eur_one,
            broker_confirmation: logistic.broker_confirmation,
            additional_document_one: logistic.additional_document_one,
            additional_document_two: logistic.additional_document_two,
            additional_document_three: logistic.additional_document_three,
          };
        }),
    );
  }

  connectToDbl() {
    const logisticIds = this.logistics
      .filter((logistic: any) => logistic._selected)
      .map((logistic: any) => {
        return logistic.id;
      });
    if (logisticIds.length === 0) {
      return this.GtUtils.notify(this.gettext('any of logistics'), 'error');
    }

    this.onConnectionToDbl({ logisticIds: logisticIds, logistic_type: 'Logistic' }).then(() =>
      this.updateData(),
    );
  }

  selectAll(checked: any) {
    this.logistics.forEach((logistic: any) => {
      logistic._selected = logistic._showCheckbox && checked;
    });

    this.selectedLogistics = this.logistics.filter((logistic: any) => logistic._selected);

    this.getSelectedTotals({
      idList: this.selectedLogistics.map((logistic: any) => logistic.id),
    });
  }

  callSelectAll() {
    setTimeout(() => this.selectAll(this.selectAllChecked));
  }

  connectToBl() {
    const filteredLogistics = this.logistics.filter((logistic: any) => logistic._selected);
    const logisticIds = filteredLogistics.map((logistic: any) => logistic.id);

    if (!logisticIds.length) {
      return this.GtUtils.notify(this.gettext('any of logistics'), 'error');
    }
    const groupedLogistics = this.CoreService.groupBy(filteredLogistics, 'supplier_contract');
    if (Object.keys(groupedLogistics).length !== 1) {
      return this.GtUtils.notify(this.gettext('logistics with single purchase contract'), 'error');
    }

    this.onConnectionToBl({
      volume: this.amountsData.bl_amount_volume,
      logisticIds: logisticIds,
      purchaseContractId: Object.keys(groupedLogistics)[0],
      containerNumber: logisticIds.length.toString(),
    }).then(() => this.updateData());
  }

  setValue(key: keyof LogisticsTableController, value: any) {
    (this as any)[key] = value;
  }

  showCheckboxes(show: any) {
    this.logistics.forEach(
      (item: any) => (item._showCheckbox = typeof show === 'function' ? show(item) : !!show),
    );
  }
  extraEditModes() {
    return this.editModes.map((key: keyof LogisticsTableController) => this[key]);
  }
  cleanEditMode() {
    this.invoicePurpose = null;
    this.editModes.forEach((mode: keyof LogisticsTableController) => {
      (this as any)[mode] = false;
    });
    this.editMode = false;
  }
  setEditMode(mode: any, purpose: any) {
    this.editModes.forEach(
      (_mode: keyof LogisticsTableController) => ((this as any)[_mode] = mode === _mode),
    );
    this.invoicePurpose = purpose;
    this.editMode = true;
    this.refreshCheckboxes(undefined);
  }
  updateEditMode() {
    this.editMode = this.extraEditModes().some(Boolean);
  }
  getCheckBoxVisibilityFunc(role: any) {
    const commonCondition = (item: any) =>
      item.approval_status !== 'rejected' &&
      item[`${role}_contract`] &&
      !(item[`${role}_ten_invoice`] && item[`${role}_nineteen_invoice`]) &&
      !(item.has_diff_limit && item.approval_status === 'process');

    if (this.invoiceCondition === 'prepay') {
      return (item: any) => commonCondition(item) && !item[`${role}_invoiced`];
    }
    if (this.invoiceCondition === 'balance') {
      return (item: any) => commonCondition(item) && !item[`${role}_invoiced_balance`];
    }
    return commonCondition;
  }
  updateChangedFields(changedFields: Record<string, string> = {}) {
    Object.keys(changedFields)
      .filter((key) => changedFields[key])
      .forEach((key) => ((this as any)[key] = changedFields[key]));
  }
  refreshCheckboxes(changedFields: any) {
    if (!this.editMode || !this.allowRefreshCheckboxes) {
      this.selectAll(false);
      this.showCheckboxes(false);
      this.selectedLogistics = [];
      return;
    }
    if (this.deletingLogistics) {
      this.selectAll(false);
      this.showCheckboxes((item: any) => !!item.id);
    }
    if (this.connectingToBl) {
      this.selectAll(false);
      this.showCheckboxes((item: any) => (item.bl_volume_balance || 0) > 0);
    }
    if (this.connectingToDbl) {
      this.selectAll(false);
      this.showCheckboxes(true);
    }
    if (this.connectingToConsignment) {
      this.selectAll(false);
      this.showCheckboxes(
        (item: any) =>
          (item.writeof_volume_balance || 0) > 0 && this.queryParams.export_contract_list,
      );
    }
    if (this.connectingToExportContract) {
      this.selectAll(false);
      this.showCheckboxes(true);
    }
    if (this.invoicing) {
      this.updateChangedFields(changedFields);
      if (this.invoicePurpose === 'export') {
        this.updateLogisticVolumes();
      }
      if (this.invoicePurpose === 'costs') {
        this.updateLogisticVolumes();
        this.showCheckboxes(
          (item: any) => !item.cost_invoiceposition && item.costs && item.supplier_contract,
        );
        this.refreshCheckboxesInvoiceCostsContract();
      }
      if (this.invoicePurpose === 'consolidated_costs') {
        this.showCheckboxes(
          (item: any) =>
            item._selected ||
            item.transport_costs_data?.filter(
              (cost: any) =>
                (!this.charge_list?.length || this.charge_list.includes(cost.charge_id)) &&
                cost.price_per_deal > (cost.invoiced_amount || 0),
            )?.length ||
            false,
        );
        this.refreshCheckboxesInvoiceCostsContract();
      }
      if (['incoming', 'outgoing'].includes(this.invoicePurpose)) {
        const role = this.invoicignByInvoicePurposeConfig[this.invoicePurpose].role;
        this.showCheckboxes((item: any) => this.getCheckBoxVisibilityFunc(role)(item));
      }
      if (this.connectingToExportContract) {
        this.showCheckboxes(true);
      }
      return;
    }
    this.selectedLogistics = this.logistics.filter((item: any) => item._selected);
  }
  _hasContract(logistic: any) {
    return !!{
      purchase: logistic.supplier_contract_contract_number,
      sale: logistic.supplier_contract_contract_number,
      none: true,
    }[this.contractType];
  }

  _hasUninvoicedCost(logistic: any) {
    return !!{
      purchase: logistic.supplier_contract_contract_number,
      sale: logistic.supplier_contract_contract_number,
      none: undefined,
    }[this.contractType];
  }

  refreshCheckboxesInvoiceCostsContract() {
    this.logistics.forEach((item: any) => (item._selected = item._selected && item._showCheckbox));
    this.selectedLogistics = this.logistics.filter((logistic: any) => logistic._selected);
  }

  updateLogisticVolumes() {
    this.logistics.forEach((item: any) => {
      if (this.invoicingVolume) {
        const key = `quantity_to_be_invoiced_${this.invoicingVolume}_based`;
        item.quantity_to_be_invoiced = item[key];
      }
      item._showCheckbox = item.quantity_to_be_invoiced > 0 && !!item.export_contract_number;
      item._show_quanity_input = item._showCheckbox;
    });
  }

  toggleDocumentCheckboxes() {
    this.connectToDocument = !this.connectToDocument;
    if (this.connectToDocument) {
      this.documentWatcher?.();
      this.documentWatcher = this.$scope.$watch(
        this.getLogisticCheckboxes(),
        () => this.broadcastLogisticCheckboxes(),
        true,
      );
      this.uncheckEvent?.();
      this.uncheckEvent = this.$scope.$on(
        'logistics__uncheck-bad',
        (ev: any, certificateTypes: any) => {
          this.logistics.forEach((logistic: any) => {
            certificateTypes.forEach((certificateType: any) => {
              if (logistic[certificateType]) {
                logistic._selected = false;
              }
            });
          });
        },
      );
      this.showCheckboxes(true);
    } else {
      this.showCheckboxes(false);
    }
  }

  updateData(updated = true) {
    this.cleanEditMode();
    if (updated) {
      return this.onUpdate();
    }
  }

  createLogistic(logistic: any) {
    const logisticToCreate = { logistic: { ...logistic } };
    this.newLogistic = { ...this.initNewLogisticTemplate(this.newLogistic) };
    this.saveLogistic(logisticToCreate);
  }

  deleteLogistic() {
    const msg = this.gettext('Are you sure that you want to delete?');
    if (!confirm(msg)) {
      return;
    }
    this.newLogistic = {};
    this.updateData();
  }

  prepareDeleting() {
    this.setEditMode('deletingLogistics', undefined);
    this.refreshCheckboxes(undefined);
  }
  editSave() {
    this.logistics.forEach((logistic: any) => {
      if (!logistic._dirty) {
        return;
      }

      logistic.volume_departed = logistic.volume_departed || 0;
      logistic.volume_departed_consignment = logistic.volume_departed_consignment || 0;
      logistic.volume_received = logistic.volume_received || 0;
      logistic.volume_departed_real = logistic.volume_departed_real || 0;
      logistic.volume_boarded = logistic.volume_boarded || 0;
    });

    return this.LogisticsService.Logistic.bulkCreateOrUpdate(
      { partial: true },
      this.logistics.filter((logistic: any) => logistic.clicked),
    ).$promise.then(() => this.updateData());
  }

  onCheckboxClick(logistic: any) {
    if (logistic._selected) {
      this.selectedLogistics = [...this.selectedLogistics, logistic];
    } else {
      this.selectedLogistics = this.selectedLogistics.filter(
        (selectedLogistic: any) => selectedLogistic.id !== logistic.id,
      );
    }

    if (!this.deletingLogistics) {
      this.getSelectedTotals({
        idList: this.selectedLogistics.map((logistic: any) => logistic.id),
      });
    }
    this.broadcastLogisticCheckboxes();
  }

  connectDocument() {
    if (!this.sertDate) {
      return this.GtUtils.notify(this.gettext('Please set date.'), 'error');
    }
    this.logistics.forEach((logistic: any) => {
      if (!logistic._selected) {
        return;
      }
      logistic[this.sertType] = this.sertDate;
      if (logistic.contract_documents.indexOf(this.sertDocument.id) === -1) {
        logistic.contract_documents.push(this.sertDocument.id);
      }
      logistic._dirty = true;
    });
    this.editSave();
  }

  updateQuantitySum() {
    this.selectedLogistics = [...this.selectedLogistics];
  }

  showAllData() {
    this.gtFilterService.updateQueryParams(
      { ...this.queryParams, page_size: 999999 },
      this.filterLevel,
    );
    return this.onUpdate().then((data: any) => {
      this.updateTableData();
      return data;
    });
  }
  startInvoicing(invoicePurpose: any) {
    const currentConfig = this.invoicignByInvoicePurposeConfig[invoicePurpose];
    if (!currentConfig) {
      return;
    }
    if (!currentConfig.allowedSerializers.includes(this.queryParams.serializer)) {
      this.queryParams.serializer = currentConfig.default;
      this.activeTab = currentConfig.default;
      this.changeActiveTab(this.activeTab);
      this.invoicingContractType = currentConfig.contractType;
      if (
        this.invoicePurpose !== 'costs' &&
        this.invoicePurpose !== 'sale' &&
        this.invoicePurpose !== 'purchase'
      ) {
        this.onUpdate();
      }
    }
    if (this.showAllOnInvoicing) {
      return this.showAllData().then(() => this.startInvoicingClb(invoicePurpose));
    }
    this.startInvoicingClb(invoicePurpose);
  }
  startInvoicingClb(invoicePurpose: any) {
    this.setContractType();
    this.setEditMode('invoicing', invoicePurpose);
    this.refreshCheckboxes(undefined);
  }
  cloneLogistic(logistic: any) {
    return this.LogisticsService.cloneLogistic(logistic).then(() => this.updateData());
  }

  setHovering(value: any) {
    this.hovering = value;
  }

  openLogisticModal(logistic: any) {
    return this.LogisticsService.logisticModal(logistic).then(() => this.updateData());
  }

  openDocxModal(logistic: any) {
    return this.DocumentsService.generateDocxModal('Logistic', logistic.id);
  }

  updateLogistic(logistic: any) {
    return this.LogisticsService.Logistic.update({ partial: true }, logistic).$promise.then(
      this.onUpdate(),
    );
  }

  openQualitiesModal(logistic: any) {
    this.CropsService.qualitiesModal(
      logistic.qualities,
      null,
      logistic.cargo,
      null,
      logistic.id,
    ).then((data: any) => {
      if (!data || data === 'cancel') {
        return;
      }
      this.onUpdate();
    });
  }

  deleteDoc(logistic: any, doc: any) {
    const msg = this.gettext('Are you sure that you want delete this document?');
    if (!confirm(msg)) {
      return;
    }

    const eventName = this.deleteEventSource
      ? `documents-list__remove-from-${this.deleteEventSource}`
      : 'documents-list__remove';

    this.$rootScope.$emit(eventName, logistic, doc);
  }

  setDateField(logistic: any, $event: any, field: any) {
    logistic[field] = $event.date;
    logistic._dirty = true;
  }

  setContractType() {
    this.contractType =
      (this.initQueryParams.serializer === 'table_buyer' && 'sale') ||
      (this.initQueryParams.serializer === 'table_supplier' && 'purchase') ||
      'purchase';
  }

  changeActiveTab(tab: any) {
    this.activeTab = tab;
    this.tableOptions = { ...this.tableOptions, activeTab: this.activeTab };
    if (tab === 'table_supplier') {
      this.invoicePurpose = 'purchase';
    } else if (tab === 'table_buyer') {
      this.invoicePurpose = 'sale';
    } else if (tab === 'table_service') {
      this.invoicePurpose = 'service';
    } else {
      this.invoicePurpose = null;
    }
    this.applyFilters({ params: { serializer: this.activeTab } });
  }
  getCertificateTabTitles() {
    if (this.tableOptions?.tabs) {
      const certsTab = this.tableOptions.tabs.find((tab: any) => tab.serializer === 'table_certs');
      if (certsTab?.columns) {
        return this.tableOptions.columnDefs.filter((column: any) =>
          certsTab.columns.includes(column.columnName),
        );
      }
    }
  }

  setTableOptions() {
    const options: any = {
      tableName: this.tableName,
      filterLevel: this.filterLevel,
      columnDefs: [] as any[],
      tabs: [],
      tableClass: 'request-table sticky-table-first-td-ultra-condensed',
      applyFilters: this.applyFilters,
      activeTab: this.queryParams.serializer,
      activeReportConfig: this.activeReportConfig,
      configurable: true,
      showMore: true,
      changePageSize: true,
      templateArgs: {
        onCheck: (logistic: any) => this.onCheckboxClick(logistic),
        updateQuantitySum: () => this.updateQuantitySum(),
        clone: (logistic: any) => this.cloneLogistic(logistic),
        openLogisticModal: (logistic: any) => this.openLogisticModal(logistic),
        openDocxModal: (logistic: any) => this.openDocxModal(logistic),
        updateLogistic: (logistic: any) => this.updateLogistic(logistic),
        openQualitiesModal: (logistic: any) => this.openQualitiesModal(logistic),
        deleteDoc: (logistic: any, doc: any) => this.deleteDoc(logistic, doc),
        setDateField: (logistic: any, $event: any, field: any) =>
          this.setDateField(logistic, $event, field),
        total: this.total,
        mainTotal: this.mainTotals,
        intermediateTotals: this.intermediateTotals,
      },
    };

    options.tabs = [
      {
        serializer: 'table_info',
        title: this.gettext('Info'),
        icon: 'fa-info-circle',
        columns: [
          'logistic_type',
          'shipment_warehouse',
          'unloading_warehouse',
          'vehicle_number',
          'consignment_number',
          'custom_status',
          'voyage',
          'acceptance_certificate',
          'billoflading',
          'status',
          'approval_status',
          'payment',
          'crop',
          'disbursementbl',
          'volume_departed',
          'writeof_volume_balance',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'volume_boarded',
          'volume_lost',
          'loading_shortage',
          'loading_date',
          'receiving_date',
          'sale_date',
          'boarding_date',
          'days_at_port',
          'supplier_contract_supplier_name',
          'forwarder',
          'exporter',
          'export_contract',
          'export_contract_price',
          'buyer_contract__buyer',
          'purchase_passports_data',
          'sale_passports_data',
          'business_unit',
          'unloading_business_unit',
          'shipment_business_unit',
          'station',
          'station_receiving',
          'port__name',
          'elevator',
          'crop_year',
          'cargo_brand',
          'bags_weight',
          'bags_quantity',
          'additional_info',
          'update_time',
          'shipment_place',
          'unloading_place',
          'estimated_receiving_date',
          'deliverer_in_port',
          'intermediate_contract__contract_number',
          'producer',
          'receiver',
          'buyer_contract__contract_number',
          'supplier_contract__contract_number',
          'fromStockReserveUnit',
          'toStockReserveUnit',
          'supplier_contract__contract_number',
        ],
      },
      {
        serializer: 'table_supplier',
        title: this.gettext('Purchase'),
        icon: 'fa-arrow-down',
        permission: !this.AccountsService.hasPerm('view_logisticpurchaseprice'),
        columns: [
          'logistic_type',
          'shipment_warehouse',
          'unloading_warehouse',
          'vehicle_number',
          'consignment_number',
          'custom_status',
          'status',
          'approval_status',
          'payment',
          'crop',
          'volume_departed',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'volume_boarded',
          'volume_lost',
          'loading_shortage',
          'loading_date',
          'receiving_date',
          'supplier_contract_supplier_name',
          'exporter',
          'forwarder',
          'station',
          'station_receiving',
          'port__name',
          'supplier_contract__contract_number',
          'conditions_supplier',
          'supplier_nineteen_invoice__number',
          'supplier_ten_invoice__number',
          'supplier_contract__price',
          'supplier_to_be_invoiced',
          'supplier_to_be_invoiced_balance',
          'supplier_invoiced',
          'supplier_invoiced_balance',
          'supplier_paid',
          'supplier_paid_balance',
          'supplier_total_invoiced_plan',
          'supplier_total_invoiced',
          'supplier_total_paid',
          'cargo_brand',
          'bags_weight',
          'bags_quantity',
          'additional_info',
          'estimated_receiving_date',
          'intermediate_contract__contract_number',
          'producer',
          'receiver',
          'fromStockReserveUnit',
          'toStockReserveUnit',
        ],
      },
      {
        serializer: 'table_buyer',
        title: this.gettext('Sale'),
        icon: 'fa-arrow-up',
        permission: !this.AccountsService.hasPerm('view_logisticsaleprice'),
        columns: [
          'logistic_type',
          'shipment_warehouse',
          'unloading_warehouse',
          'vehicle_number',
          'consignment_number',
          'custom_status',
          'status',
          'approval_status',
          'payment',
          'crop',
          'buyer_contract__buyer',
          'volume_departed',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'volume_boarded',
          'volume_lost',
          'loading_shortage',
          'loading_date',
          'receiving_date',
          'supplier_contract_supplier_name',
          'exporter',
          'forwarder',
          'buyer_contract__buyer',
          'station',
          'station_receiving',
          'port__name',
          'buyer_contract__contract_number',
          'conditions_buyer',
          'buyer_nineteen_invoice__number',
          'buyer_ten_invoice__number',
          'buyer_contract__price',
          'buyer_to_be_invoiced',
          'buyer_to_be_invoiced_balance',
          'buyer_invoiced',
          'buyer_invoiced_balance',
          'buyer_paid',
          'buyer_paid_balance',
          'buyer_total_invoiced_plan',
          'buyer_total_invoiced',
          'buyer_total_paid',
          'cargo_brand',
          'bags_weight',
          'bags_quantity',
          'additional_info',
          'estimated_receiving_date',
          'intermediate_contract__contract_number',
          'producer',
          'receiver',
          'fromStockReserveUnit',
          'toStockReserveUnit',
        ],
      },
      {
        serializer: 'table_costs',
        title: this.gettext('Costs'),
        icon: 'fa-dollar',
        permission: !this.AccountsService.hasPerm('view_logisticfinancetab'),
        columns: [
          'logistic_type',
          'shipment_warehouse',
          'unloading_warehouse',
          'vehicle_number',
          'consignment_number',
          'custom_status',
          'voyage',
          'acceptance_certificate',
          'billoflading',
          'status',
          'approval_status',
          'payment',
          'crop',
          'disbursementbl',
          'volume_departed',
          'writeof_volume_balance',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'volume_boarded',
          'volume_lost',
          'loading_shortage',
          'loading_date',
          'receiving_date',
          'sale_date',
          'boarding_date',
          'days_at_port',
          'supplier_contract_supplier_name',
          'forwarder',
          'exporter',
          'export_contract',
          'export_contract_price',
          'buyer_contract__buyer',
          'purchase_passports_data',
          'sale_passports_data',
          'business_unit',
          'unloading_business_unit',
          'shipment_business_unit',
          'station',
          'station_receiving',
          'port__name',
          'elevator',
          'crop_year',
          'cargo_brand',
          'bags_weight',
          'bags_quantity',
          'additional_info',
          'update_time',
          'shipment_place',
          'unloading_place',
          'estimated_receiving_date',
          'deliverer_in_port',
          'intermediate_contract__contract_number',
          'producer',
          'receiver',
          'transport_costs',
          'cost_invoiceposition',
          'delivery',
          'penalties',
          'payment',
          'buyer_contract__contract_number',
          'supplier_contract__contract_number',
          'fromStockReserveUnit',
          'toStockReserveUnit',
        ],
      },
      {
        serializer: 'table_certs',
        title: this.gettext('Certs'),
        icon: 'fa-list-alt',
        permission: !this.AccountsService.hasPerm('view_logisticcerts'),
        columns: [
          'logistic_type',
          'shipment_warehouse',
          'unloading_warehouse',
          'vehicle_number',
          'consignment_number',
          'custom_status',
          'acceptance_certificate',
          'billoflading',
          'railway',
          'quality',
          'vet',
          'quarantine',
          'declaration',
          'eur_one',
          'broker_confirmation',
          'additional_document_one',
          'additional_document_two',
          'additional_document_three',
          'status',
          'payment',
          'crop',
          'disbursementbl',
          'volume_departed',
          'cargo_brand',
          'bags_weight',
          'bags_quantity',
          'additional_info',
          'update_time',
          'fromStockReserveUnit',
          'toStockReserveUnit',
        ],
      },
      {
        serializer: 'table_qualities',
        title: this.gettext('Qualities'),
        icon: 'fa-flask',
        permission: !this.AccountsService.hasPerm('view_logisticqualitytab'),
        columns: [
          'logistic_type',
          'vehicle_number',
          'consignment_number',
          'custom_status',
          'voyage',
          'payment',
          'crop',
          'approval_status',
          'has_diff_limit',
          'qualities',
          'volume_departed',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'volume_discounted',
          'volume_boarded',
          'discount_volume',
          'supplier_contract__price',
          'supplier_contract__contract_number',
          'discount_purchase_price',
          'purchase_init_price',
          'purchase_total_value',
          'purchase_discount_value',
          'purchase_total_value_discounted',
          'buyer_contract__price',
          'buyer_contract__contract_number',
          'discount_sale_price',
          'sale_init_price',
          'sale_total_value',
          'sale_discount_value',
          'sale_total_value_discounted',
          'volume_sale_trader',
          'sale_trader_amount',
          'sale_trader_volume_diff',
          'sale_trader_amount_diff',
          'sale_trader_discount_amount',
          'additional_info',
          'update_time',
          'deliverer_in_port',
          'export_contract_price',
          'fromStockReserveUnit',
          'toStockReserveUnit',
        ],
      },
      {
        serializer: 'table_export',
        title: this.gettext('Export'),
        permission: !this.AccountsService.hasPerm('view_logisticexporttab'),
        icon: 'fa fa-arrow-up-from-bracket',
        columns: [
          'logistic_type',
          'shipment_warehouse',
          'unloading_warehouse',
          'vehicle_number',
          'consignment_number',
          'crop',
          'volume_boarded',
          'volume_departed',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'export_contract',
          'export_invoices_quantity_sum',
          'export_invoice_list',
          'additional_info',
          'update_time',
          'customs_declarations_data',
          'cargo_customs_declarations_data',
          'periodic_customs_declarations_data',
          'fromStockReserveUnit',
          'toStockReserveUnit',
        ],
      },
    ];

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.transport_pnl_tab) {
      options.tabs.push({
        serializer: 'table_pnl',
        title: this.gettext('PnL'),
        icon: 'fa-money',
        columns: [
          'vehicle_number',
          'buyer_contract__contract_number',
          'supplier_contract__contract_number',
          'volume_departed',
          'volume_departed_consignment',
          'volume_received',
          'volume_departed_real',
          'volume_boarded',
          'supplier_contract_price',
          'buyer_contract_price',
          'simple_purchase_price',
          'simple_sale_price',
          'total_purchase_value',
          'total_sale_value',
          'transport_costs_amount',
          'invoice_positions_amount',
          'payment_amount_sum',
          'purchase_contract_cost_per_ton',
          'sale_contract_cost_per_ton',
          'passport_cost_per_ton',
          'passports_related',
          'purchase_invoice_total',
          'sale_invoice_total',
          'contract_pnl_per_auto_with_vat',
          'sale_contract_pnl_per_t_with_vat',
          'purchase_contract_pnl_per_t_with_vat',
          'execution_pnl_per_auto_with_vat',
          'sale_execution_pnl_per_t_with_vat',
          'purchase_execution_pnl_per_t_with_vat',
          'invoices_pnl_per_auto_with_vat',
          'sale_invoices_pnl_per_t_with_vat',
          'purchase_invoices_pnl_per_t_with_vat',
          'payments_pnl_per_auto_with_vat',
          'sale_payments_pnl_per_t_with_vat',
          'purchase_payments_pnl_per_t_with_vat',
        ],
      });
    }

    options.columnDefs = [
      {
        showAlways: true,
        columnName: 'vehicle_number',
        title: this.gettext('Vehicle (#)'),
        predicate: 'vehicle_number',
        class: 'td-width-vehicle-number-cell name',
        filters: [
          {
            type: 'text',
            predicate: 'vehicle_number',
            placeholder: 'Vehicle number',
            addon: '#',
          },
          {
            type: 'select',
            predicate: 'vehicle_type',
            label: this.gettext('Vehicle type'),
            values: {
              car: this.gettext('Car'),
              wagon: this.gettext('Wagon'),
              container: this.gettext('Container'),
            },
          },
        ],
        cellTemplate: /*html*/ `
              <div ng-if="!item.$_edit">
                <div class="col-xs-1" ng-if="item._showCheckbox" style="padding-left:0; padding-right:5px;">
                  <input
                    type="checkbox"
                    ng-model="item._selected"
                    ng-change="args.onCheck(item)"
                  />
                </div>
                <div class="col-xs-2" ng-if="item._showCheckbox && item._show_quanity_input" style="padding-left:0; padding-right:0;">
                  <input class="form-control" type="number" ng-change="args.updateQuantitySum()" ng-model="item.quantity_to_be_invoiced" />
                </div>
                <div class="space-between" ng-class="{'col-xs-12': !item._showCheckbox, 'col-xs-9': item._showCheckbox && item._show_quanity_input, 'col-xs-11': item._showCheckbox && !item._show_quanity_input}" style="padding-left:5px; padding-right:0;">
                  <ul class="nav navbar-nav item-settings-dropdown">
                    <li>
                      <a
                        class="dropdown-toggle user-dropdown-btn"
                        ui-sref="gt.page.logistic({id: item.id })"
                        class="btn btn-xs aligned-btn"
                      >
                        <span
                          ng-if="item.vehicle_number"
                          ng-class="{ 'label label_danger': !!item.has_diff_limit }"
                        >
                        <i class="fa" ng-class="{ 'fa-truck': item.vehicle_type == 'car', 'fa-train': item.vehicle_type == 'wagon', 'fa-box': item.vehicle_type == 'container', 'fa-ship': item.vehicle_type == 'vessel' }"></i>
                          {[{ item.vehicle_number | cut:true:40:'' }]}
                          <span ng-if="item.vehicle_number_alt">
                            / {[{ item.vehicle_number_alt | cut:true:40:'' }]}
                          </span>
                          <span ng-if="item.vehicle_subtype_title">
                            / {[{ item.vehicle_subtype_title | cut:true:40:'' }]}
                          </span>
                        </span>
                        <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                        <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                      </a>
                    </li>
                  </ul>
                  <ul
                    class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                    ng-mouseenter="args.setHovering(true)"
                    ng-mouseleave="args.setHovering(false)"
                  >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      <i class="fa fa-ellipsis hide-on-hover"></i>
                      <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="btn btn-xs col-xs-12" ui-sref="gt.page.logistic({id: item.id })">
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn"
                          ng-disabled="item.buyer_contract_status === 'executed' && item.supplier_contract_status === 'executed' && item.custom_status_codename === 'executed'"
                          ng-click="args.openLogisticModal(item)"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a class="btn btn-xs col-xs-12" ng-click="args.clone(item)">
                          <i class="fa fa-files-o"></i> <translate>Clone</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'add_document'"
                          class="btn btn-xs aligned-btn"
                          ng-click="args.openDocxModal(item)"
                          ng-if="!item._showCheckbox"
                          >
                          <i class="fa fa-download"></i><translate>Create Document</translate>
                        </a>
                      </li>
                    </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div ng-if="item.$_edit">
                <input
                  type="checkbox"
                  ng-model="item._selected"
                  ng-change="args.onCheck(item)"
                  ng-if="item._showCheckbox && item.approval_status !== 'process'"
                  style="margin-right: 5px"
                />
                <input
                  ng-if="item.clicked"
                  class="form-control"
                  type="text"
                  ng-model="item.vehicle_number"
                />
                <select ng-model="item.vehicle_type" ng-click="item.clicked=true">
                  <option value="car"><translate>Car</translate></option>
                  <option value="wagon"><translate>Wagon</translate></option>
                  <option value="container"><translate>Container</translate></option>
                  <option value="vessel"><translate>Vessel</translate></option>
                </select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked">
                  {[{ item.vehicle_number }]}
                </span>
              </div>
            `,
        totalTemplate: /*html*/ `
              <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
            `,
      },
      {
        columnName: 'export_contract',
        title: this.gettext('Export Contract (#)'),
        class: 'td-width-export-contract-cell',
        filters: [
          {
            type: 'ui-select',
            predicate: 'export_contract_list',
            label: this.gettext('Export contract number'),
            resource: 'contracts.ContractBase',
            related_fk: 'export_contract',
            related_model: 'logistics.Logistic',
            queryParams: {
              deal_type: 'export',
              contract_type: 'sale',
            },
          },
        ],
        cellTemplate: `
                <span ng-if="item.export_contract_supplier_name">
                    <i class="fa fa-bookmark-o"></i> {[{item.export_contract_supplier_name}]}
                </span>
                <span class="label-opacity" ng-if="!item.export_contract_supplier_name">
                    <i class="fa fa-bookmark-o"></i> ---
                </span>
                <span ng-if="item.export_contract_buyer_name">
                    <i class="fa fa-bookmark"></i> {[{item.export_contract_buyer_name}]}
                </span>
                <span class="label-opacity" ng-if="!item.export_contract_buyer_name">
                    <i class="fa fa-bookmark"></i> ---
                </span>
                <a
                    target="_blank"
                    class="btn-link"
                    ng-href="/#/contract/{[{item.export_contract}]}"
                    ng-if="item.export_contract_number"
                >
                    <span># {[{item.export_contract_number}]}</span>
                </a>

            `,
      },

      {
        showAlways: true,
        columnName: 'logistic_type',
        title: this.gettext('Logistic type'),
        predicate: 'logistic_type',
        filters: [
          {
            type: 'select',
            predicate: 'logistic_type',
            label: this.gettext('Logistic type'),
            values: {
              main: this.gettext('main'),
              intermediate: this.gettext('intermediate'),
            },
          },
        ],
        cellTemplate: /*html*/ `
              <div ng-if="item.$_edit">
                <select ng-model="item.logistic_type" ng-click="item.clicked=true">
                  <option value="main"><translate>Main</translate></option>
                  <option value="intermediate"><translate>Intermediate</translate></option>
                </select>
              </div>
              <div ng-if="!item.$_edit">
                <span ng-if="item.logistic_type === 'main'"><translate>Main</translate></span>
                <span ng-if="item.logistic_type === 'intermediate'">
                  <translate>Intermediate</translate>
                </span>
              </div>
            `,
      },
      {
        columnName: 'consignment_number',
        title: this.gettext('Consignment (#)'),
        predicate: 'consignment_number',
        filters: [
          {
            type: 'text',
            predicate: 'vehicle_number_alt',
            placeholder: 'Vehicle addon number',
            addon: '#',
          },
          {
            type: 'text',
            predicate: 'consignment_number',
            placeholder: 'Consignment number',
            addon: '#',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span
                  ng-if="item.consignment_number"
                  ng-class="{ 'label label_danger': !!item.has_diff_limit }"
                >
                  {[{ item.consignment_number | cut:true:30:'' }]}
                </span>
                <span ng-if="!item.consignment_number"> - </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  class="form-control"
                  type="text"
                  ng-if="item.clicked"
                  ng-model="item.consignment_number"
                />
                <span ng-click="item.clicked=true" ng-if="!item.clicked">
                  {[{ item.consignment_number }]}
                </span>
              </div>
            `,
      },
      {
        columnName: 'custom_status',
        title: this.gettext('Custom status'),
        class: 'td-left-align small-font-cel',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="label" ng-if="item.custom_status_title"
                  ng-class="{ 'label_calculate': item.custom_status_codename == 'washout',
                              'label_success': item.custom_status_codename == 'new',
                              'label-default': item.custom_status_codename == 'cancelled',
                              'label_default': item.custom_status_codename == 'executed',
                              'label_warning': item.custom_status_codename == 'shipment' } "
                  >
                  {[{item.custom_status_title | cut:true:30:'' }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-model="item.custom_status"
                  title="item.custom_status_title"
                  placeholder="'custom status'|translate"
                  resource-name="'core.CustomStatus'"
                  query-params="{content_type__model: 'logistic'}"
                  allow-clear="true"
                  ng-if="item.clicked"
                ></gt-resource-select>
                <span
                  class="label"
                  ng-if="item.custom_status_title && !item.clicked"
                  ng-click="item.clicked=true"
                  ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
                >
                  {[{item.custom_status_title | cut:true:30:'' }]}
                </span>
              </div>
            `,
      },
      {
        columnName: 'voyage',
        title: this.gettext('Voyage'),
        class: 'td-left-align small-font-cel',
        cellTemplate: /*html*/ `
              <span>
                {[{ item.voyage | cut:true:30:'' }]}
                <a class="btn-link" ng-href="/#/vessels/{[{item.voyage_vessel_id}]}" ng-if="item.voyage_vessel_id">
                  <span> {[{ item.voyage_name }]} </span>
                </a>
              </span>
            `,
      },
      {
        columnName: 'deliverer_in_port',
        title: this.gettext('Deliverer in port'),
        class: 'td-left-align small-font-cel',
        cellTemplate: /*html*/ `
              <span>
                {[{ item.deliverer_in_port | cut:true:30:'' }]}
                <a class="btn-link" ng-href="#/clients/clients/{[{item.deliverer_in_port_id}]}" ng-if="item.deliverer_in_port_id">
                  <span> {[{ item.deliverer_in_port_name }]} </span>
                </a>
              </span>
            `,
      },
      {
        columnName: 'acceptance_certificate',
        title: this.gettext(this.$rootScope.user.settings.ACCEPTANCE_CETRIFICATE_NAME),
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span
                  ng-if="item.acceptance_certificate_title" permission
                  permission-only="'view_acceptancecertificate'"
                >
                  {[{item.acceptance_certificate_title }]}
                </span>
                <span ng-if="!item.billofladings_data"> - </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.acceptance_certificate_title"
                  ng-model="item.acceptance_certificate"
                  placeholder="$root.user.settings.ACCEPTANCE_CETRIFICATE_NAME|translate"
                  resource-name="'logistics.acceptancecertificate'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control"" ng-click="item.clicked=true" ng-if="!item.clicked">
                  {[{item.acceptance_certificate_title }]}
                </span>
              </div>
            `,
      },
      {
        columnName: 'billoflading',
        title: this.gettext('B\\L'),
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-repeat=" bl in item.billofladings_data">
                  {[{bl.number }]}
                  <div class="clearfix"></div>
                </span>
                <span ng-if="!item.billofladings_data"> - </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.billoflading_number"
                  ng-model="item.billoflading"
                  resource-name="'logistics.billoflading'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.billoflading_number }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'status',
        title: this.gettext('Status'),
        class: 'td-left-align small-font-cell status-cell td-width-status-cell',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="label label_default" ng-if="!item.volume_departed">
                    <translate>Loading</translate>
                </span>
                <span class="label label_warning"
                      ng-if="item.volume_departed && (!item.volume_received && !item.volume_departed_real)"
                >
                    <translate>At road</translate>
                </span>
                <span class="label label_success" ng-if="(item.volume_received || item.volume_departed_real)"
                >
                    <translate>Delivered</translate>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <span class="label label_default" ng-if="!item.volume_departed">
                <translate>Loading</translate>
                </span>
                <span
                  class="label label_warning"
                  ng-if="item.volume_departed && (!item.volume_received && !item.volume_departed_real)"
                >
                  <translate>At road</translate>
                </span>
                <span
                  class="label label_success"
                  ng-if="item.volume_departed && (item.volume_received || item.volume_departed_real)"
                >
                  <translate>Delivered</translate>
                </span>
              </div>
            `,
      },
      {
        columnName: 'railway',
        title: this.gettext('Railway'),
        predicate: 'railway',
        class: 'highlighted-td',
        filters: [
          {
            type: 'select',
            predicate: 'railway_required',
            label: this.gettext('Railway required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'railway',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_railway_date',
            endDateField: 'end_railway_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.railway" class="label-strong label">
                  <i class="fa fa-calendar"></i> {[{item.railway | date:'dd.MM' }]}
                </span>
                <span ng-if="item.railway_required == false" class="label" translate>
                  <i class="fa fa-times-circle"></i>
                </span>
                <a ng-if="item.railway_cert_file"
                  href="{[{item.railway_cert_file}]}"
                  target="_blank"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ item.railway_cert_file_name }]}"
                >
                  <i class="fa fa-download"></i> {[{item.railway_cert_file_name | cut:true:30:' ...'}]}
                </a>
                <a
                  ng-if="item.railway_cert_file"
                  ng-click="args.deleteDoc(item, 'railway')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.railway"
                  allow-clear="true"
                  on-selected="args.setDateField(item, $event, 'railway')"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'quality',
        title: this.gettext('Quality'),
        class: 'highlighted-td',
        predicate: 'quality',
        filters: [
          {
            type: 'select',
            predicate: 'quality_required',
            label: this.gettext('Quality required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'quality',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_quality_date',
            endDateField: 'end_quality_date',
          },
        ],
        cellTemplate: `
             <div ng-if="!item.$_edit">
                <span ng-if="item.quality" class="label-strong label">
                  <i class="fa fa-calendar"></i> {[{item.quality | date:'dd.MM' }]}
                </span>
                <span ng-if="item.quality_required == false" class="label" translate>
                  <i class="fa fa-times-circle"></i>
                </span>
                <a ng-if="item.quality_cert_file"
                    href="{[{item.quality_cert_file}]}"
                    target="_blank"
                    class="btn-link smaller-label tooltip"
                    data-tip="{[{ item.quality_cert_file_name }]}"
                  >
                <i class="fa fa-download"></i> {[{item.quality_cert_file_name | cut:true:30:' ...'}]}
                </a>
                <a
                  ng-if="item.quality_cert_file"
                  ng-click="args.deleteDoc(item, 'quality')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.quality"
                  allow-clear="true"
                  on-selected="args.setDateField(item, $event, 'quality')"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'vet',
        title: this.gettext('Vet'),
        predicate: 'vet',
        class: 'highlighted-td',
        filters: [
          {
            type: 'select',
            predicate: 'vet_required',
            label: this.gettext('Vet required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'vet',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_vet_date',
            endDateField: 'end_vet_date',
          },
        ],
        cellTemplate: `
            <div ng-if="!item.$_edit">
              <span ng-if="item.vet" class="label-strong label">
                <i class="fa fa-calendar"></i> {[{item.vet | date:'dd.MM' }]}
              </span>
              <span ng-if="item.vet_required == false" class="label" translate>
                <i class="fa fa-times-circle"></i>
              </span>
              <a ng-if="item.vet_cert_file"
                  href="{[{item.vet_cert_file}]}"
                  target="_blank"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ item.vet_cert_file_name }]}"
              >
              <i class="fa fa-download"></i> {[{item.vet_cert_file_name | cut:true:30:' ...'}]}
              </a>
              <a
                ng-if="item.vet_cert_file"
                ng-click="args.deleteDoc(item, 'vet')"
                permission
                permission-only="'delete_document'"
                class="btn btn-xs btn-danger hover-element"
              >
                <i class="fa fa-trash"></i>
              </a>
            </div>
            <div ng-if="item.$_edit">
              <gt-date-select
              ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                ng-click="item.clicked=true"
                date-model="item.vet"
                allow-clear="true"
                on-selected="args.setDateField(item, $event, 'vet')"
              ></gt-date-select>
            </div>
            `,
      },
      {
        columnName: 'quarantine',
        title: this.gettext('Quarantine'),
        predicate: 'quarantine',
        class: 'highlighted-td',
        filters: [
          {
            type: 'select',
            predicate: 'quarantine_required',
            label: this.gettext('Quarantine required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'quarantine',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_quarantine_date',
            endDateField: 'end_quarantine_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.quarantine" class="label-strong label">
                  <i class="fa fa-calendar"></i> {[{item.quarantine | date:'dd.MM' }]}
                </span>
                <span ng-if="item.quarantine_required == false" class="label" translate>
                  <i class="fa fa-times-circle"></i>
                </span>
                <a ng-if="item.quarantine_cert_file" href="{[{item.quarantine_cert_file}]}"
                  target="_blank"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ item.quarantine_cert_file_name }]}"
                >
                <i class="fa fa-download"></i> {[{item.quarantine_cert_file_name | cut:true:30:' ...'}]}
                </a>
                <a
                  ng-if="item.quarantine_cert_file"
                  ng-click="args.deleteDoc(item, 'quarantine')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.quarantine"
                  allow-clear="true"
                  on-selected="args.setDateField(item, $event, 'quarantine')"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'declaration',
        title: this.gettext('Declaration'),
        predicate: 'declaration',
        class: 'highlighted-td',
        filters: [
          {
            type: 'select',
            predicate: 'declaration_required',
            label: this.gettext('Declaration required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'declaration',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_declaration_date',
            endDateField: 'end_declaration_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.declaration" class="label-strong label">
                  <i class="fa fa-calendar"></i> {[{item.declaration | date:'dd.MM' }]}
                </span>
                <span ng-if="item.declaration_required == false" class="label" translate>
                  <i class="fa fa-times-circle"></i>
                </span>
                <a ng-if="item.declaration_cert_file" href="{[{item.declaration_cert_file}]}"
                  target="_blank"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ item.declaration_cert_file_name }]}"
                >
                  <i class="fa fa-download"></i> {[{item.declaration_cert_file_name | cut:true:30:'
                  ...'}]}
                </a>
                <a
                  ng-if="item.declaration_cert_file"
                  ng-click="args.deleteDoc(item, 'declaration')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.declaration"
                  allow-clear="true"
                  on-selected="args.setDateField(item, $event, 'declaration')"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'eur_one',
        title: this.gettext('EUR 1'),
        predicate: 'eur_one',
        class: 'highlighted-td',
        filters: [
          {
            type: 'select',
            predicate: 'eur_one_required',
            label: this.gettext('EUR 1 required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'eur_one',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_eur_one_date',
            endDateField: 'end_eur_one_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.eur_one" class="label-strong label">
                    <i class="fa fa-calendar"></i> {[{item.eur_one | date:'dd.MM' }]}
                </span>
                <span ng-if="item.eur_one_required == false" class="label" translate>
                    <i class="fa fa-times-circle"></i>
                </span>
                <a ng-if="item.eur_one_cert_file" href="{[{item.eur_one_cert_file}]}"
                    target="_blank"
                    class="btn-link smaller-label tooltip"
                    data-tip="{[{ item.eur_one_cert_file_name }]}"
                >
                    <i class="fa fa-download"></i> {[{item.eur_one_cert_file_name | cut:true:30:' ...'}]}
                </a>
                <a
                  ng-if="item.eur_one_cert_file"
                  ng-click="args.deleteDoc(item, 'eur_one')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.eur_one"
                  allow-clear="true"
                  on-selected="args.setDateField(item, $event, 'eur_one')"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'broker_confirmation',
        title: this.gettext('Broker confirmation'),
        predicate: 'broker_confirmation',
        class: 'highlighted-td',
        filters: [
          {
            type: 'select',
            predicate: 'broker_confirmation_required',
            label: this.gettext('EUR 1 required'),
            values: {
              True: this.gettext('Yes'),
              False: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'broker_confirmation',
            label: this.gettext('Has date'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'daterange',
            startDateField: 'start_broker_confirmation_date',
            endDateField: 'end_broker_confirmation_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.broker_confirmation" class="label-strong label">
                    <i class="fa fa-calendar"></i> {[{item.broker_confirmation | date:'dd.MM' }]}
                </span>
                <span ng-if="item.broker_confirmation_required == false" class="label" translate>
                    <i class="fa fa-times-circle"></i>
                </span>
                <a ng-if="item.broker_cert_file" href="{[{'protected/'+item.broker_cert_file}]}"
                    target="_blank"
                    class="btn-link smaller-label tooltip"
                    data-tip="{[{ item.broker_cert_file_name }]}"
                >
                    <i class="fa fa-download"></i> {[{item.broker_cert_file_name | cut:true:30:' ...'}]}
                </a>
                <a
                  ng-if="item.broker_cert_file"
                  ng-click="args.deleteDoc(item, 'broker')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.broker_confirmation"
                  allow-clear="true"
                  on-selected="args.setDateField(item, $event, 'broker_confirmation')"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'additional_document_one',
        title: this.gettext('Additional doc one'),
        predicate: 'additional_document_one',
        class: 'highlighted-td',
        cellTemplate: `
                <span ng-if="item.additional_document_one" class="label-strong label">
                    <i class="fa fa-calendar"></i> {[{item.additional_document_one | date:'dd.MM' }]}
                </span>
                <a ng-if="item.additional_document_one_file"
                    href="{[{'protected/'+item.additional_document_one_file}]}"
                    target="_blank"
                    class="btn-link smaller-label tooltip"
                    data-tip="{[{ item.additional_document_one_file_name }]}"
                >
                    <i class="fa fa-download"></i> {[{item.additional_document_one_file_name | cut:true:30:'
                        ...'}]}
                </a>
                <a
                  ng-if="item.additional_document_one_file"
                  ng-click="args.deleteDoc(item, 'additional_document_one')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
            `,
      },
      {
        columnName: 'additional_document_two',
        title: this.gettext('Additional doc two'),
        predicate: 'additional_document_two',
        class: 'highlighted-td',
        cellTemplate: `
              <span ng-if="item.additional_document_two" class="label-strong label">
                <i class="fa fa-calendar"></i> {[{item.additional_document_two | date:'dd.MM' }]}
              </span>
              <a ng-if="item.additional_document_two_file"
                  href="{[{'protected/'+item.additional_document_two_file}]}"
                  target="_blank"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ item.additional_document_two_file_name }]}"
              >
                <i class="fa fa-download"></i> {[{item.additional_document_two_file_name | cut:true:30:'
                  ...'}]}
              </a>
              <a
                ng-if="item.additional_document_two_file"
                ng-click="args.deleteDoc(item, 'additional_document_two')"
                permission
                permission-only="'delete_document'"
                class="btn btn-xs btn-danger hover-element"
              >
                <i class="fa fa-trash"></i>
              </a>
            `,
      },
      {
        columnName: 'additional_document_three',
        title: this.gettext('Additional doc three'),
        predicate: 'additional_document_three',
        class: 'highlighted-td',
        cellTemplate: `
              <span ng-if="item.additional_document_three" class="label-strong label">
                <i class="fa fa-calendar"></i> {[{item.additional_document_three | date:'dd.MM' }]}
              </span>
                <a ng-if="item.additional_document_one_file"
                    href="{[{'protected/'+item.additional_document_three_file}]}"
                    target="_blank"
                    class="btn-link smaller-label tooltip"
                    data-tip="{[{ item.additional_document_three_file_name }]}"
                >
                <i class="fa fa-download"></i> {[{item.additional_document_three_file_name | cut:true:30:'
                    ...'}]}
                </a>
                <a
                  ng-if="item.additional_document_three_file"
                  ng-click="args.deleteDoc(item, 'additional_document_three')"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger hover-element"
                >
                  <i class="fa fa-trash"></i>
                </a>
            `,
      },
      {
        columnName: 'sale_price',
        title: this.gettext('Sale price'),
        predicate: 'sale_price',
        class: 'highlighted-td td-right-align',
        filters: [
          {
            type: 'text',
            predicate: 'min_sale_price',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_sale_price',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.showSaleVat">
                  {[{item.sale_vat || 0 | number:2 }]}
                      <span class="smaller-label label-strong">
                          {[{ item.sale_vat * item.buyer_real_volume | number:2 }]}
                      </span>
                  </span>
                  <a class="label label_default" ng-if="!item.showSaleVat && item.sale_vat > 0"
                      ng-click="item._showSaleVat = true"
                  >
                      <i class="fa fa-plus"></i> <translate>VAT</translate>
                  </a>
                  <a class="label label_default" ng-if="item.showSaleVat && item.sale_vat > 0"
                      ng-click="item._showSaleVat = false"
                  >
                      <i class="fa fa-minus"></i> <translate>hide</translate> <translate>VAT</translate>
                  </a>
                  <span ng-if="(item.true_sale_price > 0)">
                      <span ng-if="item.sale_price > 0" class="tooltip"
                          data-tip="{[{'price for current vehicle differs to contract'|translate}]}"
                      >
                          <i class="fa fa-info-circle"></i>
                      </span>
                          {[{ item.true_sale_price || 0 | number:2 }]}
                      <span class="smaller-label" ng-if="item.sale_price_multiplicator != 100">
                          {[{ item.sale_price_multiplicator }]}
                      </span>
                      <span class="smaller-label label-strong">
                          {[{ item.true_sale_value | number:2 }]}
                      </span>
                      <span class="smaller-label" ng-if="!item.sale_ctr_currency">
                          {[{ item.sale_currency }]}
                      </span>
                      <span class="smaller-label" ng-if="item.sale_ctr_currency">
                          {[{ item.sale_ctr_currency }]}
                      </span>
                  </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  gt-clear-input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.sale_price"
                />
                  <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                    >{[{item.sale_price | number:2 }]}</span
                  >
              </div>
              `,
      },
      {
        columnName: 'price',
        title: this.gettext('Purchase price'),
        predicate: 'price',
        class: 'highlighted-td td-right-align',
        filters: [
          {
            type: 'text',
            predicate: 'min_price',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_price',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.showPurchaseVat">
                  {[{item.purchase_vat || 0 | number:2 }]}
                  <span class="smaller-label label-strong">
                      {[{ item.purchase_vat * vitem.supplier_real_volume | number:2 }]}
                  </span>
                </span>
                <a class="label label_default" ng-if="!item.showPurchaseVat && item.purchase_vat > 0"
                  ng-click="item._showPurchaseVat = true"
                >
                  <i class="fa fa-plus"></i> <translate>VAT</translate>
                </a>
                <a class="label label_default" ng-if="item.showPurchaseVat && item.purchase_vat > 0"
                  ng-click="item._showPurchaseVat = false"
                >
                  <i class="fa fa-minus"></i> <translate>hide</translate> <translate>VAT</translate>
                </a>
                <span ng-if="(item.one_price > 0)">
                  <span ng-if="item.price > 0" class="tooltip"
                      data-tip="{[{'price for current vehicle differs to contract'|translate}]}"
                  >
                      <i class="fa fa-info-circle"></i>
                  </span>
                  <span ng-if="item.price == 0 && item.transshipment_price_cargo"
                      class="tooltip"
                      data-tip="{[{'price from transshpment from cars'|translate }]} =  {[{item.transshipment_price_cargo}]}"
                  >
                      <i class="fa fa-industry"></i>
                  </span>
                  {[{item.one_price || 0 | number:2 }]}
                  <span class="smaller-label label-strong">
                  {[{ item.true_purchase_value | number:2 }]}
                  </span>
                  <span class="smaller-label" ng-if="!item.purchase_currency">
                  {[{itemc.currency }]}
                  </span>
                  <span class="smaller-label" ng-if="item.purchase_currency">
                  {[{item.purchase_currency }]}
                  </span>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  gt-clear-input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.price"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.price | number:2 }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'cost_invoiceposition',
        title: this.gettext('Service invoice'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
            <div ng-if="!item.$_edit">
              <span ng-if="item.cost_invoice">
                <a ui-sref="gt.page.payment({id: item.cost_invoice})" class="btn btn-xs btn-link">
                  <i class="fa fa-credit-card-alt"></i>{[{item.cost_invoice_number }]}
                </a>
              </span>
            </div>
            <div ng-if="item.$_edit">
              <span
                class="btn btn-xs btn-link"
                ng-if="item.cost_invoice"
                ui-sref="gt.page.payment({id: item.cost_invoice})"
              >
                {[{item.cost_invoice_number }]}
              </span>
            </div>
            `,
      },
      {
        columnName: 'delivery',
        title: this.gettext('Delivery'),
        predicate: 'costs',
        class: 'highlighted-td td-right-align',
        filters: [
          {
            type: 'text',
            predicate: 'min_costs',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_costs',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.showDeliveryVat">
                  {[{item.costs_vat || 0 | number:2 }]}
                  <span class="smaller-label label-strong">
                    {[{ item.costs_vat_total | number:2 }]}
                  </span>
                </span>
                <a class="label label_default" ng-if="!item.showDeliveryVat && item.costs_vat > 0"
                  ng-click="item._showDeliveryVat = true"
                >
                <i class="fa fa-plus"></i> <translate>VAT</translate>
                </a>
                <a class="label label_default" ng-if="item.showDeliveryVat && item.costs_vat > 0"
                  ng-click="item._showDeliveryVat = false"
                >
                  <i class="fa fa-minus"></i> <translate>hide</translate> <translate>VAT</translate>
                </a>
                <span class="label label_default smaller-label tooltip"
                      data-tip="{[{'intermediate logistics delivery costs'|translate}]}"
                      ng-if="item.intermediate_costs_in_USD"
                >
                  <i class="fa fa-truck"></i> {[{item.intermediate_costs_in_USD || 0 | number:2 }]}
                </span>
                <span ng-if="item.costs_no_vat > 0"
                      ng-class="{'label label_warning': item.cost_invoiceposition && !item.cost_invoice_fact_payments, 'label label_success': item.cost_invoiceposition && item.cost_invoice_fact_payments}"
                >
                  {[{item.costs_no_vat || 0 | number:2 }]}
                    <span class="smaller-label label-strong">
                      {[{item.costs_no_vat_value || 0 | number:2 }]}
                    </span>
                    <span class="smaller-label" ng-if="item.purchase_currency">
                      {[{item.purchase_currency }]}
                    </span>
                      <span class="smaller-label" ng-if="item.purchase_currency">
                          {[{item.currency }]}
                      </span>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  gt-clear-input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.costs"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.costs }]}</span
                >
              </div>
            `,
        totalTemplate: `
              {[{item.costs_sum || 0 | gtDecimal: 2 }]} / {[{item.costs_sum_value || 0 | gtDecimal: 2 }]}
            `,
      },
      {
        columnName: 'penalties',
        title: this.gettext('Penalties'),
        predicate: 'penalties',
        class: 'highlighted-td td-right-align td-width-penalties-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_penalties',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_penalties',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.showPenaltiesVat">
                  {[{item.penalties_vat || 0 | number:2 }]}
                  <span class="smaller-label label-strong">
                    {[{ item.penalties_vat_total | number:2 }]}
                  </span>
                </span>
                <a class="label label_default" ng-if="!item.showPenaltiesVat && item.penalties_vat > 0"
                  ng-click="item._showPenaltiesVat = true"
                >
                <i class="fa fa-plus"></i> <translate>VAT</translate>
                </a>
                <a class="label label_default" ng-if="item.showPenaltiesVat && item.penalties_vat > 0"
                  ng-click="item._showPenaltiesVat = false"
                >
                  <i class="fa fa-minus"></i> <translate>hide</translate> <translate>VAT</translate>
                </a>
                <span ng-if="item.penalties">
                  {[{ item.penalties_no_vat / (item.supplier_real_volume || 1) | number:2 }]}
                  <span class="smaller-label label-strong">
                    {[{ item.penalties_no_vat || 0 | number:2 }]}
                  </span>
                  <span class="smaller-label" ng-if="item.purchase_currency">
                    {[{item.purchase_currency }]}
                  </span>
                  <span class="smaller-label" ng-if="!item.purchase_currency">
                    {[{item.currency }]}
                  </span>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  gt-clear-input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.penalties"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.penalties }]}</span
                >
              </div>
            `,
        totalTemplate: `
            {[{item.penalties_sum || 0 | gtDecimal: 2 }]}
            `,
      },
      {
        columnName: 'payment',
        title: this.gettext('Payment'),
        class: 'td-width-payment-cell',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="label label_warning"
                      ng-if="item.supplier_nineteen_invoice_id && item.supplier_nineteen_fact_amount == 0"
                >
                <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i>
                </span>
                <span
                      class="label label-opacity"
                      ng-if="!item.supplier_nineteen_invoice_id && item.supplier_nineteen_fact_amount == 0"
                >
                <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i>
                </span>
                <span
                      class="label label_success"
                      ng-if="item.supplier_nineteen_invoice_id && item.supplier_nineteen_fact_amount != 0"
                >
                <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i>
                </span>
                <span
                      class="label label_warning"
                      ng-if="!item.no_balance_invoice && item.supplier_ten_invoice_id && item.supplier_ten_fact_amount == 0"
                >
                <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                      class="label label-opacity"
                      ng-if="!item.no_balance_invoice && !item.supplier_ten_invoice_id && item.supplier_ten_fact_amount == 0"
                >
                <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                      class="label label_success"
                      ng-if="!item.no_balance_invoice && item.supplier_ten_invoice_id && item.supplier_ten_fact_amount != 0"
                >
                    <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                    class="label label_danger"
                    ng-if="item.no_balance_invoice"
                >
                    <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                    class="label label_warning"
                    ng-if="item.buyer_nineteen_invoice_id && item.buyer_nineteen_fact_amount == 0"
                >
                    <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i>
                </span>
                <span
                    class="label label-opacity"
                    ng-if="!item.buyer_nineteen_invoice_id && item.buyer_nineteen_fact_amount == 0"
                >
                    <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i>
                </span>
                <span
                    class="label label_success"
                    ng-if="item.buyer_nineteen_invoice_id && item.buyer_nineteen_fact_amount != 0"
                >
                    <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i>
                </span>
                <span
                    class="label label_warning"
                    ng-if="!item.no_balance_invoice && item.buyer_ten_invoice_id && item.buyer_ten_fact_amount == 0"
                >
                    <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                  class="label label-opacity"
                  ng-if="!item.no_balance_invoice && !item.buyer_ten_invoice_id && item.buyer_ten_fact_amount == 0"
                >
                <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                  class="label label_success"
                  ng-if="!item.no_balance_invoice && item.buyer_ten_invoice_id && item.buyer_ten_fact_amount != 0"
                >
                  <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
                <span
                  class="label label_danger"
                  ng-if="item.no_balance_invoice"
                >
                  <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card-alt"></i> <translate>B</translate>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <span
                class="label label_warning tooltip"
                data-tip="{'supplier invoiced'|translate}"
                ng-if="item.supplier_total_invoiced && !item.supplier_total_paid"
                >
                  <translate>SI</translate>
                </span>
                <span
                  class="label label_success tooltip"
                  data-tip="{'supplier paid'|translate}"
                  ng-if="item.supplier_total_paid"
                >
                  <translate>SP</translate>
                </span>
                <span
                  class="label label_warning tooltip"
                  data-tip="{'buyer invoiced'|translate}"
                  ng-if="item.buyer_total_invoiced && !item.buyer_total_paid"
                >
                  <translate>BI</translate>
                </span>
                <span
                  class="label label_success tooltip"
                  data-tip="{'buyer paid'|translate}"
                  ng-if="item.buyer_total_paid"
                >
                  <translate>BP</translate>
                </span>
              </div>
            `,
      },
      {
        columnName: 'crop',
        title: this.gettext('commodity'),
        predicate: 'cargo',
        class: 'td-left-align',
        filters: [
          {
            type: 'ui-select',
            predicate: 'cargo',
            label: this.gettext('Commodity'),
            resource: 'crops.Crop',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="biggerlable">
                  {[{item.crop_title}]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                {[{item.crop_title}]} {[{item.cargo_class }]}
              </div>
            `,
      },
      {
        columnName: 'disbursementbl',
        title: this.gettext('Disbursement bl'),
        class: 'td-right-align',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.disbursementbl_number">
                  {[{item.disbursementbl_number || '---' }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.disbursementbl_number"
                  ng-model="item.disbursementbl"
                  placeholder="'disbursementbl'|translate"
                  resource-name="'finances.disbursementbl'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.disbursementbl_number }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'receiver',
        title: this.gettext('Receiver'),
        class: 'td-right-align',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.receiver">
                  <a ui-sref="gt.page.client({id: item.receiver})">
                    {[{item.receiver_name || '---' }]}
                  </a>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.receiver_name"
                  ng-model="item.receiver"
                  placeholder="'receiver'|translate"
                  resource-name="'clients.client'"
                  query-params="{ is_receiver: '1' }"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked">
                  {[{item.receiver_name }]}
                </span>
              </div>
            `,
      },
      {
        columnName: 'producer',
        title: this.gettext('Producer'),
        class: 'td-right-align',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.producer">
                  <a ui-sref="gt.page.client({id: item.producer})">
                    {[{item.producer_name || '---' }]}
                  </a>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.producer_name"
                  ng-model="item.producer"
                  placeholder="'producer'|translate"
                  resource-name="'clients.client'"
                  query-params="{ is_producer: '1' }"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.producer_name }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'volume_departed',
        title: this.gettext('Volume (departed)'),
        predicate: 'volume_departed',
        class: 'highlighted-td td-right-align td-width-volume-departed-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_volume_departed',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_volume_departed',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.volume_departed">
                  {[{item.volume_departed || 0 | number:3 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.volume_departed"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.volume_departed }]}</span
                >
              </div>
            `,
        totalTemplate: /*html*/ `
              {[{ item.volume_departed_sum || 0 |number:3}]}
            `,
      },
      {
        columnName: 'volume_departed_consignment',
        title: this.gettext('Volume (departed consignment)'),
        predicate: 'volume_departed_consignment',
        class: 'highlighted-td td-right-align volume',
        filters: [
          {
            type: 'text',
            predicate: 'min_volume_departed_consignment',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_volume_departed_consignment',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.volume_departed_consignment">
                  {[{item.volume_departed_consignment || 0 | number:3 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.volume_departed_consignment"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.volume_departed_consignment }]}</span
                >
              </div>
            `,
        totalTemplate: /*html*/ `
              {[{item.volume_departed_consignment_sum || 0 | number:3}]}
            `,
      },
      {
        columnName: 'volume_received',
        title: this.gettext('Volume (received)'),
        predicate: 'volume_received',
        class: 'highlighted-td td-right-align td-width-volume-recived-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_volume_received',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_volume_received',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.volume_received">
                  {[{item.volume_received || 0 | number:3 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.volume_received"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.volume_received }]}</span
                >
              </div>
            `,
        totalTemplate: /*html*/ `
            {[{item.volume_received_sum || 0 | number:3}]} `,
      },
      {
        columnName: 'volume_departed_real',
        title: this.gettext('Volume (real)'),
        predicate: 'volume_departed_real',
        class: 'highlighted-td td-right-align td-width-volume-departed-real-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_volume_departed_real',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_volume_departed_real',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.volume_departed_real">
                  {[{item.volume_departed_real || 0 | number:3 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.volume_departed_real"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.volume_departed_real }]}</span
                >
              </div>
            `,
        totalTemplate: /*html*/ `
            {[{item.volume_departed_real_sum || 0 | number:3}]}
            `,
      },
      {
        columnName: 'export_contract_price',
        title: this.gettext('Export contract price'),
        class: 'td-left-align small-font-cel',
        cellTemplate: /*html*/ `
              <span>
                {[{item.export_contract_price || 0 | number:3 }]}
              </span>
            `,
      },
      {
        columnName: 'volume_boarded',
        title: this.gettext('Volume (boarded)'),
        predicate: 'volume_boarded',
        class: 'highlighted-td td-right-align td-width-volume-borded-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_volume_boarded',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_volume_boarded',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.volume_boarded">
                  {[{item.volume_boarded || 0 | number:3 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  ng-if="item.clicked"
                  class="form-control"
                  type="number"
                  ng-model="item.volume_boarded"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.volume_boarded }]}</span
                >
              </div>
            `,
        totalTemplate: /*html*/ `
            {[{item.volume_boarded_sum || 0 | number:3}]}
            `,
      },
      {
        columnName: 'volume_lost',
        title: this.gettext('Volume (lost)'),
        predicate: 'volume_lost',
        class: 'td-width-volume-lost-cell',
        filters: [
          {
            type: 'text',
            predicate: 'volume_lost_percent_min',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'volume_lost_percent_max',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
            <div ng-if="!item.$_edit">
                <span
                  ng-if="item.volume_lost && item.volume_received"
                  ng-class="{'negative-number': item.volume_lost / (item.volume_departed_real || item.volume_departed || 1 )  * 100 <= - item.normal_loss_rate, 'positive-number': item.volume_lost / (item.volume_departed_real || item.volume_departed || 1 )  * 100 >= item.normal_loss_rate} "
                >
                  {[{item.volume_lost || 0 | gtDecimal:3 }]}
                  <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span> ({[{
                  item.volume_lost_percent | gtDecimal:2 }]}%)
                </span>
                <span
                  ng-if="item.volume_lost && (item.volume_lost / (item.volume_departed || 1 )  * 100 <= - item.normal_loss_rate)"
                >
                  {[{ item.volume_lost_normal | gtDecimal:3 }]}
                  <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                </span>
                <span ng-if="item.volume_lost == 0"> 0 </span>
              </div>
              <div ng-if="item.$_edit">
                <span
                ng-if="item.volume_lost && (item.volume_lost / (item.volume_departed || 1 )  * 100 <= - item.normal_loss_rate)"
                >
                  {[{( item.volume_lost * -1) - item.volume_departed * item.normal_loss_rate
                  / 100 || 0 | gtDecimal:3 }]}
                  <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                </span>
              </div>
            `,
        totalTemplate: `
              <span ng-if="item.volume_lost_sum !=0 && item.volume_received_sum != 0">
                {[{ item.volume_lost_sum || 0 | gtDecimal:3 }]}
                  <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span
                  >({[{ $item.volume_lost_percent_total__sum /
                    item.volume_departed_or_real_lost__sum || 0 | gtDecimal:2 }]}%)
              </span>
              <span ng-if="item.volume_lost_sum != 0">
                {[{ item.volume_lost_normal__sum || 0 | gtDecimal:3 }]}
                <span class="smaller-label">
                {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
              <span ng-if="item.volume_lost_sum == 0"> 0 </span>
            `,
      },
      {
        columnName: 'loading_shortage',
        title: this.gettext('Loading shortage (boarding losses)'),
        predicate: 'loading_shortage',
        class: 'td-width-volume-lost-cell',
        cellTemplate: `
                <span
                  ng-if="item.loading_shortage"
                  ng-class="{
                    'negative-number': item.loading_shortage < 0,
                    'positive-number': item.loading_shortage > 0
                  }"
                >
                  {[{ item.loading_shortage || 0 | gtDecimal:3 }]}
                  <span class="smaller-label"> {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                </span>
                <span ng-if="!item.loading_shortage">
                  <span ng-if="item.loading_shortage == 0"> 0 </span>
                </span>
            `,
        totalTemplate: `
              <span
                ng-if="item.loading_shortage_sum !=0"
                ng-class="{
                  'negative-number': item.loading_shortage_sum < 0,
                  'positive-number': item.loading_shortage_sum > 0
                }"
              >
                {[{ item.loading_shortage_sum || 0 | gtDecimal:3 }]}
                <span class="smaller-label">{[{$root.user.settings.MAIN_MEASUREMENT_UNIT}]}</span>
              </span>
              <span ng-if="item.loading_shortage_sum == 0"> 0 </span>
            `,
      },
      {
        columnName: 'writeof_volume_balance',
        title: this.gettext('Write-of volume balance'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
                <span ng-if="item.writeof_volume_balance">
                  {[{item.writeof_volume_balance || 0 | number:3 }]}
                </span>
            `,
      },
      {
        columnName: 'volume_discounted',
        title: this.gettext('Volume discounted'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
              <span ng-if="item.volume_discounted">
                {[{ item.volume_discounted || 0 | number:3 }]}
              </span>
            `,
      },
      {
        columnName: 'discount_volume',
        title: this.gettext('Volume discount'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
                <span ng-if="item.discount_volume">
                  {[{ item.discount_volume || 0 | number:2 }]} %
                </span>
            `,
      },
      {
        columnName: 'loading_date',
        title: this.gettext('Loading (date)'),
        predicate: 'loading_date',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_loading_date',
            endDateField: 'end_loading_date',
          },
          {
            type: 'select',
            predicate: 'loading_date_changed',
            label: this.gettext('Loading date was changed'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
            <div ng-if="!item.$_edit">
              <span ng-if="item.loading_date">
                <i class="fa fa-calendar-o"></i> {[{item.loading_date | date:'dd.MM.yy' }]}
              </span>
              <span class="label-opacity" ng-if="!item.loading_date">
                <i class="fa fa-calendar-o"></i> ---
              </span>
            </div>
            <div ng-if="item.$_edit">
              <gt-date-select
              ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                ng-click="item.clicked=true"
                ng-if="item"
                date-model="item.loading_date"
                allow-clear="true"
              ></gt-date-select>
            </div>
            `,
      },
      {
        columnName: 'receiving_date',
        title: this.gettext('Receiving (date)'),
        predicate: 'receiving_date',
        filters: [
          {
            type: 'daterange',
            startDateField: 'receiving_date_range_after',
            endDateField: 'receiving_date_range_before',
          },
          {
            type: 'select',
            predicate: 'receiving_date_changed',
            label: this.gettext('Receiving date was changed'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.receiving_date">
                  <i class="fa fa-calendar-o"></i> {[{item.receiving_date | date:'dd.MM.yy' }]}
                </span>
                <span class="label-opacity" ng-if="!item.receiving_date">
                  <i class="fa fa-calendar-o"></i> ---
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  ng-if="item"
                  date-model="item.receiving_date"
                  allow-clear="true"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'sale_date',
        title: this.gettext('Sale (date)'),
        predicate: 'sale_date',
        class: 'td-width-sale-date-cell',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_sale_date',
            endDateField: 'end_sale_date',
          },
        ],
        cellTemplate: `
              <span ng-if="item.sale_date">
                <i class="fa fa-calendar-o"></i> {[{item.sale_date | date:'dd.MM.yy' }]}
              </span>
              <span class="label-opacity" ng-if="!item.sale_date">
                <i class="fa fa-calendar-o"></i> ---
              </span>
            `,
      },
      {
        columnName: 'boarding_date',
        title: this.gettext('Boarded (date)'),
        predicate: 'boarding_date',
        class: 'td-width-sale-date-cell',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_boarding_date',
            endDateField: 'end_boarding_date',
          },
        ],
        cellTemplate: `
              <span ng-if="item.boarding_date">
                <i class="fa fa-calendar-o"></i> {[{item.boarding_date | date:'dd.MM.yy' }]}
              </span>
              <span class="label-opacity" ng-if="!item.boarding_date">
                <i class="fa fa-calendar-o"></i> ---
              </span>
            `,
      },
      {
        columnName: 'days_at_port',
        title: this.gettext('Days at port'),
        cellTemplate: `
              <span ng-if="item.days_at_port || item.days_at_port == 0">
                <i class="fa fa-calendar-o"></i> {[{item.days_at_port }]}
              </span>
              <span class="label-opacity" ng-if="!item.days_at_port">
                <i class="fa fa-calendar-o"></i> ---
              </span>
            `,
      },
      {
        columnName: 'supplier_contract_supplier_name',
        title: this.gettext('Supplier'),
        predicate: 'supplier_contract_supplier_name',
        filters: [
          {
            type: 'ui-select',
            predicate: 'supplier_contract__supplier',
            label: this.gettext('supplier'),
            resource: 'clients.Supplier',
          },
          {
            type: 'ui-select',
            predicate: 'supplier_contract__basis',
            label: this.gettext('basis of supplier contract'),
            resource: 'logistics.basis',
          },
        ],
        cellTemplate: `
            <div ng-if="!item.$_edit">
              <span ng-if="item.supplier_name">
                <i class="fa fa-bookmark-o"></i> {[{item.supplier_name }]}
              </span>
              <span class="label-opacity" ng-if="!item.supplier_name">
                <i class="fa fa-bookmark-o"></i> ---
              </span>
              <a
                class="btn-link"
                ng-href="/#/contract/{[{item.supplier_contract}]}"
                ng-if="item.supplier_contract_contract_number"
              >
                <span> # {[{item.supplier_contract_contract_number }]} </span>
                <span ng-if="item.supplier_contract_basis_name"
                  >({[{ item.supplier_contract_basis_name }]})</span
                >
              </a>
            </div>
            <div ng-if="item.$_edit">
              <gt-resource-select
              ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                ng-if="item.clicked"
                title="item.supplier_contract_contract_number"
                ng-model="item.supplier_contract"
                query-params="{ with_contract: item.supplier_contract, invoice_type: 'incoming' }"
                placeholder="'Supplier contract'|translate"
                resource-name="'contracts.PurchaseContract'"
                allow-clear="true"
              ></gt-resource-select>
              <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                >{[{item.supplier_contract_contract_number }]}</span
              >
            </div>
            `,
      },
      {
        columnName: 'exporter',
        title: this.gettext('Exporter'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'exporter',
            label: this.gettext('exporter'),
            resource: 'clients.Exporter',
          },
        ],
        cellTemplate: `
            <div ng-if="!item.$_edit">
              <span ng-if="item.exporter_name ">
                <i class="fa fa-shield"></i> {[{item.exporter_name }]}
              </span>
              <span class="smaller-label" ng-if="item.exporter_contract_number ">
                # {[{item.exporter_contract_number }]}
              </span>
            </div>
            <div ng-if="item.$_edit">
              <gt-resource-select
              ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                ng-if="item.clicked"
                title="item.exporter_name"
                ng-model="item.exporter"
                query-params="{ with_contract: item.supplier_contract, invoice_type: 'incoming' }"
                placeholder="'Exporter'|translate"
                resource-name="'clients.Exporter'"
                allow-clear="true"
              ></gt-resource-select>
              <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                >{[{item.exporter_name }]}</span
              >
            </div>
            `,
      },
      {
        title: this.gettext('Intermediate Contract #'),
        columnName: 'intermediate_contract__contract_number',
        predicate: 'intermediate_contract__contract_number',
        filters: [
          {
            type: 'text',
            predicate: 'intermediate_contract__contract_number',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  class="btn-link"
                  ng-href="/#/contract/{[{item.intermediate_contract}]} "
                  ng-if="item.intermediate_contract_contract_number "
                >
                  <span> # {[{item.intermediate_contract_contract_number }]} </span>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                    title="item.intermediate_contract_contract_number"
                    ng-model="item.intermediate_contract"
                    query-params="item.supplierQueryParams"
                    placeholder="'Intermediate contract'|translate"
                    resource-name="'contracts.IntermediateContract'"
                    allow-clear="true"
                  ></gt-resource-select>
                  <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                    >{[{item.intermediate_contract_contract_number }]}</span
                  >
              </div>
            `,
      },
      {
        columnName: 'forwarder',
        title: this.gettext('Forwarder'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'forwarder',
            label: this.gettext('forwarder'),
            resource: 'clients.Deliverer',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.forwarder_name ">
                  <i class="fa fa-truck"></i> {[{item.forwarder_name }]}
                </span>
                <a
                  target="_blank"
                  class="btn-link"
                  ng-href="/#/contract/{[{item.forwarder_contract}]} "
                  ng-if="item.forwarder_contract_number "
                >
                  <span> # {[{item.forwarder_contract_number }]} </span>
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.forwarder_name"
                  ng-model="item.forwarder"
                  query-params="{ with_contract: item.supplier_contract, invoice_type: 'incoming' }"
                  placeholder="'Forwarder'|translate"
                  resource-name="'clients.Deliverer'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.forwarder_name }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'buyer_contract__buyer',
        title: this.gettext('Buyer'),
        predicate: 'buyer_contract_buyer_name',
        class: 'td-width-buyer-cell',
        filters: [
          {
            type: 'ui-select',
            predicate: 'buyer_contract__buyer',
            label: this.gettext('buyer'),
            resource: 'clients.Buyer',
          },
          {
            type: 'ui-select',
            predicate: 'buyer_contract__basis',
            label: this.gettext('basis of buyer contract'),
            resource: 'logistics.basis',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.buyer_name ">
                  <i class="fa fa-bookmark"></i> {[{item.buyer_name }]}
                </span>
                <span class="label-opacity" ng-if="!item.buyer_name ">
                  <i class="fa fa-bookmark"></i> ---
                </span>
                <a
                  class="btn-link"
                  ng-href="/#/contract/{[{item.buyer_contract}]} "
                  ng-if="item.buyer_contract_contract_number "
                >
                  # {[{item.buyer_contract_contract_number }]}
                  <span ng-if="item.buyer_contract_basis_name"
                    >({[{ item.buyer_contract_basis_name }]})</span
                  >
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.buyer_contract_contract_number"
                  ng-model="item.buyer_contract"
                  query-params="{ with_contract: item.buyer_contract, invoice_type: 'outgoing' }"
                  placeholder="'Buyer contract'|translate"
                  resource-name="'contracts.SaleContract'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.buyer_contract_contract_number }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'purchase_passports_data',
        title: this.gettext('Purchase contract passports'),
        cellTemplate: `
              <span ng-repeat="passport in item.purchase_passports_data">
                <a class="btn-link" ng-href="/#/passport/{[{passport.id}]}" ng-if="passport.id">
                    <span> <i class="fa fa-exchange"></i> {[{ passport.title }]} </span>
                </a>
              </span>
            `,
      },
      {
        columnName: 'sale_passports_data',
        title: this.gettext('Sale contract passports'),
        cellTemplate: `
              <span ng-repeat="passport in item.sale_passports_data">
                <a class="btn-link" ng-href="/#/passport/{[{passport.id}]}" ng-if="passport.id">
                  <span> <i class="fa fa-exchange"></i> {[{ passport.title }]} </span>
                </a>
              </span>
            `,
      },
      {
        columnName: 'business_unit',
        title: this.gettext('Business unit'),
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.business_unit_title">
                  {[{ item.business_unit_title | cut:true:30:' ...' }]}
                </span>
                <span ng-if="!item.business_unit_title"> --- </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.business_unit_title"
                  ng-model="item.business_unit"
                  placeholder="'Business unit'|translate"
                  resource-name="'core.BusinessUnit'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.business_unit_title }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'unloading_business_unit',
        title: this.gettext('Unloading business unit'),
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.unloading_business_unit_title">
                  {[{ item.unloading_business_unit_title | cut:true:30:' ...' }]}
                </span>
                <span ng-if="!item.unloading_business_unit_title"> --- </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.unloading_business_unit_title"
                  ng-model="item.unloading_business_unit"
                  placeholder="'Unloading business unit'|translate"
                  resource-name="'core.BusinessUnit'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.unloading_business_unit_title }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'shipment_business_unit',
        title: this.gettext('Shipment business unit'),
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.shipment_business_unit_title">
                  {[{ item.shipment_business_unit_title | cut:true:30:' ...' }]}
                </span>
                <span ng-if="!item.shipment_business_unit_title"> --- </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.shipment_business_unit_title"
                  ng-model="item.shipment_business_unit"
                  placeholder="'Shipment business unit'|translate"
                  resource-name="'core.BusinessUnit'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.shipment_business_unit_title }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'station',
        title: this.gettext('Station depart'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'station',
            label: this.gettext('station'),
            resource: 'logistics.Station',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.station_name ">
                  <i class="fa fa-train"></i> {[{item.station_name }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.station_name"
                  ng-model="item.station"
                  query-params="{ with_contract: item.supplier_contract, invoice_type: 'incoming' }"
                  placeholder="'Station'|translate"
                  resource-name="'logistics.Station'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.station_name }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'station_receiving',
        title: this.gettext('Station receive'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'station_receiving',
            label: this.gettext('station'),
            resource: 'logistics.Station',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.station_receiving_name ">
                  <i class="fa fa-train"></i> {[{item.station_receiving_name }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.station_receiving_name"
                  ng-model="item.station_receiving"
                  query-params="{ with_contract: item.supplier_contract, invoice_type: 'incoming' }"
                  placeholder="'Station receiving'|translate"
                  resource-name="'logistics.Station'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.station_receiving_name }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'shipment_place',
        title: this.gettext('Shipment place'),
        cellTemplate: `
              <div ng-if="!item.$_edit || !item.clicked" ng-click="item.clicked = item.$_edit">
                <span ng-if="item.shipment_place">
                  <i
                    class="fa fa-building-o"
                    ng-class="{
                      'fa-building-o': item.shipment_place_type === 'farm',
                      'fa-industry': item.shipment_place_type === 'elevator',
                      'fa-anchor': item.shipment_place_type === 'terminal',
                      'fa-warehouse': item.shipment_place_type === 'warehouse',
                    }"
                  ></i> {[{item.item.shipment_place }]}
                </span>
                <span ng-if="!item.shipment_place"> - </span>
              </div>
              <div ng-if="item.$_edit && item.clicked">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  title="item.shipment_place"
                  ng-model="item.shipment_warehouse"
                  placeholder="'Shipment warehouse'|translate"
                  resource-name="'logistics.warehouse'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>
            `,
      },
      {
        columnName: 'unloading_place',
        title: this.gettext('Unloading place'),
        cellTemplate: `
              <div ng-if="!item.$_edit || !item.clicked" ng-click="item.clicked = item.$_edit">
                <span ng-if="item.unloading_place ">
                  <i
                    class="fa"
                    ng-class="{
                      'fa-building-o': item.unloading_place_type === 'farm',
                      'fa-industry': item.unloading_place_type === 'elevator',
                      'fa-anchor': item.unloading_place_type === 'terminal',
                      'fa-warehouse': item.unloading_place_type === 'warehouse',
                      'fa-store': item.unloading_place_type === 'other',
                    }"
                  ></i> {[{item.item.unloading_place }]}
                </span>
              </div>
              <div ng-if="item.$_edit && item.clicked">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  title="item.unloading_place"
                  ng-model="item.unloading_warehouse"
                  placeholder="'Unloading warehouse'|translate"
                  resource-name="'logistics.warehouse'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>
            `,
      },
      {
        columnName: 'shipment_warehouse',
        title: this.gettext('Shipment warehouse'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'shipment_warehouse_list',
            label: this.gettext('Shipment warehouse'),
            resource: 'logistics.warehouse',
            related_fk: 'shipment_warehouse',
            related_model: 'logistics.Logistic',
            queryParams: () => {
              return { shipment_related_contract_list: this.contractId };
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit || !item.clicked" ng-click="item.clicked = item.$_edit">
                <span ng-if="item.shipment_warehouse">
                  <i
                    class="fa fa-building-o"
                    ng-class="{
                      'fa-building-o': item.shipment_warehouse_type === 'farm',
                      'fa-industry': item.shipment_warehouse_type === 'elevator',
                      'fa-anchor': item.shipment_warehouse_type === 'terminal',
                      'fa-warehouse': item.shipment_warehouse_type === 'warehouse',
                    }"
                  ></i>
                  <a ui-sref="logistics.warehouseDetails({id: item.shipment_warehouse})">
                    {[{item.shipment_warehouse_title }]}
                  </a>
                </span>
                <span ng-if="!item.shipment_warehouse"> - </span>
              </div>
              <div ng-if="item.$_edit && item.clicked">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  title="item.shipment_warehouse_title"
                  ng-model="item.shipment_warehouse"
                  warehouseholder="'Shipment warehouse'|translate"
                  resource-name="'logistics.warehouse'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>
            `,
      },
      {
        columnName: 'unloading_warehouse',
        title: this.gettext('Unloading warehouse'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'unloading_warehouse_list',
            label: this.gettext('Unloading warehouse'),
            resource: 'logistics.warehouse',
            related_fk: 'unloading_warehouse',
            related_model: 'logistics.Logistic',
            queryParams: () => {
              return { unloading_related_contract_list: this.contractId };
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit || !item.clicked" ng-click="item.clicked = item.$_edit">
                <span ng-if="item.unloading_warehouse ">
                  <i
                    class="fa fa-building-o"
                    ng-class="{
                      'fa-building-o': item.unloading_warehouse_type === 'farm',
                      'fa-industry': item.unloading_warehouse_type === 'elevator',
                      'fa-anchor': item.unloading_warehouse_type === 'terminal',
                      'fa-warehouse': item.unloading_warehouse_type === 'warehouse',
                      'fa-store': item.unloading_warehouse_type === 'other',
                    }"
                  ></i>
                  <a ui-sref="logistics.warehouseDetails({id: item.unloading_warehouse})">
                    {[{item.unloading_warehouse_title }]}
                  </a>
                </span>
              </div>
              <div ng-if="item.$_edit && item.clicked">
                <gt-resource-select
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  title="item.unloading_warehouse"
                  ng-model="item.unloading_warehouse"
                  warehouseholder="'Unloading warehouse'|translate"
                  resource-name="'logistics.warehouse'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>
            `,
      },
      {
        columnName: 'port__name',
        title: this.gettext('Port'),
        predicate: 'port_name',
        filters: [
          {
            type: 'ui-select',
            predicate: 'port',
            label: this.gettext('port'),
            resource: 'logistics.Port',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.terminal_title ">
                  <i class="fa fa-anchor"></i> {[{item.port_title }]} ({[{item.terminal_title
                  }]})
                </span>
              </div>
                <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.terminal_title"
                  ng-model="item.terminal"
                  query-params="item.supplierQueryParams"
                  placeholder="'Terminal'|translate"
                  resource-name="'logistics.Terminal'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.terminal }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'elevator',
        title: this.gettext('Elevator'),
        filters: [
          {
            type: 'ui-select',
            predicate: 'elevator',
            label: this.gettext('elevator'),
            resource: 'clients.Elevator',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.elevator_name ">
                    <i class="fa fa-building-o"></i> {[{item.elevator_name }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.elevator_name"
                  ng-model="item.elevator"
                  query-params="item.supplierQueryParams"
                  placeholder="'Elevator'|translate"
                  resource-name="'clients.Elevator'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.elevator }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'start_usage_date',
        title: this.gettext('Start usage (date)'),
        predicate: 'start_usage_date',
        class: 'highlighted-td',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_start_usage_date',
            endDateField: 'end_start_usage_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.start_usage_date ">
                  <i class="fa fa-calendar"></i> {[{item.start_usage_date | date:'dd.MM' }]}
                </span>
              </div>
                <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.start_usage_date"
                  allow-clear="true"
                ></gt-date-select>s
              </div>
            `,
      },
      {
        columnName: 'end_usage_date',
        title: this.gettext('End usage (date)'),
        predicate: 'end_usage_date',
        class: 'highlighted-td',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_end_usage_date',
            endDateField: 'end_end_usage_date',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.end_usage_date ">
                  <i class="fa fa-calendar"></i> {[{item.end_usage_date | date:'dd.MM' }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-date-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-click="item.clicked=true"
                  date-model="item.end_usage_date"
                  allow-clear="true"
                ></gt-date-select>
              </div>
            `,
      },
      {
        columnName: 'usage_cost',
        title: this.gettext('Usage cost'),
        predicate: 'usage_cost',
        filters: [
          {
            type: 'text',
            predicate: 'min_usage_cost',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_usage_cost',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                  {[{item.usage_cost || 0 | gtDecimal:2 }]}
                <span class="smaller-label" ng-if="item.purchase_currency ">
                  {[{item.purchase_currency }]}
                </span>
                <span class="smaller-label" ng-if="!item.purchase_currency ">
                  {[{item.currency }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input ng-if="item.clicked" class="form-control" type="number" ng-model="item.usage_cost" />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.usage_cost }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'approval_status',
        title: this.gettext('Approval status'),
        class: 'highlighted-td td-width-approval-status-cell td-left-align',
        filters: [
          {
            type: 'select',
            predicate: 'approval_status',
            label: this.gettext('Choose status'),
            values: {
              wait: this.gettext('Wait'),
              approved: this.gettext('Approved'),
              process: this.gettext('Process'),
              rejected: this.gettext('Rejected'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="td-left-align" ng-if="item.approval_status == 'process'">
                  <a
                    ng-click="args.updateLogistic({
                      id:item.id,
                      approval_status : 'approved',
                    })"
                    class="btn btn-xs btn-success hover-element"
                  >
                    <i class="fa fa-thumbs-up"></i>
                  </a>
                  <a
                    ng-click="args.updateLogistic({
                      id :item.id,
                      approval_status : 'rejected',
                    })"
                    class="btn btn-xs btn-danger hover-element"
                  >
                    <i class="fa fa-thumbs-down"></i>
                  </a>
                </span>
                <span ng-if="item.approval_status != 'wait'">
                  <span
                    class="label"
                    ng-class="{
                    'label_success': item.approval_status == 'approved',
                    'label_danger':  item.approval_status == 'rejected',
                    'label_warning': item.approval_status == 'process',
                    'label_default': item.approval_status == 'wait' }"
                  >
                    <span class="td-left-align" ng-if="item.approval_status == 'approved'">
                      <translate>Approved</translate>
                    </span>
                    <span ng-if="item.approval_status == 'process'">
                      <translate>Needs approval</translate>
                    </span>
                    <span class="td-left-align" ng-if="item.approval_status == 'rejected'">
                      <translate>Rejected</translate>
                    </span>
                  </span>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <select ng-model="item.approval_status" ng-click="item.clicked=true">
                  <option value="approved">
                    <translate>Approved</translate>
                  </option>
                  <option value="rejected">
                    <translate>Rejected</translate>
                  </option>
                  <option value="wait">
                    <translate>Waiting for approval</translate>
                  </option>
                  <option value="process">
                    <translate>Approval in process</translate>
                  </option>
                </select>
              </div>
            `,
      },
      {
        columnName: 'has_diff_limit',
        title: this.gettext('Has diff limit'),
        class: 'highlighted-td',
        cellTemplate: `
              <span ng-if="item.has_diff_limit" class="label label_danger">
                <i class="fa fa-exclamation-triangle"></i><translate>Limit</translate>
              </span>
            `,
      },
      {
        columnName: 'qualities',
        title: this.gettext('Qualities'),
        class: 'highlighted-td',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <table
                  class="table table-responsive table-condensed main-table counterparty_detail_table pull-left"
                  style="width: 90% !important"
                  aria-hidden="true"
                >
                  <tbody>
                    <tr
                      ng-repeat="quality in item.qualities"
                      ng-if="quality.value != 0 && quality.rule_value != 0"
                    >
                      <td>{[{ quality.title | cut:true:15 }]}</td>
                      <td>
                        {[{ quality.value | gtDecimal:1 }]} / {[{ (quality.rule_value || 0) | gtDecimal:1 }]}
                      </td>
                      <td>
                        <span class="label label_danger" ng-if="quality.diff_limit < 0"
                          ><i data-tip="limit: {[{ quality.rule_limit }]}" class="fa fa-info-circle"></i
                        ></span>
                        <span ng-class="{'label label_danger': quality.diff_value < 0}">
                          {[{ quality.diff_value }]}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span class="pull-right" style="width: 10%">
                  <a
                    ng-if="item.id && item.custom_status_codename != 'executed'"
                    class="btn btn-orange-border btn-xs hover-element"
                    ng-click="args.openQualitiesModal(item)"
                    >+ <i class="fa fa-flask"></i>
                  </a>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <span class="smaller-label">
                  <span
                    ng-repeat="quality in item.qualities"
                    class="tooltip"
                    data-tip="{[{ quality.title }]}"
                  >
                    {[{ quality.value }]};
                  </span>
                  <span class="label label_default" ng-if="item.protein_value">
                    <translate>protein * volume</translate> = {[{item.protein_value}]}
                  </span>
                </span>
              </div>
            `,
      },
      {
        title: this.gettext('Purchase Contract #'),
        columnName: 'supplier_contract__contract_number',
        predicate: 'supplier_contract__contract_number',
        class: 'highlighted-td td-right-align warning-highlighted',
        filters: [
          {
            type: 'text',
            predicate: 'supplier_contract__contract_number',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  class="btn-link"
                  ng-href="/#/contract/{[{item.supplier_contract}]} "
                  ng-if="item.supplier_contract_contract_number "
                >
                  <span> # {[{item.supplier_contract_contract_number }]} </span>
                </a>
                <span
                  ng-if="item.block == 'table_qualities' && !item.purchase_contract_rule_exist && $root.user.settings.SYSTEM_BLOCKS['block_execution_quality_rules']"
                  class="label_warning smaller-label"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  <translate>Quality rules are not included</translate>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                    title="item.supplier_contract_contract_number"
                    ng-model="item.supplier_contract"
                    query-params="item.supplierQueryParams"
                    placeholder="'Supplier contract'|translate"
                    resource-name="'contracts.PurchaseContract'"
                    allow-clear="true"
                  ></gt-resource-select>
                  <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                    >{[{item.supplier_contract_contract_number }]}</span
                  >
              </div>
            `,
      },
      {
        columnName: 'conditions_supplier',
        title: this.gettext('Conditions'),
        class: 'highlighted-td warning-highlighted',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="label" ng-if="item.purchase_payment_conditions ">
                  <i class="fa fa-train" ng-if="item.vehicle_type == 'wagon'"></i>
                  <i class="fa fa-truck" ng-if="item.vehicle_type == 'car'"></i> {[{
                  item.purchase_payment_conditions }]}%
                </span>
                <span
                  class="label"
                  ng-if="item.supplier_contract_payment_conditions && !item.purchase_payment_conditions "
                >
                  <i class="fa fa-train"></i> {[{item.supplier_contract_payment_conditions }]}%
                </span>
                <span
                  class="label"
                  ng-if="item.supplier_contract_payment_conditions_auto  && !item.purchase_payment_conditions"
                >
                  <i class="fa fa-truck"></i> {[{item.supplier_contract_payment_conditions_auto }]}%
                </span>
                <span class="label"> {[{ item.contract_balance_payment }]} </span>
              </div>
              <div ng-if="item.$_edit">
                <span class="label" ng-if="item.supplier_contract_payment_conditions">
                  <i class="fa fa-train"></i> {[{item.supplier_contract_payment_conditions }]}%
                </span>
                <span class="label" ng-if="item.supplier_contract_payment_conditions_auto">
                  <i class="fa fa-truck"></i> {[{item.supplier_contract_payment_conditions_auto }]}%
                </span>
              </div>
            `,
      },
      {
        columnName: 'supplier_nineteen_invoice__number',
        title: this.gettext('Prepay invoice'),
        predicate: 'supplier_nineteen_invoice__number',
        class: 'highlighted-td warning-highlighted',
        filters: [
          {
            type: 'text',
            predicate: 'supplier_nineteen_invoice__number',
          },
          {
            type: 'select',
            predicate: 'with_supplier_nineteen_invoice',
            label: this.gettext('Has prepay'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  ng-if="item.supplier_nineteen_number "
                  ui-sref="gt.page.payment({id: item.supplier_nineteen_invoice_id})"
                  target="_blank"
                  class="btn-link"
                >
                  # {[{item.supplier_nineteen_number }]}</a
                >
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.supplier_nineteen_number"
                  ng-model="item.supplier_nineteen_invoice"
                  query-params="item.supplierQueryParams"
                  placeholder="'Prepayment invoice'|translate"
                  resource-name="'finances.Finance'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.supplier_nineteen_number }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'supplier_ten_invoice__number',
        title: this.gettext('Balance invoice'),
        predicate: 'supplier_ten_invoice__number',
        class: 'highlighted-td warning-highlighted',
        filters: [
          {
            type: 'text',
            predicate: 'supplier_ten_invoice__number',
          },
          {
            type: 'select',
            predicate: 'with_supplier_ten_invoice',
            label: this.gettext('Has balance prepay'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  ng-if="item.supplier_ten_number "
                  ui-sref="gt.page.payment({id: item.supplier_ten_invoice_id})"
                  target="_blank"
                  class="btn-link"
                >
                  # {[{item.supplier_ten_number }]}
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="items.clicked"
                  title="item.supplier_ten_number"
                  ng-model="item.supplier_ten_invoice"
                  query-params="items.supplierQueryParams"
                  placeholder="'Balance invoice'|translate"
                  resource-name="'finances.Finance'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="items.clicked=true" ng-if="!items.clicked"
                  >{[{item.supplier_ten_number }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'supplier_contract__price',
        title: this.gettext('Purchase price'),
        predicate: 'supplier_contract__price',
        class: 'th highlighted-td td-right-align warning-highlighted td-width-purchase-price-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_supplier_contract__price',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_supplier_contract__price',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.price">
                  <span
                    class="tooltip label_danger label"
                    data-tip="{[{'price for current vehicle differs to contract'|translate}]}"
                    ng-if="item.price != item.purchase_price"
                  >
                    <i class="fa fa-info-circle"></i>
                  </span>
                  {[{ item.price || 0 | number:2 }]}
                </span>
                <span
                  ng-if="item.purchase_price"
                  ng-class="{'label-smoke smaller-label': item.price }"
                >
                  {[{ item.purchase_price || 0 | number:2 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <span ng-if="item.purchase_price ">
                {[{item.purchase_price || 0 | gtDecimal:2 }]}
                  <span class="smaller-label label-opacity">
                    {[{item.purchase_currency }]} / {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
              </div>
            `,
      },
      {
        columnName: 'discount_purchase_price',
        title: this.gettext('Price discount'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
              <span ng-if="item.discount_purchase_price">
                {[{item.discount_purchase_price || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'purchase_init_price',
        title: this.gettext('Purchase init price'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
             <span ng-if="item.purchase_init_price">
                {[{item.purchase_init_price || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'purchase_total_value',
        title: this.gettext('total value'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
              <span ng-if="item.purchase_total_value">
                {[{item.purchase_total_value || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'purchase_discount_value',
        title: this.gettext('discount value'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
              <span ng-if="item.purchase_discount_value">
                {[{item.purchase_discount_value || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'purchase_total_value_discounted',
        title: this.gettext('total value discounted'),
        class: 'highlighted-td td-right-align',
        cellTemplate: `
              <span ng-if="item.purchase_total_value_discounted">
                {[{item.purchase_total_value_discounted || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'supplier_to_be_invoiced',
        title: this.gettext('To be invoiced'),
        sortType: 'supplier_to_be_invoiced',
        class:
          'highlighted-td td-right-align warning-highlighted td-width-supplier-to-be-invoiced-cell',
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.supplier_to_be_invoiced != 0 ">
                  {[{item.supplier_to_be_invoiced || 0 | number:2 }]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <span ng-if="item.supplier_to_be_invoiced != 0 ">
                  {[{item.supplier_to_be_invoiced || 0 | gtDecimal:2 }]}
                  <span class="smaller-label label-opacity"> {[{item.purchase_currency }]} </span>
                </span>
              </div>
            `,
        totalTemplate: `
            {[{item.supplier_to_be_invoiced_sum || 0 | number: 2 }]}
            `,
      },
      {
        columnName: 'supplier_to_be_invoiced_balance',
        title: this.gettext('To be invoiced (bal)'),
        sortType: 'supplier_to_be_invoiced_balance',
        class:
          'highlighted-td td-right-align warning-highlighted td-width-supplier-to-be-invoiced-balance-cell',
        cellTemplate: `
              <span ng-if="item.supplier_to_be_invoiced_balance != 0 ">
                {[{item.supplier_to_be_invoiced_balance || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.supplier_to_be_invoiced_balance_sum || 0 | number: 2}]}
            `,
      },
      {
        columnName: 'supplier_invoiced',
        title: this.gettext('Invoiced'),
        sortType: 'supplier_invoiced',
        class: 'highlighted-td td-right-align warning-highlighted td-width-supplier-invoiced-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_invoiced != 0 && item.supplier_to_be_invoiced != item.supplier_invoiced "
              >
                ({[{ item.supplier_to_be_invoiced - item.supplier_invoiced || 0 | number:2 }]})
              </span>
              <span
                class="tooltip"
                ng-if="item.supplier_invoiced != 0 "
                data-tip="discount {[{ item.supplier_to_be_invoiced_discount | number:2 }]}"
              >
                {[{item.supplier_invoiced_sum || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
                {[{item.supplier_invoiced_sum || 0 | number: 2 }]}
                <div class="clearfix"></div>
                <span
                    class="smaller-label negative-number"
                    ng-if="item.total.supplier_invoiced_sum != 0 && item.supplier_to_be_invoiced_sum - item.supplier_invoiced_sum != 0 "
                >
                    ({[{ item.supplier_to_be_invoiced_sum - item.supplier_invoiced_sum || 0 |
                    number:2 }]})
                </span>
            `,
      },
      {
        columnName: 'supplier_invoiced_balance',
        title: this.gettext('Invoiced (bal)'),
        sortType: 'supplier_invoiced_balance',
        class: 'td-width-supplier-invoiced-balance-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_invoiced_balance != 0 && item.supplier_to_be_invoiced_balance != item.supplier_invoiced_balance "
              >
                ({[{ item.supplier_to_be_invoiced_balance - item.supplier_invoiced_balance || 0 |
                number:2 }]})
              </span>
              <span
                class="tooltip"
                ng-if="item.supplier_invoiced_balance != 0"
                data-tip="discount {[{ item.supplier_to_be_invoiced_balance_discount | number:2 }]}"
              >
                {[{item.supplier_invoiced_balance || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.supplier_invoiced_balance_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_invoiced_balance_sum != 0 && item.supplier_to_be_invoiced_balance_sum != item.supplier_invoiced_balance_sum "
              >
              ({[{ items.upplier_to_be_invoiced_balance_sum -
              item.supplier_invoiced_balance_sum || 0 | number:2 }]})
            </span>
            `,
      },
      {
        columnName: 'supplier_paid',
        title: this.gettext('Paid'),
        sortType: 'supplier_paid',
        class: 'highlighted-td td-right-align warning-highlighted td-width-supplier-paid-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_paid != 0 && item.supplier_paid != item.supplier_invoiced "
              >
                ({[{ item.supplier_invoiced - item.supplier_paid || 0 | number:2 }]})
              </span>
              <span ng-if="item.supplier_invoiced != '0'">
                {[{item.supplier_paid || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
            {[{item.supplier_paid_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_paid_sum != 0 && item.supplier_invoiced_sum != item.supplier_paid_sum "
              >
                ({[{ item.supplier_invoiced_sum - item.supplier_paid_sum || 0 | number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'supplier_paid_balance',
        title: this.gettext('Paid (bal)'),
        sortType: 'supplier_paid_balance',
        class:
          'highlighted-td td-right-align warning-highlighted td-width-supplaier-paid-balance-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_paid_balance != 0 && item.supplier_invoiced_balance != item.supplier_paid_balance "
              >
                ({[{ item.supplier_invoiced_balance - item.supplier_paid_balance || 0 | number:2
                }]})
              </span>
              <span ng-if="item.supplier_invoiced_balance != '0'">
                {[{item.supplier_paid_balance || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.supplier_paid_balance_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_paid_balance_sum != 0 && item.supplier_invoiced_balance_sum != item.supplier_paid_balance_sum "
              >
                ({[{ item.supplier_invoiced_balance_sum - item.supplier_paid_balance_sum || 0
                | number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'supplier_total_invoiced_plan',
        title: this.gettext('Total invoiced (plan)'),
        sortType: 'supplier_total_invoiced_plan',
        class:
          'highlighted-td td-right-align warning-highlighted td-width-supplier-total-invoiced-plan-cell',
        cellTemplate: `
              <span ng-if="item.supplier_total_invoiced_plan != 0 ">
                {[{item.supplier_total_invoiced_plan || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
            {[{item.supplier_total_invoiced_plan_sum || 0 | number: 2 }]}
            `,
      },
      {
        columnName: 'supplier_total_invoiced',
        title: this.gettext('Total invoiced (fact)'),
        sortType: 'supplier_total_invoiced',
        class:
          'highlighted-td td-right-align warning-highlighted td-width-supplier-total-invoiced-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_total_invoiced != 0 && item.supplier_total_invoiced_plan != item.supplier_total_invoiced "
              >
                ({[{ item.supplier_total_invoiced_plan - item.supplier_total_invoiced || 0 |
                number:2 }]})
              </span>
              <span ng-if="item.supplier_total_invoiced != 0 ">
                {[{item.supplier_total_invoiced || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.supplier_total_invoiced_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_total_invoiced_sum != 0 && item.supplier_total_invoiced_plan_sum != item.supplier_total_invoiced_sum "
              >
                ({[{ item.supplier_total_invoiced_plan_sum - item.supplier_total_invoiced_sum
                || 0 | number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'supplier_total_paid',
        title: this.gettext('Total paid'),
        sortType: 'supplier_total_paid',
        class:
          'highlighted-td td-right-align warning-highlighted td-width-supplier-total-paid-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_total_paid != 0 && item.supplier_total_invoiced != item.supplier_total_paid "
              >
                ({[{ item.supplier_total_invoiced - item.supplier_total_paid || 0 | number:2 }]})
              </span>
              <span ng-if="item.supplier_total_paid != 0 ">
                {[{item.supplier_total_paid || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.supplier_total_paid_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.supplier_total_invoiced_sum != item.supplier_total_paid_sum "
              >
                ({[{ item.supplier_total_invoiced_sum - item.supplier_total_paid_sum || 0 |
                number:2 }]})
              </span>
            `,
      },
      {
        title: this.gettext('Sale Contract #'),
        columnName: 'buyer_contract__contract_number',
        predicate: 'buyer_contract__contract_number',
        class: 'highlighted-td td-right-align warning-highlighted',
        filters: [
          {
            type: 'text',
            predicate: 'buyer_contract__contract_number',
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  ng-href="/#/contract/{[{item.buyer_contract}]}"
                  ng-if="item.buyer_contract_contract_number"
                  class="btn-link"
                >
                  # {[{item.buyer_contract_contract_number }]}
                </a>
                <span
                  ng-if="item.block == 'table_qualities' && !item.sale_contract_rule_exist && $root.user.settings.SYSTEM_BLOCKS['block_execution_quality_rules']"
                  class="label_warning smaller-label"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  <translate>Quality rules are not included</translate>
                </span>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  ng-if="item.clicked"
                  title="item.buyer_contract_contract_number"
                  ng-model="item.buyer_contract"
                  query-params="item.buyerQueryParams"
                  placeholder="'Buyer contract'|translate"
                  resource-name="'contracts.SaleContract'"
                  allow-clear="true"
                ></gt-resource-select>
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.buyer_contract_contract_number }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'conditions_buyer',
        title: this.gettext('Conditions'),
        class: 'highlighted-td td-right-align warning-highlighted',
        cellTemplate: `
              <span class="label" ng-if="item.sale_payment_conditions">
                <i class="fa fa-train" ng-if="item.vehicle_type == 'wagon'"></i>
                <i class="fa fa-truck" ng-if="item.vehicle_type == 'car'"></i> {[{
                item.sale_payment_conditions }]}%
              </span>
              <span
                class="label"
                ng-if="item.buyer_contract_payment_conditions && !item.sale_payment_conditions"
              >
                <i class="fa fa-train"></i> {[{item.buyer_contract_payment_conditions }]}%
              </span>
              <span
                class="label"
                ng-if="item.buyer_contract_payment_conditions_auto && !item.sale_payment_conditions"
              >
                <i class="fa fa-truck"></i> {[{item.buyer_contract_payment_conditions_auto }]}%
              </span>
              <span class="label"> {[{ item.contract_balance_payment }]} </span>
            `,
      },
      {
        columnName: 'buyer_nineteen_invoice__number',
        title: this.gettext('Prepay invoice'),
        predicate: 'buyer_nineteen_invoice__number',
        class: 'highlighted-td td-right-align warning-highlighted',
        filters: [
          {
            type: 'text',
            predicate: 'buyer_nineteen_invoice__number',
          },
          {
            type: 'select',
            predicate: 'with_buyer_nineteen_invoice',
            label: this.gettext('Has prepay'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  ng-if="item.buyer_nineteen_number"
                  ui-sref="gt.page.payment({id: item.buyer_nineteen_invoice_id})"
                  target="_blank"
                  class="btn-link"
                  ># {[{item.buyer_nineteen_number }]}</a>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  dry="true"
                  ng-model="item.buyer_nineteen_invoice"
                  placeholder="'Prepayment invoice'|translate"
                  query-params="{ with_contract: item.buyer_contract, invoice_type: 'outgoing' }"
                  resource-name="'finances.Finance'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>
            `,
      },
      {
        columnName: 'buyer_ten_invoice__number',
        title: this.gettext('Balance invoice'),
        predicate: 'buyer_ten_invoice__number',
        class: 'highlighted-td td-right-align warning-highlighted',
        filters: [
          {
            type: 'text',
            predicate: 'buyer_ten_invoice__number',
          },
          {
            type: 'select',
            predicate: 'with_buyer_ten_invoice',
            label: this.gettext('Has balance prepay'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <a
                  ng-if="item.buyer_ten_number"
                  ui-sref="gt.page.payment({id: item.buyer_ten_invoice_id})"
                  target="_blank"
                  class="btn-link"
                >
                  # {[{item.buyer_ten_number }]}
                </a>
              </div>
              <div ng-if="item.$_edit">
                <gt-resource-select
                ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                  dry="true"
                  ng-model="item.buyer_ten_invoice"
                  placeholder="'Prepayment invoice'|translate"
                  query-params="{ with_contract: item.buyer_contract, invoice_type: 'outgoing' }"
                  resource-name="'finances.Finance'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>
            `,
      },
      {
        columnName: 'buyer_contract__price',
        title: this.gettext('Sale price'),
        predicate: 'buyer_contract__price',
        class: 'highlighted-td td-right-align success-highlighte td-width-sale-price-cell',
        filters: [
          {
            type: 'text',
            predicate: 'min_buyer_contract__price',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_buyer_contract__price',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <span ng-if="item.sale_price">
                <span
                  class="tooltip label_danger label"
                  ng-if="item.sale_price != item.sale_ctr_price"
                  data-tip="{[{'price for current vehicle differs to contract'|translate}]}"
                >
                  <i class="fa fa-info-circle"></i>
                </span>
                {[{ item.sale_price - item.sale_vat || 0 | number:2 }]}
              </span>
              <span
                ng-if="item.sale_ctr_price"
                ng-class="{'label-smoke smaller-label': item.sale_price }"
              >
                {[{ item.sale_ctr_price || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'discount_sale_price',
        title: this.gettext('Sale price discount'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span ng-if="item.discount_sale_price">
                {[{item.discount_sale_price || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_init_price',
        title: this.gettext('Sale init price'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span ng-if="item.sale_init_price">
                {[{item.sale_init_price || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_total_value',
        title: this.gettext('Sale total value'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span ng-if="item.sale_total_value">
                {[{item.sale_total_value || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_discount_value',
        title: this.gettext('Sale discount value'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span ng-if="item.sale_discount_value">
                {[{item.sale_discount_value || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_total_value_discounted',
        title: this.gettext('Sale total value discounted'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span ng-if="item.sale_total_value_discounted">
                {[{item.sale_total_value_discounted || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'volume_sale_trader',
        title: this.gettext('Traders volume'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
            <span ng-if="item.volume_sale_trader">
              {[{item.volume_sale_trader || 0 | number:3 }]}
            </span>
            `,
      },
      {
        columnName: 'sale_trader_amount',
        title: this.gettext('Traders amount'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span ng-if="item.sale_trader_amount">
                {[{item.sale_trader_amount || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_trader_volume_diff',
        title: this.gettext('Volume reconciliation'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span
                ng-if="item.sale_trader_volume_diff"
                ng-class="{ 'label label_danger': item.sale_trader_volume_diff < 0 }"
              >
                {[{ item.sale_trader_volume_diff || 0 | number:3 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_trader_amount_diff',
        title: this.gettext('Amount reconciliation'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
              <span
                ng-if="item.sale_trader_volume_diff"
                ng-class="{ 'label label_danger': item.sale_trader_volume_diff < 0 }"
              >
                {[{ item.sale_trader_volume_diff || 0 | number:3 }]}
              </span>
            `,
      },
      {
        columnName: 'sale_trader_discount_amount',
        title: this.gettext('Traders discount value'),
        class: 'highlighted-td td-right-align success-highlighte',
        cellTemplate: `
               <span
                ng-if="item.sale_trader_discount_amount"
                ng-class="{ 'label label_danger': item.sale_trader_discount_amount < 0 }"
              >
                {[{item.sale_trader_discount_amount || 0 | number:2 }]}
              </span>
            `,
      },
      {
        columnName: 'buyer_to_be_invoiced',
        title: this.gettext('To be invoiced'),
        sortType: 'buyer_to_be_invoiced',
        class:
          'highlighted-td td-right-align success-highlighted td-width-buyer-to-be-invoiced-cell',
        cellTemplate: `
              <span ng-if="item.buyer_to_be_invoiced_balance != 0">
                {[{item.buyer_to_be_invoiced_balance || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_to_be_invoiced_sum || 0 | number: 2 }]}
            `,
      },
      {
        columnName: 'buyer_to_be_invoiced_balance',
        title: this.gettext('To be invoiced (bal)'),
        sortType: 'buyer_to_be_invoiced_balance',
        class:
          'highlighted-td td-right-align success-highlighted td-width-byuyer-to-be-invoiced-balance-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_invoiced_balance != 0 && item.buyer_to_be_invoiced_balance != item.buyer_invoiced_balance "
              >
                ({[{ item.buyer_to_be_invoiced_balance - item.buyer_invoiced_balance || 0 |
                number:2 }]})
              </span>
              <span
                class="tooltip"
                ng-if="item.buyer_invoiced_balance != 0"
                data-tip="discount {[{ item.buyer_to_be_invoiced_balance_discount | number:2 }]}"
              >
                {[{item.buyer_invoiced_balance || 0 | number:2 }]}
            `,
        totalTemplate: `
              {[{item.buyer_to_be_invoiced_balance_sum || 0 | number: 2 }]}
            `,
      },
      {
        columnName: 'buyer_invoiced',
        title: this.gettext('Invoiced'),
        sortType: 'buyer_invoiced',
        class: 'highlighted-td td-right-align success-highlighted td-width-buyer-invoiced-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_invoiced != 0 && item.buyer_to_be_invoiced != item.buyer_invoiced "
              >
                ({[{ item.buyer_to_be_invoiced - item.buyer_invoiced || 0 | number:2 }]})
              </span>
              <span
                class="tooltip"
                ng-if="m.logistic.buyer_invoiced != 0"
                data-tip="discount {[{ item.buyer_to_be_invoiced_discount | number:2 }]}"
              >
                {[{item.buyer_invoiced || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_invoiced_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_invoiced_sum != 0 && item.buyer_to_be_invoiced_sum != item.buyer_invoiced_sum "
              >
                ({[{ item.buyer_to_be_invoiced_sum - item.buyer_invoiced_sum || 0 | number:2
                }]})
              </span>
            `,
      },
      {
        columnName: 'buyer_invoiced_balance',
        title: this.gettext('Invoiced (balance)'),
        sortType: 'buyer_invoiced_balance',
        class:
          'highlighted-td td-right-align success-highlighted td-width-buyer-invoiced-balance-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_invoiced_balance != 0 && item.buyer_to_be_invoiced_balance != item.buyer_invoiced_balance "
              >
                ({[{ item.buyer_to_be_invoiced_balance - item.buyer_invoiced_balance || 0 |
                number:2 }]})
              </span>
              <span
                class="tooltip"
                ng-if="item.buyer_invoiced_balance != 0"
                data-tip="discount {[{ item.buyer_to_be_invoiced_balance_discount | number:2 }]}"
              >
                {[{item.buyer_invoiced_balance || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_invoiced_balance_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_invoiced_balance_sum != 0 && item.buyer_to_be_invoiced_balance_sum != item.buyer_invoiced_balance_sum "
              >
                ({[{ item.buyer_to_be_invoiced_balance_sum - item.buyer_invoiced_balance_sum
                || 0 | number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'buyer_paid',
        title: this.gettext('Paid'),
        sortType: 'buyer_paid',
        class: 'highlighted-td td-right-align success-highlighted td-width-buyer-paid-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_paid != 0 && item.buyer_paid != item.buyer_invoiced "
              >
                ({[{ item.buyer_invoiced - item.buyer_paid || 0 | number:2 }]})
              </span>
              <span ng-if="item.buyer_paid != 0">
                {[{item.buyer_paid || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_paid_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_paid_sum != 0 && item.buyer_invoiced_sum != item.buyer_paid_sum "
              >
                ({[{ item.buyer_invoiced_sum - $item.buyer_paid_sum || 0 | number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'buyer_paid_balance',
        title: this.gettext('Paid (bal)'),
        sortType: 'buyer_paid_balance',
        class: 'highlighted-td td-right-align success-highlighted td-width-buyer-paid-balance-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_paid_balance != 0 && item.buyer_invoiced_balance != item.buyer_paid_balance "
              >
                ({[{ item.buyer_invoiced_balance - item.buyer_paid_balance || 0 | number:2 }]})
              </span>
              <span ng-if="item.buyer_paid_balance != 0">
                {[{item.buyer_paid_balance || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_paid_balance_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_paid_balance_sum != 0 && item.buyer_invoiced_balance_sum != item.buyer_paid_balance_sum "
              >
                ({[{ item.buyer_invoiced_balance_sum - item.buyer_paid_balance_sum || 0 |
                number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'buyer_total_invoiced_plan',
        title: this.gettext('Total invoiced (plan)'),
        sortType: 'buyer_total_invoiced_plan',
        class:
          'highlighted-td td-right-align success-highlighted td-width-buyer-total-invoiced-plan-cell',
        cellTemplate: `
              <span ng-if="item.buyer_total_invoiced_plan != 0">
                {[{item.buyer_total_invoiced_plan || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
            {[{item.buyer_total_invoiced_plan_sum || 0 | number: 2 }]}
            `,
      },
      {
        columnName: 'buyer_total_invoiced',
        title: this.gettext('Total invoiced (fact)'),
        sortType: 'buyer_total_invoiced',
        class:
          'highlighted-td td-right-align success-highlighted td-width-buyer-total-invoiced-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_total_invoiced != 0 && item.buyer_total_invoiced_plan != item.buyer_total_invoiced "
              >
                ({[{ item.buyer_total_invoiced_plan - item.buyer_total_invoiced || 0 | number:2
                }]})
              </span>
              <span ng-if="item.buyer_total_invoiced != 0">
                {[{item.buyer_total_invoiced || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_total_invoiced_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_total_invoiced_sum != 0 && item.buyer_total_invoiced_plan_sum != item.buyer_total_invoiced_sum "
              >
                ({[{ item.buyer_total_invoiced_plan_sum - item.buyer_total_invoiced_sum || 0
                | number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'buyer_total_paid',
        title: this.gettext('Total paid'),
        sortType: 'buyer_total_paid',
        class:
          'th highlighted-td td-right-align success-highlighted td-width-buyer-total-paid-cell',
        cellTemplate: `
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_total_paid != 0 && item.buyer_total_invoiced != item.buyer_total_paid "
              >
                ({[{ item.buyer_total_invoiced - item.buyer_total_paid || 0 | number:2 }]})
              </span>
              <span ng-if="item.buyer_total_paid != 0">
                {[{item.buyer_total_paid || 0 | number:2 }]}
              </span>
            `,
        totalTemplate: `
              {[{item.buyer_total_paid_sum || 0 | number: 2 }]}
              <div class="clearfix"></div>
              <span
                class="smaller-label negative-number"
                ng-if="item.buyer_total_invoiced_sum != item.buyer_total_paid_sum "
              >
                ({[{ item.buyer_total_invoiced_sum - item.buyer_total_paid_sum || 0 |
                number:2 }]})
              </span>
            `,
      },
      {
        columnName: 'crop_year',
        title: this.gettext('Crop year'),
        predicate: 'crop_year',
        filters: [
          {
            type: 'text',
            predicate: 'min_crop_year',
            addon: this.gettext('Min'),
          },
          {
            type: 'text',
            predicate: 'max_crop_year',
            addon: this.gettext('Max'),
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span ng-if="item.crop_year">
                  <i class="fa fa-calendar-o"></i> {[{item.crop_year || 0 }]}
                </span>
                <span class="label-opacity" ng-if="!item.crop_year"> </span>
              </div>
              <div ng-if="item.$_edit">
                <input ng-if="item.clicked" class="form-control" type="text" ng-model="item.crop_year" />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.crop_year }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'cargo_brand',
        title: this.gettext('Cargo brand'),
        cellTemplate: `
              <span ng-if="item.cargo_brand_name"> {[{item.cargo_brand_name }]} </span>
              <span class="label-opacity" ng-if="!item.cargo_brand_name"> </span>
            `,
      },
      {
        columnName: 'bags_weight',
        title: this.gettext('Bags weight'),
        cellTemplate: `
              <span ng-if="item.bags_weight"> {[{item.bags_weight | number:0 || 0 }]} </span>
              <span class="label-opacity" ng-if="!item.bags_weight"> </span>
            `,
      },
      {
        columnName: 'bags_quantity',
        title: this.gettext('Bags quantity'),
        cellTemplate: `
              <span ng-if="item.bags_quantity">
                {[{item.bags_quantity | number:0 || 0 }]}
              </span>
            `,
      },
      {
        columnName: 'export_invoices_quantity_sum',
        title: this.gettext('Export invoiced'),
        cellTemplate: `
              <span ng-if="item.export_invoices_quantity_sum">
                {[{item.export_invoices_quantity_sum | number:3 || 0 }]}
              </span>
              <span class="label-opacity" ng-if="!item.export_invoices_quantity_sum"> </span>
            `,
      },
      {
        columnName: 'export_invoice_list',
        title: this.gettext('Export invoice'),
        cellTemplate: `
              <ul class="inside-table-ul" ng-if="item.export_invoice_list.length">
                <li ng-repeat="invoice in item.export_invoice_list">
                  <a
                    ui-sref="gt.page.payment({id: invoice.id})"
                    target="_blank"
                    style="padding-left: 5px"
                    class="btn-link smaller-label pull-left"
                  >
                    <span class="smaller-label secondary-text" ng-if="invoice.date">
                      <i class="fa fa-calendar"></i> {[{invoice.date | date:'dd/MM/yy'}]}
                    </span>
                    <div class="clearfix"></div>
                    <i class="fa fa-credit-card"></i> {[{invoice.number | cut:true:30:'...' }]}
                    <span class="label label_danger" ng-if="invoice.not_pay"
                      ><translate>Not pay</translate></span
                    >
                    <span class="negative-number" ng-if="invoice.status == 'canceled'"
                      >(<translate>{[{ invoice.status }]})</translate></span
                    >
                    <span
                      class="badge label_danger tooltip smaller-label"
                      data-tip="With discount"
                      ng-if="invoice.discount != 100"
                      style="font-size: 6px !important"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                    </span>
                    <div class="clearfix"></div>
                    <span ng-if="invoice.invoice_type == 'incoming'" class="smaller-label secondary-text">
                      <i class="fa fa-building"></i>
                      {[{ invoice.client_from_name | cut:true:30:'...' }]}</span
                    >

                    <span ng-if="invoice.invoice_type == 'outgoing'" class="smaller-label secondary-text">
                      <i class="fa fa-building"></i>
                      {[{ invoice.client_to_name | cut:true:30:'...' }]}</span
                    >
                    <div class="clearfix"></div>
                  </a>
                </li>
              </ul>
            `,
      },
      {
        columnName: 'additional_info',
        title: this.gettext('Additional info'),
        filters: [
          {
            type: 'select',
            predicate: 'with_additional_info',
            label: this.gettext('Has additional info'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
          {
            type: 'select',
            predicate: 'with_note_filter',
            label: this.gettext('Has note'),
            values: {
              1: this.gettext('Yes'),
              0: this.gettext('No'),
            },
          },
        ],
        cellTemplate: `
              <div ng-if="!item.$_edit">
                <span class="tooltip" data-tip="{[{item.additional_info}]}">
                  {[{item.additional_info | cut:true:30:'...'}]}
                </span>
                <span class="tooltip" data-tip="{[{item.note}]}" ng-if="item.note">
                  <i class="fa fa-folder-o"></i> {[{item.note | cut:true:30:'...'}]}
                </span>
              </div>
              <div ng-if="item.$_edit">
                <input
                  ng-if="item.clicked"
                  class="form-control pull-left"
                  placeholder="{[{ 'Info' |translate }]}"
                  type="text"
                  ng-model="item.additional_info"
                />
                <span class="form-control" ng-click="item.clicked=true" ng-if="!item.clicked"
                  >{[{item.additional_info }]}</span
                >
              </div>
            `,
      },
      {
        columnName: 'update_time',
        title: this.gettext('Last update'),
        predicate: 'update_time',
        class: 'update_time',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_update_time',
            endDateField: 'end_update_time',
          },
        ],
        cellTemplate: `
              <span ng-if="item.update_time">
                <i class="fa fa-clock-o"></i> {[{item.update_time | date:'HH:mm' || '---' }]} -
                <i class="fa fa-calendar"></i> {[{item.update_time | date:'dd.MM' || '---' }]}
              </span>
            `,
      },
      {
        columnName: 'estimated_receiving_date',
        title: this.gettext('Estimated receiving (date)'),
        predicate: 'estimated_receiving_date',
        filters: [
          {
            type: 'daterange',
            startDateField: 'start_estimated_receiving_date',
            endDateField: 'end_estimated_receiving_date',
          },
        ],
        cellTemplate: `
              <span ng-if="item.estimated_receiving_date">
                <i class="fa fa-calendar-o"></i> {[{item.estimated_receiving_date | date:'dd.MM.yy'
                }]}
              </span>
              <span class="label-opacity" ng-if="!item.estimated_receiving_date">
                <i class="fa fa-calendar-o"></i> ---
              </span>
            `,
      },
      {
        columnName: 'cargo_customs_declarations_data',
        title: this.gettext('Cargo customs declarations'),
        class: 'td-width-volume-departed-cell',
        cellTemplate: /*html*/ `
             <span ng-if="item.cargo_customs_declarations_data">
                <div ng-repeat="cargoDeclaration in item.cargo_customs_declarations_data">
                  <a class="btn-link" ng-href="/#/customs-cargo-declaration-details/{[{cargoDeclaration.id}]}" ng-if="cargoDeclaration.id">
                    <span> {[{cargoDeclaration.number}]} </span>
                  </a>
                </div>
             </span>
            `,
      },
      {
        columnName: 'periodic_customs_declarations_data',
        title: this.gettext('Periodic customs declarations'),
        class: 'td-width-volume-departed-cell',
        cellTemplate: /*html*/ `
             <span ng-if="item.periodic_customs_declarations_data">
                <div ng-repeat="periodicDeclaration in item.periodic_customs_declarations_data">
                  <a class="btn-link" ng-href="/#/customs-cargo-declaration-details/{[{periodicDeclaration.id}]}" ng-if="periodicDeclaration.id">
                    <span> {[{periodicDeclaration.number}]} </span>
                  </a>
                </div>
             </span>
            `,
      },
      {
        columnName: 'transport_costs',
        title: this.gettext('Transport costs'),
        class: 'td-width-volume-departed-cell',
        cellTemplate: /*html*/ `
              <span ng-if="item.transport_costs_amount">
                <span ng-if="!item.$costsClicked" ng-click="item.$costsClicked=true" class="btn-link">
                  <span class="label label-opacity">
                    <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i>{[{item.transport_costs_amount_default}]} {[{args.defaultCurrency}]}
                  </span>
                  <span class="label label-opacity">
                    <i class="fa fa-bookmark"></i> <i class="fa fa-credit-card"></i>{[{item.transport_costs_invoiced_amount_default}]} {[{args.defaultCurrency}]}
                  </span>
                  <span class="label label-opacity">
                    <i class="fa fa-bookmark-o"></i> <i class="fa fa-credit-card-alt"></i>{[{item.transport_costs_paid_amount_default}]} {[{args.defaultCurrency}]}
                  </span>
                </span>
                <span ng-if="item.$costsClicked">
                  <table>
                    <thead ng-click="item.$costsClicked=false">
                      <tr>
                        <th><translate>Cost</translate></th>
                        <th><translate>Amount</translate></th>
                        <th><translate>Amount/t</translate></th>
                        <th><translate>Invoiced</translate></th>
                        <th><translate>Paid</translate></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr ng-repeat="transport_cost in item.transport_costs_data">
                        <td>
                          <span>
                            <a ui-sref="finances.contractCharges({id: transport_cost.cost_id})">
                              {[{transport_cost.cost_title}]}
                            </a>
                          </span>
                        </td>
                        <td class="td-right-align">
                          <span ng-if="transport_cost.price_per_deal" >
                            <span>{[{transport_cost.price_per_deal || 0 | number: 2}]}</span>
                            <span class="label smaller-label">{[{transport_cost.currency_symbol}]}</span>
                          </span>
                        </td>
                        <td class="td-right-align">
                          <span ng-if="transport_cost.price_per_t" >
                            <span>{[{transport_cost.price_per_t || 0 | number: 2}]}</span>
                            <span class="label smaller-label">{[{transport_cost.currency_symbol}]}</span>
                          </span>
                        </td>
                        <td>
                          <a ng-click="transport_cost.$clicked=!transport_cost.$clicked && transport_cost.invoiced_amount" >
                            <span>{[{transport_cost.invoiced_amount || 0 | number: 2}]}</span>
                            <span class="label smaller-label">{[{transport_cost.currency_symbol}]}</span>
                          </a>
                          <span ng-if="transport_cost.$clicked">
                            <ul>
                              <li ng-repeat="invoice_data in transport_cost.invoicing_data">
                                <i class="fa fa-stack-overflow" ng-if="!invoice_data.direct"></i>
                                <a ui-sref="gt.page.payment({ id: invoice_data.invoice_id})">
                                  {[{invoice_data.invoice_number}]}
                                </a>
                                <span class="label label_danger">
                                 {[{invoice_data.invoiced_amount || 0 | number: 2}]}
                                </span>/
                                <span class="label label_success">
                                  {[{invoice_data.paid_amount || 0 | number: 2}]}
                                </span>
                              </li>
                            </ul>
                          </span>
                        </td>
                        <td>
                          <span ng-if="transport_cost.paid" >
                            <span>{[{transport_cost.paid || 0 | number: 2}]}</span>
                            <span class="label smaller-label">{[{transport_cost.currency_symbol}]}</span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              </span>
            `,
      },
      {
        columnName: 'fromStockReserveUnit',
        title: this.gettext('From Stock Reserve Unit'),
        class: 'td-right-align',
        cellTemplate: `
            <span ng-if="item.from_stock_reserve_unit_id">
              <a ui-sref="logistics.stockReserveUnit({id: item.from_stock_reserve_unit_id})">
                {[{item.from_stock_reserve_unit_title || '---' }]}
              </a>
            </span>
          `,
      },
      {
        columnName: 'toStockReserveUnit',
        title: this.gettext('To Stock Reserve Unit'),
        class: 'td-right-align',
        cellTemplate: `
            <span ng-if="item.to_stock_reserve_unit_id">
              <a ui-sref="logistics.stockReserveUnit({id: item.to_stock_reserve_unit_id})">
                {[{item.to_stock_reserve_unit_title || '---' }]}
              </a>
            </span>
          `,
      },
      {
        columnName: 'supplier_contract_price',
        title: this.gettext('Contract Purchase Price'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span ng-if="item.supplier_contract_price">
            {[{item.supplier_contract_price || 0 | number:2 }]}
          </span>
        `,
      },
      {
        columnName: 'buyer_contract_price',
        title: this.gettext('Contract Sales Price'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span ng-if="item.buyer_contract_price">
            {[{item.buyer_contract_price || 0 | number:2 }]}
          </span>
        `,
      },
      {
        columnName: 'simple_purchase_price',
        title: this.gettext('Vehicle Purchase Price'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span ng-if="item.price">
            {[{item.price || 0 | number:2 }]}
          </span>
        `,
      },
      {
        columnName: 'simple_sale_price',
        title: this.gettext('Vehicle Sales Price'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span ng-if="item.sale_price">
            {[{item.sale_price || 0 | number:2 }]}
          </span>
        `,
      },
      {
        columnName: 'total_purchase_value',
        title: this.gettext('Total purchase value'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.total_purchase_value || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.total_purchase_value_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'total_sale_value',
        title: this.gettext('Total sale value'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.total_sale_value || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.total_sale_value_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'transport_costs_amount',
        title: this.gettext('Vehicle Expenses'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.transport_costs_amount || 0 | number:2 }]} {[{args.defaultCurrency}]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.transport_costs_amount_sum || 0 | number:3}]} {[{args.defaultCurrency}]}
        `,
      },
      {
        columnName: 'invoice_positions_amount',
        title: this.gettext('Total Logistics Invoice Amount'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.invoice_positions_amount || 0 | number:2 }]} {[{args.defaultCurrency}]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.invoice_positions_amount_sum || 0 | number:3}]} {[{args.defaultCurrency}]}
        `,
      },
      {
        columnName: 'payment_amount_sum',
        title: this.gettext('Total Logistics Payment Amount'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.payment_amount_sum || 0 | number:2 }]} {[{args.defaultCurrency}]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.payment_amount_sum || 0 | number:3}]} {[{args.defaultCurrency}]}
        `,
      },
      {
        columnName: 'purchase_contract_cost_per_ton',
        title: this.gettext('Purchase Contract Expenses per T'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.purchase_contract_cost_per_ton || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.purchase_contract_cost_per_ton_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'sale_contract_cost_per_ton',
        title: this.gettext('Sales Contract Expenses per T'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.sale_contract_cost_per_ton || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.sale_contract_cost_per_ton_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'passport_cost_per_ton',
        title: this.gettext('Passport Expenses per T'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.passport_cost_per_ton || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.passport_cost_per_ton_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'passports_related',
        title: this.gettext('Passports Linked to Vehicle'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span ng-if="item.passports_related">
            <span ng-repeat="passport in item.passports_related">
              <a
                  target="_blank"
                  class="btn-link"
                  ng-href="/#/passport/{[{passport.id}]}"
                  ng-if="passport.id"
              >
                  <span># {[{passport.title}]}</span>
              </a>
            </span>
          </span>
        `,
      },
      {
        columnName: 'purchase_invoice_total',
        title: this.gettext('Purchase Invoices Amount'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.purchase_invoice_total || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.purchase_invoice_total_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'sale_invoice_total',
        title: this.gettext('Sales Invoices Amount'),
        class: 'td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.sale_invoice_total || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.sale_invoice_total_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'contract_pnl_per_auto_with_vat',
        title: this.gettext('Contract PnL per auto (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.contract_pnl_per_auto_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.contract_pnl_per_auto_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'sale_contract_pnl_per_t_with_vat',
        title: this.gettext('Sales Contract PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.sale_contract_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.sale_contract_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'purchase_contract_pnl_per_t_with_vat',
        title: this.gettext('Purchase Contract PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.purchase_contract_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.purchase_contract_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'execution_pnl_per_auto_with_vat',
        title: this.gettext('Execution PnL per auto (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.execution_pnl_per_auto_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.execution_pnl_per_auto_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'sale_execution_pnl_per_t_with_vat',
        title: this.gettext('Sale Execution PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.sale_execution_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.sale_execution_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'purchase_execution_pnl_per_t_with_vat',
        title: this.gettext('Purchase execution PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.purchase_execution_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.purchase_execution_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'invoices_pnl_per_auto_with_vat',
        title: this.gettext('Invoices PnL per auto (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.invoices_pnl_per_auto_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.invoices_pnl_per_auto_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'sale_invoices_pnl_per_t_with_vat',
        title: this.gettext('Sales Invoices PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.sale_invoices_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.sale_invoices_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'purchase_invoices_pnl_per_t_with_vat',
        title: this.gettext('Purchase Invoices PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.purchase_invoices_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.purchase_invoices_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'payments_pnl_per_auto_with_vat',
        title: this.gettext('Payments PnL per auto (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.payments_pnl_per_auto_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.payments_pnl_per_auto_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'sale_payments_pnl_per_t_with_vat',
        title: this.gettext('Sales Payments PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.sale_payments_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.sale_payments_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
      {
        columnName: 'purchase_payments_pnl_per_t_with_vat',
        title: this.gettext('Purchase Payments PnL per t (with VAT)'),
        class: 'highlighted-td td-right-align',
        cellTemplate: /*html*/ `
          <span>
            {[{item.purchase_payments_pnl_per_t_with_vat || 0 | number:2 }]}
          </span>
        `,
        totalTemplate: /*html*/ `
          {[{item.purchase_payments_pnl_per_t_with_vat_sum || 0 | number:3}]}
        `,
      },
    ];

    if (this.$rootScope.user.settings.SIMPLE_TABLES) {
      options.tabs = options.tabs.filter((item: { serializer: string }) => {
        return !['table_qualities', 'table_certs', 'table_costs'].includes(item.serializer);
      });
    }

    return this.LogisticFormFieldsService.getCustomFieldTableColumns().then((columns: any) => {
      options.columnDefs.push(...columns);
      options.tabs.forEach((tab: { serializer: string; columns: any[] }) => {
        if (tab.serializer === 'table_info') {
          tab.columns.push(...columns.map((column: any) => column.columnName));
        }
      });
      this.tableOptions = options;
      this.certificateTabTitles = this.getCertificateTabTitles();
    });
  }
}

export const logisticsTable: ng.IComponentOptions = {
  bindings: {
    logistics: '<',
    count: '<',
    filterLevel: '<?',
    tableName: '<?',
    total: '<',
    mainTotals: '<',
    intermediateTotals: '<',
    onUpdate: '&?',
    sertDocument: '<?',
    canConnectToDbl: '<?',
    newLogistic: '=?',
    onConnectionToDbl: '&?',
    saveLogistic: '&?',
    getSelectedTotals: '&?',
    applyQualityDiscount: '&?',
    applyCheckLimit: '&?',
    setPurchasePrice: '&?',
    logisticsExportConfig: '<?',
    view: '<?',
    selectedTotals: '<?',
    allowRefreshCheckboxes: '<?',
    readonly: '<?',
    applyFilters: '&',
    openQualitiesModal: '&?',
    deleteEventSource: '<?',
    addLogistic: '&?',
    activeReportConfig: '<?',
    initQueryParams: '<?',
    showAllOnInvoicing: '<?',
  },
  template: template,
  controller: LogisticsTableController,
};
