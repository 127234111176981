import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').directive('passportsPassportItem', directive);

  function directive() {
    return {
      template: require('./passport-item.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        passport: '<',
        mode: '<?', // 'plan' or 'fact'
        updateList: '<?',
        currency: '<',
        passportMode: '@?',
        filterLevel: '<',
        queryParams: '<',
        tab: '<?',
        updatePassportData: '&?',
        createVoyage: '&?',
        updateFactsByExecution: '&?',
        updateVolumeFromExportExecution: '&?',
        updateData: '&?',
        createContractFromTickets: '&?',
        changeTab: '&?',
        hideWizard: '<?',
        hideInfo: '<?',
      },
    };
  }

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$window',
    'ContractsService',
    'LogisticsService',
    'ClientsService',
    'DocumentsService',
    'gtFilterService',
    'FinancesService',
    'FactPlListService',
    'GtUtils',
    'gettext',
    '$httpParamSerializer',
  ];
  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    ContractsService: ContractsService,
    LogisticsService: any,
    ClientsService: any,
    DocumentsService: any,
    gtFilterService: GtFilterService,
    FinancesService: FinancesService,
    FactPlListService: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    $httpParamSerializer: any,
  ) {
    const vm = this;

    vm.expanded = false;
    vm.openPassportModal = openPassportModal;
    vm.openFinanceModal = openFinanceModal;
    vm.openContractModal = openContractModal;
    vm.hideContracts = false;
    vm.showTotals = false;

    vm.connecting = false;
    vm.subtab = 'list';
    vm.updatePassport = updatePassport;
    vm.sum = sum;
    vm.currency_symbol = 'USD';
    vm.changeCurrency = changeCurrency;

    vm.convert = convert;
    vm.getValueByCurrency = getValueByCurrency;
    vm.getNetPrice = getNetPrice;
    vm.curSign = curSign;
    vm.counterparty_vat = false;
    vm.showResultPlan = true;
    vm.showResultFact = true;
    vm.expandInfo = false;

    vm.salePlans = [];
    vm.purchasePlans = [];
    vm.saleFacts = [];
    vm.purchaseFacts = [];
    vm.eventsPlan = [];
    vm.eventsFact = [];
    vm.derivativePnL = [];

    vm.voyages = [];
    vm.voyagesCount = 0;
    vm.updateVoyages = updateVoyages;
    vm.connect = connect;
    vm.processEvents = [];
    vm.afterContractClone = afterContractClone;
    vm.saleDocuments = [];
    vm.purchaseDocuments = [];
    vm.intermediateDocuments = [];
    vm.openPrintWindow = openPrintWindow;
    vm.updatePassportResult = updatePassportResult;
    vm.shareQualityRules = shareQualityRules;
    vm.isUpdating = false;

    vm.resultSubtab = 'consolidated';
    vm.docsSubtab = 'contracts';

    vm.tabFields = {
      all: [
        'status',
        'title',
        'crop_title',
        'total_volume_plan',
        'volume_options',
        'origin_of_crop',
        'destination_of_crop',
        'exchange_rate',
        'currency',
        'currency_symbol',
        'id',
        'sale_plans_count',
        'purchase_plans_count',
        'update_time',
        'editor_first_name',
        'create_time',
        'author_first_name',
        'editor_avatar',
        'total_volume_plan',
        'sale_plans_count',
        'volume_options',
        'purchase_plans_count',
        'id',
        'sale_facts_count',
        'purchase_facts_count',
      ],
      info: [],
      info_light: ['id', 'total_volume_purchase', 'conclusion_date', 'currency_symbol'],
      dashboard: ['id', 'status', 'currency_symbol', 'exchange_rate'],
      result: ['id', 'status', 'currency_symbol', 'exchange_rate'],
      plan: [
        'sale_plans',
        'purchase_plans',
        'sale_facts',
        'purchase_facts',
        'status',
        'currency_symbol',
        'id',
        'cargo',
      ],
      goods_sold: ['conclusion_date', 'cargo'],
      fact: ['id', 'status', 'cargo', 'payments_avg_trade_exchange_rate', 'voyage'],
      logistics: ['id', 'payments_avg_trade_exchange_rate'],
      costs: [
        'plan_costs_sale',
        'fact_costs_sale',
        'total_volume_plan',
        'total_charges_sale_plan',
        'total_charges_sale',
        'total_charges_purchase',
        'total_vat_costs_sale_plan',
        'total_vat_costs_sale',
        'plan_costs_purchase',
        'fact_costs_purchase',
        'delivery_costs_purchase',
        'delivery_penalties_purchase',
        'total_charges_purchase_plan',
        'total_vat_costs_purchase_plan',
        'total_vat_costs_purchase',
      ],
      documents: ['sale_facts', 'purchase_facts'],
      billoflading: ['voyage'],
    };
    vm.logisticsFilterLevel = 'logistics-container';
    vm.logisticsQueryParams = {};
    vm.actions = {
      info: () => updatePassportResult().then(updateVoyages),
      // @ts-ignore
      voyage: () => updateVoyages(),
      // @ts-ignore
      documents: () => updateVoyages(),
      result: () =>
        updatePassportResult().then(getDerivatinesPnL).then(updatePassportGroupedCharges),
      recap: () =>
        updatePassportResult()
          .then(updateTicketsInfo)
          .then(getContractInfo)
          .then(updatePassportGroupedCharges),
      logistics: () => updatePassportResult().then(updateVoyages),
      costs: () => updatePassportResult(),
    };

    vm.useValuesWithVat = true;
    vm.getConvertedValue = getConvertedValue;
    vm.getSwapedValue = getSwapedValue;
    vm.openVoyageModal = openVoyageModal;
    vm.downloadAllPassportDocuments = downloadAllPassportDocuments;
    vm.openEmailModal = openEmailModal;
    vm.openDocxModal = openDocxModal;
    vm.defaultCurrencySymbol = $rootScope.user.settings.DEFAULT_CURRENCY;

    vm.$onInit = function () {
      if ($rootScope.user.profile.wizard) {
        vm.hideWizard = false;
      } else {
        vm.hideWizard = true;
      }
      vm.currency = vm.currency || 'local';
      vm.mode = vm.mode || 'plan';
      vm.salePlansFilterLevel = 'sale-requests-passport';
      vm.purchasePlansFilterLevel = 'purchase-requests-passport';
      vm.saleFactsFilterLevel = 'sale-facts-passport';
      vm.intermediateFactsFilterLevel = 'intermediate-facts-passport';
      vm.exportFactsFilterLevel = 'export-facts-passport';
      vm.purchaseFactsFilterLevel = 'purchase-facts-passport';
      vm.incomingInvoicesFilterLevel = 'incoming-finances-passport';
      vm.outgoingInvoicesFilterLevel = 'outgoing-finances-passport';
      vm.financesFilterLevel = 'invoices-page-view';
      vm.billOfLadingFilterLevel = 'bill-of-lading-passport';
      vm.billOfLadingQueryParams = { passport: vm.passport.id };
      vm.paymentsQueryParams = { passport: vm.passport.id };
      vm.blQueryParams = { passport: vm.passport.id };
      vm.logisticsFilterLevel = 'logistics-container-passport';
      vm.transferQueryParams = { passport: vm.passport.id };
      vm.logisticsQueryParams = { passport: vm.passport.id };
      vm.updateList = vm.updateList || gtFilterService.updateQueryParams;
      vm.paymentsExportConfig = FinancesService.getPaymentsExportConfig();
      if ($rootScope.user.settings.PASSPORT_INVOICE_FILTER_BY_LOGISTICS) {
        vm.incomingFinancesQueryParams = {
          passport_by_logistics: vm.passport.id,
          invoice_type: 'incoming',
        };
      } else {
        vm.incomingFinancesQueryParams = { passport: vm.passport.id, invoice_type: 'incoming' };
      }
      vm.outgoingFinancesQueryParams = { passport: vm.passport.id, invoice_type: 'outgoing' };
      vm.financeExtraData = {
        invoicePositionInitQueryParams: { passport: vm.passport.id },
      };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (_ev: any, data: any) {
        vm.queryParams = data;
        vm.tab =
          vm.queryParams.tab ||
          $rootScope.user.settings.PASSPORT_DETAILS_DEFAULT_TAB ||
          'dashboard';
      });

      gtFilterService.setQueryParams(vm.paymentsQueryParams, vm.paymentsExportConfig);

      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (_ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      vm.modelName = 'passport';
      vm.controlsObjectId = vm.passport.id;
      vm.controlsFilterLevel = 'passports-bill-of-lading-controls';
      if (!vm.hideInfo) {
        updatePassportResult();
        // @ts-ignore
        updateVoyages();
      }
    };

    function openPrintWindow() {
      $window.print();
    }

    function updateData() {
      // @ts-ignore
      return updatePassport();
    }

    function updatePassportResult() {
      GtUtils.overlay('show');
      vm.isUpdating = true;
      return ContractsService.Passport.result({ id_list: [vm.passport.id] })
        .$promise.then((data: any) => {
          vm.passport.results = data.results[0];
          if (vm.currency == 'local' && vm.passport.results.currency_symbol) {
            vm.currency_symbol = vm.passport.results.currency_symbol;
          } else {
            vm.currency_symbol = 'USD';
          }
        })
        .catch((err: any) => GtUtils.errorClb(err))
        .finally(() => {
          GtUtils.overlay('hide');
          vm.isUpdating = false;
        });
    }

    function updatePassportGroupedCharges() {
      return ContractsService.Passport.groupedCharges(
        { passport_id: vm.passport.id },
        function (data: any) {
          vm.passport.groupedCharges = data;
        },
      );
    }

    function updatePassport(tab: any) {
      if (tab) {
        vm.tab = tab;
      }

      if (Array.isArray(vm.tab)) {
        vm.tab.forEach((t: any) => vm.actions[t]?.());
      } else {
        vm.actions[vm.tab]?.();
      }
    }

    function getDerivatinesPnL() {
      vm.isUpdating = true;
      GtUtils.overlay('show');
      return FactPlListService.getPassportDerivativeMargin(vm.passport.id)
        .then((data: any) => {
          vm.passport.results.derivativeMargin = data;
          return data;
        })
        .catch(GtUtils.errorClb)
        .finally(() => {
          vm.isUpdating = false;
          GtUtils.overlay('hide');
        });
    }

    function afterContractClone(contract: any) {
      if (!contract || contract == 'cancel') {
        return false;
      }
      if (vm.passport.voyage) {
        LogisticsService.Voyage.get({ id: vm.passport.voyage }, function (data: any) {
          const contracts = data[contract.contract_type + '_contracts'];
          if (contracts.indexOf(contract.id) < 0) {
            contracts.push(contract.id);
            data[contract.contract_type + '_contracts'] = contracts;
            LogisticsService.Voyage.update(data);
          }
        });
      }
      return ContractsService.connectToPassport({
        id: vm.passport.id,
        deal_id: contract.id,
        stage: 'contract',
        type: contract.contract_type,
        volume: contract.volume,
      });
    }

    function updateVoyages(params: any) {
      params = { ...params };
      params.passport = vm.passport.id;
      return LogisticsService.Voyage.query(params, function (data: any) {
        vm.voyages = data.results;
        vm.voyagesCount = data.count;
      }).$promise;
    }

    function updateTicketsInfo() {
      const saleParams = {
        contract_type: 'sale',
        with_passport: vm.passport.id,
        stage: 'ticket',
      };
      const purchaseParams = {
        contract_type: 'purchase',
        with_passport: vm.passport.id,
        stage: 'ticket',
      };
      ContractsService.Request.queryInfo(saleParams, (data: any) => {
        vm.saleTickets = data.results;
      });
      ContractsService.Request.queryInfo(purchaseParams, (data: any) => {
        vm.purchaseTickets = data.results;
      });
      ContractsService.Request.totalInfo(saleParams, function (data: any) {
        vm.saleTotals = data;
      });
      ContractsService.Request.totalInfo(purchaseParams, function (data: any) {
        vm.purchaseTotals = data;
      });
    }

    function getContractInfo() {
      ContractsService.Contract.query(
        {
          with_passport: vm.passport.id,
        },
        (data: any) => {
          vm.contracts = data.results;
        },
      );
    }

    function openPassportModal(tab: any) {
      if (tab) {
        vm.tab = 'info';
      }
      return ContractsService.passportModal(vm.passport, { tab: tab || 'info' }).then(function (
        data: any,
      ) {
        if (tab) {
          vm.tab = tab;
        }
        if (!data || data == 'cancel') {
          return false;
        } else {
          vm.passport = data;
          vm.updateList();
          updatePassportResult();
        }
      });
    }

    function connect(contract: any) {
      vm.connecting = true;
      if (contract.stage == 'contract') {
        const factResource =
          (contract.contract_type == 'sale' && ContractsService.SaleFact) ||
          ContractsService.PurchaseFact;
        factResource.save(
          {
            volume: contract.volume,
            contract: contract.id,
            passport: vm.passport.id,
          },
          function () {
            vm.connecting = false;
            GtUtils.notify(gettext('Fact created'));
          },
          function (data: any) {
            vm.connecting = false;
            GtUtils.errorClb(data);
          },
        );
      } else {
        const planResource =
          (contract.contract_type == 'sale' && ContractsService.PassportSalePlan) ||
          ContractsService.PassportPurchasePlan;
        planResource.save(
          {
            volume: contract.volume,
            request: contract.id,
            passport: vm.passport.id,
          },
          function () {
            vm.connecting = false;
            GtUtils.notify(gettext('Plan created'));
          },
          function (data: any) {
            vm.connecting = false;
            GtUtils.errorClb(data);
          },
        );
      }
    }

    function sum(operands: any) {
      let result = 0;
      ng.forEach(operands, function (operand: any) {
        result += parseFloat(operand);
      });
      return result;
    }

    function convert(value: any) {
      value = parseFloat(value);
      return (vm.currency == 'usd' && value) || value * (vm.passport.exchange_rate || 1);
    }

    function getValueByCurrency(usd: any, local: any) {
      if (vm.currency.toLowerCase() == 'usd') {
        return usd;
      } else {
        return local;
      }
    }

    function calculateNetPrice(
      contractAvgPrice: any,
      contractAvgPriceLocal: any,
      costAvgPrice: any,
      costAvgPriceLocal: any,
    ) {
      return (
        vm.getValueByCurrency(contractAvgPrice, contractAvgPriceLocal) -
          vm.getValueByCurrency(costAvgPrice, costAvgPriceLocal) || 0
      );
    }

    function calculatePurchaseNetPrice(
      contractAvgPrice: any,
      contractAvgPriceLocal: any,
      costAvgPrice: any,
      costAvgPriceLocal: any,
    ) {
      return (
        vm.getValueByCurrency(contractAvgPrice, contractAvgPriceLocal) +
          vm.getValueByCurrency(costAvgPrice, costAvgPriceLocal) || 0
      );
    }

    function getPlanSaleNetPrice() {
      return calculateNetPrice(
        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_plans_avg_price_analytical_without_vat) ||
          vm.passport.results.sale_plans_avg_price_without_vat,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_plans_avg_price_analytical_without_vat_local) ||
          vm.passport.results.sale_plans_avg_price_without_vat_local,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_plans_avg_cost_price_analytical) ||
          vm.passport.results.sale_plans_avg_cost_price,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_plans_avg_cost_price_analytical_local) ||
          vm.passport.results.sale_plans_avg_cost_price_local,
      );
    }

    function getPlanPurchaseNetPrice() {
      return calculatePurchaseNetPrice(
        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_plans_avg_price_analytical_without_vat) ||
          vm.passport.results.purchase_plans_avg_price_without_vat,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_plans_avg_price_analytical_without_vat_local) ||
          vm.passport.results.purchase_plans_avg_price_without_vat_local,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_plans_avg_cost_price_analytical) ||
          vm.passport.results.purchase_plans_avg_cost_price,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_plans_avg_cost_price_analytical_local) ||
          vm.passport.results.purchase_plans_avg_cost_price_local,
      );
    }

    function getFactSaleNetPrice() {
      return calculateNetPrice(
        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_facts_avg_price_analytical_without_vat) ||
          vm.passport.results.sale_facts_avg_price_without_vat,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_facts_avg_price_analytical_without_vat_local) ||
          vm.passport.results.sale_facts_avg_price_without_vat_local,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_facts_avg_cost_price_analytical) ||
          vm.passport.results.sale_facts_avg_cost_price,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.sale_facts_avg_cost_price_analytical_local) ||
          vm.passport.results.sale_facts_avg_cost_price_local,
      );
    }

    function getFactPurchaseNetPrice() {
      return calculatePurchaseNetPrice(
        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_facts_avg_price_analytical_without_vat) ||
          vm.passport.results.purchase_facts_avg_price_without_vat,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_facts_avg_price_analytical_without_vat_local) ||
          vm.passport.results.purchase_facts_avg_price_without_vat_local,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_facts_avg_cost_price_analytical) ||
          vm.passport.results.purchase_facts_avg_cost_price,

        ($rootScope.user.settings.RESULT_PASSPORT_ANALYTICAL_PRIORITY_TICKETS &&
          vm.passport.results.purchase_facts_avg_cost_price_analytical_local) ||
          vm.passport.results.purchase_facts_avg_cost_price_local,
      );
    }

    function getNetPrice(contractType: any, passportConnectionType: any) {
      if (passportConnectionType === 'fact') {
        if (contractType === 'purchase') {
          return getFactPurchaseNetPrice();
        } else {
          return getFactSaleNetPrice();
        }
      } else if (contractType === 'purchase') {
        return getPlanPurchaseNetPrice();
      } else {
        return getPlanSaleNetPrice();
      }
    }

    function changeCurrency(currency: any) {
      vm.currency = currency;
      if (vm.currency == 'usd') {
        vm.currency_symbol = 'USD';
      } else {
        vm.currency_symbol = vm.passport.results.currency_symbol;
      }
    }

    function curSign() {
      return (vm.currency == 'usd' && '$') || vm.passport.currency_symbol;
    }

    function openContractModal(contract: any) {
      ContractsService.contractModal(contract)
        .then((data: any) => {
          if (!data || data == 'cancel') {
            return;
          }
          vm.updateData();
        })
        .catch(GtUtils.errorClb);
    }

    function openFinanceModal() {
      return FinancesService.financeModal({}, vm.financeExtraData).then(updateData);
    }

    function shareQualityRules(contractId: any) {
      if (
        !confirm(gettext('Are you sure that you want copy quality rules for all purchase facts?'))
      ) {
        return false;
      }
      GtUtils.overlay('show');
      return ContractsService.Contract.shareQualityRules({
        contract_id: contractId,
        passport_id: vm.passport.id,
      }).$promise.then(() => {
        GtUtils.overlay('hide');
        GtUtils.notify(gettext('Updated'));
      });
    }

    function getConvertedValue(amountFieldName: any, vatFieldName: any) {
      if (!vm.passport.results) {
        return 0;
      }

      const amount = getValueByCurrency(
        vm.passport.results[amountFieldName],
        vm.passport.results[amountFieldName + '_local'],
      );
      const vatValue = getValueByCurrency(
        vm.passport.results[vatFieldName],
        vm.passport.results[vatFieldName + '_local'],
      );

      return vm.useValuesWithVat ? amount : amount - (vatValue || 0);
    }

    function getSwapedValue(amountFieldName: any, withVatAmountFieldName: any) {
      if (!vm.passport.results) {
        return 0;
      }

      const withVatAmount = getValueByCurrency(
        vm.passport.results[withVatAmountFieldName],
        vm.passport.results[withVatAmountFieldName + '_local'],
      );
      const amount = getValueByCurrency(
        vm.passport.results[amountFieldName],
        vm.passport.results[amountFieldName + '_local'],
      );

      return vm.useValuesWithVat ? withVatAmount : amount;
    }

    function openVoyageModal(passportId: any) {
      return LogisticsService.voyageModal({}, { passportId }).then((data: any) => {
        if (!data || data == 'cancel') {
          return;
        }
        updateData();
      });
    }

    function downloadAllPassportDocuments() {
      const queryParams = $httpParamSerializer({ passport_id: vm.passport.id });
      $window.open('/api/passports/passports/full_document_download/?' + queryParams, '_blank');
    }

    function openEmailModal() {
      return ClientsService.sendEmailModal('Passport', vm.passport.id);
    }
    function openDocxModal() {
      return DocumentsService.generateDocxModal('Passport', vm.passport.id);
    }
  }
})();
