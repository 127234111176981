import React from 'react';

import { ThreeDotsIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { Preset } from '../../types';
import { CreatePreset } from '../create-preset';
import { FilterButton } from '../filter-button';
import { UpdatePreset } from '../update-preset';

export const PresetActions: React.FC<{
  preset: Preset;
  presetOptions: Preset[];
  onSave: (preset: Preset) => void;
}> = ({ preset, presetOptions, onSave }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <FilterButton intent={preset.id ? 'default' : 'empty'} rounded="right">
          <ThreeDotsIcon
            size={14}
            className={
              preset?.id.trim() ? 'text-text-section-secondary' : 'text-stroke-main-stronger'
            }
          />
        </FilterButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex w-[180px] flex-col gap-0.5 p-1">
        <CreatePreset preset={preset} onSave={onSave} />
        <UpdatePreset preset={preset} presetOptions={presetOptions} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
