import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('execution.legacy').component('voyagesContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      addButton: '@?',
      view: '<?',
    },
    template: require('./voyages-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$window',
    'gettext',
    'CoreService',
    'LogisticsService',
    'GtUtils',
    'gtFilterService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    $q: ng.IQService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    CoreService: CoreService,
    LogisticsService: any,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.voyages = [];
    vm.$rootScope = $rootScope;
    vm.timelineChartItems = [];
    vm.inlineOrderingParams = [];
    vm.voyagesCount = 0;
    vm.updateVoyages = updateVoyages;
    vm.updateVoyagesEta = updateVoyagesEta;
    vm.openVoyageModal = openVoyageModal;
    vm.applyFilters = applyFilters;
    vm.updateStatus = updateStatus;

    ////////////////

    vm.$onInit = function () {
      if ($rootScope.isDeviceMobile) {
        vm.view = 'block';
      } else {
        vm.view = ['list', 'block'].includes($rootScope.user.profile.voyages_view)
          ? $rootScope.user.profile.voyages_view
          : vm.view || 'table';
      }

      vm.filterLevel = vm.filterLevel || 'voyages-container';
      vm.savedFilterChoices = CoreService.getSavedFilterChoices(vm.filterLevel);
      vm.queryParams = { show_uncompleted: 1, ...this.initQueryParams };
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateVoyages();
      });
      $scope.$on('voyages-update', function (ev: any, data: any) {
        gtFilterService.updateQueryParams(data, vm.filterLevel);
      });
      updateVoyages();
      updateOrdering();
    };

    function updateOrdering() {
      vm.inlineOrderingParams = [
        { title: 'Charter party (date)', value: 'cp_date' },
        { title: 'ETA', value: 'eta' },
        { title: 'ETC', value: 'etc' },
      ];
    }

    function applyFilters() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateVoyages() {
      vm.timelineChartItems = [];
      GtUtils.overlay('show');
      return LogisticsService.Voyage.query(vm.queryParams, function (data: any) {
        vm.voyages = data.results;
        vm.voyagesCount = data.count;
        vm.timelineChartItems = data.results
          .filter((item: any) => item.eta || item.eta_destination)
          .map((item: any) => ({
            id: item.id.toString(),
            start: item.eta || item.eta_destination,
            end: item.eta_destination || item.eta,
            name: item.name,

            popupHtml: ` Loading port ${item.port_loading_name}<br />
                       Destination port ${item.port_destination_name}<br />
                       <span><a href="#/logistics/voyages/${item.id}">
                       Details <i class="fa fa-arrow-right"></i></a></span> `,
          }));

        GtUtils.overlay('hide');
      }).$promise;
    }

    function updateVoyagesEta(data: any) {
      GtUtils.overlay('show');
      let chain = $q.when();
      data
        .map((item: any) => {
          return vm.voyages
            .filter((voyage: any) => voyage.id == item.id)
            .reduce((acc: any, voyage: any) => {
              acc[voyage.id] = {
                ...voyage,
                id: item.id,
                eta: item.start,
                eta_destination: item.end,
              };
              return acc;
            }, {});
        })
        .forEach((item: any) => {
          chain = chain.then(() => LogisticsService.Voyage.update(item).$promise);
        });

      chain.then(() => {
        GtUtils.overlay('hide');
        updateVoyages();
      });
    }

    function openVoyageModal() {
      return LogisticsService.voyageModal({ ...vm.queryParams }).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        updateVoyages();
      });
    }

    function updateStatus() {
      GtUtils.overlay('show');
      return LogisticsService.Voyage.updateStatus({}, () => {
        updateVoyages();
        GtUtils.notify(gettext('Voyage status updated'));
        GtUtils.overlay('hide');
      });
    }
  }
})();
