import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './budgets-container.html?raw';

export const BudgetsContainer = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'BudgetsService',
    'gtFilterService',
    'gettext',
    'GtUtils',
    class {
      $scope: ng.IScope;
      BudgetsService: any;
      GtUtils: GtUtilsService;
      filterLevel = '';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams & { year: number } = {
        year: new Date().getFullYear(),
        page_size: 200,
        with_totals: true,
      };
      showFacts: any;
      tableApi: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        BudgetsService: any,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.BudgetsService = BudgetsService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
        this.showFacts = false;
      }

      $onInit() {
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateFinanceAccounts();
        });
        this.updateFinanceAccounts();
      }

      updateFinanceAccounts() {
        this.GtUtils.overlay('show');
        return this.BudgetsService.getFilledFinanceAccounts(this.queryParams).then((data: any) => {
          this.tableApi.setRowData(data.results);
          this.tableApi.setRowCount(data.count);
          this.tableApi.setTotalData(data.totals);
          this.GtUtils.overlay('hide');
        });
      }

      showWithFacts() {
        this.tableOptions.templateArgs.showFacts = true;
        this.gtFilterService.updateQueryParams({ with_facts: true }, this.filterLevel);
      }

      nextYear() {
        this.queryParams.year++;
        this.updateFinanceAccounts();
      }

      prevYear() {
        this.queryParams.year--;
        this.updateFinanceAccounts();
      }

      getQuarterCellTemplate(q: any) {
        return `
        <span class="label bigger-label">
          {[{ (item['q${q}'].plan_amount | currency) || '--' }]}
        </span>
        <div ng-if="args.showFacts">
          <hr style="margin:0;">
          <span class="label bigger-label">
            {[{ (item['q${q}'].fact_amount | currency) || '--' }]}
          </span>
        </div>
      `;
      }
      getQuarterTotalsTemplate(q: any) {
        return `
        <span class="label label_success">
          <i class="fa fa-plus"></i>
        </span>
        <span class="label positive-number">
          {[{ (item['q${q}'].income_amount | currency) || '--' }]}
        </span>
        <div class="clearfix"></div>
        <span class="label label_danger">
          <i class="fa fa-minus"></i>
        </span>
        <span class="label negative-number">
          {[{ (item['q${q}'].expense_amount | currency) || '--' }]}
        </span>
        <div ng-if="args.showFacts">
          <hr style="margin:0;">
          <span class="label label_success">
            <i class="fa fa-plus"></i>
          </span>
          <span class="label positive-number">
          <i class="fa fa-credit-card-alt"></i>
            {[{ (item['q${q}'].income_amount_fact | currency) || '--' }]}
          </span>
          <div class="clearfix"></div>
          <span class="label label_danger">
            <i class="fa fa-minus"></i>
          </span>
          <span class="label negative-number">
          <i class="fa fa-credit-card-alt"></i>
            {[{ (item['q${q}'].expense_amount_fact | currency) || '--' }]}
          </span>
        </div>
      `;
      }

      getTableOptions() {
        const options: any = {
          tableName: 'requests',
          tableClass: ['request-table', 'budget-table'],
          filterLevel: this.filterLevel,
          templateArgs: {
            edit: false,
            showFacts: false,
          },
          columnDefs: [] as any[],
          tabs: [],
          rowData: [],
          rowCount: 0,
          totalsData: {},
          totalsRow: true,
          onInit: (api: any) => (this.tableApi = api),
        };

        options.columnDefs = [
          {
            columnName: 'title',
            title: this.gettext('Account'),
            classExpr: 'item.cell_class',
            cellTemplate: `
          <i class="fa fa-sort-up" ng-repeat="i in item.level_arr track by $index"></i>
          <span class="label label_success" ng-if="item.budget_account_type == 'income'">
            <i class="fa fa-plus"></i>
          </span>
          <span class="label label_danger" ng-if="item.budget_account_type == 'expense'">
            <i class="fa fa-minus"></i>
          </span>
          {[{ item.title }]}
        `,
            totalTemplate: '<i class="fa fa-pie-chart"></i><translate>TOTAL</translate>',
          },
        ];

        const monthColumns = Array.from({ length: 12 }, (v, k) => ({
          columnName: `month_${k + 1}`,
          classExpr: 'item.cell_class',
          title: this.gettext(this.getMonthNameByDate(new Date(2000, k))),
          cellTemplate: `
          <div ng-if="!args.edit || item.descendant_count">
            {[{ (item.budget_items[${k}].plan_amount | currency) || '--' }]}
            <span class="label" ng-if="item.budget_items[${k}].diff_plan_prc" ng-class="{ 'label_success':  item.budget_account_type == 'income', 'label_danger':  item.budget_account_type == 'expense'  }">
              {[{ item.budget_items[${k}].diff_plan_prc | gtDecimal:1 }]}%
            </span>
            <div ng-if="args.showFacts">
              <hr style="margin:0;"><i class="fa fa-credit-card" ng-if="item.budget_items[${k}].fact_amount"></i>  {[{ (item.budget_items[${k}].fact_amount | currency) || '--' }]}
              <span class="label" ng-if="item.budget_items[${k}].diff_fact_prc" ng-class="{ 'label_success':  item.budget_account_type == 'income', 'label_danger':  item.budget_account_type == 'expense'  }">
              {[{ item.budget_items[${k}].diff_fact_prc | gtDecimal:1 }]}%
              </span>
            </div>
          </div>
          <div ng-if="args.edit && !item.descendant_count">
            <input class="form-control" type="number" ng-model="item.budget_items[${k}].plan_amount">
          </div>
        `,
          totalTemplate: `
          <span class="label label_success">
            <i class="fa fa-plus"></i>
          </span>
          <span class="label positive-number">
            {[{ (item[${k + 1}].income_amount | currency) || '--' }]}
          </span>
          <div class="clearfix"></div>
          <span class="label label_danger">
            <i class="fa fa-minus"></i>
          </span>
          <span class="label negative-number">
            {[{ (item[${k + 1}].expense_amount | currency) || '--' }]}
          </span>
          <div class="clearfix"></div>
          <span class="label label_success" ng-if="item[${k + 1}].diff_amount > 0">
            <i class="fa fa-plus"></i> {[{ (item[${k + 1}].diff_amount | currency) || '--' }]}
          </span>
          <span class="label label_danger" ng-if="item[${k + 1}].diff_amount < 0">
            <i class="fa fa-minus"></i> {[{ (item[${k + 1}].diff_amount | currency) || '--' }]}
          </span>

          <div ng-if="$ctrl.showFacts">
            <hr style="margin:0;">
            <span class="label label_success">
              <i class="fa fa-plus"></i>
            </span>
            <span class="label positive-number">
              {[{ (item[${k + 1}].income_amount_fact | currency) || '--' }]}
            </span>
            <div class="clearfix"></div>
            <span class="label label_danger">
              <i class="fa fa-minus"></i>
            </span>
            <span class="label negative-number">
              {[{ (item[${k + 1}].expense_amount_fact | currency) || '--' }]}
            </span>
            <div class="clearfix"></div>
            <span class="label label_success" ng-if="item[${k + 1}].diff_amount_fact > 0">
              <i class="fa fa-plus"></i> {[{ (item[${
                k + 1
              }].diff_amount_fact | currency) || '--' }]}
            </span>
            <span class="label label_danger" ng-if="item[${k + 1}].diff_amount_fact < 0">
              <i class="fa fa-minus"></i> {[{ (item[${
                k + 1
              }].diff_amount_fact | currency) || '--' }]}
            </span>
          </div>

        `,
        }));
        options.columnDefs = options.columnDefs.concat(monthColumns);

        options.columnDefs.splice(4, 0, {
          columnName: 'q1',
          title: this.gettext('1Q'),
          classExpr: 'item.cell_class',
          cellTemplate: this.getQuarterCellTemplate(1),
          totalTemplate: this.getQuarterTotalsTemplate(1),
        });
        options.columnDefs.splice(8, 0, {
          columnName: 'q2',
          title: this.gettext('2Q'),
          classExpr: 'item.cell_class',
          cellTemplate: this.getQuarterCellTemplate(2),
          totalTemplate: this.getQuarterTotalsTemplate(2),
        });
        options.columnDefs.splice(12, 0, {
          columnName: 'q3',
          title: this.gettext('3Q'),
          classExpr: 'item.cell_class',
          cellTemplate: this.getQuarterCellTemplate(3),
          totalTemplate: this.getQuarterTotalsTemplate(3),
        });
        options.columnDefs.splice(16, 0, {
          columnName: 'q4',
          title: this.gettext('TOTAL'),
          classExpr: 'item.cell_class',
          cellTemplate: this.getQuarterCellTemplate(4),
          totalTemplate: this.getQuarterTotalsTemplate(4),
        });
        return options;
      }

      getMonthNameByDate(date: any) {
        const locale = 'en-us';
        return date.toLocaleString(locale, { month: 'long' });
      }

      cancelEdit() {
        this.tableOptions.templateArgs.edit = false;
        this.updateFinanceAccounts();
      }

      saveItems() {
        this.tableOptions.templateArgs.edit = false;
        this.GtUtils.overlay('show');
        this.BudgetsService.saveBudgetItemsFromAccount(this.tableOptions.api.getRowData()).then(
          () => {
            this.updateFinanceAccounts();
            this.GtUtils.overlay('hide');
          },
        );
      }
    },
  ],
};
