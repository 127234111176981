import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './loyalty-program-execution-list-container.html?raw';

export const LoyaltyProgramExecutionListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'LoyaltyProgramsService',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      LoyaltyProgramsService: any;
      data: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams & { year: number } = { year: new Date().getFullYear() };
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        LoyaltyProgramsService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.LoyaltyProgramsService = LoyaltyProgramsService;
        this.gtFilterService = gtFilterService;

        this.filterLevel = '';
        this.data = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'loyalty-program-execution-list-container';
        this.queryParams = {
          year: new Date().getFullYear(),
          page_size: 5,
          ...this.initQueryParams,
        };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.LoyaltyProgramsService.getTableDataExecutionReport(this.queryParams).then(
          (data: any) => {
            this.data = data;
            this.GtUtils.overlay('hide');
          },
        );
      }

      getDateRange(start: any, end: any) {
        return this.LoyaltyProgramsService.getDateRange(start, end);
      }

      updateQueryParams(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      nextYear() {
        this.queryParams.year++;
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      prevYear() {
        this.queryParams.year--;
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
    },
  ],
};
