import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('financeAccountActivityPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./finance-account-activity-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'FinancesService'];

  function Controller(this: any, PageService: PageService, FinancesService: any) {
    const vm = this;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'finance-client-details-page-view';
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-payments-tab',
        buttons: [
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Filters(),
        ],
        pages: FinancesService.getPagesConfig(),
      };

      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [],
        clicked: false,
        search: true,
        dates: true,
        dateSelects: [],
        multiSelects: [],
        nestedSelects: [],
        nestedMultiSelects: [],
      };
      return config;
    }
  }
})();
