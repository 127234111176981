import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

export const clientDetailsPage = {
  bindings: {
    filterLevel: '<?',
  },
  template: require('./client-details-page.html?raw'),
  controller: [
    '$stateParams',
    'ClientsService',
    'ClientFilterConfigService',
    'PageService',
    class {
      $stateParams: ng.ui.IStateParamsService;
      ClientFilterConfigService: any;
      ClientsService: any;
      PageService: PageService;
      client: any;
      filterLevel = 'client-side-list';
      updateData: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        ClientsService: any,
        ClientFilterConfigService: any,
        PageService: PageService,
      ) {
        this.$stateParams = $stateParams;
        this.ClientsService = ClientsService;
        this.ClientFilterConfigService = ClientFilterConfigService;
        this.PageService = PageService;

        this.client = {};
      }

      $onInit() {
        this.client = { id: this.$stateParams.id };
        if (this.$stateParams.action || this.$stateParams.id == 'new') {
          const id = (parseInt(this.$stateParams.id, 10) && this.$stateParams.id) || null;
          return this.ClientsService.clientModal({ id: id }).then(() => this.updateData());
        }
      }

      updateConfigWithFilterLevel(client: any, filterLevel: string) {
        const config = this.PageService.getConfig();
        let isOpen;
        if (ng.equals(config, {}) || config.sidebar?.is_open) {
          isOpen = true;
        } else {
          isOpen = false;
        }
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            sidebar: {
              verboseName: 'Counterparties',
              modelName: 'clients.client',
              instance: client,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: 'Counterparties',
              modelName: 'clients.client',
              instance: client,
              filterLevel: filterLevel,
            },
            filters: this.ClientFilterConfigService.getFilterConfig(filterLevel),
            pages: [],
          });
        }
      }
    },
  ],
};
