import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './bank-accounts-widget.html?raw';

export const BankAccountsWidget = {
  template,
  bindings: {
    initQueryParams: '<?',
    bankAccounts: '<',
    paymentInfo: '<',
  },
  controller: [
    '$scope',
    '$rootScope',
    'FinancesService',
    'gettext',
    'GtUtils',
    class {
      $scope: ng.IScope;
      $rootScope: GtRootScopeService;
      FinancesService: FinancesService;
      gettext: ng.gettext.gettextFunction;
      GtUtils: GtUtilsService;
      queryParams: QueryParams = {};
      bankAccounts: any;
      paymentInfo: any;
      showAdding: boolean;
      showEditing: boolean;
      newBankAccountArgs: any;
      currencySelected: any;
      newBankAccount: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        FinancesService: FinancesService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.FinancesService = FinancesService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.queryParams = {};
        this.bankAccounts = [];
        this.paymentInfo = null;
        this.showAdding = false;
        this.showEditing = false;
        this.newBankAccountArgs = [];
      }

      $onInit() {
        this.bankAccounts = this.bankAccounts || [];
        this.queryParams = { ...(this as any).initQueryParams };
        this.getInitialNewBankAccount();

        this.$scope.$watch(
          '$ctrl.paymentInfo',
          (newPaymentInfo: any, oldPaymentInfo: any) => {
            if (newPaymentInfo && newPaymentInfo !== oldPaymentInfo) {
              this.paymentInfo = newPaymentInfo;
              this.getInitialNewBankAccount();
            }
          },
          true,
        );

        this.currencySelected = (bankAccount: any) => {
          if (bankAccount?.currency) {
            return this.FinancesService.Currency.get({ id: bankAccount.currency }).$promise.then(
              (response: any) => {
                bankAccount.currency = response.id;
                bankAccount.currency_symbol = response.symbol;
              },
            );
          }
        };
      }

      addBankAccount() {
        this.bankAccounts.unshift(this.newBankAccount);
        this.$scope.$emit('bankAccountsUpdated', this.bankAccounts);
        this.getInitialNewBankAccount();
      }

      getInitialNewBankAccount() {
        this.newBankAccount = {
          bank_name: this.paymentInfo?.bank_name || this.paymentInfo?.financebank_name || null,
          account_name: this.paymentInfo?.account || null,
        };

        this.newBankAccountArgs = [this.newBankAccount];
      }

      removeElement(element: any) {
        const index = this.bankAccounts.indexOf(element);
        if (index !== -1) {
          this.bankAccounts.splice(index, 1);
        }

        if (element.id) {
          this.FinancesService.BankAccount.delete({ id: element.id }).$promise.then(() => {
            this.GtUtils.notify(this.gettext('Bank account deleted successfully'));
            this.$scope.$emit('bankAccountsUpdated', this.bankAccounts);
          });
        }
      }
    },
  ],
};
