import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './contracts-consignee-widget.html?raw';

export const ContractsConsigneeWidget = {
  bindings: {
    data: '<?',
    contract: '<?',
    onChange: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      contract: any;
      data: any;
      gettext: ng.gettext.gettextFunction;
      newObj: any;
      onChange: any;
      queryParams: QueryParams = {};
      constructor($scope: ng.IScope, GtUtils: GtUtilsService, gettext: ng.gettext.gettextFunction) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = {
          id_list: this.contract?.contract_prices?.length
            ? this.contract.contract_prices.map((price: any) => price.cargo)
            : [this.contract.cargo],
        };
        this.data = this.data || [];
        this.newObj = { volume: 0 };
      }

      saveItem() {
        this.data.push({ ...this.newObj, contract: this.contract.id });
        this.onChange(this.data);
      }

      removeElement(element: any) {
        const index = this.data.indexOf(element);
        this.data.splice(index, 1);
        this.onChange(this.data);
      }
    },
  ],
};
