import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

export const StatisticsReportPage = {
  bindings: {
    filterLevel: '<',
    queryParams: '<?',
  },
  template: `
      <statistics-report-container
        filter-level="$ctrl.filterLevel"
        init-query-params="$ctrl.queryParams"
        >
      </statistics-report-container>
    `,

  controller: [
    'PageService',
    'ReportsService',
    class {
      PageService: PageService;
      ReportsService: any;
      filterLevel = 'statistics-report-page';
      constructor(PageService: PageService, ReportsService: any) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };
      }
    },
  ],
};
