import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('bankAccountsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      tableExpanded: '<?',
      addButton: '<?',
    },
    template: require('./bank-accounts-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'FinancesService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.bankAccounts = [];
    vm.bankAccountsCount = 0;
    vm.updateBankAccounts = updateBankAccounts;
    vm.queryParams = { ...vm.initQueryParams };
    vm.newBankAccount = { ...vm.queryParams };
    vm.quickAdd = false;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'bank-accounts-container';
      vm.queryParams = { page_size: 1000, ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateBankAccounts();
      });
      updateBankAccounts();
    };

    vm.$onChanges = function () {
      vm.queryParams = { ...vm.initQueryParams };
    };

    ////////////////

    function updateBankAccounts() {
      GtUtils.overlay('show');
      vm.newBankAccount = { ...vm.queryParams };
      return FinancesService.BankAccount.query(vm.queryParams, function (data: any) {
        vm.bankAccounts = data.results;
        vm.bankAccountsCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }
  }
})();
