import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('updatesGeneralContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      mainView: '<?',
      subscribeOnUpdates: '&',
      isDetailPage: '<?',
    },
    template: require('./updates-general-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope', '$scope', 'gtFilterService'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.upcomingQueryParams = {};
    vm.overdueQueryParams = {};
    vm.completedQueryParams = {};
    vm.allTasksQueryParams = {};
    vm.autoCreatedQueryParams = {};

    vm.applyAuthorFilter = applyAuthorFilter;
    vm.applyResponsibleFilter = applyResponsibleFilter;
    vm.applyTodayFilter = applyTodayFilter;
    vm.applyUpcomingFilter = applyUpcomingFilter;
    vm.applyOverdueFilter = applyOverdueFilter;
    vm.applyCompletedFilter = applyCompletedFilter;
    vm.onUpdate = onUpdate;
    vm.mainView = undefined;
    vm.overdueFilterApplied = false;
    vm.upcomingFilterApplied = false;
    vm.completedFilterApplied = false;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'updates-table-container';
      vm.mainView = $rootScope.user.settings.DEFAULT_TASKS_VIEW || 'columns';
      applyAuthorFilter();
      vm.upcomingQueryParams = {
        is_autocreated: '0',
        is_upcoming: '1',
        ordering: 'future_contact',
        is_future: '1',
        ...vm.initQueryParams,
      };
      vm.overdueQueryParams = {
        is_autocreated: '0',
        is_upcoming: '1',
        ordering: 'future_contact',
        is_future: '0',
        ...vm.initQueryParams,
      };
      vm.completedQueryParams = {
        is_autocreated: '0',
        is_upcoming: '0',
        ordering: '-update_time',
        ...vm.initQueryParams,
      };
      vm.autoCreatedQueryParams = {
        is_autocreated: '1',
        ordering: '-update_time',
        ...vm.initQueryParams,
      };
      vm.allTasksQueryParams = {
        is_autocreated: '0',
        ordering: '-update_time',
        ...vm.initQueryParams,
      };
      vm.autoCreatedQueryParams = {
        is_autocreated: '1',
        ordering: '-update_time',
        ...vm.initQueryParams,
      };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (_: any, data: any) {
        vm.upcomingQueryParams = {
          is_autocreated: '0',
          is_upcoming: '1',
          ordering: 'future_contact',
          is_future: '1',
          ...data,
        };
        vm.overdueQueryParams = {
          is_autocreated: '0',
          is_upcoming: '1',
          ordering: 'future_contact',
          is_future: '0',
          ...data,
        };
        vm.completedQueryParams = {
          is_autocreated: '0',
          is_upcoming: '0',
          ordering: '-update_time',
          ...data,
        };
        vm.allTasksQueryParams = {
          is_autocreated: '0',
          ordering: '-update_time',
          ...data,
        };
        vm.autoCreatedQueryParams = {
          is_autocreated: '1',
          ordering: '-update_time',
          ...data,
        };
        gtFilterService.setQueryParams(vm.upcomingQueryParams, vm.filterLevel + '-upcoming');
        gtFilterService.setQueryParams(vm.overdueQueryParams, vm.filterLevel + '-overdue-table');
        gtFilterService.setQueryParams(
          vm.completedQueryParams,
          vm.filterLevel + '-completed-table',
        );
        gtFilterService.setQueryParams(vm.allTasksQueryParams, vm.filterLevel + '-all-tasks-table');
        gtFilterService.setQueryParams(
          vm.autoCreatedQueryParams,
          vm.filterLevel + '-auto-created-table',
        );
      });
      gtFilterService.setQueryParams(vm.initQueryParams, vm.filterLevel);
    };

    ////////////////

    function onUpdate() {
      gtFilterService.updateQueryParams({}, vm.filterLevel);
    }

    function applyAuthorFilter() {
      if (vm.initQueryParams.author) {
        vm.initQueryParams.author = undefined;
      } else {
        vm.initQueryParams.author = $rootScope.user.id;
      }
      gtFilterService.updateQueryParams(vm.initQueryParams, vm.filterLevel);
    }

    function applyResponsibleFilter() {
      if (vm.initQueryParams.responsible_user_list) {
        vm.initQueryParams.responsible_user_list = undefined;
      } else {
        vm.initQueryParams.responsible_user_list = [$rootScope.user.id];
      }
      gtFilterService.updateQueryParams(vm.initQueryParams, vm.filterLevel);
    }

    function applyTodayFilter() {
      if (vm.initQueryParams.is_today) {
        vm.initQueryParams.is_today = undefined;
      } else {
        vm.initQueryParams.is_today = '1';
      }
      gtFilterService.updateQueryParams(vm.initQueryParams, vm.filterLevel);
    }

    function setQueryParams() {
      const filterStates = {
        100: { is_upcoming: '1', is_future: '1' },
        10: { is_upcoming: '1', is_future: '0' },
        110: { is_upcoming: '1', is_future: undefined },
        11: { is_future: '0', is_upcoming: undefined },
        101: { is_future: '1', is_upcoming: undefined },
        1: { is_upcoming: '0', is_future: undefined },
        0: { is_upcoming: undefined, is_future: undefined },
      };

      const stateKey =
        +`${+vm.overdueFilterApplied}${+vm.upcomingFilterApplied}${+vm.completedFilterApplied}`;
      return filterStates[stateKey] || { is_upcoming: undefined, is_future: undefined };
    }

    function updateFilters() {
      const queryParams = setQueryParams();
      vm.initQueryParams.is_upcoming = queryParams.is_upcoming;
      vm.initQueryParams.is_future = queryParams.is_future;
      gtFilterService.updateQueryParams(vm.initQueryParams, vm.filterLevel);
    }

    function applyOverdueFilter() {
      vm.overdueFilterApplied = !vm.overdueFilterApplied;
      updateFilters();
    }

    function applyUpcomingFilter() {
      vm.upcomingFilterApplied = !vm.upcomingFilterApplied;
      updateFilters();
    }

    function applyCompletedFilter() {
      vm.completedFilterApplied = !vm.completedFilterApplied;
      updateFilters();
    }
  }
})();
