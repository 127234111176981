import React from 'react';

import { cn } from '~/shared/lib/utils';

export const TextView: React.FC<{
  key: string;
  label: { text: string; context: string };
}> = ({ key, label }) => (
  <p key={key} className={cn('text-xs font-medium', label?.context)}>
    {label?.text}
  </p>
);
