import React from 'react';

import { AddFileIcon, CloseIcon } from '~/shared/ui/icons';
import {
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { CreatePresetForm } from './create-preset-form';
import type { Preset } from '../../types';

export const CreatePreset: React.FC<{ preset: Preset; onSave: (preset: Preset) => void }> = ({
  preset,
  onSave,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  return (
    <DropdownMenuSub open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownMenuSubTrigger className="trasition flex cursor-pointer items-center gap-2 px-2 py-1 duration-300 ease-in-out hover:bg-transparent-light">
        <AddFileIcon size={14} className="text-text-main-secondary" />
        <p className="text-xs text-text-main-primary">Save as New Preset</p>
      </DropdownMenuSubTrigger>
      <DropdownMenuSubContent className="flex flex-col gap-2 p-0" sideOffset={2}>
        <div className="flex items-center gap-2 border-b border-stroke-main-medium px-2 pb-2 pt-1">
          <CloseIcon
            size={14}
            className="cursor-pointer text-text-main-tertiary transition duration-300 ease-in-out hover:opacity-70"
            onClick={() => setIsDropdownOpen(false)}
          />
          <p className="text-xs font-medium text-text-main-primary">New Preset</p>
        </div>
        <CreatePresetForm preset={preset} onSave={onSave} />
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  );
};
