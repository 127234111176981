import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations',
)({
  component: () => (
    <div>
      Hello
      /_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/sale-export-contract-allocations!
    </div>
  ),
});
