import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Input, type InputProps } from '~/shared/ui/kit/input';

import { messageIntent } from '../../lib/constants';
import { type DataTypeProps } from '../../lib/types';

export const NumberWrite: React.FC<DataTypeProps<number> & InputProps> = ({
  value,
  valueChanged,
  ref,
  disabled,
  message,
  ...props
}) => (
  <div className={cn('p-1', message && messageIntent[message.type])}>
    <Input
      intent="transparent"
      data-testid="number-field"
      type="number"
      ref={ref}
      onBlur={(e) => valueChanged?.(Number(e.target.value))}
      defaultValue={value}
      disabled={disabled}
      fullWidth
      {...props}
    />
  </div>
);
