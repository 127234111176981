import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('bankAccountsPageView', {
    bindings: {
      queryParams: '<?',
    },
    template: require('./bank-accounts-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['FinancesService', 'PageService', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    FinancesService: FinancesService,
    PageService: PageService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.filterLevel = 'bank-accounts-page-view';
    vm.updateBankAccounts = updateBankAccounts;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-payments-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Import('finances.bankaccount'),
          new PageService.buttons.Export('finances.bankaccount', vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: FinancesService.getPagesConfig(),
      };

      // @ts-ignore
      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [
          {
            predicate: 'update_time',
            title: gettext('UPDATED'),
          },
          {
            predicate: 'create_time',
            title: gettext('CREATED'),
          },
          {
            predicate: 'initial_date',
            title: gettext('INITIAL DATE'),
          },
        ],
        clicked: false,
        search: true,
        dates: true,
        dateSelects: [
          {
            argument: 'initial_date',
            placeholder: gettext('Date'),
          },
        ],
        selects: [
          {
            argument: 'payment_info',
            placeholder: gettext('payment info'),
            resource: 'finances.PaymentInfo',
          },
          {
            argument: 'currency',
            placeholder: gettext('currency'),
            resource: 'finances.Currency',
          },
          {
            argument: 'business_unit',
            placeholder: gettext('business unit'),
            resource: 'core.BusinessUnit',
          },
          {
            argument: 'client_role',
            placeholder: gettext('owner'),
            resource: 'clients.ClientRole',
          },
          {
            argument: 'bank_name_list',
            placeholder: gettext('Bank'),
            resource: 'finances.FinanceBank',
          },
          {
            argument: 'account_name',
            placeholder: gettext('Account'),
            resource: 'finances.PaymentInfo',
          },
        ],
        nestedSelects: [
          {
            argument: 'status',
            placeholder: gettext('Status'),
            items: [
              {
                id: 'valid',
                title: gettext('Valid'),
              },
              {
                id: 'invalid',
                title: gettext('Invalid'),
              },
            ],
          },
          {
            argument: 'account_type',
            placeholder: gettext('Type'),
            items: [
              {
                id: 'cash',
                title: gettext('Cash'),
              },
              {
                id: 'regular',
                title: gettext('Regular'),
              },
              {
                id: 'cryptocurrency',
                title: gettext('Cryptocurrency'),
              },
            ],
          },
        ],
      };

      return config;
    }

    function updateBankAccounts() {
      GtUtils.overlay('show');
      return FinancesService.BankAccount.query(vm.queryParams, function (data: any) {
        vm.bankAccounts = data.results;
        vm.bankAccountsCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }
  }
})();
