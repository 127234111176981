import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BankAccountActivityModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'GtUtils',
    'gtFilterService',
    'queryParams',
    'gettext',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    queryParams: QueryParams,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.GtUtils = GtUtils;
    vm.GtFilterService = gtFilterService;
    vm.applyFilter = applyFilter;
    vm.resetFilter = resetFilter;
    vm.close = close;
    vm.queryParams = queryParams;
    vm.filterLevel = 'bank-account-activity-modal';
    vm.startDate = undefined;
    vm.endDate = undefined;
    vm.selectedClientroles = [];
    vm.selectedInvoices = [];

    ////////////////

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function applyFilter() {
      const filters: any = {
        start_date_field: vm.GtUtils.convertDate(vm.startDate, 'YYYY.MM.DD'),
        end_date_field: vm.GtUtils.convertDate(vm.endDate, 'YYYY.MM.DD'),
        clientrole_list: vm.selectedClientroles,
        invoice_list: vm.selectedInvoices,
      };
      vm.GtFilterService.updateQueryParams(filters, vm.filterLevel);
    }

    function resetFilter() {
      vm.startDate = undefined;
      vm.endDate = undefined;
      vm.selectedClientroles = undefined;
      vm.selectedInvoices = undefined;
      applyFilter();
    }
  }
})();
