import ng from 'angular';

import { AnalyticsContainer } from './analytics-container/analytics-container.component';
import { AnalyticsPageView } from './analytics-page-view/analytics-page-view.component';
import { MarketAnalyticsService } from './analytics.service';
import { MarketElevatorPrices } from './market-elevator-prices/market-elevator-prices.component';
import { MarketPortPrices } from './market-port-prices/market-port-prices.component';

export const analytics = ng
  .module('deals.market.analytics', [])
  .component('marketAnalyticsContainer', AnalyticsContainer)
  .component('marketAnalyticsPageView', AnalyticsPageView)
  .component('marketElevatorPrices', MarketElevatorPrices)
  .component('marketPortPrices', MarketPortPrices)
  .service('MarketAnalyticsService', MarketAnalyticsService).name;
