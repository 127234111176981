import ng from 'angular';

import { warehouseLossesPageView } from './warehouse-losses-page-view/warehouse-losses-page-view.component';

const losesConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.warehouseLosses', {
      url: '/warehouse/losses/?id',
      component: 'warehouseLossesPageView',
      data: {
        pageTitle: gettext('Warehouse Losses'),
        permissions: {
          only: 'view_logistic',
        },
      },
      resolve: {
        filterLevel: () => 'warehouse-losses-page-view',
        queryParams: [
          'PageService',
          '$stateParams',
          (Service: any, $stateParams: ng.ui.IStateParamsService) => {
            const params = Service.syncUrlFilter('warehouse-losses-page-view');
            if ($stateParams.id) {
              params.id_list = [$stateParams.id];
            }
            return params;
          },
        ],
      },
    });
  },
];

export const warehouseLosses = ng
  .module('execution.warehouse.warehouseLosses', [])
  .component('warehouseLossesPageView', warehouseLossesPageView)
  .config(losesConfig).name;
