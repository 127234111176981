import React from 'react';

import { NumberWrite } from '~/shared/ui/data-types';

import type { FilterTypeProps } from '../types';

export const NumberFilter: React.FC<FilterTypeProps> = ({ filterValue, onChange }) => {
  return (
    <NumberWrite
      value={parseInt(filterValue.values[0], 10)}
      intent="default"
      valueChanged={(input) =>
        onChange({ ...filterValue, values: [input ? input.toString() : ''] })
      }
    />
  );
};
