import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './event-subscription-modal.html?raw';

export const EventSubscriptionModal = {
  bindings: {
    modalInstance: '<',
    eventSubscription: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'GtUtils',
    'gettext',
    'NotificationService',
    'CoreService',
    class {
      $rootScope: GtRootScopeService;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      NotificationService: any;
      eventSubscription: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      selectedEvent: any;
      systemEvents: any;
      constructor(
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        NotificationService: any,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.NotificationService = NotificationService;
        this.CoreService = CoreService;

        this.modalInstance = undefined;
        this.eventSubscription = {};

        this.systemEvents = {};
        this.form = undefined;
        this.fields = [];
        this.selectedEvent = undefined;
      }

      $onInit() {
        this.NotificationService.getSystemEvents().then((data: any) => {
          this.systemEvents = data.data;
          this.rebuildForm();
          if (this.eventSubscription.id) {
            this.updateData();
          } else {
            this.setDefaultTemplate();
          }
        });
      }

      rebuildForm() {
        this.selectedEvent = this.systemEvents[this.eventSubscription.action];
        this.fields = this.getFormConfig().fieldsDef;
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.NotificationService.getEventSubscription(this.eventSubscription.id).then(
          (response: any) => {
            this.eventSubscription = response;
            this.eventSubscription.filters = this.eventSubscription.filters.map((filter: any) => {
              if (filter.value.includes(';')) {
                filter.value = filter.value.split(';').map((val: any) => Number(val));
              } else {
                filter.value = [Number(filter.value)];
              }
              return filter;
            });
            if (!this.eventSubscription.message_template) {
              this.setDefaultTemplate();
            }
            this.GtUtils.overlay('hide');
          },
        );
      }

      setDefaultTemplate() {
        this.eventSubscription.message_template = undefined;
        this.eventSubscription.message_template_name = undefined;
        if (this.eventSubscription.action) {
          this.NotificationService.EventSubscriptionTemplateResource.query(
            { event_action: this.eventSubscription.action },
            (res: any) => {
              if (res.results[0]) {
                this.eventSubscription.message_template = res.results[0].id;
                this.eventSubscription.message_template_name = res.results[0].title;
              }
            },
          );
        }
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      delete() {
        if (confirm(this.gettext('Are you sure?'))) {
          return this.NotificationService.deleteEventSubscription(this.eventSubscription.id).then(
            () => this.close('delete', true),
          );
        }
      }

      save() {
        this.eventSubscription.filters = this.eventSubscription.filters
          .filter((i: any) => i.value)
          .map((filter: any) => {
            if (Array.isArray(filter.value)) {
              filter.value = filter.value.join(';');
            }
            return filter;
          });

        let waitingFilters: any = [];
        if (!this.eventSubscription.id && this.eventSubscription.filters) {
          waitingFilters = this.eventSubscription.filters;
        }
        return this.NotificationService.saveEventSubscription(this.eventSubscription).then(
          (data: any) => {
            this.eventSubscription = data;
            if (waitingFilters.length) {
              this.eventSubscription.filters = waitingFilters;
              return this.save();
            }
            this.close(data, true);
          },
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      makeFilterRepresentation() {
        return (x: any) => {
          let type = `${x} [${this.selectedEvent.filters[x]}]`;
          if (Array.isArray(this.selectedEvent.filters[x])) {
            type = `${x} [${this.selectedEvent.filters[x].map((i: any) => i.name).join(', ')}]`;
          }
          return { field: x, type: type };
        };
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-md-6 col-xs-12',
          fieldGroup: [],
        };

        const col2: any = {
          className: 'form-group-container col-md-6 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('CONFIGURATION'),
          },
          fieldGroup: [
            {
              key: 'action',
              type: 'gt-select',
              templateOptions: {
                label: this.gettext('System Event'),
                valueProp: 'action',
                labelProp: 'title',
                required: true,
                onSelected: () => {
                  this.eventSubscription.filters = [];
                  if (this.eventSubscription) {
                    this.setDefaultTemplate();
                  }
                  this.rebuildForm();
                },
                options: Object.keys(this.systemEvents).map((k) => ({
                  action: k,
                  title: `[${this.systemEvents[k].model}] ${this.systemEvents[k].title}`,
                })),
              },
            },
            {
              key: 'active',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('Active'),
              },
            },
            {
              key: 'system_notification',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('SYSTEM NOTIFICATION'),
              },
            },
            {
              key: 'extra_actions',
              type: 'gt-multiselect',
              templateOptions: {
                label: this.gettext('Extra actions/buttons'),
                wrapperclass: 'multiselect',
                options: [
                  { id: 'vote', title: this.gettext('✅Approve/❌Decline') },
                  { id: 'comment', title: this.gettext('💬 Add comment') },
                  { id: 'open', title: this.gettext('🔗 Open in browser') },
                ],
              },
            },
            {
              key: 'telegram_chat',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Telegram Chat'),
                placeholder: this.gettext('Telegram Chat'),
                resource: 'notifications.telegramchat',
                title: this.eventSubscription.telegram_chat_name,
                queryParams: () => {
                  return { user: this.eventSubscription.user, active: '1' };
                },
              },
            },
            {
              key: 'email',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Email'),
                type: 'text',
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('TEMPLATE'),
          },
          fieldGroup: [
            {
              key: 'message_template',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Message Template'),
                placeholder: this.gettext('Message Template'),
                resource: 'notifications.eventsubscriptiontemplate',
                title: this.eventSubscription.message_template_name,
                required: true,
                queryParams: () => {
                  return { event_action: this.eventSubscription.action };
                },
              },
              hideExpression: (viewValue: any, modelValue: any, scope: any) => !scope.model.action,
            },
          ],
        });

        if (this.selectedEvent?.filters) {
          const filtersGroup = {
            wrapper: 'gt-panel',
            templateOptions: {
              label: this.gettext('FILTERS'),
            },
            fieldGroup: [
              {
                key: 'add_filter',
                type: 'gt-select',
                templateOptions: {
                  label: this.gettext('Add filter'),
                  valueProp: 'field',
                  labelProp: 'type',
                  onSelected: () => {
                    this.eventSubscription.filters.push({
                      subscription: this.eventSubscription.id,
                      field: this.eventSubscription.add_filter,
                      value: '',
                    });
                    this.eventSubscription.add_filter = undefined;
                    this.rebuildForm();
                  },
                  options: (() => {
                    const options = Object.keys(this.selectedEvent.filters).map(
                      this.makeFilterRepresentation(),
                    );
                    return [{ field: undefined, type: this.gettext('Chose new filter...') }].concat(
                      options,
                    );
                  })(),
                },
              },
            ],
          };
          if (this.eventSubscription?.filters) {
            filtersGroup.fieldGroup = filtersGroup.fieldGroup.concat(
              this.eventSubscription.filters.map((filter: any, idx: any) => {
                const filterType = this.selectedEvent.filters[filter.field] || 'string';
                if (filterType.includes('.')) {
                  return {
                    key: `filters[${idx}].value`,
                    type: 'gt-ui-multiselect',
                    templateOptions: {
                      label: this.gettext('Filter by') + ` ${filter.field}`,
                      placeholder: this.gettext('Select') + ` ${filter.filterType}`,
                      resource: filterType,
                    },
                  };
                }

                if (typeof filterType === 'object') {
                  return {
                    key: `filters[${idx}].value`,
                    type: 'gt-select',
                    templateOptions: {
                      label: this.gettext('Filter by') + ` ${filter.field}`,
                      valueProp: 'value',
                      labelProp: 'name',
                      options: filterType,
                    },
                  };
                }

                return {
                  key: `filters[${idx}].value`,
                  type: filterType === 'boolean' ? 'gt-checkbox' : 'gt-input',
                  templateOptions: {
                    label: this.gettext('Filter by') + ` ${filter.field}`,
                    placeholder: this.gettext('Value'),
                    type: filterType,
                  },
                };
              }),
            );
          }
          col2.fieldGroup.push(filtersGroup);
        }
        return {
          formName: 'event-subscription-modal',
          fieldsDef: [col1, col2],
        };
      }
    },
  ],
};
