import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './costs-statistics-table.html?raw';

export const CostsStatisticsTable = {
  bindings: {
    filterLevel: '<',
    data: '<',
    savedFilterChoices: '<?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    'gettext',
    '$rootScope',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      Comments: any;
      GtUtils: GtUtilsService;
      amountMinusInvoiced: any;
      amountMinusPaid: any;
      chargeDetails: any;
      currencyShowDetails: any;
      filterLevel = 'costs-statistics-table';
      getIcon: any;
      gettext: ng.gettext.gettextFunction;
      invoicingRef: any;
      queryParams: QueryParams = {};
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.queryParams = {};
        this.getIcon = GtUtils.getIcon;
      }

      $onInit() {
        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (ev: any, queryParams: QueryParams) => {
            this.queryParams = queryParams;
          },
        );
        this.tableOptions = this.getTableOptions();
        this.invoicingRef = this.$state.href('finances.contractCharges');
      }

      updateTable() {
        this.tableOptions = this.getTableOptions();
      }

      get_unconnected_amount(row: any) {
        if (!row.contract_passport_list) {
          return 0;
        }
        return (
          row.price -
          row.contract_passport_list.reduce((res: any, curr: any) => res + (curr.amount || 0), 0)
        );
      }

      getTableOptions() {
        const options: any = {
          tableName: 'costs-statistics-table',
          filterLevel: this.filterLevel,
          columnDefs: [] as any[],
          tabs: [],
        };

        options.columnDefs = [
          {
            title: this.gettext('Cost counterparty'),
            columnName: 'client',
          },
        ];

        if (this.currencyShowDetails || this.chargeDetails) {
          options.columnDefs.push(
            {
              title: this.gettext('Status'),
              columnName: 'status',
            },
            {
              title: this.gettext('Contract'),
              columnName: 'contract',
            },
            {
              title: this.gettext('Passport'),
              columnName: 'passport',
            },
          );
        }

        if (this.Comments) {
          options.columnDefs.push(
            {
              title: this.gettext('Note'),
              columnName: 'additional_info',
            },
            {
              title: this.gettext('Trader note'),
              columnName: 'trader_note',
            },
          );
        }

        options.columnDefs.push({
          title: this.gettext('Exec forecasted amount'),
          columnName: 'price_usd',
        });

        if (!this.$rootScope.user.settings.SIMPLE_CHARGES) {
          options.columnDefs.push(
            {
              title: this.gettext('Our amount'),
              columnName: 'our_amount',
            },
            {
              title: this.gettext('Counterparty amount'),
              columnName: 'counterparty_amount',
            },
            {
              title: this.gettext('Analytical amount'),
              columnName: 'analytical_amount',
            },
          );
        }

        options.columnDefs.push(
          {
            title: this.gettext('invoiced'),
            columnName: 'invoiced_usd',
          },
          {
            title: this.gettext('invoiced cancelled'),
            columnName: 'invoiced_cancelled_usd',
          },
          {
            title: this.gettext('Payment'),
            columnName: 'paid_usd',
          },
        );

        if (!this.amountMinusInvoiced) {
          options.columnDefs.push({
            title: this.gettext('To be invoiced'),
            columnName: 'amount_minus_invoiced_usd',
          });
        }

        if (!this.amountMinusPaid) {
          options.columnDefs.push({
            title: this.gettext('Forecasted - Payment'),
            columnName: 'price_m_paid_usd',
          });
        }

        options.columnDefs.push({
          title: this.gettext('Invoices to be paid'),
          columnName: 'invoiced_paid_usd',
        });

        if (!this.$rootScope.user.settings.SIMPLE_CHARGES) {
          options.columnDefs.push({
            title: this.gettext('discount'),
            columnName: 'discount_usd',
          });
        }

        if (this.$rootScope.user.settings.RESULT_VAT) {
          options.columnDefs.push({
            title: this.gettext('Vat'),
            columnName: 'vat',
          });
        }

        return options;
      }
    },
  ],
};
