import ng from 'angular';

import { AnalyticsContainer } from './analytics-container/analytics-container.component';
import { AnalyticsPageView } from './analytics-page-view/analytics-page-view.component';
import { ContractsAnalyticsService } from './analytics.service';

const contractsAnalyticsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('contracts.analytics', {
      url: '/analytics',
      component: 'contractsAnalyticsPageView',
      data: { pageTitle: gettext('Analytics') },
    });
  },
];

export const analytics = ng
  .module('deals.contracts.analytics', [])
  .component('contractsAnalyticsContainer', AnalyticsContainer)
  .component('contractsAnalyticsPageView', AnalyticsPageView)
  .service('ContractsAnalyticsService', ContractsAnalyticsService)
  .config(contractsAnalyticsConfig).name;
