import React from 'react';

import { container } from '~/shared/lib/di';
import { useObservableEagerState } from '~/shared/lib/state';

import { deleteRecordsConfig } from '../lib/common-configs';
import { GlobalDialogStore } from '../services/global-dialog.store';

export const useDeleteRecordsDialog = <T>(config: { records: T[]; onDelete: () => void }) => {
  const globalDialogStore = React.useMemo(() => container.resolve(GlobalDialogStore), []);

  React.useEffect(() => {
    globalDialogStore.dialogConfigRegistered(
      deleteRecordsConfig({
        ...config,
        onCancel: () => globalDialogStore.dialogToggled({ value: false, id: 'deleteRecords' }),
      }),
    );
  }, [globalDialogStore, config]);

  const open = useObservableEagerState(globalDialogStore.dialogOpen$);

  return {
    open,
    dialogToggled: (open: boolean) =>
      globalDialogStore.dialogToggled({ value: open, id: 'deleteRecords' }),
  };
};
