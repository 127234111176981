import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('common.crops.legacy').directive('warehouseRow', directive);

  function directive() {
    return {
      template: require('./warehouse-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        warehouse: '<',
        crops: '<',
        filterLevel: '<',
        openWarehouseModal: '<',
      },
    };
  }

  Controller.$inject = ['GtUtils'];

  function Controller(this: any, GtUtils: GtUtilsService) {
    const vm = this;
    vm.GtUtils = GtUtils;
    vm.heapByCrop = heapByCrop;
    vm.getIcon = getIcon;

    ////////////////

    function heapByCrop(crop: any) {
      return vm.warehouse.heaps.filter((item: any) => item.cargo_id === crop.id).shift();
    }
    function getIcon() {
      return vm.GtUtils.getIcon(`warehouses.${vm.warehouse.warehouse_type}`);
    }
  }
})();
