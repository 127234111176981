import ng from 'angular';

import { PhysicalPositionService } from './physical-position.service';
import { RiskPositionOriginationContainer } from './risk-position-origination-container/risk-position-origination-container.component';
import { RiskPositionTerminalContainer } from './risk-position-terminal-container/risk-position-terminal-container.component';

export const physicalPosition = ng
  .module('reports.physicalPosition', ['finances.shared'])
  .service('PhysicalPositionService', PhysicalPositionService)
  .component('riskPositionTerminalContainer', RiskPositionTerminalContainer)
  .component('riskPositionOriginationContainer', RiskPositionOriginationContainer).name;
