import ng from 'angular';

import type { QueryParams } from '~/app/core/types';

import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtResourceMultiselect', {
    bindings: {
      ngModel: '=',
      resourceName: '<',
      queryParams: '<?',
      getQueryParams: '<?',
      allowClear: '<?',
      placeholder: '<',
      gtDisabled: '<?',
      updateOnEvent: '<?',
      required: '<?',
      onOpenClose: '&?',
      callOnSelect: '&?',
      callOnRemove: '&?',
    },
    template: require('./gt-resource-multiselect.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils'];

  function Controller(this: any, $scope: ng.IScope, GtUtils: GtUtilsService) {
    const vm = this;
    vm.items = [];
    vm.modelCopy = [];
    vm.updateItems = updateItems;
    vm.initLoad = true;
    vm.clear = clear;
    vm.isOpenHandler = isOpenHandler;

    vm.$onInit = function () {
      vm.ngModel = vm.ngModel || [];
      if (vm.updateOnEvent) {
        $scope.$on(vm.updateOnEvent, function () {
          updateItems();
        });
      }

      $scope.$watch('vm.ngModel', function (newVal: any, oldVal: any) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          updateSelectedItems();
        }
      });

      vm.ngModel.map((item: any, index: any) => {
        if (typeof item === 'string') {
          vm.ngModel[index] = parseInt(item, 10);
        }
      });

      getSavedItems();
    };

    vm.$onChanges = function () {
      initNgModel();
      vm.resourceName =
        (typeof vm.resourceName == 'function' && vm.resourceName()) || vm.resourceName;
      vm.resource = GtUtils.getResource(vm.resourceName);
      getSavedItems();
    };

    ////////////////
    function initNgModel() {
      vm.ngModel = vm.ngModel || [];
      if (vm.ngModel && !ng.isArray(vm.ngModel)) {
        vm.ngModel = [vm.ngModel];
      }
    }
    function getSavedItems() {
      if (vm.ngModel.length && vm.resourceName) {
        return GtUtils.getPredictions([vm.resourceName], vm.queryParams).then((data: any) => {
          vm.items = data[vm.resourceName];
          vm.modelCopy = [
            ...vm.ngModel.filter(
              (modelItem: any) => !vm.items.map((item: any) => item.id).includes(modelItem),
            ),
          ];
        });
      }
    }

    function updateItems(search?: string, force?: boolean) {
      if (vm.initLoad) {
        vm.initLoad = false;
        return updateSelectedItems();
      }

      let params = {};
      if (vm.getQueryParams) {
        params = vm.getQueryParams() as QueryParams;
      }
      if (vm.queryParams || search) {
        params = { ...(params ?? {}), ...vm.queryParams, search };
      }
      return GtUtils.getPredictions([vm.resourceName], params, undefined, force).then(
        (data: any) => {
          vm.items = data[vm.resourceName];
          if (!vm.items?.length) {
            return !force && updateItems(search, true);
          }
          updateSelectedItems();
        },
      );
    }
    function addItem(item: any) {
      vm.items.push({
        id: item.id,
        title: GtUtils.getObjectTitle(item),
      });
    }

    function updateSelectedItems() {
      initNgModel();
      const itemsIds = vm.items.map((item: any) => item.id);
      const modelIds = vm.ngModel.filter((itemId: any) => itemId && !itemsIds.includes(itemId));

      const selectedItems = vm.ngModel.filter((item: any) => itemsIds.includes(item));
      vm.modelCopy = vm.modelCopy.filter((modelItem: any) => !itemsIds.includes(modelItem));

      selectedItems.map((selectedItem: any) => {
        vm.modelCopy.push(selectedItem);
      });

      vm.ngModel = (vm.modelCopy.length && [...vm.modelCopy]) || vm.ngModel;

      if (!modelIds.length) {
        return;
      }
      if (vm.resource.predictions) {
        return vm.resource.predictions({ id: modelIds }, (data: any) =>
          data.results.forEach(addItem),
        );
      }
      modelIds.forEach((modelId: any) => vm.resource.get({ id: modelId }, addItem));
    }

    function clear() {
      vm.ngModel = [];
    }

    function isOpenHandler(isOpen: any) {
      return typeof vm.onOpenClose == 'function' && vm.onOpenClose({ isOpen: isOpen });
    }
  }
})();
