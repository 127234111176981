import ng from 'angular';

export const getModalRoot = () => {
  const el = document.querySelector('#ajs-root');

  if (!el) {
    throw new Error('Modal Root element (#ajs-root) not found');
  }
  return ng.element(el) as ng.IAugmentedJQuery;
};
