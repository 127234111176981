import { BehaviorSubject, tap } from 'rxjs';

import { singleton } from '~/shared/lib/di';
import { lsRead, lsWrite } from '~/shared/lib/utils';

import { type Theme, type ThemeSection } from '../lib';

const LS_KEY = 'gt-theme';

@singleton()
export class ThemeStore {
  private themeSubj = new BehaviorSubject<Theme>(lsRead(LS_KEY, 'light'));
  private sectionSubj = new BehaviorSubject<ThemeSection>('default');

  readonly theme$ = this.themeSubj.pipe(tap((theme) => lsWrite(LS_KEY, theme)));
  readonly section$ = this.sectionSubj.asObservable();

  public setTheme(theme: Theme) {
    this.themeSubj.next(theme);
  }

  public setSection(section: ThemeSection) {
    this.sectionSubj.next(section);
  }
}
