import ng from 'angular';

import { consignmentsPage } from './consignments-page/consignments-page.component';

const consignmentsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.consignments', {
      url: '/warehouse/consignments/?id',
      component: 'consignmentsPage',
      data: {
        pageTitle: gettext('Consignments'),
        permissions: {
          only: 'view_consignment',
        },
      },
      resolve: {
        filterLevel: () => 'consignments-page',
        queryParams: [
          'PageService',
          '$stateParams',
          (Service: any, $stateParams: ng.ui.IStateParamsService) => {
            const params = Service.syncUrlFilter('consignments-page');
            if ($stateParams.id) {
              params.id_list = [$stateParams.id];
            }
            return params;
          },
        ],
      },
    });
  },
];

export const consignments = ng
  .module('execution.warehouse.consignments', [])
  .component('consignmentsPage', consignmentsPage)
  .config(consignmentsConfig).name;
