export const calculatePageTotal = (totalCount: number, pageSize: number) =>
  Math.ceil(totalCount / pageSize);

export const calculatePaginationData = ({
  pageCurrent,
  pageSize,
  recordsTotal,
}: {
  pageCurrent: number;
  pageSize: number;
  recordsTotal: number;
}) => {
  const pageTotal = calculatePageTotal(recordsTotal, pageSize);
  const from = (pageCurrent - 1) * pageSize + 1;
  const to = Math.min(pageCurrent * pageSize, recordsTotal);
  const shouldShowPaginationButtonsBar = pageTotal > 1;

  return {
    pageTotal,
    from,
    to,
    shouldShowPaginationButtonsBar,
  };
};

export const calculatePaginationButtonsBar = ({
  pageCurrent,
  pageTotal,
}: {
  pageCurrent: number;
  pageTotal: number;
}) => {
  const rangeWithDots: (number | string)[] = [];
  let delta = 1;

  if (pageTotal <= 5) {
    for (let i = 1; i <= pageTotal; i++) {
      rangeWithDots.push(i);
    }
    return rangeWithDots;
  }

  if (pageCurrent === 1 || pageCurrent === pageTotal) {
    delta = 2;
  }

  rangeWithDots.push(1);

  if (pageCurrent > 2 + delta) {
    rangeWithDots.push('...');
  }

  for (
    let i = Math.max(2, pageCurrent - delta);
    i <= Math.min(pageTotal - 1, pageCurrent + delta);
    i++
  ) {
    rangeWithDots.push(i);
  }

  if (pageCurrent < pageTotal - 1 - delta) {
    rangeWithDots.push('...');
  }

  rangeWithDots.push(pageTotal);

  return rangeWithDots;
};

export const calculateSizeOptionsMaxPage = ({
  pageSizeOptions,
  recordsTotal,
  pageCurrent,
}: {
  pageSizeOptions: number[];
  recordsTotal: number;
  pageCurrent: number;
}) =>
  pageSizeOptions.reduce<Record<string, number>>((acc, option) => {
    acc[option] = Math.min(calculatePageTotal(recordsTotal, option), pageCurrent);
    return acc;
  }, {});

export const calculateCanClickPage = ({
  pageCurrent,
  pageTotal,
}: {
  pageCurrent: number;
  pageTotal: number;
}) => ({ canClickPreviousPage: pageCurrent > 1, canClickNextPage: pageCurrent < pageTotal });
