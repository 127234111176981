import React from 'react';

import type { CurrentUser } from '~/core/auth/lib';
import { Avatar } from '~/shared/ui/kit/avatar';

import { formatUserFallback } from './format-user-fallback';

export const UserAvatar: React.FC<{
  src: CurrentUser['avatar'];
  name: CurrentUser['name'];
}> = ({ name, src }) => {
  return (
    <span className="flex w-full cursor-pointer items-center gap-2 rounded-sm px-2 py-1">
      <Avatar src={src} alt="user-avatar" fallback={formatUserFallback(name)} />
      <p className="whitespace-nowrap text-xs font-medium text-text-main-secondary">{name}</p>
    </span>
  );
};
