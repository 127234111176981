import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

import template from './customs-cargo-declaration-list-table-view.html?raw';
import numberCellTemplate from './customs-cargo-declaration-nuber-cell-template.html?raw';

export const CustomsCargoDeclarationListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<?',
    applyFilters: '&',
    saveAll: '&?',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    onMenuHover: '&?',
    tableName: '<?',
    openModal: '&',
    readonly: '<?',
    invoiceCreatingModal: '<?',
  },
  template,
  controller: [
    'gettext',
    '$window',
    '$rootScope',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      CustomValuesService: any;
      applyFilters: any;
      count: number;
      destroy: any;
      filterLevel = 'customs-cargo-declaration-page-view';
      gettext: ng.gettext.gettextFunction;
      invoiceCreatingModal: any;
      list: any;
      numberInput: any;
      openModal: any;
      saveItem: any;
      selection: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      total: any;
      updateData: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
        CustomValuesService: any,
      ) {
        this.gettext = gettext;
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.CustomValuesService = CustomValuesService;
        this.invoiceCreatingModal = false;
        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
      }
      $onChanges(changes: any) {
        if (changes.list || changes.count || changes.total) {
          this.setTableOptions();
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }
      }

      clone(item: any) {
        Object.assign(this.tableData.rows[0], item, {
          author: undefined,
          create_time: undefined,
          editor: undefined,
          update_time: undefined,
          id: undefined,
        });
      }

      getUniquePositionsByField(positions: any, field: any) {
        const seen = {};
        if (positions && positions.length > 1) {
          return positions.filter((position: any) => {
            const value = position[field];
            if (value && !seen[value]) {
              seen[value] = true;
              return true;
            } else {
              return false;
            }
          });
        } else {
          return positions;
        }
      }

      getCustomFieldTableColumns() {
        return this.CustomValuesService.getCustomFieldTableColumns({
          purpose_model: 'customscargodeclaration',
        });
      }

      setTableOptions() {
        const options: any = {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: true,
          showMore: true,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            openModal: (item: any) => this.openModal({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            clone: (item: any) => this.clone(item),
            getUniquePositionsByField: (positions: any, field: any) =>
              this.getUniquePositionsByField(positions, field),
            updateData: () => this.updateData(),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          changePageSize: true,
          columnDefs: [
            {
              title: this.gettext('Type'),
              columnName: 'declaration_type',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="item.declaration_type === 'import'" class="label label-info">
                  {[{ 'import' | translate}]}
                </span>
                <span ng-if="item.declaration_type === 'export'" class="label label-default">
                  {[{ 'export' | translate}]}
                </span>
                <span ng-if="item.declaration_type === 'transit'" class="label label-danger">
                  {[{ 'transit' | translate}]}
                </span>
                <span ng-if="item.declaration_type === 'periodic'" class="label label-warning">
                  {[{ 'periodic' | translate}]}
                </span>
                `,
            },
            {
              title: this.gettext('Stage'),
              columnName: 'stage',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="item.stage === 'periodic'">{[{ 'PCD' | translate}]}</span>
                <span ng-if="item.stage === 'cargo'">{[{ 'CCD' | translate}]}</span>
                `,
            },
            {
              title: this.gettext('PCD'),
              columnName: 'from_declaration',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span># {[{ item.from_declaration_number || '---' }]}</span>
                `,
            },
            this.invoiceCreatingModal
              ? {
                  title: this.gettext('In number'),
                  columnName: 'number',
                  class: 'td-left-align',
                  cellTemplate: /*html*/ `
              <span class="label-strong">
                {[{ item.number }]}
              </span>`,
                  filters: [{ type: 'text', predicate: 'number' }],
                }
              : {
                  title: this.gettext('In number'),
                  columnName: 'number',
                  class: 'td-left-align',
                  cellTemplate: numberCellTemplate,
                  filters: [{ type: 'text', predicate: 'number' }],
                },
            {
              title: this.gettext('date'),
              columnName: 'date',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <i class="fa fa-calendar"></i> {[{ item.date | date:'dd.MM.yy' || '---'  }]}
              </span>`,
            },
            {
              title: this.gettext('Business unit'),
              columnName: 'business_unit',
              class: 'td-left-align',
              cellTemplate:
                /*html*/ "<span>{[{ item.business_unit_title | cut:true:30:''}]}</span>",
            },
            {
              title: this.gettext('Volume'),
              columnName: 'volume',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong">
                {[{ item.declaration_positions_volume || 0 | number: 3  }]}
                <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              </span>`,
            },
            {
              title: this.gettext('Total amount'),
              columnName: 'declaration_positions_amount_sum',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong">
                {[{ item.declaration_positions_amount_local_sum || 0 | number: 3  }]}
                <span class="smaller-label">{[{ item.currency_symbol }]}</span>
              </span>
              <span
                class="label-strong"
                ng-if="item.default_currency_symbol !== item.currency_symbol"
              >
                 / {[{ item.declaration_positions_amount_sum || 0 | number: 3  }]}
                <span class="smaller-label">{[{ item.default_currency_symbol }]}</span>
              </span>
            `,
            },
            {
              title: this.gettext('Commodity'),
              columnName: 'commodity',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li
                    ng-repeat="position in args.getUniquePositionsByField(
                      item.declaration_positions_data, 'commodity_title')"
                  >
                    <span>
                      {[{ position.commodity_title | cut:true:30:' '  }]}
                    </span>
                    <span ng-if="!position.commodity_title">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Quantity'),
              columnName: 'quantity',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="position in item.declaration_positions_data">
                    <span>
                      {[{ position.declaration_invoice_volume_part | number: 3  }]}
                      <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                    </span>
                    <span ng-if="!position.declaration_invoice_volume_part">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Price'),
              columnName: 'price',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="position in item.declaration_positions_data">
                    <span>
                      {[{ position.invposition_price_per_piece | number: 2  }]}
                      <span ng-if="position.invposition_price_per_piece" class="smaller-label">
                        {[{ item.currency_symbol }]}
                      </span>
                    </span>
                    <span ng-if="!position.invposition_price_per_piece">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Amount'),
              columnName: 'amount',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="position in item.declaration_positions_data">
                    <span>
                      {[{ position.invposition_amount_local | number: 2  }]}
                      <span ng-if="position.invposition_amount_local" class="smaller-label">
                        {[{ item.currency_symbol }]}
                      </span>
                    </span>
                    <span ng-if="!position.invposition_amount_local">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Currency'),
              columnName: 'currency',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <div class="td-left-align">
                  <span ng-if="item.currency_symbol"> {[{ item.currency_symbol }]} </span>
                  <span ng-if="item.exchange_rate"> {[{ item.exchange_rate | number:6 }]} </span>
                </div>`,
            },
            {
              title: this.gettext('Origin'),
              columnName: 'origin_of_commodity',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li
                    ng-repeat="position in args.getUniquePositionsByField(
                      item.declaration_positions_data, 'origin_of_commodity_title')"
                  >
                    <span ng-if="position.origin_of_commodity_title">
                      {[{ position.origin_of_commodity_title | cut:true:30:' '  }]}
                    </span>
                    <span ng-if="!position.origin_of_commodity_title">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Destination'),
              columnName: 'destination_of_commodity',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="item.destination_of_crop_title">
                  {[{ item.destination_of_crop_title || '---'  | cut:true:30:' ' }]}
                </span>
                <ul class="inside-table-ul">
                  <li
                    ng-repeat="position in args.getUniquePositionsByField(
                      item.declaration_positions_data, 'destination_of_commodity_title')"
                  >
                    <span ng-if="position.destination_of_commodity_title">
                      {[{ position.destination_of_commodity_title | cut:true:30:' '  }]}
                    </span>
                    <span ng-if="!position.destination_of_commodity_title">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Date of payment'),
              columnName: 'invoice_date_of_payment',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <div ng-repeat="invoice in item.invoices_data">
                  <span>
                    <i class="fa fa-calendar"></i>
                    {[{ invoice.date_of_payment | date:'dd.MM.yy' || '---' }]}
                    <span ng-if="!invoice.date_of_payment">---</span>
                  </span>
                  <span ng-if='invoice.date_of_payment'>
                    {[{ invoice.status }]}
                  </span>
                 </div>
              `,
            },
            {
              title: this.gettext('Border crossing date'),
              columnName: 'border_crossing_date',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span>
                  <i class="fa fa-calendar"></i> {[{ item.border_crossing_date | date:'dd.MM.yy' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'cd_type_title',
              class: 'td-left-align',
              cellTemplate: /*html*/ '{[{ item.cd_type_title }]}</span>',
              title: this.gettext('Type of CD'),
            },
            {
              title: this.gettext('Export contract price'),
              columnName: 'export_contract_price',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="contract in item.declaration_positions_data">
                    <span ng-if="contract.id"> {[{ contract.price || 0 | number:2 }]} </span>
                    <span ng-if="!contract.id">---</span>
                    <span ng-if="contract.price" class="smaller-label"
                      >{[{ item.currency_symbol }]}
                    </span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Export contract total'),
              columnName: 'export_contract_total',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span>
                  {[{ item.export_contract_total || 0 | number:2  }]}
                  <span class="smaller-label"
                    >{[{ item.currency_symbol }]}
                  </span>
                </span>
              `,
            },
            {
              title: this.gettext('Final date'),
              columnName: 'final_payment_date',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <div ng-repeat="invoice in item.invoices_data">
                  <span>
                    <i class="fa fa-calendar"></i>
                    {[{ invoice.final_payment_date | date:'dd.MM.yy' || '---' }]}
                    <span ng-if="!invoice.final_payment_date">---</span>
                  </span>
                  <span ng-if='invoice.final_payment_date'>
                    {[{ invoice.status }]}
                  </span>
                 </div>
              `,
            },
            {
              title: this.gettext('Days until final day'),
              columnName: 'invoice_days_until_final_day',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <div ng-repeat="invoice in item.invoices_data">
                  <span
                    ng-if="invoice.days_until_final_day !== null"
                    ng-class="{
                      'label label_danger': invoice.days_until_final_day <= 10
                    }"
                  >
                    {[{ invoice.days_until_final_day }]}
                    <span ng-if="invoice.days_until_final_day < 0">
                      {[{ 'Overdue' | translate}]}
                    </span>
                  </span>
                </div>
              `,
            },
            {
              title: this.gettext('Invoice'),
              columnName: 'invoice',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <div ng-repeat="invoice in item.invoices_data">
                  <a ui-sref="gt.page.payment({id: invoice.id })">
                    {[{ invoice.number || '---'  | cut:true:30:' ' }]}
                  </a>
                  <span class="label-strong">
                    {[{ invoice.amount || 0 | number: 2 }]}
                    <span class="smaller-label">{[{ item.currency_symbol }]}</span>
                  </span>
                </div>
              `,
            },

            {
              columnName: 'logistics',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <a
                  ng-if="item.logistics_count > 0"
                  ng-href="#/logistics/transport?customs_declaration_list={[{ item.id }]}"
                  target="_blank"
                >
                  <i class="fa fa-truck"></i>: {[{item.logistics_count}]}
                  <translate>Show details</translate>
                </a>
              </span>
              `,
              title: this.gettext('Logistics'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'sale_contract',
                  label: this.gettext('Sale'),
                  resource: 'contracts.SaleContract',
                },
              ],
            },
            {
              columnName: 'sale_contracts',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <ul>
                  <li ng-repeat="contract in item.sale_contracts_data">
                    <a ui-sref="gt.page.contract({id: contract.id })">
                      <i class="fa fa-arrow-up"></i>
                      {[{ contract.contract_number || '---'  | cut:true:30:' ' }]}
                    </a>
                  </li>
                </ul>
              </span>
              `,
              title: this.gettext('Sale contracts'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'sale_contract',
                  label: this.gettext('Sale'),
                  resource: 'contracts.SaleContract',
                },
              ],
            },
            {
              columnName: 'purchase_contracts',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <ul>
                  <li ng-repeat="contract in item.purchase_contracts_data">
                    <a ui-sref="gt.page.contract({id: contract.id })">
                      <i class="fa fa-arrow-down"></i>
                      {[{ contract.contract_number || '---'  | cut:true:30:' ' }]}
                    </a>
                  </li>
                </ul>
              </span>
              `,
              title: this.gettext('Purchase contracts'),
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'purchase_contract',
                  label: this.gettext('Sale'),
                  resource: 'contracts.PurchaseContract',
                },
              ],
            },
            {
              title: this.gettext('Passports'),
              columnName: 'passports',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <ul>
                  <li ng-repeat="passport in item.passports_data">
                    <a ui-sref="gt.page.passport({id: passport.id })">
                      <i class="fa fa-arrow-down"></i>
                      {[{ passport.title || '---'  | cut:true:30:' ' }]}
                    </a>
                  </li>
                </ul>
              </span>
              `,
            },
            {
              title: this.gettext('Voyages'),
              columnName: 'voyages',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <ul>
                  <li ng-repeat="voyage in item.voyages_data">
                    <i class="fa fa-signs-post"></i>
                    {[{ voyage.voyage_name || '---'  | cut:true:30:' ' }]}
                  </li>
                </ul>
              </span>
              `,
            },
            {
              title: this.gettext('Export contract position'),
              columnName: 'export_contract_position',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="contract in item.declaration_positions_data" target="_blank">
                    <span ng-if="contract.id">
                      <a ui-sref="gt.page.contract({id: contract.id })">
                        {[{ contract.contract_number || '---'  | cut:true:30:' ' }]}
                      </a>
                    </span>
                    <span ng-if="!contract.id">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Export contract'),
              columnName: 'export_contract',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="multicontract in item.multicontracts_data">
                    <span ng-if="multicontract.id">
                      <a ui-sref="gt.multicontract({id: multicontract.id })" target="_blank">
                        {[{ multicontract.number || '---'  | cut:true:30:' ' }]}
                      </a>
                    </span>
                    <span ng-if="!multicontract.id">---</span>
                  </li>
                </ul>
              `,
            },
            {
              title: this.gettext('Exporter'),
              columnName: 'exporter',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li
                    ng-repeat="position in args.getUniquePositionsByField(
                      item.declaration_positions_data, 'exporter_title')"
                  >
                    <span ng-if="position.id">
                      <i class="fa fa-person"></i> {[{ position.exporter_title || '---'  | cut:true:30:' '  }]}
                    </span>
                    <span ng-if="!position.id">---</span>
                  </li>
                </ul>`,
            },
            {
              title: this.gettext('Customs broker'),
              columnName: 'customs_broker',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="item.customs_broker_name">
                  <i class="fa fa-person"></i>
                  {[{ item.customs_broker_name || '---'  | cut:true:30:' ' }]}
                </span>
                <ul class="inside-table-ul">
                  <li
                    ng-repeat="position in args.getUniquePositionsByField(
                      item.declaration_positions_data, 'customs_broker_title')"
                  >
                     <span ng-if="position.id">
                      <i class="fa fa-person"></i>
                      {[{ position.customs_broker_title || '---'  | cut:true:30:' '  }]}
                    </span>
                    <span ng-if="!position.id">---</span>
                  </li>
                </ul>`,
            },
            {
              columnName: 'terminal',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span>
                <i class="fa fa-anchor"></i> {[{ item.terminal_title || '---'  | cut:true:30:' '  }]}
              </span>`,
              title: this.gettext('Terminal'),
            },
            {
              columnName: 'port',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span>
                  <i class="fa fa-anchor"></i> {[{ item.port_title || '---'  | cut:true:30:' '  }]}
                </span>`,
              title: this.gettext('Port'),
            },
            {
              columnName: 'result_status',
              class: 'td-left-align',
              title: this.gettext('Result status'),
              cellTemplate: /*html*/ `
                <span ng-class="{
                  'label label_success': item.result_status == 'approved',
                  'label label_danger': item.result_status == 'rejected'
                }"> {[{ item.result_status || '---' }]}</span>
                `,
            },
          ],
        };
        if (this.invoiceCreatingModal) {
          options.columnDefs.push({
            columnName: 'edit',
            class: 'td-left-align',
            cellTemplate: /*html*/ `
                <a
                    class="btn btn-xs aligned-btn btn-blue-border"
                    ng-click="args.openModal(item)"
                    ng-disabled="this.invoiceCreatingModal"
                >
                    <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                </a>`,
            title: this.gettext('Edit'),
          });
        }
        return this.getCustomFieldTableColumns().then((columns: any) => {
          options.columnDefs.push(...columns);
          this.tableOptions = options;
        });
      }
    },
  ],
};
