import type { DataViewProperties } from '~/features/data-view-fields/lib';
import type { ColumnParams } from '~/shared/api';
import { replaceUnderscoresWithSpaces, snakeCaseToCamelCase } from '~/shared/lib/utils';

export const fromColumnParamsListData = (properties: ColumnParams[]): DataViewProperties[] => {
  return properties.map((property) => ({
    key: snakeCaseToCamelCase(property.column_name),
    title: replaceUnderscoresWithSpaces({ input: property.column_name, capitalize: true }),
    visible: property.visible as unknown as boolean,
    position: property.index ?? 0,
    userTitle: property.new_title as unknown as string,
  }));
};
