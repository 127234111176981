import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').directive('bankAccountQuickEdit', directive);

  function directive() {
    return {
      template: require('./bank-account-quick-edit.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        bankAccount: '=?',
        quickAdd: '=?',
        onSave: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', 'FinancesService', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.save = save;
    vm.close = close;
    vm.errors = {};
    vm.contractParams = { stage: 'contract' };
    vm.saveDisabled = false;

    activate();

    ////////////////

    function activate() {
      vm.bankAccount = vm.bankAccount || {
        initial_cash: 0,
      };
      vm.onSave = vm.onSave || function () {};
      $scope.$watch('vm.bankAccount.payment_info', autocompleteBankAndAccount);
    }

    function close() {
      vm.quickAdd = false;
    }

    function autocompleteBankAndAccount() {
      if (!vm.bankAccount.payment_info) {
        return;
      }
      FinancesService.PaymentInfo.get({ id: vm.bankAccount.payment_info }).$promise.then(function (
        data: any,
      ) {
        vm.bankAccount.bank_name = data.financebank_name;
        vm.bankAccount.account_name = data.account;
      });
    }

    function save() {
      vm.saveDisabled = true;
      let hasErrors = false;

      if (!vm.bankAccount.currency) {
        vm.saveDisabled = false;
        hasErrors = true;
        GtUtils.notify(gettext('Currency is required'), 'error');
      }

      if (hasErrors) {
        return;
      }
      if (!vm.bankAccount.id) {
        return FinancesService.BankAccount.save(
          vm.bankAccount,
          function () {
            vm.bankAccount = {
              initial_cash: 0,
            };
            vm.onSave();
            vm.errors = {};
            vm.saveDisabled = false;
            GtUtils.notify(gettext('Bank account saved'));
          },
          _error,
        );
      }
      return FinancesService.BankAccount.update(
        vm.bankAccount,
        function () {
          vm.bankAccount = {
            initial_cash: 0,
          };
          vm.onSave();
          vm.errors = {};
          vm.saveDisabled = false;
        },
        _error,
      );
    }

    function _error(data: any) {
      vm.saveDisabled = false;
      vm.errors = data.data;
    }
  }
})();
