import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('clientRisks', {
    bindings: {
      role: '<',
    },
    template: require('./client-risks.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['GtUtils', 'ClientsService', 'ContractsService', 'gettext'];

  function Controller(
    this: any,
    GtUtils: GtUtilsService,
    ClientsService: any,
    ContractsService: ContractsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.save = save;
    vm.edit = false;
    vm.limitParts = getLimits();

    function save() {
      GtUtils.overlay('show');
      vm.edit = false;
      vm.role.limit_one_deal_volume_spot = vm.limitParts.limit_one_deal_volume_spot;
      vm.role.limit_general_volume_spot = vm.limitParts.limit_general_volume_spot;
      vm.role.limit_open_balance_volume_spot = vm.limitParts.limit_open_balance_volume_spot;
      vm.role.limit_one_deal_in_usd_spot = vm.limitParts.limit_one_deal_in_usd_spot;
      vm.role.limit_general_in_usd_spot = vm.limitParts.limit_general_in_usd_spot;
      vm.role.limit_unpaid_volume_spot = vm.limitParts.limit_unpaid_volume_spot;
      vm.role.limit_one_deal_volume_forward = vm.limitParts.limit_one_deal_volume_forward;
      vm.role.limit_general_volume_forward = vm.limitParts.limit_general_volume_forward;
      vm.role.limit_open_balance_volume_forward = vm.limitParts.limit_open_balance_volume_forward;
      vm.role.limit_one_deal_in_usd_forward = vm.limitParts.limit_one_deal_in_usd_forward;
      vm.role.limit_general_in_usd_forward = vm.limitParts.limit_general_in_usd_forward;
      vm.role.limit_unpaid_volume_forward = vm.limitParts.limit_unpaid_volume_forward;
      return ClientsService[vm.role.model_name].update(
        vm.role,
        function () {
          GtUtils.overlay('hide');
          GtUtils.notify(gettext('Limit saved.'));
        },
        function (data: any) {
          GtUtils.overlay('hide');
          GtUtils.errorClb(data);
        },
      );
    }

    function getLimits() {
      return ContractsService.Contract.limits({
        role: vm.role.model_name.toLowerCase(),
        role_id: vm.role.id,
      });
    }
  }
})();
