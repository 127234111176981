import ng from 'angular';

(function () {
  'use strict';
  ng.module('company.legacy').component('teamPageView', {
    bindings: {
      pageTitle: '<',
      permissions: '<',
    },
    template: require('./team-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  function Controller(this: any) {
    const vm = this;

    vm.addButton = true;
    vm.groupsCount = 0;
    vm.hbConfig = {
      print: {},
    };
    vm.filterLevel = 'team-page-view';

    ////////////////
  }
})();
