import ng from 'angular';

import type { GtFilterService } from '../gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtPaginationOld', directive);

  function directive() {
    return {
      template: require('./gt-pagination-old.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        count: '=',
        pageSize: '=?',
        update: '=?',
        params: '=?',
      },
    };
  }

  Controller.$inject = ['$scope', 'gtFilterService'];

  function Controller(this: any, $scope: ng.IScope, gtFilterService: GtFilterService) {
    const vm = this;

    vm.count = vm.count || 0;
    vm.pageSize = vm.pageSize || 25;
    vm.pages = 0;
    vm.page = 1;
    vm.onPageChange = onPageChange;
    vm.rotateNum = 8;
    vm.range = [];
    vm.firstLoad = true;

    activate();

    ////////////////

    function activate() {
      vm.params = vm.params || {};
      $scope.$watch('vm.count', function () {
        vm.params.page = 1;
        updateData();
      });
      $scope.$watch('vm.pageSize', updateData);
      $scope.$on('gt-filter-updated', function () {
        vm.page = (vm.update && 1) || gtFilterService.getQueryParams('').page;
      });
    }

    function updateData() {
      vm.pages = ~~(vm.count / vm.pageSize);
      if (vm.count % vm.pageSize) {
        vm.pages++;
      }
      vm.page = vm.params.page || gtFilterService.getQueryParams('').page || 1;
      refreshRange();
      reloadData();
    }

    function reloadData() {
      if (vm.firstLoad) {
        return;
      }
      const params = { page: vm.page, page_size: null };
      if (gtFilterService.getQueryParams('').page_size) {
        params.page_size = null;
      }
      if (!vm.update) {
        return gtFilterService.updateQueryParams(params);
      }
      return vm.update(vm.params);
    }

    function onPageChange(page: any) {
      vm.firstLoad = false;
      page = (page <= vm.pages && page) || 1;
      vm.params.page = page;
      vm.params.page_size = vm.pageSize;
      updateData();
    }

    function refreshRange() {
      let start = vm.page - vm.rotateNum / 2,
        end = vm.page + vm.rotateNum / 2;
      vm.range = [];
      start = (start > 2 && start) || 2;
      end = (end < vm.pages - 1 && end) || vm.pages - 1;
      for (let i = start; i <= end; i++) {
        vm.range.push(i);
      }
    }
  }
})();
