import React from 'react';

import { cn } from '~/shared/lib/utils';
import { HeartIcon } from '~/shared/ui/icons';
import { DropdownMenu, DropdownMenuTrigger } from '~/shared/ui/kit/dropdown-menu';

import { PresetListContent } from './preset-list-content';
import type { Preset } from '../../types';
import { FilterButton } from '../filter-button';

export const PresetList: React.FC<{
  preset: Preset;
  presetOptions: Preset[];
  onSelect: (preset: Preset) => void;
  onDelete: (preset: Preset) => void;
}> = ({ preset, presetOptions, onSelect, onDelete }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <FilterButton intent={preset.id ? 'default' : 'empty'} rounded="left">
          <div className="flex items-center gap-1">
            <HeartIcon
              size={14}
              className={preset.id ? 'text-text-section-secondary' : 'text-stroke-main-stronger'}
            />
            <p
              className={cn(
                'text-xs font-medium',
                preset.id ? 'text-text-section-secondary' : 'text-text-main-secondary',
              )}
            >
              {preset.id ? presetOptions.find((p) => p.id === preset.id)?.title : 'Presets'}
            </p>
          </div>
        </FilterButton>
      </DropdownMenuTrigger>
      <PresetListContent
        selectedPresetId={preset.id}
        presetOptions={presetOptions}
        onDelete={onDelete}
        onSelect={onSelect}
      />
    </DropdownMenu>
  );
};
