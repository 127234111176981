import type React from 'react';

import { entityAutocompletesRegistry } from '~/shared/ui/data-types';

import type { FilterTypeProps } from '../types';

export const RelationFilter: React.FC<FilterTypeProps> = ({
  filterValue,
  filterOption,
  onChange,
}) => {
  if (!filterOption.typeExtra) {
    throw new Error('EntityFilter: typeExtra is required');
  }

  const Autocomplete = entityAutocompletesRegistry[filterOption.typeExtra];

  return (
    <Autocomplete
      value={{ id: filterValue.values[0], title: '' }}
      valueChanged={(input) => onChange({ ...filterValue, values: [input?.id ?? ''] })}
    />
  );
};
