import ng from 'angular';

import { ContractQualityRulesListContainer } from './contract-quality-rules-list-container/contract-quality-rules-list-container.component';
import { ContractQualityRulesListTableView } from './contract-quality-rules-list-table-view/contract-quality-rules-list-table-view.component';
import { ContractQualityRulesService } from './contract-quality-rules.service';

export const contractQualityRules = ng
  .module('deals.contracts.contractQualityRules', [])
  .service('ContractQualityRulesService', ContractQualityRulesService)
  .component('contractQualityRulesListContainer', ContractQualityRulesListContainer)
  .component('contractQualityRulesListTableView', ContractQualityRulesListTableView).name;
