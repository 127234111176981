import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { QueryParams } from '~/app/core/types';

import template from './passport-connection-shipment-plan-list-page.html?raw';

export const PassportConnectionShipmentPlanListPage = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    'gettext',
    'LogisticsService',
    'PageService',
    class {
      LogisticsService: any;
      PageService: PageService;
      filterLevel = 'passport-connection-shipment-plan-list-page';
      gettext: ng.gettext.gettextFunction;
      groupBy: any;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        gettext: ng.gettext.gettextFunction,
        LogisticsService: any,
        PageService: PageService,
      ) {
        this.gettext = gettext;
        this.LogisticsService = LogisticsService;
        this.PageService = PageService;

        this.groupBy = null;

        this.queryParams = {
          ...this.initQueryParams,
        };
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const relatedModel = 'logistics.passportconnectionshipmentplan';
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.LogisticsService.getPagesConfig(),
        };

        // @ts-ignore
        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          search: false,
          dates: false,
          multiSelects: [
            {
              argument: 'commodity_list',
              placeholder: this.gettext('commodity'),
              resource: 'crops.crop',
              related_fk: 'commodity_id',
              related_model: relatedModel,
            },
            {
              argument: 'sale_contract_list',
              placeholder: this.gettext('sale contract'),
              resource: 'contracts.salecontract',
              related_fk: 'contract_id',
              related_model: relatedModel,
            },
            {
              argument: 'purchase_contract_list',
              placeholder: this.gettext('purchase contract'),
              resource: 'contracts.purchasecontract',
              related_fk: 'contract_id',
              related_model: relatedModel,
            },
            {
              argument: 'passport_list',
              placeholder: this.gettext('passport'),
              resource: 'passports.passport',
              related_fk: 'passport_id',
              related_model: relatedModel,
            },
          ],
          nestedSelects: [],
          nestedMultiSelects: [],
        };

        return config;
      }
    },
  ],
};
