import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './operations-container.html?raw';

export const OperationsContainer = {
  bindings: {
    filterLevel: '<?',
    applyFilters: '&?',
    derivativeContract: '<?',
    initQueryParams: '<?',
    showOperations: '<?',
    mode: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$window',
    '$q',
    'GtUtils',
    'gettext',
    'OperationsService',
    'ClientsService',
    'ContractsService',
    'gtFilterService',
    'CoreService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      s;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      ClientsService: any;
      ContractsService: ContractsService;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      OperationsService: any;
      applyFilters: any;
      contract: any;
      derivativeContract: any;
      derivativePriceTotals: any;
      filterLevel = 'operations-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      mode: any;
      operations: any;
      operationsExportConfig: any;
      queryParams: QueryParams & { passport_list: string[] } = { passport_list: [] };
      rolling: any;
      rollingCreation: any;
      tableData: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $window: ng.IWindowService,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        OperationsService: any,
        ClientsService: any,
        ContractsService: ContractsService,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$window = $window;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.OperationsService = OperationsService;
        this.ClientsService = ClientsService;
        this.ContractsService = ContractsService;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;

        this.tableOptions = {};
        this.tableData = {};

        this.derivativePriceTotals = {};
        this.operations = [];
        this.rollingCreation = false;
        this.rolling = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'operations-container';
        this.mode = this.mode || 'commodity';
        this.contract = { derivatives: [], ...this.derivativeContract };
        this.contract.derivatives =
          this.contract.derivatives !== null ? this.contract.derivatives : [];
        this.contract.derivatives = this.contract.derivatives.map(
          (item: any) => item.derivative || item.derivative_id,
        );
        this.queryParams = {
          page_size: 20,
          contract: this.contract.id,
          passport_list: [],
          ...this.initQueryParams,
        };
        this.operationsExportConfig = this.OperationsService.getOperationsExportConfig();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });

        this.tableOptions = this.getTableOptions();
        this.updateData();
        this.$scope.$watch(
          () => this.mode,
          () => (this.tableOptions = this.getTableOptions()),
        );
      }

      getNewOperation(operation: any) {
        operation = operation || {
          deal_type: 'long',
          derivative_type: 'futures',
          operation_type: 'hedging',
          quantity: 0,
          business_date: new Date(),
          business_unit:
            this.contract.business_unit || [...this.CoreService.getDefaultBuList()].shift(),
          derivative: this.contract.derivatives[0],
          derivative_opposite: this.contract.derivative_opposite,
          contract: this.contract.id,
          use_in_position: true,
        };
        if (this.queryParams.passport_list?.length) {
          return Promise.resolve({
            ...operation,
            passport: this.queryParams.passport_list[0],
          });
        }
        return this.getDefaultPassportByContract(operation.contract).then((passportId: any) => ({
          ...operation,
          passport: passportId,
        }));
      }

      updateData(newOperation?: any) {
        this.GtUtils.overlay('show');
        this.rolling = {};
        this.getNewOperation(newOperation).then((operation: any) => {
          this.OperationsService.getOperationData(this.queryParams).then((data: any) => {
            if (this.$rootScope.user.settings.ENABLE_INLINE_ADD) {
              data.results.unshift(operation);
            }
            this.tableData = { rows: data.results, count: data.count };
            this.GtUtils.overlay('hide');
            this.updateDerivativePriceTotals();
            this.gtFilterService.updateQueryParams({}, 'operations-page-view');
            this.OperationsService.getOperationTotals(this.queryParams).then((data: any) => {
              this.tableData = {
                ...this.tableData,
                total: data,
              };
            });
          });
        });
      }

      updateDerivativePriceTotals(contractIds?: any) {
        contractIds = contractIds || [this.contract.id];
        const ids = contractIds.filter((element: any) => element);
        if (!ids.length) {
          return this.$q.resolve({});
        }
        return this.ContractsService.Contract.derivativePriceList({ id_list: ids }).$promise.then(
          (data: any) => (this.derivativePriceTotals = data.results?.[0] || {}),
        );
      }

      setPassportId(operation: any) {
        this.getDefaultPassportByContract(operation.contract).then(
          (passport: any) => (operation.passport = passport),
        );
      }

      getRollingData(rolling: any, derivatives: any) {
        const currentDerivative = derivatives.filter(
          (item: any) => item.derivative_id === rolling.derivative_from,
        );
        if (currentDerivative.length === 0) {
          return;
        }
        rolling.quantity_from = currentDerivative[0].not_fixed_quantity_lots || 0;
        rolling.quantity_to = currentDerivative[0].not_fixed_quantity_lots || 0;
        rolling.fixed_quantity_lots = currentDerivative[0].fixed_quantity_lots || 0;
        rolling.not_fixed_quantity_lots = currentDerivative[0].not_fixed_quantity_lots || 0;
        rolling.price_premium = currentDerivative[0].price_premium || 0;
        rolling.contract_type = currentDerivative[0].contract_type || 0;
      }

      createRolling(rolling: any) {
        return this.OperationsService.createRolling({
          ...rolling,
          contract_id: this.contract.id,
        }).then(
          () => {
            this.rollingCreation = false;
            this.updateData();
          },
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      openOperationModal(operation: any) {
        return this.getNewOperation(operation).then((data: any) =>
          this.OperationsService.operationModal(data).then(
            () => this.updateData(),
            (error: any) => {
              if (error !== 'escape key press') {
                this.GtUtils.errorClb(error);
              }
            },
          ),
        );
      }

      getDefaultPassportByContract(contractId: any) {
        return this.ContractsService.getPassportIdListByContract(contractId).then(
          (passports: any) => (passports.length === 1 ? passports[0].id : null),
        );
      }

      saveItem(item: any) {
        this.contractDerivativeCheck(item).then((warnings: any) =>
          this.decideWarnings(warnings).then((result: any) =>
            result
              ? this.OperationsService.saveOperation(item).then(
                  () => this.updateData(),
                  (error: any) => this.GtUtils.errorClb(error),
                )
              : false,
          ),
        );
      }

      updateItem(item: any) {
        this.OperationsService.updateOperation(item).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      contractDerivativeCheck(operation: any) {
        return this.getContractDerivativeData(operation.contract).then((contract: any) => {
          return (
            [
              {
                condition:
                  operation.derivative &&
                  operation.operation_type !== 'rolling' &&
                  !contract.derivative_id_list?.includes(operation.derivative),
                message:
                  'The derivative by the operation is different from the contract derivative.' +
                  'Are you sure you want to save the operation?',
              },
              {
                condition:
                  operation.derivative &&
                  operation.operation_type !== 'rolling' &&
                  operation.quantity > contract.notFixedLots,
                message:
                  'The number of lots by operations is more then planned number.' +
                  'You are sure you want to save the operation',
              },
            ].filter((warning) => warning.condition) || []
          );
        });
      }

      getContractDerivativeData(contractId: any) {
        return this.updateDerivativePriceTotals([contractId]).then((derivativeData: any) => ({
          derivative_id_list: derivativeData.derivative_id_list,
          notFixedLots: derivativeData.not_fixed_quantity_lots,
        }));
      }

      decideWarnings(warnings: any) {
        let result = true;
        for (const warning of warnings) {
          if (!confirm(warning.message)) {
            result = false;
            break;
          }
        }
        return this.$q.resolve(result);
      }

      clone(item: any) {
        if (!confirm(this.gettext('Do you want to clone this object?'))) {
          return false;
        }
        return this.OperationsService.clone(item.id).then(
          (data: any) => this.updateData(data),
          (e: any) => this.GtUtils.errorClb(e),
        ).$promise;
      }

      destroy(itemId: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return;
        }
        return this.OperationsService.deleteOperation(itemId).then(
          () => this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel),
          (e: any) => this.GtUtils.errorClb(e),
        ).$promise;
      }

      getTableOptions() {
        return {
          tableName: 'futures-table',
          configurable: true,
          tableClass: 'futures-table min-height-table',
          filterLevel: this.filterLevel,
          applyFilters: (params: any) =>
            this.applyFilters
              ? this.applyFilters(params)
              : this.gtFilterService.updateQueryParams(params.params, this.filterLevel),
          templateArgs: {
            saveItem: (item: any) => this.saveItem(item),
            clone: (item: any) => this.clone(item),
            destroy: (itemId: any) => this.destroy(itemId),
            updateData: () => this.updateData(),
            updateItem: (item: any) => this.updateItem(item),
            setPassportId: (item: any) => this.setPassportId(item),
            openOperationModal: (item: any) => this.openOperationModal(item),
            contract: this.contract,
            mode: this.mode,
            subAccountParams: {
              stock_exchange: this.$rootScope.user.settings.DEFAULT_VALUES.stock_exchange,
            },
            count: this.tableData.count,
          },
          columnDefs: [
            {
              columnName: 'business_date',
              class: 'td-left-align',
              title:
                this.mode === 'commodity'
                  ? this.gettext('Entry date / Exit date')
                  : 'Operation date',
              filters: [
                {
                  type: 'daterange',
                  placeholder: 'Entry/Business date',
                  datePredicate: 'business_date',
                  startDateField: 'start_date',
                  endDateField: 'end_date',
                },
                {
                  type: 'daterange',
                  placeholder: 'Exit/Matched date',
                  datePredicate: 'match_date',
                  startDateField: 'start_date',
                  endDateField: 'end_date',
                },
              ],
              cellTemplate: `
                <span ng-if="!item.edit && item.id">
                  <i class="fa fa-calendar"></i>
                  {[{ item.business_date | date:'dd.MM.yy' || '---' }]}
                  <span ng-if="item.operationpart_list && item.operationpart_list.length == 1">
                    /
                    <span ng-repeat="operationpart in item.operationpart_list">
                      {[{ operationpart.exit_date | date:'dd.MM.yy' || '---' }]}
                    </span>
                  </span>
                  <span ng-if="item.operationpart_list && item.operationpart_list.length > 1">
                    <span ng-repeat="operationpart in item.operationpart_list | limitTo: -1">
                      / {[{ operationpart.exit_date | date:'dd.MM.yy' || '---' }]}
                    </span>
                    <span
                      class="btn-link"
                      ng-click="item.exitDatesExpanded = !item.exitDatesExpanded"
                    >
                      <i class="fa fa-calendar"></i> {[{item.operationpart_list.length}]}
                    </span>
                    <div class="clearfix"></div>
                    <span ng-if="item.exitDatesExpanded">
                      <span ng-repeat="operationpart in item.operationpart_list">
                        {[{ operationpart.exit_date | date:'dd.MM.yy' || '---' }]}
                        <div class="clearfix"></div>
                      </span>
                    </span>
                  </span>
                  <div class="clearfix"></div>
                  <span class="smaller-label label-smoke"> # {[{item.id || '---'}]} </span>
                  <div class="clearfix"></div>
                  <span class="smaller-label label-smoke"> {[{item.business_unit_title}]} </span>
                </span>
                <span ng-if="item.edit || !item.id">
                  <gt-date-select
                    date-model="item.business_date"
                    use-watch="true"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-date-select>
                </span>
              `,
            },
            {
              columnName: 'id',
              class: 'td-left-align',
              title: this.gettext('ID'),
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'id_list',
                  label: this.gettext('ID'),
                  resource: 'stockexchanges.Operation',
                },
              ],
              cellTemplate: `
                  <span> # {[{item.id || '---'}]} </span>
            `,
            },
            {
              columnName: 'business_unit',
              class: 'td-left-align',
              title: this.gettext('Business unit'),
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'business_unit_list',
                  label: this.gettext('businessunit'),
                  resource: 'core.businessunit',
                },
              ],
              cellTemplate: `
              <span ng-if="!item.edit && item.id">
                    <span> {[{item.business_unit_title}]} </span>
              </span>
              <span ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.business_unit"
                    placeholder="'Business unit'|translate"
                    resource-name="'core.businessunit'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
            `,
            },
            {
              columnName: 'currency_exchange',
              class: 'td-left-align',
              title: this.gettext('Currency exchange'),
              cellTemplate: `
              <span ng-if="!item.edit && item.id">
                <span class="smaller-label label-smoke">
                  {[{item.currencyexchange_rate}]} {[{ item.currencyexchange_currency_symbol }]}
                </span>
              </span>
              <span ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.currency_exchange"
                  placeholder="'Currency exchange'|translate"
                  resource-name="'finances.currencyexchange'"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </span>
            `,
            },
            {
              columnName: 'operation_type',
              title: this.gettext('Type of operation'),
              filters: [
                {
                  type: 'select',
                  predicate: 'operation_type',
                  label: this.gettext('Type'),
                  values: {
                    fixing: this.gettext('fixing'),
                    hedging: this.gettext('hedging'),
                    rolling: this.gettext('rolling'),
                    paper_trade: this.gettext('paper trade'),
                  },
                },
                {
                  type: 'select',
                  predicate: 'derivative_mode',
                  label: this.gettext('Derivative mode'),
                  values: {
                    commodity: this.gettext('Commodity'),
                    currency: this.gettext('Currency'),
                  },
                },
                {
                  type: 'select',
                  predicate: 'is_virtual',
                  label: this.gettext('Is virtual'),
                  values: {
                    1: this.gettext('Yes'),
                    0: this.gettext('No'),
                  },
                },
                {
                  type: 'select',
                  predicate: 'use_in_position',
                  label: this.gettext('Use in position'),
                  values: {
                    1: this.gettext('Yes'),
                    0: this.gettext('No'),
                  },
                },
              ],
              cellTemplate: `
              <span ng-if="!item.edit && item.id" class="label pull-left">
                {[{item.operation_type || '---'}]}
              </span>
              <span ng-if="item.edit || !item.id">
                <select class="form-control" ng-model="item.operation_type">
                  <option value="fixing">
                    <translate>Fixing</translate>
                  </option>
                  <option value="hedging">
                    <translate>Hedging</translate>
                  </option>
                  <option value="rolling">
                    <translate>Rolling</translate>
                  </option>
                  <option value="paper_trade">
                    <translate>Trade</translate>
                  </option>
                </select>
              </span>
              <div class="clearfix"></div>
              <span ng-if="!item.edit && item.id && item.is_virtual" class="label label_info">
                  <translate ng-if="args.mode == 'commodity'">virtual</translate>
                  <translate ng-if="args.mode == 'currency'">NDF</translate>
              </span>
              <div class="clearfix"></div>
              <span ng-if="(item.edit || !item.id)" style="display: block; width: 100px">
                <input type="checkbox" ng-model="item.is_virtual" /> <label style="padding-top:4px; padding-left:2px">
                  <translate ng-if="args.mode == 'commodity'">virtual</translate>
                  <translate ng-if="args.mode == 'currency'">NDF</translate>
                </label>
              </span>
              <div class="clearfix"></div>
              <span ng-if="!item.edit && item.id && !item.use_in_position" class="label label-warning pull-left">
              <translate>Position excluded</translate> <i class="fa fa-times"></i>
              </span>
              <div class="clearfix"></div>
              <span ng-if="(item.edit || !item.id) && args.mode == 'commodity'" style="display: block; width: 100px">
                <input type="checkbox" ng-model="item.use_in_position" /> <label style="padding-top:4px; padding-left:2px"><translate>position</translate></label>
              </span>
            `,
            },
            {
              columnName: 'deal_type',
              title: this.gettext('Type'),
              class: 'td-left-align',
              filters: [
                {
                  type: 'select',
                  predicate: 'deal_type',
                  label: this.gettext('Type'),
                  values: {
                    long: this.gettext('Long'),
                    short: this.gettext('Short'),
                  },
                },
              ],
              cellTemplate: `
              <span
                  ng-if="!item.edit && item.id"
                  class="label pull-left"
                  ng-class="{'label_success': item.deal_type == 'long', 'label_danger': item.deal_type == 'short' }"
                >
                  <span ng-if="item.deal_type == 'long'">
                    <translate>Buy</translate>
                  </span>
                  <span ng-if="item.deal_type == 'short'">
                    <translate>Sell</translate>
                  </span>
                </span>
                <span ng-if="item.edit || !item.id">
                  <select class="form-control" ng-model="item.deal_type">
                    <option value="long">
                      <translate>Buy</translate>
                    </option>
                    <option value="short">
                      <translate>Sell</translate>
                    </option>
                  </select>
                </span>
                <span ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.derivative"
                    resource-name="'stockexchanges.Derivative'"
                    query-params="{ id_list: args.contract.derivatives, is_expired: 0 }"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
            `,
            },
            {
              columnName: 'derivative',
              title: this.gettext('Type'),
              class: 'td-left-align',
              filters: [
                {
                  type: 'ui-select',
                  predicate: 'derivative_cargo',
                  label: this.gettext('Commodity'),
                  resource: 'crops.Crop',
                },
              ],
              cellTemplate: /*html*/ `
                  <span ng-if="!item.edit && item.id"> {[{item.derivative_title || '---'}]} </span>
                  <div class="clearfix"></div>
                  <span ng-if="item.cargo_title && !item.edit" class="smaller-label label-smoke"
                    >{[{ item.cargo_title }]}</span
                  >
                  <span ng-if="item.edit || !item.id">
                    <gt-resource-select
                      ng-model="item.derivative"
                      resource-name="'stockexchanges.Derivative'"
                      query-params="{ id_list: args.contract.derivatives, is_expired: 0 }"
                      allow-clear="true"
                      on-open-close="args.setHovering(isOpen)"
                    ></gt-resource-select>
                  </span>
                `,
            },
            {
              columnName: 'quantity_lots',
              title: this.gettext('Lots'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="!item.edit && item.id">
                  {[{item.quantity || '---'}]}
                </span>
                <span ng-if="(item.edit || !item.id) && args.mode == 'commodity'">
                  <input class="form-control" type="number" ng-model="item.quantity"
                    ng-change="item.metric_tons = 0"
                  >
                </span>
                <div class="clearfix"></div>
            <span ng-if="!item.edit && item.id && item.metric_tons" class="smaller-label label-smoke">
             {[{item.metric_tons || 0 | number: 3}]}
              <span style="font-size:0.8em;">
                <translate>t</translate>
              </span>
            </span>

            <span ng-if="item.edit || !item.id">
            <input class="form-control" type="number" ng-model="item.metric_tons">
          </span>
            `,
              totalTemplate: `
                 {[{item.quantity || '---'}]}
                <div class="clearfix"></div>
                {[{ item.metric_tons || 0 | number: 3 }]}
                <span style="font-size:0.8em;">
                  <translate>t</translate>
                </span>

            `,
            },
            {
              columnName: 'price',
              title: this.gettext('Price'),
              class: 'td-left-align',
              cellTemplate: `
              <span ng-if="!item.edit && item.id" class="pull-left" ng-class="{'alert-success': item.deal_type == 'long', 'alert-danger': item.deal_type == 'short' }">
                <span class="label-strong">
                  {[{item.price || '---'}]}
                  <div class="clearfix"></div>
                  <span ng-if="item.derivative_point_value != 100">
                  <translate>cent</translate></span>
                  {[{ item.derivative_currency_symbol }]}/{[{ item.derivative_measurement_title }]}
                </span>
              </span>
              <span ng-if="item.edit || !item.id">
                <input class="form-control" type="number" ng-model="item.price">
              </span>
            `,
            },
            {
              columnName: 'flat_price',
              title: this.gettext('Flat price'),
              class: 'td-left-align',
              cellTemplate: `
              <span
              ng-if="!item.edit && item.id && item.flat_price" class="smaller-label label-smoke"
            >
                {[{ item.flat_price || 0 | number: 2 }]} {[{ item.derivative_currency_symbol }]} /
                <translate>t</translate>
            </span>
            `,
              totalTemplate: `
              <div ng-repeat="row in item.data">
                {[{ row.average_flat_price || 0 | number: 2 }]} {[{ row.currency_symbol  || '---' }]}
                <div class="clearfix"></div>
              </div>
            `,
            },
            {
              columnName: 'contract',
              class: 'td-left-align',
              title: this.gettext('Contract'),
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'contract_list',
                  label: this.gettext('Contract'),
                  resource: 'contracts.ContractBase',
                  queryParams: { stage: 'contract' },
                },
                {
                  type: 'ui-multiselect',
                  predicate: 'counterparty_list',
                  label: this.gettext('Counterparty'),
                  resource: 'clients.CLientRole',
                },
              ],
              cellTemplate: /*html*/ `
                <a
                  class="btn-link"
                  ng-if="!item.edit && item.id && item.contract_number"
                  ui-sref="gt.page.contract({id: item.contract })"
                  target="_blank"
                >
                  <i class="fa fa-file-text"></i> {[{item.contract_number || '---'}]}
                </a>
                <div class="clearfix"></div>
                <span
                  ng-if="item.contract_type =='purchase' && item.supplier_name && !item.edit"
                  class="smaller-label label-smoke"
                >
                  <i class="fa fa-bookmark-o"></i> {[{item.supplier_name | cut:true:30:'...' ||
                  "---" }]}
                </span>
                <span
                  ng-if="item.contract_type =='sale' && item.buyer_name && !item.edit"
                  class="smaller-label label-smoke"
                >
                  <i class="fa fa-bookmark"></i> {[{item.buyer_name | cut:true:30:'...' || "---"
                  }]}
                </span>
                <span ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.contract"
                    placeholder="'Contract'|translate"
                    resource-name="'contracts.ContractBase'"
                    query-params="{stage:'contract', derivatives: [item.derivative]}"
                    allow-clear="true"
                    on-select="args.setPassportId"
                    on-select-args="[item]"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
            },
            {
              columnName: 'passport',
              class: 'td-left-align',
              title: this.gettext('Passport'),
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'passport_list',
                  label: this.gettext('Passport'),
                  resource: 'passports.Passport',
                  queryParams: { stage: 'passport' },
                },
                {
                  type: 'ui-multiselect',
                  predicate: 'passport_vessel',
                  label: this.gettext('Vessel'),
                  resource: 'logistics.vessel',
                },
              ],
              cellTemplate: /*html*/ `
                <a
                  class="btn-link"
                  ng-if="!item.edit && item.id && item.passport_title"
                  ui-sref="gt.page.passport({id: item.passport })"
                  target="_blank"
                >
                  <i class="fa fa-exchange"></i> {[{ item.passport_title }]}
                </a>
                <div class="clearfix"></div>
                <span class="smaller-label label-smoke" ng-if="item.passport_vessel_name"
                  ><i class="fa fa-ship"></i> {[{ item.passport_vessel_name || '---' |
                  cut:true:50:'...' }]}
                </span>
                <span ng-if="item.edit || !item.id">
                  <div class="clearfix"></div>
                  <gt-resource-select
                    ng-model="item.passport"
                    placeholder="'Passport'|translate"
                    resource-name="'passports.Passport'"
                    allow-clear="true"
                    query-params="{contract: item.contract}"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
            },
            {
              columnName: 'broker',
              class: 'td-left-align',
              title: this.mode === 'commodity' ? this.gettext('Broker') : 'Hedging provider',
              filters: [
                {
                  type: 'ui-multiselect',
                  predicate: 'derivative_account',
                  label: this.gettext('Account'),
                  resource: 'stockexchanges.DerivativeAccount',
                },
                {
                  type: 'ui-multiselect',
                  predicate: 'derivative_stock_exchange',
                  label: this.gettext('Stock exchange'),
                  resource: 'stockexchanges.Stockexchange',
                },
                {
                  type: 'ui-multiselect',
                  predicate: 'broker',
                  label: this.gettext('Broker'),
                  resource: 'clients.Broker',
                },
              ],
              cellTemplate: /*html*/ `
                <span ng-if="!item.edit && item.id && item.broker_name">
                  <i class="fa fa-suitcase"></i> {[{item.broker_name}]}
                </span>
                <span ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.broker"
                    placeholder="'Broker'|translate"
                    resource-name="'clients.Broker'"
                    query-params="{ stockexchange: item.stock_exchange }"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
                <div class="clearfix"></div>
                <span ng-if="!item.edit && item.id" class="smaller-label label-smoke">
                  {[{item.derivative_account_number}]}
                </span>
                <span ng-if="item.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.derivative_account"
                    resource-name="'stockexchanges.DerivativeAccount'"
                    allow-clear="true"
                    query-params="{ is_active: 1 }"
                    on-open-close="args.setHovering(isOpen)"
                  ></gt-resource-select>
                </span>
              `,
            },
            {
              columnName: 'comm_fees',
              class: 'td-left-align',
              title: this.gettext('Comm fees,$'),
              cellTemplate: /*html*/ `
                <span ng-if="!item.edit && item.id && item.tariff_correction">
                  {[{item.tariff_correction || 0 }]}
                </span>
                <span ng-if="item.edit || !item.id">
                  <input
                    class="form-control"
                    style="max-width:70px !important;"
                    type="number"
                    ng-model="item.tariff_correction"
                    placeholder="Tariff correction"
                  />
                </span>
                <div class="clearfix"></div>
                <div
                  class="alert-danger"
                  ng-if="!item.edit && (item.tariffs_cost_per_deal || item.tariff_correction || item.tariffs_cost_per_deal_with_correction)"
                >
                  <span class="label-strong btn-link" ng-click="item.openCosts = !item.openCosts">
                    <span ng-if="item.tariffs_cost_per_deal"
                      >{[{ item.tariffs_cost_per_deal || 0 | number:2 }]}</span
                    >
                    <span ng-if="item.tariff_correction">
                      + <i class="fa fa-exclamation-triangle"></i> {[{ item.tariff_correction || 0
                      }]}
                    </span>
                    <span ng-if="item.tariff_correction && item.tariffs_cost_per_deal"
                      >= {[{ item.tariffs_cost_per_deal_with_correction }]}
                    </span>
                  </span>
                  <div class="clearfix"></div>
                  <span ng-repeat="operationpart in item.tariffs_list" ng-if="item.openCosts">
                    <span ng-repeat="tariff in operationpart.data" ng-if="item.openCosts">
                      {[{ tariff.tariff_type }]}: {[{ tariff.cost_per_deal }]}
                      <div class="clearfix"></div>
                    </span>
                  </span>
                </div>
                <div class="alert-danger" ng-if="!item.edit && item.charge_per_mt">
                  <span class="label-strong btn-link">
                    {[{ item.charge_per_mt || 0 | number:2 }]}
                  </span>
                </div>
                <div class="clearfix"></div>
                <div class="pull-left">
                  <span ng-if="!item.edit && item.id && item.additional_info">
                    {[{item.additional_info || '---'}]}
                  </span>
                  <span ng-if="item.edit || !item.id">
                    <input
                      class="form-control"
                      type="text"
                      style="max-width:70px !important;"
                      placeholder="Note"
                      ng-model="item.additional_info"
                    />
                  </span>
                </div>
              `,
            },
            {
              columnName: 'save_action',
              title: this.gettext('actions'),
              class: 'no-blur',
              cellTemplate: `
              <a class="btn btn-xs btn-blue-border hover-element" ng-click="args.clone(item)" ng-if="!item.edit && item.id">
                <i class="fa fa-files-o"></i>
              </a>
              <span ng-show="$root.user.settings.ENABLE_INLINE_ADD">
              <a class="btn btn-xs btn-blue-border" ng-click="item.edit=true ;item.setHovering(true);" ng-show="!item.edit && item.id">
                <i class="fa fa-pencil-square"></i>
              </a>
              </span>
              <span ng-show="!$root.user.settings.ENABLE_INLINE_ADD">
              <a class="btn btn-xs btn-blue-border" ng-click="args.openOperationModal(item) ;item.setHovering(true);" ng-show="!item.edit && item.id">
                <i class="fa fa-pencil-square"></i>
              </a>
              </span>

              <span ng-if="item.edit || !item.id">
                <a class="btn btn-md btn-success btn_success" ng-if="item.id" ng-click="args.updateItem(item);item.edit=false;item.setHovering(false);">
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a class="btn btn-md btn-success btn_success" ng-if="!item.id" ng-click="args.saveItem(item);item.setHovering(false);">
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a class="btn btn-md" ng-if="item.id" ng-click="item.edit=false;item.setHovering(false);">
                  <i class="fa fa-times"></i>
                </a>
                <a class="btn btn-md btn-danger btn_danger" ng-if="item.id" ng-click="args.destroy(item.id);item.edit=false;item.setHovering(false);">
                  <i class="fa fa-trash"></i>
                </a>
              `,
            },
            {
              columnName: 'created',
              title: this.gettext('Created / Edited'),
              class: 'td-left-align',
              cellTemplate: `
             <span ng-if="item.author_first_name">
                <i class="fa fa-plus"></i>
                <div
                  class="user-avatar"
                  ng-if="item.author_first_name && item.author_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.author_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.author_avatar.length == 0">
                  {[{ item.author_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.author_first_name }]} <i class="fa fa-calendar"></i>
                {[{ item.create_time | date:'dd/MM/yy HH:mm' || '---'  }]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.editor_first_name">
                <i class="fa fa-pencil-square"></i>
                <div class="user-avatar" ng-if="item.editor_first_name && item.editor_avatar.length">
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.editor_first_name }]} <i class="fa fa-calendar"></i>
                {[{ item.update_time | date:'dd/MM/yy HH:mm' || '---'  }]}
                </span>
            `,
            },
          ],
        };
      }

      excludeFromFixingPositions() {
        this.OperationsService.OperationResource.excludeFromFixingPositions({
          operations: this.tableData.rows
            .filter((item: any) => item.id)
            .map((item: any) => item.id),
        }).$promise.then(() => {
          this.updateData();
        }, this.GtUtils.errorClb);
      }
    },
  ],
};
