import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('kpisigningsTableContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      contractStatus: '<?',
      contractType: '<?',
      percentCount: '<?',
    },
    template: require('./kpisignings-table-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext', 'gtFilterService', 'ContractsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    gtFilterService: GtFilterService,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.updateData = updateData;
    vm.data = [];
    vm.count = 0;
    vm.tableOptions = {};
    vm.tableData = { rows: vm.data, count: vm.count };

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'kpisignings-table-container';
      vm.queryParams = { page_size: 10, all_signed: true, ...vm.initQueryParams };
      vm.percentCount = vm.percentCount || 0;
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateReport();
      });
      updateReport();
      vm.tableOptions = getTableOptions();
    };

    vm.$onChanges = function (changes: any) {
      if (changes.data || changes.count) {
        vm.tableData = { rows: vm.data, count: vm.count };
      }
    };

    ////////////////
    function updateData() {
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function getTableOptions() {
      const options: any = {
        tableName: vm.filterLevel,
        filterLevel: vm.filterLevel,
        applyFilters: (params: any) => {
          gtFilterService.updateQueryParams(params.params, vm.filterLevel);
        },
        columnDefs: [] as any[],
        tabs: [],
        configurable: true,
        showMore: false,
      };
      options.columnDefs = [
        {
          title: gettext('Contract #'),
          columnName: 'contract_number',
          cellTemplate: `
            <a ui-sref="gt.page.contract({id: item.id})" target="_blank" class="content-label">
              #{[{ item.contract_number }]}
            </a>
          `,
        },
        {
          title: gettext('Conclusion'),
          columnName: 'conclusion_date',
          cellTemplate: `
            <div>
              <span class="date-label" ng-if="item.conclusion_date">
                <i class="fa fa-calendar"></i> {[{ item.conclusion_date | date: 'dd.MM.yy' }]}
              </span>
              <span class="date-label" ng-if="!item.conclusion_date"> <i class="fa fa-calendar"></i> - </span>
            </div>
          `,
        },
        {
          title: gettext('All signed'),
          columnName: 'signature_all_signed',
          cellTemplate: `
            <div>
              <span class="date-label" ng-if="item.signature_all_signed">
                <i class="fa fa-calendar"></i> {[{ item.signature_all_signed | date: 'dd.MM.yy' }]}
              </span>
              <span class="date-label" ng-if="!item.signature_all_signed"> <i class="fa fa-calendar"></i> - </span>
            </div>
          `,
        },
        {
          title: gettext('Notes'),
          columnName: 'signature_additional_info',
          cellTemplate: `
            <span class="tooltip" data-tip="{[{item.signature_additional_info}]}">\
              {[{item.signature_additional_info}]}\
            </span>
          `,
        },
      ];
      if (vm.contractStatus == 'Success') {
        options.columnDefs.push({
          title: gettext('Success'),
          columnName: 'kpi_signing_success',
          cellTemplate: `
            <span ng-if="item.kpi_signing_success"><i class="fa fa-check-circle"></i></span>
          `,
        });
      }
      return options;
    }

    function updateReport() {
      vm.data = [];

      if (vm.contractType == 'Sale') {
        return ContractsService.SaleContract.query(vm.queryParams).$promise.then(function (
          data: any,
        ) {
          vm.data = data.results;
          vm.count = data.count;
          vm.tableData = { rows: data.results, count: data.count };
          if (!vm.percentCount[vm.queryParams.responsible_for_signing]) {
            vm.percentCount[vm.queryParams.responsible_for_signing] = {};
          }
          if (vm.queryParams.report_kpi_signings == 1) {
            vm.percentCount[vm.queryParams.responsible_for_signing].sale_success = data.count;
          } else {
            vm.percentCount[vm.queryParams.responsible_for_signing].sale_failed = data.count;
          }
        });
      }
      if (vm.contractType == 'Purchase') {
        return ContractsService.PurchaseContract.query(vm.queryParams).$promise.then(function (
          data: any,
        ) {
          vm.data = data.results;
          vm.count = data.count;
          vm.tableData = { rows: data.results, count: data.count };
          if (!vm.percentCount[vm.queryParams.responsible_for_signing]) {
            vm.percentCount[vm.queryParams.responsible_for_signing] = {};
          }
          if (vm.queryParams.report_kpi_signings == 1) {
            vm.percentCount[vm.queryParams.responsible_for_signing].purchase_success = data.count;
          } else {
            vm.percentCount[vm.queryParams.responsible_for_signing].purchase_failed = data.count;
          }
        });
      }
    }
  }
})();
