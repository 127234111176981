import {
  PatchedClientRequest,
  clientsClientsDestroy,
  clientsClientsInfoList,
  clientsClientsPartialUpdate,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import type { CounterpartyDTO } from '../lib';
import { fromClientTableInfo, toPatchedClientRequest } from './mappers/counterparty-dto';

@singleton()
export class CounterpartiesRepository {
  async getCounterpartyList(params: {
    page: number;
    pageSize: number;
  }): Promise<{ count: number; records: CounterpartyDTO[] }> {
    const { count, results } = await clientsClientsInfoList(params);

    return {
      count,
      records: results.map(fromClientTableInfo),
    };
  }

  updateCounterparty = async (dto: CounterpartyDTO): Promise<void> => {
    try {
      await clientsClientsPartialUpdate({
        id: parseInt(dto.id, 10),
        requestBody: toPatchedClientRequest(dto) as unknown as PatchedClientRequest,
      });
    } catch (err) {
      throw new Error('Failed to update Counterparty', { cause: err });
    }
  };

  updateCounterparties = async (dtos: Partial<CounterpartyDTO>[]) => {
    const mergedDtos: Record<string, Partial<CounterpartyDTO>> = {};

    dtos.forEach((dto) => {
      if (dto.id) {
        if (!mergedDtos[dto.id]) {
          mergedDtos[dto.id] = { ...dto };
        } else {
          mergedDtos[dto.id] = { ...mergedDtos[dto.id], ...dto };
        }
      }
    });

    try {
      await Promise.all(
        Object.entries(mergedDtos).map(([id, rest]) =>
          clientsClientsPartialUpdate({
            id: Number(id),
            requestBody: toPatchedClientRequest(rest) as unknown as PatchedClientRequest,
          }),
        ),
      );
    } catch (err) {
      throw new Error('Failed to update Counterparties', { cause: err });
    }
  };

  deleteCounterparty = async (id: string): Promise<void> => {
    try {
      // await clientsClientsDeleteConfirmationRetrieve({ id: Number(id) });
      await clientsClientsDestroy({ id: Number(id) });
    } catch (err) {
      throw new Error('Failed to delete Counterparty', { cause: err });
    }
  };
}
