import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

export class WarehousesFormFieldsService {
  $q: ng.IQService;
  $window: ng.IWindowService;
  CoordinatesService: any;
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  WarehousesService: any;
  fieldsToCopyFromParent: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $window: ng.IWindowService,
    $q: ng.IQService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
    CoordinatesService: any,
    WarehousesService: any,
  ) {
    this.$window = $window;
    this.$q = $q;
    this.FormFieldParamsService = FormFieldParamsService;
    this.CoordinatesService = CoordinatesService;
    this.WarehousesService = WarehousesService;

    this.GtUtils = GtUtils;
    this.gettext = gettext;
    this.fieldsToCopyFromParent = [
      'business_units',
      'client',
      'address',
      'terminal',
      'warehouse_type',
      'coordinates',
      'latitude',
      'longitude',
      'responsible',
      'working_hours',
      'working_hours_start',
      'working_hours_end',
      'additional_info',
    ];
  }
  getFieldsConfig(warehouse: any) {
    return {
      formName: 'warehouse-edit-modal',
      fieldsDef: [
        {
          className: 'form-group-container col-sm-4 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: this.gettext('MAIN') },
              wrapper: 'gt-panel',
              fieldGroup: [
                {
                  key: 'parent',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Parent'),
                    resource: 'logistics.warehouse',
                    queryParams: () => ({ is_group: '1', client: warehouse.client }),
                    onSelect: () => this.fillDetailsOnParentChanged(warehouse),
                  },
                },
                {
                  key: 'title',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Title'),
                    placeholder: this.gettext('Warehouse name'),
                    required: true,
                  },
                },
                {
                  key: 'is_group',
                  type: 'gt-checkbox',
                  templateOptions: {
                    type: 'checkbox',
                    label: this.gettext('Group'),
                    placeholder: this.gettext('Warehouse name'),
                  },
                },
                {
                  key: 'warehouse_type',
                  type: 'gt-select',
                  templateOptions: {
                    label: this.gettext('Type'),
                    valueProp: 'value',
                    required: true,
                    labelProp: 'title',
                    options: [
                      { title: this.gettext('Farm'), value: 'farm' },
                      { title: this.gettext('Elevator'), value: 'elevator' },
                      { title: this.gettext('Terminal'), value: 'terminal' },
                      { title: this.gettext('Warehouse'), value: 'warehouse' },
                      { title: this.gettext('Other'), value: 'other' },
                    ],
                  },
                },
                {
                  key: 'client',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Client'),
                    resource: 'clients.client',
                    required: true,
                  },
                },
                {
                  key: 'terminal',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Terminal'),
                    resource: 'logistics.Terminal',
                  },
                  expressionProperties: {
                    'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                      scope.model.warehouse_type !== 'terminal',
                    'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                      scope.model.warehouse_type === 'terminal',
                  },
                },
                {
                  key: 'capacity',
                  type: 'gt-input',
                  defaultValue: 0,
                  templateOptions: {
                    label: this.gettext('Capacity'),
                    type: 'number',
                  },
                },
                {
                  key: 'business_units',
                  type: 'gt-ui-multiselect',
                  templateOptions: {
                    label: this.gettext('Business units'),
                    placeholder: this.gettext('Business Units'),
                    resource: 'core.BusinessUnit',
                    hint: this.gettext(
                      'If you pick something here, information about this' +
                        ' object will be displayed only for people from those business units',
                    ),
                    addFunc: () => this.$window.open('/admin/core/businessunit/add/'),
                    addIcon: 'fa fa-university',
                    addPerms: true,
                  },
                },
              ],
            },
          ],
        },
        {
          className: 'form-group-container col-sm-4 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: this.gettext('Contacts') },
              wrapper: 'gt-panel',
              fieldGroup: [
                {
                  key: 'responsible',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Responsible'),
                    required: false,
                    resource: 'clients.Person',
                  },
                },
                {
                  key: 'address',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Address'),
                    required: false,
                    resource: 'location.Address',
                    addFunc: () => {
                      this.$window.open('/admin/location/address/add/');
                    },
                    addPerms: ['add_station'],
                    addIcon: 'fa-location-arrow',
                  },
                },
                {
                  key: 'working_hours',
                  type: 'gt-input',
                  defaultValue: this.gettext('24h'),
                  templateOptions: {
                    required: false,
                    label: this.gettext('Working hours'),
                  },
                },
                {
                  key: 'working_hours_start',
                  type: 'gt-input',
                  templateOptions: {
                    startView: 'hour',
                    minView: 'minute',
                    label: this.gettext('Works from'),
                    placeholder: this.gettext('Pick a time'),
                    useWatch: true,
                  },
                },
                {
                  key: 'working_hours_end',
                  type: 'gt-input',
                  templateOptions: {
                    startView: 'hour',
                    minView: 'minute',
                    label: this.gettext('Works till'),
                    placeholder: this.gettext('Pick a time'),
                    useWatch: true,
                  },
                },
              ],
            },
            {
              templateOptions: { label: this.gettext('Coordinates') },
              wrapper: 'gt-panel',
              fieldGroup: [
                {
                  key: 'coordinates',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Coordinates'),
                    required: false,
                    onChange: (value: any, elev: any, scope: any) => {
                      const coords = this.CoordinatesService.parseCoordinates(value);
                      scope.model.coordinates = coords.coordinates;
                      scope.model.latitude = coords.latitude;
                      scope.model.longitude = coords.longitude;
                    },
                  },
                },
                {
                  key: 'latitude',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Latitude'),
                    type: 'number',
                    required: false,
                    onChange: (value: any, elev: any, scope: any) => {
                      const coords = this.CoordinatesService.formatResult(
                        scope.model.latitude,
                        scope.model.longitude,
                      );
                      scope.model.coordinates = coords.coordinates;
                      scope.model.latitude = coords.latitude;
                      scope.model.longitude = coords.longitude;
                    },
                  },
                },
                {
                  key: 'longitude',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Longitude'),
                    type: 'number',
                    required: false,
                    onChange: (value: any, elev: any, scope: any) => {
                      const coords = this.CoordinatesService.formatResult(
                        scope.model.latitude,
                        scope.model.longitude,
                      );
                      scope.model.coordinates = coords.coordinates;
                      scope.model.latitude = coords.latitude;
                      scope.model.longitude = coords.longitude;
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          className: 'form-group-container col-sm-4 col-xs-12',
          fieldGroup: [
            {
              templateOptions: { label: this.gettext('Additional info') },
              wrapper: 'gt-panel',
              fieldGroup: [
                {
                  key: 'additional_info',
                  type: 'gt-textarea',
                  templateOptions: {
                    label: this.gettext('Additional Info'),
                    placeholder: this.gettext('Specific information about this object'),
                    className: 'additional-info',
                  },
                },
                {
                  template: /* html */ `
                    <custom-values-container
                      ng-if="model.id"
                      filter-level="'warehouse-custom-values-container'"
                      init-query-params="{
                        object_id: model.id,
                        purpose: model.content_type,
                        purpose_model: 'warehouse',
                      }"
                    ></custom-values-container>
                  `,
                },
              ],
            },
          ],
        },
      ],
    };
  }
  fillDetailsOnParentChanged(warehouse: any) {
    if (!warehouse.parent) {
      return this.$q.when();
    }
    return this.WarehousesService.getWarehouse(warehouse.parent).then((parent: any) => {
      if (confirm(this.gettext('Should all the details be filled in from the Parent warehouse?'))) {
        return this.fieldsToCopyFromParent.forEach((f: any) => (warehouse[f] = parent[f]));
      }
      return this.fieldsToCopyFromParent
        .filter((f: any) => warehouse[f] !== parent[f])
        .map((f: any) => `The ${f} field in the parent warehouse differs from the current one.`)
        .forEach((text: any) => this.GtUtils.notify(this.gettext(text)));
    });
  }
  getFields(warehouse: any) {
    return this.FormFieldParamsService.getFields(this.getFieldsConfig(warehouse));
  }
  openWarehouseFieldsConfigModal(warehouse: any) {
    return this.FormFieldParamsService.fieldsConfigModal(this.getFieldsConfig(warehouse));
  }
}

WarehousesFormFieldsService.$inject = [
  '$window',
  '$q',
  'GtUtils',
  'gettext',
  'FormFieldParamsService',
  'CoordinatesService',
  'WarehousesService',
];
