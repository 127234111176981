import { Subject, defer, switchMap, tap } from 'rxjs';

import { type BiDashboard, BiDashboardsRepository } from '~/features/common/bi-dashboards';
import { singleton } from '~/shared/lib/di';

@singleton()
export class StartPageStore {
  private selectedDashboardSubj = new Subject<BiDashboard>();

  constructor(private biDashboardsRepo: BiDashboardsRepository) {}

  readonly selectedDashboard$ = this.selectedDashboardSubj.asObservable();

  public usersDashboards$ = defer(() => this.biDashboardsRepo.getUsersDashboards()).pipe(
    tap((dashboards) => {
      const defaultDashboard = dashboards.find((d) => d.slug === 'analytics-company-basic');
      if (defaultDashboard) {
        this.selectedDashboardSubj.next(defaultDashboard);
      }
    }),
  );

  public selectedDashboardUUID$ = this.selectedDashboardSubj.pipe(
    switchMap((dashboard) => this.biDashboardsRepo.getDashboardUUID(dashboard.id)),
  );

  public fetchGuestToken(dashboardUUID: string) {
    return this.biDashboardsRepo.getGuestToken(dashboardUUID);
  }

  public dashboardSelected(dashboardId: BiDashboard) {
    this.selectedDashboardSubj.next(dashboardId);
  }
}
