import type { ContractsAnalyticsService } from '../../contracts/analytics/analytics.service';

export const ContractsVolumeByCargoChart = {
  bindings: {},
  template:
    '<charts-block config="$ctrl.chartConfig" chart-type="pie" hide-header="true" hide-selects="true"></charts-block>',
  controller: [
    'ContractsAnalyticsService',
    class {
      ContractsAnalyticsService: ContractsAnalyticsService;
      chartConfig: any;
      constructor(ContractsAnalyticsService: ContractsAnalyticsService) {
        this.ContractsAnalyticsService = ContractsAnalyticsService;
        this.chartConfig = undefined;
      }

      $onInit() {
        this.ContractsAnalyticsService.getVolumeByCargoChartConfig().then(
          (data: any) => (this.chartConfig = data),
        );
      }
    },
  ],
};
