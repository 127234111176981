import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';
import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtShowMore', {
    bindings: {
      filterLevel: '<?',
      restPagination: '<?',
      applyFilters: '&?',
      count: '<?',
    },
    template: require('./gt-show-more.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', '$rootScope', 'gtFilterService', 'GtUtils'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;

    vm.$onInit = function () {
      vm.queryParams = gtFilterService.getQueryParams(vm.filterLevel);
      vm.showMore = showMore;
      vm.count = vm.count || 0;
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });

      if ($rootScope.user.settings.HANDLE_ON_SCROLLBOTTOM) {
        $scope.$on('scroll-bottom-reached', function () {
          GtUtils.overlay('show');
          showMore();
        });
      }
    };

    ////////////////

    function showMore() {
      if (
        vm.queryParams.page &&
        vm.queryParams.page >= vm.count / (vm.queryParams.page_size || 25)
      ) {
        return false;
      }

      if (vm.restPagination) {
        vm.queryParams.next = true;
        vm.queryParams.page = vm.queryParams.page || 1;
        vm.queryParams.page++;
      } else {
        vm.queryParams.page = null;
        vm.queryParams.page_size = (vm.queryParams.page_size || 0) + 25;
      }
      $rootScope.$broadcast('gt-show-more', vm.queryParams);
      if (vm.applyFilters) {
        vm.applyFilters({ params: vm.queryParams });
      } else {
        gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
      }
    }
  }
})();
