import ng from 'angular';

import { SaleExportContractAllocationsContainer } from './sale-export-contract-allocations-container/sale-export-contract-allocations-container.component';
import { SaleExportContractAllocationsTableView } from './sale-export-contract-allocations-table-view/sale-export-contract-allocations-table-view.component';
import { SaleExportContractAllocationService } from './sale-export-contract-allocations.service';

export const saleExportContractAllocations = ng
  .module('deals.contracts.contractConnection', [])
  .service('SaleExportContractAllocationService', SaleExportContractAllocationService)
  .component('saleExportContractAllocationsContainer', SaleExportContractAllocationsContainer)
  .component('saleExportContractAllocationsTableView', SaleExportContractAllocationsTableView).name;
