import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('contractTerminalCertsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<',
    },
    template: require('./contract-terminal-certs-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext', 'GtUtils', 'gtFilterService', 'ContractsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.certs = [];
    vm.count = 0;
    vm.updateCerts = updateCerts;
    vm.updateQueryParams = updateQueryParams;
    vm.saveCert = saveCert;
    vm.deleteCert = deleteCert;

    vm.$onInit = function () {
      vm.queryParams = { page_size: 20, ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateCerts();
      });
      updateCerts();
    };

    ////////////////

    function saveCert(cert: any) {
      cert.contract = vm.queryParams.contract;
      if (cert.id) {
        return ContractsService.ContractTerminalCerts.update(cert, updateCerts, (e: any) =>
          GtUtils.errorClb(e),
        ).$promise;
      }
      return ContractsService.ContractTerminalCerts.save(cert, updateCerts, (e: any) =>
        GtUtils.errorClb(e),
      ).$promise;
    }

    function deleteCert(cert: any) {
      if (!confirm(gettext('Are you sure that you want delete Cert?'))) {
        return;
      }
      return ContractsService.ContractTerminalCerts.delete(cert, updateCerts, (e: any) =>
        GtUtils.errorClb(e),
      ).$promise;
    }

    function updateQueryParams() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateCerts() {
      GtUtils.overlay('show');
      vm.newCert = { ...vm.queryParams };
      return ContractsService.ContractTerminalCerts.query(vm.queryParams, function (data: any) {
        vm.certs = data.results;
        if (vm.newCert) {
          vm.certs.unshift(vm.newCert);
        }
        vm.count = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }
  }
})();
