import React from 'react';

import { DateWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const DateCellWrite = <T,>({ value, valueChanged }: CellTypeProps<T>) => {
  return (
    <DateWrite
      value={new Date(String(value))}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  );
};
