import ng from 'angular';

import { LoanContainer } from './loan-container/loan-container.component';
import { LoanModal } from './loan-modal/loan-modal.component';
import { LoanPageView } from './loan-page-view/loan-page-view.component';
import { LoanService } from './loan.service';

const loanConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('finances.loansPage', {
      url: '/loan',
      component: 'loanPageView',
      data: {
        pageTitle: gettext('Loan'),
      },
    });
  },
];

export const loan = ng
  .module('finances.loans', [])
  .component('loanContainer', LoanContainer)
  .component('loanPageView', LoanPageView)
  .component('loanModal', LoanModal)
  .service('LoanService', LoanService)
  .config(loanConfig).name;
