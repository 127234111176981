import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './freight-position-list-table-view.html?raw';

export const FreightPositionListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    setHovering: '&',
    tableName: '<?',
    openContractModal: '&',
    openVoyageModal: '&',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    '$window',
    class {
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      applyFilters: any;
      count: number;
      filterLevel = 'freight-position-list-table-view';
      gettext: ng.gettext.gettextFunction;
      list: any;
      numberInput: any;
      openContractModal: any;
      openVoyageModal: any;
      selection: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      total: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$window = $window;

        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onChanges(changes: any) {
        this.tableName = this.tableName || 'freight-position';
        if (changes.list || changes.count || changes.total) {
          this.tableOptions = this.getTableOptions();
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'contract-charges-table freight-position-table',
          applyFilters: this.applyFilters,
          configurable: true,
          templateArgs: {
            openContractModal: (contract: any) => this.openContractModal({ contract: contract }),
            openVoyageModal: (voyage: any) => this.openVoyageModal({ voyage: voyage }),
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [
            {
              columnName: 'contractNumber',
              class: 'td-left-align',
              title: this.gettext('Contract number'),
              cellTemplate: /*html*/ `
                <a
                  permission
                  permission-only="'change_contractbase'"
                  ng-disabled="item.status == 'executed'"
                  ng-click="args.openContractModal(item)"
                  ng-if="item.row_type == 2"
                >
                  <i class="fa fa-pencil-square"></i> # {[{ item.contract_number || '---' }]}
                </a>

                <span
                  ng-click="args.openVoyageModal({id: item.voyage_id})"
                  style="cursor: pointer;"
                  ng-if="item.row_type == 1"
                >
                  <i class="fa fa-ship"></i> {[{ item.contract_number }]}</span
                >
              `,
              totalTemplate: `
              <i class="fa-solid fa-chart-simple"></i>
              <translate>TOTAL</translate>
            `,
            },
            {
              columnName: 'businessunitTitle',
              class: 'td-left-align',
              title: this.gettext('Business unit'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.businessunit_title }">
                  <i class="fa fa-home"></i> {[{ item.businessunit_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'cropTitle',
              class: 'td-left-align',
              title: this.gettext('Commodity'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.crop_title }">
                  {[{ item.crop_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'originOfCropTitle',
              class: 'td-left-align',
              title: this.gettext('Origin'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.origin_of_crop_title }">
                  {[{ item.origin_of_crop_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'destinationOfCropTitle',
              class: 'td-left-align',
              title: this.gettext('Destination'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.destination_of_crop_title }">
                  {[{ item.destination_of_crop_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'sides',
              class: 'td-left-align',
              title: this.gettext('Sides'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.supplier_name && !item.buyer_name }">
                  <span ng-if="item.contract_type == 'sale'">
                    <i class="fa fa-bookmark"></i> {[{ item.buyer_name || '---' | cut:true:30:'...'
                    }]}
                  </span>
                  <span ng-if="item.contract_type == 'purchase'">
                    <i class="fa fa-bookmark-o"></i> {[{ item.supplier_name || '---' |
                    cut:true:30:'...' }]}
                  </span>
                </span>
              `,
            },
            {
              columnName: 'shipment',
              class: 'td-left-align',
              title: this.gettext('Shipment'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.date_of_execution }">
                  <i class="fa fa-calendar"></i> {[{ item.date_of_execution | date:'dd MMM yy' ||
                  '---' }]} - {[{ item.end_of_execution | date:'dd MMM yy' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'volume',
              class: 'td-left-align',
              title: this.gettext('volume'),
              cellTemplate: /*html*/ `
                <span
                  ng-if="item.volume_estimated_open"
                  class="tooltip label"
                  data-tip="{[{ 'This contract has estimated volume'|translate }]}"
                  ><b
                    ><translate>est.</translate> {[{item.volume_estimated_open | number:3}]}</b
                  ></span
                >
                <div class="clearfix"></div>
                <span
                  class="smaller-label label-opacity"
                  ng-if="item.volume_min || item.volume_max"
                >
                  min.{[{item.volume_min | number:0}]} max.{[{item.volume_max | number:0}]}
                </span>
                <div class="clearfix"></div>
                <span>
                  {[{item.volume | number:3}]}
                  <span ng-if="item.volume_options">(+/-{[{item.volume_options}]}) </span>
                </span>
              `,
            },
            {
              columnName: 'passports',
              class: 'td-left-align',
              title: this.gettext('passports'),
              cellTemplate: /*html*/ `
                <span ng-repeat="conn in item.connections">
                  <a
                    class="smaller-label btn-link"
                    ui-sref="gt.page.passport({id: conn.passport })"
                    data-tip="{[{ 'Attached to passport'|translate }]}: {[{ conn.passport_title }]} - {[{ conn.volume | gtDecimal:2 }]}"
                  >
                    <i class="fa fa-exchange"></i> #{[{conn.passport_title | cut:true:30:'...' ||
                    "---" }]} - {[{ conn.volume | number:3 }]}
                  </a>
                  <div class="clearfix"></div>
                </span>
              `,
            },
            {
              columnName: 'available',
              class: 'td-left-align',
              title: this.gettext('available'),
              cellTemplate: /*html*/ `
                <a ng-href="{[{ vm.getHref(item.cargo_id) }]}" target="_blank">
                  <span ng-class="{ 'label-opacity': item.available_to_connect == 0 }">
                    <i class="fa fa-chain-broken"></i> {[{ item.available_to_connect | number:3 || 0
                    }]}
                    <span class="smaller-label">mT</span>
                  </span>
                </a>
              `,
            },
            {
              columnName: 'unexecuted',
              class: 'td-left-align',
              title: this.gettext('unexecuted'),
              cellTemplate: /*html*/ `
                <span> {[{ item.unexecuted | number:3 || 0 }]}</span>
              `,
              totalTemplate: `
                <span> {[{ item.unexecuted | number:3 || 0 }]}</span>
            `,
            },
            {
              columnName: 'basis',
              class: 'td-left-align',
              title: this.gettext('basis'),
              cellTemplate: /*html*/ `
                <span class="label label_default" ng-class="{ 'label-opacity': !item.basis_title }">
                  {[{ item.basis_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'price',
              class: 'td-left-align',
              title: this.gettext('price'),
              cellTemplate: /*html*/ `
                <span
                  ng-if="item.voyage_tariff_freight_local_price"
                  ng-class="{ 'label-opacity': item.voyage_tariff_freight_local_price == 0 }"
                >
                  {[{ item.voyage_tariff_freight_local_price | number:2 || 0 }]}
                  <span class="smaller-label label-opacity">
                    {[{ item.freight_currency_symbol }]}
                  </span>
                </span>
                <span ng-repeat="cost in item.contract_freight_costs_data">
                  {[{ cost.cost_price_t | number:2 }]}
                  <span class="currency-symbol smaller-label label-opacity"
                    >{[{cost.currency_symbol}]}</span
                  >
                </span>
              `,
            },
            {
              columnName: 'loadport',
              class: 'td-left-align',
              title: this.gettext('loadport'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.loadport_name}">
                  <i class="fa fa-anchor"></i> {[{ item.loadport_name || "---" }]}
                </span>
              `,
            },
            {
              columnName: 'disport',
              class: 'td-left-align',
              title: this.gettext('disport'),
              cellTemplate: /*html*/ `
                <span ng-class="{ 'label-opacity': !item.disport_name}">
                  <i class="fa fa-anchor"></i> {[{ item.disport_name || "---" }]}
                </span>
              `,
            },
            {
              columnName: 'freight',
              class: 'td-left-align',
              title: this.gettext('freight'),
              cellTemplate: /*html*/ `
                <span ng-if="item.price_usd" ng-class="{ 'label-opacity': item.price_usd == 0 }">
                  {[{ item.price_usd | number:2 }]}
                  <span class="smaller-label label-opacity">
                    {[{ item.default_currency_symbol }]}
                  </span>
                </span>
              `,
            },
            {
              columnName: 'freightOpposite',
              class: 'td-left-align',
              title: this.gettext('freight opposite'),
              cellTemplate: /*html*/ `
                <span
                  ng-if="item.opposite_price_usd"
                  ng-class="{ 'label-opacity': item.opposite_price_usd == 0 }"
                >
                  {[{ item.opposite_price_usd | number:2 }]}
                  <span class="smaller-label label-opacity">
                    {[{ item.default_currency_symbol }]}
                  </span>
                </span>
              `,
            },
            {
              columnName: 'mtmPer1mt',
              class: 'td-left-align',
              title: this.gettext('mtm per 1mt'),
              cellTemplate: /*html*/ `
                <span
                  ng-class="{ 'alert-danger': item.margin_t < 0, 'alert-success': item.margin_t > 0 }"
                >
                  <span class="label" ng-class="{ 'label-opacity': item.margin_t == 0 }">
                    {[{ item.margin_t | number:2 || 0 }]} {[{ item.default_currency_symbol }]}/t
                  </span>
                </span>
              `,
            },
            {
              columnName: 'mtm',
              class: 'td-left-align',
              title: this.gettext('mtm'),
              cellTemplate: /*html*/ `
                <span
                  ng-class="{ 'alert-success': item.valorization > 0, 'alert-danger': item.valorization < 0}"
                >
                  <span class="label" ng-class="{ 'label-opacity': item.valorization == 0 }">
                    {[{ item.default_currency_symbol }]} {[{ item.valorization | number:2 || 0 }]}
                  </span>
                </span>
              `,
              totalTemplate: `
              <span
                ng-class="{ 'alert-success': item.valorization > 0, 'alert-danger': item.valorization < 0}"
              >
                <span class="label" ng-class="{ 'label-opacity': item.valorization == 0 }">
                  {[{ item.default_currency_symbol }]} {[{ item.valorization | number:2 || 0 }]}
                </span>
              </span>
            `,
            },
          ],
        };
      }
    },
  ],
};
