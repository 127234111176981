import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
export class MulticontractFilterConfigService {
  $rootScope: GtRootScopeService;
  CustomValuesService: any;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;
  constructor(
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    CustomValuesService: any,
  ) {
    this.$rootScope = $rootScope;
    this.GtUtils = GtUtils;
    this.gtFilterService = gtFilterService;
    this.gettext = gettext;
    this.CustomValuesService = CustomValuesService;
  }

  getFilterConfig(filterLevel: string, contractType: any, useType: any, stage: any) {
    const filters: any = {
      filterLevel: filterLevel,
      clicked: false,
      search: true,
      dates: true,
      dateSelects: [
        {
          argument: 'create_time',
          placeholder: this.gettext('Create date'),
        },
        {
          argument: 'conclusion_date',
          placeholder: this.gettext('Conclusion date'),
        },
        {
          argument: 'contractbase__conclusion_date',
          placeholder: this.gettext('Conclusion date position'),
        },
        {
          argument: 'contractbase__date_of_execution',
          placeholder: this.gettext('Execution start date position'),
        },
        {
          argument: 'contractbase__end_of_execution',
          placeholder: this.gettext('Execution end date position'),
        },
        {
          argument: 'contractbase__arrival_period_start',
          placeholder: this.gettext('Arrival start position'),
        },
        {
          argument: 'contractbase__arrival_period_end',
          placeholder: this.gettext('Arrival end position'),
        },
        {
          argument:
            contractType === 'sale'
              ? 'contractbase__sale_facts__passport__business_date'
              : 'contractbase__purchase_facts__passport__business_date',
          placeholder: this.gettext('Position passport business date'),
        },
      ],
      multiSelects: [
        {
          argument: 'cargo_list',
          placeholder: this.gettext('cargo'),
          resource: 'crops.Crop',
        },
        {
          argument: 'port_list',
          placeholder: this.gettext('port'),
          resource: 'logistics.Port',
        },
        {
          argument: 'terminal_list',
          placeholder: this.gettext('terminal'),
          resource: 'logistics.Terminal',
        },
        {
          argument: 'elevator_list',
          placeholder: this.gettext('elevator'),
          resource: 'clients.Elevator',
        },
        {
          argument: 'farm_list',
          placeholder: this.gettext('farm'),
          resource: 'clients.Farm',
        },
        {
          argument: 'origin_of_crop_list',
          placeholder: this.gettext('origin of crop'),
          resource: 'location.country',
        },
        {
          argument: 'destination_of_crop_list',
          placeholder: this.gettext('destination of crop'),
          resource: 'location.country',
        },
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
          related_fk: 'business_unit',
          related_model: 'contracts.Multicontract',
          getQueryParams: this.GtUtils.getBUQueryParams,
        },
        {
          argument: 'positions_custom_status_list',
          placeholder: this.gettext('positons custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'contractbase__custom_status',
          related_model: 'contracts.Multicontract',
          queryParams: {
            content_type__model: 'contractbase',
          },
        },
        {
          argument: 'positions_season_list',
          placeholder: this.gettext('positons season'),
          resource: 'core.Season',
          related_fk: 'contractbase__season',
          related_model: 'contracts.Multicontract',
          queryParams: {
            content_type__model: 'contractbase',
          },
        },
        {
          argument: 'budget_list',
          placeholder: this.gettext('budget'),
          resource: 'finances.budget',
          related_fk: 'budget',
          related_model: 'contracts.Multicontract',
        },
        {
          argument: 'editor_list',
          placeholder: this.gettext('editor'),
          resource: 'auth.user',
          related_fk: 'editor_id',
          related_model: 'contracts.Multicontract',
        },
        {
          argument: 'owner_list',
          placeholder: this.gettext('owner'),
          resource: 'clients.owner',
          related_fk: 'owner',
          related_model: 'contracts.Multicontract',
        },
        {
          argument: 'responsible_for_signing_list',
          placeholder: this.gettext('responsible signing'),
          resource: 'auth.user',
          related_fk: 'responsible_for_signing',
          related_model: 'contracts.Multicontract',
        },
        {
          argument: 'company_group_list',
          placeholder: this.gettext('Company group'),
          resource: 'clients.CompanyGroup',
        },
        {
          argument: 'cp_custom_status_list',
          placeholder: this.gettext('CP custom status'),
          resource: 'core.CustomStatus',
          queryParams: {
            content_type__model: 'client',
          },
        },
      ],
      nestedSelects: [
        this.gtFilterService.getBoolFilter(
          'has_position_with_derivative',
          this.gettext('With derivative'),
        ),
        this.gtFilterService.getBoolFilter('with_buyer', this.gettext('With buyer')),
        this.gtFilterService.getBoolFilter('with_supplier', this.gettext('With supplier')),
        this.gtFilterService.getBoolFilter(
          'with_business_unit',
          this.gettext('With Business Unit'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_internal_chain',
          this.gettext('With internal chain'),
        ),
        this.gtFilterService.getBoolFilter(
          'is_client_producer',
          this.gettext('Is client producer'),
        ),
        this.gtFilterService.getBoolFilter('is_client_agent', this.gettext('Is client agent')),
        this.gtFilterService.getBoolFilter(
          'is_client_resident',
          this.gettext('Is client resident'),
        ),
        this.gtFilterService.getBoolFilter(
          'ignore_client_status',
          this.gettext('Ignore client status'),
        ),
        {
          argument: 'contract_type',
          placeholder: this.gettext('Contract type'),
          items: [
            { id: 'sale', title: this.gettext('Sale') },
            { id: 'purchase', title: this.gettext('Purchase') },
          ],
        },
      ],
      nestedMultiSelects: [
        {
          argument: 'cp_approval_status_list',
          placeholder: this.gettext('CP approval status'),
          items: [
            { id: 'wait', title: 'Waiting for approval' },
            { id: 'approved', title: 'Approved' },
            { id: 'process', title: 'Approval in process' },
            { id: 'rejected', title: 'Rejected' },
          ],
        },
      ],
      selects: [],
    };

    if (contractType == 'purchase') {
      filters.multiSelects.push({
        argument: 'supplier_list',
        placeholder: this.gettext('supplier'),
        resource: 'clients.supplier',
        related_fk: 'supplier',
        related_model: 'contracts.Multicontract',
      });
    } else {
      filters.multiSelects.push({
        argument: 'buyer_list',
        placeholder: this.gettext('buyer'),
        resource: 'clients.buyer',
        related_fk: 'buyer',
        related_model: 'contracts.Multicontract',
      });
    }

    if (useType == 'export') {
      filters.nestedSelects.push(
        this.gtFilterService.getBoolFilter(
          'has_position_connected_to_passport',
          this.gettext('Has position connected to passport'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_position_with_periodic_declaration',
          this.gettext('Has position with periodic customs declaration'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_position_with_cargo_declaration',
          this.gettext('Has position with cargo customs declaration'),
        ),
      );
    }

    if (stage == 'contract') {
      filters.multiSelects.push(
        {
          argument: 'id_list',
          placeholder: this.gettext('multicontract'),
          resource: 'contracts.Multicontract',
          related_fk: 'id',
          related_model: 'contracts.Multicontract',
        },
        {
          argument: 'custom_status_list',
          placeholder: this.gettext('Contract custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'custom_status',
          related_model: 'contracts.Multicontract',
          queryParams: {
            content_type__model: 'multicontract',
          },
        },
        {
          argument: 'derivative_list',
          placeholder: this.gettext('derivative'),
          resource: 'stockexchanges.Derivative',
        },
      );
      filters.nestedSelects.push(
        this.gtFilterService.getBoolFilter(
          'has_position_signaturestage_cancel',
          this.gettext('Has position cancelled signature'),
        ),
      );
      filters.nestedMultiSelects.push(
        this.gtFilterService.getMultiContractStatusFilter('status_list', 'Contract status'),
        this.gtFilterService.getContractStatusFilter('positions_status_list', 'Positions status'),
        {
          argument: 'positions_payment_status_list',
          placeholder: this.gettext('Position payment status'),
          items: [
            { id: 'executed', title: this.gettext('Executed') },
            { id: 'not_executed', title: this.gettext('Not executed') },
          ],
        },
      );
    }

    if (stage == 'ticket') {
      filters.multiSelects.push(
        {
          argument: 'id_list',
          placeholder: this.gettext('multiticket'),
          resource: 'contracts.Multicontract',
          related_fk: 'id',
          related_model: 'contracts.Multicontract',
        },
        {
          argument: 'custom_status_list',
          placeholder: this.gettext('Ticket custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'custom_status',
          related_model: 'contracts.Multicontract',
          queryParams: {
            content_type__model: 'multicontract',
          },
        },
      );
      filters.nestedSelects.push(
        this.gtFilterService.getBoolFilter('has_multicontract', this.gettext('With contracts')),
        this.gtFilterService.getBoolFilter(
          'with_general_agreement',
          this.gettext('Has general agreement'),
        ),
      );
      filters.nestedMultiSelects.push(
        {
          argument: 'counterparty_approval_status_list',
          placeholder: this.gettext('Counterparty approval status'),
          items: [
            { id: 'wait', title: this.gettext('Waiting for approval') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        {
          argument: 'general_agreement_status_list',
          placeholder: this.gettext('Agreement status'),
          items: [
            { id: 'new', title: this.gettext('New') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        {
          argument: 'approval_status_list',
          placeholder: this.gettext('Approval status'),
          items: [
            { id: 'wait', title: this.gettext('Waiting for approval') },
            { id: 'approved', title: this.gettext('Approved') },
            { id: 'process', title: this.gettext('Approval in process') },
            { id: 'rejected', title: this.gettext('Rejected') },
          ],
        },
        this.gtFilterService.getTicketPositionStatusFilter(
          'positions_request_status_list',
          'Positions status',
        ),
      );
    }

    this.CustomValuesService.getCustomFieldFilters({
      purpose_model: `multi${stage}`,
    }).then((customFieldsFilters: any) => {
      filters.multiSelects.push(...customFieldsFilters.multiSelects);
      filters.selects.push(...customFieldsFilters.selects);
      filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
      filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
    });

    return filters;
  }
}
MulticontractFilterConfigService.$inject = [
  '$rootScope',
  'GtUtils',
  'gtFilterService',
  'gettext',
  'CustomValuesService',
];
