import React from 'react';

import type { CellRenderDef } from '~/shared/ui/components/data-grid';

import { DynamicReadCell } from './dynamic-read-cell';
import { DynamicWriteCell } from './dynamic-write-cell';

export const CellRender = <TData extends { id: string }>({
  value,
  valueChanged,
  id,
  template,
  cellTemplateContext,
  dataTypeVariant,
  dataType,
  typeExtra,
  render,
}: CellRenderDef<TData>) => {
  if (dataTypeVariant === 'read') {
    return (
      <DynamicReadCell
        value={value}
        dataType={dataType}
        template={template}
        cellTemplateContext={cellTemplateContext}
        valueChanged={valueChanged}
        render={render}
      />
    );
  } else {
    return (
      <DynamicWriteCell
        value={value}
        dataType={dataType}
        rowId={id}
        valueChanged={valueChanged}
        template={template}
        cellTemplateContext={cellTemplateContext}
        typeExtra={typeExtra}
      />
    );
  }
};
