import ng from 'angular';

(function () {
  'use strict';
  ng.module('company.emailTemplates').directive('emailTemplatesList', directive);

  function directive() {
    return {
      template: require('./email-templates-list.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        emailTemplates: '<',
        count: '<',
      },
    };
  }

  Controller.$inject = ['$scope', 'gettext', 'EmailTemplates'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    EmailTemplates: any,
  ) {
    const vm = this;
    vm.updateData = updateData;
    vm.remove = remove;
    vm.openEmailTemplateModal = openEmailTemplateModal;

    ////////////////

    function updateData(params: any) {
      $scope.$emit('emailtemplates-list__update', params);
    }

    function remove(emailtemplates: any) {
      const msg = gettext('Are you sure that you want delete this template?');
      if (!confirm(msg)) {
        return;
      }
      $scope.$emit('emailtemplates-list__remove', emailtemplates);
    }

    function openEmailTemplateModal(emailtemplate: any) {
      return EmailTemplates.emailTemplateModal(emailtemplate).then(updateData);
    }
  }
})();
