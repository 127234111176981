import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('warehouseTradePageView', {
    bindings: {},
    template: require('./warehouse-trade-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    'gettext',
    'PageService',
    'moment',
    'gtFilterService',
    'CoreService',
    'LogisticsService',
  ];

  function Controller(
    this: any,
    gettext: ng.gettext.gettextFunction,
    PageService: PageService,
    moment: any,
    gtFilterService: GtFilterService,
    CoreService: CoreService,
    LogisticsService: any,
  ) {
    const vm = this;
    vm.filterLevel = 'warehouse-trade-page-view';
    vm.fromDate = new Date();
    vm.fromDate.setDate(vm.fromDate.getDate() - 1);
    vm.toDate = new Date();
    vm.queryParams = {
      from_date: moment(vm.fromDate).format('YYYY-MM-DD'),
      to_date: moment(vm.toDate).format('YYYY-MM-DD'),
    };
    vm.selectedBusinessUnits = [];

    vm.applyFilter = applyFilter;
    vm.resetFilter = resetFilter;

    vm.$onInit = function () {
      vm.selectedBusinessUnits = CoreService.getBuList();
      vm.queryParams = {
        from_date: moment(vm.fromDate).format('YYYY-MM-DD'),
        to_date: moment(vm.toDate).format('YYYY-MM-DD'),
        commodity_list: vm.selectedCrops,
        owner_list: vm.selectedOwners,
        business_unit_list: vm.selectedBusinessUnits,
      };
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function applyFilter() {
      vm.filters = {
        from_date: moment(vm.fromDate).format('YYYY-MM-DD'),
        to_date: moment(vm.toDate).format('YYYY-MM-DD'),
        commodity_list: vm.selectedCrops,
        owner_list: vm.selectedOwners,
        business_unit_list: vm.selectedBusinessUnits,
      };
      gtFilterService.updateQueryParams(vm.filters, 'warehouse-trade-page-view');
    }

    function resetFilter() {
      vm.fromDate = new Date();
      vm.fromDate.setDate(vm.fromDate.getDate() - 1);
      vm.toDate = new Date();
      vm.selectedCrops = undefined;
      vm.selectedOwners = undefined;
      vm.selectedBusinessUnits = undefined;
      applyFilter();
    }

    function getPageConfig() {
      return {
        class: 'page-header-main-logistics-tab',
        buttons: [new PageService.buttons.Refresh(vm.filterLevel)],
        pages: LogisticsService.getPagesConfig(),
        filters: {
          dateSelects: [
            {
              argument: 'date',
              placeholder: gettext('Date'),
            },
          ],
          multiSelects: [
            {
              argument: 'owner_list',
              placeholder: gettext('Owner'),
              resource: 'clients.owner',
            },
            {
              argument: 'warehouse_list',
              placeholder: gettext('Warehouse'),
              resource: 'logistics.warehouse',
              queryParams: () => ({ is_group: '0' }),
            },
            {
              argument: 'commodity_list',
              placeholder: gettext('Commodity'),
              resource: 'crops.crop',
            },
          ],
        },
      };
    }
  }
})();
