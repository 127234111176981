import ng from 'angular';

import { analytics } from './analytics/analytics.module';
import { ClientFilterConfigService } from './client-filter-config.service';
import { ClientFormFieldsService } from './client-form-fields.service';
import { ClientListBlockView } from './components/client-list-block-view/client-list-block-view.component';
import { ClientListContainer } from './components/client-list-container/client-list-container.component';
import { ClientListListView } from './components/client-list-list-view/client-list-list-view.component';
import { ClientListPage } from './components/client-list-page/client-list-page.component';
import { ClientListTableView } from './components/client-list-table-view/client-list-table-view.component';
import { ClientModal } from './components/client-modal/client-modal.component';
import { ClientRelatedInfoContainer } from './components/client-related-info-container/client-related-info-container.component';
import { clientDetails } from './components/details/client-details.module';
import { PersonListBlockView } from './components/person-list-block-view/person-list-block-view.component';
import { PersonListTableView } from './components/person-list-table-view/person-list-table-view.component';
import { PersonQuickAdd } from './components/person-quick-add/person-quick-add.component';
import { PotentialsContainer } from './components/potentials-container/potentials-container.component';
import { PotentialService } from './components/potentials-container/potentials-container.service';
import { PotentialsGroupContainer } from './components/potentials-container/potentials-group-container/potentials-group-container.component';
import { RoleLimits } from './components/role-limits/role-limits.component';
import { clientsLegacyModule } from './legacy';

export const clientsModule = ng
  .module('crm.clients', [clientsLegacyModule, analytics, clientDetails])
  .component('roleLimits', RoleLimits)
  .component('clientListPage', ClientListPage)

  .component('clientListContainer', ClientListContainer)
  .component('clientListTableView', ClientListTableView)
  .component('clientListBlockView', ClientListBlockView)
  .component('clientListListView', ClientListListView)
  .component('clientModal', ClientModal)
  .component('personListTableView', PersonListTableView)
  .component('personListBlockView', PersonListBlockView)
  .component('potentialsContainer', PotentialsContainer)
  .component('potentialsGroupContainer', PotentialsGroupContainer)
  .component('personQuickAdd', PersonQuickAdd)
  .component('clientRelatedInfoContainer', ClientRelatedInfoContainer)
  .service('PotentialService', PotentialService)
  .service('ClientFormFieldsService', ClientFormFieldsService)
  .service('ClientFilterConfigService', ClientFilterConfigService).name;
