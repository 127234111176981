import React from 'react';

import { Link } from '~/shared/lib/router';
import { cn } from '~/shared/lib/utils';
import { ChevronRightIcon } from '~/shared/ui/icons';

export const PageNavigation: React.FC<{
  href: string;
  isActive: boolean;
  label: string;
}> = ({ href, isActive, label }) => {
  return (
    <Link
      to={href}
      className="group flex w-full items-center gap-2 rounded-sm py-0.5 pl-4 font-normal text-text-main-secondary transition duration-300 ease-in-out hover:bg-transparent-light"
      activeProps={{ className: 'bg-transparent-light' }}
      activeOptions={{ exact: true }}
    >
      <ChevronRightIcon
        size={16}
        className={cn(
          'rounded text-stroke-main-strong transition duration-300 ease-in-out hover:bg-background-main-quarternary group-hover:text-stroke-main-stronger',
          isActive && 'rotate-90 text-stroke-main-inverted',
        )}
      />
      <span
        className={cn(
          'text-xs font-normal text-text-main-secondary transition duration-300 ease-in-out group-hover:text-text-main-secondary',
          isActive && 'font-medium text-text-main-primary',
        )}
      >
        {label}
      </span>
    </Link>
  );
};
