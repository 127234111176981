import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

export class InvoicePositionBulkEditFieldsService {
  $rootScope: GtRootScopeService;
  gettext: ng.gettext.gettextFunction;
  constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
    this.$rootScope = $rootScope;
    this.gettext = gettext;
  }

  getFields(instance: any) {
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup.push({
      wrapper: 'gt-panel',
      fieldGroup: [
        {
          key: 'contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Contract'),
            resource: 'contracts.ContractBase',
            queryParams: { stage: 'contract' },
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.passport;
            },
          },
        },
        {
          key: 'setContractNull',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Clear contract'),
            onChange: () => {
              if (instance.setContractNull) {
                instance.contract = null;
              }
            },
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.contract;
            },
          },
        },
      ],
    });
    col2.fieldGroup.push({
      wrapper: 'gt-panel',
      fieldGroup: [
        {
          key: 'passport',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
            resource: 'passports.passport',
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.contract;
            },
          },
        },
        {
          key: 'setPassportNull',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Clear passport'),
            onChange: () => {
              if (instance.setPassportNull) {
                instance.passport = null;
              }
            },
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.passport;
            },
          },
        },
      ],
    });
    col3.fieldGroup.push({
      wrapper: 'gt-panel',
      fieldGroup: [
        {
          key: 'costsSyncStrategy',
          type: 'gt-select',
          defaultValue: 'replace',
          templateOptions: {
            label: this.gettext('Costs sync strategy'),
            placeholder: this.gettext('Choose strategy'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { value: 'replace', name: this.gettext('Replace') },
              { value: 'createNew', name: this.gettext('Create new') },
            ],
            onSelected: () => {
              if (instance.costsSyncStrategy == 'createNew') {
                instance.contractcharge = null;
              } else {
                instance.contractcharge = undefined;
              }
            },
          },
        },
      ],
    });

    return {
      formName: 'invoice-position-bulk-edit',
      fieldsDef: [col1, col2, col3],
    };
  }
}
InvoicePositionBulkEditFieldsService.$inject = ['$rootScope', 'gettext'];
