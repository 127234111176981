import React from 'react';

import { PlusIcon } from '~/shared/ui/icons';
import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { CreateViewList } from './create-view-list';
import type { ViewCreated } from '../view-configurator';

export const CreateView: React.FC<{
  viewCreated: ViewCreated;
}> = ({ viewCreated }) => {
  return (
    <section className="p-1">
      <DropdownMenuSub>
        <DropdownMenuSubTrigger
          className="flex h-8 cursor-pointer items-center gap-2 rounded-sm px-2 transition duration-300 ease-in-out hover:bg-background-main-tertiary"
          data-testid="create-view"
        >
          <PlusIcon size={14} className="text-text-main-secondary" />
          <p className="text-xs text-text-main-primary">Create view</p>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent
            className="flex w-[200px] flex-col gap-1 p-1"
            sideOffset={6}
            alignOffset={0}
            data-testid="create-view-content"
          >
            <CreateViewList viewCreated={viewCreated} />
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </section>
  );
};
