import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { InvoiceRiskReportContainer } from './invoice-risk-report-container/invoice-risk-report-container.component';
import { InvoiceRiskReportPageView } from './invoice-risk-report-page-view/invoice-risk-report-page-view.component';
import { InvoiceRiskReportTableView } from './invoice-risk-report-table-view/invoice-risk-report-table-view.component';
import { InvoiceRiskReportService } from './invoice-risk-report.service';

export const invoiceRiskReport = ng
  .module('reports.invoiceRiskReport', [])
  .service('InvoiceRiskReportService', InvoiceRiskReportService)
  .component('invoiceRiskReportPageView', InvoiceRiskReportPageView)
  .component('invoiceRiskReportContainer', InvoiceRiskReportContainer)
  .component('invoiceRiskReportTableView', InvoiceRiskReportTableView)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('reports.invoiceRiskReport', {
        url: '/invoice-risk-report',
        component: 'invoiceRiskReportPageView',
        data: {
          pageTitle: gettext('Invoice risk report'),
        },
        resolve: {
          filterLevel: () => {
            return 'invoice-risk-report-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('invoice-risk-report-page-view');
            },
          ],
        },
      });
    },
  ]).name;
