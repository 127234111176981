import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('core.legacy').controller('GtWrapperController', Controller);

  Controller.$inject = ['$location', '$state', '$rootScope'];

  function Controller(
    this: any,
    $location: ng.ILocationService,
    $state: ng.ui.IStateService,
    $rootScope: GtRootScopeService,
  ) {
    const vm = this;
    vm.hideNavbar = false;

    activate();

    ////////////////

    function activate() {
      if ($rootScope.user.profile.hide_navbar || $rootScope.isDeviceMobile) {
        vm.hideNavbar = true;
      } else {
        vm.hideNavbar = false;
      }

      vm.urlParams = $location.search() || {};
      vm.isNotIncludedByState = isNotIncludedByState;
      vm.isIncludedByState = isIncludedByState;
      // Set the tab based on the state
      if (
        isIncludedByState('lineup') ||
        isIncludedByState('reports') ||
        isIncludedByState('finances.pl') ||
        isIncludedByState('finances.balance') ||
        isIncludedByState('pl.analyticalPlanFactPnlReport') ||
        isIncludedByState('pl.passport')
      ) {
        vm.tab = 'clients_tab';
      }
      if (
        isIncludedByState('settings') ||
        isIncludedByState('team') ||
        isIncludedByState('documents') ||
        isIncludedByState('drivers') ||
        isIncludedByState('*.*.driver-details') ||
        isIncludedByState('*.*.vehicle-details') ||
        isIncludedByState('vehicles') ||
        isIncludedByState('company.quality-rule-templates') ||
        isIncludedByState('tasksTemplates') ||
        isIncludedByState('custom-fields.values') ||
        isIncludedByState('*.*.trader') ||
        isIncludedByState('notifications')
      ) {
        vm.tab = 'pl_tab';
      }
      if (
        isIncludedByState('clients') ||
        isIncludedByState('*.*.client') ||
        isIncludedByState('updates') ||
        isIncludedByState('mailing') ||
        isIncludedByState('analytic.counterparty') ||
        isIncludedByState('crm')
      ) {
        vm.tab = 'crm_tab';
      }
      if (
        isIncludedByState('contract') ||
        isIncludedByState('contracts') ||
        isIncludedByState('tickets') ||
        isIncludedByState('market') ||
        isIncludedByState('*.*.passport') ||
        isIncludedByState('*.generalagreement') ||
        isIncludedByState('*.*.request') ||
        isIncludedByState('*.*.contract') ||
        isIncludedByState('*.multiticket') ||
        isIncludedByState('*.multicontract') ||
        isIncludedByState('multiticketsList') ||
        isIncludedByState('analytic.counterparty') ||
        isIncludedByState('abstract-passports') ||
        isIncludedByState('multicontractsList')
      ) {
        vm.tab = 'prices_tab';
      }
      if (
        isIncludedByState('logistics') ||
        isIncludedByState('*.*.logistic') ||
        isIncludedByState('*.logisticslogistics') ||
        isIncludedByState('*.*.customs-cargo-declaration-details') ||
        isIncludedByState('warehouse') ||
        isIncludedByState('vessels') ||
        isIncludedByState('*.*.vessel') ||
        isIncludedByState('voyages') ||
        isIncludedByState('analytic.logistics')
      ) {
        vm.tab = 'logistics_tab';
      }
      if (
        isIncludedByState('finances') ||
        isIncludedByState('analytic.invoices') ||
        isIncludedByState('*.*.payment')
      ) {
        vm.tab = 'payments_tab';
      }
    }

    function isIncludedByState(state: any) {
      const statePart = state.split('.').pop();

      return $location.url().includes(statePart);
    }
    function isNotIncludedByState(state: any) {
      return !$state.includes(state);
    }
  }
})();
