import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';

import template from './certificate-dates-report-table-view.html?raw';

export const CertificateDatesReportTableView = {
  bindings: {
    filterLevel: '<',
    reportData: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'LogisticsService',
    'gtFilterService',
    class {
      LogisticsService: any;
      filterLevel = 'certificate-dates-report-table-view';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(LogisticsService: any, gtFilterService: GtFilterService) {
        this.LogisticsService = LogisticsService;
        this.gtFilterService = gtFilterService;
      }

      openLogisticModal(logistic: any) {
        this.LogisticsService.logisticModal(logistic).then(() =>
          this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel),
        );
      }
    },
  ],
};
