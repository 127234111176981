import React from 'react';

import { useDeleteRecordsDialog } from '~/core/global-dialog';
import { DataSetView } from '~/core/page-views/components/data-set-view';
import type { PageViewsStore } from '~/core/page-views/services/page-views-store';
import type { DataViewProperties } from '~/features/data-view-fields/lib';
import { useObservableEagerState } from '~/shared/lib/state';

import { counterpartyPropertiesBuilder } from './helpers';
import { type CounterpartyDTO } from '../../lib';

export const CounterpartiesDatasetView: React.FC<{
  records: CounterpartyDTO[];
  editedRecords: CounterpartyDTO[];
  properties: DataViewProperties[];
  viewStore: PageViewsStore;
  changes: Partial<CounterpartyDTO>[];
  loading: boolean;
  editing: boolean;
  onDelete: (id: string) => Promise<void>;
  onEdit: (part: Partial<CounterpartyDTO>[]) => void;
  onSave: () => void;
}> = ({
  records,
  properties,
  viewStore,
  loading,
  editing,
  editedRecords,
  changes,
  onEdit,
  onDelete,
  onSave,
}) => {
  const [selectedRecordIds, setSelectedRecordIds] = React.useState<string[]>([]);

  const currentView = useObservableEagerState(viewStore.currentView$);

  const { dialogToggled } = useDeleteRecordsDialog({
    records: selectedRecordIds,
    onDelete: () => selectedRecordIds.map((id) => onDelete(id)),
  });

  const dataProperties = counterpartyPropertiesBuilder(properties);

  return (
    <section className="mb-1" data-testid="counterparties-list">
      <DataSetView<CounterpartyDTO>
        data={{
          records,
          properties: dataProperties,
        }}
        editedRecords={editedRecords}
        selectable={true}
        loading={loading}
        viewType={currentView.type}
        onSelect={setSelectedRecordIds}
        editing={editing}
        selectedRecordIds={selectedRecordIds}
        onEdit={onEdit}
        changes={changes}
        onDelete={() => dialogToggled(true)}
        onSave={onSave}
      />
    </section>
  );
};
