import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './shipment-schedule-modal.html?raw';

export const ShipmentScheduleModal = {
  bindings: {
    modalInstance: '<',
    item: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'ShipmentScheduleService',
    'AccountsService',
    'CoreService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      AccountsService: any;
      CoreService: CoreService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      ShipmentScheduleService: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      item: any;
      logEntries: any;
      logEntriesCount = 0;
      modalInstance: any;
      shipmentSchedule: any;
      tab: any;
      updateQuantity: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        ShipmentScheduleService: any,
        AccountsService: any,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.ShipmentScheduleService = ShipmentScheduleService;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
        this.form = undefined;
        this.fields = [];
        this.tab = 'edit';
      }

      $onInit() {
        this.updateQuantity = () => {
          this.shipmentSchedule.quantity = Math.ceil(
            this.shipmentSchedule.volume / this.shipmentSchedule.packing || 1,
          );
        };
        this.shipmentSchedule = this.item || {};
        if (this.shipmentSchedule.id) {
          this.updateData();
        } else {
          this.updateFields();
        }
        this.$scope.$watch(() => this.shipmentSchedule.volume, this.updateQuantity);
        this.$scope.$watch(() => this.shipmentSchedule.packing, this.updateQuantity);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ShipmentScheduleService.getModalData({ id: this.shipmentSchedule.id }).then(
          (data: any) => {
            this.shipmentSchedule = data;
            this.updateQuantity();
            this.updateFields();
            this.updateLogEntries();
            this.GtUtils.overlay('hide');
          },
        );
      }

      updateLogEntries() {
        return this.AccountsService.LogEntry.query(
          {
            shipment_schedule_id: this.shipmentSchedule.id,
          },
          (data: any) => {
            this.logEntries = data.results;
            this.logEntriesCount = data.count;
          },
        );
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure?'))) {
          return;
        }
        return this.ShipmentScheduleService.delete({ id: this.shipmentSchedule.id }).then(() => {
          this.GtUtils.notify(this.gettext('Shipment plan deleted.'));
          this.close(null, true);
        }).$promise;
      }

      save() {
        const packingSegments = this.shipmentSchedule.packing.toString().split('.');
        const packingInvalid =
          packingSegments[0].length > 3 || (packingSegments[1] && packingSegments[1].length > 3);
        if (packingInvalid) {
          this.GtUtils.notify(
            this.gettext(
              'Packing is too large number, less than 6 digits required (3 for integer, 3 for decimal).',
            ),
            'error',
          );
          return;
        }

        return this.ShipmentScheduleService.bulkSave(this.shipmentSchedule).then(
          (data: any) => {
            this.GtUtils.notify(this.gettext('Shipment plan saved.'));
            this.modalInstance.close(data || 'close');
          },
          (data: any) => {
            this.shipmentSchedule.errors = data.data;
            this.GtUtils.errorClb(data);
          },
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col3: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col4: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('INFO'),
          },
          fieldGroup: [
            {
              key: 'status',
              type: 'gt-select',
              defaultValue: 'new',
              templateOptions: {
                label: this.gettext('Status'),
                placeholder: this.gettext('Choose status'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: this.gettext('New'), value: 'new' },
                  { name: this.gettext('Approved'), value: 'approved' },
                  { name: this.gettext('Process'), value: 'process' },
                  { name: this.gettext('Executed'), value: 'executed' },
                  { name: this.gettext('Cancelled'), value: 'cancelled' },
                ],
              },
            },
            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Number'),
                required: true,
                addon: this.gettext('#'),
              },
            },
            {
              key: 'start_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Start date'),
                placeholder: this.gettext('Select date'),
                required: true,
                type: 'date',
              },
            },
            {
              key: 'end_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('End date'),
                placeholder: this.gettext('Select date'),
                required: true,
                type: 'date',
              },
            },
            {
              key: 'responsible',
              type: 'gt-ui-select',
              defaultValue: this.$rootScope.user.id,
              templateOptions: {
                label: this.gettext('Responsible'),
                placeholder: this.gettext('Select User to be responsible'),
                resource: 'auth.User',
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => this.CoreService.getDefaultBuId(this.shipmentSchedule),
              templateOptions: {
                label: this.gettext('Business unit'),
                placeholder: this.gettext('Select Business Unit'),
                queryParams: this.GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Contract'),
          },
          fieldGroup: [
            {
              key: 'purchase_contract',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Purchase contract'),
                placeholder: this.gettext('Choose contract'),
                resource: 'contracts.PurchaseContract',
                addIcon: this.GtUtils.getIcon('contracts.PurchaseContract'),
              },
              hideExpression: 'model.sale_contract > 0',
            },
            {
              key: 'sale_contract',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Sale contract'),
                placeholder: this.gettext('Choose contract'),
                resource: 'contracts.SaleContract',
                addIcon: this.GtUtils.getIcon('contracts.SaleContract'),
              },
              hideExpression: 'model.purchase_contract > 0',
            },
          ],
        });

        col3.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Cargo'),
          },
          fieldGroup: [
            {
              key: 'volume',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Volume'),
                placeholder: this.gettext('Type volume'),
                required: true,
                type: 'number',
                addon: this.gettext('t'),
              },
            },
            {
              key: 'packing',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Packing'),
                placeholder: this.gettext('Type packing'),
                required: true,
                type: 'number',
                addon: this.gettext('t'),
              },
            },
            {
              key: 'quantity',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('quantity'),
                type: 'number',
                disabled: true,
              },
            },
          ],
        });

        col4.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Destination'),
          },
          fieldGroup: [
            {
              key: 'station_destination',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Station destination'),
                placeholder: this.gettext('Station'),
                resource: 'logistics.Station',
              },
            },
            {
              key: 'cargo_receiver',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Cargo receiver'),
                placeholder: this.gettext('Choose counterparty'),
                resource: 'clients.ClientRole',
              },
            },
            {
              key: 'terminal',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Terminal'),
                placeholder: this.gettext('Terminal'),
                resource: 'logistics.Terminal',
              },
            },
            {
              key: 'receiver_code',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('receiver code'),
                placeholder: this.gettext('receiver code'),
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Additional Info'),
                placeholder: this.gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
          ],
        });

        return {
          formName: 'shipment-schedule-edit-modal',
          fieldsDef: [col1, col2, col3, col4],
        };
      }
    },
  ],
};
