import React from 'react';

import { CSS, useSortable } from '~/shared/lib/dnd';
import { CloseEyeIcon, EyeIcon } from '~/shared/ui/icons';

import { DragHandle } from './drag-handle';
import { SortableItemContext } from './sortable-item-context';
import type { ReorderedColumn } from '../../types';
import { EditProperty } from '../edit-property';

export const SortableProperty = ({
  children,
  id,
  visible,
  visibilityChange,
  column,
}: React.PropsWithChildren<{
  id: string;
  visible: boolean;
  visibilityChange: () => void;
  column: ReorderedColumn;
}>) => {
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const context = React.useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );
  const style: React.CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <div
        className="flex w-full items-center justify-between rounded-sm p-1"
        ref={setNodeRef}
        style={style}
      >
        <div className="flex items-center gap-1">
          <DragHandle />
          <p className="text-xs text-text-main-primary">{children}</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="mr-1 cursor-pointer rounded-sm transition duration-300 ease-in-out hover:bg-background-main-tertiary">
            {visible ? (
              <EyeIcon
                size={12}
                className="text-stroke-main-stronger"
                onClick={visibilityChange}
                data-testid="column-visible"
              />
            ) : (
              <CloseEyeIcon
                size={12}
                className="text-stroke-main-stronger"
                onClick={visibilityChange}
                data-testid="column-hidden"
              />
            )}
          </div>
          <EditProperty value={column} open={openEdit} onOpen={setOpenEdit} />
        </div>
      </div>
    </SortableItemContext.Provider>
  );
};
