import ng from 'angular';

import { settingsParsing } from './settings-parsing/settings-parsing.module';
import { SettingsPersonalContainer } from './settings-personal-container/settings-personal-container.component';
import { UserSettingsContainer } from './user-settings-container/user-settings-container.component';

export const userSettings = ng
  .module('accounts.userSettings', [settingsParsing])
  .component('userSettingsContainer', UserSettingsContainer)
  .component('settingsPersonalContainer', SettingsPersonalContainer).name;
