import ng from 'angular';

(function () {
  'use strict';
  ng.module('reports.legacy').component('pricesPositionInlineCreate', {
    bindings: {
      onSave: '<',
      newPrice: '<',
      m2mPriceDetailing: '<?',
    },
    template: require('./prices-position-inline-create.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  function Controller(arg: any) {
    const vm = arg;
    vm.savePrice = savePrice;
    vm.users = [];
    vm.count = 0;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'prices-position';
      vm.newPrice = vm.newPrice || {};
    };

    ////////////////

    function savePrice() {
      vm.onSave(vm.newPrice);
    }
  }
})();
