import React from 'react';

import type { IconComponent } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { ViewTypeList } from './view-type-list';
import type { ViewVariant } from '../../../data-set-view';
import type { ViewConfigChanged } from '../../types';

export const ViewType = ({
  viewId,
  isOpen,
  currentViewType,
  onOpenChanged,
  Icon,
  viewConfigChanged,
}: {
  viewId: number;
  isOpen: boolean;
  currentViewType: ViewVariant;
  onOpenChanged: (open: boolean) => void;
  Icon: IconComponent;
  viewConfigChanged: ViewConfigChanged;
}) => {
  return (
    <DropdownMenu open={isOpen} onOpenChange={onOpenChanged}>
      <DropdownMenuTrigger className="flex flex-col gap-1 text-xs outline-none">
        <p className="text-xs font-semibold text-text-main-tertiary">View type</p>
        <div className="flex h-8 w-full items-center gap-2 border border-stroke-main-medium bg-transparent-lighter p-2 text-left">
          <Icon size={14} className="text-stroke-main-stronger" />
          <p className="capitalize text-text-main-tertiary">{currentViewType}</p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex w-[200px] flex-col gap-0.5 p-2">
        <ViewTypeList
          viewId={viewId}
          currentViewType={currentViewType}
          viewConfigChanged={viewConfigChanged}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
