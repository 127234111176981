import type { ClientTableInfo, ColumnParams } from '~/shared/api';
import { snakeCaseToCamelCase } from '~/shared/lib/utils';

import type { CounterpartyDTO } from '../../lib';

export const fromClientTableInfo = (counterparty: ClientTableInfo): CounterpartyDTO => {
  return {
    id: String(counterparty.id),
    title: counterparty.name,
    isProducer: counterparty.is_producer,
    companyGroupTitle: `${counterparty.company_group_title}`,
    supplierNames: counterparty.supplier_names,
    exporterNames: counterparty.exporter_names,
    roleNames: counterparty.role_names,
    companyCode: `${counterparty.company_code}`,
    status: (counterparty.status ?? 'New') as CounterpartyDTO['status'],
    dateConfirmation: `${counterparty.date_confirmation}`,
    termOfProxy: `${counterparty.term_of_proxy}`,
    confirmationInfo: `${counterparty.confirmation_info}`,
    blacklisted: counterparty.blacklisted ?? false,
    clientDataIsChecked: counterparty.client_data_is_checked ?? false,
    bankDataIsChecked: counterparty.bank_data_is_checked ?? false,
    phone: `${counterparty.phone}`,
    personsCount: counterparty.persons_count,
    lastUpdate: {
      update: counterparty.last_update.update,
      messageType: counterparty.last_update.message_type,
      updateTime: counterparty.last_update.update_time,
    },
    updatesCount: counterparty.updates_count,
    documentsCount: counterparty.documents_count,
    contractsCount: counterparty.contracts_count,
    invoices: counterparty.invoices_count,
    logisticsCount: counterparty.logistics_count,
    relations: counterparty.relations,
    additionalInfo: `${counterparty.additional_info}`,
    createTime: `${counterparty.create_time}`,
    updateTime: `${counterparty.update_time}`,
    authorFirstName: counterparty.author_first_name,
    authorLastName: counterparty.author_last_name,
    authorAvatar: counterparty.author_avatar,
    editorFirstName: `${counterparty.editor_first_name}`,
    editorLastName: `${counterparty.editor_last_name}`,
    editorAvatar: `${counterparty.editor_avatar}`,
    businessUnits:
      counterparty.business_units?.map((unit) => ({
        id: unit.id,
        title: unit.title,
      })) ?? [],
    relatedCounterparties:
      counterparty.related_counterparties?.map((i) => ({
        id: i.id,
        name: i.name,
        fromClientId: i.from_client_id,
        toClientId: i.to_client_id,
      })) ?? [],
    approvalId: counterparty.approval_id,
    approvalConfig: counterparty.approval_config ?? null,
    approvalStatus: counterparty.approval_status,
    contentType: counterparty.content_type,
    // check if this is correct and write typeguard
    regularStatus: (counterparty.regular_status ?? 'active') as CounterpartyDTO['regularStatus'],
    customStatus: counterparty.custom_status ?? 0,
    countryName: `${counterparty.country_name}`,
    regionTitles: counterparty.region_titles ?? [],
    districtTitle: `${counterparty.district_title}`,
    districtPhoneCode: `${counterparty.district_phone_code}`,
    cityCode: counterparty.city ?? 0,
    currentAddress: `${counterparty.current_address}`,
    person: `${counterparty.person}`,
    phoneAdditional: `${counterparty.phone_additional}`,
    email: `${counterparty.email}`,
    website: `${counterparty.website}`,
    city: `${counterparty.city_title}`,
    address: `${counterparty.address}`,
    customClientrolesNameList: counterparty.custom_clientroles_name_list ?? [],
    daysBeforeDeadlineToNotify: counterparty.days_before_deadline_to_notify,
    sapVendorNumber: `${counterparty.sap_vendor_number}`,
    abbreviation: `${counterparty.abbreviation}`,
    customStatusTitle: `${counterparty.custom_status_title}`,
    customStatusCodename: `${counterparty.custom_status_codename}`,
    doNumOfVoyages: counterparty.do_num_of_voyages,
    hoNumOfVoyage: counterparty.ho_num_of_voyage,
    customFieldsData: counterparty.custom_fields_data.map((i) => ({
      fieldId: i.field_id,
      fieldName: i.field_name,
      fieldPurpose: {
        id: i.field_puprose.id,
        customFieldId: i.field_puprose.customfield_id,
        contentTypeId: i.field_puprose.contenttype_id,
      },
      value: `${i.value}`,
    })),
    renewalDate: `${counterparty.renewal_date}`,
    predictionsOrder: counterparty.predictions_order ?? 0,
    warehousesList: `${counterparty.warehouses_list}`,
    paymentConditionsOptionTitle: `${counterparty.payment_conditions_option_title}`,
  };
};

export const toPatchedClientRequest = (counterparty: Partial<CounterpartyDTO>) => {
  return {
    name: counterparty.title,
    is_producer: counterparty.isProducer,
    // company_group_title: counterparty.companyGroupTitle,
    // supplier_names: counterparty.supplierNames,
    // exporter_names: counterparty.exporterNames,
    role_names: counterparty.roleNames,
    company_code: counterparty.companyCode,
    status: counterparty.status,
    date_confirmation: counterparty.dateConfirmation,
    term_of_proxy: counterparty.termOfProxy,
    confirmation_info: counterparty.confirmationInfo,
    blacklisted: counterparty.blacklisted,
    client_data_is_checked: counterparty.clientDataIsChecked,
    bank_data_is_checked: counterparty.bankDataIsChecked,
    phone: counterparty.phone,
    // persons_count: counterparty.personsCount,
    // last_update: counterparty.lastUpdate,
    // updates_count: counterparty.updatesCount,
    // documents_count: counterparty.documentsCount,
    // contracts_count: counterparty.contractsCount,
    // invoices_count: counterparty.invoicesCount,
    // logistics_count: counterparty.logisticsCount,
    relations: counterparty.relations,
    additional_info: counterparty.additionalInfo,
    // create_time: counterparty.createTime,
    // update_time: counterparty.updateTime,
    // author_first_name: counterparty.authorFirstName,
    // author_last_name: counterparty.authorLastName,
    // author_avatar: counterparty.authorAvatar,
    // editor_first_name: counterparty.editorFirstName,
    // editor_last_name: counterparty.editorLastName,
    // editor_avatar: counterparty.editorAvatar,
    // business_units: counterparty.businessUnits.map((unit) => unit.id),
    // related_counterparties: counterparty.relatedCounterparties.map((i) => ({
    //   id: i.id,
    //   name: i.name,
    //   from_client_id: i.fromClientId,
    //   to_client_id: i.toClientId,
    // })),
    // approval_id: counterparty.approvalId,
    approval_config: counterparty.approvalConfig,
    approval_status: counterparty.approvalStatus,
    // content_type: counterparty.contentType,
    regular_status: counterparty.regularStatus,
    custom_status: counterparty.customStatus,
    // country_name: counterparty.countryName,
    // region_titles: counterparty.regionTitles,
    // district_title: counterparty.districtTitle,
    // district_phone_code: counterparty.districtPhoneCode,
    city: counterparty.city,
    current_address: counterparty.currentAddress,
    person: counterparty.person,
    phone_additional: counterparty.phoneAdditional,
    email: counterparty.email,
    website: counterparty.website,
    // city_title: counterparty.cityTitle,
    address: counterparty.address,
    // custom_clientroles_name_list: counterparty.customClientrolesNameList,
    days_before_deadline_to_notify: counterparty.daysBeforeDeadlineToNotify,
    sap_vendor_number: counterparty.sapVendorNumber,
    abbreviation: counterparty.abbreviation,
    // custom_status_title: counterparty.customStatusTitle,
    // custom_status_codename: counterparty.customStatusCodename,
    // do_num_of_voyages: counterparty.doNumOfVoyages,
    // ho_num_of_voyage: counterparty.hoNumOfVoyage,
    // custom_fields_data: counterparty.customFieldsData,
    // renewal_date: counterparty.renewalDate,
    predictions_order: counterparty.predictionsOrder,
    // warehouses_list: counterparty.warehousesList,
    // payment_conditions_option_title: counterparty.paymentConditionsOptionTitle,
  };
};

export const fromColumnParamsList = (properties: ColumnParams[]) => {
  return properties.map((property) => ({
    key: snakeCaseToCamelCase(property.column_name),
    title: property.column_name.charAt(0).toUpperCase() + property.column_name.slice(1),
    visible: property.visible as unknown as boolean,
    position: property.index ?? 0,
    userTitle: property.new_title as unknown as string,
  }));
};
