import React from 'react';

import { MultiSelectRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const MultiselectCellRead = <T,>({ value, cellTemplateContext }: CellTypeProps<T>) => {
  return (
    <MultiSelectRead
      value={value as string[]}
      options={cellTemplateContext?.options ?? []}
      cellTemplateContext={cellTemplateContext}
    />
  );
};
