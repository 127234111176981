export const GtDraggableElement = () => {
  return {
    restrict: 'A',
    scope: { data: '<' },
    link($scope: ng.IScope, $element: ng.IAugmentedJQuery) {
      $element[0].ondragstart = ($event: any) => {
        let dataTransfer = '';
        if (typeof ($scope as any).data == 'object') {
          dataTransfer = JSON.stringify(($scope as any).data);
        } else {
          dataTransfer = ($scope as any).data;
        }

        $event.dataTransfer.setData('text/plain', dataTransfer);
      };
      $element[0].draggable = true;
      $element[0].style.cursor = 'grab';
    },
  };
};
