import type ng from 'angular';

import type { FinancesService } from '~/app/finances/legacy/finances.srv';
import { getModalRoot } from '~/shared/ui/modal';

export class TransportationStorageExpenseService {
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  FinancesService: FinancesService;
  TransportationStorageExpensePositionResource: any;
  TransportationStorageExpenseResource: any;
  gettext: ng.gettext.gettextFunction;
  moment: any;
  constructor(
    $q: ng.IQService,
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    moment: any,
    gettext: ng.gettext.gettextFunction,
    FinancesService: FinancesService,
  ) {
    this.$q = $q;
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.moment = moment;
    this.moment.defaultFormat = 'YYYY-MM-DD';

    this.gettext = gettext;
    this.FinancesService = FinancesService;
    this.TransportationStorageExpenseResource = $resource(
      '/api/logistics/transportation-storage-expenses/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        predictions: {
          method: 'GET',
          url: '/api/logistics/transportation-storage-expenses/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/transportation-storage-expenses/export_column_names/',
        },
        bulkCreateOrUpdateWithPositions: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/transportation-storage-expenses/bulk-create-or-update-with-positions/',
        },
        preparePositionsData: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/transportation-storage-expenses/prepare-positions-data/',
        },
        prepareInvoiceData: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/transportation-storage-expenses/:id/prepare-invoice-data/',
        },
      },
    );
    this.TransportationStorageExpensePositionResource = $resource(
      '/api/logistics/transportation-storage-expense-positions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          url: '/api/logistics/transportation-storage-expense-position/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/transportation-storage-expense-position/export_column_names/',
        },
        bulkCreateOrUpdateWithPositions: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/transportation-storage-expense-position/bulk-create-or-update-with-positions/',
        },
      },
    );
  }
  getExpensesList(params: any) {
    return this.TransportationStorageExpenseResource.query({
      serializer: 'depth',
      ...params,
    }).$promise;
  }
  expenseModal(expense: any, extra: any) {
    if (!expense) {
      expense = {};
    }
    expense = { ...expense };
    return this.$uibModal.open({
      backdrop: 'static',
      template: /* html */ `
        <transportation-storage-expense-modal
          expense="expense"
          modal-instance="$uibModalInstance"
        ></transportation-storage-expense-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).expense = expense;
          ($scope as any).extra = extra;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }
  cloneExpense(expense: any, extra: any) {
    const newExpense = { ...expense };
    newExpense.id = undefined;
    newExpense.positions = undefined;
    return this.expenseModal(newExpense, extra);
  }
  getExpense(expenseId: any) {
    return this.TransportationStorageExpenseResource.get({ id: expenseId }).$promise;
  }
  saveExpense(expense: any) {
    if (expense.id) {
      return this.TransportationStorageExpenseResource.update({ id: expense.id }, expense).$promise;
    }
    return this.TransportationStorageExpenseResource.save(expense).$promise;
  }
  deleteExpense(expense: any) {
    return this.TransportationStorageExpenseResource.delete({ id: expense.id }).$promise;
  }
  preparePositionsData(expense: any) {
    return this.TransportationStorageExpenseResource.preparePositionsData({
      date: this.moment(expense.date).format(),
      start_date: this.moment(expense.start_date).format(),
      end_date: this.moment(expense.end_date).format(),
      commodity: expense.commodity,
      batch: expense.batch,
      contract_position: expense.contract_position,
      warehouse_or_parent: expense.warehouse,
      strategy: expense.strategy,
    }).$promise.then((data: any) => this.updateDistributionBase(expense, data.results));
  }
  updateDistributionBase(expense: any, positions: any) {
    const getKey = (pos: any) =>
      JSON.stringify({
        commodity: pos.commodity,
        batch: pos.batch,
        contract_position: pos.contract_position,
        warehouse: pos.warehouse,
      });
    const positionsMap = expense.positions
      .filter((pos: any) => pos.id)
      .reduce((res: any, pos: any) => {
        res[getKey(pos)] = pos.id;
        return res;
      }, {});
    expense.positions = positions.map((pos: any) => ({
      ...pos,
      id: positionsMap[getKey(pos)],
    }));
  }
  savePosition(position: any) {
    return this.TransportationStorageExpensePositionResource.save(position).$promise;
  }
  positionModal(position: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: /* html */ `
        <transportation-storage-position-modal
          position="position"
          modal-instance="$uibModalInstance"
        ></transportation-storage-position-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).position = position;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }

  deletePosition(positionId: any) {
    return this.TransportationStorageExpensePositionResource.delete({ id: positionId }).$promise;
  }

  getExpenseWarehousesCount(expenseId: any) {
    return this.getExpense(expenseId).then(
      (expense: any) =>
        new Set([expense.warehouse, ...expense.positions.map((pos: any) => pos.warehouse)]).size,
    );
  }
  getInvoicingMethod(expenseId: any) {
    return this.getExpenseWarehousesCount(expenseId).then((count: number) => {
      if (count === 1) {
        return 'main_warehouse';
      }
      const messageText =
        'Do you want to group costs by main warehouse? Other way costs will be grouped by warehouse from position';
      return confirm(this.gettext(messageText)) ? 'main_warehouse' : 'warehouse_from_positions';
    });
  }

  createInvoice(expenseId: any) {
    return this.getInvoicingMethod(expenseId).then((invoicingMethod: any) =>
      this.TransportationStorageExpenseResource.prepareInvoiceData({
        id: expenseId,
        invoicing_method: invoicingMethod,
      }).$promise.then((res) => this.FinancesService.financeModal(res)),
    );
  }
}
TransportationStorageExpenseService.$inject = [
  '$q',
  '$resource',
  '$uibModal',
  'moment',
  'gettext',
  'FinancesService',
];
