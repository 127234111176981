import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.emailTemplates').controller('PreviewEmailModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'GtUtils', 'gettext', 'body', 'recipients', 'extra'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    body: any,
    recipients: any,
    extra: any,
  ) {
    const vm = this;
    vm.close = close;
    vm.body = body;
    vm.extra = extra;
    vm.recipients = recipients;

    vm.copyToClipboard = copyToClipboard;

    vm.$onInit = function () {};

    function copyToClipboard(value: any) {
      GtUtils.copyToClipboard(value);
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return false;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
