import ng from 'angular';

import template from './gt-table.html?raw';

export const GtTableComponent = {
  bindings: {
    options: '<',
    data: '<',
    overflowVisible: '<?',
  },
  template,
  controller: [
    '$scope',
    '$compile',
    '$q',
    '$timeout',
    'GtTableService',
    class {
      $compile: any;
      $q: ng.IQService;
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      GtTableService: any;
      _rowTpl: any;
      _totalTpl: any;
      activeTab: any;
      activeTabIcon: any;
      activeTabTitle: any;
      allSelected: any;
      columns: any;
      data: any;
      forceRebuild: any;
      gtTableAlignColHeight: any;
      hovering: any;
      options: any;
      rebuildAlignTotalRowTrigger: any;
      renderedRows: any;
      renderedTotalBlocks: any;
      renderedTotalRow: any;
      showFilters: any;
      tableClasses: any;
      constructor(
        $scope: ng.IScope,
        $compile: any,
        $q: ng.IQService,
        $timeout: ng.ITimeoutService,
        GtTableService: any,
      ) {
        this.$scope = $scope;
        this.$compile = $compile;
        this.$q = $q;
        this.$timeout = $timeout;
        this.GtTableService = GtTableService;

        this.renderedRows = {};
        this.renderedTotalRow = undefined;
        this.rebuildAlignTotalRowTrigger = false;
        this.renderedTotalBlocks = undefined;
        this._rowTpl = '';
        this._totalTpl = '';
        this.hovering = false;
        this.allSelected = false;
        this.columns = [];
        this.tableClasses = [];
        this.showFilters = undefined;
        this.forceRebuild = false;
        this.activeTab = undefined;
        this.activeTabTitle = undefined;
        this.activeTabIcon = undefined;
        this.gtTableAlignColHeight = {
          alighColHeight: true,
          colSelector: 'tr td',
          watch: new Date(),
        };
      }

      $onChanges(changes: any) {
        let defer = this.$q.when();

        if (changes.data) {
          this.data.rows = this.data.rows || [];
          this.data.count = this.data.count || 0;
        }

        if (changes.options || this.forceRebuild) {
          if (this.activeTab) {
            this.options.activeTab = this.activeTab;
            this.options.activeTabTitle = this.activeTabTitle;
            this.options.activeTabIcon = this.activeTabIcon;
          } else {
            this.activeTab = this.options.activeTab;
            this.activeTabTitle = this.options.activeTabTitle;
            this.activeTabIcon = this.options.activeTabIcon;
          }
          defer = defer.then(() => this.rebuildTemplate());
        }

        if (changes.data && !this.forceRebuild) {
          defer.then(() => this.setData());
        }
        this.gtTableAlignColHeight.watch = new Date();
      }

      rebuildTemplate() {
        this.destroyTemplates();
        this.addInnerApi();
        this.defineShowFiltersButton();
        return this.updateColumns()
          .then(() => this.setData())
          .then(() => this.updateTableClass())
          .then(() => (this.forceRebuild = false));
      }

      destroyTemplates() {
        this.renderedRows = {};
        this.renderedTotalRow = undefined;
        this.renderedTotalBlocks = undefined;
        this._rowTpl = '';
        this._totalTpl = '';
      }

      openColumnParamsModal() {
        return this.GtTableService.columnParamsModal(this.options).then(() => {
          this.forceRebuild = true;
          this.rebuildTemplate();
        });
      }

      setData() {
        const newRenderedRows = {};
        this.renderedTotalRow = undefined;
        this.renderedTotalBlocks = undefined;

        this.data.rows.forEach((item: any, index: any) => {
          // MGRAIN-3987 - check to prevent compiling blank templates
          const template = this.getRowTemplate();
          if (template) {
            const row = this.getRenderedRow(index, this.getRowTemplate());
            row.scope().item = item;
            row.scope().index = index;
            newRenderedRows[index] = row;
          }
        });

        this.data.count = this.data.count || this.data.rows.length;
        this.renderedRows = newRenderedRows;

        if (this.showTotalRow() && this.getTotalRowTemplate() && this.data.count) {
          // wait for renderer queue
          this.$timeout(() => {
            this.renderedTotalRow = this.renderRow(this.getTotalRowTemplate());
            this.renderedTotalRow.scope().item = this.data.total;

            const totalBlocksTemplate = this.getTotalBlocksTemplate();
            if (totalBlocksTemplate) {
              this.renderedTotalBlocks = this.renderRow(totalBlocksTemplate);
              this.renderedTotalBlocks.scope().item = this.data.total;
            }
            this.rebuildAlignTotalRowTrigger = !this.rebuildAlignTotalRowTrigger;
          }, 0);
        }
      }

      showTotalRow() {
        // @ts-ignore
        return this.data.total && !ng.equals(this.data.total, {});
      }

      updateColumns() {
        if (!this.options.activeTab && this.options.tabs?.length) {
          this.options.activeTab = this.options.tabs[0].serializer;
          this.activeTab = this.options.activeTab;
        }
        if (!this.options.activeTabTitle && this.options.tabs?.length) {
          this.options.activeTabTitle = this.options.tabs[0].title;
          this.activeTabTitle = this.options.activeTabTitle;
        }
        if (!this.options.activeTabIcon && this.options.tabs?.length) {
          this.options.activeTabIcon = this.options.tabs[0].icon;
          this.activeTabIcon = this.options.activeTabIcon;
        }

        return this.GtTableService.getColumns(this.options).then((columns: any) => {
          if (this.options.numberInput) {
            columns.unshift({
              columnName: '$_numberInput',
              title: this.options.numberInputCellTitle || 'Input number',
              cellTemplate:
                this.options.numberInputCellTemplate ||
                `<input
              type="number"
              ng-model="item.$_inputedNumber"
            />`,
            });
          }
          if (this.options.selection) {
            columns.unshift({
              columnName: '$_selection',
              title: 'all',
              onHeadClick: () => {
                this.allSelected = !this.allSelected;
                this.data.rows.forEach((item: any) => (item.$_selected = this.allSelected));
                this.options.allSelectedCallback?.(this.allSelected);
              },
              cellTemplate:
                this.options.selectionCellTemplate ||
                '<input type="checkbox" ng-model="item.$_selected">',
            });
          }
          this.columns = columns;
        });
      }

      updateTableClass() {
        if (this.options.tableAltClass && this.columns.length > 15) {
          this.tableClasses = this.options.tableAltClass;
        } else {
          this.tableClasses = (this.options.tableClass && [this.options.tableClass]) || [];
          this.gtTableAlignColHeight.alighColHeight = false;
          this.gtTableAlignColHeight.watch = new Date();
        }
      }

      addInnerApi() {
        this.options.templateArgs = this.options.templateArgs || {};
        this.options.templateArgs.setHovering = (value: any) => (this.hovering = value);
      }

      defineShowFiltersButton() {
        if (this.options.columnDefs.some((c: any) => c.filters)) {
          this.showFilters = false;
        } else {
          this.showFilters = undefined;
        }
      }

      toggleFillters() {
        if (!this.showFilters) {
          this.showFilters = true;
          return;
        }

        const params = { page: undefined };
        this.options.columnDefs.forEach((col: any) => {
          (col.filters || []).forEach((filter: any) => (params[filter.predicate] = undefined));
        });
        this.options.applyFilters({ params: params });
        this.showFilters = false;
      }

      getRenderedRow(index: any, template: any) {
        if (!this.renderedRows[index]?.scope()) {
          this.renderedRows[index] = this.renderRow(template);
        }
        return this.renderedRows[index];
      }

      renderRow(template: any) {
        const newScope: any = this.$scope.$new(true);
        newScope.args = this.options.templateArgs;
        newScope.options = this.options;
        newScope.count = this.data.count || 0;
        return this.$compile(template)(newScope);
      }

      getRowTemplate() {
        if (!this._rowTpl) {
          this._rowTpl = '';
          const colTpls: any = [];
          this.columns.forEach((col: any) => {
            const tpl = col.cellTemplate || `{[{ item.${col.columnName} }]}`;
            col.class = col.class || '';
            col.classExpr = col.classExpr || '';
            colTpls.push(`<td class="${col.class}" ng-class="${col.classExpr}">${tpl}</td>`);
          });
          this._rowTpl = colTpls.join('');
        }
        return this._rowTpl;
      }

      getTotalRowTemplate() {
        if (!this._totalTpl) {
          this._totalTpl = '';
          const tpl: any = [];
          this.columns.forEach((col: any) =>
            tpl.push(/*html*/ `
            <td class="highlighted-tr ${col.class}" ng-class="${col.classExpr}">
              ${col.totalTemplate || ''}
            </td>
          `),
          );
          this._totalTpl = tpl.join('');
        }
        return this._totalTpl;
      }

      getTotalBlocksTemplate() {
        const tpl: any = [];
        this.columns.forEach((col: any) => {
          (col.totalBlockTemplates || []).forEach((template: any) => {
            tpl.push(/*html*/ `
              <div class="item-details-el alert alert-default">${template}</div>
            `);
          });
        });
        return tpl.join('');
      }

      getSelectedRowData() {
        return this.options.rowData.filter((item: any) => !!item.$_selected);
      }

      activateTab(serializer: any, title: any, icon: any) {
        this.options.activeTab = serializer;
        this.options.activeTabTitle = title;
        this.options.activeTabIcon = icon;
        this.activeTab = this.options.activeTab;
        this.activeTabTitle = this.options.activeTabTitle;
        this.activeTabIcon = this.options.activeTabIcon;
        this.forceRebuild = true;
        this.options.applyFilters({ params: { serializer: serializer } });
      }
    },
  ],
};
