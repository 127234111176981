import template from './projects-status-report-list-table-view.html?raw';

export const ProjectsStatusReportListTableView = {
  bindings: {
    data: '<',
    totals: '<',
    count: '<',
    filterLevel: '<',
  },
  template,
  controller: [
    class {
      count: number;
      data: any;
      filterLevel = 'projects-status-report-list-table-view';
      isDetailShow: any;
      totals: any;
      constructor() {
        this.data = [];
        this.totals = {};
        this.count = 0;
        this.isDetailShow = false;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'projects-status-report-list-table-view';
        this.data = this.data || [];
        this.totals = this.totals || {};
        this.count = this.count || 0;
      }

      showHideDetail(item: any) {
        if (item) {
          item.show_detail = !item.show_detail;
        } else {
          this.isDetailShow = !this.isDetailShow;
          this.data.map((item: any) => (item.show_detail = this.isDetailShow));
        }
      }
    },
  ],
};
