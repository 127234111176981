import type { DataDef } from '~/core/page-views/components/data-set-view';
import type { ColumnDef } from '~/shared/ui/components/data-grid';

import type { useTableView } from './hooks/use-table-view/use-table-view';

export const tableDataBuilder = <T extends { id: string }>({
  table,
  selectedRecordIds,
  selectable,
  onEdit,
}: {
  table: ReturnType<typeof useTableView<T>>;
  selectedRecordIds: string[];
  selectable: boolean;
  onEdit: (diff: Partial<T>) => void;
}) => {
  return {
    rows: selectable
      ? table.getRowModel().rows.map((row) => ({
          ...row,
          original: {
            ...row.original,
            _selectRowCheckbox: { rowId: row.original.id, selectedRowIds: selectedRecordIds },
          },
          getVisibleCells: () => {
            const visibleCells = row.getVisibleCells();
            return visibleCells.map((cell) => ({
              ...cell,
              original: {
                ...row.original,
                _selectRowCheckbox: { rowId: row.original.id, selectedRowIds: selectedRecordIds },
              },
            }));
          },
        }))
      : table.getRowModel().rows,
    headerGroups: table.getHeaderGroups(),
    onChange: (newValue: T[keyof T] | undefined, accessorKey: string, id: string) => {
      const updatedValue = {
        [accessorKey]: newValue,
        id: id,
      } as Partial<T>;

      onEdit(updatedValue);
    },
  };
};

export const tableColumnsBuilder = <T extends { id: string }>(
  properties: DataDef<T>['properties'],
): ColumnDef<T>[] => {
  return properties.map((property) => ({
    accessorKey: property.key as keyof T,
    header: property.title,
    dataType: property.dataType,
    hidden: !property.visible,
    position: property.position,
    cellTemplateContext: property.cellTemplateContext,
    size: property.size,
    render: property.render,
  }));
};
