import ng from 'angular';

(function () {
  'use strict';
  ng.module('reports.legacy').component('warehouseTradeTable', {
    bindings: {
      result: '<',
      filterLevel: '<',
      count: '<',
    },
    template: require('./warehouse-trade-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gettext'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.theadConfig = getTheadConfig();

    ////////////////

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('Commodity'),
          columnName: 'crop',
          class: 'th-left-align',
        },
        {
          title: gettext('Volume at start'),
          columnName: 'volume_at_start',
          class: 'th-right-align',
        },
        {
          title: gettext('Incoming volume'),
          columnName: 'incoming_volume',
          class: 'th-right-align',
        },
        {
          title: gettext('Outgoing Volume'),
          columnName: 'outgoing_volume',
          class: 'th-right-align',
        },
        {
          title: gettext('On Road Volume'),
          columnName: 'on_road_volume',
          class: 'th-right-align',
        },
        {
          title: gettext('Volume at end'),
          columnName: 'volume_at_end',
          class: 'th-right-align',
        },
      ];

      return config;
    }
  }
})();
