import React from 'react';

import type { PageParams } from '~/core/data/lib';
import { useObservableEagerState } from '~/shared/lib/state';

import type { PageFiltersStore } from '../../services/page-filters.store';
import type { FilterOption, Preset, SortingOption } from '../../types';
import { FiltersLine } from '../filters-line';

export const PageFiltersLine: React.FC<{
  filtersStore: PageFiltersStore;
  pageParams: PageParams;
  onParamsChanged: (params: Partial<PageParams>) => void;
}> = ({ filtersStore, pageParams, onParamsChanged }) => {
  const viewMode = useObservableEagerState(filtersStore.viewMode$);

  // filters
  const filtersProps: {
    preset: Preset;
    presetOptions: Preset[];
    sortingOptions: SortingOption[];
    filtersOptions: FilterOption[];
    onChange: (filterSet: Preset) => void;
    onSave: (filterSet: Preset) => void;
    onDelete: (filterSet: Preset) => void;
  } = {
    preset: {
      id: '',
      title: '',
      icon: 'HeartIcon',
      filters: [
        {
          argument: 'account',
          operator: 'includes',
          values: ['includes', 'is', 'is not'],
        },
        {
          argument: 'status',
          operator: '',
          values: ['includes', 'is', 'is not'],
        },
      ],
      sorting: pageParams.sort,
    },
    presetOptions: [],
    filtersOptions: [
      {
        argument: 'account',
        title: 'Account Owner',
        type: 'text',
        icon: 'EyeIcon',
        groupName: 'Properties',
        groupIcon: 'EyeIcon',
        valueOptions: [],
        operatorOptions: ['includes', 'is', 'is not'],
      },
      {
        argument: 'status',
        title: 'Status',
        type: 'label',
        icon: 'NewspaperIcon',
        groupName: 'Select',
        groupIcon: 'EyeIcon',
        valueOptions: [],
        operatorOptions: ['includes', 'is', 'is not'],
      },
    ],
    sortingOptions: [
      {
        title: 'Title',
        argument: 'name',
        icon: 'SearchIcon',
      },
      {
        title: 'Creator',
        argument: 'create_time',
        icon: 'SearchIcon',
      },
      {
        title: 'Status',
        argument: 'status',
        icon: 'ReportsIcon',
      },
    ],
    onChange: (preset) => onParamsChanged({ sort: preset.sorting }),
    onSave: console.info,
    onDelete: console.info,
  };
  return (viewMode == 'line' && <FiltersLine {...filtersProps} />) || null;
};
