import type { CommonDataType } from '~/shared/ui/data-types/lib';
import type { IconName } from '~/shared/ui/icons';

export type FilterOption<T = string> = {
  argument: string;
  title: string;
  type: CommonDataType;
  typeExtra?: string;
  icon: IconName;
  groupName: string;
  groupIcon: IconName;
  valueOptions: T[];
  operatorOptions: string[];
};

export type FilterValue = {
  argument: FilterOption['argument'];
  operator: string;
  values: FilterOption['valueOptions'];
};

export type SortingOption = {
  argument: string;
  title: string;
  icon: IconName;
};

export type SortingValue = {
  argument: SortingOption['argument'];
  direction: SortingDirection;
};

export type SortingDirection = 'asc' | 'desc';

export const SortingDirectionVariant = {
  Asc: 'asc',
  Desc: 'desc',
} satisfies Record<string, SortingDirection>;

export type Preset = {
  id: string;
  title: string;
  icon: IconName;
  filters: FilterValue[];
  sorting?: SortingValue;
};
