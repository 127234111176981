import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';
export class FactPlListService {
  $http: ng.IHttpService;
  $rootScope: GtRootScopeService;
  StockExchangesService: any;
  constructor($http: ng.IHttpService, $rootScope: GtRootScopeService, StockExchangesService: any) {
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.StockExchangesService = StockExchangesService;
  }

  getListData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/passports/' + params.fact_type + '-fact-list/pl/',
      params: params,
    });
  }
  round(value: any, precision = 3) {
    return Math.round(value * 10 ** precision) / 10 ** precision;
  }
  getPassportDerivativeMargin(passportId: any) {
    switch (this.$rootScope.user.settings.PASSPORT_DERIVATIVE_MARGIN_CALCULATION_TYPE) {
      case 'calculate_by_facts':
        return this.getPassportDerivativeMarginByFacts(passportId);
      case 'calcucate_by_passport':
        return this.getPassportDerivativeMarginByPassport({
          page_size: 999,
          passport_list: [passportId],
        });
      default:
        return Promise.resolve(0);
    }
  }

  getPassportDerivativeMarginByPassport(params: any) {
    return this.StockExchangesService.getAccountBalance(params).then((data: any) => {
      return this.round(
        data.accounts.reduce((res: any, curr: any) => res + (curr.account_total || 0), 0),
      );
    });
  }

  getPassportDerivativeMarginByFacts(passportId: any) {
    return this.$http({
      method: 'GET',
      url: '/api/passports/passports/get_derivatives_margin',
      params: { passport_list: passportId },
    }).then((data: any) => this.round(data?.data.derivatives_margin || 0));
  }

  getTotalsData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/passports/' + params.fact_type + '-fact-totals/pl/',
      params: params,
    });
  }
}
FactPlListService.$inject = ['$http', '$rootScope', 'StockExchangesService'];
