import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { ShipmentScheduleListContainer } from './shipment-schedule-list-container/shipment-schedule-list-container.component';
import { ShipmentScheduleListPage } from './shipment-schedule-list-page/shipment-schedule-list-page.component';
import { ShipmentScheduleListTableView } from './shipment-schedule-list-table-view/shipment-schedule-list-table-view.component';
import { ShipmentScheduleModal } from './shipment-schedule-modal/shipment-schedule-modal.component';
import { ShipmentSchedulePositionTable } from './shipment-schedule-position-table/shipment-schedule-position-table.component';
import { ShipmentScheduleService } from './shipment-schedule.service';

export const shipmentSchedule = ng
  .module('logistics.planning.shipmentSchedule', [])
  .service('ShipmentScheduleService', ShipmentScheduleService)
  .component('shipmentScheduleModal', ShipmentScheduleModal)
  .component('shipmentScheduleListContainer', ShipmentScheduleListContainer)
  .component('shipmentScheduleListTableView', ShipmentScheduleListTableView)
  .component('shipmentSchedulePositionTable', ShipmentSchedulePositionTable)
  .component('shipmentScheduleListPage', ShipmentScheduleListPage)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('logistics.planningShipmentSchedule', {
        url: '/shipment-schedule/list/',
        component: 'shipmentScheduleListPage',
        data: {
          pageTitle: gettext('Shipment Plan'),
        },
        resolve: {
          filterLevel: () => {
            return 'shipment-schedule-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('shipment-schedule-list-page');
            },
          ],
        },
      });
    },
  ]).name;
