import type ng from 'angular';

export const LoyaltyProgramPositionTable = {
  bindings: {
    positions: '<',
    addPosition: '&',
    addMonth: '&',
    programType: '<?',
  },
  template: require('./loyalty-program-position-table.html?raw'),
  controller: [
    'gettext',
    'LoyaltyProgramsService',
    class {
      LoyaltyProgramsService: any;
      gettext: ng.gettext.gettextFunction;
      positions: any;
      programType: any;
      theadConfig: any;
      totalAmount: any;
      constructor(gettext: ng.gettext.gettextFunction, LoyaltyProgramsService: any) {
        this.gettext = gettext;
        this.LoyaltyProgramsService = LoyaltyProgramsService;

        this.positions = [];
        this.totalAmount = 0;
        this.programType = 'absolute_value';
        this.theadConfig = {};
      }

      $onInit() {
        this.positions = this.positions || [];
        this.theadConfig = this.getTheadConfig();
      }

      $onChanges(changes: any) {
        if (changes.programType) {
          this.theadConfig = this.getTheadConfig();
        }
      }

      destroyPosition(position: any) {
        return this.positions.splice(this.positions.indexOf(position), 1);
      }

      destroyMonth(position: any, month: any) {
        return position.months.splice(position.months.indexOf(month), 1);
      }

      cloneMonth(position: any, month: any) {
        const newMonth = { ...month };
        delete newMonth.id;
        delete newMonth.index;
        return position.months.push(newMonth);
      }

      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          {
            title: this.gettext('crops'),
            columnName: 'crops',
          },
          {
            title: this.gettext('month'),
            columnName: 'month',
          },
          {
            title: this.gettext('volume'),
            columnName: 'volume',
          },
          {
            title: this.gettext('price'),
            columnName: 'price',
          },
          {
            title: this.gettext('premium'),
            columnName: 'premium',
            hideExpression: this.programType == 'percentage',
          },
          {
            title: this.gettext('percentage'),
            columnName: 'premium_percentage',
            hideExpression: this.programType == 'absolute_value',
          },
          {
            title: this.gettext(''),
            columnName: 'save',
          },
        ];

        config.columns = config.columns.filter((col) => !col.hideExpression);
        return config;
      }
    },
  ],
};
