import type ng from 'angular';
export class MarketAnalyticsService {
  $filter: ng.IFilterService;
  $http: ng.IHttpService;
  $log: ng.ILogService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  analyticsData: any;
  moment: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService,
    $log: ng.ILogService,
    moment: any,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.$log = $log;
    this.moment = moment;

    this.analyticsData = undefined;
  }

  getAnalyticsData(params: any) {
    if (!params && this.analyticsData) {
      return this.$q.when().then(() => this.analyticsData);
    }
    this.analyticsData = this.$http({
      method: 'GET',
      url: '/api/contracts/market-analytics/',
      params: params,
    }).then((response: any) => {
      this.analyticsData = response.data;
      return this.analyticsData;
    });
    return this.analyticsData;
  }

  getVolumeExecutionChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Sale', 'Purchase'],
      count: 0,
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.indicators_by_execution_date.length;
      data.indicators_by_execution_date.forEach((i: any) => {
        chartConfig.labels.push(this.moment(i.date_of_execution_month).format('MMM, YY'));
        chartConfig.data[0].push(Math.round(i.sale_volume * 100) / 100);
        chartConfig.data[1].push(Math.round(i.purchase_volume * 100) / 100);
      });

      return chartConfig;
    });
  }

  getValueExecutionChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Sale', 'Purchase'],
      count: 0,
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.indicators_by_execution_date.length;
      data.indicators_by_execution_date.forEach((i: any) => {
        chartConfig.labels.push(this.moment(i.date_of_execution_month).format('MMM, YY'));
        chartConfig.data[0].push(Math.round((i.sale_total_value / i.sale_volume) * 100) / 100);
        chartConfig.data[1].push(
          Math.round((i.purchase_total_value / i.purchase_volume) * 100) / 100,
        );
      });

      return chartConfig;
    });
  }

  getPortPricesData(params: any) {
    if (!params?.crop) {
      this.$log.warn('Commodity is undefined!');
      return this.$q.when([]);
    }
    return this.$http({
      method: 'GET',
      url: `/api/crops/crops/${params.crop}/port_prices/`,
      params: params,
    }).then((response: any) => response.data);
  }

  getPotentialsByCropChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
      count: 0,
    };
    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.potentials_by_crop.length;
      data.potentials_by_crop.forEach(
        (i: any) => (i.volume__sum = Math.round(i.volume__sum * 100) / 100),
      );
      data.potentials_by_crop.forEach((i: any) => {
        chartConfig.labels.push(i.crop__title);
        chartConfig.data.Volume.push(i.volume__sum);
      });
      return chartConfig;
    });
  }
}
MarketAnalyticsService.$inject = ['$resource', '$q', '$http', '$filter', '$log', 'moment'];
