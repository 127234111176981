import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('contracts', {
        parent: 'gt.page',
        url: '/contracts',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('contracts.sale', {
        url: '/sale',
        component: 'contractsPageView',
        resolve: {
          filterLevel: () => {
            return 'sale-contracts-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              ...PageService.syncUrlFilter('sale-contracts-page-view'),
              contract_type: 'sale',
              stage: 'contract',
            }),
          ],
        },
        data: {
          pageTitle: gettext('Contracts'),
          permissions: {
            only: 'view_salecontract',
          },
        },
      })
      .state('contracts.purchase', {
        url: '/purchase',
        component: 'contractsPageView',
        resolve: {
          filterLevel: () => {
            return 'purchase-contracts-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              ...PageService.syncUrlFilter('purchase-contracts-page-view'),
              contract_type: 'purchase',
              stage: 'contract',
            }),
          ],
        },
        data: {
          pageTitle: gettext('Contracts'),
          permissions: {
            only: 'view_purchasecontract',
          },
        },
      })
      .state('contracts.servicesPositionsDirect', {
        url: '/services',
        component: 'contractsPageView',
        resolve: {
          filterLevel: () => {
            return 'services-direct-contracts-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              ...PageService.syncUrlFilter('services-direct-contracts-page-view'),
              deal_type: 'services',
              stage: 'contract',
              charge_use_type_list: ['direct'],
            }),
          ],
        },
        data: {
          pageTitle: gettext('Contracts'),
        },
      })
      .state('contracts.servicesPositionsGeneral', {
        url: '/services',
        component: 'contractsPageView',
        resolve: {
          filterLevel: () => {
            return 'services-general-contracts-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              ...PageService.syncUrlFilter('services-general-contracts-page-view'),
              deal_type: 'services',
              stage: 'contract',
              charge_use_type_list: ['general'],
            }),
          ],
        },
        data: {
          pageTitle: gettext('Contracts'),
          permissions: {
            only: 'view_servicescontract',
          },
        },
      })
      .state('contracts.intermediate', {
        url: '/intermediate',
        component: 'contractsPageView',
        resolve: {
          filterLevel: () => {
            return 'intermediate-contracts-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              ...PageService.syncUrlFilter('intermediate-contracts-page-view'),
              deal_type: 'intermediate',
              stage: 'contract',
            }),
          ],
        },
        data: {
          pageTitle: gettext('Contracts'),
        },
      })
      .state('contracts.export', {
        url: '/export',
        component: 'contractsPageView',
        resolve: {
          filterLevel: () => {
            return 'export-contracts-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              ...PageService.syncUrlFilter('export-contracts-page-view'),
              deal_type: 'export',
              contract_type: 'sale',
              stage: 'contract',
            }),
          ],
        },
        data: {
          pageTitle: gettext('Contract positions'),
        },
      })
      .state('contracts.agreements', {
        url: '/agreements',
        component: 'generalAgreementsPageView',
        resolve: {
          filterLevel: () => {
            return 'general-agreement-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('general-agreement-page-view');
            },
          ],
        },
        data: {
          pageTitle: gettext('Agreements'),
          permissions: {
            only: 'view_general_agreement',
          },
        },
      })
      .state('contracts.saleAgreements', {
        url: '/sale-agreements',
        component: 'generalAgreementsPageView',
        resolve: {
          filterLevel: () => {
            return 'sale-general-agreement-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                ...PageService.syncUrlFilter('sale-general-agreement-page-view'),
                deal_type: 'spot',
                agreement_type: 'sale',
              };
            },
          ],
        },
        data: {
          pageTitle: gettext('Agreements'),
          permissions: {
            only: 'view_general_agreement',
          },
        },
      })
      .state('contracts.intermediateAgreements', {
        url: '/intermediate-agreements',
        component: 'generalAgreementsPageView',
        resolve: {
          filterLevel: () => {
            return 'intermediate-general-agreement-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                ...PageService.syncUrlFilter('intermediate-general-agreement-page-view'),
                deal_type: 'intermediate',
                agreement_type: '',
              };
            },
          ],
        },
        data: {
          pageTitle: gettext('Agreements'),
          permissions: {
            only: 'view_general_agreement',
          },
        },
      })
      .state('contracts.exportAgreements', {
        url: '/export-agreements',
        component: 'generalAgreementsPageView',
        resolve: {
          filterLevel: () => {
            return 'export-general-agreement-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                ...PageService.syncUrlFilter('export-general-agreement-page-view'),
                deal_type: 'export',
                agreement_type: '',
              };
            },
          ],
        },
        data: {
          pageTitle: gettext('Agreements'),
          permissions: {
            only: 'view_general_agreement',
          },
        },
      })
      .state('contracts.servicesAgreements', {
        url: '/services-agreements',
        component: 'generalAgreementsPageView',
        resolve: {
          filterLevel: () => {
            return 'services-general-agreement-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                ...PageService.syncUrlFilter('services-general-agreement-page-view'),
                deal_type: 'services',
                agreement_type: '',
              };
            },
          ],
        },
        data: {
          pageTitle: gettext('Agreements'),
          permissions: {
            only: 'view_general_agreement',
          },
        },
      })
      .state('contracts.purchaseAgreements', {
        url: '/purchase-agreements',
        component: 'generalAgreementsPageView',
        resolve: {
          filterLevel: () => {
            return 'purchase-general-agreement-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return {
                ...PageService.syncUrlFilter('purchase-general-agreement-page-view'),
                deal_type: 'spot',
                agreement_type: 'purchase',
              };
            },
          ],
        },
        data: {
          pageTitle: gettext('Agreements'),
          permissions: {
            only: 'view_general_agreement',
          },
        },
      })
      .state('indicators', {
        parent: 'gt.page',
        url: '/indicators',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('indicators.sale', {
        url: '/sale',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'requests-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('requests-page-view');
              queryParams.stage = 'indicator';
              queryParams.contract_type = 'sale';
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Indicators'),
          permissions: {
            only: 'view_indicator',
          },
        },
      })
      .state('indicators.purchase', {
        url: '/purchase',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'requests-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('requests-page-view');
              queryParams.stage = 'indicator';
              queryParams.contract_type = 'purchase';
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Indicators'),
          permissions: {
            only: 'view_indicator',
          },
        },
      })
      .state('tickets', {
        parent: 'gt.page',
        url: '/tickets',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('tickets.sale', {
        url: '/sale',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'request-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('request-list-page');
              queryParams.stage = 'ticket';
              queryParams.contract_type = 'sale';
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Tickets'),
          permissions: {
            only: 'view_request',
          },
        },
      })
      .state('tickets.purchase', {
        url: '/purchase',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'request-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('request-list-page');
              queryParams.stage = 'ticket';
              queryParams.contract_type = 'purchase';
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Tickets'),
          permissions: {
            only: 'view_request',
          },
        },
      })
      .state('tickets.export', {
        url: '/export',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'export-request-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('export-request-list-page');
              queryParams.stage = 'ticket';
              queryParams.contract_type = 'sale';
              queryParams.deal_type = 'export';
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Tickets'),
          permissions: {
            only: 'view_request',
          },
        },
      })
      .state('tickets.servicesPositionsGeneral', {
        url: '/services',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'service-general-request-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('service-general-request-list-page');
              queryParams.stage = 'ticket';
              queryParams.deal_type = 'services';
              queryParams.charge_use_type_list = ['general'];
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Tickets'),
          permissions: {
            only: 'view_request',
          },
        },
      })
      .state('tickets.servicesPositionsDirect', {
        url: '/services',
        component: 'requestsPageView',
        resolve: {
          filterLevel: () => {
            return 'service-direct-request-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('service-direct-request-list-page');
              queryParams.stage = 'ticket';
              queryParams.deal_type = 'services';
              queryParams.charge_use_type_list = ['direct'];
              return queryParams;
            },
          ],
        },
        data: {
          pageTitle: gettext('Tickets'),
          permissions: {
            only: 'view_request',
          },
        },
      })
      .state('tickets.prices', {
        url: '/prices',
        component: 'requestsPricesPageView',
        resolve: {
          filterLevel: () => {
            return 'requests-prices-page-view';
          },
        },
        data: {
          pageTitle: gettext('Prices'),
          permissions: {
            only: 'view_prices',
          },
        },
      })
      .state('gt.generalagreement', {
        url: '/generalagreement/:id',
        controller: 'GeneralAgreementPageViewController',
        controllerAs: 'vm',
        template: require('./components/general-agreement-page/general-agreement-page.tpl.html?raw'),
        reloadOnSearch: false,
        data: {
          pageTitle: gettext('General Agreement'),
          permissions: {
            only: ['view_general_agreement'],
          },
        },
      });
  }
})();
