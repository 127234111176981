import React from 'react';

import { container } from '~/shared/lib/di';

import { StartPageStore } from '../services';

export const useStartPage = () => {
  const startPageStore = container.resolve(StartPageStore);

  const startPage = React.useMemo(() => ({ startPageStore }), [startPageStore]);

  return startPage;
};
