import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { CreditReportContainer } from './credit-report-container/credit-report-container.component';
import { CreditReportPageView } from './credit-report-page-view/credit-report-page-view.component';
import { CreditReportTableView } from './credit-report-table-view/credit-report-table-view.component';
import { CreditReportService } from './credit-report.service';

export const creditReport = ng
  .module('reports.creditReport', [])
  .service('CreditReportService', CreditReportService)
  .component('creditReportPageView', CreditReportPageView)
  .component('creditReportContainer', CreditReportContainer)
  .component('creditReportTableView', CreditReportTableView)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('reports.creditReport', {
        url: '/credit-report',
        component: 'creditReportPageView',
        data: {
          pageTitle: gettext('Credit report'),
        },
        resolve: {
          filterLevel: () => {
            return 'credit-report-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('credit-report-page-view');
            },
          ],
        },
      });
    },
  ]).name;
