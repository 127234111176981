import ng from 'angular';

import { PnlMtmConsolidatedTable } from './pnl-mtm-consolidated-table/pnl-mtm-consolidated-table.component';
import { PnlMtmPage } from './pnl-mtm-page/pnl-mtm-page.component';
import { PnlMtmService } from './pnl-mtm.service';

export const pnlMtm = ng
  .module('reports.pnlMtm', [])
  .service('PnlMtmService', PnlMtmService)
  .component('pnlMtmConsolidatedTable', PnlMtmConsolidatedTable)
  .component('pnlMtmPage', PnlMtmPage).name;
