import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './reservations-list-page.html?raw';

export const ReservationsListPage = {
  template,
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  controller: [
    'gettext',
    'PageService',
    'LogisticsService',
    class {
      LogisticsService: any;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      constructor(
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        LogisticsService: any,
      ) {
        this.gettext = gettext;
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.filterLevel = 'reservations-list-page';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'reservations-list-page';
        this.PageService.setConfig(this.getPageConfig());
      }
      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
        };

        // @ts-ignore
        config.filters = {
          groupBy: {},
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          dateSelects: [
            {
              argument: 'date',
              placeholder: this.gettext('Date'),
            },
          ],
          selects: [],
          nestedMultiSelects: [
            {
              argument: 'status',
              class: '',
              placeholder: this.gettext('status'),
              items: [
                { id: 'new', title: this.gettext('New') },
                { id: 'processed', title: this.gettext('Processed') },
                { id: 'cancelled', title: this.gettext('Cancelled') },
              ],
            },
          ],
          multiSelects: [
            {
              argument: 'to_stock_reserve_unit',
              placeholder: this.gettext('To Stock Reserve Unit'),
              resource: 'logistics.stockReserveUnit',
              related_fk: 'to_stock_reserve_unit_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'commodity',
              placeholder: this.gettext('commodity'),
              resource: 'crops.Crop',
              related_fk: 'commodity_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'warehouse',
              placeholder: this.gettext('warehouse'),
              resource: 'logistics.warehouse',
              related_fk: 'warehouse_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'contract_position',
              placeholder: this.gettext('Purchase contract'),
              resource: 'contracts.purchaseContract',
              related_fk: 'contract_position_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'receiver',
              placeholder: this.gettext('receiver'),
              resource: 'clients.Client',
              related_fk: 'receiver_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'owner',
              placeholder: this.gettext('owner'),
              resource: 'clients.Client',
              related_fk: 'owner_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'producer',
              placeholder: this.gettext('producer'),
              resource: 'clients.Client',
              related_fk: 'producer_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'from_stock_reserve_unit',
              placeholder: this.gettext('From Stock Reserve Unit'),
              resource: 'logistics.stockReserveUnit',
              related_fk: 'from_stock_reserve_unit_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'business_unit',
              placeholder: this.gettext('Business Unit'),
              resource: 'core.BusinessUnit',
              related_fk: 'business_unit_id',
              related_model: 'warehouses.Reservation',
            },
          ],
        };
        return config;
      }
    },
  ],
};
