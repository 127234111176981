import type { DataDef } from '~/core/page-views/components/data-set-view';
import type { CellData, MatrixPrimitive } from '~/shared/ui/components/spreadsheet/types';

// TO DO: refactor strange method
const getValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value
      .map((item) => (typeof item === 'object' ? JSON.stringify(item) : String(item)))
      .join(', ');
  }

  if (!value) {
    return '';
  }

  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  if (typeof value === 'string') {
    return value;
  }

  return '';
};

export const spreadsheetDataBuilder = <TRecord extends { id: string }>({
  records,
  properties,
}: {
  records: DataDef<TRecord>['records'];
  properties: DataDef<TRecord>['properties'];
}) => {
  const cellData: Record<number, Record<number, { v: string; custom: object }>> = {};

  records.forEach((record, i) => {
    properties.forEach((property, j) => {
      cellData[i] = cellData[i] || {};
      cellData[i][j] = {
        v: getValue(record[property.key as keyof TRecord]),
        custom: { key: property.key, id: record.id },
      };
    });
  });

  return cellData;
};

export const updatedDataBuilder = <T extends { id: string }>(
  uniqueObjects: MatrixPrimitive<CellData>,
) => {
  const result: Partial<T>[] = [];

  for (const rowKey in uniqueObjects) {
    const row = uniqueObjects[rowKey];

    for (const cellKey in row) {
      const cell = row[cellKey];

      if (cell?.v && cell.custom) {
        const obj = {
          [cell.custom.key]: cell.v,
          id: cell.custom.id as T['id'],
        } as Partial<T>;
        result.push(obj);
      }
    }
  }

  return result;
};
