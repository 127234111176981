import ng from 'angular';

(function () {
  'use strict';
  ng.module('accounts.legacy').directive('accountsLogentryRowItem', directive);

  function directive() {
    return {
      template: require('./logentry-row-item.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',

      scope: {
        logentry: '=',
      },
    };
  }

  Controller.$inject = ['AccountsService'];

  function Controller(this: any, AccountsService: any) {
    const vm = this;
    vm.openLogentryModal = openLogentryModal;
    vm.getFormattedChangeMessage = getFormattedChangeMessage;
    vm.shouldShowExand = shouldShowExand;

    ////////////////

    function openLogentryModal(logentry: any) {
      return AccountsService.logentryModal(logentry);
    }

    function getFormattedChangeMessage(changeMessage: any, itemscount: number) {
      if (!changeMessage) {
        return changeMessage;
      }
      const [messageTitle, message] = changeMessage.split(':');
      if (
        !message ||
        !['Added groups', 'Added permissions', 'Remove groups', 'Removed permissions'].includes(
          messageTitle.trim(),
        )
      ) {
        return changeMessage;
      }

      let items = message.split(',').map((item: any) => item.trim());

      items = itemscount ? items.slice(0, itemscount + 1) : items;
      return `${messageTitle.trim()}:\n${items.join('\n')}`;
    }

    function shouldShowExand(changeMessage: any, maxItemscount: number) {
      if (!changeMessage) {
        return changeMessage;
      }
      const [messageTitle, message] = changeMessage.split(':');
      if (
        !message ||
        !['Added groups', 'Added permissions', 'Remove groups', 'Removed permissions'].includes(
          messageTitle.trim(),
        )
      ) {
        return false;
      }
      return message.split(',').length > maxItemscount;
    }
  }
})();
