import ng from 'angular';

import { ConsolidatedCropPositionListTable } from './consolidated-crop-position-list-table/consolidated-crop-position-list-table.component';
import { ConsolidatedCropPositionPage } from './consolidated-crop-position-page/consolidated-crop-position-page.component';
import { ConsolidatedCropPositionTable } from './consolidated-crop-position-table/consolidated-crop-position-table.component';
import { ConsolidatedCropPositionService } from './consolidated-crop-position.service';

export const consolidatedCropPosition = ng
  .module('reports.consolidatedCropPosition', ['finances.shared'])
  .service('ConsolidatedCropPositionService', ConsolidatedCropPositionService)
  .component('consolidatedCropPositionTable', ConsolidatedCropPositionTable)
  .component('consolidatedCropPositionListTable', ConsolidatedCropPositionListTable)
  .component('consolidatedCropPositionPage', ConsolidatedCropPositionPage).name;
