import type ng from 'angular';

import type { QueryParams } from '~/app/core/types';
import { getModalRoot } from '~/shared/ui/modal';

export class WarehousesService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  CoordinatesService: any;
  recorderReferenceResource: any;
  warehousesResource: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    CoordinatesService: any,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.CoordinatesService = CoordinatesService;

    this.warehousesResource = $resource(
      '/api/logistics/warehouses/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        delete: {
          method: 'DELETE',
          isArray: false,
        },
        predictions: {
          method: 'GET',
          url: '/api/logistics/warehouses/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/warehouses/export_column_names/',
        },
      },
    );
    this.recorderReferenceResource = $resource(
      '/api/warehouses/recorder-reference/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/warehouses/recorder-reference/predictions/',
        },
      },
    );
  }
  getWarehousesList(queryParams: QueryParams) {
    return this.warehousesResource.query(queryParams).$promise.then((data: any) => ({
      ...data,

      results: data.results.map((item: any) => ({
        ...item,
        placeUrl: this.CoordinatesService.getPlaceURL(item.coordinates),
      })),
    }));
  }
  getWarehouse(id: any) {
    return this.warehousesResource.get({ id: id }).$promise;
  }
  deleteWarehouse(warehouse: any) {
    return this.warehousesResource.delete({ id: warehouse.id }).$promise;
  }
  cleanWarehouse(warehouse: any) {
    const result: any = { ...warehouse };
    ['farm', 'elevator', 'terminal']
      .filter((field) => !result[field])
      .forEach((field) => {
        delete result[field];
      });
    return result;
  }
  saveWarehouse(warehouse: any) {
    const item = this.cleanWarehouse(warehouse);
    if (warehouse.id) {
      return this.warehousesResource.update({ id: warehouse.id }, item).$promise;
    }
    return this.warehousesResource.save(item).$promise;
  }
  warehouseModal(warehouse: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <warehouse-modal warehouse="warehouse" modal-instance="$uibModalInstance">
        </warehouse-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).warehouse = warehouse;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }
}

WarehousesService.$inject = ['$resource', '$uibModal', 'CoordinatesService'];
