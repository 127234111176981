import ng from 'angular';

import { BankBalanceReportContainer } from './bank-balance/bank-balance-report-container/bank-balance-report-container.component';
import { BankBalanceReportTree } from './bank-balance/bank-balance-report-tree/bank-balance-report-tree.component';
import { BankBalanceReportService } from './bank-balance-report.service';
import { CashflowReportContainer } from './cashflow/cashflow-report-container/cashflow-report-container.component';
import { CashflowReportTree } from './cashflow/cashflow-report-tree/cashflow-report-tree.component';
import { CashflowBalanceReportContainer } from './cashflow-bank-balance/cashflow-balance-report-container/cashflow-balance-report-container.component';
import { CashflowBalanceReportTree } from './cashflow-bank-balance/cashflow-balance-report-tree/cashflow-balance-report-tree.component';
import { CashflowReportPage } from './cashflow-report-page/cashflow-report-page.component';
import { CashflowReportService } from './cashflow-report.service';
import { ChartsList } from './charts-list/charts-list.component';

export const cashflowReport = ng
  .module('reports.cashflowReport', ['finances.shared'])
  .service('CashflowReportService', CashflowReportService)
  .service('BankBalanceReportService', BankBalanceReportService)
  .component('cashflowReportPage', CashflowReportPage)
  .component('cashflowReportContainer', CashflowReportContainer)
  .component('bankBalanceReportContainer', BankBalanceReportContainer)
  .component('bankBalanceReportTree', BankBalanceReportTree)
  .component('cashflowBalanceReportContainer', CashflowBalanceReportContainer)
  .component('cashflowBalanceReportTree', CashflowBalanceReportTree)
  .component('chartsList', ChartsList)
  .component('cashflowReportTree', CashflowReportTree).name;
