import type { GtRootScopeService } from '~/app/core/types';

import template from './settings-parsing-table-view.html?raw';

export const SettingsParsingTableView = {
  bindings: {
    quotesParsingConfigs: '<',
    count: '<',
    filterLevel: '<',
    applyFilters: '&',
    openQuotesParsingConfigsModal: '&',
    deleteQuotesParsingConfig: '&',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      applyFilters: any;
      count: number;
      deleteQuotesParsingConfig: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      openQuotesParsingConfigsModal: any;
      quotesParsingConfigs: any;
      tableData: any;
      tableOptions: any;
      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;

        this.quotesParsingConfigs = [];
        this.count = 0;

        this.tableOptions = {};
        this.tableData = {};

        this.filterLevel = 'settings-parsing-table-view';
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.quotesParsingConfigs || changes.count) {
          this.tableData = { rows: this.quotesParsingConfigs, count: this.count };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'quotes-parsing-configs invoice-position-table operations-table',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          templateArgs: {
            openQuotesParsingConfigsModal: (parsingConfig: any) =>
              this.openQuotesParsingConfigsModal({ parsingConfig: parsingConfig }),
            deleteQuotesParsingConfig: (parsingConfig: any) =>
              this.deleteQuotesParsingConfig({ parsingConfig: parsingConfig }),
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
          showMore: true,
        };

        options.columnDefs = [
          {
            columnName: 'resource',
            title: this.gettext('Resource'),
            predicate: 'resource',
            showAlways: true,
            class: 'resource td-left-align',
            cellTemplate: /*html*/ `
              <ul
                class="nav navbar-nav item-settings-dropdown"
                ng-mouseenter="args.setHovering(true)"
                ng-mouseleave="args.setHovering(false)"
              >
                <li>
                  <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                    <i class="fa fa-scissors"></i> {[{ item.resource }]}
                    <i class="fa fa-sort-desc"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        permission
                        permission-only="'change_quotesparsingconfig'"
                        class="btn btn-xs col-xs-12 tooltip"
                        ng-click="args.openQuotesParsingConfigsModal(item)"
                      >
                        <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'delete_quotesparsingconfig'"
                        class="btn btn-xs col-xs-12"
                        ng-click="args.deleteQuotesParsingConfig(item);"
                      >
                        <i class="fa fa-trash"></i> <translate>Delete</translate>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'derivative_title',
            title: this.gettext('Derivative title'),
            class: 'derivative td-left-align',
          },
          {
            columnName: 'derivative_ticker',
            title: this.gettext('Derivative ticker'),
            class: 'derivative td-left-align',
          },
          {
            columnName: 'parsing_enabled',
            title: this.gettext('Parsing enabled'),
            class: 'parsing_enabled td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.parsing_enabled">
                <i class="fa fa-check"></i>
              </span>
              <span ng-if="!item.parsing_enabled">
                <i class="fa fa-times"></i>
              </span>
            `,
          },
        ];

        return options;
      }
    },
  ],
};
