import type ng from 'angular';

export class CostsReportService {
  $http: ng.IHttpService;
  ReportsService: any;
  constructor($http: ng.IHttpService, ReportsService: any) {
    this.$http = $http;
    this.ReportsService = ReportsService;
  }

  getReportData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/costs-report/',
      params: params,
    }).then((response: any) =>
      this.ReportsService.unpackReportDataFromTree(response.data.results, params),
    );
  }
}
CostsReportService.$inject = ['$http', 'ReportsService'];
