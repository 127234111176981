import { BehaviorSubject, tap } from 'rxjs';

import { ResetPasswordCredentials } from '~/features/reset-password/lib';
import { singleton } from '~/shared/lib/di';

import { ResetPasswordApiService } from './reset-password-api.service';

@singleton()
export class ResetPasswordService {
  private loadingSubj = new BehaviorSubject(false);
  private isSendSubj = new BehaviorSubject(false);

  constructor(private resetPasswordApiService: ResetPasswordApiService) {}

  readonly loading$ = this.loadingSubj.asObservable();
  readonly isSend$ = this.isSendSubj.asObservable();

  public passwordReset(credentials: ResetPasswordCredentials) {
    this.loadingSubj.next(true);
    this.resetPasswordApiService
      .resetPassword(credentials)
      .pipe(
        tap({
          next: () => {
            this.loadingSubj.next(false);
            this.isSendSubj.next(true);
          },
          error: () => {
            this.loadingSubj.next(false);
          },
        }),
      )
      .subscribe();
  }
}
