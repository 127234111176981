import React from 'react';

import { UrlWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const UrlCellWrite = <T,>({ value, valueChanged }: CellTypeProps<T>) => {
  return (
    <UrlWrite value={String(value)} valueChanged={(value) => valueChanged?.(value as T[keyof T])} />
  );
};
