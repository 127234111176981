import type ng from 'angular';

export class IntermodalTransportsService {
  $http: ng.IHttpService;
  $resource: ng.resource.IResourceService;
  IntermodalTransportsResource: any;
  constructor($http: ng.IHttpService, $resource: ng.resource.IResourceService) {
    this.$http = $http;
    this.$resource = $resource;
    this.IntermodalTransportsResource = $resource(
      '/api/logistics/intermodal-transports/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  getIntermodalTransportsListInfo(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/logistics/logistic-intermodal-list/',
      params: params,
    }).then((response: any) => {
      const logistics: any = [];
      response.data.results.forEach((logistic: any) => {
        logistic.children_list = this.flatten(logistic.children);
        logistics.push(logistic);
      });
      return { results: logistics, count: response.data.count };
    });
  }

  flatten(children: any) {
    children = children || [];
    const result: any = [];
    this.flattenRecursive(result, children);
    return result;
  }

  flattenRecursive(result: any, children: any) {
    children = children.flat();
    children.forEach((child: any) => {
      child._selected = child._selected || false;
      result.push(child);
      this.flattenRecursive(result, child.children || []);
    });
  }

  createIntermodalTransport(item: any) {
    return this.IntermodalTransportsResource.save(item).$promise;
  }

  updateIntermodalTransport(item: any) {
    return this.IntermodalTransportsResource.update(item).$promise;
  }

  delete(id: any) {
    return this.IntermodalTransportsResource.delete({ id: id }).$promise;
  }
}
IntermodalTransportsService.$inject = ['$http', '$resource'];
