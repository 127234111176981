import {
  BehaviorSubject,
  Subject,
  combineLatest,
  defer,
  from,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { AuthStore } from '~/core/auth/services';
import type { EntityFieldsProperties } from '~/core/page-views/lib';
import { EntityFieldsRepository } from '~/core/page-views/services/entity-fields.repository';
import { CounterpartyDTO } from '~/features/crm/counterparties/lib';
import { DataViewFieldsRepository } from '~/features/data-view-fields/services';
import { injectable } from '~/shared/lib/di';
import { notifySuccess } from '~/shared/lib/notify';
import { ColumnDef } from '~/shared/ui/components/data-grid';

import { UserViewRepository } from './user-view.repository';
import { BasicView, ViewConfig, basicViews } from '../components/view-configurator';

@injectable()
export class PageViewsStore {
  // remove basicViews import from component
  private readonly currentViewIdSubj = new BehaviorSubject<number>(basicViews[0].id);
  private readonly availableViewsChangedSubj = new BehaviorSubject<boolean>(false);
  // private readonly editingSubj = new BehaviorSubject(false);
  // wtf
  readonly dataSetId = 'counterparties';
  private readonly viewConfigChangedSubj = new Subject<{
    id: number;
    config: Partial<ViewConfig>;
  }>();
  private readonly deletedViewId = new Subject<number>();
  private readonly pageDataSubj = new BehaviorSubject<CounterpartyDTO[]>([]);
  private readonly editingPageDataSubj = new BehaviorSubject<CounterpartyDTO[]>([]);

  constructor(
    private readonly authService: AuthStore,
    private readonly userViewRepository: UserViewRepository,
    private readonly dataViewFieldsRepo: DataViewFieldsRepository,
    private readonly entityFieldsRepo: EntityFieldsRepository,
  ) {}
  private readonly createViewSubj = new Subject<Omit<BasicView, 'id'>>();

  private entityName: string | null = null;
  private readonly entityFieldProperties = new BehaviorSubject<EntityFieldsProperties[]>([]);

  readonly entityFieldProperties$ = this.entityFieldProperties.pipe(
    switchMap(() =>
      this.entityName
        ? of(this.entityFieldsRepo.getEntityProperties({ entityName: this.entityName }))
        : of([]),
    ),
  );

  readonly availableViews$ = combineLatest(
    [
      this.availableViewsChangedSubj,
      defer(() => this.authService.currentUser$).pipe(map((user) => ({ id: user.id }))),
    ],
    (_, user) => ({ userId: user.id }),
  ).pipe(
    switchMap(() => {
      return of(this.userViewRepository.getUserViews({ dataSetId: this.dataSetId }));
    }),
    switchMap((userViews) =>
      // wtf
      from(this.dataViewFieldsRepo.getDataViewColumns({ purposeModel: 'client' })).pipe(
        map((customFields) => ({
          userViews,
          customFields,
        })),
      ),
    ),
    switchMap(({ userViews, customFields }) => {
      return from(userViews).pipe(
        map((views) =>
          views.map((view) => ({
            ...view,
            data: {
              ...view.data,
              properties: {
                ...view.data.properties,
                customFields: customFields.map((field) => ({
                  ...field,
                })) as ColumnDef<CounterpartyDTO>[],
              },
            },
          })),
        ),
      );
    }),
    startWith([]),
  );

  readonly currentView$ = combineLatest(
    [this.currentViewIdSubj, this.availableViews$],
    (id, views) => ({ id, views }),
  ).pipe(
    switchMap(async ({ id, views }) => {
      const currentView = await this.userViewRepository.getUserView({
        dataSetId: this.dataSetId,
        viewId: id,
      });
      return views.find((view) => view.id === currentView.id) ?? basicViews[0];
    }),
    startWith(basicViews[0]),
  );

  readonly viewConfigChanged$ = this.viewConfigChangedSubj.pipe(
    switchMap(async ({ id, config }) => {
      await this.userViewRepository.updateUserView(
        { dataSetId: this.dataSetId, viewId: id },
        config,
      );
      return true;
    }),
  );

  public init({ entityName }: { entityName: string }) {
    this.entityName = entityName;
  }

  public viewConfigChanged = <T>(id: number, config: Partial<ViewConfig<T>>) => {
    this.viewConfigChangedSubj.next({ id, config: config as Partial<ViewConfig> });
    this.availableViewsChangedSubj.next(true);
  };

  public currentViewChanged = (id: number) => {
    const newCurrentView = this.availableViews$.pipe(
      map((views) => {
        return views.find((view) => view.id === id);
      }),
    );

    if (newCurrentView) {
      this.currentViewIdSubj.next(id);
    }
  };

  public createView = async (view: Omit<BasicView, 'id'>) => {
    try {
      await this.userViewRepository.createUserView(view);
    } catch (err) {
      console.error(err);
    }

    this.availableViewsChangedSubj.next(true);
  };

  public viewDeleted = async (id: number) => {
    try {
      await this.userViewRepository.deleteUserView({ dataSetId: this.dataSetId, viewId: id });
      notifySuccess(`View #${id} deleted`);
    } catch (err) {
      console.error(err);
    }

    this.availableViewsChangedSubj.next(true);
  };

  public changesReset = () => {
    this.editingPageDataSubj.next(this.pageDataSubj.value);
  };

  // public saveRequested = () => {
  //   this.editingSubj.next(false);
  // };
}
