import template from './deleted-objects-view.html?raw';

export const DeletedObjectsView = {
  bindings: {
    objects: '<?',
  },
  template,
  controller: class {
    isArray(object: any) {
      return object instanceof Array;
    }
  },
};
