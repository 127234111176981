import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('personsPageView', {
    bindings: {},
    template: require('./persons-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'gettext', 'gtFilterService', 'ClientsService'];

  function Controller(
    this: any,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    gtFilterService: GtFilterService,
    ClientsService: any,
  ) {
    const vm = this;
    vm.filterLevel = 'clients-tab-persons';
    vm.quickAdd = false;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-crm-tab',
        buttons: [new PageService.buttons.Filters()],
      };

      // @ts-ignore
      config.filters = {
        filterLevel: vm.filterLevel,
        search: true,
        dates: true,
        useFlexDateRange: true,
        nestedSelects: [gtFilterService.getRelationFilter()],
        selects: [
          {
            argument: 'companies',
            placeholder: gettext('company'),
            resource: 'clients.client',
          },
        ],
      };
      // @ts-ignore
      config.filters.multiSelects = [];
      // @ts-ignore
      config.filters.nestedMultiSelects = [];
      // @ts-ignore
      config.pages = ClientsService.getPagesConfig();
      return config;
    }
  }
})();
