export class InvoicePositionConfigService {
  $resource: ng.resource.IResourceService;
  InvoicePositionConfigResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;
    this.InvoicePositionConfigResource = $resource(
      '/api/finances/invoice-position-configs/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        getFinanceAccounts: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/invoice-position-configs/get_finance_accounts/',
        },
      },
    );
  }

  getInvoicePositionConfigTableData(params: any) {
    return this.InvoicePositionConfigResource.query(params).$promise;
  }

  getInitValue(params: any) {
    return {
      invoice_type: 'incoming',
      use: 'cargo',
      contract_type: 'purchase',
      ...params,
    };
  }

  getRequiredFields(use: any) {
    const requiredField: any = [
      'invoice_type',
      'currency',
      'use',
      'debit_account',
      'credit_account',
    ];
    switch (use) {
      case 'cargo':
        requiredField.push('contract_type');
        break;
      case 'costs':
      case 'gains':
        requiredField.push('charge');
        break;
      case 'expenses':
        requiredField.push('expenses_option');
        break;
      case 'washout':
        break;
      case 'other':
        requiredField.push('other_activity');
        break;
    }
    return requiredField;
  }

  getFinanceAccounts(params: any) {
    return this.InvoicePositionConfigResource.getFinanceAccounts(params).$promise;
  }

  save(item: any) {
    return this.InvoicePositionConfigResource.save(item).$promise;
  }

  update(item: any) {
    return this.InvoicePositionConfigResource.update(item).$promise;
  }

  delete(itemId: any) {
    return this.InvoicePositionConfigResource.delete({ id: itemId }).$promise;
  }
}
InvoicePositionConfigService.$inject = ['$resource'];
