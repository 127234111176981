import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './contract-details-item-view.html?raw';

export const contractDetailsItemView = {
  bindings: {
    contract: '<',
    contractChargesFilterLevel: '<',
    tab: '<',
    fields: '<',
    openEmailModal: '&?',
    editContract: '&?',
    editContractQuick: '&?',
    openDocxModal: '&?',
    clone: '&?',
    reverse: '&?',
    approve: '&?',
    createPassport: '&?',
    connectToPassport: '&?',
    addLogistic: '&?',
    addFinance: '&?',
    addBalanceInvoice: '&?',
    openRoleModal: '&?',
    openRoleDetails: '&?',
    goBack: '&?',
    changeTab: '&?',
    updateData: '&?',
    hideWizard: '<?',
    costsPredictionsQueryParams: '<?',
    filterLevel: '<?',
    passportQueryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      addBalanceInvoice: any;
      addFinance: any;
      addLogistic: any;
      approve: any;
      clone: any;
      contract: any;
      createPassport: any;
      editContract: any;
      editContractQuick: any;
      fields: any;
      goBack: any;
      hideWizard: any;
      openDocxModal: any;
      openEmailModal: any;
      openRoleModal: any;
      passportQueryParams: QueryParams = {};
      reverse: any;
      showDetail: any;
      tab: any;
      updateData: any;
      constructor($rootScope: GtRootScopeService) {
        this.contract = {};
        this.tab = '';
        this.openEmailModal;
        this.editContract;
        this.editContractQuick;
        this.openDocxModal;
        this.clone;
        this.reverse;
        this.approve;
        this.createPassport;
        this.addLogistic;
        this.addFinance;
        this.addBalanceInvoice;
        this.openRoleModal;
        this.goBack;
        this.updateData;
        this.hideWizard;
        this.passportQueryParams;

        this.fields = [];

        if ($rootScope.user.profile.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
      }
    },
  ],
};
