import ng from 'angular';

import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').controller('BasisDocsModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'ContractsService', 'objectId', 'gettext'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    ContractsService: ContractsService,
    objectId: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.close = close;
    vm.objectId = objectId;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      return ContractsService.ContractBasisDoc.query(
        {
          contract__id: vm.objectId,
        },
        function (data: any) {
          vm.basisDocSet = data.results;
        },
      ).$promise;
    }

    function close(data: any, silent: any) {
      updateData();
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
