import React from 'react';

import { notifySuccess } from '~/shared/lib/notify';

import type { BasicView } from '../view-configurator';
import { type ViewCreated, basicViews } from '../view-configurator';

export const CreateViewList: React.FC<{ viewCreated: ViewCreated }> = ({ viewCreated }) => {
  const onCreateHandler = async (view: BasicView) => {
    try {
      await viewCreated(view);
      notifySuccess(`View "${view.name}" created`);
    } catch (error) {
      console.error('Failed to create view:', error);
    }
  };

  return basicViews.map(({ Icon, ...view }) => (
    <button
      key={view.type}
      className="flex cursor-pointer items-center gap-2 p-2 text-xs transition duration-300 ease-in-out hover:bg-background-main-tertiary"
      onClick={() => {
        void onCreateHandler({ Icon, ...view });
      }}
    >
      <Icon size={14} className="text-stroke-main-stronger" />
      <p className="text-main-primary text-xs capitalize">{view.type}</p>
    </button>
  ));
};
