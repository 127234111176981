import React from 'react';

import { BasicView } from '../basic-view';
import { LimitAccess, Properties } from '../view-configurator/data';
import type { ListView, ReorderedColumn, ViewConfigChanged } from '../view-configurator/types';

export const SheetViewSettings: React.FC<{
  trigger: React.ReactNode;
  view: ListView;
  viewConfigChanged: ViewConfigChanged;
  viewDeleted: (viewId: number) => void;
  columns: ReorderedColumn[];
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({
  viewDeleted,
  view,
  trigger,
  viewConfigChanged,
  columns,
  columnsReordered,
  columnVisibilityChanged,
}) => {
  return (
    <BasicView
      view={view}
      trigger={trigger}
      displayTriggerIcon={false}
      sideOffset={6}
      viewConfigChanged={viewConfigChanged}
      viewDeleted={viewDeleted}
      content={
        <>
          <LimitAccess
            isLimitAccess={view.data.isLimitAccess}
            limitAccessChanged={() =>
              viewConfigChanged(view.id, { data: { isLimitAccess: !view.data.isLimitAccess } })
            }
          />
          <Properties
            columns={columns}
            columnsReordered={columnsReordered}
            columnVisibilityChanged={columnVisibilityChanged}
          />
        </>
      }
    />
  );
};
