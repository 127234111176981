import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { QueryParams } from '~/app/core/types';

import template from './terminal-transshipment-tariff-page.html?raw';

export const TerminalTransshipmentTariffPage = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'LogisticsService',
    'gettext',
    class {
      LogisticsService: any;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      queryParams: QueryParams = {};
      constructor(
        PageService: PageService,
        LogisticsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;

        this.filterLevel = 'terminal-transshipment-tariff-page-filter-level';
        this.queryParams = { transportation_type: 'terminal_transshipment' };
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.PageService.syncUrlFilter(this.filterLevel);
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Import('logistics.logistictariff'),
            new this.PageService.buttons.Export('logistics.logistictariff', this.filterLevel),
            new this.PageService.buttons.Filters(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
        };

        // @ts-ignore
        config.filters = {
          filterLevel: this.filterLevel,
          ordering: [
            {
              title: this.gettext('RECENTLY UPDATED'),
              predicate: 'update_time',
            },
          ],
          search: true,
          dates: true,
          selects: [
            {
              argument: 'terminal',
              placeholder: this.gettext('Terminal'),
              resource: 'logistics.terminal',
            },
            {
              argument: 'crop',
              placeholder: this.gettext('Cargo'),
              resource: 'crops.crop',
            },
            {
              argument: 'currency',
              placeholder: this.gettext('Currency'),
              resource: 'finances.Currency',
            },
            {
              argument: 'business_unit',
              placeholder: this.gettext('Business unit'),
              resource: 'core.businessunit',
            },
          ],
        };
        // @ts-ignore
        config.filters.nestedSelects = [
          {
            argument: 'status',
            placeholder: this.gettext('Status'),
            items: [
              {
                id: 'valid',
                title: this.gettext('Valid'),
              },
              {
                id: 'invalid',
                title: this.gettext('Invalid'),
              },
            ],
          },
        ];
        // @ts-ignore
        config.filters.multiSelects = [];
        // @ts-ignore
        config.filters.nestedMultiSelects = [];
        config.pages = this.LogisticsService.getPagesConfig();

        return config;
      }
    },
  ],
};
