import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BankAccountPageController', Controller);

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$stateParams',
    '$state',
    'FinancesService',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $stateParams: ng.ui.IStateParamsService,
    $state: ng.ui.IStateService,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.bankAccount = { id: $stateParams.id };
    vm.updateData = updateData;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return FinancesService.BankAccount.get(
        { id: vm.bankAccount.id },
        function (data: any) {
          vm.bankAccount = data;
          $rootScope.pageTitle =
            $rootScope.pageTitle +
            ' ' +
            vm.bankAccount.bank_name +
            ' ' +
            vm.bankAccount.account_name;
          GtUtils.overlay('hide');
        },
        function () {
          GtUtils.notify(gettext("Bank account doesn't exist"));
          $state.go('finances.bankAccount.list');
        },
      );
    }
  }
})();
