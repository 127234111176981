import ng from 'angular';

import { companyDocumentsModule } from './documents';
import { emailTemplatesModule } from './emailtemplates';
import { companyLegacyModule } from './legacy';
import { qualityRuleTemplatesModule } from './quality-rule-templates';
import { tasksTemplatesModule } from './tasks-templates/tasks-templates.module';

export const companyModule = ng.module('company', [
  companyLegacyModule,
  companyDocumentsModule,
  emailTemplatesModule,
  qualityRuleTemplatesModule,
  tasksTemplatesModule,
]).name;
