import ng from 'angular';

import type { GtRootScopeService } from '../../types';

(function () {
  'use strict';

  ng.module('core.legacy').factory('HelpButtonsService', ServiceFunction);

  ServiceFunction.$inject = ['$rootScope'];

  function ServiceFunction($rootScope: GtRootScopeService) {
    const _config: any = {
      default: {
        table: {
          expanded: false,
        },
        sidebar: {
          clicked: false,
        },
      },
    };
    let startIntro: any;

    return {
      getConfig: getConfig,
      setConfig: setConfig,
      updateConfig: updateConfig,
      getTableConfig: getTableConfig,
      setStartIntroFunc: setStartIntroFunc,
      getStartIntroFunc: getStartIntroFunc,
    };

    ///////////////

    function getConfig(level: any) {
      level = level || 'default';
      return _config[level] || {};
    }

    function setConfig(config: any, level: any) {
      level = level || 'default';
      _config[level] = config;
      $rootScope.$broadcast('hb-config-updated', getConfig(level), level);
    }

    function updateConfig(config: any, level: any) {
      level = level || 'default';
      _config[level] = { ...(_config[level] || {}), ...config };
      for (const i in _config[level]) {
        if (_config[level][i] === null || _config[level][i] === undefined) {
          delete _config[level][i];
        }
      }
      $rootScope.$broadcast('hb-config-updated', _config[level], level);
    }

    function getTableConfig(level: any) {
      level = level || 'default';
      if (!_config[level].table || _config[level].table.expanded == 'undefined') {
        return { expanded: true };
      }
      return _config[level].table;
    }

    function setStartIntroFunc(func: any) {
      startIntro = func;
    }

    function getStartIntroFunc() {
      return startIntro;
    }
  }
})();
