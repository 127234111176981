import template from './transportation-storage-expenses-table.html?raw';
export const TransportationStorageExpensesView = {
  bindings: {
    expenses: '<?',
    count: '<?',
    openModal: '&',
    clone: '&',
    filterLevel: '<?',
    applyFilters: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      applyFilters: any;
      clone: any;
      count: number;
      expenses: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      openModal: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;

        this.expenses = [];
        this.count = 0;
        this.tableData = {};
        this.tableName = '';
        this.filterLevel = '';
        this.openModal = () => {};
        this.applyFilters = () => {};
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'transportation-storage-expenses-view';
        this.tableName = this.tableName || this.filterLevel;
        this.tableOptions = this.getTableOptions();
      }
      $onChanges(changes: any) {
        if (changes.expenses || changes.count) {
          this.tableData = { rows: this.expenses, count: this.count };
        }
      }
      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          configurable: true,
          changePageSize: true,
          showMore: true,
          templateArgs: {
            openModal: (item: any) => this.openModal({ item: item }),
            clone: (item: any) => this.clone({ item: item }),
          },
          tabs: [],
          columnDefs: [
            {
              columnName: 'date',
              class: 'td-left-align',
              title: this.gettext('Date'),
              cellTemplate: /*html*/ `
               {[{ item.date | date:'dd.MM.yy'}]}`,
            },
            {
              columnName: 'number',
              class: 'td-left-align',
              title: this.gettext('Number'),
              cellTemplate: /*html*/ `
                <a
                 class="btn-link"
                 ui-sref="logistics.transportationStorageExpense(item)"
                >{[{ item.number }]}</a>
                  <ul
                    class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                    ng-mouseenter="args.setHovering(true)"
                    ng-mouseleave="args.setHovering(false)"
                  >
                    <li>
                      <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                        <i class="fa fa-ellipsis hide-on-hover"></i>
                        <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="btn btn-xs col-xs-12" ui-sref="logistics.transportationStorageExpense(item)">
                            <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'change_transportationstorageexpense'"
                            class="btn btn-xs aligned-btn"
                            ng-disabled="item.result_status == 'approved'"
                            ng-click="args.openModal(item)"
                          >
                            <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'edit_closed_elements'"
                            class="btn btn-xs aligned-btn"
                            ng-click="args.openModal(item)"
                          >
                            <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            ng-click="args.clone(item)"
                            class="btn btn-xs aligned-btn"
                            permission
                            permission-only="'change_transportationstorageexpense'"
                          >
                            <i class="fa fa-files-o"></i> <translate>Clone</translate>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
              `,
            },
            {
              columnName: 'status',
              class: 'td-left-align',
              title: this.gettext('Status'),
              cellTemplate: /*html*/ `
                <span>{[{ item.status | translate}]}</span> `,
            },
            {
              columnName: 'base',
              class: 'td-left-align',
              title: this.gettext('Base'),
              cellTemplate: /*html*/ `
                <span>{[{ item.base | translate}]}</span> `,
            },
            {
              columnName: 'strategy',
              class: 'td-left-align',
              title: this.gettext('Strategy'),
              cellTemplate: /*html*/ `
                <span>{[{ item.strategy | translate}]}</span> `,
            },
            {
              columnName: 'startDate',
              class: 'td-left-align',
              title: this.gettext('Start date'),
              cellTemplate: /*html*/ `
                <span>{[{ item.start_date | date:'dd.MM.yy'}]}</span> `,
            },
            {
              columnName: 'endDate',
              class: 'td-left-align',
              title: this.gettext('End date'),
              cellTemplate: /*html*/ `
                <span>{[{ item.end_date | date:'dd.MM.yy'}]}</span> `,
            },
            {
              columnName: 'warehouse',
              class: 'td-left-align',
              title: this.gettext('Warehouse'),
              cellTemplate: /*html*/ `
                <span>{[{ item.warehouse.title | translate}]}</span> `,
            },
            {
              columnName: 'commodity',
              class: 'td-left-align',
              title: this.gettext('Commodity'),
              cellTemplate: /*html*/ `
                <span>{[{ item.commodity.title }]}</span>
              `,
            },
            {
              columnName: 'contractPosition',
              class: 'td-left-align',
              title: this.gettext('Contract'),
              cellTemplate: /*html*/ `
                <a ui-sref="gt.page.contract({id: item.contract_position.id })" target="_blank">
                  <span>{[{ item.contract_position.contract_number }]}</span>
                </a>
              `,
            },
            {
              columnName: 'contract',
              class: 'td-left-align',
              title: this.gettext('Multicontract'),
              cellTemplate: /*html*/ `
                <a ui-sref="gt.multicontract({id: item.contract_position.contract.id })" target="_blank">
                  <span>{[{ item.contract_position.contract.number }]}</span>
                </a>
              `,
            },
            {
              columnName: 'costs_amount',
              class: 'td-left-align',
              title: this.gettext('Costs amount'),
              cellTemplate: /*html*/ `
                <span>{[{item.costs_amount}]}</span>
              `,
            },
            {
              columnName: 'currency',
              class: 'td-left-align',
              title: this.gettext('currency'),
              cellTemplate: /*html*/ `
                <span>{[{ item.currency.symbol }]}</span>
              `,
            },
            {
              columnName: 'charge',
              class: 'td-left-align',
              title: this.gettext('charge'),
              cellTemplate: /*html*/ `
                <span>{[{ item.charge.title }]}</span>
              `,
            },
            {
              columnName: 'currency_exchange',
              class: 'td-left-align',
              title: this.gettext('currency rate'),
              cellTemplate: /*html*/ `
                <span>{[{ item.currency_exchange.title }]}</span>
              `,
            },
          ],
        };
      }
    },
  ],
};
