import ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').controller('LineupModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    '$timeout',
    '$window',
    '$uibModalInstance',
    'GtUtils',
    'FormFieldParamsService',
    'ContractsService',
    'LogisticsService',
    'CoreService',
    'gettext',
    'moment',
    'lineup',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $q: ng.IQService,
    $timeout: ng.ITimeoutService,
    $window: ng.IWindowService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    FormFieldParamsService: FormFieldParamsService,
    ContractsService: ContractsService,
    LogisticsService: any,
    CoreService: CoreService,
    gettext: ng.gettext.gettextFunction,
    moment: any,
    lineup: any,
  ) {
    const vm = this;

    vm.lineup = lineup;
    vm.save = save;
    vm.close = close;
    vm.updateConnections = updateConnections;
    vm.accordionState = 1;
    vm.voyage = {};
    vm.voyageForm = undefined;
    vm.voyageFields = undefined;
    vm.passport = {};
    vm.passportForm = undefined;
    vm.passportFields = undefined;
    vm.switchTab = switchTab;
    vm.updating = false;
    vm.saleContractsFilterLevel = 'sale-contracts';
    vm.purchaseContractsFilterLevel = 'purchase-contracts';
    vm.contractFilterCargo = undefined;

    activate();

    ////////////////

    function activate() {
      let lineupPromise;
      if (vm.lineup?.id) {
        lineupPromise = ContractsService.Passport.lineup(vm.lineup, function (data: any) {
          vm.passport = data.passport;
          vm.voyage = data.voyage;
        }).$promise;
      } else {
        lineupPromise = $q.when();
      }
      $q.all([lineupPromise]).then(function () {
        vm.voyageFields = getVoyageFields();
        if ($rootScope.user.settings.PASSPORT_CHECK_CROP) {
          vm.contractFilterCargo = vm.passport.cargo;
        }
        FormFieldParamsService.resolveDefaultValues(getPassportFields()).then(function (
          fields: any,
        ) {
          vm.passportFields = fields;
        });
        $scope.$watch('vm.passport.cargo', savePassport);
        $scope.$watch('vm.voyage.vessel', updateVessel);
        $scope.$watch('vm.voyage.name', changePassportName);
        $scope.$watch('vm.voyage.port_loading', updateLoadingPort);
        $scope.$watch('vm.voyage.port_destination', updateDestinationPort);
      });
    }

    function updateVessel() {
      if (!vm.voyage.vessel) {
        vm.voyage.name = null;
        vm.voyage.age = null;
        vm.voyage.imo = null;
        vm.voyage.capacity = null;
        return;
      }
      LogisticsService.Vessel.query({ id: vm.voyage.vessel }, function (data: any) {
        vm.voyage.name = data.name;
        vm.voyage.age = data.age;
        vm.voyage.imo = data.imo;
        vm.voyage.capacity = data.capacity;
      });
    }

    function updateLoadingPort() {
      if (!vm.voyage.port_loading) {
        vm.voyage.loading_port_name = null;
        return;
      }
      LogisticsService.Port.query({ id: vm.voyage.port_loading }, function (data: any) {
        vm.voyage.loading_port_name = data.name;
      });
    }

    function updateDestinationPort() {
      if (!vm.voyage.port_destination) {
        vm.voyage.destination_port_name = null;
        return;
      }
      LogisticsService.Port.query({ id: vm.voyage.port_destination }, function (data: any) {
        vm.voyage.destination_port_name = data.name;
      });
    }

    function switchTab(newState: any) {
      if (vm.accordionState == newState) {
        return;
      }
      GtUtils.overlay('show');
      saveCurrentTab().then(function () {
        GtUtils.overlay('hide');
        GtUtils.notify(gettext('Step saved'));
        vm.accordionState = newState;
      });
    }

    function saveCurrentTab() {
      if (vm.accordionState == 1) {
        return saveVoyage();
      }
      if (vm.accordionState == 2) {
        return savePassport();
      }
      return $q.when();
    }

    function save() {
      saveCurrentTab().then(function () {
        close({}, true);
      });
    }

    function savePassport() {
      if (vm.passport.cargo) {
        const method = vm.passport.id ? 'update' : 'save';
        return ContractsService.Passport[method](
          vm.passport,
          function (data: any) {
            vm.passport = data;
            updateConnections();
          },
          (e: any) => GtUtils.errorClb(e),
        ).$promise;
      } else {
        return $q.when();
      }
    }

    function saveVoyage() {
      let vesselQ, loadingPortQ, destinationPortQ;
      if (vm.voyage.name) {
        const vesselMethod = vm.voyage.vessel ? 'update' : 'save';
        vesselQ = LogisticsService.Vessel[vesselMethod](
          { name: vm.voyage.name, id: vm.voyage.vessel, imo: vm.voyage.imo },
          function (data: any) {
            vm.voyage.vessel = data.id;
          },
        ).$promise;
      } else {
        vesselQ = $q.when();
      }
      if (vm.voyage.loading_port_name) {
        const loadingMethod = vm.voyage.port_loading ? 'update' : 'save';
        loadingPortQ = LogisticsService.Port[loadingMethod](
          { name: vm.voyage.loading_port_name, id: vm.voyage.port_loading },
          function (data: any) {
            vm.voyage.port_loading = data.id;
          },
        ).$promise;
      } else {
        loadingPortQ = $q.when();
      }
      if (vm.voyage.destination_port_name) {
        const destinationMethod = vm.voyage.port_destination ? 'update' : 'save';
        destinationPortQ = LogisticsService.Port[destinationMethod](
          { name: vm.voyage.destination_port_name, id: vm.voyage.port_destination },
          function (data: any) {
            vm.voyage.port_destination = data.id;
          },
        ).$promise;
      } else {
        destinationPortQ = $q.when();
      }
      return $q.all([vesselQ, loadingPortQ, destinationPortQ]).then(function () {
        if (!vm.voyage.vessel) {
          return $q.when();
        }
        const voyageMethod = vm.voyage.id ? 'update' : 'save';
        return LogisticsService.Voyage[voyageMethod](vm.voyage, function (data: any) {
          vm.voyage.id = data.id;
          vm.passport.voyage = vm.voyage.id;
          if (vm.passport.id) {
            return savePassport();
          }
        }).$promise;
      });
    }

    function changePassportName() {
      if (!vm.lineup?.id) {
        vm.passport.title = vm.voyage.name || 'Passport ' + moment().format('DD.MM.YYYY');
      }
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function updateConnections() {
      vm.updating = true;
      $timeout(function () {
        vm.updating = false;
      }, 100);
    }

    function getVoyageFields() {
      const col1: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };
      const col4: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };

      col1.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('BOAT'),
          },
          fieldGroup: [
            {
              key: 'vessel',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('existing vessel'),
                resource: 'logistics.Vessel',
              },
            },
            {
              key: 'name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('New vessel name'),
                placeholder: gettext('Vessel name'),
                required: true,
              },
            },
            {
              key: 'age',
              type: 'gt-input',
              templateOptions: {
                type: 'number',
                max: 9999,
                label: gettext('Vessel year'),
                placeholder: gettext('Vessel year'),
              },
            },
            {
              key: 'imo',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Vessel imo'),
                placeholder: gettext('Vessel imo'),
                required: true,
              },
            },
            {
              key: 'capacity',
              type: 'gt-input',
              templateOptions: {
                type: 'number',
                label: gettext('Vessel capacity'),
                placeholder: gettext('Vessel capacity'),
              },
            },
          ],
        },
      ];

      col2.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('VOYAGE'),
          },
          fieldGroup: [
            {
              key: 'port_loading',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Loading Port'),
                resource: 'logistics.Port',
              },
            },
            {
              key: 'loading_port_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('New loading port name'),
                placeholder: gettext('New loading port name'),
              },
            },
            {
              key: 'port_destination',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Destination Port'),
                resource: 'logistics.Port',
              },
            },
            {
              key: 'destination_port_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('New destination port name'),
                placeholder: gettext('New destination port name'),
              },
            },
            {
              key: 'cp_date',
              type: 'gt-date',
              templateOptions: {
                label: gettext('Charter party date'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
          ],
        },
      ];

      col3.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('VOYAGE LOADING DATES'),
          },
          fieldGroup: [
            {
              key: 'start_date_load',
              type: 'gt-date',
              templateOptions: {
                label: gettext('Laycan start date'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'end_date_load',
              type: 'gt-date',
              templateOptions: {
                label: gettext('Laycan end date'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'eta',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('ETA'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'etb',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETB'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'ets',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETS'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'etc',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETC'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
          ],
        },
      ];

      col4.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('VOYAGE DESTINATION DATES'),
          },
          fieldGroup: [
            {
              key: 'start_date_disch',
              type: 'gt-date',
              templateOptions: {
                label: gettext('Laycan start date'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'end_date_disch',
              type: 'gt-date',
              templateOptions: {
                label: gettext('Laycan end date'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'eta_destination',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETA'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'etb_destination',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETB'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'ets_destination',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETS'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'etc_destination',
              type: 'gt-date',
              templateOptions: {
                label: gettext('ETC'),
                placeholder: gettext('date'),
                type: 'date',
              },
            },
          ],
        },
      ];

      return [col1, col2, col3, col4];
    }

    function getPassportFields() {
      const col1: any = {
        className: 'form-group-container col-sm-12 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup = [
        {
          wrapper: 'gt-panel',
          fieldGroup: [
            {
              key: 'title',
              type: 'gt-input',
              defaultValue: vm.voyage.name || 'Passport ' + moment().format('DD.MM.YYYY'),
              templateOptions: {
                label: gettext('Title'),
                placeholder: gettext('Title'),
              },
            },
            {
              key: 'cargo',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Commodity'),
                placeholder: gettext('Choose commodity'),
                resource: 'crops.Crop',
                required: true,
                addFunc: () => {
                  $window.open('/admin/crops/crop/add/');
                },
                addIcon: GtUtils.getIcon('crops.Crop'),
                addPerms: ['add_crop'],
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'currency',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Currency'),
                placeholder: gettext('USD, UAH'),
                resource: 'finances.Currency',
                addFunc: () => {
                  $window.open('/admin/finances/currency/add/');
                },
                addIcon: GtUtils.getIcon('finances.Currency'),
                addPerms: ['add_currency'],
                required: true,
              },
              validation: {
                show: true,
              },
            },
            {
              key: 'custom_status',
              type: 'gt-ui-select',
              defaultValueResolve: () => GtUtils.getCustomStatusId('passport'),
              templateOptions: {
                label: gettext('Custom status'),
                placeholder: gettext('Choose custom status'),
                resource: 'core.CustomStatus',
                addPerms: ['add_customstatus'],
                hint: gettext('custom status'),
                queryParams: {
                  content_type__model: 'passport',
                },
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => CoreService.getDefaultBuId(vm.passport),
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
                required: true,
              },
            },
            {
              key: 'origin_of_crop',
              type: 'gt-ui-select',
              defaultValueResolve: () => GtUtils.getDefaultOriginId(),
              templateOptions: {
                label: gettext('Origin'),
                placeholder: gettext('Type origin'),
                resource: 'location.Country',
                addon: gettext('country'),
                required: true,
              },
            },
            {
              key: 'destination_of_crop',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Destination'),
                placeholder: gettext('Type destination'),
                resource: 'location.Country',
                addon: gettext('country'),
                required: true,
              },
            },
            {
              key: 'total_volume_plan',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                type: 'number',
                label: gettext('Total volume plan'),
                placeholder: gettext('Total volume plan'),
                required: true,
              },
            },
            {
              key: 'conclusion_date',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Conclusion (date)'),
                placeholder: gettext('date'),
              },
            },
            {
              key: 'responsible',
              type: 'gt-ui-select',
              defaultValue: $rootScope.user.id,
              templateOptions: {
                label: gettext('Responsible'),
                placeholder: gettext('User to be responsible'),
                resource: 'auth.User',
              },
            },
          ],
        },
      ];

      return [col1];
    }
  }
})();
