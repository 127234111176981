import ng from 'angular';

(function () {
  'use strict';
  ng.module('common.crops.legacy').component('warehousesTable', {
    bindings: {
      warehouses: '<',
      filterLevel: '<',
      count: '<',
      crops: '<',
      openWarehouseModal: '<',
    },
    template: require('./warehouses-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gettext'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.crops = vm.crops || [];
    vm.theadConfig = getTheadConfig();
    vm.tableTrigger = new Date();

    vm.$onChanges = function (changes: any) {
      if (changes.crops) {
        vm.theadConfig = getTheadConfig();
        vm.cropsTotalSum = vm.crops.reduce(
          (acc: any, item: any) => acc + (item.volume_sum || 0),
          0,
        );
        vm.warehousesTotalSum = vm.warehouses.reduce(
          (acc: any, item: any) => acc + (item.total_volume || 0),
          0,
        );
        vm.tableTrigger = new Date();
      }
    };

    ////////////////

    function getTheadConfig() {
      const config: any = {};

      if (vm.role === 'Supplier' || vm.role === 'Farm') {
        config.tabs = [
          {
            serializer: 'table_contacts',
            title: gettext('Indicators (spot)'),
            icon: 'fa-file-o',
          },
        ];
      }

      config.columns = [
        {
          columnName: 'name',
          title: gettext('Warehouse'),
          predicate: 'name',
        },
        ...vm.crops.reduce(
          (res: any, curr: any) => [...res, { columnName: curr.id, title: gettext(curr.title) }],
          [],
        ),
        {
          columnName: 'totalVolume',
          title: gettext('Total'),
        },
      ];

      return config;
    }
  }
})();
