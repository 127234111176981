import ng from 'angular';

import { DebitCreditPage } from './debit-credit-page/debit-credit-page.component';
import { DebitCreditTable } from './debit-credit-table/debit-credit-table.component';
import { DebitCreditService } from './debit-credit.service';

export const debitCredit = ng
  .module('reports.debitCredit', [])
  .service('DebitCreditService', DebitCreditService)
  .component('debitCreditTable', DebitCreditTable)
  .component('debitCreditPage', DebitCreditPage).name;
