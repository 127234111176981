import ng from 'angular';

import { BankAccountTurnoverTotals } from './bank-account-turnover-totals/bank-account-turnover-totals.component';
import { BankAccountTurnoverTotalsService } from './bank-account-turnover-totals/bank-account-turnover-totals.service';
import { BankAccountsWidget } from './bank-accounts-widget/bank-accounts-widget.cmp';
import { CurrencyToggle } from './currency-toggle/currency-toggle.component';
import { DBLLogisticModal } from './dbl/dbl-logistic-modal/dbl-logistic-modal.component';
import { DblLogisticsListContainer } from './dbl/dbl-logistics-list-container/dbl-logistics-list-container.component';
import { DBLService } from './dbl/dbl.service';
import { DisbursementBLConsignment } from './disbursementbl-consignment/disbursementbl-consignment.cmp';
import { FinancesCountsByMonthChart } from './finances-counts-by-month-chart/finances-counts-by-month-chart.component';
import { gtCurrencyFilter } from './gt-currency/gt-currency.filter';
import { LogisticsBLFormationContainer } from './logistics-bl-formation/logistics-bl-formation.component';
import { PaymentInfoListContainer } from './payment-info-list-container/payment-info-list-container.component';
import { PaymentOrdersListContainer } from './payment-orders-list-container/payment-orders-list-container.component';
import { PaymentOrdersService } from './payment-orders-list-container/payment-orders.service';
import { paymentPlanFinances } from './payment-plan-finances/payment-plan-finances.component';
import { PaymentPlanFinancesService } from './payment-plan-finances/payment-plan-finances.service';
import { paymentPlanFinancesPagination } from './payment-plan-finances-pagination/payment-plan-finances-pagination.component';

export const shared = ng
  .module('finances.shared', [])
  .component('financesCountsByMonthChart', FinancesCountsByMonthChart)
  .component('currencyToggle', CurrencyToggle)
  .component('paymentPlanFinances', paymentPlanFinances)
  .component('paymentPlanFinancesPagination', paymentPlanFinancesPagination)
  .component('paymentInfoListContainer', PaymentInfoListContainer)
  .component('paymentOrdersListContainer', PaymentOrdersListContainer)
  .service('PaymentPlanFinancesService', PaymentPlanFinancesService)
  .service('PaymentOrdersService', PaymentOrdersService)
  .component('bankAccountTurnoverTotals', BankAccountTurnoverTotals)
  .service('BankAccountTurnoverTotalsService', BankAccountTurnoverTotalsService)
  .component('dblLogisticModal', DBLLogisticModal)
  .component('dblLogisticsListContainer', DblLogisticsListContainer)
  .component('logisticsBlFormationContainer', LogisticsBLFormationContainer)
  .component('disbursementBlConsignment', DisbursementBLConsignment)
  .component('bankAccountsWidget', BankAccountsWidget)
  .service('DBLService', DBLService)
  .filter('gtCurrency', gtCurrencyFilter).name;
