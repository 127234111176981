import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { WarehousesContainer } from './container/warehouses-container.component';
import { WarehousesPage } from './page/warehouses-page.component';
import { WarehousesTableView } from './table-view/warehouses-table-view.component';

const warehouseConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.warehouseList', {
      url: '/warehouses-list/',
      component: 'warehousesListPage',
      data: { pageTitle: gettext('Warehouses'), permissions: { only: 'can_view_warehouses_list' } },
      resolve: {
        filterLevel: () => 'warehouses-page',
        queryParams: [
          'PageService',
          (PageService: PageService) => PageService.syncUrlFilter('warehouses-page'),
        ],
      },
    });
  },
];

export const warehousesList = ng
  .module('warehouses.list', [])
  .component('warehousesListPage', WarehousesPage)
  .component('warehousesListContainer', WarehousesContainer)
  .component('warehousesTableView', WarehousesTableView)
  .config(warehouseConfig).name;
