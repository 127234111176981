import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './oil-extension-list-table-view.html?raw';

export const OilExtensionListTableView = {
  bindings: {
    list: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    setHovering: '&',
    tableName: '<?',
  },
  template,
  controller: [
    'gettext',
    'GtUtils',
    '$window',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      GtUtils: GtUtilsService;
      applyFilters: any;
      count: number;
      destroy: any;
      filterLevel = 'oil-extension-list-table-view';
      gettext: ng.gettext.gettextFunction;
      list: any;
      numberInput: any;
      saveItem: any;
      selection: any;
      tableApi: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      total: any;
      updateData: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$window = $window;
        this.$rootScope = $rootScope;

        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onChanges(changes: any) {
        this.tableName = this.tableName || 'oil-extension';
        if (changes.list || changes.count || changes.total) {
          this.tableOptions = this.getTableOptions();
          this.tableData = { rows: this.list, count: this.count, total: this.total };
        }
      }

      clone(item: any) {
        Object.assign(this.tableData.rows[0], item, {
          author: undefined,
          create_time: undefined,
          editor: undefined,
          update_time: undefined,
          id: undefined,
        });
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'contract-charges-table oil-extension-table',
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: true,
          showMore: true,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            clone: (item: any) => this.clone(item),
            updateData: () => this.updateData(),
            setHovering: (value: any) => this.tableApi.setHovering({ value: value }),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            $rootScope: this.$rootScope,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [
            {
              title: this.gettext('Actions'),
              columnName: 'actions',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <a
                ng-click="args.clone(item); args.scrollToTop()"
                class="btn btn-xs table-edit-btn"
                ng-if="!$ctrl.edit && item.id"
              >
                <i class="fa fa-files-o"></i>
              </a>
              <a class="btn btn-xs table-edit-btn" ng-hide="$ctrl.edit || !item.id" ng-click="$ctrl.edit = true">
                <i class="fa fa-pencil-square"></i>
              </a>
              <span ng-if="$ctrl.edit || !item.id" class="input-group-table-edit-btn">
                <a
                  class="btn-success btn_success btn btn-md"
                  ng-if="item.id"
                  ng-click="args.saveItem(item);$ctrl.edit = false"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  class="btn-success btn_success btn btn-md"
                  ng-if="!item.id"
                  ng-click="args.saveItem(item)"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  class="btn btn-md"
                  ng-if="item.id"
                  ng-click="$ctrl.edit = false"
                >
                  <i class="fa fa-times"></i>
                </a>
                <a
                  class="btn-danger btn_danger btn btn-md"
                  ng-if="item.id"
                  ng-click="args.destroy(item.id);$ctrl.edit = false"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </span>`,
            },
            {
              title: this.gettext('Market price'),
              columnName: 'market_price',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                {[{ item.market_price || 0 | number: 2  }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input class="form-control" gt-clear-input type="number" ng-model="item.market_price">
              </span>
              `,
            },
            {
              title: this.gettext('Commission'),
              columnName: 'commission',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                {[{ item.commission || 0 | number: 2  }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input class="form-control" gt-clear-input type="number" ng-model="item.commission">
              </span>
              `,
            },
            {
              title: this.gettext('Density'),
              columnName: 'density',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                {[{ item.density || 0 | number: 5  }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input class="form-control" gt-clear-input type="number" ng-model="item.density">
              </span>
              `,
            },
            {
              title: this.gettext('Quantity'),
              columnName: 'density',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                <span class="label-strong" ng-if="item.is_liquid">
                  {[{ item.contract_volume * item.density || 0 | number: 3  }]}
                </span>
              </span>
              `,
            },
            {
              title: this.gettext('Is liquid'),
              columnName: 'is_liquid',
              cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id && item.is_liquid">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!$ctrl.edit && item.id && !item.is_liquid">
                <i class="fa fa-circle-o"></i>
              </span>

              <input
                ng-if="$ctrl.edit || !item.id"
                style="width: 100%;"
                type="checkbox"
                ng-model="item.is_liquid"
              >`,
            },
            {
              title: this.gettext('Use escalations'),
              columnName: 'use_escalations',
              cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id && item.use_escalations">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!$ctrl.edit && item.id && !item.use_escalations">
                <i class="fa fa-circle-o"></i>
              </span>

              <input
                ng-if="$ctrl.edit || !item.id"
                style="width: 100%;"
                type="checkbox"
                ng-model="item.use_escalations"
              >`,
            },
          ],
        };
      }
    },
  ],
};
