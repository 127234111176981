import type ng from 'angular';

import { type GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import { type LogisticsService } from '~/app/execution/legacy/logistics.srv';

import template from './logistics-container.html?raw';

class LogisticsContainerController implements ng.IController {
  _error: any;
  allowRefreshCheckboxes = false;
  filterLevel?: string;
  googleSheetUrl?: string;
  initQueryParams: QueryParams = {};
  light: any;
  listResources: Record<string, any>;
  logistics = [];
  logisticsCount = 0;
  logisticsExportConfig: string;
  logisticsIntermediateTotals = {};
  logisticsMainTotals = {};
  logisticsQueryParams: QueryParams = {};
  logisticsTotal = {};
  newLogistic: any;
  newLogisticInitData: any;
  queryParams: QueryParams & { serializer: string } = { serializer: 'table_info' };
  reportConfig: any;
  selectedTotals = {};
  showAllOnInvoicing = false;
  showMore = [];
  tableName?: string;
  totalResources: Record<string, any>;
  view: any;
  readonly?: boolean;
  activeReportConfig?: any;

  static readonly $inject = [
    '$scope',
    '$rootScope',
    'gettext',
    'LogisticsService',
    'gtFilterService',
    'GtUtils',
  ];

  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly gettext: ng.gettext.gettextFunction,
    private LogisticsService: LogisticsService,
    private gtFilterService: GtFilterService,
    private GtUtils: GtUtilsService,
  ) {
    this.logisticsExportConfig = this.$rootScope.user.settings.DEFAULT_LOGISTICS_WITH_QUALITY
      ? 'logistics-export-config-table_qualities'
      : 'logistics-export-config-table_info';
    this.listResources = {
      default: this.LogisticsService.Logistic.query,
      table_info: this.LogisticsService.Logistic.queryLight,
      table_costs: this.LogisticsService.Logistic.queryCostsLight,
      table_buyer: this.LogisticsService.Logistic.queryBuyerLight,
      table_supplier: this.LogisticsService.Logistic.querySupplierLight,
      table_certs: this.LogisticsService.Logistic.queryCertsLight,
      table_qualities: this.LogisticsService.Logistic.queryQualitiesLight,
      table_export: this.LogisticsService.Logistic.queryExportLight,
      table_pnl: this.LogisticsService.Logistic.queryPnl,
    };
    this.totalResources = {
      table_buyer: this.LogisticsService.Logistic.totalsSale,
      table_supplier: this.LogisticsService.Logistic.totalsPurchase,
      table_pnl: this.LogisticsService.Logistic.totalsPnl,
      default: this.LogisticsService.Logistic.totalsInfo,
    };
  }

  $onInit() {
    this.view = this.view || 'table';
    this.light = this.light || false;

    this.filterLevel = this.filterLevel ?? 'logistics-container';
    this.tableName = this.tableName ?? this.filterLevel;
    this.queryParams = {
      serializer: this.$rootScope.user.settings.DEFAULT_LOGISTICS_WITH_QUALITY
        ? 'table_qualities'
        : 'table_info',
      page_size: 100,
      ...this.initQueryParams,
    };
    this.newLogistic = { ...this.newLogisticInitData };

    this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
      this.queryParams = data;
      this.updateLogistics(true);
    });
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.$rootScope.$on(
      `gt-report-config-selected_${this.filterLevel}`,
      (event: any, config: any) => {
        this.reportConfig = config ? config.column_params : null;
      },
    );
  }

  updateLogistics = (updateTotals = true) => {
    this.GtUtils.overlay('show', true);
    this.allowRefreshCheckboxes = false;
    return this.getResource()(this.queryParams).$promise.then((data: any) =>
      this.logisticUpdateClb(data, updateTotals),
    );
  };

  getResource() {
    return (
      (this.light && this.listResources[this.queryParams.serializer]) ?? this.listResources.default
    );
  }

  getTotalsResource(serializer?: string) {
    return (
      this.totalResources[serializer ?? this.queryParams.serializer] || this.totalResources.default
    );
  }

  logisticUpdateClb(data: any, updateTotals: any) {
    if (this.queryParams.next) {
      this.logistics = this.logistics.concat(data.results);
      delete this.queryParams.next;
    } else {
      this.logistics = data.results;
    }
    this.allowRefreshCheckboxes = true;
    this.logisticsCount = data.count;
    this.GtUtils.overlay('hide');
    this.queryParams.light = true;
    this.updateLogisticExportConfig();
    return updateTotals && this.updateTotals();
  }

  updateTotals() {
    if (this.getTotalsResource()) {
      return this.getTotalsResource()({ ...this.queryParams }).$promise.then((defaultData: any) => {
        this.logisticsTotal = { ...defaultData };

        this.getTotalsResource()({
          ...this.queryParams,
          logistic_type: 'main',
        }).$promise.then((mainData: any) => {
          this.logisticsMainTotals = { ...defaultData, ...mainData };
        });

        this.getTotalsResource()({
          ...this.queryParams,
          logistic_type: 'intermediate',
        }).$promise.then((intermediateData: any) => {
          this.logisticsIntermediateTotals = { ...defaultData, ...intermediateData };
        });
      });
    } else {
      this.totalResources.default(this.queryParams).$promise.then((defaultData: any) => {
        this.logisticsTotal = defaultData;
      });

      this.totalResources
        .default({ ...this.queryParams, logistic_type: 'main' })
        .$promise.then((defaultData: any) => {
          this.logisticsMainTotals = defaultData;
        });

      this.totalResources
        .default({ ...this.queryParams, logistic_type: 'intermediate' })
        .$promise.then((defaultData: any) => {
          this.logisticsIntermediateTotals = defaultData;
        });
    }
  }

  applyQualityDiscount() {
    if (!confirm(this.gettext('Do you want to update logistics by discount?'))) {
      return false;
    }
    return this.LogisticsService.Logistic.setDiscountByQuality(this.queryParams)
      .$promise.then(() => this.updateLogistics())
      .catch(() => this.GtUtils.notify(this.gettext('Data updated')))
      .finally((err: any) => this._error(err));
  }

  applyCheckLimit() {
    if (!confirm(this.gettext('Do you want to update status of logistics with limits?'))) {
      return false;
    }
    return this.LogisticsService.Logistic.setApprovalStatusProcess(this.queryParams)
      .$promise.then(() => this.updateLogistics())
      .catch(() => this.GtUtils.notify(this.gettext('Data updated')))
      .finally((err: any) => this._error(err));
  }

  setPurchasePrice() {
    if (
      !confirm(this.gettext('Do you want to update purchase price of logistics from indicator?'))
    ) {
      return false;
    }
    return this.LogisticsService.Logistic.setPurchasePriceFromIndicator(this.queryParams)
      .$promise.then(() => this.updateLogistics())
      .catch(() => this.GtUtils.notify(this.gettext('Data updated')))
      .finally((err: any) => this._error(err));
  }

  getSelectedTotals(idList: any) {
    idList = idList || [];

    if (idList.length === 0) {
      this.selectedTotals = {};
      return;
    }

    const chunkSize = 50;
    const chunks: any = [];

    for (let i = 0; i < idList.length; i += chunkSize) {
      chunks.push(idList.slice(i, i + chunkSize));
    }

    const requests = chunks.map((chunk: any) => {
      return this.LogisticsService.Logistic.totalsInfo({
        id_list: chunk,
      }).$promise;
    });

    Promise.all(requests)
      .then((results) => {
        this.selectedTotals = results.reduce((acc, data) => {
          for (const key in data) {
            if (Object.hasOwn(data, key)) {
              if (!acc[key]) {
                acc[key] = 0;
              }
              acc[key] += data[key] || 0;
            }
          }
          return acc;
        }, {});
        setTimeout(() => {
          if (!this.$scope.$$phase) {
            this.$scope.$apply();
          }
        });
      })
      .catch((error) => {
        this._error(error);
      });
  }

  updateLogisticExportConfig() {
    this.logisticsQueryParams = { ...this.queryParams };
    delete this.logisticsQueryParams.page_size;
    this.gtFilterService.setQueryParams(this.logisticsQueryParams, this.logisticsExportConfig);
  }

  openLogisticsDocumentModal() {
    return this.LogisticsService.logisticsDocumentsModal(this.queryParams);
  }

  openLogisticModal() {
    return this.LogisticsService.logisticModal(this.newLogistic).then(() => this.updateLogistics());
  }

  saveLogistic(logistic: any) {
    return this.LogisticsService.Logistic.save(logistic, this.updateLogistics, (e: any) =>
      this.GtUtils.errorClb(e),
    ).$promise;
  }

  applyFilters(params: any) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }
}

export const logisticsContainer: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    addButton: '<?',
    quickAdd: '<?',
    showAllOnInvoicing: '<?',
    view: '<?',
    light: '<?',
    sertDocument: '<?',
    canConnectToDbl: '<?',
    onConnectionToDbl: '&?',
    newLogisticInitData: '<?',
    readonly: '<?',
    activeReportConfig: '<?',
  },
  template: template,
  controller: LogisticsContainerController,
};
