import type { useAuth } from '~/core/auth/hooks';
import { RootLayout } from '~/core/theme/components';
import { ErrorPage } from '~/pages/common/error';
import { NotFoundPage } from '~/pages/common/not-found';
import { createRootRouteWithContext } from '~/shared/lib/router';

export const Route = createRootRouteWithContext<{ auth: ReturnType<typeof useAuth> }>()({
  component: RootLayout,
  notFoundComponent: NotFoundPage,
  errorComponent: ErrorPage,
  wrapInSuspense: true,
});
