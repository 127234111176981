import type { GtRootScopeService } from '~/app/core/types';

import template from './charts-block.html?raw';

export const ChartsBlock = {
  bindings: {
    config: '<',
    chartType: '@?',
    hideHeader: '<?',
    hideSelects: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      chartType: any;
      colors: any;
      config: any;
      data: any;
      dataKeys: any;
      firstDataKey: any;
      hideSelects: any;
      labels: any;
      options: any;
      secondDataKey: any;
      constructor($rootScope: GtRootScopeService) {
        this.$rootScope = $rootScope;

        this.labels = [];
        this.data = [];
        this.options = {};
        this.dataKeys = [];
        this.firstDataKey = null;
        this.secondDataKey = undefined;
        this.colors = ['#3575b7', '#d4b668', '#35B78B', '#f45f5c'];
      }

      $onChanges(changes: any) {
        if (this.config && changes.config) {
          this.chartType = this.chartType || this.$rootScope.user.settings.CHART_TYPE || 'line';
          this.options = {
            legend: {
              display: this.config?.series,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          };
          this.updateData();
        }
      }

      updateData() {
        this.options = this.config.options
          ? Object.assign(this.options, this.config.options)
          : this.options;

        if (Array.isArray(this.config.data)) {
          this.data = this.config.data;
          this.hideSelects = true;
          return;
        }
        this.colors = this.config.colors || this.colors;
        this.dataKeys = Object.keys(this.config.data);
        this.firstDataKey = this.firstDataKey || this.dataKeys[0];
        if (this.dataKeys.length > 1 && this.secondDataKey === undefined) {
          this.secondDataKey = this.secondDataKey || this.dataKeys[1];
        }
        this.data = [this.config.data[this.firstDataKey]];
        if (this.secondDataKey) {
          this.data.push(this.config.data[this.secondDataKey]);
        }
        if (this.data.length == 1) {
          this.data = this.data[0];
        }
      }
    },
  ],
};
