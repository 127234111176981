import template from './reservation-details-view.html?raw';

export const ReservationDetailsView = {
  bindings: {
    filterLevel: '<?',
    item: '<',
    fields: '<',
    tab: '<',
    openModal: '&',
    changeTab: '&',
  },
  template,
  controller: [
    class {
      fields: any;
      item: any;
      constructor() {
        this.fields = [];
        this.item = {};
      }
    },
  ],
};
