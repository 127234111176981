import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').filter('trusted', filter);

  filter.$inject = ['$sce'];

  function filter($sce: any) {
    return function (url: any) {
      return $sce.trustAsResourceUrl(url);
    };
  }
})();
