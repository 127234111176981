import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './client-invoice-payment-reconciliation-report-table.html?raw';

export const ClientInvoicePaymentReconciliationReportTable = {
  bindings: {
    filterLevel: '<?',
    reportData: '<?',
  },
  template,
  controller: [
    'moment',
    'gettext',
    'GtUtils',
    class {
      GtUtils: GtUtilsService;
      config: any;
      detailsLevel: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      levels: any;
      moment: any;
      reportData: any;
      constructor(moment: any, gettext: ng.gettext.gettextFunction, GtUtils: GtUtilsService) {
        this.moment = moment;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.filterLevel = '';
        this.detailsLevel = 0;
        this.reportData = [];
      }

      $onInit() {
        this.reportData = this.reportData || [];
        this.filterLevel = this.filterLevel || 'client-invoice-payment-reconciliation-report-table';
        this.config = this.getTheadConfig();
      }

      $onChanges(changes: any) {
        if (changes.reportData && this.reportData.length) {
          this.detailsLevel = Math.max(...this.reportData.map((v: any) => v.level));
          this.levels = Array.from(Array(this.detailsLevel + 1).keys());
        }
      }

      prettifyDate(date: any) {
        return this.moment(date).format('DD.MM.YYYY');
      }

      getRowClass(row: any) {
        return (
          {
            0: 'mega-highlighted-tr',
            1: 'super-highlighted-tr',
            2: 'highlighted-tr',
            3: '',
            4: '',
            undefined: '',
          }[row.level] || ''
        );
      }

      showLevel(level: any) {
        this.reportData
          .filter((v: any) => v.level !== undefined)
          .forEach((v: any) => (v.expand = v.level < level));
      }

      updateVisibility(row: any, expand: any) {
        row.expand = expand;
        if (!expand) {
          this.reportData
            .filter((v: any) => v.parentIndex === row.index)
            .forEach((x: any) => {
              this.updateVisibility(x, expand);
            });
        }
      }

      getTheadConfig() {
        const config: any = {
          tabs: [],
          columns: [
            {
              title: this.gettext('Counterparty'),
              level: 1,
              type: 'client',
              columnName: 'counterparty',
              predicate: 'counterparty',
              group: 'groups',
              icon: '',
              iconTotal: 'fa fa-pie-chart',
              titleTotal: this.gettext('TOTAL'),
              goToObject: (item: any) => this.GtUtils.goToDetails('clients.client', item.id),
              getTitle: (item: any) => item.name,
              ngClass: () => '',
            },
            {
              title: this.gettext('Currency'),
              level: 2,
              columnName: 'currency',
              predicate: 'currency',
              group: 'groups',
              icon: '',
              getTitle: (item: any) => item.symbol,
              ngClass: () => '',
            },
            {
              title: this.gettext('Invoice type'),
              level: 3,
              columnName: 'invoice_type',
              predicate: 'invoice_type',
              group: 'groups',
              icon: '',
              getTitle: (item: any) => item,
              ngClass: () => '',
            },
            {
              title: this.gettext('Invoice date'),
              columnName: 'invoiceDate',
              predicate: 'invoice',
              type: 'date',
              group: 'invoice',
              icon: '',
              goToObject: (item: any) => this.GtUtils.goToDetails('finances.finance', item.id),
              getTitle: (item: any) => this.prettifyDate(item.date),
              ngClass: () => '',
            },
            {
              title: this.gettext('Invoice'),
              columnName: 'invoice',
              predicate: 'invoice',
              type: 'payment',
              group: 'invoice',
              goToObject: (item: any) => this.GtUtils.goToDetails('finances.finance', item.id),
              getTitle: (item: any) => item.number,
              icon: '',
              ngClass: () => '',
            },
            {
              title: this.gettext('Volume'),
              columnName: 'volume',
              predicate: 'volume',
              group: 'invoice',
              icon: '',
              type: 'number',
              getTitle: (item: any) => item,
            },
            {
              title: this.gettext('Payment date'),
              columnName: 'paymentDate',
              predicate: 'payment_max_date',
              group: 'invoice',
              type: 'date',
              getTitle: (item: any) => this.prettifyDate(item),
              icon: '',
            },
            {
              title: this.gettext('Debit'),
              columnName: 'debit',
              predicate: 'debit',
              group: 'data',
              type: 'number',
              getTitle: (item: any) => item,
              ngClass: (x: any) => ({
                'negative-number': x < 0,
                'positive-number': x > 0,
              }),
            },
            {
              title: this.gettext('Credit'),
              columnName: 'credit',
              predicate: 'credit',
              group: 'data',
              type: 'number',
              getTitle: (item: any) => item,
              ngClass: (x: any) => ({
                'negative-number': x < 0,
                'positive-number': x > 0,
              }),
            },
            {
              title: this.gettext('Balance'),
              columnName: 'balance',
              predicate: 'balance',
              group: 'data',
              type: 'number',
              getTitle: (item: any) => item,
              ngClass: (x: any) => ({
                'negative-number': x < 0,
                'positive-number': x > 0,
              }),
            },
            {
              title: this.gettext('Debit default'),
              columnName: 'debitDefault',
              predicate: 'debit_default',
              group: 'data_default',
              type: 'number',
              getTitle: (item: any) => item,
              ngClass: (x: any) => ({
                'negative-number': x < 0,
                'positive-number': x > 0,
              }),
            },
            {
              title: this.gettext('Credit default'),
              columnName: 'creditDefault',
              predicate: 'credit_default',
              group: 'data_default',
              type: 'number',
              getTitle: (item: any) => item,
              ngClass: (x: any) => ({
                'negative-number': x < 0,
                'positive-number': x > 0,
              }),
            },
            {
              title: this.gettext('Balance default'),
              columnName: 'balanceDefault',
              predicate: 'balance_default',
              group: 'data_default',
              type: 'number',
              getTitle: (item: any) => item,
              ngClass: (x: any) => ({
                'negative-number': x < 0,
                'positive-number': x > 0,
              }),
            },
          ],
          columnSets: [
            { groupTitle: this.gettext('Groups'), group: 'groups' },
            { groupTitle: this.gettext('Invoice data'), group: 'invoice' },
            { groupTitle: this.gettext('By invoice currency'), group: 'data' },
            { groupTitle: this.gettext('By default currency'), group: 'data_default' },
          ],
        };
        config.columnSets.forEach((item) => {
          // @ts-ignore
          item.count = config.columns.filter((v) => v.group === item.group).length;
        });
        return config;
      }
    },
  ],
};
