import type ng from 'angular';
export class ClientsAnalyticsService {
  $filter: ng.IFilterService;
  $http: ng.IHttpService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  analyticsData: any;
  moment: any;

  static readonly $inject = ['$resource', '$q', '$http', '$filter', 'moment'];
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService,
    moment: any,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.moment = moment;

    this.analyticsData = undefined;
  }

  getAnalyticsData(params?: any) {
    if (!params && this.analyticsData) {
      return this.$q.when().then(() => this.analyticsData);
    }
    this.analyticsData = this.$http({
      method: 'GET',
      url: '/api/clients/analytics/',
      params: params,
    }).then((response: any) => {
      this.analyticsData = response.data;
      return this.analyticsData;
    });
    return this.analyticsData;
  }

  getUserResponsibles() {
    return this.getAnalyticsData().then((data: any) => {
      data.responsibles_count.forEach((i: any) => {
        i.clients_count = i.clients_count || 1;
        i.responsible_count = i.responsible_count || 0;
        i.responsible_confirmation_count = i.responsible_confirmation_count || 0;
        i.responsible_count_percentage = (i.responsible_count / i.clients_count) * 100;
        i.responsible_confirmation_count_percentage =
          (i.responsible_confirmation_count / i.clients_count) * 100;
      });
      return this.$filter('orderBy')(data.responsibles_count, 'responsible_count', true);
    });
  }

  getBuyerConfirmations() {
    return this.getAnalyticsData().then((data: any) => data.buyer_confirmations);
  }

  getSlowpokeClients() {
    return this.getAnalyticsData().then((data: any) => {
      data.slowpoke_clients.forEach((item: any) => {
        item.days = Math.ceil(
          this.moment.duration(item.date_confirmation - item.create_time, 'milliseconds').asDays(),
        );
      });
      return data.slowpoke_clients;
    });
  }

  getApprovedChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], [], []],
      series: ['Created', 'Approved', 'Process'],
    };

    return this.getAnalyticsData().then((data: any) => {
      let dates: any = [];
      data.client_create_time_months.forEach((i: any) => dates.push(i.create_time_month));
      data.client_date_confirmation_months.forEach((i: any) =>
        dates.push(i.date_confirmation_month),
      );
      data.client_in_process_months.forEach((i: any) => dates.push(i.month));
      dates = this.$filter<(str: string) => string>('orderBy')(dates);
      dates = dates.map((d: any) => this.moment(d).format('MMM, YY'));
      dates = dates.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) {
          a.push(b);
        }
        return a;
      }, []);
      dates.forEach((d: any) => {
        chartConfig.labels.push(d);
        const created = data.client_create_time_months
          .filter((i: any) => {
            return this.moment(i.create_time_month).format('MMM, YY') == d;
          })
          .shift();
        chartConfig.data[0].push(created?.count || 0);
        const approved = data.client_date_confirmation_months
          .filter((i: any) => {
            return this.moment(i.date_confirmation_month).format('MMM, YY') == d;
          })
          .shift();
        chartConfig.data[1].push(approved?.count || 0);
        const process = data.client_in_process_months
          .filter((i: any) => {
            return this.moment(i.month).format('MMM, YY') == d;
          })
          .shift();
        chartConfig.data[2].push(process?.count || 0);
      });

      return chartConfig;
    });
  }

  getRelationsChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Relations: [],
      },
      series: ['Hot', 'Warm', 'Neutral', 'Cold', 'Angry'],
      colors: ['#E5B878', '#1bc98e', '#A8B0BD', '#34495e', '#CF6163'],
    };
    const relations = {
      Hot: 0,
      Warm: 0,
      Neutral: 0,
      Cold: 0,
      Angry: 0,
    };
    return this.getAnalyticsData().then((data: any) => {
      data.role_relations.forEach((d: any) => (relations[d.relations] = d.count));
      Object.keys(relations).forEach((k) => {
        chartConfig.labels.push(k);
        chartConfig.data.Relations.push(relations[k]);
      });
      return chartConfig;
    });
  }

  getSuppliersContractVolumes() {
    return this.getAnalyticsData().then((data: any) => {
      data.supplier_contract_volumes.forEach((i: any) => {
        i.volume_percentage = (i.volume__sum / data.contracts_total.purchase_volume__sum) * 100;
        i.volume_percentage = Math.round(i.volume_percentage * 100) / 100;
      });
      return data.supplier_contract_volumes;
    });
  }

  getSuppliersContractVolumesChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volumes: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.supplier_contract_volumes.forEach((i: any) => {
        chartConfig.labels.push(i.supplier__name);
        chartConfig.data.Volumes.push(Math.round(i.volume__sum * 100) / 100);
      });
      return chartConfig;
    });
  }

  getBuyersContractVolumes() {
    return this.getAnalyticsData().then((data: any) => {
      data.buyer_contract_volumes.forEach((i: any) => {
        i.volume_percentage = (i.volume__sum / data.contracts_total.sale_volume__sum) * 100;
        i.volume_percentage = Math.round(i.volume_percentage * 100) / 100;
      });
      return data.buyer_contract_volumes;
    });
  }

  getBuyersContractVolumesChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volumes: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.buyer_contract_volumes.forEach((i: any) => {
        chartConfig.labels.push(i.buyer__name);
        chartConfig.data.Volumes.push(Math.round(i.volume__sum * 100) / 100);
      });
      return chartConfig;
    });
  }

  getExportersContractVolumes() {
    return this.getAnalyticsData().then((data: any) => {
      data.exporter_contract_volumes.forEach((i: any) => {
        i.volume_percentage = (i.volume__sum / data.contracts_total.purchase_volume__sum) * 100;
        i.volume_percentage = Math.round(i.volume_percentage * 100) / 100;
      });
      return data.exporter_contract_volumes;
    });
  }

  getExportersContractVolumesChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volumes: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.exporter_contract_volumes.forEach((i: any) => {
        chartConfig.labels.push(i.exporters__name);
        chartConfig.data.Volumes.push(Math.round(i.volume__sum * 100) / 100);
      });
      return chartConfig;
    });
  }

  getSuppliersCanceledContracts() {
    return this.getAnalyticsData().then((data: any) => data.cancelled_suppliers);
  }
}
