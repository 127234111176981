import React from 'react';

import { cn } from '~/shared/lib/utils';
import { ChevronRightIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';

import { PaginationButtonsList } from './paginations-buttons-list';
import { calculateCanClickPage, calculatePaginationButtonsBar } from '../../lib';

export const PaginationButtonsBar: React.FC<{
  pageTotal: number;
  pageCurrent: number;
  onPageClick: (page: number) => void;
  className?: string;
}> = ({ pageTotal, onPageClick, pageCurrent, className }) => {
  const buttonsBar = React.useMemo(
    () => calculatePaginationButtonsBar({ pageCurrent, pageTotal }),
    [pageCurrent, pageTotal],
  );

  const { canClickNextPage, canClickPreviousPage } = calculateCanClickPage({
    pageCurrent,
    pageTotal,
  });

  return (
    <div
      className={cn('flex items-center gap-2', className)}
      data-testid={'pagination__buttons_bar'}
    >
      <div className="h-full w-6 rotate-180">
        <Button
          variant="fadedPrimary"
          disabled={!canClickPreviousPage}
          onClick={() => onPageClick(Math.max(pageCurrent - 1, 0))}
          data-testid="pagination__buttons_bar__previous_btn"
          fullWidth
        >
          <div className="flex items-center justify-center">
            <ChevronRightIcon size={16} color="grey" />
          </div>
        </Button>
      </div>
      <PaginationButtonsList
        onPageClick={onPageClick}
        buttonsBar={buttonsBar}
        pageCurrent={pageCurrent}
      />
      <div className="w-6">
        <Button
          variant="fadedPrimary"
          onClick={() => onPageClick(Math.min(pageCurrent + 1, pageTotal))}
          disabled={!canClickNextPage}
          data-testid="pagination__buttons_bar__next_btn"
          fullWidth
        >
          <div className="flex w-6 items-center justify-center">
            <ChevronRightIcon size={16} color="grey" />
          </div>
        </Button>
      </div>
    </div>
  );
};
