import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './logistic-delete-container.html?raw';

export const LogisticDeleteContainer = {
  bindings: {
    selectedLogistics: '<?',
    onFinish: '&?',
    selectAll: '&',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'gettext',
    'LogisticsService',

    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      gettext: ng.gettext.gettextFunction;
      onFinish: any;
      selectedLogistics: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        LogisticsService: any,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.LogisticsService = LogisticsService;
      }

      delete() {
        const ids = this.selectedLogistics
          .filter((item: any) => item.id)
          .map((item: any) => item.id);

        if (!this.selectedLogistics.filter((item: any) => !!item.id).length) {
          return this.GtUtils.notify(this.gettext('No one logistic selected'), 'error');
        }

        const msg = this.gettext('Do you want to delete selected Logistics?');
        if (!confirm(msg)) {
          return;
        }

        this.GtUtils.overlay('show');

        this.LogisticsService.bulkDeleteLogistics(ids).then((deletedIds: any) => {
          this.GtUtils.notify(
            this.gettext(`Deleted ${deletedIds.length} logistics from ${ids.length} selected'`),
          );
          this.GtUtils.overlay('hide');
          this.close();
        });
      }
      close() {
        return this.onFinish();
      }
    },
  ],
};
