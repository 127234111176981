import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { contractDetailsItemContainer } from './item-container/contract-details-item-container.component';
import { contractDetailsPage } from './item-page/contract-details-page.component';
import { contractDetailsItemView } from './item-view/contract-details-item-view.component';

const contractConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('gt.page.contract', {
      url: '/contract/:id',
      component: 'contractDetailsPage',
      resolve: {
        filterLevel: () => {
          return 'contract-side-list';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('contract-side-list');
          },
        ],
      },
      data: {
        pageTitle: gettext('Contract'),
        permissions: {
          only: ['view_purchasecontract', 'view_salecontract', 'view_servicescontract'],
        },
      },
    });
  },
];

export const contractDetails = ng
  .module('deals.contracts.details', [])
  .component('contractDetailsPage', contractDetailsPage)
  .component('contractDetailsItemContainer', contractDetailsItemContainer)
  .component('contractDetailsItemView', contractDetailsItemView)
  .config(contractConfig).name;
