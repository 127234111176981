import type ng from 'angular';

import template from './passport-connection-shipment-plan-list-table-view.html?raw';
import type { PassportConnectionShipmentPlanService } from '../passport-connection-shipment-plan.service';

export const PassportConnectionShipmentPlanListTableView = {
  bindings: {
    data: '<',
    count: '<',
    total: '<',
    filterLevel: '<?',
    openModal: '&',
    openCloneModal: '&',
    destroy: '&',
  },
  template,
  controller: [
    'gettext',
    'PassportConnectionShipmentPlanService',
    class {
      PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService;
      count = 0;
      data: any;
      destroy: any;
      filterLevel = 'passport-connection-shipment-plan-list-table-view';
      gettext: ng.gettext.gettextFunction;
      openCloneModal: any;
      openModal: any;
      remove: any;
      save: any;
      tableData: any;
      tableOptions: any;
      total: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService,
      ) {
        this.gettext = gettext;
        this.PassportConnectionShipmentPlanService = PassportConnectionShipmentPlanService;
      }

      $onInit() {
        this.tableData = { rows: this.data, count: this.count, total: this.total };
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.data || changes.count || changes.total) {
          this.tableData = { rows: this.data, count: this.count, total: this.total };
        }
      }

      getTableOptions() {
        return {
          tableName: `${this.filterLevel}-table`,
          rowData: this.data,
          tableClass: 'table table-responsive table-condensed main-table contract-charges-table',
          configurable: true,
          templateArgs: {
            save: (item: any) => this.save(item),
            remove: (item: any) => this.remove(item),
            openModal: (item: any) => this.openModal({ plan: item }),
            openCloneModal: (item: any) => this.openCloneModal({ plan: item }),
            destroy: (itemId: any) => this.destroy({ planId: itemId }),
          },
          columnDefs: [
            {
              columnName: 'actions',
              title: this.gettext('Actions'),
              class: 'td-left-align no-blur',
              cellTemplate: `
                <a
                  class="btn btn-xs btn-add-dark"
                  ng-if="!args.readOnly"
                  permission
                  permission-only="'change_passportconnectionshipmentplan'"
                >
                  <i
                    class="fa fa-pencil-square"
                    ng-click="args.openModal(item)"
                    style="width: auto; cursor: pointer"
                  ></i>
                </a>
                <a
                  class="btn btn-xs btn-add-dark"
                  ng-if="!args.readOnly"
                  permission
                  permission-only="'add_passportconnectionshipmentplan'"
                >
                  <i
                    class="fa fa-files-o"
                    ng-click="args.openCloneModal(item)"
                    style="width: auto; cursor: pointer"
                  ></i>
                </a>
              `,
              totalTemplate:
                ' <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>',
            },
            {
              columnName: 'commodityTitle',
              title: this.gettext('commodity'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                {[{ item.commodity_title }]}
              `,
            },
            {
              columnName: 'businessunitName',
              title: this.gettext('businessunit'),
              class: 'td-left-align',
              cellTemplate: `
                {[{ item.businessunit_name }]}
              `,
            },
            {
              columnName: 'measurementName',
              title: this.gettext('measurement'),
              class: 'td-left-align',
              cellTemplate: `
                {[{ item.measurement_name }]}
              `,
            },
            {
              columnName: 'logisticTypeName',
              title: this.gettext('logistic_type'),
              class: 'td-left-align',
              cellTemplate: `
                {[{ item.logistic_type_name }]}
              `,
            },
            {
              columnName: 'producerName',
              title: this.gettext('producer'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                {[{ item.producer_name }]}
              `,
            },
            {
              columnName: 'exporterName',
              title: this.gettext('exporter'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                <span ng-if="item.exporter_name">
                 {[{ item.exporter_name }]}
                </span>
              `,
            },
            {
              columnName: 'commodityShipperName',
              title: this.gettext('commodity shipper'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                <span ng-if="item.commodity_shipper_name">
                 {[{ item.commodity_shipper_name }]}
                </span>
              `,
            },
            {
              columnName: 'commodityReceiverName',
              title: this.gettext('commodity_receiver'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                <span ng-if="item.commodity_receiver_name">
                 {[{ item.commodity_receiver_name }]}
                </span>
              `,
            },
            {
              columnName: 'shipmentWarehouseName',
              title: this.gettext('shipment warehouse'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                <span ng-if="item.shipment_warehouse_name">
                  {[{ item.shipment_warehouse_name }]}
                </span>
              `,
            },
            {
              columnName: 'unloadingWarehouseName',
              title: this.gettext('unloading warehouse'),
              class: 'td-left-align wrap-text',
              cellTemplate: `
                <span ng-if="item.unloading_warehouse_name">
                 {[{ item.unloading_warehouse_name }]}
                </span>
              `,
            },
            {
              columnName: 'volume',
              title: this.gettext('Volume, mt'),
              class: 'td-right-align',
              cellTemplate: `
                {[{ item.volume || 0 |number:3 }]} 
                ({[{ item.quantity || 0 }]} {[{ item.measurement_name }]})
              `,
              totalTemplate: `
                {[{ item.volume || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'discharge_approval_volume',
              title: this.gettext('Discharge approval volume, mt'),
              class: 'td-right-align',
              cellTemplate: `
                {[{ item.discharge_approval_volume || 0 |number:3 }]}
              `,
              totalTemplate: `
                {[{ item.discharge_approval_volume || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'volumeDelivered',
              title: this.gettext('Volume delivered, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.execution_data_basis_volume || 0 |number:3 }]}
              `,
              totalTemplate: `
                {[{ item.execution_data_basis_volume || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'volumeRest',
              title: this.gettext('Volume rest, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.plan_volume_rest || 0 |number:3 }]}
                ({[{ item.plan_quantity_rest || 0 |number:0 }]} {[{ item.measurement_name }]})
              `,
              totalTemplate: `
                {[{ item.plan_volume_rest || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'volumeAcceptedAtUnloading',
              title: this.gettext('Volume accepted at unloading, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.execution_data_export_invoice_volume || 0 |number:3 }]}
                ({[{ item.execution_data_export_invoice_count || 0 }]})
              `,
              totalTemplate: `
                {[{ item.execution_data_export_invoice_volume || 0 |number:3 }]}
                ({[{ item.execution_data_export_invoice_count || 0 }]})
              `,
            },
            {
              columnName: 'volumeCCD',
              title: this.gettext('Customs declaration volume, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.plan_ccd_volume || 0 |number:3 }]}
              `,
              totalTemplate: `
                {[{ item.plan_ccd_volume || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'volumeRestPlanBeforeCCD',
              title: this.gettext('Volume rest plan before CD, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.plan_ccd_rest_plan_volume || 0 |number:3 }]}
              `,
              totalTemplate: `
                {[{ item.plan_ccd_rest_plan_volume || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'volumeRestFactBeforeCCD',
              title: this.gettext('Volume rest fact before CD, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.plan_ccd_rest_fact_volume || 0 |number:3 }]}
              `,
              totalTemplate: `
                {[{ item.plan_ccd_rest_fact_volume || 0 |number:3 }]}
              `,
            },
            {
              columnName: 'volumeNormalWriteOff',
              title: this.gettext('Normal write-off volume, mt'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.volume_normal_write_off || 0 | number:6 }]}
              `,
              totalTemplate: `
                {[{ item.volume_normal_write_off || 0 |number:6 }]}
              `,
            },
            {
              columnName: 'quantityFact',
              title: this.gettext('Quantity fact'),
              class: 'td-right-align highlighted-td',
              cellTemplate: `
                {[{ item.execution_data_count || 0 }]}
              `,
              totalTemplate: `
                {[{ item.execution_data_count || 0 }]}
              `,
            },
          ],
        };
      }
    },
  ],
};
