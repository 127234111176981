import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './debit-credit-page.html?raw';

export const DebitCreditPage = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'PageService',
    'ReportsService',
    'moment',
    'gtFilterService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      assignmentOptions: any;
      filters: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      moment: any;
      reportsService: any;
      roleTypeOptions: any;
      statusOptions: any;
      filterLevel = 'debit-credit-page';
      view: any;
      queryParams: QueryParams = {};
      payablesQueryParams: QueryParams = {};
      receivablesQueryParams: QueryParams = {};

      constructor(
        $rootScope: GtRootScopeService,
        PageService: PageService,
        ReportsService: any,
        moment: any,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.PageService = PageService;
        this.reportsService = ReportsService;
        this.moment = moment;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;

        this.roleTypeOptions = [
          { name: this.gettext('Receivables'), id: 'to' },
          { name: this.gettext('Payables'), id: 'from' },
        ];
        this.assignmentOptions = [
          { id: 'commercial', name: this.gettext('Commercial Invoice') },
          { id: 'proforma', name: this.gettext('Proforma Invoice') },
          { id: 'debit', name: this.gettext('Debit Note') },
          { id: 'credit', name: this.gettext('Credit Note') },
        ];
        this.statusOptions = [
          { name: this.gettext('New'), id: 'new' },
          { name: this.gettext('Process'), id: 'process' },
          { name: this.gettext('Paid'), id: 'paid' },
          { name: this.gettext('Canceled'), id: 'canceled' },
        ];
        this.filters = {};
      }

      $onInit() {
        this.initQueryParams = this.initQueryParams || {};
        this.view = this.$rootScope.user.settings.DEFAULT_AP_AR_VIEW || 'split';
        this.queryParams = {
          ...{ role_type: 'to' },
          ...this.getInitDatesFilter(),
          ...this.initQueryParams,
        };
        this.filterLevel = this.filterLevel || 'debit-credit-page-view';
        this.PageService.setConfig(this.getPageConfig());

        this.payablesQueryParams = {
          ...this.getInitDatesFilter(),
          ...this.queryParams,
          role_type: 'from',
        };
        this.receivablesQueryParams = {
          ...this.getInitDatesFilter(),
          ...this.queryParams,
          role_type: 'to',
        };
      }

      getInitDatesFilter() {
        const date = new Date();
        return {
          start_date: this.moment(this.$rootScope.user.settings.DEBIT_CREDIT_START_DATE).format(
            'DD.MM.YYYY',
          ),
          end_date: this.moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
            'DD.MM.YYYY',
          ),
        };
      }

      applyFilter() {
        if (!this.queryParams.start_date || !this.queryParams.end_date) {
          return this.GtUtils.notify(this.gettext('Dates are reqired'), 'error');
        }
        if (!this.filters.with_payment_delay) {
          this.queryParams.with_payment_delay = null;
          this.queryParams.payment_delay_min_days = null;
          this.queryParams.payment_delay_min_days = null;
        } else {
          this.queryParams.with_payment_delay = '1';
          this.queryParams.payment_delay_min_days = this.queryParams.payment_delay_min_days ?? null;
          this.queryParams.payment_delay_min_days = this.queryParams.payment_delay_min_days ?? null;
        }

        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      resetFilter() {
        this.queryParams = {
          role_type: this.queryParams.role_type,
          ...this.getInitDatesFilter(),
        };
        this.applyFilter();
      }
      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.reportsService.getPagesConfig(),
        };
      }
    },
  ],
};
