import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './logistic-consignment-link-container.html?raw';

export const LogisticConsignmentLinkContainer = {
  bindings: {
    selectedLogistics: '<',
    queryParams: '<',
    selectAll: '&',
    onFinish: '&',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    '$state',
    'gettext',

    'FinancesService',
    'ContractsService',
    'LogisticConsignmentLinkService',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      ContractsService: ContractsService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      LogisticConsignmentLinkService: any;
      data: any;
      error: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      onFinish: any;
      queryParams: QueryParams = {};
      selectAll: any;
      selectAllChecked: any;
      selectedLogistics: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        $state: ng.ui.IStateService,
        gettext: ng.gettext.gettextFunction,

        FinancesService: FinancesService,
        ContractsService: ContractsService,
        LogisticConsignmentLinkService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.$state = $state;
        this.gettext = gettext;

        this.FinancesService = FinancesService;
        this.ContractsService = ContractsService;
        this.LogisticConsignmentLinkService = LogisticConsignmentLinkService;
        this.gtFilterService = gtFilterService;

        this.selectedLogistics = [];
        this.queryParams = {};
        this.selectAll = undefined;

        this.data = {};
        this.error = undefined;

        this.selectAllChecked = false;
      }

      $onInit() {
        this.$scope.$watchCollection(
          () => this.selectedLogistics,
          () => {
            this.updateData();
          },
        );
      }

      cleanEmptyValues(obj: any) {
        return Object.keys(obj)
          .filter((key) => {
            // @ts-ignore
            if (ng.isArray(obj[key])) {
              return !!obj[key].length;
            }
            // @ts-ignore
            if (ng.isObject(obj[key])) {
              return !!Object.keys(obj[key]).length;
            }
            return !!obj[key];
          })
          .reduce((result, key) => {
            result[key] = obj.key;
            return result;
          }, {});
      }

      updateData() {
        if (!this.selectedLogistics.length) {
          this.data = {};
          return;
        }
        return this.LogisticConsignmentLinkService.getGroupedData({
          logistic_ids: this.selectedLogistics.map((logistic: any) => logistic.id),
          ...this.queryParams,
        })
          .then((data: any) => (this.data = data.results))
          .catch((data: any) => this.GtUtils.errorClb(data))
          .finally(() => this.GtUtils.overlay('hide'));
      }

      close() {
        return this.onFinish();
      }
      createPassport() {
        const passportList: any = [];
        const localCurrency = this.FinancesService.getLocalCurrency().id;
        this.GtUtils.overlay('show');

        this.data.passport_list.forEach((passport: any) => {
          passport.currency = localCurrency;
          passport.cargo = parseInt(passport.cargo, 10);
          passport.sale_facts = passport.sale_facts.map((fact: any) => ({
            volume: fact.volume,
            contract: fact.contract_id,
          }));
          passport.purchase_facts = Object.keys(passport.purchase_facts).map((key) => ({
            volume: passport.purchase_facts[key].volume,
            contract: passport.purchase_facts[key].contract_id,
          }));
          passportList.push(passport);
        });

        return this.ContractsService.Passport.bulkCreateOrUpdate({ partial: true }, passportList)
          .$promise.then(() => {
            this.GtUtils.notify(this.gettext('Passports created.'));
          })
          .then(() =>
            this.LogisticConsignmentLinkService.bulkSave(
              this.data.logistic_consignment_list.map((item: any) => ({
                volume: item.volume,
                logistic: item.logistic_id,
                consignment: item.consignment_id,
              })),
            ),
          )
          .then(() => this.GtUtils.notify(this.gettext('Success created.')))
          .catch((data: any) => this.GtUtils.errorClb(data))
          .finally(() => this.GtUtils.overlay('hide'));
      }
    },
  ],
};
