import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { ReservationDetailsContainer } from './reservation-details/reservation-details-container/reservation-details-container.component';
import { ReservationDetailsPage } from './reservation-details/reservation-details-page/reservation-details-page.component';
import { ReservationDetailsView } from './reservation-details/reservation-details-view/reservation-details-view.component';
import { ReservationFormFieldsService } from './reservation-form-fields.service';
import { ReservationsListContainer } from './reservations-list/reservations-list-container/reservations-list-container.component';
import { ReservationsListPage } from './reservations-list/reservations-list-page/reservations-list-page.component';
import { ReservationsListTable } from './reservations-list/reservations-list-table/reservations-list-table.component';
import { ReservationsModal } from './reservations-modal/reservations-modal.component';
import { ReservationsService } from './reservations.service';

const config: any = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('logistics.reservationDetails', {
        url: '/reservation/:id',
        component: 'reservationDetailsPage',
        data: { pageTitle: gettext('Reservation') },
        resolve: {
          filterLevel: () => 'reservation-page',
          queryParams: [
            'PageService',
            (PageService: PageService) => PageService.syncUrlFilter('reservation-page'),
          ],
        },
      })
      .state('logistics.reservationList', {
        url: '/reservations/',
        component: 'reservationsListPage',
        data: { pageTitle: gettext('Reservations') },
        resolve: {
          filterLevel: () => 'reservations-list-page',
          queryParams: [
            'PageService',
            (PageService: PageService) => PageService.syncUrlFilter('reservations-list-page'),
          ],
        },
      });
  },
];

export const reservationsModule = ng
  .module('execution.reservations', [])
  .component('reservationDetailsPage', ReservationDetailsPage)
  .component('reservationDetailsContainer', ReservationDetailsContainer)
  .component('reservationDetailsView', ReservationDetailsView)
  .component('reservationsListPage', ReservationsListPage)
  .component('reservationsListContainer', ReservationsListContainer)
  .component('reservationsListTable', ReservationsListTable)
  .component('reservationsModal', ReservationsModal)
  .service('ReservationFormFieldsService', ReservationFormFieldsService)
  .service('ReservationsService', ReservationsService)
  .config(config).name;
