import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './client-list-container.html?raw';

export const ClientListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    globalAddButton: '@?',
    view: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    '$window',
    'ClientsService',
    'gtFilterService',
    'AccountsService',
    'GtUtils',
    'gettext',
    'CoreService',
    'PageService',
    'DocumentsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $window: ng.IWindowService;
      AccountsService: any;
      ClientsService: any;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      PageService: PageService;
      _error: any;
      activeFilterPresets: any;
      clients: any;
      clientsCount = 0;
      filterLevel = 'client-list-container';
      gettext: ng.gettext.gettextFunction;
      globalAddButton: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      newClient: any;
      newClientTemplate: any;
      queryParams: QueryParams = {};
      quickAdd: any;
      savedFilterChoices: any;
      view: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $state: ng.ui.IStateService,
        $window: ng.IWindowService,
        ClientsService: any,
        gtFilterService: GtFilterService,
        AccountsService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        CoreService: CoreService,
        PageService: PageService,
        DocumentsService: any,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$window = $window;
        this.ClientsService = ClientsService;
        this.gtFilterService = gtFilterService;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
        this.PageService = PageService;
        this.DocumentsService = DocumentsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.globalAddButton = this.globalAddButton || false;
        this.queryParams = { serializer: 'table_info' };
        this.clients = [];
        this.clientsCount = 0;
        this.quickAdd = false;
        this.newClient = {};
        this.newClientTemplate = {};
        this.activeFilterPresets = [];
      }

      $onInit() {
        this.initQueryParams = this.initQueryParams || {};
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);

        if (this.$rootScope.isDeviceMobile) {
          this.view = 'block';
        } else {
          this.view = ['list', 'block'].includes(this.$rootScope.user.profile.counterparties_view)
            ? this.$rootScope.user.profile.counterparties_view
            : (this.initQueryParams.view ?? 'table');
          delete this.initQueryParams.view;
        }

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.queryParams.serializer = this.queryParams.serializer ?? 'table_info';
          this.updateData();
        });
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.initQueryParams) {
          this.queryParams = { ...this.queryParams, ...this.initQueryParams };
          this.updateData();
        }
      }

      initNewClientTemplate(origin: any) {
        origin = origin || {};
        return { ...origin, ...this.initQueryParams };
      }

      applyFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      getQueryParams() {
        return this.gtFilterService.getQueryParams(this.filterLevel);
      }

      updateData(origin?: any) {
        this.GtUtils.overlay('show');
        this.newClientTemplate = this.initNewClientTemplate(origin);
        return this.ClientsService.getClientList(this.queryParams).then((data: any) => {
          this.PageService.setTabCounter('clients.client', data.count);
          if (this.queryParams.next) {
            this.clients = this.clients.concat(data.results);
            delete this.queryParams.next;
          } else {
            this.clients = data.results;
          }
          this.clientsCount = data.count;
          this.newClient = { $_edit: true };
          if (this.quickAdd && this.$rootScope.user.settings.ENABLE_INLINE_ADD) {
            this.clients.unshift(this.newClient);
          }
          this.GtUtils.overlay('hide');
        });
      }

      updateRegularStatus() {
        this.GtUtils.overlay('show');
        return this.ClientsService.Client.updateRegularStatus(
          {},
          () => {
            this.updateData();
            this.GtUtils.notify(this.gettext('Status updated'));
          },
          (data: any) => this._error(data),
        );
      }

      toggleInlineAdd() {
        if (this.quickAdd) {
          this.clients.shift();
        } else {
          this.newClient = { $_edit: true, ...this.newClientTemplate, ...this.newClient };
          this.clients.unshift(this.newClient);
        }
        this.clients = [...this.clients];
        this.quickAdd = !this.quickAdd;
      }

      createClient(client: any) {
        this.GtUtils.overlay('show');
        return this.ClientsService.Client.save(
          client,
          () => {
            this.GtUtils.notify(this.gettext(this.GtUtils.translate('Counterparty created')));
            this.updateData(client);
          },
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      deleteClient(client: any) {
        const msg = this.gettext('Are you sure that you want to delete?');
        if (!confirm(msg)) {
          return;
        }
        this.clients.splice(this.clients.indexOf(client), 1);
        this.updateData();
      }

      cloneClient(client: any, tab: any) {
        return this.ClientsService.cloneClient(client, tab).then(() => this.updateData());
      }

      openNewClientModal() {
        return this.ClientsService.clientModal().then(() => this.updateData());
      }

      openNewClientModalQuick() {
        return this.ClientsService.clientModalQuick().then(() => this.updateData());
      }

      openClientModal(client: any, tab: any) {
        return this.ClientsService.clientModal(client.id, null, tab).then(() => this.updateData());
      }
      openClientModalQuick(client: any, tab: any) {
        return this.ClientsService.clientModalQuick(client.id, null, tab).then(() =>
          this.updateData(),
        );
      }

      openClientDetails(client: any, tab: any) {
        return this.$state.go('gt.page.client', { id: client.id, tab: tab });
      }

      openEmailModal(client: any) {
        return this.ClientsService.sendEmailModal('Client', client.id);
      }

      approveClient(client: any, approve: any) {
        let whenApproval;

        if (approve) {
          whenApproval = this.AccountsService.approveObject('Client', client.id);
        } else {
          whenApproval = this.AccountsService.declineObject('Client', client.id);
        }

        whenApproval.then(
          () => {
            this.updateData();
            this.GtUtils.notify(this.gettext('Approval updated'));
          },
          (err: any) => this.GtUtils.errorClb(err),
        );
      }

      openDocxModal(client: any) {
        return this.DocumentsService.generateDocxModal('Client', client.id);
      }
    },
  ],
};
