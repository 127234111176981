import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';
import type { ImportExportContractsService } from '~/app/deals/contracts/import-export/import-export.service';

import template from './offset-container.html?raw';

export const OffsetContainer = {
  bindings: {
    filterLevel: '<',
    queryParams: '<',
    addButton: '<?',
  },
  template,
  controller: [
    '$scope',
    'OffsetService',
    'gtFilterService',
    'DocumentsService',
    'ImportExportContractsService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      ImportExportContractsService: ImportExportContractsService;
      OffsetService: any;
      filterLevel = 'offset-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      offsets: any;
      offsetsCount = 0;
      queryParams: QueryParams = {};
      total: any;
      constructor(
        $scope: ng.IScope,
        OffsetService: any,
        gtFilterService: GtFilterService,
        DocumentsService: any,
        ImportExportContractsService: ImportExportContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.OffsetService = OffsetService;
        this.gtFilterService = gtFilterService;
        this.DocumentsService = DocumentsService;
        this.ImportExportContractsService = ImportExportContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.offsetsCount = 0;
        this.offsets = [];
        this.total = {};
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        return this.OffsetService.getOffsetTableData(this.queryParams).then((data: any) => {
          this.offsets = data.results;
          this.offsetsCount = data.count;
          this.addUniqueContracts(this.offsets);
          return this.OffsetService.getOffsetTotals(this.queryParams).then((total: any) => {
            this.total = total;
            this.GtUtils.overlay('hide');
          });
        });
      }

      editOffset(offset: any) {
        this.OffsetService.offsetModal(offset).then(() =>
          this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel),
        );
      }

      openDocxModal(offset: any) {
        return this.DocumentsService.generateDocxModal('FinanceOffset', offset.id);
      }

      applyFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      openOffsetModal() {
        return this.OffsetService.offsetModal({}).then(() => this.updateTableData());
      }

      save(offset: any) {
        this.OffsetService.updateOffset(offset);
      }

      addUniqueContracts(offsets: any) {
        offsets.forEach((offset: any) => {
          if (offset.offset_contracts_data) {
            offset.offset_unique_contracts_data = this.getUniqueContracts(
              offset.offset_contracts_data,
            );
          }
        });
      }

      getUniqueContracts(contracts: any) {
        return contracts.reduce((acc: any, contract: any) => {
          if (!acc.some((c: any) => c.id === contract.id)) {
            acc.push(contract);
          }
          return acc;
        }, []);
      }
    },
  ],
};
