import type React from 'react';

import { ButtonView } from './button-view';
import { TextView } from './text-view';
import { ActionView } from '../types';

type ActionViewMap = Record<ActionView, React.ElementType>;

export const componentMap: ActionViewMap = {
  [ActionView.Text]: TextView,
  [ActionView.Button]: ButtonView,
};
