import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { QueryParams } from '~/app/core/types';

import type { AnalyticsService } from '../analytics.service';
import template from './analytics-page.html?raw';

export const AnalyticsPageComponent = {
  bindings: {
    initQueryParams: '<?',
  },
  template,
  controller: [
    'PageService',
    'AnalyticsService',
    class {
      AnalyticsService: AnalyticsService;
      PageService: PageService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(PageService: PageService, AnalyticsService: AnalyticsService) {
        this.PageService = PageService;
        this.AnalyticsService = AnalyticsService;
        this.initQueryParams = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return this.AnalyticsService.getAnalyticPageConfig(String(this.queryParams.page));
      }
    },
  ],
};
