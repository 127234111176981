import ng from 'angular';

(function () {
  'use strict';
  ng.module('reports.legacy').component('reportsDashboardPageView', {
    bindings: {},
    template: require('./reports-dashboard-page-view.tpl.html?raw'),
    controllerAs: 'vm',
  });
})();
