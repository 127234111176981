import ng from 'angular';

import { Calendar } from './calendar/calendar.component';
import { CalendarPage } from './calendar-page/calendar-page.component';
import { CalendarService } from './calendar.service';
import { GtCalendar } from './components/gt-calendar/gt-calendar.component';

export const calendar = ng
  .module('crm.calendar', [])
  .service('CalendarService', CalendarService)
  .component('calendarPage', CalendarPage)
  .component('calendar', Calendar)
  .component('gtCalendar', GtCalendar)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('crm.calendar', {
        url: '/calendar',
        component: 'calendarPage',
        data: {
          pageTitle: gettext('Calendar'),
        },
      });
    },
  ]).name;
