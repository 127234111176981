import ng from 'angular';

(function () {
  'use strict';
  ng.module('finances.legacy').directive('financesAnalyticsTable', directive);

  function directive() {
    return {
      template: require('./analytics-table.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        analytics: '<',
        count: '<',
        filterLevel: '<?',
      },
    };
  }

  Controller.$inject = ['gettext'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.theadConfig = getTheadConfig();

    ////////////////

    function getTheadConfig() {
      return [
        {
          title: gettext('Analytic'),
        },
        {
          title: gettext('TheadConfig'),
        },
      ];
    }
  }
})();
