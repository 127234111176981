import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.requests.legacy').directive('indicatorEdit', directive);

  function directive() {
    return {
      template: require('./indicator-edit.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        request: '=?',
        quickAdd: '=?',
        onSave: '=?',
        addUpdate: '<?',
        buildNewRequest: '<?',
      },
    };
  }

  Controller.$inject = [
    '$rootScope',
    '$scope',
    'ContractsService',
    'FinancesService',
    'ClientsService',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    ClientsService: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.save = save;
    vm.close = close;
    vm.errors = {};
    vm.currencyExchange = {};
    vm.saveDisabled = false;
    vm.onTypeChange = onTypeChange;
    vm.clientName = undefined;
    vm.buQueryParams = GtUtils.getBUQueryParams();
    vm.getEndOfExecution = getEndOfExecution;
    vm.getRequest = getRequest;

    vm.$onInit = function () {
      vm.initRequest = { ...vm.request };
      getRequest();

      vm.onSave = vm.onSave || function () {};
      $scope.$watch('vm.request.currency_exchange', updateCurrenyExchange);
      $scope.$watch('vm.request.execution_month', updateExecutionDates);
      $scope.$on(
        'gt-resource-select__search-finish__clients.supplier',
        function (ev: any, data: any) {
          if (data.search) {
            vm.clientName = data.search;
          }
          if (data.id) {
            vm.clientName = undefined;
          }
        },
      );
    };

    vm.$onChanges = function () {
      if (vm.addUpdate && vm.request.contract_type == 'purchase') {
        vm.newUpdate = {
          client: undefined,
          contract: undefined,
          update: undefined,
          future_contact: undefined,
        };
      }
    };

    ////////////////

    function getRequest() {
      vm.buildNewRequest?.();
      vm.request = { stage: 'indicator', ...vm.initRequest };
      vm.request.contract_type = vm.initRequest.contract_type || 'sale';
      const newRequest = ContractsService.getDefaultContract(vm.initRequest);
      newRequest.stage = 'indicator';
      vm.request = Object.assign(newRequest, vm.initRequest);
    }

    function onTypeChange() {
      vm.request.buyer = undefined;
      vm.request.supplier = undefined;
    }

    function updateCurrenyExchange() {
      return FinancesService.CurrencyExchange.get(
        {
          id: vm.request.currency_exchange,
        },
        function (data: any) {
          vm.currencyExchange = data;
        },
      );
    }

    function updateExecutionDates() {
      if (!vm.request.execution_month) {
        return;
      }
      vm.request.end_of_execution = new Date(
        vm.request.execution_month.getFullYear(),
        vm.request.execution_month.getMonth() + 1,
        0,
      );
      vm.request.date_of_execution = new Date(
        vm.request.execution_month.getFullYear(),
        vm.request.execution_month.getMonth(),
      );
    }

    function getEndOfExecution(event: any) {
      const date = event?.date;
      if (vm.request.end_of_execution || !date) {
        return false;
      }
      vm.request.end_of_execution = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    function close() {
      vm.quickAdd = false;
    }

    function save() {
      vm.saveDisabled = true;
      let hasErrors = false;

      if (!vm.request.cargo) {
        vm.saveDisabled = false;
        hasErrors = true;
        GtUtils.notify(gettext('Cargo is required'), 'error');
      }

      if (
        $rootScope.user.settings.DEFAULT_VALUES.owner &&
        vm.request.contract_type == 'sale' &&
        !vm.request.buyer &&
        !vm.clientName
      ) {
        vm.saveDisabled = false;
        hasErrors = true;
        GtUtils.notify(gettext('Buyer is required'), 'error');
      }

      if (
        $rootScope.user.settings.DEFAULT_VALUES.owner &&
        vm.request.contract_type == 'purchase' &&
        !vm.request.supplier &&
        !vm.clientName
      ) {
        vm.saveDisabled = false;
        hasErrors = true;
        GtUtils.notify(gettext('Supplier is required'), 'error');
      }

      if (hasErrors) {
        return;
      }

      if (vm.request.contract_type == 'sale') {
        vm.request.owner = vm.request.supplier;
      }

      if (vm.request.contract_type == 'purchase') {
        vm.request.owner = vm.request.buyer;
      }
      if (!vm.request.id) {
        if (vm.clientName) {
          const client = {
            name: vm.clientName,
            role_names: prepareClientRoles(),
          };
          GtUtils.notify(gettext('first_contact was automatically generated'));
          return ClientsService.Client.save(
            client,
            function (data: any) {
              ng.forEach(data.role_names_set, function (role: any) {
                vm.request[role.role_name] = role.id;
              });
              if (vm.request.exporter) {
                vm.request.exporters = [vm.request.exporter];
              }
              return createContract();
            },
            function (data: any) {
              vm.saveDisabled = false;
              GtUtils.errorClb(data);
            },
          );
        } else {
          return createContract();
        }
      }
      return ContractsService.Request.update(
        vm.request,
        function (data: any) {
          vm.request = data;
          vm.onSave();
          vm.errors = {};
          vm.saveDisabled = false;
        },
        _error,
      ).$promise;
    }

    function createContract() {
      return ContractsService.Request.save(
        vm.request,
        function (data: any) {
          if (
            vm.addUpdate &&
            vm.request.contract_type == 'purchase' &&
            vm.request.supplier &&
            (vm.newUpdate.update || vm.newUpdate.future_contact)
          ) {
            vm.newUpdate.contract = data.id;
            vm.newUpdate.client = vm.request.supplier;
            ClientsService.ClientUpdate.save(vm.newUpdate, function () {
              vm.newUpdate = {};
            });
          }

          vm.onSave(true);
          vm.errors = {};
          vm.saveDisabled = false;
          GtUtils.notify(gettext('Indicator saved'));
        },
        _error,
      ).$promise;
    }

    function prepareClientRoles() {
      const roleNames: string[] = [];
      if (vm.request.contract_type == 'purchase') {
        roleNames.push('Supplier');
      } else {
        roleNames.push('Buyer');
      }
      if (vm.isFarm) {
        roleNames.push('Farm');
      }
      if (vm.isElevator) {
        roleNames.push('Elevator');
      }
      if (vm.isExporter) {
        roleNames.push('Exporter');
      }
      return roleNames;
    }

    function _error(data: any) {
      vm.saveDisabled = false;
      GtUtils.errorClb(data);
      vm.errors = data.data;
    }
  }
})();
