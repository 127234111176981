import React from 'react';

import { FourCloversIcon, NewspaperIcon } from '~/shared/ui/icons';

import { ChoiceMenu, LimitAccess, Properties, TitleIcons } from '../view-configurator/data';
import type { CardView, ReorderedColumn, ViewConfigChanged } from '../view-configurator/types';

export const CardViewSettingsContent: React.FC<{
  columns: ReorderedColumn[];
  view: CardView;
  viewConfigChanged: ViewConfigChanged;
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({ columns, view, viewConfigChanged, columnsReordered, columnVisibilityChanged }) => {
  return (
    <>
      <ChoiceMenu
        viewId={view.id}
        title="Card Type"
        data={view.data.type}
        Icon={NewspaperIcon}
        viewConfigChanged={viewConfigChanged}
      />
      <TitleIcons
        isShow={view.data.isShowTitleIcons}
        showChanged={() =>
          viewConfigChanged(view.id, {
            data: { isShowTitleIcons: !view.data.isShowTitleIcons },
          })
        }
      />
      <Properties
        columns={columns}
        columnsReordered={columnsReordered}
        columnVisibilityChanged={columnVisibilityChanged}
      />
      <ChoiceMenu
        viewId={view.id}
        title="Group By"
        data={view.data.groupBy}
        Icon={FourCloversIcon}
        viewConfigChanged={viewConfigChanged}
      />
      <LimitAccess
        isLimitAccess={view.data.isLimitAccess}
        limitAccessChanged={() =>
          viewConfigChanged(view.id, { data: { isLimitAccess: !view.data.isLimitAccess } })
        }
      />
    </>
  );
};
