import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './analytics-page-view.html?raw';

export const AnalyticsPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'LogisticsService',
    'gettext',
    class {
      LogisticsService: any;
      PageService: PageService;
      filterLevel = 'transport-analytics-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(
        PageService: PageService,
        LogisticsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-logistics-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.LogisticsService.getPagesConfig(),
        };
      }
    },
  ],
};
