import ng from 'angular';

export const GtTableAlignColHeight = ($timeout: ng.ITimeoutService, $window: ng.IWindowService) => {
  return {
    restrict: 'A',
    scope: {
      gtTableAlignColHeight: '<',
    },
    link($scope: ng.IScope, $elem: any) {
      if (!($scope as any).gtTableAlignColHeight.alighColHeight) {
        return false;
      }

      const selector = ($scope as any).gtTableAlignColHeight.colSelector || 'td';
      const elem = $elem[0];

      $scope.$watch(
        (scope: any) => scope.gtTableAlignColHeight.watch,
        () => {
          if (($scope as any).gtTableAlignColHeight.alighColHeight) {
            $timeout(recalcHeight);
          } else {
            return false;
          }
        },
      );

      // eslint-disable-next-line sonarjs/deprecation
      ng.element($window).bind('resize', recalcHeight);

      function recalcHeight() {
        let maxHeight = 0;

        ng.forEach(elem.querySelectorAll(selector), (tdElem: any, i: any) => {
          if (i != 0) {
            maxHeight = (maxHeight > tdElem.clientHeight && maxHeight) || tdElem.clientHeight;
          }
        });
        maxHeight += 2;

        ng.forEach(elem.querySelectorAll(selector), function (tdElem: any) {
          tdElem.style.minHeight = `${maxHeight}px`;
        });
      }
    },
  };
};
GtTableAlignColHeight.$inject = ['$timeout', '$window'];
