import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './consolidated-freight-position-container.html?raw';

export const ConsolidatedFreightPositionContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'FreightPositionService',
    class sampleControlContainer {
      $scope: ng.IScope;
      FreightPositionService: any;
      GtUtils: GtUtilsService;
      data: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        FreightPositionService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.FreightPositionService = FreightPositionService;

        this.filterLevel = '';
        this.queryParams = {};
        this.initQueryParams = {};

        this.data = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'freight-position-page-view';
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: any) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.FreightPositionService.getConcolidatedData(this.queryParams).then(
          (data: any) => {
            this.data = data.data.results;
            this.GtUtils.overlay('hide');
          },
        );
      }
    },
  ],
};
