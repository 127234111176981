import ng from 'angular';

import type { GtUtilsService } from '../gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtResourceSelect', directive);

  function directive() {
    return {
      template: require('./gt-resource-select.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      link: link,
      scope: {
        ngModel: '=',
        resourceName: '=',
        placeholder: '=?',
        queryParams: '<?',
        onSearch: '&?',
        onSelect: '=?',
        onSelectArgs: '=?',
        allowClear: '=?',
        title: '<?',
        gtDisabled: '=?',
        onSelectClb: '&?',
        onOpenClose: '&?',
        submenu: '<?',
        required: '<?',
      },
    };
  }

  function link(scope: any) {
    scope.callOnSelect = function () {
      if (scope.vm.onSelectClb) {
        scope.vm.onSelectClb({ $event: '' });
      }
      if (scope.vm.onSelect) {
        scope.vm.onSelectArgs = scope.vm.onSelectArgs || [];
        return scope.vm.onSelect.apply(this, scope.vm.onSelectArgs);
      }
    };
  }

  Controller.$inject = ['$scope', '$timeout', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.updateData = updateData;
    vm.items = [];
    vm.clear = clear;
    vm.initLoad = true;
    vm.currentItem = {};
    vm.resource = vm.resourceName;
    vm.setCurrentItem = setCurrentItem;
    vm.isOpenHandler = isOpenHandler;
    vm.copyToClipboard = copyToClipboard;

    vm.$onInit = function () {
      initializeComponent();
    };

    function initializeComponent() {
      vm.model = vm.model || [];
      vm.onSearch = vm.onSearch || function () {};
      vm.placeholder = vm.placeholder || gettext('Choose');
      resolveResource();

      $scope.$watch('vm.resourceName', function (newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
          resolveResource();
          updateCurrentItem();
        }
      });

      $scope.$watch('vm.ngModel', function (newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
          updateCurrentItem();
        }
      });

      $scope.$watch('vm.title', function (newVal: any, oldVal: any) {
        if (newVal !== oldVal && vm.ngModel && vm.title) {
          vm.currentItem = { id: vm.ngModel, title: vm.title };
          vm.items = [vm.currentItem];
        }
      });

      if (vm.ngModel && vm.title) {
        vm.currentItem = { id: vm.ngModel, title: vm.title };
        vm.items = [vm.currentItem];
      }

      updateCurrentItem();
    }

    function updateCurrentItem() {
      if (!vm.ngModel) {
        vm.title = undefined;
        vm.items = [];
        return;
      }
      if (!vm.resource) {
        return;
      }
      const resource = GtUtils.getResource(vm.resource);
      const method = resource.predictions || resource.get;
      return method({ id: vm.ngModel }).$promise.then(handleItemSuccess, handleItemError);
    }

    function handleItemSuccess(item: any) {
      if (item.results?.length) {
        item = item.results.find((i: any) => i.id === vm.ngModel) || item.results[0];
      } else {
        handleEmptyResults();
        return;
      }
      if (item.length === 1) {
        item = item[0];
      }
      setCurrentItem({
        id: vm.ngModel,
        title: GtUtils.getObjectTitle(item),
      });
      vm.items.push(vm.currentItem);
      return vm.currentItem;
    }

    function handleEmptyResults() {
      vm.clear(null);
    }

    function handleItemError() {
      vm.clear(null);
    }

    function resolveResource() {
      vm.resource = (typeof vm.resourceName === 'function' && vm.resourceName()) || vm.resourceName;
    }

    function copyToClipboard(value: any, event: any) {
      event.stopPropagation();
      GtUtils.copyToClipboard(value);
    }

    function setCurrentItem(item: any) {
      vm.title = item.title;
      vm.currentItem = { id: item.id, title: item.title };
      if (!vm.items.some((curr: any) => curr.id === item.id)) {
        vm.items.push(vm.currentItem);
      }
      vm.ngModel = item.id;
    }

    function updateData(search?: string, force?: boolean) {
      if (vm.initLoad) {
        vm.initLoad = false;
        return;
      }

      vm.resource = (typeof vm.resourceName === 'function' && vm.resourceName()) || vm.resourceName;
      let params = GtUtils.filterEmptyParams(
        (typeof vm.queryParams === 'function' && vm.queryParams()) || vm.queryParams || {},
      );

      if (search !== undefined) {
        params = { ...params, search };
      }

      return GtUtils.getPredictions([vm.resource], params, undefined, force).then((data: any) => {
        handleUpdateDataSuccess(search, data, force);
      });
    }

    function handleUpdateDataSuccess(search: any, data: any, force: any) {
      $scope.$emit('gt-resource-select__search-finish__' + vm.resourceName, {
        search: search,
        id: vm.ngModel,
      });

      vm.onSearch({ event: { search: search, result: data } });
      vm.items = data[vm.resource];
      if (!vm.items?.length) {
        return !force && updateData(search, true);
      }

      const ngModelCanExtendItems =
        vm.ngModel && !vm.items.some((item: any) => item.id === vm.ngModel);
      const currentItemCanExtendItems = ngModelCanExtendItems && vm.currentItem?.id;

      if (currentItemCanExtendItems) {
        vm.items.push(vm.currentItem);
      } else if (ngModelCanExtendItems) {
        return updateCurrentItem();
      }
    }

    function clear(ev: any) {
      if (ev) {
        ev.stopPropagation();
      }
      vm.ngModel = null;
      $timeout(handleClearTimeout, 1000);
    }

    function handleClearTimeout() {
      if (vm.onSelect) {
        vm.onSelect(vm.onSelectArgs || []);
      }
      if (vm.onSelectClb) {
        vm.onSelectClb({ $event: '' });
      }
      vm.items = vm.items.filter((item: any) => item.id);
    }

    function isOpenHandler(isOpen: any) {
      if (typeof vm.onOpenClose === 'function') {
        vm.onOpenClose({ isOpen: isOpen });
      }
    }
  }
})();
