import React from 'react';

import { Link } from '~/shared/ui/kit/link';

import type { DataTypeProps } from '../../lib';

export const PhoneRead: React.FC<DataTypeProps<string>> = ({ disabled, value }) =>
  value ? (
    <div className="px-2">
      <Link href={`tel:${value}`} disabled={disabled}>
        {value}
      </Link>
    </div>
  ) : null;
