import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './offset-page.html?raw';
import type { OffsetService } from '../offset.service';

export const OffsetPageContainer = {
  template,
  controller: [
    '$stateParams',
    '$state',
    '$rootScope',
    'PageService',
    'OffsetService',
    'gettext',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      OffsetService: OffsetService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      offset: any;
      tab: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $state: ng.ui.IStateService,
        $rootScope: GtRootScopeService,
        PageService: PageService,
        OffsetService: any,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.PageService = PageService;
        this.OffsetService = OffsetService;
        this.gettext = gettext;
        this.offset = { id: $stateParams.id };
        this.GtUtils = GtUtils;
        this.tab = 'info';
        this.filterLevel = 'offset-page';
      }

      $onInit() {
        this.PageService.setConfig({});
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.OffsetService.getOffsetDetails({ id: this.offset.id })
          .then((data: any) => {
            this.offset = data;
            this.$rootScope.pageTitle = this.$rootScope.pageTitle + ' #' + this.offset.number;
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext("Offset doesn't exist"));
            this.$state.go('finances.offsetsPage');
          });
      }
    },
  ],
};
