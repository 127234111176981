import ng from 'angular';

import { CertificateDatesContainer } from './certificate-dates-container/certificate-dates-container.component';
import { CertificateDatesReportPage } from './certificate-dates-report-page/certificate-dates-report-page.component';
import { CertificateDatesReportTableView } from './certificate-dates-report-table-view/certificate-dates-report-table-view.component';
import { CertificateDatesService } from './certificate-dates.service';

export const certificateDates = ng
  .module('reports.certificateDates', [])
  .service('CertificateDatesService', CertificateDatesService)
  .component('certificateDatesContainer', CertificateDatesContainer)
  .component('certificateDatesReportTableView', CertificateDatesReportTableView)
  .component('certificateDatesReportPage', CertificateDatesReportPage).name;
