import ng from 'angular';

import { CostsBulkEditFieldsService } from './costs-bulk-edit-field.service';
import { InvoicePositionBulkEditFieldsService } from './invoice-position-bulk-edit-field.service';
import { TableBulkEditContainer } from './table-bulk-edit-container/table-bulk-edit-container.component';

export const tableBulkEditModule = ng
  .module('core.tableBulkEdit', [])
  .service('CostsBulkEditFieldsService', CostsBulkEditFieldsService)
  .service('InvoicePositionBulkEditFieldsService', InvoicePositionBulkEditFieldsService)
  .component('tableBulkEditContainer', TableBulkEditContainer).name;
