import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/deals/services/general-contract/$contractId/additional-agreements',
)({
  component: () => (
    <div>
      Hello /_auth/_sidebar/deals/services/_general-contract/$contractId/additional-agreements!
    </div>
  ),
});
