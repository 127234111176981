import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('company.legacy').directive('teamRow', directive);

  function directive() {
    return {
      template: require('./team-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        filterLevel: '<?',
        user: '<',
        update: '&?',
        count: '<?',
        index: '<?',
      },
    };
  }

  Controller.$inject = ['$rootScope', 'AccountsService'];

  function Controller(this: any, $rootScope: GtRootScopeService, AccountsService: any) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.counters = {};
    vm.openUserModal = openUserModal;

    ////////////////

    function openUserModal(item: any) {
      return AccountsService.userModal(item).then(() => vm.update());
    }
  }
})();
