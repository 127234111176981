import ng from 'angular';

import { AttachDocumentModal } from './attach-document-modal/attach-document-modal.component';
import { DocumentsListTableView } from './documents-list-table-view/documents-list-table-view.component';
import { documentsLegacyModule } from './legacy';

export const companyDocumentsModule = ng
  .module('company.documents', [documentsLegacyModule])
  .component('attachDocumentModal', AttachDocumentModal)
  .component('documentsListTableView', DocumentsListTableView).name;
