import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './contracts-derivatives-widget.html?raw';

export const ContractsDerivativesWidget = {
  bindings: {
    data: '<?',
    onChange: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      data: any;
      gettext: ng.gettext.gettextFunction;
      newObj: any;
      onChange: any;
      constructor($scope: ng.IScope, GtUtils: GtUtilsService, gettext: ng.gettext.gettextFunction) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.data = this.data || [];
        this.newObj = {};
      }

      saveItem() {
        const sameDerivative = this.data.filter(
          (item: any) => item.derivative == this.newObj.derivative,
        );
        if (sameDerivative.length) {
          return this.GtUtils.notify(
            this.gettext('The fields contract, derivative must make a unique set.'),
            'error',
          );
        }
        this.data.push({ ...this.newObj });
        this.onChange(this.data);
      }

      removeElement(element: any) {
        const index = this.data.indexOf(element);
        this.data.splice(index, 1);
        this.onChange(this.data);
      }
    },
  ],
};
