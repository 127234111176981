import React from 'react';

import { LocalSearch } from '~/core/theme/components/local-search';
import { toggleHandler } from '~/core/theme/components/menu/helpers';
import { useMenu } from '~/core/theme/hooks/use-menu';
import { useObservableEagerState } from '~/shared/lib/state';
import { cn } from '~/shared/lib/utils';

import { ExpandMenuItems } from '../expand-menu-items';
import { MenuSubItem } from '../menu-sub-item';
import { PageNavigation } from '../page-navigation';
import type { SidebarMenuItem } from '../types';

export const MenuItem: React.FC<{
  isOpen: boolean;
  item: SidebarMenuItem;
}> = ({ isOpen, item }) => {
  const { menuStore } = useMenu();

  const openMenuItemIds = useObservableEagerState(menuStore.openMenuItemIds$);

  const openMenuItemHandler = React.useCallback(
    (id: string) =>
      toggleHandler({
        id,
        currentIds: openMenuItemIds,
        updateMethod: (ids: string[]) => menuStore.openMenuItemIdsChanged(ids),
      }),
    [menuStore, openMenuItemIds],
  );

  return (
    <div
      className={cn(
        'relative flex w-full flex-col gap-0.5 pt-0.5',
        (isOpen || item.isActive) &&
          'after:absolute after:left-4 after:top-0 after:h-full after:w-[1px] after:bg-transparent-strong',
        !item.menus.length && 'hidden',
      )}
    >
      {item.menus.map((item) => (
        <div key={item.id}>
          <div className="ml-1 flex items-center gap-1">
            {!item.submenus ? (
              <PageNavigation href={item.href} isActive={item.isActive} label={item.label} />
            ) : (
              <ExpandMenuItems
                isOpen={openMenuItemIds.includes(item.id)}
                isActive={item.isActive}
                label={item.label}
                id={item.id}
                onClick={() => openMenuItemHandler(item.id)}
              />
            )}
          </div>
          {openMenuItemIds.includes(item.id) && <MenuSubItem isOpen={isOpen} item={item} />}
          <div
            className={cn(
              'relative left-1.5 flex flex-col gap-0.5',
              !openMenuItemIds.includes(item.id) &&
                'after:absolute after:left-[22px] after:top-0 after:h-full after:w-[1px] after:bg-transparent-strong',
            )}
          >
            {item.isActive && !openMenuItemIds.includes(item.id) ? <LocalSearch /> : null}
          </div>
        </div>
      ))}
    </div>
  );
};
