import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';
export class VehiclesService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  VehiclesResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    this.$uibModal = $uibModal;
    this.$resource = $resource;
    this.gettext = gettext;
    this.VehiclesResource = $resource(
      '/api/logistics/vehicles/:id/',
      {
        id: '@id',
      },
      {
        save: { method: 'POST' },
        update: { method: 'PUT' },
        delete: { method: 'DELETE' },
      },
    );
  }

  saveVehicle(vehicle: any) {
    return vehicle.id
      ? this.VehiclesResource.update({ id: vehicle.id }, vehicle).$promise
      : this.VehiclesResource.save(vehicle).$promise;
  }

  deleteVehicle(vehicleId: any) {
    return this.VehiclesResource.delete({ id: vehicleId }).$promise;
  }

  getPageConfig() {
    return [
      {
        title: this.gettext('Vehicles'),
        permissions: [],
        state: 'vehicles.list',
        icon: 'fa fa-car',
      },
    ];
  }

  getVehicle(vehicle: any) {
    return this.VehiclesResource.get({ id: vehicle }).$promise;
  }

  getFields(formName: any) {
    const fieldsDef: any = [
      {
        className: 'form-group-container col-xs-12 col-sm-3',
        fieldGroup: [
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: this.gettext('MAIN'),
            },
            fieldGroup: [
              {
                key: 'business_unit',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Business unit'),
                  placeholder: this.gettext('Business Unit'),
                  resource: 'core.BusinessUnit',
                },
              },
              {
                key: 'vehicle_type',
                type: 'gt-select',
                templateOptions: {
                  label: this.gettext('Vehicle type'),
                  placeholder: this.gettext('Choose vehicle type'),
                  valueProp: 'value',
                  labelProp: 'name',
                  hint: this.gettext('Choose vehicle type'),
                  options: [
                    { value: 'car', name: this.gettext('Car') },
                    { value: 'trailer', name: this.gettext('Trailer') },
                  ],
                },
              },
              {
                key: 'vehicle_subtype',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Vehicle subtype'),
                  placeholder: this.gettext('Vehicle subtype'),
                  resource: 'logistics.VehicleSubtype',
                },
              },
              {
                key: 'number',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Vehicle number'),
                  placeholder: this.gettext('Vehicle number'),
                  type: 'text',
                },
              },
              {
                key: 'technical_passport_number',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Technical passport number'),
                  placeholder: this.gettext('Technical passport number'),
                  type: 'text',
                },
              },
            ],
          },
        ],
      },
    ];
    return { fieldsDef, formName };
  }

  openVehicleModal(vehicle: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template:
        '<vehicle-modal vehicle="vehicle" modal-instance="$uibModalInstance"></vehicle-modal>',
      controller: [
        '$scope',
        'vehicle',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          vehicle: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).vehicle = vehicle;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      size: 'sm',
      windowClass: 'modal-vehicle',
      appendTo: getModalRoot(),
      resolve: {
        vehicle: () => vehicle,
      },
    }).result;
  }
}
VehiclesService.$inject = ['$resource', 'gettext', '$uibModal'];
