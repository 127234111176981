import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '~/app/deals/multicontract/multicontract.service';

import template from './contract-list-container.html?raw';
import type { ImportExportContractsService } from '../../import-export/import-export.service';

type ContractListQueryParams = QueryParams & { serializer?: string };

export const ContractListContainer = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    globalAddButton: '<?',
    quickAdd: '<?',
    quickAddInit: '<?',
    view: '<?',
    viewFilter: '<?',
    expanded: '<?',
    extraFields: '<?',
    showConnected: '<?',
    onUpdate: '&?',
    simplifiedView: '<?',
    hideButton: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$q',
    '$window',
    '$filter',
    'GtUtils',
    'gettext',
    'moment',
    'gtFilterService',
    'ContractsService',
    'DocumentsService',
    'ClientsService',
    'LogisticsService',
    'ImportExportContractsService',
    'CoreService',
    'MulticontractService',
    'ReportsService',
    class {
      $filter: ng.IFilterService;
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      ClientsService: any;
      ContractsService: ContractsService;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      ImportExportContractsService: ImportExportContractsService;
      LogisticsService: any;
      MulticontractService: MulticontractService;
      ReportsService: any;
      activeFilterPresets: any;
      contractPermissions: any;
      contractPurpose: any;
      contracts: any;
      contractsCount: number;
      contractsTotal: any;
      disabledSearch: any;
      filterLevel = 'contract-list-container';
      filterLevelNew: any;
      gettext: ng.gettext.gettextFunction;
      globalAddButton: any;
      gtFilterService: GtFilterService;
      initQueryParams: ContractListQueryParams = {};
      inlineOrderingParams: any;
      moment: any;
      newContract: any;
      onUpdate: any;
      queryParams: ContractListQueryParams;
      quickAddInit: any;
      reportConfig: any;
      resource: any;
      savedFilterChoices: any;
      savedReportConfigs: any;
      timelineChartItems: any;
      view: any;
      viewFilter: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $q: ng.IQService,
        $window: ng.IWindowService,
        $filter: ng.IFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        moment: any,
        gtFilterService: GtFilterService,
        ContractsService: ContractsService,
        DocumentsService: any,
        ClientsService: any,
        LogisticsService: any,
        ImportExportContractsService: ImportExportContractsService,
        CoreService: CoreService,
        MulticontractService: MulticontractService,
        ReportsService: any,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$window = $window;
        this.$filter = $filter;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.moment = moment;
        this.gtFilterService = gtFilterService;
        this.ContractsService = ContractsService;
        this.DocumentsService = DocumentsService;
        this.ClientsService = ClientsService;
        this.LogisticsService = LogisticsService;
        this.ImportExportContractsService = ImportExportContractsService;
        this.CoreService = CoreService;
        this.MulticontractService = MulticontractService;
        this.ReportsService = ReportsService;

        this.queryParams = {};
        this.contracts = [];
        this.timelineChartItems = [];
        this.contractsTotal = {};
        this.contractsCount = 0;
        this.activeFilterPresets = [];
        this.inlineOrderingParams = [];
        this.reportConfig = null;
      }

      $onInit() {
        this.queryParams = { serializer: 'table_info', ...this.initQueryParams };
        this.globalAddButton = this.globalAddButton || false;
        this.contractPermissions = this.getContractPermission(
          this.queryParams.contract_type,
          this.queryParams.deal_type,
        );
        if (this.$rootScope.isDeviceMobile) {
          this.view = 'block';
        } else {
          this.view = ['list', 'block'].includes(this.$rootScope.user.profile.contracts_view)
            ? this.$rootScope.user.profile.contracts_view
            : this.view || 'table';
        }

        this.viewFilter = this.viewFilter || 'show';

        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.resource = this.getResource(this.queryParams);
          this.filterLevelNew = this.getFilterLevel(this.queryParams) || this.filterLevel;
          this.queryParams = {
            ...this.initQueryParams,
            ...data,
            deal_type: this.initQueryParams.deal_type,
          };
          if (!data.start_date || !data.end_date) {
            delete this.queryParams.start_date;
            delete this.queryParams.end_date;
          }
          this.updateData();
          this.updateOrdering();
        });

        this.$rootScope.$on(
          `gt-report-config-selected_${this.filterLevel}`,
          (event: any, config: any) => {
            this.reportConfig = config ? config.column_params : null;
          },
        );

        this.$rootScope.$on(
          `gt-report-config-created_${this.filterLevel}`,
          (event: any, config: any) => {
            this.savedReportConfigs = [...this.savedReportConfigs, config];
          },
        );

        this.$rootScope.$on(
          `gt-report-config-updated_${this.filterLevel}`,
          (event: any, config: any) => {
            const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
            this.savedReportConfigs[index] = config;
          },
        );

        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
        this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
        this.contractPurpose = this.ContractsService.getContractPurpose({
          contract_type: this.queryParams.contract_type,
          deal_type: this.queryParams.deal_type,
        });
      }

      $onChanges(changes: any) {
        if (changes.initQueryParams) {
          Object.assign(this.queryParams, this.initQueryParams);
        }
      }

      applyFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateOrdering() {
        this.inlineOrderingParams = [
          { title: 'Conclusion date', value: 'conclusion_date' },
          { title: 'Start of execution', value: 'date_of_execution' },
          { title: 'End of execution', value: 'end_of_execution' },
        ];
      }

      getResource(queryParams: ContractListQueryParams) {
        if (!queryParams.serializer) {
          return;
        }
        const resourceMapper = {
          table_prepayments_accounting: 'prepayments_accounting',
        };
        return resourceMapper[queryParams.serializer] || undefined;
      }

      getContractPermission(contractType: any, dealType: any) {
        let contractPermissions;
        if (dealType === 'services') {
          contractPermissions = 'add_servicescontract';
        } else if (dealType === 'export' || dealType === 'intermediate') {
          contractPermissions = 'add_contractbase';
        } else {
          contractPermissions = `add_${contractType}contract`;
        }
        return contractPermissions;
      }

      getFilterLevel(queryParams: ContractListQueryParams) {
        if (!queryParams?.serializer) {
          return;
        }
        const filterLevelMapper = {
          table_prepayments_accounting: 'prepayments-accounting-page-view',
        };
        const newFilterLevel = filterLevelMapper[queryParams?.serializer];
        if (!newFilterLevel) {
          return;
        }
        const params = { ...this.gtFilterService.getQueryParams(this.filterLevel) };
        this.gtFilterService.setQueryParams(params, newFilterLevel);
        return newFilterLevel;
      }

      updateData() {
        this.contracts = [];
        this.contractsTotal = {};
        this.disabledSearch = true;
        this.timelineChartItems = [];
        this.GtUtils.overlay('show');
        this.newContract = this.getNewContract();
        return this.ContractsService.getContractList(this.queryParams).then((data: any) => {
          if (this.queryParams.next) {
            this.contracts = this.contracts.concat(data.results);
            delete this.queryParams.next;
          } else {
            this.contracts = data.results;
          }
          this.contracts.forEach((item: any) => this.ContractsService.setContractReadonly(item));
          this.contractsCount = data.count;
          if (this.queryParams.deal_type !== 'services') {
            this.ContractsService.getContractTotal(this.queryParams, this.view).then(
              (data: any) => {
                this.contractsTotal = data;
                this.disabledSearch = false;
              },
            );
          }
          this.disabledSearch = false;
          this.timelineChartItems = data.results
            .filter((item: any) => item.date_of_execution || item.end_of_execution)
            .map((item: any) => ({
              id: item.id.toString(),
              start: item.date_of_execution || item.end_of_execution,
              end: item.end_of_execution || item.date_of_execution,
              name: item.contract_number.toString(),

              popupHtml: `Commodity: ${item.crop_title}<br />
                        Volume: ${item.volume}<br />
                        Buyer: ${item.buyer_name}<br />
                        Supplier: ${item.supplier_name}<br />
                        <span><a href="#/contract/${item.id}?contract_type=${item.contract_type}&stage=contract"
                        target="_blank">
                        Details <i class="fa fa-arrow-right"></i></a></span> `,
            }));
          this.GtUtils.overlay('hide');
        });
      }

      updateContractsExecutionDates(data: any) {
        this.GtUtils.overlay('show');
        let chain = this.$q.when();
        data
          .map((item: any) => {
            return this.contracts
              .filter((contract: any) => contract.id === item.id)
              .reduce((acc: any, contract: any) => {
                acc[contract.id] = {
                  ...contract,
                  id: item.id,
                  date_of_execution: item.start,
                  end_of_execution: item.end,
                };
                return acc;
              }, {});
          })
          .forEach((item: any) => {
            chain = chain.then(() => this.ContractsService.updateContract(item));
          });

        chain.then(() => {
          this.GtUtils.overlay('hide');
          this.updateData();
        });
      }

      getNewContract() {
        const businessUnit = this.$rootScope.user.profile.business_units?.find(
          (bu: any) => bu.is_default,
        )?.businessunit;
        return {
          deal_type: 'spot',
          ...this.queryParams,
          ...this.quickAddInit,
          business_unit: businessUnit,
        };
      }

      openContractModal(contract: any) {
        return this.ContractsService.contractModal(contract).then(() => {
          this.updateData();
          this.onUpdate();
        });
      }

      createServiceContract(contract: any) {
        return this.ContractsService.createServiceContract(contract).then(() => {
          this.updateData();
          this.onUpdate();
        });
      }

      openContractModalQuick(contract: any) {
        return this.ContractsService.contractModalQuick(contract).then(() => {
          this.updateData();
          this.onUpdate();
        });
      }

      cloneContract(contract: any) {
        if (!confirm(this.gettext('Do you want to clone this Contract?'))) {
          return this.$q.resolve();
        }
        return this.ContractsService.cloneContract(contract).then(() => this.updateData());
      }

      openEmailModal(contract: any) {
        return this.ClientsService.sendEmailModal(
          contract.contract_purpose.charAt(0).toUpperCase() +
            contract.contract_purpose.slice(1) +
            'Contract',
          contract.id,
        );
      }

      openDocxModal(contract: any) {
        return this.DocumentsService.generateDocxModal(
          contract.contract_purpose.charAt(0).toUpperCase() +
            contract.contract_purpose.slice(1) +
            'Contract',
          contract.id,
        );
      }

      openDocumentModal(contract: any) {
        return this.DocumentsService.documentListModal({
          model_name: 'ContractBase',
          object_id: contract.id,
        }).then(() => this.updateData());
      }

      approveContract(contract: any) {
        return this.ContractsService.Contract.query(
          { id: contract.id, serializer: 'modal' },
          (data: any) => this.ContractsService.approveDeal(data).then(() => this.updateData()),
        );
      }

      addLogistic(contract: any) {
        return this.ContractsService.addLogistic(contract);
      }

      addFinance(contract: any, cond: any, invoiceStatus: any) {
        return this.ContractsService.addFinance(contract, cond, invoiceStatus);
      }

      addBalanceInvoice(contract: any) {
        return this.ContractsService.addBalanceInvoice(contract);
      }

      reverseContract(contract: any) {
        if (!confirm(this.gettext('Do you want to reverse contract?'))) {
          return;
        }
        return this.ContractsService.reverseDeal(contract);
      }

      createPassport(contract: any) {
        if (contract.total_connected && contract.volume - contract.total_connected <= 0) {
          return this.GtUtils.notify(
            this.gettext('This contracts already fully connected'),
            'error',
          );
        }

        return this.ContractsService.createPassportContract([contract]).then(() =>
          this.updateData(),
        );
      }

      connectToPassport(contract: any, passportId: any) {
        return this.ContractsService.getVolumeAvailableToConnect(contract, passportId)
          .then((data: any) => {
            return this.ContractsService.connectToPassport({
              id: passportId,
              deal_id: contract.id,
              stage: 'contract',
              contract_purpose: this.ContractsService.getContractPurpose(contract),
              volume: data,
            });
          })
          .then(() => this.updateData());
      }

      setFinalVolumeFromExecution(contractId: any) {
        if (!confirm(this.gettext('Do you want to update final volume from execution?'))) {
          return false;
        }
        return this.ContractsService.Contract.setFinalVolumeFromExecution(
          {
            id_list: [contractId],
          },
          () => {
            this.updateData();
            this.GtUtils.notify(this.gettext('Contract updated'));
          },
        );
      }

      openVoyageModal(voyage: any) {
        return this.LogisticsService.voyageModal(voyage).then(() => this.updateData());
      }

      openRoleModal(roleId: any, roleModel: any) {
        return this.ClientsService.roleModal({ id: roleId, model_name: roleModel });
      }

      openStageModal(stage: any) {
        return this.ContractsService.contractStageModal(stage).then(() => this.updateData());
      }

      openMulticontractModal(contract: any) {
        return this.MulticontractService.createMultiContractFromPosition(contract).then(() =>
          this.updateData(),
        );
      }

      openImportExportContractsModal() {
        return this.ImportExportContractsService.importExportModal(
          this.queryParams?.contract_type as string,
        ).then(() => this.updateData());
      }

      updateConnections() {
        const contractType = (this.contracts.length && this.contracts[0].contract_type) || 'sale';
        this.$rootScope.$broadcast(contractType + '-connections-updated', this.contracts);
      }

      updateStatus() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.updateStatus(this.queryParams, () => {
          this.updateData();
          this.GtUtils.notify(this.gettext('Contracts status updated'));
        });
      }

      createPnlControl() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.createPnlControl({
          ...this.queryParams,
          bulk_create: true,
        })
          .$promise.then(() => {
            return this.updateData().then(() => {
              this.GtUtils.notify(
                this.gettext('Contracts control is being created. Wait a few minutes'),
              );
            });
          })
          .catch((err) => {
            this.GtUtils.errorClb(err);
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      setVoyageData() {
        this.GtUtils.overlay('show');
        return this.ContractsService.Contract.setVoyageData({}, () => {
          this.updateData();
          this.GtUtils.notify(this.gettext('Contracts updated'));
        });
      }

      createUpdateRequest() {
        this.GtUtils.overlay('show');
        return this.ReportsService.CalculatedValueUpdateRequest.save(
          {
            view_name: 'view_materialized_contract_list',
          },
          (data: any) => {
            this.contractsTotal.last_update_request_status = 'pending';
            this.contractsTotal.last_update_request_update_time = this.moment(
              data.create_time,
            ).format('YYYY-MM-DDTHH:mm');
            this.GtUtils.notify(this.gettext('The totals have been updated'));
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this.GtUtils.errorClb(data);
          },
        );
      }
    },
  ],
};
