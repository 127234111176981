import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import { getModalRoot } from '~/shared/ui/modal';

export class CashflowService {
  $http: ng.IHttpService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  BankBalance: any;
  CashFlow: any;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  moment: any;
  reportPeriodMask: any;
  constructor(
    $http: ng.IHttpService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
    moment: any,
    GtUtils: GtUtilsService,
  ) {
    this.$http = $http;
    this.$uibModal = $uibModal;
    this.$resource = $resource;
    this.gettext = gettext;
    this.moment = moment;
    this.GtUtils = GtUtils;
    this.reportPeriodMask = undefined;

    this.CashFlow = this.$resource(
      '/api/reports/cashflow/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: {
          method: 'PATCH',
        },
      },
    );

    this.BankBalance = this.$resource(
      '/api/reports/bank-balances/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: true },
        update: {
          method: 'PATCH',
        },
      },
    );
  }

  getCashflowData(params: any) {
    switch (params.mode) {
      case 'day':
        this.reportPeriodMask = 'DD.MM.YYYY';
        break;
      case 'week':
        this.reportPeriodMask = 'DD.MM.YYYY - ';
        break;
      case 'month':
        this.reportPeriodMask = 'MMM YYYY';
        break;
      default:
        this.reportPeriodMask = 'DD.MM.YYYY';
        break;
    }

    return this.$http({
      method: 'GET',
      url: '/api/reports/cashflow-report/',
      params: params,
    }).then((response: any) => this.prepareCashFlowReportTable(response.data, params));
  }

  prepareCashFlowReportTable(data: any, params: any) {
    const preparedData = {
      reports: [],
    };
    preparedData.reports = data.results.map((report: any) => {
      const initialCashPeriod =
        report.table_values_list.filter((tableValue: any) => tableValue.cash_start != 0)[0] || {};
      const preparedReport = {
        mode: params.mode,
        start_date: params.start_date,
        end_date: params.end_date,
        currency_id: report.currency_id,
        currency_symbol: report.currency_symbol,
        business_unit_id: report.business_unit_id,
        business_unit_title: report.business_unit_title,
        initialCash: initialCashPeriod.cash_start,
        initialCashDate: this.generatePeriodMask(initialCashPeriod.report_period),
        dimensions: this.getDimensions(report.table_values_list),
        financeTypes: [
          {
            title: 'Total incoming',
            invoice_type: 'outgoing',
          },
          {
            title: 'Total outgoing',
            invoice_type: 'incoming',
          },
        ],
        periods: this.getPeriods(report.table_values_list),
        periodReports: this.getPeriodReports(report.table_values_list),
        table_values_list: report.table_values_list,
        chartConfig: {},
      };
      preparedReport.chartConfig = this.getChartConfig(preparedReport);
      return preparedReport;
    });
    return preparedData;
  }

  getDimensions(rawPeriodReports: any[]) {
    const dimensions = {
      grouping: {
        incoming: [] as any[],
        incomingTotal: {},
        outgoing: [] as any[],
        outgoingTotal: {},
        bankBalances: [] as any[],
      },
    };
    (['incoming', 'outgoing'] as const).forEach((invoiceType) => {
      dimensions.grouping[invoiceType] = [];
      rawPeriodReports.forEach((rawPeriodReport: any) => {
        const invoiceTypeGroup = rawPeriodReport.row_list.filter(
          (row: any) => row.invoice_type == invoiceType,
        )[0];
        const periodGroupings = (invoiceTypeGroup?.row_list || []).map(this.buildPeriodGroupings);
        periodGroupings.forEach((periodGrouping: any) => {
          let exists = false;
          dimensions.grouping[invoiceType].forEach((useListEntry: any) => {
            if (periodGrouping.use == useListEntry.use) {
              exists = true;
              let resultArray = useListEntry.group_key.concat(periodGrouping.group_key);
              resultArray = resultArray.filter((obj: any, pos: any, arr: any) => {
                return arr.map((mapObj: any) => mapObj.group).indexOf(obj.group) === pos;
              });
              useListEntry.group_key.splice(0, useListEntry.group_key.length);
              useListEntry.group_key.push(...resultArray);
            }
          });
          if (!exists && periodGrouping) {
            dimensions.grouping[invoiceType].push(periodGrouping);
          }
        });
      });
      if (dimensions.grouping[invoiceType].length) {
        this.attachTotal(dimensions.grouping, invoiceType);
      }
    });
    dimensions.grouping.bankBalances = rawPeriodReports
      .reduce((accumulator: any, rawPeriodReport: any) => {
        rawPeriodReport.bank_list = rawPeriodReport.bank_list[0] || [];
        accumulator.push(
          ...rawPeriodReport.bank_list.map((bank: any) => {
            return {
              account_name: bank.account_name,
              bank_name: bank.bank_name,
              bank_account_id: bank.bank_account_id,
            };
          }),
        );
        return accumulator;
      }, [])
      .filter((obj: any, pos: any, arr: any) => {
        return (
          arr.map((mapObj: any) => mapObj.bank_account_id).indexOf(obj.bank_account_id) === pos
        );
      });
    if (dimensions.grouping.bankBalances.length) {
      dimensions.grouping.bankBalances.unshift({
        account_name: this.gettext('Bank Account Total'),
        bank_account_id: 'total',
      });
    }
    return dimensions;
  }

  attachTotal(grouping: any, invoiceType: any) {
    grouping[invoiceType].unshift({
      use: 'total',
      useTitle: '',
      group_key: [
        {
          group: 'use_total',
          group_title: '',
          finance_total: true,
        },
      ],
    });
    grouping[invoiceType + 'Total'] = [
      {
        total: {
          use: 'type_total',
          useTitle: '',
          group_key: [
            {
              group: 'type_total',
              group_title: '',
            },
          ],
        },
      },
    ];
  }

  buildPeriodGroupings = (row: any) => {
    const groupKey = row.row_list.reduce((accumulator: any, subrow: any) => {
      subrow.group_title = subrow.group_title || 'N/A';
      accumulator.push({
        group_title: subrow.group_title,
        group: subrow.group_title + '_total',
        group_total: true,
      });
      accumulator.push({
        group_title: '',
        group: subrow.group_title,
        finances_list: true,
      });
      return accumulator;
    }, []);
    if (groupKey.length) {
      groupKey.unshift({
        group: 'subuse_total',
        group_title: '',
        use_total: true,
      });
    }
    return {
      use: row.use,
      useTitle: row.use,
      group_key: groupKey,
    };
  };

  generatePeriodMask(date: any) {
    let periodMask;
    if (this.reportPeriodMask == 'DD.MM.YYYY - ') {
      periodMask =
        this.moment(date).format(this.reportPeriodMask) +
        this.moment(date).add(6, 'days').format('DD.MM.YYYY');
    } else {
      periodMask = this.moment(date).format(this.reportPeriodMask);
    }
    return periodMask;
  }

  getPeriods(rawPeriodReports: any) {
    return rawPeriodReports
      .map((rawPeriodReport: any) => {
        return this.generatePeriodMask(rawPeriodReport.report_period);
      })
      .filter((value: any, index: any, obj: any) => obj.indexOf(value) === index);
  }

  getPeriodReports(rawPeriodReports: any) {
    return rawPeriodReports
      .map((rawPeriodReport: any) => {
        return {
          cashflow_ids: rawPeriodReport.cashflow_ids,
          is_custom: rawPeriodReport.is_custom.some((value: any) => value),
          currency_id: rawPeriodReport.currency_id,
          business_unit_id: rawPeriodReport.business_unit_id,
          total_value: rawPeriodReport.total_value,
          report_period: rawPeriodReport.report_period,
          cash_start: rawPeriodReport.cash_start,
          cash_end: rawPeriodReport.cash_end,
          bank_amount: rawPeriodReport.bank_amount,
          bank_records: rawPeriodReport.bank_list.reduce((accumulator: any, currentValue: any) => {
            accumulator[currentValue.bank_account_id] = currentValue;
            return accumulator;
          }, {}),
          invoiceTypesGroup: this.getInvoiceTypesGroup(rawPeriodReport.row_list),
        };
      })
      .reduce((accumulator: any, periodReport: any) => {
        accumulator[this.generatePeriodMask(periodReport.report_period)] = periodReport;
        return accumulator;
      }, {});
  }

  getInvoiceTypesGroup(rawInvoiceTypeGroups: any) {
    return rawInvoiceTypeGroups
      .map((rawInvoiceTypeGroup: any) => {
        return {
          invoice_type: rawInvoiceTypeGroup.invoice_type,
          total_value: rawInvoiceTypeGroup.total_value,
          useGroup: this.getUseGroup(rawInvoiceTypeGroup.row_list),
        };
      })
      .reduce((accumulator: any, currentValue: any) => {
        accumulator[currentValue.invoice_type] = currentValue;
        accumulator[currentValue.invoice_type + 'Total'] = currentValue;
        return accumulator;
      }, {});
  }

  getUseGroup(rawInvoiceUseGroups: any) {
    return rawInvoiceUseGroups
      .map((rawInvoiceUseGroup: any) => {
        return {
          total_value: rawInvoiceUseGroup.total_value,
          use: rawInvoiceUseGroup.use,
          customGroup: this.getCustomGroup(rawInvoiceUseGroup.row_list),
        };
      })
      .reduce((accumulator: any, currentValue: any) => {
        accumulator[currentValue.use] = currentValue;
        accumulator[currentValue.use + '_total'] = currentValue;
        return accumulator;
      }, {});
  }

  getCustomGroup(rawInvoiceCustomGroups: any) {
    return rawInvoiceCustomGroups
      .map((rawInvoiceCustomGroup: any) => {
        return {
          total_value: rawInvoiceCustomGroup.total_value,
          group_key: rawInvoiceCustomGroup.group_title,
          finances: rawInvoiceCustomGroup.row_list,
        };
      })
      .reduce((accumulator: any, currentValue: any) => {
        accumulator[currentValue.group_key] = currentValue;
        accumulator[currentValue.group_key + '_total'] = currentValue;
        return accumulator;
      }, {});
  }

  getChartConfig(report: any) {
    const chartConfig: any = {
      labels: report.periods,
      data: {
        'Cash start': [] as any[],
        'Cash end': [] as any[],
        'Day balance': [] as any[],
      },
    };

    Object.values(report.periodReports).forEach((periodReport: any) => {
      chartConfig.data['Cash start'].push(periodReport.cash_start);
      chartConfig.data['Cash end'].push(periodReport.cash_end);
      chartConfig.data['Day balance'].push(periodReport.total_value);
    });
    return chartConfig;
  }

  openCashFlowModal(params: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      component: 'cashflowModal',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        params: () => params,
      },
    }).result;
  }

  openCashflowDateModal(periodReport: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      component: 'cashflowDateModal',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        periodReport: () => periodReport,
      },
    }).result;
  }

  recreate(data: any) {
    return this.$http({
      method: 'POST',
      url: '/api/reports/cashflow/recreate/',
      data: data,
    }).then(() => {
      this.GtUtils.notify(this.gettext('Cash flow recreated'));
    });
  }
}
CashflowService.$inject = ['$http', '$uibModal', '$resource', 'gettext', 'moment', 'GtUtils'];
