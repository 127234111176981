import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './sale-plan-report-container.html?raw';

export const SalePlanReportContainer = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'SalePlanReportService',
    'gettext',
    'GtUtils',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      SalePlanReportService: any;
      data: any;
      filterLevel = 'sale-plan-report-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      totals: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        SalePlanReportService: any,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.SalePlanReportService = SalePlanReportService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;

        this.data = {};
        this.totals = {};
        this.initQueryParams = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.resetFilter();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.SalePlanReportService.getData(this.queryParams).then((data: any) => {
          this.data = data;
          this.data.results.unshift({});
          return this.SalePlanReportService.getTotalsData(this.queryParams).then((data: any) => {
            this.totals = data;
            this.GtUtils.overlay('hide');
          });
        });
      }

      resetFilter() {
        this.queryParams = { ...this.initQueryParams };
        this.applyFilter();
      }

      applyFilter() {
        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      save(item: any) {
        return this.SalePlanReportService.save(item)
          .then(() => {
            this.GtUtils.notify(this.gettext('Object saved'));
            this.applyFilter();
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      delete(item: any) {
        if (!confirm('Are you sure you want delete this Item?')) {
          return;
        }
        return this.SalePlanReportService.delete(item)
          .then(() => {
            this.GtUtils.notify(this.gettext('Item was deleted'));
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }
    },
  ],
};
