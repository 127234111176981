import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
export const CostsStatisticsPage = {
  bindings: {},
  template: `
    <costs-statistics-container filter-level="$ctrl.filterLevel"></costs-statistics-container>
  `,
  controller: [
    'PageService',
    'ReportsService',
    'gettext',
    'gtFilterService',
    class {
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        PageService: PageService,
        ReportsService: any,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;

        this.filterLevel = 'costs-statistics-page-view';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Created'),
              },
              {
                argument: 'passport_business_date',
                placeholder: this.gettext('passport business date'),
              },
            ],
            multiSelects: [
              {
                argument: 'client_list',
                placeholder: this.gettext('Cost counterparty'),
                resource: 'clients.client',
              },
              {
                argument: 'contract_client_list',
                placeholder: this.gettext('Contract counterparty'),
                resource: 'clients.client',
              },
              {
                argument: 'contract_list',
                placeholder: this.gettext('contract'),
                resource: 'contracts.ContractBase',
              },
              {
                argument: 'contract_bu_list',
                placeholder: this.gettext('contract business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'charges_list',
                placeholder: this.gettext('charge'),
                resource: 'finances.Charge',
              },
              {
                argument: 'passport_list',
                placeholder: this.gettext('passports'),
                resource: 'passports.Passport',
              },
              {
                argument: 'passport_bu_list',
                placeholder: this.gettext('passport business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'passport_custom_status_list',
                placeholder: this.gettext('passports custom status'),
                resource: 'core.CustomStatus',
                queryParams: {
                  content_type__model: 'passport',
                },
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('currency'),
                resource: 'finances.currency',
              },
              {
                argument: 'author_list',
                placeholder: this.gettext('author'),
                resource: 'auth.user',
              },
              {
                argument: 'payment_bank_account_list',
                placeholder: this.gettext('payment bank account'),
                resource: 'finances.bankaccount',
              },
              {
                argument: 'invoice_list',
                placeholder: this.gettext('invoices'),
                resource: 'finances.Finance',
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter(
                'has_analytical_amount',
                this.gettext('Has Analytical amount'),
              ),
              this.gtFilterService.getBoolFilter('has_our_amount', this.gettext('Has Our amount')),
              this.gtFilterService.getBoolFilter(
                'has_final_amount',
                this.gettext('Has Final amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_several_passports',
                this.gettext('Has several passports'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_passport_key',
                this.gettext('Passport filled in  manually'),
              ),
              this.gtFilterService.getBoolFilter(
                'final_amount_diff_invoiced',
                this.gettext('final amount <> invoiced'),
              ),
              this.gtFilterService.getBoolFilter(
                'exec_amount_diff_paid',
                this.gettext('exec forecast amount <> payment amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'exec_amount_diff_invoiced',
                this.gettext('exec forecast amount <> invoiced amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'final_amount_diff_amount',
                this.gettext('final amount <> amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'our_amount_diff_amount',
                this.gettext('our amount <> amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'analytical_amount_diff_amount',
                this.gettext('analytical amount <> amount'),
              ),
              this.gtFilterService.getBoolFilter('has_invoice', this.gettext('Has invoice')),
              this.gtFilterService.getBoolFilter(
                'has_cancelled_invoice',
                this.gettext('Has cancelled invoice'),
              ),
              this.gtFilterService.getBoolFilter('has_payment', this.gettext('Has payment')),
              this.gtFilterService.getBoolFilter('is_gain', this.gettext('Is gain')),
              this.gtFilterService.getBoolFilter(
                'has_exec_forecasted_amount',
                this.gettext('Has Exec Forecasted Amount'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_additional_info',
                this.gettext('Has comments'),
              ),
              this.gtFilterService.getBoolFilter('has_contract_key', this.gettext('Has contract')),
            ],
            nestedMultiSelects: [
              {
                argument: 'status_list',
                placeholder: this.gettext('Status'),
                items: [
                  { id: 'validated', title: 'Validated' },
                  { id: 'under_discussion', title: 'Under discussion' },
                  { id: 'pending_confirmation', title: 'Pending confirmation' },
                  { id: 'forecast', title: 'Forecast' },
                  { id: 'planned', title: 'Planned' },
                  { id: 'cancelled', title: 'Cancelled' },
                  { id: 'closed', title: 'Closed' },
                ],
              },
              this.gtFilterService.getClientRoleFilter(
                'clientrole_type_list',
                this.gettext('client role type'),
              ),
              this.gtFilterService.getPassportStatusFilter(
                'passport_status_list',
                'passport status',
              ),
              this.gtFilterService.getContractStatusFilter(
                'contract_status_list',
                this.gettext('contract status'),
              ),
            ],
          },
        };
      }
    },
  ],
};
