import React from 'react';

import { PageFiltersLine } from '~/core/page-filters';
import { PageDatasetActions, PageViewConfig } from '~/core/page-views';
import { CounterpartiesDatasetView } from '~/features/crm/counterparties/components/counterparties-dataset-view/counterparties-dataset-view';
import { notifySuccess } from '~/shared/lib/notify';
import { useObservableEagerState, useObservableState } from '~/shared/lib/state';
import { PageLayout } from '~/shared/ui/components/page/components';
import { Pagination } from '~/shared/ui/components/pagination';
import { DocumentIcon, ThreeDotsIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';

import { useCounterpartiesListStore } from '../../hooks/use-counterparties-list-store';

export const CounterpartiesListPage: React.FC = () => {
  const pageStore = useCounterpartiesListStore();

  const pageParams = useObservableEagerState(pageStore.pageParams$);
  const pageData = useObservableEagerState(pageStore.pageData$);
  const properties = useObservableEagerState(pageStore.properties$);
  const loading = useObservableEagerState(pageStore.loading$);

  const editing = useObservableEagerState(pageStore.editing$);
  const editedRecords = useObservableEagerState(pageStore.editedRecords$);
  const updatedData = useObservableEagerState(pageStore.updatedData$);
  const updatedDataSaved = useObservableState(pageStore.updatedDataSaved$, false);

  // wtf. temporary
  React.useEffect(() => {
    if (updatedDataSaved) {
      notifySuccess('Counterparties updated');
    }
  }, [updatedDataSaved]);

  return (
    <PageLayout
      leftContent={
        <div className="flex-items flex gap-2.5">
          <div className="flex items-center justify-between gap-2">
            <DocumentIcon size={16} className="text-stroke-main-inverted" />
            <h4>Counterparties</h4>
          </div>
          <PageViewConfig viewsStore={pageStore.viewsStore} />
        </div>
      }
      rightContent={
        <div className="flex items-center justify-between gap-2">
          <Button variant="tertiary">
            <ThreeDotsIcon size={14} className="text-stroke-main-stronger" />
          </Button>
          <PageDatasetActions
            editing={editing}
            editingChanged={pageStore.editingChanged}
            onSave={pageStore.saveRequested}
          />
        </div>
      }
    >
      <section>
        <PageFiltersLine
          filtersStore={pageStore.filtersStore}
          pageParams={pageParams}
          onParamsChanged={pageStore.pageParamsChanged}
        />
        <CounterpartiesDatasetView
          records={pageData.records}
          properties={properties}
          editedRecords={editedRecords}
          changes={updatedData}
          loading={loading}
          editing={editing}
          viewStore={pageStore.viewsStore}
          onDelete={(id: string) => pageStore.deleteRecord(id)}
          onEdit={pageStore.counterpartiesUpdated}
          onSave={pageStore.saveRequested}
        />
        <Pagination
          pageCurrent={pageParams.page}
          pageSize={pageParams.pageSize}
          recordsTotal={pageData.count}
          currentPageChanged={(page) => pageStore.pageParamsChanged({ page: page })}
          pageSizeChanged={(pageSize) => pageStore.pageParamsChanged({ pageSize })}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </section>
    </PageLayout>
  );
};
