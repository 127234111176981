import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';

export class DBLService {
  $http: ng.IHttpService;
  $uibModal: ng.ui.bootstrap.IModalService;
  constructor($http: ng.IHttpService, $uibModal: ng.ui.bootstrap.IModalService) {
    this.$http = $http;
    this.$uibModal = $uibModal;
  }

  dblLogisticModal(dbl: any, extraData: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <dbl-logistic-modal dbl="dbl" extra-data="extraData" modal-instance="$uibModalInstance">
        </dbl-logistic-modal>`,
      controller: [
        '$scope',
        'dbl',
        'extraData',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          dbl: any,
          extraData: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).dbl = dbl;
          ($scope as any).extraData = extraData;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      size: 'sm',
      appendTo: getModalRoot(),
      resolve: {
        dbl: () => dbl,
        extraData: () => extraData,
      },
    }).result;
  }
}
DBLService.$inject = ['$http', '$uibModal'];
