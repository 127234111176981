import ng from 'angular';

import type { GtFilterService } from '../gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtTheadTh', directive);

  function directive() {
    return {
      template: require('./gt-thead-th.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        config: '=',
        noOrdering: '=?',
        update: '=?',
      },
    };
  }

  Controller.$inject = ['$scope', '$log', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $log: ng.ILogService,
    gtFilterService: GtFilterService,
  ) {
    const PARAM = 'ordering';
    const vm = this;

    vm.icon = 'fa-sort';
    vm.order = order;
    vm.ordering = !vm.noOrdering || false;
    vm.queryParams = {};

    activate();

    ////////////////

    function activate() {
      $log.warn('gtTheadTh is deprecated. Use `gt-thead-row` instead.');
      $scope.$on('gt-filter-updated', updateData);
      updateData();
    }

    function updateData() {
      vm.icon = getIcon(getParams()[PARAM]);
    }

    function getIcon(newPredicate: any) {
      const pos = _substringPosition(newPredicate);
      if (pos == -1) {
        return 'fa-sort';
      }
      if (pos === 0) {
        return 'fa-sort-amount-asc';
      }
      return 'fa-sort-amount-desc';
    }

    function _substringPosition(newPredicate: any) {
      if (!newPredicate) {
        return -1;
      }
      return newPredicate.indexOf(vm.config.predicate);
    }

    function order() {
      if (!vm.config.predicate || !vm.ordering) {
        return;
      }
      const predicate = getParams()[PARAM];
      const pos = _substringPosition(predicate);
      const newParams = {};
      newParams[PARAM] = vm.config.predicate;

      if (pos === 0) {
        newParams[PARAM] = '-' + vm.config.predicate;
      } else if (pos > 0) {
        newParams[PARAM] = null;
      }
      setParams(newParams);
    }

    function getParams() {
      if (vm.update) {
        return vm.queryParams;
      }
      return gtFilterService.getQueryParams('default-value');
    }

    function setParams(params: any) {
      if (vm.update) {
        vm.queryParams = params;
        vm.update(params);
        updateData();
      } else {
        gtFilterService.updateQueryParams(params, 'default-value');
      }
    }
  }
})();
