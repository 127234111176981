import React from 'react';

import { cn } from '~/shared/lib/utils';
import { ArrowBottomIcon, CheckIcon } from '~/shared/ui/icons';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

import type { FilterOption, FilterValue } from '../../types';

export const OperatorSelect: React.FC<{
  filterOption: FilterOption;
  filterValue: FilterValue;
  onChange: (filter: FilterValue) => void;
}> = ({ filterOption, filterValue, onChange }) => {
  return (
    <div className="flex items-center gap-1 px-2 py-1">
      <p className="text-xs font-medium text-text-main-primary">{filterOption.title}</p>
      <Popover>
        <PopoverTrigger className="flex items-center gap-0.5 text-xs text-text-main-tertiary outline-none">
          <p>{filterValue?.operator ? filterValue.operator : filterValue?.values[0]}</p>
          <ArrowBottomIcon size={14} className="pt-0.5 text-stroke-main-stronger" />
        </PopoverTrigger>
        <PopoverContent className="flex w-[200px] flex-col gap-0.5 p-1">
          {filterOption?.operatorOptions.map((operator) => (
            <button
              key={operator}
              className={cn(
                'flex w-full cursor-pointer items-center justify-between rounded-sm px-2 py-1 transition duration-300 ease-in-out hover:bg-transparent-light',
                operator === filterValue?.operator && 'bg-transparent-light',
              )}
              onClick={() => onChange({ ...filterValue, operator })}
            >
              <p className="text-xs text-text-main-primary">{operator}</p>
              {operator === filterValue?.operator && (
                <CheckIcon size={14} className="text-text-main-secondary" />
              )}
            </button>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};
