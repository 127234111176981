import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './cashflow-date-modal.html?raw';

export const CashflowDateModal = {
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  template,
  controller: [
    '$q',
    '$uibModalInstance',
    'gettext',
    'GtUtils',
    'moment',
    'CashflowService',
    'FinancesService',
    class {
      $q: ng.IQService;
      $uibModalInstance: ng.ui.bootstrap.IModalInstanceService;
      CashflowService: any;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      bankBalances: any;
      errors: any;
      gettext: ng.gettext.gettextFunction;
      inProgress: any;
      moment: any;
      periodReport: any;
      resolve: any;
      constructor(
        $q: ng.IQService,
        $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        moment: any,
        CashflowService: any,
        FinancesService: FinancesService,
      ) {
        this.$q = $q;
        this.$uibModalInstance = $uibModalInstance;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.moment = moment;
        this.CashflowService = CashflowService;
        this.FinancesService = FinancesService;
      }

      $onInit() {
        this.periodReport = this.resolve.periodReport;
        this.updateData();
      }

      updateData() {
        this.updateBankBalances();
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.$uibModalInstance.close(data || 'cancel');
      }

      save() {
        this.inProgress = true;
        this.errors = {};
        return this.saveCashFlow()
          .then(() => this.saveBankBalances())
          .then(
            (success: any) => this.saveSuccess(success),
            (error: any) => this.saveError(error),
          );
      }

      saveCashFlow() {
        const toSave = {
          id: this.periodReport.cashflow_ids[0],
          cash_start: this.periodReport.cash_start || 0,
          is_custom: true,
        };
        return this.CashflowService.CashFlow.update(toSave).$promise;
      }

      saveSuccess(data: any) {
        this.inProgress = false;
        this.close(data, true);
      }

      saveError(data: any) {
        this.inProgress = false;
        this.errors = data.data;
        this.GtUtils.errorClb(data);
      }

      updateBankBalances() {
        this.bankBalances = [];
        return this.FinancesService.BankAccount.query({
          currency: this.periodReport.currency_id,
          business_unit: this.periodReport.business_unit_id,
        }).$promise.then((accountsData: any) => {
          return this.CashflowService.BankBalance.query({
            report_date: this.moment(this.periodReport.report_period).format('DD.MM.YYYY'),
          }).$promise.then((balancesData: any) => {
            accountsData.results.forEach((bankAccount: any) => {
              let bankBalance = balancesData
                .filter(function (el: any) {
                  return el.bank_account == bankAccount.id;
                })
                .shift();
              if (!bankBalance) {
                bankBalance = {
                  amount: 0,
                  report_date: this.periodReport.report_period,
                  bank_account: bankAccount.id,
                  bank_name: bankAccount.bank_name,
                };
              }
              this.bankBalances.push(bankBalance);
            });
          });
        });
      }

      saveBankBalances() {
        let chain = this.$q.when();
        this.bankBalances
          .filter((item: any) => item.id || item.amount)
          .forEach((item: any) => {
            const saveFunc = item.id
              ? this.CashflowService.BankBalance.update
              : this.CashflowService.BankBalance.save;
            chain = chain.then(() => saveFunc(item).$promise);
          });

        return chain;
      }
    },
  ],
};
