import ng from 'angular';

(function () {
  'use strict';
  ng.module('execution.legacy').component('freightsTable', {
    bindings: {
      filterLevel: '<',
      freights: '<',
      count: '<',
      onUpdate: '&',
    },
    template: require('./freights-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext'];

  function Controller(this: any, $scope: ng.IScope, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.theadConfig = getTheadConfig();
    vm.queryParams = {};

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('Commodity'),
          columnName: 'commodity',
        },
        {
          title: gettext('Loading port'),
          columnName: 'loading_port',
          predicate: 'port__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'port',
              label: gettext('loading port'),
              resource: 'logistics.Port',
            },
          ],
        },
        {
          title: gettext('Destination port'),
          columnName: 'destination_port',
          predicate: 'departure_port__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'departure_port',
              label: gettext('destination port'),
              resource: 'logistics.Port',
            },
          ],
        },
        {
          title: gettext('Forwarder'),
          columnName: 'forwarder',
          predicate: 'deliverer',
          filters: [
            {
              type: 'ui-select',
              predicate: 'deliverer',
              label: gettext('forwarder'),
              resource: 'clients.Deliverer',
            },
          ],
        },
        {
          title: gettext('Tariff 20'),
          columnName: 'tarrif_20',
          predicate: 'price_20',
          filters: [
            {
              type: 'text',
              predicate: 'min_price_20',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_price_20',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Tariff 40'),
          columnName: 'tarrif_40',
          predicate: 'price_40',
          filters: [
            {
              type: 'text',
              predicate: 'min_price_40',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_price_40',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Charge'),
          columnName: 'charge',
        },
        {
          title: gettext('Additional info'),
          columnName: 'additional_info',
        },
        {
          title: gettext('Business unit'),
          columnName: 'business_unit',
          predicate: 'business_unit',
          filters: [
            {
              type: 'ui-select',
              predicate: 'business_unit',
              label: gettext('business unit'),
              resource: 'core.BusinessUnit',
            },
          ],
        },
        {
          title: gettext('Last update'),
          columnName: 'update_time',
          predicate: 'update_time',
          class: 'update_time',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
        },
        {
          title: gettext('Author'),
          columnName: 'author',
          predicate: 'author',
          filters: [
            {
              type: 'ui-select',
              predicate: 'author',
              label: gettext('autor'),
              resource: 'auth.User',
            },
          ],
        },
        {
          title: gettext(''),
          columnName: 'edit',
        },
      ];

      return config;
    }
  }
})();
