import type ng from 'angular';

export class MarketPricesService {
  MarketPrice: any;
  _$filter: ng.IFilterService;
  _$http: ng.IHttpService;
  _$resource: ng.resource.IResourceService;
  _moment: any;
  constructor(
    $http: ng.IHttpService,
    $resource: ng.resource.IResourceService,
    $filter: ng.IFilterService,
    moment: any,
  ) {
    this._$http = $http;
    this._$resource = $resource;
    this._$filter = $filter;
    this._moment = moment;

    this.MarketPrice = $resource(
      '/api/contracts/prices-position-indicators/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        bulkUpdate: {
          method: 'POST',
          url: '/api/contracts/prices-position-indicators/bulk_update/',
        },
      },
    );
  }

  query(params: any) {
    return this.MarketPrice.query(params).$promise;
  }

  create(marketPrice: any) {
    return this.MarketPrice.save(marketPrice).$promise;
  }

  update(marketPrice: any) {
    return this.MarketPrice.update(marketPrice).$promise;
  }

  delete(marketPrice: any) {
    return this.MarketPrice.delete({ id: marketPrice.id }).$promise;
  }

  getConsolidatedTable(params: any) {
    return this._$http({
      method: 'GET',
      url: '/api/contracts/consolidated-market-prices/',
      params: params,
    }).then((response: any) => {
      const result: any = {
        cargoList: [],
        data: response.data,
      };

      response.data.forEach((rowItem: any) => {
        rowItem.cargo_map = {};
        rowItem.cargo_data.forEach((cargoItem: any) => {
          if (!result.cargoList.filter((i: any) => i.id == cargoItem.cargo_id).length) {
            result.cargoList.push({
              id: cargoItem.cargo_id,
              title: cargoItem.cargo_title,
            });
          }
          rowItem.cargo_map[cargoItem.cargo_id] = cargoItem;
        });
      });
      result.cargoList = this._$filter('orderBy')(result.cargoList, 'id', true);
      return result;
    });
  }
}
MarketPricesService.$inject = ['$http', '$resource', '$filter', 'moment'];
