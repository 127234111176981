import ng from 'angular';

import { comments } from './components/comments/comments.module';
import { ConfirmDeletionModal } from './components/confirm-deletion-modal/confirm-deletion-modal.component';
import { controls } from './components/controls/controls.module';
import { CoreInlineFilterView } from './components/core-inline-filter-view/core-inline-filter-view.component';
import { CoreInlineOrdering } from './components/core-inline-ordering/core-inline-ordering.component';
import { CoreInlineReportConfigView } from './components/core-inline-report-config-view/core-inline-report-config-view.component';
import { CropQualityFilter } from './components/crop-quality-filter/crop-quality-filter.component';
import { DeletedObjectsView } from './components/deleted-objects-view/deleted-objects-view.component';
import { FilterConfigModal } from './components/filter-config-modal/filter-config-modal.component';
import { FormFieldParamsModal } from './components/form-field-params/form-field-params-modal/form-field-params-modal.component';
import { FormFieldParamsService } from './components/form-field-params/form-field-params.service';
import { GoToObject } from './components/go-to-object/go-to-object.component';
import { GtDateSelect } from './components/gt-date-select/gt-date-select.component';
import { GtLogo } from './components/gt-logo/gt-logo.component';
import { GtSidebarList } from './components/gt-sidebar-list/gt-sidebar-list.component';
import { gtTable } from './components/gt-table/gt-table.module';
import { gtTableNew } from './components/gt-table-new/gt-table.module';
import { GtTimelineChart } from './components/gt-timeline-chart/gt-timeline-chart.component';
import { navbars } from './components/navbars/navbars.module';
import { SettingsParsingPage } from './components/settings-parsing-page/settings-parsing-page.component';
import { startPageComponent } from './components/start-page/start-page.component';
import { AuthHttpInterceptor } from './core.interceptor';
import { CoreService } from './core.service';
import { CoreUtils } from './core.utils';
import { AdjustHeight } from './directives/adjust-height.dierctive';
import { AutoScrollOnHover } from './directives/auto-scroll-on-hover.directive.';
import { GtDraggableArea } from './directives/gt-draggable-area.directive';
import { GtDraggableElement } from './directives/gt-draggable-element.directive';
import { gtAbsFilter } from './filters/gt-abs/gt-abs.filter';
import { gtDistinctFilter } from './filters/gt-distinct/gt-distinct.filter';
import { coreLegacyModule } from './legacy';
import { CoordinatesService } from './services/coordinates.service';
import { GtSidebarListService } from './services/gt-sidebar-list.service';
import { ModelFieldsService } from './services/model-fields.service';
import { ResourcesService } from './services/resources.service';
import { UtilsService } from './services/utils.service';

export const coreModule = ng
  .module('core', [coreLegacyModule, comments, controls, gtTable, gtTableNew, navbars])
  .component('startPage', startPageComponent)
  .component('formFieldParamsModal', FormFieldParamsModal)
  .component('filterConfigModal', FilterConfigModal)
  .component('gtDateSelect', GtDateSelect)
  .component('cropQualityFilter', CropQualityFilter)
  .component('confirmDeletionModal', ConfirmDeletionModal)
  .component('settingsParsingPage', SettingsParsingPage)
  .component('deletedObjectsView', DeletedObjectsView)
  .component('coreInlineFilterView', CoreInlineFilterView)
  .component('coreInlineReportConfigView', CoreInlineReportConfigView)
  .component('coreInlineOrdering', CoreInlineOrdering)
  .component('gtSidebarList', GtSidebarList)
  .component('gtTimelineChart', GtTimelineChart)
  .component('goToObject', GoToObject)
  .component('gtLogo', GtLogo)
  .directive('gtDraggableElement', GtDraggableElement)
  .directive('gtDraggableArea', GtDraggableArea)
  .directive('adjustHeight', AdjustHeight)
  .directive('autoScrollOnHover', AutoScrollOnHover)
  .service('CoordinatesService', CoordinatesService)
  .service('ResourcesService', ResourcesService)
  .service('ModelFieldsService', ModelFieldsService)
  .service('UtilsService', UtilsService)
  .service('FormFieldParamsService', FormFieldParamsService)
  .service('CoreService', CoreService)
  .service('AuthHttpInterceptor', AuthHttpInterceptor)
  .service('CoreUtils', CoreUtils)
  .service('GtSidebarListService', GtSidebarListService)
  .filter('gtDistinct', gtDistinctFilter)
  .filter('gtAbs', gtAbsFilter).name;
