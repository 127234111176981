import ng from 'angular';

(function () {
  'use strict';
  ng.module('execution.legacy').component('vesselsTableList', {
    bindings: {
      vessels: '<',
      count: '<',
      filterLevel: '<?',
      onChange: '&',
    },
    template: require('./vessels-table-list.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'HelpButtonsService', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    HelpButtonsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.theadConfig = getTheadConfig();
    vm.expanded = false;

    vm.block = 'info';
    vm.toggBlock = toggBlock;
    vm.updateData = updateData;
    vm.queryParams = {};
    vm.search = search;
    vm.setHovering = setHovering;
    vm.hovering = false;

    ////////////////

    vm.$onInit = function () {
      $scope.$on('hb-config-updated', function () {
        vm.expanded = HelpButtonsService.getTableConfig().expanded;
      });
    };

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function search() {
      return updateData(vm.queryParams);
    }

    function toggBlock(block: any) {
      vm.block = block;
      vm.theadConfig = getTheadConfig();
      updateData({ serializer: 'table_' + block });
    }

    function updateData(params: any) {
      $scope.$emit('vessels-update', params);
    }

    function getTheadConfig() {
      const config: any = [
        {
          title: gettext('mv'),
          predicate: 'name',
        },
        {
          title: gettext('status'),
        },
        {
          title: gettext('imo'),
          predicate: 'imo',
        },
        {
          title: gettext('year'),
          predicate: 'age',
        },
        {
          title: gettext('owner'),
        },
        {
          title: gettext('managing company'),
        },
        {
          title: gettext('flag'),
          predicate: 'flag',
        },
      ];

      if (vm.block == 'voyage') {
        config.push(
          {
            title: gettext('purchase'),
          },
          {
            title: gettext('sale'),
          },
        );
      }

      config.push({
        title: gettext('capacity'),
        predicate: 'capacity',
      });

      if (vm.block == 'voyage') {
        config.push(
          {
            title: gettext('real capacity'),
          },
          {
            title: gettext('stowage factor'),
          },
          {
            title: gettext('volume_freighted'),
            predicate: 'volume_freighted',
          },
        );
      }

      if (vm.block == 'loading') {
        config.push(
          {
            title: gettext('loading window'),
            predicate: 'start_date_load',
          },
          {
            title: gettext('days to load'),
          },
          {
            title: gettext('POL'),
            predicate: 'port_loading_name',
          },
          {
            title: gettext('ETA'),
            predicate: 'eta',
          },
          {
            title: gettext('ETB'),
            predicate: 'etb',
          },
          {
            title: gettext('ETC'),
            predicate: 'etc',
          },
          {
            title: gettext('ETS'),
            predicate: 'ets',
          },
          {
            title: gettext('volume loaded'),
            predicate: 'volume_loaded',
          },
          {
            title: gettext('df quantity'),
          },
        );
      }

      if (vm.block == 'destination') {
        config.push(
          {
            title: gettext('discharge window'),
            predicate: 'start_date_disch',
          },
          {
            title: gettext('days to load'),
          },
          {
            title: gettext('POD'),
            predicate: 'port_destination_name',
          },
          {
            title: gettext('ETA'),
            predicate: 'eta',
          },
          {
            title: gettext('ETB'),
            predicate: 'etb',
          },
          {
            title: gettext('ETC'),
            predicate: 'etc',
          },
          {
            title: gettext('ETS'),
            predicate: 'ets',
          },
          {
            title: gettext('volume discharged'),
            predicate: 'volume_discharged',
          },
          {
            title: gettext('shortage quantity'),
          },
        );
      }

      if (vm.block == 'rates') {
        config.push(
          {
            title: gettext('load rate'),
            predicate: 'load_rate',
          },
          {
            title: gettext('disch rate'),
            predicate: 'disch_rate',
          },
          {
            title: gettext('shortage rate'),
            predicate: 'shortage_rate',
          },
          {
            title: gettext('demurrage rate'),
            predicate: 'demurrage_rate',
          },
          {
            title: gettext('dispatch rate'),
            predicate: 'dispatch_rate',
          },
        );
      }

      if (vm.block == 'costs') {
        config.push(
          {
            title: gettext('freight costs'),
            predicate: 'freight_costs',
          },
          {
            title: gettext('broker freight costs'),
            predicate: 'broker_freight_costs',
          },
          {
            title: gettext('df costs'),
          },
          {
            title: gettext('shortage fee'),
          },
          {
            title: gettext('loading dem/dis'),
          },
          {
            title: gettext('discharge dem/dis'),
          },
          {
            title: gettext('additional'),
          },
        );
      }

      config.push(
        {
          title: gettext('notes'),
        },
        {
          title: gettext('marine traffic'),
        },
        {
          title: gettext('docs'),
        },
        {
          title: gettext('last update'),
          predicate: 'update_time',
        },
        {
          title: gettext('editor'),
        },
      );

      return config;
    }
  }
})();
