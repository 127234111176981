import React from 'react';

import { NumberWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const NumberCellWrite = <T,>({ value, valueChanged }: CellTypeProps<T>) => {
  return (
    <NumberWrite
      value={Number(value)}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  );
};
