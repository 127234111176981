import ng from 'angular';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('personInfo', {
    bindings: {
      brokerContacts: '<',
    },
    template: require('./person-info.tpl.html?raw'),
    controllerAs: 'vm',
  });
})();
