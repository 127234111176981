import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './multipassport-list-page.html?raw';

export const MultipassportListPage = {
  bindings: {
    initQueryParams: '<?',
  },
  template,
  controller: [
    'gettext',
    'ContractsService',
    'PageService',
    'gtFilterService',
    class {
      ContractsService: ContractsService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        gettext: ng.gettext.gettextFunction,
        ContractsService: ContractsService,
        PageService: PageService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.ContractsService = ContractsService;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.filterLevel = 'multipassport-list-page';
        this.queryParams = { ...this.initQueryParams };
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-prices-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ContractsService.getPassportPagesConfig(),
        };

        // @ts-ignore
        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          search: false,
          dates: false,
          multiSelects: [
            {
              argument: 'id_list',
              placeholder: this.gettext('multipassport'),
              resource: 'passports.multipassport',
            },
            {
              argument: 'passport_list',
              placeholder: this.gettext('passport'),
              resource: 'passports.passport',
            },
            {
              argument: 'sale_contract_list',
              placeholder: this.gettext('sale contract'),
              resource: 'contracts.salecontract',
            },
            {
              argument: 'purchase_contract_list',
              placeholder: this.gettext('purchase contract'),
              resource: 'contracts.purchasecontract',
            },
            {
              argument: 'sale_contract_cargo_list',
              placeholder: this.gettext('sale commodity'),
              resource: 'crops.crop',
            },
            {
              argument: 'passport_cargo_list',
              placeholder: this.gettext('passport commodity'),
              resource: 'crops.crop',
            },
            {
              argument: 'sale_contract_buyer_list',
              placeholder: this.gettext('sale buyer'),
              resource: 'clients.buyer',
            },
            {
              argument: 'purchase_contract_supplier_list',
              placeholder: this.gettext('purchase supplier'),
              resource: 'clients.supplier',
            },
            {
              argument: 'sale_contract_owner_list',
              placeholder: this.gettext('sale owner'),
              resource: 'clients.owner',
            },
            {
              argument: 'passport_bu_list',
              placeholder: this.gettext('passport business unit'),
              resource: 'core.businessunit',
            },
            {
              argument: 'passport_season_list',
              placeholder: this.gettext('passport season'),
              resource: 'core.season',
            },
            {
              argument: 'passport_voyage_vessel_list',
              placeholder: this.gettext('passport vessel'),
              resource: 'logistics.vessel',
            },
          ],
          nestedSelects: [
            this.gtFilterService.getBoolFilter(
              'has_debit_credit_cargo',
              this.gettext('Нas Debit/Credit Cargo'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_debit_credit_contract',
              this.gettext('Нas Debit/Credit Contract'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_debit_credit_invoice',
              this.gettext('Нas Debit/Credit Invoice'),
            ),
          ],
          nestedMultiSelects: [
            {
              argument: 'passport_status_list',
              placeholder: this.gettext('Passport status'),
              items: [
                { id: 'Request', title: this.gettext('Request') },
                { id: 'Approved', title: this.gettext('Approved') },
                { id: 'Processing', title: this.gettext('Processing') },
                { id: 'cargo_partial_executed', title: this.gettext('Cargo partial executed') },
                { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                { id: 'Executed', title: this.gettext('Executed') },
                { id: 'Cancelled', title: this.gettext('Cancelled') },
              ],
            },
          ],
        };
        return config;
      }
    },
  ],
};
