import { BehaviorSubject } from 'rxjs';

import { singleton } from '~/shared/lib/di';

@singleton()
export class MenuStore {
  private openMenuIdsSubj = new BehaviorSubject<string[]>([]);
  private openMenuItemIdsSubj = new BehaviorSubject<string[]>([]);
  private openNestedMenuIdsSubj = new BehaviorSubject<string[]>([]);
  private isSidebarOpenSubj = new BehaviorSubject<boolean>(true);

  readonly openMenuIds$ = this.openMenuIdsSubj.asObservable();
  readonly openMenuItemIds$ = this.openMenuItemIdsSubj.asObservable();
  readonly openNestedMenuIds$ = this.openNestedMenuIdsSubj.asObservable();
  readonly isSidebarOpen$ = this.isSidebarOpenSubj.asObservable();

  public openMenuIdsChanged(ids: string[]) {
    this.openMenuIdsSubj.next(ids);
  }

  public openMenuItemIdsChanged(ids: string[]) {
    this.openMenuItemIdsSubj.next(ids);
  }

  public openNestedMenuIdsChanged(ids: string[]) {
    this.openNestedMenuIdsSubj.next(ids);
  }

  public sidebarToggled(value: boolean) {
    this.isSidebarOpenSubj.next(value);
  }
}
