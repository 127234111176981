import { createIconComponent } from '../lib';

const iconPath = `<path d="M5.33337 3.33329C5.33337 3.5101 5.40361 3.67967 5.52864 3.8047C5.65366 3.92972 5.82323 3.99996 6.00004 3.99996C6.17685 3.99996 6.34642 3.92972 6.47145 3.8047C6.59647 3.67967 6.66671 3.5101 6.66671 3.33329C6.66671 3.15648 6.59647 2.98691 6.47145 2.86189C6.34642 2.73686 6.17685 2.66663 6.00004 2.66663C5.82323 2.66663 5.65366 2.73686 5.52864 2.86189C5.40361 2.98691 5.33337 3.15648 5.33337 3.33329Z" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.33337 7.99996C5.33337 8.17677 5.40361 8.34634 5.52864 8.47136C5.65366 8.59639 5.82323 8.66663 6.00004 8.66663C6.17685 8.66663 6.34642 8.59639 6.47145 8.47136C6.59647 8.34634 6.66671 8.17677 6.66671 7.99996C6.66671 7.82315 6.59647 7.65358 6.47145 7.52855C6.34642 7.40353 6.17685 7.33329 6.00004 7.33329C5.82323 7.33329 5.65366 7.40353 5.52864 7.52855C5.40361 7.65358 5.33337 7.82315 5.33337 7.99996Z" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.33337 12.6666C5.33337 12.8434 5.40361 13.013 5.52864 13.138C5.65366 13.2631 5.82323 13.3333 6.00004 13.3333C6.17685 13.3333 6.34642 13.2631 6.47145 13.138C6.59647 13.013 6.66671 12.8434 6.66671 12.6666C6.66671 12.4898 6.59647 12.3202 6.47145 12.1952C6.34642 12.0702 6.17685 12 6.00004 12C5.82323 12 5.65366 12.0702 5.52864 12.1952C5.40361 12.3202 5.33337 12.4898 5.33337 12.6666Z" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.33337 3.33329C9.33337 3.5101 9.40361 3.67967 9.52864 3.8047C9.65366 3.92972 9.82323 3.99996 10 3.99996C10.1769 3.99996 10.3464 3.92972 10.4714 3.8047C10.5965 3.67967 10.6667 3.5101 10.6667 3.33329C10.6667 3.15648 10.5965 2.98691 10.4714 2.86189C10.3464 2.73686 10.1769 2.66663 10 2.66663C9.82323 2.66663 9.65366 2.73686 9.52864 2.86189C9.40361 2.98691 9.33337 3.15648 9.33337 3.33329Z" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.33337 7.99996C9.33337 8.17677 9.40361 8.34634 9.52864 8.47136C9.65366 8.59639 9.82323 8.66663 10 8.66663C10.1769 8.66663 10.3464 8.59639 10.4714 8.47136C10.5965 8.34634 10.6667 8.17677 10.6667 7.99996C10.6667 7.82315 10.5965 7.65358 10.4714 7.52855C10.3464 7.40353 10.1769 7.33329 10 7.33329C9.82323 7.33329 9.65366 7.40353 9.52864 7.52855C9.40361 7.65358 9.33337 7.82315 9.33337 7.99996Z" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.33337 12.6666C9.33337 12.8434 9.40361 13.013 9.52864 13.138C9.65366 13.2631 9.82323 13.3333 10 13.3333C10.1769 13.3333 10.3464 13.2631 10.4714 13.138C10.5965 13.013 10.6667 12.8434 10.6667 12.6666C10.6667 12.4898 10.5965 12.3202 10.4714 12.1952C10.3464 12.0702 10.1769 12 10 12C9.82323 12 9.65366 12.0702 9.52864 12.1952C9.40361 12.3202 9.33337 12.4898 9.33337 12.6666Z" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
`;
export const DragIcon = createIconComponent(iconPath, 'DragIcon', '0 0 16 16');
