import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('signingsContractsFilter', {
    bindings: {
      filterLevel: '<',
    },
    template: require('./signings-contracts-filter.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gtFilterService'];

  function Controller(this: any, $scope: ng.IScope, gtFilterService: GtFilterService) {
    const vm = this;
    vm.years = ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'];
    vm.queryParams = gtFilterService.getQueryParams(vm.filterLevel);
    vm.applyFilters = applyFilters;

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function applyFilters(reset: any) {
      if (reset) {
        vm.queryParams.basis = undefined;
        vm.queryParams.crop_year = undefined;
        vm.queryParams.responsible_for_signing = undefined;
      }

      return gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }
  }
})();
