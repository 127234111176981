import ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('abstract-passports', {
        parent: 'gt.page',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('abstract-passports.passports', {
        url: '/passports',
        component: 'passportsPageView',
        resolve: {
          filterLevel: () => {
            return 'passports-page-view';
          },
          queryParams: [
            'PageService',
            'ContractsService',
            (PageService, ContractsService) => ({
              ...ContractsService.getDefaultsParams(),
              date_predicate: 'business_date',
              ...PageService.syncUrlFilter('passports-page-view'),
            }),
          ],
        },
        data: {
          pageTitle: gettext('Passports'),
          permissions: {
            only: 'view_passport',
          },
        },
      })
      .state('gt.old-page.passports.add', {
        url: '/add',
        data: {
          permissions: {
            only: 'add_passport',
          },
        },
        onEnter: [
          '$uibModal',
          '$state',
          function ($uibModal: ng.ui.bootstrap.IModalService, $state: ng.ui.IStateService) {
            return $uibModal
              .open({
                backdrop: 'static',
                template: require('./passport-modal/passport-modal.tpl.html?raw'),
                controller: 'PassportsPassportModalController as vm',
                controllerAs: 'vm',
                windowClass: 'modal-template',
                appendTo: getModalRoot(),
                resolve: {
                  passport: () => {
                    return {};
                  },
                },
              })
              .result.finally(function () {
                $state.go('^');
              });
          },
        ],
      });
  }
})();
