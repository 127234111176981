import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
export class WarehouseMovementsService {
  $resource: ng.resource.IResourceService;
  CoreService: CoreService;
  GtUtils: GtUtilsService;
  contentTypes: any;
  hrefMap: any;
  srefMap: any;
  warehouseMovementsResource: any;
  warehousesResource: any;
  constructor(
    $resource: ng.resource.IResourceService,
    CoreService: CoreService,
    GtUtils: GtUtilsService,
  ) {
    this.$resource = $resource;
    this.CoreService = CoreService;
    this.GtUtils = GtUtils;

    this.contentTypes = {};
    this.CoreService.initContentTypes().then(
      () => (this.contentTypes = this.CoreService.contentTypeObjects),
    );
    this.srefMap = {
      contract_position: (item: any) => `gt.page.contract({ id: ${item.pk}})`,
      passport: (item: any) => `gt.page.passport({ id: ${item.pk}})`,
      voyage: (item: any) => `gt.old-page.vessel({ id: ${item?.vessel?.pk}})`,
      vessel: (item: any) => `gt.old-page.vessel({ id: ${item?.pk}})`,
      stock_reserve_unit: (item: any) => `logistics.stockReserveUnit({ id: ${item?.pk}})`,
      transport_charge: (item: any) => `finances.contractCharges({ id: ${item?.pk}})`,
      warehouse: (item: any) => `logistics.warehouseDetails({ id: ${item?.pk}})`,
      client: (item: any) => `gt.page.client({ id: ${item?.pk}})`,
      batch: (item: any) => {
        return (
          {
            logistic: (item: any) => `gt.page.logistic({ id: ${item?.object_id}})`,
            transportationstorageexpense: (item: any) =>
              `logistics.transportationStorageExpense({ id: ${item?.object_id}})`,
            consignment: (item: any) => `logistics.consignments({ id: ${item?.object_id}})`,
            warehouseloss: (item: any) => `logistics.warehouseLosses({ id: ${item?.object_id}})`,
          }[this.contentTypes[item.content_type || item.content_type_id]?.model]?.(item) ||
          undefined
        );
      },
    };
    this.hrefMap = {
      port: (item: any) => `admin/logistics/port/${item.pk}`,
      terminal: (item: any) => `admin/logistics/terminal/${item.pk}`,
      commodity: (item: any) => `admin/crops/crop/${item.pk}`,
      business_unit: (item: any) => `admin/core/business_unit/${item.pk}`,
      country: (item: any) => `admin/location/country/${item.pk}`,
      district: (item: any) => `admin/location/district/${item.pk}`,
      region: (item: any) => `admin/location/region/${item.pk}`,
      city: (item: any) => `admin/crops/city/${item.pk}`,
      address: (item: any) => `admin/location/address/${item.pk}`,
      charge: (item: any) => `admin/location/country/${item.pk}`,
    };
    this.warehousesResource = $resource(
      '/api/logistics/warehouses/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        delete: {
          method: 'DELETE',
          isArray: false,
        },
        predictions: {
          method: 'GET',
          url: '/api/logistics/warehouses/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/warehouses/export_column_names/',
        },
      },
    );
    this.warehouseMovementsResource = $resource(
      '/api/warehouses/warehouse-commodity-movements/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        getBalances: {
          method: 'POST',
          isArray: false,
          url: '/api/warehouses/warehouse-commodity-movements/balances-report/',
          cancellable: true,
        },
        getRests: {
          method: 'POST',
          isArray: false,
          url: '/api/warehouses/warehouse-commodity-movements/rests/',
          cancellable: true,
        },
      },
      { cancellable: true },
    );
  }
  getMovements(params: any) {
    return this.warehouseMovementsResource.query(params).$promise.then((data: any) => {
      data.results.forEach((row: any) => this.updateRowRefs(row));
      return data;
    });
  }
  getBalances(params: any) {
    return this.warehouseMovementsResource.getBalances({ ...params });
  }
  getRests(params: any) {
    return this.warehouseMovementsResource.getRests({ ...params });
  }
  prepareBalancesData(data: any) {
    data.result.details.forEach((row: any) => this.updateRowRefs(row));
    data.result.totals.forEach((groupSet: any) =>
      groupSet.data.forEach((row: any) => this.updateRowRefs(row)),
    );
    return data.result;
  }
  updateRowRefs(row: any) {
    this.updateRefs('contract_position', 'contract_position', row);
    this.updateRefs('commodity', 'commodity', row);
    this.updateRefs('warehouse', 'warehouse', row);
    this.updateRefs('batch', 'batch', row);
    this.updateRefs('batch', 'recorder', row);
    this.updateRefs('port', 'port', row);
    this.updateRefs('terminal', 'terminal', row);
    this.updateRefs('client', 'owner', row);
    this.updateRefs('client', 'producer', row);
    this.updateRefs('client', 'receiver', row);

    this.updateRefs('country', 'country', row);
    this.updateRefs('district', 'district', row);
    this.updateRefs('region', 'region', row);
    this.updateRefs('city', 'city', row);
    this.updateRefs('client', 'owner', row);
    this.updateRefs('client', 'warehouse_owner', row);
    this.updateRefs('client', 'contract_position_company', row);
    this.updateRefs('client', 'contract_position_client', row);
    this.updateRefs('client', 'contract_position_client', row);
    this.updateRefs('warehouse', 'warehouse_parent', row);
    this.updateRefs('warehouse_type', 'warehouse_type', row);
    this.updateRefs('stock_reserve_unit', 'stock_reserve_unit', row);
    this.updateRefs('passport', 'reserve_passport', row);
    this.updateRefs('client', 'reserve_client', row);
    this.updateRefs('contract_position', 'reserve_contract_position', row);
    this.updateRefs('voyage', 'reserve_passport_voyage', row);
    this.updateRefs('vessel', 'reserve_passport_vessel', row);
    this.updateRefs('voyage', 'batch_voyage', row);
    this.updateRefs('vessel', 'batch_vessel', row);
    this.updateRefs('business_unit', 'business_unit', row);
  }
  updateRefs(type: any, fieldName: any, obj: any) {
    if (!obj[fieldName]) {
      obj[fieldName] = { pk: 0, title: '<EMPTY>' };
      return;
    }
    if (typeof obj[fieldName] !== 'object') {
      obj[fieldName] = { pk: obj[fieldName], title: obj[fieldName] };
    }
    obj[fieldName].sref = this.getRef(type, obj[fieldName], this.srefMap);
    obj[fieldName].href = this.getRef(type, obj[fieldName], this.hrefMap);
  }
  getRef(group: any, item: any, refsMap: any) {
    if (!item) {
      return;
    }
    return refsMap[group]?.(item);
  }
}

WarehouseMovementsService.$inject = ['$resource', 'CoreService', 'GtUtils'];
