import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('common.crops.legacy').controller('ObjectCropQualitiesEditController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'GtUtils',
    'qualities',
    'cropId',
    'objectId',
    'cropQualityResurce',
    'gettext',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    qualities: any,
    cropId: any,
    objectId: any,
    cropQualityResurce: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.qualities = qualities;
    vm.cropId = cropId;
    vm.objectId = objectId;
    vm.cropQualityResurce = cropQualityResurce;

    vm.close = close;
    vm.save = save;
    vm.errors = {};
    vm.cropQualities = null;
    vm.findQuality = findQuality;
    vm.nullSensitiveComparator = nullSensitiveComparator;
    vm.refreshCheckboxes = refreshCheckboxes;
    vm.createCharge = createCharge;
    vm.deleteQuality = deleteQuality;
    vm.qualitySelected = qualitySelected;
    vm.createButton = false;
    vm.hovering = false;
    vm.setHovering = setHovering;

    /////////////

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function save() {
      vm.cropQualityResurce.bulkCreateOrUpdate(
        vm.qualities,
        function () {
          updateContractQualities();
          close(vm.qualities, true);
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function findQuality(title: any, list: any) {
      if (!title || !list) {
        return { value: 0 };
      }
      return (
        list
          .filter(function (quality: any) {
            return quality.title == title;
          })
          .shift() || { value: '--' }
      );
    }

    function nullSensitiveComparator(previousEntry: any, nextEntry: any) {
      if (previousEntry.value === undefined) {
        return -1;
      }
      if (nextEntry.value === undefined) {
        return 1;
      }
      return previousEntry < nextEntry ? -1 : 1;
    }

    function refreshCheckboxes() {
      vm.createButton = !vm.createButton;
      if (vm.createButton) {
        vm.qualities.forEach(function (item: any) {
          if (item.contract_charge == null) {
            item._showCheckbox = true;
          }
        });
      }
    }

    function createCharge() {
      vm.qualities.unshift({
        contract: vm.contractId,
        logistic: vm.logisticId,
        value: 0,
        crop_area: vm.objectId,
      });
      vm.createButton = false;
    }

    function updateContractQualities() {
      vm.cropQualityResurce.query({ crop_area: vm.objectId }, function (data: any) {
        vm.qualities.length = 0;
        vm.qualities.push(...data.results);
      });
    }

    function deleteQuality(quality: any) {
      if (!quality.id) {
        vm.qualities.splice(vm.qualities.indexOf(quality), 1);
        return;
      }
      GtUtils.overlay('show');
      vm.cropQualityResurce.delete({ id: quality.id }, function () {
        updateContractQualities();
        GtUtils.overlay('hide');
        GtUtils.notify(gettext('Quality deleted'));
      });
    }

    function qualitySelected(vm: any) {
      const selectedId = vm.$select.selected.id;
      const selectedTitle = vm.$select.selected.title;

      const duplicateQualities = vm.qualities.filter((q: any) => q.quality === selectedId);

      if (duplicateQualities.length > 1) {
        GtUtils.notify(
          'This quality is already selected. Please select a different quality.',
          'error',
        );

        for (let i = 0; i < vm.qualities.length; i++) {
          if (vm.qualities[i].quality === selectedId && !vm.qualities[i].id) {
            vm.qualities.splice(i, 1);
            break;
          }
        }
      } else if (duplicateQualities.length === 1) {
        duplicateQualities[0].title = selectedTitle;
      }
    }
  }
})();
