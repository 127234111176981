import ng from 'angular';

(function () {
  'use strict';
  ng.module('finances.legacy').component('paymentInfoTable', {
    bindings: {
      paymentInfos: '<',
      count: '<',
      updateList: '&?',
      saveItem: '&?',
      deleteItem: '&?',
      edit: '@?',
      filterLevel: '<?',
      newPaymentInfo: '<?',
    },
    template: require('./payment-info-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gettext', 'FinancesService'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction, FinancesService: any) {
    const vm = this;

    vm.count = 0;
    vm.edit = false;
    vm.setHovering = setHovering;
    vm.hovering = false;
    vm.theadConfig = {};
    vm.openPaymentInfoModal = openPaymentInfoModal;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'payment-info-table';
      vm.theadConfig = getTheadConfig();
    };

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function openPaymentInfoModal(paymentInfo: any) {
      FinancesService.paymentInfoModal(paymentInfo).then(() => vm.updateList());
    }

    function getTheadConfig() {
      return {
        columns: [
          {
            columnName: 'property',
            title: gettext('property'),
          },
          {
            columnName: 'name',
            title: gettext('Name'),
          },
        ],
      };
    }
  }
})();
