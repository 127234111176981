import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './potentials-group-container.html?raw';

export const PotentialsGroupContainer = {
  bindings: {
    filterLevel: '<?',
    groupBy: '<',
  },
  template,
  controller: [
    '$scope',
    '$filter',
    'gtFilterService',
    'PotentialService',
    'GtUtils',
    'gettext',
    class {
      $filter: ng.IFilterService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      PotentialService: any;
      filterLevel = 'potentials-group-container';
      gettext: ng.gettext.gettextFunction;
      groupBy: any;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        $filter: ng.IFilterService,
        gtFilterService: GtFilterService,
        PotentialService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.gtFilterService = gtFilterService;
        this.PotentialService = PotentialService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.queryParams = {};
        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          delete data.with_totals;
          this.queryParams = { group_by: this.groupBy, ...data };
          this.updateCropAreasTable();
        });
        this.updateCropAreasTable();
      }

      $onChanges(changes: any) {
        if (changes.groupBy) {
          this.tableOptions = {};
          this.tableData = {};
          this.queryParams = Object.assign(this.queryParams, { group_by: this.groupBy });
          this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
          this.tableOptions = this.getTableOptions();
        }
      }

      updateCropAreasTable() {
        this.GtUtils.overlay('show');
        return this.PotentialService.getCropAreaGroupedList(this.queryParams).then((data: any) => {
          this.tableData = { rows: data.results, count: 10 };
          this.GtUtils.overlay('hide');
        });
      }

      getTableOptions() {
        const options: any = {
          configurable: true,
          columnDefs: [] as any[],
          tableName: 'potentials-group',
        };
        options.columnDefs = [
          {
            columnName: 'crop',
            title: this.gettext('Commodity'),
            cellTemplate: '<span>{[{item.cargo_title}]}</span>',
          },
          {
            columnName: 'volume',
            title: this.gettext('Total Volume'),
            cellTemplate: '<span>{[{item.volume_sum}]}</span>',
          },
        ];

        switch (this.groupBy) {
          case 'client_cargo':
            options.columnDefs.unshift({
              columnName: 'client',
              title: this.gettext('Client'),
              cellTemplate: '<span>{[{item.client_name}]}</span>',
            });
            break;
          case 'storage_cargo':
            options.columnDefs.unshift({
              columnName: 'storage',
              title: this.gettext('Storage'),
              cellTemplate: '<span>{[{item.storage_name}]}</span>',
            });
            break;
        }

        return options;
      }
    },
  ],
};
