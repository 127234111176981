import React from 'react';

import { PhoneWrite } from '~/shared/ui/data-types';

import type { FilterTypeProps } from '../types';

export const PhoneFilter: React.FC<FilterTypeProps> = ({ filterValue, onChange }) => {
  return (
    <PhoneWrite
      value={filterValue.values[0]}
      intent="default"
      valueChanged={(input) =>
        onChange({ ...filterValue, values: [input ? input.toString() : ''] })
      }
    />
  );
};
