export const getCookies = (document: Document) => {
  const cookies: Record<string, string> = {};
  document.cookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookies[key.trim()] = value;
  });
  return cookies;
};

export const lsRead = <T>(key: string, fallback: T): T => {
  const value = localStorage.getItem(key);
  return value ? (JSON.parse(value) as T) : fallback;
};

export const lsWrite = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getWindowSize = () => ({ height: window.innerHeight, width: window.innerWidth });
