import React from 'react';

import type { IconComponent } from '~/shared/ui/icons';
import { BucketIcon, CloseIcon, CopyIcon } from '~/shared/ui/icons';
import { DropdownMenuSeparator } from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';

import { IconList, ViewType } from '../view-configurator/data';
import type { View, ViewConfigChanged } from '../view-configurator/types';

export const BasicViewContent = <T extends View>({
  content,
  view,
  viewConfigChanged,
  contentClosed,
  isOpenIconList,
  isOpenViewVariants,
  Icon,
  iconListOpened,
  viewVariantsOpened,
  viewDeleted,
  title = 'View',
}: {
  isOpenIconList: boolean;
  iconListOpened: (value: boolean) => void;
  viewVariantsOpened: (value: boolean) => void;
  isOpenViewVariants: boolean;
  view: T;
  content: React.ReactNode;
  viewConfigChanged: ViewConfigChanged;
  viewDeleted: (id: number) => void;
  contentClosed: () => void;
  Icon: IconComponent;
  title?: string;
}) => {
  return (
    <>
      <div className="flex items-center gap-1 px-2 pt-1.5">
        <CloseIcon
          size={14}
          className="cursor-pointer text-text-main-tertiary transition duration-300 ease-in-out hover:opacity-70"
          onClick={contentClosed}
        />
        <p className="text-xs font-medium text-text-main-primary">{title}</p>
      </div>
      <DropdownMenuSeparator className="h-[1px] bg-stroke-main-medium" />
      <div className="flex items-center gap-2 px-2" data-testid="basic-view-title-and-icon">
        <IconList
          viewId={view.id}
          isOpen={isOpenIconList}
          onOpenChanged={iconListOpened}
          CurrentIcon={view.Icon}
          DefaultIcon={view.DefaultIcon}
          viewConfigChanged={viewConfigChanged}
        />
        <Input
          defaultValue={view.name}
          onBlur={(e) => viewConfigChanged(view.id, { name: e.target.value })}
          fullWidth
        />
      </div>
      <div className="flex flex-col gap-2 px-2">
        <ViewType
          viewId={view.id}
          isOpen={isOpenViewVariants}
          onOpenChanged={viewVariantsOpened}
          currentViewType={view.type}
          Icon={Icon}
          viewConfigChanged={viewConfigChanged}
        />
        {content}
      </div>
      <DropdownMenuSeparator className="my-0 h-[1px] bg-stroke-main-medium" />
      <div className="mb-2 flex flex-col gap-2 px-2">
        <div className="flex cursor-pointer items-center gap-2">
          <CopyIcon size={14} className="text-stroke-main-stronger" />
          <p className="text-xs text-text-main-primary">Duplicate View</p>
        </div>
        <button
          className="flex cursor-pointer items-center gap-2"
          onClick={() => viewDeleted(view.id)}
        >
          <BucketIcon size={14} className="text-stroke-additional-danger" />
          <p className="text-xs text-text-additional-danger">Delete View</p>
        </button>
      </div>
    </>
  );
};
