import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './offset-item.html?raw';

export const OffsetItem = {
  bindings: {
    offset: '<',
  },
  template,
  controller: [
    '$stateParams',
    '$rootScope',
    '$scope',
    '$state',
    'OffsetService',
    'DocumentsService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      OffsetService: any;
      documentsCount: number;
      gettext: ng.gettext.gettextFunction;
      offset: any;
      positionsTableCount: number;
      positionsTableRows: any;
      tab: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        OffsetService: any,
        DocumentsService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.offset = { id: $stateParams.id };
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.OffsetService = OffsetService;
        this.DocumentsService = DocumentsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.tab = 'info';
        this.documentsCount = 0;
        this.positionsTableRows = [];
        this.positionsTableCount = 0;
      }

      $onInit() {
        this.updateData();
        this.updateCounters();
        this.$scope.$on('documents-container__updated', () => {
          this.updateCounters();
        });
      }

      updateOffsetPositionTableData() {
        this.OffsetService.getOffsetPositions({
          id_list: this.offset.offsetpositions,
        }).then((data: any) => {
          this.positionsTableRows = Object.assign([], data.results);
          this.positionsTableCount = data.count;
          this.GtUtils.overlay('hide');
        });
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.OffsetService.getOffsetDetails({ id: this.offset.id })
          .then((data: any) => {
            this.offset = data;
            this.updateOffsetPositionTableData();
            this.$rootScope.pageTitle = this.$rootScope.pageTitle + ' #' + this.offset.number;
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext("Offset doesn't exist"));
            this.GtUtils.overlay('hide');
            this.$state.go('finances.offsetsPage');
          });
      }

      updateCounters() {
        this.GtUtils.getCounters(['documents.document'], {
          object_id: this.offset.id,
          content_type: this.offset.content_type,
        })
          .then((data: any) => {
            this.documentsCount = data['documents.document'];
          })
          .catch(this.GtUtils.errorClb);
      }

      saveOffsetPositions() {
        this.OffsetService.saveOffset(this.offset)
          .then(() => {
            this.updateData();
            this.GtUtils.notify(this.gettext('Offset saved'));
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      editOffset() {
        this.OffsetService.offsetModal(this.offset).then(() => this.updateData());
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('FinanceOffset', this.offset.id);
      }
    },
  ],
};
