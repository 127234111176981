import React from 'react';

import { cn } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import { PlusIcon, useIcon } from '~/shared/ui/icons';
import { CommandItem } from '~/shared/ui/kit/command';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { DynamicFilterControl } from './dynamic-filter-control';
import { OperatorSelect } from './operator-select';
import type { FilterOption, FilterValue } from '../../types';
import { FilterButton } from '../filter-button';

const FilterListItem: React.FC<{
  option: FilterOption;
  value: FilterValue;
  onChange: (filter: FilterValue) => void;
}> = ({ option, value, onChange }) => {
  const Icon = useIcon(option.icon);

  return (
    <CommandItem key={option.argument}>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger
          className="m-1 w-full p-1 transition duration-300 hover:bg-transparent-light"
          displayChevron={false}
        >
          <button
            onClick={() => onChange({ argument: option.argument, operator: '', values: [] })}
            className="flex items-center gap-2"
          >
            <Icon size={14} className="text-stroke-main-stronger" />
            <p className="text-xs text-text-main-primary">{option.title}</p>
          </button>
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent sideOffset={6} className="p-0">
          <div className="border-b border-stroke-main-light">
            <OperatorSelect filterOption={option} filterValue={value} onChange={onChange} />
          </div>
          <DynamicFilterControl
            option={option}
            value={{ argument: option.argument, operator: '', values: [] }}
            onChange={onChange}
          />
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </CommandItem>
  );
};

export const FiltersSelect: React.FC<{
  filterValues: FilterValue[];
  filterOptions: FilterOption[];
  onChange: (filter: FilterValue) => void;
}> = ({ filterValues, filterOptions, onChange }) => {
  const valuesByArgument = React.useMemo(
    () =>
      filterValues.reduce<Record<string, FilterValue>>(
        (acc, filterValue) => ({ ...acc, [filterValue.argument]: filterValue }),
        {},
      ),
    [filterValues],
  );

  const optionsByGroup = React.useMemo(() => {
    return filterOptions.reduce<Record<string, FilterOption[]>>(
      (acc, filterOption) => ({
        ...acc,
        [filterOption.groupName]: [...(acc[filterOption.groupName] || []), filterOption],
      }),
      {},
    );
  }, [filterOptions]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <FilterButton intent="empty">
          <div className="flex items-center gap-1">
            <PlusIcon size={14} className="text-stroke-main-stronger" />
            <p className="text-xs text-text-main-secondary">Filter</p>
          </div>
        </FilterButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <SearchCommandList
          groupClassName="p-0"
          options={Object.entries(optionsByGroup).map(([groupName, options], index) => (
            <div key={groupName}>
              <p
                className={cn(
                  'border-t border-stroke-main-light bg-transparent-lighter px-2 py-1 text-xs font-semibold text-text-main-tertiary',
                  index === 0 && 'border-t-0',
                )}
              >
                {groupName}
              </p>
              {options.map((option) => (
                <FilterListItem
                  key={option.argument}
                  option={option}
                  value={valuesByArgument[option.argument]}
                  onChange={onChange}
                />
              ))}
            </div>
          ))}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
