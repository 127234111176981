import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

function Controller(
  this: any,
  $scope: ng.IScope,
  $state: ng.ui.IStateService,
  LogisticsService: any,
  ContractsService: ContractsService,
  ReportsService: any,
  GtUtils: GtUtilsService,
  gtFilterService: GtFilterService,
  moment: any,
  $q: ng.IQService,
) {
  const vm = this;
  vm.filterLevel = '';
  vm.contracts = [];
  vm.contractsTotal = {};
  vm.contractsCount = 0;
  vm.resourceName = undefined;
  vm.editContract = editContract;
  vm.getMonthName = getMonthName;
  vm.contractPartialUpdate = contractPartialUpdate;
  vm.getHref = getHref;
  vm.updatePage = updatePage;
  vm.getVoyageListHref = getVoyageListHref;
  vm.timelineChartItems = [];
  vm.updateContractsExecutionDates = updateContractsExecutionDates;

  vm.$onInit = function () {
    vm.filterLevel = vm.filterLevel || 'physical-position-container';
    vm.queryParams = { page_size: 25, ...vm.initQueryParams };

    $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
      vm.queryParams = { ...vm.initQueryParams, ...data };
      vm.resourceName =
        vm.queryParams.contract_type === 'sale' ? 'SaleContract' : 'PurchaseContract';
      updateData();
    });

    gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    LogisticsService.Basis.query({ codename_list: ['CIF', 'CFR'] }, function (data: any) {
      vm.chosenBasisList = data.results;
    });
    vm.view = 'table';
  };

  function updateData() {
    GtUtils.overlay('show');
    vm.timelineChartItems = [];
    ContractsService[vm.resourceName].physicalPosition(vm.queryParams, function (data: any) {
      vm.contracts = data.results;
      vm.contractsCount = data.count;
      vm.timelineChartItems = data.results
        .filter((item: any) => item.date_of_execution || item.end_of_execution)
        .map((item: any) => ({
          id: item.id.toString(),
          start: item.date_of_execution || item.end_of_execution,
          end: item.end_of_execution || item.date_of_execution,
          name: item.contract_number.toString(),

          popupHtml: `Commodity: ${item.crop_title}<br />
                      Volume: ${item.volume}<br />
                      Buyer: ${item.buyer_name}<br />
                      Supplier: ${item.supplier_name}<br />
                      <span><a href="#/contract/${item.id}?contract_type=${item.contract_type}&stage=contract"
                      target="_blank">
                      Details <i class="fa fa-arrow-right"></i></a></span> `,
        }));
      ContractsService[vm.resourceName].physicalPositionTotal(vm.queryParams, function (data: any) {
        vm.contractsTotal = data;
        GtUtils.overlay('hide');
      });
    });
  }

  function updateContractsExecutionDates(data: any) {
    GtUtils.overlay('show');
    let chain = $q.when();
    data
      .map((item: any) => {
        return vm.contracts
          .filter((contract: any) => contract.id === item.id)
          .reduce((acc: any, contract: any) => {
            acc[contract.id] = {
              ...contract,
              id: item.id,
              date_of_execution: item.start,
              end_of_execution: item.end,
            };
            return acc;
          }, {});
      })
      .forEach((item: any) => {
        chain = chain.then(() => ContractsService.updateContract(item));
      });

    chain.then(() => {
      GtUtils.overlay('hide');
      updateData();
    });
  }

  function updatePage(params: any) {
    gtFilterService.updateQueryParams(params, vm.filterLevel);
    vm.queryParams = { ...vm.queryParams, ...params };
    gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
  }

  function editContract(contract: any) {
    if (contract.row_type == 2) {
      ContractsService.contractModal(contract)
        .then(function (data: any) {
          if (!data || data == 'cancel') {
            return;
          }
          updateData();
        })
        .catch(GtUtils.errorClb);
    }
    if (contract.row_type == 1) {
      ReportsService.riskPositionTerminalModal(contract).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        updateData();
      });
    }
  }

  function getMonthName(number: any) {
    if (number) {
      return moment(number, 'M').format('MMMM');
    }
    return null;
  }

  function contractPartialUpdate(contractId: any, contractUpdateFields: any) {
    ContractsService[vm.resourceName].update(
      { id: contractId },
      contractUpdateFields,
      updateData,
      (e: any) => GtUtils.errorClb(e),
    );
  }

  function getHref(cargoId: any) {
    return $state.href('lineup.lineup') + '?subtab=allocations' + '&cargo_list=' + cargoId;
  }

  function getVoyageListHref(name: any) {
    return $state.href('logistics.voyages') + '?search=' + name;
  }
}

(function () {
  'use strict';
  ng.module('reports.legacy').component('physicalPositionContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      convert: '<?',
      currency: '<?',
      sourcePrice: '<?',
      marketPriceType: '<?',
    },
    template: require('./physical-position-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$state',
    'LogisticsService',
    'ContractsService',
    'ReportsService',
    'GtUtils',
    'gtFilterService',
    'moment',
    '$q',
  ];
})();
