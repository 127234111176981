import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').controller('ActivityPageController', Controller);

  Controller.$inject = [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'HelpButtonsService',
    'PageService',
    'AccountsService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    HelpButtonsService: any,
    PageService: PageService,
    AccountsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.logentries = [];
    vm.logentriesCount = 0;
    vm.filterLevel = 'activity-page-filter';
    vm.hbConfig = null;
    vm.queryParams = {};

    activate();

    ////////////////

    function activate() {
      vm.hbConfig = _prepareHbConfig();
      HelpButtonsService.setConfig(vm.hbConfig);
      PageService.setConfig(vm.hbConfig);
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated', updateData);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, updateData);
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return AccountsService.LogEntry.query(
        gtFilterService.getQueryParams(vm.filterLevel),
        function (data: any) {
          vm.logentries = data.results;
          vm.logentriesCount = data.count;
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function _prepareHbConfig() {
      const config: any = {
        update: { method: updateData },
        export: {
          link: '/admin/admin/logentry/',
        },
        print: {},
      };

      config.filter = {
        filterLevel: vm.filterLevel,
        clicked: false,
        search: true,
        dates: true,
        selects: [
          {
            argument: 'author',
            resource: 'auth.user',
            placeholder: gettext('author'),
          },
          {
            argument: 'passport_id',
            resource: 'passports.passport',
            placeholder: gettext('passport'),
          },
          {
            argument: 'passport_contracts_id',
            resource: 'passports.passport',
            placeholder: gettext('passport contracts'),
          },
          {
            argument: 'passport_execution_id',
            resource: 'passports.passport',
            placeholder: gettext('passport execution'),
          },
          {
            argument: 'passport_finance_id',
            resource: 'passports.passport',
            placeholder: gettext('passport finance'),
          },
          {
            argument: 'passport_charges_id',
            resource: 'passports.passport',
            placeholder: gettext('passport charges'),
          },
        ],
        nestedSelects: [
          {
            argument: 'model',
            placeholder: gettext('Model'),
            items: [
              { id: 'client', title: gettext('Client') },
              { id: 'document', title: gettext('Document') },
              { id: 'elevator', title: gettext('Elevator') },
              { id: 'farm', title: gettext('Farm') },
              { id: 'deliverer', title: gettext('Forwarder') },
              { id: 'supplier', title: gettext('Supplier') },
              { id: 'buyer', title: gettext('Buyer') },
              { id: 'broker', title: gettext('broker') },
              { id: 'bank', title: gettext('bank') },
              { id: 'owner', title: gettext('owner') },
              { id: 'station', title: gettext('station') },
              { id: 'surveyor', title: gettext('surveyor') },
              { id: 'exporter', title: gettext('exporter') },
              { id: 'terminal', title: gettext('terminal') },
              { id: 'insurer', title: gettext('insurer') },
              { id: 'finance', title: gettext('Finance') },
              { id: 'person', title: gettext('Person') },
              { id: 'logistic', title: gettext('Logistic') },
              { id: 'purchasecontract', title: gettext('PurchaseContract') },
              { id: 'salecontract', title: gettext('SaleContract') },
              { id: 'request', title: gettext('Request') },
              { id: 'vessel', title: gettext('Vessel') },
              { id: 'passport', title: gettext('Passport') },
              { id: 'additionalagreement', title: gettext('Additional agreement') },
              { id: 'customscargodeclaration', title: gettext('Customs Declaration') },
              { id: 'payment', title: gettext('Payment') },
              { id: 'margincontrol', title: gettext('Margin control') },
              { id: 'voyage', title: gettext('Voyage') },
              { id: 'disbursementbl', title: gettext('Disbursementbl') },
              { id: 'billoflading', title: gettext('Billoflading') },
            ],
          },
          {
            argument: 'action_flag',
            placeholder: gettext('Action flag'),
            items: [
              { id: 1, title: gettext('Add') },
              { id: 2, title: gettext('Edit') },
              { id: 3, title: gettext('Delete') },
              { id: 4, title: gettext('Connection') },
              { id: 5, title: gettext('Import') },
            ],
          },
        ],
      };
      config.filters = { ...config.filter };
      return config;
    }
  }
})();
