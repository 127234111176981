import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { QueryParams } from '~/app/core/types';
import { getModalRoot } from '~/shared/ui/modal';

(function () {
  'use strict';

  ng.module('company.documents.legacy').factory('DocumentsService', DocumentsService);

  DocumentsService.$inject = [
    '$resource',
    '$upload',
    '$uibModal',
    '$window',
    '$httpParamSerializer',
    'CoreService',
  ];

  function DocumentsService(
    $resource: ng.resource.IResourceService,
    $upload: any,
    $uibModal: ng.ui.bootstrap.IModalService,
    $window: ng.IWindowService,
    $httpParamSerializer: any,
    CoreService: CoreService,
  ) {
    const DocumentResource = $resource(
      '/api/documents/documents/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        patch: { method: 'PATCH', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/documents/documents/predictions/',
        },
        deleteConfirmation: {
          method: 'GET',
          url: '/api/documents/documents/:id/delete_confirmation/',
        },
      },
    );

    const DocxDocumentResource = $resource(
      '/api/documents/docxdocuments/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        generateDocument: {
          method: 'GET',
          url: '/api/documents/docxdocuments/:id/generate/',
          transformResponse: (data: any, headers: any) => ({
            data: data,
            headers: headers(),
          }),
          responseType: 'blob',
        },
      },
    );

    const DocumentChecklistResource = $resource(
      '/api/documents/documents-checklists/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        delete: { method: 'DELETE' },
        update: { method: 'PATCH', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/documents/documents-checklists/predictions/',
        },
      },
    );
    const DocumentChecklistPointResource = $resource(
      '/api/documents/documents-checklist-points/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        delete: { method: 'DELETE' },
        patch: { method: 'PATCH', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/documents/documents-checklist-points/predictions/',
        },
      },
    );

    return {
      Document: DocumentResource,
      DocxDocument: DocxDocumentResource,
      DocumentChecklist: DocumentChecklistResource,
      DocumentChecklistPoint: DocumentChecklistPointResource,
      documentModal: documentModal,
      documentListModal: documentListModal,
      generateDocxModal: generateDocxModal,
      saveDocument: saveDocument,
      saveDocuments: saveDocuments,
      attachDocumentToLogistics: attachDocumentToLogistics,
      addIcons: addIcons,
      bulkDownload: bulkDownload,
      queryDocuments: queryDocuments,
      getFileIcoClass: getFileIcoClass,
      deleteDocument: deleteDocument,
      attachDocumentModal: attachDocumentModal,
      getDocumentChoicePointObjectTable: getDocumentChoicePointObjectTable,
    };

    ///////////////

    function attachDocumentModal(object: any, model: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: `
          <attach-document-modal object="object" modal-instance="$uibModalInstance" model="model">
          </attach-document-modal>`,
        controller: [
          '$scope',
          '$uibModalInstance',
          ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
            ($scope as any).object = object;
            ($scope as any).model = model;
            ($scope as any).$uibModalInstance = $uibModalInstance;
          },
        ],
        windowClass: 'modal-template',
        appendTo: getModalRoot(),
        size: 'sm',
      }).result;
    }

    function documentModal(model: any, objectId: any, contentType: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./document-modal/document-modal.tpl.html?raw'),
        controller: 'DocumentModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          contentType: () => {
            if (contentType) {
              return contentType;
            }
            return CoreService.getModelContentType(model).then((ct: any) => {
              return ct.id;
            });
          },
          objectId: () => {
            return objectId;
          },
          type: () => {
            return model?.toLowerCase() || 'contract';
          },
        },
      }).result;
    }

    function queryDocuments(params: any) {
      return DocumentResource.query(params).$promise.then(function (data: any) {
        data.results = addIcons(data.results);
        return data;
      });
    }

    function documentListModal(queryParams: QueryParams) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./components/document-list-modal/document-list-modal.tpl.html?raw'),
        controller: 'DocumentListModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          queryParams: () => {
            return queryParams;
          },
        },
      }).result;
    }

    function generateDocxModal(modelType: any, objectId: any, extra: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./components/generate-docx-modal/generate-docx-modal.tpl.html?raw'),
        controller: 'GenerateDocxModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          modelType: () => modelType,
          objectId: () => objectId,
          extra: () => extra,
        },
      }).result;
    }

    function saveDocument(doc: any) {
      const file = doc.file,
        data: any = {
          title: doc.title,
          content_type: doc.content_type,
          object_id: doc.object_id,
          basis_doc: doc.basis_doc,
          checklist_point: doc.checklist_point,
          client_document_type: doc.client_document_type,
        };

      delete data.basis_doc;
      delete data.checklist_point;
      delete data.client_document_type;

      return $upload.upload({
        url: '/api/documents/documents/',
        data: data,
        file: file,
      });
    }

    function attachDocumentToLogistics(doc: any) {
      const file = doc.file,
        data = {
          title: doc.title,
          content_type: doc.content_type,
          object_id: 1,
          logistics: doc.logistics,
        };
      return $upload.upload({
        url: '/api/documents/documents/attach_to_logistics/',
        data: data,
        file: file,
      });
    }

    function bulkDownload(params: any) {
      const queryParams = $httpParamSerializer(params);
      $window.open('/api/documents/documents/bulk_download/?' + queryParams, '_blank');
    }

    function addIcons(documents: any) {
      documents.map((i: any) => (i.class = getFileIcoClass(i.file)));
      return documents;
    }

    function getFileIcoClass(filename: string) {
      const fileClasses: any = {
        xls: 'docs-xls',
        xlsx: 'docs-xls',
        doc: 'docs-word',
        docx: 'docs-word',
      };
      return (
        (typeof filename === 'string' && fileClasses[String(filename.split('.').pop())]) ||
        'docs-pdf'
      );
    }

    function saveDocuments(files: any, objectId: any, contentType: any) {
      return Promise.all(
        files.map((file: any) => {
          const fileToCreate = {
            file: file,
            title: file.name,
            content_type: contentType,
            object_id: objectId,
            basis_doc: file.basis_doc,
            client_document_type: file.client_document_type,
          };

          return saveDocument(fileToCreate);
        }),
      );
    }

    function deleteDocument({ id }: any) {
      return DocumentResource.delete({ id }).$promise;
    }

    function getDocumentChoicePointObjectTable(params: any) {
      return DocumentChecklistPointResource.query(params).$promise.then((pointsResponse: any) => {
        if (!pointsResponse?.results?.length) {
          return {
            count: 0,
            results: [],
          };
        }
        return DocumentChecklistResource.query(params).$promise.then((checklistResponse: any) => {
          const filteredPoints = pointsResponse.results.filter((point: any) => {
            const hasAssociatedDocument = checklistResponse.results.some(
              (checklistItem: any) => checklistItem.point === point.id,
            );
            return point.is_main || hasAssociatedDocument;
          });

          return {
            count: pointsResponse.count,
            results: filteredPoints.map((point: any) => {
              const associatedDocument = checklistResponse.results.find(
                (v: any) => v.point === point.id,
              ) || { is_visible: true };
              return {
                ...params,
                ...associatedDocument,
                point: point.id,
                title: point.title,
              };
            }),
          };
        });
      });
    }
  }
})();
