import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('transshipmentTariffPageView', {
    bindings: {},
    template: require('./transshipment-tariff-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'LogisticsService', 'gettext'];

  function Controller(
    this: any,
    PageService: PageService,
    LogisticsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.filterLevel = 'transshipment-tariff-page-view';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = { class: 'page-header-main-logistics-tab', pages: [] };
      // @ts-ignore
      config.buttons = [
        new PageService.buttons.Print(),
        new PageService.buttons.Refresh(vm.filterLevel),
        new PageService.buttons.Import('logistics.transshipmenttariff'),
        new PageService.buttons.Export('logistics.transshipmenttariff', vm.filterLevel),
        new PageService.buttons.Filters(),
      ];

      // @ts-ignore
      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [
          {
            title: gettext('RECENTLY UPDATED'),
            predicate: 'update_time',
          },
        ],
        search: true,
        dates: true,
        selects: [
          {
            argument: 'station',
            placeholder: gettext('station'),
            resource: 'logistics.Station',
          },
          {
            argument: 'crop',
            placeholder: gettext('crop'),
            resource: 'crops.Crop',
          },
          {
            argument: 'rate',
            placeholder: gettext('rate'),
            resource: 'finances.CurrencyExchange',
          },
          {
            argument: 'business_unit',
            placeholder: gettext('business unit'),
            resource: 'core.BusinessUnit',
          },
          {
            argument: 'author',
            placeholder: gettext('autor'),
            resource: 'auth.User',
          },
        ],
      };
      // @ts-ignore
      config.filters.nestedSelects = [];
      // @ts-ignore
      config.filters.multiSelects = [];
      // @ts-ignore
      config.filters.nestedMultiSelects = [];
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }
  }
})();
