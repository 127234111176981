import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './dbl-logistic-modal.html?raw';

export const DBLLogisticModal = {
  bindings: {
    dbl: '<',
    extraData: '<',
    modalInstance: '<',
  },
  template,
  controller: [
    'gettext',
    'DBLService',
    'GtUtils',
    'LogisticsService',
    class {
      DBLService: any;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      dbl: any;
      extraData: any;
      gettext: ng.gettext.gettextFunction;
      logisticInitQueryParams: QueryParams = {};
      modalInstance: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        DBLService: any,
        GtUtils: GtUtilsService,
        LogisticsService: any,
      ) {
        this.gettext = gettext;
        this.DBLService = DBLService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;

        this.dbl = this.dbl || {};
        this.extraData = this.extraData || {};
      }

      $onInit() {
        const contractField: any = [this.extraData.contractType + '_contract'];
        this.logisticInitQueryParams = {
          dblType: this.extraData.dblType,
          contractType: this.extraData.contractType,
          // @ts-ignore
          contract: this.dbl[contractField],
          with_dbl: 0,
          serializer: 'table_info',
        };
      }

      onConnectionToDbl(event: any) {
        const logisticsData = event.logisticIds.map((id: any) => ({
          id: id,
          disbursementbl: this.dbl.id,
        }));
        return this.LogisticsService[event.logistic_type]
          .bulkCreateOrUpdate(
            { partial: true, logistic_type: event.logistic_type, dbl_id: this.dbl.id },
            logisticsData,
          )
          .$promise.then(() => {
            this.GtUtils.notify(this.gettext('Logistics updated!'));
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        return this.modalInstance.close(data || 'cancel');
      }
    },
  ],
};
