import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';
export class QualityRuleTemplatesService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  gettext: ng.gettext.gettextFunction;
  qualityRuleTemplatesResource: any;
  constructor(
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    this.$uibModal = $uibModal;
    this.$resource = $resource;
    this.gettext = gettext;
    this.qualityRuleTemplatesResource = $resource(
      '/api/crops/quality-rule-templates/:id/',
      {
        id: '@id',
      },
      {
        save: { method: 'POST' },
        update: { method: 'PUT' },
        delete: { method: 'DELETE' },
      },
    );
  }

  saveTemplate(tmpl: any) {
    return tmpl.id
      ? this.qualityRuleTemplatesResource.update({ id: tmpl.id }, tmpl).$promise
      : this.qualityRuleTemplatesResource.save(tmpl).$promise;
  }

  deleteTemplate(tmpl: any) {
    return this.qualityRuleTemplatesResource.delete({ id: tmpl.id }).$promise;
  }

  getPageConfig() {
    return [
      {
        title: this.gettext('Quality rule templates'),
        permissions: [],
        state: 'company.quality-rule-templates',
        icon: 'fa-cog',
      },
    ];
  }

  openTemplateModal(template: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template:
        '<quality-rule-template-modal template="template" modal-instance="$uibModalInstance"></quality-rule-template-modal>',
      controller: [
        '$scope',
        'template',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          template: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).template = template;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      size: 'sm',
      appendTo: getModalRoot(),
      windowClass: 'modal-template',
      resolve: {
        template: () => template,
      },
    }).result;
  }
}
QualityRuleTemplatesService.$inject = ['$resource', 'gettext', '$uibModal'];
