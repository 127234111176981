import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { TerminalTransshipmentTariffContainer } from './terminal-transshipment-tariff-container/terminal-transshipment-tariff-container.component';
import { TerminalTransshipmentTariffListTableView } from './terminal-transshipment-tariff-list-table-view/terminal-transshipment-tariff-list-table-view.component';
import { TerminalTransshipmentTariffPage } from './terminal-transshipment-tariff-page/terminal-transshipment-tariff-page.component';
import { TerminalTransshipmentTariffService } from './terminal-transshipment-tariff.service';

export const terminalTransshipment = ng
  .module('tariffs.terminalTransshipment', [])
  .component('terminalTransshipmentTariffPage', TerminalTransshipmentTariffPage)
  .component('terminalTransshipmentTariffContainer', TerminalTransshipmentTariffContainer)
  .component('terminalTransshipmentTariffListTableView', TerminalTransshipmentTariffListTableView)
  .service('TerminalTransshipmentTariffService', TerminalTransshipmentTariffService)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('logistics.tariffs.terminalTransshipment', {
        url: '/terminal-transshipment-tariff',
        component: 'terminalTransshipmentTariffPage',
        data: {
          pageTitle: gettext('Terminal Transshipment Tariff'),
        },
        resolve: {
          filterLevel: () => {
            return 'terminal-transshipment-tariff-page-filter-level';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('terminal-transshipment-tariff-page');
            },
          ],
        },
      });
    },
  ]).name;
