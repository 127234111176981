import React from 'react';

import { useObservableEagerState } from '~/shared/lib/state';

import type { PageViewsStore } from '../../services/page-views-store';
import type { ReorderedColumn } from '../view-configurator';
import { ViewConfigurator } from '../view-configurator';

const mockColumns: ReorderedColumn[] = [
  ['Column 1', 'BuildingIcon', 150, 'select', 'Column 1', true] as const,
  ['Column 2', 'BuildingIcon', 150, 'select', 'Column 2', true] as const,
  ['Column 3', 'BuildingIcon', 150, 'select', 'Column 3', true] as const,
  ['Column 4', 'BuildingIcon', 150, 'select', 'Column 4', true] as const,
  ['Column 5', 'BuildingIcon', 150, 'select', 'Column 5', true] as const,
  ['Column 6', 'BuildingIcon', 150, 'select', 'Column 6', true] as const,
  ['Column 7', 'BuildingIcon', 150, 'select', 'Column 7', true] as const,
  ['Column 8', 'BuildingIcon', 150, 'select', 'Column 8', true] as const,
  ['Column 9', 'BuildingIcon', 150, 'select', 'Column 9', true] as const,
  ['Column 10', 'BuildingIcon', 150, 'select', 'Column 10', true] as const,
].map(([id, icon, size, type, name, visible]) => ({
  id,
  icon,
  size,
  type,
  name,
  visible,
}));

export const PageViewConfig: React.FC<{ viewsStore: PageViewsStore }> = ({ viewsStore }) => {
  const currentView = useObservableEagerState(viewsStore.currentView$);
  const availableViews = useObservableEagerState(viewsStore.availableViews$);

  const [isViewConfigOpen, setIsViewConfigOpen] = React.useState(false);

  // wtf
  // TO DO: mock data fow dnd-column list. Implement
  const [reorderedColumns, setReorderedColumns] = React.useState<ReorderedColumn[]>(mockColumns);
  // wtf
  const columnsReordered = (columns: ReorderedColumn[]) => {
    setReorderedColumns(columns);
  };
  // wtf
  const columnVisibilityChanged = (column: ReorderedColumn) => {
    const updatedColumns = reorderedColumns.map((col) =>
      col.id === column.id ? { ...column, visible: !column.visible } : col,
    );

    setReorderedColumns(updatedColumns);
  };

  return (
    <ViewConfigurator
      columns={reorderedColumns}
      isOpen={isViewConfigOpen}
      currentView={currentView}
      availableViews={availableViews}
      viewConfigChanged={viewsStore.viewConfigChanged}
      onOpenChanged={setIsViewConfigOpen}
      currentViewChanged={viewsStore.currentViewChanged}
      viewDeleted={viewsStore.viewDeleted}
      viewCreated={viewsStore.createView}
      columnsReordered={columnsReordered}
      columnVisibilityChanged={columnVisibilityChanged}
    />
  );
};
