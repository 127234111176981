import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('tariffsContainer', {
    bindings: {
      initQueryParams: '<?',
      hideExpiredCheckbox: '<?',
      filterLevel: '<?',
    },
    template: require('./tariffs-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'LogisticsService',
    'FinancesService',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'CoreService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    LogisticsService: any,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    CoreService: CoreService,
  ) {
    const vm = this;

    vm.tariffs = [];
    vm.count = 0;
    vm.showExpired = false;
    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;
    vm.updateQueryParams = updateQueryParams;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'tariffs-container';
      vm.newTariff = getNewTariff();
      vm.queryParams = {
        show_expired: false,
        ...vm.initQueryParams,
      };
      if (vm.hideExpiredCheckbox) {
        delete vm.queryParams.show_expired;
      }
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateTariffs();
      });
      updateTariffs();
      $scope.$watch('vm.showExpired', function (newValue: any) {
        vm.queryParams.show_expired = newValue;
        updateQueryParams();
      });
    };

    ////////////////

    function getNewTariff() {
      return {
        business_unit: Object.assign([], CoreService.getDefaultBuList()).shift(),
        ...vm.queryParams,
      };
    }

    function saveTariff(tariff: any) {
      if (tariff.id) {
        return LogisticsService.LogisticTariff.update(tariff, updateTariffs, (e: any) =>
          GtUtils.errorClb(e),
        ).$promise;
      }
      return LogisticsService.LogisticTariff.save(tariff, updateTariffs, (e: any) =>
        GtUtils.errorClb(e),
      ).$promise;
    }

    function deleteTariff(tariff: any) {
      if (!confirm(gettext('Are you sure that you want delete Tariff?'))) {
        return;
      }
      return LogisticsService.LogisticTariff.delete(tariff, updateTariffs, (e: any) =>
        GtUtils.errorClb(e),
      ).$promise;
    }

    function updateQueryParams() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateTariffs() {
      GtUtils.overlay('show');
      vm.newTariff = getNewTariff();
      return LogisticsService.LogisticTariff.query(vm.queryParams, function (data: any) {
        vm.tariffs = data.results;
        setFromAndToName();
        FinancesService.Charge.query(
          { tariff_type: vm.newTariff.transportation_type },
          function (data: any) {
            if (data.count > 0) {
              vm.newTariff.charge = data.results[0].id;
            }
          },
        );
        if (vm.newTariff) {
          vm.tariffs.unshift(vm.newTariff);
        }
        vm.count = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function setFromAndToName() {
      vm.tariffs.forEach((tariff: any) => {
        if (tariff.farm || tariff.elevator) {
          tariff.from_name = tariff.farm_name || tariff.elevator_name;
        }
        if (tariff.terminal || tariff.port || tariff.elevator) {
          tariff.to_name = tariff.terminal_name || tariff.port_name || tariff.elevator_name;
        }
      });
    }
  }
})();
