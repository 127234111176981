import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';
export class PassportConnectionShipmentPlanService {
  $filter: ng.IFilterService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  PassportConnectionShipmentPlanResource: any;
  gettext: ng.gettext.gettextFunction;
  moment: any;
  constructor(
    gettext: ng.gettext.gettextFunction,
    $filter: ng.IFilterService,
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    moment: any,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    this.gettext = gettext;
    this.$filter = $filter;
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.moment = moment;
    this.PassportConnectionShipmentPlanResource = $resource(
      '/api/logistics/passport-connection-shipment-plan/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/passport-connection-shipment-plan/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/passport-connection-shipment-plan-list/totals/',
        },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        saveDays: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/passport-connection-shipment-plan/save_days/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/passport-connection-shipment-plan/bulk_create_or_update/',
        },
      },
    );
  }

  modal(plan: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <passport-connection-shipment-plan-modal plan="plan" modal-instance="$uibModalInstance">
        </passport-connection-shipment-plan-modal>`,
      controller: [
        '$scope',
        'plan',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          plan: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).plan = plan;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        plan: () => plan,
      },
    }).result;
  }

  save(item: any) {
    if (item.id) {
      return this.PassportConnectionShipmentPlanResource.update(item).$promise;
    }
    return this.PassportConnectionShipmentPlanResource.save(item).$promise;
  }

  getTableGroupedData(params: any) {
    return this.PassportConnectionShipmentPlanResource.totals(params).$promise;
  }

  getModalData(params: any) {
    return this.PassportConnectionShipmentPlanResource.get(params).$promise;
  }

  delete(id: any) {
    return this.PassportConnectionShipmentPlanResource.delete({ id: id }).$promise;
  }
}
PassportConnectionShipmentPlanService.$inject = [
  'gettext',
  '$filter',
  '$resource',
  '$q',
  'moment',
  '$uibModal',
];
