import type ng from 'angular';
import Gantt from 'frappe-gantt';

import type { GtRootScopeService } from '../../types';

export const GtTimelineChart = {
  bindings: {
    timelines: '<',
    updateDates: '&',
    filterLevel: '<?',
  },
  template: require('./gt-timeline-chart.html?raw'),
  controller: [
    '$rootScope',
    '$filter',
    '$timeout',
    class {
      $filter: ng.IFilterService;
      $rootScope: GtRootScopeService;
      $timeout: ng.ITimeoutService;
      timeline: any;
      timelines: any;
      updateDates: any;
      filterLevel?: string;
      constructor(
        $rootScope: GtRootScopeService,
        $filter: ng.IFilterService,
        $timeout: ng.ITimeoutService,
      ) {
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.$timeout = $timeout;

        this.timeline = null;
      }

      $onChanges(changes: any) {
        if (changes.timelines) {
          this.$timeout(() => this.updateTimelineConfig());
        }
      }
      getTimelineClass() {
        return this.filterLevel ? 'timeline-' + this.filterLevel : 'timeline';
      }

      updateTimelineConfig() {
        const currentLang: any = ['en', 'es', 'ru', 'ptBr', 'fr', 'tr', 'zh'].includes(
          this.$rootScope.user.profile.language,
        )
          ? this.$rootScope.user.profile.language
          : 'en';
        this.timeline = new Gantt(
          '.' + this.getTimelineClass(),
          this.timelines.map((item: any) => {
            const startDate = typeof item.start === 'string' ? new Date(item.start) : item.start;
            const endDate = typeof item.end === 'string' ? new Date(item.end) : item.end;

            if (
              startDate.valueOf() === endDate.valueOf() &&
              startDate.getHours() === 0 &&
              endDate.getHours() === 0
            ) {
              item.start = new Date(startDate.setHours(9));
              item.end = new Date(endDate.setHours(22));
            }
            if (endDate.getHours() === 0) {
              item.end = new Date(endDate.setHours(23, 59));
            }

            return {
              ...item,
              progress: 100,
            };
          }),
          {
            header_height: 50,
            column_width: 30,
            step: 24,
            bar_height: 20,
            bar_corner_radius: 3,
            arrow_curve: 5,
            padding: 14,
            view_mode: 'Month',
            language: currentLang,
            custom_popup_html: (item: any) => {
              return ` <div style="padding: 8px; width: 220px;"
              class="details-container">
              <span><i class="fa fa-calendar-o"></i>
              ${this.$filter('date')(item._start, 'mediumDate')} -
              ${this.$filter('date')(item._end, 'mediumDate')}
              </span><br />
              ${item.popupHtml} `;
            },
          },
        );
        this.timeline.change_view_mode();
      }

      saveData() {
        this.updateDates({
          data: this.timelines
            .filter((item: any) => {
              return (
                item.start.valueOf() != item._start.valueOf() ||
                item.end.valueOf() != item._end.valueOf()
              );
            })
            .map((item: any) => ({
              id: item.id,
              start: item._start,
              end: item._end,
            })),
        });
      }
    },
  ],
};
