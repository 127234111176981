import ng from 'angular';

import { VehiclesContainer } from './vehicles-container/vehicles-container.component';
import { VehiclesListTableView } from './vehicles-list-table-view/vehicles-list-table-view.component';
import { VehicleModal } from './vehicles-modal/vehicles-modal.component';
import { VehiclesPageView } from './vehicles-page-view/vehicles-page-view.component';
import { VehiclesService } from './vehicles.service';

const vehiclesConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('vehicles', {
        parent: 'gt.page',
        url: '/vehicles',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('vehicles.list', {
        url: '/vehicles',
        component: 'vehiclesPageView',
        data: {
          pageTitle: gettext('Vehicles'),
        },
      });
  },
];

export const vehiclesModule = ng
  .module('vehicles.list', [])
  .component('vehiclesPageView', VehiclesPageView)
  .component('vehiclesContainer', VehiclesContainer)
  .component('vehiclesListTableView', VehiclesListTableView)
  .component('vehicleModal', VehicleModal)
  .config(vehiclesConfig)
  .service('VehiclesService', VehiclesService).name;
