import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').directive('positionsPlansEditRow', directive);

  function directive() {
    return {
      template: require('./positions-plans-edit-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        plan: '<',
        onUpdateTotal: '&?',
      },
    };
  }

  Controller.$inject = ['$rootScope', 'gettext'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.volumeChanged = volumeChanged;
    vm.$onInit = function () {
      vm.initVolume = vm.plan.volume;
    };

    function volumeChanged() {
      vm.onUpdateTotal();
      vm.plan._dirty = true;
      vm.plan._error = undefined;
      if (!$rootScope.user.settings.PASSPORT_CHECK_VOLUME) {
        return true;
      }

      const newVolume = vm.plan.volume;
      const additionalVolume = newVolume - vm.initVolume;
      const availableVolume =
        vm.plan.request_available_to_connect ||
        parseFloat(vm.plan.request_volume) -
          parseFloat(vm.plan.request_total_connected) +
          vm.initVolume;

      if (
        ($rootScope.user.settings.PASSPORT_CHECK_VOLUME &&
          vm.plan.volume &&
          (additionalVolume > availableVolume || vm.plan.volume < 0)) ||
        isNaN(vm.plan.volume)
      ) {
        vm.plan._error = gettext('Check volume');
      }
    }
  }
})();
