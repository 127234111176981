import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import { getModalRoot } from '~/shared/ui/modal';

import bankAccountPageTemplate from './bank-account-page/bank-account-page.tpl.html?raw';
import financeModalTemplate from './finance-modal/finance-modal.tpl.html?raw';

(function () {
  'use strict';
  ng.module('finances.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('finances', {
        parent: 'gt.page',
        url: '/finances',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('finances.invoices', {
        url: '/invoices',
        abstract: true,
        template: '<ui-view/>',
        data: {
          pageTitle: gettext('Payments'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.invoices.timeline', {
        url: '/timeline',
        component: 'financesTimelinePageView',
      })
      .state('finances.payments', {
        url: '/payments',
        abstract: true,
        template: '<ui-view/>',
        data: {
          pageTitle: gettext('Payments'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.payments.list', {
        url: '/list',
        resolve: {
          filterLevel: () => {
            return 'payments-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('payments-page-view');
            },
          ],
        },
        component: 'paymentsPageView',
      })
      .state('gt.page.payments.add', {
        url: '/add',
        data: {
          permissions: {
            only: 'view_finance',
          },
        },
        onEnter: [
          '$uibModal',
          '$state',
          function ($uibModal: ng.ui.bootstrap.IModalService, $state: ng.ui.IStateService) {
            $uibModal
              .open({
                template: financeModalTemplate,
                controller: 'FinancesFinanceModalController as vm',
                windowClass: 'modal-template',
                appendTo: getModalRoot(),
                resolve: {
                  finance: () => {
                    return {};
                  },
                },
              })
              .result.finally(function () {
                $state.go('^');
              });
          },
        ],
      })
      .state('finances.bankAccount', {
        url: '/bank-account',
        abstract: true,
        template: '<ui-view/>',
        data: {
          pageTitle: gettext('Bank Accounts'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.bankAccount.list', {
        url: '/list',
        component: 'bankAccountsPageView',
        data: {
          pageTitle: gettext('Bank Accounts'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.bankOperation', {
        url: '/bank-operation',
        abstract: true,
        template: '<ui-view/>',
        data: {
          pageTitle: gettext('Bank Operations'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.bankOperation.list', {
        url: '/list',
        component: 'bankOperationsPageView',
        data: {
          pageTitle: gettext('Bank Operations'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.creditNote', {
        url: '/credit-note',
        abstract: true,
        template: '<ui-view/>',
        data: {
          pageTitle: gettext('Bank Operations'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.creditNote.list', {
        url: '/list',
        component: 'creditNotesPageView',
        data: {
          pageTitle: gettext('Credit Notes'),
          permissions: {
            only: 'view_finance',
          },
        },
      })
      .state('finances.paymentplans', {
        url: '/paymentplans/?id',
        component: 'paymentplansPageView',
        data: {
          pageTitle: gettext('Payment Plans'),
        },
      })
      .state('finances.transactions', {
        url: '/transactions',
        component: 'transactionsPageView',
        data: {
          pageTitle: gettext('Transactions'),
        },
      })
      .state('finances.generalTransactions', {
        url: '/general-transactions',
        component: 'generalTransactionsPageView',
        data: {
          pageTitle: gettext('General Transactions'),
        },
      })
      .state('finances.balance', {
        url: '/balance',
        component: 'balancePageView',
        data: {
          pageTitle: gettext('Balance'),
        },
      })
      .state('finances.pl', {
        url: '/pl',
        component: 'plPageView',
        data: {
          pageTitle: gettext('Profit and Loss'),
        },
      })
      .state('finances.contractCharges', {
        url: '/contract-charges-list/?id',
        component: 'contractChargesPageView',
        data: {
          pageTitle: gettext('Contract Charges / Gains'),
        },
        resolve: {
          filterLevel: () => 'contract-charges-page-view',
          queryParams: [
            'PageService',
            '$stateParams',
            (PageService: PageService, $stateParams: ng.ui.IStateParamsService) => {
              const params = PageService.syncUrlFilter('contract-charges-page-view');
              if ($stateParams.id) {
                params.id_list = [$stateParams.id];
              }
              return params;
            },
          ],
        },
      })
      .state('finances.accountActivity', {
        url: '/account-activity',
        component: 'financeAccountActivityPageView',
        data: {
          pageTitle: gettext('Account activity'),
        },
      })
      .state('gt.bankAccount', {
        url: '/:id',
        controller: 'BankAccountPageController',
        controllerAs: 'vm',
        template: bankAccountPageTemplate,
        reloadOnSearch: false,
        data: {
          pageTitle: gettext('Bank account'),
          permissions: {
            only: 'view_finance',
          },
        },
      });
  }
})();
