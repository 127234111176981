import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './invoice-risk-report-page-view.html?raw';

export const InvoiceRiskReportPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'GtUtils',
    'ReportsService',
    'gettext',
    'moment',
    class InvoiceRiskReportPageView {
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReportsService: any;
      filterLevel = 'invoice-risk-report-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      moment: any;
      queryParams: QueryParams = {};
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        ReportsService: any,
        gettext: ng.gettext.gettextFunction,
        moment: any,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.ReportsService = ReportsService;
        this.gettext = gettext;
        this.moment = moment;
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = {
          start_date: this.moment().subtract(1, 'years').startOf('year').format('DD.MM.YYYY'),
          end_date: this.moment().endOf('year').format('DD.MM.YYYY'),
          predicate: 'invoice_date',
          position_use_list: ['cargo'],
          group_by: 'vessel_name',
          has_balance: 1,
          currency_list: [1, 3],
        };
        this.filterLevel = this.filterLevel || 'invoice-risk-report-page-view';
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: { filterLevel: this.filterLevel },
        };
      }
    },
  ],
};
