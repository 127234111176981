import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('bankOperationsPageView', {
    bindings: {
      queryParams: '<?',
    },
    template: require('./bank-operations-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['FinancesService', 'PageService'];

  function Controller(this: any, FinancesService: FinancesService, PageService: PageService) {
    const vm = this;
    vm.filterLevel = 'bank-operations-page-view';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-payments-tab',
        buttons: [
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Import('finances.bankoperation'),
          new PageService.buttons.Export('finances.bankoperation', vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: FinancesService.getPagesConfig(),
      };
    }
  }
})();
