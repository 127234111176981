import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('balanceReportContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./balance-report-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$httpParamSerializer',
    'moment',
    'GtUtils',
    'ReportsService',
    'gtFilterService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $httpParamSerializer: any,
    moment: any,
    GtUtils: GtUtilsService,
    ReportsService: any,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.financeAccounts = [];
    vm.openBalanceModal = openBalanceModal;
    vm.updateFinanceAccounts = updateFinanceAccounts;
    vm.applyFilter = applyFilter;

    vm.balanceDate = new Date();
    vm.business_unit_list = [];
    vm.url = undefined;
    vm.bankFinanceAccounts = ['Bank accounts'];
    vm.cropFinanceAccounts = ['Inventory'];
    vm.debitorFinanceAccounts = ['Trade receivables', 'Other short-term receivables'];
    vm.creditorFinanceAccounts = ['Trade payables', 'Other short-term payables'];
    vm.activeAccountsValue = 0;
    vm.passiveAccountsValue = 0;
    vm.currency = 'usd';
    vm.db_convert = dbConvert;
    vm.changeCurrency = changeCurrency;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'balance-report-container';
      vm.queryParams = { ...vm.initQueryParams };
      vm.total = {};
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        vm.url =
          '/api/reports/finance-balance/?' +
          $httpParamSerializer({
            as_xlsx: 1,
            ...vm.queryParams,
          });
        updateFinanceAccounts();
      });
      applyFilter();
    };

    vm.$onChanges = function () {
      vm.queryParams = { ...vm.initQueryParams };
      applyFilter();
    };

    ////////////////

    function applyFilter() {
      vm.queryParams.end_date = moment(vm.balanceDate).format('YYYY-MM-DD');
      vm.queryParams.business_unit_list = vm.business_unit_list;
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateFinanceAccounts() {
      GtUtils.overlay('show');
      const c = ReportsService.FinanceBalance.query(vm.queryParams, function (data: any) {
        vm.financeAccounts = prepareAccounts(data.results);
        vm.total = data.total;
        GtUtils.overlay('hide');
      });
      return GtUtils.addConnection(c).$promise;
    }

    function openBalanceModal(mode: any, financeAccount: any) {
      return ReportsService.balanceModal(
        mode,
        {
          finance_account_id: financeAccount.id,
          start_date: vm.queryParams.start_date,
          end_date: vm.queryParams.end_date,
          business_unit_list: vm.business_unit_list,
        },
        financeAccount.total_value,
      );
    }

    function prepareAccounts(accountsList: any) {
      const newAccountsList: any = [];
      vm.activeAccountsValue = 0;
      vm.passiveAccountsValue = 0;
      vm.activeAccountsValueLocal = 0;
      vm.passiveAccountsValueLocal = 0;
      ng.forEach(accountsList, function (account: any) {
        if (['Assets', 'Liabilities', 'Equity'].includes(account.title)) {
          newAccountsList.push(account);
          prepareAccountBranch(account, newAccountsList, accountsList);
          newAccountsList.push({ subTotal: true, ...account });
          if (account.title == 'Assets') {
            vm.activeAccountsValue = account.total_value || 0;
            vm.activeAccountsValueLocal = account.total_value_local || 0;
          }
          if (account.title == 'Liabilities') {
            vm.passiveAccountsValue += account.total_value || 0;
            vm.passiveAccountsValueLocal += account.total_value_local || 0;
          }
          if (account.title == 'Equity') {
            vm.passiveAccountsValue += account.total_value || 0;
            vm.passiveAccountsValueLocal += account.total_value_local || 0;
          }
        }
      });
      return newAccountsList;
    }

    function prepareAccountBranch(accountRoot: any, newAccountsList: any, oldAccountsList: any) {
      ng.forEach(oldAccountsList, function (account: any) {
        if (account.parent_id == accountRoot.id) {
          newAccountsList.push(account);
          prepareAccountBranch(account, newAccountsList, oldAccountsList);
        }
      });
    }

    function dbConvert(usd: any, local: any) {
      if (vm.currency == 'usd') {
        return usd;
      } else {
        return local;
      }
    }

    function changeCurrency(currency: any) {
      vm.currency = currency;
      vm.currency_symbol = currency === 'usd' ? 'usd' : 'local';
    }
  }
})();
