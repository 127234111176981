import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('contractsPageView', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./contracts-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'ContractFilterConfigService',
    'ContractsService',
    'gtFilterService',
    'PageService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ContractFilterConfigService: any,
    ContractsService: ContractsService,
    gtFilterService: GtFilterService,
    PageService: PageService,
  ) {
    const vm = this;
    vm.quickAdd = false;
    vm.view = 'table';
    vm.groupBy = null;
    vm.tableName = null;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || vm.queryParams.contract_type + '-contracts-page-view';
      vm.tableName =
        vm.queryParams.deal_type == 'services' ||
        vm.queryParams.deal_type == 'intermediate' ||
        vm.queryParams.deal_type == 'export'
          ? vm.queryParams.deal_type + '-contracts-page-view'
          : vm.filterLevel;
      PageService.setConfig(getPageConfig());

      if (vm.queryParams.resetDateFilters === 'yes') {
        delete vm.queryParams.date_predicate;
        delete vm.queryParams.start_date;
        delete vm.queryParams.end_date;
      }

      $scope.$on('group-by', function (ev: any, data: any) {
        vm.queryParams = gtFilterService.getQueryParams(vm.filterLevel);
        delete vm.queryParams.ordering;
        delete vm.queryParams.page_size;
        vm.groupBy = data;
      });
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-prices-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
      };

      const contractPurpose = ContractsService.getContractPurpose(vm.queryParams);

      if (contractPurpose !== 'intermediate') {
        config.buttons.push(
          new PageService.buttons.Import(`contracts.${contractPurpose}contract`),
          new PageService.buttons.Export(`contracts.${contractPurpose}contract`, vm.filterLevel),
        );
      }

      // @ts-ignore
      config.quickAdd = function () {
        vm.quickAdd = !vm.quickAdd;
      };

      // @ts-ignore
      config.pages = ContractsService.getPagesConfig();
      // @ts-ignore
      config.filters = ContractFilterConfigService.getFilterConfig(
        vm.filterLevel,
        vm.queryParams.contract_type,
        vm.queryParams.deal_type,
      );

      return config;
    }
  }
})();
