import type ng from 'angular';

import type { SettingsParsingService } from '~/app/accounts/components/user-settings/settings-parsing/settings-parsing.service';
import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './quotes-parsing-configs-modal.html?raw';

export const QuotesParsingConfigsModal = {
  bindings: {
    quoteParsingConfig: '<?',
    modalInstance: '<',
  },
  template,
  controller: [
    '$state',
    '$q',
    '$window',
    'gettext',
    'SettingsParsingService',
    'FormFieldParamsService',
    'GtUtils',
    class {
      $q: ng.IQService;
      $state: ng.ui.IStateService;
      $window: ng.IWindowService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      SettingsParsingService: SettingsParsingService;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      quoteParsingConfig: any;
      updating: any;
      constructor(
        $state: ng.ui.IStateService,
        $q: ng.IQService,
        $window: ng.IWindowService,
        gettext: ng.gettext.gettextFunction,
        SettingsParsingService: SettingsParsingService,
        FormFieldParamsService: FormFieldParamsService,
        GtUtils: GtUtilsService,
      ) {
        this.$state = $state;
        this.$q = $q;
        this.$window = $window;
        this.gettext = gettext;
        this.SettingsParsingService = SettingsParsingService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.GtUtils = GtUtils;

        this.updating = true;
        this.form = undefined;
        this.fields = undefined;
        this.quoteParsingConfig = {};
        this.modalInstance = undefined;
      }

      $onInit() {
        let promise;
        if (this.quoteParsingConfig?.id) {
          promise = this.updateData();
        } else {
          promise = this.$q.when();
        }

        promise.then(() => {
          this.updateFields();
          this.updating = false;
        });
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      updateData() {
        return this.SettingsParsingService.getQuoteParsingConfigModalData({
          id: this.quoteParsingConfig.id,
        }).then((data: any) => {
          this.quoteParsingConfig = data;
          this.updating = false;
        });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        return this.modalInstance.close(data || 'cancel');
      }

      save(stayAfterSave: any) {
        this.form.$invalid = true;
        this.SettingsParsingService.saveQuoteParsingConfig(this.quoteParsingConfig)
          .then((data: any) => {
            if (stayAfterSave) {
              this.quoteParsingConfig = data;
              this.updateData();
            } else {
              this.close('saved', true);
            }
            this.GtUtils.notify(this.gettext('Quote Parsing Config saved'));
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure you want to delete quote parsing config?'))) {
          return;
        }
        return this.SettingsParsingService.destroyQuotesParsingConfig({
          id: this.quoteParsingConfig.id,
        })
          .then(() => {
            this.close('deleted', true);
            this.$state.go('settings.parsing');
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-md-12 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('GENERAL INFO'),
          },
          fieldGroup: [
            {
              key: 'derivative',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Derivative'),
                placeholder: this.gettext('Derivative'),
                addPerms: ['add_derivative'],
                addFunc: () => {
                  this.$window.open('/admin/stockexchanges/derivative/add/');
                },
                resource: 'stockexchanges.derivative',
                required: true,
              },
            },
            {
              key: 'resource',
              type: 'gt-select',
              defaultValue: 'barchart',
              templateOptions: {
                label: this.gettext('Resource'),
                placeholder: this.gettext('Choose resource'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: this.gettext('Barchart'), value: 'barchart' },
                  { name: this.gettext('Agritel'), value: 'agritel' },
                ],
              },
            },
            {
              key: 'parsing_enabled',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Parse this quote'),
                hint: this.gettext('Check this if you want to parse this quote'),
              },
            },
          ],
        });

        return {
          formName: 'quote-parsing-config-edit-modal',
          fieldsDef: [col1],
        };
      }
    },
  ],
};
