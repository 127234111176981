import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '~/app/deals/multicontract/multicontract.service';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('contractsContractItemFinanceTab', directive);

  function directive() {
    return {
      template: require('./finance-tab.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        contract: '=',
        readonly: '<',
        invoiceFilterLevel: '<?',
        reassignmentFilterLevel: '<?',
        callFrom: '<',
        subtab: '<?',
      },
    };
  }

  Controller.$inject = ['$scope', 'gtFilterService', 'ContractsService', 'MulticontractService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gtFilterService: GtFilterService,
    ContractsService: ContractsService,
    MulticontractService: MulticontractService,
  ) {
    const vm = this;

    vm.addFinance = addFinance;
    vm.updateData = updateData;
    vm.invoicesQueryParams = {};
    vm.reassignmentsQueryParams = {};

    ////////////////

    vm.$onInit = function () {
      vm.callFrom = vm.callFrom || 'position';
      vm.subtab = vm.subtab || 'invoices';
      const paramsKeyPostfixInvoice = { position: '', multicontract: '_multicontract' }[
        vm.callFrom
      ];
      const paramsKeyPostfixReassignment = { position: '', multicontract: '__multicontract' }[
        vm.callFrom
      ];
      vm.invoiceFilterLevel = vm.invoiceFilterLevel || 'invoices-page-view';
      vm.reassignmentFilterLevel = vm.reassignmentFilterLevel || 'reassignment-container';
      ($scope as any).queryParams = ($scope as any).queryParams || {};

      vm.invoicesQueryParams[`finance_contract${paramsKeyPostfixInvoice}`] = vm.contract.id;
      vm.reassignmentsQueryParams[`contract${paramsKeyPostfixReassignment}`] = vm.contract.id;
    };

    function updateData() {
      return ContractsService.refreshContract(vm.contract, true).then(function (data: any) {
        vm.contract = data;
      });
    }

    function addFinance() {
      let contractSrv: ContractsService | MulticontractService = ContractsService;
      let params = [vm.contract];

      if (vm.callFrom === 'multicontract') {
        contractSrv = MulticontractService;
        params = [vm.contract.id];
      }
      return contractSrv.addFinance(params[0]).then(function () {
        updateData();
        gtFilterService.updateQueryParams({}, 'incoming-invoices-list-container');
      });
    }
  }
})();
