import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './warehouse-content-table-view.html?raw';

export const WarehouseContentTableView = {
  bindings: {
    data: '<',
    totals: '<',
  },
  template,
  controller: [
    '$rootScope',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      data: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      totals: any;
      constructor(
        $rootScope: GtRootScopeService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.tableOptions = {};
        this.queryParams = { page_size: 1000000 };
        this.tableData = undefined;
        this.filterLevel = 'warehouse-content-table-view';
      }

      $onInit() {
        this.data = this.data || [];
        this.totals = this.totals || {};
        this.tableOptions = this.getTableOptions();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.data || changes.totals) {
          this.tableData = { rows: this.data, total: this.totals };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'warehouse-content-table-view',
          configurable: true,
          tableClass:
            'table main-table table-responsive table-condensed places-table offers-table clients-table warehouse-table table-hover',
          filterLevel: this.filterLevel,
          templateArgs: {
            $rootScope: this.$rootScope,
          },
          columnDefs: [] as any[],
          tabs: [],
          showMore: false,
        };

        options.columnDefs = [
          {
            columnName: 'date',
            title: this.gettext('date'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
                <span>{[{ item.date || "---" | date:"dd.MM.yyyy" }]}</span> `,
            totalTemplate: /*html*/ `
                <i class="fa fa-pie-chart"></i> <translate>TOTAL</translate> `,
          },
          {
            columnName: 'object',
            title: this.gettext('object'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
                <div ng-repeat="consignment in item.consignment_data">
                   <span>
                      <i class="fa" ng-class="{
                          'fa-truck': consignment.object_type === 'logistic',
                          'fa-file': consignment.object_type !== 'logistic'
                        }"></i>
                      {[{ consignment.title }]}
                      <span class="label label_default">
                        <translate>{[{ consignment.object_subtype }]}</translate>
                        <translate>{[{ consignment.object_type }]}</translate>
                      </span>
                   </span>
                </div> `,
          },
          {
            columnName: 'purchase_contract_number',
            title: this.gettext('purchase contract number'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="object in item.consignment_data">
                <span ng-if="object.purchase_contract_contract_number">
                  <a ui-sref="gt.page.contract({id: object.purchase_contract_id })" target="_blank">
                    <i class="fa fa-file-text"></i>
                    {[{object.purchase_contract_contract_number | cut:true:50:' ...'}]}
                  </a>
                  <span ng-if="object.purchase_contract_supplier_name"><i class="fa fa-bookmark-o"></i> {[{ object.purchase_contract_supplier_name }]}</span>
                </span>
                <span ng-if="!object.purchase_contract_contract_number">---</span>
              </div>
              `,
          },
          {
            columnName: 'purchase_contract_responsible_full_name',
            title: this.gettext('purchase contract responsible'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <div
                ng-repeat="object in item.consignment_data"
              >
                <span ng-if="object.purchase_contract_responsible_full_name">
                <i class="fa fa-user"></i> {[{ object.purchase_contract_responsible_full_name }]}
                </span>
                <span ng-if="!object.purchase_contract_responsible_full_name">---</span>
              </div>
              `,
          },
          {
            columnName: 'sale_contract_number',
            title: this.gettext('sale contract number'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="object in item.consignment_data">
                <span ng-if="object.sale_contract_contract_number">
                  <a ui-sref="gt.page.contract({id: object.sale_contract_id })" target="_blank">
                    <i class="fa fa-file-text"></i>
                    {[{object.sale_contract_contract_number | cut:true:50:' ...'}]}
                  </a> <span ng-if="object.sale_contract_buyer_name"><i class="fa fa-bookmark"></i> {[{ object.sale_contract_buyer_name }]}</span>
                </span>
                <span ng-if="!object.sale_contract_contract_number">---</span>
              </div>
              `,
          },
          {
            columnName: 'billoflading_number',
            title: this.gettext('billoflading number'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="logistic in item.consignment_data">
                <div ng-repeat="billoflading_number in logistic.billofladings_number_list">
                <i class="fa fa-drivers-license"></i> {[{ billoflading_number }]}
                </div>
              </div>
              `,
          },
          {
            columnName: 'volume',
            title: this.gettext('Volume'),
            classExpr: "{ 'alert-danger': item.coef < 0, 'alert-success': item.coef > 0 }",
            cellTemplate: /*html*/ `
                <div ng-repeat="consignment in item.consignment_data">
                  <span class="bigger-label negative-number span-right" ng-if="consignment.coef < 0">
                    {[{ consignment.warehouse_volume || 0 | number:3 }]}
                    <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                  </span>
                  <span class="bigger-label positive-number  span-left" ng-if="consignment.coef > 0">
                    {[{ consignment.warehouse_volume || 0 | number:3 }]}
                    <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                  </span>
                </div> `,
            totalTemplate: /*html*/ `
                <span>
                  {[{ item.volume_sum || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                </span> `,
          },
          {
            columnName: 'residual_volume',
            title: this.gettext('Residual volume'),
            classExpr: "{ 'alert-danger': item.volume < 0, 'alert-success': item.volume > 0 }",
            cellTemplate: /*html*/ `
                <span   class="bigger-label negative-number span-right" ng-if="item.volume < 0">
                  {[{ item.volume || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                </span>
                <span class="bigger-label positive-number  span-left" ng-if="item.volume > 0">
                  {[{ item.volume || 0 | number:3 }]}
                  <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
                </span>  `,
          },
          {
            columnName: 'bu',
            title: this.gettext('Business Unit'),
            cellTemplate: /*html*/ `
                <div ng-repeat="consignment in item.consignment_data">
                  <span ng-if="consignment.bu_title"><i class="fa fa-home"></i> {[{ consignment.bu_title || consignment.consignment_bu_title}]}</span>
                </div> `,
          },
        ];

        return options;
      }
    },
  ],
};
