import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/deals/tickets/sale/$ticketId/potential-purchase',
)({
  component: () => (
    <div>Hello /_auth/_sidebar/deals/tickets/_sale-ticket/$ticketId/potential-purchase!</div>
  ),
});
