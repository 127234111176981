import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';

import template from './certificate-dates-report-page.html?raw';

export const CertificateDatesReportPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    'gtFilterService',
    class {
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(PageService: PageService, ReportsService: any, gtFilterService: GtFilterService) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gtFilterService = gtFilterService;

        this.filterLevel = 'certificate-dates-page-view';
        this.queryParams = { page_size: 100 };
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.ReportsService.getPagesConfig(),
        };
      }
    },
  ],
};
