import { container } from '~/shared/lib/di';
import { useObservableEagerState } from '~/shared/lib/state';

import { type Theme, type ThemeSection } from '../lib';
import { ThemeStore } from '../services';

export const useTheme = () => {
  const themeStore = container.resolve(ThemeStore);
  const theme = useObservableEagerState(themeStore.theme$);

  return {
    theme,
    setTheme: (theme: Theme) => themeStore.setTheme(theme),
    setSection: (section: ThemeSection) => themeStore.setSection(section),
  };
};
