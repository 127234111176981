import React from 'react';

import { ResetPasswordCard } from '~/features/reset-password/components';
import { useResetPassword } from '~/features/reset-password/hooks';

export const ResetPasswordPage: React.FC = () => {
  const { resetPasswordService, isSend, loading } = useResetPassword();

  return (
    <section className="flex h-screen items-center justify-center bg-pexels bg-cover bg-center bg-no-repeat">
      <ResetPasswordCard
        isSend={isSend}
        loading={loading}
        onPasswordReset={(creds) => resetPasswordService.passwordReset(creds)}
      />
    </section>
  );
};
