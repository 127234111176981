import {
  BuildingIcon,
  CircleDollarIcon,
  HeartHandshakeIcon,
  HouseIcon,
  PieChartIcon,
  ReportsIcon,
  TruckIcon,
} from '~/shared/ui/icons/';

import { type SidebarMenuItem } from './types';

export const getMenuList = (pathname: string): SidebarMenuItem[] => {
  return [
    {
      id: 'start',
      groupLabel: 'Start',
      icon: HouseIcon,
      isActive: pathname === '/',
      href: '/',
      menus: [],
    },
    {
      id: 'company',
      groupLabel: 'Company',
      icon: BuildingIcon,
      isActive: pathname.includes('/company'),
      href: '/company/settings',
      menus: [
        {
          id: 'company-settings',
          href: '/company/settings',
          label: 'Settings',
          isActive: pathname.includes('/company/settings'),
          submenus: [
            {
              id: 'company-settings-lists',
              href: '/company/settings/list',
              label: 'Lists',
              isActive: pathname.includes('/company/settings'),
              details: [
                {
                  id: 'company-settings-list-users',
                  href: '/company/settings/users',
                  label: 'Users',
                  isActive: pathname.includes('/company/settings/users'),
                },
                {
                  id: 'company-settings-list-groups',
                  href: '/company/settings/groups',
                  label: 'Groups',
                  isActive: pathname.includes('/company/settings/groups'),
                },
                {
                  id: 'company-settings-list-commodity',
                  href: '/company/settings/commodities',
                  label: 'Commodity',
                  isActive: pathname.includes('/company/settings/commodities'),
                },
                {
                  id: 'company-settings-list-quality',
                  href: '/company/settings/quality',
                  label: 'Quality',
                  isActive: pathname.includes('/company/settings/quality'),
                },
                {
                  id: 'company-settings-list-newsletter',
                  href: '/company/settings/newsletter',
                  label: 'Newsletter',
                  isActive: pathname.includes('/company/settings/newsletter'),
                },
                {
                  id: 'company-settings-list-documents',
                  href: '/company/settings/documents',
                  label: 'Documents',
                  isActive: pathname.includes('/company/settings/documents'),
                },
                {
                  id: 'company-settings-list-finance-accounts',
                  href: '/company/settings/finance-accounts',
                  label: 'Finance accounts',
                  isActive: pathname.includes('/company/settings/finance-accounts'),
                },
                {
                  id: 'company-settings-list-contract-charges',
                  href: '/company/settings/contract-charges',
                  label: 'Contract charges',
                  isActive: pathname.includes('/company/settings/contract-charges'),
                },
                {
                  id: 'company-settings-list-general-expenses',
                  href: '/company/settings/general-expenses',
                  label: 'General expenses',
                  isActive: pathname.includes('/company/settings/general-expenses'),
                },
                {
                  id: 'company-settings-list-other-activities',
                  href: '/company/settings/other-activities',
                  label: 'Other activities',
                  isActive: pathname.includes('/company/settings/other-activities'),
                },
                {
                  id: 'company-settings-list-currencies',
                  href: '/company/settings/currencies',
                  label: 'Currencies',
                  isActive: pathname.includes('/company/settings/currencies'),
                },
                {
                  id: 'company-settings-list-exchanges',
                  href: '/company/settings/exchanges',
                  label: 'Exchanges',
                  isActive: pathname.includes('/company/settings/exchanges'),
                },
                {
                  id: 'company-settings-list-finance-bank',
                  href: '/company/settings/finance-bank',
                  label: 'Finance bank',
                  isActive: pathname.includes('/company/settings/finance-bank'),
                },
                {
                  id: 'company-settings-list-payment-info',
                  href: '/company/settings/payment-info',
                  label: 'Payment info',
                  isActive: pathname.includes('/company/settings/payment-info'),
                },
                {
                  id: 'company-settings-list-stock-exchange',
                  href: '/company/settings/stock-exchange',
                  label: 'Stock exchange',
                  isActive: pathname.includes('/company/settings/stock-exchange'),
                },
                {
                  id: 'company-settings-list-derivative-accounts',
                  href: '/company/settings/derivative-accounts',
                  label: 'Derivative accounts',
                  isActive: pathname.includes('/company/settings/derivative-accounts'),
                },
                {
                  id: 'company-settings-list-derivatives',
                  href: '/company/settings/derivatives',
                  label: 'Derivatives',
                  isActive: pathname.includes('/company/settings/derivatives'),
                },
                {
                  id: 'company-settings-list-delivery-conditions',
                  href: '/company/settings/delivery-conditions',
                  label: 'Delivery conditions',
                  isActive: pathname.includes('/company/settings/delivery-conditions'),
                },
                {
                  id: 'company-settings-list-ports',
                  href: '/company/settings/ports',
                  label: 'Ports',
                  isActive: pathname.includes('/company/settings/ports'),
                },
                {
                  id: 'company-settings-list-terminals',
                  href: '/company/settings/terminals',
                  label: 'Terminals',
                  isActive: pathname.includes('/company/settings/terminals'),
                },
                {
                  id: 'company-settings-list-stations',
                  href: '/company/settings/stations',
                  label: 'Stations',
                  isActive: pathname.includes('/company/settings/stations'),
                },
                {
                  id: 'company-settings-list-logistic-types',
                  href: '/company/settings/logistic-types',
                  label: 'Logistic types',
                  isActive: pathname.includes('/company/settings/logistic-types'),
                },
                {
                  id: 'company-settings-list-vessel-sizes',
                  href: '/company/settings/vessel-sizes',
                  label: 'Vessel sizes',
                  isActive: pathname.includes('/company/settings/vessel-sizes'),
                },
                {
                  id: 'company-settings-list-vehicle-subtypes',
                  href: '/company/settings/vehicle-subtypes',
                  label: 'Vehicle subtypes',
                  isActive: pathname.includes('/company/settings/vehicle-subtypes'),
                },
                {
                  id: 'company-settings-list-vehicles',
                  href: '/company/settings/vehicles',
                  label: 'Vehicles',
                  isActive: pathname.includes('/company/settings/vehicles'),
                },
                {
                  id: 'company-settings-list-drivers',
                  href: '/company/settings/drivers',
                  label: 'Drivers',
                  isActive: pathname.includes('/company/settings/drivers'),
                },
                {
                  id: 'company-settings-list-countries',
                  href: '/company/settings/countries',
                  label: 'Countries',
                  isActive: pathname.includes('/company/settings/countries'),
                },
                {
                  id: 'company-settings-list-regions',
                  href: '/company/settings/regions',
                  label: 'Regions',
                  isActive: pathname.includes('/company/settings/regions'),
                },
                {
                  id: 'company-settings-list-districts',
                  href: '/company/settings/districts',
                  label: 'Districts',
                  isActive: pathname.includes('/company/settings/districts'),
                },
                {
                  id: 'company-settings-list-cities',
                  href: '/company/settings/cities',
                  label: 'Cities',
                  isActive: pathname.includes('/company/settings/cities'),
                },
                {
                  id: 'company-settings-list-addresses',
                  href: '/company/settings/addresses',
                  label: 'Addresses',
                  isActive: pathname.includes('/company/settings/addresses'),
                },
                {
                  id: 'company-settings-list-configs',
                  href: '/company/settings/configs',
                  label: 'Configs',
                  isActive: pathname.includes('/company/settings/configs'),
                },
                {
                  id: 'company-settings-list-client-chats',
                  href: '/company/settings/client-chats',
                  label: 'Client chats',
                  isActive: pathname.includes('/company/settings/client-chats'),
                },
                {
                  id: 'company-settings-list-bots',
                  href: '/company/settings/bots',
                  label: 'Bots',
                  isActive: pathname.includes('/company/settings/bots'),
                },
                {
                  id: 'company-settings-list-chats',
                  href: '/company/settings/chats',
                  label: 'Chats',
                  isActive: pathname.includes('/company/settings/chats'),
                },
                {
                  id: 'company-settings-list-company-groups',
                  href: '/company/settings/company-groups',
                  label: 'Company groups',
                  isActive: pathname.includes('/company/settings/company-groups'),
                },
                {
                  id: 'company-settings-list-responsibilities',
                  href: '/company/settings/responsibilities',
                  label: 'Responsibilities',
                  isActive: pathname.includes('/company/settings/responsibilities'),
                },
              ],
            },
            {
              id: 'company-settings-organization',
              href: '/company/settings/organization',
              label: 'Organization',
              isActive: pathname.includes('/company/settings/organization'),
            },
            {
              id: 'company-settings-parsing',
              href: '/company/settings/parsing',
              label: 'Parsing',
              isActive: pathname.includes('/company/settings/parsing'),
            },
            {
              id: 'company-settings-personal',
              href: '/company/settings/personal',
              isActive: pathname.includes('/company/settings/personal'),
              label: 'Personal',
            },
          ],
        },
        {
          id: 'company-teams',
          href: '/company/team',
          label: 'Team',
          isActive: pathname.includes('/company/team'),
        },
        {
          id: 'company-documents',
          href: '/company/documents',
          label: 'Documents',
          isActive: pathname.includes('/company/documents'),
        },
        {
          id: 'company-notifications',
          href: '/company/notifications',
          label: 'Notifications',
          isActive: pathname.includes('/company/notifications'),
        },
        {
          id: 'company-drivers',
          href: '/company/drivers',
          label: 'Drivers',
          isActive: pathname.includes('/company/drivers'),
        },
        {
          id: 'company-vehicles',
          href: '/company/vehicles',
          label: 'Vehicles',
          isActive: pathname.includes('/company/vehicles'),
        },
        {
          id: 'company-templatePlayground',
          href: '/company/template-playground',
          label: 'Template Playground',
          isActive: pathname.includes('/company/template-playground'),
        },
        {
          id: 'company-qualityRoleTemplates',
          href: '/company/quality-rule-templates',
          label: 'Quality Role Templates',
          isActive: pathname.includes('/company/quality-rule-templates'),
        },
        {
          id: 'company-tasksTemplates',
          href: '/company/tasks-templates',
          label: 'Tasks Templates',
          isActive: pathname.includes('/company/tasks-templates'),
        },
        {
          id: 'company-customFields',
          href: '/company/custom-fields',
          label: 'Custom Fields',
          isActive: pathname.includes('/company/custom-fields'),
        },
      ],
    },
    {
      id: 'crm',
      groupLabel: 'CRM',
      isActive: pathname.includes('/crm/'),
      icon: PieChartIcon,
      href: '/crm/counterparties',
      menus: [
        {
          id: 'crm-counterparties',
          href: '/crm/counterparties',
          label: 'Counterparties',
          isActive: pathname.includes('/crm/counterparties'),
        },
        {
          id: 'crm-contacts',
          isActive: pathname.includes('/crm/contacts'),
          href: '/crm/contacts',
          label: 'Deals',
        },
        {
          id: 'crm-updates',
          isActive: pathname.includes('/crm/updates'),
          href: '/crm/updates',
          label: 'Updates',
        },
        {
          id: 'crm-newsletters',
          isActive: pathname.includes('/crm/newsletters'),
          href: '/crm/newsletters',
          label: 'Newsletters',
          submenus: [
            {
              id: 'crm-newsletters-templates',
              href: '/crm/newsletters/templates',
              label: 'Templates',
              isActive: pathname.includes('/crm/newsletters/templates'),
            },
            {
              id: 'crm-newsletters-logistics',
              href: '/crm/newsletters/logistics',
              label: 'Logistics',
              isActive: pathname.includes('/crm/newsletters/logistics'),
            },
            {
              id: 'crm-newsletters-invoices',
              href: '/crm/newsletters/invoices',
              label: 'Invoices',
              isActive: pathname.includes('/crm/newsletters/invoices'),
            },
          ],
        },
      ],
    },
    {
      id: 'deals',
      groupLabel: 'Deals',
      isActive: pathname.includes('/deals'),
      icon: HeartHandshakeIcon,
      href: '/deals/potentials',
      menus: [
        {
          id: 'deals-potentials',
          isActive: pathname.includes('/deals/potentials'),
          href: '/deals/potentials',
          label: 'Potentials',
        },
        {
          id: 'deals-market',
          isActive: pathname.includes('/deals/market'),
          href: '/deals/market',
          label: 'Market',
        },
        {
          id: 'deals-quotations',
          isActive: pathname.includes('/deals/quotations'),
          href: '/deals/quotations',
          label: 'Quotations',
        },
        {
          id: 'deals-calculator',
          isActive: pathname.includes('/deals/calculator'),
          href: '/deals/calculator',
          label: 'Calculator',
        },
        {
          id: 'deals-tickets',
          isActive: pathname.includes('/deals/tickets'),
          href: '/deals/tickets',
          label: 'Tickets',
        },
        {
          id: 'deals-multitickets',
          isActive: pathname.includes('/deals/multitickets'),
          href: '/deals/multitickets',
          label: 'Multitickets',
        },
        {
          id: 'deals-agreements',
          isActive: pathname.includes('/deals/agreements'),
          href: '/deals/agreements',
          label: 'Agreements',
        },
        {
          id: 'deals-services',
          isActive: pathname.includes('/deals/services'),
          href: '/deals/services',
          label: 'Services',
        },
        {
          id: 'deals-contracts',
          isActive: pathname.includes('/deals/contracts'),
          href: '/deals/contracts',
          label: 'Contracts',
        },
        {
          id: 'deals-passports',
          isActive: pathname.includes('/deals/passports'),
          href: '/deals/passports',
          label: 'Passports',
        },
        {
          id: 'deals-multicontracts',
          isActive: pathname.includes('/deals/multicontracts'),
          href: '/deals/multicontracts',
          label: 'Multicontracts',
        },
      ],
    },
    {
      id: 'execution',
      groupLabel: 'Execution',
      isActive: pathname.includes('/execution'),
      icon: TruckIcon,
      href: '/execution/tariffs',
      menus: [
        {
          id: 'execution-tariffs',
          href: '/execution/tariffs',
          isActive: pathname.includes('/execution/tariffs'),
          label: 'Tariffs',
        },
        {
          id: 'execution-samples',
          href: '/execution/samples',
          isActive: pathname.includes('/execution/samples'),
          label: 'Samples',
        },
        {
          id: 'execution-customs-declarations',
          href: '/execution/customs-declarations',
          isActive: pathname.includes('/execution/customs-declarations'),
          label: 'Customs declarations',
        },
        {
          id: 'execution-warehouse',
          href: '/execution/warehouse',
          isActive: pathname.includes('/execution/warehouse'),
          label: 'Warehouse',
        },
        {
          id: 'execution-consignments',
          href: '/execution/consignments',
          isActive: pathname.includes('/execution/consignments'),
          label: 'Consignments',
        },
        {
          id: 'execution-disbursement-bill-of-lading',
          href: '/execution/disbursement-bill-of-lading',
          isActive: pathname.includes('/execution/disbursement-bill-of-lading'),
          label: 'Disbursement bill of lading',
        },
        {
          id: 'execution-planning',
          href: '/execution/planning',
          isActive: pathname.includes('/execution/planning'),
          label: 'Planning',
        },
        {
          id: 'execution-transport',
          href: '/execution/transport',
          isActive: pathname.includes('/execution/transport'),
          label: 'Transport',
        },
        {
          id: 'execution-bills-of-lading',
          href: '/execution/bills-of-lading',
          isActive: pathname.includes('/execution/bills-of-lading'),
          label: 'Bills of lading',
        },
        {
          id: 'execution-vessels',
          href: '/execution/vessels',
          isActive: pathname.includes('/execution/vessels'),
          label: 'Vessels',
        },
        {
          id: 'execution-analytics',
          href: '/execution/analytics',
          isActive: pathname.includes('/execution/analytics'),
          label: 'Analytics',
        },
      ],
    },
    {
      id: 'finance',
      groupLabel: 'Finance',
      isActive: pathname.includes('/finance'),
      icon: CircleDollarIcon,
      href: '/finance/invoices',
      menus: [
        {
          id: 'finance-invoices',
          isActive: pathname.includes('/finance/invoices'),
          href: '/finance/invoices',
          label: 'Invoices',
        },
        {
          id: 'finance-payments-delays',
          isActive: pathname.includes('/finance/payment-delays'),
          href: '/finance/payment-delays',
          label: 'Payments Delays',
        },
        {
          id: 'finance-payments-plans',
          isActive: pathname.includes('/finance/payment-plans'),
          href: '/finance/payment-plans/',
          label: 'Payments Plans',
        },
        {
          id: 'finance-charges-or-gains',
          isActive: pathname.includes('/finance/charges-or-gains'),
          href: '/finance/charges-or-gains',
          label: 'Charges/Gains',
        },
        {
          id: 'finance-payments',
          isActive: pathname.includes('/finance/payments'),
          href: '/finance/payments/',
          label: 'Payments',
        },
        {
          id: 'finance-bank-accounts',
          isActive: pathname.includes('/finance/bank-accounts'),
          href: '/finance/bank-accounts',
          label: 'Bank Accounts',
        },
        {
          id: 'finance-invoice-positions',
          isActive: pathname.includes('/finance/invoice-positions'),
          href: '/finance/invoice-positions',
          label: 'Invoice Positions',
        },
        {
          id: 'finance-accounting',
          isActive: pathname.includes('/finance/accounting'),
          href: '/finance/accounting',
          label: 'Accounting',
        },
        {
          id: 'finance-reasignment',
          isActive: pathname.includes('/finance/reasignment'),
          href: '/finance/reasignment',
          label: 'Reasignment',
        },
        {
          id: 'finance-offset',
          isActive: pathname.includes('/finance/offset'),
          href: '/finance/offset',
          label: 'Offset',
        },
      ],
    },
    {
      id: 'reports',
      groupLabel: 'Reports',
      isActive: pathname.includes('/reports'),
      icon: ReportsIcon,
      href: '/reports/dashboard',
      menus: [
        {
          id: 'reports-dashboard',
          href: '/reports/dashboard',
          isActive: pathname.includes('/reports/dashboard'),
          label: 'Dashboard',
        },
        {
          id: 'reports-trading-reports',
          href: '/reports/trading-reports',
          isActive: pathname.includes('/reports/trading-reports'),
          label: 'Trading Reports',
          submenus: [
            {
              id: 'reports-trading-reports-position',
              href: '/reports/trading-reports/position',
              label: 'Position',
              isActive: pathname.includes('/reports/trading-reports/position'),
              details: [
                {
                  id: 'reports-trading-reports-position-pl-and-mtm',
                  href: '/reports/trading-reports/position-pl-and-mtm',
                  label: 'Position P&L + MTM',
                  isActive: pathname.includes('/reports/trading-reports/position-pl-and-mtm'),
                },
                {
                  id: 'reports-trading-reports-physical-position',
                  href: '/reports/trading-reports/physical-position-by-months',
                  label: 'Physical position by months',
                  isActive: pathname.includes(
                    '/reports/trading-reports/physical-position-by-months',
                  ),
                },
                {
                  id: 'reports-trading-reports-physical-position',
                  href: '/reports/trading-reports/physical-position-mtm',
                  label: 'Physical position + MTM',
                  isActive: pathname.includes('/reports/trading-reports/physical-position-mtm'),
                },
              ],
            },
            {
              id: 'reports-trading-reports-freight',
              href: '/reports/trading-reports/freight',
              label: 'Freight',
              isActive: pathname.includes('/reports/trading-reports/freight'),
            },
            {
              id: 'reports-trading-reports-derivatives',
              href: '/reports/trading-reports/derivatives',
              label: 'Derivatives',
              isActive: pathname.includes('/reports/trading-reports/derivatives'),
            },
            {
              id: 'reports-trading-reports-fixing-or-hedging',
              href: '/reports/trading-reports/fixing-or-hedging',
              label: 'Fixing/Hedging',
              isActive: pathname.includes('/reports/trading-reports/fixing-or-hedging'),
            },
            {
              id: 'reports-trading-reports-line-up',
              href: '/reports/trading-reports/line-up',
              label: 'Line Up',
              isActive: pathname.includes('/reports/trading-reports/line-up'),
            },
            {
              id: 'reports-trading-reports-contracts-execution',
              href: '/reports/trading-reports/contracts-execution',
              label: 'Contracts Execution',
              isActive: pathname.includes('/reports/trading-reports/contracts-execution'),
            },
          ],
        },
        {
          id: 'reports-finance-reports',
          href: '/reports/finance-reports/',
          isActive: pathname.includes('/reports/finance-reports'),
          label: 'Finance Reports',
          submenus: [
            {
              id: 'reports-finance-reports-passports-pnl',
              href: '/reports/finance-reports/passports-pnl',
              label: 'P/L',
              isActive: pathname.includes('/reports/finance-reports/passports-pnl'),
            },
            {
              id: 'reports-finance-reports-ap-ar',
              href: '/reports/finance-reports/ap-ar',
              label: 'AP/AR',
              isActive: pathname.includes('/reports/finance-reports/ap-ar'),
            },
            {
              id: 'reports-finance-reports-cash-flow',
              href: '/reports/finance-reports/cash-flow',
              label: 'Cash Flow',
              isActive: pathname.includes('/reports/finance-reports/cash-flow'),
            },
            {
              id: 'reports-finance-reports-statistics',
              href: '/reports/finance-reports/statistics',
              label: 'Statistics',
              isActive: pathname.includes('/reports/finance-reports/statistics'),
            },
            {
              id: 'reports-finance-reports-costs-report',
              href: '/reports/finance-reports/costs-report',
              label: 'Costs report',
              isActive: pathname.includes('/reports/finance-reports/costs-report'),
            },
            {
              id: 'reports-finance-reports-balance',
              href: '/reports/finance-reports/balance',
              label: 'Balance',
              isActive: pathname.includes('/reports/finance-reports/balance'),
            },
            {
              id: 'reports-finance-reports-budgets',
              href: '/reports/finance-reports/budgets',
              label: 'Budgets',
              isActive: pathname.includes('/reports/finance-reports/budgets'),
            },
            {
              id: 'reports-finance-reports-reconciliations',
              href: '/reports/finance-reports/reconciliations',
              label: 'Reconciliations',
              isActive: pathname.includes('/reports/finance-reports/reconciliations'),
            },
          ],
        },
        {
          id: 'reports-risk-reports',
          href: '/reports/risk-reports',
          isActive: pathname.includes('/reports/risk-reports'),
          label: 'Risk Reports',
          submenus: [
            {
              id: 'reports-risk-reports-credit-report',
              href: '/reports/risk-reports/credit-report',
              label: 'Credit report',
              isActive: pathname.includes('/reports/risk-reports/credit-report'),
            },
            {
              id: 'reports-risk-reports-invoices-risk',
              href: '/reports/risk-reports/invoices-risk',
              label: 'Invoices risk',
              isActive: pathname.includes('/reports/risk-reports/invoices-risk'),
            },
          ],
        },
        {
          id: 'reports-management-reports',
          href: '/reports/management-reports',
          isActive: pathname.includes('/reports/management-reports'),
          label: 'Management Reports',
          submenus: [
            {
              id: 'reports-management-reports-approvals',
              href: '/reports/management-reports/approvals',
              label: 'Approvals',
              isActive: pathname.includes('/reports/management-reports/approvals'),
            },
            {
              id: 'reports-management-reports-documents-dates',
              href: '/reports/management-reports/documents-dates',
              label: 'Documents dates',
              isActive: pathname.includes('/reports/management-reports/documents-dates'),
            },
            {
              id: 'reports-management-reports-team',
              href: '/reports/management-reports/team',
              label: 'Team',
              isActive: pathname.includes('/reports/management-reports/team'),
            },
            {
              id: 'reports-management-reports-compliance',
              href: '/reports/management-reports/compliance',
              label: 'Compliance',
              isActive: pathname.includes('/reports/management-reports/compliance'),
            },
            {
              id: 'reports-management-reports-signings',
              href: '/reports/management-reports/signings',
              label: 'Signings',
              isActive: pathname.includes('/reports/management-reports/signings'),
            },
            {
              id: 'reports-management-reports-kpi',
              href: '/reports/management-reports/kpi',
              label: 'KPI',
              isActive: pathname.includes('/reports/management-reports/kpi'),
            },
            {
              id: 'reports-management-reports-activity',
              href: '/reports/management-reports/activity',
              label: 'Activity',
              isActive: pathname.includes('/reports/management-reports/activity'),
            },
          ],
        },
      ],
    },
  ];
};
