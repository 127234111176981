import React from 'react';

import { cn } from '~/shared/lib/utils';
import { DropdownMenuItem } from '~/shared/ui/kit/dropdown-menu';

import type { ViewVariant } from '../../../data-set-view';
import { basicViews } from '../../constants';
import type { ViewConfigChanged } from '../../types';

export const ViewTypeList = ({
  viewId,
  currentViewType,
  viewConfigChanged,
}: {
  viewId: number;
  currentViewType: ViewVariant;
  viewConfigChanged: ViewConfigChanged;
}) => {
  return basicViews.map(({ Icon, ...view }) => {
    return (
      <DropdownMenuItem
        key={view.type}
        className={cn(
          'group relative flex h-8 cursor-pointer items-center gap-2 px-2 text-xs transition duration-300 ease-in-out hover:bg-background-main-tertiary',
          currentViewType === view.type && 'bg-background-main-tertiary',
        )}
        onClick={() => viewConfigChanged(viewId, { type: view.type })}
      >
        {Icon && (
          <Icon
            size={14}
            className={cn(
              'text-stroke-main-stronger transition duration-300 ease-in-out group-hover:text-stroke-main-inverted',
              currentViewType === view.type && 'text-stroke-main-inverted',
            )}
          />
        )}
        <p className="text-main-primary text-xs capitalize">{view.type}</p>
      </DropdownMenuItem>
    );
  });
};
