import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').directive('updatesList', directive);

  function directive() {
    return {
      template: require('./updates-list.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        clientUpdates: '<',
        count: '<',
        filterLevel: '<?',
        isTemplate: '<?',
        onUpdate: '&?',
        applyFilters: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', 'ClientsService', 'gettext', 'GtUtils'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ClientsService: any,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;
    vm.queryParams = {};
    vm.deleteClientUpdate = deleteClientUpdate;
    vm.updateClientUpdate = updateClientUpdate;
    vm.goToObject = goToObject;
    vm.tableData = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'updates-list';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        vm.theadConfig = getTheadConfig();
      });
      vm.tableOptions = getTableOptions();
      vm.tableData = { rows: vm.clientUpdates, count: vm.count };
    };

    vm.$onChanges = function (changes: any) {
      if (changes.clientUpdates) {
        vm.tableData = { rows: vm.clientUpdates, count: vm.count };
      }
    };

    function getTableOptions() {
      const options: any = {
        tableName: 'updates',
        tableClass: 'contract-charges-table',
        filterLevel: vm.filterLevel,
        configurable: true,
        columnDefs: [] as any[],
        tabs: [],
        applyFilters: vm.applyFilters,
        templateArgs: {
          updateClientUpdate: (item: any) => updateClientUpdate(item),
          deleteClientUpdate: (item: any) => deleteClientUpdate(item),
          goToObject: (contentType: any, objectId: any, tab: any) =>
            goToObject(contentType, objectId, tab),
        },
      };

      options.columnDefs.push(
        {
          columnName: 'update_time',
          title: gettext('Update date'),
          predicate: 'update_time',
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <span class="updated_time">
              
              {[{item.update_time | date:'dd/MMM/yy HH:mm'}]}
              <span ng-if="item._edit">
                <div class="clearfix"></div>
                <gt-resource-multiselect
                  ng-model="item.responsible_users"
                  placeholder="'Responsible users'|translate"
                  resource-name="'auth.User'"
                  allow-clear="true"
                ></gt-resource-multiselect>
              </span>
            </span>
          `,
        },
        {
          columnName: 'priority',
          title: gettext('Priority'),
          class: 'td-left-align',
          filters: [
            {
              type: 'select',
              predicate: 'priority',
              label: gettext('Priority'),
              values: {
                low: gettext('Low'),
                medium: gettext('Medium'),
                high: gettext('High'),
                critical: gettext('Critical'),
              },
            },
          ],
          cellTemplate: /*html*/ `
            <div class="highlighted-td td-left-align" ng-if="!item._edit">
              <span
                ng-if="!item._edit && item.priority"
                class="label-smoke"
                ng-class="{'label label_success': item.priority == 'low', 'label label_warning': item.priority == 'high', 'label label_danger': item.priority == 'critical' }"
              >
                {[{item.priority }]}
              </span>
            </div>
            <div ng-if="item._edit">
              <select ng-model="item.priority">
                <option value="low"><translate>Low</translate></option>
                <option value="medium"><translate>Medium</translate></option>
                <option value="high"><translate>High</translate></option>
                <option value="critical"><translate>Critical</translate></option>
              </select>
            </div>
          `,
        },
        {
          columnName: 'initiator',
          title: gettext('Initiator'),
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'initiator_list',
              label: gettext('Initiator'),
              resource: 'auth.User',
            },
          ],
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.initiator">
                <i class="fa fa-user"></i>
                {[{item.initiator_first_name}]} {[{item.initiator_last_name}]}
              </span>
            </div>
            <div ng-if="item._edit">
              <gt-resource-select
                ng-model="item.initiator"
                placeholder="'Initiator'|translate"
                resource-name="'auth.User'"
                allow-clear="true"
              ></gt-resource-select>
            </div>
          `,
        },
        {
          columnName: 'responsible_users',
          title: gettext('Responsible users'),
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-multiselect',
              predicate: 'responsible_user_list',
              label: gettext('Responsible users'),
              resource: 'auth.User',
            },
          ],
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-repeat="responsibleUser in item.responsible_users_data">
                <i class="fa fa-user"></i>
                {[{responsibleUser.first_name}]} {[{responsibleUser.last_name}]}
                <div class="clearfix"></div>
              </span>
            </div>
            <div ng-if="item._edit">
              <gt-resource-multiselect
                ng-model="item.responsible_users"
                placeholder="'Responsible users'|translate"
                resource-name="'auth.User'"
                allow-clear="true"
              ></gt-resource-multiselect>
            </div>
          `,
        },
        {
          columnName: 'message_type',
          title: gettext('Type'),
          class: 'td-left-align',
          filters: [
            {
              type: 'select',
              predicate: 'message_type',
              label: gettext('Type'),
              values: {
                call: gettext('Call'),
                email: gettext('Email'),
                commentary: gettext('Commentary'),
                meet: gettext('Meet'),
                task: gettext('Task'),
              },
            },
          ],
          cellTemplate: /*html*/ `
            <span class="label label-warning" ng-if="item.is_autocreated">
              <i class="fa fa-robot"></i>
            </span>
            <span ng-if="!item.is_autocreated">
              <span
                class="label bigger-label label_success"
                ng-if="item.message_type == 'call'"
                ><i class="fa fa-phone-square"></i>
              </span>
              <span
                class="label bigger-label label_warning"
                ng-if="item.message_type == 'email'"
                ><i class="fa fa-envelope"></i>
              </span>
              <span
                class="label bigger-label label_default"
                ng-if="item.message_type == 'commentary'"
                ><i class="fa fa-commenting"></i>
              </span>
              <span
                class="label bigger-label label-info"
                ng-if="item.message_type == 'meet'"
                ><i class="fa fa-map-marker"></i>
              </span>
              <span
                class="label bigger-label label_calculate"
                ng-if="item.message_type == 'task'"
                ><i class="fa fa-tasks"></i>
              </span>
            </span>
          `,
        },
        {
          columnName: 'future_contact',
          title: gettext('Due date'),
          predicate: 'future_contact',
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.future_contact">
                
                {[{item.future_contact | date:'dd/MMM/yy HH:mm'}]}
              </span>
            </div>
            <div ng-if="item._edit">
              <gt-date-select
                date-model="item.future_contact"
                pull-right="true"
                min-view="'minute'"
              ></gt-date-select>
            </div>
          `,
        },
        {
          columnName: 'sequence_order',
          title: gettext('Sequence order'),
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <i class="fa fa-sort"></i>
              {[{item.sequence_order}]}
            </div>
            <div ng-if="item._edit">
              <input
                type="number"
                class="form-control"
                placeholder="{[{ 'Sequence order'|translate }]}"
                ng-model="item.sequence_order"
              />
            </div>
          `,
        },
        {
          columnName: 'comments',
          title: gettext('Comments'),
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <div ng-repeat="comment in item.comments_data">
                <div>
                  <span>
                    <i class="fa fa-user"></i> {[{ comment.author_first_name }]} {[{comment.author_last_name }]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <i class="fa fa-clock"></i> {[{comment.create_time | date:'dd/MMM/yy HH:mm'}]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <i class="fa fa-message"></i> {[{ comment.text }]}
                  </span>
                </div>
              </div>
            </div>
          `,
        },
        {
          columnName: 'message',
          title: gettext('Message'),
          class: 'td-left-align wrap-text',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.failure_reason_title" class="negative-number">
                [{[{item.failure_reason_title }]}]
              </span>
              {[{item.update }]}
              <a
              permission
              permission-only="'change_clientupdate'"
              ng-click="item._edit = true"
              ng-if="!item._edit"
              class="btn-xs inside-table-btn"
            >
              <i class="fa fa-pencil-square"></i>
            </a>
              <div class="clearfix"></div>
            </div>
            <div ng-if="item._edit">
              <div class="form-group" ng-class="{error: vm.client.update.$invalid}">
                <div>
                  <textarea
                    ng-blur="args.updateClientUpdate(item)"
                    class="form-control ng-pristine ng-valid"
                    type="text"
                    row="2"
                    placeholder="update"
                    ng-model="item.update"
                    style="text-align: center; width: 100% !important; border-radius: 2px !important"
                  >
                  </textarea>
                  <div class="clearfix"></div>
                  <gt-resource-select
                    ng-model="item.failure_reason"
                    placeholder="'Failure reasons'|translate"
                    resource-name="'clients.UpdateFailureReason'"
                    ng-if="(item.contact_status == 'cancelled' || item.contact_status == 'fail') && !vm.isTemplate"
                    allow-clear="true"
                  ></gt-resource-select>
                  <div class="clearfix"></div>
                  <div
                    class="btn-group btn-group-xs btn-group-justified"
                    style="width: 100% !important; margin-top: 10px"
                    ng-if="item._edit"
                  >
                    <a
                      ng-click="args.updateClientUpdate(item)"
                      class="btn btn-xs btn-success"
                      ng-if="item._edit"
                    >
                      <i class="fa fa-floppy-o"></i>
                    </a>
                    <a
                      ng-click="item._edit = false"
                      ng-if="item._edit"
                      class="btn btn-xs btn-default"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                    <a
                      permission
                      permission-only="'delete_clientupdate'"
                      ng-if="item._edit"
                      ng-click="args.deleteClientUpdate(item)"
                      class="btn btn-xs btn-danger"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                  <span class="error-msg" ng-show="vm.update.$error.float">
                    <i class="fa fa-exclamation-triangle"></i>
                    <translate
                      >This is not a valid float number. Type value in format 5 or 5.5 for
                      example</translate
                    >. <br />
                  </span>
                </div>
              </div>
            </div>
          `,
        },
        {
          columnName: 'response',
          title: gettext('Response'),
          class: 'td-left-align wrap-text',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
             <span ng-if="item.response">
                <i class="fa fa-reply"></i> {[{item.response }]}
              </span>
              <a
              permission
              permission-only="'change_clientupdate'"
              ng-click="item._edit = true"
              ng-if="!item._edit"
              class="btn-xs inside-table-btn"
            >
              <i class="fa fa-pencil-square"></i>
            </a>

            </div>
            <div ng-if="item._edit">
              <div class="form-group" ng-class="{error: vm.client.update.$invalid}">
                <div>
                  <textarea
                    ng-blur="args.updateClientUpdate(item)"
                    class="form-control ng-pristine ng-valid"
                    type="text"
                    row="2"
                    placeholder="response"
                    ng-model="item.response"
                    style="text-align: center; width: 100% !important; border-radius: 2px !important"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          `,
        },
        {
          columnName: 'object',
          title: gettext('Object'),
          class: 'td-left-align wrap',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
             <div class="small">
                <a
                  class="btn-link updated_time"
                  ng-click="args.goToObject(item.updates_entity_content_type, item.updates_entity_id, 'updates')"
                >
                  {[{item.updates_entity_title | cut:true:25:' '}]}
                </a>
                <span ng-if="item.updates_reason_entity_id">
                  <span><i class="fa fa-arrow-right"></i></span>
                  <span
                    class="btn-link"
                    ng-click="args.goToObject(item.updates_reason_entity_content_type, item.updates_reason_entity_id, 'updates')"
                  >
                    <i class="fa fa-building-flag"></i>{[{item.updates_reason_entity_title }]}
                  </span>
                </span>
              </div>
            </div>
            
          `,
        },
        {
          columnName: 'created_time',
          title: gettext('Created time'),
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.create_time">
                
                {[{item.create_time | date:'dd/MMM/yy HH:mm'}]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'topic',
          title: gettext('Topic'),
          predicate: 'topic_title',
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'topic_list',
              label: gettext('Topic'),
              resource: 'clients.UpdateTopic',
            },
          ],
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.topic">
                <i class="fa fa-envelope"></i>
                {[{item.topic_title}]}
              </span>
            </div>
            <div ng-if="item._edit">
              <gt-resource-select
                ng-model="item.topic"
                placeholder="'Topic'|translate"
                resource-name="'clients.UpdateTopic'"
                allow-clear="true"
              ></gt-resource-select>
            </div>
          `,
        },
        {
          columnName: 'section',
          title: gettext('Section'),
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.section">
                <i class="fa fa-envelope"></i>
                {[{item.section_title}]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'status',
          title: gettext('Status'),
          predicate: 'contact_status',
          class: 'td-left-align',
          filters: [
            {
              type: 'select',
              predicate: 'contact_status_list',
              label: gettext('Status'),
              values: {
                success: gettext('Success'),
                fail: gettext('Fail'),
                progress: gettext('Progress'),
                cancelled: gettext('Cancelled'),
                upcoming: gettext('Upcoming'),
              },
            },
          ],
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span>
                <span
                  class="label bigger-label label_success"
                  ng-if="item.contact_status == 'success'"
                  ><i class="fa fa-check"></i>
                  <translate>Success</translate>
                </span>
                <span
                  class="label bigger-label label_danger"
                  ng-if="item.contact_status == 'fail'"
                  ><i class="fa fa-ban"></i>
                  <translate>Fail</translate>
                </span>
                <span
                  class="label bigger-label label_warning"
                  ng-if="item.contact_status == 'progress'"
                  ><i class="fa fa-spinner"></i>
                  <translate>Progress</translate>
                </span>
                <span
                  class="label bigger-label label_default"
                  ng-if="item.contact_status == 'cancelled'"
                  ><i class="fa fa-times"></i>
                  <translate>Cancelled</translate>
                </span>
                <span
                  class="label bigger-label label_info"
                  ng-if="item.contact_status == 'upcoming'"
                  >
                  <translate>Upcoming</translate>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'notification_date',
          title: gettext('Notification date'),
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.notification_date">
                
                {[{item.notification_date | date:'dd/MMM/yy HH:mm'}]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'author',
          title: gettext('Author'),
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.author">
                <i class="fa fa-user"></i>
                {[{item.author_first_name}]} {[{item.author_last_name}]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'task_state',
          title: gettext('Task state'),
          predicate: 'task_state',
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span
                ng-if="!item._edit && item.task_state"
                class="label-smoke"
                ng-class="{'label label_success': item.task_state == 'completed', 'label label_warning': item.task_state == 'upcoming', 'label label_danger': item.task_state == 'overdue' }"
              >
                {[{item.task_state }]}
              </span>
            </div>
          `,
        },
      );

      if (vm.isTemplate) {
        options.columnDefs.push(
          {
            columnName: 'checklist_point',
            title: gettext('Checklist point'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.checklistpoint_title">
                <i class="fa fa-envelope"></i>
                {[{item.checklistpoint_title}]}
              </span>
            </div>
            <div ng-if="item._edit">
              <div class="clearfix"></div>
              <gt-resource-select
                ng-model="item.checklist_point"
                placeholder="'Checklist Point'|translate"
                resource-name="'core.checklistpoint'"
                allow-clear="true"
              ></gt-resource-select>
            </div>
          `,
          },
          {
            columnName: 'due_date_days_after_create',
            title: gettext('Due date days after create'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <div ng-if="!item._edit">
              <span ng-if="item.due_date_days_after_create">
                {[{item.due_date_days_after_create}]}
              </span>
            </div>
            <div ng-if="item._edit">
              <input
                ng-if="vm.isTemplate"
                type="number"
                class="form-control"
                placeholder="{[{ 'Due date days after create'|translate }]}"
                ng-model="item.due_date_days_after_create"
              />
            </div>
          `,
          },
        );
      }

      return options;
    }

    function goToObject(contentType: any, objectId: any, tab: any) {
      return GtUtils.goDetails(contentType, objectId, tab);
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: 'date',
          columnName: 'update date',
          predicate: 'update_time',
        },
        {
          title: gettext('type'),
          columnName: 'type',
          predicate: 'message_type',
        },
        {
          title: gettext('message'),
          columnName: 'type',
        },
        {
          title: gettext('future contact'),
          columnName: 'future_contract',
        },
        {
          title: gettext('Comments'),
          columnName: 'comments',
        },
      ];

      return config;
    }

    function deleteClientUpdate(update: any) {
      if (!confirm(gettext('Are you sure?'))) {
        return;
      }
      ClientsService.ClientUpdate.delete({ id: update.id }, vm.onUpdate);
    }

    function updateClientUpdate(update: any) {
      update._edit = false;
      return ClientsService.ClientUpdate.update(update, () => {
        GtUtils.notify(gettext('Object updated'));
        vm.onUpdate();
      });
    }
  }
})();
