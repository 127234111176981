import React from 'react';

import { Link } from '~/shared/lib/router';
import { Button } from '~/shared/ui/kit/button';

import darkImgSrc from './dark-not-found.svg';
import lightImgSrc from './light-not-found.svg';

export const NotFoundPage: React.FC = () => {
  return (
    <section className="h-screen bg-background-main-secondary">
      <section className="flex h-full flex-col items-center justify-center gap-8">
        <div>
          <img
            src={lightImgSrc}
            alt="not-found-img"
            className="block dark:hidden"
            width={240}
            height={216}
          />
          <img
            src={darkImgSrc}
            alt="not-found-img"
            className="hidden dark:block"
            width={240}
            height={216}
          />
        </div>
        <div className="flex flex-col items-center gap-4">
          <h1 className="text-2xl font-semibold text-text-main-primary">Off the beaten path</h1>
          <p className="text-xs text-text-main-secondary">
            The page you&apos;re seeking is either gone or never was. Let&apos;s get you back on
            track..
          </p>
        </div>
        <Link to="/" className="w-[200px]">
          <Button variant="default" size="md" fontWeight="semibold" fullWidth>
            Back to content
          </Button>
        </Link>
      </section>
    </section>
  );
};
