import React from 'react';

import { TraderDetailsLayout } from '~/pages/company/team';
import { createFileRoute } from '~/shared/lib/router';

const RouteComponent: React.FC = () => {
  const { traderId } = Route.useParams();
  return <TraderDetailsLayout traderId={traderId} />;
};

export const Route = createFileRoute('/_auth/_sidebar/company/team/$traderId/')({
  component: RouteComponent,
});
