import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('common.crops.legacy').component('warehousePageView', {
    bindings: {
      filterLevel: '<?',
      isWarehouseRestPage: '<?',
    },
    template: require('./warehouse-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    'gtFilterService',
    'PageService',
    'gettext',
    'LogisticsService',
    'moment',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    gtFilterService: GtFilterService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    LogisticsService: any,
    moment: any,
  ) {
    const vm = this;
    vm.filterLevel = 'warehouse-page-view';
    vm.warehouseType = $rootScope.user.profile.warehouse_show_capitalize;
    vm.warehouseTypes = ['Farm', 'Elevator', 'Terminal', 'Warehouse', 'Other'];
    vm.businessUnitList = [];
    vm.cargoList = [];
    vm.purchase_contracts = [];
    vm.receiverList = [];
    vm.producerList = [];
    vm.queryParams = {
      warehouse_type: vm.warehouseType,
    };
    vm.applyFilter = applyFilter;
    vm.resetFilter = resetFilter;
    vm.fromDate = undefined;
    vm.toDate = undefined;
    vm.isWarehouseRestPage = false;

    vm.$onInit = function () {
      vm.warehouseType = vm.isWarehouseRestPage ? 'Terminal' : vm.warehouseType;
      vm.queryParams = {
        warehouse_type: vm.warehouseType,
        business_unit_list: vm.businessUnitList,
        cargo_list: vm.cargoList,
        farm_list: vm.farmList,
        receiver_list: vm.receiverList,
        producer_list: vm.producerList,
        elevator_list: vm.elevatorList,
        terminal_list: vm.terminalList,
        warehouse_list: vm.warehouseList,
        purchase_contracts: vm.purchase_contracts,
      };
      if ($rootScope.user.settings.WAREHOUSE_DEFAULT_CTR_STATUS) {
        vm.queryParams.purchase_contract_status_list = [
          'new',
          'delivery_period',
          'shipment',
          'approved',
          'cargo_executed',
          'executed',
        ];
      }

      vm.contractStatuses = [
        { id: 'washout', title: gettext('washout') },
        { id: 'new', title: gettext('new') },
        { id: 'delivery_period', title: gettext('delivery_period') },
        { id: 'shipment', title: gettext('shipment') },
        { id: 'cargo_executed', title: gettext('cargo_executed') },
        { id: 'executed', title: gettext('executed') },
        { id: 'approved', title: gettext('approved') },
        { id: 'cancelled', title: gettext('cancelled') },
      ];
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function applyFilter(params?: any) {
      let filters = {
        warehouse_type: vm.warehouseType,
        business_unit_list: vm.businessUnitList,
        cargo_list: vm.cargoList,
        warehouse_list: vm.warehouseList,
        receiver_list: vm.receiverList,
        producer_list: vm.producerList,
        from_date: vm.fromDate && moment(vm.fromDate).format('YYYY-MM-DD'),
        to_date: vm.toDate && moment(vm.toDate).format('YYYY-MM-DD'),
        purchase_contract_responsible_list: vm.queryParams.purchase_contract_responsible_list,
        sale_contract_status_list: vm.queryParams.sale_contract_status_list,
        purchase_contract_status_list: vm.queryParams.purchase_contract_status_list,
      };
      if (params) {
        filters = { ...filters, ...params };
      }
      gtFilterService.updateQueryParams(filters, vm.filterLevel);
    }

    function resetFilter() {
      vm.businessUnitList = [];
      vm.cargoList = [];
      vm.farmList = [];
      vm.receiverList = [];
      vm.producerList = [];
      vm.elevatorList = [];
      vm.terminalList = [];
      vm.warehouseList = [];
      vm.fromDate = undefined;
      vm.toDate = undefined;
      vm.queryParams.purchase_contract_status_list = [];
      vm.queryParams.sale_contract_status_list = [];
      applyFilter();
    }

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-logistics-tab',
        buttons: [
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: [],
      };
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }
  }
})();
