import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

import template from './custom-values-page-view.html?raw';

export const CustomValuesPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'CustomValuesService',
    'gettext',
    class CustomValuesPageView {
      CustomValuesService: any;
      PageService: PageService;
      filterLevel = 'custom-values-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        CustomValuesService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.CustomValuesService = CustomValuesService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }
      getPageConfig() {
        return {
          class: 'page-header-main-crm-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          filters: {
            selects: [],
            multiSelects: [
              {
                argument: 'field_list',
                placeholder: this.gettext('fields'),
                resource: 'core.customfield',
              },
              {
                argument: 'purpose_list',
                placeholder: this.gettext('purpose'),
                resource: 'contenttypes.contenttype',
              },
            ],
            nestedSelects: [],
            nestedMultiSelects: [],
          },
          pages: this.CustomValuesService.getPagesConfig(),
        };
      }
    },
  ],
};
