import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('noDataRow', directive);

  function directive() {
    return {
      template: require('./no-data-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        columns: '<?',
      },
    };
  }

  function Controller(this: any) {
    const vm = this;
    vm.columns = vm.columns || 50;

    ////////////////
  }
})();
