import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';

export class ImportExportContractsService {
  $filter: ng.IFilterService;
  $http: ng.IHttpService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  $upload: any;
  analyticsData: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $upload: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.$uibModal = $uibModal;
    this.$upload = $upload;
    this.gettext = gettext;

    this.analyticsData = undefined;
  }

  importExportModal(contractType?: string) {
    return this.$uibModal.open({
      backdrop: 'static',
      component: 'importExportContractsModal',
      windowClass: 'modal-template modal-template-half-width',
      size: 'sm',
      resolve: { contractType: () => contractType },
      appendTo: getModalRoot(),
    }).result;
  }

  updateStages(file: any, contractType: any, save: any) {
    contractType = contractType || 'sale';
    return this.$upload.upload({
      url: `/api/contracts/${contractType}-contracts/update_stages/`,
      file: file,
      data: { save: save ? true : false },
    });
  }
}
ImportExportContractsService.$inject = [
  '$resource',
  '$q',
  '$http',
  '$filter',
  '$uibModal',
  '$upload',
  'gettext',
];
