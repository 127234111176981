import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

export class ContractFilterConfigService {
  $rootScope: GtRootScopeService;
  CustomValuesService: any;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;
  constructor(
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    CustomValuesService: any,
  ) {
    this.$rootScope = $rootScope;
    this.GtUtils = GtUtils;
    this.gtFilterService = gtFilterService;
    this.gettext = gettext;
    this.CustomValuesService = CustomValuesService;
  }

  getRelatedModel(dealType: any, contractType: any) {
    const contractMap = {
      export: 'contracts.ExportContract',
      intermediate: 'contracts.IntermediateContract',
      services: 'contracts.ServicesContract',
      sale: 'contracts.SaleContract',
      purchase: 'contracts.PurchaseContract',
    };

    return contractMap[dealType] || contractMap[contractType] || 'contracts.ContractBase';
  }

  getFilterConfig(filterLevel: string, contractType: any, dealType: any) {
    const relatedModel = this.getRelatedModel(dealType, contractType);

    const filters: any = {
      groupBy: {
        supplier: this.gettext('Supplier'),
        buyer: this.gettext('Buyer'),
        basis: this.gettext('Basis'),
        cargo: this.gettext('Cargo'),
        crop_year: this.gettext('Crop year'),
        status: this.gettext('Status'),
        region: this.gettext('Region'),
        district: this.gettext('District'),
        origin_of_crop: this.gettext('Origin'),
        destination_of_crop: this.gettext('Destination'),
        season: this.gettext('Season'),
        business_unit: this.gettext('Business unit'),
      },
      filterLevel: filterLevel,
      ordering: [
        {
          predicate: 'update_time',
          title: this.gettext('RECENTLY UPDATED'),
        },
        {
          predicate: 'conclusion_date',
          title: this.gettext('CONCLUSION DATE'),
        },
        {
          predicate: 'date_of_execution',
          title: this.gettext('SHIPMENT START'),
        },
        {
          predicate: 'end_of_execution',
          title: this.gettext('SHIPMENT END'),
        },
        {
          predicate: 'volume',
          title: this.gettext('VOLUME'),
        },
        {
          predicate: 'price_in_USD',
          title: this.gettext('PRICE'),
        },
        {
          predicate: 'total_value',
          title: this.gettext('VALUE'),
        },
        {
          predicate: 'total_charges',
          title: this.gettext('CHARGES'),
        },
        {
          predicate: 'status',
          title: this.gettext('STATUS'),
        },
      ],
      clicked: false,
      search: true,
      dates: true,
      useFlexDateRange: true,
      invert_filters: true,
      dateSelects: [
        {
          argument: 'create_time',
          placeholder: this.gettext('Create date'),
        },
        {
          argument: 'conclusion_date',
          placeholder: this.gettext('Conclusion date'),
        },
        {
          argument: 'date_of_execution',
          placeholder: this.gettext('Execution start date'),
        },
        {
          argument: 'end_of_execution',
          placeholder: this.gettext('Execution end date'),
        },
        {
          argument: 'arrival_period_start',
          placeholder: this.gettext('arrival start'),
        },
        {
          argument: 'arrival_period_end',
          placeholder: this.gettext('arrival end'),
        },
        {
          argument:
            contractType === 'sale'
              ? 'sale_facts__passport__business_date'
              : 'purchase_facts__passport__business_date',
          placeholder: this.gettext('passport business date'),
        },
        {
          argument: 'from_request__date',
          placeholder: this.gettext('request date'),
        },
      ],
      selects: [
        {
          argument: 'currency',
          placeholder: this.gettext('currency'),
          resource: 'finances.currency',
          related_fk: 'currency_id',
          related_model: relatedModel,
        },
      ],
      multiSelects: [
        {
          argument: 'id_list',
          placeholder: this.gettext('contract number'),
          resource: 'contracts.ContractBase',
          related_fk: 'id',
          related_model: relatedModel,
        },
        {
          argument: 'season_list',
          placeholder: this.gettext('season'),
          resource: 'core.Season',
          related_fk: 'season',
          related_model: relatedModel,
        },
        {
          argument: 'cargo_list',
          placeholder: this.gettext('contract commodity'),
          resource: 'crops.Crop',
        },
        {
          placeholder: this.gettext('analytic parent'),
          argument: 'analytic_commodity_parent_list',
          resource: 'crops.Crop',
          queryParams: {
            is_analytic_parent: '1',
          },
        },
        {
          argument: 'basis_list',
          placeholder: this.gettext('basis'),
          resource: 'logistics.basis',
          related_fk: 'basis_id',
          related_model: relatedModel,
        },
        {
          argument: 'author_list',
          placeholder: this.gettext('author'),
          resource: 'auth.user',
          related_fk: 'author_id',
          related_model: relatedModel,
        },
        {
          argument: 'editor_list',
          placeholder: this.gettext('editor'),
          resource: 'auth.user',
          related_fk: 'editor_id',
          related_model: relatedModel,
        },
        {
          argument: 'responsible_list',
          placeholder: this.gettext('responsible'),
          resource: 'auth.user',
          related_fk: 'responsible',
          related_model: relatedModel,
        },
        {
          argument: 'responsible_for_signing_list',
          placeholder: this.gettext('responsible signing'),
          resource: 'auth.user',
          related_fk: 'responsible_for_signing',
          related_model: relatedModel,
        },
        {
          argument: 'region_list',
          placeholder: this.gettext('origin region'),
          resource: 'location.region',
          related_fk: 'region',
          related_model: relatedModel,
        },
        {
          argument: 'district_list',
          placeholder: this.gettext('origin district'),
          resource: 'location.district',
          related_fk: 'district',
          related_model: relatedModel,
        },
        {
          argument: 'origin_of_crop_list',
          placeholder: this.gettext('origin country'),
          resource: 'location.country',
          related_fk: 'origin_of_crop',
          related_model: relatedModel,
        },
        {
          argument: 'origins_of_crop_list',
          placeholder: this.gettext('Origins of crop'),
          resource: 'location.country',
        },
        {
          argument: 'destination_of_crop_list',
          placeholder: this.gettext('destination country'),
          resource: 'location.country',
          related_fk: 'destination_of_crop',
          related_model: relatedModel,
        },
        {
          argument: 'destinations_of_crop_list',
          placeholder: this.gettext('Destinations of crop'),
          resource: 'location.country',
        },
        {
          argument: 'terminal_consignment_list',
          placeholder: this.gettext('terminal (with consignment)'),
          resource: 'logistics.terminal',
          related_fk: 'terminal_consignment_list',
          related_model: relatedModel,
        },
        {
          argument: 'from_request_list',
          placeholder: this.gettext('ticket'),
          resource: 'contracts.ContractBase',
          related_fk: 'from_request',
          related_model: relatedModel,
        },
        {
          argument: 'planned_distributor_list',
          placeholder: this.gettext('Planned distributor'),
          resource: 'clients.ClientRole',
          related_fk: 'planned_distributor_list',
          related_model: relatedModel,
        },
        {
          argument: 'reassignment_list',
          placeholder: this.gettext('Reassignment'),
          resource: 'finances.Reassignment',
          related_fk: 'reassignment',
          related_model: relatedModel,
        },
        {
          argument: 'offset_list',
          placeholder: this.gettext('Offset'),
          resource: 'finances.financeoffset',
          related_fk: 'offset_list',
          related_model: relatedModel,
        },
        {
          argument: 'consignee_list',
          placeholder: this.gettext('Consignees'),
          resource: 'clients.ClientRole',
          related_fk: 'consignee_list',
          related_model: relatedModel,
        },
        {
          argument: 'type_of_activities_list',
          placeholder: this.gettext('Types of activities'),
          resource: 'core.TypeOfActivity',
          related_fk: 'type_of_activities',
          related_model: relatedModel,
        },
        {
          argument: 'company_group_list',
          placeholder: this.gettext('Company group'),
          resource: 'clients.CompanyGroup',
        },
        {
          argument: 'regional_manager_heads_list',
          placeholder: this.gettext('Heads of regional manager'),
          resource: 'accounts.RegionalManager',
          related_fk: 'regional_manager_heads',
          related_model: relatedModel,
        },
        {
          argument: 'fact_distributor_list',
          placeholder: this.gettext('Fact distributor'),
          resource: 'clients.ClientRole',
        },
        {
          argument: 'elevator_consignment_list',
          placeholder: this.gettext('elevator (with consignment)'),
          resource: 'clients.elevator',
          related_fk: 'elevator_consignment_list',
          related_model: relatedModel,
        },
        {
          argument: 'farm_consignment_list',
          placeholder: this.gettext('farm (with consignment)'),
          resource: 'clients.farm',
          related_fk: 'farm_consignment_list',
          related_model: relatedModel,
        },
        {
          argument: 'port_list',
          placeholder: this.gettext('port'),
          resource: 'logistics.port',
          related_fk: 'ports',
          related_model: relatedModel,
        },
        {
          argument: 'market_zone_list',
          placeholder: this.gettext('market zone'),
          resource: 'logistics.port',
          related_fk: 'market_zone',
          related_model: relatedModel,
        },
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
          getQueryParams: this.GtUtils.getBUQueryParams,
          related_fk: 'business_unit',
          related_model: relatedModel,
        },
        {
          argument: 'paper_trade_list',
          placeholder: this.gettext('paper trade'),
          resource: 'contracts.papertrade',
          related_fk: 'paper_trade',
          related_model: relatedModel,
        },
        {
          argument: 'multicontract_list',
          placeholder: this.gettext('multicontract'),
          resource: 'contracts.multicontract',
          related_fk: 'multicontract',
          related_model: relatedModel,
          getQueryParams: () => {
            return { contract_type: contractType };
          },
        },
        {
          argument: 'exporter_list',
          placeholder: this.gettext('exporter'),
          resource: 'clients.exporter',
          related_fk: 'exporters',
          related_model: relatedModel,
        },
        {
          argument: 'owner_list',
          placeholder: this.gettext('owner'),
          resource: 'clients.owner',
          related_fk: 'owner',
          related_model: relatedModel,
        },
        {
          argument: 'broker_list',
          placeholder: this.gettext('broker'),
          resource: 'clients.broker',
          related_fk: 'broker',
          related_model: relatedModel,
        },
        {
          argument: 'agent_list',
          placeholder: this.gettext('agent'),
          resource: 'clients.clientrole',
          related_fk: 'agent',
          related_model: relatedModel,
        },
        {
          argument: 'budget_list',
          placeholder: this.gettext('budget'),
          resource: 'finances.budget',
          related_fk: 'budget',
          related_model: relatedModel,
        },
        {
          argument: 'derivative_list',
          placeholder: this.gettext('derivative'),
          resource: 'stockexchanges.Derivative',
          related_fk: 'derivatives__derivative',
          related_model: relatedModel,
        },
        {
          argument: 'custom_status_list',
          placeholder: this.gettext('contract custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'custom_status',
          related_model: relatedModel,
          queryParams: {
            content_type__model: 'contractbase',
          },
        },
        {
          argument: 'multicontract_custom_status_list',
          placeholder: this.gettext('Multicontract custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'multicontract__custom_status',
          related_model: relatedModel,
          queryParams: {
            content_type__model: 'multicontract',
          },
        },
        {
          argument: 'cp_custom_status_list',
          placeholder: this.gettext('CP custom status'),
          resource: 'core.CustomStatus',
          queryParams: {
            content_type__model: 'client',
          },
        },
        {
          argument: 'passport_list',
          placeholder: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
          resource: 'passports.passport',
          related_model: 'passports.Passport',
          related_fk: `${contractType}_facts__contract__${contractType}_facts__passport`,
        },
        {
          argument: 'passport_custom_status_list',
          placeholder: this.gettext('passport custom status'),
          resource: 'core.CustomStatus',
          queryParams: {
            content_type__model: 'passport',
          },
        },
        {
          argument: 'guarantor_list',
          placeholder: this.gettext('guarantor'),
          resource: 'clients.other',
        },
        {
          argument: 'basis_docs_with_copy_list',
          placeholder: this.gettext('basis doc with copy'),
          resource: 'contracts.basisdoc',
        },
        {
          argument: 'basis_docs_without_copy_list',
          placeholder: this.gettext('basis doc without copy'),
          resource: 'contracts.basisdoc',
        },
        {
          argument: 'basis_docs_with_file_list',
          placeholder: this.gettext('basis doc with file'),
          resource: 'contracts.basisdoc',
        },
        {
          argument: 'basis_docs_without_file_list',
          placeholder: this.gettext('basis doc without file'),
          resource: 'contracts.basisdoc',
        },
        {
          argument: 'delivery_condition_list',
          placeholder: this.gettext('delivery condition'),
          resource: 'logistics.DeliveryCondition',
        },
        {
          argument: 'delivery_conditions_list',
          placeholder: this.gettext('delivery conditions'),
          resource: 'logistics.DeliveryCondition',
        },
        {
          argument: 'payment_condition_list',
          placeholder: this.gettext('payment condition'),
          resource: 'finances.PaymentCondition',
        },
        {
          argument: 'payment_conditions_list',
          placeholder: this.gettext('payment conditions'),
          resource: 'finances.PaymentCondition',
        },

        {
          argument: 'balance_payment_list',
          placeholder: this.gettext('balance payment'),
          resource: 'finances.BalancePayment',
        },
        {
          argument: 'sap_order_list',
          placeholder: this.gettext('sap orders'),
          resource: 'contracts.saporder',
        },
        {
          argument: 'general_agreement_list',
          placeholder: this.gettext('general agreement'),
          resource: 'contracts.GeneralAgreement',
        },
        {
          argument: 'loadport_list',
          placeholder: this.gettext('loadport'),
          related_fk: 'loadport',
          resource: 'logistics.port',
        },
        {
          argument: 'disport_list',
          placeholder: this.gettext('disport'),
          related_fk: 'disport',
          resource: 'logistics.port',
        },
      ],
      nestedSelects: [
        this.gtFilterService.getBoolFilter('is_swap', this.gettext('Is Swap')),
        this.gtFilterService.getBoolFilter('in_passport', this.gettext('Allocated')),
        this.gtFilterService.getBoolFilter('with_buyers', this.gettext('With buyers')),
        this.gtFilterService.getBoolFilter('with_suppliers', this.gettext('With suppliers')),
        this.gtFilterService.getBoolFilter('with_exporters', this.gettext('With exporters')),
        this.gtFilterService.getBoolFilter('with_payments', this.gettext('Invoices')),
        this.gtFilterService.getBoolFilter('with_transport', this.gettext('Transport')),
        this.gtFilterService.getBoolFilter('with_aggrements', this.gettext('Additional aggr')),
        this.gtFilterService.getBoolFilter(
          'with_general_agreement',
          this.gettext('Has general agreement'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_signaturestage_cancel',
          this.gettext('Has cancelled signature'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_signaturestage_originals_received',
          this.gettext('Has originals received'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_cargo_confirmation',
          this.gettext('Has cargo confirmation'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_payment_confirmation',
          this.gettext('Has payment confirmation'),
        ),
        this.gtFilterService.getBoolFilter('with_season', this.gettext('Has season')),
        this.gtFilterService.getBoolFilter(
          'with_final_confirmation',
          this.gettext('Has final confirmation'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_volume_with_docs',
          this.gettext('Has volume with docs'),
        ),
        this.gtFilterService.getBoolFilter(
          'logistics_supplier_invoiced',
          this.gettext('Supplier invoiced'),
        ),
        this.gtFilterService.getBoolFilter(
          'logistics_supplier_paid',
          this.gettext('Supplier paid'),
        ),
        this.gtFilterService.getBoolFilter(
          'logistics_buyer_invoiced',
          this.gettext('Buyer invoiced'),
        ),
        this.gtFilterService.getBoolFilter('logistics_buyer_paid', this.gettext('Buyer paid')),
        this.gtFilterService.getBoolFilter(
          'with_currency_exchange',
          this.gettext('With currency exchange'),
        ),

        this.gtFilterService.getBoolFilter('is_iscc', this.gettext('ISCC')),
        this.gtFilterService.getBoolFilter('with_ports', this.gettext('With ports')),
        this.gtFilterService.getBoolFilter(
          'with_shipment_terminal',
          this.gettext('With shipment terminal'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_origin_country',
          this.gettext('With origin country'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_destination_country',
          this.gettext('With destination country'),
        ),
        this.gtFilterService.getBoolFilter('with_loadport', this.gettext('With loadport')),
        this.gtFilterService.getBoolFilter('with_disport', this.gettext('With disport')),
        this.gtFilterService.getBoolFilter('with_business_unit', this.gettext('Has Business Unit')),
        {
          argument: 'wait_my_approval',
          class: '',
          items: [{ id: this.$rootScope.user.id, title: this.gettext('Yes') }],
          placeholder: this.gettext('Waiting my approval'),
        },
        this.gtFilterService.getBoolFilter(
          'all_add_agreements_has_originals',
          this.gettext('All Additional Agreements has originals'),
        ),
        this.gtFilterService.getBoolFilter('internal_chain', this.gettext('With internal chain')),
        this.gtFilterService.getBoolFilter('final_volume_not_zero', this.gettext('Final volume')),
        this.gtFilterService.getBoolFilter(
          'price_opposite_not_zero',
          this.gettext('Opposite price'),
        ),
        this.gtFilterService.getBoolFilter(
          'calc_intermediate_logistics',
          this.gettext('Calc intermediate logistics'),
        ),
        this.gtFilterService.getBoolFilter(
          'final_volume_equal_facts_volume',
          this.gettext('Final volume equals connections'),
        ),
        this.gtFilterService.getBoolFilter(
          'is_client_producer',
          this.gettext('Is client producer'),
        ),
        this.gtFilterService.getBoolFilter('is_client_agent', this.gettext('Is client agent')),
        this.gtFilterService.getBoolFilter(
          'is_client_resident',
          this.gettext('Is client resident'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_passport_with_multipassport',
          this.gettext('Multipassport'),
        ),
        this.gtFilterService.getBoolFilter('has_extension', this.gettext('Extension')),
        this.gtFilterService.getBoolFilter('has_derivative', this.gettext('Derivative')),
        this.gtFilterService.getBoolFilter('has_fixing', this.gettext('Has fixing')),
        this.gtFilterService.getBoolFilter('has_hedging', this.gettext('Has hedging')),
        this.gtFilterService.getBoolFilter('has_bls', this.gettext('Bill of Lading ')),
        this.gtFilterService.getBoolFilter('has_discount_mt', this.gettext('Discount per 1 mt')),
        this.gtFilterService.getBoolFilter('has_discount_amount', this.gettext('Discount amount')),
        this.gtFilterService.getBoolFilter(
          'has_volume_boarded_empty',
          this.gettext('Volume boarded empty'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_plan_crop_protection',
          this.gettext('Has plan crop protection'),
        ),
        this.gtFilterService.getBoolFilter('with_plan_seeds', this.gettext('Has plan seeds')),
        this.gtFilterService.getBoolFilter(
          'with_fact_crop_protection',
          this.gettext('Has fact crop protection'),
        ),
        this.gtFilterService.getBoolFilter('with_fact_seeds', this.gettext('Has fact seeds')),
        this.gtFilterService.getBoolFilter(
          'ignore_client_status',
          this.gettext('Ignore client status'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_analytical_price',
          this.gettext('Analytical price'),
        ),
        this.gtFilterService.getBoolFilter(
          'analytical_price_diff_price',
          this.gettext('Analytical price different from contract price'),
        ),
        this.gtFilterService.getBoolFilter('with_documents', this.gettext('Has documents')),
        this.gtFilterService.getBoolFilter(
          'with_checklist_copy',
          this.gettext('Checklist completed (copy)'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_checklist_originals',
          this.gettext('Checklist completed (originals)'),
        ),
      ],
      nestedMultiSelects: [
        {
          argument: 'contract_type_list',
          class: '',
          placeholder: this.gettext('Contract type'),
          items: [
            { id: 'sale', title: this.gettext('Sale') },
            { id: 'purchase', title: this.gettext('Purchase') },
          ],
        },
        {
          argument: 'deal_type_list',
          placeholder: this.gettext('Deal type'),
          items: [
            { id: 'spot', title: this.gettext('Spot') },
            { id: 'forward', title: this.gettext('Forward') },
            { id: 'intermediate', title: this.gettext('Intermediate') },
            { id: 'export', title: this.gettext('Export') },
          ],
        },
        {
          argument: 'crop_year_list',
          placeholder: this.gettext('crop year'),
          items: this.GtUtils.getYearList(),
        },
        {
          argument: 'signature_stages',
          placeholder: this.gettext('Signature stages'),
          items: [
            { id: 'none', title: this.gettext('No sign') },
            { id: 'draft', title: this.gettext('Draft') },
            { id: 'broker_signed', title: this.gettext('Broker signed') },
            { id: 'we_signed', title: this.gettext('Supplier signed') },
            { id: 'both_signed', title: this.gettext('Buyer signed') },
            { id: 'all_signed', title: this.gettext('All signed') },
          ],
        },
        this.gtFilterService.getContractStatusFilter('status_list'),
        this.gtFilterService.getMultiContractStatusFilter(
          'multicontract_status_list',
          'Multicontract status',
        ),
        {
          argument: 'approval_status_list',
          placeholder: this.gettext('Approval status'),
          items: [
            { id: 'wait', title: 'Waiting for approval' },
            { id: 'approved', title: 'Approved' },
            { id: 'process', title: 'Approval in process' },
            { id: 'rejected', title: 'Rejected' },
          ],
        },
        {
          argument: 'payment_status_list',
          placeholder: this.gettext('Payment status'),
          items: [
            { id: 'executed', title: 'Executed' },
            { id: 'not_executed', title: 'Not executed' },
          ],
        },
        {
          argument: 'cp_approval_status_list',
          placeholder: this.gettext('CP approval status'),
          items: [
            { id: 'wait', title: 'Waiting for approval' },
            { id: 'approved', title: 'Approved' },
            { id: 'process', title: 'Approval in process' },
            { id: 'rejected', title: 'Rejected' },
          ],
        },
      ],
    };

    if (contractType == 'purchase') {
      filters.multiSelects.push(
        {
          argument: 'supplier_list',
          placeholder: this.gettext('supplier'),
          resource: 'clients.supplier',
          related_fk: 'supplier',
          related_model: 'contracts.PurchaseContract',
        },
        {
          argument: 'station_list',
          placeholder: this.gettext('station departure'),
          resource: 'logistics.station',
          related_fk: 'station',
          related_model: 'contracts.PurchaseContract',
        },
        {
          argument: 'station_receiving_list',
          placeholder: this.gettext('station arrival'),
          resource: 'logistics.station',
          related_fk: 'station_receiving',
          related_model: 'contracts.PurchaseContract',
        },
        {
          argument: 'contract_option_list',
          placeholder: this.gettext('contract option'),
          resource: 'contracts.contractoption',
          related_fk: 'contract_option',
          related_model: 'contracts.PurchaseContract',
        },
        {
          argument: 'regional_managers_list',
          placeholder: this.gettext('sales manager'),
          resource: 'accounts.regionalmanager',
          related_fk: 'regional_managers',
          related_model: relatedModel,
        },
      );
    } else {
      filters.multiSelects.push({
        argument: 'buyer_list',
        placeholder: this.gettext('buyer'),
        resource: 'clients.buyer',
        related_fk: 'buyer',
        related_model: 'contracts.SaleContract',
      });
    }
    if (dealType == 'export') {
      filters.nestedSelects.push(
        this.gtFilterService.getBoolFilter(
          'connected_to_passport',
          this.gettext('Has connection to passport'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_periodic_declaration',
          this.gettext('Has periodic customs declaration'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_cargo_declaration',
          this.gettext('Has cargo customs declaration'),
        ),
      );
    }

    if (dealType === 'services') {
      filters.multiSelects.push({
        argument: 'charge_list',
        placeholder: this.gettext('charge'),
        resource: 'finances.Charge',
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_reassignment) {
      filters.nestedSelects.push(
        this.gtFilterService.getBoolFilter(
          'has_all_dbl_docs_copy',
          this.gettext('Has all docs copy of DBL'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_all_dbl_is_tax_return_registered',
          this.gettext('DBL are tax registered'),
        ),
        this.gtFilterService.getBoolFilter(
          'has_all_dbl_is_sap_registered',
          this.gettext('DBL SAP registered'),
        ),
      );
    }

    if (!['export', 'intermediate', 'service'].includes(dealType)) {
      filters.multiSelects.push({
        argument: 'certification_scheme_list',
        placeholder: this.gettext('certificate scheme'),
        resource: 'contracts.CertificationScheme',
        related_fk: 'certification_schemes',
        related_model: relatedModel,
      });
    }

    if (['sale', 'purchase', 'export'].includes(contractType) || dealType === 'intermediate') {
      filters.multiSelects.push({
        argument: 'contractcharge_charge_list',
        placeholder: this.gettext('costs charge'),
        resource: 'finances.Charge',
      });
    }

    this.CustomValuesService.getCustomFieldFilters({
      purpose_model: 'contractbase',
    }).then((customFieldsFilters: any) => {
      filters.multiSelects.push(...customFieldsFilters.multiSelects);
      filters.selects.push(...customFieldsFilters.selects);
      filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
      filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
    });

    if (contractType === 'sale' || contractType === 'purchase') {
      filters.nestedSelects.push(
        this.gtFilterService.getBoolFilter('ignore_allocation', this.gettext('Ignore allocation')),
      );
    }

    return filters;
  }
}
ContractFilterConfigService.$inject = [
  '$rootScope',
  'GtUtils',
  'gtFilterService',
  'gettext',
  'CustomValuesService',
];
