import React from 'react';

import { useDialog } from '~/core/global-dialog';
import { useObservableEagerState, useObservableState } from '~/shared/lib/state';
import { Button } from '~/shared/ui/kit/button';
import { Dialog } from '~/shared/ui/kit/dialog';

export const GlobalDialogContainer = () => {
  const { globalDialogStore } = useDialog();

  const dialogOpen = useObservableEagerState(globalDialogStore.dialogOpen$);
  const dialogConfig = useObservableState(globalDialogStore.currentDialogConfig$);

  return (
    <Dialog
      open={dialogOpen.value}
      onOpen={(value) => globalDialogStore.dialogToggled({ value })}
      title={dialogConfig?.title}
    >
      <div className="flex flex-col gap-6">
        <p>{dialogConfig?.description}</p>
        <section className="flex flex-col gap-2">
          {dialogConfig?.actions.map((item) => (
            <Button
              key={item.label + item.variant}
              onClick={() => {
                item.action();
                globalDialogStore.dialogToggled({ value: false, id: dialogConfig?.id });
              }}
              variant={item.variant}
              size="md"
              fullWidth
            >
              {item.label}
            </Button>
          ))}
        </section>
      </div>
    </Dialog>
  );
};
