import React from 'react';

import { entityAutocompletesRegistry } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const EntityCellWrite = <T,>({ value, valueChanged, typeExtra, id }: CellTypeProps<T>) => {
  if (!typeExtra) {
    throw new Error('EntityCellWrite requires a valid typeExtra');
  }

  const Autocomplete = entityAutocompletesRegistry[typeExtra];

  return (
    <Autocomplete
      value={{ id: id ?? '', title: String(value) }}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  );
};
