import ng from 'angular';

import { ClientInvoicePaymentReconciliationReportContainer } from './client-invoice-payment-reconciliation-report-container/client-invoice-payment-reconciliation-report-container.component';
import { ClientInvoicePaymentReconciliationReportPage } from './client-invoice-payment-reconciliation-report-page/client-invoice-payment-reconciliation-report-page.component';
import { ClientInvoicePaymentReconciliationReportTable } from './client-invoice-payment-reconciliation-report-table/client-invoice-payment-reconciliation-report-table.component';
import { ClientInvoicePaymentReconciliationReportService } from './client-invoice-payment-reconciliation-report.service';

const reportConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('reports.clientInvoicePaymentReconciliationReport', {
      url: '/client-invoice-payment-reconciliation-report',
      component: 'clientInvoicePaymentReconciliationReportPage',
      data: { pageTitle: gettext('Clients Reconciliations') },
    });
  },
];

export const clientInvoicePaymentReconciliationReport = ng
  .module('reports.clientInvoicePaymentReconciliationReport', ['finances.shared'])
  .service(
    'ClientInvoicePaymentReconciliationReportService',
    ClientInvoicePaymentReconciliationReportService,
  )
  .component(
    'clientInvoicePaymentReconciliationReportPage',
    ClientInvoicePaymentReconciliationReportPage,
  )
  .component(
    'clientInvoicePaymentReconciliationReportContainer',
    ClientInvoicePaymentReconciliationReportContainer,
  )
  .component(
    'clientInvoicePaymentReconciliationReportTable',
    ClientInvoicePaymentReconciliationReportTable,
  )
  .config(reportConfig).name;
