import type ng from 'angular';

export class ClientLogisticPaymentReconciliationReportService {
  $http: ng.IHttpService;
  $httpParamSerializer: any;
  $window: ng.IWindowService;
  constructor($http: ng.IHttpService, $window: ng.IWindowService, $httpParamSerializer: any) {
    this.$http = $http;
    this.$window = $window;
    this.$httpParamSerializer = $httpParamSerializer;
  }

  getReportData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/client-logistic-payment-reconciliation-report/',
      params: params,
    });
  }

  downloadXlsxReport(params: any) {
    this.$window.open(
      `/api/reports/client-logistic-payment-reconciliation-report/?${this.$httpParamSerializer({
        ...params,
        as_xlsx: 1,
        page_size: 999,
      })}`,
      '_blank',
    );
  }
}

ClientLogisticPaymentReconciliationReportService.$inject = [
  '$http',
  '$window',
  '$httpParamSerializer',
];
