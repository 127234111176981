import ng from 'angular';

import { CashflowContainer } from './cashflow-container/cashflow-container.component';
import { CashflowDateModal } from './cashflow-date-modal/cashflow-date-modal.component';
import { CashflowModal } from './cashflow-modal/cashflow-modal.component';
import { CashflowPage } from './cashflow-page/cashflow-page.component';
import { CashflowTable } from './cashflow-table/cashflow-table.component';
import { CashflowService } from './cashflow.service';

export const cashflow = ng
  .module('reports.cashflow', ['finances.shared'])
  .service('CashflowService', CashflowService)
  .component('cashflowContainer', CashflowContainer)
  .component('cashflowTable', CashflowTable)
  .component('cashflowModal', CashflowModal)
  .component('cashflowDateModal', CashflowDateModal)
  .component('cashflowPage', CashflowPage).name;
