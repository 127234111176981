import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './projects-status-report-list-container.html?raw';

export const ProjectsStatusReportListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'moment',
    'GtUtils',
    'ProjectsStatusReportService',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      ProjectsStatusReportService: any;
      count: number;
      data: any;
      filterLevel = 'projects-status-report-list-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      moment: any;
      queryParams: QueryParams = {};
      totals: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        moment: any,
        GtUtils: GtUtilsService,
        ProjectsStatusReportService: any,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.moment = moment;
        this.GtUtils = GtUtils;
        this.ProjectsStatusReportService = ProjectsStatusReportService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.data = [];
        this.totals = {};
        this.count = 0;
        this.queryParams = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'projects-status-report-list-container';
        this.queryParams = { page_size: 10, ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ProjectsStatusReportService.getTableData(this.queryParams).then((data: any) => {
          this.data = data.results;
          this.totals = data.totals;
          this.count = data.count;
          this.GtUtils.overlay('hide');
        });
      }

      updateQueryParams(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }
    },
  ],
};
