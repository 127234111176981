export class PaymentOrdersService {
  PaymentOrderResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.PaymentOrderResource = $resource(
      '/api/finances/payment-orders/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payment-orders/predictions/',
        },
      },
    );
  }

  getPaymentOrders(params: any) {
    return this.PaymentOrderResource.query(params).$promise;
  }
}
PaymentOrdersService.$inject = ['$resource'];
