import React from 'react';

import type { ColumnDef } from '~/shared/ui/components/data-grid';
import { Tag } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

export const LabelRead: React.FC<
  DataTypeProps<string> & { context?: ColumnDef['cellTemplateContext'] }
> = ({ disabled, value, context }) => (
  <Tag disabled={disabled} {...context}>
    {value}
  </Tag>
);
