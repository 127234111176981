import ng from 'angular';

(function () {
  'use strict';
  ng.module('company.legacy').component('teamTable', {
    bindings: {
      filterLevel: '<?',
      users: '<?',
      count: '<?',
      onUpdate: '&',
      openUserModal: '<?',
    },
    template: require('./team-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gettext'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction) {
    const vm = this;

    vm.$onInit = function () {
      vm.theadConfig = getTheadConfig();
    };

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('username / login'),
          columnName: 'username',
          predicate: 'username',
        },

        {
          title: gettext('first name'),
          columnName: 'first_name',
          predicate: 'first_name',
        },
        {
          title: gettext('last name'),
          columnName: 'last_name',
          predicate: 'last_name',
        },
        {
          title: gettext('phone'),
          columnName: 'phone',
        },
        {
          title: gettext('email'),
          columnName: 'email',
          predicate: 'email',
        },
        {
          title: gettext('active'),
          columnName: 'actice',
          predicate: 'is_active',
        },
        {
          title: gettext('access admin'),
          columnName: 'access_admin',
          predicate: 'is_staff',
        },
        {
          title: gettext('superuser'),
          columnName: 'superuser',
          predicate: 'is_superuser',
        },
        {
          title: gettext('business units'),
          columnName: 'business_unit',
        },
        {
          title: gettext('date joined'),
          columnName: 'date_joined',
          predicate: 'date_joined',
        },
        {
          title: gettext('last login'),
          columnName: 'last_login',
          predicate: 'last_login',
        },
        {
          title: gettext('telegram'),
          columnName: 'telegram',
        },
        {
          title: gettext('language'),
          columnName: 'language',
        },
        {
          title: gettext('simple'),
          columnName: 'simple_system',
        },
        {
          title: gettext('wizard'),
          columnName: 'wizard',
        },
      ];

      return config;
    }
  }
})();
