import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BankOperationModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$uibModalInstance',
    'FinancesService',
    'bankOperationData',
    'GtUtils',
    'ClientsService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    FinancesService: FinancesService,
    bankOperationData: any,
    GtUtils: GtUtilsService,
    ClientsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.errors = [];
    vm._invoicesCopy = [];
    vm._creditNotesCopy = [];
    vm.form = undefined;
    vm.fields = undefined;
    vm.save = save;
    vm.close = close;
    vm.destroy = destroy;
    vm.updateData = updateData;
    vm.createPayment = createPayment;
    vm.deletePayment = deletePayment;
    vm.paymentsValid = paymentsValid;
    vm.creditNotePayments = [];
    vm.invoicePayments = [];

    activate();

    ////////////////

    function activate() {
      vm.bankOperation = { ...bankOperationData, invoices: [], credit_notes: [] };
      if (vm.bankOperation.id) {
        updateData().then(() => {
          setWatches();
        });
      } else {
        setWatches();
        vm.fields = getFields();
      }
    }

    function setWatches() {
      $scope.$watch('vm.bankOperation.invoices', updateInvoices);
      $scope.$watch('vm.bankOperation.credit_notes', updateCreditNotes);
      $scope.$watch('vm.invoicePayments', updateAmount, true);
      $scope.$watch('vm.creditNotePayments', updateAmount, true);
    }

    function updateData() {
      return FinancesService.BankOperation.get(
        { id: vm.bankOperation.id },
        (bankOperationDb: any) => {
          return FinancesService.Payment.query(
            {
              bank_operation: bankOperationDb.id,
              with_invoice: 1,
              with_creditnote: 0,
            },
            (invoicePaymentsData: any) => {
              vm.bankOperation = { ...bankOperationDb };
              vm.invoicePayments = invoicePaymentsData.results;
              ng.forEach(vm.invoicePayments, preparePayment);
              vm.bankOperation.invoices = [
                ...vm.invoicePayments.map((payment: any) => payment.finance),
              ];
              vm._invoicesCopy = vm.bankOperation.invoices.map((invoice: any) => invoice);
              return FinancesService.Payment.query(
                { bank_operation: vm.bankOperation.id, with_creditnote: 1 },
                function (data: any) {
                  vm.creditNotePayments = data.results;
                  ng.forEach(vm.creditNotePayments, preparePayment);
                  vm.bankOperation.credit_notes = [
                    ...vm.creditNotePayments.map((payment: any) => payment.credit_note),
                  ];
                  vm._creditNotesCopy = vm.creditNotePayments.map(
                    (payment: any) => payment.credit_note,
                  );
                  vm.fields = getFields();
                },
              ).$promise;
            },
          ).$promise;
        },
      ).$promise;
    }

    function preparePayment(payment: any) {
      payment.fromServer = true;
      payment.amount_to_pay = Math.round(payment.amount_to_pay * 100) / 100;
    }

    function updateInvoices() {
      if (vm._invoicesCopy.length > vm.bankOperation.invoices.length) {
        const invoiceDiff = vm._invoicesCopy.filter(function (invoiceId: any) {
          return vm.bankOperation.invoices.indexOf(invoiceId) < 0;
        });
        const payment = vm.invoicePayments.filter(function (payment: any) {
          return invoiceDiff[0] == (payment.finance_id || payment.finance);
        })[0];

        if (
          payment &&
          deletePayment(payment, vm.bankOperation.invoices, vm.invoicePayments, 'finance') == -1
        ) {
          vm.bankOperation.invoices = vm._invoicesCopy.map((invoice: any) => invoice);
          return;
        }
      }
      if (vm.bankOperation.invoices.length) {
        FinancesService.Finance.query(
          {
            id_list: vm.bankOperation.invoices,
            serializer: 'table_bank_operations',
          },
          function (data: any) {
            ng.forEach(data.results, function (dataEntry: any) {
              let exists = false;
              let deleted = false;
              dataEntry.amount_to_pay = Math.round(dataEntry.amount_to_pay * 100) / 100;
              ng.forEach(vm.invoicePayments, function (invoicePayment: any) {
                let toDelete = true;
                const financeId = invoicePayment.finance_id || invoicePayment.finance;
                ng.forEach(data.results, function (inlineData: any) {
                  if (inlineData.finance_id == financeId) {
                    toDelete = false;
                  }
                });
                const idx = vm.invoicePayments.indexOf(invoicePayment);
                if (toDelete && idx != -1) {
                  vm.invoicePayments.splice(idx, 1);
                  deleted = true;
                }
                if (dataEntry.finance_id == financeId && !toDelete) {
                  exists = true;
                  assignPaymentProps(invoicePayment, dataEntry, 'finance_id');
                }
                if (invoicePayment.fromServer && dataEntry.finance_id == financeId) {
                  invoicePayment.fromServer = false;
                  assignPaymentProps(invoicePayment, dataEntry, 'finance_id');
                }
              });
              if (!exists && !deleted) {
                vm.invoicePayments.push(dataEntry);
              }
            });
          },
        );
      } else {
        vm.invoicePayments = [];
      }
    }

    function updateCreditNotes() {
      if (vm._creditNotesCopy.length > vm.bankOperation.credit_notes?.length) {
        const creditNoteDiff = vm._creditNotesCopy.filter(function (invoiceId: any) {
          return vm.bankOperation.invoices.indexOf(invoiceId) < 0;
        });
        const payment = vm.creditNotePayments.filter(function (payment: any) {
          return creditNoteDiff[0] == (payment.credit_note_id || payment.credit_note);
        })[0];

        if (
          payment &&
          deletePayment(payment, vm.bankOperation.invoices, vm.invoicePayments, 'credit_note') == -1
        ) {
          vm.bankOperation.credit_notes = vm._creditNotesCopy.map((creditNote: any) => creditNote);
          return;
        }
      }
      if (vm.bankOperation.credit_notes?.length) {
        FinancesService.CreditNote.query(
          {
            id_list: vm.bankOperation.credit_notes,
            serializer: 'table_bank_operations',
          },
          function (data: any) {
            ng.forEach(data.results, function (dataEntry: any) {
              let exists = false;
              let deleted = false;
              dataEntry.amount_to_pay = Math.round(dataEntry.amount_to_pay * 100) / 100;
              ng.forEach(vm.creditNotePayments, function (creditNotePayment: any) {
                let toDelete = true;
                const creditNoteId = creditNotePayment.finance_id || creditNotePayment.finance;
                ng.forEach(data.results, function (inlineData: any) {
                  if (inlineData.credit_note_id == creditNoteId) {
                    toDelete = false;
                  }
                });
                const idx = vm.creditNotePayments.indexOf(creditNotePayment);
                if (toDelete && idx != -1) {
                  vm.creditNotePayments.splice(idx, 1);
                  deleted = true;
                }
                if (dataEntry.credit_note_id == creditNoteId && !toDelete) {
                  exists = true;
                  assignPaymentProps(creditNotePayment, dataEntry, 'credit_note_id');
                }
                if (creditNotePayment.fromServer && dataEntry.credit_note_id == creditNoteId) {
                  creditNotePayment.fromServer = false;
                  assignPaymentProps(creditNotePayment, dataEntry, 'credit_note_id');
                }
              });
              if (!exists && !deleted) {
                vm.creditNotePayments.push(dataEntry);
              }
            });
          },
        );
      } else {
        vm.creditNotePayments = [];
      }
    }

    function assignPaymentProps(payment: any, paymentFromServer: any, financeFk: any) {
      payment[financeFk] = paymentFromServer[financeFk];
      payment.number = paymentFromServer.number;
      payment.total_amount = paymentFromServer.total_amount;
      payment.amount_paid = paymentFromServer.amount_paid;
      payment.amount_to_pay = paymentFromServer.amount_to_pay;
    }

    function paymentsValid() {
      const invoicePaymentsValid = vm.invoicePayments.every(function (invoicePayment: any) {
        let hasFinAccounts = invoicePayment.debit_fin_account && invoicePayment.credit_fin_account;
        if (invoicePayment.commission) {
          hasFinAccounts =
            hasFinAccounts &&
            invoicePayment.commission_debit_fin_account &&
            invoicePayment.commission_credit_fin_account;
        }
        return hasFinAccounts;
      });
      const creditNotePaymentsValid = vm.creditNotePayments.every(function (
        creditNotePayment: any,
      ) {
        let hasFinAccounts =
          creditNotePayment.debit_fin_account && creditNotePayment.credit_fin_account;
        if (creditNotePayment.commission) {
          hasFinAccounts =
            hasFinAccounts &&
            creditNotePayment.commission_debit_fin_account &&
            creditNotePayment.commission_credit_fin_account;
        }
        return hasFinAccounts;
      });
      return invoicePaymentsValid && creditNotePaymentsValid;
    }

    function updateAmount() {
      let newAmount = 0;
      ng.forEach(vm.invoicePayments, function (invoicePayment: any) {
        newAmount += (invoicePayment.amount || 0) - (invoicePayment.commission || 0);
      });
      ng.forEach(vm.creditNotePayments, function (creditNotePayment: any) {
        newAmount += (creditNotePayment.amount || 0) - (creditNotePayment.commission || 0);
      });
      vm.bankOperation.amount = Math.round(newAmount * 100) / 100;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete this bank operation?');
      if (!confirm(msg)) {
        return;
      }
      FinancesService.BankOperation.delete({ id: vm.bankOperation.id }, function () {
        GtUtils.notify(gettext('Bank operation removed'));
        close(null, true);
      });
    }

    function save(closeAfter: any) {
      vm.form.$invalid = true;
      if (!vm.bankOperation.id) {
        return FinancesService.BankOperation.save(
          vm.bankOperation,
          function (data: any) {
            vm.bankOperation.id = data.id;
            ng.forEach(vm.invoicePayments, createPayment);
            ng.forEach(vm.creditNotePayments, createPayment);
            if (closeAfter) {
              close(data, true);
            }
            GtUtils.notify(gettext('Bank operation saved'));
          },
          _error,
        ).$promise;
      } else {
        return FinancesService.BankOperation.update(
          vm.bankOperation,
          function (data: any) {
            vm.bankOperation.id = data.id;
            ng.forEach(vm.invoicePayments, createPayment);
            ng.forEach(vm.creditNotePayments, createPayment);
            if (closeAfter) {
              close(data, true);
            }
            GtUtils.notify(gettext('Bank operation saved'));
          },
          _error,
        ).$promise;
      }
    }

    function createPayment(payment: any) {
      payment.bank_account = vm.bankOperation.bank_account;
      payment.date = vm.bankOperation.operation_date;
      if (payment.finance_id) {
        payment.finance = payment.finance_id;
      }
      if (payment.credit_note_id) {
        payment.credit_note = payment.credit_note_id;
      }
      payment.finance = payment.finance_id;
      if (!vm.bankOperation.id) {
        // @ts-ignore
        save().then(function () {
          vm.form.$invalid = false;
          if (payment.id) {
            payment.bank_operation = vm.bankOperation.id;
            FinancesService.Payment.update(
              payment,
              function (data: any) {
                GtUtils.notify(gettext('Payment updated'));
                payment.id = data.id;
              },
              (e: any) => GtUtils.errorClb(e),
            );
          } else {
            payment.bank_operation = vm.bankOperation.id;
            FinancesService.Payment.save(
              payment,
              function (data: any) {
                GtUtils.notify(gettext('Payment saved'));
                payment.id = data.id;
              },
              (e: any) => GtUtils.errorClb(e),
            );
          }
        });
      } else if (payment.id) {
        payment.bank_operation = vm.bankOperation.id;
        FinancesService.Payment.update(
          payment,
          function (data: any) {
            GtUtils.notify(gettext('Payment updated'));
            payment.id = data.id;
          },
          (e: any) => GtUtils.errorClb(e),
        );
      } else {
        payment.bank_operation = vm.bankOperation.id;
        FinancesService.Payment.save(
          payment,
          function (data: any) {
            GtUtils.notify(gettext('Payment saved'));
            payment.id = data.id;
          },
          (e: any) => GtUtils.errorClb(e),
        );
      }
    }

    function deletePayment(payment: any, financeList: any, paymentList: any, mode: any) {
      if (!confirm(gettext('Delete payment?'))) {
        return -1;
      }
      FinancesService.Payment.delete(
        { id: payment.id },
        function () {
          const index = financeList.indexOf(payment[mode + '_id'] || payment[mode]);
          if (index !== -1) {
            financeList.splice(index, 1);
          }
          const paymentIndex = paymentList.indexOf(payment);
          if (paymentIndex !== -1) {
            paymentList.splice(paymentIndex, 1);
          }
          updateAmount();
          save(false);
        },
        _error,
      );
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.bankOperation.errors = data.data;
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col4: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          // label: gettext('PAYMENT')
        },
        fieldGroup: [
          {
            key: 'operation_date',
            type: 'gt-date-select',
            defaultValue: new Date(),
            templateOptions: {
              label: gettext('Date of conduction'),
              placeholder: gettext('date'),
              hint: gettext('When you have conducted money'),
              type: 'date',
              required: true,
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'amount',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Amount'),
              placeholder: gettext('Operation amount'),
              type: 'number',
              required: true,
              maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
              // for example 100 000 000,200
            },
          },
        ],
      });
      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          // label: gettext('CONDITIONS')
        },
        fieldGroup: [
          {
            key: 'bank_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Bank Account'),
              resource: 'finances.BankAccount',
              required: true,
            },
          },
          {
            key: 'business_unit',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Busines Unit'),
              resource: 'core.BusinessUnit',
            },
          },
        ],
      });
      col3.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            key: 'operation_type',
            type: 'gt-select',
            defaultValue: 'credit',
            templateOptions: {
              label: gettext('Operation type'),
              placeholder: gettext('Choose operation type'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: gettext('Debit'), value: 'debit' },
                { name: gettext('Credit'), value: 'credit' },
              ],
            },
          },
          {
            key: 'counterparty',
            type: 'gt-ui-select',
            templateOptions: {
              // Add client but display only clients with role
              label: gettext('Related counterparty'),
              placeholder: gettext('Choose counterparty'),
              addPerms: ['add_client'],
              addIcon: GtUtils.getIcon('clients.Client'),
              required: true,
              addFunc: () => {
                // Need to not return id
                ClientsService.clientModal();
              },
              resource: 'clients.Client',
              hint: gettext('Pick a counterparty that will be bound to this ' + 'invoice'),
            },
          },
          {
            key: 'additional_info',
            type: 'gt-textarea',
            templateOptions: {
              label: gettext('Description'),
              placeholder: gettext('Write down the specific information about this bank operation'),
              className: 'additional-info',
            },
          },
        ],
      });
      col4.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          // label: gettext('OTHER')
        },
        fieldGroup: [
          {
            key: 'invoices',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('Invoices'),
              hint: gettext('invoices'),
              resource: 'finances.Finance',
              getQueryParams: () => {
                const queryParams = { unpaid: 1 };
                if (vm.bankOperation.operation_type == 'credit') {
                  // @ts-ignore
                  queryParams.clientrole_from__client = vm.bankOperation.counterparty;
                } else {
                  // @ts-ignore
                  queryParams.clientrole_to__client = vm.bankOperation.counterparty;
                }
                return queryParams;
              },
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.counterparty',
            },
          },
          {
            key: 'credit_notes',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('Credit notes'),
              hint: gettext('credit notes'),
              resource: 'finances.CreditNote',
            },
          },
        ],
      });

      return [col1, col2, col3, col4];
    }
  }
})();
