import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './costs-report-table.html?raw';

export const CostsReportTable = {
  bindings: {
    filterLevel: '<',
    reportData: '<',
    selectedGroups: '<',
    savedFilterChoices: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      $rootScope: GtRootScopeService;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      _columnsMap: any;
      config: any;
      defaultCurrency: any;
      filterLevel = 'costs-report-table';
      gettext: ng.gettext.gettextFunction;
      groups: any;
      queryParams: QueryParams = {};
      ref: any;
      reportData: any;
      selectedGroups: any;
      sets: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.queryParams = {};
        this.selectedGroups = [];
        this.config = {};
        this.sets = {
          groups: { title: this.gettext('Groups'), group: 'groups' },
          details: { title: this.gettext('Details'), group: 'details', hidden: true },
          bu: { title: this.gettext('BU / Client'), group: 'bu', hidden: true },
          comments: { title: this.gettext('Comments'), group: 'comments', hidden: true },
          amounts: { title: this.gettext('Amounts'), group: 'amounts' },
          amountMinusInvoiced: {
            title: this.gettext('To be invoiced'),
            group: 'amountMinusInvoiced',
            hidden: true,
          },
          amountMinusPaid: {
            title: this.gettext('Forecasted - Payment'),
            group: 'amountMinusPaid',
            hidden: true,
          },
          invoicedMinusPaid: {
            title: this.gettext('Invoices to be paid'),
            group: 'invoicedMinusPaid',
          },
          factMinusPlan: { title: this.gettext('Fact - Plan'), group: 'factMinusPlan' },
          vat: { title: this.gettext('VAT'), group: 'vat' },
          plannedCosts: { title: this.gettext('Planned costs'), group: 'plannedCosts' },
        };
        this.groups = [];
        this.ref = undefined;
        this._columnsMap = {};
        this.reportData = [];
      }

      $onInit() {
        this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (ev: any, queryParams: QueryParams) => {
            this.queryParams = queryParams;
          },
        );
        this.updateTable();
        this.ref = this.$state.href('finances.contractCharges');
        this.defaultCurrency = this.$rootScope.user.settings.DEFAULT_CURRENCY;
      }

      prepareTable() {
        this.reportData.forEach((row: any) => {
          row.levelClass = this.getRowLevelClass(row.level);
          this.config.columns
            .filter((col: any) => col.type === 'agg')
            .forEach((col: any) => {
              row[col.title] = {
                value: row[col.predicate],
                valueLocal: row[col.predicateLocal],
                currency: row.currency?.symbol,
                showLocal:
                  row.currency?.symbol &&
                  row.currency?.symbol !== this.defaultCurrency &&
                  row[col.predicateLocal],
                class:
                  (row[col.predicate] < 0 && 'negative_number') ||
                  (row[col.predicate] > 0 && 'positive_number') ||
                  '',
              };
            });
          this.config.columns
            .filter((col: any) => col.type !== 'agg')
            .map((col: any) => ({
              ...this.defaultColumnData(),
              ...col,
            }))
            .forEach((col: any) => {
              row[col.title] = {
                title: col.getTitle(row),
                sref: col.sref(row),
                href: col.href(row),
                icon: col.getIcon(row),
                class: col.ngClass(row),
                ...((col.expandIcon && { expandIcon: () => col.expandIcon(row) }) || {}),
                additionalData: col
                  .additionalData(row)
                  .map((addDataRow: any) => ({
                    ...this.defaultAdditionalData(),
                    ...addDataRow,
                  }))
                  .map((addDataRow: any) => ({
                    name: addDataRow.name,
                    title: addDataRow.getTitle(row),
                    class: addDataRow.getClass(row),
                    sref: addDataRow.sref(row),
                    href: addDataRow.href(row),
                    icon: addDataRow.getIcon(row),
                    newRow: addDataRow.newRow,
                    dataTip: addDataRow.getDataTip?.(row),
                  })),
              };
            });
        });
      }

      showAggLocal(row: any, column: any) {
        return (
          row.currency?.symbol &&
          row.currency?.symbol !== this.defaultCurrency &&
          row[column.predicateLocal]
        );
      }
      defaultColumnData() {
        return {
          sref: () => {},
          href: () => {},
          getIcon: () => {},
          getTitle: () => {},
          ngClass: () => {},
          additionalData: () => [],
        };
      }
      defaultAdditionalData() {
        return {
          sref: () => {},
          href: () => {},
          getIcon: () => {},
          getTitle: () => {},
          ngClass: () => {},
          newRow: false,
        };
      }
      getGroupColumnForRow(row: any) {
        return {
          ...this.defaultColumnData(),
          ...(this._columnsMap[row.group_data] || {}),
        };
      }

      rowVisibility(row: any) {
        return row.level === 0 || !!row.parent.expand;
      }
      getRowLevelClass(level: any) {
        return {
          'highlighted-tr-level-0': level === 0,
          'highlighted-tr-level-1': level === 1,
          'highlighted-tr-level-2': level === 2,
          'highlighted-tr-level-3': level === 3,
          'highlighted-tr-level-4': level === 4,
          'highlighted-tr-level-5': level === 5,
        };
      }

      columnVisibility(column: any) {
        return !column.set.hidden && !column.hidden;
      }

      showLevel(level: any) {
        this.reportData.forEach((v: any) => (v.expand = v.level < level));
      }

      updateVisibility(row: any, expand: any) {
        row.expand = expand;
        if (!expand) {
          this.reportData
            .filter((v: any) => v.parentIndex === row.index)
            .forEach((x: any) => {
              this.updateVisibility(x, expand);
            });
        }
      }

      $onChanges(changes: any) {
        if (changes.selectedGroups) {
          this.updateTable();
        }
        if (changes.reportData && this.reportData?.length) {
          this.prepareTable();
        }
      }

      updateTable() {
        this.config = this.getTheadConfig();
      }

      getContractchargeRef(row: any) {
        if (!row?.contractcharge) {
          return '';
        }
        let res = `${this.$state.href('finances.contractCharges')}`;
        res = `${res}?charges_list=${row.contractcharge?.charge_id}`;
        if (row.contractcharge?.passport_id) {
          res = `${res}&passport_list=${row.contractcharge.passport_id}`;
        }
        if (row.contractcharge?.contract_id) {
          res = `${res}&contract_list=${row.contractcharge.contract_id}`;
        }
        if (row.client?.id) {
          res = `${res}&client_list=${row.client.id}`;
        }
        return res;
      }

      getTheadConfig() {
        const selected = this.selectedGroups.map((v: any) => v.param);
        const config: any = { tabs: [], columns: [], columnSets: Object.values(this.sets) };
        // @ts-ignore
        config.class = 'costs-report-table table';
        config.tabs = [];
        config.columnSets = Object.values(this.sets);
        config.columns = [
          {
            title: '#',
            columnName: '',
            predicate: '',
            type: 'expand',
            group: 'groups',
            set: this.sets.groups,
            expandIcon: (row: any) => ({
              fa: row.level <= this.selectedGroups.length,
              'fa fa-square-plus': row.level <= this.selectedGroups.length && !row.expand,
              'fa fa-square-minus': row.level <= this.selectedGroups.length && row.expand,
            }),
            columnClass: 'column-number',
          },
          {
            title: this.gettext('Group'),
            columnName: 'group',
            predicate: 'group_data',
            type: 'group',
            group: 'groups',
            columnClass: 'column-group',
            set: this.sets.groups,
            getIcon: (row: any) =>
              row.level === 0 ? 'fa fa-pie-chart' : this.getGroupColumnForRow(row).getIcon(row),
            getTitle: (row: any) =>
              row.level === 0 ? 'TOTAL' : this.getGroupColumnForRow(row).getTitle(row),
            ngClass: (row: any) => this.getGroupColumnForRow(row).ngClass(row),
            expandIcon: (row: any) => ({
              fa: row.level <= this.selectedGroups.length,
              'fa fa-square-plus': row.level <= this.selectedGroups.length && !row.expand,
              'fa fa-square-minus': row.level <= this.selectedGroups.length && row.expand,
            }),
            sref: (row: any) => this.getGroupColumnForRow(row).sref(row),
            href: (row: any) => this.getGroupColumnForRow(row).href(row),
            additionalData: (row: any) => this.getGroupColumnForRow(row).additionalData(row),
          },
          {
            title: this.gettext('Final total'),
            columnName: 'final_total',
            predicate: 'final_total',
            type: 'group',
            group: 'groups',
            set: this.sets.groups,
            hidden: true,
            getIcon: () => 'fa fa-pie-chart',
            getTitle: () => 'TOTAL',
          },
          {
            title: this.gettext('Cost / Gain'),
            hidden: selected.includes('contractcharge'),
            predicate: 'contractcharge',
            columnName: 'contractCharge',
            type: 'details',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-details',
            additionalData: () => [
              {
                name: 'status',
                getTitle: (row: any) => row.contractcharge?.status,
                getClass: (row: any) =>
                  (
                    ({
                      validated: 'label label_success pull-right',
                      forecast: 'label label_info pull-right',
                      under_discussion: 'label label_default pull-right',
                      pending_confirmation: 'label label_warning pull-right',
                      planned: 'label label-default pull-right',
                      cancelled: 'label label_danger pull-right',
                    }) as any
                  )[row.contractcharge?.status],
                getDataTip: (row: any) =>
                  (
                    ({
                      planned: this.gettext(
                        this.GtUtils.translate('Not used in P/L and totals calculations'),
                      ),
                      cancelled: this.gettext(
                        this.GtUtils.translate('Not used in P/L and totals calculations'),
                      ),
                    }) as any
                  )[row.contractcharge?.status],
              },
              {
                name: 'is_gain',
                getClass: (row: any) =>
                  (row.contractcharge?.is_gain && 'label label_success pull-right') || '',
                getTitle: (row: any) => (row.contractcharge?.is_gain && 'gain') || '',
              },
            ],
            getIcon: () => 'fa fa-building',
            getTitle: (row: any) =>
              (this.$rootScope.user.profile.language === 'en' && row.contractcharge?.title_eng) ||
              row.contractcharge?.title,
            href: (row: any) => this.getContractchargeRef(row),
          },
          {
            title: this.gettext('Company group'),
            hidden: selected.includes('company_title'),
            predicate: 'company_title',
            columnName: 'companyGroup',
            type: 'details',
            group: 'details',
            set: this.sets.bu,
            columnClass: 'column-details',
            getIcon: () => 'fa fa-building-circle-arrow-right',
            getTitle: (row: any) => row.company_title,
          },
          {
            title: this.gettext('Counterparty'),
            hidden: selected.includes('client'),
            predicate: 'client',
            predicateField: 'name',
            columnName: 'client',
            type: 'details',
            group: 'details',
            set: this.sets.bu,
            columnClass: 'column-details',
            getIcon: () => 'fa fa-building',
            getTitle: (row: any) => row.client?.name || '',
            sref: () => 'gt.page.client(row.client)',
          },
          {
            title: this.gettext('Contract'),
            columnName: 'contract',
            predicate: 'contract',
            type: 'details',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-details',
            additionalData: () => [
              {
                name: 'client',
                newRow: true,
                getTitle: (row: any) => row.contract?.client?.name,
                sref: () => 'gt.page.client(row.contract.client)',
                getClass: () => 'label smaller_label',
                getIcon: () => 'fa fa-building',
              },
            ],
            getIcon: () => 'fa fa-file-text',
            getTitle: (row: any) => row.contract?.contract_number || '',
            ngClass: (row: any) => ({
              'label label_info': row.contractcharge?.contract_id,
            }),
            sref: () => 'gt.page.contract(row.contract)',
          },
          {
            title: this.gettext('Contract BU'),
            columnName: 'contractBusinessUnit',
            predicate: 'contract_bu_title',
            type: 'details',
            group: 'bu',
            set: this.sets.bu,
            columnClass: 'column-details',
            getIcon: () => '',
            getTitle: (row: any) => row.contract_bu_title,
          },
          {
            title: this.gettext('Passport'),
            predicate: 'passport',
            predicateField: 'title',
            columnName: 'passport',
            type: 'details',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-details',
            getIcon: (row: any) => (row.passport?.id ? 'fa fa-exchange' : ''),
            getTitle: (row: any) => row.passport?.title,
            ngClass: (row: any) => ({
              'label label_info': row.contractcharge?.passport_id,
            }),
            sref: (row: any) => (row.passport?.id ? 'gt.page.passport(row.passport)' : ''),
          },
          {
            title: this.gettext('Passport BU'),
            hidden: selected.includes('passport_bu_title'),
            predicate: 'passport_bu_title',
            columnName: 'passportBusinessUnit',
            type: 'details',
            group: 'bu',
            set: this.sets.bu,
            columnClass: 'column-details',
            getIcon: () => '',
            getTitle: (row: any) => row.passport_bu_title,
          },
          {
            title: this.gettext('Charge'),
            hidden: true,
            predicate: 'charge_title',
            columnName: 'chargeTitle',
            type: 'details',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-details',
            getIcon: () => 'fa fa-coins',
            getTitle: (row: any) =>
              (this.$rootScope.user.profile.language === 'en' && row.charge_title_eng) ||
              row.charge_title,
          },
          {
            title: this.gettext('Parent charge'),
            hidden: true,
            predicate: 'parent_charge_title',
            columnName: 'parentChargeTitle',
            type: 'details',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-details',
            getIcon: () => 'fa fa-coins',
            getTitle: (row: any) =>
              (this.$rootScope.user.profile.language === 'en' && row.parent_charge_title_eng) ||
              row.parent_charge_title,
          },
          {
            title: this.gettext('Currency'),
            hidden: true,
            columnName: 'currency',
            predicate: 'currency',
            type: 'details',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-details',
            getIcon: () => 'fa fa-currency',
            getTitle: (row: any) => row.currency?.symbol,
          },
          {
            title: this.gettext('Note'),
            columnName: 'note',
            type: 'details',
            predicate: 'additional_info',
            group: 'comments',
            set: this.sets.comments,
            columnClass: 'column-details',
            ngClass: () => ({ message: true, comments: true }),
            getIcon: () => 'fa fa-comment',
            getTitle: (row: any) => row.additional_info,
          },
          {
            title: this.gettext('Trader Note'),
            columnName: 'traderNote',
            type: 'details',
            predicate: 'trader_note',
            group: 'comments',
            set: this.sets.comments,
            columnClass: 'column-details',
            ngClass: () => ({ message: true, comments: true }),
            getIcon: () => 'fa fa-comment-text',
            getTitle: (row: any) => row.trader_note,
          },
          {
            title: this.gettext('Planned costs'),
            type: 'agg',
            columnName: 'plannedCosts',
            group: 'plannedCosts',
            predicateLocal: 'planned_costs_amount',
            predicate: 'planned_costs_amount_default',
            set: this.sets.plannedCosts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Price / t'),
            columnName: 'priceT',
            type: 'agg',
            predicate: 'price_per_t_default',
            predicateLocal: 'price_per_t',
            group: 'details',
            set: this.sets.details,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Exec forecasted amount'),
            type: 'agg',
            group: 'amounts',
            columnName: 'amount',
            predicateLocal: 'amount',
            predicate: 'amount_default',
            set: this.sets.amounts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Our amount'),
            type: 'agg',
            columnName: 'ourAmount',
            predicateLocal: 'our_amount',
            predicate: 'our_amount_default',
            set: this.sets.amounts,
            hidden: this.$rootScope.user.settings.SIMPLE_CHARGES,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Counterparty amount'),
            type: 'agg',
            columnName: 'counterpartyAmount',
            predicateLocal: 'counterparty_amount',
            predicate: 'counterparty_amount_default',
            set: this.sets.amounts,
            hidden: this.$rootScope.user.settings.SIMPLE_CHARGES,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Analytical amount'),
            type: 'agg',
            columnName: 'analyticalAmount',
            predicateLocal: 'analytical_amount',
            predicate: 'analytical_amount_default',
            set: this.sets.amounts,
            hidden: this.$rootScope.user.settings.SIMPLE_CHARGES,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Invoiced'),
            type: 'agg',
            group: 'amounts',
            columnName: 'invoiced',
            predicateLocal: 'invoiced',
            predicate: 'invoiced_default',
            set: this.sets.amounts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Invoiced canceled'),
            type: 'agg',
            group: 'amounts',
            columnName: 'canceled',
            predicateLocal: 'canceled',
            predicate: 'canceled_default',
            set: this.sets.amounts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Paid'),
            type: 'agg',
            group: 'amounts',
            columnName: 'paid',
            predicateLocal: 'paid',
            predicate: 'paid_default',
            set: this.sets.amounts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('To pay'),
            type: 'agg',
            hidden: true,
            group: 'amounts',
            columnName: 'to_pay',
            predicateLocal: 'to_pay',
            predicate: 'to_pay_default',
            set: this.sets.amounts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('To be invoiced'),
            columnName: 'amountMinusInvoiced',
            type: 'agg',
            group: 'amountMinusInvoiced',
            predicateLocal: 'delta_amount_invoiced',
            predicate: 'delta_amount_invoiced_default',
            set: this.sets.amountMinusInvoiced,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Forecasted - Payment'),
            columnName: 'amountMinusPaid',
            type: 'agg',
            group: 'amountMinusPaid',
            predicateLocal: 'delta_amount_paid',
            predicate: 'delta_amount_paid_default',
            set: this.sets.amountMinusPaid,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Invoices to be paid'),
            type: 'agg',
            columnName: 'invoicedMinusPaid',
            group: 'invoicedMinusPaid',
            predicateLocal: 'delta_invoiced_paid',
            predicate: 'delta_invoiced_paid_default',
            set: this.sets.invoicedMinusPaid,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Fact - Plan'),
            type: 'agg',
            columnName: 'factMinusPlan',
            group: 'factMinusPlan',
            predicateLocal: 'delta_fact_plan',
            predicate: 'delta_fact_plan_default',
            set: this.sets.factMinusPlan,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('Discount'),
            type: 'agg',
            group: 'amounts',
            columnName: 'discount',
            predicateLocal: 'discount',
            predicate: 'discount_default',
            set: this.sets.amounts,
            columnClass: 'column-agg',
          },
          {
            title: this.gettext('VAT'),
            type: 'agg',
            columnName: 'vat',
            group: 'vat',
            predicateLocal: 'vat',
            predicate: 'vat_default',
            set: this.sets.vat,
            columnClass: 'column-agg',
          },
        ];
        config.columnSets.forEach((item: any) => {
          // @ts-ignore
          item.count = config.columns.filter((v) => v.set === item).length;
        });
        this._columnsMap = config.columns.reduce((res: any, col: any) => {
          res[col.predicate] = col;
          return res;
        }, {});
        return config;
      }
    },
  ],
};
