import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss',
)({
  component: () => (
    <div>
      Hello /_auth/_sidebar/deals/multiservices/_multiservice/$multiserviceId/profit-and-loss!
    </div>
  ),
});
