import React from 'react';

import type { IconComponent } from '../types';

export const createIconComponent = (
  svgContent: string,
  displayName: string,
  viewBox = '0 0 16 16',
): IconComponent => {
  const Icon = ({ size = 16, className = '', ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      height={size}
      className={className}
      fill="none"
      stroke="currentColor"
      dangerouslySetInnerHTML={{ __html: svgContent }}
      {...props}
    />
  );

  Icon.displayName = displayName;
  return Icon;
};
