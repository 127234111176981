import ng from 'angular';

import { ContractStatusPage } from './contract-status-page/contract-status-page.component';
import { ContractStatusTable } from './contract-status-table/contract-status-table.component';
import { ContractStatusService } from './contract-status.service';

export const contractStatus = ng
  .module('reports.contractStatus', [])
  .service('ContractStatusService', ContractStatusService)
  .component('contractStatusTable', ContractStatusTable)
  .component('contractStatusPage', ContractStatusPage).name;
