import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './sale-export-contract-allocations-table-view.html?raw';

export const SaleExportContractAllocationsTableView = {
  bindings: {
    filterLevel: '<',
    allocations: '<',
    saveItem: '&',
    deleteItem: '&',
    onContractSelect: '&',
    readonly: '<?',
    columns: '<?',
    tableName: '<?',
    exportContractQueryParams: '<?',
    saleContractQueryParams: '<?',
    passportQueryParams: '<?',
  },
  template,
  controller: [
    '$window',
    '$rootScope',
    'gettext',
    class SaleExportContractAllocationTableComponent {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      allocations: any;
      applyFilters: any;
      columns: any;
      count: number;
      deleteItem: any;
      exportContractQueryParams: QueryParams = {};
      filterLevel = 'sale-export-contract-allocations-table-view';
      gettext: ng.gettext.gettextFunction;
      initQueryParams: QueryParams = {};
      list: any;
      numberInput: any;
      onContractSelect: any;
      passportQueryParams: QueryParams = {};
      readonly: any;
      saleContractQueryParams: QueryParams = {};
      saveItem: any;
      selection: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      updateData: any;
      constructor(
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gettext = gettext;
        this.$window = $window;
        this.$rootScope = $rootScope;

        this.list = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
        this.allocations = [];
        this.readonly = false;

        this.initQueryParams = {};
        this.columns = ['actions', 'saleContract', 'exportContract', 'volume'];
      }
      $onInit() {
        this.tableData = { rows: this.allocations };
        this.tableOptions = this.getTableOptions();
        this.tableName = this.tableName || 'sale-export-contract-allocations-table';
      }
      $onChanges() {
        this.tableName = this.tableName || 'sale-export-contract-allocations-table-view';
        if (this.allocations) {
          this.tableData = {
            rows: this.allocations,
            count: this.allocations.length,
            totals: this.allocations
              .filter((v: any) => v.id)
              .reduce((res: any, curr: any) => res + curr.volume, 0),
          };
          this.tableOptions = this.getTableOptions();
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add',
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: true,
          showMore: true,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            deleteItem: (item: any) => this.deleteItem({ item: item }),
            updateData: () => this.updateData(),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            onContractSelect: (item: any) => this.onContractSelect({ selectedItem: item }),
            $rootScope: this.$rootScope,
            readonly: this.readonly,
            exportContractQueryParams: this.exportContractQueryParams,
            saleContractQueryParams: this.saleContractQueryParams,
            passportQueryParams: this.passportQueryParams,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [] as any[],
        };
        if (!this.readonly) {
          options.columnDefs.push(
            {
              columnName: 'saleContract',
              class: 'td-left-align',
              title: this.gettext('Sale contract'),
              cellTemplate: /*html*/ `
              <div ng-if="!item._editMode && item.id">
                <span>
                  <a ui-sref="gt.page.contract({id: item.sale_contract })">
                    <i class="fa fa-file-text"></i>
                    {[{ item.sale_contract_number || '---'  | cut:true:30:' ' }]}
                  </a>
                </span>
              </div>
              <div ng-if="item._editMode || !item.id">
                <span>
                    <gt-resource-select
                      ng-model="item.sale_contract"
                      placeholder="'Sale contract'|translate"
                      resource-name="'contracts.salecontract'"
                      query-params="args.saleContractQueryParams"
                      allow-clear="false"
                      on-select="args.onContractSelect"
                      on-select-args="[item]"
                      disabled="true"
                    ></gt-resource-select>
                </span>
              </div>
            `,
            },
            {
              columnName: 'exportContract',
              class: 'td-left-align',
              title: this.gettext('Export Contract'),
              cellTemplate: /*html*/ `
              <div ng-if="!item._editMode && item.id">
                <span>
                  <a ui-sref="gt.page.contract({ id: item.export_contract })">
                    <i class="fa fa-file-text"></i>
                    {[{ item.export_contract_number || '---'  | cut:true:30:' ' }]}
                  </a>
                </span>
              </div>
              <div ng-if="item._editMode || !item.id">
                <span>
                  <gt-resource-select
                    ng-model="item.export_contract"
                    placeholder="'Export contract'|translate"
                    resource-name="'contracts.exportcontract'"
                    query-params="args.exportContractQueryParams"
                    allow-clear="false"
                    on-select="args.onContractSelect"
                    on-select-args="[item]"
                  ></gt-resource-select>
                </span>
              </div>
            `,
            },
            {
              columnName: 'passport',
              class: 'td-left-align',
              title: this.gettext('Passport'),
              cellTemplate: /*html*/ `
              <div ng-if="!item._editMode && item.id">
                <span>
                  <a ui-sref="gt.page.passport({ id: item.passport })">
                    <i class="fa fa-file-text"></i>
                    {[{ item.passport_title || '---'  | cut:true:30:' ' }]}
                  </a>
                </span>
              </div>
              <div ng-if="item._editMode || !item.id">
                <span>
                  <gt-resource-select
                    ng-model="item.passport"
                    placeholder="'Passport'|translate"
                    resource-name="'passports.passport'"
                    query-params="args.passportQueryParams"
                  ></gt-resource-select>
                </span>
              </div>
            `,
            },
            {
              columnName: 'volume',
              class: 'td-left-align',
              title: this.gettext('Volume'),
              cellTemplate: /*html*/ `
            <div ng-if="!item._editMode && item.id">
              <span class="label-strong">
                {[{ item.volume || 0 | number: 3  }]}
                <span class="smaller-label">
                {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              </span>
            </div>
            <div ng-if="item._editMode || !item.id">
              <input
                class="form-control"
                placeholder="{[{ 'Volume'|translate }]}"
                type="number"
                ng-model="item.volume"
                ng-change="args.calculateChargePrice(item, 't')"
              />
`,
              totalTemplate: /*html*/ `
            {[{ item.volume || 0 | number:3 }]}
            <span class="smaller-label">
            {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
            },
            {
              columnName: 'actions',
              class: 'td-left-align',
              title: '',
              cellTemplate: /*html*/ `
              <div ng-if="!args.readonly">
                <div class="clearfix"></div>
                <div ng-if="!item._editMode && item.id" class="edit">
                  <a
                    class="btn btn-xs btn-blue-border hover-element"
                    ng-click="item._editMode = true"
                  >
                    <i class="fa fa-pencil-square"></i>
                  </a>
                </div>
                <div ng-if="item._editMode || !item.id" class="edit">
                  <div class="input-group-btn">
                    <a
                      class="btn-success btn_success btn btn-md"
                      ng-click="args.saveItem(item); item._editMode = false;"
                    >
                      <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                    </a>
                  </div>
                  <div ng-if="item.id && item._editMode" class="input-group-btn">
                    <a class="btn btn-xs col-xs-12" ng-click="item._editMode = false">
                      <i class="fa fa-cancel"></i> <translate>Cancel</translate>
                    </a>
                  </div>
                  <div ng-if="item.id" class="input-group-btn">
                    <a
                      class="btn btn-xs btn-danger btn_danger col-xs-12"
                      ng-click="args.deleteItem(item);"
                    >
                      <i class="fa fa-trash"></i> <translate>Delete</translate>
                    </a>
                </div>
                </div>
              </div>
            `,
            },
          );
        }

        return options;
      }
    },
  ],
};
