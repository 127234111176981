import React from 'react';

import { ThreeDotsIcon } from '~/shared/ui/icons';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { FilterOption, FilterValue } from '../../types';

export const FilterItemOptions: React.FC<{
  value: FilterValue;
  option: FilterOption;
  onDelete: (filter: FilterValue) => void;
}> = ({ option, onDelete, value }) => {
  return (
    <DropdownMenuContent className="w-[200px]">
      <div className="flex items-center justify-between border-b border-stroke-main-light px-2 py-1">
        <p className="text-xs font-medium text-text-main-primary">{option.title}</p>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger displayChevron={false}>
            <ThreeDotsIcon
              size={14}
              className="text-stroke-main-strongest"
              data-testid="inactive-filter-add-actions"
            />
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent sideOffset={10}>
            <button
              className="px-1 text-xs text-text-additional-danger"
              onClick={() => onDelete(value)}
            >
              Delete Filter
            </button>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
      </div>
      <div className="flex flex-col gap-0.5 p-1">
        {option.operatorOptions.map((operator) => (
          <DropdownMenuItem key={operator} onClick={() => onDelete(value)}>
            <p className="w-full cursor-pointer rounded-sm p-1 text-xs text-text-main-primary transition duration-300 ease-in-out hover:bg-transparent-light">
              {operator}
            </p>
          </DropdownMenuItem>
        ))}
      </div>
    </DropdownMenuContent>
  );
};
