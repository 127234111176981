import React from 'react';

import { cn } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import { SortArrowsIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { FiltersSortHeader } from './filters-sort-header';
import { FiltersSortItem } from './filters-sort-item';
import { formatSortingDirection } from './format-sorting-direction';
import type { SortingOption, SortingValue } from '../../types';
import { FilterButton } from '../filter-button';

export const FiltersSortSelector: React.FC<{
  sorting?: SortingValue;
  sortingOptions: SortingOption[];
  onChange: (sorting?: SortingValue) => void;
}> = ({ sorting, sortingOptions, onChange }) => {
  const [open, setOpen] = React.useState(false);

  const selectHandler = React.useCallback(
    (value: string) => {
      onChange(
        sortingOptions
          .filter((s) => s.title === value)
          .map((s) => ({ argument: s.argument, direction: 'asc' as const }))[0],
      );
      setOpen(false);
    },
    [sortingOptions, onChange, setOpen],
  );

  return (
    <div className="flex items-center space-x-4">
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <FilterButton intent={sorting ? 'default' : 'empty'}>
            <div className="flex items-center gap-1">
              <SortArrowsIcon
                size={14}
                className={cn(
                  'pt-0.5',
                  sorting ? 'text-text-section-secondary' : 'text-text-main-secondary',
                )}
              />
              {sorting ? (
                <p>{`${sortingOptions.find((s) => s.argument === sorting.argument)?.title}: ${formatSortingDirection(sorting.direction)}`}</p>
              ) : (
                <>Sort</>
              )}
            </div>
          </FilterButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-0" side="bottom" align="start">
          <FiltersSortHeader selectedDirection={sorting?.direction} onChange={onChange} />
          <SearchCommandList
            placeholder="Search fields to sort by"
            options={sortingOptions.map((option) => (
              <FiltersSortItem
                key={option.title}
                selectedOption={sorting}
                option={option}
                selectHandler={selectHandler}
              />
            ))}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
