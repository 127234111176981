import type ng from 'angular';

import template from './quality-rule-template-modal.html?raw';

export const QualityRuleTemplateModal = {
  bindings: {
    template: '<?',
    modalInstance: '<',
  },
  template,
  controller: [
    '$scope',
    'gettext',
    'QualityRuleTemplatesService',
    class {
      $scope: ng.IScope;
      QualityRuleTemplatesService: any;
      fields: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      template: any;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        QualityRuleTemplatesService: any,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.QualityRuleTemplatesService = QualityRuleTemplatesService;
        this.fields = {};
      }

      $onInit() {
        this.fields = this.getModalOptions().fieldsDef;
      }

      getModalOptions() {
        const col0: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        col0.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('TITLE'),
          },
          fieldGroup: [
            {
              key: 'title',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Title'),
                placeholder: this.gettext('Type title of template'),
                hint: this.gettext('The name for internal use, can be whatever you want'),
                required: true,
              },
            },
          ],
        });

        return {
          formName: 'quality-rule-template-modal',
          fieldsDef: [col0],
        };
      }

      saveTemplate() {
        this.QualityRuleTemplatesService.saveTemplate(this.template).then((tmpl: any) => {
          if (this.template.id) {
            this.modalInstance.close();
          } else {
            this.template = tmpl;
            (this.$scope as any).tog = 0;
          }
        });
      }

      deleteTemplate() {
        this.QualityRuleTemplatesService.deleteTemplate(this.template).then(() =>
          this.modalInstance.close(),
        );
      }

      closeModal(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }
    },
  ],
};
