import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { invoiceDetailsItemContainer } from './item-container/invoice-details-item-container.component';
import { invoiceDetailsPage } from './item-page/invoice-details-page.component';
import { invoiceDetailsItemView } from './item-view/invoice-details-item-view.component';

const invoiceConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('gt.page.payment', {
      url: '/payment/:id/',
      params: {
        tab: 'positions',
      },
      component: 'invoiceDetailsPage',
      resolve: {
        filterLevel: () => {
          return 'payments-page-view';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('payments-page-view');
          },
        ],
      },
      data: {
        pageTitle: gettext('Invoice'),
        permissions: {
          only: 'view_finance',
        },
      },
    });
  },
];

export const invoiceDetails = ng
  .module('finances.details', [])
  .component('invoiceDetailsPage', invoiceDetailsPage)
  .component('invoiceDetailsItemContainer', invoiceDetailsItemContainer)
  .component('invoiceDetailsItemView', invoiceDetailsItemView)
  .config(invoiceConfig).name;
