import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('helpButtons', directive);

  function directive() {
    return {
      template: require('./help-buttons.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        config: '<?',
        level: '<?',
      },
    };
  }

  Controller.$inject = ['$scope', 'HelpButtonsService'];

  function Controller(this: any, $scope: ng.IScope, HelpButtonsService: any) {
    const vm = this;

    activate();

    ////////////////

    function activate() {
      if (vm.config) {
        HelpButtonsService.updateConfig(vm.config, vm.level);
      } else {
        vm.config = HelpButtonsService.getConfig(vm.level);
      }

      $scope.$on('hb-config-updated', function () {
        vm.config = HelpButtonsService.getConfig(vm.level);
      });
    }
  }
})();
