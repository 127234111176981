import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './basis-docs-table.html?raw';

export const BasisDocsTable = {
  bindings: {
    objectId: '=',
    ownerObjectId: '=?',
    ownerContentType: '=?',
    editRights: '<?',
    isEditable: '<?',
    showEdit: '<?',
    updateList: '=?',
    rowType: '<?',
    mode: '<?',
    contractStage: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'ContractsService',
    'DocumentsService',
    'CoreService',
    'gtFilterService',
    'gettext',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      basisDocSet: any;
      contractStage: any;
      edit: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      isEditable: any;
      mode: any;
      objectId: any;
      ownerContentType: any;
      ownerObjectId: any;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      updateList: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        ContractsService: ContractsService,
        DocumentsService: any,
        CoreService: CoreService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ContractsService = ContractsService;
        this.DocumentsService = DocumentsService;
        this.CoreService = CoreService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;

        this.objectId = this.objectId || null;
        this.queryParams = {};
        this.basisDocSet = [];
        this.filterLevel = 'basic-docs-table';
        this.tableOptions = {};
        this.tableData = { rows: [], count: 0, total: {} };
        this.updateList = this.updateList || this.gtFilterService.updateQueryParams;
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
        if (!this.ownerContentType && this.mode) {
          this.CoreService.getModelContentType(this.mode).then((ct: any) => {
            this.ownerContentType = ct.id;
            this.updateData();
          });
        } else {
          this.updateData();
        }
        this.$rootScope.$on('update-basis-doc', () => this.updateData());
      }

      updateData() {
        if (this.objectId) {
          return this.updateContractData();
        }
        return this.DocumentsService.getDocumentChoicePointObjectTable({
          owner_object_id: this.ownerObjectId,
          owner_content_type: this.ownerContentType,
          purpose_model: this.mode,
          ...this.queryParams,
        }).then(
          (data: any) => {
            this.basisDocSet = data.results;
            this.basisDocSet.unshift(this.getNewChecklistPointObject());
            this.setTableData();
          },
          (err: any) => {
            this.basisDocSet = [];
            return this.GtUtils.errorClb(err);
          },
        );
      }

      updateContractData() {
        return this.ContractsService.ContractBasisDoc.query(
          {
            contract__id: this.objectId,
            ...this.queryParams,
          },
          (data: any) => {
            this.basisDocSet = data.results;
            this.basisDocSet.unshift(this.getNewObject());
            this.setTableData();
          },
        ).$promise;
      }

      setTableData() {
        this.tableData = {
          rows: this.basisDocSet.filter((doc: any) => doc.is_visible),
          count: this.basisDocSet.filter((doc: any) => doc.is_visible).length,
          total: {},
        };
      }

      getResource() {
        if (this.objectId) {
          return this.ContractsService.ContractBasisDoc;
        }
        return this.DocumentsService.DocumentChecklist;
      }

      saveItem(item: any) {
        return this.getResource()
          [item.id ? 'update' : 'save'](item)
          .$promise.then(
            () => this.updateData(),
            (err: any) => this._error(err),
          );
      }

      deleteItem(item: any) {
        const msg = this.gettext('Are you sure that you want to delete?');
        if (!confirm(msg)) {
          return;
        }
        if (!item.id) {
          return this.updateData();
        }
        return this.getResource().delete(
          { id: item.id },
          () => {
            this.updateData();
            this.edit = false;
          },
          (err: any) => this._error(err),
        ).$promise;
      }

      getNewObject() {
        return { contract: this.objectId, is_visible: true };
      }

      getNewChecklistPointObject() {
        return {
          owner_object_id: this.ownerObjectId,
          owner_content_type: this.ownerContentType,
          purpose_model: this.mode,
          is_visible: true,
          edit: true,
        };
      }

      applyFilters(event: any) {
        this.queryParams = { ...this.queryParams, ...event.params };
        this.updateData();
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.GtUtils.overlay('hide');
      }

      getTableOptions() {
        const options: any = {
          tableName: 'basic-docs-table',
          filterLevel: this.filterLevel,
          columnDefs: [] as any[],
          tabs: [],
          applyFilters: this.applyFilters.bind(this),
          tableClass: 'request-table basis-doc-table',
          configurable: true,
          templateArgs: {
            updateData: () => this.updateData(),
            saveItem: (item: any) => this.saveItem(item),
            deleteItem: (item: any) => this.deleteItem(item),
            isEditable: this.isEditable,
            contractStage: this.contractStage,
            type: this.objectId ? 'basisDocItem' : 'checklistRow',
          },
        };
        options.columnDefs = [
          {
            columnName: 'title',
            predicate: this.objectId ? 'basis_doc__title' : 'title',
            title: this.gettext('Title'),
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.point"
                  placeholder="'Select a document'|translate"
                  resource-name="'documents.documentchecklistpoint'"
                  query-params="{purpose: item.owner_content_type, is_main: 'False'}"
                  ng-if="args.type === 'checklistRow'"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
                <gt-resource-select
                  ng-model="item.basis_doc"
                  placeholder="'Select a document'|translate"
                  resource-name="'contracts.BasisDoc'"
                  ng-if="args.type === 'basisDocItem'"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                  query-params="{contract_stage:args.contractStage}"
                ></gt-resource-select>
                <div class="clearfix"></div>
              </div>
              <div ng-if="!item.edit && item.id">
                <i class="fa fa-file"></i> {[{ item.title }]}
              </div>
            `,
          },
          {
            columnName: 'additional_info',
            predicate: this.objectId ? 'basis_doc__title' : 'title',
            title: this.gettext('Note'),
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <input
                  class="form-control"
                  type="text"
                  placeholder="{[{'Note'|translate}]}"
                  ng-model="item.additional_info"
                />
                <div class="clearfix"></div>
              </div>
              <div ng-if="!item.edit && item.id">
                <div class="small" ng-if="item.additional_info">{[{ item.additional_info }]}</div>
              </div>
            `,
          },
          {
            columnName: 'copy',
            title: this.gettext('Copy'),
            class: 'no-blur',
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <input type="checkbox" ng-model="item.copy_available" />
              </div>
              <div ng-if="!item.edit && item.id">
               <i ng-if="!item.copy_available" class="fa fa-circle-o"></i>
               <i ng-if="item.copy_available" class="fa fa-check-circle"></i>
              </div>
            `,
          },
          {
            columnName: 'original',
            title: this.gettext('Original'),
            class: 'no-blur',
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <input type="checkbox" ng-model="item.original_available" />
              </div>
              <div ng-if="!item.edit && item.id">
               <i ng-if="!item.original_available" class="fa fa-circle-o"></i>
               <i ng-if="item.original_available" class="fa fa-check-circle"></i>
              </div>
            `,
          },
          {
            columnName: 'sent',
            title: this.gettext('Sent'),
            predicate: 'date_of_sending',
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <gt-date-select
                  type="date"
                  date-model="item.date_of_sending"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-date-select>
              </div>
              <div ng-if="!item.edit && item.id">
               <span ng-if="item.date_of_sending">
               <i class="fa fa-calendar"></i> {[{ item.date_of_sending | date:'dd/MMM/yy' || '---' }]}
               </span>
              </div>
            `,
          },
          {
            columnName: 'received',
            title: this.gettext('Received'),
            predicate: 'date_of_receipt',
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <gt-date-select
                  type="date"
                  date-model="item.date_of_receipt"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-date-select>
              </div>
              <div ng-if="!item.edit && item.id">
               <span ng-if="item.date_of_receipt">
               <i class="fa fa-calendar"></i> {[{ item.date_of_receipt | date:'dd/MMM/yy' || '---' }]}
               </span>
              </div>
            `,
          },
          {
            columnName: 'responsible',
            title: this.gettext('Responsible'),
            class: 'column-min-width',
            predicate: 'responsible',
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.responsible"
                  placeholder="'Select a responsible'|translate"
                  resource-name="'accounts.RegionalManager'"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </div>
              <div ng-if="!item.edit && item.id">
               <span>{[{ item.responsible_full_name}]}</span>
              </div>
            `,
          },
          {
            columnName: 'custom_status',
            title: this.gettext('Custom status'),
            predicate: 'custom_status',
            class: 'column-min-width',
            cellTemplate: /*html*/ `
            <div ng-if="item.edit || !item.id">
                <gt-resource-select
                  ng-model="item.custom_status"
                  placeholder="'Select a status'|translate"
                  resource-name="'core.CustomStatus'"
                  query-params="{content_type__model: 'contractbasisdoc'}"
                  allow-clear="true"
                  gt-disabled="!item.edit && item.id"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
                </div>
                <div ng-if="!item.edit && item.id && item.custom_status_title">
               <span>{[{ item.custom_status_title}]}</span>
              </div>
            `,
          },
          {
            columnName: 'sequence_order',
            title: this.gettext('Sequence order'),
            predicate: 'sequence_order',
            cellTemplate: /*html*/ `
                 <div ng-if="item.edit || !item.id">
                <input
                  class="form-control"
                  type="number"
                  placeholder="{[{'Order'|translate}]}"
                  ng-model="item.sequence_order"
                />
              </div>
              <div ng-if="!item.edit && item.id && item.sequence_order != 0">
               <span>{[{ item.sequence_order}]}</span>
              </div>
            `,
          },
          {
            columnName: 'is_autocreated',
            title: this.gettext('Is Autocreated'),
            class: 'no-blur',
            cellTemplate: /*html*/ `
                <i ng-if="!item.is_autocreated" class="fa fa-circle-o"></i>
               <i ng-if="item.is_autocreated" class="fa fa-check-circle"></i>
            `,
          },
          {
            columnName: 'edit',
            title: this.gettext('Actions'),
            class: 'no-blur',
            cellTemplate: /*html*/ `
              <div ng-if="item.edit || !item.id">
                <a class="btn btn-md btn-success btn_success" ng-click="args.saveItem(item)">
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a class="btn btn-md" ng-click="item.edit=false">
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <div ng-if="!item.edit && item.id">
               <div class="input-group input-group-table-edit-btn">
                  <a
                    class="btn btn-xs btn-blue-border"
                    ng-click="item.edit = true"
                    style="margin-right: 5px"
                  >
                    <i class="fa fa-pencil-square"></i>
                  </a>
                  <a class="btn btn-xs btn-danger" ng-click="args.deleteItem(item)">
                    <i class="fa fa-trash"></i>
                  </a>
              </div>
            `,
          },
        ];
        return options;
      }
    },
  ],
};
