import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Input, type InputProps } from '~/shared/ui/kit/input';

import { type DataTypeProps, messageIntent } from '../../lib';

export const PhoneWrite: React.FC<DataTypeProps<string> & InputProps> = ({
  value,
  valueChanged,
  ref,
  disabled,
  message,
  ...props
}) => (
  <div className={cn('h-full w-full', message && messageIntent[message.type])}>
    <Input
      type="text"
      intent="transparent"
      data-testid="text-field"
      ref={ref}
      onBlur={(e) => valueChanged?.(e.target.value)}
      defaultValue={value}
      disabled={disabled}
      {...props}
    />
  </div>
);
