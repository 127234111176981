import ng from 'angular';

import { FactIntermediateListContainer } from './fact-intermediate-list-container/fact-intermediate-list-container.component';
import { FactIntermediateListTableView } from './fact-intermediate-list-table-view/fact-intermediate-list-table-view.component';
import { FactIntermediateListService } from './fact-intermediate-list.service';

export const factIntermediateList = ng
  .module('deals.passports.factIntermediateList', [])
  .component('factIntermediateListContainer', FactIntermediateListContainer)
  .component('factIntermediateListTableView', FactIntermediateListTableView)
  .service('FactIntermediateListService', FactIntermediateListService).name;
