import type { QueryParams } from '~/app/core/types';

import template from './contract-list-modal.html?raw';

export const ContractListModal = {
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  template,
  controller: [
    'gettext',

    class {
      args: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      queryParams: QueryParams = {};
      queryParamsPurchase: any;
      queryParamsSale: any;
      resolve: any;
      showBoth: any;
      showVertical: any;
      tab: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
        this.filterLevel = 'contracts-list-modal';
      }

      $onInit() {
        this.queryParams = this.resolve.queryParams;
        this.args = this.resolve.args;
        this.tab =
          this.queryParams.contract_type === 'purchase' ? 'purchase-contracts' : 'sale-contracts';
        this.showBoth = this.args.showBoth || false;
        this.showVertical = this.args.showVertical || false;
        this.queryParamsSale = { ...this.queryParams, contract_type: 'sale' };
        this.queryParamsPurchase = { ...this.queryParams, contract_type: 'purchase' };
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return false;
        }
        this.modalInstance.close(data || 'cancel');
      }
    },
  ],
};
