import ng from 'angular';

import { StatisticsReportContainer } from './statistics-report-container/statistics-report-container.component';
import { StatisticsReportPage } from './statistics-report-page/statistics-report-page.component';
import { StatisticsReportTreeView } from './statistics-report-tree-view/statistics-report-tree-view.component';
import { StatisticsReportService } from './statistics-report.service';

export const statisticsReport = ng
  .module('reports.statisticsReport', [])
  .service('StatisticsReportService', StatisticsReportService)
  .component('statisticsReportPage', StatisticsReportPage)
  .component('statisticsReportContainer', StatisticsReportContainer)
  .component('statisticsReportTreeView', StatisticsReportTreeView).name;
