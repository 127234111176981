import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './consolidated-invoicing-container.html?raw';

export const ConsolidatedInvoicingContainer = {
  bindings: {
    filterLevel: '<',
    items: '<?',
    mode: '<',
    onFinish: '&',
    selectAll: '&',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'gettext',
    'ConsolidatedInvoicingService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      Service: any;
      _selectAll: any;
      additionalFilters: any;
      connectTo: any;
      connectToChoices: any;
      contractToConnect: any;
      filterLevel = '';
      gettext: ng.gettext.gettextFunction;
      items: any;
      mainUnit: any;
      mode: any;
      onFinish: any;
      passportToConnect: any;
      queryParams: QueryParams & { uninvoiced_charge_list?: string[]; charge_list?: string[] } = {};
      tableData: any;
      tableOptions: any;
      totals: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        ConsolidatedInvoicingService: any,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.Service = ConsolidatedInvoicingService;

        this.connectTo = 'purchase';
        this.connectToChoices = [
          { value: 'none', title: this.gettext('Set directly') },
          { value: 'purchase', title: this.gettext('Purchase contract') },
          { value: 'sale', title: this.gettext('Sale contract') },
          { value: 'passport', title: this.gettext('Passport') },
        ];
        this.additionalFilters = {
          logistics: [
            {
              key: 'charge_list',
              type: 'multiselect',
              resource: 'finances.Charge',
              default: [],
              label: this.gettext('Charge'),
            },
            {
              key: 'uninvoiced_charge_list',
              default: [],
              type: 'multiselect',
              resource: 'finances.Charge',
              label: this.gettext('Uninvoiced charges'),
            },
            {
              key: 'with_buyer_contract',
              type: 'bool',
              label: this.gettext('has sale contract'),
              choices: [
                { value: '1', title: this.gettext('Yes') },
                { value: '0', title: this.gettext('No') },
              ],
            },
            {
              key: 'with_supplier_contract',
              type: 'bool',
              label: this.gettext('has purchase contract'),
            },
          ],
          costs: [
            {
              key: 'charge_list',
              default: [],
              type: 'multiselect',
              resource: 'finances.Charge',
              label: this.gettext('Charge'),
            },
            {
              key: 'not_invoiced',
              default: [],
              type: 'bool',
              label: this.gettext('Not invoiced'),
            },
            {
              key: 'transport_cost_with_buyer_contract',
              type: 'bool',
              label: this.gettext('has sale contract'),
            },
            {
              key: 'transport_cost_with_supplier_contract',
              type: 'bool',
              label: this.gettext('has purchase contract'),
            },
          ],
        };
        this.items = [];
        this.mode = 'logistics';
        this._selectAll = false;

        this.passportToConnect = undefined;
        this.contractToConnect = undefined;
        this.queryParams = {};
      }
      _getDefaultValue(valueName: any) {
        return this.$rootScope.user.settings.DEFAULT_VALUES[valueName];
      }
      _notifyError(msg: any) {
        return this.GtUtils.notify(msg, 'error');
      }
      getSelectedItems() {
        return this.items.filter((item: any) => item._selected);
      }
      initQueryParams() {
        this.queryParams = this.Service.initFilters(
          this.additionalFilters[this.mode],
          this.filterLevel,
        );
      }
      $onInit() {
        this.mainUnit = this.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT;
        this.connectTo = 'purchase';

        this.initQueryParams();
        this.tableOptions = this.getInvoiceTableOptions();
        this.$scope.$watchCollection(
          () => this.getSelectedItems(),
          () => this.updateData(),
        );
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = this.Service.getFilters(this.additionalFilters[this.mode], data);
          return this.updateData();
        });
      }
      $onChanges(changes: any) {
        if (changes.items) {
          this.refreshCheckboxes();
          this.updateData();
        }
      }
      setBoolFilter(key: any, value: any) {
        this.queryParams[key] = value;
        return this.updateFilters();
      }
      refreshCheckboxesCostsMode(item: any) {
        item._showCheckbox = item.logistic && item.totally_not_invoiced != 0;
        return item._showCheckbox;
      }
      refreshCheckboxesLogisticMode(item: any) {
        item._showCheckbox = item.transport_costs_data?.filter(
          (cost: any) =>
            (!this.queryParams.charge_list?.length ||
              this.queryParams.charge_list?.includes(cost.charge_id)) &&
            (!this.queryParams.uninvoiced_charge_list?.length ||
              this.queryParams.uninvoiced_charge_list?.includes(cost.charge_id)) &&
            cost.price_per_deal > (cost.invoiced_amount || 0),
        ).length;
        return item._showCheckbox;
      }
      _showFuncByMode() {
        return (
          {
            // @ts-ignore
            logistics: (item: any, index: any) => this.refreshCheckboxesLogisticMode(item, index),
            // @ts-ignore
            costs: (item: any, index: any) => this.refreshCheckboxesCostsMode(item, index),
          }[this.mode] || (() => false)
        );
      }
      refreshCheckboxes() {
        const selectedIds = this.getSelectedItems().map((item: any) => item.id);
        this.items.forEach((item: any) => {
          item._selected = this._showFuncByMode()(item) && selectedIds.includes(item.id);
        });
      }
      selectAllLocal(value: any) {
        this.items.forEach((item: any) => (item._selected = value && item._showCheckbox));
      }
      initTotals() {
        this.totals = {
          volume_received: { value: 0, title: this.gettext('Volume received') },
          volume_departed: { value: 0, title: this.gettext('Volume departed') },
          volume_departed_consignment: {
            value: 0,
            title: this.gettext('Volume departed consignment'),
          },
          volume_departed_real: { value: 0, title: this.gettext('Volume departed real') },
          volume_boarded: { value: 0, title: this.gettext('Volume boarded') },
        };
      }
      updateTotals() {
        this.initTotals();
        if (!this.getSelectedItems()?.length) {
          return;
        }
        const keys = Object.keys(this.totals);
        if (this.mode === 'logistics') {
          return this.getSelectedItems().forEach((item: any) => {
            keys.forEach((key) => (this.totals[key].value += item[key]));
          });
        }

        return this.Service.getSelectedTotals(
          this.getSelectedItems().map((item: any) => item.logistic),
        ).then((totals: any) => {
          this.totals.volume_departed.value = totals.volume_departed_sum;
          this.totals.volume_departed_consignment.value = totals.volume_departed_consignment_sum;
          this.totals.volume_departed_real.value = totals.volume_departed_real_sum;
          this.totals.volume_boarded.value = totals.volume_boarded_sum;
          this.totals.volume_received.value = totals.volume_received_sum;
        });
      }
      prepareInvoiceParams() {
        return {
          connect_to: this.connectTo,
          logistic_list:
            this.mode === 'logistics' ? this.getSelectedItems().map((item: any) => item.id) : [],
          costs_list:
            this.mode === 'costs' ? this.getSelectedItems().map((item: any) => item.id) : [],
          passport: this.passportToConnect,
          contract: this.contractToConnect,
          charge_list: [
            ...new Set([
              ...(this.queryParams.charge_list ?? []),
              ...(this.queryParams.uninvoiced_charge_list ?? []),
            ]),
          ],
        };
      }

      updateData() {
        this.updateTotals();
        if (this.getSelectedItems().length === 0) {
          this.tableData = {};
          return;
        }
        this.GtUtils.overlay('show');
        return this.Service.prepareConsolidatedInvoice(this.prepareInvoiceParams())
          .then(
            (data: any) =>
              (this.tableData = {
                rows: data.invoices,
                count: data.invoices_count,
                total: data.totals,
              }),
          )
          .catch((err: any) => this.GtUtils.errorClb(err))
          .finally(() => this.GtUtils.overlay('hide'));
      }

      createInvoices() {
        const promises = this.tableData.rows.map((invoice: any) => this.saveInvoice(invoice));
        return Promise.all(promises).then((results) =>
          this.GtUtils.goPage('finances.invoices.list', {
            id_list: results.map((item) => item.id),
          }),
        );
      }

      _prepareInvoice(invoice: any) {
        const extraFields: any = ['positions', 'logistics'];
        return {
          invoice: Object.fromEntries(
            Object.entries(invoice).filter((v) => !extraFields.includes(v[0])),
          ),
          ...Object.fromEntries(extraFields.map((f) => [f, invoice[f]])),
        };
      }
      close() {
        this.clearFilters();
        return this.onFinish();
      }
      saveInvoice(invoice: any) {
        return this.Service.createInvoice(this._prepareInvoice(invoice)).then(
          (data: any) => data.invoice.id,
        );
      }
      openInvoice(invoice: any) {
        return this.Service.openInvoice(this._prepareInvoice(invoice)).then((savedInvoice: any) => {
          invoice.id = savedInvoice.id;
          invoice.number = savedInvoice.number;
        });
      }
      deleteInvoice(invoice: any, index: any) {
        this.tableData.total = this.tableData.total
          .filter((total: any) => total.currency_symbol === invoice.currency_symbol)
          .map((total: any) => ({
            currency_symbol: total.currency_symbol,
            amount: total.amount - invoice.amount,
          }))
          .filter((total: any) => total.amount);
        this.tableData.rows.splice(index, 1);
        this.tableData.count--;
        this.tableData = { ...this.tableData };
      }
      updateFilters() {
        this.Service.updateFilters(this.queryParams, this.filterLevel);
      }
      clearFilters() {
        this.queryParams = Object.fromEntries(
          Object.entries(this.queryParams).map((entr) => [entr[0], undefined]),
        );
        this.Service.updateFilters(this.queryParams, this.filterLevel);
      }

      getInvoiceTableOptions() {
        const options: any = {
          tableClass:
            'table table-responsive table-condensed main-table request-table payments_table',
          tableAltClass:
            'table table-responsive table-condensed main-table request-table payments_table sticky-table-first-td',
          tableName: 'finances',
          alignColHeight: true,
          columnDefs: [] as any[],
          tabs: [],
          templateArgs: {
            openInvoice: (item: any) => this.openInvoice(item),
            deleteInvoice: (invoice: any, index: any) => this.deleteInvoice(invoice, index),
            getIcon: (model: any) => this.GtUtils.getIcon(model),
          },
        };
        options.columnDefs = [
          {
            columnName: 'edit',
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <a class="btn btn-xs aligned-btn btn-blue-border" ng-click="args.openInvoice(item)">
                <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
              </a>
              <a
                ng-if="!item.id"
                ng-click="args.deleteInvoice(item, index)"
                class="btn btn-xs aligned-btn btn-danger">
                <i class="fa fa-trash"></i> <translate>Delete</translate>
              </a>
            `,
            title: this.gettext('Edit'),
          },
          {
            columnName: 'number',
            title: this.gettext('Invoice number'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-if="item.id">
                <a ui-sref="gt.page.payment({ id: item.id})" class="btn-link">
                  <i class="fa fa-credit-card-alt"></i>{[{ item.number}]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'side_from',
            title: this.gettext('side from'),
            class: 'td-left-align',
            predicate: 'clientrole_from_name',
            cellTemplate: `

              <a
                ng-if="item.clientrole_from"
                ui-sref="gt.page.client({ id: item.clientrole_from_id})"
                class="btn-link"
                data-tip="{[{ item.clientrole_from_name }]}"
              >
                <i class="fa {[{args.getIcon('clients.' + item.clientrole_from_role)}]}"></i> {[{ item.clientrole_from_name | cut:true:30:'...' || "---" }]}
              </a>
            `,
          },
          {
            columnName: 'side_to',
            title: this.gettext('side to'),
            class: 'td-left-align',
            predicate: 'clientrole_to_name',
            cellTemplate: `
              <a
                ng-if="item.clientrole_to"
                ui-sref="gt.page.client({ id: item.clientrole_to_id})"
                class="btn-link"
                data-tip="{[{ item.clientrole_to_name }]}"
              >
                <i class="fa {[{args.getIcon('clients.' + item.clientrole_to_role)}]}"></i> {[{ item.clientrole_to_name | cut:true:30:'...' || "---"
                }]}
              </a>
            `,
          },
          {
            columnName: 'uses',
            title: this.gettext('uses'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-repeat="position in item.positions">
                <span>{[{ position.use }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'amount',
            title: this.gettext('amount'),
            predicate: 'default_currency_amount',
            classExpr:
              "{ 'alert-danger': item.invoice_type == 'incoming', 'alert-success': item.invoice_type == 'outgoing' }",
            cellTemplate: `
              <span
                class="bigger-label label negative-number span-right"
                ng-if="item.invoice_type == 'incoming'"
              >
                <i class="fa fa-minus"></i> {[{ item.amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
              <span
                class="bigger-label label positive-number span-right"
                ng-if="item.invoice_type == 'outgoing'"
              >
                <i class="fa fa-plus"></i> {[{ item.amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total amount of invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                 {[{total.amount || 0 | number: 2 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'nested_volume',
            title: this.gettext('Nested costs volume'),
            predicate: 'nested_volume',
            cellTemplate: `
              <span class="bigger-label label span-right">
                {[{ item.nested_volume | number:2 }]}
              </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total Nested costs volume of invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                 {[{total.nested_volume || 0 | number: 2 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'currency_rate',
            title: this.gettext('currency rate'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-if="item.rate">
                <span class="label"> {[{ item.rate || 0 | number:3 }]} </span>
              </span>
            `,
          },
          {
            columnName: 'details',
            title: this.gettext('details'),
            class: 'td-left-align',
            cellTemplate: `
              <ul>
                <li ng-repeat="position in item.positions">
                  <span class="label">
                    {[{ position.charge_title}]} -> 
                    <a ui-sref="gt.page.contract({id: position.contract})"> {[{ position.contract_number}]}</a>
                  </span>
                  <span class="label" ng-repeat="cost in position.transport_costs_data">
                    <a
                      ui-sref="gt.page.logistic({ id: cost.logistic_id})"
                      class="label label-default"
                    >
                      {[{ cost.logistic_number}]}
                    </a>
                  </span>
                </li>
              </ul>
            `,
          },
        ];
        return options;
      }
    },
  ],
};
