import ng from 'angular';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('vesselTableRow', directive);

  function directive() {
    return {
      template: require('./vessel-table-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        vessel: '<',
        expanded: '<?',
        onChange: '&',
        block: '<?',
        onMenuHover: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', 'LogisticsService'];

  function Controller(this: any, $scope: ng.IScope, LogisticsService: any) {
    const vm = this;
    vm.openVesselModal = openVesselModal;
    vm.clone = clone;

    ////////////////

    function updateData() {
      $scope.$emit('vessels-update');
    }

    function openVesselModal(vessel: any) {
      return LogisticsService.vesselModal(vessel).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        updateData();
      });
    }

    function clone(vessel: any) {
      delete vessel.id;
      return LogisticsService.vesselModal(vessel).then(updateData);
    }
  }
})();
