import template from './analytical-plan-fact-pnl-report-table.html?raw';

export const AnalyticalPlanFactPnlReportTable = {
  bindings: {
    filterLevel: '<',
    data: '<',
    config: '<',
    levelsCount: '<',
  },
  template,
  controller: [
    class {
      data: any;
      showArrows(row: any) {
        return row.level !== 'passport';
      }
      updateVisibility(row: any, expand: any) {
        row.expand = expand;
        if (!expand) {
          this.data
            .filter((v: any) => v.parentIndex === row.index)
            .forEach((x: any) => this.updateVisibility(x, expand));
        }
      }
    },
  ],
};
