import ng from 'angular';

import { BankTransactionContainer } from './bank-transaction-container/bank-transaction-container.component';
import { BankTransactionListTableView } from './bank-transaction-list-table-view/bank-transaction-list-table-view.component';
import { BankTransactionModal } from './bank-transaction-modal/bank-transaction-modal.component';
import { BankTransactionPageView } from './bank-transaction-pave-view/bank-transaction-page-view.component';
import { BankTransactionService } from './bank-transaction.service';

const conf: any = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('finances.bankAccount.bankTransaction', {
      url: '/transactions',
      component: 'bankTransactionPageView',
      data: {
        pageTitle: gettext('Bank Transactions'),
        permissions: {
          only: 'view_banktransaction',
        },
      },
    });
  },
];

export const bankTransactionModule = ng
  .module('finances.bankAccount.bankTransaction', [])
  .component('bankTransactionPageView', BankTransactionPageView)
  .component('bankTransactionContainer', BankTransactionContainer)
  .component('bankTransactionModal', BankTransactionModal)
  .component('bankTransactionListTableView', BankTransactionListTableView)
  .service('BankTransactionService', BankTransactionService)
  .config(conf).name;
