import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Label } from '~/shared/ui/kit/label';

const inputVariants = cva(
  '[&::-webkit-outer-spin-button]:appearance-non h-8 bg-transparent-lighter [&::-webkit-inner-spin-button]:appearance-none',
  {
    variants: {
      intent: {
        danger:
          'border-stroke-additional-dangerLight text-text-additional-danger focus-within:ring-0',
        floating:
          'border-stroke-main-medium shadow-strong backdrop-blur-strong focus-within:border-stroke-additional-info focus-within:shadow-strong focus-within:ring-background-additional-infoLight',
        default:
          'border-stroke-main-medium text-text-main-primary focus-within:border-stroke-additional-info focus-within:ring-background-additional-infoLight',
        transparent: 'border-none bg-transparent focus-within:border-none focus-within:ring-0',
      },
      fontWeight: {
        normal: 'font-normal',
        medium: 'font-medium',
        semibold: 'font-semibold',
        bold: 'font-bold',
      },
      fontSize: {
        xs: 'text-xs',
        base: 'text-base',
        lg: 'text-lg',
        xl: 'text-xl',
      },
      textColor: {
        tertiary: 'text-text-main-tertiary',
        primary: 'text-text-main-primary',
      },
    },
    defaultVariants: {
      intent: 'default',
      fontWeight: 'normal',
      textColor: 'primary',
      fontSize: 'xs',
    },
  },
);

type InputVariants = VariantProps<typeof inputVariants>;
export type InputProps = {
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  fullWidth?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'className'> &
  InputVariants;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      intent,
      label,
      fontWeight,
      textColor,
      prefix,
      suffix,
      helperText,
      fullWidth,
      fontSize,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn('relative flex flex-col', fullWidth ? 'w-full' : 'w-max')}
        data-testid="input-container"
      >
        <Label>
          <p className="text-xs font-semibold text-text-main-tertiary">{label}</p>
          <div
            className={cn(
              'flex items-center rounded-sm border focus-within:ring',
              disabled && 'cursor-not-allowed',
              inputVariants({ intent, fontWeight, textColor, fontSize }),
            )}
            data-testid="input"
          >
            {prefix && (
              <span
                className={cn('ml-2.5', disabled && 'text-text-main-light')}
                data-testid="input-prefix"
              >
                {prefix}
              </span>
            )}
            <input
              className={cn(
                'w-full px-2.5 placeholder-text-main-light [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
                'bg-transparent selection:bg-background-additional-infoLight focus:outline-none',
                'disabled:cursor-not-allowed disabled:text-text-main-light',
              )}
              ref={ref}
              disabled={disabled}
              data-testid="input-element"
              {...props}
            />
            {suffix && (
              <span
                className={cn('mr-2.5', disabled && 'text-text-main-light')}
                data-testid="input-suffix"
              >
                {suffix}
              </span>
            )}
          </div>
        </Label>
        {helperText && (
          <span className="absolute -bottom-4 z-20 text-xs text-text-main-light">{helperText}</span>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
