import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './warehouse-modal.html?raw';

export const WarehouseModal = {
  bindings: {
    modalInstance: '<',
    warehouse: '<?',
  },
  template,
  controller: [
    '$state',
    'GtUtils',
    'gettext',
    'WarehousesService',
    'WarehousesFormFieldsService',
    class {
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      WarehousesFormFieldsService: any;
      WarehousesService: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      warehouse: any;
      constructor(
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        WarehousesService: any,
        WarehousesFormFieldsService: any,
      ) {
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.WarehousesService = WarehousesService;
        this.WarehousesFormFieldsService = WarehousesFormFieldsService;

        this.modalInstance = undefined;

        this.form = undefined;
        this.fields = [];
        this.warehouse = {};
      }

      $onInit() {
        this.warehouse = this.warehouse || this.initNewWarehouse();
        if (this.warehouse.id) {
          return this.updateData();
        }
        return this.updateFields();
      }

      initNewWarehouse() {
        return {
          warehouse_type: 'warehouse',
        };
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.WarehousesService.getWarehouse(this.warehouse.id)
          .then((data: any) => {
            this.warehouse = data;
            this.updateFields();
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext("Warehouse doesn't exist"));
            this.$state.go('logistics.warehousesList');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure that you want delete this warehouse?'))) {
          return;
        }
        return this.WarehousesService.deleteWarehouse({ id: this.warehouse.id }).then(() => {
          this.GtUtils.notify(this.gettext('Warehouse deleted'));
          this.close('delete', true);
        });
      }

      save() {
        return this.WarehousesService.saveWarehouse(this.warehouse)
          .then((data: any) => this.close(data, true))
          .catch((e: any) => this.GtUtils.errorClb(e))
          .finally(() => this.GtUtils.overlay('hide'));
      }

      openFieldsConfigModal() {
        return this.WarehousesFormFieldsService.openWarehouseFieldsConfigModal(this.warehouse).then(
          () => this.updateFields(),
        );
      }

      updateFields() {
        return this.WarehousesFormFieldsService.getFields(this.warehouse).then((fields: any) => {
          this.fields = fields;
        });
      }
    },
  ],
};
