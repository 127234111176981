import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

export class ConsolidatedInvoicingService {
  $http: ng.IHttpService;
  FinancesService: FinancesService;
  LogisticsService: any;
  gtFilterService: GtFilterService;
  constructor(
    $http: ng.IHttpService,
    gtFilterService: GtFilterService,
    LogisticsService: any,
    FinancesService: FinancesService,
  ) {
    this.$http = $http;
    this.gtFilterService = gtFilterService;
    this.LogisticsService = LogisticsService;
    this.FinancesService = FinancesService;
  }

  createInvoiceObject(initInvoice: any) {
    return this.$http({
      method: 'POST',
      url: '/api/logistics/logistics/generate_invoice/',
      data: initInvoice,
    }).then((response: any) => response.data);
  }
  createInvoice(invoiceData: any) {
    return this.FinancesService.Finance.bulkCreateOrUpdatePositions({
      invoice: invoiceData.invoice,
      invoice_positions: invoiceData.positions,
      logistics: invoiceData.logistics,
    }).$promise;
  }
  openInvoice(invoiceData: any) {
    return this.FinancesService.financeModal(invoiceData.invoice, {
      financePositions: invoiceData.positions,
      logistics: invoiceData.logistics,
    });
  }
  prepareConsolidatedInvoice(invoiceParams: any) {
    return this.FinancesService.Finance.prepareConsolidatedServiceInvoice(invoiceParams).$promise;
  }
  getSelectedTotals(idList: any) {
    return this.LogisticsService.Logistic.totalsInfo({ id_list: idList }).$promise;
  }

  updateFilters(queryParams: QueryParams, filterLevel: string) {
    this.gtFilterService.updateQueryParams(queryParams, filterLevel);
  }
  getFilters(defaultQueryParams: (keyof QueryParams)[], queryParams: QueryParams) {
    return Object.fromEntries(
      defaultQueryParams.map((filter: any) => [
        filter.key,
        queryParams[filter.key] ?? filter.default,
      ]),
    );
  }
  initFilters(defaultQueryParams: (keyof QueryParams)[], filterLevel: string) {
    return this.getFilters(defaultQueryParams, this.gtFilterService.getQueryParams(filterLevel));
  }
  clearFilters(queryParams: QueryParams, filterLevel: string) {
    this.gtFilterService.updateQueryParams(queryParams, filterLevel);
  }
}
ConsolidatedInvoicingService.$inject = [
  '$http',
  'gtFilterService',
  'LogisticsService',
  'FinancesService',
];
