import ng from 'angular';

import { WarehouseCommodityBatchMovementsContainer } from './warehouse-commodity-batch-movements-container/warehouse-commodity-batch-movements-container.component';
import { WarehouseCommodityBatchMovementsView } from './warehouse-commodity-batch-movements-view/warehouse-commodity-batch-movements-view.component';
import { WarehouseMovementsService } from './warehouse-commodity-batch-movements.service';

export const MovementsModule = ng
  .module('execution.warehouseMovements', [])
  .component('warehouseCommodityBatchMovementsContainer', WarehouseCommodityBatchMovementsContainer)
  .component('warehouseCommodityBatchMovementsView', WarehouseCommodityBatchMovementsView)
  .service('WarehouseMovementsService', WarehouseMovementsService).name;
