import React from 'react';

import { cn } from '~/shared/lib/utils';
import { type IconComponent, type IconName, useAllIcons, useIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';

import type { ViewConfigChanged } from '../../types';

const IconItem: React.FC<{
  iconName: IconName;
  viewConfigChanged: ViewConfigChanged;
  CurrentIcon: IconComponent;
  viewId: number;
}> = ({ iconName, viewConfigChanged, CurrentIcon, viewId }) => {
  const Icon = useIcon(iconName);

  return (
    <DropdownMenuItem
      key={iconName}
      className={cn(
        'flex cursor-pointer items-center gap-2 p-2 transition duration-300 ease-in-out hover:bg-transparent-light',
        CurrentIcon === Icon && 'bg-transparent-medium',
      )}
      onClick={() => viewConfigChanged(viewId, { Icon })}
    >
      <Icon size={14} className="text-text-main-secondary" />
    </DropdownMenuItem>
  );
};

export const IconList: React.FC<{
  viewId: number;
  DefaultIcon: IconComponent;
  CurrentIcon: IconComponent;
  isOpen: boolean;
  onOpenChanged: (open: boolean) => void;
  viewConfigChanged: ViewConfigChanged;
}> = ({ viewId, isOpen, onOpenChanged, CurrentIcon, DefaultIcon, viewConfigChanged }) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const allIcons = useAllIcons();

  const filteredIcons: IconName[] = React.useMemo(
    () =>
      Object.keys(allIcons).filter((iconName) =>
        iconName.toLowerCase().includes(searchTerm.toLowerCase()),
      ) as IconName[],
    [searchTerm, allIcons],
  );

  return (
    <DropdownMenu open={isOpen} onOpenChange={onOpenChanged}>
      <DropdownMenuTrigger className="flex flex-col gap-1 text-xs outline-none">
        <div className="rounded-sm border border-stroke-main-medium p-2">
          <CurrentIcon size={14} className="text-stroke-main-stronger" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex w-[200px] flex-col p-2">
        <Input
          placeholder="Search"
          intent="transparent"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <DropdownMenuSeparator className="h-[1px] bg-stroke-main-light" />
        <div className="flex flex-wrap" data-testid="icons-list">
          {filteredIcons.map((iconName) => (
            <IconItem
              key={iconName}
              iconName={iconName}
              viewConfigChanged={viewConfigChanged}
              CurrentIcon={CurrentIcon}
              viewId={viewId}
            />
          ))}
        </div>
        <div className="mt-1.5">
          <Button
            variant="fadedPrimary"
            onClick={() => console.info('Reset to ', DefaultIcon)}
            fullWidth
          >
            Reset to Default
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
