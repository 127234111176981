import React from 'react';

import { TableIcon } from '~/shared/ui/icons';

import { ChoiceMenu, LimitAccess, Properties, TitleIcons } from '../view-configurator/data';
import type { ReorderedColumn, TableView, ViewConfigChanged } from '../view-configurator/types';

export const TableViewSettingsContent: React.FC<{
  view: TableView;
  viewConfigChanged: ViewConfigChanged;
  columns: ReorderedColumn[];
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({ view, viewConfigChanged, columns, columnsReordered, columnVisibilityChanged }) => {
  return (
    <>
      <ChoiceMenu
        viewId={view.id}
        title="Table Type"
        data={view.data.type}
        Icon={TableIcon}
        viewConfigChanged={viewConfigChanged}
      />
      <TitleIcons
        isShow={view.data.isShowTitleIcons}
        showChanged={() =>
          viewConfigChanged(view.id, {
            data: { isShowTitleIcons: !view.data.isShowTitleIcons },
          })
        }
      />
      <Properties
        columns={columns}
        columnsReordered={columnsReordered}
        columnVisibilityChanged={columnVisibilityChanged}
      />
      <LimitAccess
        isLimitAccess={view.data.isLimitAccess}
        limitAccessChanged={() =>
          viewConfigChanged(view.id, { data: { isLimitAccess: !view.data.isLimitAccess } })
        }
      />
    </>
  );
};
