import { Subject } from 'rxjs';

import { type PageParams } from '~/core/data/lib';
import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { PageViewsStore } from '~/core/page-views';
import { counterpartyColumns } from '~/features/crm/counterparties/constants/columns';
import { CounterpartyDTO } from '~/features/crm/counterparties/lib';
import { CounterpartiesRepository } from '~/features/crm/counterparties/services';
import { singleton } from '~/shared/lib/di';
import { notifyError } from '~/shared/lib/notify';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  from,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from '~/shared/lib/state';

@singleton()
export class CounterpartiesListStore {
  private readonly pageParamsSubj = new BehaviorSubject<PageParams>({
    pageSize: 100,
    filters: [],
    page: 1,
    sort: undefined,
  });

  private readonly loadingSubj = new BehaviorSubject(false);

  private readonly updatedDataSubj = new BehaviorSubject<Partial<CounterpartyDTO>[]>([]);
  private readonly pageDataSubmittedSubj = new Subject<boolean>();
  private readonly editingSubj = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly counterpartiesRepo: CounterpartiesRepository,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    this.pageViewsStore.init({ entityName: 'clients' });
  }

  readonly loading$ = this.loadingSubj.asObservable();
  readonly editing$ = this.editingSubj.asObservable();

  readonly pageParams$ = this.pageParamsSubj.asObservable();

  readonly pageData$ = this.pageParamsSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((params) => this.counterpartiesRepo.getCounterpartyList(params)),
    tap(() => this.loadingSubj.next(false)),
    shareReplay({ bufferSize: 1, refCount: false }),
    startWith({ records: [], count: 0 }),
  );

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) =>
      from(propertiesPromise).pipe(
        map((properties) =>
          properties.filter((property) =>
            counterpartyColumns.some((column) => column.key === property.key),
          ),
        ),
      ),
    ),
    startWith([]),
  );

  readonly updatedData$ = this.updatedDataSubj.asObservable();

  readonly editedRecords$ = combineLatest([this.pageData$, this.updatedData$]).pipe(
    map(([records, updates]) => {
      return records.records.map((record) => {
        const relevantUpdates = updates.filter((u) => u.id === record.id);

        const combinedUpdate = relevantUpdates.reduce(
          (acc, update) => ({ ...acc, ...update }),
          {} as Partial<CounterpartyDTO>,
        );

        return { ...record, ...combinedUpdate };
      });
    }),
  );

  readonly pageDataSubmitted$ = this.pageDataSubmittedSubj.asObservable();

  // TO DO: refactor more correctly
  readonly updatedDataSaved$ = this.pageDataSubmitted$.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap(() => {
      const changes = this.updatedDataSubj.value;

      // remove from
      return this.counterpartiesRepo.updateCounterparties(changes).then(
        () => {
          this.updatedDataSubj.next([]);
          this.pageParamsSubj.next(this.pageParamsSubj.value);
          return true;
        },
        (error) => {
          console.error('Failed to update counterparties', error);
          this.pageParamsSubj.next(this.pageParamsSubj.value);
          return false;
        },
      );
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public counterpartiesUpdated = (dtos: Partial<CounterpartyDTO>[]) => {
    this.updatedDataSubj.next(dtos);
  };

  public editingChanged = (editing: boolean) => {
    this.editingSubj.next(editing);
  };

  public saveRequested = () => {
    this.editingSubj.next(false);
    this.pageDataSubmittedSubj.next(true);
  };

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: CounterpartyDTO[]) => {
    await Promise.all(records.map(this.counterpartiesRepo.updateCounterparty));
    this.pageParamsSubj.next(this.pageParamsSubj.value);
  };

  public deleteRecord = async (id: string) => {
    try {
      await this.counterpartiesRepo.deleteCounterparty(id);
    } catch (err) {
      notifyError('Failed to delete Counterparty');
      console.error(err);
    }
    this.pageParamsSubj.next(this.pageParamsSubj.value);
  };

  public pageParamsChanged = (params: Partial<PageParams>) => {
    this.pageParamsSubj.next({ ...this.pageParamsSubj.value, ...params });
  };
}
