import type { DataProperties } from '~/core/page-views/components/data-set-view';
import { counterpartyColumns } from '~/features/crm/counterparties/constants/columns';
import type { DataViewProperties } from '~/features/data-view-fields/lib';

export const counterpartyPropertiesBuilder = (
  properties: DataViewProperties[],
): DataProperties[] => {
  return properties.map((property) => {
    const column = counterpartyColumns.find((column) => column.key === property.key);
    return {
      ...property,
      dataType: column ? column.dataType : 'text',
      sourceKey: column?.sourceKey ?? undefined,
      cellTemplateContext: {
        props: column?.cellTemplateContext?.props ?? {},
        options: column?.cellTemplateContext?.options ?? [],
      },
      size: column?.size ?? 150,
      render: column?.render,
    };
  });
};
