import ng from 'angular';

import { OilExtensionListContainer } from './oil-extension-list-container/oil-extension-list-container.component';
import { OilExtensionListTableView } from './oil-extension-list-table-view/oil-extension-list-table-view.component';
import { OilExtensionService } from './oil-extension.service';

export const oilExtension = ng
  .module('deals.contracts.oilExtension', [])
  .service('OilExtensionService', OilExtensionService)
  .component('oilExtensionListContainer', OilExtensionListContainer)
  .component('oilExtensionListTableView', OilExtensionListTableView).name;
