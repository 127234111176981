import type ng from 'angular';
export class TransportAnalyticsService {
  $filter: ng.IFilterService;
  $http: ng.IHttpService;
  $log: ng.ILogService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  analyticsData: any;
  moment: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService,
    $log: ng.ILogService,
    moment: any,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.$log = $log;
    this.moment = moment;

    this.analyticsData = undefined;
  }

  getAnalyticsData(params: any) {
    if (!params && this.analyticsData) {
      return this.$q.when().then(() => this.analyticsData);
    }
    this.analyticsData = this.$http({
      method: 'GET',
      url: '/api/logistics/analytics/',
      params: params,
    }).then((response: any) => {
      this.analyticsData = response.data;
      return this.analyticsData;
    });
    return this.analyticsData;
  }

  getVolumesByMonthChartConfig(params: any) {
    const chartConfig: any = {
      labels: [],
      data: [[]],
      series: ['Volume'],
      count: 0,
    };

    return this.getAnalyticsData(params).then((data: any) => {
      chartConfig.count = data.volumes_by_month.length;
      data.volumes_by_month.forEach((i: any) => {
        chartConfig.labels.push(this.moment(i.group_date).format('MMM, YY'));
        chartConfig.data[0].push(Math.round(i.total_volume * 100) / 100);
      });
      return chartConfig;
    });
  }

  getBlVolumesByMonthChartConfig(params = {}) {
    const chartConfig: any = {
      labels: [],
      data: [[]],
      series: ['Volume'],
      count: 0,
    };

    return this.getAnalyticsData(params).then((data: any) => {
      chartConfig.count = data.bl_volume_by_month.length;
      data.bl_volume_by_month.forEach((i: any) => {
        chartConfig.labels.push(this.moment(i.month).format('MMM, YY'));
        chartConfig.data[0].push(Math.round(i.total_volume * 100) / 100);
      });
      return chartConfig;
    });
  }

  getBlMarginByMonthChartConfig(params = {}) {
    const chartConfig: any = {
      labels: [],
      data: [[]],
      series: ['Margin'],
      count: 0,
    };

    return this.getAnalyticsData(params).then((data: any) => {
      chartConfig.count = data.bl_margin_by_month.length;
      data.bl_margin_by_month.forEach((i: any) => {
        chartConfig.labels.push(this.moment(i.date).format('MMM, YY'));
        chartConfig.data[0].push(Math.round(i.margin * 100) / 100);
      });
      return chartConfig;
    });
  }

  getVolumesByCropChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
      count: 0,
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.volumes_by_crop.length;
      data.volumes_by_crop.forEach((i: any) => {
        chartConfig.labels.push(i.cargo__title);
        chartConfig.data.Volume.push(Math.round(i.total_volume * 100) / 100);
      });
      return chartConfig;
    });
  }

  getVolumesByTerminalChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[]],
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      data.volumes_by_terminal.forEach((i: any) => {
        chartConfig.labels.push(i.terminal__name);
        chartConfig.data[0].push(Math.round(i.volume_real_received * 100) / 100);
      });
      return chartConfig;
    });
  }

  getVolumesByTerminalData() {
    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => data.volumes_by_terminal);
  }

  getLostsByTerminalChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[]],
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      data.volumes_by_terminal.forEach((i: any) => {
        chartConfig.labels.push(i.terminal__name);
        chartConfig.data[0].push(Math.round(i.volume_lost * 100) / 100);
      });
      return chartConfig;
    });
  }

  getLostsByTerminalData() {
    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => data.volumes_by_terminal);
  }

  getTopStationsChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      data.top_stations.forEach((i: any) => {
        chartConfig.labels.push(i.station__name);
        chartConfig.data.Volume.push(Math.round(i.volume_departed * 100) / 100);
      });
      return chartConfig;
    });
  }

  getTopStationData() {
    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => data.top_stations);
  }

  getBottomStationsChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };

    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => {
      data.bottom_stations.forEach((i: any) => {
        chartConfig.labels.push(i.station__name);
        chartConfig.data.Volume.push(Math.round(i.volume_departed * 100) / 100);
      });
      return chartConfig;
    });
  }

  getBottomStationData() {
    // @ts-ignore
    return this.getAnalyticsData().then((data: any) => data.bottom_stations);
  }
}
TransportAnalyticsService.$inject = ['$resource', '$q', '$http', '$filter', '$log', 'moment'];
