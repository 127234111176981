import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';
import type { PaymentPlanFinancesService } from '~/app/finances/shared/payment-plan-finances/payment-plan-finances.service';

import template from './invoice-list-table-view.html?raw';
import numberCellTemplate from './number-cell-template.html?raw';
import statusCellTemplate from './status-cell-template.html?raw';

export const InvoiceListTableView = {
  bindings: {
    finances: '<?',
    count: '<?',
    filterLevel: '<?',
    onCreatePaymentPlan: '&?',
    createPlanButton: '<?',
    total: '<',
    applyFilters: '&?',
    openFinanceModal: '&?',
    openDocumentModal: '&?',
    openDocxModal: '&?',
    openRoleModal: '&?',
    openPaymentPlanModal: '&?',
    clone: '&?',
    isGreen: '&?',
    getIcon: '&?',
    addInvoiceButton: '<?',
    addInvoiceButtonFunc: '&?',
    showPaymentsExportButton: '<?',
    paymentsExportConfig: '<?',
    onCreateDocument: '&',
    selectToPayment: '<?',
    selectToDocument: '<?',
    selectToApprove: '<?',
    selectToPay: '<?',
    activeReportConfig: '<?',
    cancelSelection: '&',
  },
  template,
  controller: [
    '$rootScope',
    'AccountsService',
    'gettext',
    'PaymentPlanFinancesService',
    'InvoiceFormFieldsService',
    class {
      $rootScope: GtRootScopeService;
      AccountsService: any;
      InvoiceFormFieldsService: any;
      PaymentPlanFinancesService: PaymentPlanFinancesService;
      activeReportConfig: any;
      addInvoiceButtonFunc: any;
      applyFilters: any;
      clone: any;
      count = 0;
      currencySymbols: any;
      filterLevel = '';
      finances: any;
      getIcon: any;
      gettext: ng.gettext.gettextFunction;
      initialPaymentPlanTotals: any;
      invoiceExportButton: any;
      isGreen: any;
      numberInput: any;
      onCreateDocument: any;
      onCreatePaymentPlan: any;
      onPaymentPlanSelect: any;
      openDocumentModal: any;
      openDocxModal: any;
      openFinanceModal: any;
      openPaymentPlanModal: any;
      openRoleModal: any;
      paymentPlanId: any;
      paymentPlanTotals: any;
      paymentsExportConfig: any;
      selectToApprove: any;
      selectToDocument: any;
      selectToPay: any;
      selectToPayment: any;
      selection: any;
      showOverpaid: any;
      showPaymentsExportButton: any;
      tableData: any;
      tableOptions: any;
      total: any;
      constructor(
        $rootScope: GtRootScopeService,
        AccountsService: any,
        gettext: ng.gettext.gettextFunction,
        PaymentPlanFinancesService: PaymentPlanFinancesService,
        InvoiceFormFieldsService: any,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.AccountsService = AccountsService;
        this.PaymentPlanFinancesService = PaymentPlanFinancesService;
        this.InvoiceFormFieldsService = InvoiceFormFieldsService;

        this.finances = this.finances || [];
        this.total = this.total || {};
        this.tableOptions = {};
        this.tableData = {};
        this.selection = false;
        this.numberInput = false;
        this.invoiceExportButton = false;
        this.showPaymentsExportButton = false;
        this.paymentsExportConfig = '';
        this.paymentPlanId = null;
        this.onPaymentPlanSelect = () => {
          this.updateInitialPaymentPlanTotals();
        };
      }

      $onInit() {
        this.setTableOptions();
        this.paymentPlanTotals = {};
        this.initialPaymentPlanTotals = {};
        this.currencySymbols = {
          USD: '$',
          EUR: '€',
          GBP: '£',
          JPY: '¥',
          KZT: '₸',
          PLN: 'Zł',
          RUB: '₽',
          CNY: '¥',
          ILS: '₪',
          TRY: '₺',
        };
      }

      $onChanges(changes: any) {
        if (changes.activeReportConfig) {
          this.setTableOptions();
        }

        if (changes.finances || changes.count || changes.total) {
          this.finances.forEach(
            (fin: any) => (fin.$_inputedNumber = fin.$_inputedNumber || fin.amount_to_plan),
          );
          this.tableData = { rows: this.finances, count: this.count, total: this.total };
        }

        const isSelectionActive =
          changes.selectToPayment ||
          changes.selectToDocument ||
          changes.selectToApprove ||
          changes.selectToPay;

        if (changes && isSelectionActive) {
          this.toggleSelection();
        }
      }

      toggleSelection() {
        this.numberInput = this.selection =
          this.selectToPayment || this.selectToDocument || this.selectToApprove || this.selectToPay;

        if (this.numberInput) {
          this.finances.forEach((finance: any) => {
            finance.$_inputedNumber = finance.amount_to_plan;
          });
        }
        this.setTableOptions();
      }

      createPaymentPlan() {
        const selectedFinances = this.finances.filter((item: any) => {
          return !!item.$_selected;
        });
        this.onCreatePaymentPlan({
          finances: selectedFinances,
          paymentPlanId: this.paymentPlanId,
        });
        this.toggleSelection();
      }

      createDocument() {
        const selectedIds = this.finances
          .filter((item: any) => item.$_selected)
          .map((item: any) => item.id);
        this.onCreateDocument({ finances: selectedIds });
        this.toggleSelection();
      }

      approveInvoices() {
        const selectedFinances = this.finances.filter((item: any) => {
          return !!item.$_selected;
        });
        this.$rootScope.$broadcast('gt-invoices-approved', selectedFinances);
      }

      payInvoices() {
        const selectedFinances = this.finances.filter((item: any) => {
          return !!item.$_selected;
        });
        this.$rootScope.$broadcast('gt-invoices-paid', selectedFinances);
      }

      updatePaymentPlanTotals() {
        this.paymentPlanTotals = this.finances
          .filter((row: any) => row.$_selected && row.$_inputedNumber)
          .reduce(
            (res: any, row: any) => {
              res[row.currency_symbol] = (res[row.currency_symbol] || 0) + row.$_inputedNumber;
              return res;
            },
            { ...this.initialPaymentPlanTotals },
          );
      }

      updateInitialPaymentPlanTotals() {
        return this.PaymentPlanFinancesService.getPaymentPlanFinances({
          payment_plan: this.paymentPlanId,
        }).then((response: any) => {
          this.initialPaymentPlanTotals = response.results.reduce((res: any, row: any) => {
            res[row.currency_symbol] = (res[row.currency_symbol] || 0) + row.value;
            return res;
          }, {});
          this.updatePaymentPlanTotals();
        });
      }

      currencyTotalsVisibility() {
        return Object.keys(this.paymentPlanTotals).length != 0;
      }

      formatIban(iban: any) {
        if (!iban) {
          return '';
        }
        return iban.replace(/(.{4})/g, '$1 ');
      }

      getItemsCount() {
        return this.finances.length;
      }

      setTableOptions() {
        const defaultCurrencySymbol =
          this.PaymentPlanFinancesService.FinancesService.getDefaultCurrency().symbol;
        const options: any = {
          tableClass:
            'table table-responsive table-condensed main-table request-table payments_table',
          tableAltClass:
            'table table-responsive table-condensed main-table request-table payments_table sticky-table-first-td',
          tableName: 'finances',
          alignColHeight: true,
          filterLevel: this.filterLevel,
          columnDefs: [] as any[],
          tabs: [],
          activeReportConfig: this.activeReportConfig,
          rowData: this.finances,
          configurable: true,
          selection: this.selection,
          numberInput: this.numberInput,
          showMore: true,
          changePageSize: true,
          applyFilters: this.applyFilters,
          templateArgs: {
            openFinanceModal: (finance: any, data: any) =>
              this.openFinanceModal({ finance: finance, data: data }),
            openDocumentModal: (finance: any) => this.openDocumentModal({ finance: finance }),
            openDocxModal: (finance: any) => this.openDocxModal({ finance: finance }),
            openRoleModal: (id: any, model: any) =>
              this.openRoleModal({ roleId: id, roleModel: model }),
            openPaymentPlanModal: (plan: any) => this.openPaymentPlanModal({ plan: plan }),
            clone: (finance: any) => this.clone({ finance: finance }),
            isGreen: (finance: any) => this.isGreen({ finance: finance }),
            getIcon: (resourceName: any) => this.getIcon({ resourceName: resourceName }),
            addInvoiceButtonFunc: () => this.addInvoiceButtonFunc(),
            selectToPayment: this.selectToPayment,
            selectToDocument: this.selectToDocument,
            selectToApprove: this.selectToApprove,
            selectToPay: this.selectToPay,
            showOverpaid: this.showOverpaid,
            $rootScope: this.$rootScope,
            itemsCount: () => this.getItemsCount(),
            statusClass: {
              new: 'contract_new',
              process: 'contract_processing',
              paid: 'contract_executed',
              canceled: 'contract_executed',
            },
            assignmentClass: {
              reserve: 'contract_calculate',
            },
            showSelection: (item: any) =>
              (this.selectToPayment || this.selectToPay) &&
              item.status != 'canceled' &&
              (item.amount_to_plan > 0 ||
                (this.showOverpaid && item.amount_to_plan) ||
                (item.discount_amount > 0 && item.amount == 0 && item.amount_to_plan == 0)) &&
              (item.approval_status == 'approved' ||
                this.$rootScope.user.settings.ALLOW_PAYMENT_PLAN_UNAPPROVED_FOR_INVOICES),
            onRowSelect: () => this.updatePaymentPlanTotals(),
            approvalAction: (action: any, financeId: any, ContentType: any) =>
              this.AccountsService.voteApprovable(action, financeId, ContentType).then(() =>
                this.applyFilters(),
              ),
            formatIban: (iban: any) => this.formatIban(iban),
            defaultCurrencySymbol: defaultCurrencySymbol,
          },
          selectionCellTemplate: /*html*/ `
            <input
              ng-if="args.selectToDocument"
              type="checkbox"
              ng-model="item.$_selected"
              style="margin-right:5px; float: none"
            />
            <input
              ng-if="args.selectToApprove && item.approval_status === 'wait'"
              type="checkbox"
              ng-model="item.$_selected"
              style="margin-right:5px; float: none"
            />
            <input
              ng-if="args.showSelection(item)"
              type="checkbox"
              ng-model="item.$_selected"
              ng-change="args.onRowSelect()"
              style="margin-right:5px; float: none"
            />
          `,
          numberInputCellTemplate: /*html*/ `
            <input
              ng-class="{'positive-number': item.amount_to_plan > 0, 'negative-number': item.amount_to_plan < 0}"
              ng-if="args.showSelection(item)"
              type="number"
              ng-model="item.$_inputedNumber"
              ng-change="args.onRowSelect()"
              style="margin-right:5px;"
            />
            <span
              class="tooltip initial-transform"
              ng-if="!args.showSelection(item) && !args.selectToDocument && !args.selectToApprove"
            >
            <span ng-if="item.status == 'paid'" class="label label_success"><translate>Paid</translate></span>
            <span ng-if="item.status != 'paid' && item.approval_status != 'approved' && !args.$rootScope.user.settings.ALLOW_PAYMENT_PLAN_UNAPPROVED_FOR_INVOICE" class="label label_warning">
              <translate>Not approved</translate>
            </span>
            <span ng-if="item.status != 'paid' && item.paymentplans_data" class="label label_success">
              <translate>Is in a payment plan</translate>
            </span>
            <span ng-if="item.status == 'canceled'" class="label label_danger"><translate>Canceled</translate></span>
            </span>
            <span ng-if="item.amount == 0" class="label label_warning">
              <translate>Zero amount</translate>
            </span>
          `,
        };
        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('number'),
            showAlways: true,
            predicate: 'number',
            classExpr: "args.statusClass[item.status] + ' number-column'",
            cellTemplate: numberCellTemplate,
            totalTemplate: `
              <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate><br/>
              <label>{[{ args.itemsCount() }]} / {[{ count }]}</label>
              `,
          },
          {
            columnName: 'invoice_type',
            title: this.gettext('invoice type'),
            filters: [
              {
                type: 'select',
                predicate: 'invoice_type',
                label: this.gettext('Contract invoice type'),
                values: {
                  incoming: this.gettext('incoming'),
                  outgoing: this.gettext('outgoing'),
                },
              },
            ],
            cellTemplate: `
               <span>
                {[{ item.invoice_type | translate }]}
              </span>
              `,
          },
          {
            columnName: 'status',
            title: this.gettext('status'),
            predicate: 'status',
            cellTemplate: `
              <span
              class="label"
              ng-class="{ 'label_default': item.status == 'paid' , 'label_success': item.status == 'new', 'label_warning': item.status == 'process' } "
              style="margin-left: 2px"
              >
                {[{ item.status | translate }]}
              </span>
              <span class="label label_danger" ng-if="item.not_pay" style="margin-left: 2px">
                <i class="fa fa-exclamation-triangle"></i> <translate> Not pay</translate>
              </span>
              <span class="label" ng-if="item.discount != 100" style="margin-left: 2px">
                <i class="fa fa-exclamation-triangle"></i> <translate> Discount</translate>
              </span>
              <span
                class="label"
                ng-if="item.approval_status != 'on_approval' && item.approval_status != 'wait'"
                ng-class="{'label_success': item.approval_status == 'approved', 'label_danger': item.approval_status == 'rejected' }"
                style="margin-left: 2px"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.approval_status == 'approved', 'fa-times': item.approval_status == 'rejected' }"
                ></i>
                <translate>{[{ item.approval_status }]}</translate>
              </span>
            `,
          },
          {
            columnName: 'condition',
            title: this.gettext('condition'),
            predicate: 'condition',
            cellTemplate: statusCellTemplate,
          },
          {
            columnName: 'date_of_issuance',
            title: this.gettext('Issuance'),
            predicate: 'date',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date_of_issuance',
                endDateField: 'end_date_of_issuance',
              },
            ],
            cellTemplate: `
              <div
                class="tooltip"
                data-tip="{[{ 'Date of issuance'| translate }]} {[{ item.date | date:'dd MMM yy'}]}"
              >
                <i class="fa fa-calendar"></i> {[{ item.date || "---" | date:'dd.MM.yy'}]}
              </div>
            `,
          },
          {
            columnName: 'contract_counterparty',
            title: this.gettext('Contract counterparty'),
            cellTemplate: `
              <span ng-repeat="client in item.contract_clients_data">
                <a ui-sref="gt.page.client({ id: client.client_id })" target="_blank" class="btn-link">
                  {[{ client.client_name }]}
                </a>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'company_group_owner_title',
            title: this.gettext('Company group owner'),
            cellTemplate: /*html*/ `
              <span ng-if="item.company_group_owner_title">
                {[{ item.company_group_owner_title }]}
              </span>
            `,
          },
          {
            columnName: 'company_group_counterparty_title',
            title: this.gettext('Company group counterparty'),
            cellTemplate: /*html*/ `
              <span ng-if="item.company_group_counterparty_title">
                {[{ item.company_group_counterparty_title }]}
              </span>
            `,
          },
          {
            columnName: 'assignment',
            title: this.gettext('assignment'),
            classExpr: 'args.assignmentClass[item.assignment]',
            predicate: 'assignment',
            filters: [
              {
                type: 'select',
                predicate: 'assignment',
                label: this.gettext('Assignment'),
                values: {
                  commercial: this.gettext('Commercial Invoice'),
                  proforma: this.gettext('Proforma Invoice'),
                  debit: this.gettext('Debit Note'),
                  credit: this.gettext('Credit Note'),
                  reserve: this.gettext('Reserve'),
                },
              },
            ],
            cellTemplate: `
              <span
                class="label"
                ng-class="{
                  'label_default': item.assignment == 'commercial',
                  'label_warning': item.assignment == 'proforma',
                  'label_info': (item.assignment == 'debit' || item.assignment == 'credit')
                } "
                style="margin-left: 2px"
              >
                {[{ item.assignment | translate }]}
              </span>
            `,
          },
          {
            columnName: 'related_invoice_number',
            title: this.gettext('related invoice'),
            predicate: 'related_invoice_number',
            cellTemplate: `
              <span
              ng-if="item.related_invoice_number"
              >
              <i class="fa fa-credit-card-alt"></i> {[{ item.related_invoice_number }]}
              </span>
            `,
          },
          {
            columnName: 'date_of_withdrawal',
            title: this.gettext('Withdrawal'),
            predicate: 'date_of_withdrawal',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date_of_withdrawal',
                endDateField: 'end_date_of_withdrawal',
              },
            ],
            cellTemplate: `
              <div
                ng-if="item.date_of_withdrawal"
              >
                <i class="fa fa-calendar"></i> {[{ item.date_of_withdrawal || "---" | date:'dd.MM.yy'}]}
              </div>
            `,
          },
          {
            columnName: 'received',
            title: this.gettext('received'),
            predicate: 'date_of_receiving',
            cellTemplate: `
              <div
                class="tooltip"
                ng-if="item.date_of_receiving"
                data-tip="{[{ 'creation date'| translate }]} {[{ item.date_of_receiving | date:'dd MMM yy'}]}"
              >
                <i class="fa fa-calendar"></i> {[{ item.date_of_receiving || "---" | date:'dd.MM.yy'}]}
              </div>
            `,
          },
          {
            columnName: 'paid_plan',
            title: this.gettext('paid (plan)'),
            predicate: 'date_of_execution',
            cellTemplate: `
              <div
                class="tooltip"
                ng-if="item.date_of_execution"
                data-tip="{[{ 'payment date (plan)'| translate }]} {[{ item.date_of_execution | date:'dd MMM yy'}]}"
                ng-class="{
                  'label label_danger': (item.date_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 < 0 && item.status!='paid'
                }"
              >
                <i class="fa fa-calendar"></i> {[{ item.date_of_execution || "---" | date:'dd.MM.yy'}]}
              </div>
            `,
          },
          {
            columnName: 'payment_delay',
            title: this.gettext('payment delay'),
            predicate: 'paid_diff_days',
            class: 'td-left-align',
            cellTemplate: `
              <span ng-if="item.paid_diff_days">
              <span
                class="smaller-label label tooltip"
                ng-class="{'label_danger': item.paid_diff_days > 0, 'label_success': item.paid_diff_days < 0 }"
                data-tip="{[{ 'days of payments delay'| translate }]} "
              >
                <i class="fa fa-calendar-check"></i> {[{ item.paid_diff_days }]}
                </span>
                <translate>days</translate>
              </span>
              <span
                class="smaller-label tooltip label-smoke"
                style="margin-left: 5px"
                ng-if="item.paid_diff_coef"
                data-tip="{[{ 'coefficient of payments delay'| translate }]}">
                (<translate>AVG delay</translate>
                <i class="fa fa-thermometer-empty"></i> {[{ item.paid_diff_coef | number:2}]})</span>
            `,
          },
          {
            columnName: 'paid_max_payments_date',
            title: this.gettext('paid date'),
            predicate: 'max_payments_date',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date_of_execution_fact',
                endDateField: 'end_date_of_execution_fact',
              },
            ],
            cellTemplate: `
              <div
                class="tooltip"
                ng-if="item.date_of_execution_fact || item.date_of_payment"
                data-tip="{[{ 'payment date (fact)'| translate }]} {[{ item.date_of_payment | date:'dd MMM yy'}]}"
                ng-class="{ 'label label_success': (item.date_of_execution_fact < item.date_of_execution) || (item.date_of_payment < item.date_of_execution) || !item.date_of_execution, 'label label_danger': ((item.date_of_execution_fact > item.date_of_execution) || item.date_of_payment > item.date_of_execution) && item.date_of_execution}"
              >
                <i class="fa fa-calendar"></i> {[{ item.date_of_execution_fact ||
                item.date_of_payment || "---" | date:'dd.MM.yy'}]}
              </div>
            `,
          },
          {
            columnName: 'amount',
            title: this.gettext('amount'),
            predicate: 'default_currency_amount',
            filters: [
              {
                type: 'number',
                predicate: 'amount',
                addon: this.gettext('Exact'),
              },
            ],
            classExpr:
              "{ 'alert-danger': item.invoice_type == 'incoming', 'alert-success': item.invoice_type == 'outgoing' }",
            cellTemplate: `
              <span
                class="bigger-label label negative-number span-right"
                ng-if="item.invoice_type == 'incoming'"
              >
                <i class="fa fa-minus"></i> {[{ item.amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
              <span
                class="bigger-label label positive-number  span-right"
                ng-if="item.invoice_type == 'outgoing'"
              >
                <i class="fa fa-plus"></i> {[{ item.amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item.totals">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total amount of all outgoing invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  + {[{total.outgoing_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total amount of all incoming invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  - {[{total.incoming_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  = {[{ total.outgoing_amount - total.incoming_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div><br/>
              </div>
            `,
          },
          {
            columnName: 'amount_usd',
            title: this.gettext('amount, $'),
            predicate: 'default_currency_amount',
            filters: [
              {
                type: 'number',
                predicate: 'default_currency_amount',
                addon: this.gettext('Exact'),
              },
            ],
            cellTemplate: `
              <span class="label span-right" ng-if="item.invoice_type == 'incoming'">
                - {[{ item.default_currency_amount | number:2 }]} <span class="smaller-label"> $</span>
              </span>
              <span class="label span-right" ng-if="item.invoice_type == 'outgoing'">
                + {[{ item.default_currency_amount | number:2 }]} <span class="smaller-label"> $</span>
              </span>
            `,
            totalTemplate: `
              <div class="currency-totals">
                <label>$ </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total amount of all outgoing invoices converted in USD'| translate }]}"
                >
                  + {[{item.outgoing_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total amount of all incoming invoices converted in USD'| translate }]}"
                >
                  - {[{item.incoming_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming'| translate }]}"
                >
                  = {[{ item.invoice_default_currency_balance || 0 | number: 2 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'currency_rate',
            title: this.gettext('currency rate'),
            class: 'td-left-align',
            filters: [
              {
                type: 'number',
                predicate: 'rate',
              },
            ],
            cellTemplate: `
              <span ng-if="item.rate">
                <span class="label"> {[{ item.rate || 0 | number:3 }]} </span>
              </span>
            `,
          },
          {
            columnName: 'payments_currency_exchange_rate',
            title: this.gettext('Payments currency exchange'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-if="item.payments_currency_exchange_rate">
                <span class="label"> {[{ item.payments_currency_exchange_rate || 0 | number:3 }]} </span>
              </span>
            `,
          },
          {
            columnName: 'volume',
            title: this.gettext('volume'),
            class: 'td-right-align',
            filters: [
              {
                type: 'number',
                predicate: 'volume',
                addon: this.gettext('Exact'),
              },
            ],
            cellTemplate: `
              <span ng-if="item.volume">
                <span class="label">
                  {[{ item.volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                </span>
              </span>
            `,
            totalTemplate: `
              <span ng-if="item.volume">
                <span class="label">
                  {[{ item.volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                </span>
              </span>
            `,
          },
          {
            columnName: 'full_invoiced_volume',
            title: this.gettext('Quantity Total'),
            class: 'td-right-align',
            cellTemplate: `
              <span ng-if="item.full_invoiced_volume">
                <span class="label">
                  {[{ item.full_invoiced_volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                </span>
              </span>
            `,
            totalTemplate: `
              <span ng-if="item.full_invoiced_volume">
                <span class="label">
                  {[{ item.full_invoiced_volume || 0 | number:3 }]}
                  <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                </span>
              </span>
            `,
          },
          {
            columnName: 'interest_rate',
            title: this.gettext('Interest rate'),
            predicate: 'interest_rate',
            class: 'td-right-align',
            cellTemplate: `
              <span
                class="tooltip label label_default"
                data-tip="{[{ 'interest_rate'| translate}]} %"
                ng-if="item.interest_rate != 100"
              >
                {[{ item.interest_rate | gtDecimal:2 }]}%
              </span>
              <span class="label" ng-if="item.amount && item.interest_rate">
              {[{ ((item.amount * (item.interest_rate / 100)) / 360) | number:2 }]}
              </span>
            `,
          },
          {
            columnName: 'interest_rate_delay',
            title: this.gettext('interest rate delay'),
            class: 'td-right-align',
            cellTemplate: `
              <span class="label" ng-if="item.amount && item.interest_rate && item.paid_diff_coef">
                {[{ ((item.amount * (item.interest_rate / 100)) / 360) * item.paid_diff_coef | number:2 }]}
              </span>
            `,
          },
          {
            columnName: 'discount',
            title: this.gettext('discount'),
            predicate: 'discount',
            class: 'td-right-align',
            filters: [
              {
                type: 'text',
                predicate: 'min_discount',
                addon: this.gettext('Min'),
              },
              {
                type: 'text',
                predicate: 'max_discount',
                addon: this.gettext('Max'),
              },
            ],
            cellTemplate: `
              <span
              class="tooltip"
              data-tip="{[{ 'discount mt'| translate}]}"
              ng-if="item.discount_mt > 0"
              >
                {[{ item.discount_mt | gtDecimal:2 }]} {[{ item.currency_symbol }]} / {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
                <span
                class="tooltip"
                data-tip="{[{ 'discount amount'| translate}]}"
                ng-if="item.discount_amount > 0"
              >
                {[{ item.discount_amount | gtDecimal:2 }]} {[{ item.currency_symbol }]}
              </span>
              <span
                class="tooltip label label_default"
                data-tip="{[{ 'discount'| translate}]} %"
                ng-if="item.discount != 100"
              >
                {[{ item.discount | gtDecimal:2 }]}%
              </span>
            `,
          },
          {
            columnName: 'payment_conditions',
            title: this.gettext('payment conditions'),
            class: 'td-left-align',
            filters: [
              {
                type: 'text',
                predicate: 'min_payment_conditions',
                addon: this.gettext('Min'),
              },
              {
                type: 'text',
                predicate: 'max_payment_conditions',
                addon: this.gettext('Max'),
              },
            ],
            cellTemplate: `
              <span
                class="tooltip label label_default"
                data-tip="{[{ 'payment condition'| translate}]}"
                ng-if="item.payment_conditions <= 100"
              >
                {[{ item.payment_conditions }]}%
              </span>
            `,
          },
          {
            columnName: 'paid_sum_payments_amount',
            title: this.gettext('Total Paid'),
            predicate: 'sum_payments_amount',
            classExpr:
              "{ 'alert-danger': item.invoice_type == 'incoming' && item.fact_amount, 'alert-success': item.invoice_type == 'outgoing' && item.fact_amount }",
            cellTemplate: `
              <span
                class="bigger-label label negative-number span-right"
                ng-if="item.invoice_type == 'incoming' && item.fact_amount"
              >
                <i class="fa fa-minus"></i> {[{ item.fact_amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                <span ng-if="!item.to_pay"><i class="fa fa-check-circle"></i></span>
              </span>
              <span
                class="bigger-label label positive-number  span-right"
                ng-if="item.invoice_type == 'outgoing' && item.fact_amount"
              >
                <i class="fa fa-plus"></i> {[{ item.fact_amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                <span ng-if="!item.to_pay"><i class="fa fa-check-circle"></i></span>
              </span>
              <span class="label-opacity" ng-if="!item.fact_amount"> --- </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item.totals">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount by all outgoing invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  + {[{total.outgoing_payments_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount by all incoming invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  - {[{total.incoming_payments_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  = {[{ total.payments_balance || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div><br/>
              </div>
            `,
          },
          {
            columnName: 'paid_sum_payments_amount_USD',
            title: `${this.gettext('Total paid')}, ${defaultCurrencySymbol}`,
            cellTemplate: `
              <span
                class="label span-right"
                ng-if="item.fact_amount && item.invoice_type == 'incoming'"
              >
                {[{ item.fact_default_currency_amount | number: 2 }]} $
              </span>
              <span
                class="label span-right"
                ng-if="item.fact_amount && item.invoice_type == 'outgoing'"
              >
                {[{ item.fact_default_currency_amount | number: 2 }]} $
              </span>
              <span class="label-opacity" ng-if="!item.fact_amount"> --- </span>
            `,
            totalTemplate: `
              <div class="currency-totals">
                <label> $ </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount of all outgoing invoices converted in USD'| translate }]}"
                >
                  + {[{item.outgoing_payments_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount of all incoming invoices converted in USD'| translate }]}"
                >
                  - {[{item.incoming_payments_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming'| translate }]}"
                >
                  = {[{ item.payments_default_currency_balance || 0 | number:
                  2 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'offsets_sum_amount',
            title: this.gettext('Offset'),
            predicate: 'sum_offsets_amount',
            classExpr:
              "{ 'alert-danger': item.invoice_type == 'incoming' && item.offset_amount, 'alert-success': item.invoice_type == 'outgoing' && item.offset_amount }",
            cellTemplate: `
              <span
                class="bigger-label label negative-number span-right"
                ng-if="item.invoice_type == 'incoming' && item.offset_amount"
              >
                <i class="fa fa-minus"></i> {[{ item.offset_amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                <span ng-if="!item.to_pay"><i class="fa fa-check-circle"></i></span>
              </span>
              <span
                class="bigger-label label positive-number  span-right"
                ng-if="item.invoice_type == 'outgoing' && item.offset_amount"
              >
                <i class="fa fa-plus"></i> {[{ item.offset_amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                <span ng-if="!item.to_pay"><i class="fa fa-check-circle"></i></span>
              </span>
              <span class="label-opacity" ng-if="!item.offset_amount"> --- </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item.totals">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount by all outgoing invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  + {[{total.outgoing_offsets_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount by all incoming invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  - {[{total.incoming_offsets_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  = {[{ total.outgoing_offset_amount - total.incoming_offset_amount || 0 | number:
                  2 }]}
                </span>
                <div class="clearfix"></div><br/>
              </div>
            `,
          },
          {
            columnName: 'offsets_sum_default_currency_amount',
            title: `${this.gettext('Offset')}, ${defaultCurrencySymbol}`,
            cellTemplate: `
              <span
                class="label span-right"
                ng-if="item.offset_amount && item.invoice_type == 'incoming'"
              >
                {[{ item.offset_default_currency_amount | number: 2 }]} $
              </span>
              <span
                class="label span-right"
                ng-if="item.offset_amount && item.invoice_type == 'outgoing'"
              >
                {[{ item.offset_default_currency_amount | number: 2 }]} $
              </span>
              <span class="label-opacity" ng-if="!item.offset_amount"> --- </span>
            `,
            totalTemplate: `
              <div class="currency-totals">
                <label> $ </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount of all outgoing invoices converted in USD'| translate }]}"
                >
                  + {[{item.outgoing_offsets_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount of all incoming invoices converted in USD'| translate }]}"
                >
                  - {[{item.incoming_offsets_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming'| translate }]}"
                >
                  = {[{ item.offsets_default_currency_balance || 0 | number:
                  2 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'payments_sum_amount',
            title: this.gettext('Payments'),
            predicate: 'sum_payments_amount',
            classExpr:
              "{ 'alert-danger': item.invoice_type == 'incoming' && item.payment_amount, 'alert-success': item.invoice_type == 'outgoing' && item.payment_amount }",
            cellTemplate: `
              <span
                class="bigger-label label negative-number span-right"
                ng-if="item.invoice_type == 'incoming' && item.payment_amount"
              >
                <i class="fa fa-minus"></i> {[{ item.payment_amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                <span ng-if="!item.to_pay"><i class="fa fa-check-circle"></i></span>
              </span>
              <span
                class="bigger-label label positive-number  span-right"
                ng-if="item.invoice_type == 'outgoing' && item.payment_amount"
              >
                <i class="fa fa-plus"></i> {[{ item.payment_amount | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                <span ng-if="!item.to_pay"><i class="fa fa-check-circle"></i></span>
              </span>
              <span class="label-opacity" ng-if="!item.payment_amount"> --- </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item.totals">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount by all outgoing invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  + {[{total.outgoing_payments_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount by all incoming invoices in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  - {[{total.incoming_payments_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  = {[{ total.outgoing_payment_amount - total.incoming_payment_amount || 0 | number:
                  2 }]}
                </span>
                <div class="clearfix"></div><br/>
              </div>
            `,
          },
          {
            columnName: 'payments_sum_default_currency_amount',
            title: `${this.gettext('Payments')}, ${defaultCurrencySymbol}`,
            cellTemplate: `
              <span
                class="label span-right"
                ng-if="item.payment_amount && item.invoice_type == 'incoming'"
              >
                {[{ item.payment_default_currency_amount | number: 2 }]} $
              </span>
              <span
                class="label span-right"
                ng-if="item.payment_amount && item.invoice_type == 'outgoing'"
              >
                {[{ item.payment_default_currency_amount | number: 2 }]} $
              </span>
              <span class="label-opacity" ng-if="!item.payment_amount"> --- </span>
            `,
            totalTemplate: `
              <div class="currency-totals">
                <label> $ </label>
                <div class="clearfix"></div>
                <span
                  class="label positive-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount of all outgoing invoices converted in USD'| translate }]}"
                >
                  + {[{item.outgoing_payments_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label negative-number label-strong tooltip"
                  data-tip="{[{ 'Total paid amount of all incoming invoices converted in USD'| translate }]}"
                >
                  - {[{item.incoming_payments_default_currency_amount || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming'| translate }]}"
                >
                  = {[{ item.payments_default_currency_balance || 0 | number:
                  2 }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'to_pay',
            class: 'td-right-align',
            title: this.gettext('to pay'),
            cellTemplate: `
              <a
                permission
                permission-only="'change_payment'"
                class="btn btn-xs btn_success"
                ng-class="{'btn-success': item.to_pay > '0' && item.status !== 'paid' && item.invoice_type === 'outgoing', 'btn-danger': item.to_pay > '0' && item.status !== 'paid' && item.invoice_type === 'incoming' } "
                ng-if="item.status !== 'paid' && item.status !== 'canceled'"
                ng-click="args.openFinanceModal(item, {tab: 'partial'})"
              >
                <span ng-if="item.invoice_type === 'incoming' && item.to_pay > 0">
                  <translate>To pay</translate>
                </span>
                <span ng-if="item.invoice_type === 'outgoing' && item.to_pay > 0">
                  <translate>To receive</translate>
                </span>

                <span ng-if="(item.to_pay < 0) || (item.discount_amount > 0 && item.amount == 0 && item.to_pay == 0)">
                  <translate>Overpaid</translate>
                </span>
              </a>
              <span ng-if="item.to_pay && item.status !== 'canceled'" ng-class = "{'label_warning': item.to_pay > '0' && item.status === 'process'}">
                  <span ng-if="item.to_pay > '0' && item.status === 'process'" class="smaller-label">
                    <translate>remains</translate> :
                  </span>
                  {[{ item.to_pay | number:2 }]} {[{ item.currency_symbol }]}
                </span>
            `,
            totalTemplate: `
              <div class="currency-totals" ng-repeat="total in item.totals">
                <label> {[{ total.currency_symbol || '---' }]} </label>
                <div class="clearfix"></div>
                <label><translate>outgoing </translate>: </label>
                <span
                  class="label label-strong"
                >
                {[{total.outgoing_balance || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <label><translate>incoming</translate>: </label>
                <span
                  class="label label-strong"
                >
              {[{total.incoming_balance || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div>
                <span
                  class="label label-strong tooltip"
                  data-tip="{[{ 'Total outgoing - Total incoming in'| translate }]} {[{ total.currency_symbol}]}"
                >
                  = {[{ total.outgoing_balance - total.incoming_balance || 0 | number: 2 }]}
                </span>
                <div class="clearfix"></div><br/>
              </div>
            `,
          },
          {
            columnName: 'logistics_total_value',
            title: this.gettext('logistics total value'),
            class: 'td-right-align',
            cellTemplate: `
              <div ng-if="item.logistics_total_value" ng-class="{ 'alert-danger': item.logistics_total_value != item.amount, 'alert-success': item.logistics_total_value == item.amount }">
                <span class="label">
                  {[{ item.amount | number:2 }]} {[{ item.currency_symbol }]} /
                </span>
                <span class="label">
                  {[{ item.logistics_total_value | number:2 }]} {[{ item.currency_symbol }]}
                </span>
              </div>
            `,
          },
        ];
        if (this.$rootScope.user.settings.USE_FINANCE_ACCOUNTING) {
          options.columnDefs.push({
            columnName: 'invoiced_logistics',
            title: this.gettext('invoiced logistics'),
            class: 'td-right-align',
            cellTemplate: `
              <div ng-if="item.invoiced_logistics" ng-class="{ 'alert-danger': !args.isGreen(item), 'alert-success': args.isGreen(item) }">
                <span ng-if="item.invoiced_logistics != 0" class="label">
                  {[{ item.inventory_invoice_position_sum | number:2 }]} {[{ item.currency_symbol }]}
                  /
                </span>
                <span ng-if="item.invoiced_logistics != 0" class="label">
                  {[{ item.invoiced_logistics | number:2 }]} {[{ item.currency_symbol }]}
                </span>
                <span ng-if="item.invoiced_logistics == 0" class="label positive-number">
                  0 {[{ item.currency_symbol }]} /
                </span>
              </div>
            `,
          });
        }
        options.columnDefs.push(
          {
            columnName: 'counterparty',
            title: this.gettext('counterparty'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-if="item.clientrole_from && item.invoice_type == 'incoming'"
                ng-click="args.openRoleModal(item.clientrole_from, item.clientrole_from_role)"
                class="btn-link"
                data-tip="{[{ item.clientrole_from_name }]}"
              >
                <i class="fa {[{args.getIcon('clients.' + item.clientrole_from_role)}]}"></i> {[{ item.clientrole_from_name | cut:true:30:'...' || "---" }]}
              </span>
              <span
                ng-if="item.clientrole_to && item.invoice_type == 'outgoing'"
                ng-click="args.openRoleModal(item.clientrole_to, item.clientrole_to_role)"
                class="btn-link"
                data-tip="{[{ item.clientrole_to_name }]}"
              >
                <i class="fa {[{args.getIcon('clients.' + item.clientrole_to_role)}]}"></i> {[{ item.clientrole_to_name | cut:true:30:'...' || "---"
                }]}
              </span>
            `,
          },
          {
            columnName: 'side_from',
            title: this.gettext('side from'),
            class: 'td-left-align',
            predicate: 'clientrole_from_name',
            filters: [
              {
                type: 'ui-select',
                predicate: 'clientrole_from',
                label: this.gettext('clientrole from'),
                resource: 'clients.Clientrole',
              },
            ],
            cellTemplate: `

              <span
                ng-if="item.clientrole_from"
                ng-click="args.openRoleModal(item.clientrole_from, item.clientrole_from_role)"
                class="btn-link"
                data-tip="{[{ item.clientrole_from_name }]}"
              >
                <i class="fa {[{args.getIcon('clients.' + item.clientrole_from_role)}]}"></i> {[{ item.clientrole_from_name | cut:true:30:'...' || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'side_from_status',
            title: this.gettext('side from status'),
            class: 'td-left-align',
            predicate: 'client_from_approval_status',
            filters: [
              {
                type: 'ui-select',
                predicate: 'clientrole_from',
                label: this.gettext('clientrole from'),
                resource: 'clients.Clientrole',
              },
            ],
            cellTemplate: `
             <span
                class="label smaller-label"
                ng-class="{ 'label_warning': item.client_from_approval_status == 'wait', 'label_danger': item.client_from_approval_status == 'rejected', 'label_success': item.client_from_approval_status == 'approved', 'label_default': item.client_from_approval_status == 'process' } "
              >
                <i class="fa fa-check-circle" ng-if="item.client_from_approval_status == 'approved'"></i>
                {[{ item.client_from_approval_status | translate }]}
              </span>
            `,
          },
          {
            columnName: 'side_to',
            title: this.gettext('side to'),
            class: 'td-left-align',
            predicate: 'clientrole_to_name',
            filters: [
              {
                type: 'ui-select',
                predicate: 'clientrole_to',
                label: this.gettext('clientrole to'),
                resource: 'clients.Clientrole',
              },
            ],
            cellTemplate: `
              <span
                ng-if="item.clientrole_to"
                ng-click="args.openRoleModal(item.clientrole_to, item.clientrole_to_role)"
                class="btn-link"
                data-tip="{[{ item.clientrole_to_name }]}"
              >
                <i class="fa {[{args.getIcon('clients.' + item.clientrole_to_role)}]}"></i> {[{ item.clientrole_to_name | cut:true:30:'...' || "---"
                }]}
              </span>
            `,
          },
          {
            columnName: 'side_to_status',
            title: this.gettext('side to status'),
            class: 'td-left-align',
            predicate: 'client_to_approval_status',
            filters: [
              {
                type: 'ui-select',
                predicate: 'clientrole_to',
                label: this.gettext('clientrole to'),
                resource: 'clients.Clientrole',
              },
            ],
            cellTemplate: `
             <span
                class="label smaller-label"
                ng-class="{ 'label_warning': item.client_to_approval_status == 'wait', 'label_danger': item.client_to_approval_status == 'rejected', 'label_success': item.client_to_approval_status == 'approved', 'label_default': item.client_to_approval_status == 'process' } "
              >
                <i class="fa fa-check-circle" ng-if="item.client_to_approval_status == 'approved'"></i>
                {[{ item.client_to_approval_status | translate }]}
              </span>
            `,
          },
          {
            columnName: 'quantity_total_amount_sum',
            title: this.gettext('quantity total amount'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-if="item.quantity_total_amount_sum">
                {[{ item.quantity_total_amount_sum | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
            `,
          },
          {
            columnName: 'position_passports',
            title: this.gettext('Passports'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-repeat="passport in item.position_passports | unique:'title' || []"
                class="btn-link"
                ui-sref="gt.page.passport({id: passport.id})"
              >
                <i class="fa fa-exchange"></i> {[{ passport.title | cut:true:40:' ...' }]}
                <span
                  class="label smaller-label"
                  ng-class="{
                     'label_success': passport.status == 'Request',
                     'label_default': passport.status == 'Cancelled',
                     'label-default': passport.status == 'Executed',
                     'label_calculate': passport.status == 'Approved',
                     'label_warning': passport.status == 'Processing' || passport.status == 'cargo_partial_executed' || passport.status == 'cargo_executed'
                  } "
                  style="margin-left: 5px"
                  ng-if="passport.status"
                >
                  {[{ passport.status }]}
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'position_passports_vessels',
            title: this.gettext('Vessels'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-repeat="passport in item.position_passports | unique:'vessel_name' || []"
                ng-if="passport.vessel_name"
              >
                <i class="fa fa-ship"></i> {[{ passport.vessel_name }]}

                <span
                  class="label smaller-label"
                  ng-class="{
                     'label_success': passport.voyage_status == 'New',
                     'label_default': passport.voyage_status == 'Washout' || passport.voyage_status == 'Cancelled',
                     'label-default': passport.voyage_status == 'Executed',
                     'label_calculate': passport.voyage_status == 'pod_under_way'|| passport.voyage_status == 'at_discharging_port' || passport.voyage_status == 'Discharging' || passport.voyage_status == 'Discharged',
                     'label_warning': passport.voyage_status == 'pol_under_way' || passport.voyage_status == 'at_loading_port' || passport.voyage_status == 'Loading' || passport.voyage_status == 'Loaded'
                  } "
                  style="margin-left: 5px"
                  ng-if="passport.status"
                >
                {[{ passport.voyage_status }]}
                </span>
                <span ng-if="passport.voyage_etc_destination || passport.voyage_eta_destination || passport.voyage_etb_destination" class="label label_default smaller-label">
                <span
                      ng-if="passport.voyage_eta_destination && !passport.voyage_etc_destination && !passport.voyage_etb_destination"
                    >
                      ETA {[{passport.voyage_eta_destination | date:'dd MMM'}]}</span
                    >
                    <span
                      ng-if="passport.voyage_etb_destination && !passport.voyage_etc_destination"
                    >
                      ETB {[{passport.voyage_etb_destination | date:'dd MMM'}]}</span
                    >
                    <span ng-if="passport.voyage_etc_destination">
                      ETC {[{passport.voyage_etc_destination | date:'dd MMM'}]}</span
                    >
                </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'contract',
            title: this.gettext('contract'),
            class: 'td-left-align small-font-cell contract-column',
            filters: [
              {
                type: 'select',
                predicate: 'contract_deal_type',
                label: this.gettext('Contract deal type'),
                values: {
                  spot: this.gettext('spot'),
                  forward: this.gettext('forward'),
                  brokerage: this.gettext('brokerage'),
                  services: this.gettext('services'),
                  export: this.gettext('export'),
                  intermediate: this.gettext('intermediate'),
                },
              },
            ],
            cellTemplate: /*html*/ `
              <span ng-if="item.position_contracts && item.position_contracts.length < 3">
                <span
                  ng-repeat="position_contract in item.position_contracts | unique:'contract_number'"
                  ui-sref="gt.page.contract({id: position_contract.contract_id})"
                  class="btn-link"
                  title="{[{ position_contract.contract_number }]} {[{ position_contract.crop_title ? '(' + position_contract.crop_title + ')' : '' }]}">
                  <i class="fa fa-file-text"></i> {[{ position_contract.contract_number | cut:true:20:' ...' }]}
                  <span ng-if="position_contract.crop_title" class="smaller-label">
                    ( <span>{[{ position_contract.crop_title }]}</span> )
                  </span>
                  <div class="clearfix"></div>
                </span>
                <div class="clearfix"></div>
              </span>

              <span
                class="btn-link"
                ng-if="item.position_contracts && item.position_contracts.length >= 3"
                ng-click="item.contractsExpanded = !item.contractsExpanded"
                title="{[{item.position_contracts.length}]} contracts">
                <i class="fa fa-file-text"></i> {[{item.position_contracts.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.contractsExpanded">
                <span
                  ng-repeat="position_contract in item.position_contracts"
                  ui-sref="gt.page.contract({id: position_contract.contract_id})"
                  title="{[{ position_contract.contract_number }]} {[{ position_contract.crop_title ? '(' + position_contract.crop_title + ')' : '' }]}">
                  {[{ position_contract.contract_number | cut:true:15:' ...' }]}
                  <span ng-if="position_contract.crop_title" class="smaller-label">
                    ( <span>{[{ position_contract.crop_title }]}</span> )
                  </span>
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.position_contracts.length"> --- </span>
            `,
          },
          {
            columnName: 'contract_payment_conditions',
            title: this.gettext('contract payment conditions'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-if="item.position_contracts && item.position_contracts.length < 3">
                <span
                  ng-repeat="position_contract in item.position_contracts | unique:'payment_conditions_option_title'"
                  ui-sref="gt.page.contract({id: position_contract.contract_id})"
                  class="tooltip"
                  data-tip=" {[{ position_contract.payment_conditions_option_title }]}"
                >
                  {[{ position_contract.payment_conditions_option_title | cut:true:20:' ...'  }]}
                </span>
              </span>

              <span
                class="btn-link"
                ng-if="item.position_contracts && item.position_contracts.length >= 3"
                ng-click="item.contractsExpanded = !item.contractsExpanded"
              >
                <i class="fa fa-file-text"></i> {[{item.position_contracts.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.contractsExpanded">
                <span
                  ng-repeat="position_contract in item.position_contracts | unique:'payment_conditions_option_title'"
                  ui-sref="gt.page.contract({id: position_contract.contract_id})"
                  class="tooltip"
                  data-tip=" {[{ position_contract.payment_conditions_option_title }]}"
                >
                  {[{ position_contract.payment_conditions_option_title | cut:true:20:' ...'  }]}
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.position_contracts.length"> --- </span>
            `,
          },
          {
            columnName: 'bank',
            title: this.gettext('bank_name'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-if="item.bank_name"

              >
                <i class="fa fa-bank"></i> {[{ item.bank_name || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'counterpaty_payment_info_bank_name',
            title: this.gettext('counterparty payment info'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-if="item.counterpaty_payment_info_bank_name"
              >
                <i class="fa fa-bank"></i> {[{ item.counterpaty_payment_info_bank_name }]}
              </span>
              <div class="clearfix"></div>
              <span
                ng-if="item.counterpaty_payment_info_branch_name"
              >
                {[{ item.counterpaty_payment_info_branch_name }]}
              </span>
            `,
          },
          {
            columnName: 'owner_bank_account',
            title: this.gettext('Owner bank account'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-if="item.owner_bank_account_name"
              >
                <i class="fa fa-bank"></i> {[{ item.owner_bank_account_name }]}
              </span>
              <div class="clearfix"></div>
              <span
                ng-if="item.owner_bank_account_branch_name"
              >
                {[{ item.owner_bank_account_branch_name }]}
              </span>
            `,
          },
          {
            columnName: 'account',
            title: this.gettext('finance_account_title'),
            class: 'td-left-align',
            cellTemplate: `
              <span
                ng-if="$root.user.settings.USE_FINANCE_ACCOUNTING && item.finance_account_title"
              >
                <i class="fa fa-empire"></i> {[{ item.finance_account_title || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'uses',
            title: this.gettext('uses'),
            class: 'td-left-align',
            filters: [
              {
                type: 'select',
                predicate: 'use_list',
                label: this.gettext('Choose usage'),
                values: {
                  cargo: this.gettext('Cargo'),
                  costs: this.gettext('Costs'),
                  gains: this.gettext('Gains'),
                  expenses: this.gettext('Expenses'),
                  washout: this.gettext('Washout'),
                  credit: this.gettext('Credit'),
                  other: this.gettext('Other'),
                },
              },
            ],
            cellTemplate: `
              <span ng-repeat="position in item.invoicepositions_set">
                <span>{[{ position.use }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'subuses',
            title: this.gettext('subuses'),
            class: 'td-left-align',
            cellTemplate: `
              <span ng-repeat="position in item.invoicepositions_set">
                <span class="smaller-label" ng-if="position.subuses">
                  {[{ position.subuses }]}
                </span>
                <span class="smaller-label label-smoke" ng-if="position.use == 'cargo'">{[{ position.quantity_sum | number: 3 }]} {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'documents',
            title: this.gettext('documents'),
            cellTemplate: `
              <a
                class="modal-btn"
                ng-click="args.openDocumentModal(item, 'documents');"
                ng-class="{ 'label-opacity': !item.document_count }"
              >
                <i class="fa fa-upload"></i> {[{ item.document_count || 0 }]}
              </a>
            `,
          },
          {
            columnName: 'business_unit',
            title: this.gettext('Business unit'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'business_unit_list',
                label: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
              },
            ],
            cellTemplate: `
              <span ng-if="item.business_unit_title">
                {[{ item.business_unit_title | cut:true:30:' ...' }]}
              </span>
              <span ng-if="!item.business_unit_title"> --- </span>
            `,
          },
          {
            columnName: 'responsible',
            title: this.gettext('responsible'),
            class: 'td-left-align small-font-cell',
            filters: [
              {
                type: 'ui-select',
                predicate: 'responsible_list',
                label: this.gettext('responsible'),
                resource: 'auth.user',
              },
            ],
            cellTemplate: /*html*/ `
              <span
                class="tooltip"
                data-tip="{[{ 'responsible' | translate }]} - {[{ item.responsible_first_name }]}"
                >
                <div
                  class="user-avatar"
                  ng-if="item.responsible_first_name && item.responsible_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.responsible_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.responsible_avatar.length == 0">
                  {[{ item.responsible_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            `,
          },
          {
            columnName: 'responsible_for_signing',
            title: this.gettext('responsible for signing'),
            class: 'td-left-align small-font-cell',
            filters: [
              {
                type: 'ui-select',
                predicate: 'responsible_for_signing_list',
                label: this.gettext('responsible for signing'),
                resource: 'auth.user',
              },
            ],
            cellTemplate: /*html*/ `
              <span
              class="tooltip"
              data-tip="{[{ 'responsible_for_signing' | translate }]} - {[{ item.responsible_for_signing_first_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.responsible_for_signing_first_name && item.responsible_for_signing_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.responsible_for_signing_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.responsible_for_signing_avatar.length == 0">
                  {[{ item.responsible_for_signing_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            `,
          },
          {
            columnName: 'payment_plans',
            title: this.gettext('Payment plans'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span
                ng-repeat="plan in item.paymentplans_data"
                class="btn-link"
              >
                <span ng-click="args.openPaymentPlanModal(plan)">
                  <i class="fa fa-calendar-check"></i> {[{ plan.name | cut:true:40:' ...' }]}
                </span>
                <span
                class="label smaller-label"
                ng-class="{ 'label_warning': plan.approval_status == 'wait', 'label_danger': plan.approval_status == 'rejected', 'label_success': plan.approval_status == 'approved', 'label_default': plan.approval_status == 'process' } "
              >
                <i class="fa fa-check-circle" ng-if="plan.approval_status == 'approved'"></i>
                {[{ plan.approval_status | translate }]}
              </span>
              <span
                class="label smaller-label"
                ng-class="{ 'label_warning': plan.payment_status == 'new', 'label_danger': plan.payment_status == 'rejected', 'label_success': plan.payment_status == 'approved', 'label_default': plan.payment_status == 'executed' } "
              >
                <i class="fa fa-check-circle" ng-if="plan.payment_status == 'approved'"></i>
                {[{ plan.payment_status | translate }]}
              </span>
              <span
                class="smaller-label"
              >
                {[{ plan.value | translate }]} {[{ item.currency_symbol }]}
              </span>
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'additional_info',
            title: this.gettext('additional info'),
            class: 'td-left-align',
            cellTemplate:
              '<span class="tooltip" data-tip="{[{ item.additional_info }]}">{[{ item.additional_info | cut:true:50:\' \' }]}</span>',
          },
          {
            columnName: 'delivery_hyperlink',
            title: this.gettext('Delivery status'),
            class: 'td-left-align',
            cellTemplate:
              '<a ng-if="item.delivery_hyperlink" class="btn btn-link" href="{[{ item.delivery_hyperlink }]}" target="_blank"><i class="fa fa-chain"></i> <translate>Delivery status</translate></a>',
          },
          {
            columnName: 'editor',
            class: 'td-left-align',
            title: this.gettext('editor'),
            filters: [
              {
                type: 'ui-select',
                predicate: 'editor_list',
                label: this.gettext('editor'),
                resource: 'auth.user',
              },
            ],
            cellTemplate: `
              <span
                class="tooltip"
                data-tip="{[{ 'last updated'| translate }]} - {[{item.update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.editor_first_name }]} {[{ item.editor_last_name }]}, {[{ 'created'| translate }]} - {[{item.create_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.author_first_name }]} {[{ item.author_last_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.editor_avatar.length == 0">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            `,
          },
          {
            columnName: 'counterparty_payment_iban',
            class: 'td-left-align',
            title: this.gettext('Counterparty payment IBAN'),
            cellTemplate: `
              <span
                ng-if="item.counterparty_payment_info_currency && item.counterpaty_payment_info_iban"
              >
                {[{ args.formatIban(item.counterpaty_payment_info_iban) }]} ({[{ item.counterparty_payment_info_currency }]})
              </span>
            `,
          },
          {
            columnName: 'bank_name_iban',
            class: 'td-left-align',
            title: this.gettext('Bank name IBAN'),
            cellTemplate: `
              <span
                ng-if="item.bankaccount_iban && item.bank_account_currency"
              >
                {[{ args.formatIban(item.bankaccount_iban) }]} ({[{ item.bank_account_currency }]})
              </span>
            `,
          },
          {
            columnName: 'payment_bank_name',
            class: 'td-left-align',
            title: this.gettext('Payment Bank name'),
            cellTemplate: `
              <span ng-if="item.payments_data">
                <span ng-repeat="payment in item.payments_data">
                  <div>{[{ payment.bank_name }]}</div>
                  <span>{[{ args.formatIban(payment.iban) }]} ({[{ payment.symbol }]})</span>
                  <div class="clearfix"></div>
                  <span ng-if="payment.branch_name">{[{ payment.branch_name }]}</span>
                  <div class="clearfix"></div>
                </span>
              </span>
            `,
          },
          {
            columnName: 'customs_declaration',
            class: 'td-left-align',
            title: this.gettext('Customs declaration'),
            cellTemplate: `
              <span ng-repeat="position in item.invoicepositions_set">
                <div ng-if="item.customs_declaration_data" ng-repeat="custom_declaration in position.customs_declaration_data">
                  <a ng-if="custom_declaration.id" ui-sref="customsCargoDeclarationDetails({id: custom_declaration.id })">
                    <i class="fa fa-file-invoice-dollar"></i>
                    <span class="value">{[{ custom_declaration.number }]}</span>
                  </a>
                </span>
              </span>
            `,
          },
          {
            columnName: 'bills_of_lading',
            class: 'td-left-align',
            title: this.gettext('bill of lading'),
            cellTemplate: `
              <span ng-if="item.bl_data">
                <div ng-repeat="bl in item.bl_data">
                  <a ng-if="bl.id" ui-sref="logistics.billoflading({ id: bl.id })">
                    <i class="fa fa fa-drivers-license"></i>
                    <span class="value">{[{ bl.number }]}</span>
                  </a>
                </span>
              </span>
            `,
          },
          {
            columnName: 'daily_fines_rate',
            class: 'td-left-align',
            title: this.gettext('Daily fines rate'),
            cellTemplate: `
              <span ng-if="item.daily_fines_rate">
                {[{ item.daily_fines_rate | gtDecimal :3 }]}%
              </span>
            `,
          },
          {
            columnName: 'fines_days_count',
            class: 'td-left-align',
            title: this.gettext('Fines days count'),
            cellTemplate: `
              <span ng-if="item.fines_days">
                {[{item.fines_days}]}
                <span class="label smaller-label label_success" ng-if="item.fines_days_count === 'calendar_days'">
                  {[{ 'Calendar days' | translate }]}
                </span>
                <span class="label smaller-label label_warning" ng-if="item.fines_days_count === 'business_days'">
                  {[{ 'Business days' | translate }]}
                </span>
              </span>
            `,
          },
          {
            columnName: 'fines_invoicing',
            class: 'td-left-align',
            title: this.gettext('Fines invoicing'),
            cellTemplate: `
              <span class="label label_default" ng-if="item.fines_invoicing === 'costs'">
                {[{ 'Costs' | translate }]}
              </span>
              <span class="label label_default" ng-if="item.fines_invoicing === 'position'">
                {[{ 'Position' | translate }]}
              </span>
            `,
          },
          {
            columnName: 'fines_amount',
            class: 'td-left-align',
            title: this.gettext('Fines amount'),
            cellTemplate: `
              <span ng-if="item.fines_amount">
                {[{ item.fines_amount | number:2 }]}
                <span class="label smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
            `,
          },
          {
            columnName: 'fines_amount_default',
            class: 'td-left-align',
            title: this.gettext(`Fines amount ${defaultCurrencySymbol}`),
            cellTemplate: `
              <span ng-if="item.fines_amount_default">
                  {[{ item.fines_amount_default | number:2 }]}
                <span class="label smaller-label"> {[{ args.defaultCurrencySymbol }]} </span>
              </span>
            `,
          },
          {
            columnName: 'amount_not_reserved',
            class: 'td-left-align',
            title: this.gettext('Amount not reserved'),
            cellTemplate: `
              <span ng-if="item.amount_not_reserved">
                  {[{ item.amount_not_reserved | number:2 }]}
              </span>
            `,
          },
        );

        return this.InvoiceFormFieldsService.getCustomFieldTableColumns().then((columns: any) => {
          options.columnDefs.push(...columns);
          options.tabs.forEach((tab) => {
            tab.columns.push(...columns.map((column: any) => column.columnName));
          });
          this.tableOptions = options;
        });
      }
    },
  ],
};
