import React from 'react';

import { PageTopBar } from '~/core/theme/components/page-top-bar';

export const PageLayout: React.FC<{
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
}> = ({ leftContent, rightContent, children }) => {
  return (
    <section className="relative">
      <PageTopBar leftContent={leftContent} rightContent={rightContent} />
      <section className="px-3">{children}</section>
    </section>
  );
};
