import React from 'react';

import { container } from '~/shared/lib/di';

import { GlobalSearchStore } from '../services/global-search.store';

export const useGlobalSearch = () => {
  const globalSearchStore = React.useMemo(() => container.resolve(GlobalSearchStore), []);

  return { globalSearchStore };
};
