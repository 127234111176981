import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import { getModalRoot } from '~/shared/ui/modal';

(function () {
  'use strict';
  ng.module('accounts.legacy').factory('AccountsService', Service);

  Service.$inject = [
    '$resource',
    '$q',
    '$rootScope',
    '$uibModal',
    '$injector',
    'gettext',
    'CoreService',
  ];

  function Service(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $rootScope: GtRootScopeService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $injector: ng.auto.IInjectorService,
    gettext: ng.gettext.gettextFunction,
    CoreService: CoreService,
  ) {
    let user: any = null;
    const UserResource: any = $resource(
      '/api/accounts/users/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        currentUser: {
          method: 'GET',
          url: '/api/accounts/users/current_user/',
          cache: true,
        },
        currentUserNoCache: {
          method: 'GET',
          url: '/api/accounts/users/current_user/',
          cache: false,
        },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/users/predictions/',
        },
      },
    );
    const UserSelfResource = $resource(
      '/api/accounts/users/self/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        changePassword: {
          method: 'POST',
          url: '/api/accounts/users/self/:id/change_password/',
        },
      },
    );

    const GroupResource = $resource(
      '/api/accounts/groups/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/groups/predictions/',
        },
      },
    );

    const PermissionResource = $resource(
      '/api/accounts/permissions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/permissions/predictions/',
        },
      },
    );

    const LogEntryResource = $resource(
      '/api/core/logentries/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
      },
    );

    const ApprovalResource: any = $resource(
      '/api/accounts/approvals/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/approvals/predictions/',
        },
        approve: {
          method: 'GET',
          url: '/api/accounts/approvals/:id/approve/',
        },
        decline: {
          method: 'GET',
          url: '/api/accounts/approvals/:id/decline/',
        },
        reactivate: {
          method: 'GET',
          url: '/api/accounts/approvals/:id/reactivate/',
        },
        approvalResentRequest: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/approvals/:id/request/',
        },
      },
    );

    const ApprovalConfigResource: any = $resource(
      '/api/accounts/approval-configs/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/approval-configs/predictions/',
        },
        reactivateByConfig: {
          method: 'PATCH',
          url: '/api/accounts/approval-configs/reactivate_by_config/',
        },
        getLatestActiveByObjectType: {
          method: 'GET',
          isArray: false,
          params: { object_type: '@object_type' },
          url: '/api/accounts/approval-configs/get_latest_active_by_object_type/?object_type=:object_type',
        },
      },
    );

    const RegionalManagerResource = $resource(
      '/api/accounts/regional-managers/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/regional-managers/predictions/',
        },
        tableTotal: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/regional-managers/table_total/',
        },
        details: {
          method: 'GET',
          url: '/api/accounts/regional-managers/:id/details/',
        },
      },
    );

    return {
      User: UserResource,
      UserSelf: UserSelfResource,
      Group: GroupResource,
      Permission: PermissionResource,
      LogEntry: LogEntryResource,
      RegionalManager: RegionalManagerResource,
      Approval: ApprovalResource,
      ApprovalConfig: ApprovalConfigResource,
      getClientName: getClientName,
      authorize: authorize,
      getUser: getUser,
      getSetting: getSetting,
      hasPerm: hasPerm,
      logentryModal: logentryModal,
      approvalsModal: approvalsModal,
      getLogEntries: getLogEntries,
      approversModal: approversModal,
      approveObject: approveObject,
      declineObject: declineObject,
      getObjectApproval: getObjectApproval,
      saveApproval: saveApproval,
      getApprovals: getApprovals,
      deleteApproval: deleteApproval,
      getApprovalConfigList: getApprovalConfigList,
      getDefaultApprovalConfigId: getDefaultApprovalConfigId,
      userModal: userModal,
      reCreateApprovals: reCreateApprovals,
      getActualApproval: getActualApproval,
      getActualMainApproval: getActualMainApproval,
      getApprovalsByConfig: getApprovalsByConfig,
      getUserApproval: getUserApproval,
      voteApprovable: voteApprovable,
      approvalResentRequest: approvalResentRequest,
    };

    ////////////////

    function getUser() {
      const defer = $q.defer();
      if ($rootScope.user) {
        defer.resolve($rootScope.user);
      } else {
        UserResource.currentUser({}, function (data: any) {
          $rootScope.user = data;
          if ($rootScope.user.profile.deals_show === 'sale') {
            $rootScope.user.profile.market_show = 'bids';
          } else {
            $rootScope.user.profile.market_show = 'offers';
          }
          defer.resolve($rootScope.user);
        }).$promise.catch(function (err: any) {
          if (err?.status === 403) {
            window.location.href = '/accounts/login';
          }
        });
      }
      return defer.promise;
    }

    function getSetting(settingName: any) {
      return $rootScope.user.settings[settingName];
    }

    function hasPerm(perms: any) {
      if (!$rootScope.user || !perms) {
        throw new Error(`Wrong permission usage. User: ${$rootScope.user}, Perms: ${perms}`);
      }
      perms = ng.isArray(perms) ? perms : [perms];
      if (!perms.every((i: any) => $rootScope.user.all_perms.includes(i))) {
        throw new Error(`Permission was not defined. Perms: ${perms}`);
      }
      return !!$rootScope.user.perms.filter((perm: any) => perms.includes(perm)).length;
    }

    function getClientName() {
      return queryUser().then(function (user: any) {
        return user.client_name;
      });
    }

    function queryUser() {
      const deferred = $q.defer();
      if (user) {
        deferred.resolve(user);
      } else {
        UserResource.currentUser(function (data: any) {
          user = data;
          deferred.resolve(user);
        });
      }
      return deferred.promise;
    }

    function authorize(perms: any) {
      return queryUser().then(function () {
        return hasPerm(perms);
      });
    }

    function logentryModal(logentry: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./logentry-modal/logentry-modal.tpl.html?raw'),
        controller: 'AccountsLogentryModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          logentry: () => {
            return logentry;
          },
        },
      }).result;
    }

    function approvalsModal(objectId: any, contentType: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./common/approvals-modal/approvals-modal.tpl.html?raw'),
        controller: 'ApprovalsModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          objectId: () => {
            return objectId;
          },
          contentType: () => {
            return contentType;
          },
        },
      }).result;
    }

    function approversModal(objectId: any, contentType: any, permission: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./common/approvers-modal/approvers-modal.tpl.html?raw'),
        controller: 'ApproversModalController as vm',
        windowClass: 'modal-template modal-template-half-width',
        appendTo: getModalRoot(),
        resolve: {
          objectId: () => {
            return objectId;
          },
          contentType: () => {
            return contentType;
          },
          permission: () => {
            return permission;
          },
        },
      }).result;
    }

    function getLogEntries(objectId: any, model: any, appLabel: any) {
      return CoreService.ContentType.query({
        model: model,
        app_label: appLabel,
      }).$promise.then(function (data: any) {
        const contentType = data.results.shift();
        return LogEntryResource.query({
          object_id: objectId,
          content_type: contentType?.id,
        }).$promise;
      });
    }

    function approveObject(model: any, objectId: any) {
      return CoreService.getModelContentType(model).then(function (ct: any) {
        return getObjectApproval(objectId, ct.id).then(function (approval: any) {
          return ApprovalResource.approve({ id: approval.id });
        });
      });
    }

    function declineObject(model: any, objectId: any) {
      return CoreService.getModelContentType(model).then(function (ct: any) {
        return getObjectApproval(objectId, ct.id).then(function (approval: any) {
          return ApprovalResource.decline({ id: approval.id });
        });
      });
    }

    function getObjectApproval(objectId: any, contentType: any) {
      const params = {
        object_id: objectId,
        content_type: contentType,
        user: $rootScope.user.id,
        is_archived: false,
      };
      return getApprovals(params).then((data: any) => data.results[0]);
    }

    function getApprovals(params: any) {
      return ApprovalResource.query(params).$promise;
    }

    function approvalResentRequest(approvalId: any) {
      return ApprovalResource.approvalResentRequest({ id: approvalId }).$promise;
    }

    function saveApproval(approval: any) {
      // rm
      if (!approval.content_type) {
        throw Error("You can't save Approval without content_type");
      }
      if (!approval.user) {
        throw Error("You can't save Approval without user");
      }
      if (!approval.object_id) {
        throw Error("You can't save Approval without object_id");
      }
      const saveFunc = approval.id ? ApprovalResource.update : ApprovalResource.save;
      return saveFunc(approval).$promise;
    }

    function deleteApproval(approval: any) {
      return ApprovalResource.delete(approval).$promise;
    }

    function getApprovalConfigList(params: any) {
      return ApprovalConfigResource.query(params).$promise;
    }

    function getDefaultApprovalConfigId(objectType: any) {
      // FIX: cache me
      const requestParams: any = {
        object_type: objectType,
        page_size: 1,
        is_active: 1,
        main_approvers_list: [],
      };

      if ($rootScope.user.settings.DEFAULT_APPROVAL_CONFIG_FOR_MAIN_APPROVERS) {
        requestParams.main_approvers_list = [$rootScope.user.id];
      }

      return getApprovalConfigList(requestParams).then(function (data: any) {
        return (data.results.shift() || {})?.id;
      });
    }

    function userModal(item: any) {
      return $uibModal.open({
        backdrop: 'static',
        template: require('./user-modal/user-modal.tpl.html?raw'),
        controller: 'UserModalController as vm',
        controllerAs: 'vm',
        appendTo: getModalRoot(),
        windowClass: 'modal-template',
        resolve: {
          item: () => ({ ...item }),
        },
      }).result;
    }

    function reCreateApprovals(objectId: any, contentType: any) {
      // rm
      return ApprovalResource.reCreateApprovals({
        object_id: objectId,
        content_type: contentType,
      }).$promise;
    }

    function getActualApproval(configLevel: any) {
      // rm
      const levelWithApprovals = configLevel.levels_data.filter(
        (level: any) => level.approvals_created,
      );
      const levelIndexes = levelWithApprovals.map(function (level: any) {
        return level.index;
      });
      const maxIndex = Math.max(...levelIndexes);
      const actualLevel = levelWithApprovals.filter(
        (level: any) => level.index === maxIndex,
      )[0] || {
        approvers_data: [],
      };

      const userApproval = actualLevel.approvers_data.filter(
        (approver: any) => approver.id == $rootScope.user.id,
      );

      return (userApproval.length && userApproval[0]) || {};
    }

    function getActualMainApproval(configLevel: any) {
      // rm
      const userApproval = (configLevel.main_approvers_data || []).filter(
        (approver: any) => approver.id == $rootScope.user.id,
      );
      return (userApproval.length && userApproval[0]) || {};
    }

    function getApprovalsByConfig(approvableId: any, approvalConfigId: any) {
      return ApprovalConfigResource.query({ id: approvalConfigId }).$promise.then((config: any) => {
        const appModel = config.object_type.toLowerCase().split('.');
        return CoreService.ContentType.query({
          app_label: appModel[0],
          model: appModel[1],
        }).$promise.then((data: any) => {
          const contentType = data.results.shift();
          return ApprovalResource.query({
            object_id: approvableId,
            content_type: contentType.id,
          }).$promise.then((approvals: any) => {
            const levelApprovals = {
              main: config.main_approvers
                .map((userId: any) => approvals.results.filter((a: any) => a.user == userId))
                .reduce((a: any, b: any) => a.concat(b), []),
            };
            let added = levelApprovals.main.filter((a: any) => a.is_archived).map((a: any) => a.id);
            config.config_levels.forEach((lvl: any) => {
              levelApprovals[lvl.index] = lvl.approvers
                .map((approver: any) => {
                  const foundApprovals = approvals.results.filter(
                    (a: any) => a.user == approver.id,
                  );
                  if (!foundApprovals.filter((a: any) => !a.is_archived).length) {
                    foundApprovals.push({
                      user_name: approver.username,
                      user_avatar: approver.avatar,
                    });
                  }
                  return foundApprovals;
                })
                .reduce((a: any, b: any) => a.concat(b), [])
                .filter((a: any) => !a.is_archived || !added.includes(a.id));
              added = added.concat(
                levelApprovals[lvl.index].filter((a: any) => a.is_archived).map((a: any) => a.id),
              );
            });
            return { config, levelApprovals };
          });
        });
      });
    }

    function getUserApproval(approvableId: any, approvableCtId: any, approvableModelName: any) {
      if (!approvableCtId && !approvableModelName) {
        throw Error('Approvables ContentType id or ModelName must be provided');
      }

      const whenContentTypeId = $q.when();
      if (approvableCtId) {
        whenContentTypeId.then(() => approvableCtId);
      } else {
        CoreService.getModelContentType(approvableModelName.split('.')[1]).then((ct: any) =>
          whenContentTypeId.then(() => ct.id),
        );
      }

      return whenContentTypeId.then((ctId: any) => {
        const params = {
          object_id: approvableId,
          content_type: ctId,
          user: $rootScope.user.id,
          is_archived: false,
        };
        return getApprovals(params).then((data: any) => data.results[0]);
      });
    }

    function voteApprovable(
      action: any,
      approvableId: any,
      approvableContentTypeId: any,
      approvableModelName: any,
    ) {
      if (!['approve', 'decline', 'reactivate'].includes(action)) {
        throw Error('Wrong action. Can be one of [approve, decline, reactivate]');
      }
      const GtUtils = $injector.get<GtUtilsService>('GtUtils');
      if (action === 'reactivate') {
        return ApprovalConfigResource.reactivateByConfig({
          approvable_id: approvableId,
          approvable_content_type_id: approvableContentTypeId,
          approvable_model_name: approvableModelName,
        }).$promise.then((approval: any) => {
          GtUtils.notify(gettext('Approvals reactivated'));
          return approval;
        }, GtUtils.errorClb);
      }
      return getUserApproval(approvableId, approvableContentTypeId, approvableModelName).then(
        (approval: any) => {
          if (!approval) {
            return GtUtils.notify(gettext("You can't vote for this object"), 'error');
          }
          if (
            (action === 'approve' && approval.approved) ||
            (action === 'decline' && approval.declined)
          ) {
            return GtUtils.notify(gettext('This object has voted by you already. Skipping.'));
          }
          let ask = '';
          if (action === 'reactivate') {
            ask = gettext('This actin will start voting again. Continue?');
          } else if (approval.approved || approval.declined) {
            ask = gettext('You have voted for this object. Revote this?');
          }

          if (ask && !confirm(GtUtils.translate(ask))) {
            return;
          }

          return ApprovalResource[action]({ id: approval.id }).$promise.then(
            (approval: any) => {
              GtUtils.notify(gettext('Approval updated'));
              return approval;
            },
            (err: any) => {
              GtUtils.errorClb(err);
            },
          );
        },
      );
    }
  }
})();
