import * as Sentry from '@sentry/react';
import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { GT_CLIENT_NAME, GT_STAGING, GT_VERSION, HOST_URL, SENTRY_DSN } from '~/shared/config';
import { RouterProvider, createRouter } from '~/shared/lib/router';

import { routeTree } from './route-tree.gen';

const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
  },
});

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
}

console.info('Version:', GT_VERSION);

Sentry.init({
  dsn: SENTRY_DSN,
  environment: GT_CLIENT_NAME,
  release: GT_VERSION,
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration({
      maskAllText: !GT_STAGING,
      maskAllInputs: !GT_STAGING,
      blockAllMedia: !GT_STAGING,
    }),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', new RegExp(`^${HOST_URL}/api`)],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
});

export const AppInner: React.FC = () => {
  const auth = useAuth();

  return auth.initialized ? <RouterProvider router={router} context={{ auth }} /> : <>Loading...</>;
};
