import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './lineup-contracts-table.html?raw';

export const LineupContractsTable = {
  bindings: {
    contractType: '<',
    initQueryParams: '<',
    mode: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      contractType: any;
      contracts: any;
      contractsCount: number;
      filterLevel = 'lineup-contracts-table';
      initQueryParams: QueryParams = {};
      mode: any;
      onUpdate: any;
      queryParams: QueryParams = {};
      resourceName: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;

        this.queryParams = {};
        this.contracts = [];
        this.contractsCount = 0;
      }

      $onInit() {
        this.resourceName = this.contractType === 'sale' ? 'SaleContract' : 'PurchaseContract';
        this.filterLevel = `lineup-contracts-table-${this.contractType}`;
        this.queryParams = Object.assign(
          this.queryParams,
          { contract_type: this.contractType, in_passport: '0' },
          this.initQueryParams,
        );
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = { contract_type: this.contractType, in_passport: '0', ...data };
          this.updateData();
        });
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.ContractsService[this.resourceName]
          .physicalPosition(this.queryParams)
          .$promise.then(
            (data: any) => {
              this.contracts = data.results;
              this.contractsCount = data.count;
            },
            (error: any) => this.GtUtils.errorClb(error),
          )
          .finally(() => this.GtUtils.overlay('hide'));
      }

      updateConnections() {
        const contractType = (this.contracts.length && this.contracts[0].contract_type) || 'sale';
        this.$rootScope.$broadcast(contractType + '-connections-updated', this.contracts);
      }

      openContractModal(contract: any) {
        return this.ContractsService.contractModal(
          { id: contract.id },
          this.mode === 'passport' ? { openedFromPassportModal: true } : {},
        ).then(() => {
          this.updateData();
          this.onUpdate();
        });
      }
    },
  ],
};
