import { differenceInCalendarDays, differenceInMonths, format } from 'date-fns';

export const dateFormat = (date: Date, formatType: string) => format(date, formatType);

export const dateFormatToRecent = (date: Date, formatType: string) => {
  const todayDate = new Date();
  const daysDifference = differenceInCalendarDays(todayDate, date);
  const monthsDifference = differenceInMonths(todayDate, date);

  if (monthsDifference < 1) {
    return `${daysDifference} days ago`;
  }

  return dateFormat(date, formatType);
};
