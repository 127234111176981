import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './bank-balance-report-tree.html?raw';

export const BankBalanceReportTree = {
  bindings: {
    filterLevel: '<',
    data: '<',
    config: '<',
    indicatorView: '<',
    chartsConfigs: '<',
    levelsCount: '<',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    '$rootScope',
    'BankTransactionService',
    class {
      $rootScope: GtRootScopeService;
      BankTransactionService: any;
      GtUtils: GtUtilsService;
      config: any;
      currencyIcons: any;
      data: any;
      defaultCurrency: any;
      defaultCurrencyIcon: any;
      gettext: ng.gettext.gettextFunction;
      indicatorView: any;
      levelsCount = 0;
      verticalGroups: any;
      constructor(
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
        BankTransactionService: any,
      ) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.BankTransactionService = BankTransactionService;

        this.currencyIcons = {
          EUR: 'fa-euro-sign',
          USD: 'fa-dollar-sign',
          UAH: 'fa-hryvnia-sign',
          RUB: 'fa-ruble-sign',
          GBP: 'fa-sterling-sign',
          KZT: 'fa-tenge-sign',
        };

        this.verticalGroups = [
          {
            name: 'start_balance',
            title: this.gettext('Start balance'),
            indicator: 'start_balance',
            indicatorLocal: 'start_balance_local',
            icon: 'fa-flag-o',
            class: 'alert-success',
            showDetails: false,
            ngClass: {},
          },
          {
            name: 'incoming',
            title: this.gettext('Incoming'),
            indicator: 'incoming',
            indicatorLocal: 'incoming_local',
            icon: 'fa-plus',
            class: 'alert-success',
            showDetails: true,
            ngClass: {
              'positive-number': true,
            },
          },
          {
            name: 'outgoing',
            title: this.gettext('Outgoing'),
            indicator: 'outgoing',
            indicatorLocal: 'outgoing_local',
            icon: 'fa-minus',
            class: 'alert-danger',
            showDetails: true,
            ngClass: {
              'negative-number': true,
            },
          },
          {
            name: 'total',
            title: this.gettext('Total'),
            indicator: 'amount',
            indicatorLocal: 'amount_local',
            class: 'alert-success',
            showDetails: false,
            ngClass: {},
          },
          {
            name: 'end_balance',
            title: this.gettext('End balance'),
            indicator: 'end_balance',
            indicatorLocal: 'end_balance_local',
            icon: 'fa-flag',
            class: 'alert-success',
            showDetails: false,
            ngClass: {},
          },
        ];

        this.defaultCurrency = this.$rootScope.user.settings.DEFAULT_CURRENCY;
        this.defaultCurrencyIcon = this.currencyIcons[this.defaultCurrency];
      }

      updateVisibility(row: any, expand: any) {
        row.expand = expand;
        if (!expand) {
          this.data
            .filter((v: any) => v.parentIndex === row.index)
            .forEach((x: any) => {
              this.updateVisibility(x, expand);
            });
        }
      }

      rowHasData(row: any, group: any) {
        return (
          row &&
          ((row.level === 'detailsGrouped' && this.rowHasData(row.parent, group)) ||
            this.config.columns
              .filter((v: any) => v.group === 'months')
              .some(
                (v: any) =>
                  !!row.indicators[v.predicate] &&
                  (!!row.indicators[v.predicate][group.indicator] ||
                    !!row.indicators[v.predicate][group.indicatorLocal]),
              ))
        );
      }

      showArrows(row: any, verticalGroup: any) {
        if (this.levelsCount != row.level) {
          return true;
        }
        const isVertical = this.indicatorView === 'vertical';
        const indicators = this.verticalGroups
          .filter((v: any) => !isVertical || (verticalGroup && v.name === verticalGroup.name))
          .filter((v: any) => v.showDetails)
          .reduce((res: any, v: any) => res.concat(v.indicatorLocal, v.indicator), []);
        const columns = this.config.columns
          .filter((c: any) => c.group !== 'groups' && c.group !== 'totals')
          .map((c: any) => (isVertical && c.predicate) || c.group)
          .reduce((res: any, c: any) => (res.indexOf(c) === -1 ? res.concat(c) : res), []);
        return this.data
          .filter((v: any) => v.parentIndex === row.index)
          .reduce(
            (flat: any, child: any) =>
              flat.concat(columns.reduce((r: any, c: any) => r.concat(child[c]), [])),
            [],
          )
          .some((child: any) => indicators.some((i: any) => child?.[i]));
      }

      openTransactionModal(id: any) {
        this.GtUtils.overlay('show');
        this.BankTransactionService.getBankTransaction(id)
          .then((transaction: any) => {
            this.BankTransactionService.bankTransactionModal(transaction);
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error.detail);
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }
    },
  ],
};
