import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

export const ConsolidatedCropPositionPage = {
  bindings: {
    queryParams: '<',
    filterLevel: '<',
  },
  template: `
    <consolidated-crop-position-list-table
      filter-level="$ctrl.filterLevel"
      init-query-params="$ctrl.queryParams"
    ></consolidated-crop-position-list-table>
  `,
  controller: [
    'gettext',
    'GtUtils',
    'PageService',
    'gtFilterService',
    'ReportsService',
    class {
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        PageService: PageService,
        gtFilterService: GtFilterService,
        ReportsService: any,
      ) {
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.ReportsService = ReportsService;

        this.filterLevel = 'crop-position-page-view';
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'crop-position-page-view';
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            nestedSelects: [
              this.gtFilterService.getCropsFilter('cargo_list', null, { included_in_position: 1 }),
              this.gtFilterService.getBoolFilter(
                'with_internal_chain',
                this.gettext('With internal chain'),
              ),
              this.gtFilterService.getBoolFilter(
                'include_intermediate_logistic',
                this.gettext('Include intermediate logistics'),
              ),
            ],
            nestedMultiSelects: [
              {
                argument: 'warehouse_type_list',
                placeholder: this.gettext('Warehouse type'),
                items: [
                  { title: this.gettext('Farm'), id: 'farm' },
                  { title: this.gettext('Elevator'), id: 'elevator' },
                  { title: this.gettext('Terminal'), id: 'terminal' },
                  { title: this.gettext('Warehouse'), id: 'warehouse' },
                  { title: this.gettext('Other'), id: 'other' },
                ],
              },
            ],
            multiSelects: [
              {
                argument: 'basis_list',
                placeholder: this.gettext('basises'),
                resource: 'logistics.basis',
              },
              {
                argument: 'paper_trade_list',
                placeholder: this.gettext('paper trade'),
                resource: 'contracts.papertrade',
              },
              {
                argument: 'warehouse_list',
                placeholder: this.gettext('Warehouse'),
                resource: 'logistics.warehouse',
                queryParams: () => ({ is_group: '0' }),
              },
              {
                argument: 'port_list',
                placeholder: this.gettext('ports'),
                resource: 'logistics.port',
              },
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
                getQueryParams: this.GtUtils.getBUQueryParams,
              },
            ],
          },
        };
      }
    },
  ],
};
