import React from 'react';

import { cn } from '~/shared/lib/utils';
import { PencilIcon } from '~/shared/ui/icons';

import { viewSettingsMap } from '../helpers';
import type { ReorderedColumn, View, ViewConfigChanged } from '../view-configurator/types';

export const AvailableViews: React.FC<{
  currentViewId: number;
  views: View[];
  columns: ReorderedColumn[];
  viewConfigChanged: ViewConfigChanged;
  currentViewChanged: (id: number) => void;
  viewDeleted: (id: number) => Promise<void>;
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({
  columns,
  viewDeleted,
  currentViewId,
  views,
  viewConfigChanged,
  currentViewChanged,
  columnsReordered,
  columnVisibilityChanged,
}) => {
  return (
    <section className="my-0.5 flex flex-col gap-0.5 p-1" data-testid="available-views">
      {!views.length ? (
        <p className="text-xs">No Data</p>
      ) : (
        views.map(({ Icon, ...view }) => {
          const ViewSettings = viewSettingsMap[view.type];

          return (
            <div
              key={view.id}
              className={cn(
                'group relative flex w-full justify-between rounded-sm transition duration-300 ease-in-out hover:bg-background-main-tertiary',
                currentViewId === view.id && 'bg-background-main-tertiary',
              )}
            >
              <button
                className="flex h-8 w-full cursor-pointer items-center gap-2 px-2 text-xs transition duration-300 ease-in-out"
                onClick={() => currentViewChanged(view.id)}
              >
                {Icon && (
                  <Icon
                    size={14}
                    className={cn(
                      'text-stroke-main-stronger transition duration-300 ease-in-out group-hover:text-stroke-main-inverted',
                      currentViewId === view.id && 'text-stroke-main-inverted',
                    )}
                  />
                )}
                <p>{view.name}</p>
              </button>
              <button
                className="h-8 opacity-0 transition duration-300 ease-in-out group-hover:opacity-100"
                onClick={(e) => e.stopPropagation()}
                data-testid="editing-view"
              >
                <ViewSettings
                  view={{ Icon, ...view }}
                  viewConfigChanged={viewConfigChanged}
                  viewDeleted={viewDeleted}
                  trigger={
                    <div className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-sm bg-background-main-secondary">
                      <PencilIcon size={14} className="text-stroke-main-stronger" />
                    </div>
                  }
                  columns={columns}
                  columnsReordered={columnsReordered}
                  columnVisibilityChanged={columnVisibilityChanged}
                />
              </button>
            </div>
          );
        })
      )}
    </section>
  );
};
