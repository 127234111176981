import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './lineup-requests-table.html?raw';

export const LineupRequestsTable = {
  bindings: {
    contractType: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      contractType: any;
      filterLevel = 'lineup-requests-table';
      initQueryParams: QueryParams = {};
      onUpdate: any;
      queryParams: QueryParams = {};
      requests: any;
      requestsCount: number;
      resourceName: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;

        this.queryParams = {};
        this.requests = [];
        this.requestsCount = 0;
        this.resourceName = '';
      }

      $onInit() {
        this.filterLevel = `lineup-requests-table-${this.contractType}`;
        this.queryParams = Object.assign(
          this.queryParams,
          { contract_type: this.contractType },
          this.initQueryParams,
        );
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = { contract_type: this.contractType, ...data };
          this.updateData();
        });
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        const promise = this.ContractsService.getRequestsInfo(this.queryParams);
        if (promise?.then) {
          promise
            .then(
              (data: any) => {
                this.requests = data.results;
                this.requestsCount = data.count;
              },
              (error: any) => this.GtUtils.errorClb(error),
            )
            .finally(() => this.GtUtils.overlay('hide'));
        }
      }

      updateConnections() {
        const contractType = (this.requests.length && this.requests[0].contract_type) || 'sale';
        this.$rootScope.$broadcast(contractType + '-connections-updated', this.requests);
      }

      openRequestModal(request: any) {
        return this.ContractsService.requestModal({ id: request.id }).then(() => {
          this.updateData();
          this.onUpdate();
        });
      }
    },
  ],
};
