import ng from 'angular';

import { warehouseDetails } from './details/module';
import { WarehousesFormFieldsService } from './details/warehouse-form-fields.service';
import { warehousesList } from './list/module';
import { WarehousesService } from './warehouse.service';

export const warehouseModule = ng
  .module('execution.warehouses', [warehouseDetails, warehousesList])
  .service('WarehousesService', WarehousesService)
  .service('WarehousesFormFieldsService', WarehousesFormFieldsService).name;
