import React from 'react';

import { MenuStore } from '~/core/theme/services';
import { container } from '~/shared/lib/di';

export const useMenu = () => {
  const menuStore = React.useMemo(() => container.resolve(MenuStore), []);

  return { menuStore };
};
