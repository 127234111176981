import React from 'react';

import { Spreadsheet } from '~/shared/ui/components/spreadsheet';
import {
  type CellData,
  LocaleType,
  type MatrixPrimitive,
} from '~/shared/ui/components/spreadsheet/types';

import { spreadsheetDataBuilder, updatedDataBuilder } from './builders';
import type { DataDef } from '../data-set-view';

export const SheetView = <TRecord extends { id: string }>({
  data,
  onEdit,
}: {
  data: DataDef<TRecord>;
  onEdit: (diff: Partial<TRecord>[]) => void;
}) => {
  const spreadsheetRef = React.useRef();

  const mappedData = React.useMemo(
    () =>
      spreadsheetDataBuilder<TRecord>({
        records: data.records,
        properties: data.properties,
      }),
    [data.records, data.properties],
  );

  const onEditHandler = (newData: MatrixPrimitive<CellData>) => {
    const updatedData = updatedDataBuilder<TRecord>(newData);
    onEdit(updatedData);
  };

  return (
    <Spreadsheet
      ref={spreadsheetRef}
      onEdit={onEditHandler}
      data={{
        id: 'workbook-01',
        name: 'sheet',
        sheetOrder: ['sheet-01'],
        appVersion: '3.0.0-alpha',
        locale: LocaleType.EN_US,
        styles: {},
        sheets: {
          'sheet-01': {
            id: 'sheet-01',
            cellData: mappedData,
            name: 'sheet1',
            rowCount: data.records.length,
            columnCount: data.properties.length,
            defaultColumnWidth: 200,
            defaultRowHeight: 27,
            showGridlines: 1,
            rowHeader: {
              width: 46,
            },
            columnHeader: {
              height: 20,
            },
          },
        },
      }}
    />
  );
};
