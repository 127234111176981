import type ng from 'angular';

export class PhysicalPositionService {
  $http: ng.IHttpService;
  $resource: ng.resource.IResourceService;
  RiskPositionOrigination: any;
  RiskPositionStock: any;

  static readonly $inject = ['$http', '$resource'];
  constructor($http: ng.IHttpService, $resource: ng.resource.IResourceService) {
    this.$http = $http;
    this.$resource = $resource;
    this.RiskPositionOrigination = this.$resource(
      '/api/reports/risk-position-originations/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/reports/risk-position-originations/predictions/',
        },
      },
    );
    this.RiskPositionStock = this.$resource(
      '/api/reports/risk-position-stocks/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/reports/risk-position-stocks/predictions/',
        },
      },
    );
  }

  getRiskPositionTerminalGrouping(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/risk-position-terminal-grouping/',
      params: params,
    }).then((response: any) => this.prepareRiskPositionTerminalGrouping(response.data));
  }

  getRiskPositionOriginationGrouping(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/risk-position-origination-grouping/',
      params: params,
    }).then((response: any) => response.data.results);
  }

  prepareRiskPositionTerminalGrouping(data: any) {
    return data.results;
  }
}
