import React from 'react';

import { ScrollArea } from '~/shared/ui/kit/scroll-area';

import { MenuList } from './menu-list/menu-list';

export const Menu: React.FC = () => {
  return (
    <div className="flex h-full flex-col overflow-hidden" data-testid="menu">
      <ScrollArea className="flex-grow overflow-y-auto">
        <MenuList />
      </ScrollArea>
    </div>
  );
};
