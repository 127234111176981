import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';
import type { ImportExportContractsService } from '~/app/deals/contracts/import-export/import-export.service';

import template from './reassignment-container.html?raw';

export const ReassignmentContainer = {
  bindings: {
    filterLevel: '<',
    queryParams: '<',
    showConnected: '<?',
    onConnect: '&?',
    connectedReassignments: '<?',
  },
  template,
  controller: [
    '$scope',
    'ReassignmentService',
    'gtFilterService',
    'DocumentsService',
    'ImportExportContractsService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      ImportExportContractsService: ImportExportContractsService;
      ReassignmentService: any;
      connectedReassignments: any;
      filterLevel = 'reassignment-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      reassignments: any;
      reassignmentsCount = 0;
      total: any;
      constructor(
        $scope: ng.IScope,
        ReassignmentService: any,
        gtFilterService: GtFilterService,
        DocumentsService: any,
        ImportExportContractsService: ImportExportContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.ReassignmentService = ReassignmentService;
        this.gtFilterService = gtFilterService;
        this.DocumentsService = DocumentsService;
        this.ImportExportContractsService = ImportExportContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.$scope = $scope;
      }

      $onInit() {
        this.reassignments = [];
        this.total = {};
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        return this.ReassignmentService.getReassignmentsTableData(this.queryParams).then(
          (data: any) => {
            this.reassignments = data.results;
            this.reassignmentsCount = data.count;

            if (this.connectedReassignments) {
              this.reassignments.forEach((reassignment: any) => {
                if (this.connectedReassignments.indexOf(reassignment.id) > -1) {
                  reassignment._toConnect = true;
                }
              });
            }
            return this.ReassignmentService.getReassignmentsTotals(this.queryParams).then(
              (total: any) => {
                this.total = total;
                this.GtUtils.overlay('hide');
              },
            );
          },
        );
      }

      editReassignment(reassignment: any) {
        this.ReassignmentService.reassignmentModal(reassignment).then(() =>
          this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel),
        );
      }

      openDocxModal(reassignment: any) {
        return this.DocumentsService.generateDocxModal('Reassignment', reassignment.id);
      }

      openImportExportContractsModal() {
        return this.ImportExportContractsService.importExportModal().then(() =>
          this.updateTableData(),
        );
      }

      addParentReassignment(reassignment: any) {
        this.ReassignmentService.openNewParentReassignmentModal(reassignment, () =>
          this.updateTableData(),
        );
      }

      applyFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      save(reassignment: any) {
        this.ReassignmentService.updateReassignment(reassignment);
      }
    },
  ],
};
