import React from 'react';

import logoIconSvg from '~/core/theme/components/sidebar/logo.svg';
import { Link } from '~/shared/lib/router';

import { SidebarToggle } from '../sidebar';

export const Logo: React.FC = () => {
  return (
    <div className="relative pl-4" data-testid="sidebar-logo">
      <Link className="flex h-[52px] items-center gap-2" to="/">
        <img src={logoIconSvg} className="transition duration-300 ease-in-out" alt="logo" />
        <span className="pt-0.5 font-semibold text-text-main-primary">GrainTrack</span>
      </Link>
      <SidebarToggle />
    </div>
  );
};
