import ng from 'angular';

(function () {
  'use strict';
  ng.module('reports.legacy').directive('activityReports', directive);

  function directive() {
    return {
      bindToController: true,
      controller: 'ActivityPageController',
      template: require('./activity.tpl.html?raw'),
      controllerAs: 'vm',
      scope: {},
    };
  }
})();
