import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './client-mailings-table.html?raw';

export const ClientMailingsTable = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$q',
    '$scope',
    'gettext',
    'GtUtils',
    'MailingService',
    'gtFilterService',
    class {
      $q: ng.IQService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      MailingService: any;
      filterLevel = 'client-mailings-table';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      constructor(
        $q: ng.IQService,
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        MailingService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$q = $q;
        this.$scope = $scope;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.MailingService = MailingService;
        this.gtFilterService = gtFilterService;

        this.filterLevel = '';
        this.initQueryParams = {};

        this.tableOptions = {};
        this.tableData = {};
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = { results_status: 'new', ...this.initQueryParams };
        this.tableOptions = this.getTableOptions();
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MailingService.getClientMailings(this.queryParams).then((data: any) => {
          this.tableData = { rows: data.results, count: data.count };
          this.GtUtils.overlay('hide');
          return data;
        });
      }

      applyFilter() {
        this.queryParams.page = undefined;
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      clearFilters() {
        this.queryParams = { results_status: 'new', ...this.initQueryParams };
        this.applyFilter();
      }

      getTableOptions() {
        return {
          tableName: 'client-mailings-table',
          tableClass: ' table-hover',
          configurable: false,
          applyFilters: (args: any) =>
            this.gtFilterService.updateQueryParams(args.params, this.filterLevel),
          selection: true,
          changePageSize: true,
          templateArgs: {
            editMailing: (item: any) => this.editMailing(item),
            deleteMailing: (item: any) => this.deleteMailing(item),
          },
          columnDefs: [
            {
              columnName: 'client_name',
              title: this.gettext('client'),
              class: 'td-left-align',
              cellTemplate: /*html*/ `
             <span class="label-strong" style="white-space: normal"> {[{ item.client_name }]} </span>
            `,
            },
            {
              columnName: 'emails',
              title: this.gettext('emails'),
              class: 'td-left-align',
              cellTemplate: `
              <span 
                ng-repeat="el in item.emails_to"
                class="smaller-label""
              >
              <i class="fa fa-envelope"></i> {[{ el.email }]}
                <div class="clearfix"></div>
              </span>
            `,
            },
            {
              columnName: 'status',
              title: this.gettext('status'),
              class: 'td-left-align',
              cellTemplate: `
              <span class="label tooltip" data-tip="{[{ item.results[0].response }]}" ng-class="{
                'label_default': !item.results[0].status,
                'label_warning': item.results[0].status == 'progress',
                'label_danger': item.results[0].status == 'failed',
                'label_success': item.results[0].status == 'sent'
              }">
                {[{ item.results[0].status || 'new' }]}
                <i class="fa fa-info-circle" ng-if="item.results[0].status == 'failed'"></i> 
                <span class="smaller-label label-smoke">
                  <i class="fa fa-calendar"></i> {[{ item.create_time | date:'dd/MM/yy' }]}
                </span>
              </span>
            `,
            },
            {
              columnName: 'template',
              title: this.gettext('template'),
              class: 'td-left-align',
              cellTemplate: `
              <i class="fa fa-envelope-o"></i>
              {[{ item.template_title || '---' }]}
              <div class="clearfix"></div>
              <i class="fa fa-file-excel-o"></i>
              {[{ item.export_params_collection_title || '---' }]}
            `,
            },
            {
              columnName: 'status',
              title: this.gettext('preview'),
              cellTemplate: `
              <a class="btn btn-xs btn-info" ng-click="args.editMailing(item)">
                <i class="fa fa-envelope-open"></i>
              </a>
              <a class="btn btn-xs" ng-click="args.deleteMailing(item)">
                <i class="fa fa-trash"></i>
              </a>
         
             
            `,
            },
          ],
        };
      }

      editMailing(mailing: any) {
        return this.MailingService.clientMailingModal(mailing).then(() => this.updateData());
      }

      sendSelected() {
        let chain = this.$q.when();
        const selected = this.tableData.rows.filter(
          (item: any) => item.$_selected && !item.results[0],
        );
        if (
          !selected.length ||
          !confirm(
            this.GtUtils.translate(this.gettext(`Do you want to send ${selected.length} mails?`)),
          )
        ) {
          this.GtUtils.notify(
            this.GtUtils.translate(this.gettext('There are not unsent mailing in your set')),
          );
          return false;
        }
        this.GtUtils.overlay('show');
        selected.forEach(
          (item: any) => (chain = chain.then(() => this.MailingService.sendClientMailing(item))),
        );
        chain.then(() => this.updateData());
      }

      dropSelected() {
        let chain = this.$q.when();
        const selected = this.tableData.rows.filter((item: any) => item.$_selected);
        if (
          !selected.length ||
          !confirm(
            this.GtUtils.translate(this.gettext(`Do you want to drop ${selected.length} mails?`)),
          )
        ) {
          this.GtUtils.notify(
            this.GtUtils.translate(this.gettext('There are not undrop mailing in your set')),
          );
          return false;
        }
        this.GtUtils.overlay('show');
        selected.forEach(
          (item: any) =>
            (chain = chain.then(
              () => this.MailingService.deleteClientMailing({ id: item.id }),
              () => {},
            )),
        );
        chain.then(() => this.updateData());
      }

      deleteMailing(mailing: any) {
        if (confirm(this.GtUtils.translate(this.gettext('Do you want to delete this mailing?')))) {
          this.GtUtils.overlay('show');
          return this.MailingService.deleteClientMailing({ id: mailing.id }).then(() =>
            this.updateData(),
          );
        }
      }
    },
  ],
};
