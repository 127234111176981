import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './settings-personal-container.html?raw';

export const SettingsPersonalContainer = {
  bindings: { user: '<' },
  template,
  controller: [
    '$rootScope',
    'GtUtils',
    'AccountsService',
    'CoreService',
    class {
      $rootScope: GtRootScopeService;
      AccountsService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      user: any;
      constructor(
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        AccountsService: any,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
      }

      $onInit() {
        this.user = this.user || {};
      }

      changePreferences(newPrefsObj: any) {
        this.GtUtils.overlay('show');
        this.AccountsService.UserSelf.update(
          {
            id: this.user.id,
            profile: newPrefsObj,
          },
          (data: any) => {
            this.user.profile = data.profile;
            if (this.user.profile.deals_show === 'sale') {
              this.user.profile.market_show = 'bids';
            } else {
              this.user.profile.market_show = 'offers';
            }
            this.CoreService.setTheme(this.user.profile.color);
            this.CoreService.setFont(this.user.profile.font);
            this.CoreService.setLocale(this.user.profile.language, this.user.profile.day_of_week);
            this.GtUtils.overlay('hide');
          },
          (data: any) => this._error(data),
        );
        if (newPrefsObj.default_general_agreement_tab_view === 'purchase') {
          this.$rootScope.user.profile.default_general_agreement_tab_view = 'purchase';
        } else {
          this.$rootScope.user.profile.default_general_agreement_tab_view = 'sale';
        }
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.user.errors = data.data;
      }
    },
  ],
};
