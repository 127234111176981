import {
  accountsLoginCreate,
  accountsLogoutCreate,
  accountsUsersCurrentUserRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { type AuthCredentials, type CurrentUser } from '../lib';

@singleton()
export class AuthRepository {
  async login(credentials: AuthCredentials): Promise<boolean> {
    await accountsLoginCreate({ requestBody: credentials });
    return true;
  }

  async logout(): Promise<void> {
    await accountsLogoutCreate();
  }

  async getCurrentUser(): Promise<CurrentUser> {
    const user = await accountsUsersCurrentUserRetrieve();
    return {
      id: String(user.id),
      name: user.first_name ?? user.username,
      email: user.email ?? '',
      avatar: user.profile?.avatar ?? '',
      perms: (Array.isArray(user.perms) && user.perms) || [user.perms],
      settings: { biApiUrl: user.settings.BI_API_URL, biEnabled: user.settings.BI_ENABLED },
    };
  }
}
