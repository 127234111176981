import template from './vehicle-details-item-view.html?raw';

export const VehicleDetailsItemView = {
  bindings: {
    vehicle: '<',
    fields: '<',
    tab: '<',
    changeTab: '&',
    editVehicle: '&',
    filterLevel: '<',
    updating: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      fields: any;
      constructor() {
        this.fields = [];
      }
    },
  ],
};
