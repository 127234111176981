export const GtTableCell = () => {
  return {
    restrict: 'A',
    scope: {
      gtTableCell: '<',
    },
    link($scope: ng.IScope, $element: ng.IAugmentedJQuery) {
      $element.append(($scope as any).gtTableCell);
    },
  };
};
