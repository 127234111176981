import ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').controller('GeneralAgreementModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    '$rootScope',
    'DocumentsService',
    '$scope',
    'gettext',
    'ContractsService',
    'ClientsService',
    'CoreService',
    'agreement',
    'GtUtils',
    'FormFieldParamsService',
    'GeneralAgreementFormFieldsService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $rootScope: GtRootScopeService,
    DocumentsService: any,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    ContractsService: ContractsService,
    ClientsService: any,
    CoreService: CoreService,
    agreement: any,
    GtUtils: GtUtilsService,
    FormFieldParamsService: FormFieldParamsService,
    GeneralAgreementFormFieldsService: any,
    CustomValuesService: any,
  ) {
    const vm = this;
    vm.save = save;
    vm.destroy = destroy;
    vm.close = close;
    vm.onTypeChange = onTypeChange;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.fields = undefined;
    vm.onInit = true;

    let savedFiles: any = [];
    let tempId = 1;

    activate();

    //////////////

    function activate() {
      vm.agreement = {
        agreement_type: 'purchase',
        volume: 0,
        amount: 0,
        documents: [],
        ...agreement,
      };
      vm.agreement.agreement_type = vm.agreement.agreement_type || 'purchase';
      onTypeChange();
      vm.onInit = false;

      if (vm.agreement.id) {
        const { content_type: contentType, id: objectId } = vm.agreement;
        DocumentsService.queryDocuments({ content_type: contentType, object_id: objectId }).then(
          ({ results }: any) => (vm.agreement.documents = results),
        );
      }

      updateFields();

      if (!vm.agreement.id) {
        $scope.$watch(() => vm.agreement.auto_number, getAgreementNumber);
        $scope.$watch(() => vm.agreement.agreement_type, getAgreementNumber);
        $scope.$watch(() => vm.agreement.date_of_execution, getAgreementNumber);
        $scope.$watch(() => vm.agreement.commodity, getAgreementNumber);
      }

      $rootScope.$on('documents-list__remove-from-argeement-modal', removeDocument);
      $rootScope.$on('agreement-type-updated', onTypeChange);
      $rootScope.$on('vat-option-updated', vatOptionUpdate);
      $scope.$on('custom-values-updated__generalagreement', function (event: any, data: any) {
        vm.customValues = data;
      });
      $scope.$watch('vm.agreement.business_unit', (newVal: any, oldVal: any) => {
        if (newVal !== oldVal) {
          clearApprovalConfig();
        }
      });
    }

    function getAgreementNumber() {
      if (vm.agreement.auto_number && !vm.agreement.number) {
        return ContractsService.getAgreementNumber(vm.agreement)
          .then((data: any) => {
            vm.agreement.number = data.agreement_number;
          })
          .catch(_error);
      }
    }

    function vatOptionUpdate() {
      if (vm.agreement.vat_option) {
        vm.agreement.vat_value = $rootScope.user.settings.VAT_VALUE;
      } else {
        vm.agreement.vat_value = 0;
      }
      updateFields();
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFields(vm.agreement))
        .then((fields: any) => (vm.fields = fields))
        .catch(GtUtils.errorClb);
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(() => updateFields());
    }

    function getFormConfig() {
      return getFields(vm.agreement);
    }

    function onTypeChange() {
      const defaultOwner = $rootScope.user.settings.DEFAULT_VALUES.owner;
      const isSale = vm.agreement.agreement_type === 'sale';

      ClientsService.getClient(vm.agreement.client).then((data: any) => {
        const owner = data.role_names_set.find((item: any) => item.role_name === 'owner');

        if (isSale) {
          vm.agreement.supplier = vm.agreement.supplier || owner.id || defaultOwner;
          if (!vm.onInit) {
            vm.agreement.buyer = null;
          }
        } else {
          vm.agreement.buyer = vm.agreement.buyer || owner.id || defaultOwner;
          if (!vm.onInit) {
            vm.agreement.supplier = null;
          }
        }
      });

      updateFields();
    }

    function getFields(agreement: any) {
      return GeneralAgreementFormFieldsService.getFields(agreement, onFileSelect);
    }

    function destroy() {
      if (!confirm(gettext('Are you sure that you want delete agreement?'))) {
        return;
      }
      return ContractsService.GeneralAgreements.delete({
        id: agreement.id,
      }).$promise.then(() => {
        GtUtils.notify(gettext('Agreement removed'));
        vm.close();
      });
    }

    function save() {
      const agreementId = vm.agreement.id;
      if (vm.agreement.agreement_type == 'sale') {
        vm.agreement.owner = vm.agreement.supplier;
      }

      if (vm.agreement.agreement_type == 'purchase') {
        vm.agreement.owner = vm.agreement.buyer;
      }
      vm.agreement.vat_value = vm.agreement?.vat_value || 0;

      vm.newDocuments = [];
      vm.form.$invalid = true;
      const saveFunction = vm.agreement.id
        ? ContractsService.GeneralAgreements.update
        : ContractsService.GeneralAgreements.save;
      return saveFunction(vm.agreement).$promise.then((data: any) => {
        if (agreementId === undefined) {
          CustomValuesService.createCustomValues(data.id, vm.customValues);
        }
        if (vm.agreement.documents.length) {
          DocumentsService.saveDocuments(
            savedFiles.map(({ file }) => file),
            data.id,
            data.content_type,
          );
          savedFiles = [];
        }

        GtUtils.notify(gettext('Agreement saved'));
        vm.close(data, true);
      }, _error);
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function onFileSelect([file]) {
      const { name, type } = file;
      savedFiles.push({ tempId, file });
      vm.agreement.documents.push({ tempId, name, type });
      tempId++;
    }

    function removeDocument(event: any, fileToRemove: any) {
      if (fileToRemove.tempId) {
        savedFiles = savedFiles.filter(({ tempId }) => tempId !== fileToRemove.tempId);
        vm.agreement.documents = vm.agreement.documents.filter(
          ({ tempId }: any) => tempId !== fileToRemove.tempId,
        );
      } else {
        vm.agreement.documents = vm.agreement.documents.filter(
          ({ id }: any) => id !== fileToRemove.id,
        );
        CoreService.confirmDeletionModal(DocumentsService.Document, fileToRemove.id)
          .then((data: any) => {
            if (data == 'delete') {
              GtUtils.notify(gettext('Doc removed'));
            }
          })
          .catch((error: any) => {
            GtUtils.errorClb(error);
            vm.agreement.documents.push(fileToRemove);
          });
      }
    }

    function clearApprovalConfig() {
      vm.agreement.approval_config = null;
    }
  }
})();
