import { GridIcon, KanbanIcon, ListIcon } from '~/shared/ui/icons';

import type { CardView, ListView, TableView } from './types';

export const basicViews: (TableView | CardView | ListView)[] = [
  {
    id: 1,
    name: 'Table View',
    type: 'table',
    Icon: GridIcon,
    DefaultIcon: GridIcon,
    data: {
      isLimitAccess: false,
      isShowTitleIcons: false,
      type: {
        selected: 'compact',
        options: ['compact', 'detailed'],
      },
      properties: {
        customFields: [],
      },
    },
  },
  {
    id: 2,
    name: 'List View',
    type: 'list',
    Icon: ListIcon,
    DefaultIcon: ListIcon,
    data: {
      isLimitAccess: false,
      properties: {
        customFields: [],
      },
    },
  },
  {
    id: 3,
    name: 'Cards View',
    type: 'card',
    Icon: KanbanIcon,
    DefaultIcon: KanbanIcon,
    data: {
      isLimitAccess: true,
      groupBy: {
        selected: 'Status',
        options: ['status', 'name', 'age'],
      },
      type: {
        selected: 'compact',
        options: ['compact', 'detailed'],
      },
      isShowTitleIcons: true,
      properties: {
        customFields: [],
      },
    },
  },
  {
    id: 4,
    name: 'Sheet View',
    type: 'sheet',
    Icon: ListIcon,
    DefaultIcon: ListIcon,
    data: {
      isLimitAccess: false,
      properties: {
        customFields: [],
      },
    },
  },
];
