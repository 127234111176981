import template from './customs-cargo-declaration-details-item-view.html?raw';

export const CustomsCargoDeclarationDetailsItemView = {
  bindings: {
    declaration: '<',
    fields: '<',
    tab: '<',
    changeTab: '&',
    editDeclaration: '&',
    filterLevel: '<',
    updating: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      fields: any;
      formName: any;
      constructor() {
        this.fields = [];
        this.formName = 'customs-cargo-declaration';
      }
    },
  ],
};
