import React from 'react';

import { SearchIcon } from '~/shared/ui/icons';

import { useGlobalSearch } from '../../hooks/use-global-search';

export const GlobalSearchButton: React.FC = () => {
  const { globalSearchStore } = useGlobalSearch();

  return (
    <button
      className="flex w-full items-center gap-2 px-4 py-2 transition duration-300 ease-in-out hover:opacity-70"
      onClick={() => globalSearchStore.popupToggled()}
    >
      <SearchIcon size={16} className="text-text-main-secondary" />
      <p className="text-xs text-text-main-tertiary">Search</p>
    </button>
  );
};
