import React from 'react';

import type { DropAnimation } from '~/shared/lib/dnd';
import { DragOverlay, defaultDropAnimationSideEffects } from '~/shared/lib/dnd';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

export const SortableOverlay = ({ children }: { children: React.ReactNode }) => {
  return (
    <DragOverlay
      dropAnimation={dropAnimationConfig}
      style={{
        position: 'absolute',
        left: '42.3%',
      }}
    >
      {children}
    </DragOverlay>
  );
};
