import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './analytical-plan-fact-pnl-report-container.html?raw';
export const AnalyticalPlanFactPnlReportContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'AnalyticalPlanFactPnlReportService',
    'ReportsService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      AnalyticalPlanFactPnlReportService: any;
      GtUtils: GtUtilsService;
      ReportsService: any;
      config: any;
      data: any;
      filterLevel = 'analytical-plan-fact-pnl-report-container';
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      groupTitle: any;
      gtFilterService: GtFilterService;
      hideFilters: any;
      indicators: any;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      selectedGroups: any;
      unselectedGroups: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        AnalyticalPlanFactPnlReportService: any,
        ReportsService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.AnalyticalPlanFactPnlReportService = AnalyticalPlanFactPnlReportService;
        this.ReportsService = ReportsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.data = [];
        this.indicators = [];
        this.queryParams = {};
        this.hideFilters = true;
        this.selectedGroups = [];
        this.unselectedGroups = [];
        this.groupTitle = '';
        this.initQueryParams = {};
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'analytical-plan-fact-pnl-report-page';
        this.groupChoices = [
          { param: 'currency', title: this.gettext('currency'), icon: 'fa-money' },
          { param: 'account', title: this.gettext('bank account'), icon: 'fa-bank' },
          { param: 'owner', title: this.gettext('owner'), icon: 'fa-building' },
        ];
        this.queryParams = {
          ...this.initQueryParams,
          ...this.gtFilterService.getQueryParams(this.filterLevel),
        };
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          Object.assign(this.queryParams, data);
          [this.selectedGroups, this.unselectedGroups] = this.ReportsService.parseGroupsFromString(
            this.groupChoices,
            this.queryParams.group_by,
          );
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }
      parseGroups(groupChoices: any, groupByParams: any) {
        if (typeof groupByParams !== 'string') {
          groupByParams = '';
        }
        const groupParams = groupByParams.split(',');
        return [
          groupChoices.filter((choice: any) => groupParams.includes(choice.param)),
          groupChoices.filter((choice: any) => !groupParams.includes(choice.param)),
        ];
      }
      updateData() {
        this.GtUtils.overlay('show');
        this.AnalyticalPlanFactPnlReportService.getReportData(this.queryParams)
          .then((data: any) => {
            this.data = data.results;
            this.config = this.AnalyticalPlanFactPnlReportService.getTheadConfig(this.groupTitle);
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
          });
      }
      applyFilter() {
        this.queryParams.group_by = this.selectedGroups.map((v: any) => v.param).join(',');
        this.queryParams.start_date =
          this.queryParams.start_date ?? this.initQueryParams.start_date;
        this.queryParams.end_date = this.queryParams.end_date ?? this.initQueryParams.end_date;
        this.groupTitle = this.selectedGroups
          .map((v: any) => this.gettext(v.param))
          .join(' | ')
          .toUpperCase();
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
        this.updateData();
      }
      selectGroup(group: any) {
        this.selectedGroups.push(group);
        this.unselectedGroups.splice(this.unselectedGroups.indexOf(group), 1);
        this.applyFilter();
      }
      unselectGroup(group: any) {
        this.unselectedGroups.push(group);
        this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
        this.applyFilter();
      }
    },
  ],
};
