import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './market-purchase-plans.html?raw';
import type { ContractsService } from '../../contracts/legacy/contracts.srv';

export const MarketPurchasePlans = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$timeout',
    'moment',
    'GtUtils',
    'ContractsService',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $timeout: ng.ITimeoutService;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      datepicker: any;
      datepickerConfig: any;
      filterLevel = 'purchase-plans-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      marketUserVolumeFilterLevel: string;
      moment: any;
      purchasePlansCount: number;
      queryParams: QueryParams = {};
      tableApi: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        $timeout: ng.ITimeoutService,
        moment: any,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.moment = moment;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.datepicker = '';
        this.datepickerConfig = this.getDatePickerConfig();

        this.marketUserVolumeFilterLevel = this.filterLevel || 'market-user-volumes';
        this.purchasePlansCount = 0;
        const volumeCalcBy =
          ($rootScope.user.settings.MARKET_PURCHASE_PLANS == 'indicators' && 'indicators_volume') ||
          'contracts_volume';
        this.queryParams = { extrafields: [volumeCalcBy, 'crop_title'] };
        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
      }

      $onInit() {
        this.gtFilterService.getQueryParams(this.filterLevel);
        this.gtFilterService.subscribe(this.filterLevel, (params: any) => {
          this.queryParams = params;
          this.updatePurchasePlans();
        });
        this.updatePurchasePlans();
      }

      updatePurchasePlans() {
        return this.ContractsService.PurchasePlan.query(this.queryParams, (data: any) => {
          this.tableApi.setRowData(data.results);
          this.tableApi.setRowCount(data.count);
        }).$promise;
      }

      openPurchasePlanModal = (purchasePlan: any) => {
        return this.ContractsService.purchasePlanModal(purchasePlan).then(() => {
          this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
          this.gtFilterService.updateQueryParams({}, this.marketUserVolumeFilterLevel);
        });
      };

      getDatePickerConfig() {
        const config = this.gtFilterService.getDateRangePickerConfig();
        config.eventHandlers = {
          'apply.daterangepicker': () => {
            this.$timeout(() => this.setDates());
          },
          'cancel.daterangepicker': () => {
            this.$timeout(() => {
              this.datepicker = false;
              this.setDates();
            });
          },
        };
        return config;
      }

      setDates() {
        if (!this.datepicker) {
          this.queryParams.start_date = undefined;
          this.queryParams.end_date = undefined;
        } else {
          this.queryParams.start_date = this.moment(this.datepicker.startDate).format('DD.MM.YYYY');
          this.queryParams.end_date = this.moment(this.datepicker.endDate).format('DD.MM.YYYY');
        }
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      getTableOptions() {
        return {
          tableName: 'market-purchase-plans',
          tableClass: 'request-table',
          filterLevel: this.filterLevel,
          templateArgs: {
            openPurchasePlanModal: this.openPurchasePlanModal,
            ContractsService: this.ContractsService,
            gtFilterService: this.gtFilterService,
            filterLevel: this.filterLevel,
            queryParams: this.queryParams,
            marketUserVolumeFilterLevel: this.marketUserVolumeFilterLevel,
            MARKET_PURCHASE_PLANS: this.$rootScope.user.settings.MARKET_PURCHASE_PLANS,
          },
          configurable: true,
          columnDefs: [
            {
              columnName: 'crop_title',
              cellTemplate: `
              {[{ item.crop_title }]}
              <a ng-click="args.openPurchasePlanModal({id:item.id})" class="btn btn-xs btn-blue-border">
               <i class="fa fa-pencil-square"></i>
              </a>
          `,
              title: this.gettext('Cargo'),
            },
            {
              columnName: 'volume',
              cellTemplate: `
              <div class="progress" ng-if="args.MARKET_PURCHASE_PLANS=='indicators'">
                <div class="progress-bar progress_bar_success" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: {[{item.indicators_volume / (item.volume || 1) * 100 | number:2 }]}%">
                  {[{item.indicators_volume || 0 | number:3 }]} / {[{item.volume || 1 | number:3 }]}
                </div>
              </div>
              <div class="progress" ng-if="args.MARKET_PURCHASE_PLANS=='contracts'">
                <div class="progress-bar progress_bar_success" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: {[{item.contracts_volume / (item.volume || 1) * 100 | number:2 }]}%">
                  {[{item.contracts_volume || 0 | number:3 }]} / {[{item.volume || 1 | number:3 }]}
                </div>
              </div>
          `,
              title: this.gettext('Volume/Goal'),
            },
            {
              columnName: 'date_of_execution',
              cellTemplate: `
              <div>
                <i class="fa fa-calendar"></i>
                {[{item.date_of_execution | date:"dd MMM" }]} - {[{item.end_of_execution | date:"dd MMM" }]}
              </div>
          `,
              title: this.gettext('Period'),
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };
      }
    },
  ],
};
