import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').controller('SendEmailModalController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    '$window',
    '$timeout',
    'gettext',
    'GtUtils',
    'modelType',
    'objectId',
    'EmailTemplates',
    'getRecipients',
    'hiddenCopy',
    'gtFilterService',
    'filterLevel',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $window: ng.IWindowService,
    $timeout: ng.ITimeoutService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    modelType: any,
    objectId: any,
    EmailTemplates: any,
    getRecipients: any,
    hiddenCopy: any,
    gtFilterService: GtFilterService,
    filterLevel: string,
  ) {
    const vm = this;
    vm.close = close;
    vm.tab = 'templates';
    vm.send = send;
    vm.templates = [];
    vm.recipients = [];
    vm.recipientsCount = 0;
    vm.selectedTemplate = undefined;
    vm.selectTemplate = selectTemplate;
    vm.updateEmails = updateEmails;
    vm.updateTemplates = updateTemplates;
    vm.openPreviewModal = openPreviewModal;
    vm.openEmailTemplateModal = openEmailTemplateModal;
    vm.createMailtoLink = createMailtoLink;
    vm.emailData = {};
    vm.rendered = false;
    vm.model_type = modelType;
    vm.filterLevel = filterLevel + '__send-email-modal' || 'send-email-modal';

    vm.$onInit = function () {
      vm.queryParams = { page_size: 100, ...gtFilterService.getQueryParams(vm.filterLevel) };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateEmails();
        if (vm.tab == 'templates') {
          updateTemplates();
        }
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    function updateTemplates() {
      return EmailTemplates.EmailTemplate.query(
        {
          model_type: modelType,
          page_size: 50,
        },
        function (data: any) {
          vm.templates = data.results;
          vm.selectedTemplate = undefined;
        },
      ).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return false;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function send() {
      const mailtoLink = createMailtoLink(vm.emailData);
      if (
        $rootScope.user.profile.browser_mailclient_max_url_length &&
        mailtoLink.length > $rootScope.user.profile.browser_mailclient_max_url_length
      ) {
        GtUtils.notify(
          'The length of the message is longer than browser mail client can accept',
          'error',
        );
        openPreviewModal().then((data: any) => {
          if (data !== 'cancel') {
            $window.open(createMailtoLink(data));
          }
        });
      } else {
        $window.open(mailtoLink);
      }
      return close(true, true);
    }

    function createMailtoLink(emailData: any) {
      let mailTo = 'mailto:';
      const emails: any = [];
      const params = {
        cc: emailData.cc,
        subject: emailData.subject,
        body: emailData.body,
      };
      const paramsList: any = [];

      vm.recipients.forEach(function (item: any) {
        return item._selected && emails.push(item.email);
      });

      if (hiddenCopy) {
        mailTo += vm.emailData.cc;
        // @ts-ignore
        params.bcc = emails;
      } else {
        mailTo += emails;
      }

      mailTo += '?';
      Object.keys(params).forEach(function (key) {
        paramsList.push(key + '=' + encodeURIComponent(params[key]));
      });

      return mailTo + paramsList.join('&');
    }

    function updateEmails() {
      vm.rendered = false;
      if (!vm.selectedTemplate) {
        GtUtils.notify(gettext('template'));
        return false;
      }
      return EmailTemplates.EmailTemplate.emailData(
        {
          id: vm.selectedTemplate.id,
          object_id: objectId,
        },
        function (data: any) {
          vm.emailData = data;
          if (getRecipients) {
            getRecipients(vm.filterLevel).then(function (result: any) {
              vm.recipients = result.results;
              vm.recipientsCount = result.count;
              vm.nonSystemUsersRecipients = vm.recipients.filter(function (item: any) {
                return !item.system_user;
              });
              vm.systemUsersRecipients = vm.recipients.filter(function (item: any) {
                return item.system_user;
              });
              vm.nonSystemUsersRecipients.forEach(function (item: any) {
                item._selected = true;
              });
              vm.systemUsersRecipients.forEach(function (item: any) {
                item._selected = true;
              });
              vm.rendered = true;
            });
          } else {
            vm.recipients = data.recipients;
            vm.nonSystemUsersRecipients = vm.recipients.filter(function (item: any) {
              return !item.system_user;
            });
            vm.systemUsersRecipients = vm.recipients.filter(function (item: any) {
              return item.system_user;
            });
            vm.nonSystemUsersRecipients.forEach(function (item: any) {
              item._selected = true;
            });
            vm.systemUsersRecipients.forEach(function (item: any) {
              item._selected = false;
            });
            vm.rendered = true;
          }
        },
        function (error: any) {
          vm.selectedTemplate = undefined;
          GtUtils.errorClb(error);
        },
      ).$promise;
    }

    function selectTemplate(template: any) {
      vm.templates.forEach(function (item: any) {
        if (item.id == template.id) {
          return false;
        }
        item._selected = false;
      });
      $timeout(() => (vm.selectedTemplate = (template._selected && template) || undefined));
      if (vm.selectedTemplate) {
        updateEmails();
      }
    }

    function openPreviewModal() {
      let extra = { mailtoLink: createMailtoLink(vm.emailData) };
      if (vm.model_type == 'Supplier') {
        // @ts-ignore
        extra = { ...extra, show_prices_table: true };
      }
      return EmailTemplates.previewEmailModal(vm.emailData, vm.recipients, extra);
    }

    function openEmailTemplateModal(emailTemplate = {}) {
      return EmailTemplates.emailTemplateModal(emailTemplate).then(() => updateTemplates());
    }
  }
})();
