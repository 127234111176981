import React from 'react';

import { Link, Outlet } from '~/shared/lib/router';

export const SettingsListLayout: React.FC = () => {
  return (
    <div>
      <h1>List</h1>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
      <Outlet />
    </div>
  );
};
