import ng from 'angular';

import { ApprovalsByConfigContainer } from './components/approvals-container/approvals-by-config-container.component';
import { userPermission } from './components/user-permission/user-permission.module';
import { userSettings } from './components/user-settings/user-settings.module';
import { accountsLegacyModule } from './legacy';

export const accountsModule = ng
  .module('accounts', [accountsLegacyModule, userPermission, userSettings])
  .component('approvalsByConfigContainer', ApprovalsByConfigContainer).name;
