import React from 'react';

import { ChevronLeftIcon } from '~/shared/ui/icons';
import { DropdownMenuSeparator } from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';
import { Switch } from '~/shared/ui/kit/switch';

export const LimitAccessContent: React.FC<{
  isLimitAccess: boolean;
  onOpenChange: (open: boolean) => void;
  limitAccessChanged: () => void;
}> = ({ isLimitAccess, onOpenChange, limitAccessChanged }) => {
  return (
    <>
      <div className="mb-3 flex items-center gap-1 px-2 pt-2">
        <ChevronLeftIcon
          size={14}
          className="cursor-pointer text-text-main-tertiary transition duration-300 ease-in-out hover:opacity-70"
          onClick={() => onOpenChange(false)}
        />
        <p className="text-xs font-medium text-text-main-primary">Limit User Access</p>
      </div>
      <DropdownMenuSeparator className="my-0 h-[1px] bg-stroke-main-light" />
      <p className="bg-transparent-lighter px-2 py-1 text-[10px] font-semibold text-text-main-secondary">
        By default, everyone sees this view, if you want to limit access, turn on the switch
      </p>
      <div className="flex items-center justify-between p-2">
        <p className="text-xs font-medium text-text-main-primary">Limit Access</p>
        <Switch
          size="small"
          checked={isLimitAccess}
          onCheckedChange={limitAccessChanged}
          data-testid="limit-access-switch"
        />
      </div>
      {isLimitAccess && (
        <div className="p-0">
          <DropdownMenuSeparator className="my-0 h-[1px] bg-stroke-main-light" />
          <div className="bg-transparent-lighter px-2 py-1 text-[10px] font-semibold text-text-main-secondary">
            Who has access
          </div>
          <Input placeholder="Search" intent="transparent" />
          <DropdownMenuSeparator className="h-[1px] bg-stroke-main-light" />
          <p className="p-2 text-xs text-text-main-tertiary">
            No one is added right now. Use the search above to manage users or user groups.
          </p>
        </div>
      )}
    </>
  );
};
