import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './certificate-dates-container.html?raw';

export const CertificateDatesContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'CertificateDatesService',
    'LogisticsService',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      CertificateDatesService: any;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      dateFilterOptions: any;
      filterLevel = 'certificate-dates-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      reportData: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        CertificateDatesService: any,
        LogisticsService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.CertificateDatesService = CertificateDatesService;
        this.LogisticsService = LogisticsService;
        this.gtFilterService = gtFilterService;
        this.queryParams = {};

        this.dateFilterOptions = [
          {
            predicate: 'loading_date',
            title: this.gettext('Loading date'),
          },
          {
            predicate: 'receiving_date',
            title: this.gettext('Receiving date'),
          },
        ];
      }

      $onInit() {
        Object.assign(this.queryParams, this.initQueryParams);
        this.filterLevel = this.filterLevel || 'contract-status-table';
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
      }

      applyFilters() {
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      resetFilters() {
        this.queryParams = {};
        Object.assign(this.queryParams, this.initQueryParams);
        this.applyFilters();
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.CertificateDatesService.getCertificateDatesReport(this.queryParams).then(
          (data: any) => {
            this.reportData = data;
            this.GtUtils.overlay('hide');
          },
        );
      }

      openLogisticModal(logistic: any) {
        this.LogisticsService.logisticModal(logistic).then(() => this.updateData());
      }
    },
  ],
};
