import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './person-quick-add.html?raw';

export const PersonQuickAdd = {
  bindings: {
    person: '<?',
    quickAdd: '=?',
    onSave: '&?',
  },
  template,
  controller: [
    '$rootScope',
    'GtUtils',
    'ClientsService',
    'CoreService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      ClientsService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      buQueryParams: QueryParams = {};
      errors: any;
      filterLevel = 'person-quick-add';
      gettext: ng.gettext.gettextFunction;
      onSave: any;
      person: any;
      quickAdd: any;
      saveDisabled: any;
      constructor(
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        ClientsService: any,
        CoreService: CoreService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.ClientsService = ClientsService;
        this.CoreService = CoreService;
        this.gettext = gettext;

        this.person = {};
        this.quickAdd = undefined;
        this.onSave = undefined;
      }

      $onInit() {
        this.CoreService.getDefaultBuId(this.person).then(
          (data: any) => (this.person.business_unit = data),
        );
        this.person.relations = 'Neutral';
        this.saveDisabled = false;
        this.buQueryParams = this.GtUtils.getBUQueryParams();
      }

      $onChanges(changes: any) {
        if (changes.person) {
          this.person = Object.assign(this.person, changes.person.currentValue);
        }
      }

      close() {
        this.quickAdd = false;
      }

      save() {
        let hasErrors = false;
        this.saveDisabled = true;
        if (!this.person.first_name) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('First Name is required'), 'error');
        }
        if (!this.person.second_name) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('Last name is required'), 'error');
        }
        if (!this.person.companies.length) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('At least one company is required'), 'error');
        }
        if (hasErrors) {
          return false;
        }
        this.GtUtils.overlay('show');
        return this.ClientsService.Person.save(
          this.person,
          () => {
            this.GtUtils.overlay('hide');
            this.person = {};
            this.onSave?.();
            this.errors = {};
            this.saveDisabled = false;
            this.GtUtils.notify(this.gettext('Person saved'));
          },
          (error: any) => {
            this.GtUtils.errorClb(error);
            this.saveDisabled = false;
            this.GtUtils.overlay('hide');
          },
        );
      }
    },
  ],
};
