import type ng from 'angular';

import template from './import-export-modal.html?raw';
import type { ImportExportContractsService } from '../import-export.service';

export const ImportExportModal = {
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  template,
  controller: [
    'gettext',
    'ImportExportContractsService',
    class {
      ImportExportContractsService: ImportExportContractsService;
      alerts: any;
      close: any;
      contractType: any;
      diff: any;
      file: any;
      gettext: ng.gettext.gettextFunction;
      processing: any;
      resolve: any;
      showSkippedDiff: any;
      tableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        ImportExportContractsService: ImportExportContractsService,
      ) {
        this.gettext = gettext;
        this.ImportExportContractsService = ImportExportContractsService;

        this.file = undefined;
        this.contractType = undefined;
        this.processing = false;
        this.alerts = [];
        this.tableOptions = undefined;
        this.diff = undefined;
        this.showSkippedDiff = false;
      }

      $onInit() {
        this.contractType = this.resolve.contractType || 'sale';
      }

      closeModal(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.close({ $value: 'cancel' });
      }

      fileSelect(file: any) {
        this.file = file;
        this.alerts = [];
        this.processing = false;
        this.tableOptions = undefined;
        this.diff = undefined;
      }

      closeAlert(index: any) {
        this.alerts.splice(index, 1);
      }

      updateStages(form: any, save: any) {
        this.alerts = [];
        this.processing = true;
        this.tableOptions = undefined;
        this.diff = undefined;
        this.ImportExportContractsService.updateStages(this.file, this.contractType, save).then(
          (res: any) => {
            res.data.messages.forEach((msg: any) =>
              this.alerts.push({ type: 'success', msg: msg }),
            );
            res.data.errors.forEach((msg: any) => this.alerts.push({ type: 'danger', msg: msg }));
            this.tableOptions = this.getTableOptions(res.data.diff);
            this.diff = res.data.diff;
            this.processing = false;
          },
        );
      }

      getTableOptions(diff: any) {
        const options: any = {
          tableName: 'contractsDiff',
          columnDefs: [
            {
              columnName: 'status',
              title: this.gettext('Status'),
              cellTemplate: `
            <span class="label" ng-class="{label_warning: item.status == 'updated', 'label_default': item.status == 'skipped'}">
              {[{item.status}]}
            </span>
            `,
            },
          ],
          rowData: [],
          onInit: (api: any) => {
            if (this.showSkippedDiff) {
              api.setRowData(diff);
            } else {
              api.setRowData(diff.filter((d: any) => d.status != 'skipped'));
            }
          },
        };
        if (diff.length) {
          Object.keys(diff[0].new).forEach((k) => {
            options.columnDefs.push({
              columnName: k,
              title: k,
              cellTemplate: `
            <span ng-if="item.old.${k} != item.new.${k}">
              <strike>
              <span class="label label_danger">{[{item.old.${k}}]}</span>
              </strike>
              <span class="label label_success">{[{item.new.${k}}]}</span>
            </span>
            <span ng-if="item.old.${k} == item.new.${k}">
              {[{item.new.${k}}]}
            </span>
            `,
            });
          });
        }
        return options;
      }
    },
  ],
};
