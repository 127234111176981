import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { WarehouseShipmentListContainer } from './warehouse-shipment-list-container/warehouse-shipment-list-container.component';
import { WarehouseShipmentListTableView } from './warehouse-shipment-list-table-view/warehouse-shipment-list-table-view.component';
import { WarehouseShipmentPageView } from './warehouse-shipment-page-view/warehouse-shipment-page-view.component';
import { WarehouseShipmentService } from './warehouse-shipment.service';

export const warehouseShipment = ng
  .module('execution.warehouse.warehouseShipment', [])
  .service('WarehouseShipmentService', WarehouseShipmentService)
  .component('warehouseShipmentPageView', WarehouseShipmentPageView)
  .component('warehouseShipmentListContainer', WarehouseShipmentListContainer)
  .component('warehouseShipmentListTableView', WarehouseShipmentListTableView)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('logistics.warehouseShipment', {
        url: '/warehouse/shipment',
        component: 'warehouseShipmentPageView',
        data: {
          pageTitle: gettext('Warehouse Shipment'),
          permissions: {
            only: 'view_logistic',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'warehouse-shipment-page-view';
          },
          queryParams: [
            'PageService',
            (PageService: PageService) => {
              return PageService.syncUrlFilter('warehouse-shipment-page-view');
            },
          ],
        },
      });
    },
  ]).name;
