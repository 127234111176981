import React from 'react';

import type { CellDataType, ColumnDef } from '~/shared/ui/components/data-grid';

import { CheckboxCellRead } from '../cell-table-adapters/checkbox-cell-read';
import { DateCellWrite } from '../cell-table-adapters/date-cell-write';
import { EmailCellWrite } from '../cell-table-adapters/email-cell-write';
import { EntityCellWrite } from '../cell-table-adapters/entity-cell-write';
import { LabelCellWrite } from '../cell-table-adapters/label-cell-write';
import { MultiselectCellWrite } from '../cell-table-adapters/multiselect-cell-write';
import { NumberCellWrite } from '../cell-table-adapters/number-cell-write';
import { PhoneCellWrite } from '../cell-table-adapters/phone-cell-write';
import { SelectCellWrite } from '../cell-table-adapters/select-cell-write';
import { TemplateCell } from '../cell-table-adapters/template-cell';
import { TextFieldCellWrite } from '../cell-table-adapters/text-field-cell-write';
import { UrlCellWrite } from '../cell-table-adapters/url-cell-write';

export const DynamicWriteCell = <TData extends { id: string }>({
  value,
  valueChanged,
  rowId,
  template,
  cellTemplateContext,
  dataType,
  typeExtra,
}: {
  dataType: CellDataType;
  rowId: string;
  value: TData[keyof TData];
  valueChanged: (value?: TData[keyof TData]) => void;
  template?: React.ElementType;
  cellTemplateContext?: ColumnDef<TData>['cellTemplateContext'];
  typeExtra: string;
}) => {
  if (dataType === 'text') {
    return <TextFieldCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'number') {
    return <NumberCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'string') {
    return <TextFieldCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'url') {
    return <UrlCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'date') {
    return <DateCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'select') {
    return (
      <SelectCellWrite
        value={value}
        valueChanged={valueChanged}
        cellTemplateContext={cellTemplateContext}
      />
    );
  }

  if (dataType === 'entity') {
    return (
      <EntityCellWrite id={rowId} value={value} valueChanged={valueChanged} typeExtra={typeExtra} />
    );
  }

  if (dataType === 'email') {
    return <EmailCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'phone') {
    return <PhoneCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'checkbox') {
    return <CheckboxCellRead value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'label') {
    return <LabelCellWrite value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'multiselect') {
    return (
      <MultiselectCellWrite
        value={value}
        cellTemplateContext={cellTemplateContext}
        valueChanged={valueChanged}
      />
    );
  }

  if (dataType === 'template') {
    return (
      <TemplateCell
        value={value}
        valueChanged={valueChanged}
        cellTemplateContext={cellTemplateContext}
        Template={template}
      />
    );
  }
};
