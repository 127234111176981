import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './bill-of-lading-list-container.html?raw';

export const billOfLadingListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    onUpdate: '&?',
    tableName: '<?',
    voyage: '<?',
    showControl: '<?',
    hideTotals: '<?',
    readonly: '<',
  },
  template,
  controller: [
    '$q',
    '$scope',
    '$window',
    'GtUtils',
    'gtFilterService',
    'BillOfLadingService',
    'gettext',
    'DocumentsService',
    'CoreService',
    'ContractsService',
    class billOfLadingContainer {
      $q: ng.IQService;
      $scope: ng.IScope;
      $window: ng.IWindowService;
      BillOfLadingService: any;
      ContractsService: ContractsService;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      billsOfLading: any;
      billsOfLadingCount: number;
      filterLevel = 'bill-of-lading-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      modelName: any;
      newBl: any;
      onUpdate: any;
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      selection: any;
      tableName: any;
      total: any;
      voyage: any;
      constructor(
        $q: ng.IQService,
        $scope: ng.IScope,
        $window: ng.IWindowService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        BillOfLadingService: any,
        gettext: ng.gettext.gettextFunction,
        DocumentsService: any,
        CoreService: CoreService,
        ContractsService: ContractsService,
      ) {
        this.$q = $q;
        this.$scope = $scope;
        this.$window = $window;
        this.gettext = gettext;
        this.DocumentsService = DocumentsService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.BillOfLadingService = BillOfLadingService;
        this.CoreService = CoreService;
        this.ContractsService = ContractsService;

        this.queryParams = {};
        this.billsOfLading = [];
        this.newBl = {};
        this.billsOfLadingCount = 0;
        this.selection = false;
        this.savedFilterChoices = [];
        this.modelName = 'passport';
      }

      $onInit() {
        this.tableName = this.tableName || this.filterLevel;
        this.queryParams = { page_size: 50, ...this.initQueryParams };
        this.newBl = {
          ...this.initQueryParams,
          voyage: this.voyage ? this.voyage : this.initQueryParams.voyage,
          date: new Date(),
        };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: any) {
        this.gtFilterService.updateQueryParams(
          {
            ...this.initQueryParams,
            ...params,
          },
          this.filterLevel,
        );
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.BillOfLadingService.getBillOfLadingData(this.queryParams)
          .then((data: any) => {
            if (this.queryParams.next) {
              this.billsOfLading = [...this.billsOfLading, ...data.data.results];
              delete this.queryParams.next;
            } else {
              data.data.results.unshift(this.newBl);
              this.billsOfLading = data.data.results;
            }
            this.billsOfLadingCount = data.data.count;
            this.total = data.totals;
          })
          .then(() => this.onUpdate())
          .catch((error: any) => this.GtUtils.errorClb(error))
          .finally(() => {
            this.GtUtils.overlay('hide');
          });
      }

      invoiceCallback() {
        this.GtUtils.notify(this.GtUtils.translate(this.gettext('BLs are invoiced')));
        this.selection = !this.selection;
        this.updateData();
        return {
          $promise: this.$q.when(),
        };
      }

      createInvoice(invoiceType: any, invoiceCondition: any, invoicePercent = 100) {
        const bills = this.billsOfLading.filter((item: any) => item.$_selected && item.id);
        if (invoicePercent !== 100) {
          bills.forEach((bill: any) => {
            bill.quantity_to_be_invoiced_incoming *= invoicePercent / 100;
            bill.quantity_to_be_invoiced_outgoing *= invoicePercent / 100;
          });
        }
        this.BillOfLadingService.createInvoice(
          bills,
          this.queryParams.passport,
          () => this.invoiceCallback(),
          invoiceType,
          invoiceCondition,
        ).then(
          () => this.GtUtils.notify('BLs are invoiced'),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      openDocumentModal(object: any, modelName: any) {
        return this.DocumentsService.attachDocumentModal(object, modelName).then(() =>
          this.updateData(),
        );
      }

      openBlModal(bl: any, tab: any) {
        bl = bl || this.newBl;
        return this.BillOfLadingService.billOfLadingModal(bl, {
          tab: tab,
          quantity_to_be_invoiced_incoming: bl.quantity_to_be_invoiced_incoming,
          quantity_to_be_invoiced_outgoing: bl.quantity_to_be_invoiced_outgoing,
        }).then(() => this.updateData());
      }

      updateItem(billOfLading: any) {
        this.BillOfLadingService.updateBillOfLading(billOfLading).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      saveItem(billOfLading: any) {
        return this.checkContractsVolumeLimit(billOfLading).then((save: any) =>
          save
            ? this.BillOfLadingService.saveBillOfLading(billOfLading).then(
                () => {
                  this.updateData();
                  this.onUpdate();
                },
                (error: any) => this.GtUtils.errorClb(error),
              )
            : false,
        );
      }

      checkContractsVolumeLimit(bl: any) {
        this.GtUtils.overlay('show');
        return this.BillOfLadingService.checkContractsVolumeLimit(
          [bl.sale_contract, bl.purchase_contract],
          bl.volume,
        )
          .then(
            (warnings: any) =>
              warnings.length
                ? this.decideWarnings(warnings).then((result: any) => (result ? true : false))
                : true,
            (error: any) => this.GtUtils.errorClb(error),
          )
          .finally(() => this.GtUtils.overlay('hide'));
      }

      decideWarnings(warnings: any) {
        const deferred = this.$q.defer();
        let result = true;
        for (const warning of warnings) {
          if (!confirm(warning.message)) {
            result = false;
            break;
          }
        }
        deferred.resolve(result);
        return deferred.promise;
      }

      destroy(billOfLadingId: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.BillOfLadingService.deleteBillOfLading({ id: billOfLadingId }).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      openDocxModal(id: any) {
        return this.DocumentsService.generateDocxModal('BillOfLading', id);
      }

      showContractsDetails(item: any) {
        if (!item) {
          return;
        }
        return this.ContractsService.Contract.predictionsDetails({
          id: item.sale_contract,
          full_title: true,
        })
          .$promise.then((data: any) => (item.saleContractDetails = data))
          .then(
            this.ContractsService.Contract.predictionsDetails({
              id: item.purchase_contract,
              full_title: true,
            }).$promise.then((data: any) => (item.purchaseContractDetails = data)),
          );
      }

      onContractSelect(item: any) {
        return this.showContractsDetails(item);
      }
    },
  ],
};
