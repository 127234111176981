import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { InvoicePositionContainer } from './invoice-position-container/invoice-position-container.component';
import { InvoicePositionPageView } from './invoice-position-page-view/invoice-position-page-view.component';
import { InvoicePositionService } from './invoice-position.service';

const invoicePositionConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('finances.invoicePositionPage', {
      url: '/invoice-positions/',
      component: 'invoicePositionPageView',
      data: {
        pageTitle: gettext('Invoice Positions'),
      },
      resolve: {
        filterLevel: () => {
          return 'invoice-position-page-view';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('invoice-position-page-view');
          },
        ],
      },
    });
  },
];

export const invoicePosition = ng
  .module('finances.invoice-position', [])
  .component('invoicePositionContainer', InvoicePositionContainer)
  .component('invoicePositionPageView', InvoicePositionPageView)
  .service('InvoicePositionService', InvoicePositionService)
  .config(invoicePositionConfig).name;
