import ng from 'angular';

import { AnalyticsContainerComponent } from './analytics-container/analytics-container.component';
import { AnalyticsPageComponent } from './analytics-page/analytics-page.component';
import { AnalyticsService } from './analytics.service';

export const analyticsModule = ng
  .module('comon.analytics', [])
  .component('analyticsPageComponent', AnalyticsPageComponent)
  .component('analyticsContainerComponent', AnalyticsContainerComponent)
  .service('AnalyticsService', AnalyticsService)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider
        .state('analytic', {
          parent: 'gt.page',
          url: '/analytics',
          abstract: true,
          template: '<ui-view/>',
        })
        .state('analytic.counterparty', {
          url: '/counterparty',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Counterparty Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'crm', dashboard: 'analytics-crm-counterparties' };
            },
          },
        })
        .state('analytic.updates', {
          url: '/updates',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Updates Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'crm', dashboard: 'analytics-crm-updates' };
            },
          },
        })
        .state('analytic.tickets', {
          url: '/tickets',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Tickets Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'deals', dashboard: 'analytics-deals-tickets' };
            },
          },
        })
        .state('analytic.contracts', {
          url: '/contracts',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Contracts Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'deals', dashboard: 'analytics-deals-contracts' };
            },
          },
        })
        .state('analytic.passports', {
          url: '/passports',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Passports Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'deals', dashboard: 'analytics-deals-passports' };
            },
          },
        })
        .state('analytic.logistics', {
          url: '/logistics',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Logistics Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'executions', dashboard: 'analytics-execution-logistics' };
            },
          },
        })
        .state('analytic.voyages', {
          url: '/voyages',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Voyages Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'executions', dashboard: 'analytics-execution-voyages' };
            },
          },
        })
        .state('analytic.invoices', {
          url: '/invoices',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Invoices Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'finances', dashboard: 'analytics-finance-invoices' };
            },
          },
        })
        .state('analytic.charges', {
          url: '/charges',
          component: 'analyticsPageComponent',
          data: {
            pageTitle: gettext('Charges Analytics'),
          },
          resolve: {
            initQueryParams: () => {
              return { page: 'finances', dashboard: 'analytics-finance-charges' };
            },
          },
        });
    },
  ]).name;
