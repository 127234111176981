import ng from 'angular';

import { AnalyticsContainer } from './analytics-container/analytics-container.component';
import { AnalyticsPageView } from './analytics-page-view/analytics-page-view.component';
import { FinancesAnalyticsService } from './analytics.service';

const financesAnalytics: any = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('finances.analytics', {
      url: '/analytics',
      component: 'financesAnalyticsPageView',
      data: {
        pageTitle: gettext('Analytics'),
      },
    });
  },
];

export const analytics = ng
  .module('finances.analytics', [])
  .component('financesAnalyticsContainer', AnalyticsContainer)
  .component('financesAnalyticsPageView', AnalyticsPageView)
  .service('FinancesAnalyticsService', FinancesAnalyticsService)
  .config(financesAnalytics).name;
