import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './currency-toggle.html?raw';

export const CurrencyToggle = {
  bindings: {
    onSelect: '&?',
  },
  template,
  controller: [
    'FinancesService',
    'AccountsService',
    class {
      AccountsService: any;
      FinancesService: FinancesService;
      defaultCurrency: any;
      exchangeRate: any;
      localCurrency: any;
      onSelect: any;
      selectedCurrency: any;
      constructor(FinancesService: FinancesService, AccountsService: any) {
        this.FinancesService = FinancesService;
        this.AccountsService = AccountsService;

        this.defaultCurrency = undefined;
        this.localCurrency = undefined;
        this.selectedCurrency = undefined;
        this.exchangeRate = undefined;
      }

      $onInit() {
        this.AccountsService.getUser().then((data: any) => {
          this.localCurrency = data.settings.LOCAL_CURRENCY;
          this.defaultCurrency = data.settings.DEFAULT_CURRENCY;
          this.getCurrencyExchange().then(this.selectCurrency(this.defaultCurrency));
        });
      }

      selectCurrency(value: any) {
        this.selectedCurrency = value;
        this.onSelect?.({
          $event: {
            currency: this.selectedCurrency,
            exchangeRate:
              (this.selectedCurrency === this.defaultCurrency && 1) || this.exchangeRate,
          },
        });
      }

      getCurrencyExchange() {
        return this.FinancesService.getLatestRate(this.localCurrency).then((data: any) => {
          this.exchangeRate = data;
        });
      }
    },
  ],
};
