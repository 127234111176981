import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

import template from './drivers-list-table-view.html?raw';

export const DriversListTableView = {
  bindings: {
    drivers: '<',
    openDriverModal: '&',
  },
  template,
  controller: [
    'gettext',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      drivers: any;
      gettext: ng.gettext.gettextFunction;
      hovering: any;
      openDriverModal: any;
      tableData: any;
      tableOptions: any;
      constructor(gettext: ng.gettext.gettextFunction, $rootScope: GtRootScopeService) {
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.hovering = false;
      }

      $onInit() {
        this.tableData = { rows: this.drivers };
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.drivers) {
          this.tableData = { rows: this.drivers };
        }
      }

      setHovering(value: any) {
        this.hovering = value;
      }

      getTableOptions() {
        return {
          tableName: 'drivers',
          tableClass: 'contract-charges-table',
          alignColHeight: true,
          rowData: this.drivers,
          configurable: true,
          templateArgs: {
            openDriverModal: (item: any) => this.openDriverModal({ item }),
          },
          columnDefs: [
            {
              columnName: 'first_name',
              title: this.gettext('First name'),
              class: 'td-left-align',
              cellTemplate: `
               <div class="space-between">
                <ul
                  class="nav navbar-nav item-settings-dropdown"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      {[{ item.first_name || "---" }]}
                      <i class="fa fa-sort-desc hover-element"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="btn btn-xs col-xs-12" ui-sref="driverDetails({id: item.id })">
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'change_driver'"
                          class="btn btn-xs aligned-btn"
                          ng-disabled="item.result_status == 'approved'"
                          ng-click="args.openDriverModal(item)"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                    </ul>
              `,
            },
            {
              columnName: 'last_name',
              title: this.gettext('Last name'),
              class: 'td-left-align',
              cellTemplate: `
              <div ng-if="item.last_name" style="line-height: 2; display: inline-block; margin-left: 5px;" >{[{ item.last_name }]}</div>
              `,
            },
            {
              columnName: 'middle_name',
              title: this.gettext('Middle name'),
              class: 'td-left-align',
              cellTemplate: `
              <div ng-if="item.middle_name" style="line-height: 2; display: inline-block; margin-left: 5px;" >{[{ item.middle_name }]}</div>
              `,
            },
            {
              columnName: 'create_time',
              title: this.gettext('create time'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.create_time">
                  <i class="fa fa-clock-o"></i> {[{ ::item.create_time | date:'HH:mm' ||
                  '---' }]} - <i class="fa fa-calendar"></i> {[{ ::item.create_time |
                  date:'dd.MM.yy' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'update_time',
              title: this.gettext('update time'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.update_time">
                  <i class="fa fa-clock-o"></i> {[{ ::item.update_time | date:'HH:mm' ||
                  '---' }]} - <i class="fa fa-calendar"></i> {[{ ::item.update_time |
                  date:'dd.MM.yy' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'business_unit',
              title: this.gettext('business unit'),
              class: 'td-left-align',
              cellTemplate: `
                <div ng-if="item.business_unit_title" style="line-height: 2; display: inline-block; margin-left: 5px;" >{[{ item.business_unit_title }]}</div>
              `,
            },
            {
              columnName: 'ukrainian_id',
              title: this.gettext('Driver passport seria & number (Ukrainian)'),
              class: 'td-left-align',
              cellTemplate: `
                <div ng-if="item.ukrainian_id" style="line-height: 2; display: inline-block; margin-left: 5px;" >{[{ item.ukrainian_id }]}</div>
              `,
            },
            {
              columnName: 'passport_number',
              title: this.gettext('Driver passport seria & number'),
              class: 'td-left-align',
              cellTemplate: `
                <div ng-if="item.passport_number" style="line-height: 2; display: inline-block; margin-left: 5px;" >{[{ item.passport_number }]}</div>
              `,
            },
            {
              columnName: 'license_expire_date',
              title: this.gettext('License expiration date'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.license_expire_date">
                  <i class="fa fa-calendar"></i> {[{ ::item.license_expire_date |
                  date:'dd.MM.yy' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'passport_expire_date',
              title: this.gettext('Passport expiration date'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.passport_expire_date">
                  <i class="fa fa-calendar"></i> {[{ ::item.passport_expire_date |
                  date:'dd.MM.yy' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'author',
              title: this.gettext('Author'),
              class: 'td-left-align',
              cellTemplate: `
                <div
                  class="user-avatar"
                  ng-if="item.author_first_name && item.author_avatar.length"
                >
                  <img ng-src="/pictures/{[{item.author_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="!item.author_avatar.length">
                  {[{ item.author_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.author_first_name }]}
              `,
            },
            {
              columnName: 'docs',
              title: this.gettext('Docs'),
              class: 'td-left-align',
              hint: this.gettext(
                'the number of documents attached to this driver. Click on the counter and you will see them all',
              ),
              hideExpr: () => !this.$rootScope.user.settings.SYSTEM_BLOCKS.block_company_documents,
              cellTemplate: /*html*/ `
              <a
                class="modal-btn"
                ng-class="{ 'label-opacity': !item.documents_count }"
              >
                <i class="fa fa-upload"></i> {[{ item.documents_count }]}
              </a>
            `,
            },
          ],
        };
      }
    },
  ],
};
