import ng from 'angular';

import { BudgetsContainer } from './budgets-container/budgets-container.component';
import { BudgetsPageView } from './budgets-page-view/budgets-page-view.component';
import { BudgetsService } from './budgets.service';

const budgetsConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state('reports.budgets.finances', {
      url: '/finances',
      component: 'budgetsPageView',
    });
  },
];

export const budgets = ng
  .module('finances.budgets', [])
  .component('budgetsContainer', BudgetsContainer)
  .component('budgetsPageView', BudgetsPageView)
  .service('BudgetsService', BudgetsService)
  .config(budgetsConfig).name;
