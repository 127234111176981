import React, { type ButtonHTMLAttributes, type DetailedHTMLProps } from 'react';

import { ChevronRightIcon } from '~/shared/ui/icons';

export const NextMonthButton = (
  props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => {
  return (
    <button {...props}>
      <ChevronRightIcon size={16} className="text-stroke-main-stronger" />
    </button>
  );
};
