import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './cashflow-report-container.html?raw';

export const CashflowReportContainer = {
  bindings: {
    filterLevel: '<',
    indicatorView: '<',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gtFilterService',
    'CashflowReportService',
    'FinancesService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CashflowReportService: any;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      chartsConfigs: any;
      config: any;
      data: any;
      dates: any;
      expenses: any;
      filterLevel = 'cashflow-report-tree';
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      gtFilterService: GtFilterService;
      hideFilters: any;
      indicatorView: any;
      indicators: any;
      queryParams: QueryParams = {};
      reports: any;
      selectedExpenses: any;
      selectedGroups: any;
      unselectedGroups: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gtFilterService: GtFilterService,
        CashflowReportService: any,
        FinancesService: FinancesService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.gtFilterService = gtFilterService;
        this.CashflowReportService = CashflowReportService;
        this.FinancesService = FinancesService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.data = [];
        this.indicators = [];
        this.queryParams = {};
        this.hideFilters = true;
        this.indicatorView = undefined;
        this.selectedGroups = [];
        this.unselectedGroups = [];

        this.expenses = { planned: 0, totalSelected: 0, balance: 0 };

        this.selectedExpenses = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'cashflow-report-page-view';

        this.groupChoices = [
          { param: 'currency', title: this.gettext('currency'), icon: 'fa-money' },
          { param: 'account', title: this.gettext('bank account'), icon: 'fa-bank' },
          { param: 'invoice_type', title: this.gettext('invoice type'), icon: 'fa-credit-card' },
          { param: 'use', title: this.gettext('use'), icon: 'fa-building' },
          { param: 'subuse', title: this.gettext('subuse'), icon: 'fa-university' },
          { param: 'counterparty', title: this.gettext('counterparty'), icon: 'fa-list' },
          { param: 'owner', title: this.gettext('owner'), icon: 'fa-home' },
          { param: 'finance_type', title: this.gettext('finance type'), icon: 'fa-level-up' },
          {
            param: 'report_column_type',
            title: this.gettext('report column type'),
            icon: 'fa-level-down',
          },
          { param: 'generalexpenses', title: this.gettext('generalexpenses'), icon: 'fa-money' },
          { param: 'otheractivity', title: this.gettext('otheractivity'), icon: 'fa-money' },
          { param: 'charge', title: this.gettext('charge'), icon: 'fa-money' },
          { param: 'businessunit', title: this.gettext('businessunit'), icon: 'fa-money' },
        ];

        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          [this.selectedGroups, this.unselectedGroups] = this.CashflowReportService.parseGroups(
            this.groupChoices,
            this.queryParams.turnover_groupping,
          );
          this.updateCashFlowReportData();
        });
        this.$scope.$watch(
          () => this.indicatorView,
          () => this.changeView(),
        );
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      selectGroup(group: any) {
        this.selectedGroups.push(group);
        this.unselectedGroups.splice(this.unselectedGroups.indexOf(group), 1);
        this.queryParams.turnover_groupping = this.selectedGroups
          .map((v: any) => v.param)
          .join(',');
      }

      unselectGroup(group: any) {
        this.unselectedGroups.push(group);
        this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
      }

      updateCashFlowReportData() {
        this.GtUtils.overlay('show');
        this.reports = [];
        return this.CashflowReportService.getReportData({
          ...this.queryParams,
          group_by: this.queryParams.turnover_groupping,
        }).then((data: any) => {
          this.GtUtils.overlay('hide');
          this.data = data.results;
          this.dates = data.dates;
          this.chartsConfigs = data.chartsConfigs;
          this.changeView();
        });
      }

      applyFilter() {
        this.queryParams.turnover_groupping = this.selectedGroups
          .map((v: any) => v.param)
          .join(',');
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      changeView() {
        this.config = this.CashflowReportService.getTheadConfig(
          this.selectedGroups.map((v: any) => v.title.toUpperCase()).join(' | '),
          this.indicatorView,
        );
      }

      calculateExpenses() {
        this.expenses.totalSelected = this.selectedExpenses
          .map((item: any) => {
            if (item.report_column_type == 'overpaid') {
              return Math.abs(Math.round(item.total_local * 100) / 100) * -1;
            } else {
              return Math.abs(Math.round(item.total_local * 100) / 100);
            }
          })
          .reduce((acc: any, cur: any) => acc + cur, 0);
        this.expenses.balance = +this.expenses.planned - +this.expenses.totalSelected;
      }

      createPaymentPlan() {
        const idList = this.selectedExpenses.map((item: any) => item.invoice_id);

        if (idList.length === 0) {
          this.FinancesService.paymentPlanModal({
            finances: [],
            plannedExpenses: this.expenses.planned,
          });
          return;
        }

        this.FinancesService.Finance.tabInfo({ id_list: idList })
          .$promise.then((data: any) => data.results)
          .then((results: any) =>
            results.map((finance: any) =>
              this.createPayment(
                this.selectedExpenses
                  .filter((ex: any) => ex.invoice_id === finance.id)
                  .reduce((res: any, ex: any) => res + Math.abs(ex.total_local), 0),
                finance,
              ),
            ),
          )
          .then((finances: any) => {
            this.selectedExpenses = [];
            this.calculateExpenses();

            this.FinancesService.paymentPlanModal({
              finances,
              plannedExpenses: this.expenses.planned,
            });
          })
          .catch((err: any) => {
            this.GtUtils.errorClb(err);
          });
      }

      createPayment(amount: any, finance: any) {
        let overpaidCoef = 1;

        if (finance.fact_amount > finance.amount) {
          overpaidCoef = -1;
        }

        return {
          finance: finance.id,
          value: Math.abs(Math.round(amount * 100) / 100) * overpaidCoef,
          finance_number: finance.number,
          additional_info: finance.additional_info,
          clientrole_from_name: finance.clientrole_from_name,
          clientrole_from_role: finance.clientrole_from_role,
          clientrole_from: finance.clientrole_from,
          clientrole_to_name: finance.clientrole_to_name,
          clientrole_to_role: finance.clientrole_to_role,
          clientrole_to: finance.clientrole_to,
          isOverpaid: finance.fact_amount > finance.amount,
          currency_symbol: finance.currency_symbol,
          invoicepositions: (finance.invoicepositions_set || []).map((position: any) => {
            return {
              amount: position.amount_sum,
              quantity: position.quantity_sum,
              subuses: position.subuses,
              use: position.use,
            };
          }) || { quantity: 0, subuses: 0, use: 0 },
        };
      }
    },
  ],
};
