import type ng from 'angular';

export class AuthHttpInterceptor implements ng.IHttpInterceptor {
  $q: ng.IQService;
  $window: ng.IWindowService;
  reloading: any;
  constructor($window: ng.IWindowService, $q: ng.IQService) {
    this.$window = $window;
    this.$q = $q;
    this.reloading = false;

    return {
      $window: this.$window,
      $q: this.$q,
      reloading: this.reloading,
      responseError: (rejection) => this.responseError(rejection),
    };
  }

  responseError(rejection: any) {
    if (!this.reloading && rejection.status == 401) {
      this.reloading = true;
      this.$window.location.reload();
    }
    return this.$q.reject(rejection);
  }
}
AuthHttpInterceptor.$inject = ['$window', '$q'];
