import React from 'react';

import { PlusIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';

export const AddButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => {
  return (
    <Button variant="primary" {...props}>
      <span className="flex items-center gap-1">
        <PlusIcon size={14} className="text-stroke-main-extraLight" />
        <p className="font-medium text-text-main-onPrimarySection">Add</p>
      </span>
    </Button>
  );
};
