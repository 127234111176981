import type { TransportAnalyticsService } from '../../components/transport/analytics/analytics.service';

export const BlVolumeByMonthChart = {
  bindings: {},
  template:
    '<charts-block config="$ctrl.chartConfig" chart-type="bar" hide-header="true" hide-selects="true"></charts-block>',
  controller: [
    'TransportAnalyticsService',
    class BlVolumeByMonthChart {
      TransportAnalyticsService: TransportAnalyticsService;
      chartConfig: any;
      constructor(TransportAnalyticsService: TransportAnalyticsService) {
        this.TransportAnalyticsService = TransportAnalyticsService;
        this.chartConfig = undefined;
      }

      $onInit() {
        this.TransportAnalyticsService.getBlVolumesByMonthChartConfig().then(
          (data: any) => (this.chartConfig = data),
        );
      }
    },
  ],
};
