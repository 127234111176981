import ng from 'angular';

import { calendar } from './calendar/calendar.module';
import { clientsModule } from './clients';
import { mailing } from './mailing/mailing.module';
import { notifications } from './notifications/notifications.module';
import { shared } from './shared/shared.module';

const crmConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state('crm', {
      parent: 'gt.page',
      url: '/crm',
      abstract: true,
      template: '<ui-view/>',
    });
  },
];

export const crmModule = ng
  .module('crm', [clientsModule, shared, mailing, notifications, calendar])
  .config(crmConfig).name;
