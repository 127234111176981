import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

import template from './mailing-logistics-page-view.html?raw';

export const MailingLogisticsPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'gtFilterService',
    'MailingService',
    'gettext',
    class {
      MailingService: any;
      PageService: PageService;
      filterLevel = 'mailing-logistics-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      mailingsFilterLevel: string;
      constructor(
        PageService: PageService,
        gtFilterService: GtFilterService,
        MailingService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.MailingService = MailingService;

        this.gettext = gettext;

        this.mailingsFilterLevel = 'logistics-mailings-table';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-crm-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.MailingService.getPagesConfig(),
        };
      }
    },
  ],
};
