import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './transportation-storage-expense-positions-table.html?raw';
export const TransportationStorageExpensePositionsTable = {
  bindings: {
    expense: '<',
    positions: '<',
    formPrefix: '<',
    updatePositions: '&?',
    destroyPosition: '&?',
    formValidChange: '&?',
    completePositionsData: '&?',
    distributeCosts: '&?',
    readonly: '<?',
  },

  template,
  controller: [
    '$scope',
    '$rootScope',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      destroyPosition: any;
      emptyArrow: any;
      expense: any;
      formPrefix: any;
      formValidChange: any;
      gettext: ng.gettext.gettextFunction;
      openModal: any;
      positionFormName: any;
      positions: any;
      queryParams: QueryParams = {};
      unwatch: any;
      updateFields: any;
      updateFieldsTrigger: any;
      updatePositions: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.positions = [];
        this.expense = {};
        this.emptyArrow = () => {};
        this.unwatch = this.emptyArrow;
        this.updatePositions = this.emptyArrow;
        this.openModal = this.emptyArrow;
        this.formValidChange = this.emptyArrow;
        this.destroyPosition = this.emptyArrow;
        this.formPrefix = '';
      }
      $onInit() {
        this.positions = this.positions || [];
        this.expense = this.expense || {};
        this.queryParams = this.queryParams || {};
        this.updatePositions = this.updatePositions || this.emptyArrow;
        this.openModal = this.openModal || this.emptyArrow;
        this.destroyPosition = this.destroyPosition || this.emptyArrow;
        this.formValidChange = this.formValidChange || this.emptyArrow;
        this.positionFormName =
          this.formPrefix + '-transportation-storage-expense-positions-edit-form';
        this.toggleUpdateFieldsTrigger();
      }
      toggleUpdateFieldsTrigger() {
        this.updateFieldsTrigger = !this.updateFieldsTrigger;
      }
      clone(position: any) {
        const excludeFields: any = ['id'];
        const cleanObj = (object: any) =>
          Object.keys(object)
            .filter((key) => object[key] && !excludeFields.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: object[key] }), {});

        this.positions.unshift({
          ...cleanObj(position),
          derivatives: position.derivatives.map(cleanObj),
          sequence_number: this.positions.length + 1,
        });
        this.updatePositions({ positions: this.positions });
      }
      destroy(position: any) {
        return this.destroyPosition({ position: position });
      }
      getNewPosition() {
        return {
          date: this.expense.start_date,
          contract_position: this.expense.contract_position,
          commodity: this.expense.commodity,
          warehouse: this.expense.warehouse,
          batch: this.expense.batch,
          position_metric: 0,
          position_volume: 0,
          position_amount: 0,
          distribution_base: 0,
          costs_amount: 0,
        };
      }
      addPosition(init: any) {
        if (init && this.positions.length) {
          return false;
        }

        this.positions.unshift(this.getNewPosition());

        this.updatePositions({ positions: this.positions });
        this.updateFields();
      }
    },
  ],
};
