import type { GtRootScopeService } from '~/app/core/types';

export class MarketService {
  $rootScope: GtRootScopeService;
  gettext: ng.gettext.gettextFunction;
  constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
    this.$rootScope = $rootScope;
    this.gettext = gettext;
  }

  getPageConfig() {
    const config: any = [
      {
        title: this.gettext('Market'),
        permissions: {
          only: ['view_prices'],
        },
        state: 'market.bids',
        icon: 'fa-area-chart',
        tabs: [],
      },
    ];

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_derivatives) {
      // @ts-ignore
      config.push({
        title: this.gettext('Quotations'),
        state: 'market.derivatives',
        icon: 'fa-money-bill-trend-up',
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_deals_indicators) {
      config[0].tabs.push(
        {
          title: this.gettext('Bids'),
          state: 'market.bids',
          icon: 'fa-arrow-up',
        },
        {
          title: this.gettext('Offers'),
          state: 'market.offers',
          icon: 'fa-arrow-down',
        },
      );
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_potentials) {
      // @ts-ignore
      config.push({
        title: this.gettext('Potentials'),
        state: 'market.potentials',
        icon: 'fa-square',
      });
    }

    return config;
  }
}
MarketService.$inject = ['$rootScope', 'gettext'];
