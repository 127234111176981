import React from 'react';

import type { ColumnDef } from '~/shared/ui/components/data-grid';

import type { DataTypeProps } from '../../lib';

export const Template: React.FC<
  DataTypeProps<unknown> & {
    Template: React.ElementType;
    context: ColumnDef['cellTemplateContext'];
  }
> = ({ Template, value, context, ...props }) => <Template value={value} {...context} {...props} />;
