import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './payment-orders-list-container.html?raw';

export const PaymentOrdersListContainer = {
  bindings: {
    paymentPlanId: '<?',
  },
  template,
  controller: [
    'DocumentsService',
    'FinancesService',
    class {
      DocumentsService: any;
      FinancesService: FinancesService;
      finances: any;
      paymentOrders: any;
      paymentPlanId: any;
      constructor(DocumentsService: any, FinancesService: any) {
        this.DocumentsService = DocumentsService;
        this.FinancesService = FinancesService;

        this.paymentOrders = this.paymentOrders || [];
      }

      $onInit() {
        if (!this.paymentOrders.length) {
          this.updateData();
        }
      }

      updateData() {
        if (this.paymentPlanId) {
          this.finances = this.FinancesService.PaymentPlan.getPaymentOrders(
            {
              id: this.paymentPlanId,
            },
            (response: any) => (this.paymentOrders = response.payment_orders),
          );
        }
      }

      generatePaymentOrderDocument(paymentOrder: any) {
        return this.DocumentsService.generateDocxModal('PaymentPlan', this.paymentPlanId, {
          params: {
            clientrole_from: paymentOrder.finance__clientrole_from__id,
            clientrole_to: paymentOrder.finance__clientrole_to__id,
            bank_account: paymentOrder.finance__bank_account__id,
            currency: paymentOrder.finance__currency__id,
            payment_info: paymentOrder.finance__counterpaty_payment_info__id,
            payment_plan: paymentOrder.payment_plan_id,
          },
        });
      }
    },
  ],
};
