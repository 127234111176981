import ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').controller('ClientsPersonModalController', Controller);

  Controller.$inject = [
    '$q',
    '$uibModalInstance',
    'person',
    'ClientsService',
    'AccountsService',
    'FormFieldParamsService',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    $q: ng.IQService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    person: any,
    ClientsService: any,
    AccountsService: any,
    FormFieldParamsService: FormFieldParamsService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.close = close;
    vm.person = person || {};
    vm.logEntries = [];
    vm.logEntriesCount = 0;
    vm.tab = 'edit';
    vm.destroy = destroy;
    vm.save = save;
    vm.createUser = createUser;
    vm.updateData = updateData;
    vm.form = undefined;
    vm.AccountsService = AccountsService;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.getFormConfig = getFormConfig;
    activate();

    ////////////////

    function activate() {
      let promise;
      if (vm.person.id) {
        promise = updateData();
      } else {
        promise = $q.when();
      }
      promise.then(() => updateFields());
    }

    function updateData() {
      return ClientsService.Person.get({ id: vm.person.id }, function (data: any) {
        vm.person = data;
        updateLogEntries();
      }).$promise;
    }

    function updateLogEntries() {
      return AccountsService.LogEntry.query(
        {
          object_id: vm.person.id,
          content_type: vm.person.content_type,
        },
        function (data: any) {
          vm.logEntries = data.results;
          vm.logEntriesCount = data.count;
        },
      );
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete this Person?');
      if (!confirm(msg)) {
        return;
      }
      ClientsService.Person.delete({ id: vm.person.id }, function () {
        GtUtils.notify(gettext('Person removed'));
        close('delete', true);
      });
    }

    function save() {
      vm.form.$invalid = true;
      if (vm.person.id) {
        return ClientsService.Person.update(
          vm.person,
          function (data: any) {
            close(data, true);
            GtUtils.notify(gettext('Person updated'));
          },
          _error,
        );
      }
      return ClientsService.Person.save(
        vm.person,
        function (data: any) {
          close(data, true);
          GtUtils.notify(gettext('Person saved'));
        },
        _error,
      );
    }

    function createUser() {
      return ClientsService.createUser(vm.person).then(function () {
        close(vm.person, true);
      });
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.person.errors = data.data;
    }

    function getFormConfig() {
      const classes = 'form-group-container col-sm-12 col-md-6';
      const fieldsConfig: any = {
        formName: 'person-edit-modal',
        fieldsDef: [],
      };

      const col1: any = {
        fieldGroup: [],
        className: classes,
      };

      const col2: any = {
        fieldGroup: [],
        className: classes,
      };

      col1.fieldGroup.push(
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('INFO'),
          },
          fieldGroup: [
            {
              key: 'first_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('First Name'),
                placeholder: gettext('First Name'),
                required: true,
              },
            },
            {
              key: 'middle_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Middle Name'),
                placeholder: gettext('Middle Name'),
              },
            },
            {
              key: 'second_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Second Name'),
                placeholder: gettext('Second Name'),
                required: true,
              },
            },
            {
              key: 'user',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('GT User'),
                placeholder: gettext('User'),
                resource: 'auth.user',
              },
              hideExpression: '!AccountsService.hasPerm("view_user")',
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('COMPANY'),
          },
          fieldGroup: [
            {
              key: 'companies',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: gettext('Companies'),
                placeholder: gettext('Link with companies'),
                resource: 'clients.Client',
                addPerms: ['add_client'],
                addIcon: GtUtils.getIcon('clients.Client'),
                addFunc: ClientsService.clientModal,
                required: true,
              },
            },
            {
              key: 'main_contact',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Main contact of company?'),
              },
            },
            {
              key: 'position',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Position'),
                placeholder: gettext('Position'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
            {
              key: 'responsibilities',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: gettext('Responsibilities'),
                hint: gettext('responsibilities'),
                resource: 'clients.Responsibility',
              },
            },
          ],
        },
      );
      col2.fieldGroup.push(
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('CONTACTS'),
          },
          fieldGroup: [
            {
              key: 'phone',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Phone'),
                placeholder: gettext('Phone'),
              },
            },
            {
              key: 'verified',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Is person verified?'),
                disabled: !vm.AccountsService.hasPerm('can_verify_person'),
              },
            },
            {
              key: 'phone_additional',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Phone additional'),
                placeholder: gettext('Phone additional'),
              },
            },
            {
              key: 'email',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Email'),
                placeholder: gettext('Email'),
                type: 'email',
              },
            },
            {
              key: 'birthday',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Birthday'),
                placeholder: gettext('Birthday'),
              },
            },
            {
              key: 'telegram_username',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Telegram username'),
                placeholder: gettext('Telegram username'),
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('STATUS'),
            defaultHide: true,
          },
          fieldGroup: [
            {
              key: 'relations',
              type: 'gt-select',
              defaultValue: 'Neutral',
              templateOptions: {
                label: gettext('Relation'),
                placeholder: gettext('Choose relations'),
                hint: gettext(
                  'Pick one of the options to define the status of relations with the company',
                ),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: gettext('Hot'), value: 'Hot' },
                  { name: gettext('Warm'), value: 'Warm' },
                  { name: gettext('Neutral'), value: 'Neutral' },
                  { name: gettext('Cold'), value: 'Cold' },
                  { name: gettext('Angry'), value: 'Angry' },
                ],
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: gettext('Additional Info'),
                placeholder: gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
          ],
        },
      );
      fieldsConfig.fieldsDef.push(col1, col2);
      return fieldsConfig;
    }

    function openFieldsConfigModal() {
      return FormFieldParamsService.fieldsConfigModal(vm.getFormConfig()).then(() =>
        updateFields(),
      );
    }

    function updateFields() {
      return FormFieldParamsService.getFields(vm.getFormConfig()).then(
        (fields: any) => (vm.fields = fields),
      );
    }
  }
})();
