import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './logistics-documents-container.html?raw';

export const LogisticsDocumentsContainer = {
  bindings: {
    filterLevel: '<?',
    logistics: '<?',
    fieldsTitles: '<?',
  },
  template,
  controller: [
    '$scope',
    '$q',
    'GtUtils',
    'gtFilterService',
    'CoreService',
    'DocumentsService',
    'gettext',
    class {
      $q: ng.IQService;
      $scope: ng.IScope;
      CoreService: CoreService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      allDocumentsSelected: any;
      badLogistics: any;
      certificateRepr: any;
      certificateTypes: any;
      contentTypeId: any;
      errors: any;
      fieldsTitles: any;
      files: any;
      filterLevel = 'logistics-documents-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      logistics: any;
      onEvent: any;
      selectedLogisticsTotal: any;
      watch: any;
      constructor(
        $scope: ng.IScope,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
        DocumentsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.DocumentsService = DocumentsService;
        this.gettext = gettext;
        this.certificateTypes = undefined;
        this.certificateRepr = undefined;
        this.files = undefined;
        this.errors = undefined;
        this.allDocumentsSelected = undefined;
        this.contentTypeId = undefined;
        this.badLogistics = undefined;
        this.fieldsTitles = [];
        this.selectedLogisticsTotal = undefined;
      }

      $onInit() {
        this.files = {};
        this.errors = {};
        this.badLogistics = [];
        this.allDocumentsSelected = false;
        this.certificateTypes = [
          'railway',
          'vet',
          'quarantine',
          'quality',
          'declaration',
          'eur_one',
          'broker_confirmation',
          'additional_document_one',
          'additional_document_two',
          'additional_document_three',
        ];
        this.certificateRepr = this.fieldsTitles.reduce((res: any, f: any) => {
          res[f.columnName] = f.title;
          return res;
        }, {});
        this.certificateTypes.forEach((certificateType: any) => {
          this.files[certificateType] = [];
          this.errors[certificateType] = {};
        });

        this.CoreService.getModelContentType('logistic').then(
          (ct: any) => (this.contentTypeId = ct.id),
        );

        this.onEvent = this.$scope.$on(
          'logistics__checkboxes-update',
          (ev: any, rawLogistics: any, badLogistics: any) => {
            this.getFlatFilesList()
              .filter((file) => file.isSelected)
              .forEach((file) => (file.rawLogistics = rawLogistics));
            this.badLogistics = badLogistics;
          },
        );

        this.watch = this.$scope.$watch(
          () => this.allDocumentsSelected,
          (allDocumentsSelected: any) => this.toggleAll(allDocumentsSelected),
        );
      }

      fileSelect(files: any, certificateType: any) {
        delete this.errors[certificateType].file_size;
        this.files[certificateType] = this.files[certificateType].concat(
          files.map((file: any) => {
            return {
              file: file,
            };
          }),
        );
        this.files[certificateType].forEach(function (this: any, fileObj: any) {
          if (!fileObj.file.size) {
            this.errors[certificateType].file_size = this.gettext('empty file');
          } else if (fileObj.file.size > 52428800) {
            this.errors[certificateType].file_size = this.gettext(
              'single document size up to 50 mb',
            );
          }
          fileObj.title = fileObj.file.name;
          fileObj.certificate_type = certificateType;
          fileObj.certificate_date = new Date();
        });

        if (this.errors[certificateType].file_size) {
          this.GtUtils.notify(this.errors[certificateType].file_size, 'error');
          return false;
        }
        if (this.getFlatFilesList().length == 1) {
          this.getFlatFilesList()[0].isSelected = true;
        }
        this.getFlatFilesList()
          .filter((file) => file.isSelected)
          .forEach(
            (file) =>
              (file.rawLogistics = this.logistics.filter((logistic: any) => logistic._selected)),
          );
      }

      getFlatFilesList(): any {
        return Object.values(this.files).flat();
      }

      toggleAll(allDocumentsSelected: any) {
        const files = this.getFlatFilesList();
        if (allDocumentsSelected) {
          files.forEach((file) => (file.isSelected = true));
        } else {
          files.forEach((file) => (file.isSelected = false));
        }
      }

      deleteItem(file: any) {
        this.certificateTypes.forEach((certificateType: any) => {
          const index = this.files[certificateType].indexOf(file);
          if (index !== -1) {
            this.files[certificateType].splice(index, 1);
          }
        });
      }

      createErrorMessage(certificateGroups: any) {
        let logisticErrorString = '';
        this.badLogistics
          .filter(
            (badLogistic: any) =>
              Object.keys(badLogistic).filter(
                (badLogisticKey) =>
                  Object.keys(certificateGroups).includes(badLogisticKey) &&
                  badLogistic[badLogisticKey],
              ).length,
          )
          .forEach((badLogistic: any) => {
            logisticErrorString += badLogistic.vehicle_number + ': ';
            logisticErrorString += Object.keys(badLogistic)
              .filter(
                (badLogisticKey) =>
                  Object.keys(certificateGroups).includes(badLogisticKey) &&
                  badLogistic[badLogisticKey],
              )
              .join(', ');
            logisticErrorString += '\n';
          });
        return logisticErrorString;
      }

      invokeConfirmModal() {
        const certificateGroups = this.CoreService.groupBy(
          this.getFilteredFiles(),
          'certificate_type',
        );
        const logisticErrorString = this.createErrorMessage(certificateGroups);
        if (
          !this.badLogistics.filter(
            (badLogistic: any) =>
              Object.keys(badLogistic).filter(
                (badLogisticKey) =>
                  Object.keys(certificateGroups).includes(badLogisticKey) &&
                  badLogistic[badLogisticKey],
              ).length,
          ).length ||
          confirm(
            this.gettext(
              'Certificates exist in following logistics. Do you want to override them?',
            ) +
              '\n' +
              logisticErrorString,
          )
        ) {
          this.save();
        } else {
          this.uncheckLogistics(certificateGroups);
        }
      }

      uncheckLogistics(certificateGroups: any) {
        this.$scope.$parent.$parent.$broadcast(
          'logistics__uncheck-bad',
          Object.keys(certificateGroups),
        );
      }

      save() {
        let chain = this.$q.when();
        const filteredFiles = this.getFilteredFiles();
        filteredFiles.forEach((file) => {
          chain = chain.then(
            () =>
              this.DocumentsService.attachDocumentToLogistics({
                content_type: this.contentTypeId,
                title: file.title,
                file: file.file,
                logistics: this.perpareLogistics(file.rawLogistics, file),
              }),
            (err: any) => this.error(err),
          );
        });
        return chain.then(
          () => this.success(),
          (err: any) => this.error(err),
        );
      }

      success() {
        this.GtUtils.notify(this.gettext('Documents saved'));
        this.getFilteredFiles().forEach((item) => this.deleteItem(item));
        this.gtFilterService.updateQueryParams({}, this.filterLevel);
      }

      error(err: any) {
        this.GtUtils.errorClb(err);
      }

      getFilteredFiles() {
        return this.getFlatFilesList().filter((file) => file.isSelected && file.rawLogistics);
      }

      perpareLogistics(rawLogistics: any, file: any) {
        return rawLogistics.map((rawLogistic: any) => {
          return {
            logistic: rawLogistic.id,
            certificate_type: file.certificate_type,
            certificate_date: file.certificate_date,
          };
        });
      }
    },
  ],
};
