import React from 'react';

import { CloseIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';

export const CancelEditButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => {
  return (
    <Button variant="fadedPrimary" {...props}>
      <span className="flex items-center gap-1">
        <CloseIcon size={14} className="text-stroke-main-strongest" />
        <p className="font-medium text-text-main-secondary">Cancel Editing</p>
      </span>
    </Button>
  );
};
