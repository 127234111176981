import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './multicontract-list-page.html?raw';
import type { MulticontractService } from '../../multicontract.service';

export const MulticontractListPage = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    useType: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'ContractsService',
    'PageService',
    'CustomValuesService',
    'MulticontractFilterConfigService',
    'MulticontractService',
    'GtUtils',
    'gettext',
    class {
      ContractsService: ContractsService;
      CustomValuesService: any;
      GtUtils: GtUtilsService;
      MulticontractFilterConfigService: any;
      MulticontractService: MulticontractService;
      PageService: PageService;
      filterLevel = 'multicontract-list-page';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        gtFilterService: GtFilterService,
        ContractsService: ContractsService,
        PageService: PageService,
        CustomValuesService: any,
        MulticontractFilterConfigService: any,
        MulticontractService: MulticontractService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.ContractsService = ContractsService;
        this.PageService = PageService;
        this.CustomValuesService = CustomValuesService;
        this.MulticontractFilterConfigService = MulticontractFilterConfigService;
        this.MulticontractService = MulticontractService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-prices-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Import('contracts.multicontract'),
            new this.PageService.buttons.Export('contracts.multicontract', this.filterLevel),
          ],
          pages: [],
        };
        if (this.queryParams.use_type === 'services') {
          config.pages = this.ContractsService.getMulticontractServicesPagesConfig();
        } else if (this.queryParams.stage === 'ticket') {
          config.pages = this.MulticontractService.getMutiticketsPagesConfig();
        } else {
          config.pages = this.ContractsService.getMulticontractPagesConfig();
        }

        // @ts-ignore
        config.filters = this.MulticontractFilterConfigService.getFilterConfig(
          this.filterLevel,
          this.queryParams.contract_type,
          this.queryParams.use_type,
          this.queryParams.stage,
        );

        return config;
      }
    },
  ],
};
