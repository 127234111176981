import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './stock-reserve-unit-modal.html?raw';

export const StockReserveUnitModal = {
  bindings: {
    modalInstance: '<',
    item: '<?',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    'StockReserveUnitsService',
    'StockReserveUnitFormFieldsService',
    class {
      GtUtils: GtUtilsService;
      StockReserveUnitFormFieldsService: any;
      StockReserveUnitsService: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      item: any;
      modalInstance: any;
      constructor(
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        StockReserveUnitsService: any,
        StockReserveUnitFormFieldsService: any,
      ) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.StockReserveUnitsService = StockReserveUnitsService;
        this.StockReserveUnitFormFieldsService = StockReserveUnitFormFieldsService;
        this.modalInstance = undefined;

        this.form = undefined;
        this.fields = [];
        this.item = {};
      }
      $onInit() {
        this.item = this.item || this.initNewItem();
        if (this.item.id) {
          return this.updateData();
        }
        return this.updateFields();
      }
      initNewItem() {
        return {};
      }
      updateData() {
        this.GtUtils.overlay('show');

        return this.StockReserveUnitsService.get(this.item.id)
          .then((data: any) => {
            this.item = data;
            this.updateFields();
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext('"Stock Reserve Unit" doesn\'t exist'));
            this.GtUtils.goPage('logistics.stockReserveUnits');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (
          !confirm(this.gettext('Are you sure that you want delete this "Stock Reserve Unit"?'))
        ) {
          return;
        }
        return this.StockReserveUnitsService.delete({ id: this.item.id }).then(() => {
          this.GtUtils.notify(this.gettext('"Stock Reserve Unit" deleted'));
          this.close('delete', true);
        });
      }

      save() {
        return this.StockReserveUnitsService.save(this.item)
          .then((data: any) => this.close(data, true))
          .catch((e: any) => this.GtUtils.errorClb(e))
          .finally(() => this.GtUtils.overlay('hide'));
      }

      openFieldsConfigModal() {
        return this.StockReserveUnitFormFieldsService.openFieldsConfigModal().then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        return this.StockReserveUnitFormFieldsService.getFields(this.item).then((fields: any) => {
          this.fields = fields;
        });
      }
    },
  ],
};
