import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('financePositionTable', {
    bindings: {
      financePositions: '=?',
      initQueryParams: '<?',
      isInvalid: '<?',
      model: '<',
      finance: '<',
      usesList: '<',
      updateCounterparties: '&?',
      updateInvoiceFormFields: '&?',
      updateFinanceAccounts: '<?',
      changeShowReserve: '&?',
      showReserve: '<?',
    },
    template: require('./finance-position-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    '$scope',
    'gettext',
    'GtUtils',
    'FinancesService',
    'ContractsService',
    'InvoicePositionConfigService',
    'AccountsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    ContractsService: ContractsService,
    InvoicePositionConfigService: any,
    AccountsService: any,
  ) {
    const vm = this;

    vm.useVat = false;
    vm.totalWithoutVat = 0;
    vm.totalVat = 0;
    vm.total = 0;
    vm.addPosition = addPosition;
    vm.destroy = destroy;
    vm.clone = clone;
    vm.clearContractCharge = clearContractCharge;
    vm.showContractDetails = showContractDetails;

    vm.checkFieldRequired = checkFieldRequired;
    vm.calculateAmount = calculateAmount;
    vm.changedAmount = changedAmount;
    vm.setHovering = setHovering;
    vm.hovering = false;
    vm.hasFinblockPermission = hasFinblockPermission;
    vm.useChangeBlocked = useChangeBlocked;
    vm.showDeleteCtrChargeWarning = showDeleteCtrChargeWarning;
    vm.showDeletePassportChargeWarning = showDeletePassportChargeWarning;
    vm.getFinanceAccounts = getFinanceAccounts;
    vm.updateReserveAmount = updateReserveAmount;
    vm.applyReserveAmount = applyReserveAmount;
    vm.setPositionReserveValues = setPositionReserveValues;
    vm.initFinancePositions = [];

    vm.$onInit = function () {
      vm.financePositions = vm.financePositions || [];
      vm.finance = vm.finance || {};
      vm.isInvalid = vm.isInvalid || false;
      vm.usesList = vm.usesList || [];
      vm.theadConfig = getTheadConfig();
      updatePositionsAmount();
      setReadOnly();
      $rootScope.$on('update-position-amount', () => {
        updatePositionsQuantity();
        updatePositionsAmount();
      });
      $scope.$watch(
        'vm.financePositions',
        () => {
          calculateVatValue();
          updateTotals();
        },
        true,
      );
      $scope.$watch('vm.useVat', clearVat);
      $scope.$watchCollection('vm.financePositions', onPositionsChange);

      if (vm.updateCounterparties) {
        vm.updateCounterparties();
      }
    };

    ////////////////

    vm.$onChanges = function (changes: any) {
      if (changes.updateFinanceAccounts) {
        vm.financePositions.forEach((position: any) => vm.getFinanceAccounts(position));
      }
    };

    function updatePositionsAmount() {
      ng.forEach(vm.financePositions, function (position: any) {
        calculateAmount(position);
      });
    }

    function updatePositionsQuantity() {
      ng.forEach(vm.financePositions, function (position: any) {
        calculatePositionQuantityTotal(position);
      });
    }

    function updateReserveAmount() {
      vm.changeShowReserve({ value: true });
      ng.forEach(vm.financePositions, (position: any) => {
        updatePositionReserveAmount(position);
      });
    }

    function applyReserveAmount() {
      ng.forEach(vm.financePositions, (position: any) => {
        setPositionReserveValues(position);
      });
    }

    function getPositionReserveAvailable(position: any) {
      const reserveAmount = position.reserve_amount || 0;
      const reserveAmountUsed = position.reserve_amount_used || 0;
      const reserveAmountUsedInit = position.reserve_amount_used_init || 0;

      return reserveAmount - reserveAmountUsed + reserveAmountUsedInit;
    }

    function updatePositionReserveAmount(position: any) {
      const bankAccountList: any = [vm.finance.bank_account || 0];
      const invoiceType = vm.finance.invoice_type;
      const clientroleFrom = vm.finance.clientrole_from;
      const clientroleTo = vm.finance.clientrole_to;
      return FinancesService.BankAccount.getBankAccountsBalance({
        id_list: bankAccountList,
        invoice_type: invoiceType,
        clientrole_from: clientroleFrom,
        clientrole_to: clientroleTo,
        use: position.use,
        crop: position.crop,
        charge: position.charge || position.reserve_charge,
        contract: position.contract,
        passport: position.passport,
      })
        .$promise.then((bankAccountList: any) => {
          position.reserve_amount = bankAccountList.reduce((sum: any, account: any) => {
            return (
              sum +
              (account.new_incoming_reserve_amount || account.new_outgoing_reserve_amount || 0)
            );
          }, 0);
          position.reserve_amount_available = getPositionReserveAvailable(position);
        })
        .catch((error: any) => {
          GtUtils.notify(error, 'error');
        });
    }

    function setPositionReserveValues(position: any) {
      position.reserve_amount_used = position.amount;
      position.reserve_amount_available = getPositionReserveAvailable(position);
      if (position.reserve_amount_available < 0) {
        position.reserve_amount_available = 0;
        position.reserve_amount_used = position.reserve_amount || position.reserve_amount_used_init;
      }
    }

    function calculatePositionQuantityTotal(position: any) {
      if (position.use === 'cargo') {
        let quantity;
        position.quantity_total = position.quantity_total || position.quantity;
        if (vm.finance.payment_conditions <= 100) {
          quantity =
            Math.round(
              ((position.quantity_total * vm.finance.payment_conditions) / 100) * 1000000,
            ) / 1000000;
        }
        position.quantity = quantity ?? position.quantity;
        position.quantity = Number(position.quantity.toFixed(6));
      }
    }

    function calculateVatValue() {
      ng.forEach(vm.financePositions, function (position: any) {
        if (
          position.vat_option &&
          position.price_per_piece &&
          position.quantity &&
          position.vat_value
        ) {
          GtUtils.applyVat(
            position.price_per_piece,
            position.quantity,
            position.vat_value,
            'vat',
          ).then(function (response: any) {
            position.calculated_vat_value = Math.round(response.data.value * 1000) / 1000;
          });
          GtUtils.applyVat(position.price_per_piece, 1, position.vat_value, 'without').then(
            function (response: any) {
              position.price_per_piece_without_vat = Math.round(response.data.value * 1000) / 1000;
            },
          );
        } else {
          position.price_per_piece_without_vat = position.price_per_piece;
          position.calculated_vat_value = 0;
        }
      });
    }

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function onPositionsChange() {
      vm.initFinancePositions = vm.financePositions
        .filter((p: any) => p.id !== undefined)
        .map(function (position: any) {
          return {
            id: position.id,
            contract: position.contract,
            passport: position.passport,
            use: position.use,
          };
        });
      setReadOnly();
    }

    function setReadOnly() {
      ng.forEach(vm.financePositions, function (position: any) {
        position.read_only = position.read_only || positionDisabled(position);
      });
    }

    function addPosition() {
      const newPosition = {
        quantity: 0,
        quantity_total: 0,
        price_per_piece: 0,
        vat: 0,
        ...vm.initQueryParams,
      };
      vm.financePositions.push(newPosition);
    }

    function destroy(financePosition: any) {
      if (!confirm(gettext('Are you sure that you want delete Position?'))) {
        return;
      }
      if (financePosition.id) {
        FinancesService[vm.model].delete({ id: financePosition.id });
      }
      vm.financePositions.splice(vm.financePositions.indexOf(financePosition), 1);
    }

    function clone(financePosition: any) {
      const newPosition = { ...financePosition, _charge_id: financePosition.charge };
      delete newPosition.id;
      delete newPosition.$$hashKey;
      delete newPosition.charge;
      delete newPosition.contractcharge;
      vm.financePositions.push(newPosition);
    }

    function changedAmount(position: any) {
      calculateAmount(position);
    }

    function calculateAmount(position: any) {
      if (position?.read_only) {
        return;
      }

      if (
        position.vat_option &&
        position.price_per_piece &&
        position.quantity &&
        position.vat_value
      ) {
        GtUtils.applyVat(
          position.price_per_piece,
          position.quantity,
          position.vat_value,
          'with',
        ).then(
          function (response: any) {
            const calculatedPercent = position.disbursement_bl_volume
              ? (position.quantity * 100) / position.disbursement_bl_volume / 100
              : 1;
            const amountCorrectionWithPercentage =
              Math.round((position.amount_correction || 0) * calculatedPercent * 100) / 100;
            position.amount =
              Math.round((response.data.value + amountCorrectionWithPercentage) * 100) / 100;
            if (!position.quantity_total) {
              return;
            }
            GtUtils.applyVat(
              position.price_per_piece,
              position.quantity_total,
              position.vat_value,
              'with',
            ).then(
              function (response: any) {
                position.amount_total = response.data.value + amountCorrectionWithPercentage;
              },
              function (response: any) {
                GtUtils.notify(response.error, 'error');
              },
            );
          },
          function (response: any) {
            GtUtils.notify(response.error, 'error');
          },
        );
      } else {
        const calculatedPercent = position.disbursement_bl_volume
          ? (position.quantity * 100) / position.disbursement_bl_volume / 100
          : 1;
        const amount = position.quantity * position.price_per_piece;
        const amountCorrectionWithPercentage =
          Math.round((position.amount_correction || 0) * calculatedPercent * 100) / 100;
        position.amount = Math.round((amount + amountCorrectionWithPercentage) * 100) / 100;

        const amountTotal =
          Math.round(
            (position.quantity_total || position.quantity) * position.price_per_piece * 100,
          ) / 100;
        position.amount_total =
          Math.round((amountTotal + amountCorrectionWithPercentage) * 100) / 100;
      }
    }

    function updateTotals() {
      let totalQuantityTotalAmount = 0;
      let totalQuantityTotalAmountWithoutVat = 0;
      let totalQuantityTotal = 0;
      let totalVolume = 0;
      let quantityTotalAmountVAT = 0;

      vm.totalWithoutVat = 0;
      vm.totalVat = 0;
      vm.total = 0;

      ng.forEach(vm.financePositions, function (financePosition: any) {
        let amount = financePosition.amount;
        let pricePerPieceWithoutVat = financePosition.price_per_piece_without_vat;

        if (
          (vm.finance.invoice_type === 'outgoing' &&
            ['costs', 'expenses'].includes(financePosition.use)) ||
          (vm.finance.invoice_type === 'incoming' && ['gains'].includes(financePosition.use))
        ) {
          amount = -amount;
          pricePerPieceWithoutVat = -pricePerPieceWithoutVat;
        }

        vm.total += amount;
        vm.totalWithoutVat += pricePerPieceWithoutVat * financePosition.quantity;
        vm.totalVat += financePosition.calculated_vat_value || 0;
        quantityTotalAmountVAT +=
          (financePosition.calculated_vat_value / financePosition.quantity) *
          (financePosition.quantity_total || financePosition.quantity);

        if (financePosition.use === 'cargo') {
          totalQuantityTotal += financePosition.quantity_total || financePosition.quantity;
          totalQuantityTotalAmount += financePosition.amount_total || financePosition.amount;
          totalQuantityTotalAmountWithoutVat +=
            pricePerPieceWithoutVat * (financePosition.quantity_total || financePosition.quantity);
          totalVolume += financePosition.quantity;
        }
      });

      vm.total = Math.round(vm.total * 100) / 100;
      vm.totalWithoutVat = Math.round(vm.totalWithoutVat * 100) / 100;

      if (vm.total) {
        $scope.$emit('finance-position-table__change-full-amount', {
          newFullAmount: vm.total,
          fullAmountWithoutVat: vm.totalWithoutVat,
          totalVat: vm.totalVat,
          totalQuantityTotalAmount: Math.round(totalQuantityTotalAmount * 100) / 100,
          quantityTotalAmountWithoutVat: Math.round(totalQuantityTotalAmountWithoutVat * 100) / 100,
          quantityTotalAmountVAT: Math.round(quantityTotalAmountVAT * 100) / 100,
          totalQuantityTotal: Math.round(totalQuantityTotal * 100) / 100,
          totalVolume: totalVolume,
        });
      }
    }

    function clearVat() {
      if (!vm.useVat) {
        vm.totalVat = 0;
        ng.forEach(vm.financePositions, function (financePosition: any) {
          financePosition.vat = 0;
        });
      }
    }

    function clearContractCharge(position: any) {
      position._charge_id = position.charge;
    }

    function showContractDetails(position: any) {
      return ContractsService.Contract.predictionsDetails({
        id: position.contract,
        full_title: true,
      }).$promise.then((data: any) => (position.contractDetails = data));
    }

    function checkFieldRequired(position: any, field: any) {
      return FinancesService.getRequiredFields(position).includes(field);
    }

    function positionDisabled(position: any) {
      if (!position.id || !position.contract) {
        return false;
      }

      return (
        !$rootScope.user.settings.INVOICE_POSITION_DISABLE_FOR_EXECUTED_CONTRACTS &&
        ['executed', 'cancelled'].includes(position.contract_status)
      );
    }

    function showDeleteCtrChargeWarning(position: any) {
      const initPosition = vm.initFinancePositions.find((p: any) => p.id === position.id);
      if (!initPosition) {
        return;
      }

      return (
        ['costs', 'gains'].includes(initPosition.use) && initPosition.contract !== position.contract
      );
    }

    function showDeletePassportChargeWarning(position: any) {
      const initPosition = vm.initFinancePositions.find((p: any) => p.id === position.id);
      if (!initPosition) {
        return;
      }

      return (
        ['costs', 'gains'].includes(initPosition.use) && initPosition.passport !== position.passport
      );
    }

    function useChangeBlocked(position: any) {
      const initPosition = vm.initFinancePositions.find((p: any) => p.id === position.id);
      if (!initPosition) {
        return;
      }
      return ['costs', 'gains'].includes(initPosition.use);
    }

    function getFinanceAccounts(position: any) {
      if (!position.use) {
        return;
      }
      position.currency = vm.finance.currency;
      position.invoice_type = vm.finance.invoice_type;
      position.charge = position._charge_id;
      if (position.contractcharge) {
        return ContractsService.ContractCharge.get({ id: position.contractcharge })
          .$promise.then((data: any) => (position.charge = data.charge))
          .then(() => {
            InvoicePositionConfigService.getFinanceAccounts(position).then(function (data: any) {
              position.debit_account = position.debit_account || data.debit_account;
              position.credit_account = position.credit_account || data.credit_account;
            });
          });
      } else {
        InvoicePositionConfigService.getFinanceAccounts(position).then(function (data: any) {
          position.debit_account = position.debit_account || data.debit_account;
          position.credit_account = position.credit_account || data.credit_account;
        });
      }
    }

    function hasFinblockPermission(position: any) {
      if (!['costs', 'gains'].includes(position.use)) {
        return true;
      }
      return !vm.finance.id || AccountsService.hasPerm('configurate_finblock_entries');
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] as any[] };

      config.columns = [
        {
          title: gettext('Position'),
          columnName: 'note',
        },
        {
          title: gettext('Allocation'),
          columnName: 'contract',
        },
        {
          title: gettext('Quantity'),
          columnName: 'quantity',
        },
        {
          title: gettext('Price'),
          columnName: 'price',
        },
        {
          title: gettext('Amount'),
          columnName: 'amount',
        },
      ];
      return config;
    }
  }
})();
