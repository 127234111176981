import React from 'react';

import { cn } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import { BucketIcon, ThreeDotsIcon, useIcon } from '~/shared/ui/icons';
import { CommandItem } from '~/shared/ui/kit/command';
import {
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { Preset } from '../../types';

const ListItem: React.FC<{
  selectedPresetId: Preset['id'];
  item: Preset;
  onSelect: (preset: Preset) => void;
  onDelete: (preset: Preset) => void;
}> = ({ item, selectedPresetId, onSelect, onDelete }) => {
  const Icon = useIcon(item.icon);

  return (
    <CommandItem
      key={item.id}
      className={cn(
        'flex cursor-pointer items-center justify-between px-2 py-0.5 transition duration-300 ease-in-out hover:bg-transparent-light',
        selectedPresetId === item.id && 'bg-transparent-light',
      )}
      onSelect={() => onSelect(item)}
    >
      <div className="flex items-center gap-2">
        <Icon size={14} className="text-text-main-secondary" />
        <p className="text-xs text-text-main-primary">{item.title}</p>
      </div>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger displayChevron={false}>
          <ThreeDotsIcon size={14} className="text-text-main-secondary" />
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent className="p-1" sideOffset={14}>
          <button className="flex items-center gap-2 rounded-sm p-1" onClick={() => onDelete(item)}>
            <BucketIcon size={14} className="text-text-additional-danger" />
            <p className="text-xs text-text-additional-danger">Delete Preset</p>
          </button>
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </CommandItem>
  );
};

export const PresetListContent: React.FC<{
  selectedPresetId: Preset['id'];
  presetOptions: Preset[];
  onSelect: (preset: Preset) => void;
  onDelete: (preset: Preset) => void;
}> = ({ presetOptions, selectedPresetId, onSelect, onDelete }) => {
  return (
    <DropdownMenuContent className="p-0">
      <SearchCommandList
        options={presetOptions.map((item) => (
          <ListItem
            key={item.id}
            selectedPresetId={selectedPresetId}
            item={item}
            onSelect={onSelect}
            onDelete={onDelete}
          />
        ))}
      />
    </DropdownMenuContent>
  );
};
