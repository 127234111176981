import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('requestsPricesContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./requests-prices-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$window',
    'gtFilterService',
    'GtUtils',
    'gettext',
    'ContractsService',
    'FinancesService',
    'ClientsService',
    'CoreService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $window: ng.IWindowService,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    ClientsService: any,
    CoreService: CoreService,
  ) {
    const vm = this;
    vm.view = vm.view || 'table';
    vm.filterLevel = vm.filterLevel || 'requests-prices-container';
    vm.results = [];

    vm.tableElevatorsOptions = getTableElevatorsOptions();
    vm.tableElevatorsApi = undefined;

    vm.fields = getFields();
    vm.calculator = {};
    vm.updatePrices = updatePrices;
    vm.changeCurrency = changeCurrency;
    vm.updatePresets = updatePresets;
    vm.applySelected = applySelected;
    vm.savePreset = savePreset;
    vm.removePreset = removePreset;
    vm.clearData = clearData;
    vm.db_convert = dbConvert;
    vm.form = undefined;
    vm.currency = 'usd';
    vm.currency_exchange = undefined;
    vm.page_size = 40;
    vm.newPresetParams = {};
    vm.presets = [];

    vm.$onInit = function () {
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updatePrices();
      });
      updatePrices();
      updatePresets();
    };

    ////////////////

    function updatePresets() {
      return CoreService.QueryParamsSet.query(
        { filter_level: vm.filterLevel },
        function (data: any) {
          vm.presets = data.results;
        },
      ).$promise;
    }

    function applySelected() {
      if (vm.newPresetParams) {
        vm.calculator = JSON.parse(vm.newPresetParams.query_params);
      }
    }

    function savePreset() {
      vm.newPresetParams.filter_level = vm.filterLevel;
      vm.newPresetParams.query_params = JSON.stringify(vm.calculator);

      if (vm.newPresetParams.id) {
        return CoreService.QueryParamsSet.update(vm.newPresetParams, function () {
          updatePresets();
          GtUtils.notify(gettext('Preset Set Updated'), 'info');
        });
      }

      CoreService.QueryParamsSet.save(vm.newPresetParams, function () {
        updatePresets();
        GtUtils.notify(gettext('Preset Set Saved'), 'info');
      });
    }

    function clearData() {
      vm.calculator = {};
      vm.newPresetParams = {};
    }

    function removePreset() {
      return CoreService.QueryParamsSet.delete({ id: vm.newPresetParams.id }, function () {
        clearData();
        updatePresets();
        GtUtils.notify(gettext('Preset Set removed'), 'info');
      }).$promise;
    }

    function updatePrices() {
      GtUtils.overlay('show');
      if (ng.equals(vm.calculator, {})) {
        FinancesService.CurrencyExchange.query(
          {
            currency: GtUtils.getLocalCurrency(),
          },
          function (data: any) {
            vm.calculator.currency_exchange_rate = data.count && data.results[0].rate * 1;
          },
        );
        GtUtils.overlay('hide');
        return;
      }

      return ContractsService.Request.pricesOffer(
        {
          calculator: vm.calculator,
          page_size: vm.page_size,
        },
        function (data: any) {
          vm.tableElevatorsApi.setRowData(data.results.elevators);
          GtUtils.overlay('hide');
        },
      );
    }

    function changeCurrency(currency: any) {
      vm.currency = currency;
      vm.tableElevatorsOptions.templateArgs.currency = currency.toUpperCase();
    }

    function dbConvert(usd: any, local: any) {
      if (vm.currency == 'usd') {
        return usd;
      } else {
        return local;
      }
    }

    function getTableElevatorsOptions() {
      return {
        tableName: 'elevators_prices',
        templateArgs: {
          db_convert: dbConvert,
          currency: vm.currency,
        },
        columnDefs: [
          {
            columnName: 'name',
            title: gettext('Elevator Name'),
            class: 'td-left-align',
          },
          {
            columnName: 'sale_price',
            title: gettext('Sale Price'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.sale_price, item.sale_price_local) | gtCurrency : args.currency }]}</span>',
          },
          {
            columnName: 'margin',
            title: gettext('Margin'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.margin, item.margin_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'purchase_price_without_vat',
            title: gettext('Purchase price without VAT'),
            class: 'td-right-align',
            cellTemplate:
              ' <span>{[{ args.db_convert(item.purchase_price_without_vat, item.purchase_price_without_vat_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'purchase_price_with_vat',
            title: gettext('Purchase price wit VAT'),
            class: 'td-right-align',
            cellTemplate:
              ' <span class="label">{[{ args.db_convert(item.purchase_price_with_vat, item.purchase_price_with_vat_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'railway_cost',
            title: gettext('Railway cost'),
            class: 'td-right-align',
            cellTemplate:
              '<span>' +
              '{[{ args.db_convert(item.railway_cost, item.railway_cost_local) || 0 | gtCurrency: args.currency }]}' +
              '<span ng-if="item.railway_vat && item.railway_vat != 0">' +
              ', <translate>VAT</translate>: ' +
              '{[{ args.db_convert(item.railway_vat, item.railway_vat_local) || 0 | gtCurrency: args.currency }]}' +
              '</span>' +
              '</span><br>' +
              '<span ng-if="item.railway_deliverer_name">' +
              '<i class="fa fa-truck"></i> {[{ item.railway_deliverer_name }]}' +
              '</span>',
          },
          {
            columnName: 'elevator_total_cost',
            title: gettext('Elevator cost'),
            class: 'td-right-align',
            cellTemplate:
              '<span ng-repeat="tariff in item.elevator_tariff_set">' +
              "{[{ $root.user.profile.language === 'en' && tariff.charge_title_eng || tariff.charge_title }]}: {[{ args.db_convert(tariff.cost, tariff.cost_local) || 0 | gtCurrency: args.currency}]}" +
              '<span ng-if="tariff.vat && tariff.vat != 0">, <translate>VAT</translate>: ' +
              '{[{ args.db_convert(tariff.vat, tariff.vat_local) || 0 | gtCurrency: args.currency}]}' +
              '</span>' +
              '<br>' +
              '</span>' +
              '<span ng-if="item.elevator_total_cost && item.elevator_total_cost != 0"><translate>Total</translate>:' +
              ' {[{ args.db_convert(item.elevator_total_cost, item.elevator_total_cost_local) || 0 | gtCurrency: args.currency }]}' +
              '<span ng-if="item.elevator_total_vat && item.elevator_total_vat != 0">, <translate>VAT</translate>: ' +
              '{[{ args.db_convert(item.elevator_total_vat, item.elevator_total_vat_local) || 0 | gtCurrency: args.currency }]}</span>' +
              '</span>',
          },
          {
            columnName: 'railway_lost',
            title: gettext('Railway losts'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.railway_lost, item.railway_lost_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'elevator_lost',
            title: gettext('Elevator losts'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.elevator_lost, item.elevator_lost_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'broker_cost',
            title: gettext('Broker cost'),
            class: 'td-right-align',
            cellTemplate:
              '<span>' +
              '{[{ args.db_convert(item.broker_cost, item.broker_cost_local) || 0 | gtCurrency: args.currency }]}' +
              '<span ng-if="item.broker_vat && item.broker_vat != 0">' +
              ', <translate>VAT</translate>: ' +
              '{[{ args.db_convert(item.broker_vat, item.broker_vat_local) || 0 | gtCurrency: args.currency }]}' +
              '</span>' +
              '</span><br>' +
              '<span ng-if="item.broker_name">' +
              '<i class="fa fa-briefcase"></i> {[{ item.broker_name }]}' +
              '</span>',
          },
          {
            columnName: 'bank_comission',
            title: gettext('Bank Comission'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.bank_comission, item.bank_comission_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'financing_value',
            title: gettext('Financing'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.financing_value, item.financing_value_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'purchase_price_with_vat_elevator',
            title: gettext('Purchase price wit VAT on Elevator'),
            class: 'td-right-align',
            cellTemplate:
              '<span class="label">{[{ args.db_convert(item.purchase_price_with_vat_elevator, item.purchase_price_with_vat_elevator_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'all_vat',
            title: gettext('VAT'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.all_vat, item.all_vat_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'profit',
            title: gettext('Profit'),
            class: 'td-right-align highlighted-td',
            cellTemplate:
              '<span class="label">{[{ args.db_convert(item.profit, item.profit_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
          {
            columnName: 'rentability',
            title: gettext('Rentability'),
            class: 'td-right-align highlighted-td',
            cellTemplate:
              '<span class="label" ng-class="{\'label_success\': item.rentability > 0, \'label_danger\': item.rentability < 0 }">{[{ item.rentability | gtDecimal:2 || 0 }]} %</span>',
          },
          {
            columnName: 'tax',
            title: gettext('Tax profit to pay'),
            class: 'td-right-align',
            cellTemplate:
              '<span>{[{ args.db_convert(item.tax, item.tax_local) || 0 | gtCurrency: args.currency }]}</span>',
          },
        ],
        rowData: [],
        onInit: function (api: any) {
          vm.tableElevatorsApi = api;
        },
      };
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };

      const col2: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };

      const col3: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };

      const col4: any = {
        className: 'form-group-container col-md-3 col-sm-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            key: 'sale_price',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Sale Price'),
              placeholder: gettext('Sale Price'),
              type: 'number',
              required: true,
              addon: gettext('per 1 mt'),
            },
          },
          {
            key: 'sale_currency',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Currency'),
              placeholder: gettext('USD, UAH'),
              resource: 'finances.Currency',
              addIcon: GtUtils.getIcon('finances.CurrencyExchange'),
            },
          },
          {
            key: 'currency_exchange_rate',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Currency Rate'),
              placeholder: gettext('Currency Rate'),
              type: 'number',
              required: true,
            },
            validation: {
              show: true,
            },
            validators: {
              notFalse: function ($viewValue: any, $modelValue: any) {
                return ($viewValue || $modelValue || 0) > 0;
              },
            },
          },
          {
            key: 'margin',
            type: 'gt-input',
            defaultValue: 2,
            templateOptions: {
              label: gettext('Margin'),
              placeholder: gettext('Margin'),
              type: 'number',
              required: true,
            },
          },
        ],
      });

      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            key: 'cargo',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('COMMODITY'),
              placeholder: gettext('Choose commodity'),
              resource: 'crops.Crop',
              required: true,
              addButtonUrl: '/admin/crops/crop/add/',
              addPerms: ['add_crop'],
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'port',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Port'),
              resource: 'logistics.port',
              required: true,
              addFunc: () => {
                $window.open('/admin/logistics/port/add/', '_blank');
              },
              addIcon: GtUtils.getIcon('logistics.Port'),
              addPerms: ['add_port'],
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'deliverers',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('Forwarder railway'),
              resource: 'clients.Deliverer',
              addFunc: () => {
                return ClientsService.roleModal({ model_name: 'Deliverer' });
              },
              addIcon: GtUtils.getIcon('clients.Deliverer'),
              addPerms: ['add_deliverer'],
            },
          },
          {
            key: 'elevators',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('Elevators'),
              resource: 'clients.Elevator',
            },
          },
        ],
      });

      col3.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            key: 'normal_loss_rw_rate',
            type: 'gt-input',
            defaultValue: 0.5,
            templateOptions: {
              label: gettext('The normal loss rate of RW'),
              placeholder: gettext('The normal loss rate of RW'),
              type: 'number',
              required: true,
              addon: gettext('per 1 mt'),
            },
          },
          {
            key: 'normal_loss_elevator_rate',
            type: 'gt-input',
            defaultValue: 0.5,
            templateOptions: {
              label: gettext('The normal loss rate of Elevator'),
              placeholder: gettext('The normal loss rate of Elevator'),
              type: 'number',
              required: true,
              addon: gettext('per 1 mt'),
            },
          },
          {
            key: 'storage_days',
            type: 'gt-input',
            defaultValue: 30,
            templateOptions: {
              label: gettext('Storage days'),
              type: 'number',
            },
          },
          {
            key: 'finance_days',
            type: 'gt-input',
            defaultValue: 30,
            templateOptions: {
              label: gettext('Finance days'),
              type: 'number',
            },
          },
          {
            key: 'year_percent',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Year %'),
              type: 'number',
            },
          },
        ],
      });

      col4.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            key: 'bank_commission_rate',
            type: 'gt-input',
            defaultValue: 0.1,
            templateOptions: {
              label: gettext('The commission of bank'),
              placeholder: gettext('The commission of bank'),
              type: 'number',
            },
          },
          {
            key: 'broker_use',
            defaultValue: false,
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Calculate broker cost'),
              hint: gettext('Check this if you want to use broker tariff in price calculate'),
            },
          },
          {
            key: 'tax_profit_to_pay',
            defaultValue: false,
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Calculate tax profit to pay'),
              hint: gettext('Check this if you want to use tax profit to pay in price calculate'),
            },
          },
        ],
      });

      return [col1, col2, col3, col4];
    }
  }
})();
