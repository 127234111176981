import ng from 'angular';

(function () {
  'use strict';
  ng.module('company.legacy').factory('TeamService', Service);
  Service.$inject = ['$resource'];

  function Service($resource: ng.resource.IResourceService) {
    const TraderResource = $resource(
      '/api/team/traders/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
      },
    );

    const _TradersResource = $resource(
      '/consolidated/info/',
      {
        info_type: 'Traders',
      },
      {
        query: { method: 'GET', isArray: false },
      },
    );

    return {
      getTrader: getTrader,
      getTeam: getTeam,
      Trader: TraderResource,
    };

    ////////////////

    function getTrader(id: any) {
      return _TradersResource.query({
        info_type: 'Traders',
        object_id: id,
      }).$promise;
    }

    function getTeam() {
      return _TradersResource.query({
        info_type: 'Traders',
      }).$promise;
    }
  }
})();
