import ng from 'angular';

(function () {
  'use strict';
  ng.module('company.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('gt.page.trader', {
        url: '/team/trader/:id?tab',
        controller: 'TraderTeamController',
        controllerAs: 'vm',
        template: require('./trader/trader.tpl.html?raw'),
        reloadOnSearch: false,
        data: {
          permissions: {
            only: 'view_userdetail',
          },
        },
      })
      .state('gt.team', {
        url: '/team',
        component: 'teamPageView',
        data: {
          pageTitle: gettext('Team'),
          permissions: {
            only: 'view_user',
          },
        },
      });
  }
})();
