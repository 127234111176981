import React from 'react';

import { LimitAccess, Properties } from '../view-configurator/data';
import type { ListView, ReorderedColumn, ViewConfigChanged } from '../view-configurator/types';

export const ListViewSettingsContent: React.FC<{
  view: ListView;
  viewConfigChanged: ViewConfigChanged;
  columns: ReorderedColumn[];
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({ view, columns, viewConfigChanged, columnsReordered, columnVisibilityChanged }) => {
  return (
    <>
      <Properties
        columns={columns}
        columnsReordered={columnsReordered}
        columnVisibilityChanged={columnVisibilityChanged}
      />
      <LimitAccess
        isLimitAccess={view.data.isLimitAccess}
        limitAccessChanged={() =>
          viewConfigChanged(view.id, { data: { isLimitAccess: !view.data.isLimitAccess } })
        }
      />
    </>
  );
};
