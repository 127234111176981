import template from './person-list-block-view.html?raw';

export const PersonListBlockView = {
  bindings: {
    newPerson: '<?',
    quickAdd: '<?',
    persons: '<',
    count: '<',
    applyFilters: '&',
    openPersonModal: '&',
    clonePerson: '&',
    createUser: '&',
    openEmailModal: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      gettext: ng.gettext.gettextFunction;
      newPerson: any;
      quickAdd: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;

        this.newPerson = {};
        this.quickAdd = false;
      }
    },
  ],
};
