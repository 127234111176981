import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { QueryParams } from '~/app/core/types';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';
import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('core.legacy').component('queryParamsSetWidget', {
    bindings: {
      filterLevel: '<',
      applyFilters: '&?',
    },
    template: require('./queryparamsset-widget.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$q',
    '$filter',
    'GtUtils',
    'CoreService',
    'gtFilterService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $q: ng.IQService,
    $filter: ng.IFilterService,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.queryParamsSet = refreshQueryParamsSet();
    vm.queryParamsSets = [];
    vm.save = save;
    vm.remove = remove;
    vm.updateQueryParamsSets = updateQueryParamsSets;
    vm.refreshQueryParamsSet = refreshQueryParamsSet;
    vm.applySelected = applySelected;
    vm.reorderParamsList = reorderParamsList;

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        refreshQueryParamsSet(data);
      });
      updateQueryParamsSets();
      refreshQueryParamsSet();
    };

    ////////////////

    function updateQueryParamsSets() {
      return CoreService.QueryParamsSet.query(
        { filter_level: vm.filterLevel },
        function (data: any) {
          vm.queryParamsSets = data.results;
          vm.queryParamsSets = $filter('orderBy')(vm.queryParamsSets, 'index');
          vm.queryParamsSets.map((column: any, index: any) => (column.index = index));
        },
      ).$promise;
    }

    function refreshQueryParamsSet(queryParams?: QueryParams) {
      if (!queryParams) {
        queryParams = gtFilterService.getQueryParams(vm.filterLevel);
      }
      const params = { ...queryParams };
      delete params.page_size;
      delete params.serializer;
      vm.queryParamsSet = vm.queryParamsSet || {};
      vm.queryParamsSet.query_params = JSON.stringify(params);
      vm.queryParamsSet.filter_level = vm.filterLevel;
      return vm.queryParamsSet;
    }

    function applySelected(clear: any) {
      let params: any = {};
      if (clear) {
        vm.queryParamsSet = {};
        refreshQueryParamsSet();
      } else if (vm.queryParamsSet?.query_params) {
        params = JSON.parse(
          vm.queryParamsSets.filter((item: any) => item.id === vm.queryParamsSet.id).at(0)
            .query_params,
        );
        const queryParams = gtFilterService.getQueryParams(vm.filterLevel);
        if (queryParams.serializer) {
          params.serializer = queryParams.serializer;
        }
        if (queryParams.page_size) {
          params.page_size = queryParams.page_size;
        }
      }
      if (vm.applyFilters) {
        return vm.applyFilters({ params: params });
      }
      return gtFilterService.setQueryParams(params, vm.filterLevel);
    }

    function save() {
      const params = gtFilterService.getQueryParams(vm.filterLevel);
      if (params?.choice_key) {
        return GtUtils.notify(
          gettext('Disable previous template and clear all filter before create new.'),
          'error',
        );
      }

      if (!vm.queryParamsSet.filter_level) {
        return GtUtils.notify(gettext('There are no selected filters to save'), 'error');
      }

      if (vm.queryParamsSet.id) {
        return CoreService.QueryParamsSet.update(vm.queryParamsSet, function (data: any) {
          vm.queryParamsSet = data;
          updateQueryParamsSets();
          GtUtils.notify(gettext('Filter Set updated'), 'info');
        }).$promise;
      }
      return CoreService.QueryParamsSet.save(vm.queryParamsSet, function (data: any) {
        vm.queryParamsSet = data;
        updateQueryParamsSets();
        GtUtils.notify(gettext('Filter Set updated'), 'info');
      }).$promise;
    }

    function remove() {
      return CoreService.QueryParamsSet.delete(
        { id: vm.queryParamsSet.id },
        function () {
          vm.queryParamsSet = refreshQueryParamsSet();
          updateQueryParamsSets().then(() => applySelected(true));
          GtUtils.notify(gettext('Filter Set removed'), 'info');
        },
        (error: any) => GtUtils.errorClb(error),
      ).$promise;
    }

    function reorderParamsList(position: any, newPosition: any) {
      const item = vm.queryParamsSets[position];
      vm.queryParamsSets.splice(position, 1);
      vm.queryParamsSets.splice(newPosition, 0, item);
      vm.queryParamsSets.map((i: any, idx: any) => (i.index = idx));
      updateQueryParamsSetList();
    }

    function updateQueryParamsSetList() {
      GtUtils.overlay('show');
      let chain = $q.when();
      vm.queryParamsSets.forEach((item: any) => {
        chain = chain.then(
          () => CoreService.QueryParamsSet.update(item),
          (error: any) => GtUtils.errorClb(error),
        );
      });
      chain.then(
        () => {
          GtUtils.overlay('hide');
          updateQueryParamsSets();
        },
        (error: any) => GtUtils.errorClb(error),
      );
    }
  }
})();
