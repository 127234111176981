export const AutoScrollOnHover: any = [
  '$timeout',
  '$document',
  ($document: any) => {
    return {
      restrict: 'A',
      link(_$scope: ng.IScope, $element: ng.IAugmentedJQuery) {
        const containerSelector = 'table-container';

        function scrollContainerToRight() {
          const container = $document[0].getElementById(containerSelector);
          if (!container) {
            return;
          }

          const scrollWidth = container.scrollWidth;
          const containerWidth = container.clientWidth;
          const scrollDistance = scrollWidth - containerWidth;

          if (scrollWidth > containerWidth) {
            container.scrollBy({
              top: 0,
              left: scrollDistance,
              behavior: 'smooth',
            });
          }
        }

        $element.on('mouseenter', () => setTimeout(scrollContainerToRight, 0));
      },
    };
  },
];
