import React from 'react';

import { ChevronRightIcon } from '~/shared/ui/icons';

export const Trigger = ({
  from,
  to,
  recordsTotal,
}: {
  from: number;
  to: number;
  recordsTotal: number;
}) => (
  <p className="flex items-center text-xs">
    <span className="text-black mr-1 font-bold">
      {from} - {to}
    </span>
    <span className="mr-1 text-xs text-gray-35">of</span>
    <span className="font-semibold text-text-main-primary">{recordsTotal} </span>
    <ChevronRightIcon size={16} color="grey" className="mr-1 rotate-90" />
  </p>
);
