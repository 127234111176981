import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { ProjectsStatusReportListContainer } from './projects-status-report-list-container/projects-status-report-list-container.component';
import { ProjectsStatusReportListTableView } from './projects-status-report-list-table-view/projects-status-report-list-table-view.component';
import { ProjectsStatusReportPageView } from './projects-status-report-page-view/projects-status-report-page-view.component';
import { ProjectsStatusReportService } from './projects-status-report.service';

const projectsStatusReportConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state('reports.budgets.projectsStatusReport', {
      url: '/projects-status-report',
      component: 'projectsStatusReportPageView',
      resolve: {
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('projects-status-report-page-view');
          },
        ],
      },
    });
  },
];

export const projectsStatusReport = ng
  .module('reports.projectsStatusReport', [])
  .component('projectsStatusReportPageView', ProjectsStatusReportPageView)
  .component('projectsStatusReportListContainer', ProjectsStatusReportListContainer)
  .component('projectsStatusReportListTableView', ProjectsStatusReportListTableView)
  .service('ProjectsStatusReportService', ProjectsStatusReportService)
  .config(projectsStatusReportConfig).name;
