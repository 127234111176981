import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';
export class DriversService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  DriversResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    this.$uibModal = $uibModal;
    this.$resource = $resource;
    this.gettext = gettext;
    this.DriversResource = $resource(
      '/api/logistics/drivers/:id/',
      {
        id: '@id',
      },
      {
        save: { method: 'POST' },
        update: { method: 'PUT' },
        delete: { method: 'DELETE' },
      },
    );
  }

  saveDriver(driver: any) {
    return driver.id
      ? this.DriversResource.update({ id: driver.id }, driver).$promise
      : this.DriversResource.save(driver).$promise;
  }

  deleteDriver(driverId: any) {
    return this.DriversResource.delete({ id: driverId }).$promise;
  }

  getPageConfig() {
    return [
      {
        title: this.gettext('Drivers'),
        permissions: [],
        state: 'drivers.list',
        icon: 'fa-drivers-license',
      },
    ];
  }

  getDriver(driver: any) {
    return this.DriversResource.get({ id: driver }).$promise;
  }

  getFields(formName: any) {
    const fieldsDef: any = [
      {
        className: 'form-group-container col-xs-12 col-sm-3',
        fieldGroup: [
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: this.gettext('MAIN'),
            },
            fieldGroup: [
              {
                key: 'first_name',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('First name'),
                  type: 'text',
                },
              },
              {
                key: 'last_name',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Last name'),
                  type: 'text',
                },
              },
              {
                key: 'middle_name',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Middle name'),
                  type: 'text',
                },
              },
              {
                key: 'business_unit',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Business unit'),
                  placeholder: this.gettext('Business Unit'),
                  resource: 'core.BusinessUnit',
                },
              },
              {
                key: 'ukrainian_id',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Driver passport seria & number (Ukrainian)'),
                  type: 'text',
                },
              },
              {
                key: 'passport_number',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Driver passport seria & number'),
                  type: 'text',
                },
              },
              {
                key: 'license_expire_date',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('License expiration date'),
                  placeholder: this.gettext('License expiration date'),
                  useWatch: true,
                },
              },
              {
                key: 'passport_expire_date',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('Passport expiration date'),
                  placeholder: this.gettext('Passport expiration date'),
                  useWatch: true,
                },
              },
            ],
          },
        ],
      },
    ];
    return { fieldsDef, formName };
  }

  openDriverModal(driver: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: '<driver-modal driver="driver" modal-instance="$uibModalInstance"></driver-modal>',
      controller: [
        '$scope',
        'driver',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          driver: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).driver = driver;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      size: 'sm',
      windowClass: 'modal-driver',
      appendTo: getModalRoot(),
      resolve: {
        driver: () => driver,
      },
    }).result;
  }
}
DriversService.$inject = ['$resource', 'gettext', '$uibModal'];
