import ng from 'angular';

import { passportDetails } from './components/details/passport-details.module';
import { factIntermediateList } from './components/fact-intermediate-list/fact-intermediate-list.module';
import { FactPlList } from './components/fact-pl-list/fact-pl-list.component';
import { FactPlListService } from './components/fact-pl-list/fact-pl-list.service';
import { MarginControl } from './components/margin-control/margin-control.component';
import { passportsLegacyModule } from './legacy';
import { PassportFilterConfigService } from './passport-filter-config.service';
import { PassportFormFieldsService } from './passport-form-fields.service';

export const passports = ng
  .module('deals.passports', [passportsLegacyModule, passportDetails, factIntermediateList])
  .component('marginControl', MarginControl)
  .component('factPlList', FactPlList)
  .service('PassportFilterConfigService', PassportFilterConfigService)
  .service('FactPlListService', FactPlListService)
  .service('PassportFormFieldsService', PassportFormFieldsService).name;
