import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './request-details-item-view.html?raw';

export const requestDetailsItemView = {
  bindings: {
    request: '<',
    tab: '<',
    fields: '<',
    goBackButton: '<',
    updateRequest: '&?',
    openRequestModal: '&?',
    openRequestModalQuick: '&?',
    openRoleModal: '&?',
    openRoleDetails: '&?',
    updateQualities: '&?',
    openQualitiesModal: '&?',
    updateFields: '&?',
    getFormConfig: '&?',
    createContract: '&?',
    openEmailModal: '&?',
    openDocxModal: '&?',
    createTicket: '&?',
    createChildTicket: '&?',
    clone: '&?',
    reverse: '&?',
    approve: '&?',
    cancel: '&?',
    createPassport: '&?',
    connectToPassport: '&?',
    logEntries: '<',
    logEntriesCount: '<',
    additionalAgreementsFields: '<?',
    saveFunction: '&',
    hideWizard: '<?',
    changeTab: '&?',
  },
  template,
  controller: [
    '$rootScope',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      approve: any;
      cancel: any;
      changeTab: any;
      checkEditPossibility: any;
      checkTicketCreatePossibility: any;
      clone: any;
      createChildTicket: any;
      createContract: any;
      createPassport: any;
      createTicket: any;
      fields: any;
      getFormConfig: any;
      goBackButton: any;
      hideWizard: any;
      isTicketDisabledForPassportCreate: any;
      logEntries: any;
      logEntriesCount: number;
      openDocxModal: any;
      openEmailModal: any;
      openQualitiesModal: any;
      openRequestModal: any;
      openRequestModalQuick: any;
      openRoleModal: any;
      request: any;
      reverse: any;
      saveFunction: any;
      showDetail: any;
      tab: any;
      updateFields: any;
      updateQualities: any;
      updateRequest: any;
      constructor($rootScope: GtRootScopeService, ContractsService: ContractsService) {
        this.$rootScope = $rootScope;
        this.request = {};
        this.tab = '';
        this.updateRequest;
        this.openRequestModal;
        this.openRequestModalQuick;
        this.openRoleModal;
        this.updateQualities;
        this.openQualitiesModal;
        this.updateFields;
        this.getFormConfig;
        this.createContract;
        this.openEmailModal;
        this.openDocxModal;
        this.createTicket;
        this.createChildTicket;
        this.clone;
        this.reverse;
        this.approve;
        this.cancel;
        this.createPassport;
        this.fields = [];
        this.goBackButton = {};
        this.logEntries = [];
        this.logEntriesCount = 0;
        this.hideWizard;
        this.saveFunction;
        this.changeTab;

        this.checkTicketCreatePossibility = () =>
          ContractsService.checkTicketCreatePossibility(this.request);
        this.checkEditPossibility = () => {
          const restrictedStatuses: any = ['executed', 'expired'];

          if ($rootScope.user.settings.TICKET_DENY_EDIT_AFTER_APPROVE) {
            restrictedStatuses.push('approved');
          }

          return ContractsService.checkEditPossibility(this.request, restrictedStatuses);
        };

        if (this.$rootScope.user.profile.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
        this.isTicketDisabledForPassportCreate = () =>
          ContractsService.isTicketDisabledForPassportCreate(this.request);
      }

      save() {
        this.saveFunction();
      }

      getTelegramConfigUrl() {
        return `/admin/notifications/telegrambotconfigset/?indicator=${this.request.id}`;
      }

      capitalize(text: any) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
  ],
};
