import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './request-details-item-container.html?raw';
import type { RequestFormFieldsService } from '../../request-form-field.service';

export const requestDetailsItemContainer = {
  bindings: {
    request: '<',
    filterLevel: '<',
    updateConfigWithFilterLevel: '&?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    'FormFieldParamsService',
    'RequestFormFieldsService',
    'ContractsService',
    'GtUtils',
    'AccountsService',
    'CropsService',
    'ClientsService',
    'DocumentsService',
    'gtFilterService',
    'gettext',
    'moment',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      AccountsService: any;
      ClientsService: any;
      ContractsService: ContractsService;
      CropsService: any;
      DocumentsService: any;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      RequestFormFieldsService: RequestFormFieldsService;
      activeTabs: any;
      additionalAgreementsFields: any;
      contractChargesFilterLevel: string;
      cropQualities: any;
      fields: any;
      filterLevel = 'request-list-page';
      form: any;
      gettext: ng.gettext.gettextFunction;
      goBackButton: any;
      gtFilterService: GtFilterService;
      hideWizard: any;
      logEntries: any;
      logEntriesCount = 0;
      moment: any;
      passportConnectParams: any;
      passportConnectShow: any;
      request: any;
      tab: any;
      updateConfigWithFilterLevel: any;
      updating: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $state: ng.ui.IStateService,
        $stateParams: ng.ui.IStateParamsService,
        FormFieldParamsService: FormFieldParamsService,
        RequestFormFieldsService: RequestFormFieldsService,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        AccountsService: any,
        CropsService: any,
        ClientsService: any,
        DocumentsService: any,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
        moment: any,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.FormFieldParamsService = FormFieldParamsService;
        this.RequestFormFieldsService = RequestFormFieldsService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;
        this.CropsService = CropsService;
        this.ClientsService = ClientsService;
        this.DocumentsService = DocumentsService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.moment = moment;

        this.request = {};
        this.cropQualities = [];
        this.updateData;

        this.passportConnectShow = false;
        this.passportConnectParams = {};

        this.form = undefined;
        this.fields = [];
        this.additionalAgreementsFields = [];
        this.goBackButton = {};
        this.updating = true;
        this.activeTabs = [];
        this.tab = [''];
        this.logEntries = [];
        this.contractChargesFilterLevel = '';
        if (this.$rootScope.user.profile.wizard) {
          this.hideWizard = false;
        } else {
          this.hideWizard = true;
        }
      }

      $onInit() {
        this.goBackButton = {
          tip: 'Go back',
          icon: 'fa-arrow-left',
          state: 'gt.home',
        };
        this.tab = this.$stateParams.tab || ['info', 'quality'];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.updateData().then(() => {
          if (this.request.stage == 'indicator') {
            this.goBackButton.state = 'market.bids';
          } else {
            this.goBackButton.state = this.request.stage + 's.' + this.request.contract_type;
          }
          this.updating = false;
        });
      }

      updateData = () => {
        return this.ContractsService.getRequest({
          id: this.request.id,
          serializer: 'full',
        })
          .then((data: any) => {
            this.request = data;
            this.$rootScope.pageTitle =
              this.$rootScope.pageTitle + ' #' + this.request.contract_number;
            this.contractChargesFilterLevel =
              (this.request.deal_type !== 'services' && 'requests-contract-charges-list-table') ||
              'service-requests-contract-charges-list-table';
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
            this.updateConfigWithFilterLevel({
              request: this.request,
              filterLevel: this.filterLevel,
            });
            this.updateQualities();
            this.updateLogEntries();
            this.updateFields();
            this.updateAdditionalAgreementsFields();
            this.ContractsService.getRequestCalculatedAmount(this.request).then((request: any) => {
              this.request = request;
              this.GtUtils.overlay('hide');
            });
            this.updatePriceEstimated();
            this.updatePriceIndicative();
            return this.request;
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext(this.GtUtils.translate("Ticket doesn't exist")));
            this.GtUtils.overlay('hide');
            this.$state.go('tickets.sale');
          });
      };
      updatePriceEstimated() {
        this.ContractsService.Request.priceEstimated({ id: this.request.id }, (data: any) => {
          this.request.price_estimated = data.price_estimated;
        });
      }

      updatePriceIndicative() {
        this.ContractsService.Request.getPriceIndicative({ id: this.request.id }, (data: any) => {
          this.request.indicator_price = data.indicator_price;
          this.request.price_indicative = data.price_indicative;
          this.request.price_indicative_with_vat = data.price_indicative_with_vat;
          this.request.price_indicative_default_currency = data.price_indicative_default_currency;
          this.request.indicator_currency_symbol = data.indicator_currency_symbol;
        });
      }

      updateLogEntries() {
        return this.AccountsService.LogEntry.query(
          {
            object_id: this.request.id,
            content_type: this.request.content_type,
          },
          (data: any) => {
            this.logEntries = data.results;
            this.logEntriesCount = data.count;
          },
        );
      }

      updateQualities() {
        this.CropsService.CropQuality.query({ crop: this.request.cargo }, (data: any) => {
          this.cropQualities = data.results;
        });
      }

      openQualitiesModal() {
        return this.CropsService.qualitiesModal(
          this.request.qualities,
          null,
          this.request.cargo,
          this.request.id,
        ).then((data: any) => {
          if (!data || data == 'cancel') {
            return;
          }
          this.request.qualities = data;
          this.ContractsService.Request.update(this.request);
          this.updateQualities();
        });
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
            this.GtUtils.overlay('hide');
          })
          .catch(this.GtUtils.errorClb);
      }

      getAdditionalAgreementsFields(request: any) {
        return {
          formName: 'request-edit-additional-agreement',
          fieldsDef: [
            {
              fieldGroup: [
                {
                  templateOptions: {
                    label: this.gettext('Additional agreements'),
                  },
                  fieldGroup: [
                    {
                      key: 'freight_estimated',
                      type: 'gt-input',
                      defaultValue: 0,
                      templateOptions: {
                        label: this.gettext('Freight'),
                        placeholder: this.gettext('Logistics cost'),
                        type: 'number',
                        addon: this.gettext('per 1 mt'),
                        required: false,
                      },
                    },
                    {
                      key: 'freight_estimated_vat_option',
                      type: 'gt-checkbox',
                      templateOptions: {
                        label: this.gettext('VAT'),
                        onChange: () => {
                          if (request.freight_estimated_vat_option) {
                            request.freight_estimated_vat =
                              this.$rootScope.user.settings.CHARGE_VAT_VALUE;
                          } else {
                            request.freight_estimated_vat = 0;
                          }
                        },
                      },
                      expressionProperties: {
                        'templateOptions.disabled': '!model.price',
                      },
                    },
                    {
                      key: 'freight_estimated_vat',
                      type: 'gt-input',
                      defaultValue: 0,
                      templateOptions: {
                        label: this.gettext('Freight VAT'),
                        placeholder: this.gettext('Logistics costs VAT'),
                        type: 'number',
                        required: false,
                      },
                    },
                    {
                      key: 'price_estimated',
                      type: 'gt-input',
                      defaultValue: 0,
                      templateOptions: {
                        label: this.gettext('Price estimated:'),
                        type: 'number',
                        required: false,
                        disabled: true,
                      },
                    },
                    {
                      key: 'additional_info',
                      type: 'gt-textarea',
                      templateOptions: {
                        label: this.gettext('Additional Info'),
                        placeholder: this.gettext('Specific information about this object'),
                        className: 'additional-info',
                      },
                    },
                  ],
                },
              ],
            },
          ],
          fieldsTypes: [{ key: 'freight_estimated', type: Number }],
        };
      }

      updateAdditionalAgreementsFields() {
        this.FormFieldParamsService.getFields(this.getAdditionalAgreementsFields(this.request))
          .then((fields: any) => (this.additionalAgreementsFields = fields))
          .catch(this.GtUtils.errorClb);
      }
      saveAdditionalAgreements() {
        return this.ContractsService.saveRequest({
          id: this.request.id,
          freight_estimated: this.request.freight_estimated,
          additional_info: this.request.additional_info,
          freight_estimated_vat_option: this.request.freight_estimated_vat_option,
          freight_estimated_vat: this.request.freight_estimated_vat,
        }).then(() => this.updateData());
      }

      getFormConfig() {
        let config = 'request-edit-modal';

        if (this.request.stage === 'indicator') {
          config = 'indicator-edit-modal';
        }
        return this.RequestFormFieldsService.getFields(this.request, config);
      }

      openRequestModal(request: any) {
        return this.ContractsService.requestModal(request).then((data: any) => {
          if (!data || data == 'cancel') {
            return;
          }
          this.updateData();
        });
      }
      openRequestModalQuick(request: any) {
        return this.ContractsService.requestModalQuick(request).then((data: any) => {
          if (!data || data == 'cancel') {
            return;
          }
          this.updateData();
        });
      }

      createContract() {
        if (!confirm(this.gettext(this.GtUtils.translate('Do you want to create contract?')))) {
          return;
        }
        if (this.request.stage == 'prepayment') {
          return this.ContractsService.contractModal({
            cargo: this.request.cargo,
            volume: this.request.volume,
            regional_managers: this.request.regional_managers,
            regional_manager_heads: this.request.regional_manager_heads,
            contract_number:
              this.request.contract_type.charAt(0).toUpperCase() +
              this.moment(new Date()).format('YYYYMMDD') +
              '-' +
              this.request.crop_title,
          });
        }
        this.GtUtils.overlay('show');
        return this.ContractsService.Request.createContract(
          {
            id: this.request.id,
          },
          (data: any) => {
            this.GtUtils.dismissAllModals();
            this.GtUtils.overlay('hide');
            this.$state.go('gt.page.contract', { id: data.id });
          },
          (e: any) => this.GtUtils.errorClb(e),
        ).$promise;
      }

      openEmailModal() {
        return this.ClientsService.sendEmailModal('Request', this.request.id);
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Request', this.request.id);
      }

      openRoleModal(id: any, model: any) {
        return this.ClientsService.roleModal({ id: id, model_name: model });
      }

      openRoleDetails(id: any) {
        return this.ClientsService.openRoleDetails({ id: id });
      }

      createTicket() {
        return this.ContractsService.createTicket(this.request.id);
      }

      createChildTicket() {
        return this.ContractsService.createChildTicket(this.request).then(() => this.updateData());
      }

      clone() {
        return this.ContractsService.cloneRequest(this.request).then(() => this.updateData());
      }

      reverse() {
        if (!confirm(this.gettext(this.GtUtils.translate('Do you want to reverse request?')))) {
          return;
        }
        return this.ContractsService.reverseDeal(this.request);
      }

      approve() {
        return this.ContractsService.Request.get(
          {
            id: this.request.id,
            serializer: 'modal',
          },
          (data: any) => {
            return this.ContractsService.approveDeal(data).then(() => this.updateData());
          },
        );
      }

      cancel() {
        return this.ContractsService.Request.get(
          {
            id: this.request.id,
            serializer: 'modal',
          },
          (data: any) => {
            return this.ContractsService.cancelDeal(data).then(() => this.updateData());
          },
        );
      }

      createPassport() {
        return this.ContractsService.createPassportContract([this.request]);
      }

      connectToPassport(passportId: any) {
        return this.ContractsService.connectToPassport({
          id: passportId,
          deal_id: this.request.id,
          stage: 'request',
          type: this.request.contract_type,
          volume: this.request.volume,
        }).then(
          () => {
            this.updateData();
            this.GtUtils.notify(this.gettext('Passport updated'));
          },
          (e: any) => this.GtUtils.errorClb(e),
        );
      }
      changeTab(tab: any) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);

        if (!this.$rootScope.user.profile.wizard) {
          this.hideWizard = true;
        }
      }
    },
  ],
};
