import ng from 'angular';

import { ContractSignatureContainer } from './contract-signature-container/contract-signature-container.component';
import { ContractsVolumeByCargoChart } from './contracts-volume-by-cargo-chart/contracts-volume-by-cargo-chart.component';
import { ContractsVolumeExecutionChart } from './contracts-volume-execution-chart/contracts-volume-execution-chart.component';
import { CpStatusDisplay } from './cp-status-display/cp-status-display.component';
import { LoyalityProgramData } from './loyality-program-data/loyality-program-data.component';
import { LoyalityProgramDataService } from './loyality-program-data/loyality-program-data.service';
import { MarketPurchasePlans } from './market-purchase-plans/market-purchase-plans.component';
import { MarketUserVolumesGrid } from './market-user-volumes-grid/market-user-volumes-grid.component';
import { PotentialPurchase } from './potential-purchase/potential-purchase.component';
import { PotentialPurchaseService } from './potential-purchase/potential-purchase.service';

export const shared = ng
  .module('deals.shared', [])
  .component('marketUserVolumesGrid', MarketUserVolumesGrid)
  .component('marketPurchasePlans', MarketPurchasePlans)
  .component('contractsVolumeExecutionChart', ContractsVolumeExecutionChart)
  .component('contractsVolumeByCargoChart', ContractsVolumeByCargoChart)
  .component('loyalityProgramData', LoyalityProgramData)
  .component('cpStatusDisplay', CpStatusDisplay)
  .component('potentialPurchase', PotentialPurchase)
  .component('contractSignatureContainer', ContractSignatureContainer)
  .service('LoyalityProgramDataService', LoyalityProgramDataService)
  .service('PotentialPurchaseService', PotentialPurchaseService).name;
