import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('paymentsPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./payments-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['FinancesService', 'GtUtils', 'PageService', 'gettext'];

  function Controller(
    this: any,
    FinancesService: FinancesService,
    GtUtils: GtUtilsService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'payments-page-view';
      vm.queryParams = { ...vm.queryParams };
      vm.queryParams.date_predicate = 'date';
      getPageConfig()
        .then(function (pageConfig: any) {
          PageService.setConfig(pageConfig);
        })
        .catch(GtUtils.errorClb);
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-payments-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Print(),
          new PageService.buttons.Import('finances.payment'),
          new PageService.buttons.Export('finances.payment', vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: [],
      };

      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [
          {
            argument: 'date',
            placeholder: gettext('Date'),
          },
          {
            argument: 'create_time',
            placeholder: gettext('Created'),
          },
          {
            predicate: 'update_time',
            title: gettext('RECENTLY UPDATED'),
          },
          {
            predicate: 'status',
            title: gettext('STATUS'),
          },
        ],
        clicked: false,
        search: true,
        dates: true,
        useFlexDateRange: true,
        invert_filters: true,
        dateSelects: [
          {
            argument: 'date',
            placeholder: gettext('Date'),
          },
          {
            argument: 'create_time',
            placeholder: gettext('Created'),
          },
          {
            argument: 'update_time',
            placeholder: gettext('Updated'),
          },
        ],
        selects: [
          {
            argument: 'currency',
            placeholder: gettext('currency'),
            resource: 'finances.currency',
          },
          {
            argument: 'bank_account',
            placeholder: gettext('invoice bank account'),
            resource: 'finances.bankaccount',
          },
          {
            argument: 'payment_bank_account',
            placeholder: gettext('payment bank account'),
            resource: 'finances.bankaccount',
          },
        ],
        multiSelects: [
          {
            argument: 'invoice_list',
            placeholder: gettext('invoices'),
            resource: 'finances.Finance',
          },
          {
            argument: 'finance_contract_list',
            placeholder: gettext('contract'),
            resource: 'contracts.ContractBase',
          },
          {
            argument: 'clientrole_from_list',
            placeholder: gettext('clientrole from'),
            resource: 'clients.clientrole',
          },
          {
            argument: 'client_list',
            placeholder: gettext('client'),
            resource: 'clients.client',
          },
          {
            argument: 'clientrole_to_list',
            placeholder: gettext('clientrole to'),
            resource: 'clients.clientrole',
          },
          {
            argument: 'business_unit_list',
            placeholder: gettext('business unit'),
            resource: 'core.BusinessUnit',
            getQueryParams: GtUtils.getBUQueryParams,
          },
          {
            argument: 'purchase_ctrs_logistics_buyer_invoice_list',
            placeholder: gettext('purchase contracts logistics buyer invoices'),
            resource: 'contracts.PurchaseContract',
          },
          {
            argument: 'suppliers_purchase_ctrs_logistics_buyer_invoice_list',
            placeholder: gettext('suppliers of purchase contracts logistics buyer invoices'),
            resource: 'clients.supplier',
          },
        ],
        nestedSelects: [
          {
            argument: 'status',
            placeholder: gettext('Total'),
            items: [
              {
                id: 'new',
                title: gettext('New'),
              },
              {
                id: 'process',
                title: gettext('Process'),
              },
              {
                id: 'paid',
                title: gettext('Paid'),
              },
            ],
          },
          {
            argument: 'invoice_type',
            placeholder: gettext('Type'),
            items: [
              {
                id: 'outgoing',
                title: gettext('Outgoing'),
              },
              {
                id: 'incoming',
                title: gettext('incoming'),
              },
            ],
          },
          {
            argument: 'condition',
            placeholder: gettext('Condition'),
            items: [
              {
                id: 'prepay',
                title: gettext('Prepay'),
              },
              {
                id: 'balance',
                title: gettext('Balance'),
              },
            ],
          },
          {
            argument: 'without_rate',
            placeholder: gettext('Without rate'),
            items: [
              {
                id: 1,
                title: gettext('Without'),
              },
            ],
          },
          {
            argument: 'contract_deal_type',
            class: '',
            items: [
              { id: 'spot', title: gettext('Spot') },
              { id: 'forward', title: gettext('Forward') },
              { id: 'intermediate', title: gettext('Intermediate') },
              { id: 'export', title: gettext('Export') },
            ],
            placeholder: gettext('Contract deal type'),
          },
        ],
      };
      // @ts-ignore
      config.filters.nestedMultiSelects = [
        {
          argument: 'use_list',
          placeholder: gettext('Use'),
          items: [
            {
              id: 'cargo',
              title: gettext('Cargo'),
            },
            {
              id: 'costs',
              title: gettext('Costs'),
            },
            {
              id: 'gains',
              title: gettext('Gains'),
            },
            {
              id: 'expenses',
              title: gettext('General expenses'),
            },
            {
              id: 'washout',
              title: gettext('Washout'),
            },
            {
              id: 'credit',
              title: gettext('Credit'),
            },
            {
              id: 'other',
              title: gettext('Other'),
            },
          ],
        },
      ];
      return GtUtils.getCounters(['finances.Payment'], vm.queryParams).then(function () {
        config.pages = FinancesService.getPagesConfig();
        return config;
      });
    }
  }
})();
