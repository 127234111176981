import { type FieldTypeEnum } from '@gt/api';
import type React from 'react';

import type { CounterpartyDTO } from '~/features/crm/counterparties/lib';
import type { CommonDataType } from '~/shared/ui/data-types/lib';

import type { CellDataType, ColumnDef } from '../types';

export const applyDefaultColumnDef = <T extends { id: string }>(
  columns: ColumnDef<T>[],
): ColumnDef<T>[] =>
  columns.map((column) => ({
    ...column,
    editable: column.editable ?? false,
    draggable: column.draggable ?? false,
  }));

export const createColumnDef = <T>(
  accessorKey: keyof T | `_${string}`,
  config: {
    header: string;
    dataType: CellDataType;
    cellTemplate?: React.ElementType;
    cellTemplateContext?: ColumnDef['cellTemplateContext'];
    editable?: boolean;
    draggable?: boolean;
    hidden?: boolean;
    cellValueChanged?: (diff: Partial<CounterpartyDTO>) => void;
    columnSize?: {
      width?: number;
      height?: number;
    };
    sticky?: boolean;
  },
): ColumnDef<T> => ({
  accessorKey,
  header: config.header,
  dataType: config.dataType,
  cellTemplate: config.cellTemplate ?? undefined,
  cellTemplateContext: config.cellTemplateContext ?? undefined,
  editable: config.editable ?? true,
  draggable: config.draggable ?? true,
  hidden: config.hidden ?? false,
  cellValueChanged: config.cellValueChanged ?? undefined,
  size: config.columnSize?.width,
  sticky: config.sticky ?? false,
});

export const mapControlNameFromFieldType: Record<FieldTypeEnum, CommonDataType> = {
  number: 'number',
  string: 'string',
  boolean: 'checkbox',
  date: 'date',
  text: 'text',
  choice: 'select',
  multiple_choices: 'multiselect',
};
