import type { HeaderGroup as HeaderGroupDef, Row } from '@tanstack/react-table';
import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Loading } from '~/shared/ui/components/data-grid/components/loading';

import { HeaderGroup } from './components/header-group';
import { RowGroup } from './components/row-group';
import type { CellRenderDef, HeaderRenderDef } from './lib/types';

export const DataGrid = <TRecord extends { id: string }>({
  rows,
  headerGroups,
  stickyHeaders = true,
  CellRender,
  HeaderRender,
  editing,
  onEdit,
  loading,
  onSelect,
  selectedRowIds,
}: {
  rows: Row<TRecord>[];
  headerGroups: HeaderGroupDef<TRecord>[];
  selectedRowIds?: string[];
  stickyHeaders?: boolean;
  editing: boolean;
  CellRender: React.FC<CellRenderDef<TRecord>>;
  HeaderRender: React.FC<HeaderRenderDef<TRecord>>;
  onEdit: (
    newValue: TRecord[keyof TRecord] | undefined,
    accessorKey: string,
    rowId: string,
  ) => void;
  onSelect?: (ids: string[]) => void;
  loading?: boolean;
}) => {
  const gridRef = React.useRef<HTMLDivElement>(null);
  const [isHorizontalScroll, setIsHorizontalScroll] = React.useState(false);

  gridRef.current?.addEventListener('scroll', () => {
    if (gridRef.current) {
      setIsHorizontalScroll(gridRef.current.scrollLeft > 0);
    }
  });

  // const rowVirtualizer = useVirtualizer({
  //   count: table.getRowModel().rows.length,
  //   estimateSize: () => 32,
  //   getScrollElement: () => gridRef.current,
  //   measureElement:
  //     typeof window !== 'undefined' && !navigator.userAgent.includes('Firefox')
  //       ? (element) => element?.getBoundingClientRect().height
  //       : undefined,
  //   overscan: 15,
  // });

  return (
    <div data-testid="data-grid-container" className="text-xs">
      <div ref={gridRef} className="scrollbar relative max-h-[89vh] w-full overflow-auto">
        <div className="grid">
          <div
            className={cn(
              stickyHeaders && 'sticky top-0 w-full',
              isHorizontalScroll ? 'z-[1]' : 'z-[21]',
            )}
          >
            {loading ? (
              <Loading recordsCount={12} rowsCount={1} />
            ) : (
              headerGroups.map((headerGroup) => (
                <HeaderGroup
                  key={headerGroup.id}
                  headerGroup={headerGroup}
                  editing={editing}
                  HeaderRender={HeaderRender}
                />
              ))
            )}
          </div>
          <div
          // Uncomment when https://github.com/TanStack/virtual/issues/860 is fixed
          // style={{
          //   display: 'grid',
          //   height: `${rowVirtualizer.getTotalSize()}px`,
          //   position: 'relative',
          // }}
          >
            {/*rowVirtualizer.getVirtualItems(). row - virtualRow*/}
            {loading ? (
              <Loading recordsCount={12} rowsCount={25} />
            ) : (
              rows.map((row) => (
                // const row = rows[virtualRow.index]
                <RowGroup
                  key={row.id}
                  row={row}
                  CellRender={CellRender}
                  editing={editing}
                  onEdit={onEdit}
                  onSelect={onSelect}
                  selectedRowIds={selectedRowIds}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
