import ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').controller('VoyageModalController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$uibModalInstance',
    '$window',
    '$q',
    'extraData',
    'LogisticsService',
    'voyage',
    'GtUtils',
    'ContractsService',
    'AccountsService',
    'gettext',
    'FormFieldParamsService',
    'CoreService',
    'ClientsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $window: ng.IWindowService,
    $q: ng.IQService,
    extraData: any,
    LogisticsService: any,
    voyage: any,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    AccountsService: any,
    gettext: ng.gettext.gettextFunction,
    FormFieldParamsService: FormFieldParamsService,
    CoreService: CoreService,
    ClientsService: any,
  ) {
    const vm = this;
    vm.$rootScope = $rootScope;
    vm.voyage = voyage || {};
    vm.close = close;
    vm.save = save;
    vm.extraData = extraData;
    vm.fields = undefined;
    vm.destroy = destroy;
    vm.tab = 'edit';
    vm.form = undefined;
    vm.updateLogEntries = updateLogEntries;
    vm.logEntries = [];
    vm.logEntriesCount = 0;
    vm.updateCounters = updateCounters;
    vm.onBlUpdate = onBlUpdate;
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.stayAfterSave = false;
    vm.onTariffsUpdate = onTariffsUpdate;
    activate();

    ////////////////

    function activate() {
      if (vm.voyage.id) {
        updateData();
      } else {
        updateFields();
      }
    }

    function updateData() {
      return LogisticsService.Voyage.get({ id: vm.voyage.id }, function (data: any) {
        vm.voyage = data;
        updateLogEntries();
        updateCounters();
        updateFields();
      }).$promise;
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(updateFields);
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFormConfig())
        .then((fields: any) => (vm.fields = fields))
        .catch(GtUtils.errorClb);
    }

    function updateLogEntries() {
      return AccountsService.LogEntry.query(
        {
          object_id: vm.voyage.id,
          content_type: vm.voyage.content_type,
        },
        function (data: any) {
          vm.logEntries = data.results;
          vm.logEntriesCount = data.count;
        },
      );
    }

    function updateCounters() {
      GtUtils.getCounters(['logistics.billoflading'], {
        voyage: vm.voyage.id,
      })
        .then(function (data: any) {
          vm.billOfLadingCount = data['logistics.billoflading'];
        })
        .catch(GtUtils.errorClb);
    }
    function onTariffsUpdate(voyagetariff: any) {
      vm.voyage.voyagetariff = voyagetariff;
      vm.updateCounters();
    }

    function onBlUpdate() {
      return LogisticsService.Voyage.get({ id: vm.voyage.id }, function (data: any) {
        vm.voyage.few_commodity = data.few_commodity;
        updateLogEntries();
        updateCounters();
        updateFields();
      }).$promise;
    }

    function destroy() {
      if (!confirm(gettext('Are you sure?'))) {
        return;
      }
      return LogisticsService.Voyage.delete(
        {
          id: vm.voyage.id,
        },
        function (data: any) {
          return close(data, true);
        },
        _error,
      );
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function _error(data: any) {
      vm.voyage.errors = data.data;
      GtUtils.errorClb(data);
    }

    function _success(data: any) {
      GtUtils.notify(gettext(vm.voyage.id ? 'Voyage updated' : 'Voyage saved'));
      if (vm.stayAfterSave) {
        vm.voyage = { id: data.id };
        updateData();
        vm.stayAfterSave = false;
      } else {
        close(data, true);
      }
    }

    function save() {
      vm.form.$invalid = true;
      if (vm.voyage.id) {
        return LogisticsService.Voyage.update(
          vm.voyage,
          function (data: any) {
            _success(data);
          },
          _error,
        );
      }
      return LogisticsService.Voyage.save(
        vm.voyage,
        function (data: any) {
          _success(data);
          if (vm.extraData?.passportId) {
            return LogisticsService.Voyage.connectToPassport({
              voyage_id: data.id,
              passport_id: vm.extraData.passportId,
            });
          }
        },
        _error,
      );
    }

    function getFormConfig() {
      const isValueExists = (key: any, model: any) => model[key] && model[key] !== null;

      const col1: any = {
        className: 'form-group-container col-sm-6 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('MAIN'),
          },
          fieldGroup: [
            {
              key: 'voyage_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Voyage name'),
              },
            },
            {
              key: 'vessel',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Vessel'),
                placeholder: gettext('Choose vessel'),
                resource: 'logistics.Vessel',
                addFunc: () => {
                  return LogisticsService.vesselModal();
                },
                addPerms: ['add_vessel'],
                addIcon: 'fa fa-ship',
                hint: gettext('Vessel'),
                required: true,
              },
            },
            {
              key: 'status',
              type: 'gt-select',
              defaultValue: 'New',
              templateOptions: {
                label: gettext('Status'),
                placeholder: gettext('Status'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: gettext('New'), value: 'New' },
                  { name: gettext('POL under way'), value: 'pol_under_way' },
                  { name: gettext('At loading port'), value: 'at_loading_port' },
                  { name: gettext('Loading'), value: 'Loading' },
                  { name: gettext('Loaded'), value: 'Loaded' },
                  { name: gettext('POD under way'), value: 'pod_under_way' },
                  { name: gettext('At discharging port'), value: 'at_discharging_port' },
                  { name: gettext('Discharging'), value: 'Discharging' },
                  { name: gettext('Discharged'), value: 'Discharged' },
                  { name: gettext('Executed'), value: 'Executed' },
                  { name: gettext('Washout'), value: 'Washout' },
                  { name: gettext('Cancelled'), value: 'Cancelled' },
                ],
              },
            },
            {
              key: 'custom_status',
              type: 'gt-ui-select',
              defaultValue: vm.custom_status_title,
              templateOptions: {
                label: gettext('Custom status'),
                placeholder: gettext('Choose custom status'),
                resource: 'core.CustomStatus',
                hint: gettext('Custom status'),
                addFunc: () => {
                  $window.open('/admin/core/customstatus/add/');
                },
                addIcon: 'fa fa-tasks',
                addPerms: true,
                queryParams: {
                  content_type__model: 'voyage',
                },
              },
            },
            {
              key: 'is_consecutive',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Consecutive voyages'),
              },
            },
            {
              key: 'hide_from_freight_position',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Do not use in Freight position'),
              },
            },

            {
              key: 'few_commodity',
              type: 'gt-voyage-few-commodity',
              templateOptions: {
                voyageId: vm.voyage?.id || -1,
              },
            },
            {
              key: 'volume_freighted',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Volume intake sum'),
                type: 'number',
                addon: gettext('t'),
                disabled: true,
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('INFO'),
          },
          fieldGroup: [
            {
              key: 'disponent_owner',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Disponent owner'),
                addon: gettext('Disponent owner'),
              },
            },
            {
              key: 'charterer',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Charterer'),
                placeholder: gettext('Choose charterer'),
                resource: 'clients.ClientRole',
                addFunc: () => {
                  return ClientsService.roleModal({ model_name: 'Other' });
                },

                addIcon: GtUtils.getIcon('clients.Other'),
                addPerms: ['add_other'],
              },
            },
            {
              key: 'cp_date',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'hour',
                label: gettext('Charter party (date)'),
                placeholder: gettext('Pick a date'),
              },
            },
            {
              key: 'laycan_start',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Laycan (start)'),
                placeholder: gettext('Pick a date'),
              },
            },
            {
              key: 'laycan_end',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Laycan (end)'),
                placeholder: gettext('Pick a date'),
              },
            },
            {
              key: 'extension_start',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Extension (start)'),
                placeholder: gettext('Pick a date'),
              },
            },
            {
              key: 'extension_end',
              type: 'gt-date-select',
              templateOptions: {
                label: gettext('Extension (end)'),
                placeholder: gettext('Pick a date'),
              },
            },
            {
              key: 'purchase_contracts',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: gettext('Purchase contracts'),
                placeholder: gettext('Choose contract'),
                addon: gettext('#'),
                resource: 'contracts.PurchaseContract',
                addFunc: () => {
                  return ContractsService.contractModal({});
                },
                addIcon: GtUtils.getIcon('contracts.PurchaseContract'),
                addPerms: ['add_purchasecontract'],
                hint: gettext('Number of Purchase contract'),
              },
            },
            {
              key: 'sale_contracts',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: gettext('Sale contracts'),
                placeholder: gettext('Choose contract'),
                addon: gettext('#'),
                resource: 'contracts.SaleContract',
                addFunc: () => {
                  return ContractsService.contractModal({
                    contract_type: 'sale',
                  });
                },
                addPerms: ['add_salecontract'],
                addIcon: GtUtils.getIcon('contracts.SaleContract'),
                hint: gettext('Number of Sale contract'),
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: gettext('Additional Info'),
                placeholder: gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
            {
              key: 'broker',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Broker'),
                resource: 'clients.Broker',
                addFunc: () => {
                  return ClientsService.roleModal({ model_name: 'Broker' });
                },
                addIcon: GtUtils.getIcon('clients.Broker'),
                addPerms: ['add_broker'],
              },
            },
            {
              key: 'blacklisted',
              type: 'gt-checkbox',
              templateOptions: {
                label: gettext('Blacklisted'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => CoreService.getDefaultBuId(vm.voyage),
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
                addFunc: () => {
                  $window.open('/admin/core/businessunit/add/');
                },
                addIcon: 'fa fa-home',
                addPerms: true,
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('RATES & COSTS'),
          },
          fieldGroup: [
            {
              key: 'shortage_rate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Shortage rate'),
                type: 'number',
                addon: gettext('$/t'),
              },
            },
            {
              key: 'demmurage_rate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Demurrage rate'),
                type: 'number',
                addon: gettext('$/day'),
              },
            },
            {
              key: 'dispatch_rate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Dispatch rate'),
                type: 'number',
                addon: gettext('$/day'),
              },
            },
            {
              key: 'freight_costs',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Freight costs'),
                type: 'number',
                addon: gettext('$/t'),
              },
            },
            {
              key: 'freight_info',
              type: 'gt-textarea',
              templateOptions: {
                label: gettext('Freight Info'),
                placeholder: gettext('Specific information about freight'),
              },
            },
            {
              key: 'broker_freight_costs',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Freight broker costs'),
                type: 'number',
              },
            },
            {
              key: 'loading_dem_dis',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Loading dem/dis'),
                type: 'number',
                addon: gettext('$'),
              },
            },
            {
              key: 'destination_dem_dis',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Destination dem/dis'),
                type: 'number',
                addon: gettext('$'),
              },
            },
            {
              key: 'additional_costs',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Additional costs'),
                type: 'number',
                addon: gettext('$'),
              },
            },
            {
              key: 'free_days_jcc',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Free days JCC'),
                type: 'number',
              },
            },
            {
              key: 'free_days_ewrp',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Free days EWRP'),
                type: 'number',
              },
            },
          ],
        },
      ];

      col2.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('LOADING FINAL'),
          },
          fieldGroup: [
            {
              key: 'port_loading',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Loading final port'),
                placeholder: gettext('Choose port'),
                resource: 'logistics.Port',
                addFunc: () => {
                  $window.open('/admin/logistics/port/add/', '_blank');
                },
                addPerms: ['add_port'],
                addIcon: GtUtils.getIcon('logistics.Port'),
                hint: gettext('port'),
              },
            },
            {
              key: 'delivery_condition',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Delivery condition'),
                placeholder: gettext('Choose option'),
                resource: 'logistics.DeliveryCondition',
                addFunc: () => {
                  $window.open('/admin/logistics/deliverycondition/add/');
                },
                addIcon: 'fa fa-truck',
                addPerms: ['add_deliverycondition'],
              },
            },
            {
              key: 'load_rate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Load rate'),
                type: 'number',
                addon: gettext('t/day'),
              },
            },
            {
              key: 'eta',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('eta'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etb', 'start_date_load', 'ets', 'etc'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'date_of_notice_loading',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of notice'),
                placeholder: gettext('Date of notice loading'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'etb',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('etb'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.eta,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['start_date_load', 'ets', 'etc'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'date_of_berth_loading',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of berth'),
                placeholder: gettext('Date of berth loading'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'start_date_load',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of loading start'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etb,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['ets', 'etc'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'end_date_load',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of loading end'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_load,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['ets', 'etc'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'etc',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('etc'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_load,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['ets'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'ets',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ets'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etc,
              },
            },
            {
              key: 'agent_loading',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Agent'),
                addon: gettext('Agent'),
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('LOADING 2ND PORT'),
          },
          fieldGroup: [
            {
              key: 'port_loading_intermediate',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Loading 2nd port'),
                placeholder: gettext('Choose port'),
                resource: 'logistics.Port',
                addFunc: () => {
                  $window.open('/admin/logistics/port/add/', '_blank');
                },
                addPerms: ['add_port'],
                addIcon: GtUtils.getIcon('logistics.Port'),
                hint: gettext('port'),
              },
            },
            {
              key: 'delivery_condition_intermediate',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Delivery condition'),
                placeholder: gettext('Choose option'),
                resource: 'logistics.DeliveryCondition',
                addFunc: () => {
                  $window.open('/admin/logistics/deliverycondition/add/');
                },
                addIcon: 'fa fa-truck',
                addPerms: ['add_deliverycondition'],
              },
            },
            {
              key: 'load_rate_intermediate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Load rate'),
                type: 'number',
                addon: gettext('t/day'),
              },
            },
            {
              key: 'eta_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('eta'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  ![
                    'etb_intermediate',
                    'start_date_load_intermediate',
                    'etc_intermediate',
                    'ets_intermediate',
                  ].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'date_of_notice_loading_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of notice'),
                placeholder: gettext('Date of notice loading'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'etb_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('etb'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.eta_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['start_date_load_intermediate', 'etc_intermediate', 'ets_intermediate'].some(
                    (key) => isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'date_of_berth_loading_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of berth'),
                placeholder: gettext('Date of berth loading'),
                className: 'datetimepicker',
              },
            },

            {
              key: 'start_date_load_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of loading start'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etb_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etc_intermediate', 'ets_intermediate'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'end_date_load_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of loading end'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_load_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etc_intermediate', 'ets_intermediate'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'etc_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('etc'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_load_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['ets_intermediate'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'ets_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ets'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etc_intermediate,
              },
            },
            {
              key: 'volume_loaded_intermediate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Loaded volume'),
                addon: gettext('t'),
                type: 'number',
              },
            },
            {
              key: 'agent_loading_intermediate',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Agent'),
                addon: gettext('Agent'),
              },
            },
          ],
        },
      ];

      col3.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('DESTINATION FINAL'),
          },
          fieldGroup: [
            {
              key: 'port_destination',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Destination final port'),
                placeholder: gettext('Choose port'),
                resource: 'logistics.Port',
                addFunc: () => {
                  $window.open('/admin/logistics/port/add/', '_blank');
                },
                addPerms: ['add_port'],
                addIcon: GtUtils.getIcon('logistics.Port'),
                hint: gettext('port'),
              },
            },
            {
              key: 'delivery_condition_destination',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Delivery condition'),
                placeholder: gettext('Choose option'),
                resource: 'logistics.DeliveryCondition',
                addFunc: () => {
                  $window.open('/admin/logistics/deliverycondition/add/');
                },
                addIcon: 'fa fa-truck',
                addPerms: ['add_deliverycondition'],
              },
            },
            {
              key: 'disch_rate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Disch rate'),
                type: 'number',
                addon: gettext('t/day'),
              },
            },
            {
              key: 'eta_destination',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETA'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.ets,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  ![
                    'etb_destination',
                    'start_date_disch',
                    'etc_destination',
                    'ets_destination',
                  ].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'date_of_notice_destination',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of notice'),
                placeholder: gettext('Date of notice destination'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'etb_destination',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETB'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.eta_destination,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['start_date_disch', 'etc_destination', 'ets_destination'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'date_of_berth_destination',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of berth'),
                placeholder: gettext('Date of berth destination'),
                className: 'datetimepicker',
              },
            },

            {
              key: 'start_date_disch',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of discharging start'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etb_destination,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etc_destination', 'ets_destination'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'end_date_disch',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of discharging end'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_disch,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etc_destination', 'ets_destination'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'etc_destination',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETC'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_disch,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['ets_destination'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'ets_destination',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETS'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etc_destination,
              },
            },
            {
              key: 'agent_destination',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Agent'),
                addon: gettext('Agent'),
              },
            },
          ],
        },
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: gettext('DESTINATION 1ST PORT'),
          },
          fieldGroup: [
            {
              key: 'port_destination_intermediate',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Destination 1st port'),
                placeholder: gettext('Choose port'),
                resource: 'logistics.Port',
                addFunc: () => {
                  $window.open('/admin/logistics/port/add/', '_blank');
                },
                addPerms: ['add_port'],
                addIcon: GtUtils.getIcon('logistics.Port'),
                hint: gettext('port'),
              },
            },
            {
              key: 'delivery_condition_destination_intermediate',
              type: 'gt-ui-select',
              templateOptions: {
                label: gettext('Delivery condition'),
                placeholder: gettext('Choose option'),
                resource: 'logistics.DeliveryCondition',
                addFunc: () => {
                  $window.open('/admin/logistics/deliverycondition/add/');
                },
                addIcon: 'fa fa-truck',
                addPerms: ['add_deliverycondition'],
              },
            },
            {
              key: 'disch_rate_intermediate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Disch rate'),
                type: 'number',
                addon: gettext('t/day'),
              },
            },
            {
              key: 'eta_destination_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETA'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  ![
                    'etb_destination_intermediate',
                    'start_date_disch_intermediate',
                    'etc_destination_intermediate',
                    'ets_destination_intermediate',
                  ].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'date_of_notice_destination_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of notice'),
                placeholder: gettext('Date of notice destination'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'etb_destination_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETB'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.eta_destination_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  ![
                    'start_date_disch_intermediate',
                    'etc_destination_intermediate',
                    'ets_destination_intermediate',
                  ].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'date_of_berth_destination_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of berth'),
                placeholder: gettext('Date of berth destination'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'start_date_disch_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of discharging start'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.eta_destination_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etc_destination_intermediate', 'ets_destination_intermediate'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'end_date_disch_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('Date of discharging end'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_disch_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['etc_destination_intermediate', 'ets_destination_intermediate'].some((key) =>
                    isValueExists(key, scope.model),
                  ),
              },
            },
            {
              key: 'etc_destination_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETC'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.start_date_disch_intermediate,
                'templateOptions.allowClear': (viewValue: any, modelValue: any, scope: any) =>
                  !['ets_destination_intermediate'].some((key) => isValueExists(key, scope.model)),
              },
            },
            {
              key: 'ets_destination_intermediate',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: gettext('ETS'),
                placeholder: gettext('Pick a deadline'),
                className: 'datetimepicker',
              },
              expressionProperties: {
                'templateOptions.disabled': (viewValue: any, modelValue: any, scope: any) =>
                  !scope.model.etc_destination_intermediate,
              },
            },
            {
              key: 'volume_discharged_intermediate',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: gettext('Volume discharged'),
                type: 'number',
                addon: gettext('t'),
              },
            },
            {
              key: 'agent_destination_intermediate',
              type: 'gt-ui-select',
              templateOptions: {
                resource: 'clients.Other',
                label: gettext('Agent'),
                addon: gettext('Agent'),
              },
            },
          ],
        },
      ];

      return {
        formName: 'voyage-edit-modal',
        fieldsDef: [col1, col2, col3],
      };
    }
  }
})();
