import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('balanceCropContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      amountTotal: '<?',
    },
    template: require('./balance-crop-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'ReportsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ReportsService: any,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.cropBalances = [];
    vm.financeAccountsUndistributed = 0;
    vm.updateCropBalance = updateCropBalance;
    vm.total = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'balance-crop-container';
      const { start_date: startDate, end_date: endDate, ...params } = vm.initQueryParams;
      vm.queryParams = {
        ...params,
        from_date: startDate,
        to_date: endDate,
      };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateCropBalance();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    function updateCropBalance() {
      GtUtils.overlay('show');
      ReportsService.FinanceBalance.cropBalance(vm.queryParams, function (data: any) {
        vm.cropBalances = data.results;
        ReportsService.FinanceBalance.cropBalanceTotal(vm.queryParams, function (data: any) {
          vm.financeAccountsUndistributed = vm.amountTotal - data.amount;
          GtUtils.overlay('hide');
        });
      });
    }
  }
})();
