import React from 'react';

import { type ErrorRouteComponent, Link } from '~/shared/lib/router';
import { Button } from '~/shared/ui/kit/button';

import darkImgSrc from './dark-server-error.svg';
import lightImgSrc from './light-server-error.svg';

export const ErrorPage: ErrorRouteComponent = () => {
  return (
    <section className="h-screen bg-background-main-secondary">
      <section className="flex h-full flex-col items-center justify-center gap-8">
        <div>
          <img
            src={lightImgSrc}
            alt="server-error-img"
            className="block dark:hidden"
            width={240}
            height={216}
          />
          <img
            src={darkImgSrc}
            alt="server-error-img"
            className="hidden dark:block"
            width={240}
            height={216}
          />
        </div>
        <div className="flex flex-col items-center gap-4">
          <h1 className="text-2xl font-semibold text-text-main-primary">Unexpected Pit Stop</h1>
          <p className="text-xs text-text-main-secondary">
            We have been notified and working on a fix.
          </p>
        </div>
        <Link to="/" className="w-[200px]">
          <Button variant="default" size="md" fontWeight="semibold" fullWidth>
            Back to content
          </Button>
        </Link>
      </section>
    </section>
  );
};
