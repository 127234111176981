import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './core-inline-filter-view.html?raw';
import type { GtFilterService } from '../../legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '../../types';

export const CoreInlineFilterView = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<?',
    choices: '<',
    addTableOptions: '&?',
    onlyOnePreset: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gtFilterService',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      ContractsService: ContractsService;
      addTableOptions: any;
      choices: any;
      filterLevel = 'core-inline-filter-view';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      onlyOnePreset = false;
      queryParams: QueryParams = {};
      selectedPresetIds: any;
      constructor(
        $rootScope: GtRootScopeService,
        gtFilterService: GtFilterService,
        ContractsService: ContractsService,
      ) {
        this.$rootScope = $rootScope;
        this.gtFilterService = gtFilterService;
        this.ContractsService = ContractsService;
        this.selectedPresetIds = [];
      }

      $onInit() {
        this.addTableOptions = this.addTableOptions || function () {};
        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);

        this.$rootScope.$on('gt-favorite-filter-clear', () => {
          if (this.selectedPresetIds?.length) {
            this.selectedPresetIds = [];
            this.clearParams();
            this.applyFilter();
          }
        });
      }

      $onChanges(changes: any) {
        if (changes.initQueryParams) {
          this.queryParams = {
            ...this.queryParams,
            ...this.initQueryParams,
          };
        }
      }
      updateSelectedFilters(preset: any) {
        if (!this.selectedPresetIds.includes(preset.id)) {
          if (this.onlyOnePreset) {
            this.selectedPresetIds = [];
          }
          this.selectedPresetIds.push(preset.id);
          this.setParamsFromPreset(preset);
        } else {
          this.selectedPresetIds = this.selectedPresetIds.filter((id: any) => id !== preset.id);
          this.clearParams();
          this.choices
            .filter((item: any) => this.selectedPresetIds.includes(item.id))
            .forEach((selectedPreset: any) => this.setParamsFromPreset(selectedPreset));
        }
      }
      multipleSelectPreset(preset: any) {
        this.updateSelectedFilters(preset);
        this.applyFilter();
      }

      setParamsFromPreset(preset: any) {
        if (this.queryParams?.start_date || this.queryParams?.end_date) {
          const defaultDates = this.ContractsService.getDefaultsParams();
          if (
            this.queryParams?.start_date === defaultDates.start_date &&
            this.queryParams?.end_date === defaultDates.end_date
          ) {
            this.queryParams.start_date = undefined;
            this.queryParams.end_date = undefined;
          }
        }
        Object.keys(preset.params).forEach((key) => {
          if (key in this.queryParams && Array.isArray(this.queryParams[key])) {
            this.queryParams[key] = [
              // @ts-ignore
              ...new Set([
                ...this.queryParams[key],
                ...preset.params[key].map((v: any) => v.toString()),
              ]),
            ];
          } else {
            this.queryParams[key] = preset.params[key];
          }
        });
      }

      clearParams() {
        if (this.selectedPresetIds.length === 0) {
          this.queryParams = { ...this.initQueryParams };
        } else {
          Object.keys(this.queryParams).forEach((key) => {
            if (key in this.queryParams && Array.isArray(this.queryParams[key])) {
              this.queryParams[key] = [];
            }
          });
        }
      }

      applyFilter() {
        this.queryParams.page = undefined;
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.addTableOptions({
          options: {
            activeFilterPresets: [...this.selectedPresetIds],
          },
        });
      }
    },
  ],
};
