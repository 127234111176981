import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './freight-position-page-view.html?raw';
export const FreightPositionPageView = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'GtUtils',
    'ReportsService',
    'gettext',
    class FreightPositionPageView {
      GtUtils: GtUtilsService;
      PageService: PageService;
      ReportsService: any;
      consolidatedFilterLevel: string;
      consolidatedQueryParams: QueryParams = {};
      filterLevel = 'freight-position-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      purchaseFilterLevel: string;
      purchaseQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      saleFilterLevel: string;
      saleQueryParams: QueryParams = {};
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        ReportsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.ReportsService = ReportsService;
        this.gettext = gettext;

        this.consolidatedFilterLevel = 'consolidated-freight-position-container';
        this.saleFilterLevel = 'freight-position-sale-list-container';
        this.purchaseFilterLevel = 'freight-position-purchase-list-container';

        this.consolidatedQueryParams = { charterer_is_owner: '1' };
      }

      $onInit() {
        this.queryParams = { ...this.queryParams, charterer_is_owner: '1' };
        this.saleQueryParams = {
          ...this.queryParams,
          contract_type: 'sale',
          exclude_status_list: ['cancelled', 'washout', 'approved'],
        };
        this.purchaseQueryParams = {
          ...this.queryParams,
          contract_type: 'purchase',
          exclude_status_list: ['cancelled', 'washout', 'approved'],
        };
        this.filterLevel = this.filterLevel || 'freight-position-page-view';
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {},
        };
      }

      applyFilter() {
        const newParams = {
          cargo_list: this.queryParams.cargo_list,
          buyer_list: this.queryParams.buyer_list,
          supplier_list: this.queryParams.supplier_list,
          basis_list: this.queryParams.basis_list,
          owner_list: this.queryParams.owner_list,
          port_list: this.queryParams.port_list,
          season_list: this.queryParams.season_list,
          charterer_is_owner: this.queryParams.charterer_is_owner,
          business_unit_list:
            (!this.queryParams.without_business_unit && this.queryParams.business_unit_list) ??
            null,
          with_business_unit: (this.queryParams.without_business_unit && '0') ?? null,
          page: undefined,
        };
        this.gtFilterService.updateQueryParams(newParams, this.consolidatedFilterLevel);
        this.gtFilterService.updateQueryParams(newParams, this.saleFilterLevel);
        this.gtFilterService.updateQueryParams(newParams, this.purchaseFilterLevel);
        this.gtFilterService.updateQueryParams(newParams, this.filterLevel);
      }

      resetFilter() {
        this.queryParams = {};
        this.applyFilter();
      }
    },
  ],
};
