import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('plReportContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./pl-report-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$timeout',
    '$scope',
    '$httpParamSerializer',
    'GtUtils',
    'ReportsService',
    'gtFilterService',
    'moment',
  ];

  function Controller(
    this: any,
    $timeout: ng.ITimeoutService,
    $scope: ng.IScope,
    $httpParamSerializer: any,
    GtUtils: GtUtilsService,
    ReportsService: any,
    gtFilterService: GtFilterService,
    moment: any,
  ) {
    const vm = this;

    vm.incomeFinanceAccounts = [];
    vm.expenseFinanceAccounts = [];
    vm.updateFinanceAccounts = updateFinanceAccounts;
    vm.openBalanceModal = openBalanceModal;
    vm.applyFilter = applyFilter;
    vm.resetFilter = resetFilter;
    vm.total = {};
    vm.balanceDate = undefined;
    vm.url = undefined;
    vm.cropFinanceAccounts = ['Sales income', ' Cost of goods sold'];
    vm.totalValue = 0;
    vm.incomeTotal = 0;
    vm.expenseTotal = 0;
    vm.datepicker = '';
    vm.business_unit_list = [];
    vm.currency = 'usd';
    vm.db_convert = dbConvert;
    vm.changeCurrency = changeCurrency;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'pl-report-container';
      vm.queryParams = { ...vm.initQueryParams };
      vm.datepickerConfig = getDatePickerConfig();
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        vm.url =
          '/api/reports/finance-balance/?' +
          $httpParamSerializer({
            as_xlsx: 1,
            root_positions: ['INCOME', 'EXPENSES'],
            ...vm.queryParams,
          });
        updateFinanceAccounts();
      });
    };

    vm.$onChanges = function () {
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    ////////////////

    function updateFinanceAccounts() {
      GtUtils.overlay('show');
      ReportsService.FinanceBalance.query(
        { ...vm.queryParams, root_positions: 'INCOME' },
        function (data: any) {
          vm.incomeFinanceAccounts = prepareAccounts(data.results, 'INCOME');
          vm.incomeTotal = data.total.total_value;
          vm.incomeTotalLocal = data.total.total_value_local;
          ReportsService.FinanceBalance.query(
            { ...vm.queryParams, root_positions: 'EXPENSES' },
            function (data: any) {
              vm.expenseFinanceAccounts = prepareAccounts(data.results, 'EXPENSES');
              vm.expenseTotal = data.total.total_value;
              vm.expenseTotalLocal = data.total.total_value_local;
              GtUtils.overlay('hide');
              vm.totalValue = vm.incomeTotal - vm.expenseTotal;
              vm.totalValueLocal = vm.incomeTotalLocal - vm.expenseTotalLocal;
            },
          );
        },
      );
    }

    function openBalanceModal(mode: any, financeAccount: any) {
      return ReportsService.balanceModal(
        mode,
        {
          finance_account_id: financeAccount.id,
          start_date: vm.queryParams.start_date,
          end_date: vm.queryParams.end_date,
          business_unit_list: vm.business_unit_list,
        },
        financeAccount.total_value,
      );
    }

    function prepareAccounts(accountsList: any, rootTitle: any) {
      const newAccountsList: any = [];
      accountsList.forEach((account: any) => {
        if (account.title != rootTitle) {
          return;
        }
        newAccountsList.push(account);
        prepareAccountBranch(account, newAccountsList, accountsList);
        newAccountsList.push({ subTotal: true, ...account });
      });
      return newAccountsList;
    }

    function prepareAccountBranch(accountRoot: any, newAccountsList: any, oldAccountsList: any) {
      oldAccountsList.forEach((account: any) => {
        if (account.parent_id == accountRoot.id) {
          newAccountsList.push(account);
          prepareAccountBranch(account, newAccountsList, oldAccountsList);
        }
      });
    }

    function getDatePickerConfig() {
      const config = gtFilterService.getDateRangePickerConfig();
      config.eventHandlers = {
        'apply.daterangepicker': () => {
          $timeout(function () {
            setDates();
          });
        },
        'cancel.daterangepicker': () => {
          $timeout(function () {
            vm.datepicker = false;
            setDates();
          });
        },
      };
      return config;
    }

    function setDates() {
      if (!vm.datepicker) {
        vm.queryParams.start_date = undefined;
        vm.queryParams.end_date = undefined;
      } else {
        vm.queryParams.start_date = moment(vm.datepicker.startDate).format('YYYY-MM-DD');
        vm.queryParams.end_date = moment(vm.datepicker.endDate).format('YYYY-MM-DD');
      }
    }

    function applyFilter() {
      setDates();
      vm.queryParams.business_unit_list = vm.business_unit_list;
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function resetFilter() {
      vm.business_unit_list = [];
      vm.datepicker = {};
      vm.queryParams = {};
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function dbConvert(usd: any, local: any) {
      if (vm.currency == 'usd') {
        return usd;
      } else {
        return local;
      }
    }

    function changeCurrency(currency: any) {
      vm.currency = currency;
      vm.currency_symbol = currency === 'usd' ? 'usd' : 'local';
    }
  }
})();
