import ng from 'angular';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('contractTerminalCertsRow', directive);

  function directive() {
    return {
      template: require('./contract-terminal-certs-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        cert: '<',
        onSave: '&',
        onDelete: '&',
      },
    };
  }

  function Controller(this: any) {
    const vm = this;

    vm.errors = {};
    vm.saveCert = saveCert;
    vm.deleteCert = deleteCert;
    vm.toggleEdit = toggleEdit;

    vm.$onInit = function () {
      vm.cert = vm.cert || {};
      vm.edit = !vm.cert.id;
    };

    function saveCert() {
      vm.onSave({ cert: vm.cert });
    }

    function deleteCert() {
      vm.onDelete({ cert: vm.cert });
    }

    function toggleEdit(key: any) {
      vm.edit = key;
    }
  }
})();
