import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtOnEnter', directive);

  function directive() {
    const directive = {
      link: link,
      restrict: 'A',
    };
    return directive;

    function link(scope: any, element: any, attrs: any) {
      element.bind('keydown keypress', eventHandler);
      element.attr('tabindex', '0');

      function eventHandler(event: any) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.gtOnEnter);
          });
          event.preventDefault();
        }
      }
    }
  }
})();
