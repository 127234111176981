import { type SortingDirection, SortingDirectionVariant } from '../../types';

export const formatSortingDirection = (
  direction: SortingDirection,
  config: { formatType: 'short' | 'long' } = { formatType: 'short' },
): string => {
  if (config.formatType === 'short') {
    return direction === SortingDirectionVariant.Asc ? 'A-Z' : 'Z-A';
  }

  return direction === SortingDirectionVariant.Asc ? 'Ascending' : 'Descending';
};
