import ng from 'angular';

import type { GtRootScopeService, QueryParams } from '../../types';

function Service(
  $timeout: ng.ITimeoutService,
  $rootScope: GtRootScopeService,
  $uibModalStack: any,
  $q: ng.IQService,
  $http: ng.IHttpService,
  $state: ng.ui.IStateService,
  $filter: ng.IFilterService,
  $document: any,
  $window: ng.IWindowService,
  $location: ng.ILocationService,
  $injector: ng.auto.IInjectorService,
  moment: any,
  gettext: ng.gettext.gettextFunction,
) {
  const ResourcesService = $injector.get<any>('ResourcesService');
  const AccountsService = $injector.get<any>('AccountsService');
  const NotificationService = $injector.get<any>('NotificationService');
  const DefaultCache: any = {
    counters: { default: {} },
    predictions: { default: {} },
    report_configs: { default: {} },
  };

  let _cache = DefaultCache;
  let currencyExchange: any = null;
  let activeOverlays = 0;
  let _connections: any = [];
  let localCurrency = '';
  const _iconsMap: Record<string, string> = {
    'auth.user': 'fa-user',
    'accounts.regionalmanager': 'fa-address-book',
    'clients.client': 'fa-building',
    'clients.bank': 'fa-university',
    'clients.broker': 'fa-briefcase',
    'clients.buyer': 'fa-bookmark',
    'clients.elevator': 'fa-industry',
    'clients.exporter': 'fa-shield',
    'clients.farm': 'fa-building-o',
    'clients.insurer': 'fa-life-ring',
    'clients.person': 'fa-users',
    'clients.owner': 'fa-home',
    'clients.surveyor': 'fa-flask',
    'clients.deliverer': 'fa-truck',
    'clients.supplier': 'fa-bookmark-o',
    'clients.companygroup': 'fa-object-group',
    'clients.responsibility': '',
    'clients.clientupdate': '',
    'clients.other': 'fa-cube',
    'core.businessunit': 'fa-tag',
    'logistics.station': 'fa-train',
    'logistics.terminal': 'fa-anchor',
    'logistics.port': 'fa-anchor',
    'logistics.shipmentplan': 'fa-calendar-check-o',
    'logistics.vessel': '',
    'logistics.logistictariff': '',
    'logistics.logisticfreight': '',
    'logistics.logistic': 'fa-truck',
    'logistics.warehouseloss': 'fa-times',
    'warehouses.farm': 'fa-building-o',
    'warehouses.elevator': 'fa-industry',
    'warehouses.terminal': 'fa-anchor',
    'warehouses.warehouse': 'fa-warehouse',
    'warehouses.other': 'fa-question-circle',
    'contracts.contractbase': 'fa-file-text',
    'contracts.salecontract': 'fa-file-text',
    'contracts.purchasecontract': 'fa-file-text',
    'contracts.intermediatecontract': 'fa-file-text',
    'contracts.request': 'fa-file-text-o',
    'contracts.dealscompare': 'fa-arrows-alt',
    'contracts.contractcharge': 'fa-money',
    'contracts.consignment': 'fa-file',
    'passports.passport': 'fa-exchange',
    'location.country': '',
    'location.district': '',
    'crops.crop': '',
    'finances.charge': 'fa-paragraph',
    'finances.currency': 'fa-coins',
    'finances.currencyexchange': '',
    'finances.finance': 'fa-credit-card',
    'finances.financeaccount': 'fa-balance-scale',
  };
  const states: Record<string, string> = {
    'contracts.request': 'gt.page.request',
    'contracts.salecontract': 'gt.page.contract',
    'contracts.purchasecontract': 'gt.page.contract',
    'contracts.contractbase': 'gt.page.contract',
    'contracts.consignment': 'gt.page.contract',
    'contracts.generalagreement': 'gt.generalagreement',
    'contracts.contractcharge': 'finances.contractCharges',
    'passports.passport': 'gt.page.passport',
    'clients.client': 'gt.page.client',
    'clients.role': 'gt.page.role',
    'clients.buyer': 'gt.page.client',
    'clients.supplier': 'gt.page.client',
    'clients.exporter': 'gt.page.client',
    'clients.bank': 'gt.page.client',
    'clients.broker': 'gt.page.client',
    'clients.elevator': 'gt.page.client',
    'clients.farm': 'gt.page.client',
    'clients.insurer': 'gt.page.client',
    'clients.owner': 'gt.page.client',
    'clients.surveyor': 'gt.page.client',
    'clients.deliverer': 'gt.page.client',
    'finances.finance': 'gt.page.payment',
    'logistics.shipmentplan': 'logistics.planning',
    'logistics.vessel': 'gt.old-page.vessel',
    'logistics.logistic': 'gt.page.logistic',
    'logistics.billOfLading': 'logistics.billoflading',
    'clients.clientupdate': 'clients.updates',
    'auth.user': 'gt.page.trader',
  };

  activate();

  return {
    debounce: debounce,
    throttle: throttle,
    getResource: getResource,
    dismissAllModals: dismissAllModals,
    getCounters: getCounters,
    getPredictions: getPredictions,
    updateCounters: updateCounters,
    overlay: overlay,
    getIcon: getIcon,
    notify: notify,
    convertDate: convertDate,
    errorClb: errorClb,
    goToDetails: goToDetails,
    goDetails: goDetails,
    goPage: goPage,
    convertCurrency: convertCurrency,
    getLocalCurrency: getLocalCurrency,
    capitalize: capitalize,
    daysInMonth: daysInMonth,
    isSameDay: isSameDay,
    addBusinessDays: addBusinessDays,
    listUnique: listUnique,
    newConnections: newConnections,
    addConnection: addConnection,
    cancelConncections: cancelConncections,
    translate: translate,
    getObjectTitle: getObjectTitle,
    weekOfMonth: weekOfMonth,
    applyVat: applyVat,
    calcBusinessDays: calcBusinessDays,
    getBUQueryParams: getBUQueryParams,
    getDefaultOriginId: getDefaultOriginId,
    getCustomStatusId: getCustomStatusId,
    getDefaultFinanceOwnerId: getDefaultFinanceOwnerId,
    filterEmptyParams: filterEmptyParams,
    downloadFile: downloadFile,
    copyToClipboard: copyToClipboard,
    getCurrentHost: getCurrentHost,
    addDaysToDate: addDaysToDate,
    isObjectsEqual: isObjectsEqual,
    updateCahce: updateCahce,
    getAllCache: getAllCache,
    getCachedValue: getCachedValue,
    resetCache: resetCache,
    getYearList: getYearList,
    getMonthList: getMonthList,
  };

  ////////////////

  function activate() {
    AccountsService.getUser().then(function (data: any) {
      localCurrency = data.settings.LOCAL_CURRENCY;
    });
    _cache = getCache();
  }

  function getLocalCurrency() {
    return localCurrency;
  }

  function listUnique(list: any) {
    return list.filter(function (elem: any, index: any, self: any) {
      return index == self.indexOf(elem);
    });
  }

  function debounce(callback: any, interval: any) {
    interval = interval || 1000;
    let timeout: any = null;
    return function () {
      $timeout.cancel(timeout);
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;

      timeout = $timeout(function (this: any) {
        callback.apply(this, args);
      }, interval);
    };
  }

  function throttle(fn: any, interval: any, scope: any) {
    interval = interval || 1000;
    let last: any, deferTimer: any;

    return function (this: any) {
      const context = scope || this;
      const now = +new Date(),
        // eslint-disable-next-line prefer-rest-params
        args = arguments;
      if (last && now < last + interval) {
        clearTimeout(deferTimer);
        deferTimer = $timeout(function () {
          last = now;
          fn.apply(context, args);
        }, interval);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  }

  function getCurrentHost() {
    return $location.host().split('.').at(-2) ?? 'localhost';
  }

  function daysInMonth(date: any) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  function isSameDay(dateOne: any, dateTwo: any) {
    if (!dateOne || !dateTwo) {
      return false;
    }
    if (dateOne.getYear() != dateTwo.getYear()) {
      return false;
    }
    if (dateOne.getMonth() != dateTwo.getMonth()) {
      return false;
    }
    return dateOne.getDate() == dateTwo.getDate();
  }

  function addBusinessDays(initialDate: any, days: any) {
    initialDate = new Date(initialDate.getTime());
    const day = initialDate.getDay();
    initialDate.setDate(
      initialDate.getDate() +
        days +
        (day === 6 ? 2 : +!day) +
        Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2,
    );
    return initialDate;
  }

  function getResource(model: any) {
    return ResourcesService.getResource(model);
  }

  function dismissAllModals() {
    return $uibModalStack.dismissAll();
  }

  function getCounters(
    models: string[],
    params: QueryParams = {},
    level?: string,
    force?: any,
  ): Promise<any> {
    if (models && models.length === 1 && models[0]) {
      return getResource(models[0])
        .query({ count_only: true, ...params })
        .$promise.then(function (data: any) {
          const res: any = {};
          res[models[0]] = data.count;
          return res;
        })
        .catch((error: any) => errorClb(error));
    }

    const conf = {
      storage: 'counters',
      apiUrl: '/api/core/counters/',
    };

    return getEntities(conf, models, level, force);
  }

  function getPredictions(models: string[], params?: QueryParams, level?: string, force?: boolean) {
    const conf = {
      storage: 'predictions',
      apiUrl: '/api/core/predictions/',
    };

    const isSingleModel = models && models.length == 1 && models[0];
    const cachedModel = _cache[conf.storage][level ?? 'default'][models[0]];
    const isSearch = params?.search && typeof params.search === 'string' && params.search.length;

    if (cachedModel?.params && cachedModel.params.search === '') {
      delete cachedModel.params.search;
    }

    if (params && params.search === '') {
      delete params.search;
    }

    const isParamsUpdated = cachedModel?.params && !isObjectsEqual(params, cachedModel.params);

    const getPredictionsResource = (conf: any, models: any, params: any) => {
      const resource = getResource(models[0]);

      if (!resource?.predictions) {
        return Promise.resolve({ [models[0]]: [] });
      }

      return getResource(models[0])
        .predictions(params)
        .$promise.then((data: any) => {
          const { results } = data;
          _cache[conf.storage][level ?? 'default'][models[0]] = {
            results,
            params,
          };
          setCache(_cache);
          return { [models[0]]: results };
        })
        .catch((error: any) => errorClb(error));
    };

    if (force || isSearch || isParamsUpdated || isSingleModel) {
      return getPredictionsResource(conf, models, params);
    } else if (cachedModel) {
      return Promise.resolve({ [models[0]]: cachedModel.results });
    }

    return getEntities(conf, models, level, force);
  }

  function isObjectsEqual(firstObj: any, secondObj: any) {
    return JSON.stringify(firstObj) === JSON.stringify(secondObj);
  }

  function getCachedEntities(conf: any, modelsUpdate: any, level: string, force?: boolean) {
    return new Promise((resolve) => {
      if (force || modelsUpdate.length) {
        $http
          .get(conf.apiUrl, { params: { models: modelsUpdate } })
          .then(function (data: any) {
            _cache[conf.storage][level] = ng.extend(_cache[conf.storage][level], data.data.results);
            setCache(_cache);
            resolve(_cache[conf.storage][level]);
          })
          .catch(resetCache);
      } else {
        resolve(_cache[conf.storage][level]);
      }
    });
  }

  function getEntities(
    this: any,
    conf: { storage: keyof typeof _cache },
    models: string[],
    level?: string,
    force?: boolean,
  ) {
    level = level ?? 'default';
    force = force === true;
    _cache[conf.storage][level] = _cache[conf.storage][level] || {};
    models = models || ResourcesService.getModelNames();
    let modelsUpdate = (force && models) || [];
    if (!force) {
      modelsUpdate = models.filter((model: any) => {
        return !_cache[conf.storage][level][model];
      });
    }

    return getCachedEntities.apply(this, [conf, modelsUpdate, level, force]);
  }

  function updateCounters(counters: any, level: any) {
    level = level || 'default';
    _cache.counters[level] = _cache.counters[level] || {};
    if (_cache.counters[level].promise) {
      return _cache.counters[level].promise.then(function () {
        ng.extend(_cache.counters[level], counters);
        $rootScope.$broadcast('gt-counters-updated', {});
      });
    }
    ng.extend(_cache.counters[level], counters);
    $rootScope.$broadcast('gt-counters-updated', {});
    setCache(_cache);
  }

  function newConnections(connections?: any) {
    cancelConncections();
    if (connections && !ng.isArray(connections)) {
      connections = [connections];
    }
    _connections = connections || [];
  }

  function cancelConncections() {
    _connections = _connections || [];
    _connections.forEach(function (item: any) {
      let conn;
      if (item.$cancelRequest) {
        conn = item;
      } else if (item.$$state?.value) {
        conn = item.$$state.value;
      }
      if (conn?.$cancelRequest) {
        conn.$cancelRequest();
      }
    });
    _connections = [];
  }

  function addConnection(connection: any) {
    _connections = _connections || [];
    _connections.push(connection);
    return connection;
  }

  function overlay(action: 'show' | 'hide', newConn?: boolean) {
    if (newConn) {
      newConnections();
    }
    if (action === 'show') {
      if (activeOverlays === 0) {
        $rootScope.$broadcast('spinner-toggled', true);
      }
      activeOverlays++;
    } else if (action === 'hide') {
      activeOverlays--;
      if (activeOverlays <= 0) {
        activeOverlays = 0;
        $rootScope.$broadcast('spinner-toggled', false);
      }
    }
  }

  function getIcon(modelName: string) {
    return _iconsMap[ResourcesService.getModelName(modelName) || modelName];
  }

  function notify(msg: string, method?: string) {
    return NotificationService.notify(translate(msg), method);
  }

  function errorClb(this: any, data: any) {
    overlay('hide');
    if (typeof data.data == 'string' || data.data === null) {
      return notify('A server error occurred. Please contact the administrator.', 'error');
    }
    if (data.data?.detail?.message) {
      return notify(data.data.detail.message, 'error');
    }
    if (data.data.positions) {
      data.data.positions.forEach((position: any) => {
        ng.forEach(position, (value: any, key: any) =>
          this.notify('position ' + key + ': ' + position[key], 'error'),
        );
      });
    }
    if (ng.isArray(data.data)) {
      data.data.forEach((singleError: any) => {
        if (typeof singleError === 'string') {
          this.notify(singleError, 'error');
        } else {
          ng.forEach(singleError, (value: any, key: any) =>
            this.notify(key + ': ' + value[0], 'error'),
          );
        }
      });
    } else {
      ng.forEach(data.data, (value: any, key: any) => {
        if (Array.isArray(data.data[key])) {
          data.data[key].forEach((error: any) => {
            if (typeof error === 'string') {
              this.notify(`${key}: ${error}`, 'error');
            } else {
              ng.forEach(error, (value: any, key: any) =>
                this.notify(key + ': ' + value[0], 'error'),
              );
            }
          });
        } else {
          this.notify(key + ': ' + data.data[key], 'error');
        }
      });
    }
  }

  function convertDate(dateField: any, mask: any) {
    mask = mask || 'YYYY-MM-DD';
    if (!dateField) {
      return dateField;
    }
    return moment(dateField).format(mask);
  }

  function goToDetails(modelName: any, objectId: any) {
    modelName = ResourcesService.getModelName(modelName);
    return $state.go(states[modelName] || 'gt.home', { id: objectId });
  }

  function goDetails(contentType: string, objectId: number, tab?: string) {
    return ResourcesService.getResource('contenttypes.contenttype')
      .get({ id: contentType })
      .$promise.then(function (data: any) {
        const key: string = data.app_label
          ? data.app_label.toLowerCase() + '.' + data.model.toLowerCase()
          : data.model.toLowerCase();

        return $state.go(states[key] || 'gt.home', { id: objectId, tab: tab });
      });
  }

  function goPage(pageState: string, params = {}) {
    return $state.go(pageState || 'gt.home', params);
  }

  function convertCurrency(value: any, fromSign: any) {
    const local = (fromSign == 'USD' && toString) || fromSign,
      defer = $q.defer();
    if (!currencyExchange) {
      currencyExchange = defer;
      ResourcesService.getResource('finances.currencyexchange').query(
        { currency: local },
        function (data: any) {
          currencyExchange = data;
          defer.resolve(_convert(value, fromSign));
        },
      );
    } else if (currencyExchange.promise) {
      currencyExchange.promise.then(function () {
        defer.resolve(_convert(value, fromSign));
      });
    } else {
      defer.resolve(_convert(value, fromSign));
    }

    return defer.promise;
  }

  function _convert(value: any, from: any) {
    value = parseFloat(value);
    if (from == 'USD') {
      return value * currencyExchange.rate;
    }
    return value / (currencyExchange?.rate || 1);
  }

  function capitalize(value: any) {
    return value ? value.charAt(0).toUpperCase() + value.substr(1).toLowerCase() : '';
  }

  function translate(text: string) {
    return $filter<(str: string) => string>('translate')(text);
  }

  function getObjectTitle(data: any) {
    return (
      data.name ||
      data.title ||
      data.model ||
      data.number ||
      data.contract_number ||
      (data.first_name && data.last_name && data.first_name + ' ' + data.last_name) ||
      data.username ||
      data.account_name ||
      data.account ||
      data.client_name ||
      data.id
    );
  }

  function weekOfMonth(date: any) {
    const prefixes: any = [1, 2, 3, 4, 5];

    return prefixes[0 | (moment(date).date() / 7)];
  }

  function applyVat(value: any, volume: any, vatValue: any, vatType?: any) {
    return $http.get('/api/core/apply-vat/', {
      params: {
        value: value,
        volume: volume,
        vat_value: vatValue,
        vat_type: vatType,
      },
    });
  }

  function calcBusinessDays(startDate: any, businessDays: any) {
    return $http.get('/api/core/calc-business-days/', {
      params: {
        start_date: startDate,
        business_days: businessDays,
      },
    });
  }

  function getBUQueryParams() {
    return {};
  }

  function getDefaultOriginId() {
    if ($rootScope.user.settings.DEFAULT_VALUES.origin_of_crop) {
      return ResourcesService.getResource('location.country')
        .query({
          search: $rootScope.user.settings.DEFAULT_VALUES.origin_of_crop,
        })
        .$promise.then((data: any) => data.results.shift()?.id);
    } else {
      return $q.when();
    }
  }

  function getCustomStatusId(modelName: any) {
    return ResourcesService.getResource('core.customstatus')
      .query({
        content_type__model: modelName,
        is_default: 1,
      })
      .$promise.then(function (data: any) {
        return data.results.shift()?.id;
      });
  }

  function getDefaultFinanceOwnerId(field: any, invoiceType: any) {
    if (!$rootScope.user.settings.DEFAULT_VALUES.finance_owner) {
      return $q.when();
    }
    if (
      (field == 'clientrole_from' && invoiceType == 'outgoing') ||
      (field == 'clientrole_to' && invoiceType == 'incoming')
    ) {
      return ResourcesService.getResource('clients.clientrole')
        .predictions({
          search: $rootScope.user.settings.DEFAULT_VALUES.finance_owner,
          role_name: 'owner',
        })
        .$promise.then(function (data: any) {
          return data.results.shift()?.id;
        });
    } else {
      return $q.when();
    }
  }

  function filterEmptyParams(queryParams: QueryParams) {
    Object.keys(queryParams).forEach((key) => {
      const param = queryParams[key];
      if (
        param == null ||
        param == undefined ||
        param === typeof undefined ||
        param === 'true' ||
        (!param && typeof queryParams[key] == 'string') ||
        (key == '_theadFilters' && queryParams[key] == 'false')
      ) {
        delete queryParams[key];
      } else if (Array.isArray(param)) {
        queryParams[key] = param.filter((i) => {
          return i != null && i != undefined && i != 'undefined';
        });
      } else if (param === true) {
        queryParams[key] = 1;
      }
    });
    return queryParams;
  }

  function downloadFile(path: any) {
    const _document = $document[0] || {};
    const element = _document.createElement('a');
    element.setAttribute('href', path);
    element.setAttribute('download', '');
    element.style.display = 'none';
    _document.body.appendChild(element);
    element.click();
    _document.body.removeChild(element);
  }

  function copyToClipboard(value: any) {
    const _document = $document[0];
    const copyElement = _document.createElement('span');
    copyElement.appendChild(_document.createTextNode(value));
    copyElement.id = 'tempCopyToClipboard';
    copyElement.style = 'white-space: pre';
    ng.element(_document.body.append(copyElement));
    const range = _document.createRange();
    range.selectNode(copyElement);
    $window.getSelection()?.removeAllRanges();
    $window.getSelection()?.addRange(range);

    // copy & cleanup
    _document.execCommand('copy');
    $window.getSelection()?.removeAllRanges();
    copyElement.remove();
    notify(gettext(translate('Copied to clipboard')));
  }

  function addDaysToDate(date: any, days: any) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function setCache(cache: any) {
    try {
      localStorage.setItem('gt-resources-cache', JSON.stringify(cache));
    } catch (error) {
      console.error('Failed to set gt-resource-cache: ', error);
    }
  }

  function getCache() {
    try {
      const cachedResource = localStorage.getItem('gt-resources-cache');
      return cachedResource ? JSON.parse(cachedResource) : DefaultCache;
    } catch (error) {
      console.error('Failed to get gt-resource-cache: ', error);
      return DefaultCache;
    }
  }

  function updateCahce(storage: any, key: any, value: any, level = 'default') {
    if (typeof value === 'function') {
      errorClb(gettext('Cached value should be only number, string, array or object'));
    } else {
      _cache[storage][level][key] = value;
      setCache(_cache);
    }
  }

  function getAllCache() {
    return _cache;
  }

  function getCachedValue(storage: any, key: any, level = 'default') {
    if (_cache[storage][level]) {
      return _cache[storage][level][key];
    } else {
      resetCache(storage);
      return null;
    }
  }

  function resetCache(storage: any) {
    if (storage) {
      _cache[storage] = { default: {} };
    } else {
      _cache = DefaultCache;
    }
    localStorage.setItem('gt-resources-cache', JSON.stringify(_cache));
  }

  function getYearList() {
    return $rootScope.user.settings.CROP_YEAR_LIST.map((year: any) => ({
      id: year,
      title: year,
    }));
  }

  function getMonthList() {
    return [
      { id: 12, title: '12' },
      { id: 11, title: '11' },
      { id: 10, title: '10' },
      { id: 9, title: '09' },
      { id: 8, title: '08' },
      { id: 7, title: '07' },
      { id: 6, title: '06' },
      { id: 5, title: '05' },
      { id: 4, title: '04' },
      { id: 3, title: '03' },
      { id: 2, title: '02' },
      { id: 1, title: '01' },
    ];
  }
}

(function () {
  'use strict';
  ng.module('core.legacy').factory('GtUtils', Service);

  Service.$inject = [
    '$timeout',
    '$rootScope',
    '$uibModalStack',
    '$q',
    '$http',
    '$state',
    '$filter',
    '$document',
    '$window',
    '$location',
    '$injector',
    'moment',
    'gettext',
  ];
})();

export type GtUtilsService = ReturnType<typeof Service>;
