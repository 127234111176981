import { coreGlobalSearchRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { SearchItem } from '../lib/types';

@singleton()
export class GlobalSearchRepository {
  async search(text: string): Promise<SearchItem[]> {
    const data = (await coreGlobalSearchRetrieve({ search: text })) as Record<
      string,
      { id: number; title: string }[]
    >;

    return Object.entries(data)
      .map(([group, items]) =>
        items.map(({ id, title }) => ({
          title,
          group,
          icon: 'HouseIcon' as const,
          action: () => console.info(`Search result ${group} #${id} clicked`),
        })),
      )
      .flat();
  }
}
