import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('consignmentsList', directive);

  function directive() {
    return {
      template: require('./consignments-list.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        queryParams: '<',
        consignments: '<',
        totals: '<',
        count: '<',
        contract: '<',
        globalAddButton: '<',
        filterLevel: '<?',
        tableName: '<?',
        readonly: '<',
        createDisbursementBl: '<',
      },
    };
  }

  Controller.$inject = [
    '$scope',
    '$timeout',
    'GtUtils',
    'ContractsService',
    'gettext',
    'FinancesService',
    'gtFilterService',
    'DocumentsService',
    'CustomValuesService',
    'DisbursementBlService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    gettext: ng.gettext.gettextFunction,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    DocumentsService: any,
    CustomValuesService: any,
    DisbursementBlService: any,
  ) {
    const vm = this;

    vm.updateData = updateData;
    vm.remove = remove;
    vm.clone = clone;
    vm.openConsignmentsModal = openConsignmentsModal;
    vm.refreshCheckboxes = refreshCheckboxes;
    vm.createInvoice = createInvoice;
    vm.createDbls = createDbls;
    vm.openDocModal = openDocModal;
    vm.getCustomFieldTableColumns = getCustomFieldTableColumns;
    vm.toggleSelection = toggleSelection;
    vm.invoicingType = 'invoice';
    vm.hovering = false;
    vm.count = 0;
    vm.tableData = {};
    vm.tableOptions = {};

    ////////////////

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'consignments-list';
      vm.tableName = vm.tableName || vm.filterLevel;
      vm.contractType = vm.contract.contract_type || 'purchase';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
      setTableOptions();
    };

    vm.$onChanges = function (changesObj: any) {
      if (changesObj.consignments || changesObj.totals) {
        $timeout(function () {
          vm.tableData = {
            rows: vm.consignments || [],
            count: vm.count,
            total: vm.totals || [],
          };
        });
      }
    };

    function toggleSelection() {
      vm.consignments.forEach((consignment: any) => {
        consignment.$_inputedNumber = consignment.volume_to_connect_dbl;
      });
      setTableOptions();
    }

    function updateData(params: any) {
      $scope.$emit('consignments-list__update', params);
      vm.invoicing = false;
      setTableOptions();
      GtUtils.overlay('hide');
    }

    function remove(consignment: any) {
      const msg = gettext('Are you sure that you want delete this consignment?');
      if (!confirm(msg)) {
        return;
      }
      $scope.$emit('consignments-list__remove', consignment);
    }

    function openDocModal(consignment: any) {
      DocumentsService.attachDocumentModal(consignment, 'consignment').then(function () {
        vm.onUpdate();
      });
    }

    function getCustomFieldTableColumns() {
      return CustomValuesService.getCustomFieldTableColumns({
        purpose_model: 'consignment',
      });
    }

    function openConsignmentsModal(consignment: any) {
      consignment = consignment || {};
      consignment = { ...consignment, ...vm.queryParams };
      if (vm.contract) {
        consignment = { manager: vm.contract.responsible, ...consignment };
      }
      return ContractsService.consignmentsModal(consignment).then(updateData);
    }

    function clone(consignmentId: any) {
      return ContractsService.cloneConsignment(consignmentId).then(updateData);
    }

    function refreshCheckboxes(type: any) {
      vm.invoicingType = type;
      vm.consignments.forEach(function (item: any) {
        item._showCheckbox = vm.invoicing;
        item._showCheckbox = true;
        if (type == 'invoice' && item.invoice) {
          item._showCheckbox = false;
        }
        if (type == 'dbl' && item.volume_to_connect_dbl <= 0) {
          item._showCheckbox = false;
        }
      });
    }

    function getNewFinance() {
      return new Promise((resolve, reject) => {
        const invoiceType = (vm.contractType == 'sale' && 'outgoing') || 'incoming';
        const idsToInvoice: any = [];
        const newFinance = {
          invoice_type: invoiceType,
          currency: vm.contract.currency,
          currency_exchange: vm.contract.currency_exchange,
          condition: 'balance',
          clientrole_from: vm.contract.supplier,
          clientrole_to: vm.contract.buyer,
        };
        let extraData = {};

        vm.consignments.forEach(function (consignment: any) {
          if (!consignment.$_selected) {
            return;
          }
          idsToInvoice.push(consignment.id);
        });

        ContractsService.Consignments.calcAmounts(
          { consignments: idsToInvoice },
          (data: any) => {
            extraData = {
              financePositions: [
                {
                  use: 'cargo',
                  crop: vm.contract.cargo,
                  contract: vm.contract.id,
                  price_per_piece: vm.contract.price,
                  quantity: Math.round(data.volume * 1000) / 1000,
                  amount: Math.round(data.amount * 100) / 100,
                },
              ],
            };
            resolve({ newFinance, extraData });
          },
          (e: Error) => {
            GtUtils.errorClb(e);
            reject(e);
          },
        );
      });
    }

    function createInvoice() {
      getNewFinance()
        .then((data: any) => {
          FinancesService.financeModal(data.newFinance, data.extraData).then((data: any) => {
            if (!data || data == 'cancel') {
              return;
            }
            vm.consignments.forEach(function (consignment: any) {
              if (!consignment.$_selected) {
                return;
              }
              consignment.invoice = data.id;
              consignment._dirty = true;
            });

            vm.newFinance = {};
            return editSave();
          });
        })
        .catch((e) => GtUtils.errorClb(e));
    }

    function createDbls() {
      DisbursementBlService.createDisbursementBLAfterCheck(
        vm.consignments.filter((item: any) => {
          return item.$_selected;
        }),
      ).then(vm.updateData);
    }

    function editSave() {
      GtUtils.overlay('show');
      let chain = Promise.resolve();
      vm.consignments.forEach(function (consignment: any) {
        if (!consignment._dirty) {
          return;
        }
        GtUtils.overlay('show');

        chain = chain.then(function () {
          return ContractsService.Consignments.update(
            consignment,
            function () {
              GtUtils.overlay('show');
            },
            (e: any) => GtUtils.errorClb(e),
          ).$promise;
        });
      });
      return chain.then(function () {
        vm.updateData();
        GtUtils.overlay('show');
      }, vm.updateData);
    }

    function setTableOptions() {
      const options: any = {
        tableName: vm.tableName,
        tableClass:
          'table-responsive table-condensed main-table contract-charges-table warehouse-shipment-table table-hover',
        filterLevel: vm.filterLevel,
        applyFilters: (params: any) => {
          gtFilterService.updateQueryParams(params.params, vm.filterLevel);
        },
        templateArgs: {
          openConsignmentsModal: (item: any) => openConsignmentsModal(item),
          createDisbursementBl: (item: any) => vm.createDisbursementBl(item),
          clone: (id: any) => clone(id),
          remove: (item: any) => remove(item),
          createInvoice: () => createInvoice(),
          createDbls: () => createDbls(),
          editSave: () => editSave(),
          openDocModal: (item: any) => openDocModal(item),
        },
        columnDefs: [] as any[],
        tabs: [],
        configurable: true,
        showMore: true,
        changePageSize: true,
        selection: vm.invoicing,
        numberInput: vm.invoicing && vm.invoicingType == 'dbl',
        selectionCellTemplate: `
          <input
            ng-if="item._showCheckbox"
            type="checkbox"
            ng-model="item.$_selected"
            style="margin-right:5px; float: none"
          />
        `,
        numberInputCellTemplate: `
          <input
            ng-class="{'positive-number': item.volume_to_connect_dbl > 0}"
            ng-if="item._showCheckbox"
            type="number"
            ng-model="item.$_inputedNumber"
            style="margin-right:5px;"
          />
        `,
      };
      options.columnDefs = [
        {
          title: gettext('Number'),
          columnName: 'number',
          class: 'td-left-align',
          cellTemplate: `
            <div>
              <ul
                class="nav navbar-nav item-settings-dropdown"
                ng-mouseenter="args.setHovering(true)"
                ng-mouseleave="args.setHovering(false)"
              >
                <li>
                  <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                    #{[{ item.number }]}
                    <i class="fa fa-sort-desc hover-element"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        permission
                        permission-only="'change_consignment'"
                        ng-click="args.openConsignmentsModal(item)"
                        class="btn btn-xs aligned-btn"
                      >
                        <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'change_consignment'"
                        ng-click="args.clone(item.id)"
                        class="btn btn-xs aligned-btn"
                      >
                        <i class="fa fa-files-o"></i> <translate>Clone</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        ng-click="args.remove(item)"
                        permission
                        permission-only="'delete_consignment'"
                        data-tip="{[{ 'delete consignment'|translate }]}"
                        class="btn btn-xs aligned-btn"
                      >
                        <i class="fa fa-trash"></i> <translate>Delete</translate>
                      </a>
                      <a
                        data-tip="{[{ 'delete consignment'|translate }]}"
                        class="btn btn-xs aligned-btn"
                        ng-click="args.openDocModal(item)"
                      >
                        <i class="fa fa-upload"></i><translate>Documents</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'add_disbursementbl'"
                        ng-click="args.createDisbursementBl(item)"
                        class="btn btn-xs aligned-btn"
                        ng-disabled="!item.volume_to_connect_dbl"
                      >
                        <i class="fa fa-add"></i> <translate>Create DisbursementBL</translate>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <span
                class="tooltip-centered"
                ng-if="item.dbls_data && item.volume_to_connect_dbl > 0"
                data-tip="Volume of all connected disbursementBLs is less than consignment volume"
              >
                <i class="fa fa-exclamation-triangle"></i>
              </span>
            </div>
          `,
          totalTemplate: /*html*/ `
              <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
            `,
        },

        {
          title: gettext('Dbl'),
          columnName: 'dbls_data',
          class: 'td-left-align',
          cellTemplate: `
          <span ng-repeat="dbl in item.dbls_data">
          <i class="fa fa-file"></i> {[{ dbl.number }]}
          <div class="clearfix"></div>
        </span>

          `,
        },
        {
          title: gettext('Amount to connect DBL'),
          columnName: 'volume_to_connect_dbl',
          class: 'td-left-align',
          cellTemplate: /*html*/ `
            {[{ item.volume_to_connect_dbl || 0 | number:3 }]}
            <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
        },
        {
          title: gettext('Business unit'),
          columnName: 'bu_title',
          class: 'td-left-align',
          cellTemplate: `
          <div class="small">{[{ item.bu_title || item.consignment_bu_title}]}</div>
          `,
        },
        {
          title: gettext('Contract'),
          columnName: 'contract',
          class: 'td-left-align',
          cellTemplate: `
            <span ng-if="item.contract">
              <a
                ui-sref="gt.page.contract({id: item.contract })"
                class="btn-link"
                target="_blank"
              >
                <i class="fa fa-file-text"></i> {[{ item.contract_number | cut:true:30:' ...'
                }]}
              </a>
            </span>
          `,
        },
        {
          title: gettext('Contract'),
          columnName: 'contract_type',
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'contract_list',
              label: gettext('commodity'),
              resource: 'crops.Crop',
            },
          ],
          cellTemplate: `
          <span
            ng-class="{'label label_default': item.contract_type == 'sale', 'label label_warning': item.contract_type == 'purchase'}"
          >
            <i
              class="fa"
              ng-class="{ 'fa-arrow-up': item.contract_type == 'sale', 'fa-arrow-down': item.contract_type == 'purchase' }"
            ></i>
            {[{ item.contract_type }]}
          </span>
          `,
        },
        {
          title: gettext('Passports'),
          columnName: 'passport',
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'contract_passport_list',
              label: gettext('passport'),
              resource: 'passports.Passport',
            },
          ],
          cellTemplate: `
          <span ng-repeat="passport in item.passports_data">
            <a class="btn-link" ng-href="/#/passport/{[{passport.id}]}" ng-if="passport.id">
              <span> <i class="fa fa-exchange"></i> {[{ passport.title }]} </span>
              <span class="smaller-label label-smoke" ng-if="passport.volume">({[{ passport.volume }]}  <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span> )</span>
            </a>
          </span>
          `,
        },
        {
          title: gettext('Supplier'),
          columnName: 'contract_supplier_name',
          class: 'td-left-align',
          cellTemplate: `
          <i class="fa fa-bookmark-o"></i> {[{ item.contract_supplier_name || '---' }]}
          `,
        },

        {
          title: gettext('Buyer'),
          columnName: 'contract_buyer_name',
          class: 'td-left-align',
          cellTemplate: `
          <i class="fa fa-bookmark"></i> {[{ item.contract_buyer_name || '---' }]}
          `,
        },
        {
          columnName: 'receiver',
          title: gettext('Receiver'),
          class: 'td-right-align',
          cellTemplate: `
            <span ng-if="item.receiver_id">
              <a ui-sref="gt.page.client({id: item.receiver_id})">
                {[{item.receiver_name || '---' }]}
              </a>
            </span>
          `,
        },
        {
          columnName: 'producer',
          title: gettext('Producer'),
          class: 'td-right-align',
          cellTemplate: `
            <span ng-if="item.producer_id">
              <a ui-sref="gt.page.client({id: item.producer_id})">
                {[{item.producer_name || '---' }]}
              </a>
            </span>
          `,
        },
        {
          columnName: 'voyage',
          title: gettext('Voyage'),
          class: 'td-right-align',
          cellTemplate: `
            <span ng-if="item.voyage_id">
              <a ui-sref="gt.old-page.vessel({id: item.vessel})">
                <i class="fa fa-ship"></i>{[{item.voyage_name}]} ({[{item.vessel_name}]})
              </a>
            </span>
          `,
        },
        {
          columnName: 'stockReserveUnit',
          title: gettext('Stock Reserve Unit'),
          class: 'td-right-align',
          cellTemplate: `
            <span ng-if="item.stock_reserve_unit_id">

              <a ui-sref="logistics.stockReserveUnit({id: item.stock_reserve_unit_id})">
                <i class="fa fa-lock"></i>{[{item.stock_reserve_unit_title || '---' }]}
              </a>
            </span>
          `,
        },

        {
          title: gettext('Qualities'),
          columnName: 'qualities',
          class: 'td-left-align',
          cellTemplate: `
          <span class="smaller-label">
          <span
            ng-repeat="quality in item.qualities "
            class="tooltip"
            data-tip="{[{ quality.title }]} "
          >
            {[{ quality.title | cut:true:4:'=' }]} {[{ quality.value | gtDecimal:1 }]};
          </span>
        </span>
          `,
        },
        {
          title: gettext('Date'),
          columnName: 'date',
          class: 'td-left-align',
          cellTemplate: `
          <i class="fa fa-calendar"></i> {[{ item.date | date:'dd.MM.yy' || "---"}]}
          `,
        },
        {
          columnName: 'commodity',
          title: gettext('commodity'),
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'commodity_list',
              label: gettext('commodity'),
              resource: 'crops.Crop',
            },
          ],
          cellTemplate: /*html*/ `
            <span
              class="bigger-label"
              ng-if="item.commodity_title"
            >
             <i class="fa fa-wheat-awn"></i> {[{ item['crop_' + [$root.user.settings.CROP_TITLE_DISPLAY_MODE]] ||
              item.commodity_title }]}
            </span>
            <span
            class="bigger-label"
            ng-if="item.contract_cargo_title && !item.commodity_title"
          >
            {[{ item.contract_cargo_title }]}
            </span>
          `,
        },
        {
          title: gettext('Volume'),
          columnName: 'volume',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.volume || 0 | number:3 }]}
          <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
          totalTemplate: /*html*/ `
            {[{ item.volume_sum | number: 3}]}
            <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
            `,
        },
        {
          title: gettext('Write of volume'),
          columnName: 'write_off_volume',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.writeof_volume || 0 | number:3 }]}
          <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
        },
        {
          title: gettext('Balance volume'),
          columnName: 'balance_volume',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.balance_volume || 0 | number:3 }]}
          <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
          totalTemplate: /*html*/ `
          {[{ item.balance_volume_sum | number: 3}]}
          <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
        },
        {
          title: gettext('Price'),
          columnName: 'contract_price',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.contract_price | number: 2 || 0}]} {[{ item.contract_currency }]}
          `,
        },
        {
          title: gettext('Vat'),
          columnName: 'vat_contract_price',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.vat_contract_price | number: 2 || 0 }]} {[{ item.contract_currency }]}
          `,
        },

        {
          title: gettext('Price with VAT'),
          columnName: 'contract_price_with_vat',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.contract_price_with_vat | number: 2 || 0}]} {[{
            item.contract_currency }]}
          `,
        },
        {
          title: gettext('Amount'),
          columnName: 'amount',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.amount | number: 2 || 0}]} {[{ item.contract_currency }]}
          `,
          totalTemplate: /*html*/ `
          <div ng-repeat="total in item.data">
          {[{ total.amount | number: 2 || 0}]} {[{ total.currency_symbol }]}
        </div>
          `,
        },
        {
          title: gettext('VAT amount'),
          columnName: 'vat_amount',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.vat_amount | number: 2 || 0 }]} {[{ item.contract_currency }]}
          `,
          totalTemplate: /*html*/ `
          <div ng-repeat="total in item.data">
          {[{ total.vat_amount | number: 2 || 0}]} {[{ total.currency_symbol }]}
        </div>
          `,
        },
        {
          title: gettext('Amount with VAT'),
          columnName: 'amount_with_vat',
          class: 'td-right-align',
          cellTemplate: `
          {[{ item.amount_with_vat | number: 2 || 0}]} {[{ item.contract_currency }]}
          `,
          totalTemplate: /*html*/ `
          <div ng-repeat="total in item.data">
            {[{ total.amount_with_vat | number: 2 || 0}]} {[{ total.currency_symbol }]}
          </div>
          `,
        },
        {
          title: gettext('Note'),
          columnName: 'additional_info',
          class: 'td-left-align',
          cellTemplate: `
          {[{ item.additional_info }]}
          `,
        },
        {
          title: gettext('Warehouse'),
          columnName: 'warehouse',
          class: 'td-left-align',
          cellTemplate: `
          <i class="fa {[{ $root.gtUtils.getIcon('warehouses.' + item.warehouse_data.type) }]}"></i>
          <a
            class="btn-link"
            ui-sref="logistics.warehouseDetails({ id: item.warehouse_data.id })">
            {[{ item.warehouse_data.title }]}
          </a>
          <span class="label-smoke smaller-label" ng-if="item.warehouse_data.port.name">
            {[{ item.warehouse_data.port.name }]}
          </span>
          `,
        },
        {
          title: gettext('Editor'),
          columnName: 'editor',
          class: 'td-left-align',
          cellTemplate: `
          <span>
          <div
            class="user-avatar"
            ng-if="item.editor_first_name && item.editor_avatar.length"
          >
            <img ng-src="/pictures/{[{item.editor_avatar}]}" />
          </div>
          <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
            {[{ item.editor_first_name | cut:true:1:' ' }]}
          </div>
        </span>
          `,
        },
      ];
      return getCustomFieldTableColumns().then((columns: any) => {
        options.columnDefs.push(...columns);
        vm.tableOptions = options;
      });
    }
  }
})();
