import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './offset-modal.html?raw';

export const OffsetModal = {
  bindings: {
    offset: '<',
    modalInstance: '<',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    '$q',
    '$window',
    'gettext',
    'OffsetService',
    'FormFieldParamsService',
    'GtUtils',
    'ReassignmentService',
    'DisbursementBlService',
    'FinancesService',
    'ClientsService',
    'gtFilterService',
    'CoreService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $window: ng.IWindowService;
      ClientsService: any;
      CoreService: CoreService;
      DisbursementBlService: any;
      FinancesService: FinancesService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      OffsetService: any;
      ReassignmentService: any;
      disbursementBLFilterLevel: string;
      disbursementBLQueryParams: QueryParams = {};
      disbursmentBLTableApi: any;
      disbursmentBLTableData: any;
      fields: any;
      filterLevel: string;
      form: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      modalInstance: any;
      offset: any;
      positionsTableCount: number;
      positionsTableRows: any;
      reassignmentTableApi: any;
      reassignmentsFilterLevel: string;
      reassignmentsQueryParams: QueryParams = {};
      selectedDisbursementBL: any;
      selectedReassignmentPositions: any;
      updating: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $state: ng.ui.IStateService,
        $q: ng.IQService,
        $window: ng.IWindowService,
        gettext: ng.gettext.gettextFunction,
        OffsetService: any,
        FormFieldParamsService: FormFieldParamsService,
        GtUtils: GtUtilsService,
        ReassignmentService: any,
        DisbursementBlService: any,
        FinancesService: FinancesService,
        ClientsService: any,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$q = $q;
        this.$window = $window;
        this.gettext = gettext;
        this.ReassignmentService = ReassignmentService;
        this.DisbursementBlService = DisbursementBlService;
        this.FinancesService = FinancesService;
        this.ClientsService = ClientsService;
        this.OffsetService = OffsetService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;

        this.updating = true;
        this.form = undefined;
        this.fields = undefined;
        this.offset = { amount: 0, reassignments: [], disbursement_bls: [] };
        this.reassignmentsQueryParams = {};
        this.disbursementBLQueryParams = {
          date_predicate: 'date',
          status_list: ['new', 'ready', 'process'],
        };
        this.reassignmentsFilterLevel = 'offset-reassignments-edit-modal';
        this.disbursementBLFilterLevel = 'offset-disbursement-bl-edit-modal';
        this.positionsTableRows = [];
        this.positionsTableCount = 0;
        this.disbursmentBLTableData = { rows: [], count: 0, total: {} };

        this.filterLevel = 'offset-edit-modal';
        this.reassignmentTableApi = undefined;
        this.disbursmentBLTableApi = undefined;
        this.selectedDisbursementBL = [];
        this.selectedReassignmentPositions = [];
      }

      $onInit() {
        this.gtFilterService.subscribe(this.filterLevel, () => this.updateTables());

        this.$scope.$on('disbursementbl-connection__update', (ev: any, data: any) => {
          this.onDisbursmentBLConnect(data);
        });
        this.resetDisbursementBLFilters();
        this.resetReassignmentFilters();
        this.offset.reassignments = this.offset.reassignments || [];
        this.offset.disbursement_bls = this.offset.disbursement_bls || [];

        let promise;
        if (this.offset.id) {
          promise = this.updateData();
        } else {
          promise = this.$q.when();
        }

        promise.then(() => {
          this.updateTablesDistributor(this.offset.distributor);
          this.$scope.$watch('$ctrl.offset.distributor', (distributor: any) =>
            this.updateTablesDistributor(distributor),
          );
          this.updateFields();
          this.updating = false;
        });
      }

      updateOffsetAmount() {
        this.offset.amount = 0;

        // Calculate reassignments amount
        this.selectedReassignmentPositions.forEach((position: any) => {
          this.offset.amount = Math.round((this.offset.amount + position.amount) * 100) / 100;
        });

        // Calculate dbls amount
        this.selectedDisbursementBL.forEach((position: any) => {
          this.offset.amount = Math.round((this.offset.amount + position.amount) * 100) / 100;
        });
      }

      updateTablesDistributor(distributor: any) {
        this.disbursementBLQueryParams.purchase_contract_supplier = distributor;
        this.reassignmentsQueryParams.distributor = distributor;
        this.gtFilterService.updateQueryParams(
          this.disbursementBLQueryParams,
          this.disbursementBLFilterLevel,
        );
        this.gtFilterService.updateQueryParams(
          this.reassignmentsQueryParams,
          this.reassignmentsFilterLevel,
        );
      }

      updatePositionsTableData() {
        if (!this.offset.reassignments.length && !this.offset.disbursement_bls.length) {
          this.positionsTableRows = [];
          this.positionsTableCount = 0;
        } else {
          this.GtUtils.overlay('show');

          this.OffsetService.getOffsetPositions({
            id_list: this.offset.offsetposition_set,
          }).then((data: any) => {
            this.selectedReassignmentPositions = Object.assign(
              [],
              data.results.filter((position: any) => position.reassignment),
            );
            this.selectedDisbursementBL = Object.assign(
              [],
              data.results.filter((position: any) => position.dbl),
            );
            this.positionsTableRows = Object.assign([], data.results);
            this.positionsTableCount = data.count;
            this.GtUtils.overlay('hide');
          });
        }
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      updateTables() {
        this.updatePositionsTableData();
      }

      updateData() {
        return this.OffsetService.getOffsetModalData({ id: this.offset.id }).then((data: any) => {
          this.offset = data;
          this.updateTables();
          this.updating = false;
        });
      }

      updateTableQueryParams() {
        this.gtFilterService.updateQueryParams({}, this.filterLevel);
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        return this.modalInstance.close(data || 'cancel');
      }

      positionsIsValid() {
        let positionsValid = true;
        this.selectedReassignmentPositions.forEach((position: any) => {
          if (!position.amount || position.amount > position.ready_to_connect) {
            positionsValid = false;
          }
        });
        return positionsValid;
      }

      save(stayAfterSave: any) {
        this.form.$invalid = true;
        this.OffsetService.saveOffset(
          this.offset,
          [].concat(this.selectedReassignmentPositions.concat(this.selectedDisbursementBL)),
        )
          .then((data: any) => {
            if (stayAfterSave) {
              this.offset = data.item;
              this.updateData();
            } else {
              this.close('saved', true);
            }
            this.GtUtils.notify(this.gettext('Offset saved'));
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return;
        }
        return this.OffsetService.destroy({ id: this.offset.id })
          .then(() => {
            this.close('deleted', true);
            this.$state.go('finances.offsetsPage');
          })
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      appendDBLToOffset(item: any) {
        if (this.offset.disbursement_bls.indexOf(item.id) > -1) {
          return;
        }

        this.DisbursementBlService.getPositionFromDbl({ id: item.id }).then((position: any) => {
          this.offset.disbursement_bls.push(item.id);
          position.finance_offset_id = this.offset.id;
          position.amount = position.ready_to_connect;
          position.amount_available = position.amount_available || position.ready_to_connect;
          this.selectedDisbursementBL.push(position);
          this.positionsTableRows.push(position);
          this.positionsTableCount += 1;
          this.updateOffsetAmount();
        });
      }

      updatePrepay(item: any) {
        if (item.seeds !== 0) {
          this.offset.seeds = item.seeds;
        } else {
          this.offset.crop_protection = item.crop_protection;
        }
      }

      appendReassignmentToOffset(item: any) {
        if (this.offset.reassignments.indexOf(item.id) > -1) {
          return;
        }
        this.ReassignmentService.getPositionFromReassignment({ id: item.id }).then(
          (position: any) => {
            this.offset.reassignments.push(item.id);
            position.finance_offset_id = this.offset.id;
            position.amount = position.ready_to_connect;
            position.amount_available = position.amount_available || position.ready_to_connect;
            this.selectedReassignmentPositions.push(position);
            this.positionsTableRows.push(position);
            this.positionsTableCount += 1;
            this.updateOffsetAmount();
            if ((item.seeds !== 0 || item.crop_protection !== 0) && this.positionsTableCount) {
              this.updatePrepay(item);
            }
          },
        );
      }

      removePositionFromOffsetTable(item: any) {
        if (item.reassignment) {
          this.offset.reassignments.splice(this.offset.reassignments.indexOf(item.reassignment), 1);
          this.selectedReassignmentPositions.splice(
            this.selectedReassignmentPositions.indexOf(item),
            1,
          );
        } else {
          this.offset.disbursement_bls.splice(this.offset.disbursement_bls.indexOf(item.dbl), 1);
          this.selectedDisbursementBL.splice(this.selectedDisbursementBL.indexOf(item), 1);
        }
        this.positionsTableRows.splice(this.positionsTableRows.indexOf(item), 1);

        this.positionsTableCount -= 1;
        if (item.reassignment) {
          this.gtFilterService.updateQueryParams(
            this.reassignmentsQueryParams,
            this.reassignmentsFilterLevel,
          );

          this.offset.amount = Math.round((this.offset.amount - item.amount) * 100) / 100;
        } else {
          this.gtFilterService.updateQueryParams(
            this.disbursementBLQueryParams,
            this.disbursementBLFilterLevel,
          );

          this.offset.amount = Math.round((this.offset.amount - item.amount) * 100) / 100;
        }
      }

      removePositionFromOffset(item: any) {
        if (!confirm('Are you sure you want delete this Item?')) {
          return;
        }
        let deleteFunc = this.$q.when();
        if (item.id) {
          deleteFunc = this.OffsetService.deleteOffsetPosition({ id: item.id });
        }
        deleteFunc.then(() => {
          this.removePositionFromOffsetTable(item);
        });
      }

      onReassignmentConnect(item: any) {
        if (item?._toConnect) {
          this.appendReassignmentToOffset(item);
        } else if (item && this.offset.reassignments.includes(item.id)) {
          const positionToDelete =
            this.selectedReassignmentPositions[
              this.selectedReassignmentPositions
                .map((item: any) => item.reassignment)
                .indexOf(item.id)
            ];
          this.removePositionFromOffset(positionToDelete);
        }
      }

      onDisbursmentBLConnect(item: any) {
        if (item?._toConnect) {
          this.appendDBLToOffset(item);
        } else if (item && this.offset.disbursement_bls.includes(item.id)) {
          const positionToDelete =
            this.selectedDisbursementBL[
              this.selectedDisbursementBL.map((item: any) => item.dbl).indexOf(item.id)
            ];
          this.removePositionFromOffset(positionToDelete);
        }
      }

      applyReassignmentFilters() {
        this.gtFilterService.setQueryParams(
          this.reassignmentsQueryParams,
          this.reassignmentsFilterLevel,
        );
      }

      applyDisbursementBLFilters() {
        this.gtFilterService.setQueryParams(
          this.disbursementBLQueryParams,
          this.disbursementBLFilterLevel,
        );
      }

      resetDisbursementBLFilters() {
        const distributor = this.disbursementBLQueryParams.distributor;
        this.disbursementBLQueryParams = {
          without_offset: this.offset.id,
          has_unconnected_amount: '1',
          date_predicate: 'date',
          with_purchase_contract: '1',
          status_list: ['new', 'ready', 'process'],
        };
        if (this.offset.id) {
          this.disbursementBLQueryParams.without_offset = this.offset.id;
        }
        if (distributor) {
          this.disbursementBLQueryParams.distributor = distributor;
        }
        this.applyDisbursementBLFilters();
      }

      resetReassignmentFilters() {
        const formedQueryParams = { has_unconnected_amount: 1, without_status_list: ['done'] };
        const distributor = this.reassignmentsQueryParams.distributor;

        if (this.offset.id) {
          // @ts-ignore
          formedQueryParams.without_offset = this.offset.id;
        }
        if (distributor) {
          // @ts-ignore
          formedQueryParams.distributor = distributor;
        }
        this.reassignmentsQueryParams = formedQueryParams;
        this.applyReassignmentFilters();
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-md-6 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-md-6 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('DETAILS'),
          },
          fieldGroup: [
            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Offset number'),
                placeholder: this.gettext('Type offset number'),
                addon: this.gettext('#'),
                required: true,
              },
            },
            {
              key: 'date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Date'),
                placeholder: this.gettext('date'),
                type: 'date',
              },
            },
            {
              key: 'distributor',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Distributor'),
                placeholder: this.gettext('Distributor'),
                addPerms: ['add_client'],
                addIcon: this.GtUtils.getIcon('clients.Client'),
                queryParams: { role_name: 'supplier', is_distributor: '1' },
                addFunc: () => {
                  this.ClientsService.clientModal();
                },
                resource: 'clients.ClientRole',
                hint: this.gettext('Pick a distributor that will be bound to this offset'),
              },
            },
            {
              key: 'crop_protection',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Crop protection'),
                required: false,
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.disabled': ($viewValue: any, $modelValue: any, scope: any) =>
                  scope.model.seeds && scope.model.seeds !== 0,
              },
            },
            {
              key: 'seeds',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Seeds'),
                required: false,
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.disabled': ($viewValue: any, $modelValue: any, scope: any) =>
                  scope.model.crop_protection && scope.model.crop_protection !== 0,
              },
            },
            {
              key: 'additional_info',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Additional Info'),
                placeholder: this.gettext('Specific information about this object'),
                className: 'additional-info',
              },
            },
            {
              key: 'has_docs_original',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Has docs original'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => this.CoreService.getDefaultBuId(this.offset),
              templateOptions: {
                label: this.gettext('Business unit'),
                placeholder: this.gettext('Business Unit'),
                queryParams: this.GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('FINANCES'),
          },
          fieldGroup: [
            {
              key: 'amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount'),
                placeholder: this.gettext('Amount'),
                type: 'number',
                required: true,
                disabled: true,
              },
            },
            {
              key: 'manager',
              type: 'gt-ui-select',
              defaultValue: this.$rootScope.user.id,
              templateOptions: {
                label: this.gettext('Manager'),
                placeholder: this.gettext('User to be manager for the offset'),
                resource: 'auth.User',
              },
            },
          ],
        });

        return {
          formName: 'offset-edit-modal',
          fieldsDef: [col1, col2],
        };
      }
    },
  ],
};
