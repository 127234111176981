import { coreColumnParamsList } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { fromColumnParamsListData } from './mappers/entity-fields-dto';
import type { EntityFieldsDTO, EntityFieldsProperties } from '../lib/types';

@singleton()
export class EntityFieldsRepository {
  async getEntityProperties(dto: EntityFieldsDTO): Promise<EntityFieldsProperties[]> {
    let res;

    try {
      res = await coreColumnParamsList({ tableName: dto.entityName });
    } catch (err) {
      throw new Error('Error fetching user columns', { cause: err });
    }

    return fromColumnParamsListData(res);
  }

  // custom fields
}
