import type ng from 'angular';

import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './credit-report-table-view.html?raw';

export const CreditReportTableView = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<?',
    data: '<',
    savedFilterChoices: '<?',
  },
  template,
  controller: [
    'gettext',
    'ContractsService',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      ContractsService: ContractsService;
      gettext: ng.gettext.gettextFunction;
      hideContracts: any;
      hidePurchase: any;
      initQueryParams: QueryParams = {};
      onUpdate: any;
      theadConfig: any;
      updateData: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        ContractsService: ContractsService,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.ContractsService = ContractsService;
        this.hideContracts = true;
        this.theadConfig = {};
        this.$rootScope = $rootScope;
      }

      $onInit() {
        this.hideContracts = !this.initQueryParams.contract_type;
        if (this.initQueryParams.contract_type === 'sale') {
          this.hidePurchase = true;
        }
      }

      $onChanges(changes: any) {
        if (changes.data) {
          this.updateTable();
        }
      }

      openContractModal(contract: any) {
        return this.ContractsService.contractModal({ id: contract.id }).then(() => {
          this.updateData();
          this.onUpdate();
        });
      }

      updateTable() {
        this.theadConfig = this.getTheadConfig();
      }

      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          {
            title: this.gettext('title'),
            columnName: 'title',
          },
        ];

        if (
          this.initQueryParams.group_by == 'supplier' ||
          this.initQueryParams.group_by == 'buyer'
        ) {
          config.columns.push(
            {
              title: this.gettext('Compliance status'),
              columnName: 'complianceStatus',
              class: 'volume',
            },
            {
              title: this.gettext('Approval status'),
              columnName: 'approvalStatus',
              class: 'volume',
            },
            {
              title: this.gettext('Balance, Cost / Gains'),
              columnName: 'balanceCosts',
            },
            {
              title: this.gettext('Limit plan'),
              columnName: 'limitPlan',
            },
            {
              title: this.gettext('Limit fact'),
              columnName: 'limitFact',
            },
            {
              title: this.gettext('Contract quantity'),
              columnName: 'quantity',
            },
            {
              title: this.gettext('Limit balance'),
              columnName: 'limitBalance',
            },
            {
              title: this.gettext('Open amount balance'),
              columnName: 'open_balance_value',
            },
          );
        }

        if (
          this.initQueryParams.group_by != 'supplier' &&
          this.initQueryParams.group_by != 'buyer'
        ) {
          config.columns.push({
            title: this.gettext('Contract quantity'),
            columnName: 'quantity',
          });
        }

        if (!this.hideContracts) {
          config.columns.push(
            {
              title: this.gettext('Execution period'),
              columnName: 'executionPeriod',
            },
            {
              title: this.gettext('Commodity'),
              columnName: 'commodity',
            },
            {
              title: this.gettext('Price'),
              columnName: 'price',
            },
            {
              title: this.gettext('MTM price'),
              columnName: 'mtmPrice',
            },
            {
              title: this.gettext('Physical MTM'),
              columnName: 'physicalMtm',
            },
          );
        }

        return config;
      }
    },
  ],
};
