import type ng from 'angular';

import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './contract-list-block-view.html?raw';

export const ContractListBlockView = {
  bindings: {
    contracts: '<',
    count: '<',
    filterLevel: '<',
    total: '<',
    activeTab: '<',
    applyFilters: '&',
    openContractModal: '&',
    cloneContract: '&',
    openEmailModal: '&',
    openDocxModal: '&',
    openDocumentModal: '&',
    approveContract: '&',
    addLogistic: '&',
    addFinance: '&',
    reverseContract: '&',
    createPassport: '&',
    connectToPassport: '&',
    openVoyageModal: '&',
    openRoleModal: '&',
    openStageModal: '&',
    extraFields: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    'moment',
    class {
      $rootScope: GtRootScopeService;
      contracts: any;
      count: number;
      extraFields: any;
      gettext: ng.gettext.gettextFunction;
      moment: any;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        moment: any,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.moment = moment;

        this.contracts = [];
        this.count = 0;

        this.tableOptions = {};
        this.tableData = {};
        this.queryParams = { serializer: 'table_info' };
        this.extraFields = [];
      }

      $onInit() {
        this.contracts = this.contracts || [];
        this.count = this.count || 0;
      }
    },
  ],
};
