import ng from 'angular';

import { AnalyticalPlanFactPnlReportContainer } from './analytical-plan-fact-pnl-report-container/analytical-plan-fact-pnl-report-container.component';
import { AnalyticalPlanFactPnlReportPage } from './analytical-plan-fact-pnl-report-page/analytical-plan-fact-pnl-report-page.component';
import { AnalyticalPlanFactPnlReportTable } from './analytical-plan-fact-pnl-report-table/analytical-plan-fact-pnl-report-table.component';
import { AnalyticalPlanFactPnlReportService } from './analytical-plan-fact-pnl-report.service';

export const analyticalPlanFactPnlReport = ng
  .module('reports.AnalyticalPlanFactPnlReport', [])
  .service('AnalyticalPlanFactPnlReportService', AnalyticalPlanFactPnlReportService)
  .component('analyticalPlanFactPnlReportPage', AnalyticalPlanFactPnlReportPage)
  .component('analyticalPlanFactPnlReportContainer', AnalyticalPlanFactPnlReportContainer)
  .component('analyticalPlanFactPnlReportTable', AnalyticalPlanFactPnlReportTable).name;
