import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').directive('positionsFactsEditRow', directive);

  function directive() {
    return {
      template: require('./positions-facts-edit-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        fact: '<',
        onUpdateTotal: '&?',
      },
    };
  }

  Controller.$inject = ['$rootScope', 'gettext'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.volumeChanged = volumeChanged;

    vm.$onInit = function () {
      vm.initVolume = vm.fact.volume;
    };

    function volumeChanged() {
      vm.onUpdateTotal();
      vm.fact._dirty = true;
      vm.fact._error = undefined;
      if (!$rootScope.user.settings.PASSPORT_CHECK_VOLUME) {
        return true;
      }

      const newVolume = vm.fact.volume;
      const additionalVolume = (newVolume - parseFloat(vm.initVolume)).toFixed(6);
      const availableVolume =
        vm.fact.contract_available_to_connect ||
        parseFloat(vm.fact.contract_final_volume || vm.fact.contract_volume) -
          parseFloat(vm.fact.contract_total_connected) +
          vm.initVolume;

      if (
        ($rootScope.user.settings.PASSPORT_CHECK_VOLUME &&
          vm.fact.volume &&
          (additionalVolume > availableVolume || vm.fact.volume < 0)) ||
        isNaN(vm.fact.volume)
      ) {
        vm.fact._error = gettext('Check volume');
      }
    }
  }
})();
