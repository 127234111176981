import ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';

import type { GtRootScopeService, QueryParams } from '../../core/types';

function Service(
  $rootScope: GtRootScopeService,
  $resource: ng.resource.IResourceService,
  $uibModal: ng.ui.bootstrap.IModalService,
  gettext: ng.gettext.gettextFunction,
) {
  const CashFlowResource = $resource(
    '/api/reports/cashflow/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      tableList: {
        url: '/api/reports/cashflow/table_list/',
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
    },
  );

  const BankBalanceResource = $resource(
    '/api/reports/bank-balances/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: true },
      update: {
        method: 'PATCH',
      },
    },
  );

  const DebitCreditRoleResource = $resource(
    '/api/clients/debit-credit/:roleName/',
    {},
    {
      query: { method: 'GET', isArray: false },
    },
  );

  const StaffReportResource = $resource(
    '/api/reports/staff/',
    {},
    {
      query: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/staff-info/',
      },
      generate: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/staff/generate/',
      },
    },
  );

  const TransactionReportResource = $resource(
    '/api/reports/transactions/',
    {},
    {
      query: { method: 'GET', isArray: false },
    },
  );

  const FinanceBalanceResource = $resource(
    '/api/reports/finance-balance/',
    {},
    {
      query: { method: 'GET', isArray: false },
      cropBalance: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-crop-balance/',
      },
      cropBalanceTotal: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-crop-balance/totals/',
      },
      bankaccountBalance: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-bankaccount-balance/',
      },
      bankaccountBalanceTotal: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-bankaccount-balance/totals/',
      },
      debitorCreditorBalance: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-debitor-creditor-balance/',
      },
      debitorCreditorBalanceTotal: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-debitor-creditor-balance/totals/',
      },
    },
  );

  const FinanceClientDetailsResource = $resource(
    '/api/reports/finance-client-details/',
    {},
    {
      query: { method: 'GET', isArray: false },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/finance-client-details/?as_export_column_names=1',
      },
    },
  );

  const WarehouseTradeResource = $resource(
    '/api/reports/warehouse-trade-report/',
    {},
    {
      query: { method: 'GET', isArray: false },
    },
  );
  const WarehouseRestResource = $resource(
    '/api/reports/warehouse-rests-report',
    {},
    {
      query: { method: 'GET', isArray: true },
    },
  );

  const LineupResource = $resource(
    '/api/passports/lineup/',
    {},
    {
      query: { method: 'GET', isArray: false },
      pnl: {
        method: 'POST',
        isArray: false,
        url: '/api/passports/lineup/pnl/',
      },
    },
  );

  const LineupPnlLightResource = $resource(
    '/api/reports/lineup-pnl-light/',
    {},
    {
      query: { method: 'GET', isArray: false },
    },
  );

  const WarehouseVolumeResource = $resource(
    '/api/reports/warehouse/volume/',
    {},
    {
      query: { method: 'GET', isArray: false },
    },
  );

  const PdfReportResource = $resource(
    '/api/reports/pdf-report/',
    {},
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/pdf-report/predictions/',
      },
    },
  );

  const CalculatedValueUpdateRequestResource = $resource(
    '/api/reports/calculated-value-update-request/',
    {},
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/reports/calculated-value-update-request/predictions/',
      },
    },
  );

  ////////////////

  return {
    CashFlow: CashFlowResource,
    BankBalance: BankBalanceResource,
    WarehouseTrade: WarehouseTradeResource,
    WarehouseRest: WarehouseRestResource,
    DebitCreditRole: DebitCreditRoleResource,
    TransactionReport: TransactionReportResource,
    lineupModal: lineupModal,
    balanceModal: balanceModal,
    riskPositionTerminalModal: riskPositionTerminalModal,
    StaffReport: StaffReportResource,
    FinanceBalance: FinanceBalanceResource,
    FinanceClientDetails: FinanceClientDetailsResource,
    Lineup: LineupResource,
    LineupPnlLight: LineupPnlLightResource,
    WarehouseVolume: WarehouseVolumeResource,
    getPagesConfig: getPagesConfig,
    PdfReport: PdfReportResource,
    CalculatedValueUpdateRequest: CalculatedValueUpdateRequestResource,
    unpackReportDataFromTree: unpackReportDataFromTree,
    parseGroupsFromString: parseGroupsFromString,
  };

  function lineupModal(lineup: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./lineup-modal/lineup-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'LineupModalController as vm',
      windowClass: 'modal-template',
      resolve: {
        lineup: () => {
          return lineup;
        },
      },
    }).result;
  }

  function balanceModal(mode: any, queryParams: QueryParams, amount: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./balance-modal/balance-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'BalanceModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      resolve: {
        mode: () => {
          return mode;
        },
        queryParams: () => {
          return queryParams;
        },
        amount: () => {
          return amount;
        },
      },
    }).result;
  }

  function riskPositionTerminalModal(data: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./risk-position-terminal-modal/risk-position-terminal-modal.tpl.html?raw'),
      appendTo: getModalRoot(),
      controller: 'RiskPositionTerminalModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      resolve: {
        data: () => {
          return data;
        },
      },
    }).result;
  }

  function getPagesConfig() {
    const positionPage: any = {
      title: gettext('Position'),
      permissions: ['view_positionreport'],
      state: '',
      icon: 'fa-bar-chart',
      tabs: [],
    };

    switch ($rootScope.user.settings.DEFAULT_POSITION_TAB) {
      case 'physical_position':
        positionPage.state = 'position.physical';
        break;
      case 'position_by_month':
        positionPage.state = 'reports.consolidatedCropPosition';
        break;
      case 'pnl_position':
        positionPage.state = 'position.mtm';
        break;
      default:
        positionPage.state = 'position.physical';
        break;
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_positioncpt) {
      positionPage.tabs.push({
        title: gettext('Physical position by months'),
        permissions: ['view_position'],
        state: 'reports.consolidatedCropPosition',
        icon: 'fa-bar-chart',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_pnl_mtm) {
      positionPage.tabs.unshift({
        title: gettext('Position P&L + MTM'),
        permissions: ['view_pl_mtm'],
        state: 'position.mtm',
        icon: 'fa-line-chart',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_positionfob) {
      positionPage.tabs.push({
        title: gettext('Physical position + MTM'),
        permissions: ['view_position'],
        state: 'position.physical',
        icon: 'fa-bar-chart',
      });
    }

    const freightPositionPage = {
      title: gettext('Freight position + MTM'),
      permissions: ['view_freight'],
      state: 'position.freight',
      icon: 'fa-anchor',
    };

    const derivativesPage = {
      title: gettext('Derivatives'),
      permissions: ['view_futuresposition'],
      state: 'derivatives',
      icon: 'fa-bolt',
    };

    const fixingPage = {
      title: gettext('Fixing and hedging'),
      permissions: ['view_fixing_hedging'],
      state: 'fixingHedgingReport',
      icon: 'fa fa-lock',
    };

    const plPage: any = {
      title: gettext('P&L'),
      permissions: ['view_pl'],
      state: 'pl.consolidated',
      icon: 'fa-line-chart',
      tabs: [],
    };

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_pnl_books) {
      plPage.tabs.unshift({
        title: gettext('P&L books'),
        permissions: ['view_finance'],
        state: 'finances.pl',
        icon: 'fa-columns',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_pnl_per_passport) {
      plPage.tabs.unshift({
        title: gettext('P&L per passport'),
        permissions: ['view_pl_passport'],
        state: 'pl.passport',
        icon: 'fa-exchange',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_pnl_contract_pnl) {
      plPage.tabs.unshift({
        title: gettext('Contract P&L'),
        icon: 'fa-pie-chart',
        permissions: ['view_contract_pl'],
        state: 'pl.consolidatedContract',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_pnl_passport_pnl) {
      plPage.tabs.unshift({
        title: gettext('Passport P&L'),
        icon: 'fa-pie-chart',
        permissions: ['view_passport_pl'],
        state: 'pl.consolidated',
      });
    }

    if ($rootScope.user.settings.SYSTEM_BLOCKS.block_report_pnl_analytical_plan_fact_pnl) {
      plPage.tabs.unshift({
        title: gettext('Analytical-Plan-Fact PnL'),
        icon: 'fa-pie-chart',
        permissions: ['view_analytical_plan_fact_pnl'],
        state: 'pl.analyticalPlanFactPnlReport',
      });
    }

    return [
      {
        title: gettext('Dashboard'),
        permissions: ['view_reports'],
        state: 'dashboard',
        icon: 'fa-tachometer',
        tabs: [],
      },
      positionPage,
      freightPositionPage,
      derivativesPage,
      fixingPage,
      {
        title: gettext('Lineup'),
        permissions: ['view_lineup'],
        state: 'lineup.lineup',
        icon: 'fa-exchange',
      },
      {
        title: gettext('Execution'),
        permissions: ['view_position'],
        state: 'reports.contractStatus',
        icon: 'fa-cubes',
      },
      plPage,
      {
        title: gettext('AP/AR'),
        permissions: ['view_debitcredit'],
        state: 'reports.debitCredit',
        icon: 'fa-money',
      },
      {
        title: gettext('Cash Flow'),
        permissions: ['view_cashflow'],
        state: 'reports.cashflowReport',
        icon: 'fa-area-chart',
        tabs: [
          {
            title: gettext('Cash Flow'),
            permissions: ['view_cashflow'],
            state: 'reports.cashflowReport',
            icon: 'fa-area-chart',
          },
          {
            title: gettext('Cash Flow (mixed)'),
            permissions: ['view_cashflow'],
            state: 'reports.cashflowBalanceReport',
            icon: 'fa-area-chart',
          },
          {
            title: gettext('Old Cash Flow'),
            permissions: ['view_cashflow'],
            state: 'reports.cashflow',
            icon: 'fa-area-chart',
          },
        ],
      },
      {
        title: gettext('Statistics'),
        permissions: ['view_debitcredit'],
        state: 'reports.statistics',
        icon: 'fa fa-pie-chart',
      },
      {
        title: gettext('Costs statistics report'),
        permissions: ['view_costreports'],
        state: 'reports.statisticsCosts',
        icon: 'fa fa-dollar',
      },
      {
        title: gettext('Costs report'),
        permissions: ['view_costreports'],
        state: 'reports.costsReport',
        icon: 'fa fa-dollar',
      },
      {
        title: gettext('Clients Reconciliations'),
        permissions: ['view_reconciliation_reports'],
        state: 'reports.clientInvoicePaymentReconciliationReport',
        icon: 'fa-area-chart',
        tabs: [
          {
            title: gettext('Reconciliation by invoice'),
            permissions: ['view_reconciliation_reports'],
            state: 'reports.clientInvoicePaymentReconciliationReport',
            icon: 'fa-credit-card-alt',
          },
          {
            title: gettext('Reconciliation by logistics'),
            permissions: ['view_reconciliation_reports'],
            state: 'reports.clientLogisticPaymentReconciliationReport',
            icon: 'fa-truck',
          },
        ],
      },
      {
        title: gettext('Balance'),
        permissions: ['view_balance'],
        state: 'reports.balance',
        icon: 'fa-balance-scale',
      },
      {
        title: gettext('Budgets'),
        permissions: ['view_budgets'],
        state: 'reports.budgets.finances',
        icon: 'fa fa-list',
        tabs: [
          {
            title: gettext('Year budget'),
            permissions: ['view_budgets'],
            state: 'reports.budgets.finances',
            icon: 'fa fa-list',
          },
          {
            title: gettext('Loyalty Programs'),
            permissions: ['view_budgets'],
            state: 'reports.budgets.loyaltyPrograms',
            icon: 'fa-file-powerpoint-o',
          },
          {
            title: gettext('Execution of loyalty programs'),
            state: 'reports.budgets.loyaltyProgramsExecution',
            icon: 'fa-qrcode',
            permissions: ['view_loyalty_programs_execution'],
          },
          {
            title: gettext('Projects Status Detailed Summary'),
            state: 'reports.budgets.projectsStatusReport',
            icon: 'fa-qrcode',
            permissions: ['view_projects_status_report'],
          },
        ],
      },

      {
        title: gettext('Credit Report'),
        permissions: ['view_credit'],
        state: 'reports.creditReport',
        icon: 'fa-exclamation-triangle',
      },
      {
        title: gettext('Invoice risk'),
        permissions: ['view_invoice_risk'],
        state: 'reports.invoiceRiskReport',
        icon: 'fa-money',
      },
      {
        title: gettext('Approvals'),
        permissions: ['view_approvalsreports'],
        state: 'reports.approvalsReport',
        icon: 'fa fa-check-square-o',
      },
      {
        title: gettext('Documents dates'),
        permissions: ['view_certificate_dates'],
        state: 'reports.certificateDates',
        icon: 'fa fa-dot-circle-o',
      },
      {
        title: gettext('Compliance status'),
        permissions: ['view_confirmationsreport'],
        state: 'reports.confirmations',
        icon: 'fa-calendar-check-o',
      },
      {
        title: gettext('Signings status'),
        permissions: ['view_signingsreport'],
        state: 'reports.signings',
        icon: 'fa-pencil',
      },
      {
        title: gettext('KPI'),
        permissions: ['view_kpiconfirmationsreport'],
        state: 'kpi.confirmations',
        icon: 'fa-universal-access',
        tabs: [
          {
            title: gettext('Purchase KPI'),
            permissions: ['view_purchaseplanreport'],
            state: 'reports.purchasePlanReport',
            icon: 'fa-arrow-down',
          },
          {
            title: gettext('Sale KPI'),
            permissions: ['view_purchaseplanreport'],
            state: 'reports.salePlanReport',
            icon: 'fa-arrow-up',
          },
          {
            title: gettext('Compliance KPI'),
            permissions: ['view_kpiconfirmationsreport'],
            state: 'kpi.confirmations',
            icon: 'fa-universal-access',
          },
          {
            title: gettext('Signings KPI'),
            permissions: ['view_kpisigningsreport'],
            state: 'kpi.signings',
            icon: 'fa-universal-access',
          },
        ],
      },
      {
        title: gettext('Activity'),
        permissions: ['view_activityreports'],
        state: 'gt.old-page.reports',
        stateParams: { type: 'activity' },
        icon: 'fa-history',
      },
    ];
  }

  function unpackReportDataFromTree(reportData: any) {
    const result = unpackFromTreeData(reportData);
    result.forEach((v: any, i: any) => {
      v.index = i;
      v.parentIndex = v.parent.index;
      v.expand = Boolean(v.level < 1);
    });
    return result;
  }

  function unpackFromTreeData(data: any, parent = {}, level = 0): any {
    const result: any = [];
    for (const branch of data) {
      branch.parent = parent;
      branch.level = level;
      result.push(branch);
      if (branch.data?.length) {
        result.push(...unpackFromTreeData(branch.data, branch, level + 1));
        delete branch.data;
      }
    }
    return result;
  }
  function groupByKey(groupChoices: any, key: any) {
    return groupChoices.filter((v: any) => v.param === key).pop();
  }
  function parseGroupsFromString(groupChoices: any, groupsFilter = '') {
    const selectedGroups: any = [];
    const unselectedGroups: any = [...groupChoices];
    if (!groupsFilter) {
      return [selectedGroups, unselectedGroups];
    }
    groupsFilter
      .split(',')
      .map((gr) => groupByKey(groupChoices, gr))
      .filter((v) => v !== undefined)
      .forEach((v) => {
        selectedGroups.push(v);
        unselectedGroups.splice(unselectedGroups.indexOf(v), 1);
      });
    return [selectedGroups, unselectedGroups];
  }
}

(function () {
  'use strict';
  ng.module('reports.legacy').factory('ReportsService', Service);
  Service.$inject = ['$rootScope', '$resource', '$uibModal', 'gettext'];
})();

export type ReportsService = ReturnType<typeof Service>;
