import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './bill-of-lading-invoiceposition-list-table-view.html?raw';

export const BillOfLadingInvoicepositionListTableView = {
  bindings: {
    data: '<',
    count: '<',
    total: '<',
    extra: '<',
    filterLevel: '<',
    contractIdList: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    updateData: '&',
    updateItem: '&',
  },
  template,
  controller: [
    'gettext',
    'LogisticsService',
    'GtUtils',
    '$window',
    'BillOfLadingInvoicepositionService',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      BillOfLadingInvoicepositionService: any;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      applyFilters: any;
      contractIdList: any;
      count: number;
      data: any;
      destroy: any;
      filterLevel = 'bill-of-lading-invoiceposition-list-table-view';
      gettext: ng.gettext.gettextFunction;
      modelName: any;
      numberInput: any;
      saveItem: any;
      selection: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      total: any;
      updateData: any;
      updateItem: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        LogisticsService: any,
        GtUtils: GtUtilsService,
        $window: ng.IWindowService,
        BillOfLadingInvoicepositionService: any,
        $rootScope: GtRootScopeService,
      ) {
        this.gettext = gettext;
        this.LogisticsService = LogisticsService;
        this.GtUtils = GtUtils;
        this.$window = $window;
        this.BillOfLadingInvoicepositionService = BillOfLadingInvoicepositionService;
        this.$rootScope = $rootScope;

        this.data = [];
        this.count = 0;
        this.selection = false;
        this.numberInput = false;
        this.tableOptions = {};
        this.tableData = {};
        this.modelName = 'passport';
      }

      $onChanges(changes: any) {
        this.tableName = this.tableName || 'bill-of-lading-invoiceposition';
        if (changes.data || changes.count || changes.total) {
          this.tableOptions = this.getTableOptions();
          this.tableData = {
            rows: this.data,
            count: this.count,
            total: this.total,
          };
        }
      }

      clone(item: any) {
        Object.assign(this.tableData.rows[0], item, {
          author: undefined,
          author_avatar: undefined,
          author_first_name: undefined,
          create_time: undefined,
          editor: undefined,
          editor_avatar: undefined,
          editor_first_name: undefined,
          update_time: undefined,
          id: undefined,
          invoices: [],
        });
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'contract-charges-table bill-of-lading-invoiceposition-table',
          applyFilters: this.applyFilters,
          selection: this.selection,
          numberInput: this.numberInput,
          configurable: false,
          showMore: false,
          changePageSize: false,
          numberInputCellTitle: 'Quantity to be invoiced',
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            clone: (item: any) => this.clone(item),
            updateData: () => this.updateData(),
            updateItem: (item: any) => this.updateItem({ item: item }),
            $rootScope: this.$rootScope,
            contractIdList: this.contractIdList,
          },
          filterLevel: this.filterLevel,
          tabs: [],
          columnDefs: [
            {
              columnName: 'quantity',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
                <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                  {[{ item.quantity || 0 | number: 3  }]}
                  <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <input class="form-control" gt-clear-input type="number" ng-model="item.quantity">
                </span>
              `,
              title: this.gettext('Volume'),
            },
            {
              title: this.gettext('Invoice'),
              columnName: 'invoice',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select ng-model="item.invoice_position"
                    placeholder="'invoice position'|translate"
                    resource-name="'finances.invoiceposition'"
                    allow-clear="true"
                    on-open-close="args.setHovering(isOpen)"
                    query-params="{
                       use_list:['cargo',],
                       contract_list: args.contractIdList
                    }"
                  ></gt-resource-select>
                </span>
                <span ng-if="!$ctrl.edit && item.invoice_id">
                  <a ui-sref="gt.page.payment({id: item.invoice_id })" class="btn-link">
                    <i class="fa fa-credit-card-alt"></i> {[{ item.invoice_number | cut:true:30:' ' }]}
                      {[{ item.invoice_status | translate }]}
                  </a>
                </span>
              `,
            },
            {
              columnName: 'actions',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <div>
                <a class="btn btn-xs btn-blue-border" ng-hide="$ctrl.edit || !item.id" ng-click="$ctrl.edit=true">
                  <i class="fa fa-pencil-square"></i>
                </a>
              </div>
              <span ng-if="$ctrl.edit || !item.id">
                <a
                  class="btn btn-md btn-success btn_success"
                  ng-if="item.id"
                  ng-click="args.updateItem(item);$ctrl.edit = false"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  class="btn btn-md btn-success btn_success col-xs-12"
                  ng-if="!item.id"
                  ng-click="args.saveItem(item)"
                >
                  <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                </a>
                <a
                  class="btn btn-md"
                  ng-if="item.id"
                  ng-click="$ctrl.edit = false"
                >
                  <i class="fa fa-times"></i>
                </a>
                <a
                  class="btn btn-md btn-danger btn_danger"
                  ng-if="item.id"
                  ng-click="args.destroy(item.id);$ctrl.edit = false"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </span>`,
              title: this.gettext('Actions'),
            },
          ],
        };
      }
    },
  ],
};
