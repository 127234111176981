import React from 'react';

import { SheetView } from '~/core/page-views/components/sheet-view';

import type { CommonView, ViewVariant } from './types';
import { TableView } from '../table-view';

export const DataSetView = <TRecord extends { id: string }>({
  data,
  viewType,
  selectedRecordIds,
  editing,
  selectable,
  loading,
  onSelect,
  onEdit,
  onDelete,
  onSave,
  editedRecords,
  changes,
}: CommonView<TRecord> & {
  viewType: ViewVariant;
  editedRecords: TRecord[];
  changes: Partial<TRecord>[];
  onEdit: (diff: Partial<TRecord>[]) => void;
}) => {
  const viewVariants: Record<ViewVariant, JSX.Element> = {
    table: (
      <TableView
        data={
          editing
            ? {
                records: editedRecords,
                properties: data.properties,
              }
            : data
        }
        onSelect={onSelect}
        selectedRecordIds={selectedRecordIds}
        editing={editing}
        loading={loading}
        selectable={selectable}
        onEdit={(part) => {
          if (part.id) {
            onEdit([...changes, part]);
          } else {
            console.error('No id for record');
          }
        }}
        onDelete={onDelete}
        onSave={onSave}
      />
    ),
    card: <div>Card View</div>,
    list: <div>List View</div>,
    sheet: <SheetView data={data} onEdit={onEdit} />,
  };

  return viewVariants[viewType];
};
