import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

export const invoiceDetailsPage = {
  bindings: {},
  template: require('./invoice-details-page.html?raw'),
  controller: [
    '$rootScope',
    '$scope',
    '$stateParams',
    '$state',
    'FinancesService',
    'InvoiceFilterConfigService',
    'GtUtils',
    'PageService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      InvoiceFilterConfigService: any;
      PageService: PageService;
      filterLevel: string;
      finance: any;
      gettext: ng.gettext.gettextFunction;
      invoice: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $stateParams: ng.ui.IStateParamsService,
        $state: ng.ui.IStateService,
        FinancesService: FinancesService,
        InvoiceFilterConfigService: any,
        GtUtils: GtUtilsService,
        PageService: PageService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.FinancesService = FinancesService;
        this.InvoiceFilterConfigService = InvoiceFilterConfigService;
        this.GtUtils = GtUtils;
        this.PageService = PageService;
        this.gettext = gettext;

        this.invoice = {};
        this.filterLevel = 'payments-page-view';
      }

      $onInit() {
        this.finance = { id: this.$stateParams.id };
        const config = this.PageService.getConfig();
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            filters: this.InvoiceFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            filters: this.InvoiceFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        }
      }

      updateConfigWithFilterLevel(invoice: any, filterLevel: string) {
        const config = this.PageService.getConfig();
        let isOpen;

        if (ng.equals(config, {}) || config.sidebar?.is_open) {
          isOpen = true;
        } else {
          isOpen = false;
        }
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            sidebar: {
              verboseName: 'Invoices',
              modelName: 'finances.finance',
              instance: invoice,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            pages: [],
            filters: this.InvoiceFilterConfigService.getFilterConfig(filterLevel),
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: 'Invoices',
              modelName: 'finances.finance',
              instance: invoice,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            filters: this.InvoiceFilterConfigService.getFilterConfig(filterLevel),
            pages: [],
          });
        }
      }
    },
  ],
};
