import ng from 'angular';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('warehouseLostsTable', {
    bindings: {
      filterLevel: '<',
      losts: '<',
      totals: '<',
      count: '<',
      cargo: '<',
      warehouse: '<',
      warehouseType: '<',
      onDelete: '&',
      onSave: '&',
    },
    template: require('./warehouse-losts-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext'];

  function Controller(this: any, $scope: ng.IScope, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.theadConfig = getTheadConfig();
    vm.queryParams = {};
    vm.saveLost = saveLost;
    vm.deleteLost = deleteLost;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'warehouse-losts-table';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function saveLost(lost: any) {
      vm.onSave({ lost: lost });
    }

    function deleteLost(lost: any) {
      vm.onDelete({ lost: lost });
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('Warehouse type'),
          columnName: 'warehouse_type',
        },
        {
          title: gettext('Expense type'),
          columnName: 'expense_method',
        },
        {
          title: gettext('Warehouse'),
          columnName: 'warehouse',
        },
        {
          title: gettext('Commodity'),
          columnName: 'crop',
        },
        {
          title: gettext('Lost'),
          columnName: 'lost',
        },
        {
          title: gettext('Date'),
          columnName: 'date',
        },
        {
          title: gettext('Purchase Contract'),
          columnName: 'purchase_contract',
        },
        {
          title: gettext('Business unit'),
          columnName: 'business_unit',
        },
        {
          title: gettext('Additional info'),
          columnName: 'additional_info',
        },
        {
          title: gettext('Last update'),
          columnName: 'last_update',
          predicate: 'update_time',
          class: 'update_time',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
        },
        {
          title: gettext('Author'),
          columnName: 'author',
          predicate: 'author',
          filters: [
            {
              type: 'ui-select',
              predicate: 'author',
              label: gettext('autor'),
              resource: 'auth.User',
            },
          ],
        },
        {
          title: gettext(''),
          columnName: 'save',
        },
      ];

      return config;
    }
  }
})();
