import template from './core-inline-ordering.html?raw';
import type { GtFilterService } from '../../legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '../../types';

export const CoreInlineOrdering = {
  bindings: {
    filterLevel: '<',
    choices: '<',
  },
  template,
  controller: [
    'gtFilterService',

    class {
      filterLevel = 'core-inline-ordering';
      gtFilterService: GtFilterService;
      isAscendingOrder: any;
      orderBy: any;
      queryParams: QueryParams = {};
      constructor(gtFilterService: GtFilterService) {
        this.gtFilterService = gtFilterService;
        this.orderBy = undefined;
        this.isAscendingOrder = true;
      }

      $onInit() {
        this.queryParams = this.gtFilterService.getQueryParams(this.filterLevel);
      }

      updateOrdering() {
        if (!this.orderBy) {
          this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
          this.isAscendingOrder = true;
        } else {
          const params = {
            ...this.queryParams,
            ordering: !this.isAscendingOrder ? '-' + this.orderBy.value : this.orderBy.value,
          };
          this.gtFilterService.updateQueryParams(params, this.filterLevel);
        }
      }

      selectOrdering(choice: any) {
        this.orderBy = choice;
        this.updateOrdering();
      }

      changeOrdering() {
        if (!this.isAscendingOrder) {
          this.orderBy = undefined;
        }

        this.isAscendingOrder = !this.isAscendingOrder;
        this.updateOrdering();
      }
    },
  ],
};
