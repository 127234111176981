import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './fact-intermediate-list-table-view.html?raw';

export const FactIntermediateListTableView = {
  bindings: {
    list: '<',
    count: '<',
    filterLevel: '<',
    applyFilters: '&',
    saveItem: '&',
    destroy: '&',
    predictionsQueryParams: '<',
    customTableName: '<',
  },
  template,
  controller: [
    '$rootScope',
    '$window',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      gettext: ng.gettext.gettextFunction;
      applyFilters: any;
      count: number;
      destroy: any;
      filterLevel = '';
      list: any;
      passportSelectParams: any;
      predictionsQueryParams: QueryParams = {};
      rows: any;
      saveItem: any;
      tableApi: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      updateData: any;
      constructor(
        $rootScope: GtRootScopeService,
        $window: ng.IWindowService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.gettext = gettext;

        this.tableData = {};
        this.tableOptions = {};
        this.rows = {};
        this.list = [];
        this.count = 0;
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();

        this.passportSelectParams = {
          export: {
            id_list: [this.predictionsQueryParams.passportId],
            cargo: this.predictionsQueryParams.contractCargo,
          },
          spot: {
            id_list: [this.predictionsQueryParams.passportId],
            contract: this.predictionsQueryParams.contractId,
          },
          undefined: {
            id_list: [this.predictionsQueryParams.passportId],
          },
        };
      }

      $onChanges(changes: any) {
        this.tableName = `${this.predictionsQueryParams.contractDealType as string}-fact-intermediate-list-table-view`;
        if (changes.list || changes.count) {
          this.tableData = { rows: this.list, count: this.count };
          this.tableOptions = this.getTableOptions();
        }
      }

      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass: 'payments-table fact-intermediate-list-table',
          applyFilters: this.applyFilters,
          templateArgs: {
            saveItem: (item: any) => this.saveItem({ item: item }),
            destroy: (item: any) => this.destroy({ item: item }),
            updateData: () => this.updateData(),
            setHovering: (value: any) => this.tableApi.setHovering({ value: value }),
            scrollToTop: () => this.$window.scrollTo(0, 100),
            $rootScope: this.$rootScope,
            predictionsQueryParams: this.predictionsQueryParams,
            passportSelectParams: this.passportSelectParams,
          },
          filterLevel: this.filterLevel,
          configurable: true,
          tabs: [],
          columnDefs: [
            {
              title: this.gettext('Contract type'),
              columnName: 'contractType',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="item.id">
                  {[{ item.contract_deal_type | cut:true:30:' '  }]}
                </span>
                <span ng-if="!item.id">
                  <select class="form-control" ng-model="item.contract_deal_type">
                    <option value="intermediate">
                      <span translate>Intermediate</span>
                    </option>
                  </select>
                </span>
              `,
            },
            {
              title: this.gettext('Contract'),
              columnName: 'contract',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.contract_number | cut:true:30:' '  }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select ng-model="item.contract"
                  placeholder="'Contract'|translate"
                  resource-name="'contracts.ContractBase'"
                  query-params="{
                    deal_type_list: [item.contract_deal_type],
                    cargo: args.predictionsQueryParams.contractCargo
                  }"
                ></gt-resource-select>
              </span>
              `,
            },
            {
              title: this.gettext('Passport'),
              columnName: 'passport',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
                <span ng-if="!$ctrl.edit && item.id">
                  {[{ item.passport_title | cut:true:30:' ' }]}
                </span>
                <span ng-if="$ctrl.edit || !item.id">
                  <gt-resource-select
                    ng-model="item.passport"
                    placeholder="'Passport'|translate"
                    resource-name="'passports.passport'"
                    query-params="args.passportSelectParams[
                      args.predictionsQueryParams.contractDealType
                    ]"
                  ></gt-resource-select>
                </span>
              `,
            },
            {
              title: this.gettext('Volume'),
              columnName: 'volume',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                {[{ item.volume || 0 | number: 3  }]}
                <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input class="form-control" gt-clear-input type="number" ng-model="item.volume">
              </span>
              `,
            },
            {
              title: this.gettext('Chain order'),
              columnName: 'chainOrder',
              class: 'td-right-align nowrap',
              cellTemplate: /*html*/ `
              <span class="label-strong" ng-if="!$ctrl.edit && item.id">
                {[{ item.chain_order  }]}
                <span class="smaller-label">{[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input class="form-control" gt-clear-input type="number" ng-model="item.chain_order">
              </span>
              `,
            },
            {
              title: this.gettext('Actions'),
              columnName: 'actions',
              class: 'td-left-align',
              cellTemplate: /*html*/ `
              <a class="btn btn-xs table-edit-btn" ng-hide="$ctrl.edit || !item.id" ng-click="$ctrl.edit = true">
                <i class="fa fa-pencil-square"></i>
              </a>
              <span ng-if="$ctrl.edit || !item.id" class="input-group-table-edit-btn">
                <a
                  class="btn-success btn_success btn btn-md"
                  ng-if="item.id"
                  ng-click="args.saveItem(item);$ctrl.edit = false"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  class="btn-success btn_success btn btn-md"
                  ng-if="!item.id"
                  ng-click="args.saveItem(item)"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  class="btn btn-md"
                  ng-if="item.id"
                  ng-click="$ctrl.edit = false"
                >
                  <i class="fa fa-times"></i>
                </a>
                <a
                  class="btn-danger btn_danger btn btn-md"
                  ng-if="item.id"
                  ng-click="args.destroy(item.id);$ctrl.edit = false"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </span>`,
            },
          ],
        };
      }
    },
  ],
};
