import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('customButton', directive);

  function directive() {
    return {
      template: require('./custom-button.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        button: '=',
      },
    };
  }

  function Controller() {
    ////////////////
  }
})();
