import ng from 'angular';

(function () {
  'use strict';

  ng.module('deals.contracts.legacy').directive('tabRequests', directiveFunction);

  function directiveFunction() {
    return {
      template: require('./tab-requests.tpl.html?raw'),
      scope: {
        contract: '=',
      },
      controller: 'TabRequestsController',
      controllerAs: 'vm',
    };
  }
})();
