import type { Column } from '@tanstack/react-table';
import type { CSSProperties } from 'react';

import type { ColumnDef } from './lib/types';

export const getStickyStyles = <T>(column: Column<T>): CSSProperties => {
  const isSticky = (column.columnDef as ColumnDef<T>).sticky;

  return {
    left: isSticky ? `${column.getStart()}px` : undefined,
    position: isSticky ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isSticky ? 20 : 0,
  };
};
