import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('GeneralTransactionsModalController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'FinancesService',
    'generalTransaction',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    FinancesService: FinancesService,
    generalTransaction: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.generalTransaction = generalTransaction || {};
    vm.errors = [];
    vm.logEntries = [];
    vm.form = undefined;
    vm.payment = {};
    vm.save = save;
    vm.close = close;
    vm.destroy = destroy;
    vm.updateData = updateData;
    vm.fields = getFields();

    activate();

    ////////////////

    function activate() {
      vm.generalTransaction.date = vm.generalTransaction.date || new Date();
      if (vm.generalTransaction.id) {
        updateData();
      }
    }

    function updateData() {
      return FinancesService.FinanceTransaction.get(
        { id: vm.generalTransaction.id },
        function (data: any) {
          vm.generalTransaction = data;
        },
      ).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete this transaction?');
      if (!confirm(msg)) {
        return;
      }
      FinancesService.FinanceTransaction.delete({ id: vm.generalTransaction.id }, function () {
        GtUtils.notify(gettext('Transaction removed'));
        close(null, true);
      });
    }

    function save() {
      vm.form.$invalid = true;

      if (vm.generalTransaction.id) {
        return FinancesService.FinanceTransaction.update(
          vm.generalTransaction,
          function (data: any) {
            close(data, true);
            GtUtils.notify(gettext('Invoice updated'));
          },
          _error,
        );
      }
      return FinancesService.FinanceTransaction.save(
        vm.generalTransaction,
        function (data: any) {
          vm.generalTransaction.id = data.id;
          close(data, true);
          GtUtils.notify(gettext('Invoice saved'));
        },
        _error,
      );
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
      vm.generalTransaction.errors = data.data;
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-sm-12 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-sm-12 col-xs-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-sm-12 col-xs-12',
        fieldGroup: [],
      };
      const col4: any = {
        className: 'form-group-container col-sm-12 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('DEBIT'),
        },
        fieldGroup: [
          {
            key: 'to_finance_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Debit Finance Account'),
              resource: 'finances.FinanceAccount',
              queryParams: { with_children: 0 },
              required: true,
            },
          },
          {
            key: 'debit_bank_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Debit Bank Account'),
              resource: 'finances.BankAccount',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.to_counterparty || model.from_counterparty',
              'templateOptions.required': 'model.commission',
            },
          },
          {
            key: 'commission_debit',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Debit Finance Account (commission)'),
              resource: 'finances.FinanceAccount',
              queryParams: { with_children: 0 },
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.to_counterparty || model.from_counterparty',
              'templateOptions.required': 'model.commission',
            },
          },
          {
            key: 'to_counterparty',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Debit Counterparty'),
              resource: 'clients.Client',
            },
            expressionProperties: {
              'templateOptions.disabled':
                'model.credit_bank_account || model.commission_credit ' +
                '|| model.commission_debit || model.debit_bank_account || model.commission',
            },
          },
        ],
      });
      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('CREDIT'),
        },
        fieldGroup: [
          {
            key: 'from_finance_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Credit Finance Account'),
              resource: 'finances.FinanceAccount',
              queryParams: { with_children: 0 },
              required: true,
            },
          },
          {
            key: 'credit_bank_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Credit Bank Account'),
              resource: 'finances.BankAccount',
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.to_counterparty || model.from_counterparty',
              'templateOptions.required': 'model.commission',
            },
          },
          {
            key: 'commission_credit',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Credit Finance Account (commission)'),
              resource: 'finances.FinanceAccount',
              queryParams: { with_children: 0 },
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.to_counterparty || model.from_counterparty',
              'templateOptions.required': 'model.commission',
            },
          },
          {
            key: 'from_counterparty',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Credit Counterparty'),
              resource: 'clients.Client',
            },
            expressionProperties: {
              'templateOptions.disabled':
                'model.credit_bank_account || model.commission_credit ' +
                '|| model.commission_debit || model.debit_bank_account || model.commission',
            },
          },
        ],
      });
      col3.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('AMOUNT'),
        },
        fieldGroup: [
          {
            key: 'amount',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Amount'),
              placeholder: gettext('Invoiced amount'),
              type: 'number',
              required: true,
              maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
              // for example 100 000 000,200
            },
          },
          {
            key: 'commission',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Commission'),
              placeholder: gettext('Commission amount'),
              type: 'number',
              maxlength: '13', // real value is 14. this is hundred of millions and 3 dig after coma.
              // for example 100 000 000,200
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.to_counterparty || model.from_counterparty',
            },
          },
          {
            key: 'currency',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Currency'),
              placeholder: gettext('USD, UAH'),
              resource: 'finances.Currency',
              required: true,
            },
          },
          {
            key: 'use_convertation',
            type: 'gt-checkbox',
            defaultValue: true,
            templateOptions: {
              label: gettext('use convertation'),
            },
          },
        ],
      });
      col4.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('OTHER'),
        },
        fieldGroup: [
          {
            key: 'date',
            type: 'gt-date-select',
            templateOptions: {
              label: gettext('Date of issuance'),
              placeholder: gettext('date'),
              hint: gettext('When you have issued invoice'),
              type: 'date',
              required: true,
            },
          },
          {
            key: 'business_unit',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Busines Unit'),
              resource: 'core.BusinessUnit',
            },
          },
          {
            key: 'additional_info',
            type: 'gt-textarea',
            templateOptions: {
              label: gettext('Additional Info'),
              placeholder: gettext('Specific information about this object'),
              className: 'additional-info',
            },
          },
        ],
      });

      return [col1, col2, col3, col4];
    }
  }
})();
