import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
import { Indicator, Root } from '@radix-ui/react-checkbox';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';
import { CheckIcon } from '~/shared/ui/icons';

const checkboxVariants = cva(
  'flex h-[20px] w-[20px] items-center justify-center rounded-sm border border-stroke-main-strongest bg-background-main-primary transition duration-300 ease-in-out',
  {
    variants: {
      size: {
        small: 'h-[16px] w-[16px]',
        medium: 'h-[20px] w-[20px]',
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  },
);

type CheckboxVariants = VariantProps<typeof checkboxVariants>;

type Size = 'medium' | 'small';

const indicatorSizes: Record<Size, number> = {
  medium: 15,
  small: 11,
};

const getIndicatorClassName = (size: Size) => indicatorSizes[size];

export const Checkbox = React.forwardRef<
  HTMLButtonElement,
  Omit<RadixCheckboxProps, 'size' | 'className'> & {
    id?: string;
    label?: string;
    labelPosition?: 'left' | 'right';
    icon?: React.ReactNode;
  } & CheckboxVariants
>(({ id, label, icon, disabled, labelPosition = 'left', size = 'medium', ...props }, ref) => {
  return (
    <div className={cn('flex items-center gap-1.5', disabled && 'cursor-not-allowed opacity-20')}>
      {labelPosition === 'right' && label && (
        <label className={cn('cursor-pointer', disabled && 'cursor-not-allowed')} htmlFor={id}>
          {label}
        </label>
      )}
      <Root
        className={cn(
          'relative hover:ring-4 hover:ring-transparent-light',
          'disabled:hover:ring-0',
          'aria-checked:border-stroke-section-primary aria-checked:bg-background-section-primary aria-checked:ring-background-section-light',
          checkboxVariants({ size }),
        )}
        defaultChecked
        id={id}
        data-testid="checkbox"
        ref={ref}
        disabled={disabled}
        {...props}
      >
        <Indicator className="absolute bottom-[1px]">
          {!icon ? (
            <CheckIcon
              size={getIndicatorClassName(size!)}
              className="text-text-main-inverted"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          ) : (
            icon
          )}
        </Indicator>
      </Root>
      {labelPosition === 'left' && label && (
        <label className={cn('cursor-pointer', disabled && 'cursor-not-allowed')} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
});

Checkbox.displayName = 'Checkbox';
