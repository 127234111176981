export class ApprovalsReportService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/approvals-report/',
      params: params,
    }).then((data: any) => data.data);
  }
}
ApprovalsReportService.$inject = ['$http'];
