import template from './offset-positions-table-view.html?raw';

export const OffsetPositionsTableView = {
  bindings: {
    positionsTableRows: '<?',
    positionsTableCount: '<?',
    canEdit: '<?',
    removePositionFromOffset: '&?',
    updateOffsetAmount: '&?',
    filterLevel: '<',
  },
  template,
  controller: [
    'gettext',
    class {
      canEdit: any;
      filterLevel = 'offset-positions-table-view';
      gettext: ng.gettext.gettextFunction;
      positionsTableCount: number;
      positionsTableData: any;
      positionsTableRows: any;
      removePositionFromOffset: any;
      tableData: any;
      tableOptions: any;
      updateOffsetAmount: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;

        this.positionsTableRows = [];
        this.positionsTableCount = 0;
        this.canEdit = false;
        this.tableData = {};
      }

      $onInit() {
        this.positionsTableData = {
          rows: this.positionsTableRows,
          count: this.positionsTableCount,
        };
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.positionsTableRows || changes.positionsTableCount) {
          this.tableData = {
            rows: this.positionsTableRows,
            count: this.positionsTableCount,
          };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'offset-positions-table',
          configurable: true,
          tableClass: ['request-table'],
          filterLevel: this.filterLevel,
          templateArgs: {
            removePositionFromOffset: (item: any) => this.removePositionFromOffset({ item: item }),
            updateOffsetAmount: () => this.updateOffsetAmount(),
            canEdit: this.canEdit,
          },
          columnDefs: [] as any[],
          tabs: [],
          rowData: this.positionsTableRows,
          rowCount: 0,
        };

        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('Number'),
            cellTemplate: `
            <a ui-sref="gt.reassignment({id: item.reassignment})" target="_blank" ng-if="item.reassignment">
              <span data-tip="{[{ item.number}]}">
                <i class="fa fa-reply-all"></i> #{[{ item.number | cut:true:25:' ...' }]}
              </span>
            </a>
            <a ng-if="item.dbl">
              <span data-tip="{[{ item.number}]}">
                #{[{ item.number | cut:true:25:' ...' }]}
              </span>
            </a>
            <span
              class="label pull-right"
              style="margin-left:2px; float: right !important;"
              ng-class="{ 'label_default': item.status == 'done' , 'label_success': item.status == 'new', 'label_warning': item.status == 'process' } "
              >
              <translate>{[{ item.status }]}</translate>
            </span>
          `,
          },
          {
            columnName: 'multicontract_number',
            title: this.gettext('Multicontract No.'),
            cellTemplate: `
            <a
              ui-sref="gt.multicontract({id: item.reassignment_multicontract_id || item.purchase_contract_multicontract_id })"
              ng-if="item.reassignment_multicontract_number || item.purchase_contract_multicontract_number">
              #{[{ item.reassignment_multicontract_number || item.purchase_contract_multicontract_number }]}
            </a>
          `,
          },
          {
            columnName: 'contract_number',
            title: this.gettext('Contract'),
            cellTemplate: `
            <a ui-sref="gt.page.contract({id: item.contract })">
              #{[{ item.contract_number }]}
            </a>
          `,
          },
          {
            columnName: 'supplier_name',
            title: this.gettext('Supplier'),
            cellTemplate: `
            <span ng-if="!item.supplier">
              ---
            </span>
            <span ng-if="item.supplier">
              {[{ item.supplier_name }]}
            </span>
          `,
          },
          {
            columnName: 'distributor_name',
            title: this.gettext('Distributor'),
            cellTemplate: `
            <span ng-if="!item.distributor">
              ---
            </span>
            <span ng-if="item.distributor">
              <a ui-sref="gt.role({role: 'supplier', id: item.distributor})">
                {[{ item.distributor_name }]}
              </a>
            </span>
          `,
          },
          {
            columnName: 'date',
            title: this.gettext('Date'),
            cellTemplate: `
            <span ng-if="item.date">
              {[{ item.date | date: 'dd.MM.yy' }]}
            </span>
            <span ng-if="!item.date">
              ---
            </span>

          `,
          },
          {
            columnName: 'amount_available',
            title: this.gettext('Amount to connect'),
            cellTemplate: `
            <span ng-if="args.canEdit">
              <input
                type="number"
                class="form-control"
                ng-model="item.amount_available"
                placeholder="{[{ 'Amount to connect' | translate }]}"
                required
              />
            </span>
            <span ng-if="!args.canEdit">
              {[{ item.amount_available || 0 | number: 2 }]}
            </span>
          `,
          },
          {
            columnName: 'amount',
            title: this.gettext('Amount'),
            cellTemplate: `
            <span ng-if="args.canEdit">
              <input
                type="number"
                class="form-control"
                ng-model="item.amount"
                placeholder="{[{ 'Amount' | translate }]}"
                ng-change="args.updateOffsetAmount()"
                required
              />
            </span>
            <span ng-if="!args.canEdit">
              {[{ item.amount || 0 | number: 2 }]}
            </span>
          `,
          },
          {
            columnName: 'balance',
            title: this.gettext('Balance'),
            cellTemplate: `
            <span ng-class="{'label label_danger': item.ready_to_connect - item.amount < 0}">
              {[{ (item.ready_to_connect - item.amount) || 0 | number: 2 }]}
            </span>
          `,
          },
        ];

        if (this.canEdit) {
          options.columnDefs.push({
            columnName: 'remove',
            title: this.gettext('Remove'),
            cellTemplate: `
            <a class="btn btn-xs btn-danger btn_danger" ng-click="args.removePositionFromOffset(item);">
              <i class="fa fa-trash"></i>
            </a>
          `,
          });
        }

        return options;
      }
    },
  ],
};
