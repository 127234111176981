import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { IconComponent } from '~/shared/ui/icons';

import type { DataTypeProps } from '../../lib';

export const EntityRead: React.FC<
  DataTypeProps<{ id?: string; Icon?: IconComponent; title: string }>
> = ({ disabled, value }) => {
  return (
    <div
      className={cn(
        'flex gap-2 px-2 py-1 transition duration-300 ease-in-out hover:bg-transparent-light',
        disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      {value.Icon && <value.Icon size={14} />}
      <p className="text-xs text-text-main-primary">{value.title}</p>
    </div>
  );
};
