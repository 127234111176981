import React from 'react';

import { LocalSearch } from '~/core/theme/components/local-search';
import { toggleHandler } from '~/core/theme/components/menu/helpers';
import { useMenu } from '~/core/theme/hooks/use-menu';
import { useObservableEagerState } from '~/shared/lib/state';
import { cn } from '~/shared/lib/utils';

import { ExpandMenuItems } from '../expand-menu-items';
import { LocalSearchItem } from '../local-search-item';
import { PageNavigation } from '../page-navigation';
import type { SidebarNestedMenuItem } from '../types';

export const NestedMenuItems: React.FC<{
  items: SidebarNestedMenuItem[];
}> = ({ items }) => {
  const { menuStore } = useMenu();

  const openNestedMenuIds = useObservableEagerState(menuStore.openNestedMenuIds$);

  const openNestedMenuHandler = React.useCallback(
    (id: string) =>
      toggleHandler({
        id,
        currentIds: openNestedMenuIds,
        updateMethod: (ids: string[]) => menuStore.openNestedMenuIdsChanged(ids),
      }),
    [menuStore, openNestedMenuIds],
  );

  return (
    <div>
      {items.map((item) => (
        <div key={item.id}>
          <div className="ml-5">
            {!item.details ? (
              <PageNavigation href={item.href} isActive={item.isActive} label={item.label} />
            ) : (
              <ExpandMenuItems
                isOpen={openNestedMenuIds.includes(item.id)}
                isActive={item.isActive}
                label={item.label}
                id={item.id}
                onClick={() => openNestedMenuHandler(item.id)}
              />
            )}
          </div>
          {(item.isActive || openNestedMenuIds.includes(item.id)) && item.details && (
            <div
              className={cn(
                'relative flex flex-col gap-0.5',
                openNestedMenuIds.includes(item.id) &&
                  'after:absolute after:left-11 after:top-0 after:h-full after:w-[1px] after:bg-transparent-strong',
              )}
            >
              {item.details.map((detail) => (
                <LocalSearchItem key={detail.id} item={detail} />
              ))}
            </div>
          )}
          <div
            className={cn(
              'relative left-5 flex flex-col gap-0.5',
              !openNestedMenuIds.includes(item.id) &&
                'after:absolute after:left-6 after:top-0 after:h-full after:w-[1px] after:bg-transparent-strong',
            )}
          >
            {item.isActive && !openNestedMenuIds.includes(item.id) ? <LocalSearch /> : null}
          </div>
        </div>
      ))}
    </div>
  );
};

NestedMenuItems.displayName = 'SubmenuItem';
