import ng from 'angular';

(function () {
  'use strict';

  ng.module('deals.contracts.legacy').directive('detailsContract', directiveFunction);

  function directiveFunction() {
    return {
      template: require('./details-contract.tpl.html?raw'),
      scope: {
        contract: '=',
      },
      controller: 'DetailsContractController',
      controllerAs: 'vm',
    };
  }
})();
