import React from 'react';

import { IconSelector } from '~/shared/ui/components/icon-selector';
import { ChevronLeftIcon, CubeIcon, LockIcon, ShineIcon } from '~/shared/ui/icons';
import {
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';

import type { ReorderedColumn } from '../../types';

export const EditProperty: React.FC<{
  value: ReorderedColumn;
  open?: boolean;
  onOpen?: (open: boolean) => void;
}> = ({ value, open, onOpen }) => {
  return (
    <DropdownMenuSub open={open} onOpenChange={onOpen}>
      <DropdownMenuSubTrigger className="w-max p-0" />
      <DropdownMenuSubContent sideOffset={10} className="p-0">
        <div className="flex h-8 items-center gap-0.5 px-2">
          <ChevronLeftIcon
            size={16}
            className="cursor-pointer text-text-main-tertiary transition duration-300 ease-in-out hover:opacity-70"
            onClick={() => onOpen?.(false)}
          />
          <p className="p-1 text-xs font-medium text-text-main-primary">Edit Property</p>
        </div>
        <DropdownMenuSeparator className="bg-background-main-tertiary" />
        <div className="flex flex-col gap-2 p-2">
          <div className="flex items-center gap-2">
            <IconSelector selectedIcon={value.icon} />
            <Input defaultValue={value.name} fullWidth />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <CubeIcon size={14} className="text-stroke-main-stronger" />
              <p className="text-xs text-text-main-primary">Type</p>
            </div>
            <div className="flex items-center gap-1">
              <LockIcon size={14} className="text-stroke-main-stronger" />
              <p className="text-xs capitalize text-text-main-tertiary">{value.type}</p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <ShineIcon size={14} className="text-stroke-main-stronger" />
              <p className="text-xs text-text-main-primary">Size</p>
            </div>
            <div className="w-12">
              <Input type="number" defaultValue={value.size} fullWidth />
            </div>
          </div>
        </div>
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  );
};
