import ng from 'angular';

import { AnalyticsContainer } from './analytics-container/analytics-container.component';
import { AnalyticsPageView } from './analytics-page-view/analytics-page-view.component';
import { ClientsAnalyticsService } from './analytics.service';

const clientsAnalytics: any = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('clients.analytics', {
      url: '/analytics',
      component: 'clientsAnalyticsPageView',
      data: {
        pageTitle: gettext('Analytics'),
      },
    });
  },
];

export const analytics = ng
  .module('crm.clients.analytics', ['crm.clients'])
  .component('clientsAnalyticsContainer', AnalyticsContainer)
  .component('clientsAnalyticsPageView', AnalyticsPageView)
  .service('ClientsAnalyticsService', ClientsAnalyticsService)
  .config(clientsAnalytics).name;
