import React from 'react';

import { container } from '~/shared/lib/di';
import { createCanBoundTo } from '~/shared/lib/perms';
import { useObservableEagerState } from '~/shared/lib/state';

import { AuthStore } from '../services';

export const useAuth = () => {
  const authStore = container.resolve(AuthStore);
  const isAuthenticated = useObservableEagerState(authStore.isAuthenticated$);
  const currentUser = useObservableEagerState(authStore.currentUser$);
  const loading = useObservableEagerState(authStore.loading$);
  const initialized = useObservableEagerState(authStore.initialized$);
  const Can = createCanBoundTo(authStore.ability);

  const auth = React.useMemo(() => {
    return {
      authStore,
      isAuthenticated,
      loading,
      initialized,
      currentUser,
      Can,
    };
  }, [authStore, isAuthenticated, loading, initialized, currentUser, Can]);

  return auth;
};
