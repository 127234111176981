import type { ClientsAnalyticsService } from '../../clients/analytics/analytics.service';

export const CrmAnalyticsApprovedChart = {
  bindings: {},
  template:
    '<charts-block config="$ctrl.chartConfig" chart-type="bar" hide-header="true" hide-selects="true"></charts-block>',
  controller: [
    'ClientsAnalyticsService',
    class {
      ClientsAnalyticsService: ClientsAnalyticsService;
      chartConfig: any;
      constructor(ClientsAnalyticsService: ClientsAnalyticsService) {
        this.ClientsAnalyticsService = ClientsAnalyticsService;
        this.chartConfig = undefined;
      }

      $onInit() {
        this.ClientsAnalyticsService.getApprovedChartConfig().then(
          (data: any) => (this.chartConfig = data),
        );
      }
    },
  ],
};
