import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { logisticDetailsItemContainer } from './item-container/logistic-details-item-container.component';
import { logisticDetailsPage } from './item-page/logistic-detail-page.component';
import { logisticDetailsItemView } from './item-view/logistic-details-item-view.component';

const logisticConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('gt.page.logistic', {
      url: '/logistic/:id',
      component: 'logisticDetailsPage',
      resolve: {
        filterLevel: () => {
          return 'logistic-page-view';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('logistic-page-view');
          },
        ],
      },
      data: {
        pageTitle: gettext('Transport'),
        permissions: {
          only: ['view_logistic'],
        },
      },
    });
  },
];

export const logisticDetails = ng
  .module('logistic.details', [])
  .component('logisticDetailsPage', logisticDetailsPage)
  .component('logisticDetailsItemContainer', logisticDetailsItemContainer)
  .component('logisticDetailsItemView', logisticDetailsItemView)
  .config(logisticConfig).name;
