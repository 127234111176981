import React from 'react';

import type { CommonDataType } from '~/shared/ui/data-types';

import type { FilterOption, FilterValue } from '../../types';
import {
  CheckboxFilter,
  DateFilter,
  EmailFilter,
  type FilterTypeProps,
  LabelFilter,
  NumberFilter,
  PhoneFilter,
  RelationFilter,
  TextFieldFilter,
  UrlFilter,
} from '../filters-types';

const filtersTypes: Record<CommonDataType, React.FC<FilterTypeProps>> = {
  text: TextFieldFilter,
  string: TextFieldFilter,
  number: NumberFilter,
  checkbox: CheckboxFilter,
  url: UrlFilter,
  email: EmailFilter,
  label: LabelFilter,
  entity: RelationFilter,
  phone: PhoneFilter,
  select: RelationFilter,
  multiselect: RelationFilter,
  date: DateFilter,
};

export const DynamicFilterControl: React.FC<{
  option: FilterOption;
  value: FilterValue;
  onChange: (filter: FilterValue) => void;
}> = ({ option, value, onChange }) => {
  const Filter = React.useMemo(() => filtersTypes[option.type], [option.type]);

  return <Filter filterValue={value} filterOption={option} onChange={onChange} />;
};
