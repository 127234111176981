import ng from 'angular';

(function () {
  'use strict';
  ng.module('accounts.legacy').controller('AccountsLogentryModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'logentry', 'gettext'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    logentry: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.close = close;
    vm.logentry = logentry;

    ////////////////

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
