import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './disbursementbl-list-table-view.html?raw';

export const DisbursementblListTableView = {
  bindings: {
    listData: '<',
    totalsData: '<',
    count: '<',
    filterLevel: '<',
    openDisbursementblModal: '&',
    openDocModal: '&',
    applyFilters: '&',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      applyFilters: any;
      count: number;
      filterLevel = 'disbursementbl-list-table-view';
      gettext: ng.gettext.gettextFunction;
      listData: any;
      openDisbursementblModal: any;
      openDocModal: any;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      totalsData: any;
      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;

        this.listData = [];
        this.count = 0;

        this.tableOptions = {};
        this.queryParams = {};
        this.tableData = {};
      }

      $onInit() {
        this.listData = this.listData || [];
        this.count = this.count || 0;
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.listData || changes.count || changes.totalsData) {
          this.tableData = { rows: this.listData, count: this.count, total: this.totalsData };
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'disbursementbl-list-table-view',
          tableClass: 'request-table',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          templateArgs: {
            openDisbursementblModal: (disbursementbl: any) =>
              this.openDisbursementblModal({ disbursementbl: disbursementbl }),
            openDocModal: (disbursementbl: any) =>
              this.openDocModal({ disbursementbl: disbursementbl }),
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
        };

        options.tabs = [
          {
            serializer: 'table_info',
            title: this.gettext('Info'),
            icon: 'fa-info-circle',
            columns: [
              'status',
              'purchase_contract_multicontract_number',
              'purchase_ctr_supplier',
              'purchase_ctr_number',
              'purchase_contract_supplier_company_code',
              'purchase_contract_saporders_data',
              'purchase_ctr_conclusion_date',
              'purchase_ctr_volume',
              'purchase_ctr_value_with_vat',
              'dbl_date',
              'dbl_volume',
              'dbl_price',
              'dbl_value_with_vat',
              'amount_to_connect',
              'premium_price',
              'premium_amount',
              'distributors_plan',
              'distributors',
              'invoices',
              'sap_orders',
              'reassignments',
              'offset',
              'cargo',
              'purchase_ctr_basis',
              'purchase_ctr_terminal',
              'number',
              'purchase_contract_responsible_for_signing',
              'additional_info',
              'purchase_ctr_loyalty_program',
              'purchase_ctr_departed_station',
              'purchase_ctr_author',
              'place_of_shipment_unloading',

              'sale_contract_number',
              'sale_contract_price',
              'sale_contract_execution_dates',
              'sale_contract_buyer_name',
              'sale_contract_saporders_data',

              'purchase_contract_custom_status',
              'purchase_contract_payment_conditions_option',
              'activity_list',
            ],
          },
        ];

        options.columnDefs = [
          {
            columnName: 'purchase_ctr_supplier',
            title: this.gettext('Name of seller purchase contract'),
            predicate: 'purchase_contract_supplier_name',
            cellTemplate: /*html*/ `
            <a class="btn-link" ui-sref="gt.page.client({ id: item.purchase_contract_supplier_id })" target="_blank">
              <i class="fa fa-bookmark-o"></i> {[{ item.purchase_contract_supplier_name }]}
            </a>
             <a class="pull-right" ng-click="args.openDocModal(item)"> <i class="fa fa-upload"></i> </a>
            `,
            totalTemplate: /*html*/ `
            <i class="fa fa-pie-chart"></i> <translate>TOTAL</translate>
            `,
          },
          {
            columnName: 'purchase_contract_multicontract_number',
            title: this.gettext('multicontract No.'),
            class: 'td-left-align small-font-cell',
            predicate: 'purchase_contract_multicontract_number',
            cellTemplate: /*html*/ `
            <a class="btn-link"
              ui-sref="gt.multicontract({ id: item.purchase_contract_multicontract_id })"
              target="_blank"
              ng-if="item.purchase_contract_multicontract_number">
              <i class="fa fa-arrow-down"></i> {[{ item.purchase_contract_multicontract_number }]}
            </a>
            `,
          },
          {
            columnName: 'purchase_ctr_number',
            title: this.gettext('purchase Contr. No.'),
            class: 'td-left-align small-font-cell',
            predicate: 'purchase_contract_number',
            cellTemplate: /*html*/ `
            <a class="btn-link" ui-sref="gt.page.contract({ id: item.purchase_contract_id })" target="_blank">
              <i class="fa fa-arrow-down"></i> {[{ item.purchase_contract_number || '---' }]}
            </a>
            `,
          },
          {
            columnName: 'purchase_contract_supplier_company_code',
            title: this.gettext('Purchase contract supplier company code'),
            class: 'td-left-align small-font-cell',
          },
          {
            columnName: 'purchase_ctr_conclusion_date',
            title: this.gettext('purchase Contract Date'),
            class: 'small-font-cell',
            predicate: 'purchase_contract_conclusion_date',
            cellTemplate: /*html*/ `
            <i class="fa fa-calendar"></i>
            {[{ item.purchase_contract_conclusion_date || "---" | date:'dd.MM.yy'}]}
            `,
          },
          {
            columnName: 'purchase_ctr_volume',
            title: this.gettext('purchase Contr. Volume, mt'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            {[{ item.purchase_contract_volume || 0 | number:3 }]}
            `,
          },
          {
            columnName: 'purchase_ctr_value_with_vat',
            title: this.gettext('purchase Contr. Volume, UAH'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            {[{ item.purchase_contract_value_with_vat || 0 | number:2 }]}
            `,
          },
          {
            columnName: 'dbl_date',
            title: this.gettext('Date of Disbursement bill of lading'),
            class: 'small-font-cell no-blur',
            predicate: 'date',
            cellTemplate: /*html*/ `

              <i class="fa fa-calendar"></i>
              {[{ item.date || "---" | date:'dd.MM.yy'}]}

            <a
              class="btn btn-xs btn-blue-border"
              ng-click="args.openDisbursementblModal(item)"
            >
              <i class="fa fa-pencil-square"></i>
            </a>
            `,
          },
          {
            columnName: 'dbl_volume',
            title: this.gettext('Disbursement bill of lading Quantity, mt'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            {[{ item.volume || 0 | number:3 }]}
            `,
            totalTemplate: /*html*/ `
            <span ng-if="item.volume"> {[{ item.volume || 0 | number:3 }]} </span>
            `,
          },
          {
            columnName: 'dbl_price',
            title: this.gettext('Disbursement bill of lading Price incl. VAT, UAH'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            {[{ item.price || 0 | number:2 }]}
            `,
          },
          {
            columnName: 'dbl_value_with_vat',
            title: this.gettext('Disbursement bill of lading Amount incl. VAT, UAH'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            {[{ item.value_with_vat || 0 | number:2 }]}
            `,
            totalTemplate: /*html*/ `
            <div ng-if="item.value_with_vat">
              {[{ item.value_with_vat || 0 | number:2 }]}
              </div>
          `,
          },
          {
            columnName: 'type_of_activities',
            title: this.gettext('type of activities'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span class="label" ng-if="item.type_of_activities_data">
                <div ng-repeat="item in item.type_of_activities_data">
                  {[{item.title}]}
                </div>
              </span>
            `,
          },
          {
            columnName: 'distributors_plan',
            title: this.gettext('Distributors plan'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="distributor in item.request_distributors_data">
              <a class="btn-link" ui-sref="gt.page.client({ id: distributor.id })" target="_blank">
                <span>{[{ distributor.name }]}</span>
              </a>
              <div class="clearfix"></div>
            </span>
            `,
          },
          {
            columnName: 'distributors',
            title: this.gettext('Distributors fact'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="distributor in item.distributors_data">
              <a class="btn-link" ui-sref="gt.page.client({ id: distributor.id })" target="_blank">
                <span>{[{ distributor.name }]}</span>
              </a>
              <div class="clearfix"></div>
            </span>
            `,
          },
          {
            title: this.gettext('Amount to connect'),
            columnName: 'amount_to_connect',
            predicate: 'amount_to_connect',
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              {[{ item.amount_to_connect || 0 | number: 2 }]}
            `,
            totalTemplate: /*html*/ `
              {[{ item.amount_connected_total || 0 | number: 2 }]}
            `,
          },
          {
            columnName: 'status',
            title: this.gettext('Status'),
            cellTemplate: /*html*/ `
            <span
                  class="label"
                  ng-class="{'label_default': item.status == 'new',
              'label_success': item.status == 'done',
            'label_warning': item.status == 'process',
            'label_calculate': item.status == 'ready',
            'label_neutral': item.status == 'blocked'}"
                >
            {[{ item.status }]}
            </span>
              `,
          },
          {
            columnName: 'sap_orders',
            title: this.gettext('Sap Orders'),
            class: 'wrap-text',
            cellTemplate: /*html*/ `
              <span ng-repeat="sap_order_number in item.sap_order_numbers">
                {[{ sap_order_number }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'invoices',
            title: this.gettext('Invoices'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="invoice in item.invoices_data">
              <a class="btn-link" ui-sref="gt.page.payment({ id: invoice.id })" target="_blank">
                {[{ invoice.number }]} <translate>from</translate>
                <i class="fa fa-calendar"></i> {[{ invoice.date || "---" | date:'dd.MM.yy'}]}
              </a>
              <div class="clearfix"></div>
            </span>
            `,
          },
          {
            columnName: 'reassignments',
            title: this.gettext('Reassignments'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="reassignment in item.reassignments_data">
              <a class="btn-link" ui-sref="gt.reassignment({ id: reassignment.id })" target="_blank">
                {[{ reassignment.number }]} <translate>from</translate>
                <i class="fa fa-calendar"></i> {[{ reassignment.date || "---" | date:'dd.MM.yy'}]} = {[{ reassignment.reassignment_amount_sum | number: 2}]}
              </a>
              <div class="clearfix"></div>
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.offsets_amount_sum">
            <i class="fa fa-pie-chart"></i> {[{ item.reassignments_amount_sum | number: 2}]}
            </span>
            `,
          },
          {
            columnName: 'offset',
            title: this.gettext('Offset'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="offset in item.offsets_data">
              <a class="btn-link" ui-sref="gt.offset({ id: offset.id })" target="_blank">
                {[{ offset.number }]} <translate>from</translate>
                <i class="fa fa-calendar"></i> {[{ offset.date || "---" | date:'dd.MM.yy'}]} = {[{ offset.amount | number: 2}]}
              </a>
              <div class="clearfix"></div>
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.offsets_amount_sum">
            <i class="fa fa-pie-chart"></i> {[{ item.offsets_amount_sum | number: 2}]}
            </span>
            `,
          },
          {
            columnName: 'cargo',
            title: this.gettext('Cargo'),
            class: 'td-right-align',
            predicate: 'crop_title',
            cellTemplate: /*html*/ `
            {[{ item.crop_title }]}
          `,
          },
          {
            columnName: 'purchase_ctr_basis',
            title: this.gettext('Delivery Basis'),
            cellTemplate: /*html*/ `
            <span class="label label_default">
              {[{ item.purchase_basis_name || '---'}]}
            </span>
            `,
          },
          {
            columnName: 'place_of_shipment_unloading',
            title: this.gettext('place of shipment / unloading'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
            <span ng-repeat="port in item.purchase_contract_ports_list">
              <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE] || port.name
              | cut:true:30:'...'}]}
              <div class="clearfix"></div>
            </span>
            <div class="clearfix"></div>
            </span>
            <!-- FARM -->
            <span
              ng-if="item.purchase_contract_farm_name"
              class="tooltip btn-link"
              data-tip="{[{ item.purchase_contract_farm_name }]}"
            >
               <i class="fa fa-building-o"></i> {[{ item.purchase_contract_farm_name | cut:true:30:'...' || "---" }]}
            </span>
            <!-- ELEVATOR -->
            <span
              ng-if="item.purchase_contract_elevator_name"
              class="tooltip btn-link"
              data-tip="{[{ item.purchase_contract_elevator_name }]}"
            >
               <i class="fa fa-industry"></i> {[{ item.purchase_contract_elevator_name | cut:true:30:'...' || "---" }]}
            </span>
            <div class="clearfix"></div>
            `,
          },
          {
            columnName: 'number',
            title: this.gettext('Dbl number'),
            class: 'td-left-align small-font-cell',
          },
          {
            columnName: 'purchase_contract_responsible_for_signing',
            title: this.gettext('Responsible for signing'),
            class: 'td-left-align small-font-cell',
          },
          {
            columnName: 'additional_info',
            title: this.gettext('Additional info'),
            class: 'td-left-align small-font-cell',
          },
          {
            columnName: 'purchase_ctr_loyalty_program',
            title: this.gettext('Loyalty Program'),
            cellTemplate: /*html*/ `
            <span
              ng-if="item.loyalty_program_title"
              class="tooltip"
              data-tip="{[{'loyalty program from disbursement bill of lading'|translate}]}"
            >
              <i class="fa fa-info-circle"></i>
              {[{ item.loyalty_program_title }]}
            </span>
            <span
              ng-if="!item.loyalty_program_title && item.purchase_loyalty_program_title"
              class="tooltip"
              data-tip="{[{'loyalty program from contract'|translate}]}"
            >
              <i class="fa fa-info-circle"></i>
              {[{ item.purchase_loyalty_program_title }]}
            </span>
          `,
          },
          {
            columnName: 'purchase_ctr_author',
            title: this.gettext('Purchase contract author'),
            cellTemplate: /*html*/ `
            <span
              class="tooltip"
              data-tip="{[{ 'last updated' | translate }]} - {[{item.purchase_contract_update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.purchase_contract_author_first_name }]}, {[{ 'created' | translate }]} - {[{item.purchase_contract_create_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.purchase_contract_author_first_name }]}"
            >
              <div class="user-avatar" ng-if="item.purchase_contract_author_first_name && item.purchase_contract_author_profile_avatar.length">
                <img ng-src="/pictures/{[{item.purchase_contract_author_profile_avatar}]}" />
              </div>
              <div class="no-user-avatar" ng-if="!item.purchase_contract_author_profile_avatar.length">
                {[{ item.purchase_contract_author_first_name | cut:true:1:' ' }]}
              </div>
            </span>
          `,
          },
          {
            columnName: 'sale_contract_number',
            title: this.gettext('sale Contr. No.'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
            <a class="btn-link" ui-sref="gt.page.contract({ id: item.sale_contract_id })" target="_blank">
              <i class="fa fa-file-text"></i> {[{ item.sale_contract_number || '---' }]}
            </a>
          `,
          },
          {
            columnName: 'sale_contract_buyer_name',
            title: this.gettext('Name of Buyer sale contract'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <a class="btn-link" ui-sref="gt.page.client({ id: item.sale_contract_buyer_id })" target="_blank">
              <i class="fa fa-bookmark"></i> {[{ item.sale_contract_buyer_name }]}
            </a>
            `,
          },
          {
            columnName: 'sale_contract_price',
            title: this.gettext('Sale price (incl. VAT)'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.sale_contract_price">
              {[{ item.sale_contract_price || 0 | number:2 }]}
            </span>
            `,
          },
          {
            columnName: 'sale_contract_saporders_data',
            title: this.gettext('Order sale contract'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="saporder in item.sale_contract_saporders_data">
              <span>
                {[{ saporder.number }]}
              </span>
              <div class="clearfix"></div>
            </span>
            `,
          },
          {
            columnName: 'purchase_contract_saporders_data',
            title: this.gettext('SAP Order Purchase Contract'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="saporder in item.purchase_contract_saporders_data">
              <span>
                {[{ saporder.number }]}
              </span>
              <div class="clearfix"></div>
            </span>
            `,
          },
          {
            columnName: 'sale_contract_execution_dates',
            title: this.gettext('Delivery period sale contract'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.sale_contract_date_of_execution">
              <i class="fa fa-calendar"></i>
              {[{ item.sale_contract_date_of_execution || "---" | date:'dd.MM.yy'}]} -
              <i class="fa fa-calendar"></i>
              {[{ item.sale_contract_end_of_execution || "---" | date:'dd.MM.yy'}]}
            </span>
            `,
          },
          {
            columnName: 'premium_price',
            title: this.gettext('Price of premium'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.premium_price">
              {[{ item.premium_price || 0 | number:2 }]}
            </span>
            `,
          },
          {
            columnName: 'premium_amount',
            title: this.gettext('Amount of premium'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.premium_amount">
              {[{ item.premium_amount || 0 | number:2 }]}
            </span>
            `,
          },
          {
            columnName: 'purchase_contract_custom_status',
            title: this.gettext('purchase contract custom status'),
            cellTemplate: /*html*/ `
              {[{ item.purchase_contract_custom_status }]}
          `,
          },
          {
            columnName: 'purchase_contract_payment_conditions_option',
            title: this.gettext('Purchase contract payment conditions option'),
            cellTemplate: /*html*/ `
              {[{ item.purchase_contract_payment_conditions_option }]}
          `,
          },
          {
            columnName: 'activity_list',
            title: this.gettext('Type of activities'),
            cellTemplate: /*html*/ `
              <span ng-repeat="activity in item.activity_list">
                  <span>
                    {[{ activity }]}
                  </span>
                <div class="clearfix"></div>
              </span>
          `,
          },
        ];

        return options;
      }
    },
  ],
};
