import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('creditNotesPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./credit-notes-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'FinancesService', 'PageService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    FinancesService: FinancesService,
    PageService: PageService,
  ) {
    const vm = this;

    vm.tableExpanded = false;
    vm.quickAdd = false;
    vm.groupBy = null;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'credit-notes-page-view';
      PageService.setConfig(getPageConfig());
      $scope.$on('group-by', function (ev: any, data: any) {
        vm.groupBy = data;
      });
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-payments-tab',
        buttons: [new PageService.buttons.Print(), new PageService.buttons.Refresh(vm.filterLevel)],
        pages: FinancesService.getPagesConfig(),
      };

      // @ts-ignore
      config.filters = {
        groupBy: {},
        filterLevel: vm.filterLevel,
        ordering: [],
        clicked: false,
        search: true,
        dates: true,
        dateSelects: [],
        multiSelects: [],
        nestedSelects: [],
        nestedMultiSelects: [],
      };

      return config;
    }
  }
})();
