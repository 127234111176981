import React from 'react';

import { PaginationPageButton } from './pagination-page-button';
export const PaginationButtonsList = ({
  buttonsBar,
  pageCurrent,
  onPageClick,
}: {
  buttonsBar: (string | number)[];
  pageCurrent: number;
  onPageClick: (page: number) => void;
}) => (
  <ul className="flex gap-x-1">
    {buttonsBar.map((item, i) => (
      <li key={`${item}` + i}>
        {item === '...' ? (
          <p>{item}</p>
        ) : (
          <PaginationPageButton
            active={item === pageCurrent}
            onClick={onPageClick}
            page={Number(item)}
          />
        )}
      </li>
    ))}
  </ul>
);
