import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('accounts.legacy').directive('approvals', directive);

  function directive() {
    return {
      template: require('./approvals.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        objectId: '=',
        contentType: '=',
        configId: '<?',
        editRights: '<?',
        showEdit: '<?',
        updateList: '=?',
        approvalStatus: '<?',
      },
    };
  }

  Controller.$inject = ['$scope', '$http', 'AccountsService', 'gtFilterService', 'GtUtils'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $http: ng.IHttpService,
    AccountsService: any,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
  ) {
    const vm = this;

    vm.approvals = [];
    vm.openApprovalsModal = openApprovalsModal;
    vm.updateList = vm.updateList || gtFilterService.updateQueryParams;
    vm.config = {};
    vm.disableVote = true;
    vm.reCreateApprovals = reCreateApprovals;
    vm.showHistory = false;
    vm.toggleShowHistory = toggleShowHistory;
    vm.showRow = showRow;
    vm.actualApproval = undefined;
    vm.actualMainApproval = undefined;

    activate();

    ////////////////

    vm.$onChanges = function (changes: any) {
      if (changes.approvalStatus) {
        vm.disableVote = vm.approvalStatus == 'approved' || vm.approvalStatus == 'rejected';
      }
    };

    function activate() {
      $scope.$on('update-approvals', updateData);
      updateData();
    }

    function updateData() {
      if (vm.configId) {
        return $http
          .get('/api/accounts/approval-config/details/', {
            params: {
              config_id: vm.configId,
              object_id: vm.objectId,
              content_type: vm.contentType,
            },
          })
          .then(function (responce: any) {
            vm.config = responce.data.data;
            vm.actualApproval = AccountsService.getActualApproval(responce.data.data);
            vm.actualMainApproval = AccountsService.getActualMainApproval(responce.data.data);
          });
      }
    }

    function toggleShowHistory() {
      vm.showHistory = !vm.showHistory;
    }

    function showRow(approver: any) {
      return !(!vm.showHistory && approver.is_archived);
    }

    function openApprovalsModal() {
      return AccountsService.approvalsModal(vm.objectId, vm.contentType).then(function () {
        updateData();
        vm.updateList();
      });
    }

    function reCreateApprovals() {
      return AccountsService.reCreateApprovals(vm.objectId, vm.contentType).then(
        function () {
          updateData();
          vm.updateList();
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }
  }
})();
