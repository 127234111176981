import React from 'react';

import { CheckboxRead } from '~/shared/ui/data-types/components/checkbox-read';

import type { FilterTypeProps } from '../types';

export const CheckboxFilter: React.FC<FilterTypeProps> = ({ filterValue, onChange }) => {
  return (
    <CheckboxRead
      value={filterValue.values[0] === 'true'}
      valueChanged={(input) => onChange({ ...filterValue, values: [input ? 'true' : 'false'] })}
    />
  );
};
