import React from 'react';

import { DragIcon } from '~/shared/ui/icons';

import { SortableItemContext } from './sortable-item-context';

export const DragHandle = () => {
  const { attributes, listeners, ref } = React.useContext(SortableItemContext);

  return (
    <button
      className="rounded-xs outline-none transition duration-300 ease-in-out hover:bg-background-main-tertiary"
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <DragIcon size={14} className="text-stroke-main-stronger" />
    </button>
  );
};
