import type { GtRootScopeService } from '~/app/core/types';

import template from './client-details-item-view.html?raw';

export const clientDetailsItemView = {
  bindings: {
    client: '<',
    fields: '<',
    tab: '<',
    tabVisibility: '<',
    updateContractsData: '&?',
    hasRoles: '&?',
    supplierId: '<?',
    buyerId: '<?',
    filterLogisticVolumes: '&?',
    openClientModal: '&?',
    openClientModalQuick: '&?',
    createConfirmationTask: '&?',
    openEmailModal: '&?',
    openDocxModal: '&?',
    updateData: '&?',
    changeTab: '&?',
    contractsConsolidated: '<?',
    contractsByCrops: '<?',
    contractVolumesChartConfigs: '<?',
    hideWizard: '<?',
    isCheckList: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      buyerId: any;
      client: any;
      fields: any;
      showDetail: any;
      supplierId: any;
      tab: any;
      tabVisibility: any;
      constructor($rootScope: GtRootScopeService) {
        this.client = {};
        this.tab = '';
        this.tabVisibility = {};
        this.supplierId = undefined;
        this.buyerId = undefined;
        this.fields = [];

        if ($rootScope.user.profile.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
      }

      tabExpanded(currentTab: any) {
        return this.tab.includes(currentTab);
      }
    },
  ],
};
