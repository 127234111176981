import type { IconComponent } from '~/shared/ui/icons';

export type ActionBarConfig = {
  label?: {
    text: string;
    context?: string;
  };
  key: string;
  view: ActionView;
  Icon?: IconComponent;
  iconContext?: string;
  action?: () => void;
};

export enum ActionView {
  Button = 'button',
  Text = 'text',
}
