import ng from 'angular';

(function () {
  'use strict';
  ng.module('reports.legacy').directive('teamReports', directive);

  function directive() {
    return {
      bindToController: true,
      controller: 'RreportsTeamController',
      template: require('./team.tpl.html?raw'),
      controllerAs: 'vm',
      scope: {},
    };
  }
})();
