import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './purchase-plan-table.html?raw';

export const PurchasePlanTable = {
  bindings: {
    tableData: '<',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'PurchasePlanService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      PurchasePlanService: any;
      filterLevel = 'purchase-plan-container';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      showChildCrops: any;
      tableData: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        PurchasePlanService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.PurchasePlanService = PurchasePlanService;
        this.queryParams = {};

        this.tableData = {};

        this.showChildCrops = false;
      }

      $onInit() {
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        this.tableData = this.PurchasePlanService.getPurchasePlanTableData(this.queryParams).then(
          (data: any) => {
            this.tableData = data;
          },
        );
      }
    },
  ],
};
