import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').directive('paymentRow', directive);

  function directive() {
    return {
      template: require('./payment-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        filterLevel: '<?',
        payment: '<',
        onUpdate: '&?',
        updateInvoiceCurrencyExchange: '&?',
        onHover: '&?',
        mode: '<?',
        edition: '<?',
        bankAccountQueryParams: '<?',
        simplePayments: '<?',
        invoiceCurrency: '<?',
        invoiceApprovalStatus: '<?',
      },
    };
  }

  Controller.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    'gtFilterService',
    'GtUtils',
    'FinancesService',
    'gettext',
    'DocumentsService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    $filter: ng.IFilterService,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    gettext: ng.gettext.gettextFunction,
    DocumentsService: any,
  ) {
    const vm = this;

    vm.save = save;
    vm.destroy = destroy;
    vm.onPercentageChange = onPercentageChange;
    vm.onCommissionPercentageChange = onCommissionPercentageChange;
    vm.openDocxModal = openDocxModal;
    vm.updateCurrencyExchangeByPaymantDate = updateCurrencyExchangeByPaymantDate;
    vm.getPaymentClasses = getPaymentClasses;

    ////////////////
    vm.$onInit = function () {
      vm.queryParams = gtFilterService.getQueryParams(vm.filterLevel);
      vm.percentage = getPercentage();
      vm.commissionPercentage = getCommissionPercentage();
      vm.onAmountChange = onAmountChange;
      vm.edit = !vm.payment.id;
      vm.mode = vm.mode || 'finance';
      if (vm.payment.finance_invoice_type == 'incoming') {
        vm.payment.client_role = vm.payment.clientrole_to;
      } else {
        vm.payment.client_role = vm.payment.clientrole_from;
      }
      $scope.$watchGroup(
        ['vm.payment.amount_alternative', 'vm.payment.exchange_rate_alternative'],
        onAmountChange,
      );
    };

    function updateData() {
      return FinancesService.Payment.get({ id: vm.payment.id }, (data: any) => {
        vm.payment = data;
        vm.edit = false;
      }).$promise;
    }

    function save() {
      vm.errors = null;
      if (vm.payment.amount === undefined) {
        GtUtils.notify(gettext('Bad payment amount'), 'error');
        return;
      }
      if (vm.payment.id) {
        return FinancesService.Payment.update(vm.payment, updateData, (e: any) =>
          GtUtils.errorClb(e),
        ).$promise.then(() => saveCurrencyExchangeToInvoice());
      }
      if (
        !$rootScope.user.settings.ALLOW_PAYMENT_PLAN_UNAPPROVED_FOR_INVOICES &&
        vm.invoiceApprovalStatus != 'approved'
      ) {
        GtUtils.notify(gettext('Unable to create payment for unapproved invoice'), 'error');
        return;
      }

      return FinancesService.Payment.save(
        vm.payment,
        () => vm.onUpdate(),
        (e: any) => GtUtils.errorClb(e),
      ).$promise.then(() => saveCurrencyExchangeToInvoice());
    }

    function saveCurrencyExchangeToInvoice() {
      if ($rootScope.user.settings.SUBSTITUTE_CURRENCY_EXCHANGE && vm.payment.currency_exchange) {
        vm.updateInvoiceCurrencyExchange({ data: vm.payment.currency_exchange });
      }
    }

    function updateCurrencyExchangeByPaymantDate(date: any) {
      FinancesService.CurrencyExchange.query({
        with_invoice: vm.payment.finance,
        exchange_date: $filter('date')(date, 'dd.MM.yyyy'),
      }).$promise.then((data: any) => {
        vm.payment.currency_exchange = data.results.length ? data.results.reverse()[0].id : null;
      });
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete this?');
      if (!confirm(msg)) {
        return;
      }
      return FinancesService.Payment.delete(
        { id: vm.payment.id },
        () => vm.onUpdate(),
        (e: any) => GtUtils.errorClb(e),
      ).$promise;
    }

    function getPercentage() {
      const percentage = (vm.payment.amount || 0) / (vm.payment.finance_amount_USD || 1);
      return Math.round(percentage * 100 * 1000) / 1000;
    }

    function getCommissionPercentage() {
      const commision = (vm.payment.commission || 0) / (vm.payment.amount || 1);
      return Math.round(commision * 100 * 1000) / 1000;
    }

    function onPercentageChange(calcPercent: any) {
      if (calcPercent) {
        vm.percentage = getPercentage();
      } else {
        const amount = vm.payment.finance_amount_USD * vm.percentage;
        vm.payment.amount = amount / 100;
      }
      onCommissionPercentageChange();
    }

    function onAmountChange() {
      if (vm.payment.exchange_rate_alternative && vm.payment.amount_alternative) {
        vm.payment.amount =
          Math.round(
            (vm.payment.amount_alternative / (vm.payment.exchange_rate_alternative || 1)) * 100,
          ) / 100;
      }
    }

    function onCommissionPercentageChange(calcPercent?: any) {
      if (calcPercent) {
        vm.commissionPercentage = getCommissionPercentage();
      } else {
        const commision = vm.payment.amount * vm.commissionPercentage;
        vm.payment.commission = commision / 100;
      }
    }

    function openDocxModal() {
      return DocumentsService.generateDocxModal('Payment', vm.payment.id);
    }

    function getPaymentClasses() {
      const classesConfigs: any = {
        true: {
          incoming: { label: 'positive-number', td: 'alert-danger', icon: 'fa fa-plus-minus' },
          outgoing: { label: 'negative-number', td: 'alert-success', icon: 'fa fa-plus-minus' },
        },
        false: {
          incoming: { label: 'negative-number', td: 'alert-danger', icon: 'fa fa-minus' },
          outgoing: { label: 'positive-number', td: 'alert-success', icon: 'fa fa-plus' },
        },
      };
      return (
        classesConfigs[
          // @ts-ignore
          vm.payment.is_offset && vm.queryParams.linked_to_invoice === vm.payment.offset_invoice
        ][vm.payment.finance_invoice_type] || { label: '', td: '', icon: '' }
      );
    }
  }
})();
