import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('finances.legacy').directive('financePositionRow', directive);

  function directive() {
    return {
      template: require('./finance-position-row.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        position: '=',
        usesList: '<',
        hovering: '<?',
        count: '<',
        useChangeBlocked: '&?',
        setHovering: '&?',
        checkFieldRequired: '&?',
        getFinanceAccounts: '&?',
        hasFinblockPermission: '&?',
        calculateAmount: '&?',
        clearContractCharge: '&?',
        showContractDetails: '&?',
        showDeleteCtrChargeWarning: '&?',
        showDeletePassportChargeWarning: '&?',
        destroy: '&?',
        clone: '&?',
        updateCounterparties: '&?',
        updateInvoiceFormFields: '&?',
        applyPositionReserve: '&?',
        showReserve: '<',
        invoiceAssignment: '<',
      },
    };
  }

  Controller.$inject = ['$rootScope', 'GtUtils'];

  function Controller(this: any, $rootScope: GtRootScopeService, GtUtils: GtUtilsService) {
    const vm = this;

    vm.hovering = vm.hovering || false;
    vm.count = vm.count || 0;
    vm.useChangeBlocked = vm.useChangeBlocked || function () {};
    vm.setHovering = vm.setHovering || function () {};
    vm.getFinanceAccounts = vm.getFinanceAccounts || function () {};
    vm.hasFinblockPermission = vm.hasFinblockPermission || function () {};
    vm.calculateAmount = vm.calculateAmount || function () {};
    vm.clearContractCharge = vm.clearContractCharge || function () {};
    vm.showContractDetails = vm.showContractDetails || function () {};
    vm.showDeleteCtrChargeWarning = vm.showDeleteCtrChargeWarning || function () {};
    vm.showDeletePassportChargeWarning = vm.showDeletePassportChargeWarning || function () {};
    vm.destroy = vm.destroy || function () {};
    vm.onContractSelect = onContractSelect;
    vm.onPassportSelect = onPassportSelect;
    vm.onUseSelect = onUseSelect;
    vm.checkFieldRequired = checkFieldRequired;
    vm.isCost = isCost;
    vm.changeVat = changeVat;
    vm.copyToClipboard = copyToClipboard;
    vm.onApplyPositionReserve = onApplyPositionReserve;
    vm.showChargeRow = showChargeRow;
    vm.showReserveChargeRow = showReserveChargeRow;

    vm.$onInit = function () {
      if (vm.position.quantity) {
        vm.position.quantity = Number(vm.position.quantity.toFixed(6));
      } else {
        vm.position.quantity = 0;
      }
      vm.calculateAmount({ position: vm.position });
    };

    function onContractSelect(position: any) {
      vm.clearContractCharge(position);
      vm.getFinanceAccounts(position);
      vm.updateCounterparties();
      vm.showContractDetails(position);
    }

    function onApplyPositionReserve(position: any) {
      vm.applyPositionReserve(position);
    }

    function onPassportSelect(position: any) {
      vm.clearContractCharge(position);
    }

    function showChargeRow(position: any) {
      if (vm.invoiceAssignment === 'reserve') {
        return false;
      }
      return (
        !position.contractcharge &&
        (position.contract || position.logistic || position.passport) &&
        (vm.checkFieldRequired({ position: vm.position, field: 'charge' }) ||
          ['costs', 'gains'].includes(vm.position.use))
      );
    }

    function showReserveChargeRow(position: any) {
      if (vm.invoiceAssignment !== 'reserve') {
        return false;
      }
      return ['costs', 'gains'].includes(position.use);
    }

    function isCost() {
      return (
        $rootScope.user.settings.PASSPORT_COSTS_VALIDATE &&
        ['gains', 'costs'].includes(vm.position.use)
      );
    }

    function changeVat() {
      vm.VatIsIncluded = true;
      vm.position.price_per_piece =
        Math.round(
          (vm.position.price_per_piece +
            (vm.position.price_per_piece * vm.position.vat_value) / 100) *
            10000,
        ) / 10000;
      vm.calculateAmount();
    }

    function copyToClipboard(value: any) {
      GtUtils.copyToClipboard(value);
    }

    function onUseSelect(position: any) {
      vm.getFinanceAccounts(position);
      vm.updateCounterparties();

      if (vm.position.use === 'cargo') {
        vm.updateCounterparties();
      }
    }

    function checkFieldRequired({ position, field }: any) {
      return (
        (field === 'expenses_option' && position.use === 'expenses' && !position.expenses_option) ||
        (field === 'crop' && ['cargo', 'washout'].includes(position.use) && !position.crop) ||
        (field === 'other_activity' && position.use === 'other' && !position.other_activity) ||
        (field === 'charge' && ['costs', 'gains'].includes(position.use) && !position._charge_id) ||
        (field === 'passport' &&
          ['gains', 'cargo'].includes(position.use) &&
          !position.passport &&
          !position.contract) ||
        (field === 'contract' &&
          ['gains', 'cargo'].includes(position.use) &&
          !position.contract &&
          !position.passport) ||
        (field === 'contract_charge' && position.use === 'gains' && !position.contractcharge)
      );
    }
  }
})();
