import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './passport-connection-shipment-plan-modal.html?raw';
import type { PassportConnectionShipmentPlanFormFieldsService } from '../passport-connection-shipment-plan-form-field.service';
import type { PassportConnectionShipmentPlanService } from '../passport-connection-shipment-plan.service';

export const PassportConnectionShipmentPlanModal = {
  bindings: {
    modalInstance: '<',
    plan: '<?',
    extra: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$state',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'PassportConnectionShipmentPlanService',
    'PassportConnectionShipmentPlanFormFieldsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      GtUtils: GtUtilsService;
      gettext: ng.gettext.gettextFunction;
      FormFieldParamsService: FormFieldParamsService;
      PassportConnectionShipmentPlanFormFieldsService: PassportConnectionShipmentPlanFormFieldsService;
      PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService;
      extra: any;
      fields: any;
      form: any;
      modalInstance: any;
      plan: any;
      tab: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        PassportConnectionShipmentPlanService: PassportConnectionShipmentPlanService,
        PassportConnectionShipmentPlanFormFieldsService: PassportConnectionShipmentPlanFormFieldsService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.PassportConnectionShipmentPlanService = PassportConnectionShipmentPlanService;
        this.PassportConnectionShipmentPlanFormFieldsService =
          PassportConnectionShipmentPlanFormFieldsService;

        this.tab = 'edit';
        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.plan = this.plan || this.initNewPlan();
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';
        if (this.plan.id) {
          this.updateData();
        } else {
          this.updateFields();
        }
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.PassportConnectionShipmentPlanService.getModalData({ id: this.plan.id })
          .then((data: any) => {
            this.plan = data;
            this.updateFields();
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            this.GtUtils.notify(this.gettext("Plan doesn't exist"));
            this.$state.go('logistics.planningPassportConnectionShipmentPlan');
          });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      updateContracts() {
        if (this.plan.stage === 'cargo') {
          if (this.plan.purchase_contract) {
            this.plan.purchase_contracts = [this.plan.purchase_contract];
          }
          if (this.plan.sale_contract) {
            this.plan.sale_contracts = [this.plan.sale_contract];
          }
        }

        this.plan.contracts = [
          ...(this.plan?.sale_contracts || []),
          ...(this.plan?.purchase_contracts || []),
        ];
      }

      destroy() {
        if (!confirm(this.gettext("Are you sure that you want delete 'Plan'?"))) {
          return;
        }
        return this.PassportConnectionShipmentPlanService.delete(this.plan.id).then(() => {
          this.GtUtils.notify(this.gettext('Plan removed'));
          this.close('delete', true);
        });
      }

      save() {
        return this.PassportConnectionShipmentPlanService.save(this.plan).then(
          (data: any) => {
            this.GtUtils.overlay('hide');
            this.close(data, true);
          },
          (e: any) => this.GtUtils.errorClb(e),
        );
      }

      initNewPlan() {
        return {
          normal_write_off_coeff: 0.003,
          ...this.plan,
        };
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        return this.PassportConnectionShipmentPlanFormFieldsService.getFields(this.plan);
      }
    },
  ],
};
