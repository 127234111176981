import React from 'react';

import { ArrowBottomIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';

import type { ReorderedColumn, View, ViewConfigChanged, ViewCreated } from './types';
import { AvailableViews } from '../available-views';
import { CreateView } from '../create-view';

export const ViewConfigurator: React.FC<{
  isOpen: boolean;
  currentView: View;
  availableViews: View[];
  columns: ReorderedColumn[];
  viewConfigChanged: ViewConfigChanged;
  onOpenChanged: (open: boolean) => void;
  currentViewChanged: (id: number) => void;
  viewDeleted: (id: number) => Promise<void>;
  viewCreated: ViewCreated;
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({
  columnVisibilityChanged,
  isOpen,
  onOpenChanged,
  columns,
  currentView,
  availableViews,
  viewConfigChanged,
  currentViewChanged,
  viewDeleted,
  viewCreated,
  columnsReordered,
}) => {
  const [searchQuery, setSearchQuery] = React.useState<string>('');

  const filteredViews = availableViews.filter((view) =>
    view.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <DropdownMenu open={isOpen} onOpenChange={(open) => onOpenChanged(open)}>
      <DropdownMenuTrigger className="flex items-center gap-1 text-xs outline-none">
        <p className="text-text-main-secondary">{currentView.name}</p>
        <ArrowBottomIcon size={14} className="text-stroke-main-strong" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[200px]">
        <Input
          placeholder="Search"
          intent="transparent"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <DropdownMenuSeparator className="h-[1px] bg-stroke-main-light" />
        <AvailableViews
          columns={columns}
          currentViewId={currentView.id}
          views={filteredViews}
          viewConfigChanged={viewConfigChanged}
          currentViewChanged={currentViewChanged}
          viewDeleted={viewDeleted}
          columnsReordered={columnsReordered}
          columnVisibilityChanged={columnVisibilityChanged}
        />
        <DropdownMenuSeparator className="h-[1px] bg-stroke-main-light" />
        <CreateView viewCreated={viewCreated} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
