import ng from 'angular';

(function () {
  'use strict';
  ng.module('accounts.legacy').directive('accountsActivityTable', directive);

  function directive() {
    return {
      template: require('./activity-table.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        logEntries: '=',
        total: '=',
        noOrdering: '=?',
        update: '=?',
      },
    };
  }

  Controller.$inject = ['gettext'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction) {
    const vm = this;

    vm.theadThConfig = _prepareTheadThConfig();

    ////////////////

    function _prepareTheadThConfig() {
      return [
        {
          title: gettext('Date'),
        },
        {
          title: gettext('User'),
        },
        {
          title: gettext('Action'),
        },
        {
          title: gettext('Event'),
        },
        {
          title: gettext('Details'),
        },
      ];
    }
  }
})();
