import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './warehouses-container.html?raw';

export const WarehousesContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    readonly: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'WarehousesService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      WarehousesService: any;
      count: number;
      fields: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      readonly: any;
      warehouses: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        WarehousesService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.WarehousesService = WarehousesService;
        this.fields = [];
        this.warehouses = [];
        this.count = 0;
        this.filterLevel = '';
        this.readonly = false;
        this.initQueryParams = {};
      }
      $onInit() {
        this.queryParams = { page_size: 20, ...this.initQueryParams };
        this.filterLevel = this.filterLevel || 'warehouses-container';
        this.readonly = this.readonly || false;
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: any) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(
          { ...this.initQueryParams, ...params },
          this.filterLevel,
        );
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.WarehousesService.getWarehousesList(this.queryParams)
          .then((data: any) => {
            this.warehouses = data.results;
            this.count = data.count;
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }
      openModal(item: any) {
        return this.WarehousesService.warehouseModal(item || { ...this.queryParams }).then(() =>
          this.updateData(),
        );
      }
    },
  ],
};
