import { singleton } from '~/shared/lib/di';
import { lsRead, lsWrite } from '~/shared/lib/utils';
import { IconName, useIcon } from '~/shared/ui/icons';

import type {
  CreateUserViewDTO,
  GetUserViewsParams,
  UpdateUserViewDTO,
  UserView,
  UserViewParams,
} from '../lib';

@singleton()
export class UserViewRepository {
  getUserView(params: UserViewParams): Promise<{ id: number }> {
    return Promise.resolve({ id: params.viewId });
  }

  getUserViews(params: GetUserViewsParams) {
    console.info('@dataSetId', params.dataSetId);

    const predefinedData = [
      {
        id: 1,
        name: 'Table View',
        type: 'table',
        icon: 'GridIcon',
        defaultIcon: 'GridIcon',
        data: {
          isLimitAccess: false,
          isShowTitleIcons: false,
          type: {
            selected: 'compact',
            options: ['compact', 'detailed'],
          },
          properties: {
            customFields: [],
          },
        },
      },
    ];

    if (!lsRead('userViews', []).length) {
      lsWrite('userViews', predefinedData);
    }

    const res: UserView[] = lsRead('userViews', []);

    return Promise.resolve(UserViewRepository.mapReceivedUserViews(res));
  }

  updateUserView(params: UserViewParams, dto: Partial<UpdateUserViewDTO>) {
    const views = lsRead('userViews', []);
    const existedViews = UserViewRepository.mapReceivedUserViews(views);
    const updatedViews = existedViews.map((view: UpdateUserViewDTO) =>
      view.id === params.viewId
        ? UserViewRepository.mapUpdatedUserView(view, dto)
        : UserViewRepository.mapUserView(view),
    );

    lsWrite('userViews', updatedViews);

    return Promise.resolve({ id: params.viewId, dto });
  }

  deleteUserView(params: UserViewParams) {
    const views = lsRead('userViews', []);
    const updatedViews = views.filter((view: UserView) => view.id !== params.viewId);

    if (!updatedViews.length) {
      throw new Error('You cannot delete the last view');
    } else {
      lsWrite('userViews', updatedViews);
      return Promise.resolve(updatedViews);
    }
  }

  // params: CreateUserViewParams,
  createUserView(dto: CreateUserViewDTO) {
    const views = lsRead('userViews', []);
    const updatedViews = [...views, UserViewRepository.mapUserView(dto)];

    lsWrite('userViews', updatedViews);

    return Promise.resolve(UserViewRepository.mapUserView(dto));
  }

  static mapReceivedUserViews(views: UserView[]) {
    return views.map((view) => ({
      id: view.id,
      name: view.name,
      type: view.type,
      Icon: useIcon(view.icon as IconName),
      DefaultIcon: useIcon(view.defaultIcon as IconName),
      data: view.data,
    }));
  }

  static mapUserView(view: UpdateUserViewDTO | CreateUserViewDTO) {
    return {
      id: 'id' in view ? view.id : Number(new Date()),
      name: view.name,
      type: view.type,
      icon: view.Icon.displayName,
      defaultIcon: view.DefaultIcon.displayName,
      data: view.data,
    };
  }

  static mapUpdatedUserView(view: UpdateUserViewDTO, changedField: Partial<UpdateUserViewDTO>) {
    if (changedField.data) {
      return {
        id: view.id,
        name: view.name,
        type: view.type,
        icon: (changedField.Icon ?? view.Icon).displayName,
        defaultIcon: view.DefaultIcon.displayName,
        data: { ...view.data, ...changedField.data },
      };
    }

    return {
      id: view.id,
      name: view.name,
      type: view.type,
      icon: (changedField.Icon ?? view.Icon).displayName,
      defaultIcon: view.DefaultIcon.displayName,
      data: { ...view.data },
      ...changedField,
    };
  }
}
