import ng from 'angular';

import { PassportPnlPage } from './passport-pnl-page/passport-pnl-page.component';
import { PassportPnlTable } from './passport-pnl-table/passport-pnl-table.component';
import { PassportPnlService } from './passport-pnl.service';

export const passportPnl = ng
  .module('reports.passportPnl', [])
  .service('PassportPnlService', PassportPnlService)
  .component('passportPnlTable', PassportPnlTable)
  .component('passportPnlPage', PassportPnlPage).name;
