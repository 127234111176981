import ng from 'angular';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('contractTerminalCertsTable', {
    bindings: {
      filterLevel: '<',
      certs: '<',
      count: '<',
      onDelete: '&',
      onSave: '&',
    },
    template: require('./contract-terminal-certs-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext'];

  function Controller(this: any, $scope: ng.IScope, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.theadConfig = getTheadConfig();
    vm.saveCert = saveCert;
    vm.deleteCert = deleteCert;

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function saveCert(cert: any) {
      vm.onSave({ cert: cert });
    }

    function deleteCert(cert: any) {
      vm.onDelete({ cert: cert });
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('Terminal'),
          columnName: 'terminal',
        },
        {
          title: gettext('Railway required'),
          columnName: 'railway',
        },
        {
          title: gettext('VET required'),
          columnName: 'vet',
        },
        {
          title: gettext('Quality required'),
          columnName: 'quality',
        },
        {
          title: gettext('Declaration required'),
          columnName: 'declaration',
        },
        {
          title: gettext('EUR 1 required'),
          columnName: 'eur_1',
        },
        {
          title: gettext('Last update'),
          columnName: 'last_update',
          predicate: 'update_time',
          class: 'update_time',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
        },
        {
          title: gettext('Author'),
          columnName: 'author',
          predicate: 'author',
          filters: [
            {
              type: 'ui-select',
              predicate: 'author',
              label: gettext('autor'),
              resource: 'auth.User',
            },
          ],
        },
        {
          title: gettext(''),
          columnName: 'save',
        },
      ];

      return config;
    }
  }
})();
