import ng from 'angular';

import { AccountActivityListTableView } from './components/account-activity-list-table-view/account-activity-list-table-view.component';
import { analytics } from './components/analytics/analytics.module';
import { BankAccountRestsModal } from './components/bank-account-rests-modal/bank-account-rests-modal.component';
import { BankAccountsRow } from './components/bank-accounts-table/bank-accounts-row/bank-accounts-row.component';
import { BankAccountsTable } from './components/bank-accounts-table/bank-accounts-table.component';
import { bankTransactionModule } from './components/bank-transaction/bank-transaction.module';
import { budgets } from './components/budgets/budgets.module';
import { dblInvoicePosition } from './components/dbl-invoice-postion/dbl-invoice-position.module';
import { invoice } from './components/invoice/invoice.module';
import { invoicePosition } from './components/invoice-position/invoice-position.module';
import { invoicePositionConfig } from './components/invoice-position-config/invoice-position-config.module';
import { loan } from './components/loan/loan.module';
import { offset } from './components/offset/offset.module';
import { PaymentInfoModal } from './components/payment-info/bank-account/payment-info-modal.component';
import { reassignment } from './components/reassignment/reassignment.module';
import { financesLegacyModule } from './legacy';
import { shared } from './shared/shared.module';
import { stockExchange } from './stock-exchange/stock-exchnage.module';

export const financesModule = ng
  .module('finances', [
    financesLegacyModule,
    budgets,
    analytics,
    reassignment,
    offset,
    loan,
    invoicePosition,
    invoicePositionConfig,
    invoice,
    shared,
    stockExchange,
    bankTransactionModule,
    dblInvoicePosition,
  ])
  .component('bankAccountRestsModal', BankAccountRestsModal)
  .component('bankAccountsTable', BankAccountsTable)
  .component('bankAccountsRow', BankAccountsRow)
  .component('paymentInfoModal', PaymentInfoModal)
  .component('accountActivityListTableView', AccountActivityListTableView).name;
