import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './multicontract-page.html?raw';

export const MulticontractPageContainer = {
  template,
  bindings: {
    filterLevel: '<?',
    isTicketPage: '<?',
  },
  controller: [
    '$stateParams',
    'PageService',
    class {
      PageService: PageService;
      filterLevel: string;
      multicontract: any;
      constructor($stateParams: ng.ui.IStateParamsService, PageService: PageService) {
        this.PageService = PageService;
        this.multicontract = { id: $stateParams.id };
        this.filterLevel = 'multicontract-page';
      }

      $onInit() {
        this.PageService.setConfig({ is_detail_page: true });
      }
    },
  ],
};
