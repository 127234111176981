import ng from 'angular';

import { AttachmentsEditView } from './components/attachments-edit-view/attachments-edit-view.component';
import { ClientMailingModal } from './components/client-mailing-modal/client-mailing-modal.component';
import { ClientMailingsTable } from './components/client-mailing-table/client-mailings-table.component';
import { EmailsEditView } from './components/emails-edit-view/emails-edit-view.component';
import { MailingFinancesPageView } from './mailing-finances-page-view/mailing-finances-page-view.component';
import { MailingFinancesTable } from './mailing-finances-table/mailing-finances-table.component';
import { MailingLogisticsPageView } from './mailing-logistics-page-view/mailing-logistics-page-view.component';
import { MailingLogisticsTable } from './mailing-logistics-table/mailing-logistics-table.component';
import { MailingService } from './mailing.service';

const mailingConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('mailing', {
        parent: 'crm',
        url: '/mailing',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('mailing.prices', {
        url: '/prices',
        component: 'newslettersPageView', //FIX: MGRAIN-2439 refactor component
        data: {
          pageTitle: gettext('NewsLetters'),
        },
      })
      .state('mailing.templates', {
        url: '/templates',
        component: 'emailtemplatesPageView', //FIX: MGRAIN-2439 refactor component
        data: {
          pageTitle: gettext('Email Templates'),
        },
      })
      .state('mailing.logistics', {
        url: '/logistics',
        component: 'mailingLogisticsPageView',
        data: {
          pageTitle: gettext('Mailing Logistics'),
        },
      })
      .state('mailing.invoices', {
        url: '/invoices',
        component: 'mailingFinancesPageView',
        data: {
          pageTitle: gettext('Mailing Invoices'),
        },
      });
  },
];

export const mailing = ng
  .module('crm.mailing', [])
  .service('MailingService', MailingService)

  .component('clientMailingsTable', ClientMailingsTable)
  .component('clientMailingModal', ClientMailingModal)
  .component('emailsEditView', EmailsEditView)
  .component('attachmentsEditView', AttachmentsEditView)
  .component('mailingLogisticsTable', MailingLogisticsTable)
  .component('mailingLogisticsPageView', MailingLogisticsPageView)
  .component('mailingFinancesTable', MailingFinancesTable)
  .component('mailingFinancesPageView', MailingFinancesPageView)
  .config(mailingConfig).name;
