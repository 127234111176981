import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './approvals-report-table.html?raw';

export const ApprovalsReportTable = {
  bindings: {
    filterLevel: '<',
    data: '<',
    applyFilters: '&',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gettext',
    'gtFilterService',
    'CoreService',
    class {
      $rootScope: GtRootScopeService;
      CoreService: CoreService;
      applyFilters: any;
      data: any;
      filterLevel = 'approvals-report-table';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      tableData: any;
      tableOptions: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.queryParams = {};
        this.tableData = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'approvals-report-table';
        this.tableOptions = this.getTableOptions();
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      $onChanges(changes: any) {
        if (changes.data) {
          this.tableData = {
            rows: this.data.results,
            count: this.data.count,
            total: this.data.totals,
          };
        }
      }

      applyCurrentUserFilter() {
        if (this.queryParams.next_approver_list) {
          this.queryParams.next_approver_list = undefined;
        } else {
          this.queryParams.next_approver_list = [this.$rootScope.user.id];
        }
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      getTableOptions() {
        const options: any = {
          tableName: 'approvals-report-table',
          tableClass: 'request-table',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          templateArgs: {
            applyFilters: () => this.applyFilters(),
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
        };

        options.columnDefs = [
          {
            columnName: 'date',
            title: this.gettext('date'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            {[{ item.first_approval_create_time || '---' | date:'dd/MM/yy HH:mm' }]}
          `,
          },
          {
            columnName: 'object_title',
            title: this.gettext('object title'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.object_type == 'paymentplan'" class="btn-link">
              <i class="fa fa-calendar"></i> {[{ item.title }]} 
            </span>
            <a ng-if="item.object_type == 'invoice'"
            class="btn-link"
              ui-sref="gt.page.payment({id: item.id })"
              target="_blank"
            >
              <i class="fa fa-credit-card-alt"></i> {[{ item.title }]} 
            </a>
            <a ng-if="item.object_type == 'counterparty'"
            class="btn-link"
              ui-sref="gt.page.client({id: item.id })"
              target="_blank"
            >
              <i class="fa fa-building"></i> {[{ item.title }]} 
            </a>
            <a ng-if="item.object_type == 'passport'"
            class="btn-link"
              ui-sref="gt.page.passport({id: item.id })"
              target="_blank"
            >
              <i class="fa fa-exchange"></i> {[{ item.title }]} 
            </a>
            <a
            class="btn-link"
              ng-if="item.object_type == 'salecontract' || item.object_type == 'purchasecontract'"
              target="_blank"
              ui-sref="gt.page.contract({ id: item.id })"
            >
              <i ng-if="item.object_type == 'purchasecontract'" class="fa fa-arrow-down"></i>
              <i ng-if="item.object_type == 'salecontract'" class="fa fa-arrow-up"></i> {[{
              item.title || '---' }]}
            </a>
            <a
            class="btn-link"
              ng-if="
                item.object_type == 'saleticket' ||
                item.object_type == 'purchaseticket' ||
                item.object_type == 'saleindicator' ||
                item.object_type == 'purchaseindicator'
              "
              target="_blank"
              ui-sref="gt.page.request({ id: item.id })"
            >
              <i class="fa fa-file-text-o"></i> {[{ item.title || '---' }]}
            </a>
          `,
          },
          {
            columnName: 'object_type',
            title: this.gettext('object type'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            {[{ item.object_type }]}
          `,
          },
          {
            columnName: 'status',
            title: this.gettext('status'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span class="label" ng-class="{ 'label_default': item.approval_status == 'wait', 
            'label_success': item.approval_status == 'approved', 
            'label_warning': item.approval_status == 'process', 
            'label_danger': item.approval_status == 'rejected'  }">
            {[{ item.approval_status }]}
            </span>
          `,
          },
          {
            columnName: 'config',
            title: this.gettext('config'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <a
              ng-click="item.expandedApprovals = item.expandedApprovals == item.id ? undefined : item.id"
              style="margin-bottom: 5px"
              class="btn-link"
            >
             <i class="fa fa-expand" ng-if="item.expandedApprovals"></i> 
             <i class="fa fa-compress" ng-if="!item.expandedApprovals"></i> 
             {[{ item.approvalconfig_title }]}
            </a>
            <div class="clearfix"></div>
            <div class="well" style="min-width: 700px" ng-if="item.approval_config_id && item.expandedApprovals == item.id">
            <approvals-by-config-container
              approvable-id="item.id"
              approval-config-id="item.approval_config_id"
              can-reactivate="item.approval_status != 'rejected'"
              voted="args.applyFilters()"
              </div>
            ></approvals-by-config-container>
          `,
          },
          {
            columnName: 'last_approver',
            title: this.gettext('last approver'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <div ng-if="!item.last_approval_first_name">---</div>
            <div ng-if="item.last_approval_first_name">
               <!-- item.last_approval_approved
              item.last_approval_declined
              item.last_approval_comment -->
              <div class="user-avatar" ng-if="item.last_approval_avatar">
                <img ng-src="/pictures/{[{item.last_approval_avatar}]}" />
              </div>
              <div class="no-user-avatar" ng-if="!item.last_approval_avatar">
                {[{ item.last_approval_first_name | cut:true:1:' ' }]}
              </div>
              {[{ item.last_approval_first_name }]} {[{ item.last_approval_last_name }]}
             </div>
          `,
          },
          {
            columnName: 'next_approvers',
            title: this.gettext('next approvers'),
            class: 'td-left-align smaller-label',
            cellTemplate: /*html*/ `
            <div ng-if="!item.next_approvals_list">---</div>
            <div class="smaller-label" ng-repeat="approval in item.next_approvals_list">
              <div class="user-avatar" ng-if="approval.avatar">
                <img ng-src="/pictures/{[{approval.avatar}]}" />
              </div>
              <div class="no-user-avatar" ng-if="!approval.avatar">
                {[{ approval.first_name | cut:true:1:' ' }]}
              </div>
              {[{ approval.first_name }]} {[{ approval.last_name }]}
             </div>
          `,
          },
          {
            columnName: 'last_approve_time',
            title: this.gettext('last approve time'),
            predicate: 'last_approval_update_time',
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            {[{ item.last_approval_update_time || '---' | date:'dd/MM/yy HH:mm' }]}
          `,
          },
          {
            columnName: 'current_delay',
            title: this.gettext('current delay, h'),
            predicate: 'current_delay',
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.current_delay" class="label label-info">
            {[{ item.current_delay | number:0 }]} <translate>hours</translate>
            ({[{ item.current_delay / 24 | number:0 }]} <translate>days</translate>)
            </span>
          `,
          },
          {
            columnName: 'total_approve_time',
            title: this.gettext('total approve time, h'),
            predicate: 'total_approve_time',
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.total_approve_time" ng-class="{ 'label label_danger': item.total_approve_time > 48 }">
              {[{ item.total_approve_time | number:0 }]} <translate>hours</translate> ({[{ item.total_approve_time / 24 | number:0 }]} <translate>days</translate>)
            </span>
          `,
          },
        ];

        return options;
      }
    },
  ],
};
