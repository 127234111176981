import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './warehouse-losses-page-view.html?raw';

export const warehouseLossesPageView = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'PageService',
    'GtUtils',
    'LogisticsService',
    'gettext',
    class WarehouseLossesPageView {
      GtUtils: GtUtilsService;
      LogisticsService: any;
      PageService: PageService;
      filterLevel = 'warehouse-losses-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      constructor(
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        LogisticsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'date',
                placeholder: this.gettext('Date'),
              },
              {
                argument: 'create_time',
                placeholder: this.gettext('Create date'),
              },
            ],
            nestedSelects: [],
            multiSelects: [
              {
                argument: 'farm_list',
                placeholder: this.gettext('farms'),
                resource: 'clients.farm',
              },
              {
                argument: 'elevator_list',
                placeholder: this.gettext('elevators'),
                resource: 'clients.elevator',
              },
              {
                argument: 'terminal_list',
                placeholder: this.gettext('terminals'),
                resource: 'logistics.terminal',
              },
              {
                argument: 'purchase_contract_list',
                placeholder: this.gettext('purchase contracts'),
                resource: 'contracts.PurchaseContract',
              },
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
                getQueryParams: this.GtUtils.getBUQueryParams,
              },
            ],
            nestedMultiSelects: [this.gtFilterService.getCropsFilter('cargo_list')],
          },
        };
      }
    },
  ],
};
