export const AdjustHeight: any = [
  '$timeout',
  ($timeout: ng.ITimeoutService) => {
    return {
      restrict: 'A',
      link($scope: ng.IScope, $element: ng.IAugmentedJQuery) {
        function resize() {
          if (!$element[0]?.style) {
            return;
          }

          $timeout(() => {
            $element[0].style.height = 'auto';
            if ($element[0].scrollHeight) {
              $element[0].style.height = $element[0].scrollHeight + 'px';
            }
          }, 0);
        }

        $element.on('input', resize);
        $timeout(resize, 0);
      },
    };
  },
];
