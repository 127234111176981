import ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').controller('PassportsPassportModalController', Controller);

  Controller.$inject = [
    '$q',
    '$uibModalInstance',
    '$window',
    '$state',
    '$rootScope',
    '$scope',
    '$timeout',
    'AccountsService',
    'passport',
    'GtUtils',
    'ContractsService',
    'data',
    'gettext',
    'LogisticsService',
    'FormFieldParamsService',
    'CoreService',
    'FinancesService',
    'gtFilterService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $q: ng.IQService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $window: ng.IWindowService,
    $state: ng.ui.IStateService,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    AccountsService: any,
    passport: any,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    data: any,
    gettext: ng.gettext.gettextFunction,
    LogisticsService: any,
    FormFieldParamsService: FormFieldParamsService,
    CoreService: CoreService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    CustomValuesService: any,
  ) {
    const vm = this;
    vm.passport = passport || {};

    vm.saleContractsFilterLevel = 'sale-facts-passport-modal';
    vm.purchaseContractsFilterLevel = 'purchase-facts-passport-modal';
    vm.saleTicketsFilterLevel = 'sale-plans-passport-modal';
    vm.purchaseTicketsFilterLevel = 'purchase-plans-passport-modal';
    vm.lineupContractsSaleFilterLevel = 'lineup-contracts-table-sale';
    vm.lineupContractsPurchaseFilterLevel = 'lineup-contracts-table-purchase';
    vm.tab = 'info';
    vm.showOrigination = data.showOrigination || false;
    vm.extraData = data || {};
    vm.stayAfterSave = false;
    vm.close = close;
    vm.destroy = destroy;
    vm.save = save;
    vm.updateData = updateData;

    vm.errors = [];
    vm.checkVolume = checkVolume;
    vm.checkRequestVolume = checkRequestVolume;
    vm.updateConnections = updateConnections;
    vm.updating = false;
    vm.savePlans = false;
    vm.saveFacts = false;
    vm.form = undefined;
    vm.formName = 'passport-edit-modal';
    vm.openFieldsConfigModal = openFieldsConfigModal;
    vm.openRequestModal = openRequestModal;
    vm.fields = undefined;
    vm.onSave = data?.onSave || (() => $q.when());

    vm.changeTab = changeTab;
    vm.getFlatFields = getFlatFields;

    vm.defaultCurrency = FinancesService.getDefaultCurrency().id;

    vm.$onInit = function () {
      vm.tab = 'info';
      activate();
      if ($rootScope.user.settings.PASSPORT_CHECK_CROP && vm.passport.cargo) {
        vm.contractFilterCargo = vm.passport.cargo;
      }

      $rootScope.$on('closePassportModal', () => {
        close('details', true);
      });
      $rootScope.$on('custom-values-updated__passport', function (event: any, data: any) {
        vm.customValues = data;
      });

      $scope.$watch('vm.passport.business_unit', function (newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
          clearApprovalConfig();
        }
      });

      gtFilterService.setQueryParams(
        { hide_in_passport_connection: '0' },
        vm.lineupContractsSaleFilterLevel,
      );
      gtFilterService.setQueryParams(
        { hide_in_passport_connection: '0' },
        vm.lineupContractsPurchaseFilterLevel,
      );
    };

    ////////////////

    function changeTab(tabName: any) {
      vm.tab = tabName || vm.tab;
      activate();
    }

    function activate() {
      if (vm.passport.id) {
        updateData();
      }
      updateFields();
    }

    function updateConnections() {
      vm.updating = true;
      $timeout(function () {
        vm.updating = false;
      }, 100);
    }

    function updateData() {
      vm.updating = true;
      const params = {
        id: vm.passport.id,
        serializer: 'modal',
      };
      vm.savePlans = false;
      vm.saveFacts = false;
      return ContractsService.Passport.get(params, function (data: any) {
        vm.passport = data;
        vm.updating = false;
        vm.newRequest.cargo = passport.cargo;
        vm.newRequest.volume = passport.total_volume_plan;
        vm.newRequest.stage = 'ticket';
      }).$promise;
    }

    function openRequestModal() {
      return ContractsService.requestModal({ stage: 'ticket' }).then(updateData);
    }

    function openFieldsConfigModal() {
      FormFieldParamsService.fieldsConfigModal(getFormConfig()).then(updateFields);
    }

    function updateFields() {
      FormFieldParamsService.getFields(getFormConfig())
        .then(function (fields: any) {
          vm.fields = fields;
          vm.fieldsTemplateOptions = getFlatFields(fields);
        })
        .catch(GtUtils.errorClb);
    }

    function getFlatFields(fields: any) {
      return FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map((field: any) => ({
        ...field.templateOptions,
        key: field.key,
      }));
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete passport?');
      if (!confirm(msg)) {
        return;
      }
      ContractsService.Passport.delete({ id: vm.passport.id }, function () {
        GtUtils.notify(gettext('Passport removed'));
        close('destroy', true);
        $state.go('abstract-passports.passports');
      });
    }

    function save(callback: any) {
      GtUtils.overlay('show');

      _clearEmpty();

      if (vm.passport.id) {
        return ContractsService.Passport.update(
          vm.passport,
          function () {
            vm.savePlans = false;
            vm.saveFacts = false;
            vm.tog = null;
            if (!vm.stayAfterSave) {
              callback?.();
              close(vm.passport, true);
            }
            GtUtils.notify(gettext('Passport updated'));

            GtUtils.overlay('hide');
          },
          function (error: any) {
            _error(error);
            GtUtils.overlay('hide');
          },
        ).$promise;
      }

      return ContractsService.Passport.save(
        vm.passport,
        function (data: any) {
          vm.tog = null;
          vm.onSave(data).then(
            () => {
              vm.passport.id = data.id;
              if (!vm.stayAfterSave) {
                callback?.();
                close(vm.passport, true);
              }
              GtUtils.notify(gettext('Passport saved'));
              CustomValuesService.createCustomValues(vm.passport.id, vm.customValues);

              GtUtils.overlay('hide');
            },
            (error: any) => {
              GtUtils.errorClb(error);
              GtUtils.overlay('hide');
            },
          );
        },
        function (error: any) {
          _error(error);
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function _error(data: any) {
      vm.passport.errors = data.data;
      vm.savePlans = false;
      vm.saveFacts = false;
    }

    function _clearEmpty() {
      if (vm.passport.sale_plans) {
        for (let k = 0; k < vm.passport.sale_plans.length; ++k) {
          vm.passport.sale_plans[k].volume = parseFloat(vm.passport.sale_plans[k].volume) || 0;
          if (!vm.passport.sale_plans[k].volume) {
            vm.passport.sale_plans.splice(k, 1);
            // eslint-disable-next-line sonarjs/updated-loop-counter
            k--;
          }
        }
      }

      if (vm.passport.purchase_plans) {
        for (let l = 0; l < vm.passport.purchase_plans.length; ++l) {
          vm.passport.purchase_plans[l].volume =
            parseFloat(vm.passport.purchase_plans[l].volume) || 0;
          if (!vm.passport.purchase_plans[l].volume) {
            vm.passport.purchase_plans.splice(l, 1);
            // eslint-disable-next-line sonarjs/updated-loop-counter
            l--;
          }
        }
      }
    }

    function checkVolume(contract: any, volume: any) {
      let totalConnections = 0,
        availableVolume = 0;
      ng.forEach(contract.connections, function (connection: any) {
        if (connection.passport == vm.passport.id) {
          return;
        }
        totalConnections += connection.volume;
      });
      availableVolume = Number(
        (
          (parseFloat(contract.final_volume) || parseFloat(contract.volume)) - totalConnections
        ).toFixed(6),
      );
      if (
        $rootScope.user.settings.PASSPORT_CHECK_VOLUME &&
        (volume < 0 || availableVolume < volume)
      ) {
        vm.errors.push(contract.id);
        return false;
      }
      const errorIndex = vm.errors.indexOf(contract.id);
      if (errorIndex > -1) {
        vm.errors.splice(errorIndex, 1);
      }
      return true;
    }

    function checkRequestVolume(request: any, volume: any) {
      let totalConnections = 0,
        availableVolume = 0;
      ng.forEach(request.connections, function (connection: any) {
        if (connection.passport == vm.passport.id) {
          return;
        }
        totalConnections += connection.volume;
      });
      availableVolume = Number(
        (
          (parseFloat(request.final_volume) || parseFloat(request.volume)) - totalConnections
        ).toFixed(6),
      );
      if (
        $rootScope.user.settings.PASSPORT_CHECK_VOLUME &&
        (volume < 0 || availableVolume < volume)
      ) {
        vm.errors.push(request.id);
        return false;
      }
      const errorIndex = vm.errors.indexOf(request.id);
      if (errorIndex > -1) {
        vm.errors.splice(errorIndex, 1);
      }
      return true;
    }

    function clearApprovalConfig() {
      vm.passport.approval_config = null;
    }

    function getFormConfig() {
      let isExchangeFieldRequired: any;
      FormFieldParamsService.isRequiredCurrencyExchangeField(
        'passport-edit-modal',
        vm.passport.business_unit,
      ).then((required: any) => (isExchangeFieldRequired = required));
      const col0: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col1: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col2: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };
      const col3: any = {
        className: 'form-group-container col-sm-3 col-xs-12',
        fieldGroup: [],
      };

      col0.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('TITLE'),
        },
        fieldGroup: [
          {
            key: 'title',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Title'),
              placeholder: gettext('Type title of passport'),
              hint: gettext('The name for internal use, can be whatever you want'),
              required: true,
            },
          },
          {
            key: 'multipassport',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Multipassport'),
              placeholder: gettext('Choose Multipassport'),
              hint: gettext('Group passports into a chain'),
              resource: 'passports.Multipassport',
              addFunc: () => {
                $window.open('/admin/passports/multipassport/add/');
              },
              addIcon: 'fa fa-exchange',
              addPerms: ['add_multipassport'],
            },
          },
          {
            key: 'voyage',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Voyage'),
              placeholder: gettext('Choose vessel'),
              hint: gettext('One of the vessel voyages, which will execute this deal'),
              resource: 'logistics.Voyage',
              addFunc: () => {
                return LogisticsService.voyageModal();
              },
              addPerms: ['add_voyage'],
              addIcon: 'fa fa-ship',
            },
          },
          {
            template: /* html */ `
              <div style="margin: 8px 0" ng-if="to.voyage">
                <div>
                  <span class="label label_default" style="margin: 0 30% 10px">
                    <translate>Vessel size: </translate> {[{ to.vesselSize || 'N/A' }]}
                  </span>
                </div>
              </div>
            `,
            hideExpression: () => !vm.passport.voyage,
            expressionProperties: {
              'templateOptions.voyage': () => vm.passport.voyage,
              'templateOptions.vesselSize': () => {
                return LogisticsService.VesselSize.query({
                  voyage: vm.passport.voyage,
                }).$promise.then((data: any) => {
                  return data.results[0]?.title;
                });
              },
            },
          },
          {
            key: 'conclusion_date',
            type: 'gt-date-select',
            defaultValue: new Date(),
            templateOptions: {
              label: gettext('Conclusion (date)'),
              placeholder: gettext('date'),
              required: true,
            },
          },
          {
            key: 'business_date',
            type: 'gt-date-select',
            defaultValue: new Date(),
            templateOptions: {
              label: gettext('Business (date)'),
              placeholder: gettext('date'),
              minView: 'month',
              startView: 'month',
              required: true,
            },
          },
          {
            key: 'cargo',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Product'),
              placeholder: gettext('Choose product'),
              resource: 'crops.Crop',
              required: true,
              help: gettext('To filter contracts for allocation'),
              addFunc: () => {
                $window.open('/admin/crops/crop/add/');
              },
              addIcon: 'fa-wheat-awn',
              addPerms: ['add_crop'],
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'total_volume_plan',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Volume'),
              placeholder: gettext('Type volume'),
              hint: gettext(
                'Total volume of the deal. Choose plan value, at first, but then substitute on delivered volume',
              ),
              help: gettext('Estimated volume of the deal for contract allocation'),
              required: true,
              type: 'number',
              addon: gettext('t'),
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'currency',
            type: 'gt-ui-select',
            defaultValue: vm.defaultCurrency,
            templateOptions: {
              label: gettext('Currency'),
              placeholder: gettext('USD, EUR'),
              resource: 'finances.Currency',
              required: true,
              help: gettext('Pick currency in which you want to convert USD'),
              addFunc: () => {
                $window.open('/admin/finances/currency/add/');
              },
              addIcon: 'fa fa-dollar',
              addPerms: ['add_currency'],
            },
          },
          {
            key: 'currency_exchange',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Currency rate'),
              resource: 'finances.CurrencyExchange',
              hint: gettext(
                'If you want to convert all financial data in passport by one rate to USD',
              ),
              addFunc: () => {
                $window.open('/admin/finances/currencyexchange/add/');
              },
              queryParams: () => {
                return { local_currency: vm.passport.currency };
              },
              addIcon: 'fa fa-money',
              addPerms: ['add_currencyexchange'],
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.currency',
              'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                isExchangeFieldRequired &&
                scope.model.currency !== FinancesService.getDefaultCurrency().id,
            },
          },
          {
            template: /* html */ `
            <custom-values-container
              filter-level="'passport-custom-values-container'"
              init-query-params="{
                object_id: model.id,
                purpose: model.content_type,
                purpose_model: 'passport',
              }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
    `,
          },
        ],
      });
      col1.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('FINANCES'),
          defaultHide: true,
        },
        fieldGroup: [
          {
            key: 'custom_margin',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: gettext('Custom margin'),
              placeholder: gettext('Custom margin'),
              type: 'number',
              hint: gettext('This value will be added to your margin'),
              required: true,
            },
          },
          {
            key: 'discount',
            type: 'gt-input',
            templateOptions: {
              label: gettext('discount'),
              placeholder: gettext('0-100'),
              hint: gettext('The discount of VAT compensation by the deal'),
              addon: gettext('%'),
              type: 'number',
            },
          },
          {
            key: 'sale_price_multiplicator',
            type: 'gt-input',
            defaultValue: 100,
            templateOptions: {
              label: gettext('sale price multiplicator'),
              placeholder: gettext('1-100'),
              hint: gettext('The number on which sale price will be multiplied'),
              addon: gettext('%'),
              type: 'number',
              required: true,
            },
            hideExpression: () => !$rootScope.user.settings.DEFAULT_VALUES.field_sale_multip,
          },
          {
            key: 'premium_value',
            type: 'gt-input',
            defaultValue: 10,
            templateOptions: {
              label: gettext('Premium value'),
              placeholder: gettext('Type value'),
              type: 'number',
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'vat_return_rate',
            type: 'gt-input',
            defaultValue: $rootScope.user.settings.DEFAULT_VALUES.field_passport_vat_return_rate,
            templateOptions: {
              label: gettext('VAT return rate'),
              placeholder: gettext('Type rate'),
              hint: gettext('The rate of return for VAT for cargo'),
              type: 'number',
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'vat_costs_return_rate',
            type: 'gt-input',
            defaultValue: $rootScope.user.settings.DEFAULT_VALUES.field_passport_vat_return_rate,
            templateOptions: {
              label: gettext('VAT costs return rate'),
              placeholder: gettext('Type rate'),
              hint: gettext('The rate of return for VAT for costs'),
              type: 'number',
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'margin_selector',
            type: 'gt-select',
            defaultValue: 'contract',
            templateOptions: {
              label: gettext('margin selector'),
              placeholder: gettext('Choose margin'),
              help: gettext(
                'Depends on a stage of passport execution. Select which margin we will display in PNL',
              ),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: 'ticket', value: 'tiket' },
                { name: 'contract', value: 'contract' },
                { name: 'analytical', value: 'contract_analytical' },
                { name: 'execution', value: 'execution' },
                { name: 'invoice', value: 'invoice' },
                { name: 'payment', value: 'payment' },
              ],
            },
          },
          {
            key: 'derivative_account',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Derivative account'),
              placeholder: gettext('Derivative account'),
              resource: 'stockexchanges.derivativeaccount',
              queryParams: { is_active: 1 },
              help: gettext(
                'Allocate this passport to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report',
              ),
              addFunc: () => {
                $window.open('/admin/stockexchanges/derivativeaccount/add/');
              },
              addPerms: ['add_derivativeaccount'],
              addIcon: 'fa fa-suitcase',
            },
          },
          {
            key: 'unload_costs_t',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Transport costs per t'),
              placeholder: gettext('Type number'),
              hint: gettext('Transport costs per t'),
              type: 'number',
            },
          },
          {
            key: 'unload_costs_unit',
            type: 'gt-input',
            templateOptions: {
              label: gettext('Transport costs per unit'),
              placeholder: gettext('Type number'),
              hint: gettext('Transport costs per unit'),
              type: 'number',
            },
          },
        ],
      });
      col2.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('STATUS'),
          defaultHide: true,
        },
        fieldGroup: [
          {
            key: 'status',
            type: 'gt-select',
            defaultValue: 'Request',
            templateOptions: {
              label: gettext('Status'),
              placeholder: gettext('Choose status'),
              hint: gettext('Will be change automatically, based on passport execution'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: gettext('Request'), value: 'Request' },
                { name: gettext('Approved'), value: 'Approved' },
                {
                  name: gettext('Processing'),
                  value: 'Processing',
                },
                { name: gettext('Cargo partial executed'), value: 'cargo_partial_executed' },
                { name: gettext('Cargo executed'), value: 'cargo_executed' },
                { name: gettext('Executed'), value: 'Executed' },
                { name: gettext('Cancelled'), value: 'Cancelled' },
              ],
            },
          },
          {
            key: 'custom_status',
            type: 'gt-ui-select',
            defaultValueResolve: () => GtUtils.getCustomStatusId('passport'),
            templateOptions: {
              label: gettext('Custom status'),
              placeholder: gettext('Choose custom status'),
              hint: gettext('You can create and apply your own statuses for passport'),
              resource: 'core.CustomStatus',
              addFunc: () => {
                $window.open('/admin/core/customstatus/add/');
              },
              addIcon: 'fa fa-tasks',
              addPerms: true,
              queryParams: {
                content_type__model: 'passport',
              },
            },
          },
          {
            key: 'logistic_type',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('logistic type'),
              placeholder: gettext('Choose type of logistic'),
              resource: 'logistics.logisticType',
              addon: gettext('logistic type'),
              addFunc: () => $window.open('/admin/logistics/logistictype/add/', '_blank'),
              addIcon: 'fa fa-truck-plane',
              addPerms: ['add_logistictype'],
            },
          },
          {
            key: 'origin_of_crop',
            type: 'gt-ui-select',
            defaultValueResolve: () => GtUtils.getDefaultOriginId(),
            templateOptions: {
              label: gettext('Origin'),
              placeholder: gettext('Type origin'),
              resource: 'location.Country',
              addon: gettext('country'),
              addFunc: () => $window.open('/admin/location/country/add/', '_blank'),
              addIcon: 'fa fa-location-arrow',
              addPerms: ['add_country'],
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.voyage',
            },
          },
          {
            key: 'destination_of_crop',
            type: 'gt-ui-select',
            templateOptions: {
              label: gettext('Destination'),
              placeholder: gettext('Type destination'),
              resource: 'location.Country',
              addon: gettext('country'),
              addFunc: () => $window.open('/admin/location/country/add/', '_blank'),
              addIcon: 'fa fa-location-arrow',
              addPerms: ['add_country'],
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.voyage',
            },
          },
          {
            key: 'current_progress',
            type: 'gt-textarea',
            templateOptions: {
              label: gettext('Additional info'),
              placeholder: gettext('Type here any specific information about this deal'),
            },
          },
          {
            key: 'sap_orders',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: gettext('SAP orders'),
              placeholder: gettext('Choose SAP orders'),
              resource: 'contracts.SapOrder',
            },
          },
        ],
      });
      col3.fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: gettext('CONFIG'),
          defaultHide: true,
        },
        fieldGroup: [
          {
            key: 'responsible',
            type: 'gt-ui-select',
            defaultValue: $rootScope.user.id,
            templateOptions: {
              label: gettext('Responsible'),
              placeholder: gettext('User to be responsible'),
              resource: 'auth.User',
            },
          },
          {
            key: 'approval_config',
            type: 'gt-ui-select',
            defaultValueResolve: () =>
              AccountsService.getDefaultApprovalConfigId('passports.Passport'),
            templateOptions: {
              label: gettext('Approval Config'),
              resource: 'accounts.ApprovalConfig',
              hint: gettext(
                'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up',
              ),
              addFunc: () => {
                $window.open('/admin/accounts/approvalconfig/');
              },
              addIcon: 'fa fa-cogs',
              addPerms: true,
              queryParams: () => {
                return {
                  object_type: 'passports.Passport',
                  is_active: 1,
                  bu_list: vm.passport.business_unit,
                };
              },
            },
          },
          {
            key: 'business_unit',
            type: 'gt-ui-select',
            defaultValueResolve: () => CoreService.getDefaultBuId(vm.passport),
            templateOptions: {
              label: gettext('Business unit'),
              placeholder: gettext('Business Unit'),
              queryParams: GtUtils.getBUQueryParams(),
              resource: 'core.BusinessUnit',
              hint: gettext(
                'If you pick something here, information about this object will be displayed only for people from those business units',
              ),
              addFunc: () => {
                $window.open('/admin/core/businessunit/add/');
              },
              addIcon: 'fa fa-home',
              addPerms: true,
            },
          },
          {
            key: 'season',
            type: 'gt-ui-select',
            defaultValueResolve: () => CoreService.getDefaultSeasonId(),
            templateOptions: {
              label: gettext('Season'),
              placeholder: gettext('Season'),
              resource: 'core.Season',
              hint: gettext('For purposes of filtering information by specific season'),
              addFunc: () => {
                $window.open('/admin/core/season/add/');
              },
              addIcon: 'fa fa-calendar',
              addPerms: true,
              queryParams: () => {
                return { is_current: 1 };
              },
            },
          },
          {
            key: 'is_volume_diff_aspiration',
            defaultValue: $rootScope.user.settings.ENABLE_ASPIRATION_AUTOMATICALLY,
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Aspiration'),
              hint: gettext('Check this if you want to use volume position as aspiration'),
            },
          },
          {
            key: 'use_commodity_parent_match',
            type: 'gt-checkbox',
            templateOptions: {
              label: gettext('Use commodity parent match'),
              hint: gettext('Check this if you want to use match with commodity parent'),
            },
          },
        ],
      });

      return {
        formName: 'passport-edit-modal',
        fieldsDef: [col0, col1, col2, col3],
      };
    }
  }
})();
