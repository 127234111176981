import ng from 'angular';

import { ConsolidatedAccountsContainer } from './components/consolidated-accounts-container/consolidated-accounts-container.component';
import { ConsolidatedMonthAccountsContainer } from './components/consolidated-month-accounts-container/consolidated-month-accounts-container.component';
import { StockExchangesService } from './stock-exchanges.service';

export const stockExchanges = ng
  .module('stockExchange.stockExchanges', [])
  .component('consolidatedAccountsContainer', ConsolidatedAccountsContainer)
  .component('consolidatedMonthAccountsContainer', ConsolidatedMonthAccountsContainer)
  .service('StockExchangesService', StockExchangesService).name;
