import ng from 'angular';

import { CostsStatisticsContainer } from './costs-statistics-container/costs-statistics-container.component';
import { CostsStatisticsPage } from './costs-statistics-page/costs-statistics-page.component';
import { CostsStatisticsTable } from './costs-statistics-table/costs-statistics-table.component';
import { CostsStatisticsReportService } from './costs-statistics.service';

export const costsStatistics = ng
  .module('reports.costsStatistics', [])
  .service('CostsStatisticsReportService', CostsStatisticsReportService)
  .component('costsStatisticsPage', CostsStatisticsPage)
  .component('costsStatisticsContainer', CostsStatisticsContainer)
  .component('costsStatisticsTable', CostsStatisticsTable).name;
