export function gtCurrencyFilter($filter: ng.IFilterService) {
  return (value: any, currency: any) => {
    if (value === undefined) {
      return value;
    }
    if (!currency || currency == 'USD') {
      return $filter('currency')(value);
    }
    return `${$filter('currency')(value, '')} ${$filter<(str: string) => string>('translate')(currency)}`;
  };
}
gtCurrencyFilter.$inject = ['$filter'];
