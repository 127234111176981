import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';
export class StockReserveUnitsService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  StockReserveUnitDetailResource: any;
  StockReserveUnitsListResource: any;
  constructor($resource: ng.resource.IResourceService, $uibModal: ng.ui.bootstrap.IModalService) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.StockReserveUnitDetailResource = $resource(
      '/api/warehouses/stock-reserve-units/:id/',
      {
        id: '@id',
      },
      {
        save: { method: 'POST' },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        predictions: {
          method: 'GET',
          url: '/api/warehouses/stock-reserve-units/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/warehouses/stock-reserve-units/export_column_names/',
        },
      },
    );
    this.StockReserveUnitsListResource = $resource(
      '/api/warehouses/stock-reserve-units-list/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
      },
    );
  }
  get(id: any) {
    return this.StockReserveUnitDetailResource.get({ id: id }).$promise;
  }
  save(item: any) {
    if (item.id) {
      return this.StockReserveUnitDetailResource.update(item).$promise;
    }
    return this.StockReserveUnitDetailResource.save(item).$promise;
  }
  delete(item: any) {
    return this.StockReserveUnitDetailResource.delete({ id: item.id }).$promise;
  }
  getStockReserveUnitList(params: any) {
    return this.StockReserveUnitsListResource.query({
      serializer: 'depth',
      ...params,
    }).$promise;
  }
  openStockReserveUnitModal(item: any, extra: any) {
    let _item = {};
    if (item) {
      _item = { ...item };
    }
    return this.$uibModal.open({
      backdrop: 'static',
      template: /* html */ `
        <stock-reserve-unit-modal
          item="item"
          modal-instance="$uibModalInstance"
        ></stock-reserve-unit-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).item = _item;
          ($scope as any).extra = extra;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }
  clone(item: any, extra: any) {
    return this.openStockReserveUnitModal({ ...item, pk: undefined }, extra);
  }
}
StockReserveUnitsService.$inject = ['$resource', '$uibModal'];
