import React from 'react';

import { Outlet } from '~/shared/lib/router';

export const TraderDetailsLayout: React.FC<{ traderId: string }> = ({ traderId }) => {
  return (
    <>
      <aside className="w-1/4">
        <div className="bg-white p-4">
          <h3 className="text-lg font-semibold">Trader {traderId}</h3>
          <ul>
            <li>
              <a href={`/_auth/_sidebar/company/team/${traderId}/info`}>Info</a>
            </li>
            <li>
              <a href={`/_auth/_sidebar/company/team/${traderId}/approvals`}>Approvals</a>
            </li>
          </ul>
        </div>
      </aside>
      <div className="w-3/4">
        <Outlet />
      </div>
    </>
  );
};
