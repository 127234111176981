import React from 'react';

import { CardViewSettingsContent } from './card-view-settings-content';
import { BasicView } from '../basic-view';
import type { CardView, ReorderedColumn, ViewConfigChanged } from '../view-configurator/types';

export const CardViewSettings: React.FC<{
  columns: ReorderedColumn[];
  trigger: React.ReactNode;
  view: CardView;
  viewConfigChanged: ViewConfigChanged;
  viewDeleted: (viewId: number) => void;
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({
  columns,
  columnsReordered,
  viewDeleted,
  view,
  trigger,
  viewConfigChanged,
  columnVisibilityChanged,
}) => {
  return (
    <BasicView
      view={view}
      trigger={trigger}
      displayTriggerIcon={false}
      sideOffset={6}
      viewConfigChanged={viewConfigChanged}
      viewDeleted={viewDeleted}
      content={
        <CardViewSettingsContent
          columns={columns}
          columnsReordered={columnsReordered}
          view={view}
          viewConfigChanged={viewConfigChanged}
          columnVisibilityChanged={columnVisibilityChanged}
        />
      }
    />
  );
};
