import ng from 'angular';

import { DriversContainer } from './drivers-container/drivers-container.component';
import { DriversListTableView } from './drivers-list-table-view/drivers-list-table-view.component';
import { DriverModal } from './drivers-modal/driver-modal.component';
import { DriversPageView } from './drivers-page-view/drivers-page-view.component';
import { DriversService } from './drivers.service';

const driversConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('drivers', {
        parent: 'gt.page',
        url: '/drivers',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('drivers.list', {
        url: '/drivers',
        component: 'driversPageView',
        data: {
          pageTitle: gettext('Drivers'),
        },
      });
  },
];

export const driversModule = ng
  .module('drivers.list', [])
  .component('driversPageView', DriversPageView)
  .component('driversContainer', DriversContainer)
  .component('driversListTableView', DriversListTableView)
  .component('driverModal', DriverModal)
  .config(driversConfig)
  .service('DriversService', DriversService).name;
