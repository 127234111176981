import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('pricesPositionContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      pricePositionView: '<?',
    },
    template: require('./prices-position-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$rootScope',
    'moment',
    'GtUtils',
    'ContractsService',
    'LogisticsService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    moment: any,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    LogisticsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.edit = false;
    vm.create = false;
    vm.filterLevel = vm.filterLevel || 'prices-position-container';
    vm.queryParams = {};
    vm.updateData = updateData;
    vm.createPrice = createPrice;
    vm.updatePrices = updatePrices;
    vm.createPrices = createPrices;
    vm.deletePricePosition = deletePricePosition;
    vm.clonePricePosition = clonePricePosition;
    vm.bulkDeletePricePositions = bulkDeletePricePositions;
    vm.pricesPositionIndicators = {};
    vm.pricesPositionIndicatorsCount = 0;
    vm.months = [];
    vm.crops = [];
    vm.ports = {};
    vm.pricePositionView = 'prices';

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      vm.m2mPriceDetailing = $rootScope.user.settings.MTM_PRICES_DETAILING;
      updateData();
    };

    ////////////////

    function updateData() {
      ContractsService.PricesPositionIndicator.query({}, function (data: any) {
        vm.data = data.results;
        vm.pricesPositionIndicators = pricesToDict(data.results);
        vm.pricesPositionIndicatorsCount = data.count;
        prepareMonths(data.results);
        prepareCrops(data.results);
        preparePorts(data.results);
      });
      getNewPrice().then(function (data: any) {
        vm.newPrice = data;
      });
    }

    function getNewPrice() {
      return LogisticsService.Basis.predictions({
        search: $rootScope.user.settings.PNL_MTM_BASIS_CODENAME,
      }).$promise.then(function (data: any) {
        return { basis: data.results.shift()?.id };
      });
    }

    function pricesToDict(pricesList: any) {
      const result: any = {};
      ng.forEach(pricesList, function (price: any) {
        const yearMonthKey = moment(price.indication_date).format('YYYY-MM');
        if (!result[yearMonthKey]) {
          result[yearMonthKey] = {};
        }
        if (!result[yearMonthKey][price.cargo]) {
          result[yearMonthKey][price.cargo] = {};
        }
        const prettyUpdateTime = moment(price.update_time).format('dddd, MMMM Do YYYY, h:mm:ss a');
        price.dataTip =
          prettyUpdateTime + ', ' + price.editor_first_name + ' ' + price.editor_last_name;
        price.init_price = price.price;
        price.init_additional_info = price.additional_info;
        result[yearMonthKey][price.cargo][price.port || 'without port'] = price;
      });
      return result;
    }

    function prepareMonths(pricesList: any) {
      vm.months = [];
      ng.forEach(pricesList, function (price: any) {
        const yearMonthKey = moment(price.indication_date).format('YYYY-MM');
        let contains = false;
        ng.forEach(vm.months, function (month: any) {
          if (yearMonthKey == month.key) {
            contains = true;
          }
        });
        if (!contains) {
          vm.months.push({
            key: yearMonthKey,
            title: moment(price.indication_date).format('MMM, YYYY'),
            value: price.indication_date,
          });
        }
      });
      vm.months.sort(function (previous: any, next: any) {
        return moment(previous, 'MMM, YYYY') - moment(next, 'MMM, YYYY');
      });
    }

    function prepareCrops(pricesList: any) {
      vm.crops = [];
      ng.forEach(pricesList, function (price: any) {
        let contains = false;
        ng.forEach(vm.crops, function (crop: any) {
          if (crop.id == price.cargo) {
            contains = true;
          }
        });
        if (!contains) {
          vm.crops.push({
            title: price.cargo_title,
            id: price.cargo,
          });
        }
      });
    }

    function preparePorts(pricesList: any) {
      vm.ports = {};
      ng.forEach(pricesList, function (price: any) {
        if (!vm.ports[price.cargo]) {
          vm.ports[price.cargo] = [
            {
              title: '---',
              id: 'without port',
            },
          ];
        }
        if (!price.port) {
          return;
        }
        let contains = false;
        ng.forEach(vm.ports[price.cargo], function (port: any) {
          if (port.id == price.port) {
            contains = true;
          }
        });
        if (!contains) {
          vm.ports[price.cargo].push({
            title: price.port_name,
            id: price.port,
            priority: price.port_priority,
          });
        }
      });

      Object.keys(vm.ports).forEach(function (cargo) {
        vm.ports[cargo] = vm.ports[cargo].sort(function (a: any, b: any) {
          if (a.priority < b.priority) {
            return 1;
          }
          if (a.priority > b.priority) {
            return -1;
          }
          return 0;
        });
      });
    }

    function createPrice(price: any) {
      ContractsService.PricesPositionIndicator.save(
        price,
        function () {
          GtUtils.notify(gettext('Crop price created'), 'info');
          updateData();
        },
        _error,
      );
    }

    function deletePricePosition(price: any) {
      const message =
        gettext('Delete price') +
        ' ' +
        price.cargo_title +
        ', ' +
        (price.port_name || 'without port') +
        '?';
      if (!confirm(message)) {
        return;
      }
      ContractsService.PricesPositionIndicator.delete(
        price,
        function () {
          GtUtils.notify(gettext('Crop price deleted'), 'info');
          updateData();
        },
        _error,
      );
    }

    function clonePricePosition(pricePosition: any) {
      vm.newPrice = {};

      const fieldsToCopy: any = [
        'additional_info',
        'basis',
        'cargo',
        'currency_exchange',
        'indication_date',
        'port',
        'price',
      ];

      const pricePositionCopy = ng.copy(pricePosition);

      fieldsToCopy.forEach((field) => {
        vm.newPrice[field] = pricePositionCopy[field];
      });
    }

    function bulkDeletePricePositions(month: any) {
      const msg =
        'Are you sure that you want delete prices for ' +
        month.title +
        '? You can not revert this action!';
      if (!confirm(msg)) {
        return;
      }
      ContractsService.PricesPositionIndicator.bulkDelete(
        { month: month.key },
        function () {
          GtUtils.notify(gettext('Crop prices deleted'), 'info');
          updateData();
        },
        _error,
      );
    }

    function _error(data: any) {
      GtUtils.errorClb(data);
    }

    function updatePrices() {
      const priceList: any = [];
      ng.forEach(vm.pricesPositionIndicators, function (nestedDict: any) {
        ng.forEach(nestedDict, function (priceCell: any) {
          if (priceCell && Object.keys(priceCell).length) {
            ng.forEach(priceCell, (price: any) => isPriceChanged(price) && priceList.push(price));
          }
        });
      });
      vm.edit = false;
      ContractsService.PricesPositionIndicator.bulkUpdate(priceList, updateData);
    }

    function createPrices() {
      const priceList: any = [];
      ng.forEach(vm.pricesPositionIndicators, function (nestedDict: any) {
        ng.forEach(nestedDict, function (priceCell: any) {
          if (priceCell && Object.keys(priceCell).length) {
            ng.forEach(priceCell, function (price: any) {
              if (isPriceChanged(price)) {
                price.author = $rootScope.user.id;
                priceList.push(price);
              }
            });
          }
        });
      });
      vm.edit = false;
      vm.create = false;
      ContractsService.PricesPositionIndicator.bulkCreate(priceList, updateData);
    }

    function isPriceChanged(price: any) {
      return (
        price.init_additional_info !== price.additional_info || price.init_price !== price.price
      );
    }
  }
})();
