import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './invoice-list-page.html?raw';
import type { InvoiceFilterConfigService } from '../invoice-filter-config.service';

export const InvoicesListPage = {
  bindings: {
    queryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'FinancesService',
    'InvoiceFilterConfigService',
    'gtFilterService',
    'PageService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      InvoiceFilterConfigService: InvoiceFilterConfigService;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      groupBy: any;
      gtFilterService: GtFilterService;
      quickAdd: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        FinancesService: FinancesService,
        InvoiceFilterConfigService: any,
        gtFilterService: GtFilterService,
        PageService: PageService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.FinancesService = FinancesService;
        this.InvoiceFilterConfigService = InvoiceFilterConfigService;
        this.gtFilterService = gtFilterService;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.filterLevel = 'invoices-page-view';
        this.quickAdd = false;
        this.groupBy = null;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.$scope.$on('group-by', (ev: any, data: any) => {
          this.groupBy = data;
        });
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-payments-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.FinancesService.getPagesConfig(),
        };

        // @ts-ignore
        config.filters = this.InvoiceFilterConfigService.getFilterConfig(this.filterLevel);

        return config;
      }
    },
  ],
};
