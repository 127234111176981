import ng from 'angular';

export const GtTableSticky = ($timeout: ng.ITimeoutService, $window: ng.IWindowService) => {
  return {
    restrict: 'A',
    scope: {
      gtTableSticky: '<',
    },
    link($scope: ng.IScope, $elem: any) {
      if (!($scope as any).gtTableSticky.tableHeight) {
        return false;
      }
      // copypaste below from so
      const elem = $elem[0];
      let globTimeout = 1000;

      $scope.$watch('gtTableSticky.watchValue', transformTable);

      // eslint-disable-next-line sonarjs/deprecation
      ng.element($window).bind('resize', transformTable);

      function transformTable() {
        if (!($scope as any).gtTableSticky.watchValue) {
          return false;
        }
        $timeout(() => {
          ng.element(elem.querySelectorAll('thead, tbody, tfoot')).css('display', '');
          ng.forEach(elem.querySelectorAll('tr:first-child th'), (thElem: any, i: any) => {
            const tdElems = elem.querySelector(`tbody tr:first-child td:nth-child(${i + 1})`);
            const tfElems = elem.querySelector(`tfoot tr:first-child td:nth-child(${i + 1})`);

            const columnWidth = tdElems ? tdElems.offsetWidth : thElem.offsetWidth;
            $timeout(() => {
              if (tdElems) {
                tdElems.style['min-width'] = columnWidth + 'px';
              }
              if (thElem) {
                thElem.style['min-width'] = columnWidth + 'px';
              }
              if (tfElems) {
                tfElems.style['min-width'] = columnWidth + 'px';
              }
            });
          });

          ng.element(elem.querySelectorAll('thead, tfoot')).css('display', 'block');
          ng.element(elem.querySelectorAll('tbody')).css({
            display: 'block',
            height: ($scope as any).gtTableSticky.tableHeight || 'inherit',
            overflow: 'auto',
          });
          $timeout(() => {
            const tbody = elem.querySelector('tbody');
            const scrollBarWidth = tbody.offsetWidth - tbody.clientWidth;
            if (scrollBarWidth > 0) {
              const lastColumn = elem.querySelector('tbody tr:first-child td:last-child');
              lastColumn.style['min-width'] = lastColumn.offsetWidth - scrollBarWidth + 'px';
            }
          });
          globTimeout = 0;
        }, globTimeout);
      }
    },
  };
};
GtTableSticky.$inject = ['$timeout', '$window'];
