import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('company.documents.legacy').controller('DocumentListModalController', Controller);

  Controller.$inject = [
    '$rootScope',
    '$q',
    '$uibModalInstance',
    'GtUtils',
    'CoreService',
    'DocumentsService',
    'queryParams',
    'gettext',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $q: ng.IQService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    DocumentsService: any,
    queryParams: QueryParams,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.close = close;
    vm.persons = [];
    vm.personsCount = 0;
    vm.openDocumentModal = openDocumentModal;
    vm.updateData = updateData;

    activate();

    ////////////////

    function activate() {
      getContentType().then(function (data: any) {
        queryParams.content_type = queryParams.content_type ?? data.id;
        updateData();
      });
    }

    function updateData(params?: any) {
      params = { ...params, ...queryParams };
      return DocumentsService.Document.query(params, function (data: any) {
        vm.documents = DocumentsService.addIcons(data.results);
        vm.documentsCount = data.count;
      }).$promise;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function openDocumentModal() {
      return DocumentsService.documentModal(
        queryParams.model_name,
        queryParams.object_id,
        queryParams.content_type,
      ).then(function () {
        updateData();
      });
    }

    function getContentType() {
      if (queryParams.content_type) {
        return $q.when();
      }
      return CoreService.getModelContentType(queryParams.model_name as string);
    }

    $rootScope.$on('documents-list__remove', function (ev: any, data: any) {
      CoreService.confirmDeletionModal(DocumentsService.Document, data.id).then((data: any) => {
        if (data == 'delete') {
          GtUtils.notify(gettext('Doc removed'));
          return updateData();
        }
      });
    });
  }
})();
