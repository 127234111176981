import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').component('passportsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      tableExpanded: '<?',
      addButton: '<?',
      currency: '<?',
      suppressNotifications: '<?',
    },
    template: require('./passports-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    '$scope',
    'GtUtils',
    'ContractsService',
    'FinancesService',
    'gtFilterService',
    'CoreService',
    'PageService',
    '$window',
    '$httpParamSerializer',
    'gettext',
    'moment',
    'ReportsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    CoreService: CoreService,
    PageService: PageService,
    $window: ng.IWindowService,
    $httpParamSerializer: any,
    gettext: ng.gettext.gettextFunction,
    moment: any,
    ReportsService: any,
  ) {
    const vm = this;
    vm.gridOptions = {};
    vm.passports = [];
    vm.passportsCount = 0;
    vm.passportsTotal = {};
    vm.openPassportModal = openPassportModal;
    vm.openContractModal = openContractModal;
    vm.openFinanceModal = openFinanceModal;
    vm.updatePassports = updatePassports;
    vm.updateStatus = updateStatus;
    vm.updateBusinessDate = updateBusinessDate;
    vm.updateVolume = updateVolume;
    vm.downloadXlsxReport = downloadXlsxReport;
    vm.changeView = changeView;
    vm.getView = getView;
    vm.createUpdateRequest = createUpdateRequest;
    vm.uahRate = 1;
    vm.rQuery = undefined;
    vm.rTotalQuery = undefined;
    vm.rFullQuery = undefined;
    vm.disableRePlanButton = false;
    vm.visibilityParams = [
      'view',
      'showContracts',
      'showContractTotal',
      'showQuantity',
      'showVat',
      'currency',
      'showDiff',
      'showCosts',
      'showExecution',
      'showInvoices',
      'showDerivatives',
    ];
    vm.viewParams = {};
    vm.defaultQueryParams = {
      currency: 'default',
      page_size: 10,
      with_request_b2b: 0,
      view: $rootScope.isDeviceMobile ? 'block' : $rootScope.user.profile.passports_view || 'table',
    };
    vm.__prevQueryParams = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'passports-container';
      gtFilterService.registerCleanFilterLevel(vm.filterLevel);

      vm.suppressNotifications = vm.suppressNotifications || false;
      vm.queryParams = { ...vm.defaultQueryParams, ...vm.initQueryParams };

      if (!$rootScope.user.settings.PASSPORTS_PAGE_B2B_DEFAULT) {
        delete vm.queryParams.with_request_b2b;
      }

      vm.__prevQueryParams = {};
      $scope.$watch(
        () => vm.queryParams.search,
        (newVal: any, oldVal: any) => {
          if (oldVal !== newVal) {
            vm.updatePassports();
          }
        },
      );
      $scope.$on('gt-filter-updated_' + vm.filterLevel, (ev: any, data: any) => applyFilters(data));
      $scope.$on('gt-filter-clear_' + vm.filterLevel, () => {
        gtFilterService.setQueryParams({ ...vm.defaultQueryParams }, vm.filterLevel);
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      vm.savedFilterChoices = CoreService.getSavedFilterChoices(vm.filterLevel);
    };

    vm.$onChanges = function (changes: any) {
      if (changes.tableExpanded && vm.tableExpanded) {
        vm.queryParams.serializer = 'blocks';
        updatePassports();
      }
    };

    function applyFilters(newFilters: any) {
      vm.queryParams = newFilters;
      updateViewParams();
      if (checkNeedUpdate() || !vm.passportsCount) {
        updatePassports();
      }
      vm.__prevQueryParams = { ...vm.queryParams };
    }

    ////////////////

    function updatePassports() {
      GtUtils.overlay('hide', true);
      GtUtils.overlay('show', true);
      if (Array.isArray(vm.queryParams.id_list) && vm.queryParams.id_list.length == 0) {
        GtUtils.overlay('hide');
        return Promise.resolve();
      }
      const c = ContractsService.Passport.result(vm.queryParams, function (data: any) {
        PageService.updatePageCounters({
          queryParams: vm.queryParams,
          model: 'passports.passport',
        });
        vm.passportsCount = data.count;
        if (vm.queryParams.next) {
          vm.passports = vm.passports.concat(data.results);
          delete vm.queryParams.next;
        } else {
          vm.passports = data.results;
        }

        if (vm.queryParams.showTableTotals) {
          ContractsService.Passport.resultTotals(vm.queryParams, (total: any) => {
            vm.passportsTotal = total;
            GtUtils.overlay('hide');
          });
        }
        GtUtils.overlay('hide');
        return updateCustomsData(vm.passports.map((passport: any) => passport.id));
      });
      return GtUtils.addConnection(c).$promise;
    }
    function updateCustomsData(passportIds: any) {
      return ContractsService.Passport.customsData({ passport_ids: passportIds }).$promise.then(
        (customsData: any) => {
          if (!customsData?.[0]) {
            return;
          }
          const keys = Object.keys(customsData?.[0]);
          vm.passports.forEach((passport: any) => {
            const customsDataItem = customsData.find((data: any) => data.id === passport.id);
            if (customsDataItem) {
              keys.forEach((key) => (passport[key] = customsDataItem[key]));
            }
          });
        },
      );
    }
    function openPassportModal(passport: any) {
      return ContractsService.passportModal(passport).then(updatePassports);
    }

    function openContractModal(contract: any) {
      ContractsService.contractModal(contract).then((data: any) => {
        if (!data || data == 'cancel') {
          return;
        }
        vm.updatePassports();
      });
    }

    function openFinanceModal(invoice: any, data: any) {
      return FinancesService.financeModal(invoice, data).then(() => vm.updatePassports());
    }

    function updateStatus() {
      GtUtils.overlay('show');
      return ContractsService.Passport.updateStatus({}, () => {
        updatePassports();
        GtUtils.notify(gettext('Passport status updated'));
        GtUtils.overlay('hide');
      });
    }

    function updateBusinessDate() {
      GtUtils.overlay('show');
      return ContractsService.Passport.updateBusinessDate({}, () => {
        updatePassports();
        GtUtils.notify(gettext('Passport business date updated'));
        GtUtils.overlay('hide');
      });
    }

    function updateVolume() {
      GtUtils.overlay('show');
      return ContractsService.Passport.updateVolume({}, () => {
        updatePassports();
        GtUtils.notify(gettext('Passport volume updated'));
        GtUtils.overlay('hide');
      });
    }

    function downloadXlsxReport() {
      $window.open(
        `/api/passports/passport-result/list/?${$httpParamSerializer({
          ...vm.queryParams,
          serializer: 'table_fact',
          as_xlsx: 1,
          page_size: 999,
          currency: vm.currency,
        })}`,
        '_blank',
      );
    }

    function changeView(view: any) {
      vm.queryParams.view = view;
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function getView() {
      return vm.queryParams.view;
    }

    function getModelFilters(filterSet: any) {
      return Object.keys(filterSet)
        .sort((a, b) => a.localeCompare(b))
        .filter((f) => !vm.visibilityParams.includes(f))
        .reduce((res, f) => {
          res[f] = filterSet[f];
          return res;
        }, {} as any);
    }

    function checkNeedUpdate() {
      return (
        JSON.stringify(getModelFilters(vm.queryParams)) !==
        JSON.stringify(getModelFilters(vm.__prevQueryParams))
      );
    }

    function updateViewParams() {
      vm.viewParams = vm.visibilityParams
        .filter((f: any) => vm.queryParams[f])
        .reduce((res: any, f: any) => {
          res[f] = vm.queryParams[f];
          return res;
        }, {});
    }

    function createUpdateRequest(this: any) {
      GtUtils.overlay('show');
      return ReportsService.CalculatedValueUpdateRequest.save(
        {
          view_name: 'view_materialized_passport',
        },
        (data: any) => {
          vm.passportsTotal.last_update_request_status = 'pending';
          vm.passportsTotal.last_update_request_update_time = moment(data.create_time).format(
            'YYYY-MM-DDTHH:mm',
          );
          this.GtUtils.notify(this.gettext('The totals have been updated'));
          GtUtils.overlay('hide');
        },
        (data: any) => {
          GtUtils.errorClb(data);
        },
      );
    }
  }
})();
