import type ng from 'angular';
export class LoyalityProgramDataService {
  $http: ng.IHttpService;
  $q: ng.IQService;
  moment: any;
  prevData: any;
  prevParams: any;
  constructor($http: ng.IHttpService, $q: ng.IQService, moment: any) {
    this.$http = $http;
    this.$q = $q;
    this.moment = moment;

    this.prevParams = {};
    this.prevData = [];
  }

  fetchPremiumData(
    obj: any,
    dateFrom: Date | string,
    dateTo: Date | string,
    bonusPercentage: number,
  ) {
    if (!dateFrom || !dateTo) {
      return this.$q.when();
    }

    const fromDate = typeof dateFrom === 'string' ? new Date(dateFrom) : dateFrom;
    const toDate = typeof dateTo === 'string' ? new Date(dateTo) : dateTo;

    const monthTo = this.moment(new Date(toDate.getFullYear(), toDate.getMonth(), 1)).format(
      'DD.MM.YYYY',
    );
    const monthFrom = this.moment(new Date(fromDate.getFullYear(), fromDate.getMonth(), 1)).format(
      'DD.MM.YYYY',
    );

    const params = {
      contract_option: obj.contract_option,
      price: obj.price,
      volume: obj.volume,
      cargo: obj.cargo,
      premium_bonus_percentage: bonusPercentage,
    };
    if (monthFrom == monthTo) {
      // @ts-ignore
      params.month = monthFrom;
    } else {
      // @ts-ignore
      params.month_from = monthFrom;
      // @ts-ignore
      params.month_to = monthTo;
    }

    if (!this.paramsChanged(params)) {
      return this.$q.when().then(() => this.prevData);
    }

    return this.$http
      .get('/api/contracts/contract-option-group-monthes/get_premium_program_data/', {
        params: params,
      })
      .then((response: any) => {
        this.prevData = response.data;
        return response.data;
      });
  }

  paramsChanged(params: any) {
    if (JSON.stringify(this.prevParams) == JSON.stringify(params)) {
      return false;
    } else {
      this.prevParams = { ...params };
      return true;
    }
  }
}
LoyalityProgramDataService.$inject = ['$http', '$q', 'moment'];
