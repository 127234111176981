import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.emailTemplates').controller('EmailModalController', Controller);

  Controller.$inject = [
    '$document',
    '$uibModalInstance',
    'gettext',
    'EmailTemplates',
    'emailTemplate',
    'GtUtils',
    'CoreService',
  ];

  function Controller(
    this: any,
    $document: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    gettext: ng.gettext.gettextFunction,
    EmailTemplates: any,
    emailTemplate: any,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
  ) {
    const vm = this;
    vm.save = save;
    vm.destroy = destroy;
    vm.close = close;
    vm.fields = getFields();
    vm.emailTemplate = emailTemplate || {};
    vm.addText = addText;
    vm.tags = {};
    vm.selected = '';
    vm.contract_tags = [];
    vm.invoice_tags = [];
    vm.searchFilter = '';

    activate();

    ////////////////

    function activate() {
      getTags();
    }

    function getFields() {
      const col1: any = {
        className: 'form-group-container col-md-12 col-xs-12',
        fieldGroup: [],
      };

      col1.fieldGroup = [
        {
          wrapper: 'gt-panel',
          templateOptions: {},
          fieldGroup: [
            {
              key: 'model_type',
              type: 'gt-select',
              defaultValue: 'Client',
              templateOptions: {
                label: gettext('Model'),
                placeholder: gettext('Model'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  {
                    name: gettext('Client'),
                    value: gettext('Client'),
                  },
                  {
                    name: gettext('Ticket'),
                    value: gettext('Request'),
                  },
                  {
                    name: gettext('Sale Contract'),
                    value: gettext('SaleContract'),
                  },
                  {
                    name: gettext('Purchase Contract'),
                    value: gettext('PurchaseContract'),
                  },
                  {
                    name: gettext('Intermediate Contract'),
                    value: gettext('IntermediateContract'),
                  },
                  {
                    name: gettext('Export Contract'),
                    value: gettext('ExportContract'),
                  },
                  {
                    name: gettext('Services Contract'),
                    value: gettext('ServicesContract'),
                  },
                  {
                    name: gettext('Sale multicontract'),
                    value: 'SaleMulticontract',
                  },
                  {
                    name: gettext('Purchase multicontract'),
                    value: 'PurchaseMulticontract',
                  },
                  {
                    name: gettext('Intermediate multicontract'),
                    value: 'IntermediateMulticontract',
                  },
                  {
                    name: gettext('Export multicontract'),
                    value: 'ExportMulticontract',
                  },
                  {
                    name: gettext('Services multicontract'),
                    value: 'ServicesMulticontract',
                  },
                  {
                    name: gettext('Sale multiticket'),
                    value: 'SaleTicket',
                  },
                  {
                    name: gettext('Purchase multiticket'),
                    value: 'PurchaseTicket',
                  },
                  {
                    name: gettext('Intermediate multiticket'),
                    value: 'IntermediateTicket',
                  },
                  {
                    name: gettext('Export multiticket'),
                    value: 'ExportTicket',
                  },
                  {
                    name: gettext('Services multiticket'),
                    value: 'ServicesTicket',
                  },
                  {
                    name: gettext('Logistics mailing'),
                    value: gettext('Logistics mailing'),
                  },
                  {
                    name: gettext('Finances mailing'),
                    value: gettext('Finances mailing'),
                  },
                  {
                    name: gettext('Logistics'),
                    value: gettext('Logistics'),
                  },
                  {
                    name: gettext('Invoice'),
                    value: gettext('Invoice'),
                  },
                  {
                    name: gettext('Passport'),
                    value: gettext('Passport'),
                  },
                  {
                    name: gettext('Voyage'),
                    value: gettext('Voyage'),
                  },
                  {
                    name: gettext('Bill of lading'),
                    value: gettext('BillOfLading'),
                  },
                ],
                hint: gettext(
                  'Choose the block, to which this template will be attached.' +
                    'This means, you might use it only within this block',
                ),
                required: true,
              },
            },
            {
              key: 'group_name',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Tag'),
              },
            },
            {
              key: 'title',
              type: 'gt-input',
              templateOptions: {
                label: gettext('Title'),
                hint: gettext(
                  'These is the Title, only you will see, to identify current template',
                ),
                required: true,
              },
            },
            {
              key: 'subject',
              type: 'gt-textarea',
              templateOptions: {
                label: gettext('Subject'),
                type: 'text',
                class: 'subject',
                hint: gettext('These is the Subject, your recipients see when receive letter'),
                required: true,
              },
            },
            {
              key: 'body',
              type: 'gt-textarea',
              id: 'body_id',
              templateOptions: {
                label: gettext('Body'),
                type: 'text',
                class: 'body',
                hint: gettext(
                  'This is the Message of your letter.' +
                    'You can use tags {{ tag }} to add some fields to template here.',
                ),
                required: true,
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValue: Object.assign([], CoreService.getDefaultBuList()).shift(),
              templateOptions: {
                label: gettext('Business unit'),
                placeholder: gettext('Business Unit'),
                queryParams: GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        },
      ];

      return [col1];
    }

    function addText(text: any) {
      /*
        Add text to cursor current position and set focus to emailtemplate
        body textarea.
      */
      const txtarea = $document[0].getElementById('body_id');

      const scrollPos = txtarea.scrollTop;
      let caretPos = txtarea.selectionStart;

      const front = txtarea.value.substring(0, caretPos);
      const back = txtarea.value.substring(txtarea.selectionEnd, txtarea.value.length);
      vm.emailTemplate.body = front + text + back;
      caretPos = caretPos + text.length;
      txtarea.selectionStart = caretPos;
      txtarea.selectionEnd = caretPos;
      txtarea.focus();
      txtarea.scrollTop = scrollPos;
      vm.searchFilter = '';
      getTags();
    }

    function save() {
      vm.form.$invalid = true;
      if (vm.emailTemplate.id) {
        return EmailTemplates.EmailTemplate.update(
          vm.emailTemplate,
          function (data: any) {
            GtUtils.notify(gettext('Template saved'));
            close(data, true);
          },
          _error,
        ).$promise;
      }
      return EmailTemplates.EmailTemplate.save(
        vm.emailTemplate,
        function (data: any) {
          GtUtils.notify(gettext('Template saved'));
          close(data, true);
        },
        _error,
      ).$promise;
    }

    function destroy() {
      const msg = gettext('Are you sure that you want delete a template?');
      if (!confirm(msg)) {
        return;
      }
      return EmailTemplates.EmailTemplate.delete(
        vm.emailTemplate,
        function (data: any) {
          GtUtils.notify(gettext('Template deleted'));
          close(data, true);
        },
        _error,
      ).$promise;
    }

    function _error(data: any) {
      vm.emailTemplate.errors = data.data;
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function getTags() {
      vm.tags = [
        // General
        {
          title: gettext('Contract number'),
          tag: '{{ contract_number }}',
        },
        {
          title: gettext('Conclusion date'),
          tag: '{{ conclusion_date }}',
        },
        {
          title: gettext('Date'),
          tag: '{{ date }}',
        },
        {
          title: gettext('Price'),
          tag: '{{ price }}',
        },
        {
          title: gettext('Price in USD'),
          tag: '{{ price_in_USD }}',
        },
        {
          title: gettext('Price in USD words'),
          tag: "{{ price_in_USD | num2words(lang='ua', currency='eng') }}",
        },
        {
          title: gettext('Few prices'),
          tag:
            '{% for p in contract_price_set %}\n' +
            '{{ p.price }}\n' +
            '{{ p.basis }}\n' +
            '{{ p.port }}\n' +
            '{{ total_value }}\n' +
            '{% endfor %}',
        },
        {
          title: gettext('Contract value in UAH'),
          tag: '{{ total_value * currency_exchange.rate | float }}',
        },
        {
          title: gettext('Volume'),
          tag: '{{ volume }}',
        },
        {
          title: gettext('Final volume'),
          tag: '{{ final_volume }}',
        },
        {
          title: gettext('Measurement'),
          tag: '{{ measurement }}',
        },
        {
          title: gettext('Cargo'),
          tag: '{{ cargo }}',
        },
        {
          title: gettext('Cargo all'),
          tag: '{{ cargo.title_long }}',
        },
        {
          title: gettext('Cargo eng'),
          tag: '{{ cargo.title_eng }}',
        },
        {
          title: gettext('Option'),
          tag: '{{ basis.name }}',
        },
        {
          title: gettext('Whoose option'),
          tag: '{{ volume_options_company }}%',
        },
        {
          title: gettext('Quality'),
          tag: '{{ quality_list }}',
        },
        {
          title: gettext('Quality list'),
          tag: '{%p for p in quality_list_set %} {{ p }} {%p endfor %}',
        },
        {
          title: gettext('Choosed quality list'),
          tag: '{%p for p in quality_list_choosed %} {{ p }} {%p endfor %}',
        },
        {
          title: gettext('Quality list eng'),
          tag: '{%p for p in quality_list_eng_set %} {{ p }} {%p endfor %}',
        },
        {
          title: gettext('Quality eng'),
          tag: '{{ quality_list_eng }}',
        },
        {
          title: gettext('Contract DSTU'),
          tag: '{{ contract_dstu }}',
        },
        {
          title: gettext('Crop DSTU'),
          tag: '{{ crop_dstu }}',
        },
        {
          title: gettext('Origin'),
          tag: '{{ origin_of_crop }}',
        },
        {
          title: gettext('Destination'),
          tag: '{{ destination_of_crop }}',
        },
        {
          title: gettext('Start of execution'),
          tag: '{{ date_of_execution }}',
        },
        {
          title: gettext('End of execution'),
          tag: '{{ end_of_execution }}',
        },
        {
          title: gettext('Delivery condition'),
          tag: '{{ delivery_condition.title }}',
        },
        {
          title: gettext('Payment conditions auto (%)'),
          tag: '{{ payment_conditions _auto }}%',
        },
        {
          title: gettext('Payment conditions (%)'),
          tag: '{{ payment_conditions }}%',
        },
        {
          title: gettext('Payment conditions (description)'),
          tag: '{{ payment_conditions_option }}',
        },
        {
          title: gettext('Additional agreements'),
          tag: '{{ additional_agreement_number }}',
        },
        {
          title: gettext('Additional agreements date'),
          tag: '{{ additional_agreement_date }}',
        },
        {
          title: gettext('Exporters'),
          tag: '{{ exporters_list }}',
        },
        {
          title: gettext('Exporters eng'),
          tag: '{{ exporters_list_eng }}',
        },
        {
          title: gettext('Exporters list'),
          tag: '{% for e in exporters %}\n{{ e.name }}\n{% endfor %}',
        },
        {
          title: gettext('Exporters list eng'),
          tag: '{% for e in exporters %}\n{{ e.name_eng }}\n{% endfor %}',
        },
        {
          title: gettext('Notes'),
          tag: '{{ additional_info }}',
        },
        {
          title: gettext('Contract notes'),
          tag: '{{ contract.additional_info }}',
        },
        {
          title: gettext('Ports'),
          tag: '{{ ports.all }}',
        },
        {
          title: gettext('Ports list'),
          tag: '{{ {% for p in ports %}\n{{ p.full_name }}\n{% endfor %}',
        },
        {
          title: gettext('Ports list eng'),
          tag: '{{ {% for p in ports %}\n{{ p.name_eng }}\n{% endfor %} }}',
        },
        {
          title: gettext('Ports list short name'),
          tag: '{{ ports.all }}',
        },
        {
          title: gettext('Crop year'),
          tag: '{{ crop_year }}',
        },
        {
          title: gettext('First charge'),
          tag: '{{first_contract_charge_time}}',
        },
        // Supplier
        {
          title: gettext('Supplier'),
          tag: '{{ supplier.name }}',
        },
        {
          title: gettext('Supplier full name'),
          tag: '{{ supplier.client.full_name }}',
        },
        {
          title: gettext('Supplier full name eng'),
          tag: '{{ supplier.client.full_name_eng }}',
        },
        {
          title: gettext('Supplier EDRPOU code'),
          tag: '{{ supplier.client.company_code }}',
        },
        {
          title: gettext('Supplier ITN (Individual Tax Number)'),
          tag: '{{ supplier.client.itn }}',
        },
        {
          title: gettext('Supplier legal address'),
          tag: '{{ supplier_address }}',
        },
        {
          title: gettext('Supplier address eng'),
          tag: '{{ supplier_address_eng }}',
        },
        {
          title: gettext('Supplier address'),
          tag: '{{ supplier.client.current_address }}',
        },
        {
          title: gettext('Supplier email'),
          tag: '{{ supplier.client.email }}',
        },
        {
          title: gettext('Supplier website'),
          tag: '{{ supplier.client.website }}',
        },
        {
          title: gettext('Supplier phone'),
          tag: '{{ supplier.client.phone }}',
        },
        {
          title: gettext('Supplier country'),
          tag: '{{ supplier.client.country.title }}',
        },
        {
          title: gettext('Supplier country eng'),
          tag: '{{ supplier.client.country.title_eng }}',
        },
        {
          title: gettext('Supplier signatory'),
          tag: '{{ supplier.client.person_signatory }}',
        },
        {
          title: gettext('Person signatory eng'),
          tag: '{{ supplier.client.person_signatory_eng }}',
        },
        {
          title: gettext('Supplier can signatory docs'),
          tag: '{{ supplier.client.person_signatory_docs }}',
        },
        {
          title: gettext('Supplier can signatory docs eng'),
          tag: '{{ supplier.client.person_signatory_docs_eng }}',
        },
        //Supplier bank details
        {
          title: gettext('Supplier bank details'),
          tag:
            '{% for p in supplier_paymentinfo_set %}\n' +
            '{{ p.account }}\n' +
            '{{ p.bank_name }}\n' +
            '{{ p.bank_code }}\n' +
            '{{ p.iban }}\n' +
            '{{ p.swift }}\n' +
            '{{ p.legal_address }}\n' +
            '{{ p.country }}\n' +
            '{{ p.corr_bank }}\n' +
            '{{ p.corr_swift }}\n' +
            '{{ p.corr_account }}\n' +
            '{{ p.legal_address }}\n' +
            '{% endfor %}',
        },
        // Buyer
        {
          title: gettext('Buyer'),
          tag: '{{ buyer.name }}',
        },
        {
          title: gettext('Buyer full name'),
          tag: '{{ buyer.client.full_name }}',
        },
        {
          title: gettext('Buyer full name eng'),
          tag: '{{ buyer.client.full_name_eng }}',
        },
        {
          title: gettext('Buyer EDRPOU code'),
          tag: '{{ buyer.client.company_code }}',
        },
        {
          title: gettext('Buyer ITN (Individual Tax Number)'),
          tag: '{{ buyer.client.itn }}',
        },
        {
          title: gettext('Buyer tax info'),
          tag: '{{ buyer.client.tax_info }}',
        },
        {
          title: gettext('Buyer legal address'),
          tag: '{{ buyer_address }}',
        },
        {
          title: gettext('Buyer legal address eng'),
          tag: '{{ buyer_address_eng }}',
        },
        {
          title: gettext('Buyer address'),
          tag: '{{ buyer.client.current_address }}',
        },
        {
          title: gettext('Buyer email'),
          tag: '{{ buyer.client.email }}',
        },
        {
          title: gettext('Buyer website'),
          tag: '{{ buyer.client.website }}',
        },
        {
          title: gettext('Buyer phone'),
          tag: '{{ buyer.client.phone }}',
        },
        {
          title: gettext('Buyer country'),
          tag: '{{ buyer.client.country.title }}',
        },
        {
          title: gettext('Buyer country eng'),
          tag: '{{ buyer.client.country.title_eng }}',
        },
        {
          title: gettext('Buyer signatory'),
          tag: '{{ buyer.client.person_signatory }}',
        },
        {
          title: gettext('Buyer signatory eng'),
          tag: '{{ buyer.client.person_signatory_eng }}',
        },
        {
          title: gettext('Buyer can signatory docs'),
          tag: '{{ buyer.client.person_signatory_docs }}',
        },
        {
          title: gettext('Buyer can signatory docs eng'),
          tag: '{{ buyer.client.person_signatory_docs_eng }}',
        },
        // Buyer bank details
        {
          title: gettext('Buyer bank details'),
          tag:
            '{% for p in buyer_paymentinfo_set %}\n' +
            '{{ p.bank_name }}\n' +
            '{{ p.bank_code }}\n' +
            '{{ p.swift }}\n' +
            '{{ p.legal_address }}\n' +
            '{{ p.country }}\n' +
            '{{ p.account }}\n' +
            '{{ p.corr_bank }}\n' +
            '{{ p.corr_swift }}\n' +
            '{{ p.corr_account }}\n' +
            '{% endfor %}',
        },
        // Broker
        {
          title: gettext('Broker name'),
          tag: '{{ broker.name }}',
        },
        {
          title: gettext('Broker full name ru'),
          tag: '{{ broker.client.full_name }}',
        },
        {
          title: gettext('Broker full name eng'),
          tag: '{{ broker.client.full_name_eng }}',
        },
        {
          title: gettext('Broker EDRPOU code'),
          tag: '{{ broker.client.company_code }}',
        },
        {
          title: gettext('Broker address'),
          tag: '{{ broker.client.current_address }}',
        },
        {
          title: gettext('Broker legal address'),
          tag: '{{ broker.client.address }}',
        },
        {
          title: gettext('Broker legal address eng'),
          tag: '{{ broker.client.address_eng }}',
        },
        {
          title: gettext('Broker email'),
          tag: '{{ broker.client.email }}',
        },
        {
          title: gettext('Broker website'),
          tag: '{{ broker.client.website }}',
        },
        {
          title: gettext('Broker phone'),
          tag: '{{ broker.client.phone }}',
        },
        {
          title: gettext('Broker country'),
          tag: '{{ broker.client.country.title }}',
        },
        {
          title: gettext('Broker country eng'),
          tag: '{{ broker.client.country.title_eng }}',
        },
        {
          title: gettext('Broker signatory'),
          tag: '{{ broker.client.person_signatory }}',
        },
        {
          title: gettext('Broker signatory eng'),
          tag: '{{ broker.client.person_signatory_eng }}',
        },
        {
          title: gettext('Broker can signatory docs'),
          tag: '{{ broker.client.person_signatory_docs }}',
        },
        {
          title: gettext('Broker can signatory docs eng'),
          tag: '{{ broker.client.person_signatory_docs_eng }}',
        },
        // Exporter
        {
          title: gettext('Exporter name'),
          tag: '{{ exporter.name }}',
        },
        {
          title: gettext('Exporter list eng'),
          tag: '{{ exporters_list_eng }}',
        },
        {
          title: gettext('Exporter list'),
          tag: '{{ {% for e in exporters %} {{ e.name }} {% endfor %} }}',
        },
        {
          title: gettext('Exporter full name'),
          tag: '{{ exporter.client.full_name }}',
        },
        {
          title: gettext('Exporter full name eng'),
          tag: '{{ exporter.client.full_name_eng }}',
        },
        {
          title: gettext('Supplier EDRPOU code'),
          tag: '{{ exporter.client.company_code }}',
        },
        {
          title: gettext('Exporter address'),
          tag: '{{ exporter.client.current_address }}',
        },
        {
          title: gettext('Exporter legal address'),
          tag: '{{ exporter.client.address }}',
        },
        {
          title: gettext('Exporter legal address eng'),
          tag: '{{ exporter.client.address_eng }}',
        },
        {
          title: gettext('Exporter email'),
          tag: '{{ exporter.client.email }}',
        },
        {
          title: gettext('Exporter website'),
          tag: '{{ exporter.client.website }}',
        },
        {
          title: gettext('Exporter phone'),
          tag: '{{ exporter.client.phone }}',
        },
        {
          title: gettext('Exporter country'),
          tag: '{{ exporter.client.country.title }}',
        },
        {
          title: gettext('Exporter country eng'),
          tag: '{{ exporter.client.country.title_eng }}',
        },
        {
          title: gettext('Exporter signatory'),
          tag: '{{ exporter.client.person_signatory }}',
        },
        {
          title: gettext('Exporter signatory eng'),
          tag: '{{ exporter.client.person_signatory_eng }}',
        },
        {
          title: gettext('Exporter can signatory docs'),
          tag: '{{ exporter.client.person_signatory_docs }}',
        },
        {
          title: gettext('Exporter can signatory docs eng'),
          tag: '{{ exporter.client.person_signatory_docs_eng }}',
        },
      ];
    }
  }
})();
