import template from './account-activity-list-table-view.html?raw';

export const AccountActivityListTableView = {
  bindings: {
    transactions: '<',
    count: '<',
    totals: '<',
    filterLevel: '<?',
    onUpdate: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      count = 0;
      filterLevel = '';
      gettext: ng.gettext.gettextFunction;
      tableData: any;
      tableOptions: any;
      totals: any;
      transactions: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
      }

      $onInit() {
        this.tableData = { rows: this.transactions, count: this.count, total: this.totals };
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.transactions || changes.count || changes.totals) {
          this.tableData = { rows: this.transactions, count: this.count, total: this.totals };
        }
      }

      getTableOptions() {
        return {
          tableName: `${this.filterLevel}-table`,
          alignColHeight: true,
          rowData: this.transactions,
          tableClass: 'table table-responsive table-condensed main-table contract-charges-table',
          configurable: true,
          columnDefs: [
            {
              columnName: 'date',
              title: this.gettext('Date'),
              class: 'td-left-align',
              cellTemplate: `
                <i class="fa fa-calendar"></i> {[{ item.date | date:'dd.MM.yy' || '---' }]}
              `,
              totalTemplate: `
                <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate><br/>
              `,
            },
            {
              columnName: 'documentId',
              title: this.gettext('Document ID'),
              class: 'td-left-align',
              cellTemplate: `
                # {[{ item.document_id }]}
              `,
            },
            {
              columnName: 'counterparty',
              title: this.gettext('Counterparty'),
              class: 'td-left-align',
              cellTemplate: `
                # {[{ item.client_name }]}
              `,
            },
            {
              columnName: 'contract',
              title: this.gettext('Contract'),
              class: 'td-left-align',
              cellTemplate: `
                # {[{ item.contract_number || '---' }]}
              `,
            },
            {
              columnName: 'debit',
              title: this.gettext('Debit'),
              class: 'td-left-align',
              cellTemplate: `
                <span
                  ng-if="item.document_type != 'invoice'"
                  ng-class="{'featured': item.debit_finance_account_id == vm.financeAccount}"
                >
                  {[{ item.debit_financeaccount_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'credit',
              title: this.gettext('Credit'),
              class: 'td-left-align',
              cellTemplate: `
                <span
                  ng-if="item.document_type != 'invoice'"
                  ng-class="{'featured': item.credit_finance_account_id == vm.financeAccount}"
                >
                  {[{ item.credit_financeaccount_title || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'businessUnit',
              title: this.gettext('Business unit'),
              class: 'td-left-align',
              cellTemplate: `
                <span> {[{ item.business_unit_title || '---' }]} </span>
              `,
            },
            {
              columnName: 'amount',
              title: this.gettext('Amount'),
              class: 'td-left-align',
              cellTemplate: `
                <span
                  ng-if="item.document_type != 'invoice'"
                  ng-class="{'label negative-number': item.amount < 0,'label positive-number': item.amount > 0 }"
                >
                  {[{ item.amount || 0 | number:2}]}
                </span>
              `,
              totalTemplate: `
                <span
                  ng-class="{'label negative-number': item.total_amount < 0,'label positive-number': item.total_amount > 0 }"
                >
                  {[{ item.total_amount || 0 | number:2 }]}
                </span>
              `,
            },
            {
              columnName: 'balance',
              title: this.gettext('Balance'),
              class: 'td-left-align',
              cellTemplate: `
                <span
                  ng-class="{'label negative-number': item.balance < 0,'label positive-number': item.balance > 0 }"
                >
                  {[{ item.balance || 0 | number:2}]}
                </span>
              `,
              totalTemplate: `
                <span
                  ng-class="{'label negative-number': item.amount < 0,'label positive-number': item.amount > 0 }"
                >
                  {[{ item.amount || 0 | number:2 }]}
                </span>
              `,
            },
            {
              columnName: 'passport',
              title: this.gettext('Passport'),
              class: 'td-left-align',
              cellTemplate: `
                <span>
                  {[{ item.passports_title_list }]}
                </span>
              `,
            },
            {
              columnName: 'ownerBank',
              title: this.gettext('Owner bank'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.owner_bank_name">
                  {[{ item.owner_bank_name }]}, {[{ item.owner_account_name }]}
                </span>
              `,
            },
            {
              columnName: 'clientBank',
              title: this.gettext('Client bank'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.client_bank_name">
                  {[{ item.client_bank_name }]}, {[{ item.client_account_name }]}
                </span>
              `,
            },
            {
              columnName: 'invoicepositionUse',
              title: this.gettext('Use'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.invoiceposition_use">
                  {[{ item.invoiceposition_use }]}
                </span>
              `,
            },
            {
              columnName: 'invoicepositionQuantity',
              title: this.gettext('Quantity'),
              class: 'td-left-right',
              cellTemplate: `
                <span ng-if="item.invoiceposition_use == 'cargo'">
                  {[{ item.invoiceposition_quantity || 0 | number:3 }]}
                </span>
                <span ng-if="item.invoiceposition_use != 'cargo'">
                  {[{ item.invoiceposition_quantity || 0 | number:0 }]}
                </span>
              `,
            },
            {
              columnName: 'contractConclusionDate',
              title: this.gettext('Contract conclusion date'),
              class: 'td-left-right',
              cellTemplate: `
                <span ng-if="item.contract_conclusion_date">
                  <i class="fa fa-calendar"></i> {[{ item.contract_conclusion_date || "---"
                  | date:'dd.MM.yy'}]}
                </span>
              `,
            },
            {
              columnName: 'invoiceOwnerName',
              title: this.gettext('Owner name'),
              class: 'td-left-right',
              cellTemplate: `
                <span ng-if="item.invoice_owner_name">
                  {[{ item.invoice_owner_name }]}
                </span>
              `,
            },
          ],
        };
      }
    },
  ],
};
