import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './loyalty-program-modal.html?raw';

export const LoyaltyProgramModal = {
  bindings: {
    modalInstance: '<',
    item: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'LoyaltyProgramsService',
    'AccountsService',
    'FinancesService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      AccountsService: any;
      FinancesService: FinancesService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      LoyaltyProgramsService: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      item: any;
      logEntries: any;
      logEntriesCount = 0;
      modalInstance: any;
      newMonth: any;
      program: any;
      tab: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        LoyaltyProgramsService: any,
        AccountsService: any,
        FinancesService: FinancesService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.LoyaltyProgramsService = LoyaltyProgramsService;
        this.AccountsService = AccountsService;
        this.FinancesService = FinancesService;
        this.form = undefined;
        this.fields = [];
        this.tab = 'edit';
        this.newMonth = { contract_option_group: null };
      }

      $onInit() {
        this.program = this.item || { positions: [] };
        if (this.program.id) {
          this.updateData();
        } else {
          this.updateFields();
        }
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.LoyaltyProgramsService.getModalData({ id: this.program.id }).then(
          (data: any) => {
            this.program = data;
            this.updateFields();
            this.updateLogEntries();
            this.GtUtils.overlay('hide');
          },
        );
      }

      updateLogEntries() {
        return this.AccountsService.LogEntry.query(
          {
            object_id: this.program.id,
            content_type: this.program.content_type,
          },
          (data: { results: any; count: number }) => {
            this.logEntries = data.results;
            this.logEntriesCount = data.count;
          },
        );
      }

      addPosition() {
        const newPosition: any = {
          contract_option: null,
          price_currency: this.FinancesService.getDefaultCurrency().id,
          premium_currency: this.FinancesService.getLocalCurrency().id,
          months: [],
        };
        const dateRange = this.LoyaltyProgramsService.getDateRange(
          this.program.start_date || new Date(),
          this.program.end_date || new Date(),
        );
        dateRange.forEach((date: any) =>
          newPosition.months.push({
            contract_option_group: null,
            month: new Date(date.key),
          }),
        );
        this.program.positions.push(newPosition);
      }

      addMonth(position: any) {
        position.months.push({ contract_option_group: null });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        return this.LoyaltyProgramsService.delete(this.program).then((data: any) => {
          if (data == 'delete') {
            this.close('delete', true);
          }
          return data;
        });
      }

      save() {
        if (!this.program.id) {
          this.clearMonthIds();
        }
        return this.LoyaltyProgramsService.save(this.program).then(
          (data: any) => {
            this.GtUtils.notify(this.gettext('LoyaltyProgram saved.'));
            this.modalInstance.close(data || 'close');
          },
          (data: any) => {
            this.program.errors = data.data;
            this.GtUtils.errorClb(data);
          },
        );
      }

      clearMonthIds() {
        this.program.positions.forEach((pos: any) => {
          pos.months.forEach((month: any) => {
            delete month.id;
          });
        });
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col3: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };
        const col4: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Title'),
          },
          fieldGroup: [
            {
              key: 'title',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Title'),
                required: true,
              },
            },
            {
              key: 'program_type',
              type: 'gt-select',
              defaultValue: 'absolute_value',
              templateOptions: {
                label: this.gettext('Program type'),
                placeholder: this.gettext('Choose program type'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: this.gettext('Absolute value'), value: 'absolute_value' },
                  { name: this.gettext('Percentage'), value: 'percentage' },
                ],
              },
            },
            {
              key: 'is_main',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Main program'),
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Start'),
          },
          fieldGroup: [
            {
              key: 'start_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Start date'),
                required: true,
                startView: 'month',
                minView: 'month',
              },
            },
          ],
        });

        col3.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('End'),
          },
          fieldGroup: [
            {
              key: 'end_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('End date'),
                required: true,
                startView: 'month',
                minView: 'month',
              },
            },
          ],
        });

        col4.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('Currency Exchange'),
          },
          fieldGroup: [
            {
              key: 'currency_exchange',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Exchange rate'),
                resource: 'finances.CurrencyExchange',
                queryParams: () => {
                  return { currency: this.$rootScope.user.settings.LOCAL_CURRENCY };
                },
                required: true,
              },
            },
          ],
        });

        return {
          formName: 'loyalty-program-edit-modal',
          fieldsDef: [col1, col2, col3, col4],
        };
      }
    },
  ],
};
