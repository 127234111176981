import type ng from 'angular';

import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './statistics-report-tree-view.html?raw';

export const StatisticsReportTreeView = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<?',
    data: '<',
    choices: '<',
  },
  template,
  controller: [
    'gettext',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      choices: any;
      data: any;
      gettext: ng.gettext.gettextFunction;
      hideAmounts: any;
      hideContracts: any;
      hideDiffs: any;
      hidePurchase: any;
      hideVolumes: any;
      initQueryParams: QueryParams = {};
      showAllCtrDetail: any;
      theadConfig: any;
      constructor(gettext: ng.gettext.gettextFunction, $rootScope: GtRootScopeService) {
        this.gettext = gettext;
        this.hideContracts = true;
        this.hideVolumes = false;
        this.hideAmounts = false;
        this.hideDiffs = false;
        this.theadConfig = {};
        this.$rootScope = $rootScope;
      }

      $onInit() {
        this.hideContracts = !this.initQueryParams.contract_type;
        if (this.initQueryParams.contract_type === 'sale') {
          this.hidePurchase = true;
        }
      }

      $onChanges(changes: any) {
        if (changes.data) {
          this.updateTable();
        }
      }

      updateTable() {
        this.theadConfig = this.getTheadConfig();
      }
      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };
        // @ts-ignore
        config.columnSets = [
          {
            groupTitle: this.gettext('Groups'),
            group: 'groups',
            icons: ['fa-pie-chart', ...this.choices.map((v: any) => v.icon)],
          },
          {
            groupTitle: this.gettext('Details'),
            group: 'details',
            hide: this.hideContracts,
            icon: 'fa-info-circle',
          },
          {
            groupTitle: this.gettext('Volumes'),
            hide: this.hideVolumes,
            group: 'volumes',
            icon: 'fa-wheat-awn',
          },
          {
            group: 'amounts',
            groupTitle: this.gettext('Amounts'),
            hide: this.hideAmounts,
            icon: 'fa-money',
          },
          {
            group: 'diffs',
            groupTitle: this.gettext('Diffs'),
            hide: this.hideDiffs,
            icon: 'fa-wheat-awn',
          },
        ];
        config.columns = [
          {
            title: this.gettext('Counterparty'),
            columnName: 'group_data',
            predicate: 'group_data',
            group: 'groups',
            class: '',
          },
        ];
        if (!this.hideContracts || this.showAllCtrDetail) {
          config.columns.push(
            {
              group: 'details',
              title: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
              columnName: 'passport',
              class: 'passport',
              icon: 'fa-exchange',
            },
            {
              group: 'details',
              title: this.gettext('Delivery'),
              columnName: 'executionPeriod',
              class: 'dates',
            },
            {
              group: 'details',
              title: this.gettext('Commodity'),
              columnName: 'commodity',
              class: 'product',
            },
            {
              group: 'details',
              title: this.gettext('Counterparty'),
              columnName: 'counterparty',
              class: 'counterparty',
            },
            {
              group: 'details',
              title: this.gettext('Price'),
              columnName: 'price',
              class: 'price',
            },
          );
        } else {
          config.columns.push({
            group: 'details',
            title: this.gettext('Details'),
          });
        }
        if (!this.hideVolumes) {
          config.columns.push(
            {
              group: 'volumes',
              title: this.gettext('Volume'),
              columnName: 'trueVolume',
              predicate: 'true_volume',
            },
            {
              group: 'volumes',
              title: this.gettext('Executed qty'),
              columnName: 'executedQty',
              predicate: 'transport_open_balance_volume',
            },
            {
              group: 'volumes',
              title: this.gettext('Open balance'),
              columnName: 'openBalance',
              predicate: 'open_balance_volume',
            },
            {
              group: 'volumes',
              title: this.gettext('On road'),
              columnName: 'onRoad',
              predicate: 'transport_volume_on_road',
            },
            {
              group: 'volumes',
              title: this.gettext('Received'),
              columnName: 'ReceivedQty',
              predicate: 'transport_volume_at_port',
            },
            {
              group: 'volumes',
              title: this.gettext('Invoiced volume'),
              columnName: 'paidVolume',
              predicate: 'invoiced_volume',
            },
            {
              group: 'volumes',
              title: this.gettext('Paid volume'),
              columnName: 'paidVolume',
              predicate: 'payment_volume',
            },
          );
        }
        if (!this.hideAmounts) {
          config.columns.push(
            {
              group: 'amounts',
              title: this.gettext('Total value'),
              columnName: 'totalValue',
              predicate: 'total_value_local',
              totals_predicate: 'total_value',
            },
            {
              group: 'amounts',
              title: this.gettext('Value of goods'),
              columnName: 'valueOfGoods',
              predicate: 'transport_open_balance_value_local',
              totals_predicate: 'transport_open_balance_value',
            },
            {
              group: 'amounts',
              title: this.gettext('Open balance amount'),
              columnName: 'openBalanceAmount',
              predicate: 'open_balance_value_local',
              totals_predicate: 'open_balance_value',
            },
            {
              group: 'amounts',
              title: this.gettext('On road amount'),
              columnName: 'onRoadAmount',
              predicate: 'transport_value_on_road_local',
              totals_predicate: 'transport_value_on_road',
            },
            {
              group: 'amounts',
              title: this.gettext('Invoiced'),
              columnName: 'invoicedValue',
              predicate: 'invoiced_value_local',
              totals_predicate: 'invoiced_value',
            },
            {
              group: 'amounts',
              title: this.gettext('Paid'),
              columnName: 'Paid',
              predicate: 'payment_value_local',
              totals_predicate: 'payment_value',
            },
          );
        }
        if (!this.hideDiffs) {
          config.columns.push(
            {
              group: 'diffs',
              title: this.gettext('Executed - Paid'),
              columnName: 'paymentStock',
              predicate: 'balance_transport_payment_volume',
              measurement: this.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT,
            },
            {
              group: 'diffs',
              title: this.gettext('Value - invoiced'),
              columnName: 'valueMinusInvoiced',
              predicate: 'not_invoiced_value_local',
              totals_predicate: 'not_invoiced_value',
              measurement: this.$rootScope.user.settings.DEFAULT_CURRENCY,
            },
            {
              group: 'diffs',
              title: this.gettext('Value - paid'),
              columnName: 'valueMinusPaid',
              predicate: 'debit_credit_value_local',
              totals_predicate: 'debit_credit_value',
              measurement: this.$rootScope.user.settings.DEFAULT_CURRENCY,
            },
            {
              group: 'diffs',
              title: this.gettext('Invoiced - paid'),
              columnName: 'invoicedMinusPaid',
              predicate: 'balance_value_local',
              totals_predicate: 'balance_value',
              measurement: this.$rootScope.user.settings.DEFAULT_CURRENCY,
            },
          );
        }

        // @ts-ignore
        config.columnSets.forEach((item: any) => {
          item.count = config.columns.filter((v: any) => v.group === item.group).length;
        });

        return config;
      }

      updateVisibility(row: any) {
        row.expand = !row.expand;
        this.data
          .filter((v: any) => {
            return v.parentIndex == row.index;
          })
          .forEach((v: any) => {
            v.show = row.expand;
          });
        this.hideContracts =
          this.data.filter((v: any) => v.level == 'details' && v.show).length == 0;
        this.updateTable();
      }
    },
  ],
};
