import '@univerjs/design/lib/index.css';
import '@univerjs/ui/lib/index.css';
import '@univerjs/docs-ui/lib/index.css';
import '@univerjs/sheets-ui/lib/index.css';

import type { UnitModel } from '@univerjs/core';
import type { FUniver } from '@univerjs/facade';
import React from 'react';

import { deepObjectsEqual } from '~/shared/lib/utils';

import { destroySpreadsheet, filterUniqueCellData, getData, initSpreadsheet } from './helpers';
import type { CellData, MatrixPrimitive, SpreadsheetDataDef } from './types';

export const Spreadsheet = React.forwardRef(
  (
    {
      data,
      onEdit,
    }: {
      data: SpreadsheetDataDef;
      onEdit?: (workBookData: MatrixPrimitive<CellData>) => void;
    },
    ref,
  ) => {
    const spreadsheetRef = React.useRef<FUniver | null>(null);
    const workbookRef = React.useRef<UnitModel<object, number> | null>(null);
    const containerRef = React.useRef(null);

    const [activeWorkBookData, setActiveWorkBookData] = React.useState<SpreadsheetDataDef>(data);

    const getActiveWorkBookData = React.useCallback(() => {
      const activeWorkbook = spreadsheetRef?.current?.getActiveWorkbook();
      return activeWorkbook?.save();
    }, []);

    React.useImperativeHandle(ref, () => ({
      getActiveWorkBookData,
      getData,
    }));

    React.useEffect(() => {
      initSpreadsheet({ containerRef, spreadsheetRef, data });

      return () => {
        destroySpreadsheet({ spreadsheetRef, workbookRef });
      };

      // temprorary solution to avoid infinite rerenders
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      if (containerRef.current) {
        const mutationObserver = new MutationObserver(() => {
          const newWorkBookData = getActiveWorkBookData();
          const newCellData = newWorkBookData?.sheets[newWorkBookData.sheetOrder[0]].cellData;
          const cellData = data?.sheets[data.sheetOrder[0]].cellData;

          if (
            deepObjectsEqual({ obj1: newWorkBookData, obj2: activeWorkBookData }) &&
            newCellData &&
            cellData
          ) {
            setActiveWorkBookData(newWorkBookData);
            const updatedCellData = filterUniqueCellData({ newData: newCellData, data: cellData });
            onEdit?.(updatedCellData);
          }
        });

        mutationObserver.observe(containerRef.current, {
          attributes: true,
          childList: true,
          subtree: true,
        });

        return () => mutationObserver.disconnect();
      }
    }, [data, activeWorkBookData, getActiveWorkBookData, onEdit]);

    return <div ref={containerRef} className="h-[89vh] w-full overflow-hidden" />;
  },
);

Spreadsheet.displayName = 'Spreadsheet';
