import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('consignmentsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      tableName: '<?',
      showExportButton: '<?',
      contract: '<?',
      globalAddButton: '<',
      readonly: '<',
    },
    template: require('./consignments-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'ContractsService',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'DisbursementBlService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ContractsService: ContractsService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    DisbursementBlService: any,
  ) {
    const vm = this;
    vm.initQueryParams = vm.initQueryParams || { page: 1, page_size: 25 };
    vm.consignments = [];
    vm.consignmentsCount = 0;
    vm.totals = {};
    vm.openConsignmentsModal = openConsignmentsModal;
    vm.createDisbursementBl = createDisbursementBl;
    vm.contract = vm.contract || {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'consignments-container';
      vm.tableName = vm.tableName || vm.filterLevel;
      vm.showExportButton = vm.showExportButton || false;
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });

      $scope.$on('consignments-list__remove', function (ev: any, data: any) {
        remove(data);
      });
      $scope.$on('consignments-list__update', function () {
        updateData();
      });
      $scope.$on('dbl-updated', function () {
        updateData();
      });
      updateData();
    };

    ////////////////

    function updateData() {
      return ContractsService.Consignments.info(
        {
          ...vm.queryParams,
        },
        function (data: any) {
          $scope.$emit('consignments-container__updated', data);
          vm.consignments = data.results;
          vm.consignmentsCount = data.count;
          ContractsService.Consignments.totals(vm.queryParams, function (data: any) {
            vm.totals = data;
          });
        },
      ).$promise;
    }

    function remove(consignment: any) {
      return ContractsService.Consignments.delete(
        {
          id: consignment.id,
        },
        function () {
          GtUtils.notify(gettext('Consignment removed'));
          return updateData();
        },
      ).$promise;
    }

    function openConsignmentsModal(consignment: any) {
      consignment = consignment || { ...vm.initQueryParams };

      consignment.contract = vm.initQueryParams.contract;
      consignment.warehouse = vm.contract?.warehouse;

      return ContractsService.consignmentsModal(consignment).then(updateData);
    }

    function createDisbursementBl(consignment: any) {
      return DisbursementBlService.createDisbursementBL([consignment]).then(() => {
        $scope.$emit('dbl-updated');
      });
    }
  }
})();
