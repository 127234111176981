import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation',
)({
  component: () => (
    <div>
      Hello /_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/reconciliation!
    </div>
  ),
});
