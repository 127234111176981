import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './bank-transaction-container.html?raw';

export const BankTransactionContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$scope',
    'BankTransactionService',
    'GtUtils',
    'gtFilterService',
    class {
      $scope: ng.IScope;
      BankTransactionService: any;
      GtUtils: GtUtilsService;
      filterLevel = 'bank-transaction-container';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      total: any;
      transactions: any;
      constructor(
        $scope: ng.IScope,
        BankTransactionService: any,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.BankTransactionService = BankTransactionService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
      }

      $onInit() {
        this.transactions = [];
        this.total = {};
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTableData();
          this.updateTotals();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        return this.BankTransactionService.getBankTransactionTableData(this.queryParams).then(
          ({ results }: any) => {
            this.transactions = results;
            this.GtUtils.overlay('hide');
          },
        );
      }

      updateTotals() {
        this.BankTransactionService.getBankTransactionTotals(this.queryParams).then(
          (total: any) => {
            this.total = total;
          },
        );
      }

      openTransactionModal(transaction: any) {
        return this.BankTransactionService.bankTransactionModal(transaction).then(() => {
          this.updateTableData();
          this.updateTotals();
        });
      }

      applyFilters(params: any) {
        return this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }
    },
  ],
};
