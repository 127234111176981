import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './driver-modal.html?raw';

export const DriverModal = {
  bindings: {
    modalInstance: '<',
    driver: '<?',
    extra: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$state',
    'gettext',
    'GtUtils',
    'CoreService',
    'FormFieldParamsService',
    'LogisticsService',
    'DriversService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      gettext: ng.gettext.gettextFunction;
      GtUtils: GtUtilsService;
      CoreService: CoreService;
      FormFieldParamsService: FormFieldParamsService;
      LogisticsService: any;
      DriversService: any;

      modalInstance: any;
      driver: any;
      extra: any;

      fields: any;
      form: any;
      tab: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        CoreService: CoreService,
        FormFieldParamsService: FormFieldParamsService,
        LogisticsService: any,
        DriversService: any,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.CoreService = CoreService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.LogisticsService = LogisticsService;
        this.DriversService = DriversService;

        this.tab = 'edit';
        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.driver = this.driver || this.initNewDriver();
        this.extra = this.extra || {};
        this.tab = this.extra.tab || 'edit';
        if (this.driver.id) {
          this.updateData();
        } else {
          this.getContentType();
          this.updateFields();
        }
      }

      updateData() {
        this.GtUtils.overlay('show');

        return this.DriversService.getDriver(this.driver.id)
          .then((data: any) => {
            this.driver = data;
            this.updateFields();
            this.GtUtils.overlay('hide');
          })
          .catch(() => {
            this.GtUtils.overlay('hide');
            this.GtUtils.notify(this.gettext("Driver doesn't exist"));
            this.$state.go('drivers.list');
          });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (!confirm(this.gettext("Are you sure that you want delete 'Driver'?"))) {
          return;
        }
        return this.DriversService.deleteDriver(this.driver.id).then(() => {
          this.GtUtils.notify(this.gettext('Driver removed'));
          this.close('delete', true);
        });
      }

      save() {
        return this.DriversService.saveDriver(this.driver).then(
          (data: any) => {
            this.GtUtils.overlay('hide');
            this.close(data, true);
          },
          (e: any) => this.GtUtils.errorClb(e),
        );
      }

      initNewDriver() {
        return { ...this.driver };
      }

      getContentType() {
        this.CoreService.getModelContentType('driver').then((data: any) => {
          this.driver.content_type = data.id;
        });
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const classes = 'form-group-container col-xs-12 col-sm-8 center-block';
        const fieldsConfig: any = {
          formName: 'driver-modal',
          fieldsDef: [{ className: classes, fieldGroup: [] }],
        };
        fieldsConfig.fieldsDef[0].fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: 'INFO',
          },
          fieldGroup: [
            {
              key: 'first_name',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('First name'),
                type: 'text',
              },
            },
            {
              key: 'last_name',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Last name'),
                type: 'text',
              },
            },
            {
              key: 'middle_name',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Middle name'),
                type: 'text',
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Business unit'),
                placeholder: this.gettext('Business Unit'),
                resource: 'core.BusinessUnit',
              },
            },
            {
              key: 'ukrainian_id',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Driver passport seria & number (Ukrainian)'),
                type: 'text',
              },
            },
            {
              key: 'passport_number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Driver passport seria & number'),
                type: 'text',
              },
            },
            {
              key: 'license_expire_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('License expiration date'),
                placeholder: this.gettext('License expiration date'),
              },
            },
            {
              key: 'passport_expire_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Passport expiration date'),
                placeholder: this.gettext('Passport expiration date'),
              },
            },
            {
              key: 'predictions_order',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Predictions order'),
                type: 'number',
              },
            },
          ],
        });

        return fieldsConfig;
      }
    },
  ],
};
