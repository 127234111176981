import React from 'react';

import { cn } from '~/shared/lib/utils';

import { LocalSearch } from '../../local-search';
import { PageNavigation } from '../page-navigation';
import type { SidebarNestedMenuDetails } from '../types';

export const LocalSearchItem: React.FC<{ item: SidebarNestedMenuDetails }> = ({ item }) => {
  return (
    <div className="ml-8">
      <PageNavigation href={item.href} isActive={item.isActive} label={item.label} />
      {item.isActive && (
        <div
          className={cn(
            'relative',
            item.isActive &&
              'after:absolute after:left-6 after:top-0 after:h-full after:w-[1px] after:bg-transparent-strong',
          )}
        >
          <LocalSearch />
        </div>
      )}
    </div>
  );
};
