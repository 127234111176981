import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './user-settings-container.html?raw';

export const UserSettingsContainer = {
  bindings: { userId: '<' },
  template,
  controller: [
    '$scope',
    '$upload',
    'GtUtils',
    'gettext',
    'AccountsService',
    'CoreService',
    class {
      $scope: ng.IScope;
      $upload: any;
      AccountsService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      currentPassword: any;
      defaultBisnessUnit: any;
      errors: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      newPassword: any;
      newPasswordConfirm: any;
      user: any;
      userId: any;
      constructor(
        $scope: ng.IScope,
        $upload: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        AccountsService: any,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.$upload = $upload;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
        this.filterLevel = 'user-settings-container';
      }

      $onInit() {
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.AccountsService.User.get(
          {
            id: this.userId,
          },
          (data: any) => {
            this.user = data;
            this.defaultBisnessUnit =
              this.user.profile.business_units
                .filter((bu: any) => bu.is_default)
                .map((bu: any) => bu.businessunit)
                .shift() || null;
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          },
        );
      }

      changePreferences(newPrefsObj: any) {
        this.GtUtils.overlay('show');
        this.AccountsService.UserSelf.update(
          {
            id: this.user.id,
            profile: newPrefsObj,
          },
          (data: any) => {
            this.user.profile = data.profile;
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          },
        );
      }

      saveDefaultBu() {
        this.user.profile.business_units.forEach((bu: any) => {
          if (bu.businessunit == this.defaultBisnessUnit) {
            bu.is_default = true;
          } else {
            bu.is_default = false;
          }
        });

        this.changePreferences({ business_units: this.user.profile.business_units });
      }

      changePassword() {
        this.GtUtils.overlay('show');
        this.AccountsService.UserSelf.changePassword(
          {
            id: this.user.id,
            current: this.currentPassword,
            new: this.newPassword,
            confirm: this.newPasswordConfirm,
          },
          (data: any) => {
            this.GtUtils.notify(data.msg);
            this.currentPassword = '';
            this.newPassword = '';
            this.newPasswordConfirm = '';
            this.GtUtils.overlay('hide');
          },
          (data: any) => {
            this._error(data);
            this.GtUtils.overlay('hide');
          },
        );
      }

      fileSelect(files: FileList) {
        if (files[0]?.size > 1048576) {
          this.GtUtils.notify(this.gettext('single document size up to 1 mb'), 'error');
          this.errors = true;
          return;
        }
        this.user.profile.avatar = files[0];
        this.errors = false;
      }

      saveAttachment() {
        return this.$upload
          .upload({
            url: '/api/accounts/users/self/' + this.user.id + '/update_avatar/',
            file: this.user.profile.avatar,
          })
          .then(this.updateData());
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.user.errors = data.data;
      }
    },
  ],
};
