import type ng from 'angular';
export const GtDraggableArea = () => {
  return {
    restrict: 'A',
    link($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: any) {
      function isJson(str: string) {
        try {
          JSON.parse(str);
        } catch {
          return false;
        }
        return true;
      }
      $element[0].ondrop = ($event: any) => {
        let data: any;
        if (isJson($event.dataTransfer.getData('text/plain'))) {
          data = JSON.parse($event.dataTransfer.getData('text/plain'));
        } else {
          data = parseInt($event.dataTransfer.getData('text/plain'), 10);
        }
        $scope.$apply(() => {
          $scope.$eval($attrs.dropped, { position: data });
        });
        $event.target.style.transform = 'scale(1)';
      };
      $element[0].ondragover = ($event: any) => {
        $event.preventDefault();
        $event.target.style.transform = 'scale(0.9)';
      };
      $element[0].ondragleave = ($event: any) => {
        $event.preventDefault();
        $event.target.style.transform = 'scale(1)';
      };
    },
  };
};
