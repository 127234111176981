import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('creditNotesContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./credit-notes-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$filter',
    'GtUtils',
    'FinancesService',
    'gtFilterService',
    'gettext',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $filter: ng.IFilterService,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.gridOptions = {};

    vm.openCreditNoteModal = openCreditNoteModal;
    vm.creditNotes = [];
    vm.creditNotesCount = 0;
    vm.updateCreditNotes = updateCreditNotes;
    vm.newCreditNote = {};
    vm.creditNotesTotal = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'credit-notes-container';
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateCreditNotes();
      });
      updateCreditNotes();
    };

    vm.$onChanges = function () {
      vm.queryParams = {
        serializer: 'table_info',
        ...vm.initQueryParams,
      };
    };

    ////////////////

    function updateCreditNotes() {
      GtUtils.overlay('show');
      vm.newCreditNote = {
        condition: 'prepay',
        use: 'cargo',
        ...vm.queryParams,
      };
      return FinancesService.CreditNote.query(vm.queryParams, function (data: any) {
        if (vm.queryParams.next) {
          vm.creditNotes = vm.creditNotes.concat(data.results);
          delete vm.queryParams.next;
        } else {
          vm.creditNotes = data.results;
        }
        vm.creditNotesCount = data.count;
        GtUtils.overlay('hide');
        updateGridData();
      }).$promise;
    }

    function openCreditNoteModal(creditNote: any) {
      if (creditNote) {
        return FinancesService.creditNoteModal(creditNote).then(updateCreditNotes);
      }
      return FinancesService.creditNoteModal(vm.newCreditNote).then(updateCreditNotes);
    }

    function updateGridData() {
      vm.gridOptions = {
        data: [],
        enableSorting: false,
        columnDefs: [
          {
            field: 'number',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate:
              '<a class="btn btn-xs btn_link" style="margin-top:3px;" ng-click="grid.appScope.vm.openCreditNoteModal(row.entity)">\
              <i class="fa fa-columns"></i> {{COL_FIELD}}\
            </a>',
          },
          {
            field: 'issued',
            enableColumnMenu: false,
            enableSorting: false,
            cellFilter: 'date:" HH:mm dd/MM"',
            minWidth: 60,
          },
          {
            field: 'paid',
            enableColumnMenu: false,
            enableSorting: false,
            cellFilter: 'date:" HH:mm dd/MM"',
            minWidth: 60,
          },
          {
            field: 'status',
            displayName: gettext('status'),
            enableColumnMenu: false,
            enableSorting: false,
            cellClass: 'status-cell',
            cellTemplate:
              "<span class=\"label\" ng-class=\"{ 'label_success': row.entity.status=='new'," +
              "'label_warning': row.entity.status=='process'," +
              "'label_default': row.entity.status=='paid' }\">{{COL_FIELD}}</span>",
          },
          {
            field: 'client_name',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate:
              '<span class="tooltip" data-tip="{{COL_FIELD}}">\
                            {{COL_FIELD}}\
                          </span>',
          },
          {
            field: 'amount',
            enableColumnMenu: false,
            enableSorting: false,
            minWidth: 60,
            cellTemplate:
              "<span class=\"label\" ng-class=\"{ 'positive-number': row.entity.invoice_type=='outgoing'," +
              "'negative-number': row.entity.invoice_type=='incoming'}\">\
                            {{COL_FIELD | number: 2}}\
                          </span>",
          },
          {
            field: 'invoices',
            enableColumnMenu: false,
            enableSorting: false,
            minWidth: 60,
            cellTemplate:
              '<span class="label" ng-repeat="invoice in COL_FIELD">\
            # {{invoice.number}}: ${{ invoice.amount_USD || 0 | number:2}}\
            <div class="clearfix"></div>\
            </span>',
          },
          {
            field: 'amount_left',
            enableColumnMenu: false,
            enableSorting: false,
            minWidth: 60,
            cellTemplate:
              '<span class="label">\
                            {{COL_FIELD | number:2}}\
                          </span>',
          },
          {
            field: 'editor',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate:
              '<div class="user-avatar" ng-if="COL_FIELD.length != 0">\
                            <img ng-src="/pictures/{{COL_FIELD}}"/>\
                          </div>\
                          <div class="no-user-avatar" ng-if="COL_FIELD.length == 0">\
                            {{ row.entity.editorName | cut:true:1:" " }}\
                          </div>',
          },
          {
            field: 'edited',
            enableColumnMenu: false,
            enableSorting: false,
            cellFilter: 'date:" HH:mm dd/MM"',
            minWidth: 60,
          },
        ],
      };
      vm.creditNotes.forEach(function (creditNote: any) {
        vm.gridOptions.data.push({
          id: creditNote.id,
          status: creditNote.status,
          invoice_type: creditNote.invoice_type,
          amount: Math.round(creditNote.amount_USD * 100) / 100,
          number: creditNote.number,
          editor: creditNote.editor_avatar,
          editorName: creditNote.editor_first_name,
          edited: creditNote.update_time,
          client_name: creditNote.client_name,
          issued: creditNote.date,
          paid: creditNote.date_of_execution_fact,
          invoices: creditNote.invoices,
          amount_left: creditNote.amount_left,
        });
      });
    }
  }
})();
