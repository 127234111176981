import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('consolidatedPhysicalPositionContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      onlyPnl: '&?',
      convert: '<?',
      currency: '<?',
      sourcePrice: '<?',
      marketPriceType: '<?',
    },
    template: require('./consolidated-physical-position-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'ContractsService',
    'ConsolidatedPnlService',
    'GtUtils',
    'gtFilterService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ContractsService: ContractsService,
    ConsolidatedPnlService: any,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;

    vm.buList = [];
    vm.warehouseList = [];
    vm.cargoList = [];
    vm.monthList = [];
    vm.grouppings = ['businessUnit', 'warehouse', 'businessUnitUnrealized', 'warehouseUnrealized'];
    vm.groupsSettings = {
      businessUnit: () => vm.buList,
      warehouse: () => vm.warehouseList,
      businessUnitUnrealized: () => vm.buUnrealizedList,
      warehouseUnrealized: () => vm.warehouseUnrealizedList,
    };
    vm.groupBy = vm.grouppings[0];
    vm.unrealizedGroupBy = vm.grouppings[2];

    vm.getCargoDataByMonth = getCargoDataByMonth;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'consolidated-physical-position-container';
      vm.queryParams = {
        page_size: 20,
        ...vm.initQueryParams,
      };
      vm.withoutEndOfExecution = new Date();
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = { ...vm.queryParams, ...data };
        updateData();
      });

      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    function updateData() {
      GtUtils.overlay('show');
      getConsolidatedRiskPosition();
      getConsolidatedPosition();
      GtUtils.overlay('hide');
    }

    function filterZeroCropList(cropList: any, fieldToCheck: any, cropGroupedType = 'bu') {
      if (!cropList) {
        return [];
      }
      return cropList
        .map((item: any) => {
          const cropList = item.crop_list.filter((crop: any) => crop[fieldToCheck] != 0);

          if (cropList.length) {
            if (cropGroupedType === 'bu') {
              return {
                id: item.business_unit_id,
                title: item.business_unit_title,
                cropList: cropList,
                icon: 'fa fa-home',
              };
            }

            return {
              id: item.warehouse_id,
              title: item.warehouse_title,
              cropList: cropList,
              icon: 'fa fa-warehouse',
            };
          }
        })
        .filter((item: any) => !!item);
    }

    function getConsolidatedRiskPosition() {
      ContractsService.Contract.consolidatedRiskPosition(vm.queryParams).$promise.then(
        (data: any) => {
          vm.buList = filterZeroCropList(data.results.bu_list, 'unexecuted');
          vm.warehouseList = filterZeroCropList(
            data.results.warehouse_list,
            'unexecuted',
            'warehouse',
          );
          vm.buUnrealizedList = filterZeroCropList(data.results.bu_list, 'unrealized');
          vm.warehouseUnrealizedList = filterZeroCropList(
            data.results.warehouse_list,
            'unrealized',
            'warehouse',
          );
        },
        (error: any) => GtUtils.errorClb(error),
      );
    }

    function getConsolidatedPosition() {
      ConsolidatedPnlService.getConsolidatedPositionData(vm.queryParams).then(
        (data: any) => {
          vm.cargoList = data.cargo_data;
          vm.monthList = data.month_data.map((item: any) => new Date(item.month));
        },
        (error: any) => GtUtils.errorClb(error),
      );
    }

    function getCargoDataByMonth(month: any, cargo: any) {
      if (month) {
        return vm.cargoList
          .map((item: any) => item.data)
          .map((item: any) =>
            item.find(
              (p: any) =>
                new Date(p.month).getTime() === month.getTime() && p.parent_cargo_id === cargo,
            ),
          )
          .find((item: any) => item);
      }
    }
  }
})();
