import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './loyalty-programs-execution-page-view.html?raw';

export const LoyaltyProgramsExecutionPageView = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    class {
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      constructor(PageService: PageService, ReportsService: any) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;

        this.filterLevel = 'loyalty-programs-execution-page-view';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };
      }
    },
  ],
};
