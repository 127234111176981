import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { CostsReportContainer } from './costs-report-container/costs-report-container.component';
import { CostsReportPage } from './costs-report-page/costs-report-page.component';
import { CostsReportTable } from './costs-report-table/costs-report-table.component';
import { CostsReportService } from './costs-report.service';

const reportConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('reports.costsReport', {
      url: '/costs-report/',
      component: 'costsReportPage',
      data: { pageTitle: gettext('Costs report') },
      resolve: {
        filterLevel: () => 'costs-report-page',
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return {
              is_by_default: 0,
              status_list: ['validated', 'under_discussion', 'pending_confirmation', 'forecast'],
              group_by: 'client,currency,contractcharge',
              order_by: 'currency',
              ...PageService.syncUrlFilter('costs-report-page'),
            };
          },
        ],
      },
    });
  },
];

export const costsReport = ng
  .module('reports.costsReport', [])
  .service('CostsReportService', CostsReportService)
  .component('costsReportPage', CostsReportPage)
  .component('costsReportContainer', CostsReportContainer)
  .component('costsReportTable', CostsReportTable)
  .config(reportConfig).name;
