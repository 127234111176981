import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import { getModalRoot } from '~/shared/ui/modal';

export class BankTransactionService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  BankTransactionOperationResource: any;
  BankTransactionResource: any;
  CoreService: CoreService;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    CoreService: CoreService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.CoreService = CoreService;
    this.BankTransactionResource = $resource(
      '/api/finances/bank-transaction/:id/',
      {
        id: '@id',
      },
      {
        get: {
          method: 'GET',
        },
        save: {
          method: 'POST',
        },
        update: {
          method: 'PATCH',
        },
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-transactions-list/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-transaction/predictions/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-transactions-totals/',
        },
      },
    );
    this.BankTransactionOperationResource = $resource(
      '/api/finances/bank-transaction-operations/:id/',
      {
        id: '@id',
      },
      {
        save: {
          method: 'POST',
        },
        update: {
          method: 'PATCH',
        },
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-transaction-operations-list/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-transaction-operations/predictions/',
        },
        predictionsDetails: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-transaction-operations/:id/predictions_details/',
        },
      },
    );
  }

  bankTransactionModal(transaction: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <bank-transaction-modal transaction="transaction" modal-instance="$uibModalInstance">
        </bank-transaction-modal>`,
      controller: [
        '$scope',
        'transaction',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          transaction: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).transaction = transaction;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        transaction: () => transaction,
      },
    }).result;
  }

  getBankTransaction(id: any) {
    return this.BankTransactionResource.get({ id }).$promise;
  }

  save(item: any) {
    if (item.id) {
      return this.BankTransactionResource.update(item).$promise;
    }
    return this.BankTransactionResource.save(item).$promise;
  }

  deleteBankTransaction(id: any) {
    return this.BankTransactionResource.delete({ id }).$promise;
  }

  getBankTransactionTableData(params: any) {
    return this.BankTransactionResource.query(params).$promise;
  }

  getBankTransactionTotals() {
    return this.BankTransactionResource.totals().$promise;
  }
}
BankTransactionService.$inject = ['$resource', '$uibModal']; //, '$q', 'moment', '$uibModal', 'FinancesService'];
