import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { potentialsPage } from './potentials-page/potentials-page.component';

const potentialsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('market.potentials', {
      url: '/potentials/',
      component: 'potentialsPage',
      data: {
        pageTitle: gettext('Potentials'),
      },
      resolve: {
        filterLevel: () => {
          return 'potentials-page';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('potentials-page');
          },
        ],
      },
    });
  },
];

export const potentials = ng
  .module('market.potentials', [])
  .component('potentialsPage', potentialsPage)
  .config(potentialsConfig).name;
