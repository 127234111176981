import ng from 'angular';

import { AnalyticsContainer } from './analytics-container/analytics-container.component';
import { AnalyticsPageView } from './analytics-page-view/analytics-page-view.component';
import { TransportAnalyticsService } from './analytics.service';

const transportAnalyticsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.analytics', {
      url: '/analytics',
      component: 'transportAnalyticsPageView',
      data: {
        pageTitle: gettext('Analytics'),
      },
    });
  },
];

export const analytics = ng
  .module('execution.transport.analytics', [])
  .component('transportAnalyticsContainer', AnalyticsContainer)
  .component('transportAnalyticsPageView', AnalyticsPageView)
  .service('TransportAnalyticsService', TransportAnalyticsService)
  .config(transportAnalyticsConfig).name;
