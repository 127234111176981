import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('execution.legacy').directive('tariffRow', directive);

  function directive() {
    return {
      template: require('./tariff-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      require: '^^tariffsTable',
      scope: {
        tariff: '<',
        onSave: '&',
        onDelete: '&',
      },
    };
  }

  Controller.$inject = ['$rootScope', 'ClientsService', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    ClientsService: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;
    vm.errors = {};
    vm.openRoleModal = openRoleModal;
    vm.chargeEditQueryParams = {};
    vm.buQueryParams = { user: $rootScope.user.id };

    vm.$onInit = function () {
      vm.edit = !vm.tariff.id;
      if (vm.tariff.elevator && vm.tariff.farm) {
        vm.tariff.elevator_to = vm.tariff.elevator;
      } else if (vm.tariff.elevator) {
        vm.tariff.elevator_from = vm.tariff.elevator;
      }
      vm.tariff.transportation_type = vm.tariff.transportation_type || 'auto';
      vm.chargeEditQueryParams = { tariff_type: vm.tariff.transportation_type };
    };

    function saveTariff() {
      vm.tariff.elevator = vm.tariff.elevator_to || vm.tariff.elevator_from;

      if (vm.tariff.elevator && vm.tariff.farm && vm.tariff.port && vm.tariff.terminal) {
        return GtUtils.notify(gettext('Check point of departure'));
      }

      if (vm.tariff.port && vm.tariff.terminal) {
        return GtUtils.notify(gettext('Check destination'));
      }
      vm.onSave({ tariff: vm.tariff });
    }

    function deleteTariff() {
      vm.onDelete({ tariff: vm.tariff });
    }

    function openRoleModal(role: any) {
      return ClientsService.roleModal(role);
    }
  }
})();
