import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';

  ng.module('deals.contracts.legacy').controller('StageEditController', controllerFunction);

  controllerFunction.$inject = [
    '$uibModalInstance',
    'ContractsService',
    'stage',
    'GtUtils',
    'gettext',
  ];

  function controllerFunction(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    ContractsService: ContractsService,
    stage: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.inProgress = false;
    vm.stage = stage;
    vm.close = close;
    vm.save = save;
    vm.changeSignedDates = changeSignedDates;
    vm.errors = {};

    /////////////

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function save() {
      vm.errors = {};
      vm.inProgress = true;
      return ContractsService.stageResource.update(vm.stage, _saveSuccess, _saveError);
    }

    function _saveSuccess(value: any) {
      vm.inProgress = false;
      GtUtils.notify(gettext('Stage #') + value.title + gettext(' updated'));
      close(value, true);
    }

    function _saveError(errors: any) {
      vm.inProgress = false;
      vm.errors = errors;
    }

    function changeSignedDates(event: any) {
      const allSignedDate = event?.date;

      if (allSignedDate) {
        const dates: any = [
          vm.stage.draft,
          vm.stage.broker_signed,
          vm.stage.we_signed,
          vm.stage.both_signed,
        ];

        const isValid = dates.every((date) => !date || new Date(allSignedDate) >= new Date(date));

        if (!isValid) {
          alert(gettext('The All signed date must be greater than or equal to all other dates.'));
          vm.stage.all_signed = null;
        } else {
          vm.stage.draft = vm.stage.draft || allSignedDate;
          vm.stage.broker_signed = vm.stage.broker_signed || allSignedDate;
          vm.stage.we_signed = vm.stage.we_signed || allSignedDate;
          vm.stage.both_signed = vm.stage.both_signed || allSignedDate;
        }
      }
    }
  }
})();
