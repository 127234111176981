import template from './warehouse-details-view.html?raw';

export const WarehouseDetailsView = {
  bindings: {
    filterLevel: '<?',
    warehouse: '<',
    fields: '<',
    tab: '<',
    openModal: '&',
    changeTab: '&',
  },
  template,
  controller: [
    class {
      balanceParams: any;
      executionTab: any;
      fields: any;
      movementsParams: any;
      warehouse: any;
      constructor() {
        this.fields = [];
        this.warehouse = {};
        this.executionTab = 'logistics';
        this.balanceParams = {};
        this.movementsParams = {};
      }
      $onChanges(changes: any) {
        if (
          changes.warehouse &&
          (changes.warehouse.previousValue.is_group !== changes.warehouse.currentValue.is_group ||
            changes.warehouse.previousValue.id !== changes.warehouse.currentValue.id)
        ) {
          this.updateQueryParams();
        }
      }
      getBalanceParams() {
        if (this.warehouse.is_group) {
          return {
            horizontalGroups: ['warehouse', 'contract_position', 'batch'],
            verticalGroups: ['commodity'],
            warehouse_parent: this.warehouse.id,
          };
        }
        return {
          horizontalGroups: ['contract_position', 'batch'],
          verticalGroups: ['commodity'],
          warehouse: this.warehouse.id,
        };
      }
      getMovementsParams() {
        if (this.warehouse.is_group) {
          return {
            warehouse_parent: this.warehouse.id,
          };
        }
        return {
          warehouse: this.warehouse.id,
        };
      }
      updateQueryParams() {
        this.balanceParams = this.getBalanceParams();
        this.movementsParams = this.getMovementsParams();
      }
    },
  ],
};
