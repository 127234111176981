import * as Icons from './icons';
import type { IconComponent, IconName } from './types';

const isIcon = (icon: IconComponent | object): icon is IconComponent =>
  !!(icon as IconComponent).displayName?.includes('Icon');

export const useIcon = (name: IconName): IconComponent => {
  const icon = Icons[name] as object;

  if (!isIcon(icon)) {
    throw new Error(`Icon "${name}" not found`);
  }

  return icon;
};

export const useAllIcons = (): Record<IconName, IconComponent> => Icons;
