import ng from 'angular';

(function () {
  'use strict';
  ng.module('accounts.legacy').directive('accountsLastChangesBlock', directive);

  function directive() {
    return {
      template: require('./last-changes-block.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {},
    };
  }

  Controller.$inject = ['AccountsService'];

  function Controller(this: any, AccountsService: any) {
    const vm = this;
    vm.logentries = [];
    vm.logentries_count = 0;
    vm.updateData = updateData;
    vm.openLogentryModal = openLogentryModal;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      return AccountsService.LogEntry.query(
        {
          page_size: 15,
        },
        function (data: any) {
          vm.logentries = data.results;
          vm.logentries_count = data.count;
        },
      );
    }

    function openLogentryModal(logentry: any) {
      return AccountsService.logentryModal(logentry);
    }
  }
})();
