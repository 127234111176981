import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './approvals-by-config-container.html?raw';

export const ApprovalsByConfigContainer = {
  bindings: {
    approvableId: '<',
    approvalConfigId: '<',
    disableReactivateButton: '<',
    voted: '&?',
  },
  template: template,
  controller: [
    '$rootScope',
    'GtUtils',
    'AccountsService',
    class {
      $rootScope: GtRootScopeService;
      AccountsService: any;
      GtUtils: GtUtilsService;
      approvableId: any;
      approvalConfigId: any;
      config: any;
      filterLevel: string;
      initialRequest: any;
      levelApprovals: any;
      showArchived: any;
      updating: any;
      voted: any;
      constructor($rootScope: GtRootScopeService, GtUtils: GtUtilsService, AccountsService: any) {
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.AccountsService = AccountsService;

        this.filterLevel = 'approvals-by-config-container';
        this.config = {};
        this.levelApprovals = {};
        this.showArchived = false;
        this.updating = true;
        this.initialRequest = null;
      }

      $onInit() {
        this.loadInitialRequest();
      }

      loadInitialRequest() {
        this.AccountsService.getApprovalsByConfig(this.approvableId, this.approvalConfigId).then(
          (data: any) => {
            this.levelApprovals = data.levelApprovals;
            this.config = data.config;
            this.initialRequest = {
              approvalStatus: this.levelApprovals.main[0]?.approved || false,
            };
            this.updating = false;
          },
        );
      }

      approvalAction(action: any) {
        return this.AccountsService.voteApprovable(
          action,
          this.approvableId,
          null,
          this.config.object_type,
        ).then(() => {
          this.updateDataAfterApproval();
          this.voted?.();
        });
      }

      updateDataAfterApproval() {
        this.AccountsService.getApprovalsByConfig(this.approvableId, this.approvalConfigId).then(
          (updatedData: any) => {
            const updatedApprovalStatus = updatedData.levelApprovals.main[0]?.approved || false;

            if (
              this.$rootScope.user.settings.CONTRACT_AUTO_CREATE_ON_REQUEST_FINAL_APPROVE &&
              !this.initialRequest.approvalStatus &&
              updatedApprovalStatus
            ) {
              this.GtUtils.notify(
                'Final approval has been granted. Automatic contract creation from the ticket is now activated.',
              );
            }

            this.initialRequest.approvalStatus = updatedApprovalStatus;

            this.levelApprovals = updatedData.levelApprovals;
            this.config = updatedData.config;
            this.updating = false;
          },
        );
      }

      approvalResentRequest(approvalId: any) {
        this.AccountsService.approvalResentRequest(approvalId)
          .then((data: any) => {
            this.GtUtils.notify(data.msg);
          })
          .catch((data: any) => {
            this.GtUtils.notify(data.data.errors, 'error');
          });
      }
    },
  ],
};
