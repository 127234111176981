import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { TransportationStorageExpenseContainer } from './transportation-storage-expense/transportation-storage-expense-container/transportation-storage-expense-container.component';
import { TransportationStorageExpenseModal } from './transportation-storage-expense/transportation-storage-expense-modal/transportation-storage-expense-modal.component';
import { TransportationStorageExpensePage } from './transportation-storage-expense/transportation-storage-expense-page/transportation-storage-expense-page.component';
import { TransportationStorageExpenseView } from './transportation-storage-expense/transportation-storage-expense-view/transportation-storage-expense-view.component';
import { TransportationStorageExpenseFormFieldsService } from './transportation-storage-expense-form-fields.service';
import { TransportationStorageExpensePositionForm } from './transportation-storage-expense-positions/transportation-storage-expense-position-form/transportation-storage-expense-position-form.component';
import { TransportationStorageExpensePositionsTable } from './transportation-storage-expense-positions/transportation-storage-expense-positions-table/transportation-storage-expense-positions-table.component';
import { TransportationStorageExpenseService } from './transportation-storage-expense.service';
import { TransportationStorageExpensesContainer } from './transportation-storage-expenses/transportation-storage-expenses-container/transportation-storage-expenses-container.component';
import { TransportationStorageExpensesPage } from './transportation-storage-expenses/transportation-storage-expenses-page/transportation-storage-expenses-page.component';
import { TransportationStorageExpensesView } from './transportation-storage-expenses/transportation-storage-expenses-table/transportation-storage-expenses-table.component';

const config: any = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('logistics.transportationStorageExpense', {
        url: '/transportation-storage-expenses/:id',
        component: 'transportationStorageExpensePage',
        data: { pageTitle: gettext('Transportation Storage Expense') },
        resolve: {
          filterLevel: () => 'transportation-storage-expense-page',
          queryParams: [
            'PageService',
            (PageService: PageService) =>
              PageService.syncUrlFilter('transportation-storage-expense-page'),
          ],
        },
      })
      .state('logistics.transportationStorageExpenses', {
        url: '/transportation-storage-expenses/',
        component: 'transportationStorageExpensesPage',
        data: { pageTitle: gettext('Transportation Storage Expenses') },
        resolve: {
          filterLevel: () => 'transportation-storage-expenses-page',
          queryParams: [
            'PageService',
            (PageService: PageService) =>
              PageService.syncUrlFilter('transportation-storage-expenses-page'),
          ],
        },
      });
  },
];

export const transportationStorageExpenseModule = ng
  .module('execution.transportationStorageExpense', [])
  .service('TransportationStorageExpenseService', TransportationStorageExpenseService)
  .service(
    'TransportationStorageExpenseFormFieldsService',
    TransportationStorageExpenseFormFieldsService,
  )

  .component('transportationStorageExpenseContainer', TransportationStorageExpenseContainer)
  .component('transportationStorageExpensePage', TransportationStorageExpensePage)
  .component('transportationStorageExpenseView', TransportationStorageExpenseView)

  .component('transportationStorageExpensesContainer', TransportationStorageExpensesContainer)
  .component('transportationStorageExpensesPage', TransportationStorageExpensesPage)
  .component('transportationStorageExpensesView', TransportationStorageExpensesView)

  .component(
    'transportationStorageExpensePositionsTable',
    TransportationStorageExpensePositionsTable,
  )
  .component('transportationStorageExpensePositionForm', TransportationStorageExpensePositionForm)

  .component('transportationStorageExpenseModal', TransportationStorageExpenseModal)
  .config(config).name;
