import React from 'react';

import { cn } from '~/shared/lib/utils';

import { LocalSearch } from '../../local-search';
import { NestedMenuItems } from '../nested-menu-items';
import type { SidebarMenuSubItem } from '../types';

export const MenuSubItem: React.FC<{ isOpen: boolean; item: SidebarMenuSubItem }> = ({
  item,
  isOpen,
}) => {
  return (
    <div
      className={cn(
        'relative my-0.5 flex flex-col gap-0.5 text-left',
        isOpen &&
          'after:absolute after:left-[28px] after:top-0 after:h-full after:w-[1px] after:bg-transparent-strong',
      )}
    >
      {item.submenus?.length ? <NestedMenuItems items={item.submenus} /> : <LocalSearch />}
    </div>
  );
};
