import ng from 'angular';

import template from './gt-pagination.tpl.html?raw';
import type { GtFilterService } from '../../gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtPagination', {
    bindings: {
      count: '<',
      filterLevel: '<?',
      updateList: '&?',
      changeSize: '<?',
    },
    template,
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gtFilterService'];

  function Controller(this: any, $scope: ng.IScope, gtFilterService: GtFilterService) {
    const vm = this;

    vm.page = 1;
    vm.pageSize = 25;
    vm.pages = 0;
    vm.rotateNum = 8;
    vm.range = [];
    vm.onPageChange = onPageChange;
    vm.onPageSizeChange = onPageSizeChange;
    vm.pageSizes = [10, 25, 50, 100, 200, 500];

    vm.$onInit = function () {
      if (!vm.filterLevel && !vm.updateList) {
        throw new Error('gt-pagination: filterLevel or updateList is required');
      }
      vm.pageSize = gtFilterService.getQueryParams(vm.filterLevel).page_size ?? 25;
    };

    vm.$onChanges = function (changes: any) {
      $scope.$on('gt-show-more', function (ev: any, data: any) {
        vm.page = data.page || 1;
        vm.pageSize = data.page_size || 25;
        refreshRange();
      });

      if (changes.count) {
        vm.page = gtFilterService.getQueryParams(vm.filterLevel).page ?? 1;
        refreshRange();
      }

      if (changes.pageSize && !changes.pageSize.isFirstChange()) {
        onPageChange(1);
      }

      if (changes.filterLevel) {
        vm.pageSize = gtFilterService.getQueryParams(vm.filterLevel).page_size ?? 25;
        $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
          const needRefresh =
            vm.page === (data.page || 1) || vm.pageSize === (data.page_size || 25);
          if (vm.page !== data.page) {
            vm.page = data.page || 1;
          }
          vm.pageSize = data.page_size || 25;
          if (needRefresh) {
            refreshRange();
          }
        });
      }
    };

    ////////////////

    function onPageChange(page: any) {
      vm.page = (page <= vm.pages && page) || 1;
      refreshRange();
      applyFilter({ page: vm.page });
    }

    function onPageSizeChange() {
      refreshRange();
      applyFilter({ page_size: vm.pageSize, page: undefined });
    }

    function refreshRange() {
      let start = vm.page - vm.rotateNum / 2,
        end = vm.page + vm.rotateNum / 2;

      vm.pages = ~~(vm.count / vm.pageSize);
      if (vm.count % vm.pageSize) {
        vm.pages++;
      }

      vm.range = [];
      start = (start > 2 && start) || 2;
      end = (end < vm.pages - 1 && end) || vm.pages - 1;
      for (let i = start; i <= end; i++) {
        vm.range.push(i);
      }
    }

    function applyFilter(params: any) {
      if (vm.updateList) {
        return vm.updateList({ params: params });
      } else {
        gtFilterService.updateQueryParams(params, vm.filterLevel);
      }
    }
  }
})();
