export class TasksTemplatesService {
  gettext: ng.gettext.gettextFunction;
  constructor(gettext: ng.gettext.gettextFunction) {
    this.gettext = gettext;
  }

  getPageConfig() {
    return [
      {
        title: this.gettext('Tasks templates'),
        permissions: [],
        state: 'company.tasksTemplates',
        icon: 'fa-tasks',
      },
    ];
  }
}
TasksTemplatesService.$inject = ['gettext'];
