import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';

export class StockReserveUnitFormFieldsService {
  FormFieldParamsService: FormFieldParamsService;
  gettext: ng.gettext.gettextFunction;
  constructor(gettext: ng.gettext.gettextFunction, FormFieldParamsService: any) {
    this.gettext = gettext;
    this.FormFieldParamsService = FormFieldParamsService;
  }
  getFormConfig(sru: any, formName: any) {
    return {
      formName: formName || 'stock-reserve-unit-modal',
      fieldsDef: [
        {
          className: 'form-group-container col-sm-4 col-xs-12',
          fieldGroup: [
            {
              wrapper: 'gt-panel',
              templateOptions: { label: this.gettext('MAIN') },
              fieldGroup: [
                {
                  key: 'title',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Title'),
                    type: 'text',
                    required: true,
                  },
                },
                {
                  key: 'start_date',
                  type: 'gt-date-select',
                  defaultValue: new Date(),
                  templateOptions: {
                    label: this.gettext('Start Date'),
                    placeholder: this.gettext('Start Date'),
                    type: 'date',
                    startView: 'day',
                    minView: 'day',
                    required: true,
                  },
                },
                {
                  key: 'end_date',
                  type: 'gt-date-select',
                  defaultValue: new Date(),
                  templateOptions: {
                    label: this.gettext('End Date'),
                    placeholder: this.gettext('Start Date'),
                    required: true,
                    type: 'date',
                    startView: 'day',
                    minView: 'day',
                  },
                },
                {
                  key: 'responsible',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Responsible'),
                    placeholder: this.gettext('Choose responsible'),
                    resource: 'auth.User',
                  },
                },
                {
                  key: 'business_unit',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Business Unit'),
                    placeholder: this.gettext('Choose Business Unit'),
                    resource: 'core.BusinessUnit',
                  },
                },
                {
                  key: 'volume',
                  type: 'gt-input',
                  templateOptions: {
                    label: this.gettext('Volume'),
                    type: 'number',
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          className: 'form-group-container col-sm-4 col-xs-12',
          fieldGroup: [
            {
              wrapper: 'gt-panel',
              templateOptions: { label: this.gettext('DIMENSIONS') },
              fieldGroup: [
                {
                  key: 'commodity',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Commodity'),
                    placeholder: this.gettext('Choose commodity'),
                    resource: 'crops.Crop',
                  },
                },
                {
                  key: 'client',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Client'),
                    placeholder: this.gettext('Choose client'),
                    resource: 'clients.Client',
                    queryParams: () => ({
                      buyer_of_contract: sru?.contract_position,
                      role_name: 'buyer',
                      with_passport: sru?.passport,
                    }),
                  },
                },
                {
                  key: 'contract_position',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Contract position'),
                    placeholder: this.gettext('Choose contract position'),
                    resource: 'contracts.SaleContract',
                    queryParams: () => ({
                      buyer_client_list: sru?.client,
                      with_passport: sru?.passport,
                    }),
                  },
                },
                {
                  key: 'passport',
                  type: 'gt-ui-select',
                  templateOptions: {
                    label: this.gettext('Passport'),
                    placeholder: this.gettext('Passport'),
                    resource: 'passports.Passport',
                    queryParams: () => ({
                      buyer_client_list: sru?.client,
                      sale_contract_list: sru?.contract_position,
                    }),
                  },
                },
              ],
            },
          ],
        },
        {
          className: 'form-group-container col-sm-4 col-xs-12',
          fieldGroup: [
            {
              wrapper: 'gt-panel',
              templateOptions: { label: this.gettext('ADDITIONAL') },
              fieldGroup: [
                {
                  key: 'additional_info',
                  type: 'gt-textarea',
                  templateOptions: {
                    label: this.gettext('Additional Info'),
                    placeholder: this.gettext('Specific information about this object'),
                    className: 'additional-info',
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }
  getFields(formName: any) {
    // @ts-ignore
    return this.FormFieldParamsService.getFields(this.getFormConfig(formName));
  }
  openFieldsConfigModal(formName: any) {
    // @ts-ignore
    return this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig(formName));
  }
}
StockReserveUnitFormFieldsService.$inject = ['gettext', 'FormFieldParamsService'];
