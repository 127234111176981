import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './analytics-page-view.html?raw';

export const AnalyticsPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'ClientsService',
    'gettext',
    class {
      ClientsService: any;
      PageService: PageService;
      filterLevel = 'clients-analytics-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(
        PageService: PageService,
        ClientsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.ClientsService = ClientsService;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-crm-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.ClientsService.getPagesConfig(),
        };
      }
    },
  ],
};
