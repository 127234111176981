import type ng from 'angular';
import { format, subDays } from 'date-fns';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { ContractBase, Request } from '~/shared/api';

class StartPageController implements ng.IController {
  counters: Record<string, number> = {};
  endDate = format(new Date(), 'dd.MM.yyyy');
  startDate: string;

  static readonly $inject = ['$rootScope', '$state', '$injector', 'GtUtils'];
  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $state: ng.ui.IStateService,
    private readonly $injector: ng.auto.IInjectorService,
    private readonly GtUtils: GtUtilsService,
  ) {
    this.startDate = format(
      subDays(new Date(), this.$rootScope.user.settings.DASHBOARD_DAYS),
      'dd.MM.yyyy',
    );
  }

  $onInit(): void {
    this.updateData().catch(this.GtUtils.errorClb);
  }

  updateData = async () => {
    const modelsForCounters: string[] = [
      'logistics.Logistic',
      'logistics.voyage',
      'logistics.billoflading',
      'logistics.logistic',
      'logistics.vessel',

      'contracts.indicator',
      'contracts.ticket',
      'contracts.request',
      'contracts.purchasecontract',
      'contracts.salecontract',

      'documents.document',

      'clients.client',
      'clients.clientupdate',
      'clients.person',

      'auth.user',
      'passports.passport',

      'finances.finance',
      'finances.payment',
      'finances.bankaccount',
    ];

    this.counters = await this.GtUtils.getCounters(modelsForCounters);
    this.counters['contracts.ticket'] = await this.GtUtils.getCounters(['contracts.request'], {
      stage: 'ticket',
    });
    this.counters['contracts.indicator'] =
      this.counters['contracts.request'] - this.counters['contracts.ticket'];
  };

  openRequestModal(request: Request) {
    return this.$injector
      .get<ContractsService>('ContractsService')
      .requestModal(request)
      .then((data: Request | 'cancel') => {
        if (!data || data == 'cancel') {
          return;
        }
        this.$state.go('gt.page.request', { id: data.id });
      });
  }

  openContractModal(contract: ContractBase) {
    return this.$injector
      .get<ContractsService>('ContractsService')
      .contractModal(contract)
      .then((data) => {
        if (!data || data == 'cancel') {
          return;
        }
        this.$state.go('gt.page.contract', { id: data.id });
      });
  }

  navigateToPositionState() {
    switch (this.$rootScope.user.settings.DEFAULT_POSITION_TAB) {
      case 'pnl_position':
        this.$state.go('position.mtm');
        break;
      case 'position_by_month':
        this.$state.go('reports.consolidatedCropPosition');
        break;
      default:
        this.$state.go('position.physical');
        break;
    }
  }
}

export const startPageComponent: ng.IComponentOptions = {
  template: require('./start-page.html?raw'),
  controller: StartPageController,
};
