import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('updatesPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./updates-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    'PageService',
    'gettext',
    'NotificationService',
    'ClientsService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    NotificationService: any,
    ClientsService: any,
  ) {
    const vm = this;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'updates-page-view';
      vm.subscribeUpdates = subscribeUpdates;
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-crm-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
          new PageService.buttons.Import('clients.clientupdate'),
          new PageService.buttons.Export('clients.clientupdate', vm.filterLevel),
        ],
      };
      // @ts-ignore
      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [
          {
            title: gettext('RECENTLY UPDATED'),
            predicate: 'update_time',
          },
          {
            title: gettext('CREATION DATE'),
            predicate: 'create_time',
          },
          {
            title: gettext('DUE DATE'),
            predicate: 'due_date',
          },
        ],
        search: true,
        dates: true,
        dateSelects: [
          {
            argument: 'future_contact',
            placeholder: gettext('Future contact'),
          },
        ],
        multiSelects: [
          {
            argument: 'author',
            resource: 'auth.user',
            placeholder: gettext('author'),
            related_fk: 'author',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'responsible_user_list',
            resource: 'auth.user',
            placeholder: gettext('responsible users'),
          },
          {
            argument: 'initiator_list',
            resource: 'auth.user',
            placeholder: gettext('initiator'),
            related_fk: 'initiator',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'client_list',
            resource: 'clients.client',
            placeholder: gettext('Counterparty'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'passport_list',
            resource: 'passports.passport',
            placeholder: gettext('Passport'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'ticket_list',
            resource: 'contracts.request',
            placeholder: gettext('Ticket'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
            queryParams: {
              stage: 'ticket',
            },
          },
          {
            argument: 'indicator_list',
            resource: 'contracts.request',
            placeholder: gettext('Bid/Offer'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
            queryParams: {
              stage: 'indicator',
            },
          },
          {
            argument: 'contract_list',
            resource: 'contracts.contractbase',
            placeholder: gettext('Contract'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'multicontract_list',
            resource: 'contracts.multicontract',
            placeholder: gettext('Multicontract'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
            queryParams: {
              stage: 'contract',
            },
          },
          {
            argument: 'multiticket_list',
            resource: 'contracts.multicontract',
            placeholder: gettext('Multiticket'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
            queryParams: {
              stage: 'ticket',
            },
          },
          {
            argument: 'potential_list',
            resource: 'clients.croparea',
            placeholder: gettext('Potential'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'invoice_list',
            resource: 'finances.finance',
            placeholder: gettext('Invoice'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'voyage_list',
            resource: 'logistics.voyage',
            placeholder: gettext('Voyage'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'generalagreement_list',
            resource: 'contracts.generalagreement',
            placeholder: gettext('General Agreement'),
            related_fk: 'updates_entity_id',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'topic_list',
            resource: 'clients.updatetopic',
            placeholder: gettext('topic'),
            related_fk: 'topic',
            related_model: 'clients.ClientUpdate',
          },
          {
            argument: 'failure_reason_list',
            resource: 'clients.updatefailurereason',
            placeholder: gettext('failure reason'),
            related_fk: 'failure_reason',
            related_model: 'clients.ClientUpdate',
          },
        ],
      };

      // @ts-ignore
      config.filters.nestedSelects = [
        {
          argument: 'message_type',
          placeholder: gettext('Type'),
          items: [
            { id: 'call', title: gettext('Call') },
            { id: 'meet', title: gettext('Meet') },
            { id: 'email', title: gettext('Email') },
            { id: 'task', title: gettext('Task') },
            { id: 'commentary', title: gettext('Commentary') },
          ],
        },
        {
          argument: 'content_type_model_name',
          placeholder: gettext('Object type'),
          items: [
            { id: 'croparea', title: gettext('Potential') },
            { id: 'request', title: gettext('Ticket/Indicator') },
            { id: 'contractbase', title: gettext('Contract') },
            { id: 'passport', title: gettext('Passport') },
            { id: 'multicontract', title: gettext('Multicontract') },
            { id: 'multiticket', title: gettext('Multiticket') },
            { id: 'client', title: gettext('Client') },
            { id: 'finance', title: gettext('Invoice') },
            { id: 'voyage', title: gettext('Voyage') },
            { id: 'generalagreement', title: gettext('General Agreement') },
          ],
        },
        {
          argument: 'priority',
          placeholder: gettext('Priority'),
          items: [
            { id: 'medium', title: gettext('Medium') },
            { id: 'low', title: gettext('Low') },
            { id: 'high', title: gettext('High') },
            { id: 'critical', title: gettext('Critical') },
          ],
        },
      ];
      // @ts-ignore
      config.filters.selects = [];
      // @ts-ignore
      config.filters.nestedMultiSelects = [];
      // @ts-ignore
      config.pages = ClientsService.getPagesConfig();
      return config;
    }

    function subscribeUpdates() {
      return NotificationService.openEventSubscriptionModal({
        user: $rootScope.user.id,
        email: $rootScope.user.email,
        action: 'clientupdate_created',
        filters: [
          { field: 'updates_entity_id', value: vm.clientUpdate.updates_entity_id },
          {
            field: 'updates_entity_content_type_id',
            value: vm.clientUpdate.updates_entity_content_type_id,
          },
        ],
      });
    }
  }
})();
