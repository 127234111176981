import React from 'react';

import { CheckboxRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const CheckboxCellRead = <T,>({ value, valueChanged }: CellTypeProps<T>) => {
  return (
    <CheckboxRead
      value={value as boolean}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  );
};
