import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './multipassport-modal.html?raw';

export const MultipassportModal = {
  bindings: {
    modalInstance: '<',
    item: '<?',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'MultipassportService',
    'CoreService',
    class {
      CoreService: CoreService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      MultipassportService: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      item: any;
      modalInstance: any;
      multipassport: any;
      positions: any;
      tab: any;
      updateLogEntries: any;
      constructor(
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        MultipassportService: any,
        CoreService: CoreService,
      ) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.MultipassportService = MultipassportService;
        this.CoreService = CoreService;
        this.form = undefined;
        this.fields = [];
        this.tab = 'edit';
      }

      $onInit() {
        this.multipassport = this.item || { positions: [] };

        if (this.multipassport.id) {
          this.updateData();
        } else {
          this.updateFields();
          this.positions = [...this.multipassport.positions];
          delete this.multipassport.positions;
        }
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.MultipassportService.getModalData({ id: this.multipassport.id }).then(
          (data: any) => {
            this.multipassport = data;
            this.positions = [...this.multipassport.positions];
            delete this.multipassport.positions;
            this.updateFields();
            this.updateLogEntries();
            this.GtUtils.overlay('hide');
          },
        );
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      save() {
        return this.MultipassportService.save(this.multipassport).then(
          (data: any) => {
            this.GtUtils.notify(this.gettext('Multipassport saved.'));
            this.modalInstance.close(data || 'close');
          },
          (data: any) => {
            this.multipassport.errors = data.data;
            this.GtUtils.errorClb(data);
          },
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-sm-3 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('GENERAL'),
          },
          fieldGroup: [
            {
              key: 'title',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Number'),
                addon: this.gettext('#'),
              },
            },
            {
              key: 'business_unit',
              type: 'gt-ui-select',
              defaultValueResolve: () => this.CoreService.getDefaultBuId(this.multipassport),
              templateOptions: {
                label: this.gettext('Business unit'),
                placeholder: this.gettext('Business Unit'),
                queryParams: this.GtUtils.getBUQueryParams(),
                resource: 'core.BusinessUnit',
              },
            },
          ],
        });

        return {
          formName: 'multipassport-edit-modal',
          fieldsDef: [col1],
        };
      }
    },
  ],
};
