import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';
export class CustomsCargoDeclarationService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  CDTypeResource: any;
  LogisticsService: any;
  customsCargoDeclarationResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    gettext: ng.gettext.gettextFunction,
    LogisticsService: any,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.gettext = gettext;
    this.LogisticsService = LogisticsService;
    this.customsCargoDeclarationResource = $resource(
      '/api/logistics/customs-cargo-declaration/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        listInfo: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration-list/info/',
        },
        predictions: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration/predictions/',
        },
        getPreparedCcdByInvoice: {
          method: 'GET',
          isArray: true,
          url: '/api/logistics/customs-cargo-declaration/get_prepared_ccd_by_invoice/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/customs-cargo-declaration/bulk_create_or_update/',
        },
        bulkCreateOrUpdateWithLogistics: {
          method: 'POST',
          url: '/api/logistics/customs-cargo-declaration/bulk_create_or_update_with_logistics/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/customs-cargo-declaration/export_column_names/',
        },
      },
    );
    this.CDTypeResource = $resource(
      '/api/logistics/customs-cargo-declaration-type/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          url: '/api/logistics/customs-cargo-declaration-type/predictions/',
        },
      },
    );
  }

  getListData(params: any) {
    return this.customsCargoDeclarationResource.listInfo(params).$promise;
  }

  getCcdByInvoice(params: any) {
    return this.customsCargoDeclarationResource.getPreparedCcdByInvoice(params).$promise;
  }

  getCCD(ccdId: any) {
    return this.customsCargoDeclarationResource.get({ id: ccdId }).$promise;
  }

  deleteCCD(ccdId: any) {
    return this.customsCargoDeclarationResource.delete({ id: ccdId }).$promise;
  }

  bulkCreateOrUpdate(ccd: any) {
    return this.customsCargoDeclarationResource.bulkCreateOrUpdate(ccd).$promise;
  }

  saveCCD(item: any) {
    if (item.id) {
      return this.customsCargoDeclarationResource.update(item).$promise;
    }
    return this.customsCargoDeclarationResource.save(item).$promise;
  }

  openModal(declaration: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
          <customs-cargo-declaration-modal
            declaration="declaration"
            modal-instance="$uibModalInstance">
          </customs-cargo-declaration-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).declaration = declaration;
        },
      ],
      windowClass: 'modal-template-third-width',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }

  updateCcdFieldsModel(declaration: any, extraData: any) {
    const newDeclaration = {
      ...declaration,
      id: declaration?.id,
      currency_exchange: declaration?.currency_exchange_id,
      currency: declaration?.currency_id,
      from_declaration: declaration?.from_declaration_id,
      invoice_positions: declaration?.invoice_positions,
      passports: declaration?.passport_id ? [declaration?.passport_id] : [],
      result_status: 'undefined',
      terminal: declaration?.terminal_id,
    };

    if (extraData?.contract) {
      newDeclaration.customs_broker = extraData.contract.customs_broker;
      newDeclaration.destination_of_crop = extraData.contract.destination_of_crop;
    }

    return newDeclaration;
  }
}
CustomsCargoDeclarationService.$inject = ['$resource', '$uibModal', 'gettext', 'LogisticsService'];
