import type ng from 'angular';

import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

export const ContractChargesListTable = {
  bindings: {
    filterLevel: '<',
    tableName: '<',
    charges: '<',
    totals: '<',
    count: '<',
    invoicing: '<',
    activeFilterPresets: '<',
    activeReportConfig: '<',
    applyFilters: '&',
    onDelete: '&?',
    onSave: '&',
    onSaveMulti: '&',
    onUpdateInvoicePositions: '&',
    createInvoice: '&',
    onOpenDocumentModal: '&',
    onOpenModal: '&',
    onMenuHover: '&?',
    checkChargeType: '&',
    calcFinancingPrices: '&',
    calculateChargePrice: '&',
    onCheckboxClick: '&?',
    readOnly: '<?',
    quickAdd: '<?',
    initQueryParams: '<?',
    predictionsQueryParams: '<?',
    selection: '<?',
  },
  template: '<gt-table-new options="$ctrl.tableOptions" data="$ctrl.tableData"></gt-table-new>',

  controller: [
    '$window',
    '$rootScope',
    'gettext',
    'ContractsService',
    'FinancesService',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      ContractsService: ContractsService;
      CustomValuesService: any;
      FinancesService: FinancesService;
      activeFilterPresets: any;
      activeReportConfig: any;
      applyFilters: any;
      calcFinancingPrices: any;
      calculateChargePrice: any;
      charges: any;
      checkChargeType: any;
      count = 0;
      createInvoice: any;
      filterLevel = '';
      gettext: ng.gettext.gettextFunction;
      initQueryParams: QueryParams = {};
      isCloned: any;
      onDelete: any;
      onOpenDocumentModal: any;
      onOpenModal: any;
      onSave: any;
      onSaveMulti: any;
      onUpdateInvoicePositions: any;
      predictionsQueryParams: QueryParams = {};
      quickAdd: any;
      readOnly: any;
      rows: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      totals: any;
      selection: boolean;
      onCheckboxClick: any;
      constructor(
        $window: ng.IWindowService,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        ContractsService: ContractsService,
        FinancesService: FinancesService,
        CustomValuesService: any,
      ) {
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.ContractsService = ContractsService;
        this.FinancesService = FinancesService;
        this.CustomValuesService = CustomValuesService;

        this.tableData = {};
        this.tableOptions = {};
        this.rows = {};

        this.initQueryParams = {};
        this.predictionsQueryParams = {};

        this.isCloned = false;
        this.selection = false;
      }

      $onInit() {
        this.setTableOptions();
        this.tableName = this.tableName || 'contract-charges-list';
      }

      $onChanges(changes: any) {
        if (
          changes.activeFilterPresets ||
          changes.activeReportConfig?.currentValue ||
          changes.selection
        ) {
          this.setTableOptions();
        }

        if (changes.charges || changes.totals || changes.invoicing || changes.quickAdd) {
          this.updateTableData(this.quickAdd);
        }
      }

      updateTableData(quickAdd: any) {
        if (quickAdd) {
          this.tableData = {
            rows: this.charges,
            count: this.count,
            total: this.totals,
          };
        } else {
          this.tableData = {
            rows: this.charges.slice(1),
            count: this.count,
            total: this.totals,
          };
        }

        this.rows = this.tableData.rows.reduce((options: any, item: any, index: any) => {
          options[item.id] = { index: index, editable: false };
          return options;
        }, {});
      }

      addContractToCharge(contractId: any, chargeId: any) {
        this.ContractsService.Contract.get({ id: contractId }).$promise.then((data: any) => {
          Object.assign(this.tableData.rows[this.rows[chargeId].index], {
            contract: data.id,
            contract_true_volume:
              data.final_volume || data.volume_estimated_open || data.volume || 0,
            contract_price: data.price || 0,
            vat_value: this.$rootScope.user.settings.CHARGE_VAT_VALUE || 0,
          });
        });
      }

      getCustomFieldTableColumns() {
        return this.CustomValuesService.getCustomFieldTableColumns({
          purpose_model: 'contractcharge',
        });
      }

      showContractDetails(item: any) {
        if (!item) {
          return;
        }
        return this.ContractsService.Contract.predictionsDetails({
          id: item.contract,
          full_title: true,
        }).$promise.then((data: any) => (item.contractDetails = data));
      }

      onContractSelect(item: any) {
        this.addContractToCharge(item.contract, item.id);
        return this.showContractDetails(item);
      }

      onPassportSelect(item: any) {
        Object.assign(item, { with_passport: item.passport });
      }

      cloneCharge(charge: any) {
        const newCharge = {
          ...charge,
          status: 'forecast',
          author: undefined,
          author_avatar: undefined,
          author_first_name: undefined,
          create_time: undefined,
          editor: undefined,
          editor_avatar: undefined,
          editor_first_name: undefined,
          update_time: undefined,
          id: undefined,
          invoiced_amount: undefined,
          payments: undefined,
          invoiced: undefined,
          payments_amount_sum: undefined,
          finances: [],
          contracts: charge.contracts || [],
        };

        this.charges[0] = newCharge;
        this.updateTableData(true);
      }

      cancelCloneCharge() {
        this.updateTableData(false);
      }

      saveClonedCharge(charge: any) {
        this.onSave({ charge: charge });
        this.cancelCloneCharge();
      }

      setPassport(item: any) {
        if (item.contract_passport_list?.length) {
          Object.assign(item, { with_passport: item.contract_passport_list.at(0).id });
        }
      }

      setTableOptions() {
        const options: any = {
          tableName: this.tableName,
          tableClass:
            'table-condensed main-table contract-charges-table table-hover table-with-inline-add',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          activeFilterPresets: this.activeFilterPresets,
          activeReportConfig: this.activeReportConfig,
          templateArgs: {
            readOnly: this.readOnly,
            charges: this.charges || [{}],
            queryParams: this.initQueryParams,
            predictionsQueryParams: this.predictionsQueryParams,
            $rootScope: this.$rootScope,
            isCloned: this.isCloned,
            saveCharge: (charge: any) => this.onSave({ charge: charge }),
            saveMultiContractCharge: (charge: any) => this.onSaveMulti({ charge: charge }),
            deleteCharge: (charge: any) => this.onDelete({ charge: charge }),
            cloneCharge: (charge: any) => this.cloneCharge(charge),
            cancelCloneCharge: () => this.cancelCloneCharge(),
            saveClonedCharge: (charge: any) => this.saveClonedCharge(charge),
            openModal: (charge: any) => this.onOpenModal({ charge: charge }),
            onRowSelect: () => this.onUpdateInvoicePositions(),
            scrollToTop: () => this.$window.scrollTo(0, 400),
            openDocumentModal: (object: any) => this.onOpenDocumentModal({ object: object }),
            onContractSelect: (item: any) => this.onContractSelect(item),
            onPassportSelect: (item: any) => this.onPassportSelect(item),
            setPassport: (item: any) => this.setPassport(item),
            onChargeSelect: (charge: any) => this.checkChargeType({ charge: charge }),
            onPressCalcButton: (charge: any) => this.calcFinancingPrices({ charge: charge }),
            calculateChargePrice: (charge: any, a = 1, isPriceChanged = false) => {
              return this.calculateChargePrice({
                charge: charge,
                perVolume: a,
                isPriceChanged: isPriceChanged,
              });
            },
            onCreateInvoiceButtonClick: (charge: any) => this.createInvoice({ charge: charge }),
            onCheck: () => this.onCheckboxClick(),
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
          showMore: true,
          selection: this.selection,
          allSelectedCallback: () => {
            this.onCheckboxClick();
          },
          selectionCellTemplate: /*html*/ `
            <input
              type="checkbox"
              ng-model="item.$_selected"
              ng-change="args.onCheck()"
              style="margin-right:5px; float: none"
            />
          `,
        };

        options.columnDefs = [
          {
            title: this.gettext('Status'),
            columnName: 'status',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.status">
                  <span class="label label_info" ng-if="item.status == 'forecast'">
                    <translate>Forecast</translate>
                  </span>
                  <span class="label label-primary" ng-if="item.status == 'pending_confirmation'">
                    <translate>Pending confirmation</translate>
                  </span>
                  <span class="label label_warning" ng-if="item.status == 'under_discussion'">
                    <translate>Discussion</translate>
                  </span>
                  <span class="label label_success" ng-if="item.status == 'validated'">
                    <translate>Validated</translate>
                  </span>
                  <span
                    class="tooltip"
                    data-tip="{[{ 'Not used in P/L and totals calculations'|translate }]}"
                    ng-if="item.status == 'planned'"
                  >
                    <span class="label label-default"><translate>Planned</translate></span>
                  </span>
                  <span
                    class="tooltip"
                    data-tip="{[{ 'Not used in P/L and totals calculations'|translate }]}"
                    ng-if="item.status == 'cancelled'"
                  >
                    <span class="label label_danger"><translate>Cancelled</translate></span>
                  </span>
                  <span class="label label_default" ng-if="item.status == 'closed'">
                    <translate>Closed</translate>
                  </span>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="td-left-align">
                <select class="form-control gt_test_field_status" ng-model="item.status">
                  <option value="validated"><translate>Validated</translate></option>
                  <option value="pending_confirmation"><translate>Pending confirmation</translate></option>
                  <option value="under_discussion"><translate>Under discussion</translate></option>
                  <option value="forecast"><translate>Forecast</translate></option>
                  <option value="planned"><translate>Planned</translate></option>
                  <option value="cancelled"><translate>Cancelled</translate></option>
                  <option value="closed"><translate>Closed</translate></option>
                </select>
              </div> `,
          },
          {
            title: this.gettext('Contract'),
            columnName: 'contract',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'contract_list',
                label: this.gettext('Contract'),
                resource: 'contracts.contractBase',
              },
              {
                type: 'select',
                predicate: 'contract_deal_type',
                label: this.gettext('Contract deal type'),
                values: {
                  spot: this.gettext('spot'),
                  forward: this.gettext('forward'),
                  brokerage: this.gettext('brokerage'),
                  services: this.gettext('services'),
                  export: this.gettext('export'),
                  intermediate: this.gettext('intermediate'),
                },
              },
            ],
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <a ng-if="item.contract" class="btn-link"
                  ui-sref="{[{ item.is_request ? 'gt.page.request({id: item.contract})' : 'gt.page.contract({id: item.contract})' }]}">
                  <i class="fa fa-file-text"></i> {[{ item.contract_number }]}
                  <span ng-if="item.contract_type == 'sale'" class="secondary-text smaller-label">
                    <i class="fa fa-bookmark"></i> {[{ item.contract_buyer_name || '---' | cut:true:30:'...'
                    }]}
                  </span>
                  <span
                    ng-if="item.contract_type == 'purchase'"
                    class="secondary-text smaller-label"
                  >
                    <i class="fa fa-bookmark-o"></i> {[{ item.contract_supplier_name || '---' |
                    cut:true:30:'...' }]}
                  </span>
                </a>
              </div>
              <div ng-if="item._$editMode || !item.id" >
              <div ng-if="!(item.passport && $root.user.settings.PASSPORT_COSTS_VALIDATE) && !item.multicontract">
                <gt-resource-select
                  ng-model="item.contract"
                  placeholder="'Contract'|translate"
                  resource-name="'contracts.ContractBase'"
                  query-params="{
                    with_passport: args.queryParams.passport || item.with_passport || args.charges[0].with_passport || args.setPassport(item),
                    id_list: args.queryParams.contract,
                    multicontract_list: args.queryParams.multicontract_list,
                  }"
                  allow-clear="true"
                  on-select="args.onContractSelect"
                  on-select-args="[item]"
                ></gt-resource-select>
                <div class="clearfix"></div>
                <span ng-if="item.contractDetails" class="smaller-label label-opacity">
                  <!-- {[{ item.contractDetails }]} -->
                  <span ng-if="item.contractDetails.multicontract_number">
                    <translate>Multicontract</translate>:
                    {[{item.contractDetails.multicontract_number}]}
                  </span>
                  <span>
                    <translate>Passports</translate>: {[{item.contractDetails.sale_passport_title_list +
                    item.contractDetails.purchase_passport_title_list}]}
                  </span>
                  <div class="clearfix"></div>
                  <span>
                    <translate>Voyages</translate>: {[{item.contractDetails.sale_voyage_title_list +
                    item.contractDetails.purchase_voyage_title_list}]}
                  </span>
                </span>
              </div>
              <div>
               `,
          },
          {
            title: this.gettext('Contracts'),
            columnName: 'contracts',
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'contract_list',
                label: this.gettext('Contract'),
                resource: 'contracts.ContractBase',
              },
            ],
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-repeat="contract in item.contract_m2m_data">
                  <a
                    ui-sref="gt.page.contract({id: contract.id })"
                  >
                      <i class="fa fa-file-text"></i> {[{ contract.contract_number }]}
                  </a>
                  <div class="clearfix"></div>
                </span>
              </div>
              <div ng-if="(item._$editMode || !item.id) && item.passport">
                <gt-resource-multiselect
                  ng-model="item.contracts"
                  placeholder="'Contracts'|translate"
                  resource-name="'contracts.ContractBase'"
                  query-params="{with_passport: args.queryParams.passport || item.with_passport || args.charges[0].with_passport || args.setPassport(item), id_list: args.queryParams.contract}"
                  allow-clear="true"
                ></gt-resource-multiselect>
              </div>
               `,
          },
          {
            title: this.gettext('Customs declaration'),
            columnName: 'customs_declaration',
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'customs_declaration_list',
                label: this.gettext('Customs declaration'),
                resource: 'logistics.CustomsCargoDeclaration',
              },
            ],
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <a
                  ng-if="item.customs_declaration"
                  class="btn-link"
                  ui-sref="gt.page.customsCargoDeclarationDetails({id: item.customs_declaration })"
                >
                  <i class="fa fa-file-text"></i> {[{ item.customs_declaration_number }]}
                </a>
              </div>
              <div ng-if="item._$editMode || !item.id" >
                <gt-resource-select
                  ng-model="item.customs_declaration"
                  placeholder="'Customs declaration'|translate"
                  resource-name="'logistics.CustomsCargoDeclaration'"
                  query-params="{ export_contract: item.contract}"
                  allow-clear="true"
                  on-select="args.onContractSelect"
                  on-select-args="[item]"
                ></gt-resource-select>
              <div>
               `,
          },
          {
            title: this.gettext('Actions'),
            columnName: 'actions',
            class: 'td-left-align minWidth no-blur',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <a class="btn btn-md btn-blue-border" ng-show="!$root.user.settings.ENABLE_INLINE_ADD " ng-click="args.openModal(item)" style="display: inline-flex; padding: 6px !important;">
                  <i class="fa fa-pencil-square"></i>
                </a>
                <a class="btn btn-md btn-blue-border"  ng-click="item._$editMode = true" ng-show="$root.user.settings.ENABLE_INLINE_ADD" style="display: inline-flex; padding: 6px !important;">
                  <i class="fa fa-pencil-square"></i>
                </a>
                <a class="btn btn-md btn-green-border" permission permission-only="'add_document'" ng-click="args.openDocumentModal(item)" style="display: inline-flex; padding: 6px !important;">
                  <i class="fa fa-upload"></i>
                </a>
                <a class="btn btn-md btn-green-border" ng-if="!args.readOnly" ng-click="args.cloneCharge(item); args.scrollToTop(); args.isCloned = true" style="display: inline-flex; padding: 6px !important;">
                  <i class="fa fa-files-o"></i>
                </a>
                <a class="btn btn-md btn-green-border" ng-hide="item.not_invoiced == 0" ng-click="args.onCreateInvoiceButtonClick(item)" style="display: inline-flex; padding: 6px !important;">
                  <i class="fa fa-credit-card-alt" ></i>
                </a>
              </div>
              <div class="clearfix"></div>
              <div  ng-if="item._$editMode || !item.id" class="edit">
                <div ng-if="!item.id && item.showFinancingCalcButton">
                  <div class="input-group-btn">
                    <a
                      class="btn btn-md btn-success btn_success col-xs-12"
                      ng-click="args.onPressCalcButton(item);"
                    >
                      <i class="fa fa-calculator"></i> <translate>calc</translate>
                    </a>
                  </div>
                </div>
                <div ng-if="!args.isCloned" class="input-group-btn">
                  <a
                    ng-if="!item.multicontract || item.id"
                    class="btn btn-md btn-success btn_success col-xs-12"
                    ng-click="args.saveCharge(item); item._$editMode = false;"
                  >
                    <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                  </a>
                  <a
                    ng-if="item.multicontract && !item.id"
                    class="btn btn-md btn-success btn_success col-xs-12"
                    ng-click="args.saveMultiContractCharge(item); item._$editMode = false;"
                  >
                    <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                  </a>
                </div>
                <div ng-if="!item.id && args.isCloned" class="input-group-btn">
                  <a
                    class="btn btn-md btn-success btn_success col-xs-12"
                    ng-click="args.saveClonedCharge(item); item._$editMode = false; args.isCloned = false"
                  >
                    <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                  </a>
                </div>
                <div ng-if="!item.id && args.isCloned" class="input-group-btn">
                  <a class="btn btn-md col-xs-12" ng-click="args.cancelCloneCharge(); args.isCloned = false">
                    <i class="fa fa-times"></i> <translate>Close</translate>
                  </a>
                </div>
                <div ng-if="item.id" class="input-group-btn">
                  <a class="btn btn-md col-xs-12" ng-click="item._$editMode=false">
                    <i class="fa fa-times"></i> <translate>Cancel</translate>
                  </a>
                </div>
                <div ng-if="item.id" class="input-group-btn">
                  <a
                    class="btn btn-md btn-danger btn_danger col-xs-12"
                    ng-click="args.deleteCharge(item); item._$editMode = false"
                  >
                    <i class="fa fa-trash"></i> <translate>Delete</translate>
                  </a>
              </div>
              `,
          },
          {
            title: this.gettext('Charge'),
            columnName: 'charge',
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'charges_list',
                label: this.gettext('Charge'),
                resource: 'finances.Charge',
              },
              {
                type: 'select',
                predicate: 'ordering',
                label: this.gettext('ORDER'),
                values: {
                  '-update': this.gettext('Update'),
                  charge: this.gettext('Charge'),
                },
              },
              {
                type: 'select',
                predicate: 'is_gain',
                label: this.gettext('Is gain'),
                values: {
                  1: this.gettext('Yes'),
                  0: this.gettext('No'),
                },
              },
            ],
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span style="white-space: normal; text-align: left;">
                  {[{ $root.user.profile.language === 'en' && item.charge_title_eng || item.charge_title }]}
                  <div class="clearfix"></div>
                </span>
                <span style="white-space: normal; text-align: left;" ng-if="item.date">
                  <i class="fa fa-calendar"></i> {[{ item.date | date:'MM.yy'}]}
                  <div class="clearfix"></div>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id "  class="edit gt-test-edit-title">
                <gt-resource-select
                  ng-model="item.charge"
                  placeholder="'Charge'|translate"
                  resource-name="'finances.Charge'"
                  allow-clear="true"
                  on-select="args.onChargeSelect"
                  on-select-args="[item]"
                  query-params="{tariff_use_list:args.predictionsQueryParams.tariff_use_list}"
                ></gt-resource-select>
                <gt-date-select
                  date-model="item.date"
                  placeholder="'Date' | translate"
                  allow-clear="false"
                  min-view="'month'"
                  start-view="'month'"
                ></gt-date-select>
                <input type="checkbox" style="width:15px;height:15px;" ng-model="item.is_gain"/>
                <label style="margin-top:5px;"><translate>is gain</translate></label>
              </div>
              <input
                ng-if="item.id && item._showCheckbox"
                type="checkbox"
                ng-model="item._selected"
                ng-change="args.onRowSelect()"
                style="width:20px !important;"
              />`,
          },
          {
            title: this.gettext('Comment'),
            columnName: 'Comment',
            class: 'td-left-align minWidth',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
              <span class="smaller-label secondary-text wrap-text" ng-if="item.additional_info">
               <i class="fa fa-commenting"></i> {[{ item.additional_info }]}
              </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit">
              <textarea
              style="width:100px !important; height: 70px !important;"
              class="form-control gt_test_field_additional_info"
              placeholder="{[{ 'Info'|translate }]}"
              ng-model="item.additional_info">
            </textarea>
           </div>
              `,
          },
          {
            title: this.gettext('Amount per t'),
            columnName: 'amount_per_t',
            class: 'td-right-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_t == 0, 'negative-number': item.price_per_t != 0 }"
                  ng-if="!item.is_gain"
                >
                  <i class="fa fa-minus"></i> {[{ item.price_per_t | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
                <span
                  class="bigger-label label pull-left"
                  ng-class="{ 'label-opacity': item.price_per_t == 0, 'positive-number': item.price_per_t != 0  }"
                  ng-if="item.is_gain"
                >
                  <i class="fa fa-plus"></i> {[{ item.price_per_t | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
              `,
            totalTemplate: `
              <div class="td-right-align">
                <span
                  ng-repeat="total in item.groupped_data"
                  ng-class="{ 'label-opacity': total.price_per_t == 0 }"
                >
                  {[{ total.price_per_t || 0 | number: 2 }]}
                <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Amount per deal execution'),
            columnName: 'amount_per_deal',
            class: 'td-right-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_deal == 0, 'negative-number': item.price_per_deal != 0 }"
                  ng-if="!item.is_gain"
                >
                  <i class="fa fa-minus"></i> {[{ item.price_per_deal | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_deal == 0, 'positive-number': item.price_per_deal != 0  }"
                  ng-if="item.is_gain"
                >
                  <i class="fa fa-plus"></i> {[{ item.price_per_deal | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id"  class="edit gt-test-edit-price-deal" >
                <input
                  class="form-control"
                  placeholder="{[{ 'Amount' |  translate }]}"
                  type="number"
                  ng-model="item.price"
                  ng-change="args.calculateChargePrice(item,'', true)"
                />
                </div> `,
            totalTemplate: `
              <div class="td-right-align">
                <span
                  ng-repeat="total in item.groupped_data"
                  ng-class="{ 'label-opacity': total.price_per_deal == 0 }"
                >
                  {[{ total.price_per_deal || 0 | number: 2 }]}
                <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Amount without VAT'),
            columnName: 'price_per_deal_without_vat',
            class: 'td-right-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_deal_without_vat == 0, 'negative-number': item.price_per_deal_without_vat != 0 }"
                  ng-if="!item.is_gain"
                >
                  <i class="fa fa-minus"></i> {[{ item.price_per_deal_without_vat | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_deal_without_vat == 0, 'positive-number': item.price_per_deal_without_vat != 0  }"
                  ng-if="item.is_gain"
                >
                  <i class="fa fa-plus"></i> {[{ item.price_per_deal_without_vat | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
              </div> `,
            totalTemplate: `
              <div class="td-right-align">
                <span
                  ng-repeat="total in item.groupped_data"
                  ng-class="{ 'label-opacity': total.price_per_deal_without_vat == 0 }"
                >
                  {[{ total.price_per_deal_without_vat || 0 | number: 2 }]}
                <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Amount without VAT, $'),
            columnName: 'price_per_deal_without_vat_global',
            class: 'td-right-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_deal_without_vat_global == 0, 'negative-number': item.price_per_deal_without_vat_global != 0 }"
                  ng-if="!item.is_gain"
                >
                  <i class="fa fa-minus"></i> {[{ item.price_per_deal_without_vat_global | number:2 }]}
                  <span class="smaller-label"> {[{ item.default_currency_symbol }]} </span>
                </span>
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.price_per_deal_without_vat_global == 0, 'positive-number': item.price_per_deal_without_vat_global != 0  }"
                  ng-if="item.is_gain"
                >
                  <i class="fa fa-plus"></i> {[{ item.price_per_deal_without_vat_global | number:2 }]}
                  <span class="smaller-label"> {[{ item.default_currency_symbol }]} </span>
                </span>
              </div> `,
            totalTemplate: `
              <div class="td-right-align">
                {[{ item.price_per_deal_without_vat_global || 0 | number: 2 }]}
                <span class="smaller-label"> {[{ item.default_currency_symbol }]}</span>
              </div>`,
          },
          {
            title: this.gettext('Unconnected amount per deal'),
            columnName: 'unconnectedPricePerDeal',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.unconnected_price_per_deal == 0, 'negative-number': item.unconnected_price_per_deal != 0 }"
                  ng-if="!item.is_gain"
                >
                  <i class="fa fa-minus"></i> {[{ item.unconnected_price_per_deal | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
                <span
                  class="bigger-label label"
                  ng-class="{ 'label-opacity': item.unconnected_price_per_deal == 0, 'positive-number': item.unconnected_price_per_deal != 0  }"
                  ng-if="item.is_gain"
                >
                  <i class="fa fa-plus"></i> {[{ item.unconnected_price_per_deal | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Per 1 t'),
            columnName: 'per_1_t',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.price_t" ng-class="{ 'label-opacity': item.price_t == 0 }">
                  {[{ item.price_t | number:2 }]}
                  <span class="smaller-label">
                    {[{ item.currency_symbol }]}/ {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id"  class="edit gt-test-edit-price-t" >
              <input
                class="form-control"
                placeholder="{[{ 'Price 1t'|translate }]}"
                type="number"
                ng-model="item.price_t"
                ng-change="args.calculateChargePrice(item, 't')"
              />
            </div> `,
          },
          {
            title: this.gettext('Volume'),
            columnName: 'volume',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.volume">
                  {[{ item.volume || 0 | number:3 }]} <span class="smaller-label">t</span>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit gt-test-edit-volume" >
                <input
                  class="form-control"
                  placeholder="{[{ 'Volume'|translate }]}"
                  type="number"
                  ng-model="item.volume"
                  ng-change="args.calculateChargePrice(item, 't')"
                />
              </div> `,
          },
          {
            title: this.gettext('%'),
            columnName: 'percent',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
            <div ng-if="!item._$editMode && item.id" class="td-left-align" >
              <span ng-if="item.percentage">
               {[{ item.percentage || 0 | number:2 }]} <span class="smaller-label">%</span>
              </span>
            </div>
            <div ng-if="item._$editMode || !item.id"  class="edit gt-test-edit-percentage">
              <input
                class="form-control"
                placeholder="{[{ 'Percentage'|translate }]}"
                type="number"
                ng-model="item.percentage"
                ng-value="{[{ item.percentage || 0 }]}"
                ng-change="args.calculateChargePrice(item, 't')"
              />
            </div> `,
          },
          {
            title: this.gettext('Invoiced Amount'),
            columnName: 'invoiced_amount',
            class: 'td-left-align minWidth',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div class="td-left-align" ng-if="!item._$editMode && item.id">
              <span
              ng-class="{'label': item.invoiced_local == item.price_per_deal && item.invoiced_local, 'negative-number': item.invoiced_local > item.price_per_deal && item.invoiced_local}">
              <span
                ng-class="{ 'label-opacity': item.invoiced_local == 0}"
                ng-if="!item.is_gain"
              >
                <i class="fa fa-minus"></i> {[{ item.invoiced_local | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
              <span
                ng-class="{ 'label-opacity': item.invoiced_local == 0 }"
                ng-if="item.is_gain"
              >
                <i class="fa fa-plus"></i> {[{ item.invoiced_local | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>

            </span>

                <div class="clearfix"></div>
                 <span class="badge label_success" ng-if="item.invoiced_local == item.price_per_deal && item.price_per_deal">
            <i class="fa fa-check-circle" ></i> <translate>full invoiced</translate>
            </span>
             <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="item.finances.length">
                   <li ng-repeat="invoice in item.finances">
                    <a
                      ui-sref="gt.page.payment({id: invoice.id})"
                      target="_blank"
                      style="padding-left:5px;"
                      class="btn-link smaller-label"
                    >
                    <span class="smaller-label secondary-text" ng-if="invoice.date">
                    <i class="fa fa-calendar"></i> {[{invoice.date | date:'dd/MM/yy'}]}
                    </ span>
                     <i class="fa fa-credit-card"></i> {[{invoice.number | cut:true:30:'...' }]}
                      <span class="label label_danger" ng-if="invoice.not_pay"><translate>Not pay</translate></span>
                     <span class="negative-number" ng-if="invoice.status == 'canceled'">(<translate>{[{ invoice.status }]})</translate></span>
                      <span
                        class="badge label_danger tooltip smaller-label"
                        data-tip="With discount"
                        ng-if="invoice.discount != 100"
                        style="font-size: 6px !important"
                      >
                      <i class="fa fa-exclamation-triangle"></i>
                    </span>
                    <span
                      ng-if="invoice.invoice_type == 'incoming'"
                      class="smaller-label secondary-text"
                    >
                      <i class="fa fa-building"></i>
                      {[{ invoice.client_from_name | cut:true:30:'...' }]}</span
                    >

                    <span
                      ng-if="invoice.invoice_type == 'outgoing'"
                      class="smaller-label secondary-text"
                    >
                      <i class="fa fa-building"></i>
                      {[{ invoice.client_to_name | cut:true:30:'...' }]}</span
                    >
                    <div class="clearfix"></div>
                    </a>
                  </li>
                </ul>
              </div> `,
            totalTemplate: `
              <div class="td-right-align">
                <span ng-repeat="total in item.groupped_data">
                  <span
                    ng-class="{'negative-number': total.invoiced_local > 0 && (total.price_per_deal - total.invoiced_local > 0.1) }"
                  >
                    {[{ total.invoiced_local || 0 | number:2}]}
                  </span>
                  <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Consolidated Invoiced Amount'),
            columnName: 'consolidated_invoiced_amount',
            class: 'td-left-align minWidth',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
              <span
              ng-class="{
                'label': item.consolidated_invoiced == item.price_per_deal && item.consolidated_invoiced,
                'negative-number': item.consolidated_invoiced > item.price_per_deal && item.consolidated_invoiced
              }">
              <span
                ng-class="{ 'label-opacity': item.consolidated_invoiced == 0}"
                ng-if="!item.is_gain"
              >
                <i class="fa fa-minus"></i> {[{ item.consolidated_invoiced | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>
              <span
                ng-class="{ 'label-opacity': item.consolidated_invoiced == 0 }"
                ng-if="item.is_gain"
              >
                <i class="fa fa-plus"></i> {[{ item.consolidated_invoiced | number:2 }]}
                <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
              </span>


            </span>
            <div class="clearfix"></div>
           <span class="badge label_success" ng-if="item.consolidated_invoiced == item.price_per_deal && item.consolidated_invoiced">
            <i class="fa fa-check-circle" ></i> <translate>full invoiced</translate>
 </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="item.finances.length">
                   <li ng-repeat="invoice in item.finances">
                    <a
                      ui-sref="gt.page.payment({id: invoice.id})"
                      target="_blank"
                      class="btn-link smaller-label"
                    >
                    <span class="smaller-label secondary-text" ng-if="invoice.date">
                    <i class="fa fa-calendar"></i> {[{invoice.date | date:'dd/MM/yy'}]}
                    </ span>
                     <i class="fa fa-credit-card"></i> {[{invoice.number | cut:true:30:'...' }]}
                      <span class="label label_danger" ng-if="invoice.not_pay"><translate>Not pay</translate></span>
                     <span class="negative-number" ng-if="invoice.status == 'canceled'">(<translate>{[{ invoice.status }]})</translate></span>
                      <span
                        class="badge label_danger tooltip smaller-label"
                        data-tip="With discount"
                        ng-if="invoice.discount != 100"
                        style="font-size: 6px !important"
                      >
                      <i class="fa fa-exclamation-triangle"></i>
                    </span>
                    <span
                      ng-if="invoice.invoice_type == 'incoming'"
                      class="smaller-label secondary-text"
                    >
                      <i class="fa fa-building"></i>
                      {[{ invoice.client_from_name | cut:true:30:'...' }]}</span
                    >

                    <span
                      ng-if="invoice.invoice_type == 'outgoing'"
                      class="smaller-label secondary-text"
                    >
                      <i class="fa fa-building"></i>
                      {[{ invoice.client_to_name | cut:true:30:'...' }]}</span
                    >
                    <div class="clearfix"></div>
                    </a>
                  </li>
                </ul>
              </div> `,
            totalTemplate: `
              <div class="td-right-align">
                <span ng-repeat="total in item.groupped_data">
                  <span
                    ng-class="{'negative-number': total.consolidated_invoiced > 0 && (total.price_per_deal - total.consolidated_invoiced > 0.1) }"
                  >
                    {[{ total.consolidated_invoiced || 0 | number:2}]}
                  </span>
                  <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Not invoiced'),
            columnName: 'not_invoiced',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.not_invoiced_local != 0">
                  {[{ item.not_invoiced_local | number:2 }]}
                </span>
                <span class="smaller-label" ng-if="item.not_invoiced_local != 0"> {[{ item.currency_symbol }]}</span>
                <div class="clearfix"></div>
              </div>
              `,
            totalTemplate: `
            <div class="td-right-align">
            <span ng-repeat="total in item.groupped_data" ng-if="total.not_invoiced_local !=0">
              <span
                ng-class="{'negative-number': total.invoiced > 0 && (total.not_invoiced_local > 0.1) }"
              >
                {[{ total.not_invoiced_local  || 0 | number:2}]}
                </span>
                <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
              <div class="clearfix"></div>
              </span>
            </span>
          </div>`,
          },
          {
            title: this.gettext('Totally not invoiced'),
            columnName: 'totally_not_invoiced',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.totally_not_invoiced_local != 0">
                  {[{ item.totally_not_invoiced_local | number:2 }]}
                </span>
                <span class="smaller-label" ng-if="item.totally_not_invoiced_local != 0"> {[{ item.currency_symbol }]}</span>
                <div class="clearfix"></div>
              </div>
              `,
            totalTemplate: `
            <div class="td-right-align">
            <span ng-repeat="total in item.groupped_data" ng-if="total.totally_not_invoiced_local !=0">
              <span
                ng-class="{'negative-number': total.invoiced > 0 && (total.totally_not_invoiced_local > 0.1) }"
              >
                {[{ total.totally_not_invoiced_local  || 0 | number:2}]}
                </span>
                <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
              <div class="clearfix"></div>
              </span>
            </span>
          </div>`,
          },
          {
            title: this.gettext('VAT'),
            columnName: 'vat',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="!item.vat_option"> </span>
                <span ng-if="item.vat_option"
                  >{[{ item.vat || 0 | number:2 }]} <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>
              </div>
              {[{$rootScope.user.settings.VAT_VALUE}]}
              <div ng-if="item._$editMode || !item.id" class="edit gt-test-edit-vat">
                <input type="checkbox" ng-model="item.vat_option" />
                <input
                  class="form-control"
                  placeholder="{[{ 'VAT'|translate }]}"
                  type="number"
                  ng-model="item.vat_value"
                  ng-init="item.vat_value = item.vat_value || args.$rootScope.user.settings.CHARGE_VAT_VALUE"
                  ng-if="item.vat_option"
                />
              </div> `,
            totalTemplate: `
              <div class="td-right-align">
                <span ng-repeat="total in item.groupped_data">
                  {[{ total.vat || 0 | number:2 }]}
                <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                <div class="clearfix"></div>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Counterparty amount'),
            columnName: 'counterparty_amount',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span
                  ng-if="item.counterparty_amount"
                  class="bigger-label label"
                  ng-class="{
                     'label-opacity': item.counterparty_amount == 0,
                     'negative-number': !item.is_gain,
                     'positive-number': item.is_gain
                  }"
                >
                  <i class="fa fa-minus" ng-if="!item.is_gain"></i>
                  <i ng-if="item.is_gain" class="fa fa-plus"></i>
                  {[{ item.counterparty_amount | number:2 }]}
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id " class="td-left-align">
                <input
                  class="form-control"
                  type="number"
                  placeholder="{[{ 'Counterparty amount'|translate }]}"
                  ng-model="item.counterparty_amount"
                />
              </div> `,
          },
          {
            title: this.gettext('Our amount'),
            columnName: 'our_amount',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span
                  ng-if="item.our_amount"
                  class="bigger-label label"
                  ng-class="{
                     'label-opacity': item.our_amount == 0,
                     'negative-number': !item.is_gain,
                     'positive-number': item.is_gain
                  }"
                >
                  <i class="fa fa-minus" ng-if="!item.is_gain"></i>
                  <i ng-if="item.is_gain" class="fa fa-plus"></i>
                  {[{ item.our_amount | number:2 }]}
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="td-left-align" >
                <input
                  class="form-control"
                  type="number"
                  placeholder="{[{ 'Our amount'|translate }]}"
                  ng-model="item.our_amount"
                  />
              </div> `,
          },
          {
            title: this.gettext('Final Amount'),
            columnName: 'final_amount',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span
                  ng-if="item.final_amount"
                  class="bigger-label label"
                  ng-class="{
                     'label-opacity': item.final_amount == 0,
                     'negative-number': !item.is_gain,
                     'positive-number': item.is_gain
                  }"
                >
                  <i class="fa fa-minus" ng-if="!item.is_gain"></i>
                  <i ng-if="item.is_gain" class="fa fa-plus"></i>
                  {[{ item.final_amount | number:2 }]}
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="td-left-align">
                <input
                  class="form-control"
                  type="number"
                  placeholder="{[{ 'Final amount'|translate }]}"
                  ng-model="item.final_amount"
                />
              </div> `,
          },
          {
            title: this.gettext('Analytical Amount'),
            columnName: 'analytical_amount',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span
                  ng-if="item.analytical_amount;"
                  class="bigger-label label"
                  ng-class="{
                     'label-opacity': item.analytical_amount == 0,
                     'negative-number': !item.is_gain,
                     'positive-number': item.is_gain
                  }"
                >
                  <i class="fa fa-minus" ng-if="!item.is_gain"></i>
                  <i ng-if="item.is_gain" class="fa fa-plus"></i>
                  {[{ item.analytical_amount | number:2 }]}
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="td-left-align">
                <input
                  class="form-control"
                  type="number"
                  placeholder="{[{ 'Analytical amount'|translate }]}"
                  ng-model="item.analytical_amount"
                />
              </div> `,
          },
          {
            title: this.gettext('Counterparty'),
            columnName: 'counterparty',
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'clientrole_list',
                label: this.gettext('Counterparty'),
                resource: 'clients.clientrole',
              },
            ],
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
              <span ng-if="item.clientrole_name"
              > <i class="fa fa-building"></i> {[{ item.clientrole_name || '---' | cut:true:30:'...' }]}</span>

                <span ng-if="item.clientrole_role_name"
                  >({[{ item.clientrole_role_name | cut:true:30:'...' }]})</span
                >
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit">
                <gt-resource-select
                  ng-model="item.clientrole"
                  placeholder="'Counterparty'|translate"
                  resource-name="'clients.clientrole'"
                  allow-clear="true"
                  query-params="{role_name_list:args.predictionsQueryParams.role_name_list}"
                ></gt-resource-select>
              </div> `,
          },
          {
            title: this.gettext('Currency'),
            columnName: 'currency',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.currency_symbol"> {[{ item.currency_symbol }]} </span>
                <span ng-if="item.exchange_rate"> {[{ item.exchange_rate | number:6 }]} </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit">
                <gt-resource-select
                  ng-model="item.currency"
                  placeholder="'Currency'|translate"
                  resource-name="'finances.Currency'"
                  allow-clear="false"

                ></gt-resource-select>
                <gt-resource-select
                  ng-model="item.currency_exchange"
                  placeholder="'Exchange rate'|translate"
                  resource-name="'finances.currencyexchange'"
                  allow-clear="true"
                  query-params="{local_currency: item.currency}"
                  ng-disable="!item.currency"

                ></gt-resource-select>
              </div> `,
          },
          {
            title: this.gettext('Payment amount'),
            columnName: 'payments',
            class: 'td-left-align minWidth',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
              <span
              ng-class="{'label': item.payments_amount_sum == item.price_per_deal && item.payments_amount_sum}"
            >
              <span
              ng-class="{ 'label-opacity': item.payments_amount_sum == 0}"
              ng-if="!item.is_gain"
            >
              <i class="fa fa-minus"></i> {[{ item.payments_amount_sum | number:2 }]}
              <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
            </span>
            <span
              ng-class="{ 'label-opacity': item.payments_amount_sum == 0}"
              ng-if="item.is_gain"
            >
              <i class="fa fa-plus"></i> {[{ item.payments_amount_sum | number:2 }]}
              <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
            </span>
            </span>
            <div class="clearfix"></div>
            <span class="badge label_success" ng-if="item.payments_amount_sum == item.price_per_deal && item.payments_amount_sum">
                  <i class="fa fa-check-circle" ></i>
            <translate>full paid</translate>
            </span>
             <div class="clearfix"></div>
                <span ng-if="item.payments_amount_sum" class="secondary-text smaller-label pull-left">
                  <span ng-repeat="date in item.payments_date_list"
                    ><i class="fa fa-calendar"></i> {[{ date | date:'dd/MM/yy' }]}
                    <div class="clearfix"></div>
                  </span>
                  <div class="clearfix"></div>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit"></div> `,
            totalTemplate: `
                <div class="td-right-align">
                <span ng-repeat="total in item.groupped_data" ng-if="total.payments_amount_sum !=0">
                  <span
                    ng-class="{'negative-number': total.invoiced > 0 && (total.payments_amount_sum > 0.1) }"
                  >
                    {[{ total.payments_amount_sum  || 0 | number:2}]}
                    </span>
                    <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                  </span>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Payment bank account'),
            columnName: 'payments_bankaccount_bank_name_list',
            class: 'td-left-align minWidth',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <span ng-repeat="name in item.payments_bankaccount_bank_name_list track by $index">
              <i class="fa fa-university"></i> {[{ name }]}
                <div class="clearfix"></div>
              </span>
              `,
          },
          {
            title: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
            columnName: 'passport',
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'passport_list',
                label: this.gettext('Passport'),
                resource: 'passports.passport',
              },
            ],
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.passport_title" class="label label_info pull-left">
                  <i class="fa fa-exchange"></i> {[{ item.passport_title | cut:true:35:'...'}]}
                  <div class="clearfix"></div>
                </span>
                <ul class="inside-table-ul" ng-if="!item.passport_title">
                  <li ng-repeat="passport in item.contract_passport_list">
                    <i class="fa fa-file-text"></i> <i class="fa fa-exchange"></i> {[{ passport.title |
                    cut:true:35:'...' }]}
                  </li>
                </ul>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit" >
              <div ng-if="!(item.contract && $root.user.settings.PASSPORT_COSTS_VALIDATE) && !item.multicontract">
                <gt-resource-select
                  ng-model="item.passport"
                  placeholder="'Passport'|translate"
                  resource-name="'passports.passport'"
                  query-params="{ id_list: args.queryParams.passport || item.with_passport || args.charges[0].with_passport, contract: args.queryParams.contract }"
                  allow-clear="true"
                  on-select="args.onPassportSelect"
                  on-select-args="[item]"
                ></gt-resource-select>
                <div>
              </div>  `,
          },
          {
            title: this.gettext('Logistic'),
            columnName: 'logistic',
            filters: [
              {
                type: 'ui-multiselect',
                predicate: 'logistic_list',
                label: this.gettext('Logistic'),
                resource: 'logistics.logistic',
              },
            ],
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span ng-if="item.logistic_number" class="label label_info pull-left">
                  <a ui-sref="gt.page.logistic({id: item.logistic})">
                    <i class="fa fa-truck"></i> {[{ item.logistic_number | cut:true:35:'...'}]}
                  </a>
                  <div class="clearfix"></div>
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit" >
                <gt-resource-select
                  ng-model="item.logistic"
                  placeholder="'Logistic'|translate"
                  resource-name="'logistics.logistic'"
                  allow-clear="true"
                ></gt-resource-select>
              </div>  `,
          },
          {
            title: this.gettext('Multicontract'),
            columnName: 'multicontract',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <a
                ng-if="item.multicontract_number"
                ui-sref="gt.multicontract({id: item.multicontract})"
              >
                <i class="fa fa-file-text"></i> {[{item.multicontract_number | cut:true:40:'...' || "---"
                }]}
              </a>
              <div ng-if="!item.id" class="edit" >
                <gt-resource-select
                  ng-model="item.multicontract"
                  placeholder="'Multicontract'|translate"
                  resource-name="'contracts.multicontract'"
                  query-params="{ id_list: args.queryParams.multicontract_list }"
                  allow-clear="true"
                ></gt-resource-select>
              </div>  `,
          },
          {
            title: this.gettext('Create'),
            columnName: 'create',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span>
                  <i class="fa fa-calendar"></i> {[{ item.create_time | date:'dd/MM/yy' || '---' }]}
                </span>
                <span class="tooltip" data-tip="{[{ item.author_first_name }]}">
                  <div
                    class="user-avatar"
                    ng-if="item.author_first_name && item.author_avatar.length != 0"
                  >
                    <img ng-src="/pictures/{[{item.author_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="item.author_avatar.length == 0">
                    {[{ item.author_first_name | cut:true:1:' ' }]}
                  </div>
                </span>
              </div>
               `,
          },
          {
            title: this.gettext('Update'),
            columnName: 'update',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span>
                  <i class="fa fa-calendar"></i> {[{ item.update_time | date:'dd/MM/yy' || '---' }]}
                </span>
                <span class="tooltip" data-tip="{[{ item.editor_first_name }]}">
                  <div
                    class="user-avatar"
                    ng-if="item.editor_first_name && item.editor_avatar.length != 0"
                  >
                    <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="item.editor_avatar.length == 0">
                    {[{ item.editor_first_name | cut:true:1:' ' }]}
                  </div>
                </span>
              </div>
               `,
          },
          {
            title: this.gettext('Trader note'),
            columnName: 'trader_note',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                  <span class="smaller-label secondary-text wrap-text" ng-if="item.trader_note">
                  <i class="fa fa-comments"></i>T: {[{ item.trader_note }]}
                  </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit">
                  <input
                  class="form-control gt_test_field_trader_note"
                  placeholder="{[{ 'Trader note'|translate }]}"
                  type="text"
                  ng-model="item.trader_note"

                />
            </div>
            `,
          },
          {
            title: this.gettext('Responsible'),
            columnName: 'responsible',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
             <div ng-if="!item._$editMode && item.id" class="td-left-align" >
                <span class="tooltip" data-tip="{[{ item.responsible_first_name }]}">
                  <div
                    class="user-avatar"
                    ng-if="item.responsible_first_name && item.responsible_avatar.length != 0"
                  >
                    <img ng-src="/pictures/{[{item.responsible_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="item.responsible_avatar.length == 0">
                    {[{ item.responsible_first_name | cut:true:1:' ' }]}
                  </div>
                   {[{ item.responsible_first_name }]} {[{ item.responsible_last_name }]}
                </span>
              </div>
              <div ng-if="item._$editMode || !item.id" class="edit">
              <gt-resource-select
                  ng-model="item.responsible"
                  placeholder="'Responsible'|translate"
                  resource-name="'auth.User'"
                  allow-clear="false"
                ></gt-resource-select>
              </div>
            `,
          },
          {
            title: this.gettext('Balance to settlement'),
            columnName: 'settlement_balance',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id" class="td-left-align">
                <span
                  ng-if="item.settlement_balance_local"
                  class="bigger-label label pull-right"
                  ng-class="{
                     'label-opacity': item.settlement_balance_local == 0,
                     'negative-number': !item.is_gain,
                     'positive-number': item.is_gain
                  }"
                >
                  <i class="fa fa-minus" ng-if="!item.is_gain"></i>
                  <i ng-if="item.is_gain" class="fa fa-plus"></i>
                  {[{ item.settlement_balance_local | number:2 }]}
                  <span class="smaller-label"> {[{ item.currency_symbol }]} </span>
                </span>

              </div> `,
            totalTemplate: `
                <div class="td-right-align">
                <span ng-repeat="total in item.groupped_data" ng-if="total.settlement_balance_local !=0">
                  <span
                    ng-class="{'negative-number': total.invoiced > 0 && (total.settlement_balance_local > 0.1) }"
                  >
                    {[{ total.settlement_balance_local  || 0 | number:2}]}
                    </span>
                    <span class="smaller-label"> {[{ total.currency_symbol }]}</span>
                  <div class="clearfix"></div>
                  </span>
                </span>
              </div>`,
          },
          {
            title: this.gettext('Documents'),
            columnName: 'documents',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `

              <div
              class="btn-link"
              ng-if="item.documents && item.documents.length > 3"
              ng-click="item.BlDocsExpanded = !item.BlDocsExpanded"
            >
              <i class="fa fa-download"></i> {[{item.documents.length}]}
            </div>
              <div ng-if="item.documents && item.documents.length <= 3">
              <div class="clearfix"></div>
              <div ng-repeat="doc in item.documents">
                <a
                  href="{[{doc.file}]}"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ doc.title }]}"
                  target="_blank"
                >
                  <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                </a>
              </div>
            </div>

            <div ng-if="item.BlDocsExpanded">
            <div class="clearfix"></div>
              <div ng-repeat="doc in item.documents">
                <a
                  href="{[{doc.file}]}"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ doc.title }]}"
                  target="_blank"
                >
                  <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                </a>
                <div class="clearfix"></div>
              </div>
            </div>
              `,
          },
          {
            title: this.gettext('Invoice documents'),
            columnName: 'invoiceDocuments',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
            <div
              class="btn-link"
              ng-if="item.invoice_documents && item.invoice_documents.length > 3"
              ng-click="item.InvDocsExpanded = !item.InvDocsExpanded"
            >
              <i class="fa fa-download"></i> {[{item.invoice_documents.length}]}
            </div>
              <div ng-if="item.invoice_documents && item.invoice_documents.length <= 3">
              <div class="clearfix"></div>
              <div ng-repeat="doc in item.invoice_documents">
                <a
                  href="{[{doc.file}]}"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ doc.title }]}"
                  target="_blank"
                >
                  <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                </a>
              </div>
            </div>

            <div ng-if="item.InvDocsExpanded">
            <div class="clearfix"></div>
              <div ng-repeat="doc in item.invoice_documents">
                <a
                  href="{[{doc.file}]}"
                  class="btn-link smaller-label tooltip"
                  data-tip="{[{ doc.title }]}"
                  target="_blank"
                >
                  <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                </a>
                <div class="clearfix"></div>
              </div>
            </div>
              `,
          },
          {
            title: this.gettext('Product'),
            columnName: 'commodity',
            class: 'td-left-align',
            classExpr: "{ 'edit': item._$editMode || !item.id }",
            cellTemplate: /*html*/ `
            <div ng-if="!item._$editMode && item.id" class="td-left-align" >
              <span ng-if="item.commodity">
                <a ui-sref="gt.page.crop({id: item.commodity })">
                  <i class="fa fa-file-text"></i> {[{ item.commodity_title }]}
                </a>
              </span>
            </div>
            <div ng-if="item._$editMode || !item.id"  class="edit">
              <gt-resource-select
                ng-model="item.commodity"
                resource-name="'crops.Crop'"
                allow-clear="true"
              ></gt-resource-select>
            </div>`,
          },
          {
            title: this.gettext('Clientrole from'),
            columnName: 'clientrole_from',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span ng-if="item.finances.length" ng-repeat="invoice in item.finances">
                    <span ng-if="invoice.client_from_name"><i class="fa fa-person"></i> {[{ invoice.client_from_name }]}</span>
                </span>
              </div>
            `,
          },
          {
            title: this.gettext('Clientrole to'),
            columnName: 'clientrole_to',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span ng-if="item.finances.length" ng-repeat="invoice in item.finances">
                    <span ng-if="invoice.client_to_name"><i class="fa fa-person"></i> {[{ invoice.client_to_name }]}</span>
                </span>
              </div>
            `,
          },
          {
            title: this.gettext('Bank account'),
            columnName: 'bank_account',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span ng-if="item.finances.length" ng-repeat="invoice in item.finances">
                    <span ng-if="invoice.bank_account_name"><i class="fa fa-bank"></i> {[{ invoice.bank_account_name }]}</span>
                </span>
              </div>
            `,
          },
          {
            title: this.gettext('Counterparty payment info'),
            columnName: 'counterpaty_payment_info',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span ng-if="item.finances.length" ng-repeat="invoice in item.finances">
                    <span ng-if="invoice.payment_account"><i class="fa fa-credit-card-alt"></i> {[{ invoice.payment_account }]}</span>
                </span>
              </div>
            `,
          },
          {
            title: this.gettext('Parent charge'),
            columnName: 'parent_charge_title',
            class: 'td-left-align minWidth',
            cellTemplate: /*html*/ `
              <div ng-if="!item._$editMode && item.id">
                <span ng-if="item.parent_charge_title"><i class="fa fa-money"></i> {[{ $root.user.profile.language === 'en' && item.parent_charge_title_eng || item.parent_charge_title }]}</span>
              </div>
            `,
          },
          {
            title: this.gettext('Charge basis'),
            columnName: 'charge_basis_list',
            class: 'td-left-align minWidth',
            classExpr: "{ 'edit': args.rows[item.id].editable || !item.id }",
            cellTemplate: /*html*/ `
              <span ng-repeat="name in item.basis_codename_list track by $index">
                {[{ name }]}
              </span>
              <div class="clearfix"></div>
              <span
                class="label label_warning"
                ng-if="item.is_different_basis_passport_contract"
              >
                <i class="fa fa-exclamation-triangle"></i>
                <span
                  ng-repeat="name in item.passport_ctrs_basis_codename_list track by $index"
                >
                  {[{ name }]}
                </span>
              </span>
            `,
          },
        ];

        return this.getCustomFieldTableColumns().then((columns: any) => {
          options.columnDefs.push(...columns);
          this.tableOptions = options;
        });
      }
    },
  ],
};
