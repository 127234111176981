import template from './transportation-storage-expense-position-form.html?raw';
export const TransportationStorageExpensePositionForm = {
  bindings: {
    position: '<',
    updateFieldsTrigger: '<',
    formValidChange: '&',
    calculateTotals: '&',
    changeIsActive: '&',
    getPositionContractNumber: '&',
    positionFormName: '<',
    readonly: '<?',
  },
  template,
  controller: [
    '$scope',
    'TransportationStorageExpenseFormFieldsService',
    class {
      $scope: ng.IScope;
      TransportationStorageExpenseFormFieldsService: any;
      calculateTotals: any;
      changeIsActive: any;
      fields: any;
      formValidChange: any;
      getPositionContractNumber: any;
      position: any;
      positionFormName: any;
      readonly: any;
      unwatchChangeIsActive: any;
      unwatchForm: any;
      unwatchPositionAutoName: any;
      unwatchPositionFinalVolume: any;
      constructor($scope: ng.IScope, TransportationStorageExpenseFormFieldsService: any) {
        this.$scope = $scope;
        this.TransportationStorageExpenseFormFieldsService =
          TransportationStorageExpenseFormFieldsService;
        this.fields = [];
        this.unwatchForm = () => {};
        this.calculateTotals = () => {};
        this.changeIsActive = () => {};
        this.getPositionContractNumber = () => {};
        this.formValidChange = () => {};
        this.positionFormName = '';
        this.position = {};
        this.readonly = false;
      }
      $onInit() {
        this.unwatchPositionAutoName = this.$scope.$watch('$ctrl.position.auto_name', () => {
          if (this.position.auto_name) {
            this.getPositionContractNumber(this.position);
          }
        });
        this.unwatchPositionFinalVolume = this.$scope.$watch('$ctrl.position.final_volume', () =>
          this.calculateTotals(),
        );
        this.unwatchChangeIsActive = this.$scope.$watch('$ctrl.position.status', () => {
          this.changeIsActive(this.position);
          this.calculateTotals();
        });
      }
      $onChanges(changes: any) {
        if (
          (changes.updateFieldsTrigger && this.positionFormName) ||
          changes.positionFormName ||
          changes.position
        ) {
          this.TransportationStorageExpenseFormFieldsService.getPositionFields(
            this.position,
            this.positionFormName,
          ).then((fields: any) => {
            this.fields = fields.filter((item: any) => {
              if (
                Array.isArray(item.fieldGroup[0].fieldGroup) &&
                item.fieldGroup[0].fieldGroup.length
              ) {
                return !item.fieldGroup[0].fieldGroup.every(
                  (field: any) =>
                    typeof field.hideExpression === 'function' && field.hideExpression(),
                );
              }
              return true;
            });
          });
          this.unwatchForm();
          this.unwatchForm = this.$scope.$watch('$ctrl.form.$valid', (newValue: any) => {
            this.position.$formValid = newValue;
            this.formValidChange();
          });
        }
      }
      $onDestroy() {
        this.unwatchForm();
        this.unwatchPositionFinalVolume();
        this.unwatchPositionAutoName();
        this.unwatchChangeIsActive();
      }
    },
  ],
};
