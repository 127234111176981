import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

export const Calendar = {
  bindings: {},
  template: `
      <gt-calendar
          events="$ctrl.data"
          on-event-click="$ctrl.handlerEventClick(event)"
          on-event-drop="$ctrl.handlerEventDrop(event)"
      >
      </gt-calendar> `,

  controller: [
    'CalendarService',
    'GtUtils',
    class {
      CalendarService: any;
      GtUtils: GtUtilsService;
      data: any;
      constructor(CalendarService: any, GtUtils: GtUtilsService) {
        this.CalendarService = CalendarService;
        this.GtUtils = GtUtils;
        this.data = [];
      }

      $onInit() {
        // @ts-ignore
        this.updateData();
      }

      updateData(params: any) {
        return this.CalendarService.getEvents(params).then(
          (data: any) => {
            this.data = data.results;
          },
          (data: any) => {
            this._error(data);
          },
        );
      }

      _error(data: any) {
        this.GtUtils.errorClb(data);
        this.GtUtils.overlay('hide');
      }

      handlerEventClick(event: any) {
        console.info('handlerEventClick', event);
      }

      handlerEventDrop(event: any) {
        console.info('handlerEventDrop', event);
      }
    },
  ],
};
