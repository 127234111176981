import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy')
    .directive('taurisPrint', taurisPrintDir)
    .directive('scrollup', scrollupDir);

  // FIX: remove this file

  taurisPrintDir.$inject = ['$window'];

  function taurisPrintDir($window: ng.IWindowService) {
    return {
      restrict: 'A',
      link: function (scope: any, elem: any) {
        elem.on('click', function (event: any) {
          event.preventDefault();
          $window.print();
        });
      },
    };
  }

  scrollupDir.$inject = ['$document', '$timeout'];

  function scrollupDir($document: any, $timeout: ng.ITimeoutService) {
    return {
      restrict: 'A',
      link: function (scope: any, elm: any) {
        elm.bind('click', function () {
          // Maybe abstract this out in an animation service:
          // Ofcourse you can replace all this with the jQ
          // syntax you have above if you are using jQ
          function scrollToTop(element: any, to: any, duration: any) {
            if (duration < 0) {
              return;
            }
            const difference = to - element.scrollTop;
            const perTick = difference / duration;

            $timeout(function () {
              element.scrollTop = element.scrollTop + perTick * 10;
              scrollToTop(element, to, duration - 10);
            }, 10);
          }

          // then just add dependency and call it
          scrollToTop($document[0].body, 0, 400);
        });
      },
    };
  }
})();
