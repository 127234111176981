import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('confirmationDocumentsContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
    },
    template: require('./confirmation-documents-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'ClientsService', 'gtFilterService', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    ClientsService: any,
    gtFilterService: GtFilterService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.confirmationDocuments = [];
    vm.confirmationDocumentsCount = 0;
    vm.updateData = updateData;
    vm.save = save;
    vm.destroy = destroy;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'confirmation-documents-container';
      vm.queryParams = { ...vm.initQueryParams };
      $scope.$on('docs_updated', function () {
        updateData();
      });
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    ////////////////

    function updateData() {
      GtUtils.overlay('show');
      return ClientsService.ClientConfirmationDocument.query(vm.queryParams, function (data: any) {
        vm.confirmationDocuments = data.results;
        vm.confirmationDocumentsCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function save(confirmationDocument: any) {
      vm.errors = null;
      return ClientsService.ClientConfirmationDocument.update(
        confirmationDocument,
        function () {
          vm.edit = false;
          return updateData();
        },
        _error,
      ).$promise;
    }

    function destroy(confirmationDocumentId: any) {
      if (!confirm(gettext('Are you sure that you want delete?'))) {
        return;
      }
      return ClientsService.ClientConfirmationDocument.delete(
        { id: confirmationDocumentId },
        updateData,
        _error,
      ).$promise;
    }

    function _error(data: any) {
      vm.errors = data.data;
    }
  }
})();
