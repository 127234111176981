import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './terminal-transshipment-tariff-container.html?raw';

export const TerminalTransshipmentTariffContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'TerminalTransshipmentTariffService',
    'GtUtils',
    'CoreService',
    'gettext',
    class {
      $scope: ng.IScope;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      TerminalTransshipmentTariffService: any;
      buQueryParams: QueryParams = {};
      count: number;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      tariffs: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        TerminalTransshipmentTariffService: any,
        GtUtils: GtUtilsService,
        CoreService: CoreService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.TerminalTransshipmentTariffService = TerminalTransshipmentTariffService;
        this.GtUtils = GtUtils;
        this.CoreService = CoreService;
        this.gettext = gettext;

        this.filterLevel = 'terminal-transshipment-tariff-container-filter-level';
        this.queryParams = {};
        this.tariffs = [];
        this.buQueryParams = {};
        this.count = 0;
      }

      $onInit() {
        this.buQueryParams = this.GtUtils.getBUQueryParams();
        Object.assign(this.queryParams, this.initQueryParams);
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTariffs();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTariffs() {
        this.GtUtils.overlay('show');
        const newTariff = this.TerminalTransshipmentTariffService.getNewTariff(
          this.initQueryParams,
        );
        return this.TerminalTransshipmentTariffService.getTariffs(this.queryParams).then(
          (data: any) => {
            this.tariffs = data.results;
            this.tariffs.unshift(newTariff);
            this.count = data.count;
            this.GtUtils.overlay('hide');
          },
        ).$promise;
      }

      saveTariff(tariff: any) {
        return this.TerminalTransshipmentTariffService.saveTariff(tariff)
          .then(() => this.updateTariffs())
          .catch((error: any) => this.GtUtils.errorClb(error));
      }

      deleteTariff(tariff: any) {
        if (!confirm(this.gettext('Are you sure that you want delete Tariff?'))) {
          return;
        }
        return this.TerminalTransshipmentTariffService.deleteTariff(tariff)
          .then(() => this.updateTariffs())
          .catch((error: any) => this.GtUtils.errorClb(error));
      }

      applyFilters() {
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }
    },
  ],
};
