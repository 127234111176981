import template from './transportation-storage-expense-view.html?raw';
export const TransportationStorageExpenseView = {
  bindings: {
    filterLevel: '<?',
    expense: '<',
    fields: '<',
    tab: '<?',
    openModal: '&',
    clone: '&',
    createInvoice: '&',
    changeTab: '&',
  },
  template,
  controller: [
    class {
      expense: any;
      filterLevel: string;
      tab: any;
      constructor() {
        this.tab = [];
        this.expense = {};
        this.filterLevel = '';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'transportation-storage-expense-view';
      }
    },
  ],
};
