import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { DriverDetailsItemContainer } from './item-container/driver-details-item-container.component';
import { DriverDetailsItemPage } from './item-page/driver-details-item-page.component';
import { DriverDetailsItemView } from './item-view/driver-details-item-view.component';

const driverConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('driverDetails', {
      parent: 'gt.page',
      url: '/driver-details/:id',
      component: 'driverDetailsItemPage',
      resolve: {
        filterLevel: () => {
          return 'driver-details-item-page';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('driver-details-item-page');
          },
        ],
      },
      data: {
        pageTitle: gettext('Driver details'),
      },
    });
  },
];

export const driverDetails = ng
  .module('transport.drivers.details', [])
  .component('driverDetailsItemPage', DriverDetailsItemPage)
  .component('driverDetailsItemContainer', DriverDetailsItemContainer)
  .component('driverDetailsItemView', DriverDetailsItemView)
  .config(driverConfig).name;
