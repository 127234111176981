import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Input, type InputProps } from '~/shared/ui/kit/input';

import { type DataTypeProps, messageIntent } from '../../lib';

export const LabelWrite: React.FC<DataTypeProps<string> & InputProps> = ({
  value,
  valueChanged,
  ref,
  disabled,
  message,
  ...props
}) => {
  return (
    <div className={cn('h-full w-full p-1', message && messageIntent[message.type])}>
      <Input
        intent="transparent"
        ref={ref}
        onBlur={(e) => valueChanged?.(e.target.value)}
        defaultValue={value}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};
