import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

export const SalePlanReportPage = {
  bindings: {},
  template: `
    <sale-plan-report-container filter-level="$ctrl.filterLevel"></sale-plan-report-container>
  `,
  controller: [
    'PageService',
    'ReportsService',
    'gettext',
    class {
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      constructor(
        PageService: PageService,
        ReportsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gettext = gettext;

        this.filterLevel = 'sale-plan-report-page-view';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.Filters(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            search: false,
            dates: true,
            dateSelects: [
              {
                argument: 'start_date',
                placeholder: this.gettext('start date'),
              },
              {
                argument: 'end_date',
                placeholder: this.gettext('end date'),
              },
            ],
            multiSelects: [
              {
                argument: 'commodity_list',
                placeholder: this.gettext('commodity'),
                resource: 'crops.Crop',
              },
              {
                argument: 'currency_list',
                placeholder: this.gettext('currency'),
                resource: 'finances.Currency',
              },
            ],
            nestedSelects: [],
            nestedMultiSelects: [
              {
                argument: 'production_year_list',
                placeholder: this.gettext('production year'),
                items: [
                  { id: '2019', title: this.gettext('2019') },
                  { id: '2020', title: this.gettext('2020') },
                  { id: '2021', title: this.gettext('2021') },
                  { id: '2022', title: this.gettext('2022') },
                ],
              },
            ],
          },
        };
      }
    },
  ],
};
