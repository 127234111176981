import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { intermodalTransportsListContainer } from './components/intermodal-transports-list-container/intermodal-transports-list-container.component';
import { intermodalTransportsPage } from './components/intermodal-transports-page/intermodal-transports-page.component';
import { IntermodalTransportsService } from './intermodal-transports.service';

const intermodalTransportsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('logistics.transfer', {
      url: '/transfer',
      component: 'intermodalTransportsPage',
      data: {
        pageTitle: gettext('Transfer'),
      },
      resolve: {
        filterLevel: () => {
          return 'intermodal-transports-page';
        },
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('intermodal-transports-page');
          },
        ],
      },
    });
  },
];

export const intermodalTransports = ng
  .module('execution.transport.intermodalTransports', [])
  .component('intermodalTransportsPage', intermodalTransportsPage)
  .component('intermodalTransportsListContainer', intermodalTransportsListContainer)
  .service('IntermodalTransportsService', IntermodalTransportsService)
  .config(intermodalTransportsConfig).name;
