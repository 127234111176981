import type ng from 'angular';

export class ClientInvoicePaymentReconciliationReportService {
  $http: ng.IHttpService;
  $httpParamSerializer: any;
  $window: ng.IWindowService;
  ReportsService: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $http: ng.IHttpService,
    $window: ng.IWindowService,
    $httpParamSerializer: any,
    ReportsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$http = $http;
    this.$window = $window;
    this.$httpParamSerializer = $httpParamSerializer;
    this.ReportsService = ReportsService;
    this.gettext = gettext;
  }
  getReportData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/client-invoice-payment-reconciliation-report/',
      params: params,
    }).then((response: any) => this.ReportsService.unpackReportDataFromTree(response.data));
  }

  downloadXlsxReport(params: any) {
    this.$window.open(
      `/api/reports/client-invoice-payment-reconciliation-report/?${this.$httpParamSerializer({
        ...params,
        as_xlsx: 1,
      })}`,
      '_blank',
    );
  }
}

ClientInvoicePaymentReconciliationReportService.$inject = [
  '$http',
  '$window',
  '$httpParamSerializer',
  'ReportsService',
  'gettext',
];
