import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './fixing-hedging-list-container.html?raw';

export const FixingHedgingListContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    applyFilters: '&',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'gettext',
    'OperationsService',
    'gtFilterService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      OperationsService: any;
      applyFilters: any;
      data: any;
      filterLevel = 'fixing-hedging-list-container';
      gettext: ng.gettext.gettextFunction;
      group: any;
      groups: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      newOperation: any;
      queryParams: QueryParams = {};
      showFixing: any;
      tableData: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        OperationsService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.OperationsService = OperationsService;
        this.gtFilterService = gtFilterService;
        this.showFixing = true;
        this.newOperation = undefined;
        this.tableOptions = {};
        this.tableData = {};
        this.groups = [
          {
            name: 'passport',
            title: 'passport',
            icon: 'fa fa-exchange',
            dataPrepareFunction: this.updatePassportGroupedData,
            tablePrepareFunction: this.getPassportGroupedTableOptions,
          },
          {
            name: 'derivative',
            title: 'derivative',
            icon: 'fa fa-bolt',
            dataPrepareFunction: () => this.data,
            tablePrepareFunction: this.getTableOptions,
          },
        ];
        this.group = this.groups[0];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'fixing-hedging-list-container';
        this.tableOptions = this.group.tablePrepareFunction.call(this);
        this.initQueryParams = {
          hide_inactive_accounts: 1,
          hide_passport_whithout_accounts: 1,
        };
        this.queryParams = {
          hide_not_fixed_lost: 0,
          page_size: 9999,
          ...this.initQueryParams,
        };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.OperationsService.getFixingHedgingReportData(this.queryParams).then((data: any) => {
          this.data = { rows: data.results, count: data.count };
          this.GtUtils.overlay('hide');
          this.tableData = this.group.dataPrepareFunction.call(this);
        });
      }

      resetFilters() {
        this.gtFilterService.setQueryParams(
          {
            page_size: 20,
            ...this.initQueryParams,
          },
          this.filterLevel,
        );
      }

      getTableOptions = () => {
        return {
          tableName: 'fixing-hedging-list',
          configurable: true,
          tableClass: 'table-hover fixing-hedging-report',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          templateArgs: {
            showFixing: this.showFixing,
            group: this.group,
          },
          columnDefs: [
            {
              columnName: 'title',
              class: 'td-left-align',
              title: this.gettext(''),
              cellTemplate: `
                <span class="pull-left"> {[{ item.title }]} </span>
                <div class="clearfix"></div>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" style="margin-left:15px;" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span class="smaller-label"> # {[{ contract.contract_number }]} </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
                <div class="clearfix"></div>
                <span
                  ng-click="args.showFixing = !args.showFixing"
                  class="pull-right btn btn-sm btn-dark-border"
                  style="padding: 0; margin-left: 10px"
                >
                  <span ng-class="{ 'label-opacity': !item.fixing_contracts.length}">
                    <i class="fa fa-expand" ng-if="!args.showFixing"></i>
                    <i class="fa fa-compress" ng-if="args.showFixing"></i>
                  </span>
                </span>
              `,
            },
            {
              columnName: 'contract_details',
              class: 'td-right-align',
              title: this.gettext('Contract details'),
              cellTemplate: `
                <span ng-if="args.showFixing"> --- </span>
                <div class="clearfix" ng-if="args.showFixing"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span class="smaller-label label-smoke">
                      <span
                        ng-if="contract.contract_type == 'purchase' && contract.supplier_name"
                        class="smaller-label"
                      >
                        <i class="fa fa-bookmark-o"></i> {[{ contract.supplier_name || '---' }]}
                      </span>
                      <span
                        ng-if="contract.contract_type == 'sale' && contract.buyer_name"
                        class="smaller-label"
                        
                      >
                        <i class="fa fa-bookmark"></i> {[{ contract.buyer_name || '---' }]}
                      </span>
                      <span
                        ng-repeat="passport in contract.passport_list"
                        class="smaller-label"
                        
                      >
                        <i class="fa fa-ship"></i> {[{ passport.vessel_name || '---' |
                        cut:true:50:'...' }]} <i class="fa fa-calendar"></i> {[{
                        passport.voyage_eta || '---' | date:'dd/MM/yy' }]} {[{
                        passport.voyage_status || '---' }]}
                      </span>
                    </span>
                    <span class="smaller-label" >
                      <span ng-if="contract.volume_estimated_open"
                        >est. {[{contract.volume_estimated_open || 0 | number: 3 }]} /
                      </span>
                      {[{ contract.volume || 0 | number: 3 }]} {[{
                      $rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} 
                      <i class="fa fa-wheat-awn"></i> {[{ contract.cargo_title
                      || '---' }]}
                    </span>
                    <span class="smaller-label" >
                      <i class="fa fa-calendar"></i> {[{ contract.date_of_execution || "---" |
                      date:'dd/MM/yy' }]} - {[{ contract.end_of_execution || "---" |
                      date:'dd/MM/yy' }]}
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'account_list',
              class: 'td-right-align',
              title: this.gettext('Account'),
              cellTemplate: `
                <span ng-if="args.showFixing"> --- </span>
                <div class="clearfix" ng-if="args.showFixing"></div>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span class="smaller-label">
                      <span
                        class="smaller-label"
                        
                        ng-if="!contract.hedged_account_number_list.length"
                        >---</span
                      >
                      <span
                        ng-repeat="hedged_account_number in contract.hedged_account_number_list"
                        class="smaller-label"
                        
                      >
                        {[{ hedged_account_number || '---' | cut:true:50:'...' }]}
                      </span>
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'passport_operation_list',
              class: 'td-right-align',
              title: this.gettext('Passport operation'),
              cellTemplate: `
                <span ng-if="args.showFixing"> --- </span>
                <div class="clearfix" ng-if="args.showFixing"></div>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span class="smaller-label">
                      <span
                        class="smaller-label"
                        
                        ng-if="!contract.hedged_passport_title_list.length"
                        >---</span
                      >
                      <span
                        ng-repeat="hedged_passport_title in contract.hedged_passport_title_list"
                        class="smaller-label"
                        
                      >
                        <i class="fa fa-exchange"></i> {[{ hedged_passport_title || '---' |
                        cut:true:50:'...' }]}
                      </span>
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'passport_contract_list',
              class: 'td-right-align',
              title: this.gettext('Passport contract'),
              cellTemplate: `
                <span ng-if="args.showFixing"> --- </span>
                <div class="clearfix" ng-if="args.showFixing"></div>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span class="smaller-label">
                      <span
                        class="smaller-label"
                        
                        ng-if="!contract.passport_list.length"
                        >---</span
                      >
                      <span
                        ng-repeat="passport in contract.passport_list"
                        class="smaller-label"
                        
                      >
                        <i class="fa fa-exchange"></i> {[{ passport.title || '---' |
                        cut:true:50:'...' }]}
                      </span>
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'stock_market_price',
              class: 'td-right-align',
              title: this.gettext('Last price'),
              cellTemplate: `
                <span ng-if="item.stock_market_price">
                  {[{ item.stock_market_price | number:2 }]}
                  <span ng-if="item.point_value != 100">
                    <translate>cent</translate>
                  </span>
                  {[{ item.currency_symbol }]}/{[{ item.measurement_title }]}
                </span>
                <span ng-if="!item.stock_market_price"> --- </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span class="smaller-label" ng-if="contract.contract_flat_price">
                      {[{ contract.contract_flat_price || 0 | number: 2 }]}
                    </span>
                    <span class="smaller-label" ng-if="!contract.contract_flat_price"> --- </span>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'to_fixed_tonnes',
              class: 'td-right-align',
              title: this.gettext('To fix tonnes'),
              cellTemplate: `
                <span ng-class="{'label-opacity': !item.to_fixed_quantity }">
                  {[{ item.to_fixed_quantity || 0 | gtDecimal: 3}]}
                  <span class="smaller-label"> <translate>t</translate> </span>
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': contract.to_fixed_quantity == 0 }"
                    >
                      {[{ contract.to_fixed_quantity || 0 | gtDecimal: 3}]}
                      <span class="smaller-label"> <translate>t</translate> </span>
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'to_fixed_lots',
              class: 'td-right-align',
              title: this.gettext('To fix lots'),
              cellTemplate: `
                <span ng-class="{'label-opacity': !item.to_fixed_quantity_lots }">
                  {[{ item.to_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': contract.to_fixed_quantity_lots == '0' }"
                    >
                      {[{ contract.to_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'fixed_tonnes',
              class: 'td-right-align',
              title: this.gettext('Fixed tonnes'),
              cellTemplate: `
                <span ng-class="{'label-opacity': item.fixed_quantity == '0' }">
                  {[{ item.fixed_quantity || 0 | gtDecimal: 3}]}
                  <span class="smaller-label"> <translate>t</translate> </span>
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': !contract.fixed_quantity }"
                    >
                      {[{ contract.fixed_quantity || 0 | gtDecimal: 3}]}
                      <span class="smaller-label"> <translate>t</translate> </span>
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'fixed_lots',
              class: 'td-right-align',
              title: this.gettext('Fixed lots'),
              cellTemplate: `
                <span ng-class="{'label-opacity': !item.fixed_quantity_lots }">
                  {[{ item.fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': !contract.fixed_quantity_lots }"
                    >
                      {[{ contract.fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'not_fixed_tonnes',
              class: 'td-right-align',
              title: this.gettext('Not fixed tonnes'),
              cellTemplate: `
                <span ng-class="{'label-opacity': item.not_fixed_quantity == '0' }">
                  {[{ item.not_fixed_quantity || 0 | gtDecimal: 3}]}
                  <span class="smaller-label"> <translate>t</translate> </span>
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': contract.not_fixed_quantity == 0 }"
                    >
                      {[{ contract.not_fixed_quantity || 0 | gtDecimal: 3}]}
                      <span class="smaller-label"> <translate>t</translate> </span>
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
            {
              columnName: 'not_fixed_lots',
              class: 'td-right-align',
              title: this.gettext('Not fixed lots'),
              cellTemplate: `
                <span ng-class="{'label-opacity': item.not_fixed_quantity_lots == '0' }">
                  {[{ item.not_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': !contract.not_fixed_quantity_lots }"
                    >
                      {[{ contract.not_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },

            {
              columnName: 'hedged_lots',
              class: 'td-right-align',
              title: this.gettext('Hedged lots'),
              cellTemplate: `
                <span ng-class="{'label-opacity': item.hedged_quantity_lots == '0' }">
                  {[{ item.hedged_quantity_lots || 0 | gtDecimal: 3 }]}
                </span>
                <div class="clearfix"></div>
                <ul class="inside-table-ul" ng-if="args.showFixing">
                  <li ng-repeat="contract in item.fixing_contracts">
                    <span
                      class="smaller-label"
                      ng-class="{'label-opacity': !contract.hedged_quantity_lots }"
                    >
                      {[{ contract.hedged_quantity_lots || 0 | gtDecimal: 3 }]}
                    </span>
                    <div class="clearfix"></div>
                  </li>
                </ul>
              `,
            },
          ],
        };
      };

      applyGrouping(group: any) {
        if (this.group === group) {
          return;
        }
        this.group = group;
        this.tableData = this.group.dataPrepareFunction.call(this);
        this.tableOptions = this.group.tablePrepareFunction.call(this);
      }

      updatePassportGroupedData = () => {
        const contracts = this.data.rows.reduce(
          (res: any, x: any) => [
            ...res,
            ...x.fixing_contracts.map((c: any) => {
              c.derivative = x;
              return c;
            }),
          ],
          [],
        );

        const passports = contracts
          .reduce(
            (res: any, x: any) =>
              x.passport_list
                ? [
                    ...res,
                    ...x.passport_list
                      .filter((p: any) => res.filter((r: any) => r.id == p.id).length == 0)
                      .map((v: any) => ({
                        ...v,
                        key: v.create_time,
                      })),
                  ]
                : res,
            [
              {
                id: null,
                title: '<without passport>',
                key: new Date('1995-12-17T03:24:00'),
              },
            ],
          )
          .sort((a: any, b: any) => b.key - a.key)
          .map((p: any) => {
            p.contractList = contracts.filter(
              (c: any) =>
                c.passport_list == p.id ||
                (c.passport_list && c.passport_list.filter((pl: any) => pl.id == p.id).length > 0),
            );
            return p;
          });

        const fields: any = [
          'fixed_quantity',
          'fixed_quantity_lots',
          'hedged_quantity_lots',
          'not_fixed_quantity',
          'not_fixed_quantity_lots',
          'to_fixed_quantity',
          'to_fixed_quantity_lots',
        ];

        const filteredPassports = passports.filter((passport: any) => {
          if (this.queryParams.hide_inactive_accounts === undefined) {
            return true;
          } else {
            return passport.account_is_active == !!this.queryParams.hide_inactive_accounts;
          }
        });

        filteredPassports.forEach((passport: any) => {
          fields.forEach((field) => {
            if (field === 'hedged_quantity_lots') {
              passport[field] =
                passport.contractList.reduce(
                  (res: any, item: any) => res + (item.contract_type === 'sale' ? item[field] : 0),
                  0,
                ) || 0;
            } else {
              passport[field] =
                passport.contractList.reduce(
                  (res: any, item: any) =>
                    res +
                    (!item.without_derivative && item.contract_type === 'purchase'
                      ? item[field]
                      : 0),
                  0,
                ) || 0;
            }
          });
        });

        return { ...this.data, rows: filteredPassports };
      };
      getPassportGroupedTableOptions = () => {
        return {
          tableName: 'fixing-hedging-list',
          configurable: true,
          tableClass: 'fixing-hedging-report',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          templateArgs: {
            showFixing: this.showFixing,
            group: this.group,
          },
          columnDefs: [
            {
              columnName: 'passport',
              class: 'td-left-align',
              title: this.gettext(''),
              cellTemplate: `
                <table class="table table-inside-table">
                <thead ng-if="item.contractList.length">
                  <tr>
                    <th>#</th>
                    <th><translate>qty</translate></th>
                    <th><translate>derivative</translate></th>
                    <th><translate>premium</translate></th>
                    <th><translate>avg hedging</translate></th>
                    <th><translate>avg fixing</translate></th>
                    <th><translate>ctr price</translate></th>
                    <th><translate>fixing</translate>, <translate>tonnes</translate></th>
                    <th><translate>fixing</translate>, <translate>lots</translate></th>
                    <th><translate>hedging</translate></th>
                  </tr>
                </thead>
                <tbody>
                  <tr ng-class="{'highlighted-tr': args.showFixing}">
                    <td>
                      <a target="_blank" ui-sref="gt.page.passport({id: item.id })"> <i class="fa fa-exchange"></i> {[{ item.title }]} </a>
                      <span
                        ng-click="args.showFixing = !args.showFixing"
                        class="btn btn-xs btn-dark-border"
                      >
                        <span ng-class="{ 'label-opacity': !item.fixing_contracts.length}" style="padding:0">
                          <i class="fa fa-expand" ng-if="!args.showFixing"></i>
                          <i class="fa fa-compress" ng-if="args.showFixing"></i>
                        </span>
                      </span>
                    </td>
                    <td>
                      <span ng-if="item.vessel_name" class="label_default">
                      <i class="fa fa-ship"></i> {[{ item.vessel_name || '---' | cut:true:50:'...' }]} - <i class="fa fa-calendar"></i> {[{ item.voyage_eta || '---' |
                        date:'dd/MM/yy' }]} - {[{ item.voyage_status || '---' }]}
                      </span>
                    </td>
                    <td>
                      <span ng-if="item.account_number" class="label_info">
                        {[{ item.account_number || '---' }]}
                      </span> <span ng-if="item.account_number && !item.account_is_active" class="label_danger"><translate>inactive<translate></span>
                    </td>
                    <td colspan="4"></td>
                    <td>
                      <ul class="inside-table-ul">
                        <li>
                          <span class="bigger-label">T: {[{item.to_fixed_quantity || 0 | gtDecimal: 3 }]}</span>
                        </li>
                        <li>
                          <span class="bigger-label">F: {[{item.fixed_quantity || 0 | gtDecimal: 3 }]}</span>
                        </li>
                        <li>
                          <span class="bigger-label">B: {[{item.not_fixed_quantity || 0 | gtDecimal: 3 }]}</span>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul class="inside-table-ul">
                        <li>
                          <span class="bigger-label"
                            >T: {[{item.to_fixed_quantity_lots || 0 | gtDecimal: 3 }]}</span
                          >
                        </li>
                        <li>
                          <span class="bigger-label"
                            >F: {[{item.fixed_quantity_lots || 0 | gtDecimal: 3 }]}</span
                          >
                        </li>
                        <li>
                          <span class="bigger-label"
                            >B: {[{item.not_fixed_quantity_lots || 0 | gtDecimal: 3 }]}</span
                          >
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul class="inside-table-ul">
                      <li>
                        <span ng-class="{'label-opacity': item.to_fixed_quantity_lots == '0' }">
                          T: {[{ item.to_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                        </span>
                      </li>
                      <li>
                        <span ng-class="{'label-opacity': !item.hedged_quantity_lots }">
                        H: {[{item.hedged_quantity_lots || 0 | gtDecimal: 3 }]}
                        </span>
                        </li>
                        <li>
                        <span ng-class="{'label label_danger': item.to_fixed_quantity_lots - item.hedged_quantity_lots < 0 }">
                        B: {[{ item.to_fixed_quantity_lots - item.hedged_quantity_lots || 0 | gtDecimal: 3 }]}
                        </span>
                        </li>
                        </ul>
                    </td>
                  </tr>
                </tbody>
                <tbody ng-repeat="contract in item.contractList | orderBy: '-contract_type'" ng-if="args.showFixing">
                  <tr ng-class="{'with-no-derivative': contract.without_derivative}">
                    <td>
                      <a  target="_blank" ui-sref="gt.page.contract({id: contract.id })">
                      <span class="label_default" ng-if="contract.contract_type == 'purchase'"><i class="fa fa-arrow-down"></i> {[{ contract.contract_number }]} </span>
                      <span class="label_calculate" ng-if="contract.contract_type == 'sale'"><i class="fa fa-arrow-up"></i> {[{ contract.contract_number }]}</span>
                      </a>
                      <div class="clearfix"></div>
                      <span
                        ng-if="contract.contract_type == 'purchase' && contract.supplier_name"
                        class="smaller-label label-smoke"
                        
                      >
                        <i class="fa fa-bookmark-o"></i> {[{ contract.supplier_name || '---' }]}
                      </span>
                      <span
                        ng-if="contract.contract_type == 'sale' && contract.buyer_name"
                        class="smaller-label label-smoke"
                        
                      >
                        <i class="fa fa-bookmark"></i> {[{ contract.buyer_name || '---' }]}
                      </span>
                    </td>
                    <td class="td-left-align">
                    <span class="smaller-label">
                          <span ng-if="contract.volume_estimated_open"
                            >est. {[{contract.volume_estimated_open || 0 | number: 3 }]} /
                          </span>
                          {[{ contract.volume || 0 | number: 3 }]} {[{
                          $rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                          </span>
                        <div class="clearfix"></div>
                        <span class="smaller-label"><i class="fa fa-wheat-awn"></i> {[{ contract.cargo_title || '---' }]}</span>
                        <div class="clearfix"></div>
                        <span class="smaller-label">
                          <i class="fa fa-calendar"></i> {[{ contract.date_of_execution || "---" |
                          date:'dd/MM/yy' }]} - {[{ contract.end_of_execution || "---" | date:'dd/MM/yy' }]}
                       </span>
                      </ul>
                    </td>
                    <td>
                    <span ng-if="!contract.without_derivative" class="smaller-label"> {[{ contract.derivative.title }]} </span>
                      <div class="clearfix"></div>
                      <span ng-if="!contract.without_derivative" class="smaller-label label-smoke">
                        <span
                          ng-if="contract.derivative.stock_market_price"
                          ng-class="{'smaller-label': args.group.name == 'passport'}"
                        >
                          {[{ contract.derivative.stock_market_price | number:2 }]}
                          <span ng-if="contract.derivative.point_value != 100">
                            <translate>cent</translate>
                          </span>
                          {[{ contract.derivative.currency_symbol }]}/{[{ contract.derivative.measurement_title
                          }]}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span ng-class="{'label-opacity': !contract.price_premium }">
                        {[{ contract.price_premium || 0 | number: 2 }]}
                      </span>
                    </td>
                    <td>
                      <span ng-class="{'label-opacity': !contract.hedging_avg_price }">
                        {[{ contract.hedging_avg_price || 0 | number: 2 }]}
                      </span>
                    </td>
                    <td>
                      <span ng-class="{'label-opacity': !contract.fixings_avg_price }">
                        {[{ contract.fixings_avg_price || 0 | number: 2 }]}
                      </span>
                    </td>
                    <td>
                      <span> {[{ contract.price || 0 | number: 2 }]} </span>
                      <div class="clearfix"></div>
                      <span class="smaller-label label-smoke">
                        Fixed + MTM:
                      </span>
                       {[{ contract.contract_flat_price || 0 | number: 2 }]}
                      <div class="clearfix"></div>
                      <span class="smaller-label label-smoke">
                        Hedged + MTM:
                      </span>
                       {[{ contract.average_hedged_mtm || 0 | number: 2 }]}
                    </td>
                    <td>
                      <ul class="inside-table-ul">
                        <li>
                          <span ng-class="{'label-opacity': contract.to_fixed_quantity == 0 }">
                            T: {[{ contract.to_fixed_quantity || 0 | gtDecimal: 3}]}
                            <span> <translate>t</translate> </span>
                          </span>
                        </li>
                        <li>
                          <span ng-class="{'label-opacity': !contract.fixed_quantity }">
                            F: {[{ contract.fixed_quantity || 0 | gtDecimal: 3}]}
                            <span> <translate>t</translate> </span>
                          </span>
                        </li>
                        <li>
                          <span ng-class="{'label-opacity': contract.not_fixed_quantity == 0 }">
                            B: {[{ contract.not_fixed_quantity || 0 | gtDecimal: 3}]}
                            <span> <translate>t</translate> </span>
                          </span>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul class="inside-table-ul">
                        <li>
                          <span ng-class="{'label-opacity': contract.to_fixed_quantity_lots == '0' }">
                            T: {[{ contract.to_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                          </span>
                        </li>
                        <li>
                          <span ng-class="{'label-opacity': !contract.fixed_quantity_lots }">
                            F: {[{ contract.fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                          </span>
                        </li>
                        <li>
                          <span ng-class="{'label-opacity': !contract.not_fixed_quantity_lots }">
                            B: {[{ contract.not_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                          </span>
                        </li>
                      </ul>
                    </td>
                    <td>
                    <ul class="inside-table-ul">
                    <li>
                      <span ng-class="{'label-opacity': contract.to_fixed_quantity_lots == '0' }">
                        T: {[{ contract.to_fixed_quantity_lots || 0 | gtDecimal: 3 }]}
                      </span>
                    </li>
                    <li>
                      <span ng-class="{'label-opacity': !contract.hedged_quantity_lots }">
                      H: {[{ contract.hedged_quantity_lots || 0 | gtDecimal: 3 }]}
                      </span>
                      </li>
                      <li>
                      <span ng-if="!contract.without_derivative"
                      ng-class="{'label label_danger': contract.to_fixed_quantity_lots - contract.hedged_quantity_lots < 0 }">
                      B: {[{  contract.to_fixed_quantity_lots - contract.hedged_quantity_lots || 0 | gtDecimal: 3 }]}
                      </span>
                      </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                </table>
            `,
            },
          ],
        };
      };
    },
  ],
};
