import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './approvals-report-container.html?raw';

export const ApprovalsReportContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$scope',
    'ApprovalsReportService',
    'GtUtils',
    'gtFilterService',
    'CoreService',
    class {
      $scope: ng.IScope;
      ApprovalsReportService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      data: any;
      filterLevel = 'approvals-report-container';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      constructor(
        $scope: ng.IScope,
        ApprovalsReportService: any,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.ApprovalsReportService = ApprovalsReportService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.data = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ApprovalsReportService.getData(this.queryParams).then((data: any) => {
          this.data = data;
          this.GtUtils.overlay('hide');
        });
      }

      updateQueryParams(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }
    },
  ],
};
