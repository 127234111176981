import React from 'react';

import { cn } from '~/shared/lib/utils';
import { ArrowBottomIcon, ThreeDotsIcon } from '~/shared/ui/icons';
import {
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

import { formatSortingDirection } from './format-sorting-direction';
import { sortingVariants } from '../../constants';
import type { SortingDirection, SortingValue } from '../../types';

export const FiltersSortHeader: React.FC<{
  selectedDirection?: SortingDirection;
  onChange: (sorting?: SortingValue) => void;
}> = ({ selectedDirection, onChange }) => {
  return (
    <div className="flex items-center justify-between border-b border-stroke-main-light px-2">
      <Popover>
        <PopoverTrigger>
          <div className="ml-1 flex cursor-pointer items-center gap-1 text-xs font-medium">
            {selectedDirection
              ? formatSortingDirection(selectedDirection, { formatType: 'long' })
              : sortingVariants[0].label}
            <ArrowBottomIcon size={14} className="text-stroke-main-strong" />
          </div>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-0.5 p-1">
          {sortingVariants.map((variant) => (
            <button
              key={variant.direction}
              className={cn(
                'rounded-sm p-1 text-left text-xs text-text-main-tertiary transition duration-300 ease-in-out hover:bg-transparent-light',
                selectedDirection === variant.direction &&
                  'bg-background-main-tertiary text-text-main-primary',
              )}
            >
              {variant.label}
            </button>
          ))}
        </PopoverContent>
      </Popover>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger displayChevron={false}>
          <ThreeDotsIcon size={14} className="cursor-pointer text-stroke-main-strong" />
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent sideOffset={11}>
          <button
            className="px-2 py-1 text-xs text-text-additional-danger"
            onClick={() => onChange(undefined)}
          >
            Delete Sort
          </button>
        </DropdownMenuSubContent>
      </DropdownMenuSub>
    </div>
  );
};
