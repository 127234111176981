import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('execution.legacy').component('transshipmentTariffContainer', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<',
      hideExpiredCheckbox: '<?',
    },
    template: require('./transshipment-tariff-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    '$scope',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'LogisticsService',
    'CoreService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    LogisticsService: any,
    CoreService: CoreService,
  ) {
    const vm = this;
    vm.queryParams = { ...vm.queryParams };
    vm.tariffs = [];
    vm.count = 0;
    vm.updateTariffs = updateTariffs;
    vm.updateQueryParams = updateQueryParams;
    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;
    vm.newTariff = getNewTariff();

    vm.$onInit = function () {
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateTariffs();
      });
      updateTariffs();
    };

    ////////////////

    function saveTariff(tariff: any) {
      if (tariff.id) {
        return LogisticsService.LogisticTransshipmentTariff.update(
          tariff,
          updateTariffs,
          (e: any) => GtUtils.errorClb(e),
        ).$promise;
      }
      return LogisticsService.LogisticTransshipmentTariff.save(tariff, updateTariffs, (e: any) =>
        GtUtils.errorClb(e),
      ).$promise;
    }

    function deleteTariff(tariff: any) {
      if (!confirm(gettext('Are you sure that you want delete Tariff?'))) {
        return;
      }
      return LogisticsService.LogisticTransshipmentTariff.delete(tariff, updateTariffs, (e: any) =>
        GtUtils.errorClb(e),
      ).$promise;
    }

    function updateQueryParams() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }

    function updateTariffs() {
      GtUtils.overlay('show');
      vm.newTariff = getNewTariff();
      return LogisticsService.LogisticTransshipmentTariff.query(
        vm.queryParams,
        function (data: any) {
          vm.tariffs = data.results;
          if (vm.newTariff) {
            vm.tariffs.unshift(vm.newTariff);
          }
          vm.count = data.count;
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function getNewTariff() {
      return {
        business_unit: Object.assign([], CoreService.getDefaultBuList()).shift(),
        ...vm.queryParams,
      };
    }
  }
})();
