import type { GtUtilsService } from '../legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '../types';

export class UtilsService {
  $rootScope: GtRootScopeService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    GtUtils: GtUtilsService,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.GtUtils = GtUtils;
    this.$rootScope = $rootScope;
    this.gettext = gettext;
  }
}
UtilsService.$inject = ['GtUtils', '$rootScope', 'gettext'];
