import React from 'react';

import { dateFormat as dateFormatFn } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';

import type { DataTypeProps } from '../../lib';

export const DateRead: React.FC<DataTypeProps<Date> & { dateFormat?: string }> = ({
  disabled,
  value,
  dateFormat = 'dd/MM/yy',
}) => {
  return (
    <span className={cn('px-2 text-xs text-text-main-tertiary', disabled && 'opacity-50')}>
      {dateFormatFn(new Date(value), dateFormat)}
    </span>
  );
};
