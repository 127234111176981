import ng from 'angular';

import { BlMarginByMonthChart } from './bl-margin-by-month-chart/bl-margin-by-month-chart.component';
import { BlVolumeByMonthChart } from './bl-volume-by-month-chart/bl-volume-by-month-chart.component';
import { ExecutionVolumesByCropChart } from './execution-volumes-by-crop-chart/execution-volumes-by-crop-chart.component';

export const shared = ng
  .module('execution.shared', [])
  .component('executionVolumesByCropChart', ExecutionVolumesByCropChart)
  .component('blMarginByMonthChart', BlMarginByMonthChart)
  .component('blVolumeByMonthChart', BlVolumeByMonthChart).name;
