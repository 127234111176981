import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('execution.legacy').component('vesselPage', {
    bindings: {},
    template: require('./vessel-details-page.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope', '$stateParams', 'HelpButtonsService', 'LogisticsService'];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    $stateParams: ng.ui.IStateParamsService,
    HelpButtonsService: any,
    LogisticsService: any,
  ) {
    const vm = this;
    vm.tab = 'voyages';
    vm.vessel = { id: $stateParams.id };
    vm.openVesselModal = openVesselModal;

    activate();

    ////////////////

    function activate() {
      updateData();
    }

    function updateData() {
      HelpButtonsService.setConfig(getPageConfig());
      return LogisticsService.Vessel.get({ id: vm.vessel.id }, function (data: any) {
        vm.vessel = data;
        $rootScope.pageTitle = vm.vessel.name;
      }).$promise;
    }

    function getPageConfig() {
      return {
        print: {},
      };
    }

    function openVesselModal(vessel: any) {
      return LogisticsService.vesselModal(vessel).then(updateData);
    }
  }
})();
