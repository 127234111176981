import { delay, of } from 'rxjs';

import {
  type ResetPasswordCredentials,
  type ResetPasswordResponse,
} from '~/features/reset-password/lib';
import { singleton } from '~/shared/lib/di';

@singleton()
export class ResetPasswordApiService {
  resetPassword(credentials: ResetPasswordCredentials) {
    console.info('@creds', credentials);
    return of<ResetPasswordResponse>({
      success: true,
    }).pipe(delay(1000));
  }
}
