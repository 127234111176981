import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './documents-list-table-view.html?raw';

export const DocumentsListTableView = {
  bindings: {
    documents: '<',
    count: '<',
    canApprove: '<?',
    contractType: '<?',
    filterLevel: '<?',
    mode: '<',
    deleteEventSource: '<?',
    onUpdate: '&',
    setHovering: '&',
    readOnly: '<?',
  },
  template,
  controller: [
    'gettext',
    '$rootScope',
    'GtUtils',
    'LogisticsService',
    'DocumentsService',
    'ContractsService',
    class {
      $rootScope: GtRootScopeService;
      ContractsService: ContractsService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      basicDocTypes: any;
      canApprove: any;
      contractType: any;
      deleteEventSource: any;
      documentChecklistTypes: any;
      documents: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      hovering: any;
      mode: any;
      onUpdate: any;
      readOnly: any;
      tableData: any;
      tableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        $rootScope: GtRootScopeService,
        GtUtils: GtUtilsService,
        LogisticsService: any,
        DocumentsService: any,
        ContractsService: ContractsService,
      ) {
        this.gettext = gettext;
        this.$rootScope = $rootScope;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.DocumentsService = DocumentsService;
        this.ContractsService = ContractsService;
        this.hovering = false;
        this.basicDocTypes = ['contract', 'contractbase', 'finance', 'voyage'];
        this.documentChecklistTypes = [
          'logistic',
          'consignment',
          'billoflading',
          'contractcharge',
          'disbursementbl',
          'generalagreement',
          'vehicle',
          'driver',
          'salemulticontract',
          'purchasemulticontract',
          'intermediatemulticontract',
          'exportmulticontract',
          'servicesmulticontract',
          'salemultiticket',
          'purchasemultiticket',
          'exportmultiticket',
          'servicesmultiticket',
        ];
        this.filterLevel = 'documents-list-table-view';
      }

      $onInit() {
        this.canApprove = this.canApprove || false;

        this.tableData = { rows: this.documents };
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.documents) {
          this.tableData = { rows: this.documents };
        }
      }

      setHovering(value: any) {
        this.hovering = value;
      }

      remove(doc: any) {
        const msg = this.gettext('Are you sure that you want delete this document?');
        if (!confirm(msg)) {
          return;
        }
        const eventName = this.deleteEventSource
          ? `documents-list__remove-from-${this.deleteEventSource}`
          : 'documents-list__remove';
        this.$rootScope.$emit(eventName, doc);
      }

      connectLogistics(doc: any) {
        const extra = { contractType: this.contractType };
        return this.LogisticsService.logisticDocumentModal(doc, extra);
      }

      save(doc: any) {
        if (doc.basis_doc) {
          this.ContractsService.BasisDoc.query({ id: doc.basis_doc }).$promise.then((data: any) => {
            doc.basis_doc_title = data.title;
          });
        }

        if (!doc.id) {
          return;
        }

        return this.DocumentsService.Document.patch(
          { partial: true },
          {
            id: doc.id,
            basis_doc: doc.basis_doc,
            client_document_type: doc.client_document_type,
            checklist_point: doc.checklist_point,
          },
        ).$promise.then(
          () => {
            this.GtUtils.notify(this.gettext('Document saved'));
            this.onUpdate();
            this.$rootScope.$broadcast('update-basis-doc');
          },
          () => {
            this.GtUtils.notify(this.gettext('Server error'), 'error');
          },
        );
      }

      getTableOptions() {
        return {
          tableName: `${this.filterLevel}-table`,
          alignColHeight: true,
          rowData: this.documents,
          tableClass: 'table table-responsive table-condensed main-table',
          configurable: true,
          templateArgs: {
            basicDocTypes: this.basicDocTypes,
            mode: this.mode,
            documentChecklistTypes: this.documentChecklistTypes,
            readOnly: this.readOnly,
            setHovering: (value: any) => this.setHovering(value),
            save: (item: any) => this.save(item),
            remove: (item: any) => this.remove(item),
            connectLogistics: (item: any) => this.connectLogistics(item),
          },
          columnDefs: [
            {
              columnName: 'docType',
              title: this.gettext('Type'),
              class: 'td-left-align docs-list',
              cellTemplate: `
                <a href="{[{item.file}]}" target="_blank" class="pull-left">
                  <img class="docs-ico {[{item.class}]} docs-uploaded-img-small" />
                </a>
              `,
            },
            {
              columnName: 'title',
              title: this.gettext('Title'),
              class: 'td-left-align',
              cellTemplate: `
                <div style="white-space: normal; word-break: break-word;" ng-if="item.title">
                  {[{ item.title | cut:true:105:' '}]}
                </div>
                <div style="white-space: normal; text-align: left word-break: break-word;;" ng-if="!item.title">
                  {[{item.name | cut:true:105:' '}]}
                </div>
                <div class="clearfix"></div>
                <span ng-if="!item.edit">
                  <span class="label label_default pull-left" ng-if="item.basis_doc_title"
                    >{[{item.basis_doc_title | cut:true:25:'...'}]}
                  </span>
                  <span class="label label_default pull-left" ng-if="item.client_document_type_title"
                    >{[{item.client_document_type_title | cut:true:25:'...'}]}
                  </span>
                  <span class="label label_default pull-left" ng-if="item.checklistpoint_title"
                    >{[{item.checklistpoint_title | cut:true:25:'...'}]}
                  </span>
                </span>
                <gt-resource-select
                  ng-if="args.basicDocTypes.includes(args.mode) && item.edit"
                  ng-model="item.basis_doc"
                  placeholder="'Document type'|translate"
                  resource-name="'contracts.BasisDoc'"
                  allow-clear="true"
                  ng-mouseenter="args.setHovering({value: true})"
                  ng-mouseleave="args.setHovering({value: false})"
                ></gt-resource-select>
                <gt-resource-select
                  ng-if="args.mode == 'client' && item.edit"
                  ng-model="item.client_document_type"
                  placeholder="'Client Document Type'|translate"
                  resource-name="'clients.ClientDocumentType'"
                  allow-clear="true"
                  ng-mouseenter="args.setHovering({value: true})"
                  ng-mouseleave="args.setHovering({value: false})"
                ></gt-resource-select>
                <gt-resource-select
                  ng-if="args.documentChecklistTypes.includes(args.mode) && item.edit"
                  ng-model="item.checklist_point"
                  placeholder="'Document Type'|translate"
                  resource-name="'documents.documentchecklistpoint'"
                  query-params="{ purpose_model: args.mode }"
                  allow-clear="true"
                  ng-mouseenter="args.setHovering({value: true})"
                  ng-mouseleave="args.setHovering({value: false})"
                ></gt-resource-select>
              `,
            },
            {
              columnName: 'relatedObject',
              title: this.gettext('Object name (type)'),
              class: 'td-left-align',
              cellTemplate: `
                {[{item.content_object.title}]} 
                <div class="clearfix"></div>
                <span class="label label_default">
                {[{ item.content_object.model }]}
                 </div>
              `,
            },
            {
              columnName: 'author',
              title: this.gettext('Author'),
              class: 'td-left-align',
              cellTemplate: `
                  <i class="fa fa-user"></i> {[{ item.author_first_name }]}
                  <div class="clearfix"></div>
                   {[{
                  item.content_type.model }]}
                  <div class="clearfix"></div>
                  <i class="fa fa-clock-o"></i>
                  <span
                    am-time-ago="item.update_time"
                    am-full-date-threshold="30"
                    am-full-date-format="DD/MM/YY HH:mm"
                  >
                  </span>
              `,
            },
            {
              columnName: 'actions',
              title: this.gettext('Actions'),
              class: 'td-left-align',
              cellTemplate: `
                <a class="btn btn-xs btn-add-dark" ng-if="!args.readOnly">
                  <i
                    ng-if="!item.edit"
                    class="fa fa-pencil-square"
                    ng-click="item.edit = true"
                    style="width: auto; cursor: pointer"
                  ></i>
                  <i
                    ng-if="item.edit"
                    class="fa fa-times"
                    ng-click="item.edit = false"
                    style="width: auto; cursor: pointer"
                  ></i>
                </a>
                <a
                  ng-if="item.edit"
                  class="btn btn-xs btn-success btn_success"
                  ng-click="args.save(item);item.edit = false;"
                  style="width: auto; cursor: pointer"
                >
                  <i class="fa fa-floppy-o"></i>
                </a>
                <a
                  href="{[{item.file}]}"
                  class="btn btn-xs btn-add-dark"
                  ng-if="!item.edit"
                >
                  <i class="fa fa-download"></i>
                </a>
                <a
                  class="btn btn-xs btn-add-dark"
                  ng-if="!item.edit"
                  ng-click="args.connectLogistics(item)"
                >
                  <i class="fa fa-chain"></i>
                </a>
                <a
                  ng-click="args.remove(item)"
                  permission
                  permission-only="'delete_document'"
                  class="btn btn-xs btn-danger"
                  ng-if="!item.edit"
                >
                  <i class="fa fa-trash"></i>
                </a>
              `,
            },
          ],
        };
      }
    },
  ],
};
