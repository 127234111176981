import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Input, type InputProps } from '~/shared/ui/kit/input';

import { type DataTypeProps, messageIntent } from '../../lib';

export const EmailWrite: React.FC<DataTypeProps<string> & InputProps> = ({
  message,
  value,
  valueChanged,
  ...props
}) => {
  return (
    <div className={cn('flex h-full w-full items-center', message && messageIntent[message.type])}>
      <Input
        intent="transparent"
        defaultValue={value}
        onBlur={(e) => valueChanged?.(e.target.value)}
        height="max"
        fullWidth
        {...props}
      />
    </div>
  );
};
