import { AuthGuard } from '~/core/auth/components';
import { createFileRoute, redirect } from '~/shared/lib/router';

export const Route = createFileRoute('/_auth')({
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isAuthenticated) {
      redirect({ to: '/login', throw: true });
    }
  },
  component: AuthGuard,
});
