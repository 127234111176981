import ng from 'angular';

(function () {
  'use strict';
  ng.module('execution.legacy').component('transshipmentTariffTable', {
    bindings: {
      filterLevel: '<',
      tariffs: '<',
      count: '<',
      onDelete: '&',
      onSave: '&',
    },
    template: require('./transshipment-tariff-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext'];

  function Controller(this: any, $scope: ng.IScope, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.theadConfig = getTheadConfig();
    vm.queryParams = {};
    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;

    vm.$onInit = function () {
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    ////////////////

    function saveTariff(tariff: any) {
      vm.onSave({ tariff: tariff });
    }

    function deleteTariff(tariff: any) {
      vm.onDelete({ tariff: tariff });
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('Station'),
          columnName: 'station',
          predicate: 'station__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'station',
              label: gettext('station'),
              resource: 'logistics.Station',
            },
          ],
        },
        {
          title: gettext('Commodity'),
          columnName: 'crop',
          predicate: 'crop',
          filters: [
            {
              type: 'ui-select',
              predicate: 'crop',
              label: gettext('crop'),
              resource: 'crops.Crop',
            },
          ],
        },
        {
          title: gettext('Tariff'),
          columnName: 'tarrif',
          predicate: 'price',
          filters: [
            {
              type: 'text',
              predicate: 'price',
            },
          ],
        },
        {
          title: gettext('Status'),
          columnName: 'status',
        },
        {
          title: gettext('Rate'),
          columnName: 'rate',
          predicate: 'currency_exchange__rate',
          filters: [
            {
              type: 'ui-select',
              predicate: 'rate',
              label: gettext('rate'),
              resource: 'finances.CurrencyExchange',
            },
          ],
        },
        {
          title: gettext('Additional info'),
          columnName: 'additional_info',
        },
        {
          title: gettext('Business unit'),
          columnName: 'buisness_unit',
          predicate: 'business_unit',
          filters: [
            {
              type: 'ui-select',
              predicate: 'business_unit',
              label: gettext('business unit'),
              resource: 'core.BusinessUnit',
            },
          ],
        },
        {
          title: gettext('Last update'),
          columnName: 'lust_update',
          predicate: 'update_time',
          class: 'update_time',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
        },
        {
          title: gettext('Author'),
          columnName: 'author',
          predicate: 'author',
          filters: [
            {
              type: 'ui-select',
              predicate: 'author',
              label: gettext('autor'),
              resource: 'auth.User',
            },
          ],
        },
        {
          title: gettext(''),
          columnName: 'edit',
        },
      ];

      return config;
    }
  }
})();
