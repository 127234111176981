import React from 'react';

import { useTheme } from '~/core/theme/hooks';
import { getSectionName, themeSections } from '~/core/theme/lib';
import { Outlet, TanStackRouterDevtools, useLocation } from '~/shared/lib/router';

export const RootLayout: React.FC = () => {
  const mainRef = React.useRef<HTMLElement>(null);

  const { pathname } = useLocation();
  const { theme, setSection } = useTheme();

  React.useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  React.useEffect(() => {
    const section = getSectionName(pathname);

    document.body.classList.remove(...themeSections);

    if (section) {
      document.body.classList.add(section);
      setSection(section);
    }
  }, [pathname, setSection]);

  return (
    <main ref={mainRef} className="prose h-screen bg-noise antialiased">
      <Outlet />
      <TanStackRouterDevtools initialIsOpen={false} />
    </main>
  );
};
