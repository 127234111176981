import React from 'react';

import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { BasicViewContent } from './basic-view-content';
import type { View, ViewConfigChanged } from '../view-configurator/types';

export const BasicView = <T extends View>({
  title = 'View',
  displayTriggerIcon = true,
  sideOffset = 6,
  trigger,
  content,
  view,
  triggerClassName,
  viewConfigChanged,
  viewDeleted,
}: {
  title?: string;
  trigger: React.ReactNode;
  triggerClassName?: string;
  displayTriggerIcon?: boolean;
  sideOffset?: number;
  view: T;
  content: React.ReactNode;
  viewConfigChanged: ViewConfigChanged;
  viewDeleted: (id: number) => void;
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState<boolean>(false);
  const [isOpenIconList, setIsOpenIconList] = React.useState<boolean>(false);
  const [isOpenViewVariants, setIsOpenViewVariants] = React.useState<boolean>(false);

  return (
    <DropdownMenuSub open={isSubMenuOpen} onOpenChange={setIsSubMenuOpen}>
      <DropdownMenuSubTrigger displayChevron={displayTriggerIcon} className={triggerClassName}>
        {trigger}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent
          className="flex w-[296px] flex-col gap-2 p-0 text-xs"
          sideOffset={sideOffset}
          alignOffset={0}
        >
          <BasicViewContent
            title={title}
            isOpenIconList={isOpenIconList}
            iconListOpened={setIsOpenIconList}
            viewVariantsOpened={setIsOpenViewVariants}
            isOpenViewVariants={isOpenViewVariants}
            view={view}
            content={content}
            viewConfigChanged={viewConfigChanged}
            viewDeleted={viewDeleted}
            contentClosed={() => setIsSubMenuOpen(false)}
            Icon={view.Icon}
          />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
