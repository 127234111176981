import ng from 'angular';

import { loyaltyPrograms } from './loyalty-programs/loyalty-programs.module';
import { loyaltyProgramsExecution } from './loyalty-programs-execution/loyalty-programs-execution.module';
import { projectsStatusReport } from './projects-status-report/projects-status-report.module';

const budgetsConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state('reports.budgets', {
      url: '/budgets',
      abstract: true,
      template: '<ui-view/>',
    });
  },
];

export const budgets = ng
  .module('reports.budgets', [loyaltyPrograms, loyaltyProgramsExecution, projectsStatusReport])
  .config(budgetsConfig).name;
