import type { GlobalDialogConfig } from '../lib/types';

export const deleteRecordsConfig = <T>({
  records,
  onCancel,
  onDelete,
}: {
  records: T[];
  onDelete: () => void;
  onCancel: () => void;
}): GlobalDialogConfig => {
  return {
    id: 'deleteRecords',
    title: `Delete ${records.length} records`,
    description: 'This action cannot be undone. This will permanently delete these records',
    actions: [
      { variant: 'fadedSecondary', action: onCancel, label: 'Cancel' },
      { variant: 'danger', action: onDelete, label: 'Delete Records' },
    ],
  };
};

export const finishEditingConfig = ({
  onCancel,
  onSave,
}: {
  onSave: () => void;
  onCancel: () => void;
}): GlobalDialogConfig => {
  return {
    id: 'finishEditing',
    title: 'You should finish inline editing',
    description:
      'Please, save or discard changes before leaving the page. If you do not save, this action will discard your inline changes.',
    actions: [
      { variant: 'fadedSecondary', action: onCancel, label: 'Cancel' },
      { variant: 'default', action: onSave, label: 'Save Changes' },
    ],
  };
};
