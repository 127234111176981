import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './invoice-risk-report-container.html?raw';

export const InvoiceRiskReportContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    '$filter',
    'gettext',
    'GtUtils',
    'gtFilterService',
    'InvoiceRiskReportService',
    'CoreService',
    class invoiceRiskReportContainer {
      $filter: ng.IFilterService;
      $scope: ng.IScope;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      InvoiceRiskReportService: any;
      data: any;
      dateFilterOptions: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      groupFilters: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      positionUseOptions: any;
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      showFilters: any;
      voyageStatusOptions: any;
      constructor(
        $scope: ng.IScope,
        $filter: ng.IFilterService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        InvoiceRiskReportService: any,
        CoreService: CoreService,
      ) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.InvoiceRiskReportService = InvoiceRiskReportService;
        this.CoreService = CoreService;

        this.filterLevel = '';
        this.queryParams = {};
        this.initQueryParams = {};
        this.showFilters = false;

        this.data = {};
        this.dateFilterOptions = [
          {
            predicate: 'invoice_date',
            title: gettext('Invoice date'),
          },
        ];
        this.positionUseOptions = [
          {
            value: 'cargo',
            title: this.gettext('Cargo'),
          },
          {
            value: 'costs',
            title: this.gettext('Costs'),
          },
          {
            value: 'gains',
            title: this.gettext('Gains'),
          },
          {
            value: 'expenses',
            title: this.gettext('General expenses'),
          },
          {
            value: 'washout',
            title: this.gettext('Washout'),
          },
          {
            value: 'credit',
            title: this.gettext('Credit'),
          },
          {
            value: 'other',
            title: this.gettext('Other'),
          },
        ];
        this.voyageStatusOptions = [
          {
            value: 'New',
            title: this.gettext('New'),
          },
          {
            value: 'pol_under_way',
            title: this.gettext('POL under way'),
          },
          {
            value: 'at_loading_port',
            title: this.gettext('At loading port'),
          },
          {
            value: 'Loading',
            title: this.gettext('Loading'),
          },
          {
            value: 'Loaded',
            title: this.gettext('Loaded'),
          },
          {
            value: 'pod_under_way',
            title: this.gettext('POD under way'),
          },
          {
            value: 'at_discharging_port',
            title: this.gettext('At discharging port'),
          },
          {
            value: 'Discharging',
            title: this.gettext('Discharging'),
          },
          {
            value: 'Discharged',
            title: this.gettext('Discharged'),
          },
          {
            value: 'Executed',
            title: this.gettext('Executed'),
          },
          {
            value: 'Washout',
            title: this.gettext('Washout'),
          },
          {
            value: 'Cancelled',
            title: this.gettext('Cancelled'),
          },
        ];
        this.groupFilters = {
          vessel_name: {
            group_by: 'vessel_name',
            has_balance: 1,
            position_use_list: ['cargo'],
            currency_list: [1, 3],
          },
          counterparty_name: {
            group_by: 'counterparty_name',
            has_date_of_withdrawal: 0,
            has_balance: 1,
            position_use_list: ['cargo'],
            currency_list: [1, 3],
          },
          counterpaty_bank_name: {
            group_by: 'counterpaty_bank_name',
            has_date_of_withdrawal: 1,
            has_balance: 1,
            position_use_list: ['cargo'],
            currency_list: [1, 3],
          },
        };
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'invoice-risk-report-container';

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });

        //we need to register our filter level if we handling filter clearing inside component
        this.gtFilterService.registerCleanFilterLevel(this.filterLevel);
        this.$scope.$on('gt-filter-clear_' + this.filterLevel, () => {
          this.setDefaultFilters();
        });

        this.setDefaultFilters();
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      applyFilters() {
        this.gtFilterService.setQueryParams(
          { ...this.initQueryParams, ...this.queryParams },
          this.filterLevel,
        );
      }

      setDefaultFilters() {
        this.gtFilterService.setQueryParams({ ...this.initQueryParams }, this.filterLevel);
      }

      updateFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        if (!this.queryParams.group_by) {
          return;
        }
        this.GtUtils.overlay('show');
        return this.InvoiceRiskReportService.getData(this.queryParams).then((data: any) => {
          this.data = data.data;
          this.GtUtils.overlay('hide');
        });
      }

      resetFilter() {
        this.setDefaultFilters();
      }
    },
  ],
};
