import { embedDashboard } from '@superset-ui/embedded-sdk';
import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './analytics-container.html?raw';
import type { AnalyticsService } from '../analytics.service';

export const AnalyticsContainerComponent = {
  bindings: {
    defaultDashboard: '<?',
    multipleDashboards: '<?',
  },
  template,
  controller: [
    '$document',
    '$resource',
    '$rootScope',
    'AnalyticsService',
    'GtUtils',
    class {
      $document: ng.IDocumentService;
      $resource: ng.resource.IResourceService;
      $rootScope: GtRootScopeService;
      AnalyticsService: AnalyticsService;
      GtUtils: GtUtilsService;
      defaultDashboard: any;
      multipleDashboards: any;
      selectedDashboard: any;
      supersetDashboards: any;
      constructor(
        $document: ng.IDocumentService,
        $resource: ng.resource.IResourceService,
        $rootScope: GtRootScopeService,
        AnalyticsService: AnalyticsService,
        GtUtils: GtUtilsService,
      ) {
        this.$document = $document;
        this.$resource = $resource;
        this.$rootScope = $rootScope;
        this.AnalyticsService = AnalyticsService;
        this.GtUtils = GtUtils;

        this.supersetDashboards = [];
        this.multipleDashboards = true;
        this.defaultDashboard = this.defaultDashboard || 'analytics-company-basic';
      }

      $onInit() {
        this.getDashboardsList().then((dashboards: any) => {
          this.supersetDashboards = dashboards;
          this.sortDashboardsAlphabetically();
          this.selectedDashboard =
            this.supersetDashboards.find(
              (dashboard: any) => dashboard.slug === this.defaultDashboard,
            ) || this.supersetDashboards[0];
          void this.renderDashboard(this.selectedDashboard.id).catch(this.GtUtils.errorClb);
        });
      }

      sortDashboardsAlphabetically() {
        this.supersetDashboards.sort((a: any, b: any) => a.title.localeCompare(b.title));
      }

      getDashboardsList() {
        return this.AnalyticsService.getDashboards();
      }

      getToken(id: any) {
        return this.AnalyticsService.getToken(id);
      }

      renderDashboard = async (id: any) => {
        const res = await this.AnalyticsService.getDashboardById(id || this.selectedDashboard.id);
        await this.drawDashboard(res.result.uuid);
      };

      drawDashboard = async (id: any) => {
        const el = document.getElementById('bi-container');

        if (!el) {
          throw new Error('Element with id "bi-container" not found');
        }

        await embedDashboard({
          id,
          supersetDomain: this.$rootScope.user.settings.BI_API_URL,
          fetchGuestToken: () => this.getToken(id),
          mountPoint: el,
          dashboardUiConfig: {
            hideTitle: true,
            filters: {
              expanded: false,
            },
          },
        }).catch(this.GtUtils.errorClb);
      };
    },
  ],
};
