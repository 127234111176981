import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.legacy').controller('TeamController', Controller);

  Controller.$inject = ['$scope', 'GtUtils', 'TeamService', 'HelpButtonsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    TeamService: any,
    HelpButtonsService: any,
  ) {
    const vm = this;
    vm.teamCount = 0;
    vm.hbConfig = _prepareHbConfig();
    vm.traders = [];
    vm.team = null;
    vm.tableExpanded = HelpButtonsService.getTableConfig().expanded;
    vm.groupsCount = 0;
    vm.permissionsCount = 0;

    activate();

    ////////////////

    function activate() {
      $scope.$on('hb-config-updated', function () {
        vm.tableExpanded = HelpButtonsService.getTableConfig().expanded;
      });
      updateData();
    }

    function updateData() {
      GtUtils.overlay('show');
      return TeamService.Trader.query(function (callbackdata: any) {
        HelpButtonsService.setConfig(_prepareHbConfig());
        vm.team = callbackdata;
        vm.traders = callbackdata.traders;
        vm.teamCount = callbackdata.traders_counter;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function _prepareHbConfig() {
      return {
        update: { method: updateData },
        print: {},
      };
    }
  }
})();
