import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
export class PassportConnectionShipmentPlanFormFieldsService {
  $rootScope: GtRootScopeService;
  CoreService: CoreService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
  ) {
    this.$rootScope = $rootScope;
    this.gettext = gettext;
    this.CoreService = CoreService;
    this.GtUtils = GtUtils;
  }

  getFields(instance: any, formName?: string) {
    formName = formName ?? 'passport-connection-shipment-plan-modal';
    const fieldClass = 'table-form-field';
    const col1: any = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-4 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('Allocation'),
        },
        fieldGroup: [
          {
            key: 'contract',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Contract'),
              placeholder: this.gettext('Contract'),
              resource: 'contracts.contractbase',
              queryParams: () => ({ stage: 'contract', passport_list: [instance.passport] }),
            },
          },
          {
            key: 'passport',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Passport'),
              placeholder: this.gettext('Contract'),
              resource: 'passports.passport',
              queryParams: () => ({ contract_list: [instance.contract] }),
            },
          },
          {
            key: 'logistic_type',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('logistic type'),
              placeholder: this.gettext('logistic type'),
              resource: 'logistics.logistictype',
            },
          },
          {
            key: 'shipment_warehouse',
            type: 'gt-ui-select',
            className: fieldClass,
            templateOptions: {
              label: this.gettext('shipment warehouse'),
              placeholder: this.gettext('choose shipment warehouse'),
              resource: 'logistics.warehouse',
            },
          },
          {
            key: 'unloading_warehouse',
            type: 'gt-ui-select',
            className: fieldClass,
            templateOptions: {
              label: this.gettext('unloading warehouse'),
              placeholder: this.gettext('choose unloading warehouse'),
              resource: 'logistics.warehouse',
            },
          },
          {
            key: 'business_unit',
            type: 'gt-ui-select',
            defaultValueResolve: () => this.CoreService.getDefaultBuId(instance),
            templateOptions: {
              label: this.gettext('Business unit'),
              placeholder: this.gettext('Business Unit'),
              queryParams: this.GtUtils.getBUQueryParams(),
              resource: 'core.BusinessUnit',
            },
          },
        ],
      },
    ];
    col2.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('Counterparties'),
        },
        fieldGroup: [
          {
            key: 'commodity_shipper',
            type: 'gt-ui-select',
            className: fieldClass,
            templateOptions: {
              label: this.gettext('commodity shipper'),
              placeholder: this.gettext('choose commodity shipper'),
              resource: 'clients.Client',
            },
          },
          {
            key: 'commodity_receiver',
            type: 'gt-ui-select',
            className: fieldClass,
            templateOptions: {
              label: this.gettext('commodity receiver'),
              placeholder: this.gettext('choose commodity receiver'),
              resource: 'clients.Client',
            },
          },
          {
            key: 'exporter',
            type: 'gt-ui-select',
            className: fieldClass,
            templateOptions: {
              label: this.gettext('exporter'),
              placeholder: this.gettext('choose exporter'),
              resource: 'clients.Client',
              queryParams: () => {
                return { role_name: 'exporter', contract_exporters_list: [instance.contract] };
              },
            },
          },
          {
            key: 'producer',
            type: 'gt-ui-select',
            className: fieldClass,
            templateOptions: {
              label: this.gettext('producer'),
              placeholder: this.gettext('choose producer'),
              resource: 'clients.Client',
              queryParams: { is_producer: '1' },
            },
          },
        ],
      },
    ];
    col3.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('Commodity'),
        },
        fieldGroup: [
          {
            key: 'commodity',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('commodity'),
              placeholder: this.gettext('commodity'),
              resource: 'crops.crop',
              queryParams: () => ({ contract_list: [instance.contract] }),
            },
          },
          {
            key: 'volume',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Volume'),
              type: 'number',
              onChange: () => {
                instance.quantity = (instance.volume || 1) / (instance.packing_size || 1);
              },
            },
          },
          {
            key: 'measurement',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('measurement'),
              placeholder: this.gettext('measurement'),
              resource: 'stockexchanges.measurement',
            },
          },
          {
            key: 'packing_size',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('packing size'),
              type: 'number',
              onChange: () => {
                instance.quantity = (instance.volume || 1) / (instance.packing_size || 1);
              },
            },
          },
          {
            key: 'quantity',
            type: 'gt-input',
            defaultValue: instance.volume / (instance.packing_size || 1),
            templateOptions: {
              label: this.gettext('Quantity'),
              type: 'number',
              viewOnly: true,
              help: this.gettext('Quantity = Volume / Packing size'),
            },
          },
          {
            key: 'normal_write_off_coeff',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('normal write off coeff'),
              type: 'number',
            },
          },
          {
            key: 'discharge_approval_volume',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('discharge approval volume'),
              type: 'number',
            },
          },
        ],
      },
    ];

    return {
      formName: formName,
      fieldsDef: [col1, col2, col3],
    };
  }
}
PassportConnectionShipmentPlanFormFieldsService.$inject = [
  '$rootScope',
  'gettext',
  'GtUtils',
  'CoreService',
];
