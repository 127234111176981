import ng from 'angular';

import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';

  ng.module('deals.contracts.legacy').controller('TabRequestsController', ControllerFunction);

  ControllerFunction.$inject = ['Request', 'ContractsService'];

  function ControllerFunction(this: any, Request: any, ContractsService: ContractsService) {
    const vm = this;

    vm.requests = [];
    vm.openRequestModal = openRequestModal;
    vm.showAll = false;

    activate();

    /////////////

    function activate() {
      updateData();
    }

    function updateData() {
      if (vm.contract.contract_type == 'sale') {
        return ContractsService.getRequestsForSale(vm.contract.id).then(function (
          callbackdata: any,
        ) {
          vm.requests = callbackdata.data;
        });
      }
      return Request.query(
        {
          contract_type: vm.contract.contract_type,
          contract_id: vm.contract.id,
        },
        function (callbackdata: any) {
          vm.requests = callbackdata.results;
        },
      ).$promise;
    }

    function openRequestModal(requestId: any) {
      return ContractsService.requestModal({
        id: requestId,
      }).then(updateData);
    }
  }
})();
