import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('problemsPageView', {
    bindings: {
      initQueryParams: '<?',
    },
    template: require('./problems-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'gtFilterService',
    'LogisticsService',
    'PageService',
    'gettext',
    'GtUtils',
    'ContractsService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gtFilterService: GtFilterService,
    LogisticsService: any,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.logistics = [];
    vm.logisticsCount = 0;
    vm.passport = null;
    vm.saleVolumeField = 'volume_received';
    vm.purchaseVolumeField = 'volume_departed';
    vm.createPassport = createPassport;
    vm.connectContracts = connectContracts;
    vm.selectAllChecked = false;
    vm.selectAll = selectAll;
    vm.updateLogistics = updateLogistics;
    vm.totals = {};
    vm.untieFromPassport = false;
    vm.excludeContractType = null;
    vm.excludeContractTypeChoices = [
      { id: null, title: '---' },
      { id: 'sale', title: gettext('Sale') },
      { id: 'purchase', title: gettext('Purchase') },
    ];

    vm.$onInit = function () {
      vm.filterLevel = 'problems-page-view';
      vm.initQueryParams = vm.initQueryParams || {};
      vm.queryParams = {
        with_problems: 1,
        page_size: 25,
        serializer: 'full',
        ...vm.initQueryParams,
      };
      PageService.setConfig(getPageConfig());
      vm.theadConfig = getTheadConfig();
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateLogistics();
      });
      updateLogistics();
    };

    ////////////////

    function updateLogistics() {
      GtUtils.overlay('show', true);
      const c = LogisticsService.Logistic.listUnconnected(vm.queryParams, function (data: any) {
        if (vm.queryParams.next) {
          vm.logistics = vm.logistics.concat(data.results);
          delete vm.queryParams.next;
        } else {
          vm.logistics = data.results;
        }
        vm.logisticsCount = data.count;
        LogisticsService.Logistic.totalsUnconnected(vm.queryParams, function (data: any) {
          vm.totals = data;
        });
        GtUtils.overlay('hide');
      });
      return GtUtils.addConnection(c).$promise;
    }

    function createPassport() {
      let volume = 0,
        cargo: any = null;
      vm.logistics.forEach(function (item: any) {
        volume += (item._selected && item[vm.saleVolumeField]) || 0;
        cargo = (item._selected && item.cargo) || cargo;
      });
      return ContractsService.passportModal({
        cargo: cargo,
        total_volume_plan: volume,
      }).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        vm.passport = data.id;
        connectContracts();
      });
    }

    function selectAll() {
      vm.logistics.forEach(function (item: any) {
        item._selected = vm.selectAllChecked;
      });
    }

    function connectContracts() {
      GtUtils.overlay('show');
      const logistics: any = [];
      let errors = false;
      let params = { ...vm.queryParams };
      let withWrongCrops = false;
      vm.logistics.forEach(function (item: any) {
        if (item._selected) {
          if (
            !errors &&
            !withWrongCrops &&
            item.buyer_contract_cargo != item.supplier_contract_cargo
          ) {
            GtUtils.notify(gettext('Wrong crops'), 'error');
            if (confirm(gettext('Connect wrong crops?'))) {
              withWrongCrops = true;
            } else {
              errors = true;
            }
          }
          logistics.push(item.id);
        }
      });

      if (!logistics.length && vm.passport) {
        GtUtils.notify(gettext('few logistics'), 'error');
        errors = true;
      }
      if (errors || !confirm(getConnectionMessage(logistics))) {
        GtUtils.overlay('hide');
        return false;
      }
      params = Object.assign(params, {
        with_problems: 1,
        _untie_from_passport: vm.untieFromPassport,
        _exclude_contract_type: vm.excludeContractType,
        _logistics: logistics || undefined,
        _passport: vm.passport || undefined,
        _sale_volume_field: vm.saleVolumeField,
        _purchase_volume_field: vm.purchaseVolumeField,
      });
      delete params.page;
      delete params.page_size;
      delete params.serializer;

      return LogisticsService.Logistic.connectContracts(params, updateLogistics);
    }

    function getConnectionMessage(logistics: any) {
      let msg = gettext('Do you want to connect ');

      if (logistics.length) {
        msg += logistics.length + gettext(' logistics ');
      } else {
        msg += gettext('all logistics ');
      }

      if (vm.passport) {
        msg += gettext('with selected passport?');
      } else {
        msg += gettext("with sale contract's passports?");
      }
      return msg;
    }

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-logistics-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Import('logistics.logistic'),
          new PageService.buttons.Export('logistics.logistic', vm.filterLevel),
        ],
        pages: [],
        filters: {},
      };

      config.filters = {
        filterLevel: vm.filterLevel,
        clicked: false,
        search: true,
        dates: true,
        dateSelects: [
          {
            argument: 'create_time',
            placeholder: gettext('Create date'),
          },
          {
            argument: 'loading_date',
            placeholder: gettext('Loading date'),
          },
          {
            argument: 'receiving_date',
            placeholder: gettext('Receiving date'),
          },
          {
            argument: 'payment_date',
            placeholder: gettext('Payment date'),
          },
        ],
        multiSelects: [
          {
            argument: 'forwarder_list',
            placeholder: gettext('forwarders'),
            resource: 'clients.deliverer',
          },
          {
            argument: 'exporter_list',
            placeholder: gettext('exporters'),
            resource: 'clients.exporter',
          },
          {
            argument: 'station_list',
            placeholder: gettext('station deps'),
            resource: 'logistics.station',
          },
          {
            argument: 'station_receiving_list',
            placeholder: gettext('station recs'),
            resource: 'logistics.station',
          },
          {
            argument: 'elevator_list',
            placeholder: gettext('elevators'),
            resource: 'clients.elevator',
          },
          {
            argument: 'supplier_contract_supplier_list',
            placeholder: gettext('suppliers'),
            resource: 'clients.supplier',
          },
          {
            argument: 'buyer_contract_buyer_list',
            placeholder: gettext('buyers'),
            resource: 'clients.buyer',
          },
          {
            argument: 'buyer_contract_list',
            placeholder: gettext('buyer contracts'),
            resource: 'contracts.SaleContract',
          },
          {
            argument: 'supplier_contract_list',
            placeholder: gettext('supplier contracts'),
            resource: 'contracts.PurchaseContract',
          },
          {
            argument: 'port_list',
            placeholder: gettext('ports'),
            resource: 'logistics.Port',
          },
          {
            argument: 'terminal_list',
            placeholder: gettext('terminals'),
            resource: 'logistics.Terminal',
          },
          {
            argument: 'buyer_nineteen_invoice_list',
            placeholder: gettext('buyer balance invoices'),
            resource: 'finances.Finance',
          },
          {
            argument: 'buyer_ten_invoice_list',
            placeholder: gettext('buyer balance invoices'),
            resource: 'finances.Finance',
          },
          {
            argument: 'supplier_nineteen_invoice_list',
            placeholder: gettext('supplier prepay invoices'),
            resource: 'finances.Finance',
          },
          {
            argument: 'supplier_ten_invoice_list',
            placeholder: gettext('supplier balance invoices'),
            resource: 'finances.Finance',
          },
        ],
        nestedSelects: [
          gtFilterService.getBoolFilter('with_volume_received', gettext('Volume Received')),
          gtFilterService.getBoolFilter('with_buyer_nineteen_invoice', gettext('Buyer 90 invoice')),
          gtFilterService.getBoolFilter('with_buyer_ten_invoice', gettext('Buyer 10 invoice')),
          gtFilterService.getBoolFilter(
            'with_supplier_nineteen_invoice',
            gettext('Supplier 90 invoice'),
          ),
          gtFilterService.getBoolFilter(
            'with_supplier_ten_invoice',
            gettext('Supplier 10 invoice'),
          ),
          gtFilterService.getBoolFilter('vet', gettext('Vet')),
          gtFilterService.getBoolFilter('declaration', gettext('Declaration')),
          gtFilterService.getBoolFilter('quality', gettext('Quality')),
          gtFilterService.getBoolFilter('railway', gettext('Railway')),
          gtFilterService.getBoolFilter('with_buyer_contract', gettext('Sale Contract')),
          gtFilterService.getBoolFilter('with_supplier_contract', gettext('Purchase Contract')),
          gtFilterService.getCropsFilter('cargo'),
        ],
        nestedMultiSelects: [
          {
            argument: 'vehicle_type_list',
            placeholder: gettext('Vehicle type'),
            items: [
              { id: 'car', title: gettext('Car') },
              { id: 'wagon', title: gettext('Wagon') },
              { id: 'container', title: gettext('Container') },
            ],
          },
          {
            argument: 'supplier_contract_status_list',
            placeholder: gettext('Supplier contract status'),
            items: [
              { id: 'washout', title: gettext('Washout') },
              { id: 'new', title: gettext('New') },
              { id: 'delivery_period', title: gettext('Delivery period') },
              { id: 'shipment', title: gettext('Shipment') },
              { id: 'cargo_executed', title: gettext('Cargo executed') },
              { id: 'executed', title: gettext('Executed') },
              { id: 'approved', title: gettext('Approved') },
              { id: 'cancelled', title: gettext('Cancelled') },
            ],
          },
          {
            argument: 'buyer_contract_status_list',
            placeholder: gettext('Buyer contract status'),
            items: [
              { id: 'washout', title: gettext('Washout') },
              { id: 'new', title: gettext('New') },
              { id: 'delivery_period', title: gettext('Delivery period') },
              { id: 'shipment', title: gettext('Shipment') },
              { id: 'cargo_executed', title: gettext('Cargo executed') },
              { id: 'executed', title: gettext('Executed') },
              { id: 'approved', title: gettext('Approved') },
              { id: 'cancelled', title: gettext('Cancelled') },
            ],
          },
        ],
      };
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] as any[] };

      config.tabs = [];

      config.columns = [
        {
          title: gettext(' '),
          columnName: 'checkbox',
        },
        {
          title: gettext('Vehicle (#)'),
          columnName: 'vehicle_number',
          predicate: 'vehicle_number',
          filters: [
            {
              type: 'text',
              predicate: 'vehicle_number',
            },
            {
              type: 'select',
              predicate: 'vehicle_type',
              label: gettext('Vehicle type'),
              values: {
                car: gettext('Car'),
                wagon: gettext('Wagon'),
                container: gettext('Container'),
              },
            },
          ],
        },
        {
          title: gettext('Consignment (#)'),
          columnName: 'consignment_number',
          predicate: 'consignment_number',
          filters: [{ type: 'text', predicate: 'consignment_number' }],
        },
        {
          title: gettext('Port'),
          columnName: 'port_name',
          predicate: 'port__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'port',
              label: gettext('port'),
              resource: 'logistics.Port',
            },
          ],
        },
        {
          title: gettext('Supplier contract #'),
          columnName: 'supplier_contract_number',
          predicate: 'supplier_contract__contract_number',
          filters: [
            {
              type: 'text',
              predicate: 'supplier_contract__contract_number',
            },
          ],
        },
        {
          title: gettext('Supplier passports'),
          columnName: 'supplier_passports',
        },
        {
          title: gettext('Buyer contract #'),
          columnName: 'buyer_contract_number',
          predicate: 'buyer_contract__contract_number',
          filters: [
            {
              type: 'text',
              predicate: 'buyer_contract__contract_number',
            },
          ],
        },
        {
          title: gettext('Buyer passports #'),
          columnName: 'buyer_passport_number',
        },
        {
          title: gettext('crop'),
          columnName: 'crop',
          predicate: 'cargo',
          filters: [
            {
              type: 'ui-select',
              predicate: 'cargo',
              label: gettext('Commodity'),
              resource: 'crops.Crop',
            },
          ],
        },
        {
          title: gettext('Volume (departed)'),
          columnName: 'volume_departed',
          predicate: 'volume_departed',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume_departed',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume_departed',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Volume (departed consignment)'),
          columnName: 'volume_departed_consignment',
          predicate: 'volume_departed_consignment',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume_departed_consignment',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume_departed_consignment',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Volume (received)'),
          columnName: 'volume_received',
          predicate: 'volume_received',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume_received',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume_received',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Volume (real)'),
          columnName: 'volume_departed_real',
          predicate: 'volume_departed_real',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume_departed_real',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume_departed_real',
              addon: gettext('Max'),
            },
          ],
        },
        {
          columnName: 'volume_boarded',
          title: gettext('Volume (boarded)'),
          predicate: 'volume_boarded',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume_boarded',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume_boarded',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Loading (date)'),
          columnName: 'loading_date',
          predicate: 'loading_date',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_loading_date',
              endDateField: 'end_loading_date',
            },
          ],
        },
        {
          title: gettext('Receiving (date)'),
          columnName: 'receiving_date',
          predicate: 'receiving_date',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_receiving_date',
              endDateField: 'end_receiving_date',
            },
          ],
        },
        {
          title: gettext('Last update'),
          columnName: 'update_time',
          predicate: 'update_time',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
        },
        {
          title: gettext(''),
          columnName: 'edit',
        },
      ];
      return config;
    }
  }
})();
