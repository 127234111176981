import type { BiDashboard } from '~/features/common/bi-dashboards/types';
import type { Dashboard as SupersetDashboard } from '~/shared/api';
import {
  supersetDashboardsRetrieve,
  supersetGetDashboardByIdRetrieve,
  supersetGuestTokenRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class BiDashboardsRepository {
  public async getUsersDashboards(): Promise<BiDashboard[]> {
    const { result } = await supersetDashboardsRetrieve();
    return BiDashboardsRepository.buildBiDashboard(result);
  }

  public async getDashboardUUID(id: number) {
    const { result } = await supersetGetDashboardByIdRetrieve({ id });
    return result.uuid;
  }

  public async getGuestToken(dashboardUUID: string): Promise<string> {
    const tokenRes = await supersetGuestTokenRetrieve({ id: dashboardUUID });
    return tokenRes.token;
  }

  private static buildBiDashboard(dashboardList: SupersetDashboard[]): BiDashboard[] {
    return dashboardList
      .filter((dashboard) => dashboard.status === 'published')
      .map((dashboard) => ({
        id: dashboard.id,
        title: dashboard.dashboard_title,
        slug: dashboard.slug,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }
}
