export const allRowsSelected = <T extends { id: string }>(
  data: T[],
  selectedRowIds: string[],
  rowSelected?: (ids: string[]) => void,
) => {
  const allSelected = selectedRowIds.length === data.length;
  if (allSelected) {
    rowSelected?.([]);
  } else {
    rowSelected?.(data.map((item) => item.id));
  }
};
