import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('kpiconfirmTableContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      clientsStatus: '<?',
    },
    template: require('./kpiconfirm-table-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext', 'gtFilterService', 'ClientsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gettext: ng.gettext.gettextFunction,
    gtFilterService: GtFilterService,
    ClientsService: any,
  ) {
    const vm = this;

    vm.updateData = updateData;
    vm.openClientModal = openClientModal;
    vm.count = 0;
    vm.results = [];
    vm.tableOptions = {};
    vm.tableData = {};

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'kpiconfirm-table-container';
      vm.queryParams = { page_size: 10, ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateReport();
      });
      updateReport();
      vm.tableOptions = getTableOptions();
    };

    function updateData() {
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function getTableOptions() {
      const options: any = {
        tableName: vm.filterLevel,
        filterLevel: vm.filterLevel,
        applyFilters: (params: any) => {
          gtFilterService.updateQueryParams(params.params, vm.filterLevel);
        },
        templateArgs: {
          openClientModal: (id: any) => openClientModal(id),
        },
        columnDefs: [] as any[],
        tabs: [],
        configurable: true,
        showMore: false,
      };
      options.columnDefs = [
        {
          title: gettext('Client'),
          columnName: 'name',
          cellTemplate: `
            <a ng-click="args.openClientModal(item.id)" class="content-label">
              <i class="fa fa-building"></i> {[{ item.name }]}
            </a>

          `,
        },
        {
          title: gettext('Create date'),
          columnName: 'create_time',
          cellTemplate: `
            <div>
              <span class="date-label" ng-if="item.create_time">
                <i class="fa fa-calendar"></i> {[{ item.create_time | date: 'dd.MM.yy' }]}
              </span>
              <span class="date-label" ng-if="!item.create_time"> <i class="fa fa-calendar"></i> - </span>
            </div>
          `,
        },
        {
          title: gettext('First confirm date'),
          columnName: 'date_first_confirmation',
          cellTemplate: `
            <div>
              <span class="date-label" ng-if="item.date_first_confirmation">
                <i class="fa fa-calendar"></i> {[{ item.date_first_confirmation | date: 'dd.MM.yy' }]}
              </span>
              <span class="date-label" ng-if="!item.date_first_confirmation"> <i class="fa fa-calendar"></i> - </span>
            </div>
          `,
        },
        {
          title: gettext('Notes'),
          columnName: 'confirmation_info',
          cellTemplate: `
            <span class="tooltip" data-tip="{[{item.confirmation_info}]}">\
              {[{item.confirmation_info}]}\
            </span>
          `,
        },
        {
          title: gettext('KPI success'),
          columnName: 'kpi_confirmation_success',
          cellTemplate: `
            <span ng-if="item.kpi_confirmation_success"><i class="fa fa-check-circle"></i></span>
          `,
        },
      ];
      return options;
    }

    function updateReport() {
      return ClientsService.Client.query(vm.queryParams).$promise.then(function (data: any) {
        vm.results = data.results;
        vm.count = data.count;
        vm.tableData = { rows: vm.results, count: vm.count };
      });
    }

    function openClientModal(clientId: any) {
      return ClientsService.clientModal(clientId, null, '').then(updateReport);
    }
  }
})();
