import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './logistics-bl-formation.html?raw';

export const LogisticsBLFormationContainer = {
  bindings: {
    selectedLogistics: '<',
    selectAll: '&',
    onFinish: '&',
    onParamsChanged: '&',
  },
  template,
  controller: [
    '$scope',
    'CoreService',
    'GtUtils',
    'LogisticsService',
    'BillOfLadingService',
    'gettext',
    class {
      $scope: ng.IScope;
      BillOfLadingService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      LogisticsService: any;
      amountsData: any;
      gettext: ng.gettext.gettextFunction;
      onFinish: any;
      onParamsChanged: any;
      selectAll: any;
      selectAllChecked: any;
      selectAllOnPageChecked: any;
      selectedLogistics: any;
      volumeBase: any;
      volumeBases: any;
      constructor(
        $scope: ng.IScope,
        CoreService: CoreService,
        GtUtils: GtUtilsService,
        LogisticsService: any,
        BillOfLadingService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;
        this.LogisticsService = LogisticsService;
        this.BillOfLadingService = BillOfLadingService;
        this.gettext = gettext;
        this.volumeBases = [
          { id: 'volume_received', title: this.gettext('Volume received') },
          { id: 'volume_boarded', title: this.gettext('Volume boarded') },
        ];
        this.selectAllOnPageChecked = false;
        this.selectAllChecked = false;
        this.amountsData = {};
        this.volumeBase = 'volume_received';
      }

      selectAllItems() {
        this.selectAllChecked = !this.selectAllChecked;
        this.selectAll({ checked: this.selectAllChecked });
        this.updateBill();
      }
      changeVolumeBase(source: any) {
        if (source.id === this.volumeBase) {
          return;
        }
        this.volumeBase = source.id;
        return this.refreshCheckboxes();
      }
      refreshCheckboxes() {
        return this.onParamsChanged({
          changedFields: {
            volumeBase: this.volumeBase,
          },
        });
      }

      $onInit() {
        this.selectedLogistics = this.selectedLogistics || [];
        this.selectAllChecked = false;
        this.amountsData = {};
      }

      getDistinctLogisticValues(field: any) {
        // @ts-ignore
        return [...new Set(this.selectedLogistics.map((item: any) => item[field]))];
      }
      getUniqueLogisticValue(field: any) {
        const distinctValues = this.getDistinctLogisticValues(field);
        if (Object.keys(distinctValues).length === 1) {
          return Object.keys(distinctValues)[0];
        }
        return null;
      }

      connectToBl() {
        if (
          this.selectedLogistics.filter((logistic: any) => logistic.selected_bl_volume <= 0).length
        ) {
          return this.GtUtils.notify(
            this.gettext('Logistics have already connected to bill of lading.'),
            'error',
          );
        }
        this.updateBill();

        const newBill = {
          sale_contract: this.getUniqueLogisticValue('buyer_contract'),
          purchase_contract: this.getUniqueLogisticValue('supplier_contract'),
          volume: this.amountsData.bl_amount_volume,
          container_number: this.getDistinctLogisticValues('vehicle_number').length.toString(),
        };
        const extraData = {
          logisticBillofladingData: this.selectedLogistics.map((logistic: any) => ({
            logistic: logistic.id,
            volume: logistic.selected_bl_volume,
          })),
        };
        this.BillOfLadingService.billOfLadingModal(newBill, extraData)
          .then(() => this.close(true))
          .catch((err: any) => this.GtUtils.errorClb(err))
          .finally(() => this.GtUtils.notify(''));
      }

      updateBill() {
        this.amountsData.bl_amount_volume = this.selectedLogistics.reduce(
          (accumulator: any, currentValue: any) => {
            return Math.round((accumulator + currentValue.selected_bl_volume) * 1000) / 1000;
          },
          0,
        );
      }
      close(result: any) {
        return this.onFinish(result);
      }
      cancelFormating() {
        this.close(false);
      }
    },
  ],
};
