import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('passportFinancePositionContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./passport-finance-position-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'FinancesService', 'gtFilterService', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.passportFinancePositions = [];
    vm.passportFinancePositionsCount = 0;
    vm.total = 0;
    vm.totalQuantity = 0;
    vm.updatePassportFinancePositions = updatePassportFinancePositions;
    vm.savePassportFinancePosition = savePassportFinancePosition;
    vm.deletePassportFinancePosition = deletePassportFinancePosition;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'passport-finance-position-container';
      vm.queryParams = { page_size: 999, ...vm.initQueryParams };
      vm.newPassportFinancePosition = getNewPosition();
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updatePassportFinancePositions();
      });
      $scope.$watch('vm.passportFinancePositions', updateTotals, true);
      updatePassportFinancePositions();
    };

    vm.$onChanges = function () {
      vm.queryParams = { ...vm.initQueryParams };
    };

    ////////////////

    function getNewPosition() {
      return {
        passport: vm.queryParams.passport,
        crop: vm.queryParams.crop,
      };
    }

    function updatePassportFinancePositions() {
      GtUtils.overlay('show');
      return FinancesService.PassportFinancePosition.query(vm.queryParams, function (data: any) {
        vm.passportFinancePositions = data.results;
        vm.passportFinancePositionsCount = data.count;
        GtUtils.overlay('hide');
      }).$promise;
    }

    function savePassportFinancePosition(passportFinancePosition: any) {
      if (!passportFinancePosition.crop) {
        passportFinancePosition.crop = vm.queryParams.crop;
      }
      passportFinancePosition.amount =
        passportFinancePosition.quantity * passportFinancePosition.price_per_piece;
      passportFinancePosition.amount = passportFinancePosition.amount.toFixed(6);
      if (passportFinancePosition.id) {
        FinancesService.PassportFinancePosition.update(
          passportFinancePosition,
          function () {
            GtUtils.notify(gettext('Position updated'));
          },
          (e: any) => GtUtils.errorClb(e),
        );
      } else {
        FinancesService.PassportFinancePosition.save(
          passportFinancePosition,
          function () {
            vm.newPassportFinancePosition = getNewPosition();
            updatePassportFinancePositions();
            GtUtils.notify(gettext('Position saved'));
          },
          (e: any) => GtUtils.errorClb(e),
        );
      }
    }

    function deletePassportFinancePosition(passportFinancePosition: any) {
      if (passportFinancePosition.id) {
        FinancesService.PassportFinancePosition.delete({
          id: passportFinancePosition.id,
        });
      }
      vm.passportFinancePositions.splice(
        vm.passportFinancePositions.indexOf(passportFinancePosition),
        1,
      );
    }

    function updateTotals() {
      vm.total = 0;
      vm.totalQuantity = 0;
      ng.forEach(vm.passportFinancePositions, function (passportFinancePosition: any) {
        passportFinancePosition.amount =
          passportFinancePosition.quantity * passportFinancePosition.price_per_piece;
        vm.total += passportFinancePosition.quantity * passportFinancePosition.price_per_piece;
        vm.totalQuantity += passportFinancePosition.quantity;
      });
    }
  }
})();
