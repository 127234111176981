import { type TableOptions, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import type { ColumnDef } from '~/shared/ui/components/data-grid';
import { SelectRowCheckbox } from '~/shared/ui/components/select-row-checkbox';
import { MinusIcon } from '~/shared/ui/icons';
import { Checkbox } from '~/shared/ui/kit/checkbox';

import { allRowsSelected } from './all-rows-selected';

type UseTableViewProps<T> = Omit<TableOptions<T>, 'getCoreRowModel' | 'columns'> & {
  isRowsSelectable?: boolean;
  selectedRowIds?: string[];
  recordSelected: (ids: string[]) => void;
  columns: ColumnDef<T>[];
};

const getColumnVisibility = <T,>(columns: ColumnDef<T>[]) => {
  return columns.reduce<Record<keyof T | `_${string}`, boolean>>(
    (visibility, column) => {
      if (column.hidden) {
        visibility[column.accessorKey] = false;
      }
      return visibility;
    },
    {} as Record<keyof T | `_${string}`, boolean>,
  );
};

export const useTableView = <T extends { id: string }>({
  data,
  columns,
  isRowsSelectable = false,
  selectedRowIds = [],
  recordSelected,
  ...rest
}: UseTableViewProps<T>) => {
  const selectableTableData = React.useMemo(() => {
    return data.map((row) => ({
      ...row,
      _selectRowCheckbox: { rowId: row.id, selectedRowIds: selectedRowIds },
    }));
  }, [data, selectedRowIds]);

  return useReactTable<T>({
    data: isRowsSelectable ? selectableTableData : data,
    columns: isRowsSelectable
      ? [
          {
            id: '_selectRowCheckbox',
            accessorKey: '_selectRowCheckbox',
            header: 'Select',
            headerTemplate: () => (
              <Checkbox
                size="small"
                checked={selectedRowIds?.length !== 0}
                onClick={() => allRowsSelected(data, selectedRowIds, recordSelected)}
                icon={
                  selectedRowIds?.length !== data.length && (
                    <MinusIcon
                      size={16}
                      strokeWidth={2}
                      className="pt-1 text-background-main-primary"
                    />
                  )
                }
              />
            ),
            dataType: 'template',
            editable: false,
            draggable: false,
            cellValueChanged: (diff: Partial<{ id: string; _selectRowCheckbox: string[] }>) =>
              recordSelected(diff._selectRowCheckbox ?? []),
            cellTemplate: SelectRowCheckbox,
            enableResizing: false,
            size: 24,
          } as ColumnDef<T, unknown>,
          ...columns,
        ]
      : columns,
    state: {
      columnVisibility: getColumnVisibility(columns),
    },
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel(),
    ...rest,
  });
};
