import type ng from 'angular';

import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './multicontract-list-table-view.html?raw';

export const MulticontractListTableView = {
  bindings: {
    contracts: '<',
    count: '<',
    queryParams: '<',
    filterLevel: '<',
    total: '<',
    applyFilters: '&',
    openContractModal: '&',
    openPositionModal: '&',
    openDocxModal: '&',
    openDocumentModal: '&',
    openPositionDocxModal: '&',
    openPositionEmailModal: '&',
    openEmailModal: '&',
    openRoleModal: '&',
    onAddFinance: '&',
    onClone: '&',
    onCancel: '&',
    reverseContract: '&',
    createPassport: '&',
    connectToPassport: '&',
    getApprovalContractModel: '&',
    finalApprove: '&?',
    createMultiContract: '&?',
    setFactVolumeToPlans: '&?',
    setCargoConfirmation: '&?',
    activeReportConfig: '<',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    'moment',
    'AccountsService',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      AccountsService: any;
      CustomValuesService: any;
      activeReportConfig: any;
      addPermissionCode: any;
      applyFilters: any;
      changePermissionCode: any;
      connectToPassport: any;
      contracts: any;
      count: number;
      createMultiContract: any;
      createPassport: any;
      filterLevel = 'multicontract-list-table-view';
      finalApprove: any;
      getApprovalContractModel: any;
      gettext: ng.gettext.gettextFunction;
      moment: any;
      onAddFinance: any;
      onCancel: any;
      onClone: any;
      openContractModal: any;
      openDocumentModal: any;
      openDocxModal: any;
      openEmailModal: any;
      openPositionDocxModal: any;
      openPositionEmailModal: any;
      openPositionModal: any;
      openRoleModal: any;
      queryParams: QueryParams & { stage: string } = { stage: '' };
      reverseContract: any;
      setCargoConfirmation: any;
      setFactVolumeToPlans: any;
      tableData: any;
      tableOptions: any;
      total: any;
      constructor(
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        moment: any,
        AccountsService: any,
        CustomValuesService: any,
      ) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.moment = moment;
        this.AccountsService = AccountsService;
        this.CustomValuesService = CustomValuesService;

        this.contracts = [];
        this.count = 0;

        this.tableOptions = {};
        this.tableData = { rows: [] };
      }

      $onInit() {
        this.contracts = this.contracts || [];
        this.count = this.count || 0;
        this.setTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.activeReportConfig) {
          this.setTableOptions();
        }

        if (changes.contracts || changes.count || changes.total) {
          this.tableData = { rows: this.contracts, count: this.count, total: this.total };
        }
      }

      getCustomFieldTableColumns() {
        return this.CustomValuesService.getCustomFieldTableColumns({
          purpose_model: `multi${this.queryParams.stage}`,
        });
      }

      setTableOptions() {
        const options: any = {
          tableName: this.filterLevel,
          tableClass: 'request-table sticky-table-first-td multicontract-fixed-cols-table',
          filterLevel: this.filterLevel,
          applyFilters: this.applyFilters,
          activeReportConfig: this.activeReportConfig,
          addPermissionCode: this.addPermissionCode,
          changePermissionCode: this.changePermissionCode,
          templateArgs: {
            openContractModal: (item: any) => this.openContractModal({ item: item, data: {} }),
            openPositionModal: (position: any) => this.openPositionModal({ position: position }),
            openDocxModal: (modelName: any, contractId: any) =>
              this.openDocxModal({ modelName: modelName, contractId: contractId }),
            openDocumentModal: (modelName: any, object: any) =>
              this.openDocumentModal({ modelName: modelName, object: object }),
            openPositionDocxModal: (contract: any) =>
              this.openPositionDocxModal({ contract: contract }),
            openPositionEmailModal: (contract: any) =>
              this.openPositionEmailModal({ contract: contract }),
            openEmailModal: (contract: any) => this.openEmailModal({ contract: contract }),
            openRoleModal: (id: any, model: any) =>
              this.openRoleModal({ roleId: id, roleModel: model }),
            addFinance: (item: any, invoiceCondition: any) =>
              this.onAddFinance({ multicontractId: item.id, invoiceCondition: invoiceCondition }),
            clone: (objId: any) => this.onClone({ objId: objId }),
            cancel: (objId: any) => this.onCancel({ objId: objId }),
            reverseContract: (contractId: any) => this.reverseContract({ contractId: contractId }),
            createPassport: (contract: any, positions: any) =>
              this.createPassport({ contract: contract, positions: positions }),
            connectToPassport: (contract: any, positions: any) =>
              this.connectToPassport({
                contract: contract,
                passportId: contract.$_passportId,
                positions: positions,
              }),
            approvalAction: (action: any, contractId: any, contract: any) =>
              this.AccountsService.voteApprovable(
                action,
                contractId,
                null,
                this.getApprovalContractModel({ contract: contract }),
              ).then(() => this.applyFilters()),
            finalApprove: (item: any) => this.finalApprove({ item: item }),
            createMultiContract: (item: any) => this.createMultiContract({ item: item }),
            setFactVolumeToPlans: (params: any) => this.setFactVolumeToPlans({ params: params }),
            setCargoConfirmation: (multicontractId: any) =>
              this.setCargoConfirmation({ multicontractId: multicontractId }),
            statusClass: {
              washout: 'contract_calculate',
              new: 'contract_new',
              cancelled: 'contract_cancelled',
              executed: 'contract_executed',
              shipment: 'contract_processing',
              processed: 'contract_calculate',
              rejected: 'contract_cancelled',
              approved: 'contract_processing',
              sent: 'contract_processing',
            },
            $rootScope: this.$rootScope,
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
          showMore: false,
        };

        options.tabs = [
          {
            serializer: 'table_info',
            title: this.gettext('Info'),
            icon: 'fa-info-circle',
            columns: [
              'number',
              'position_numbers',
              'status',
              'position_statuses',
              'contracts_avg_price',
              'from_multirequest',
              'to_multicontracts',
              'passports',
              'concluded',
              'sides',
              'cargo',
              'total_volume',
              'total_volume_plan',
              'volume',
              'price',
              'analytical_price',
              'conclusion_price',
              'total_value',
              'market_zone',
              'basis',
              'port',
              'execution',
              'business_unit',
              'info',
              'positions_info',
              'generalagreement',
              'exporter',
              'vessel',
              'broker',
              'derivative',
              'not_allocated',
              'editor',
              'documents',
              'positionDocuments',
              'approval_status',
              'options',
              'chain',
              'cp_approval_status',
              'cp_custom_status',
              'generalagreement_status',
              'conclusion_date',
              'custom_status',
              'signature',
              'signaturePosition',
              'daysForInvoicePaymentPosition',
              'company_group',
              'payment_statuses',
              'warehouse',
            ],
          },
        ];

        options.columnDefs = [
          {
            columnName: 'number',
            title: this.gettext('number'),
            predicate: 'number',
            classExpr: 'args.statusClass[item.status]',
            hint: this.gettext(
              'Status of te deal: NEW - recently created, SHIPMENT - the execution has been started, EXECUTED - the Contract is closed and executed, CANCELLED - rejected by the manager ',
            ),
            filters: [
              {
                type: 'select',
                predicate: 'status',
                label: this.gettext('Choose status'),
                values: {
                  washout: this.gettext('Washout'),
                  new: this.gettext('New'),
                  shipment: this.gettext('Shipment'),
                  executed: this.gettext('Executed'),
                  cancelled: this.gettext('Cancelled'),
                },
              },
            ],
            cellTemplate: /*html*/ `
              <div class="space-between">
                <ul class="nav navbar-nav item-settings-dropdown">
                  <li>
                    <a
                      ng-if="item.stage === 'ticket'"
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                      ng-click="$root.gtUtils.dismissAllModals();"
                      ui-sref="gt.multiticket({id: item.id })"
                    >
                      <span class="initial-trsansform">
                        <i class="fa fa-file-text"></i> {[{item.number | cut:true:40:'...' || "---"
                        }]}
                        <span ng-if="!item.number"> {[{item.id}]} </span>
                      </span>
                      <span class="smaller-label label label_default" ng-if="item.contracts_count">
                        <i class="fa fa-file-text"></i> {[{ item.contracts_count }]}
                      </span>
                      <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                      <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                    </a>
                    <a
                      ng-if="item.stage === 'contract'"
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                      ng-click="$root.gtUtils.dismissAllModals();"
                      ui-sref="gt.multicontract({id: item.id })"
                    >
                      <span class="initial-trsansform">
                        <i class="fa fa-file-text"></i> {[{item.number | cut:true:30:'...' || "---"
                        }]}
                        <span ng-if="!item.number"> {[{item.id}]} </span>
                      </span>
                      <span class="smaller-label badge label_default" ng-if="item.contracts_count">
                        <i class="fa fa-file-text"></i> {[{ item.contracts_count }]}
                      </span>
                      <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                      <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                    </a>
                  </li>
                </ul>
                <ul
                  class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      <i class="fa fa-ellipsis hide-on-hover"></i>
                      <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                    </a>

                    <ul class="dropdown-menu">
                      <li>
                        <a
                          ng-if="item.stage === 'ticket'"
                          class="btn btn-xs aligned-btn"
                          permission
                          permission-only="args.viewPermissionCode"
                          ui-sref="gt.multiticket({id: item.id })"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                        <a
                          ng-if="item.stage === 'contract'"
                          class="btn btn-xs aligned-btn"
                          permission
                          permission-only="args.viewPermissionCode"
                          ui-sref="gt.multicontract({id: item.id })"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="args.changePermissionCode"
                          class="btn btn-xs aligned-btn tooltip"
                          ng-disabled="item.status == 'executed'"
                          ng-click="args.openContractModal(item)"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'edit_closed_elements'"
                          class="btn btn-xs aligned-btn"
                          ng-click="args.openContractModal(item)"
                        >
                          <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate document'|translate }]}"
                          ng-click="args.openDocxModal('Multicontract', item.id)"
                        >
                          <i class="fa fa-file" aria-hidden="true"></i> +
                          <translate>Template</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'can_clone_contract'"
                          ng-click="args.clone(item.id)"
                          class="btn btn-xs aligned-btn "
                        >
                          <i class="fa fa-files-o"></i> <translate>Clone</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="args.changePermissionCode"
                          class="btn btn-xs col-xs-12 tooltip"
                          data-tip="{[{ 'Cancel'|translate }]}"
                          ng-click="args.cancel(item.id);"
                        >
                          <i class="fa fa-times"></i> <translate>Cancel {[{ item.stage }]}</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate an email'|translate }]}"
                          ng-click="args.openEmailModal(item)"
                        >
                          <i class="fa fa-envelope"></i> <translate>Create an email</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Add document'|translate }]}"
                          ng-click="args.openDocumentModal(null, item)"
                        >
                          <i class="fa fa-upload"></i> <translate>Upload a document</translate>
                        </a>
                      </li>
                      <li ng-if="item.stage === 'contract'">
                        <a
                          permission
                          permission-only="'edit_closed_elements'"
                          class="btn btn-xs aligned-btn tooltip"
                          ng-click="args.setFactVolumeToPlans({
                          contract_type: item.contract_type,
                          id_list: [item.id]
                        })"
                        >
                          <i class="fa fa-left-right"></i> <translate>Set fact volume to plans</translate>
                        </a>
                      </li>
                      <li ng-if="item.stage === 'contract'">
                        <a
                          permission
                          permission-only="'edit_closed_elements'"
                          class="btn btn-xs aligned-btn tooltip"
                          ng-click="args.setCargoConfirmation(item.id)"
                        >
                          <i class="fa fa-check"></i> <translate>Set cargo confirmation</translate>
                        </a>
                      </li>
                      <li
                        class="horizontal-divider"
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals']"
                      ></li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals']"
                      >
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          data-toggle="dropdown"
                          ng-click="item.DropdowApprove = !item.DropdowApprove"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-thumbs-up"></i> <translate>Approval</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals'] && item.DropdowApprove"
                      >
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('approve', item.id, item );"
                        >
                          <i class="fa fa-thumbs-up"></i> <translate>Approve</translate>
                        </a>
                      </li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals'] && item.DropdowApprove"
                      >
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('decline', item.id, item);"
                        >
                          <i class="fa fa-thumbs-down"></i> <translate>Decline</translate>
                        </a>
                      </li>
                      <li
                        ng-if="item.approval_config && $root.user.settings.SYSTEM_BLOCKS['block_crm_approvals'] && item.DropdowApprove"
                      >
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-disabled="item.approval_status != 'rejected'"
                          ng-click="args.approvalAction('reactivate', item.id, item );"
                        >
                          <i class="fa fa-refresh"></i> <translate>Reactivate approving</translate>
                        </a>
                      </li>
                      <!-- TICKET -->
                      <li>
                        <a
                          permission
                          permission-only="'approve_request'"
                          ng-if="item.stage === 'ticket'"
                          ng-disabled="item.status !== 'new'"
                          data-tip="{[{ 'Approve'|translate }]}"
                          ng-click="args.finalApprove(item);"
                          class="btn btn-xs aligned-btn"
                        >
                          <i class="fa fa-check-circle"></i> <translate>Final approve</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="args.addPermissionCode"
                          ng-if="item.stage === 'ticket'"
                          ng-disabled="item.status != 'approved'"
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Create Contract from this Ticket. Ticket will become Executed'|translate }]}"
                          ng-click="args.createMultiContract(item)"
                        >
                          <i class="fa fa-file-text"></i> <translate>Create contract</translate>
                        </a>
                      </li>
                      <!-- /TICKET -->
                      <li class="horizontal-divider"></li>
                      <li ng-if="item.stage === 'contract'">
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          data-toggle="dropdown"
                          ng-click="item.DropdownInvoices = !item.DropdownInvoices"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-credit-card-alt"></i> <translate>Invoices</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li ng-if="item.DropdownInvoices">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Add finance'|translate }]}"
                          ng-click="args.addFinance(item, 'payment_conditions')"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-credit-card-alt"></i>
                          <translate>Create invoice</translate>
                        </a>
                      </li>
                      <li ng-if="item.DropdownInvoices">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Create Prepayment Invoice'|translate }]}"
                          ng-click="args.addFinance(item, 'prepayment')"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-credit-card-alt"></i>
                          <translate>Create Prepayment Invoice</translate>
                        </a>
                      </li>
                      <li ng-if="item.DropdownInvoices">
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Create Balance Invoice'|translate }]}"
                          ng-click="args.addFinance(item, 'balance')"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-credit-card-alt"></i>
                          <translate>Create Balance Invoice</translate>
                        </a>
                      </li>

                      <li ng-if="
                        item.use_type !== 'intermediate' &&
                        item.use_type !== 'services' &&
                        item.use_type !== 'export'
                      ">
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          ng-click="item.DropdownPassport = !item.DropdownPassport"
                          ng-disabled="item.readonly"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-exchange"></i> <translate>Allocation</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li ng-if="item.DropdownPassport">
                        <a
                          ng-click="args.reverseContract(item.id)"
                          permission
                          permission-only="'add_contractbase'"
                          class="btn btn-xs aligned-btn tooltip"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <span ng-if="item.contract_type == 'sale'">
                            <translate>Connect with purchase contract</translate>
                          </span>
                          <span ng-if="item.contract_type == 'purchase'">
                            <translate>Connect with sale contract</translate>
                          </span>
                        </a>
                      </li>
                      <li ng-if="item.DropdownPassport">
                        <a
                          ng-click="args.createPassport(item, item.contracts_set)"
                          class="btn btn-xs aligned-btn tooltip"
                          permission
                          permission-only="'add_passport'"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <translate>Create passport with this contract</translate>
                        </a>
                      </li>
                      <li ng-if="item.DropdownPassport">
                        <a
                          ng-if="!item.$_passportConnectShow"
                          ng-click="item.$_passportConnectShow = true"
                          class="btn btn-xs aligned-btn tooltip"
                          permission
                          permission-only="'add_passport'"
                          ng-disabled="item.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <translate>Connect contract to existing passport</translate>
                        </a>
                      </li>
                      <li ng-if="item.$_passportConnectShow">
                        <gt-resource-select
                          ng-model="item.$_passportId"
                          placeholder="'Passport' | translate"
                          resource-name="'passports.passport'"
                          allow-clear="true"
                          on-select="args.connectToPassport"
                          on-select-args="[item, item.contracts_set]"
                          on-open-close="item.$_passportDropdownOpened = !item.$_passportDropdownOpened"
                          submenu="true"
                        ></gt-resource-select>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- / ITEM SETTINGS BTN -->
            `,
            totalTemplate: /*html*/ `
            <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
          `,
          },
          {
            columnName: 'position_numbers',
            title: this.gettext('Positions'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <div class="space-between" ng-repeat="position in item.contracts_set">
                <ul
                  class="nav navbar-nav item-settings-dropdown"
                >
                  <li>
                    <a
                      ng-if="position.status !== 'draft' && position.stage === 'contract'"
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                      ui-sref="gt.page.contract({id: position.id })"
                    >
                      <span class="initial-transform">
                        <i class="fa fa-file-text"></i> {[{position.contract_number |
                        cut:true:30:'...' || "---" }]}
                        <span ng-if="!position.contract_number"> {[{position.id}]} </span>
                      </span>
                      <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                      <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                    </a>
                    <a
                      ng-if="position.status !== 'draft' && position.stage === 'ticket'"
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                      ui-sref="gt.page.request({id: position.id })"
                    >
                      <span class="initial-transform">
                        <i class="fa fa-file-text"></i> {[{position.contract_number |
                        cut:true:30:'...' || "---" }]}
                        <span ng-if="!position.contract_number"> {[{position.id}]} </span>
                      </span>
                      <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                      <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                    </a>
                    <a
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                      ng-if="position.status === 'draft'"
                    >
                      <span class="initial-transform">
                        <i class="fa fa-file-text"></i> {[{position.contract_number |
                        cut:true:40:'...' || "---" }]}
                      </span>
                    </a>
                    </a>
                  </li>
                </ul>
                <ul
                  class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      <i class="fa fa-ellipsis hide-on-hover "></i>
                      <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                    </a>
                    <ul
                      class="dropdown-menu"
                      ng-class="{ 'show-menu': position.$_passportDropdownOpened }"
                    >
                      <li ng-if="position.status !== 'draft'">
                        <a
                          ng-if="position.stage === 'ticket'"
                          class="btn btn-xs col-xs-12"
                          ui-sref="gt.page.request({id: position.id })"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                        <a
                          ng-if="position.stage === 'contract'"
                          class="btn btn-xs col-xs-12"
                          ui-sref="gt.page.contract({id: position.id })"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="args.changePermissionCode"
                          class="btn btn-xs aligned-btn "
                          ng-disabled="position.status == 'executed'"
                          ng-click="args.openPositionModal(position)"
                        >
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          permission
                          permission-only="'edit_closed_elements'"
                          class="btn btn-xs aligned-btn"
                          ng-click="args.openPositionModal(position)"
                        >
                          <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                        </a>
                      </li>
                      <li class="horizontal-divider"></li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate an email'|translate }]}"
                          ng-click="args.openPositionEmailModal(position)"
                        >
                          <i class="fa fa-envelope"></i> <translate>Create an email</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Generate document'|translate }]}"
                          ng-click="args.openPositionDocxModal(position)"
                          ng-disabled="position.readonly"
                        >
                          <i class="fa fa-file" aria-hidden="true"></i>
                          <translate>Download a template</translate>
                        </a>
                      </li>
                      <li>
                        <a
                          class="btn btn-xs aligned-btn tooltip"
                          data-tip="{[{ 'Add document'|translate }]}"
                          ng-click="args.openDocumentModal('ContractBase', position)"
                          ng-disabled="position.readonly"
                        >
                          <i class="fa fa-upload"></i> <translate>Upload a document</translate>
                        </a>
                      </li>
                      <li class="horizontal-divider"></li>
                      <li ng-if="position.deal_type !='intermediate' && position.status !== 'draft'">
                        <a
                          class="dropdown-toggle user-dropdown-btn"
                          ng-click="position.DropdownPassport = !position.DropdownPassport"
                          ng-disabled="position.readonly"
                        >
                          <span class="initial-transform">
                            <i class="fa fa-exchange"></i> <translate>Allocation</translate>
                          </span>
                          <i class="fa fa-sort-desc hover-element"></i>
                        </a>
                      </li>
                      <li ng-if="position.DropdownPassport">
                        <a
                          ng-if="position.deal_type !='export'"
                          ng-click="args.createPassport(item, [position,])"
                          class="btn btn-xs aligned-btn tooltip"
                          permission
                          permission-only="'add_passport'"
                          ng-disabled="position.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <translate>Create passport with this position</translate>
                        </a>
                      </li>
                      <li ng-if="position.DropdownPassport">
                        <a
                          ng-if="!position.$_passportConnectShow"
                          ng-click="position.$_passportConnectShow = true"
                          class="btn btn-xs aligned-btn tooltip"
                          permission
                          permission-only="'add_passport'"
                          ng-disabled="position.readonly"
                        >
                          <i class="fa fa-exchange"></i>
                          <translate>Connect position to existing passport</translate>
                        </a>
                      </li>
                      <li ng-if="position.$_passportConnectShow">
                        <gt-resource-select
                          ng-model="item.$_passportId"
                          placeholder="'Passport' | translate"
                          resource-name="'passports.passport'"
                          allow-clear="true"
                          on-select="args.connectToPassport"
                          on-select-args="[item, [position]]"
                          query-params="{ cargo: position.cargo }"
                          on-open-close="position.$_passportDropdownOpened = !position.$_passportDropdownOpened"
                          submenu="true"
                          ;
                        ></gt-resource-select>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- / ITEM SETTINGS BTN -->
            `,
          },
          {
            columnName: 'status',
            title: this.gettext('status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span
                class="label"
                ng-if="item.stage === 'contract'"
                ng-class="{
                  'label_success':item.status == 'new',
                  'label_calculate':item.status == 'washout',
                  'label_warning':item.status == 'delivery_period' || item.status == 'cargo_executed' || item.status == 'shipment',
                  'label_default':item.status == 'executed' || item.status == 'partial_executed',
                  'label_danger':item.status == 'cancelled'
                }"
              >
                <span ng-if="item.status == 'new'"> <translate>New</translate> </span>
                <span ng-if="item.status == 'washout'"> <translate>Washout</translate> </span>
                <span ng-if="item.status == 'shipment'"> <translate>In progress</translate> </span>
                <span ng-if="item.status == 'executed'"> <translate>Executed</translate> </span>
                <span ng-if="item.status == 'cargo_executed'"> <translate>Cargo executed</translate> </span>
                <span ng-if="item.status == 'delivery_period'"> <translate>Delivery period</translate> </span>
                <span ng-if="item.status == 'partial_executed'">
                  <translate>Partial executed</translate>
                </span>
                <span ng-if="item.status == 'cancelled'"> <translate>Cancelled</translate> </span>
              </span>
            `,
          },
          {
            columnName: 'position_statuses',
            title: this.gettext('position statuses'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="position in item.contracts_set">

                  <span
                    ng-if="item.stage === 'ticket'"
                    class="bigger-label"
                    ng-class="{'label-opacity': position.volume == 0}"
                  >
                    <span
                      class="label"
                      ng-class="{
                        'label_success': position.request_status =='new',
                        'label_warning': position.request_status =='approved' || position.request_status == 'processed' ,
                        'label_calculate': position.request_status =='sent',
                        'label_danger': position.request_status =='cancelled',
                        'label_default': position.request_status =='executed',
                        'label_info': position.request_status == 'draft',
                      }"
                    >
                    {[{ position.request_status }]}
                    </span
                  ></span>

                  <span
                    class="label smaller-label"
                    ng-if="item.stage === 'contract'"
                    ng-class="{
                      'label_success': position.status == 'new',
                      'label_info': position.status == 'draft',
                      'label_default': position.status == 'executed',
                      'label_danger': position.status == 'cancelled',
                      'label_calculate':position.status == 'washout',
                      'label_warning': position.status == 'delivery_period' || position.status == 'cargo_executed' || position.status == 'shipment'
                    }"
                  >
                    <span ng-if="position.status == 'new'"> <translate>New</translate> </span>
                    <span ng-if="position.status == 'washout'">
                      <translate>Washout</translate>
                    </span>
                    <span ng-if="position.status == 'shipment'">
                      <translate>In progress</translate>
                    </span>
                    <span ng-if="position.status == 'delivery_period'">
                      <translate>Delivery period</translate>
                    </span>
                    <span ng-if="position.status == 'cargo_executed'">
                      <translate>Cargo executed</translate>
                    </span>
                    <span ng-if="position.status == 'executed'">
                      <translate>Executed</translate>
                    </span>
                    <span ng-if="position.status == 'cancelled'">
                      <translate>Cancelled</translate>
                    </span>
                    <span ng-if="position.status == 'draft'">
                      <translate>Draft</translate>
                    </span>
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'payment_statuses',
            title: this.gettext('Payment statuses'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="position in item.contracts_set">
                  <span
                    class="label smaller-label"
                    ng-class="{
                      'label_success': position.payment_status == 'executed',
                      'label_warning': position.payment_status == 'not_executed'
                    }"
                  >
                    <span ng-if="position.payment_status == 'executed'">
                      <translate>Executed</translate>
                    </span>
                    <span ng-if="position.payment_status == 'not_executed'">
                      <translate>Not executed</translate>
                    </span>
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'from_multirequest',
            title: this.gettext('Request'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <a
                class="label pull-right tooltip"
                style="margin-left: 2px;"
                data-tip="{[{ 'This contract was made from ticket'|translate}]} #{[{ item.from_multirequest_number || item.from_multirequest_id }]}"
                ng-if="item.from_multirequest_id"
                ui-sref="gt.multiticket({id: item.from_multirequest_id})"
                ng-click="$root.gtUtils.dismissAllModals();"
                target="_blank"
              >
                <i class="fa fa-file-text-o"></i> #{[{ item.from_multirequest_number ||
                item.from_multirequest_id }]}
              </a>
            `,
          },
          {
            columnName: 'to_multicontracts',
            title: this.gettext('Contracts'),
            hint: this.gettext('time, date and user, who created this deal'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
            <span ng-repeat="multicontract in item.to_multicontracts_data">
              <a
                class="tooltip"
                data-tip="{[{ 'The contract'|translate}]} # {[{ multicontract.number || multicontract.id }]} {[{ 'was made from this ticket'|translate}]}"
                ui-sref="gt.multicontract({id: multicontract.id})"
              >
                <i class="fa fa-file-text"></i>
                #{[{ multicontract.number || multicontract.id | cut:true:30:'...' || "---" }]}
              </a>
              <div class="clearfix"></div>
            </span>
          `,
          },
          {
            columnName: 'passports',
            title: this.gettext('passports'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
                <ul class="inside-table-ul">
                  <li ng-repeat="position in item.contracts_set">
                  <a class="btn-link" ng-repeat="connection in position.connections" ui-sref="gt.page.passport({id: connection.passport })" target="_blank">
                      <i class="fa fa-exchange"></i> #{[{connection.passport_title | cut:true:25:'...' || "---"
                    }]} - {[{ connection.volume | number: 3 }]}
                    </a>
                    <div class="clearfix"></div>
                  </li>
                </ul>
            `,
          },
          {
            columnName: 'conclusion_date',
            title: this.gettext('conclusion date'),
            predicate: 'conclusion_date',
            hint: this.gettext('time, date and user, who created this deal'),
            class: 'small-font-cell',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date',
                endDateField: 'end_date',
              },
            ],
            cellTemplate: /*html*/ `
              <i class="fa fa-calendar"></i> {[{ item.conclusion_date || "---" | date:'dd.MM.yy'}]}
            `,
          },
          {
            columnName: 'concluded',
            title: this.gettext('position concluded'),
            predicate: 'conclusion_date',
            hint: this.gettext('time, date and user, who created this deal'),
            class: 'small-font-cell',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date',
                endDateField: 'end_date',
              },
            ],
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="position in item.contracts_set">
                  <i class="fa fa-calendar"></i> {[{ position.conclusion_date || "---" | date:'dd.MM.yy'}]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'supplier',
            title: this.gettext('supplier'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'supplier',
                label: this.gettext('supplier'),
                resource: 'clients.Supplier',
              },
            ],
            cellTemplate: /*html*/ `
              <div class="clearfix"></div>
              <span
                ng-if="item.supplier_name"
                ng-class="{'label label_danger': item.supplier_blacklisted}"
                class="tooltip btn-link"
                data-tip="{[{ item.supplier_name }]}"
              >
                <a
                  ng-click="args.openRoleModal(item.supplier, 'Supplier')"
                  ng-if="item.contract_type =='purchase'"
                  ng-class="{'bigger-label': $root.user.settings.DEFAULT_VALUES['hide_owner']}"
                >
                  <i class="fa fa-bookmark-o"></i> {[{item.supplier_name | cut:true:30:'...' ||
                  "---" }]}
                  <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                </a>
                <a
                  ng-click="args.openRoleModal(item.supplier, 'Owner')"
                  ng-if="item.contract_type =='sale' && !$root.user.settings.DEFAULT_VALUES['hide_owner']"
                >
                  <i class="fa fa-home"></i> {[{item.supplier_name | cut:true:30:'...' || "---" }]}
                  <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                </a>
              </span>
              <div class="clearfix"></div>
              <span class="label-opacity " ng-if="!item.supplier_name">
                <i class="fa fa-bookmark-o"></i> ---
              </span>
            `,
          },
          {
            columnName: 'buyer',
            title: this.gettext('buyer'),
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'buyer',
                label: this.gettext('buyer'),
                resource: 'clients.Buyer',
              },
            ],
            cellTemplate: /*html*/ `
              <span
                ng-if="item.buyer_name"
                ng-class="{'label label_danger': item.buyer_blacklisted}"
                class="tooltip btn-link"
                data-tip="{[{ item.buyer_name }]}"
              >
                <a
                  ng-click="args.openRoleModal(item.buyer, 'Buyer')"
                  ng-if="item.contract_type =='sale'"
                  ng-class="{'bigger-label': $root.user.settings.DEFAULT_VALUES['hide_owner']}"
                >
                  <i class="fa fa-bookmark"></i> {[{ item.buyer_name | cut:true:30:'...' || "---"
                  }]}
                  <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
                </a>
                <a
                  ng-click="args.openRoleModal(item.buyer, 'Owner')"
                  ng-if="item.contract_type =='purchase' && !$root.user.settings.DEFAULT_VALUES['hide_owner']"
                >
                  <i class="fa fa-home"></i> {[{ item.buyer_name | cut:true:30:'...' || "---" }]}
                  <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
                </a>
              </span>
              <span class="label-opacity" ng-if="!item.buyer_name">
                <i class="fa fa-bookmark"></i> ---
              </span>
            `,
          },
          {
            columnName: 'cargo',
            title: this.gettext('product'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span class="bigger-label">
                    {[{ contract['crop_' + [$root.user.settings.CROP_TITLE_DISPLAY_MODE]] ||
                    contract.commodity_title }]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'total_volume',
            title: this.gettext('total volume'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.contracts_volume_sum">
                <i class="fa fa-pie-chart"></i>
                <span class="bigger-label label">
                  {[{ item.contracts_volume_sum || 0 | gtDecimal:2 }]}
                  <span class="smaller-label">
                    {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
                <span class="bigger-label" ng-if="item.contracts_final_volume_sum">
                  /
                  <span class="smaller-label">
                    <i class="fa fa-check-circle"></i>
                  </span>
                  {[{ item.contracts_final_volume_sum || 0 | number:3 }]}
                  <span class="smaller-label">
                    {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
              </span>
            `,
            totalTemplate: /*html*/ `
            <span ng-if="item.final_volume_sum">
              <i class="fa fa-check-circle"></i> {[{ item.final_volume_sum || 0 | number:3 }]}
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.volume_sum"> {[{ item.volume_sum || 0 | number:3 }]} </span>
          `,
            totalBlockTemplates: [
              /*html*/ `
              <span class="label">
                {[{ item.volume_sum || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
              </span>
              <div class="clearfix"></div>
              <span class="property">
                <translate>Total</translate> <translate>volume</translate>
              </span>
            `,
              /*html*/ `
              <span class="label">
                <i class="fa fa-exchange"></i> {[{ item.connected || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
              </span>
              <div class="clearfix"></div>
              <span class="property">
                <translate>Total</translate> <translate>connected</translate>
              </span>
            `,
              /*html*/ `
              <span class="label">
                <i class="fa fa-check-circle"></i> {[{ item.final_volume_sum || 0 | number:3 }]}
                <span class="smaller-label"> {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]} </span>
              </span>
              <div class="clearfix"></div>
              <span class="property">
                <translate>Total</translate> <translate>final</translate>
                <translate>volume</translate>
              </span>
            `,
            ],
          },
          {
            columnName: 'total_volume_plan',
            title: this.gettext('Total volume plan'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.volume">
                <i class="fa fa-pie-chart"></i>
                <span class="bigger-label label">
                  {[{ item.volume || 0 | gtDecimal:2 }]}
                  <span class="smaller-label">
                    {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
              </span>
            `,
          },
          {
            columnName: 'volume',
            title: this.gettext('volume'),
            class: 'td-right-align',
            filters: [
              {
                type: 'text',
                predicate: 'min_volume',
                addon: this.gettext('Min'),
              },
              {
                type: 'text',
                predicate: 'max_volume',
                addon: this.gettext('Max'),
              },
            ],
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span class="bigger-label">
                    {[{ contract.volume || 0 | gtDecimal:2 }]}
                    <span class="smaller-label">
                      {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                    </span>
                  </span>
                  <span class="bigger-label" ng-if="contract.final_volume">
                    /
                    <span
                      class="smaller-label tooltip"
                      data-tip="{[{ 'final volume of this contract'|translate }]}"
                    >
                      <i class="fa fa-check-circle"></i>
                    </span>
                    {[{ contract.final_volume || 0 | number:3 }]}
                    <span class="smaller-label">
                      {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                    </span>
                  </span>
                  <div class="clearfix"></div>
                  <span class="smaller-label" ng-if="contract.volume_estimated_open">
                    <span class="label-smoke">
                      <translate>est.</translate>
                    </span>
                    {[{ contract.volume_estimated_open || 0 | number:3 }]}
                    <span class="smaller-label">
                      {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                    </span>
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'price',
            title: this.gettext('price'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-if="contract.price"> {[{ contract.price || 0 | number:2 }]} </span>
                  <span ng-if="!contract.price">---</span>
                  <span ng-if="contract.price" class="smaller-label"
                    >{[{ contract.currency_symbol }]}
                  </span>
                  <a
                    class="smaller-label label label_default"
                    ng-if="contract.contractprices_data.length"
                    ng-click="contract.contractpricesExpanded = !contract.contractpricesExpanded"
                  >
                    <i class="fa fa-anchor"></i> {[{contract.contractprices_data.length}]}
                  </a>
                  <div class="clearfix"></div>
                  <contract-prices-info
                    ng-if="contract.contractprices_data && contract.contractpricesExpanded"
                    ng-click="contract.contractpricesExpanded = !contract.contractpricesExpanded"
                    contract-prices="contract.contractprices_data"
                  ></contract-prices-info>
                </li>
              </ul>
            `,
            totalTemplate: /*html*/ `
            <div ng-if="item.total_value">
              {[{ item.total_value || 0 | number:2 }]}
              <span style="font-size:0.8em;"> <translate>$</translate> </span>
            </div>
          `,
          },
          {
            columnName: 'contracts_avg_price',
            title: this.gettext('contracts avg price'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.contracts_avg_price"> {[{ item.contracts_avg_price || 0 | number:2 }]} </span>
              <span ng-if="item.contracts_avg_price" class="smaller-label">
                {[{ item.currency_symbol }]}
              </span>
            `,
            totalTemplate: /*html*/ `
            <div ng-if="item.total_value">
              {[{ item.total_value || 0 | number:2 }]}
              <span style="font-size:0.8em;"> <translate>$</translate> </span>
            </div>
          `,
          },
          {
            columnName: 'analytical_price',
            title: this.gettext('analytical price'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-if="contract.analytical_price"> {[{ contract.analytical_price || 0 | number:2 }]} </span>
                  <span ng-if="!contract.analytical_price">---</span>
                  <span ng-if="contract.analytical_price" class="smaller-label"
                    >{[{ contract.currency_symbol }]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'conclusion_price',
            title: this.gettext('conclusion price'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-if="contract.conclusion_price"> {[{ contract.conclusion_price || 0 | number:2 }]} </span>
                  <span ng-if="!contract.conclusion_price">---</span>
                  <span ng-if="contract.conclusion_price" class="smaller-label"
                    >{[{ contract.currency_symbol }]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'total_value',
            title: this.gettext('total value'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-if="contract.total_value"> {[{ contract.total_value || 0 | number:2 }]} </span>
                  <span ng-if="!contract.total_value">---</span>
                  <span ng-if="contract.total_value" class="smaller-label"
                    >{[{ contract.currency_symbol }]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'market_zone',
            title: this.gettext('market zone'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-if="!contract.market_zone_name" class="smaller-label">---</span>
                  <span ng-if="contract.market_zone_name" class="smaller-label"
                    >{[{ contract.market_zone_name }]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'basis',
            title: this.gettext('basis'),
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  {[{ contract.basis_name || '---'}]}
                </li>
              </ul>
            `,
          },
          {
            columnName: 'exporter',
            title: this.gettext('exporter'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span class="exporter_set" ng-if="contract.exporter_data.length">
                    <span ng-repeat="exporter in contract.exporter_data">
                      <a
                        class=" smaller-label"
                        ng-class="{ 'label_danger': exporter.blacklisted}"
                        data-tip="{[{exporter.name}]}"
                        ng-click="args.openRoleModal(exporter.id, 'Exporter')"
                      >
                        <i class="fa fa-shield"></i> {[{ exporter.name | cut:true:25:' ...'}]}
                        <i class="fa fa-check" ng-if="exporter.date_confirmation"></i>
                        <div class="clearfix"></div>
                      </a>
                    </span>
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'vessel',
            title: this.gettext('vessel'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="connection in item.passports_data">
                <span ng-if="connection.vessel_name">
                  <i class="fa fa-ship"></i> {[{ connection.vessel_name }]}</span
                >
                <span ng-if="connection.vessel_name">
                  <span
                    class="label smaller-label"
                    ng-class="{
                   'label_success': connection.voyage_status == 'New',
                   'label_default': connection.voyage_status == 'Washout',
                   'label-default': connection.voyage_status == 'Executed',
                   'label_calculate': connection.voyage_status == 'pod_under_way' || connection.voyage_status == 'at_discharging_port' || connection.voyage_status == 'Discharging' || connection.voyage_status == 'Discharged',
                   'label_warning': connection.voyage_status == 'pol_under_way' || connection.voyage_status == 'at_loading_port' || connection.voyage_status == 'Loading' || connection.voyage_status == 'Loaded'
                } "
                    style="margin-left: 5px"
                    ng-if="connection.voyage_status"
                  >
                    {[{ connection.voyage_status }]}
                  </span>
                </span>
              </div>
            `,
          },
          {
            columnName: 'derivative',
            title: this.gettext('derivative'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span class="bigger-label" ng-repeat="derivative in contract.derivative_list">
                    {[{ derivative.derivative_title }]}
                    <div class="clearfix"></div>
                    <span class="smaller-label">
                    {[{ derivative.price_premium }]} <translate>premium</translate>
                     </span>
                     <div class="clearfix"></div
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'broker',
            title: this.gettext('broker'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
              <span ng-if="contract.broker_name">
                <i class="fa fa-briefcase"></i> {[{ contract.broker_name ||
                '---'}]}
                <i class="fa fa-suitcase"></i> {[{ contract.agent_name ||
                '---'}]}
              </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'port',
            title: this.gettext('port'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-repeat="port in contract.ports_set">
                    <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE]
                    || port.name | cut:true:30:'...'}]}
                    <div class="clearfix"></div>
                  </span>
                  <div class="clearfix"></div>
                  <span ng-if="contract.loadport_name">
                    <span class="smaller-label label-smoke"><translate>loadport</translate>: </span><i class="fa fa-anchor"></i> {[{ contract.loadport_name | cut:true:30:'...'}]}
                  </span>
                  <div class="clearfix"></div>
                  <span ng-if="contract.disport_name">
                    <span class="smaller-label label-smoke"><translate>disport</translate>: </span><i class="fa fa-anchor"></i> {[{ contract.disport_name | cut:true:30:'...'}]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'execution',
            title: this.gettext('delivery'),
            hint: this.gettext('shipment period of the contract'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span
                    class="tooltip"
                    ng-if="contract.date_of_execution"
                    ng-class="{
                  'label label_warning': ((contract.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > 0 && (contract.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 < $root.user.settings.DATE_EXECUTION_REMINDER && contract.end_of_execution > $root.serverTime) && !contract.final_volume,
                  'label label_danger': ((contract.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 <= 0 && (contract.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > $root.user.settings.DATE_EXECUTION_ALERT) && !contract.final_volume
                }"
                    data-tip="{[{ 'execution period' | translate }]} {[{contract.date_of_execution | date:'dd MMM yy'}]} - {[{contract.end_of_execution | date:'dd MMM yy'}]}"
                  >
                    <i class="fa fa-calendar"></i> {[{contract.date_of_execution || "---" |
                    date:'dd.MM.yy'}]} - {[{contract.end_of_execution || "---" | date:'dd.MM.yy'}]}
                  </span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'business_unit',
            title: this.gettext('Business unit'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              {[{ item.business_unit_title || '---' | cut:true:30:' ...' }]}
            `,
          },
          {
            columnName: 'info',
            title: this.gettext('info'),
            class: ' td-right-align',
            cellTemplate: /*html*/ `
              <span
                ng-class="{tooltip: item.additional_info, 'label-opacity': !item.additional_info}"
                data-tip="{[{item.additional_info}]}"
              >
                <i class="fa fa-comment"></i> {[{item.additional_info || '---' | cut:true:30:'
                ...'}]}
              </span>
            `,
          },
          {
            columnName: 'generalagreement',
            title: this.gettext('general agreement'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span class="bigger-label" ng-if="item.generalagreement_number">
                <i class="fa fa-handshake-o"></i> {[{ item.generalagreement_number }]}
                <span ng-if="item.generalagreement_percent_conditions">
                  {[{ item.generalagreement_percent_conditions }]} %
                </span>
              </span>
            `,
          },
          {
            columnName: 'not_allocated',
            title: this.gettext('not allocated'),
            class: 'small-font-cell td-right-align',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.available_to_connect != 0"
                ng-class="{'label label_warning': !item.final_volume, 'label label_danger': item.final_volume}"
              >
                {[{ item.available_to_connect || 0 | number:3}]}
                <span class="smaller-label">
                  {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
            `,
            totalTemplate: /*html*/ `
              <span>{[{ item.available_to_connect || 0 | number:3 }]} </span>
            `,
          },
          {
            columnName: 'editor',
            title: this.gettext('editor'),
            cellTemplate: /*html*/ `
              <span
                class="tooltip"
                data-tip="{[{ 'last updated' | translate }]} - {[{item.update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.editor_first_name }]} {[{ item.editor_last_name }]}, {[{ 'created' | translate }]} - {[{item.conclusion_date | date:'dd.MM.yy HH:mm'}]} - {[{ item.author_first_name }]} {[{ item.author_last_name }]}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            `,
          },
          {
            title: this.gettext('Documents'),
            columnName: 'documents',
            class: 'td-left-align',
            classExpr: "{ 'alert-success edit': args.rows[item.id].editable || !item.id }",
            cellTemplate: /*html*/ `
              <div
                class="btn-link smaller-label"
                ng-if="item.documents_data && item.documents_data.length > 3"
                ng-click="item.docsExpanded = !item.docsExpanded"
              >
                <i class="fa fa-download"></i> {[{item.documents_data.length}]}
              </div>
              <div ng-if="item.documents_data && item.documents_data.length <= 3">
                <div class="clearfix"></div>
                <div ng-repeat="doc in item.documents_data">
                  <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                    <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                    <span class="label label_info smaller-label" ng-if="doc.basis_doc_title"
                      >{[{doc.basis_doc_title | cut:true:25:'...'}]}
                    </span>
                  </a>
                </div>
              </div>

              <div ng-if="item.docsExpanded">
                <div class="clearfix"></div>
                <div ng-repeat="doc in item.documents_data">
                  <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                    <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                    <span class="label label_info smaller-label" ng-if="doc.basis_doc_title"
                      >{[{doc.basis_doc_title | cut:true:25:'...'}]}
                    </span>
                  </a>
                  <div class="clearfix"></div>
                </div>
              </div>
            `,
          },
          {
            title: this.gettext('Position documents'),
            columnName: 'positionDocuments',
            class: 'td-left-align',
            classExpr: "{ 'alert-success edit': args.rows[item.id].editable || !item.id }",
            cellTemplate: /*html*/ `
              <div ng-repeat="position in item.contracts_set">
                <div
                  class="btn-link smaller-label"
                  ng-if="position.documents_data && position.documents_data.length >= 1"
                  ng-click="position.docsExpanded = !position.docsExpanded"
                >
                  <i class="fa fa-download"></i> {[{position.documents_data.length}]}
                </div>
                <div ng-if="position.docsExpanded">
                  <div class="clearfix"></div>
                  <div ng-repeat="doc in position.documents_data">
                    <a href="{[{doc.file}]}" class="btn-link smaller-label" target="_blank">
                      <i class="fa fa-download"></i> {[{doc.title | cut:true:30:' ...'}]}
                      <span class="label label_info smaller-label" ng-if="doc.basis_doc_title"
                        >{[{doc.basis_doc_title | cut:true:25:'...'}]}
                      </span>
                    </a>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            `,
          },
          {
            columnName: 'approval_status',
            title: this.gettext('approval status'),
            class: 'td-left-align small-font-cell',
            filters: [
              {
                type: 'select',
                predicate: 'approval_status',
                label: this.gettext('Choose status'),
                values: {
                  wait: this.gettext('Wait'),
                  approved: this.gettext('Approved'),
                  process: this.gettext('Process'),
                  rejected: this.gettext('Rejected'),
                },
              },
            ],
            cellTemplate: /*html*/ `
              <span
                class="label pull-right"
                ng-if="item.approval_status != 'wait'"
                ng-class="{'label_success': item.approval_status == 'approved', 'label_warning': item.approval_status == 'process', 'label_danger': item.approval_status == 'rejected' }"
                style="margin-left: 2px;"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.approval_status == 'approved', 'fa-spin fa-spinner': item.approval_status == 'process', 'fa-times': item.approval_status == 'rejected' }"
                ></i>
                <translate>{[{ item.approval_status }]}</translate>
              </span>
            `,
          },
          {
            columnName: 'options',
            title: this.gettext('option'),
            hint: this.gettext('the actual tolerance range of the contract'),
            class: 'td-right-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="position in item.contracts_set">
                <div ng-if="position.volume">
                  <span ng-if="position.volume_options">
                    <div class="clearfix"></div>
                    {[{ position.volume_options || 0 | number:1}]}% - {[{ (position.volume * 1) -
                    ((position.volume * 1) * position.volume_options / 100) || 0 | number:3 }]} + {[{
                    (position.volume * 1) + ((position.volume * 1) * position.volume_options / 100) || 0 |
                    number:3 }]}
                  </span>
                </div>
                <div ng-if="!position.volume">---</div>
              </div>
            `,
          },
          {
            columnName: 'chain',
            class: 'td-left-align',
            title: this.gettext('chain'),
            cellTemplate: /*html*/ `
              <div ng-repeat="position in item.contracts_set">
                <span
                  ng-if="position.from_request"
                  class="label tooltip"
                  style="margin-left: 5px"
                  data-tip="{[{ 'This ticket was made from'|translate}]} #{[{ position.from_request_title }]}"
                >
                  <a ui-sref="gt.page.request({id: position.from_request})" class="btn-link">
                    <span> <i class="fa fa-file-o"></i> </span> {[{ position.from_request_title || position.from_request }]}
                  </a>
                </span>
                <span ng-if="!position.from_request">---</span>
                <span
                  ng-if="position.to_contract_title"
                  class="label tooltip"
                  style="margin-left: 5px"
                  data-tip="{[{ 'This contract was made from ticket'|translate}]} #{[{ position.to_contract_title }]}"
                >
                  <a ui-sref="gt.page.contract({id: position.to_contract_id})" class="btn-link">
                    <span> <i class="fa fa-file-text"></i> </span> {[{ position.to_contract_title || position.from_request }]}
                  </a>
                </span>
                <span ng-if="!position.to_contract_title">---</span>
              </div>
          `,
          },
          {
            columnName: 'positions_info',
            title: this.gettext('Positions info'),
            class: ' td-right-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="position in item.contracts_set">
                <span
                  ng-class="{tooltip: position.additional_info, 'label-opacity': !position.additional_info}"
                  data-tip="{[{position.additional_info}]}"
                >
                  <i class="fa fa-comment"></i> {[{position.additional_info || '---' | cut:true:30:'
                  ...'}]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'cp_approval_status',
            class: 'td-right-align',
            title: this.gettext('CP approval status'),
            cellTemplate: /*html*/ `
            <span
              class="bigger-label"
              ng-class="{'label-opacity': item.volume == 0}"
            >
              <span
                class="label"
                ng-class="{'label_success': item.counterparty_approval_status == 'approved', 'label_warning': item.counterparty_approval_status == 'process', 'label_danger': item.counterparty_approval_status == 'rejected' }"
                style="margin-left: 2px"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.counterparty_approval_status == 'approved', 'fa-spin fa-spinner': item.counterparty_approval_status == 'process', 'fa-times': item.counterparty_approval_status == 'rejected' }"
                ></i>
                <translate>{[{ item.counterparty_approval_status }]}</translate>
              </span>
            </span>
          `,
          },
          {
            columnName: 'cp_custom_status',
            title: this.gettext('CP custom status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.cp_custom_status_title"
                class="label"
                ng-class="{ 'label_calculate': item.cp_custom_status_codename == 'washout', 'label_success': item.cp_custom_status_codename == 'new', 'label-default': item.cp_custom_status_codename == 'cancelled', 'label_default': item.cp_custom_status_codename == 'executed', 'label_warning': item.cp_custom_status_codename == 'shipment' } "
              >
                {[{ item.cp_custom_status_title }]}
              </span>
            `,
          },
          {
            columnName: 'generalagreement_status',
            class: 'td-left-align',
            title: this.gettext('Agreement approval status'),
            cellTemplate: /*html*/ `
            <span
              class="bigger-label"
            >
              <span
                class="label"
                ng-class="{'label_success': item.generalagreement_status == 'approved', 'label_warning': item.generalagreement_status == 'process', 'label_danger': item.generalagreement_status == 'rejected' }"
              >
                <i
                  class="fa"
                  ng-class="{'fa-check': item.generalagreement_status == 'approved', 'fa-spin fa-spinner': item.generalagreement_status == 'process', 'fa-times': item.generalagreement_status == 'rejected' }"
                ></i>
                <translate>{[{ item.generalagreement_status }]}</translate>
              </span>
            </span>
          `,
          },
          {
            columnName: 'custom_status',
            title: this.gettext('custom status'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.custom_status_title"
                class="label"
                ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
              >
                {[{ item.custom_status_title }]}
              </span>
            `,
          },
          {
            columnName: 'signature',
            title: this.gettext('signature'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span
                class="label label_success"
                ng-if="item.signature_stage_all_signed"
                data-tip="{[{item.signature_stage_all_signed | date:'dd.MM.yy'}]}"
              >
                <i class="fa fa-check"></i> <i class="fa fa-pencil-square"></i>
              </span>
              <span class="label label_success" ng-if="item.signature_stage_originals_received">
                <i class="fa fa-check-square-o"></i> <i class="fa fa-file"></i>
              </span>
            `,
          },
          {
            columnName: 'signaturePosition',
            title: this.gettext('Positions signature'),
            class: ' td-right-align',
            cellTemplate: /*html*/ `
              <div ng-repeat="position in item.contracts_set">
                <span
                  class="label label_success"
                  ng-if="position.signature_stage_all_signed"
                  data-tip="{[{position.signature_stage_all_signed | date:'dd.MM.yy'}]}"
                >
                  <i class="fa fa-check"></i> <i class="fa fa-pencil-square"></i>
                </span>
                <span class="label label_success" ng-if="position.signature_stage_originals_received">
                  <i class="fa fa-check-square-o"></i> <i class="fa fa-file"></i>
                </span>
                <div class="clearfix"></div>
              </div>
            `,
          },
          {
            columnName: 'daysForInvoicePaymentPosition',
            title: this.gettext('Number of days for payment'),
            class: ' td-right-align',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="contract in item.contracts_set">
                  <span ng-if="contract.days_for_invoice_payment"> {[{ contract.days_for_invoice_payment || 0 | number:0 }]} </span>
                  <span ng-if="!contract.days_for_invoice_payment">---</span>
                </li>
              </ul>
            `,
          },
          {
            columnName: 'company_group',
            title: this.gettext('Company group'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span class="label" ng-if="item.company_group_title">
                <i class="fa fa-briefcase"></i>{[{item.company_group_title}]}
              </span>
            `,
          },
          {
            columnName: 'warehouse',
            title: this.gettext('warehouse'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <ul class="inside-table-ul">
                <li ng-repeat="warehouse in item.warehouses_data | unique:'title'">
                  <a
                    class="btn btn-xs col-xs-12"
                    target="_blank"
                    ui-sref="logistics.warehouseDetails({id: warehouse.id })"
                    style="text-align: left"
                  >
                    <i class="fa fa-building-o"></i> {[{ warehouse.title | cut:true:30:'...'}]}({[{
                    warehouse.type }]})
                  </a>
                  <div class="clearfix"></div>
                </li>
              </ul>
            `,
          },
        ];

        return this.getCustomFieldTableColumns().then((columns: any) => {
          options.columnDefs.push(...columns);
          options.tabs.forEach((tab) => {
            tab.columns.push(...columns.map((column: any) => column.columnName));
          });
          this.tableOptions = options;
        });
      }
    },
  ],
};
