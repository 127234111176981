import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('exportButton', directive);

  function directive() {
    return {
      template: require('./export-button.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {},
    };
  }

  Controller.$inject = ['$scope', 'HelpButtonsService'];

  function Controller(this: any, $scope: ng.IScope, HelpButtonsService: any) {
    const vm = this;
    vm.link = '';

    activate();

    ////////////////

    function activate() {
      $scope.$on('hb-config-updated', updateData);
      updateData();
    }

    function updateData() {
      if (HelpButtonsService.getConfig().export) {
        vm.link = HelpButtonsService.getConfig().export.link;
      }
    }
  }
})();
