import ng from 'angular';

import { CostsDistribution } from './costs-distribution/costs-distribution.module';
import { logisticDetails } from './details/logistic-details.module';
import { InvoiceBillingContainer } from './invoice-billing/invoice-billing.component';
import { logisticsContainer } from './list/logistics-container/logistics-container.component';
import { logisticsTable } from './list/logistics-table/logistics-table.component';
import { LogisticsPageView } from './list/pages/logistics-page-view/logistics-page-view.component';
import { LogisticConsignmentLinkContainer } from './logistic-consignment-link/logistic-consignment-link-container.component';
import { LogisticConsignmentLinkService } from './logistic-consignment-link/logistic-consignment-link.service';
import { LogisticDeleteContainer } from './logistic-delete/logistic-delete-container.component';
import { LogisticFormFieldsService } from './logistic-form-fields.service';
import { LogisticSetExportContractContainer } from './logistic-set-export-contract/logistic-set-export-contract-container.component';
import { LogisticsDocumentsContainer } from './logistics-documents-container/logistics-documents-container.component';
import { LogisticsDocumentsModal } from './logistics-documents-modal/logistics-documents-modal.component';
import { LogisticsInvoicingService } from './logistics-invoicing.service';

export const logistics = ng
  .module('execution.transport.logistics', [logisticDetails, CostsDistribution])
  .component('logisticsDocumentsContainer', LogisticsDocumentsContainer)
  .component('invoiceBillingContainer', InvoiceBillingContainer)
  .component('logisticConsignmentLinkContainer', LogisticConsignmentLinkContainer)
  .component('logisticSetExportContractContainer', LogisticSetExportContractContainer)
  .component('logisticsDocumentsModal', LogisticsDocumentsModal)
  .component('logisticsTable', logisticsTable)
  .component('logisticsPageView', LogisticsPageView)
  .component('logisticsContainer', logisticsContainer)
  .component('logisticDeleteContainer', LogisticDeleteContainer)
  .service('LogisticsInvoicingService', LogisticsInvoicingService)
  .service('LogisticFormFieldsService', LogisticFormFieldsService)
  .service('LogisticConsignmentLinkService', LogisticConsignmentLinkService).name;
