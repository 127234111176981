import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './consolidated-pnl-page.html?raw';

export const ConsolidatedPnlPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'ReportsService',
    class {
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      subtab: any;
      constructor(PageService: PageService, ReportsService: any) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.filterLevel = 'consolidated-pnl-page';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.subtab = 'unitpnl';
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {},
        };
      }
    },
  ],
};
