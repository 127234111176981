import React from 'react';

export const Skeleton: React.FC<{ height?: number; width?: number }> = ({
  height = 27.8,
  width = 200,
}) => (
  <div className="animate-pulse" data-testid="skeleton">
    <div
      className="rounded-sm bg-tags-background-gray"
      style={{ width: `${width}px`, height: `${height}px` }}
    />
  </div>
);
