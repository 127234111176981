import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.legacy').component('teamContainer', {
    bindings: {
      filterLevel: '<?',
    },
    template: require('./team-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'gtFilterService', 'AccountsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    AccountsService: any,
  ) {
    const vm = this;

    vm.queryParams = {};
    vm.users = [];
    vm.usersCount = 0;
    vm.updateData = updateData;
    vm.openUserModal = openUserModal;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'team-container';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    function updateData() {
      GtUtils.overlay('show');
      return AccountsService.User.query(
        vm.queryParams,
        (data: any) => {
          vm.users = data.results;
          vm.usersCount = data.count;
          GtUtils.overlay('hide');
        },
        (error: any) => {
          GtUtils.errorClb(error);
        },
      ).$promise;
    }

    function openUserModal(item: any) {
      return AccountsService.userModal(item).then(() => vm.updateData());
    }
  }
})();
