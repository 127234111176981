import type { GtRootScopeService } from '~/app/core/types';

import template from './invoice-list-list-view.html?raw';

export const InvoiceListListView = {
  bindings: {
    finances: '<?',
    count: '<?',
    filterLevel: '<?',
    onCreatePaymentPlan: '&?',
    createPlanButton: '<?',
    total: '<',
    applyFilters: '&?',
    openFinanceModal: '&?',
    openDocumentModal: '&?',
    openDocxModal: '&?',
    openRoleModal: '&?',
    clone: '&?',
    isGreen: '&?',
    getIcon: '&?',
    addInvoiceButton: '<?',
    addInvoiceButtonFunc: '&?',
    showPaymentsExportButton: '<?',
    paymentsExportConfig: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      count = 0;
      finances: any;
      gettext: ng.gettext.gettextFunction;
      invoiceExportButton: any;
      numberInput: any;
      partialTab: any;
      paymentsExportConfig: any;
      selection: any;
      showPaymentsExportButton: any;
      total: any;
      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.partialTab = { tab: 'partial' };
        this.finances = this.finances || [];
        this.total = this.total || {};
        this.selection = false;
        this.numberInput = false;
        this.invoiceExportButton = false;
        this.showPaymentsExportButton = false;
        this.paymentsExportConfig = '';
      }
    },
  ],
};
