import ng from 'angular';

import { GtTableCell } from './gt-table-cell.directive';
import { GtTableSticky } from './gt-table-sticky.directive';
import { GtTableComponent } from './gt-table.component';

export const gtTable = ng
  .module('gtTable', [])
  .directive('gtTableCell', GtTableCell)
  .directive('gtTableSticky', GtTableSticky)
  .component('gtTable', GtTableComponent).name;
