import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './reassignment-item.html?raw';

export const ReassignmentItemContainer = {
  bindings: {
    reassignment: '<',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$stateParams',
    '$rootScope',
    '$scope',
    '$state',
    'ReassignmentService',
    'DocumentsService',
    'GtUtils',
    'gtFilterService',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      DocumentsService: any;
      GtUtils: GtUtilsService;
      ReassignmentService: any;
      documentsCount: number;
      filterLevel = 'reassignment-item';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      reassignment: any;
      reassignmentPositions: any;
      tab: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        ReassignmentService: any,
        DocumentsService: any,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.reassignment = { id: $stateParams.id };
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.ReassignmentService = ReassignmentService;
        this.DocumentsService = DocumentsService;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.tab = 'info';
        this.documentsCount = 0;
      }

      $onInit() {
        this.updateData();
        this.gtFilterService.subscribe(this.filterLevel, () => this.updateData());
        this.updateCounters();
        this.$scope.$on('documents-container__updated', () => {
          this.updateCounters();
        });
      }

      addParentReassignment(reassignment: any) {
        this.ReassignmentService.openNewParentReassignmentModal(reassignment, () =>
          this.updateData(),
        );
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.ReassignmentService.getReassignmentDetails({ id: this.reassignment.id })
          .then((data: any) => {
            this.reassignment = data;
            this.$rootScope.pageTitle = this.$rootScope.pageTitle + ' #' + this.reassignment.number;

            return this.ReassignmentService.getReassignmentPositions({
              reassignment: this.reassignment.id,
            }).then((data: any) => {
              this.reassignmentPositions = data.results;
              this.GtUtils.overlay('hide');
            });
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext("Reassignment doesn't exist"));
            this.GtUtils.overlay('hide');
            this.$state.go('finances.reassignmentsPage');
          });
      }

      updateCounters() {
        this.GtUtils.getCounters(['documents.document'], {
          object_id: this.reassignment.id,
          content_type: this.reassignment.content_type,
        })
          .then((data: any) => {
            this.documentsCount = data['documents.document'];
          })
          .catch(this.GtUtils.errorClb);
      }

      editReassignment() {
        this.ReassignmentService.reassignmentModal(this.reassignment).then(() => this.updateData());
      }

      reassignmentPositionIsValid() {
        return this.ReassignmentService.reassignmentPositionIsValid(this.reassignmentPositions);
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Reassignment', this.reassignment.id);
      }
    },
  ],
};
