import React from 'react';

import { Checkbox } from '~/shared/ui/kit/checkbox';

export const SelectRowCheckbox: React.FC<{
  value: { selectedRowIds: string[]; rowId: string };
  valueChanged: (ids: string[]) => void;
}> = ({ value, valueChanged }) => {
  const isSelected = value.selectedRowIds.includes(value.rowId);

  const checkboxValueChanged = () => {
    const updatedSelectedRowIds = isSelected
      ? value.selectedRowIds.filter((id) => id !== value.rowId)
      : [...value.selectedRowIds, value.rowId];
    valueChanged?.(updatedSelectedRowIds);
  };

  return <Checkbox size="small" checked={isSelected} onClick={checkboxValueChanged} />;
};
