import template from './warehouses-table-view.html?raw';

export const WarehousesTableView = {
  bindings: {
    warehouses: '<',
    count: '<',
    tableName: '<?',
    filterLevel: '<?',
    openModal: '&',
    applyFilters: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      applyFilters: any;
      count: number;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      openModal: any;
      tableData: any;
      tableName: any;
      tableOptions: any;
      warehouses: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
        this.warehouses = [];
        this.count = 0;
        this.tableData = {};
        this.tableName = '';
        this.filterLevel = '';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'warehouses-table-view';
        this.tableName = this.tableName || this.filterLevel;
        this.tableOptions = this.getTableOptions();
      }
      $onChanges(changes: any) {
        if (changes.warehouses || changes.count) {
          this.tableData = { rows: this.warehouses, count: this.count };
        }
      }
      getTableOptions() {
        return {
          tableName: this.tableName,
          tableClass:
            'contract-charges-table customs-cargo-declaration-table sample-control-table table-with-inline-add sticky-table-first-td',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          configurable: true,
          changePageSize: true,
          showMore: true,
          templateArgs: {
            openModal: (item: any) => this.openModal({ item: item }),
            clone: (item: any) => this.openModal({ item: { ...item, id: undefined } }),
          },
          tabs: [],
          columnDefs: [
            {
              columnName: 'title',
              class: 'td-left-align',
              title: this.gettext('Title'),
              cellTemplate: /*html*/ `
               <div class="space-between">
                 <ul class="nav navbar-nav item-settings-dropdown">
                  <li>
                    <a
                      class="dropdown-toggle user-dropdown-btn"
                      class="btn btn-xs aligned-btn"
                    >
                      <span class="initial-transform">
                            <i class="fa {[{ $root.gtUtils.getIcon('warehouses.' + warehouse.type) }]}"></i>
                          {[{item.title}]}
                    </span>
                    </a>
                </li>
                </ul>
                  <ul
                    class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                    ng-mouseenter="args.setHovering(true)"
                    ng-mouseleave="args.setHovering(false)"
                  >
                    <li>
                      <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                        <i class="fa fa-ellipsis hide-on-hover"></i>
                        <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="btn btn-xs col-xs-12" ui-sref="logistics.warehouseDetails({id: item.id })">
                            <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'change_warehouse'"
                            class="btn btn-xs aligned-btn"
                            ng-disabled="item.result_status == 'approved'"
                            ng-click="args.openModal(item)"
                          >
                            <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                          </a>
                        </li>
                        <li>
                          <a
                            permission
                            permission-only="'edit_closed_elements'"
                            class="btn btn-xs aligned-btn"
                            ng-click="args.openModal(item)"
                          >
                            <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                          </a>
                        </li>
                        <li>
                          <a ng-click="args.clone(item)" class="btn btn-xs aligned-btn">
                            <i class="fa fa-files-o"></i> <translate>Clone</translate>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
              </div>
              `,
            },
            {
              columnName: 'warehouse_type',
              class: 'td-left-align',
              title: this.gettext('Type'),
              cellTemplate: /*html*/ `
                  <i class="fa {[{ $root.gtUtils.getIcon('warehouses.' + warehouse.type) }]}"></i>
                  <span>{[{ item.warehouse_type | translate}]}</span>
             `,
            },
            {
              columnName: 'parent',
              class: 'td-left-align',
              title: this.gettext('Parent'),
              cellTemplate: /*html*/ `
                <a ui-sref="logistics.warehouseDetails({id: item.parent })">
                  <i
                    class="fa {[{ $root.gtUtils.getIcon('warehouses.' + parent.warehouse_type) }]}"
                  ></i>
                  <span>{[{ item.parent_title | translate}]}</span>
                </a>
             `,
            },
            {
              columnName: 'owner',
              class: 'td-left-align',
              title: this.gettext('Client / Terminal'),
              cellTemplate: /*html*/ `
                <span ng-if="item.client">
                  <i class="fa fa-person"></i>
                  <a ui-sref="gt.page.client({id: item.client })">{[{ item.client_name }]}</a>
                </span>
                <span ng-if="item.terminal">
                  <i class="fa fa-anchor"></i>{[{ item.terminal_name }]}
                </span>
                `,
            },
            {
              columnName: 'responsible',
              class: 'td-left-align',
              title: this.gettext('Responsible'),
              cellTemplate: /*html*/ `
                <span ng-if="item.responsible">
                  <i class="fa fa-person"></i>
                  <a ui-sref="clients.persons({ id: item.responsible})">
                    {[{ (item.responsible_first_name || '') + ' ' + (item.responsible_middle_name || '') + ' ' + (item.responsible_last_name || '') }]}
                  </a>
                   
                </span>
                `,
            },
            {
              columnName: 'address',
              class: 'td-left-align',
              title: this.gettext('Address'),
              cellTemplate: /*html*/ `
                <span ng-if="item.address">
                  <i class="fa fa-envelope"></i>
                  <a href="admin/location/address/{[{item.address}]}">{[{ item.address_title }]}</a>
                </span>
                `,
            },
            {
              columnName: 'capacity',
              class: 'td-left-align',
              title: this.gettext('Capacity'),
              cellTemplate: /*html*/ `
                <span ng-if="item.capacity">{[{ item.capacity }]}</span>
                `,
            },
            {
              columnName: 'working_hours',
              class: 'td-left-align',
              title: this.gettext('Working hours'),
              cellTemplate: /*html*/ `
                <span ng-if="item.working_hours">{[{ item.working_hours }]}</span>
                ({[{ item.working_hours_start }]} :: {[{ item.working_hours_end }]})
                `,
            },
            {
              columnName: 'coordinates',
              class: 'td-left-align',
              title: this.gettext('Coordinates'),
              cellTemplate: /*html*/ `

                <span ng-if="item.coordinates">
                  <a href="{[{item.placeUrl}]}">
                    <i class="fa-solid fa-map-location-dot"></i></i>{[{ item.coordinates }]}
                  </a>
                </span>
                `,
            },
            {
              columnName: 'latitude',
              class: 'td-left-align',
              title: this.gettext('Latitude'),
              cellTemplate: /*html*/ `
                <span ng-if="item.latitude">{[{ item.latitude }]}</span>
                `,
            },
            {
              columnName: 'longitude',
              class: 'td-left-align',
              title: this.gettext('Longitude'),
              cellTemplate: /*html*/ `
                <span ng-if="item.longitude">{[{ item.longitude }]}</span>
                `,
            },
          ],
        };
      }
    },
  ],
};
