import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './debit-credit-table.html?raw';

export const DebitCreditTable = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    'gtFilterService',
    'DebitCreditService',
    'gettext',
    'GtUtils',
    '$scope',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      currencies: any;
      debitCreditService: any;
      filterLevel = 'debit-credit-table';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      reportData: any;
      showDelayDetails: any;
      tableApi: any;
      tableOptions: any;
      theadConfig: any;
      constructor(
        gtFilterService: GtFilterService,
        DebitCreditService: any,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        $scope: ng.IScope,
      ) {
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.$scope = $scope;
        this.debitCreditService = DebitCreditService;

        this.tableApi = undefined;
        this.tableOptions = {};
        this.queryParams = {};

        this.reportData = [];
        this.currencies = [];
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'debit-credit-table';

        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        if (!this.queryParams.start_date || !this.queryParams.end_date) {
          return this.GtUtils.notify(this.gettext('Dates are reqired'), 'error');
        }
        this.GtUtils.overlay('show');
        this.reportData = [];
        return this.debitCreditService.getReportData(this.queryParams).then(
          (data: any) => {
            this.reportData = data;
            this.currencies = data.totals_set || this.currencies;
            this.theadConfig = this.getTheadConfig();
            this.GtUtils.overlay('hide');
          },
          () => {
            this.GtUtils.notify(this.gettext('Something went wrong... try again.'), 'error');
          },
        );
      }

      getTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          {
            title: this.gettext('counterparty'),
            columnName: 'title',
            key: 'title',
            class: 'th-left-align',
          },
          {
            title: this.gettext('delay'),
            columnName: 'details',
            key: 'details',
            class: 'th-left-align',
          },
        ];
        this.currencies.forEach((currency: any) => {
          config.columns.push(
            {
              title: this.gettext('At start'),
              columnName: 'at_start',
              key: 'atStart',
              currency_id: currency.currency_id,
              currency_symbol: currency.currency_symbol,
              class: 'th-right-align',
            },
            {
              title: this.gettext('Invoiced'),
              columnName: 'invoiced',
              key: 'invoiced',
              currency_id: currency.currency_id,
              currency_symbol: currency.currency_symbol,
              class: 'th-right-align',
            },
            {
              title: this.gettext('Paid'),
              columnName: 'paid',
              key: 'paid',
              currency_id: currency.currency_id,
              currency_symbol: currency.currency_symbol,
              class: 'th-right-align',
            },
          );

          if (this.showDelayDetails) {
            config.columns.push(
              {
                title: this.gettext('up to 30 days'),
                columnName: 'atEnd30',
                key: 'atEnd30',
                currency_id: currency.currency_id,
                currency_symbol: currency.currency_symbol,
                class: 'th-right-align alert-success',
              },
              {
                title: this.gettext('30-60 days'),
                columnName: 'atEnd60',
                key: 'atEnd60',
                currency_id: currency.currency_id,
                currency_symbol: currency.currency_symbol,
                class: 'th-right-align alert-info',
              },
              {
                title: this.gettext('60-90 days'),
                columnName: 'atEnd90',
                key: 'atEnd90',
                currency_id: currency.currency_id,
                currency_symbol: currency.currency_symbol,
                class: 'th-right-align alert-warning',
              },
              {
                title: this.gettext('90 and over days'),
                columnName: 'atEndOver',
                key: 'atEndOver',
                currency_id: currency.currency_id,
                currency_symbol: currency.currency_symbol,
                class: 'th-right-align alert-danger',
              },
            );
          }

          config.columns.push({
            title: this.gettext('At end'),
            columnName: 'at_end',
            key: 'atEnd',
            currency_id: currency.currency_id,
            currency_symbol: currency.currency_symbol,
            class: 'th-right-align thick-border',
          });
        });

        return config;
      }
    },
  ],
};
