import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './attachments-edit-view.html?raw';

export const AttachmentsEditView = {
  bindings: {
    attachments: '<',
    deleteAttachment: '&',
    saveFile: '&',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    class {
      GtUtils: GtUtilsService;
      attachments: any;
      deleteAttachment: any;
      gettext: ng.gettext.gettextFunction;
      saveFile: any;
      updating: any;
      constructor(GtUtils: GtUtilsService, gettext: ng.gettext.gettextFunction) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.updating = false;
      }

      delete(item: any) {
        this.deleteAttachment({ attachment: item }).then(() => {
          const idx = this.attachments.indexOf(item);
          if (idx > -1) {
            this.attachments.splice(idx, 1);
          }
        });
      }

      downloadAll() {
        this.attachments.map((i: any) => this.GtUtils.downloadFile(`/media/${i.file}`));
      }

      addFiles(files: any) {
        const wrongFiles = files.filter((file: any) => file.size > 52428800);
        if (wrongFiles.length) {
          wrongFiles.map((file: any) =>
            this.GtUtils.notify(
              this.GtUtils.translate(this.gettext(`File '${file.name}' is to big`)),
              'error',
            ),
          );
          return false;
        }
        let chain = Promise.resolve();
        this.updating = true;
        files.map(
          (file: any) =>
            (chain = chain.then(() =>
              this.saveFile({ file: file }).then(
                (res: any) => this.attachments.push(res.data),
                (e: any) => this.GtUtils.errorClb(e),
              ),
            )),
        );
        chain.then(() => (this.updating = false)).catch((e) => this.GtUtils.errorClb(e));
      }
    },
  ],
};
