import React from 'react';

import { ActionView } from '~/shared/ui/components/action-bar/types';
import { DataGrid } from '~/shared/ui/components/data-grid';
import { BucketIcon } from '~/shared/ui/icons';

import { tableColumnsBuilder, tableDataBuilder } from './helpers';
import type { CommonView } from '../data-set-view';
import { InlineEditToolbar } from '../data-set-view';
import { SelectRecordToolbar } from '../data-set-view';
import { useTableView } from './hooks/use-table-view/use-table-view';
import { CellRender } from './lib/cell-render/cell-render';
import { HeaderRender } from './lib/header-render';

export const TableView = <TRecord extends { id: string }>({
  data,
  selectedRecordIds,
  editing,
  selectable,
  loading,
  onSelect,
  onEdit,
  onDelete,
  onSave,
}: CommonView<TRecord> & { onEdit: (diff: Partial<TRecord>) => void }) => {
  const columns = tableColumnsBuilder<TRecord>(data.properties);

  const table = useTableView<TRecord>({
    data: data.records,
    columns,
    isRowsSelectable: selectable,
    selectedRowIds: selectedRecordIds,
    recordSelected: onSelect,
  });

  const { rows, headerGroups, onChange } = tableDataBuilder({
    table,
    selectedRecordIds,
    selectable,
    onEdit,
  });

  return (
    <>
      <DataGrid
        rows={rows}
        headerGroups={headerGroups}
        selectedRowIds={selectedRecordIds}
        CellRender={CellRender}
        HeaderRender={HeaderRender}
        onEdit={onChange}
        onSelect={onSelect}
        editing={editing}
        loading={loading}
        stickyHeaders
      />
      {!!selectedRecordIds.length && !editing && (
        <SelectRecordToolbar
          selectedRowIds={selectedRecordIds}
          config={[
            {
              label: {
                text: 'Delete',
                context: 'text-text-additional-danger',
              },
              view: ActionView.Button,
              key: 'delete',
              Icon: BucketIcon,
              iconContext: 'text-text-additional-danger',
              action: () => selectedRecordIds.forEach((id) => onDelete(id)),
            },
          ]}
        />
      )}
      {editing && (
        <InlineEditToolbar
          config={[
            {
              label: {
                text: 'Save',
                context: 'text-text-additional-success',
              },
              view: ActionView.Button,
              key: 'save',
              action: onSave,
            },
          ]}
        />
      )}
    </>
  );
};
