import ng from 'angular';

import { QualityRuleTemplatesContainer } from './quality-rule-templates-container/quality-rule-templates-container.componet';
import { QualityRuleTemplatesListTableView } from './quality-rule-templates-list-table-view/quality-rule-templates-list-table-view.component';
import { QualityRuleTemplateModal } from './quality-rule-templates-modal/quality-rule-template-modal.component';
import { QualityRuleTemplatesPageView } from './quality-rule-templates-page-view/quality-rule-templates-page-view.component';
import { QualityRuleTemplatesService } from './quality-rule-templates.service';

const qualityRulesConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider
      .state('company', {
        parent: 'gt.page',
        url: '/company',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('company.quality-rule-templates', {
        url: '/quality-rule-templates',
        component: 'qualityRuleTemplatesPageView',
      });
  },
];

export const qualityRuleTemplatesModule = ng
  .module('company.qualityRuleTemplates', [])
  .component('qualityRuleTemplatesPageView', QualityRuleTemplatesPageView)
  .component('qualityRuleTemplatesContainer', QualityRuleTemplatesContainer)
  .component('qualityRuleTemplatesListTableView', QualityRuleTemplatesListTableView)
  .component('qualityRuleTemplateModal', QualityRuleTemplateModal)
  .config(qualityRulesConfig)
  .service('QualityRuleTemplatesService', QualityRuleTemplatesService).name;
