import React from 'react';

export const Label = React.forwardRef<
  HTMLLabelElement,
  {
    children: React.ReactNode;
  }
>(({ children }, ref) => {
  return (
    <label className="flex flex-col text-sm text-text-main-primary" ref={ref}>
      {children}
    </label>
  );
});

Label.displayName = 'Label';
