import type ng from 'angular';

import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './bank-account-rests-modal.html?raw';

export const BankAccountRestsModal = {
  bindings: {
    modalInstance: '<',
    bankAccount: '<?',
  },
  template,
  controller: [
    'gettext',
    'FinancesService',
    'moment',
    class {
      FinancesService: FinancesService;
      bankAccount: any;
      bankAccountRests: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      moment: any;
      tab: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        FinancesService: FinancesService,
        moment: any,
      ) {
        this.gettext = gettext;
        this.FinancesService = FinancesService;
        this.moment = moment;

        this.modalInstance = undefined;
        this.bankAccount = undefined;
      }

      $onInit() {
        this.bankAccount = this.bankAccount || {};
        this.tab = this.tab || 'edit';
        if (this.bankAccount.id) {
          this.updateData();
        }
      }
      newRow() {
        return {
          edit: true,
          actual_date: this.moment().format('YYYY-MM-DD'),
          amount: 0,
          bank_account: this.bankAccount.id,
        };
      }
      updateData() {
        this.FinancesService.BankAccountRests.query(
          { bank_account: this.bankAccount.id },
          (data: any) => (this.bankAccountRests = [this.newRow(), ...data.results]),
        );
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      save(rest: any) {
        return (
          rest.id
            ? this.FinancesService.BankAccountRests.update
            : this.FinancesService.BankAccountRests.save
        )(rest, () => this.updateData());
      }
      delete(rest: any) {
        return this.FinancesService.BankAccountRests.delete(rest, () => this.updateData());
      }
    },
  ],
};
