import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './pnl-mtm-page.html?raw';

export const PnlMtmPage = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'PageService',
    'ReportsService',
    'gtFilterService',
    'CoreService',
    'CropsService',
    'gettext',
    '$scope',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CoreService: CoreService;
      CropsService: any;
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      filterLevelConsolidated: any;
      filterLevelDetails: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      passportId: any;
      pricePositionView: any;
      queryParams: QueryParams = {};
      constructor(
        PageService: PageService,
        ReportsService: any,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
        CropsService: any,
        gettext: ng.gettext.gettextFunction,
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
      ) {
        this.PageService = PageService;
        this.ReportsService = ReportsService;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.CropsService = CropsService;
        this.gettext = gettext;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.filterLevel = '';
        this.passportId = undefined;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'pnl-mtm-page-view';
        this.filterLevelConsolidated = this.filterLevel + '-consolidated';
        this.filterLevelDetails = this.filterLevel + '-details';
        this.initQueryParams = { ...this.queryParams };
        this.queryParams = this.queryParams || {};
        this.pricePositionView = 'prices';

        this.PageService.setConfig(this.getPageConfig());
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          let params = data;
          if (!data.status_list) {
            params = { ...this.queryParams, ...data, ...this.initQueryParams };
          }
          this.gtFilterService.setQueryParams(params, this.filterLevelConsolidated);
          this.gtFilterService.setQueryParams(params, this.filterLevelDetails);
        });
        this.setDefaultCommodityParams();
        this.setDefaultSeasonParams();
      }

      setDefaultCommodityParams() {
        return this.CropsService.Crop.get({
          id: this.$rootScope.user.settings.DEFAULT_VALUES.default_position_commodity,
        }).$promise.then((defaultCommodityId: any) => {
          if (defaultCommodityId !== null) {
            this.queryParams = Object.assign(this.initQueryParams, {
              cargo_list: [defaultCommodityId.id],
            });
            this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
          }
        });
      }

      setDefaultSeasonParams() {
        return this.CoreService.getDefaultSeasonId().then((defaultSeasonId: any) => {
          this.queryParams = Object.assign(this.initQueryParams, {
            season_list: [defaultSeasonId],
          });

          this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        });
      }

      applyContractFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevelDetails);
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.ReportsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: false,
            dates: true,
            dateSelects: [
              {
                argument: 'date',
                placeholder: this.gettext('Date'),
              },
            ],
            multiSelects: [
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'season_list',
                placeholder: this.gettext('seasons'),
                resource: 'core.Season',
              },
              {
                argument: 'sale_passport_list',
                placeholder: this.gettext('passports'),
                resource: 'passports.Passport',
              },
              {
                argument: 'sale_contract_list',
                placeholder: this.gettext('sale contracts'),
                resource: 'contracts.SaleContract',
              },
              {
                argument: 'purchase_contract_list',
                placeholder: this.gettext('purchase contracts'),
                resource: 'contracts.PurchaseContract',
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter(
                'with_internal_chain',

                this.gettext('With internal chain'),
              ),
            ],
            nestedMultiSelects: [
              this.gtFilterService.getCropsFilter('cargo_list', this.gettext('CARGO'), {
                included_in_position: '1',
              }),
            ],
          },
        };
      }
    },
  ],
};
