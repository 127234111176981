import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './statistics-report-container.html?raw';

export const StatisticsReportContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'gtFilterService',
    'StatisticsReportService',
    'gettext',
    'moment',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      StatisticsReportService: any;
      commodityLevels: any;
      contractStatuses: any;
      data: any;
      defaultQueryParams: QueryParams = {};
      filterLevel = 'statistics-report-container';
      gettext: ng.gettext.gettextFunction;
      groupChoices: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      moment: any;
      purchaseIndicators: any;
      queryParams: any;
      saleIndicators: any;
      selectedGroups: any;
      tableView: any;
      treeView: any;
      unselectedGroups: any;
      yearList: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        StatisticsReportService: any,
        gettext: ng.gettext.gettextFunction,
        moment: any,
        GtUtils: GtUtilsService,
      ) {
        this.tableView = false;
        this.treeView = true;
        this.$rootScope = $rootScope;
        this.StatisticsReportService = StatisticsReportService;
        this.gettext = gettext;
        this.$scope = $scope;
        this.moment = moment;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.data = {};
        this.defaultQueryParams = {
          page_size: 25,
          date_predicate: 'date_of_execution',
          group_by: this.$rootScope.user.settings.DEFAULT_STATISTICS_GROUPING,
          start_date: this.moment(this.$rootScope.user.settings.DEBIT_CREDIT_START_DATE).format(
            'DD.MM.YYYY',
          ),
          end_date: this.moment(
            new Date(
              this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
              new Date().getMonth() + 1,
              0,
            ),
          ).format('DD.MM.YYYY'),
          commodity_level: '-1',
        };

        this.groupChoices = [
          { param: 'owner', title: this.gettext('owner'), icon: 'fa-home' },
          { param: 'counterparty', title: this.gettext('counterparty'), icon: 'fa-building' },
          { param: 'businessunit', title: this.gettext('business unit'), icon: 'fa-university' },
          { param: 'commodity', title: this.gettext('commodity'), icon: 'fa-list' },
          { param: 'origin', title: this.gettext('origin'), icon: 'fa-level-up' },
          { param: 'destination', title: this.gettext('destination'), icon: 'fa-level-down' },
          { param: 'incoterms', title: this.gettext('incoterms'), icon: 'fa-train' },
          { param: 'status', title: this.gettext('status'), icon: 'fa-tachometer' },
          { param: 'currencies', title: this.gettext('currencies'), icon: 'fa-money' },
        ];
        if (this.treeView) {
          this.groupChoices.push({
            param: 'contract_type',
            title: this.gettext('contract type'),
            icon: 'fa-file-text',
          });
        }
        this.contractStatuses = [
          { id: 'washout', title: this.gettext('Washout') },
          { id: 'new', title: this.gettext('New') },
          { id: 'delivery_period', title: this.gettext('Delivery period') },
          { id: 'shipment', title: this.gettext('Shipment') },
          { id: 'cargo_executed', title: this.gettext('Cargo executed') },
          { id: 'executed', title: this.gettext('Executed') },
          { id: 'cancelled', title: this.gettext('Cancelled') },
        ];
        this.commodityLevels = [
          { id: -1, title: this.gettext('Turn off') },
          { id: 0, title: '0' },
          { id: 1, title: '1' },
          { id: 2, title: '2' },
          { id: 3, title: '3' },
        ];
        this.selectedGroups = [];
        this.unselectedGroups = [...this.groupChoices];
        this.saleIndicators = [];
        this.purchaseIndicators = [];
        this.yearList = this.GtUtils.getYearList();
      }

      $onInit() {
        this.queryParams = {
          ...this.defaultQueryParams,
          ...this.initQueryParams,
          ...this.getDefaultStatusList(),
        };
        this.selectedGroups = [];
        this.unselectedGroups = [];
        this.groupChoices.forEach((v: any) => {
          if (this.queryParams.group_by.slice(',').includes(v.param)) {
            this.selectedGroups.push(v);
          } else {
            this.unselectedGroups.push(v);
          }
        });
        this.selectedGroups = this.groupChoices.filter((v: any) =>
          this.queryParams.group_by.slice(',').includes(v.param),
        );
        this.unselectedGroups = this.groupChoices.filter(
          (v: any) => !this.queryParams.group_by.slice(',').includes(v.param),
        );
        if (this.queryParams.contract_id_list && this.queryParams.contract_id_list.length > 0) {
          this.queryParams.start_date = this.moment(new Date('1970-01-01T00:00:00')).format(
            'DD.MM.YYYY',
          );
        }
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.updateData();
      }

      getDefaultStatusList() {
        return {
          status_list: this.contractStatuses
            .filter((status: any) => !['washout', 'cancelled'].includes(status.id))
            .map((status: any) => status.id),
        };
      }

      updateData() {
        if (!this.queryParams.group_by) {
          return this.GtUtils.notify(this.gettext('group by'));
        }
        this.GtUtils.overlay('show');

        return this.StatisticsReportService.getTreeData(this.queryParams).then((data: any) => {
          this.data = data.data;
          this.saleIndicators = data.saleIndicators;
          this.purchaseIndicators = data.purchaseIndicators;
          this.data.forEach((value: any, index: any) => {
            value.index = index;
            value.parentIndex = value.parent.index;
            value.expand = Boolean(value.level == 0);
            value.hasSale = this.saleIndicators.map((i: any) => value[i]).some((v: any) => v);
            value.hasPurchase = this.purchaseIndicators
              .map((i: any) => value[i])
              .some((v: any) => v);
            value.show = [0, 1].includes(value.level);
          });

          this.GtUtils.overlay('hide');
        });
      }

      resetFilter() {
        this.queryParams = this.defaultQueryParams;
        this.applyFilter();
      }

      applyFilter() {
        if (!this.queryParams.group_by.includes('commodity')) {
          this.queryParams.commodity_level = null;
        }
        return this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      selectChoice(choice: any) {
        if (this.selectedGroups.includes(choice)) {
          this.selectedGroups.splice(this.selectedGroups.indexOf(choice), 1);
          this.unselectedGroups.push(choice);
          if (choice.param == 'commodity') {
            this.queryParams.commodity_level = '1';
          }
        } else {
          this.selectedGroups.push(choice);
          this.unselectedGroups.splice(this.unselectedGroups.indexOf(choice), 1);
        }
        this.queryParams.group_by = this.selectedGroups.map((v: any) => v.param).join(',');
        this.applyFilter();
      }
    },
  ],
};
