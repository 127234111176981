import { container } from '~/shared/lib/di';
import { useObservableEagerState } from '~/shared/lib/state';

import { ResetPasswordService } from '../services';

export const useResetPassword = () => {
  const resetPasswordService = container.resolve(ResetPasswordService);
  const loading = useObservableEagerState(resetPasswordService.loading$);
  const isSend = useObservableEagerState(resetPasswordService.isSend$);

  return { resetPasswordService, loading, isSend };
};
