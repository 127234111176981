import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './additional-agreement-modal.html?raw';

export const AdditionalAgreementModal = {
  bindings: {
    modalInstance: '<',
    additionalAgreement: '<?',
    contract: '<?',
    multicontract: '<?',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'AdditionalAgreementsService',
    'ContractsService',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      AdditionalAgreementsService: any;
      ContractsService: ContractsService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      additionalAgreement: any;
      contract: any;
      contractPriceOptions: any;
      contractPriceToUpdate: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      multicontract: any;
      constructor(
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        AdditionalAgreementsService: any,
        ContractsService: ContractsService,
        $rootScope: GtRootScopeService,
      ) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.AdditionalAgreementsService = AdditionalAgreementsService;
        this.ContractsService = ContractsService;
        this.$rootScope = $rootScope;

        this.form = undefined;
        this.modalInstance = undefined;
        this.fields = [];
        this.contract = [];
        this.contractPriceOptions = [];
        this.contractPriceToUpdate = {};
        this.multicontract = null;
      }

      $onInit() {
        this.AdditionalAgreementsService.getContract(this.additionalAgreement.contract).then(
          (data: any) => {
            this.contract = data;
            this.additionalAgreement.$_position_available_volume = data.volume;
            if (this.contract.contract_prices.length) {
              this.additionalAgreement.$_position_available_volume = 0;
              this.contractPriceOptions = this.contract.contract_prices.map((price: any) => ({
                title: `${price.cargo_title} ${this.GtUtils.translate(this.gettext('Price'))}: ${
                  price.price
                }, ${this.GtUtils.translate(this.gettext('Volume'))}: ${price.volume}`,

                value: price.id,
              }));
            }
            this.initAdditionalAgreement();
            this.updateFields();
          },
        );
        this.additionalAgreement = this.additionalAgreement || {};
      }

      save(additionalAgreement: any, initNew: any) {
        this.AdditionalAgreementsService.applyAgreementToContract(
          this.contract,
          additionalAgreement,
          this.contractPriceToUpdate,
        ).then(
          (result: any) => {
            if (result) {
              const existingContractPrices = this.contractPriceOptions.map(
                (price: any) => price.value,
              );
              if (!additionalAgreement?.id) {
                additionalAgreement.contract_position =
                  existingContractPrices.length && additionalAgreement?.id
                    ? result.contract_prices.find(
                        (price: any) => !existingContractPrices.includes(price.id),
                      ).id
                    : result.contract_prices.find(
                        (price: any) => price.price === additionalAgreement.price,
                      ).id;
              }
            }
            return (
              additionalAgreement.id
                ? this.AdditionalAgreementsService.AdditionalAgreement.update(additionalAgreement)
                : this.AdditionalAgreementsService.AdditionalAgreement.save(additionalAgreement)
            ).$promise.then(
              () => {
                this.GtUtils.notify(this.gettext('Additional agreement saved'));
                if (initNew) {
                  this.initNewAdditionalAgreement();
                } else {
                  this.modalInstance.close();
                }
              },
              (error: any) => this.GtUtils.errorClb(error),
            );
          },
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      initAdditionalAgreement() {
        if (this.additionalAgreement.id) {
          this.additionalAgreement._update_contract_prices =
            !!this.additionalAgreement.contract_position;
          this.additionalAgreement._update_full_contract_price =
            !!this.additionalAgreement.contract_position && !!this.additionalAgreement.id;
          this.additionalAgreement.$_available_volume = this.additionalAgreement.volume;

          if (this.contract.contract_prices.length) {
            this.contractPriceToUpdate = this.contract.contract_prices.find(
              (price: any) => price.id === this.additionalAgreement.contract_position,
            );
          }
        } else {
          this.additionalAgreement.basis = this.contract.basis || null;
          this.additionalAgreement.ports = this.contract.ports ? [...this.contract.ports] : [];
          this.additionalAgreement.date_of_execution = this.contract.date_of_execution || null;
          this.additionalAgreement.end_of_execution = this.contract.end_of_execution || null;
          this.additionalAgreement.volume = this.contract.volume || null;
        }
      }

      delete() {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.AdditionalAgreementsService.AdditionalAgreement.delete({
          id: this.additionalAgreement.id,
        }).$promise.then(
          () => this.modalInstance.close(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      initNewAdditionalAgreement() {
        this.additionalAgreement = {};
        this.updateFields();
      }

      close(data: any) {
        if (confirm(this.gettext('Close modal?'))) {
          this.modalInstance.close(data || 'close');
        }
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(
          this.getFormConfig(this.additionalAgreement, this.contract),
        ).then(() => this.updateFields());
      }

      updateFields() {
        this.FormFieldParamsService.getFields(
          this.getFormConfig(this.additionalAgreement, this.contract),
        )
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      contractPriceSelected() {
        this.contractPriceToUpdate = this.contract.contract_prices.find(
          (price: any) => price.id === this.additionalAgreement.contract_position,
        );
        this.additionalAgreement = {
          ...this.additionalAgreement,
          cargo: this.contractPriceToUpdate.cargo,
          price: this.contractPriceToUpdate.price,
          basis: this.contractPriceToUpdate.basis || undefined,
          ports: this.contractPriceToUpdate.port ? [this.contractPriceToUpdate.port] : [],
          date_of_execution: this.contractPriceToUpdate.date_of_execution,
          end_of_execution: this.contractPriceToUpdate.end_of_execution,
          additional_info: this.contractPriceToUpdate.additional_info,
          $_available_volume: this.contractPriceToUpdate.volume,
          $_position_available_volume: this.contractPriceToUpdate.volume,
          volume: this.contractPriceToUpdate.volume,
        };
      }

      getFormConfig(agreement: any, contract: any) {
        const col1: any = {
          className: 'form-group-container col-sm-6 col-xs-12',
          fieldGroup: [],
        };
        const col2: any = {
          className: 'form-group-container col-sm-6 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'AGREEMENT',
            },
            fieldGroup: [
              {
                key: 'agreement_number',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Number'),
                  type: 'text',
                },
              },
              {
                key: 'agreement_date',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('Date'),
                  placeholder: this.gettext('date'),
                },
              },
              {
                key: 'contract',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Contract'),
                  resource: 'contracts.ContractBase',
                  queryParams: () => {
                    return {
                      status_list: [
                        'new',
                        'delivery_period',
                        'shipment',
                        'cargo_executed',
                        'executed',
                        'cancelled',
                        'washout',
                      ],
                      multicontract_list: this.multicontract ? [this.multicontract] : null,
                    };
                  },
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'CARGO',
            },
            fieldGroup: [
              {
                key: 'is_part_update',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Update part of the position'),
                  disabled: !!this.additionalAgreement.id,
                  onSelect: () => (agreement.$_position_volume = contract.volume),
                },
                hideExpression: () => !this.multicontract,
              },
              {
                key: '_update_contract_prices',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Update part of the volume'),
                  disabled: !!this.additionalAgreement.id,
                },
                hideExpression: () =>
                  (!!this.additionalAgreement.id && !agreement.contract_position) ||
                  !!this.multicontract,
              },
              {
                key: 'contract_position',
                type: 'gt-select',
                templateOptions: {
                  label: this.gettext('Contract position'),
                  hint: this.gettext('Choose contract position to update'),
                  valueProp: 'value',
                  labelProp: 'title',
                  disabled: !!this.additionalAgreement.id,
                  options: this.contractPriceOptions,
                  onSelected: () => this.contractPriceSelected(),
                },
                hideExpression: () => !contract.contract_prices.length,
              },
              {
                key: 'cargo',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Cargo'),
                  resource: 'crops.crop',
                  queryParams: () => {
                    return {
                      id_list:
                        agreement._update_contract_prices && !contract.contract_prices.length
                          ? contract.cargo
                          : null,
                    };
                  },
                  onSelect: () =>
                    (agreement.$_available_volume = agreement.contract_position
                      ? agreement.contract_position.volume
                      : contract.volume),
                },
                hideExpression: () => contract.use_type === 'services',
              },
              {
                key: 'charge',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Charge'),
                  resource: 'finances.Charge',
                  queryParams: () => {
                    return {
                      use_type_list: contract.positions_charge_use_type_list,
                    };
                  },
                },
                hideExpression: () => contract.use_type !== 'services',
              },
              {
                key: '_update_full_contract_price',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Update full contract price volume'),
                  disabled: !!this.additionalAgreement.id,
                },
                hideExpression: () =>
                  !agreement._update_contract_prices || !agreement.contract_position,
              },
              {
                key: '$_position_available_volume',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Available volume'),
                  type: 'number',
                  viewOnly: true,
                },
                hideExpression: () => !agreement.is_part_update,
              },
              {
                key: '$_available_volume',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Volume in contract'),
                  type: 'number',
                  viewOnly: true,
                },
                hideExpression: () => !agreement._update_contract_prices && !agreement.cargo,
              },
              {
                key: 'volume',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Volume'),
                  type: 'number',
                },
              },
              {
                key: 'volume_min',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Volume min'),
                  type: 'number',
                },
              },
              {
                key: 'volume_max',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Volume max'),
                  type: 'number',
                },
              },
              {
                key: 'volume_estimated_open',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Volume estimated'),
                  type: 'number',
                },
              },
              {
                key: 'volume_options',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Tolerance'),
                  type: 'number',
                },
              },
            ],
          },
        );
        col2.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'PRICE',
            },
            fieldGroup: [
              {
                key: 'price',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Price'),
                  type: 'number',
                },
              },

              {
                key: 'analytical_price',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Analytical price'),
                  type: 'number',
                },
              },
              {
                key: 'discount_mt',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Discount / 1 mt'),
                  placeholder: 'Discount per 1 mt',
                  type: 'number',
                },
              },
              {
                key: 'discount_amount',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Discount amount'),
                  placeholder: 'Discount amount',
                  type: 'number',
                },
              },
              {
                key: 'discount',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Discount %'),
                  placeholder: 'Discount %',
                  type: 'number',
                },
              },
              {
                key: 'currency_symbol',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Currency'),
                  resource: 'finances.currency',
                },
              },
              {
                key: 'price_premium',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Premium price'),
                  type: 'number',
                  placeholder: 'Premium',
                },
              },
              {
                key: 'derivative',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Derivative'),
                  placeholder: this.gettext('Derivative'),
                  resource: 'stockexchanges.Derivative',
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'DELIVERY',
            },
            fieldGroup: [
              {
                key: 'basis',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Basis'),
                  resource: 'logistics.basis',
                },
              },
              {
                key: 'ports',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('Port'),
                  resource: 'logistics.port',
                  options: this.additionalAgreement.ports,
                },
              },
              {
                key: 'warehouse',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Warehouse'),
                  resource: 'logistics.warehouse',
                  addIcon: this.GtUtils.getIcon('logistics.warehouse'),
                },
              },
              {
                key: 'date_of_execution',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('Start date'),
                  placeholder: this.gettext('date'),
                },
              },
              {
                key: 'end_of_execution',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('Start end'),
                  placeholder: this.gettext('date'),
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'ADDITIONAL',
            },
            fieldGroup: [
              {
                key: 'original_available',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Original available'),
                },
              },
              {
                key: 'additional_info',
                type: 'gt-textarea',
                templateOptions: {
                  label: this.gettext('Additional Info'),
                  placeholder: this.gettext('Specific information about this object'),
                  className: 'additional-info',
                },
              },
            ],
          },
        );

        return {
          formName: 'additional-agreement-edit-modal',
          fieldsDef: [col1, col2],
        };
      }
    },
  ],
};
