import ng from 'angular';

import { analytics } from './analytics/analytics.module';
import { billOfLading } from './bill-of-lading/bill-of-lading.module';
import { billOfLadingInvoiceposition } from './bill-of-lading-invoiceposition/bill-of-lading-invoiceposition.module';
import { customsCargoDeclaration } from './customs-cargo-declaration/customs-cargo-declaration.module';
import { customsCargoDeclarationDetails } from './customs-cargo-declaration/details/customs-cargo-declaration-details.module';
import { customsCargoDeclarationInvoicePosition } from './customs-cargo-declaration-invoice-position/customs-cargo-declaration-invoice-position.module';
import { disbursementbls } from './disbursementbls/disbursementbls.module';
import { driversModule } from './drivers';
import { driverDetails } from './drivers/details/driver-details.module';
import { intermodalTransports } from './intermodal-transports/intermodal-transports.module';
import { logistics } from './logistics/logistics.module';
import { sampleControl } from './sample-control/sample-control.module';
import { vehiclesModule } from './vehicles';
import { vehicleDetails } from './vehicles/details/vehicles-details.module';
import { voyageTariff } from './voyage-tariff/voyage-tariff.module';

export const transport = ng.module('execution.transport', [
  analytics,
  logistics,
  intermodalTransports,
  billOfLading,
  disbursementbls,
  sampleControl,
  customsCargoDeclaration,
  customsCargoDeclarationDetails,
  billOfLadingInvoiceposition,
  customsCargoDeclarationInvoicePosition,
  voyageTariff,
  driversModule,
  driverDetails,
  vehiclesModule,
  vehicleDetails,
]).name;
