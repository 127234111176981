import React from 'react';

import { Template as TemplateDataType } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const TemplateCell = <T,>({
  Template,
  cellTemplateContext,
  value,
  valueChanged,
}: CellTypeProps<T>) => {
  return Template ? (
    <TemplateDataType
      value={value}
      Template={Template}
      context={cellTemplateContext}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  ) : null;
};
