import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './client-related-info-container.html?raw';

export const ClientRelatedInfoContainer = {
  bindings: {
    client: '<',
  },
  template,
  controller: [
    'ClientsService',
    'GtUtils',
    'gettext',
    class {
      ClientsService: any;
      GtUtils: GtUtilsService;
      client: any;
      cropArea: any;
      gettext: ng.gettext.gettextFunction;
      transportsCount: number;
      constructor(
        ClientsService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.ClientsService = ClientsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.cropArea = undefined;
        this.transportsCount = 0;
      }

      saveClient() {
        return this.ClientsService.saveClient(this.client).then(
          () => {
            this.client.edit = false;
            this.GtUtils.notify(this.gettext('Counterparty saved'));
          },
          (e: any) => this.GtUtils.errorClb(e),
        );
      }

      closeClientEdit() {
        this.client.edit = false;
      }
    },
  ],
};
