import ng from 'angular';

import { ApprovalsReportContainer } from './approvals-report-container/approvals-report-container.component';
import { ApprovalsReportPage } from './approvals-report-page/approvals-report-page.component';
import { ApprovalsReportTable } from './approvals-report-table/approvals-report-table.component';
import { ApprovalsReportService } from './approvals-report.service';

export const approvalsReport = ng
  .module('reports.approvalsReport', [])
  .service('ApprovalsReportService', ApprovalsReportService)
  .component('approvalsReportPage', ApprovalsReportPage)
  .component('approvalsReportContainer', ApprovalsReportContainer)
  .component('approvalsReportTable', ApprovalsReportTable).name;
