import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

export class AnalyticalPlanFactPnlReportService {
  $http: ng.IHttpService;
  $rootScope: GtRootScopeService;
  currencyIcons: any;
  dates: any;
  defaultCurrency: any;
  defaultCurrencyIcon: any;
  details_template: any;
  gettext: ng.gettext.gettextFunction;
  indicators: any;
  indicators_template: any;
  moment: any;
  results: any;
  constructor(
    $http: ng.IHttpService,
    moment: any,
    gettext: ng.gettext.gettextFunction,
    $rootScope: GtRootScopeService,
  ) {
    this.$http = $http;
    this.moment = moment;
    this.gettext = gettext;
    this.$rootScope = $rootScope;
    this.currencyIcons = {
      EUR: 'fa-euro-sign',
      USD: 'fa-dollar-sign',
      UAH: 'fa-hryvnia-sign',
      RUB: 'fa-ruble-sign',
      GBP: 'fa-sterling-sign',
      KZT: 'fa-tenge-sign',
    };
    this.defaultCurrency = this.$rootScope.user.settings.DEFAULT_CURRENCY;
    this.defaultCurrencyIcon = this.currencyIcons[this.defaultCurrency];
  }
  getReportData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/analytical-plan-fact-pnl-report/',
      params: {
        ...params,
        view: 'tree',
      },
    }).then((response: any) => this.prepareData(response.data));
  }

  prepareData(data: any) {
    this.indicators = data.extra_list.indicators;
    this.indicators_template = data.extra_list.indicators_template;
    this.details_template = data.extra_list.details_template;
    this.results = this.unpack(data.results);
    this.setRowsVisibility(1);
    return {
      results: this.results,
      dates: this.dates,
      indicators: this.indicators,
    };
  }

  unpack(data: any, parent = {}) {
    const result: any = [];
    for (const branch of data || []) {
      branch.parent = parent;
      branch.indicators = this.deepCopy(this.indicators_template);
      result.push(branch);
      result.push(...this.unpack(branch.data, branch));
      if (branch.data) {
        delete branch.data;
      }
    }
    return result;
  }

  deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  parseGroups(groupsFilter = '') {
    const selectedGroups: any = [];
    const unselectedGroups = this.getGroupChoices();
    groupsFilter
      .split(',')
      .map((gr) => {
        const lst = unselectedGroups.filter((v) => v.param === gr);
        return (lst.length && lst[0]) || undefined;
      })
      .filter((v) => v !== undefined)
      .forEach((v: any) => {
        selectedGroups.push(v);
        unselectedGroups.splice(unselectedGroups.indexOf(v), 1);
      });
    return [selectedGroups, unselectedGroups];
  }

  setRowsVisibility(level: any) {
    this.results.forEach((v: any, i: any) => {
      v.index = i;
      v.parentIndex = v.parent.index;
      v.expand = v.show = Boolean(v.level < level);
    });
  }

  getGroupChoices() {
    return [
      { param: 'commodity', title: this.gettext('Commodity'), icon: 'fa-envira' },
      { param: 'businessunit', title: this.gettext('Business unit'), icon: 'fa-university' },
      { param: 'parent', title: this.gettext('Parent'), icon: 'fa-envira' },
      { param: 'season', title: this.gettext('Season'), icon: 'fa-envira' },
      { param: 'customstatus', title: this.gettext('Custom status'), icon: 'fa-envira' },
      { param: 'status', title: this.gettext('Status'), icon: 'fa-envira' },
      {
        param: 'analytical_parent',
        title: this.gettext('Analytical parent'),
        icon: 'fa-building',
      },
    ];
  }

  icons() {
    return {
      commodity: 'fa-envira',
      BU: 'fa-university',
      parent: 'fa-envira',
      analytic_parent: 'fa-envira',
      passport: 'fa-exchange',
      contract: 'fa fa-file-text',
    };
  }

  getTheadConfig(groupsTitle: any) {
    const config: any = {};
    // @ts-ignore
    config.columnSets = [
      { groupTitle: this.gettext('Groups'), group: 'groups', icons: this.icons() },
      { groupTitle: this.gettext('Purchases'), group: 'purchases' },
      { groupTitle: this.gettext('Costs'), group: 'costs' },
      { groupTitle: this.gettext('Sales'), group: 'sales' },
      { groupTitle: this.gettext('Margin'), group: 'margin' },
      { groupTitle: this.gettext('Diff Plan | Fact'), group: 'diffPlanFact' },
      { groupTitle: this.gettext('Diff analytical'), group: 'diffAnalytical' },
      { groupTitle: this.gettext('DIFF margin (ROS)'), group: 'diffROS' },
    ];
    // @ts-ignore
    config.columns = [
      { group: 'groups', title: groupsTitle, columnName: 'group_data' },

      { group: 'purchases', title: this.gettext('Ticket'), columnName: 'purchase_plans' },
      { group: 'purchases', title: this.gettext('Contract'), columnName: 'purchase_facts' },
      {
        group: 'purchases',
        title: this.gettext('Analytic'),
        columnName: 'purchase_facts_analytical',
      },

      { group: 'costs', title: this.gettext('Ticket'), columnName: 'plan_costs' },
      { group: 'costs', title: this.gettext('Contract'), columnName: 'fact_costs' },
      { group: 'costs', title: this.gettext('Passport'), columnName: 'passport_costs' },

      { group: 'sales', title: this.gettext('Ticket'), columnName: 'sale_plans' },
      { group: 'sales', title: this.gettext('Contract'), columnName: 'sale_facts' },
      { group: 'sales', title: this.gettext('Analytic'), columnName: 'sale_facts_analytic' },

      { group: 'margin', title: this.gettext('Ticket'), columnName: 'margin_plans' },
      { group: 'margin', title: this.gettext('Contract'), columnName: 'margin_facts' },
      { group: 'margin', title: this.gettext('Analytic'), columnName: 'margin_facts_analytic' },

      {
        group: 'diffPlanFact',
        title: this.gettext('Purchases (Plan - Fact)'),
        columnName: 'diffPurchasePlanFact',
      },
      {
        group: 'diffPlanFact',
        title: this.gettext('Sales (Plan - Fact)'),
        columnName: 'diffSalePlanFact',
      },
      {
        group: 'diffPlanFact',
        title: this.gettext('Margin (Plan - Fact)'),
        columnName: 'diffMarginPlanFact',
      },

      {
        group: 'diffAnalytical',
        title: this.gettext('Purchases (Analytical - Contract)'),
        columnName: 'diffPurchase',
      },
      {
        group: 'diffAnalytical',
        title: this.gettext('Margin (Analytical - Contract)'),
        columnName: 'diffMargin',
      },
      {
        group: 'diffROS',
        title: this.gettext('Ticket ROS'),
        columnName: 'diffTicketROS',
      },
      {
        group: 'diffROS',
        title: this.gettext('Contract ROS'),
        columnName: 'diffContractROS',
      },
      {
        group: 'diffROS',
        title: this.gettext('Margin (Analytical - Contract)'),
        columnName: 'diffAnalyticalROS',
      },
    ];
    // @ts-ignore
    config.cells = [
      {
        column: 'purchase_plans',
        title: this.gettext('ticket'),
        predicate: 'plan_purchase_amount',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'purchase_facts',
        title: this.gettext('contract'),
        predicate: 'fact_purchase_amount',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'purchase_facts_analytical',
        title: this.gettext('analytical'),
        predicate: 'fact_purchase_analytical_amount',
        icons: [this.defaultCurrencyIcon],
      },

      {
        column: 'plan_costs',
        title: this.gettext('total'),
        predicate: 'plan_costs_amount',
        icons: ['fa-pie-chart', this.defaultCurrencyIcon],
      },
      {
        column: 'plan_costs',
        title: this.gettext('purchase'),
        predicate: 'plan_purchase_costs_amount',
        icons: ['fa-arrow-down', this.defaultCurrencyIcon],
      },
      {
        column: 'plan_costs',
        title: this.gettext('sale'),
        predicate: 'plan_sale_costs_amount',
        icons: ['fa-arrow-up', this.defaultCurrencyIcon],
      },

      {
        column: 'fact_costs',
        title: this.gettext('total'),
        predicate: 'fact_costs_amount',
        icons: ['fa-pie-chart', this.defaultCurrencyIcon],
      },
      {
        column: 'fact_costs',
        title: this.gettext('purchase'),
        predicate: 'fact_purchase_costs_amount',
        icons: ['fa-arrow-down', this.defaultCurrencyIcon],
      },
      {
        column: 'fact_costs',
        title: this.gettext('sale'),
        predicate: 'fact_sale_costs_amount',
        icons: ['fa-arrow-up', this.defaultCurrencyIcon],
      },
      {
        column: 'passport_costs',
        predicate: 'passport_costs_amount',
        icons: ['fa-exchange', this.defaultCurrencyIcon],
      },

      {
        column: 'sale_plans',
        title: this.gettext('ticket'),
        predicate: 'plan_sale_amount',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'sale_facts',
        title: this.gettext('contract'),
        predicate: 'fact_sale_amount',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'sale_facts_analytical',
        title: this.gettext('analytical'),
        predicate: 'fact_sale_analytical_amount',
        icons: [this.defaultCurrencyIcon],
      },

      {
        column: 'margin_plans',
        title: this.gettext('ticket'),
        predicate: 'margin_plan',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'margin_facts',
        title: this.gettext('contract'),
        predicate: 'margin_fact',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'margin_facts_analytic',
        title: this.gettext('analytical'),
        predicate: 'margin_fact_analytical',
        icons: [this.defaultCurrencyIcon],
      },

      {
        column: 'diffPurchasePlanFact',
        title: this.gettext(''),
        predicate: 'diffs_purchase_plan_fact',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'diffSalePlanFact',
        title: this.gettext(''),
        predicate: 'diffs_sale_plan_fact',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'diffMarginPlanFact',
        title: this.gettext(''),
        predicate: 'diffs_margin_plan_fact',
        icons: [this.defaultCurrencyIcon],
      },

      {
        column: 'diffPurchase',
        title: this.gettext(''),
        predicate: 'diffs_purchase_analytical',
        icons: [this.defaultCurrencyIcon],
      },
      {
        column: 'diffMargin',
        title: this.gettext(''),
        predicate: 'diffs_margin_analytical',
        icons: [this.defaultCurrencyIcon],
      },

      {
        column: 'diffTicketROS',
        title: this.gettext('plan'),
        icons: ['fa-percent'],
        predicate: 'ros_plan',
      },
      {
        column: 'diffContractROS',
        title: this.gettext('fact'),
        icons: ['fa-percent'],
        predicate: 'ros_fact',
      },
      {
        column: 'diffAnalyticalROS',
        title: this.gettext(''),
        icons: ['fa-percent'],
        predicate: 'ros_fact_analytical',
      },
    ];
    // @ts-ignore
    config.columns = config.columns.map((col: any) =>
      Object.assign(col, {
        // @ts-ignore
        set: config.columnSets.filter((set: any) => col.group === set.group).pop(),
        // @ts-ignore
        cells: config.cells.filter((cell: any) => cell.column === col.columnName),
      }),
    );

    // @ts-ignore
    config.columnSets.forEach((set: any) => {
      // @ts-ignore
      set.count = config.columns.filter((col: any) => col.group === set.group).length;
    });
    return config;
  }
}
AnalyticalPlanFactPnlReportService.$inject = ['$http', 'moment', 'gettext', '$rootScope'];
