import ng from 'angular';

import { analytics } from './analytics/analytics.module';
import { bids } from './bids/bids.module';
import { MarketService } from './market.service';
import { offers } from './offers/offers.module';

const marketConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('market', {
        parent: 'gt.page',
        url: '/market',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('market.analytics', {
        url: '/analytics',
        component: 'marketAnalyticsPageView',
        data: {
          pageTitle: gettext('Analytics'),
        },
      });
  },
];

export const market = ng
  .module('deals.market', [analytics, offers, bids])
  .service('MarketService', MarketService)
  .config(marketConfig).name;
