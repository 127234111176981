import React from 'react';

import { MultiSelectWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const MultiselectCellWrite = <T,>({
  value,
  cellTemplateContext,
  valueChanged,
}: CellTypeProps<T>) => {
  return (
    <MultiSelectWrite
      value={value as string[]}
      cellTemplateContext={cellTemplateContext}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  );
};
