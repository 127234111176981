import ng from 'angular';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('core.legacy').component('theadFilterButton', {
    bindings: {
      switcher: '=',
    },
    template: require('./thead-filter-button.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gtFilterService'];

  function Controller(this: any, gtFilterService: GtFilterService) {
    const vm = this;
    vm.toggleButton = toggleButton;

    ////////////////

    function toggleButton() {
      if (vm.switcher) {
        gtFilterService.setQueryParams({});
      }
      vm.switcher = !vm.switcher;
    }
  }
})();
