import ng from 'angular';

(function () {
  'use strict';
  ng.module('reports.legacy').controller('RiskPositionTerminalModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'data'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    data: any,
  ) {
    const vm = this;

    vm.data = data;
    vm.close = close;

    ////////////////

    function close(data: any) {
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
