import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './notifications-page-view.html?raw';

export const notificationsPageView = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    'PageService',
    'gtFilterService',
    'NotificationService',
    'GtUtils',
    'gettext',
    class {
      GtUtils: GtUtilsService;
      NotificationService: any;
      PageService: PageService;
      filterLevel = 'notifications-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      messages: any;
      messagesCount = 0;
      queryParams: QueryParams = {};
      constructor(
        PageService: PageService,
        gtFilterService: GtFilterService,
        NotificationService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.NotificationService = NotificationService;
        this.queryParams = {};
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.gtFilterService.subscribe(
          this.filterLevel,
          (params: any) => {
            this.queryParams = params;
            this.updateData();
          },
          this.queryParams,
        );
        this.updateData();
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Print(), new this.PageService.buttons.Refresh()],
          pages: this.NotificationService.getPageConfig(),
        };
      }

      updateData() {
        this.NotificationService.getData(this.queryParams).then((res: any) => {
          this.messages = res.results;
          this.messagesCount = res.count;
        });
      }

      readMessage(notification: any) {
        notification.unread = false;
        this.NotificationService.update(notification).then(() => this.updateData());
      }

      readAll() {
        if (!confirm(this.gettext('Do you want to read all?'))) {
          return;
        }
        this.NotificationService.readAll({}).then(() => this.updateData());
      }

      openMessage(notification: any) {
        return this.GtUtils.goDetails(
          notification.actor_content_type,
          notification.actor_object_id,
        );
      }
    },
  ],
};
