import ng from 'angular';

import { GtTableAlignColHeight } from './gt-table-align-col-height.directive';
import { GtTableCell } from './gt-table-cell.directive';
import { GtTableSticky } from './gt-table-sticky.directive';
import { GtTableComponent } from './gt-table.component';
import { GtTableService } from './gt-table.service';

export const gtTableNew = ng
  .module('gtTableNew', [])
  .service('GtTableService', GtTableService)
  .directive('gtTableCellNew', GtTableCell)
  .directive('gtTableStickyNew', GtTableSticky)
  .directive('gtTableAlignColHeight', GtTableAlignColHeight)
  .component('gtTableNew', GtTableComponent).name;
