import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { DashboardEmbeded, DashboardSelect } from '~/features/common/bi-dashboards';
import { GreetingCard } from '~/pages/common/start/components/greeting-card';
import { useObservableState } from '~/shared/lib/state';
import { PageLayout } from '~/shared/ui/components/page/components';
import { ChartArcs } from '~/shared/ui/icons';

import { useStartPage } from '../../hooks';

export const StartPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { startPageStore } = useStartPage();

  const usersDashboards = useObservableState(startPageStore.usersDashboards$, []);
  const selectedDashboardUUID = useObservableState(startPageStore.selectedDashboardUUID$);
  const selectedDashboard = useObservableState(startPageStore.selectedDashboard$);

  return (
    <PageLayout
      leftContent={
        <div className="flex items-center gap-2">
          <ChartArcs size={16} className="text-stroke-main-inverted" />
          <h4 className="text-xs font-semibold text-text-main-primary">Start Page</h4>
        </div>
      }
    >
      <section className="flex py-4">
        <GreetingCard name={currentUser.name} />
      </section>
      {currentUser.settings.biEnabled && (
        <section className="w-full border-t border-stroke-main-medium bg-background-main-primary p-6">
          {selectedDashboard && (
            <div className="flex items-center justify-between">
              <h4 className="text-sm text-text-main-primary">Dashboards</h4>
              <DashboardSelect
                dashboard={selectedDashboard}
                dashboardOptions={usersDashboards}
                onSelect={(dashboard) => startPageStore.dashboardSelected(dashboard)}
              />
            </div>
          )}
          {selectedDashboardUUID && (
            <DashboardEmbeded
              dashboardUUID={selectedDashboardUUID}
              biDomain={currentUser.settings.biApiUrl}
              fetchGuestToken={() => startPageStore.fetchGuestToken(selectedDashboardUUID)}
            />
          )}
        </section>
      )}
    </PageLayout>
  );
};
