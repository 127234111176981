import type { Active } from '@dnd-kit/core';
import React from 'react';
import { createPortal } from 'react-dom';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  SortableContext,
  arrayMove,
  closestCenter,
  sortableKeyboardCoordinates,
  useSensor,
  useSensors,
} from '~/shared/lib/dnd';

import { SortableOverlay } from './sortable-overlay';
import { SortableProperty } from './sortable-property';
import type { ReorderedColumn } from '../../types';

export const DndColumnsList: React.FC<{
  columns: ReorderedColumn[];
  onChange: (columns: ReorderedColumn[]) => void;
  visibilityChange: (column: ReorderedColumn) => void;
}> = ({ columns, onChange, visibilityChange }) => {
  const [active, setActive] = React.useState<Active | null>(null);
  const activeItem = React.useMemo(
    () => columns.find((item) => item.id === active?.id),
    [active, columns],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const visibleColumns = React.useMemo(() => columns.filter((item) => item.visible), [columns]);

  return (
    <>
      <div className="bg-transparent-light p-1 text-[10px] font-semibold text-text-main-tertiary">
        Shown in view
      </div>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={({ active }) => {
            setActive(active);
          }}
          onDragEnd={({ active, over }) => {
            if (over && active.id !== over?.id) {
              const activeIndex = columns.findIndex(({ id }) => id === active.id);
              const overIndex = columns.findIndex(({ id }) => id === over.id);

              onChange(arrayMove(columns, activeIndex, overIndex));
            }
            setActive(null);
          }}
          onDragCancel={() => {
            setActive(null);
          }}
        >
          <SortableContext items={columns.filter((item) => item.visible)}>
            <div className="scrollbar flex max-h-[170px] flex-col gap-0.5 overflow-x-auto">
              {visibleColumns.map((item) => (
                <div className="flex items-center justify-between" key={item.id}>
                  <SortableProperty
                    id={item.id}
                    visible={item.visible}
                    visibilityChange={() => visibilityChange(item)}
                    column={item}
                  >
                    {item.name}
                  </SortableProperty>
                </div>
              ))}
            </div>
          </SortableContext>
          {createPortal(
            <SortableOverlay>
              {activeItem ? (
                <SortableProperty
                  id={activeItem.id}
                  visible={activeItem.visible}
                  visibilityChange={() => visibilityChange(activeItem)}
                  column={activeItem}
                >
                  {activeItem.name}
                </SortableProperty>
              ) : null}
            </SortableOverlay>,
            document.body,
          )}
        </DndContext>
      </div>
      <div className="bg-transparent-light p-1 text-[10px] font-semibold text-text-main-tertiary">
        Hidden
      </div>
      <div className="scrollbar flex max-h-[170px] flex-col gap-0.5 overflow-x-auto">
        {columns
          .filter((item) => !item.visible)
          .map((item) => (
            <div className="flex items-center justify-between" key={item.id}>
              <SortableProperty
                id={item.id}
                visible={item.visible}
                visibilityChange={() => visibilityChange(item)}
                column={item}
              >
                {item.name}
              </SortableProperty>
            </div>
          ))}
      </div>
    </>
  );
};
