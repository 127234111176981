import type ng from 'angular';

import type { QueryParams } from '~/app/core/types';

import template from './event-subscription-list.html?raw';

export const EventSubscriptionList = {
  bindings: {
    user: '<',
  },
  template,
  controller: [
    'gettext',
    'NotificationService',
    class {
      NotificationService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      queryParams: QueryParams = {};
      tableData: any;
      tableOptions: any;
      user: any;
      constructor(gettext: ng.gettext.gettextFunction, NotificationService: any) {
        this.gettext = gettext;
        this.NotificationService = NotificationService;

        this.filterLevel = 'event-subscription-list';
        this.queryParams = {};
        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.user) {
          this.queryParams = { user: this.user.id };
          this.updateData();
        }
      }

      updateData() {
        this.NotificationService.getEventSubscriptionList(this.queryParams).then((data: any) => {
          this.tableData = { rows: data.results, count: data.count };
        });
      }

      editEventSubscription(eventSubscription: any) {
        eventSubscription = eventSubscription || {
          user: this.user.id,
          email: this.user.email,
        };
        return this.NotificationService.openEventSubscriptionModal(eventSubscription).then(() =>
          this.updateData(),
        );
      }

      getTableOptions() {
        const options: any = {
          tableName: 'event-subscription-list',
          filterLevel: this.filterLevel,
          configurable: true,
          templateArgs: {
            editEventSubscription: (i: any) => this.editEventSubscription(i),
          },
          columnDefs: [] as any[],
        };

        options.columnDefs = [
          {
            columnName: 'action_title',
            title: this.gettext('System Event'),
            cellTemplate: /*html*/ `
            <span>
              {[{ item.action_title }]}
            </span>
           
            `,
          },
          {
            columnName: 'edit',
            title: this.gettext(''),
            cellTemplate: /*html*/ `
            <a class="btn-blue-border btn btn-xs" ng-click="args.editEventSubscription(item)">
            <i class="fa fa-pencil-square"></i>
          </a>
            `,
          },
          {
            columnName: 'active',
            title: this.gettext('Active'),
            cellTemplate: /*html*/ `
            <span class="positive-number">
              <i ng-if="item.active" class="fa fa-check"></i>
              </span>
            `,
          },
          {
            columnName: 'system_notification',
            title: this.gettext('System notify'),
            cellTemplate: /*html*/ `
              <i ng-if="item.system_notification" class="fa fa-bell"></i>
            `,
          },
          {
            columnName: 'telegram_chat',
            title: this.gettext('Telegram Chat'),
            cellTemplate: /*html*/ '{[{ item.telegram_chat_name }]}',
          },
          {
            columnName: 'email',
            title: this.gettext('Email'),
            cellTemplate: /*html*/ '{[{ item.email }]}',
          },
          {
            columnName: 'message_template_name',
            title: this.gettext('Template'),
            cellTemplate: /*html*/ '{[{ item.message_template_name }]}',
          },
          {
            columnName: 'filter_fields',
            title: this.gettext('Filters'),
            cellTemplate: /*html*/ "{[{ item.filter_fields.join(', ') }]}",
          },
        ];

        return options;
      }
    },
  ],
};
