import ng from 'angular';

import { contracts } from './contracts/contracts.module';
import { market } from './market/market.module';
import { multicontract } from './multicontract/multicontract.module';
import { multipassport } from './multipassport/multipassport.module';
import { passports } from './passports/passports.module';
import { requests } from './requests/request.module';
import { shared } from './shared/shared.module';

export const dealsModule = ng.module('deals', [
  contracts,
  requests,
  passports,
  multipassport,
  market,
  shared,
  multicontract,
]).name;
