import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('execution.legacy').component('freightPageView', {
    bindings: {},
    template: require('./freight-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'LogisticsService', 'gettext'];

  function Controller(
    this: any,
    PageService: PageService,
    LogisticsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.filterLevel = 'freight-page-view';

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = { class: 'page-header-main-logistics-tab', pages: [] };
      // @ts-ignore
      config.buttons = [
        new PageService.buttons.Filters(),
        new PageService.buttons.Print(),
        new PageService.buttons.Refresh(vm.filterLevel),
        new PageService.buttons.Import('logistics.logisticfreight'),
        new PageService.buttons.Export('logistics.logisticfreight', vm.filterLevel),
        new PageService.buttons.ScreenCaptureTask(),
      ];

      // @ts-ignore
      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [
          {
            title: gettext('RECENTLY UPDATED'),
            predicate: 'update_time',
          },
        ],
        search: true,
        dates: true,
        selects: [
          {
            argument: 'port',
            placeholder: gettext('Loading port'),
            resource: 'logistics.port',
          },
          {
            argument: 'departure_port',
            placeholder: gettext('Destination port'),
            resource: 'logistics.port',
          },
          {
            argument: 'deliverer',
            placeholder: gettext('forwarder'),
            resource: 'clients.deliverer',
          },
          {
            argument: 'business_unit',
            placeholder: gettext('business unit'),
            resource: 'core.BusinessUnit',
          },
          {
            argument: 'author',
            placeholder: gettext('author'),
            resource: 'auth.User',
          },
        ],
      };
      // @ts-ignore
      config.filters.nestedSelects = [];
      // @ts-ignore
      config.filters.multiSelects = [];
      // @ts-ignore
      config.filters.nestedMultiSelects = [];
      config.pages = LogisticsService.getPagesConfig();
      return config;
    }
  }
})();
