import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains',
)({
  component: () => (
    <div>
      Hello /_auth/_sidebar/crm/counterparties/_counterparty/$counterpartyId/costs-and-gains!
    </div>
  ),
});
