import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

(function () {
  'use strict';
  ng.module('finances.legacy').component('balancePageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./balance-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['PageService', 'gettext'];

  function Controller(this: any, PageService: PageService, gettext: ng.gettext.gettextFunction) {
    const vm = this;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'balance-page-view';
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-clients-tab',
        buttons: [
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Filters(),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: [
          {
            title: gettext('Balance'),
            permissions: [],
            state: 'finances.balance',
            icon: 'fa-balance-scale',
          },
        ],
      };

      // @ts-ignore
      config.filters = {
        filterLevel: vm.filterLevel,
        ordering: [],
        clicked: false,
        dates: true,
        dateSelects: [],
        multiSelects: [],
        nestedSelects: [],
        nestedMultiSelects: [],
      };
      return config;
    }
  }
})();
