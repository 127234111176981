import React from 'react';

import { UsersIcon } from '~/shared/ui/icons';
import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { LimitAccessContent } from './limit-access-content';

export const LimitAccess: React.FC<{
  isLimitAccess: boolean;
  limitAccessChanged: () => void;
}> = ({ isLimitAccess, limitAccessChanged }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState<boolean>(false);

  return (
    <DropdownMenuSub open={isSubMenuOpen} onOpenChange={setIsSubMenuOpen}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <UsersIcon size={14} className="text-stroke-main-stronger" />
          <p className="text-xs text-text-main-primary">Limit Access</p>
        </div>
        <DropdownMenuSubTrigger className="p-0">
          <p className="cursor-pointer text-xs text-text-main-tertiary">Everyone</p>
        </DropdownMenuSubTrigger>
      </div>
      <DropdownMenuPortal>
        <DropdownMenuSubContent
          className="flex w-[296px] flex-col gap-0.5 p-0"
          sideOffset={10}
          alignOffset={0}
        >
          <LimitAccessContent
            isLimitAccess={isLimitAccess}
            limitAccessChanged={limitAccessChanged}
            onOpenChange={(open) => setIsSubMenuOpen(open)}
          />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
