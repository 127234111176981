import React from 'react';

import { TableViewSettingsContent } from './table-view-settings-content';
import { BasicView } from '../basic-view';
import type { ReorderedColumn, TableView, ViewConfigChanged } from '../view-configurator/types';

export const TableViewSettings: React.FC<{
  trigger: React.ReactNode;
  view: TableView;
  columns: ReorderedColumn[];
  viewConfigChanged: ViewConfigChanged;
  viewDeleted: (id: number) => void;
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({
  view,
  trigger,
  columns,
  viewConfigChanged,
  viewDeleted,
  columnsReordered,
  columnVisibilityChanged,
}) => {
  return (
    <BasicView
      view={view}
      trigger={trigger}
      displayTriggerIcon={false}
      sideOffset={6}
      viewConfigChanged={viewConfigChanged}
      viewDeleted={viewDeleted}
      content={
        <TableViewSettingsContent
          view={view}
          viewConfigChanged={viewConfigChanged}
          columns={columns}
          columnsReordered={columnsReordered}
          columnVisibilityChanged={columnVisibilityChanged}
        />
      }
    />
  );
};
