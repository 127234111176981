import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './template-playground-page.html?raw';

export const templatePlaygroundPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'NotificationService',

    class {
      NotificationService: any;
      PageService: PageService;
      constructor(PageService: PageService, NotificationService: any) {
        this.PageService = PageService;
        this.NotificationService = NotificationService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Refresh()],
          pages: this.NotificationService.getPageConfig(),
        };
      }
    },
  ],
};
