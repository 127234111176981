import ng from 'angular';

(function () {
  'use strict';
  ng.module('execution.legacy').controller('LogiticsLogisticDocumentModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'doc', 'extra', 'gettext'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    doc: any,
    extra: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;
    vm.doc = doc || {};
    vm.extra = extra || {};
    vm.logisticsParams = {};
    vm.close = close;

    activate();

    ////////////////

    function activate() {
      if (vm.extra.contractType && vm.extra.contractType == 'purchase') {
        vm.logisticsParams = {
          supplier_contract: vm.doc.object_id,
        };
      } else if (vm.extra.contractType && vm.extra.contractType == 'sale') {
        vm.logisticsParams = {
          buyer_contract: vm.doc.object_id,
        };
      }
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
