import { createIconComponent } from '../lib';

const iconPath = `<path
        d="M2.66666 8.00001C2.66666 8.17682 2.73689 8.34639 2.86192 8.47141C2.98694 8.59644 3.15651 8.66668 3.33332 8.66668C3.51013 8.66668 3.6797 8.59644 3.80473 8.47141C3.92975 8.34639 3.99999 8.17682 3.99999 8.00001C3.99999 7.8232 3.92975 7.65363 3.80473 7.52861C3.6797 7.40358 3.51013 7.33334 3.33332 7.33334C3.15651 7.33334 2.98694 7.40358 2.86192 7.52861C2.73689 7.65363 2.66666 7.8232 2.66666 8.00001Z"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33332 8.00001C7.33332 8.17682 7.40356 8.34639 7.52859 8.47141C7.65361 8.59644 7.82318 8.66668 7.99999 8.66668C8.1768 8.66668 8.34637 8.59644 8.47139 8.47141C8.59642 8.34639 8.66666 8.17682 8.66666 8.00001C8.66666 7.8232 8.59642 7.65363 8.47139 7.52861C8.34637 7.40358 8.1768 7.33334 7.99999 7.33334C7.82318 7.33334 7.65361 7.40358 7.52859 7.52861C7.40356 7.65363 7.33332 7.8232 7.33332 8.00001Z"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.00001C12 8.17682 12.0702 8.34639 12.1953 8.47141C12.3203 8.59644 12.4898 8.66668 12.6667 8.66668C12.8435 8.66668 13.013 8.59644 13.1381 8.47141C13.2631 8.34639 13.3333 8.17682 13.3333 8.00001C13.3333 7.8232 13.2631 7.65363 13.1381 7.52861C13.013 7.40358 12.8435 7.33334 12.6667 7.33334C12.4898 7.33334 12.3203 7.40358 12.1953 7.52861C12.0702 7.65363 12 7.8232 12 8.00001Z"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />`;
export const EllipsesIcon = createIconComponent(iconPath, 'EllipsesIcon');
