import ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtHeadButton', {
    bindings: {
      config: '<',
    },
    template: require('./gt-head-button.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$rootScope'];

  function Controller(this: any, $rootScope: GtRootScopeService) {
    const vm = this;
    vm.click = click;

    vm.$onInit = function () {
      $rootScope.$on('gt-favorite-filter-clear', () => {
        vm.config.clicked = false;
      });
    };

    vm.$onChanges = function () {
      vm.config = { ...vm.config };
      vm.config.clicked = vm.config.clicked || false;
      vm.config.tip = vm.config.tip || '';
      vm.config.onClick = vm.config.onClick || function () {};
      vm.config.icon = vm.config.icon || 'fa-mouse-pointer';
      vm.config.iconClicked = vm.config.iconClicked || vm.config.icon;
      vm.config.disabled = vm.config.disabled || false;
    };

    ////////////////

    function click() {
      vm.config.clicked = !vm.config.clicked;
      vm.config.onClick();
    }
  }
})();
