import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

import template from './fixing-hedging-report-page-view.html?raw';

export const FixingHedgingReportPageView = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'PageService',
    'gtFilterService',
    'ReportsService',
    class {
      $scope: ng.IScope;
      PageService: PageService;
      ReportsService: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      constructor(
        $scope: ng.IScope,
        PageService: PageService,
        gtFilterService: GtFilterService,
        ReportsService: any,
      ) {
        this.$scope = $scope;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.ReportsService = ReportsService;

        this.filterLevel = '';
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'fixing-hedging-report-page-view';
        this.PageService.setConfig(this.getPageConfig());
      }

      applyFiltersContainer(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          pages: this.ReportsService.getPagesConfig(),
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
        };
      }
    },
  ],
};
