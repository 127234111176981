import ng from 'angular';

import type { QueryParams } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('finances.legacy').controller('BalanceModalController', Controller);

  Controller.$inject = ['$uibModalInstance', 'mode', 'queryParams', 'amount', 'gettext', 'moment'];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    mode: any,
    queryParams: QueryParams,
    amount: any,
    gettext: ng.gettext.gettextFunction,
    moment: any,
  ) {
    const vm = this;

    vm.amount = amount;
    vm.mode = mode || 'crop';
    vm.close = close;
    vm.queryParams = {
      business_unit_list: queryParams.business_unit_list,
      finance_account_id: queryParams.finance_account_id,
      start_date: queryParams.start_date && moment(queryParams.start_date).format('YYYY-MM-DD'),
      end_date: queryParams.end_date && moment(queryParams.end_date).format('YYYY-MM-DD'),
    };

    ////////////////

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }
  }
})();
