import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './vehicles-page-view.html?raw';

export const VehiclesPageView = {
  template,
  controller: [
    '$scope',
    'gettext',
    'PageService',
    'VehiclesService',
    class {
      $scope: ng.IScope;
      PageService: PageService;
      VehiclesService: any;
      filterLevel = 'vehicles-page-view';
      gettext: ng.gettext.gettextFunction;
      constructor(
        $scope: ng.IScope,
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        VehiclesService: any,
      ) {
        this.$scope = $scope;
        this.gettext = gettext;
        this.PageService = PageService;
        this.VehiclesService = VehiclesService;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.PageService.syncUrlFilter(this.filterLevel);
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Filters()],
          filters: {
            filterLevel: this.filterLevel,
            search: true,
            dates: true,
            dateSelects: [
              {
                argument: 'create_time',
                placeholder: this.gettext('Created at'),
              },
              {
                argument: 'update_time',
                placeholder: this.gettext('Updated at'),
              },
            ],
            searchFilters: [
              {
                argument: 'number_search',
                placeholder: this.gettext('Vehicle registration number'),
              },
              {
                argument: 'technical_passport_number_search',
                placeholder: this.gettext('Technical passport number'),
              },
            ],
            nestedSelects: [
              {
                argument: 'vehicle_type',
                placeholder: this.gettext('Vehicle type'),
                items: [
                  {
                    id: 'car',
                    title: this.gettext('Car'),
                  },
                  {
                    id: 'trailer',
                    title: this.gettext('Trailer'),
                  },
                ],
              },
            ],
            multiSelects: [
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('Business unit'),
                resource: 'core.BusinessUnit',
              },
              {
                argument: 'author_list',
                placeholder: this.gettext('Author'),
                resource: 'auth.User',
              },
              {
                argument: 'vehicle_subtype_list',
                placeholder: this.gettext('Vehicle subtype'),
                resource: 'logistics.VehicleSubtype',
              },
            ],
          },
          pages: this.VehiclesService.getPageConfig(),
        };
      }
    },
  ],
};
