import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';

import type { MarketAnalyticsService } from '../analytics.service';

export const MarketElevatorPrices = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template: /*html*/ `
    <div class="col-xs-12">
      <gt-resource-select
        style="width: 100%;"
        ng-model="$ctrl.queryParams.cargo"
        placeholder="'Commodity'|translate"
        resource-name="'crops.Crop'"
        allow-clear="true"
        on-select-clb="$ctrl.setFilters($event)"
      ></gt-resource-select>
    </div>
    <div class="clearfix"></div>
    <div class="row" style="margin-top: 10px;">
      <gt-inline-filter type="search" filter-level="$ctrl.filterLevel"></gt-inline-filter>
    </div>
    <gt-table options="$ctrl.tableOptions"></gt-table>
  `,
  controller: [
    'gettext',
    'MarketAnalyticsService',
    'gtFilterService',
    class {
      MarketAnalyticsService: MarketAnalyticsService;
      filterLevel = 'market-elevator-prices';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      tableApi: any;
      tableOptions: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        MarketAnalyticsService: MarketAnalyticsService,
        gtFilterService: GtFilterService,
      ) {
        this.gettext = gettext;
        this.MarketAnalyticsService = MarketAnalyticsService;
        this.gtFilterService = gtFilterService;

        this.tableOptions = this.getTableOptions();
        this.tableApi = undefined;
      }

      $onChanges(changes: any) {
        if (changes.queryParams) {
          this.setFilters();
        }
      }

      $onInit() {
        this.gtFilterService.subscribe(this.filterLevel, (params: any) => {
          this.queryParams = params;
          this.updateData();
        });
      }

      setFilters() {
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      getTableOptions() {
        return {
          tableName: 'sale_by_port',
          filterLevel: 'sale_by_port',
          columnDefs: [
            {
              columnName: 'port',
              cellTemplate: '<i class="fa fa-industry"></i> {[{ item.elevator_name }]}',
              title: this.gettext('title'),
            },
            {
              columnName: 'min_price',
              cellTemplate: `
            <span class="label label-bold positive-number">
              \${[{ item.min_price_in_USD || 0 | gtDecimal: 1 }]}
              <span class="label-opacity" ng-if="item.min_price != item.min_price_in_USD">
                {[{ item.min_price || 0 | gtDecimal: 1 }]}
              </span>
            </span>
            <span ng-if="item.min_basis">
              {[{ item.min_basis }]}
            </span>
            <span ng-if="item.min_date_of_execution">
              {[{item.min_date_of_execution | date:'MMM'}]}
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.min_buyer_name">
              <i class="fa fa-bookmark"></i> {[{ item.min_buyer_name }]}
            </span>
            <div class="clearfix"></div>
            <span class="label-opacity" ng-if="item.min_update_time">
              <translate>updated</translate>
              <i class="fa fa-calendar"></i> {[{item.min_update_time | date:'dd.MM'}]}
              <i class="fa fa-clock-o"></i> {[{item.min_update_time | date:'HH:mm'}]}
            </span>
          `,
              title: this.gettext('Min'),
            },
            {
              columnName: 'max_price',
              cellTemplate: `
            <span class="label label-bold negative-number">
              \${[{ item.max_price_in_USD || 0 | gtDecimal: 1 }]}
              <span class="label-opacity" ng-if="item.max_price != item.max_price_in_USD">
                {[{ item.max_price || 0 | gtDecimal: 1 }]}
              </span>
            </span>
            <span ng-if="item.max_basis">
              {[{ item.max_basis }]}
            </span>
            <span ng-if="item.max_date_of_execution">
              {[{item.max_date_of_execution | date:'MMM'}]}
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.max_buyer_name">
              <i class="fa fa-bookmark"></i> {[{ item.max_buyer_name }]}
            </span>
            <div class="clearfix"></div>
            <span class="label-opacity" ng-if="item.max_update_time">
              <translate>updated</translate>
              <i class="fa fa-calendar"></i> {[{item.max_update_time | date:'dd.MM'}]}
              <i class="fa fa-clock-o"></i> {[{item.max_update_time | date:'HH:mm'}]}
            </span>
          `,
              title: this.gettext('Max'),
            },
          ],
          rowData: [],
          onInit: (api: any) => (this.tableApi = api),
        };
      }

      updateData() {
        this.MarketAnalyticsService.getAnalyticsData(this.queryParams).then((data: any) => {
          this.tableApi.setRowData(data);
        });
      }
    },
  ],
};
