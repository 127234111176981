import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').filter('gtDecimal', filter);

  filter.$inject = ['$locale', 'numberFilter'];

  function filter($locale: ng.ILocaleService, numberFilter: any) {
    const formats = $locale.NUMBER_FORMATS;

    return decimalFilter;

    ////////////////////

    function decimalFilter(input: any, fractionSize: any) {
      const formattedValue = numberFilter(input || 0, fractionSize);
      const decimalIdx = formattedValue.indexOf(formats.DECIMAL_SEP);

      if (decimalIdx == -1) {
        return formattedValue;
      }

      return (
        formattedValue.substring(0, decimalIdx) +
        (Number(formattedValue.substring(decimalIdx)) || '').toString().substring(1)
      );
    }
  }
})();
