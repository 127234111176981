import React from 'react';

import type { Preset } from '../../types';
import { PresetActions } from '../preset-actions';
import { PresetList } from '../preset-list';

export const FiltersPresetSelector: React.FC<{
  preset: Preset;
  presetOptions: Preset[];
  onSelect: (preset: Preset) => void;
  onSave: (preset: Preset) => void;
  onDelete: (preset: Preset) => void;
}> = ({ preset, presetOptions, onSelect, onDelete, onSave }) => {
  return (
    <div className="flex items-center">
      <PresetList
        preset={preset}
        presetOptions={presetOptions}
        onSelect={onSelect}
        onDelete={onDelete}
      />
      <PresetActions preset={preset} presetOptions={presetOptions} onSave={onSave} />
    </div>
  );
};
