import type ng from 'angular';

import { type PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import { type GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import { type GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';
import { type FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './transactions-page-view.tpl.html?raw';

class TransactionsPageViewController implements ng.IController {
  filterLevel?: string;
  queryParams?: QueryParams;
  searchPrompt: string;

  static readonly $inject = [
    'gettext',
    'GtUtils',
    'gtFilterService',
    'FinancesService',
    'PageService',
  ];

  constructor(
    private readonly gettext: ng.gettext.gettextFunction,
    private GtUtils: GtUtilsService,
    private gtFilterService: GtFilterService,
    private FinancesService: FinancesService,
    private PageService: PageService,
  ) {
    this.searchPrompt = this.GtUtils.translate(this.gettext('Document ID'));
  }
  $onInit() {
    this.filterLevel = this.filterLevel ?? 'transactions-page-view';
    this.PageService.setConfig(this.getPageConfig());
  }

  getPageConfig() {
    const config: any = {
      class: 'page-header-main-payments-tab',
      buttons: [
        new this.PageService.buttons.Print(),
        new this.PageService.buttons.Refresh(this.filterLevel),
        new this.PageService.buttons.Filters(),
      ],
      pages: this.FinancesService.getPagesConfig(),
    };

    config.filters = {
      filterLevel: this.filterLevel,
      ordering: [],
      clicked: false,
      search: true,
      dates: true,
      dateSelects: [],
      searchPrompt: this.searchPrompt,
      selects: [
        {
          argument: 'client_id',
          placeholder: this.gettext('client'),
          resource: 'clients.Client',
        },
        {
          argument: 'debit_account_id',
          placeholder: this.gettext('finance debit account'),
          resource: 'finances.FinanceAccount',
        },
        {
          argument: 'credit_account_id',
          placeholder: this.gettext('finance credit account'),
          resource: 'finances.FinanceAccount',
        },
      ],
      nestedSelects: [
        {
          argument: 'filter',
          placeholder: this.gettext('All'),
          items: [
            {
              id: 'incoming_invoices',
              title: this.gettext('Incoming invoices'),
            },
            {
              id: 'outgoing_invoices',
              title: this.gettext('Outgoing invoices'),
            },
            {
              id: 'incoming_payments',
              title: this.gettext('Incoming payments'),
            },
            {
              id: 'outgoing_payments',
              title: this.gettext('Outgoing payments'),
            },
            {
              id: 'general',
              title: this.gettext('General'),
            },
          ],
        },
        this.gtFilterService.getBoolFilter(
          'is_complete',
          this.gettext('Only complete transactions'),
        ),
      ],
      multiSelects: [
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
        },
        {
          argument: 'contract_list',
          placeholder: this.gettext('Contracts'),
          resource: 'contracts.ContractBase',
        },
      ],
    };
    return config;
  }
}

export const transactionsPageView: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template: template,
  controller: TransactionsPageViewController,
};
