import { BehaviorSubject, map, of, switchMap } from 'rxjs';

import { singleton } from '~/shared/lib/di';

import { GlobalSearchRepository } from './global-search.repository';
import { SearchItem } from '../lib/types';

@singleton()
export class GlobalSearchStore {
  private readonly popupOpen = new BehaviorSubject(false);
  private readonly searchText = new BehaviorSubject<string>('');
  private readonly searchActions: SearchItem[] = [];

  constructor(private readonly searchRepo: GlobalSearchRepository) {}

  readonly popupOpen$ = this.popupOpen.asObservable();
  readonly searchText$ = this.searchText.asObservable();
  readonly searchResults$ = this.searchText$.pipe(
    switchMap((text) => (text ? this.searchRepo.search(text) : of(this.searchActions))),
    map((items) => this.groupByGroup(items)),
  );

  searchTextChanged(text: string) {
    this.searchText.next(text);
  }

  popupToggled() {
    this.popupOpen.next(!this.popupOpen.value);
  }

  registerSearchActions(action: SearchItem[]) {
    this.searchActions.push(...action);
  }

  private groupByGroup(items: SearchItem[]) {
    return items.reduce<Record<string, SearchItem[]>>(
      (acc, item) => ({ ...acc, [item.group]: [...(acc[item.group] || []), item] }),
      {},
    );
  }
}
