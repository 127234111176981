import ng from 'angular';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('contractPricesInfo', {
    bindings: {
      contractPrices: '<',
      onSelect: '&?',
    },
    template: require('./contract-prices-info.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  function Controller(this: any) {
    const vm = this;
    vm.cliced = undefined;

    ////////////////
  }
})();
