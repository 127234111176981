import React from 'react';

import { Link } from '~/shared/ui/kit/link';

import type { DataTypeProps } from '../../lib';

export const EmailRead: React.FC<DataTypeProps<string>> = ({ disabled, value }) => {
  return value ? (
    <div className="px-2">
      <Link href={`mailto:${value}`} disabled={disabled}>
        {value}
      </Link>
    </div>
  ) : null;
};
