import type ng from 'angular';

import type { GtRootScopeService } from '../../types';

export class CommentsService {
  $resource: ng.resource.IResourceService;
  $rootScope: GtRootScopeService;
  commentResource: any;
  constructor($resource: ng.resource.IResourceService, $rootScope: GtRootScopeService) {
    this.$resource = $resource;
    this.$rootScope = $rootScope;

    this.commentResource = $resource(
      '/api/core/comments/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  getComments(params: any) {
    return this.commentResource.query(params).$promise;
  }

  saveComment(comment: any) {
    if (comment.id) {
      return this.commentResource.update(comment).$promise;
    }
    return this.commentResource.save(comment).$promise;
  }

  deleteComment(comment: any) {
    return this.commentResource.delete(comment).$promise;
  }

  getNewComment(contentType: any, objectId: any) {
    return {
      author: this.$rootScope.user.id,
      author_avatar: this.$rootScope.user.avatar,
      author_first_name: this.$rootScope.user.first_name,
      content_type: contentType,
      object_id: objectId,
    };
  }
}
CommentsService.$inject = ['$resource', '$rootScope'];
