import React from 'react';

import { LoginCard } from '~/core/auth/components/';
import { useAuth } from '~/core/auth/hooks';
import { Navigate } from '~/shared/lib/router';

export const LoginPage: React.FC = () => {
  const { isAuthenticated, authStore } = useAuth();

  return isAuthenticated ? (
    <Navigate to="/" />
  ) : (
    <section className="flex h-screen items-center justify-center bg-pexels bg-cover bg-center bg-no-repeat">
      <LoginCard onLogin={(creds) => authStore.loginRequested(creds)} />
    </section>
  );
};
