import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

import template from './operation-modal.html?raw';

export const OperationModal = {
  bindings: {
    modalInstance: '<',
    operation: '<?',
  },
  template,
  controller: [
    '$window',
    '$q',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'OperationsService',
    '$rootScope',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $window: ng.IWindowService;
      ClientsService: any;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      OperationsService: any;
      fields: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      modalInstance: any;
      operation: any;
      constructor(
        $window: ng.IWindowService,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        OperationsService: any,
        $rootScope: GtRootScopeService,
      ) {
        this.$window = $window;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.OperationsService = OperationsService;
        this.$rootScope = $rootScope;

        this.form = undefined;
        this.fields = [];
      }

      $onInit() {
        this.operation = this.operation || {};
        this.updateFields();
      }

      initNewOperation() {
        this.operation = {};
        this.updateFields();
      }

      save(operation: any, initNew: any) {
        return (
          operation.id
            ? this.OperationsService.updateOperation(operation)
            : this.saveOperation(operation)
        )
          .then((data: any) => {
            if (initNew) {
              return this.initNewOperation();
            }
            return this.modalInstance.close(data);
          })
          .catch((error: any) => this.GtUtils.errorClb(error))
          .finally(() => this.GtUtils.notify(this.gettext('Operation saved')));
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      saveOperation(operation: any) {
        return this.chekOperation(operation).then((isValid: any) => {
          return isValid ? this.OperationsService.saveOperation(operation) : this.$q.reject();
        });
      }

      destroy(operation: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return;
        }
        return this.OperationsService.deleteOperation(operation).then(
          (e: any) => this.GtUtils.errorClb(e),
          this.modalInstance.close(),
          this.GtUtils.notify(this.gettext('Operation deleted')),
        ).$promise;
      }

      chekOperation(operation: any) {
        return this.OperationsService.checkDerivative(operation).then((messages: any) => {
          return this.takeMessage(messages);
        });
      }

      takeMessage(messages: any) {
        let isValid = true;
        for (const message of messages) {
          if (!confirm(message)) {
            isValid = false;
            break;
          }
        }
        return this.$q.resolve(isValid);
      }

      openFieldsConfigModal() {
        // @ts-ignore
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig(this.operation))
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig(operation: any) {
        const col1: any = {
          className: 'form-group-container col-sm-12 col-xs-12',
          fieldGroup: [],
        };

        col1.fieldGroup.push(
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'TYPE',
            },
            fieldGroup: [
              {
                key: 'business_date',
                type: 'gt-date-select',
                templateOptions: {
                  label: this.gettext('Business date'),
                  placeholder: this.gettext('Business date'),
                },
              },
              {
                key: 'business_unit',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Business unit'),
                  resource: 'core.businessunit',
                },
              },
              {
                key: 'deal_type',
                type: 'gt-select',
                templateOptions: {
                  label: this.gettext('Deal type'),
                  valueProp: 'value',
                  labelProp: 'name',
                  options: [
                    { value: 'long', name: this.gettext('Long') },
                    { value: 'short', name: this.gettext('Short') },
                  ],
                },
              },
              {
                key: 'operation_type',
                type: 'gt-select',
                templateOptions: {
                  label: this.gettext('Type'),
                  valueProp: 'value',
                  labelProp: 'name',
                  options: [
                    { value: 'fixing', name: this.gettext('Fixing') },
                    { value: 'hedging', name: this.gettext('Hedging') },
                    { value: 'rolling', name: this.gettext('Rolling') },
                    { value: 'paper_trade', name: this.gettext('Paper trade') },
                  ],
                },
              },
              {
                key: 'is_virtual',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Virtual'),
                },
              },
              {
                key: 'use_in_position',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Position'),
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'DERIVATIVE',
            },
            fieldGroup: [
              {
                key: 'derivative_type',
                type: 'gt-select',
                templateOptions: {
                  label: this.gettext('Derivative type'),
                  valueProp: 'value',
                  labelProp: 'name',
                  options: [
                    { value: 'forward', name: this.gettext('Forward') },
                    { value: 'swap', name: this.gettext('Swap') },
                    { value: 'option', name: this.gettext('Option') },
                    { value: 'futures', name: this.gettext('Futures') },
                  ],
                },
              },
              {
                key: 'derivative',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Derivative'),
                  resource: 'stockexchanges.Derivative',
                  addFunc: () => {
                    this.$window.open('/admin/stockexchanges/derivative/add/');
                  },
                  addPerms: ['add_derivative'],
                  addIcon: 'fa fa-suitcase',
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'PARAMETERS',
            },
            fieldGroup: [
              {
                key: 'quantity',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Lots'),
                  type: 'number',
                },
              },
              {
                key: 'price',
                type: 'gt-input',
                templateOptions: {
                  label: this.gettext('Price'),
                  type: 'number',
                },
              },
            ],
          },
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: 'ALLOCATION',
            },
            fieldGroup: [
              {
                key: 'broker',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Broker'),
                  resource: 'clients.Broker',
                  queryParams: () => ({
                    stockexchange: operation.stock_exchange,
                  }),
                  addFunc: () => {
                    return this.ClientsService.roleModal({ model_name: 'Broker' });
                  },
                  addIcon: this.GtUtils.getIcon('clients.Broker'),
                  addPerms: ['add_broker'],
                },
              },
              {
                key: 'contract',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Contract'),
                  resource: 'contracts.contractbase',
                  queryParams: () => ({
                    stage: 'contract',
                    derivatives: operation.derivative,
                  }),
                  hint: this.gettext('Allocate this operation to certain contract'),
                },
              },
              {
                key: 'passport',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
                  resource: 'passports.Passport',
                  queryParams: () => ({
                    contract: operation.contract,
                  }),
                  help: this.gettext(
                    'Allocate this operation to certain passport. This passport will be a criteria to match sales and purchases in Derivatives - Contract Position report',
                  ),
                },
              },
              {
                key: 'derivative_account',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Derivative account'),
                  resource: 'stockexchanges.DerivativeAccount',
                  queryParams: { is_active: 1 },
                  help: this.gettext(
                    'Allocate this operation to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report',
                  ),
                  addFunc: () => {
                    this.$window.open('/admin/stockexchanges/derivativeaccount/add/');
                  },
                  addPerms: ['add_derivativeaccount'],
                  addIcon: 'fa fa-suitcase',
                },
              },
            ],
          },
        );

        return {
          formName: 'operation-edit-modal',
          fieldsDef: [col1],
        };
      }
    },
  ],
};
