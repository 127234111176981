import React from 'react';

import { ListViewSettingsContent } from './list-view-settings-content';
import { BasicView } from '../basic-view';
import type { ListView, ReorderedColumn, ViewConfigChanged } from '../view-configurator/types';

export const ListViewSettings: React.FC<{
  trigger: React.ReactNode;
  view: ListView;
  viewConfigChanged: ViewConfigChanged;
  viewDeleted: (viewId: number) => void;
  columns: ReorderedColumn[];
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}> = ({
  viewDeleted,
  view,
  trigger,
  viewConfigChanged,
  columns,
  columnsReordered,
  columnVisibilityChanged,
}) => {
  return (
    <BasicView
      view={view}
      trigger={trigger}
      displayTriggerIcon={false}
      sideOffset={6}
      viewConfigChanged={viewConfigChanged}
      viewDeleted={viewDeleted}
      content={
        <ListViewSettingsContent
          view={view}
          columns={columns}
          viewConfigChanged={viewConfigChanged}
          columnsReordered={columnsReordered}
          columnVisibilityChanged={columnVisibilityChanged}
        />
      }
    />
  );
};
