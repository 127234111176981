export class LogisticsInvoicingService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  createInvoiceObject(initInvoice: any) {
    return this.$http({
      method: 'POST',
      url: '/api/logistics/logistics/generate_invoice/',
      data: initInvoice,
    }).then((response: any) => response.data);
  }
}
LogisticsInvoicingService.$inject = ['$http'];
