import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').filter('commaToDecimal', filter);

  filter.$inject = ['$locale'];

  function filter() {
    return commaToDecimalFilter;

    //////////////////////

    function commaToDecimalFilter(input: any) {
      const ret = input?.toString().trim().replace(',', '.');
      return parseFloat(ret);
    }
  }
})();
