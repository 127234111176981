import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './multicontract-position-form.html?raw';
import type { ContractsService } from '../../contracts/legacy/contracts.srv';
import type { MulticontractService } from '../multicontract.service';

export const MulticontractPositionForm = {
  bindings: {
    position: '<',
    updateFieldsTrigger: '<',
    formValidChange: '&',
    calculateTotals: '&',
    changeIsActive: '&',
    getPositionContractNumber: '&',
    positionFormName: '<',
    positionFieldsConfig: '<',
    businessUnit: '<',
  },
  template,
  controller: [
    '$scope',
    'FormFieldParamsService',
    'MulticontractService',
    'ContractsService',
    'GtUtils',
    '$timeout',
    class {
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      ContractsService: ContractsService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      MulticontractService: MulticontractService;
      businessUnit: any;
      calculateTotals: any;
      changeIsActive: any;
      fields: any;
      fieldsTemplateOptions: any;
      formValidChange: any;
      getPositionContractNumber: any;
      position: any;
      positionFieldsConfig: any;
      positionFormName: any;
      requiredFields: any;
      unwatchChangeIsActive: any;
      unwatchCurrency: any;
      unwatchForm: any;
      unwatchPositionAutoName: any;
      unwatchPositionFinalVolume: any;
      unwatchPositionVolume: any;
      unwatchPrice: any;
      unwatchVatOption: any;
      unwatchVatValue: any;
      constructor(
        $scope: ng.IScope,
        FormFieldParamsService: FormFieldParamsService,
        MulticontractService: MulticontractService,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        $timeout: ng.ITimeoutService,
      ) {
        this.$scope = $scope;
        this.FormFieldParamsService = FormFieldParamsService;
        this.MulticontractService = MulticontractService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.$timeout = $timeout;
        this.fields = [];
        this.position = {};
        this.fieldsTemplateOptions = [];
        this.requiredFields = [];
        this.unwatchForm = () => {};
      }

      needUpdateField(changes: any) {
        return (
          changes.positionFormName?.previousValue !== changes.positionFormName?.currentValue ||
          JSON.stringify(changes.positionFieldsConfig?.previousValue) !==
            JSON.stringify(changes.positionFieldsConfig?.currentValue)
        );
      }

      $onChanges(changes: any) {
        if (this.needUpdateField(changes)) {
          this.updateFields();
          this.unwatchForm();
          this.unwatchForm = this.$scope.$watch('$ctrl.form.$valid', (newValue: any) => {
            this.position.$formValid = newValue;
            this.formValidChange();
          });
        }
      }

      $onInit() {
        this.updateFields();
        this.unwatchPositionVolume = this.$scope.$watch('$ctrl.position.volume', () =>
          this.calculateTotals(),
        );
        this.unwatchPositionAutoName = this.$scope.$watch('$ctrl.position.auto_name', () => {
          if (this.position.auto_name) {
            this.getPositionContractNumber(this.position);
          }
        });
        this.unwatchPositionFinalVolume = this.$scope.$watch('$ctrl.position.final_volume', () =>
          this.calculateTotals(),
        );
        this.unwatchChangeIsActive = this.$scope.$watch('$ctrl.position.status', () => {
          this.changeIsActive(this.position);
          this.calculateTotals();
        });
        this.unwatchPrice = this.$scope.$watch('$ctrl.position.price', () => this.setPriceWoVat());
        this.unwatchVatOption = this.$scope.$watch('$ctrl.position.VAT_option', () =>
          this.setPriceWoVat(),
        );
        this.unwatchVatValue = this.$scope.$watch('$ctrl.position.VAT_value', () =>
          this.setPriceWoVat(),
        );
        this.unwatchCurrency = this.$scope.$watch('$ctrl.position.currency', () => {
          this.ContractsService.setCurrencyExchange(this.position);
        });
      }

      $onDestroy() {
        this.unwatchForm();
        this.unwatchPositionVolume();
        this.unwatchPositionFinalVolume();
        this.unwatchPositionAutoName();
        this.unwatchChangeIsActive();
        this.unwatchPrice();
        this.unwatchVatOption();
        this.unwatchVatValue();
        this.unwatchCurrency();
      }

      updateFields() {
        this.FormFieldParamsService.getContractPriceWidgetFields(
          this.positionFormName,
          this.businessUnit,
          this.positionFieldsConfig,
        ).then((priceWidgetFields: any) => {
          const widgetFieldsConfig = {};
          priceWidgetFields.forEach((field: any) => {
            widgetFieldsConfig[field.field_name] = {
              visible: field.visible,
              required: field.required,
            };
          });
          this.FormFieldParamsService.getFields(
            this.getPositionFormConfig(this.position, widgetFieldsConfig),
            this.businessUnit,
            this.positionFieldsConfig,
          )
            .then((fields: any) => {
              this.fields = fields.filter((item: any) => {
                if (
                  Array.isArray(item.fieldGroup[0].fieldGroup) &&
                  item.fieldGroup[0].fieldGroup.length
                ) {
                  return !item.fieldGroup[0].fieldGroup.every(
                    (field: any) =>
                      typeof field.hideExpression === 'function' && field.hideExpression(),
                  );
                }
                return true;
              });
              this.fieldsTemplateOptions = this.getFlatFields(fields);
              this.requiredFields = this.fieldsTemplateOptions.filter((f: any) => f.required);
            })
            .catch(this.GtUtils.errorClb);
        });
      }

      getPositionFormConfig(position: any, priceWidgetFields: any) {
        return this.MulticontractService.getContractPositionFormConfig(
          position,
          this.positionFormName,
          priceWidgetFields,
        );
      }

      getFlatFields(fields: any) {
        return this.FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map(
          (field: any) => ({
            ...field.templateOptions,
            key: field.key,
          }),
        );
      }

      setPriceWoVat() {
        if (this.position.price && this.position.VAT_option) {
          this.GtUtils.applyVat(this.position.price, 1, this.position.VAT_value, 'without')
            .then((response: any) => {
              this.position._priceWoVat = response.data.value;
              return this.GtUtils.applyVat(this.position.price, 1, this.position.VAT_value, 'vat');
            })
            .then((response: any) => {
              this.position._priceVatValue = response.data.value;
              return this.GtUtils.applyVat(
                this.position.price,
                this.position.volume,
                this.position.VAT_value,
                'with',
              );
            })
            .then((response: any) => {
              this.position._amountWithVat = response.data.value;
              this.position.calculated_amount_with_vat = this.position._amountWithVat;
              this.$timeout();
            })
            .catch((error: any) => {
              console.error('Error applying VAT calculations:', error);
            });
        }
      }
    },
  ],
};
