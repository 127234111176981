import type ng from 'angular';

import type { CoreUtils } from '~/app/core/core.utils';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '~/app/deals/multicontract/multicontract.service';

import template from './potentials-container.html?raw';

export const PotentialsContainer = {
  bindings: {
    filterLevel: '<?',
    groupBy: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$filter',
    'gtFilterService',
    'PotentialService',
    'ClientsService',
    'CropsService',
    'ContractsService',
    'MulticontractService',
    'GtUtils',
    'gettext',
    'CoreUtils',
    class {
      $filter: ng.IFilterService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ClientsService: any;
      ContractsService: ContractsService;
      CoreUtils: CoreUtils;
      CropsService: any;
      GtUtils: GtUtilsService;
      MulticontractService: MulticontractService;
      PotentialService: any;
      creatingMultiTicket: any;
      cropAreaTheadConfig: any;
      cropAreas: any;
      cropAreasCount: number;
      cropAreasTotals: any;
      filterLevel = 'potentials-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $filter: ng.IFilterService,
        gtFilterService: GtFilterService,
        PotentialService: any,
        ClientsService: any,
        CropsService: any,
        ContractsService: ContractsService,
        MulticontractService: MulticontractService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        CoreUtils: CoreUtils,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$filter = $filter;
        this.gtFilterService = gtFilterService;
        this.PotentialService = PotentialService;
        this.ClientsService = ClientsService;
        this.CropsService = CropsService;
        this.ContractsService = ContractsService;
        this.MulticontractService = MulticontractService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.CoreUtils = CoreUtils;

        this.cropAreaTheadConfig = {};
        this.cropAreas = [];
        this.cropAreasTotals = {};
        this.cropAreasCount = 0;
      }

      $onInit() {
        this.queryParams = { with_totals: true, ...this.initQueryParams };
        this.cropAreaTheadConfig = this.getCropAreaTheadConfig();
        this.queryParams = Object.assign(this.queryParams);
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = Object.assign(this.queryParams, data);
          this.updateCropAreaTable();
        });
        this.updateCropAreaTable();
      }

      createTicket(cropArea: any) {
        return this.ContractsService.createTicket(cropArea.id, cropArea.free_volume, 'potential');
      }

      updateCropAreaTable() {
        this.GtUtils.overlay('show');
        this.PotentialService.getCropAreaList(this.queryParams).then((data: any) => {
          this.cropAreas = data.results;
          this.cropAreasCount = data.count;
          this.cropAreas.unshift({
            crop_year: this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
            edit: true,
            area: 0,
            volume: 0,
          });
        });
        this.PotentialService.getCropAreaTotals(this.queryParams).then((data) => {
          this.cropAreasTotals = data;
        });
        this.GtUtils.overlay('hide');
      }

      updateFilter() {
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      clearFilter() {
        this.queryParams = { with_totals: true };
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      saveCropArea(cropArea: any) {
        return this.PotentialService.saveCropArea(cropArea).then(
          () => {
            this.GtUtils.notify(this.gettext('Saved'));
            this.updateCropAreaTable();
          },
          (e: any) => this.GtUtils.errorClb(e),
        );
      }

      cropAreaValid(cropArea: any) {
        if (cropArea.area === null || cropArea.volume === null) {
          return true;
        }
        const areaSegments = cropArea.area.toString().split('.');
        const areaInvalid =
          areaSegments[0].length > 10 || (areaSegments[1] && areaSegments[1].length > 6);

        const volumeSegments = cropArea.volume.toString().split('.');
        const volumeInvalid =
          volumeSegments[0].length > 10 || (volumeSegments[1] && volumeSegments[1].length > 6);

        return !(areaInvalid || volumeInvalid);
      }

      deleteCropArea(cropArea: any) {
        return this.PotentialService.deleteCropArea(cropArea.id).then(
          () => {
            this.GtUtils.notify(this.gettext('Deleted'));
            this.updateCropAreaTable();
          },
          (e: any) => this.GtUtils.errorClb(e),
        );
      }

      cloneCropArea(cropArea: any) {
        const newCropArea = { ...cropArea };
        newCropArea.edit = true;
        delete newCropArea.id;
        delete newCropArea.commentary;
        this.cropAreas[0] = newCropArea;
      }

      openClientModal() {
        return this.ClientsService.clientModal();
      }

      openQualitiesModal(cropArea: any) {
        return this.CropsService.qualitiesModal(
          cropArea.qualities,
          null,
          cropArea.cargo,
          null,
          null,
          null,
          null,
          cropArea.id,
        ).then(() => this.updateCropAreaTable());
      }

      startCreatingMultiTicket() {
        this.creatingMultiTicket = true;
        this.cropAreaTheadConfig = this.getCropAreaTheadConfig();
        this.cropAreas.map((potential: any) => {
          potential.$_inputedNumber = potential.free_volume;
        });
      }

      stopCreatingMultiTicket() {
        this.creatingMultiTicket = false;
        this.cropAreaTheadConfig = this.getCropAreaTheadConfig();
      }

      createMultiTicket() {
        const selectedPotentials = this.cropAreas.filter((potential: any) => {
          return potential.$_selected;
        });

        if (!selectedPotentials.length) {
          this.GtUtils.notify(this.gettext('Please select at least one object'), 'warning');
          return false;
        }

        this.GtUtils.overlay('show');
        const fieldsForClear: any = [
          'id',
          'conclusion_date',
          'number',
          'contract_number',
          'multicontract',
          'status',
          'approval_status',
          'stage',
        ];
        const newPositions: any = [];
        const firstPotential = selectedPotentials[0];
        const suppliersUnique = this.GtUtils.listUnique(
          (selectedPotentials || []).map((item: any) => item.supplier),
        );
        const supplier = suppliersUnique.length > 1 ? null : suppliersUnique[0];

        selectedPotentials.forEach((item: any) => {
          item.from_potential = item.id;
          item.contract_type = 'purchase';
          item.stage = 'ticket';
          item.conclusion_date = new Date();
          item.request_status = 'new';
          item.supplier = supplier;
          item.potential_suppliers = [supplier];
          item.responsible = this.$rootScope.user.id;

          newPositions.push(
            this.CoreUtils.cleanBeforeClone(
              { ...item, volume: item.$_inputedNumber },
              fieldsForClear,
            ),
          );
        });

        const multiTicket = {
          ...this.CoreUtils.cleanBeforeClone({ ...firstPotential }, fieldsForClear),
          contract_type: 'purchase',
          stage: 'ticket',
          use_type: 'commodity',
          positions: newPositions,
        };
        this.GtUtils.overlay('hide');

        this.MulticontractService.multicontractModal(multiTicket, { ...multiTicket }).then(() => {
          this.stopCreatingMultiTicket();
          this.updateCropAreaTable();
        });
      }

      getCropAreaTheadConfig() {
        const config: any = { tabs: [], columns: [] };

        config.columns = [
          {
            columnName: 'client',
            title: this.gettext('Client'),
            predicate: 'client',
          },
          {
            columnName: 'cargo',
            title: this.gettext('Commodity'),
            predicate: 'cargo',
          },
          {
            columnName: 'commentary',
            title: this.gettext('Additional info'),
          },
          {
            columnName: 'area',
            title: this.gettext('Area, hectare'),
            predicate: 'area',
          },
          {
            columnName: 'volume',
            title: this.gettext('Volume'),
            predicate: 'volume',
          },
          {
            columnName: 'crop_year',
            title: this.gettext('Crop year'),
            predicate: 'crop_year',
          },
          {
            columnName: 'price',
            title: this.gettext('Cargo Price'),
            predicate: 'price',
          },
          {
            columnName: 'storage',
            title: this.gettext('Storage'),
            predicate: 'storage',
          },
          {
            columnName: 'qualities',
            title: this.gettext('Qualities'),
          },
          {
            columnName: 'Total lost volume',
            title: this.gettext('Total lost volume'),
          },
          {
            columnName: 'Connected volume',
            title: this.gettext('Connected volume'),
          },
          {
            columnName: 'Free volume',
            title: this.gettext('Free volume'),
          },
          {
            columnName: 'edit',
            title: '',
          },
        ];

        if (this.creatingMultiTicket) {
          config.columns.unshift(
            {
              columnName: '$_selection',
            },
            {
              columnName: '$_numberInput',
              title: this.gettext('Input number'),
            },
          );
        }

        return config;
      }
    },
  ],
};
