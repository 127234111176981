import ng from 'angular';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').component('positionsPlansEditTable', {
    bindings: {
      plans: '=',
      contractType: '<?',
      filterLevel: '<?',
      count: '<?',
      total: '<?',
      addPlan: '&',
    },
    template: require('./positions-plans-edit-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['gettext'];

  function Controller(this: any, gettext: ng.gettext.gettextFunction) {
    const vm = this;
    vm.contractType = vm.contractType || 'sale';
    vm.theadConfig = getTheadConfig();
    vm.updateTotal = updateTotal;
    vm.screenTotalVolume = 0;

    vm.$onChanges = function (changes: any) {
      if (changes.total) {
        vm.screenTotalVolume = vm.total;
      }
    };

    function updateTotal() {
      vm.screenTotalVolume = vm.plans.reduce(
        (totalVolume: any, plan: any) => totalVolume + plan.volume,
        0,
      );
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('ticket'),
          columnName: 'number',
        },
        {
          title: gettext('price'),
          columnName: 'price',
        },
        {
          title: gettext('volume / free'),
          columnName: 'volume_left',
        },
        {
          title: gettext('connect'),
          columnName: 'connect',
        },
      ];

      return config;
    }
  }
})();
