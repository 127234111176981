import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './sample-control-list-container.html?raw';

export const SampleControlListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    tableName: '<?',
  },
  template,
  controller: [
    '$q',
    '$scope',
    'GtUtils',
    'gtFilterService',
    'SampleControlService',
    'gettext',
    class sampleControlContainer {
      $q: ng.IQService;
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      SampleControlService: any;
      dataCount: number;
      dataList: any;
      dataTotal: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      tableName: any;
      constructor(
        $q: ng.IQService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        SampleControlService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$q = $q;
        this.$scope = $scope;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.SampleControlService = SampleControlService;

        this.filterLevel = '';
        this.queryParams = {};

        this.dataList = [];
        this.dataCount = 0;
        this.dataTotal = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'sample-control-page-view';
        this.tableName = this.tableName || this.filterLevel;
        this.queryParams = { page_size: 20, ...this.initQueryParams };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: any) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.SampleControlService.getListData(this.queryParams).then((data: any) => {
          data.data.results.unshift({
            result_status: 'undefined',
            sample_type: 'type',
            ...this.initQueryParams,
          });
          this.dataList = data.data.results;
          this.dataCount = data.data.count;
          this.dataTotal = data.totals;
          this.GtUtils.overlay('hide');
        });
      }

      saveItem(item: any) {
        return this.SampleControlService.save(item).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      destroy(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.SampleControlService.delete({ id: item }).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }
    },
  ],
};
