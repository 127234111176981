import { LocaleType, type UnitModel, Univer, UniverInstanceType } from '@univerjs/core';
import { defaultTheme } from '@univerjs/design';
import { UniverDocsPlugin } from '@univerjs/docs';
import { UniverDocsUIPlugin } from '@univerjs/docs-ui';
import { UniverRenderEnginePlugin } from '@univerjs/engine-render';
import { FUniver } from '@univerjs/facade';
import { UniverSheetsPlugin } from '@univerjs/sheets';
import { UniverSheetsUIPlugin } from '@univerjs/sheets-ui';
import { UniverUIPlugin } from '@univerjs/ui';
import type React from 'react';

import type {
  CellData,
  MatrixPrimitive,
  SpreadsheetDataDef,
} from '~/shared/ui/components/spreadsheet/types';

export const initSpreadsheet = ({
  containerRef,
  spreadsheetRef,
  data = {},
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
  data: Partial<SpreadsheetDataDef>;
}) => {
  if (!containerRef.current) {
    throw Error('container not initialized');
  }
  const spreadsheet = new Univer({
    theme: defaultTheme,
    locale: LocaleType.EN_US,
    locales: {},
  });
  spreadsheetRef.current = FUniver.newAPI(spreadsheet);

  spreadsheet.registerPlugin(UniverRenderEnginePlugin);
  spreadsheet.registerPlugin(UniverUIPlugin, {
    container: containerRef.current,
    toolbar: false,
    footer: false,
    header: false,
    contextMenu: false,
  });

  spreadsheet.registerPlugin(UniverDocsPlugin, {
    hasScroll: false,
  });
  spreadsheet.registerPlugin(UniverDocsUIPlugin);

  spreadsheet.registerPlugin(UniverSheetsPlugin);
  spreadsheet.registerPlugin(UniverSheetsUIPlugin);

  spreadsheet.createUnit(UniverInstanceType.UNIVER_SHEET, data);
};

export const destroySpreadsheet = ({
  spreadsheetRef,
  workbookRef,
}: {
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
  workbookRef: React.MutableRefObject<UnitModel<object, number> | null>;
}) => {
  spreadsheetRef.current = null;
  workbookRef.current = null;
};

// TO DO: simplify
export const filterUniqueCellData = ({
  newData,
  data,
}: {
  newData: MatrixPrimitive<CellData>;
  data: MatrixPrimitive<CellData>;
}): MatrixPrimitive<CellData> => {
  const result: MatrixPrimitive<CellData> = {};

  for (const rowKey in newData) {
    const workBookRow = newData[rowKey];
    const mappedRow = data[rowKey];

    for (const cellKey in workBookRow) {
      const workBookCell = workBookRow[cellKey]?.v;
      const mappedCell = mappedRow?.[cellKey]?.v;

      if (workBookCell !== mappedCell) {
        if (!result[rowKey]) {
          result[rowKey] = {};
        }

        result[rowKey][cellKey] = {
          ...mappedRow[cellKey],
          v: workBookCell,
        };
      }
    }
  }

  return result;
};

export const getData = (workbookRef: React.MutableRefObject<UnitModel<object, number> | null>) => {
  if (!workbookRef.current) {
    throw new Error('Workbook is not initialized');
  }
};
