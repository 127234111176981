import ng from 'angular';

(function () {
  'use strict';
  ng.module('common.crops.legacy').config(configFunction);

  configFunction.$inject = ['$stateProvider', 'gettext'];

  function configFunction(
    $stateProvider: ng.ui.IStateProvider,
    gettext: ng.gettext.gettextFunction,
  ) {
    $stateProvider
      .state('gt.prices', {
        abstract: true,
        url: '/prices',
        template: '<ui-view></ui-view>',
      })
      .state('gt.prices.places', {
        url: '/logistics',
        controller: 'LogisticsPricesPageController',
        controllerAs: 'vm',
        template: require('./components/logistics-page/logistics-page.tpl.html?raw'),
        reloadOnSearch: false,
        data: {
          pageTitle: gettext('Logistics'),
          permissions: {
            only: 'view_prices',
          },
        },
      })
      .state('gt.prices.placesModal', {
        url: '/logistics/:roleName/:cropId/:roleId',
        params: {
          roleName: null,
          cropId: null,
          roleId: null,
        },
        controller: 'LogisticsPricesPageController',
        controllerAs: 'vm',
        template: require('./components/logistics-page/logistics-page.tpl.html?raw'),
        reloadOnSearch: false,
        data: {
          pageTitle: gettext('Logistics'),
          permissions: {
            only: 'view_prices',
          },
        },
      });
  }
})();
