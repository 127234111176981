import { BehaviorSubject } from 'rxjs';

import { injectable } from '~/shared/lib/di';

type FiltersViewMode = 'line' | 'side' | '';

@injectable()
export class PageFiltersStore {
  private readonly viewModeSubj = new BehaviorSubject<FiltersViewMode>('');

  public viewMode$ = this.viewModeSubj.asObservable();

  public setViewMode(viewMode: FiltersViewMode) {
    this.viewModeSubj.next(viewMode);
  }
}
