import ng from 'angular';

(function () {
  'use strict';

  ng.module('core.legacy').factory('LocationService', Service);

  Service.$inject = ['$resource', '$q'];

  function Service($resource: ng.resource.IResourceService, $q: ng.IQService) {
    const RegionResource = createResource(
      '/api/location/regions/:id/',
      '/api/location/regions/predictions/',
    );
    const CountryResource = createResource(
      '/api/location/countries/:id/',
      '/api/location/countries/predictions/',
    );
    const DistrictResource = createResource(
      '/api/location/districts/:id/',
      '/api/location/districts/predictions/',
    );
    const CityResource = createResource(
      '/api/location/cities/:id/',
      '/api/location/cities/predictions/',
    );
    const AddressResource: any = createResource(
      '/api/location/addresses/:id/',
      '/api/location/addresses/predictions/',
    );

    return {
      Region: RegionResource,
      District: DistrictResource,
      Country: CountryResource,
      City: CityResource,
      Address: AddressResource,
      getAddressListByIds: getAddressListByIds,
    };

    function createResource(baseUrl: any, predictionsUrl: any) {
      return $resource(
        baseUrl,
        { id: '@id' },
        {
          query: { method: 'GET', isArray: false },
          predictions: { method: 'GET', isArray: false, url: predictionsUrl },
        },
      );
    }

    function getAddressListByIds(ids: any) {
      if (!ids?.length) {
        return $q.when();
      }
      return fetchAddressPredictions(ids);
    }

    function fetchAddressPredictions(ids: any) {
      return AddressResource.predictions({ id: ids }).$promise.then(processPredictionResults(ids));
    }

    function processPredictionResults(ids: any) {
      return function (data: any) {
        const filteredResults = filterResultsByIds(data.results, ids);
        return joinTitles(filteredResults);
      };
    }

    function filterResultsByIds(results: any, ids: any) {
      return results.filter((address: any) => ids.includes(address.id));
    }

    function joinTitles(addresses: any) {
      return addresses.map((address: any) => address.title).join(' ');
    }
  }
})();
