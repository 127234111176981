import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { forwardRef } from 'react';

export const Dialog = forwardRef<
  HTMLDivElement,
  {
    open: boolean;
    onOpen: (open: boolean) => void;
    title?: string;
    children: React.ReactNode;
    overlayVisible?: boolean;
  }
>(({ open, onOpen, title, children, overlayVisible = true }, ref) => {
  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpen}>
      <DialogPrimitive.Portal>
        {overlayVisible && (
          <DialogPrimitive.Overlay className="fixed inset-0 z-30 bg-gray-100 bg-opacity-50" />
        )}
        <DialogPrimitive.Content
          ref={ref}
          className="fixed left-1/2 top-1/2 z-40 flex max-h-[85vh] w-[90vw] max-w-[320px] -translate-x-1/2 -translate-y-1/2 transform flex-col gap-6 rounded-sm bg-background-main-secondary p-6 text-center shadow-strong focus:outline-none"
        >
          <DialogPrimitive.Title className="text-base font-semibold text-text-main-primary">
            {title}
          </DialogPrimitive.Title>
          <DialogPrimitive.Description className="text-xs text-text-main-primary" asChild>
            <section>{children}</section>
          </DialogPrimitive.Description>
          <DialogPrimitive.Close asChild></DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
});

Dialog.displayName = 'Dialog';
