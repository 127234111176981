import React from 'react';

import type { RenderArgs } from '~/core/page-views/components/data-set-view';
import type { CellDataType, ColumnDef } from '~/shared/ui/components/data-grid';

import { CheckboxCellRead } from '../cell-table-adapters/checkbox-cell-read';
import { DateCellRead } from '../cell-table-adapters/date-cell-read';
import { EmailCellRead } from '../cell-table-adapters/email-cell-read';
import { EntityCellRead } from '../cell-table-adapters/entity-cell-read';
import { LabelCellRead } from '../cell-table-adapters/label-cell-read';
import { MultiselectCellRead } from '../cell-table-adapters/multiselect-cell-read';
import { NumberCellRead } from '../cell-table-adapters/number-cell-read';
import { PhoneCellRead } from '../cell-table-adapters/phone-cell-read';
import { SelectCellRead } from '../cell-table-adapters/select-cell-read';
import { TemplateCell } from '../cell-table-adapters/template-cell';
import { TextFieldCellRead } from '../cell-table-adapters/text-field-cell-read';
import { UrlCellRead } from '../cell-table-adapters/url-cell-read';

export const DynamicReadCell = <TData extends { id: string }>({
  value,
  valueChanged,
  template,
  cellTemplateContext,
  dataType,
  render,
}: {
  dataType: CellDataType;
  value: TData[keyof TData];
  template?: React.ElementType;
  valueChanged?: (value?: TData[keyof TData]) => void;
  cellTemplateContext?: ColumnDef<TData>['cellTemplateContext'];
  render?: (args: RenderArgs) => JSX.Element;
}) => {
  if (dataType === 'text') {
    return <TextFieldCellRead value={value} />;
  }

  if (dataType === 'number') {
    return <NumberCellRead value={value} />;
  }

  if (dataType === 'string') {
    return <TextFieldCellRead value={value} />;
  }

  if (dataType === 'url') {
    return <UrlCellRead value={value} />;
  }

  if (dataType === 'date') {
    return <DateCellRead value={value} />;
  }

  if (dataType === 'select') {
    return <SelectCellRead value={value} cellTemplateContext={cellTemplateContext} />;
  }

  if (dataType === 'entity') {
    return <EntityCellRead value={value} />;
  }

  if (dataType === 'email') {
    return <EmailCellRead value={value} />;
  }

  if (dataType === 'phone') {
    return <PhoneCellRead value={value} />;
  }

  if (dataType === 'checkbox') {
    return <CheckboxCellRead value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'label') {
    return <LabelCellRead value={value} />;
  }

  if (dataType === 'multiselect') {
    return <MultiselectCellRead value={value} cellTemplateContext={cellTemplateContext} />;
  }

  if (dataType === 'template') {
    if (render) {
      return render({ value });
    }

    return (
      <TemplateCell
        value={value}
        valueChanged={valueChanged}
        cellTemplateContext={cellTemplateContext}
        Template={template}
      />
    );
  }
};
