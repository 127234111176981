import { mainApp } from '@gt/legacy';
import ng from 'angular';
import React from 'react';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface IntrinsicElements {
      'ui-view': React.HTMLAttributes<HTMLElement>;
    }
  }
}

export const GTLegacyComponent: React.FC = () => {
  const ajsRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    ng.element(() => {
      if (ajsRef.current) {
        // eslint-disable-next-line import/no-internal-modules
        void import('@gt/legacy/src/styles.less');

        ng.bootstrap(ajsRef.current, [mainApp.name], { strictDi: true });
      }
    });
  }, []);

  return (
    <div id="ajs-root" ref={ajsRef}>
      <ui-view>Loading...</ui-view>
    </div>
  );
};
