import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './bank-accounts-row.html?raw';

export const BankAccountsRow = {
  bindings: {
    bankAccount: '=',
    onUpdate: '&?',
    showInitialBalance: '<?',
  },
  template,
  controller: [
    'gettext',
    'FinancesService',
    'GtUtils',
    class {
      bankAccount: any;
      currencyIcons: any;
      edit: any;
      errors: any;
      financesService: any;
      gettext: ng.gettext.gettextFunction;
      GtUtils: GtUtilsService;
      onUpdate: any;
      saveDisabled: any;
      showInitialBalance: any;
      tab: any;
      constructor(
        gettext: ng.gettext.gettextFunction,
        FinancesService: FinancesService,
        GtUtils: GtUtilsService,
      ) {
        this.gettext = gettext;
        this.financesService = FinancesService;
        this.GtUtils = GtUtils;

        this.edit = false;
        this.tab = 'info';
        this.bankAccount = {};
        this.showInitialBalance = false;

        this.currencyIcons = {
          EUR: 'fa-euro-sign',
          USD: 'fa-dollar-sign',
          UAH: 'fa-hryvnia-sign',
          RUB: 'fa-ruble-sign',
          GBP: 'fa-sterling-sign',
          KZT: 'fa-tenge-sign',
        };
      }

      openBankAccountActivityModal() {
        const params = {
          payment_bank_account: this.bankAccount.id,
        };
        return this.financesService.bankAccountActivityModal(params, {
          quickAdd: false,
        });
      }

      openBankAccountRestsModal() {
        return this.financesService.bankAccountRestsModal(this.bankAccount);
      }

      destroy() {
        this.edit = false;
        return this.financesService.BankAccount.delete(
          { id: this.bankAccount.id },
          () => {
            this.onUpdate().then(() => {
              this.bankAccount = {};
            });
          },
          this._error,
        ).$promise;
      }

      save() {
        this.saveDisabled = true;
        let hasErrors = false;

        if (this.bankAccount.initial_cash === undefined) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('Initial cash is required'), 'error');
        }

        if (!this.bankAccount.currency) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('Currency is required'), 'error');
        }

        if (hasErrors) {
          return;
        }
        return this.financesService.BankAccount.update(
          this.bankAccount,
          () => {
            this.onUpdate();
            this.errors = {};
            this.saveDisabled = false;
            this.edit = false;
            this.GtUtils.notify(this.gettext('Bank account saved'));
          },
          this._error,
        );
      }

      _error = (data: any) => {
        this.saveDisabled = false;
        this.errors = data.data;
      };
    },
  ],
};
