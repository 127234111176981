import ng from 'angular';

import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').directive('contractsContractItemProcessTab', directive);

  function directive() {
    return {
      template: require('./process-tab.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        contract: '=',
        showFullProcess: '<?',
      },
    };
  }

  Controller.$inject = ['$uibModal', 'ClientsService', 'ContractsService'];

  function Controller(
    this: any,
    $uibModal: ng.ui.bootstrap.IModalService,
    ClientsService: any,
    ContractsService: ContractsService,
  ) {
    const vm = this;

    vm.getStageBySlug = getStageBySlug;
    vm.editStage = editStage;
    vm.getStatusClassLabel = getStatusClassLabel;
    vm.openRoleModal = openRoleModal;
    vm.openPassportModal = openPassportModal;
    vm.getStatusClassIcon = getStatusClassIcon;

    vm.stageTemplateMapper = {
      signature: require('../../stage-edit/stage-signature-edit.tpl.html?raw'),
      'contract-executed': require('../../stage-edit/stage-contract-executed-edit.tpl.html?raw'),
      'supplier-approval': require('../../stage-edit/stage-supplier-approval-edit.tpl.html?raw'),
    };

    ////////////////

    function updateData() {
      return ContractsService.refreshContract(vm.contract, true).then(function (data: any) {
        vm.contract = data;
      });
    }

    function getStageBySlug(slug: any) {
      return ContractsService.getStageBySlug(vm.contract.stages, slug);
    }

    function editStage(stageSlug: any) {
      const stage = getStageBySlug(stageSlug);
      const template = vm.stageTemplateMapper[stageSlug];

      $uibModal
        .open({
          template,
          controller: 'StageEditController as vm',
          windowClass: 'modal-template modal-template-half-width',
          resolve: {
            stage: () => {
              return { ...stage };
            },
          },
        })
        .result.then(function () {
          updateData();
        });
    }

    function getStatusClassLabel(slug: any) {
      return ContractsService.getStatusClassLabel(vm.contract.stages, slug);
    }

    function openRoleModal(id: any, model: any) {
      if (!id) {
        return;
      }
      return ClientsService.roleModal({ id: id, model_name: model }).then(updateData);
    }

    function openPassportModal(passport: any) {
      return ContractsService.passportModal(passport);
    }

    function getStatusClassIcon(slug: any) {
      return ContractsService.getStatusClassIcon(vm.contract.stages, slug);
    }
  }
})();
