import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './multicontract-position-table.html?raw';
import type { MulticontractService } from '../../multicontract.service';

export const MulticontractPositionTable = {
  bindings: {
    positions: '<',
    contract: '<',
    updatePupdatePositionsositions: '&',
    updateTotals: '&',
    openPositionModal: '&',
    formValidChange: '&',
    withDerivative: '<',
    queryParams: '<',
    formNamePrefix: '<',
    businessUnit: '<',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gettext',
    'MulticontractService',
    'FormFieldParamsService',
    'ContractsService',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      MulticontractService: MulticontractService;
      businessUnit: any;
      contract: any;
      formNamePrefix: any;
      gettext: ng.gettext.gettextFunction;
      positionFormName: any;
      positions: any;
      positionsFieldsConfig: any;
      queryParams: QueryParams = {};
      unwatch: any;
      updateFieldsTrigger: any;
      updatePositions: any;
      updateTotals: any;
      updating: any;
      withDerivative: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        MulticontractService: MulticontractService,
        FormFieldParamsService: FormFieldParamsService,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.MulticontractService = MulticontractService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.positions = [];
        this.updateFieldsTrigger = false;
        this.contract = {};
        this.unwatch = () => {};
        this.updating = false;
      }

      $onChanges(changes: any) {
        if (
          (changes.businessUnit &&
            !!changes.businessUnit.currentValue &&
            changes.businessUnit.currentValue !== changes.businessUnit.previousValue) ||
          changes.positions
        ) {
          this.updateFields();
        }
        if (
          changes.formNamePrefix &&
          changes.formNamePrefix.currentValue !== changes.formNamePrefix.previousValue &&
          changes.formNamePrefix.currentValue !== 'undefined-'
        ) {
          this.positionFormName = this.formNamePrefix + 'position-edit-form';
        }
      }

      $onInit() {
        this.positions = this.positions || [];
        this.contract = this.contract || {};
        this.queryParams = this.queryParams || {};
        this.withDerivative = this.withDerivative || false;
        this.addPosition(true);
        this.updating = true;
        this.updatePositionsFieldsConfig().then(() => {
          this.updateFields();
          this.updating = false;
        });
      }

      updatePositionsFieldsConfig() {
        return this.FormFieldParamsService.getParams(this.positionFormName, this.businessUnit).then(
          (fields: any) => (this.positionsFieldsConfig = fields),
        );
      }

      updateFields() {
        this.updateFieldsTrigger = !this.updateFieldsTrigger;
      }

      _error(error: any) {
        this.GtUtils.errorClb(error);
      }

      getPermissionName(params: any, permType: any) {
        return this.MulticontractService.getPermissionName(params, permType);
      }

      getPositionContractNumber(position: any) {
        return this.ContractsService.getContractNumber(position, this.contract).then(
          (data: any) => {
            position.contract_number = data.contract_number;
          },
          (error: any) => this._error(error),
        );
      }

      calculatePositionsTotals() {
        const notDraftPositions = this.positions.filter(
          (position: any) => position.status !== 'draft',
        );
        const data = {
          data: {
            positions_volume_sum: notDraftPositions.reduce(
              (totalVolume: any, position: any) => totalVolume + position.volume,
              0,
            ),
            positions_final_volume_sum: notDraftPositions.reduce(
              (totalVolume: any, position: any) => totalVolume + position.final_volume,
              0,
            ),
          },
          final_volume: {
            positions_final_volume_sum: notDraftPositions.reduce(
              (totalVolume: any, position: any) => totalVolume + position.final_volume,
              0,
            ),
          },
        };
        this.updateTotals(data);
      }

      addPosition(init: any) {
        if (init && this.positions.length) {
          return false;
        }

        this.positions.unshift({
          ...this.contract,
          status: 'new',
          use_type: this.contract.use_type,
          deal_type: this.contract.use_type,
          contract_type: this.contract.contract_type || 'purchase',
          conclusion_date: this.contract.conclusion_date,
          stage: this.contract.stage,
          VAT_value: this.$rootScope.user.settings.VAT_VALUE,
          derivatives: [],
          positions_charge_use_type_list: this.contract.positions_charge_use_type_list,
          sequence_number: this.positions.length + 1,
        });

        this.updateFields();
      }

      isDerivativeSelected(position: any) {
        return position.derivatives?.some((derivative: any) => derivative.derivative);
      }

      openFieldsConfigModal() {
        // @ts-ignore
        this.FormFieldParamsService.fieldsConfigModal(this.getPositionFormConfig({})).then(() =>
          this.updateFields(),
        );
      }

      getPositionFormConfig() {
        return this.MulticontractService.getContractPositionFormConfig(
          this.queryParams,
          this.positionFormName,
        );
      }

      clone(position: any) {
        position.derivatives = position.derivatives || [];
        const excludeFields: any = ['id', 'index', 'contract_number'];
        const cleanObj = (object: any) =>
          Object.keys(object)
            .filter((key) => object[key] && !excludeFields.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: object[key] }), {});

        this.positions.unshift({
          ...cleanObj(position),
          derivatives: position.derivatives.map(cleanObj),
          sequence_number: this.positions.length + 1,
        });
        this.updatePositions({ positions: this.positions });
      }

      destroy(position: any) {
        if (!position.id) {
          return this.positions.splice(this.positions.indexOf(position), 1);
        }
        return this.MulticontractService.deletePosition(position).then((data: any) => {
          if (data == 'delete') {
            this.positions.splice(this.positions.indexOf(position), 1);
          }
        });
      }

      setEndOfExecution(position: any, $event: any) {
        const date = $event?.date;
        if (position.end_of_execution || !date) {
          return false;
        }
        position.end_of_execution = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }

      changeIsActive(position: any) {
        if (
          position.status === 'draft' ||
          this.$rootScope.user.settings.ALLOW_SET_MANY_FEW_CONTRACT_PRICE
        ) {
          return;
        }
        const prevStatus = position.status;
        this.positions
          .filter((item: any) => item.status != 'draft')
          .map((item: any) => (item.status = 'draft'));
        position.status = prevStatus;
      }
    },
  ],
};
