import React from 'react';

import { container } from '~/shared/lib/di';

import type { GlobalDialogConfig } from '../lib/types';
import { GlobalDialogStore } from '../services/global-dialog.store';

export const useDialog = (config?: GlobalDialogConfig) => {
  const globalDialogStore = React.useMemo(() => container.resolve(GlobalDialogStore), []);

  React.useEffect(() => {
    if (config) {
      globalDialogStore.dialogConfigRegistered(config);
    }
  }, [globalDialogStore, config]);

  return { globalDialogStore };
};
