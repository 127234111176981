import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';

const buttonVariants = cva(
  'flex h-6 w-max cursor-pointer items-center justify-center px-2 text-xs font-medium outline-none transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-20',
  {
    variants: {
      intent: {
        default:
          'border border-stroke-section-light bg-background-section-extraLight text-text-section-secondary hover:bg-background-section-light focus:ring disabled:hover:bg-transparent',
        empty:
          'border border-stroke-main-medium text-text-main-secondary hover:bg-transparent-light focus:border-stroke-additional-info focus:bg-transparent-primary focus:ring focus:ring-stroke-additional-infoLight active:bg-transparent-medium',
      },
      rounded: {
        full: 'rounded-sm',
        left: 'rounded-l-sm border-r-0',
        right: 'rounded-r-sm',
      },
    },
    defaultVariants: {
      intent: 'empty',
      rounded: 'full',
    },
  },
);

export type FilterButtonVariants = VariantProps<typeof buttonVariants>;

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  {
    children?: React.ReactNode;
  } & FilterButtonVariants &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>
>(({ intent, rounded, children, ...props }, ref) => {
  return (
    <button className={cn(buttonVariants({ intent, rounded }))} ref={ref} {...props}>
      {children}
    </button>
  );
});

FilterButton.displayName = 'FilterButton';
