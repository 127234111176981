import ng from 'angular';

import { SettingsParsingContainer } from './settings-parsing-container/settings-parsing-container.component';
import { SettingsParsingTableView } from './settings-parsing-table-view/settings-parsing-table-view.component';
import { SettingsParsingService } from './settings-parsing.service';

export const settingsParsing = ng
  .module('settingsParsing', [])
  .component('settingsParsingTableView', SettingsParsingTableView)
  .component('settingsParsingContainer', SettingsParsingContainer)
  .service('SettingsParsingService', SettingsParsingService).name;
