import React from 'react';

import { cn } from '~/shared/lib/utils';
import { SearchCommandList } from '~/shared/ui/components/search-command-list';
import { CheckIcon, UpdateFileIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';
import { CommandItem } from '~/shared/ui/kit/command';
import {
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { Preset } from '../../types';

export const UpdatePreset: React.FC<{
  preset?: Preset;
  presetOptions: Preset[];
}> = ({ preset, presetOptions }) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger className="trasition flex cursor-pointer items-center gap-2 px-2 py-1 duration-300 ease-in-out hover:bg-transparent-light">
        <UpdateFileIcon size={14} className="text-text-main-secondary" />
        <p className="text-xs text-text-main-primary">Update Preset</p>
      </DropdownMenuSubTrigger>
      <DropdownMenuSubContent className="p-0" sideOffset={2}>
        <SearchCommandList
          options={presetOptions.map((item) => (
            <CommandItem
              key={item.id}
              className={cn(
                'flex h-8 cursor-pointer items-center justify-between transition duration-300 ease-in-out hover:bg-transparent-light',
                preset?.id === item.id && 'bg-transparent-light',
              )}
            >
              <p className="text-xs text-text-main-primary">{item.title}</p>
              {preset?.id === item.id && (
                <CheckIcon size={14} className="text-text-main-secondary" />
              )}
            </CommandItem>
          ))}
        />
        <div className="flex justify-end border-t border-transparent-light p-2">
          <Button variant="primary">Update</Button>
        </div>
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  );
};
