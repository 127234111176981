import React from 'react';

import { Toolbar, ToolbarSeparator } from '~/shared/ui/kit/toolbar';

import { ActionBarList } from './action-bar-list';
import type { ActionBarConfig } from './types';

export const ActionBar: React.FC<{
  title: React.ReactNode;
  config: ActionBarConfig[];
}> = ({ title, config }) => {
  return (
    <Toolbar className="fixed bottom-4 left-1/2 flex w-max -translate-x-1/2 transform items-center gap-2">
      {title}
      <ToolbarSeparator className="h-5" />
      <ActionBarList config={config} />
    </Toolbar>
  );
};
