import { notifyError, notifySuccess } from '~/shared/lib/notify';

export const formatCamelCaseToTitle = (input: string) => {
  return input
    .replace(/_/g, ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase()
    .replace(/^./, (str) => str.toUpperCase());
};

export const truncateString = (str: string, symbolCount = 35) => {
  return str.length > symbolCount ? `${str.slice(0, symbolCount)}...` : str;
};

export const handleCopyText = (text: string) => () => {
  navigator.clipboard.writeText(text).then(
    () => {
      notifySuccess('Copied to clipboard');
    },
    () => {
      notifyError('Could not copy text');
    },
  );
};

export const snakeCaseToCamelCase = (snakeStr: string) => {
  return snakeStr.replace(/_([a-z])/g, (_, letter: string) => letter.toUpperCase());
};

export const camelToSnakeCase = (input: string) => {
  return input.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const replaceUnderscoresWithSpaces = ({
  input,
  capitalize = false,
}: {
  input: string;
  capitalize: boolean;
}) => {
  let result = input.replace(/_/g, ' ');

  if (capitalize) {
    result = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return result;
};
