import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import template from './stock-reserve-units-page.html?raw';
export const StockReserveUnitsPage = {
  template,
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  controller: [
    'gettext',
    'PageService',
    'LogisticsService',
    class {
      LogisticsService: any;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      constructor(
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        LogisticsService: any,
      ) {
        this.gettext = gettext;
        this.PageService = PageService;
        this.LogisticsService = LogisticsService;
        this.filterLevel = 'stock-reserve-units-page';
      }
      $onInit() {
        this.filterLevel = this.filterLevel || 'stock-reserve-units-page';
        this.PageService.setConfig(this.getPageConfig());
      }
      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
        };

        // @ts-ignore
        config.filters = {
          groupBy: {},
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          dateSelects: [
            {
              argument: 'start_date',
              placeholder: this.gettext('Start Date'),
            },
            {
              argument: 'end_date',
              placeholder: this.gettext('End Date'),
            },
          ],
          selects: [],
          nestedMultiSelects: [],
          multiSelects: [
            {
              argument: 'business_unit',
              placeholder: this.gettext('Business Unit'),
              resource: 'core.BusinessUnit',
              related_fk: 'business_unit_id',
              related_model: 'warehouses.Reservation',
            },
            {
              argument: 'client',
              placeholder: this.gettext('Client'),
              resource: 'clients.Client',
              related_fk: 'client',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'commodity',
              placeholder: this.gettext('Commodity'),
              resource: 'crops.Crop',
              related_fk: 'commodity',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'passport',
              placeholder: this.gettext('Passport'),
              resource: 'passports.Passport',
              related_fk: 'passport',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'contract_position',
              placeholder: this.gettext('Sale contract'),
              resource: 'contracts.SaleContract',
              related_fk: 'contract_position_id',
              related_model: 'warehouses.StockReserveUnit',
            },
            {
              argument: 'responsible',
              placeholder: this.gettext('Responsible'),
              resource: 'auth.User',
              related_fk: 'responsible_id',
              related_model: 'warehouses.StockReserveUnit',
            },
          ],
        };
        return config;
      }
    },
  ],
};
