export class CertificateDatesService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getCertificateDatesReport(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/logistics/logistics/certificate_dates_report/',
      params: params,
    }).then((res: any) => res.data);
  }
}
CertificateDatesService.$inject = ['$http'];
