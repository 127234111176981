import React from 'react';

import { type ValibotValidator, useForm, v, valibotValidator } from '~/shared/lib/forms';
import { IconSelector } from '~/shared/ui/components/icon-selector';
import { type IconName } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';
import { Input } from '~/shared/ui/kit/input';

import type { Preset } from '../../types';

export const CreatePresetForm: React.FC<{ preset: Preset; onSave: (preset: Preset) => void }> = ({
  preset,
  onSave,
}) => {
  const form = useForm<{ title: string; icon: IconName }, ValibotValidator>({
    onSubmit: ({ value }) => onSave({ ...preset, ...value }),
    validatorAdapter: valibotValidator(),
  });

  return (
    <form
      className="flex flex-col"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit().catch(console.error);
      }}
    >
      <div className="mb-2 flex items-center gap-2 px-2">
        <form.Field name="icon">
          {(field) => <IconSelector selectedIcon="HeartIcon" onSelect={field.handleChange} />}
        </form.Field>
        <form.Field
          name="title"
          validators={{
            onChange: v.pipe(v.string(), v.minLength(1, 'Title is required')),
          }}
        >
          {(field) => (
            <Input
              placeholder="Type Here"
              intent={field.state.meta.errors.length ? 'danger' : 'default'}
              onChange={(e) => field.handleChange(e.target.value)}
              helperText={
                !!field.state.meta.errors.length && (
                  <p className="text-text-additional-danger">
                    {field.state.meta.errors.join(', ')}
                  </p>
                )
              }
              required
            />
          )}
        </form.Field>
      </div>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit]) => (
          <div className="border-t border-stroke-main-medium p-2">
            <Button variant="primary" type="submit" disabled={!canSubmit} fullWidth>
              Create
            </Button>
          </div>
        )}
      </form.Subscribe>
    </form>
  );
};
