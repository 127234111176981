import ng from 'angular';

import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('purchasePlanVolumeWidget', {
    bindings: {
      purchasePlan: '<?',
      users: '=',
    },
    template: require('./purchase-plan-volume-widget.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['ContractsService', 'AccountsService'];

  function Controller(this: any, ContractsService: ContractsService, AccountsService: any) {
    const vm = this;
    vm.allUsers = [];
    vm.users = [];
    vm.selectAll = selectAll;
    vm.allAreSelected = allAreSelected;
    vm.disselectAll = disselectAll;

    ////////////////

    vm.$onInit = function () {
      getUsers();
    };

    function getUsers() {
      const params = {
        purchase_plan_id: vm.purchasePlan.id,
      };
      AccountsService.User.query({ page_size: 200 }, function (data: any) {
        vm.allUsers = data.results;
        ContractsService.PurchasePlanVolume.volumeForPurchasePlan(params, function (deals: any) {
          ContractsService.PurchasePlanVolume.purchasePlanVolumeTotal(
            params,
            function (purchasePlans: any) {
              vm.users = [];
              vm.allUsers.forEach(function (user: any) {
                vm.users.push({
                  first_name: user.first_name || user.username,
                  user_id: user.id,
                });
              });
              // initial volume by statuses
              deals.forEach(function (volume: any) {
                vm.users.forEach(function (user: any) {
                  if (volume.user_id == user.user_id) {
                    user.volume_new = volume.volume_new;
                    user.volume_approved = volume.volume_approved;
                    user.volume_shipment = volume.volume_shipment;
                    user.volume_rejected = volume.volume_rejected;
                    user.volume_executed = volume.volume_executed;
                    user.volume_total = volume.volume_total;
                  }
                });
              });
              // initial volume in percents
              vm.purchasePlan.purchase_plan_volume.forEach(function (volumeInfo: any) {
                vm.users.forEach(function (user: any) {
                  if (volumeInfo.user == user.user_id) {
                    user.volume_in_percent = volumeInfo.volume_in_percent;
                  }
                });
              });
              // append purchase_volume
              purchasePlans.forEach(function (purchasePlan: any) {
                vm.users.forEach(function (user: any) {
                  if (user.user_id == purchasePlan.user_id) {
                    user.is_involved = true;
                    user.purchase_volume = purchasePlan.purchase_volume;
                  }
                });
              });
              // true first, false and null last
              vm.users.sort(function (former: any, letter: any) {
                if (former.is_involved === letter.is_involved) {
                  return 0;
                }
                if (former.is_involved) {
                  return -1;
                }
                return 1;
              });
            },
          );
        });
      });
    }

    function selectAll() {
      ng.forEach(vm.users, function (user: any) {
        user.is_involved = true;
      });
    }

    function disselectAll() {
      ng.forEach(vm.users, function (user: any) {
        user.is_involved = false;
      });
    }

    function allAreSelected() {
      return vm.users.every(function (user: any) {
        return user.is_involved;
      });
    }
  }
})();
