import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { Row } from '~/shared/ui/components/data-grid';
import type { CellRenderDef, ColumnDef } from '~/shared/ui/components/data-grid';
import { getStickyStyles } from '~/shared/ui/components/data-grid/helpers';

export const RowGroup = <TRecord extends { id: string }>({
  row,
  CellRender,
  editing,
  onEdit,
  onSelect,
  selectedRowIds,
}: {
  row: Row<TRecord>;
  CellRender: React.FC<CellRenderDef<TRecord>>;
  editing: boolean;
  onEdit: (
    newValue: TRecord[keyof TRecord] | undefined,
    accessorKey: string,
    rowId: string,
  ) => void;
  onSelect?: (ids: string[]) => void;
  selectedRowIds?: string[];
}) => {
  // too much type cast. need to override once

  return (
    <div
      data-index={row.index}
      className="group z-[1] flex w-full"
      // ref={node => rowVirtualizer.measureElement(node)} - measure dynamic row height
      style={{
        // position: 'absolute',
        // transform: `translateY(${virtualRow.start}px)`,
        // height need to fix
        height: '32px',
      }}
    >
      {row.getVisibleCells().map((cell) => {
        // wtf? maybe separate selectable column
        const valueChangedHandler = (value?: TRecord[keyof TRecord]) => {
          if (
            (cell.column.columnDef as ColumnDef<TRecord>).accessorKey === '_selectRowCheckbox' &&
            Array.isArray(value)
          ) {
            onSelect?.(value);
          } else {
            onEdit(
              value,
              String((cell.column.columnDef as ColumnDef<TRecord>).accessorKey),
              row.original.id,
            );
          }
        };

        return (
          <div
            key={cell.id}
            className={cn(
              'relative flex h-full w-full items-center overflow-hidden border-b border-r border-stroke-main-light bg-background-main-primary transition duration-300 ease-in-out group-hover:bg-transparent-light',
              selectedRowIds?.includes(row.original.id) && 'bg-background-section-extraLight',
              (cell.column.columnDef as ColumnDef<TRecord>).accessorKey === '_selectRowCheckbox' &&
                'border-r-0 pl-1',
            )}
            style={{
              width: cell.column.getSize(),
              ...getStickyStyles(cell.column),
            }}
          >
            <CellRender
              value={cell.getValue() as TRecord[keyof TRecord]}
              id={row.original.id}
              dataType={(cell.column.columnDef as ColumnDef<TRecord>).dataType}
              typeExtra=""
              cellTemplateContext={
                (cell.column.columnDef as ColumnDef<TRecord>).cellTemplateContext
              }
              template={(cell.column.columnDef as ColumnDef<TRecord>).cellTemplate}
              dataTypeVariant={editing ? 'write' : 'read'}
              valueChanged={valueChangedHandler}
              render={(cell.column.columnDef as ColumnDef<TRecord>).render}
            />
          </div>
        );
      })}
    </div>
  );
};
