import ng from 'angular';

import { billOfLadingInvoicepositionListContainer } from './bill-of-lading-invoiceposition-list-container/bill-of-lading-invoiceposition-list-container.component';
import { BillOfLadingInvoicepositionListTableView } from './bill-of-lading-invoiceposition-list-table-view/bill-of-lading-invoiceposition-list-table-view.component';
import { BillOfLadingInvoicepositionService } from './bill-of-lading-invoiceposition.service';

export const billOfLadingInvoiceposition = ng
  .module('execution.vessels.voyage.billOfLadingInvoiceposition', [])
  .component('billOfLadingInvoicepositionListContainer', billOfLadingInvoicepositionListContainer)
  .component('billOfLadingInvoicepositionListTableView', BillOfLadingInvoicepositionListTableView)
  .service('BillOfLadingInvoicepositionService', BillOfLadingInvoicepositionService).name;
