import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';

import template from './quality-rule-templates-container.html?raw';

export const QualityRuleTemplatesContainer = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'QualityRuleTemplatesService',
    'CropsService',
    class {
      $scope: ng.IScope;
      CropsService: any;
      QualityRuleTemplatesService: any;
      filterLevel = 'quality-rule-templates-container';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      templates: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        QualityRuleTemplatesService: any,
        CropsService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.QualityRuleTemplatesService = QualityRuleTemplatesService;
        this.CropsService = CropsService;
      }

      $onInit() {
        this.templates = [];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateTemplatesList();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      openTemplateModal(template: any) {
        this.QualityRuleTemplatesService.openTemplateModal(template).then(() =>
          this.updateTemplatesList(),
        );
      }

      updateTemplatesList() {
        this.CropsService.QualityRuleTemplate.query(this.queryParams).$promise.then(
          (data: any) => (this.templates = data.results),
        );
      }
    },
  ],
};
