import ng from 'angular';

import { VoyageTariffListContainer } from './voyage-tariff-list-container/voyage-tariff-list-container.component';
import { VoyageTariffListTableView } from './voyage-tariff-list-table-view/voyage-tariff-list-table-view.component';
import { VoyageTariffService } from './voyage-tariff.service';

export const voyageTariff = ng
  .module('execution.transport.voyageTariff', [])
  .service('VoyageTariffService', VoyageTariffService)
  .component('voyageTariffListContainer', VoyageTariffListContainer)
  .component('voyageTariffListTableView', VoyageTariffListTableView).name;
