export const themeSections = [
  'start',
  'crm',
  'company',
  'deals',
  'execution',
  'finance',
  'reports',
];

export const getSectionName = (arg: string) => {
  return themeSections.find((section) => arg.includes(section)) ?? 'start';
};

export const theme = ['light', 'dark', 'system'];
