import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { LoyaltyProgramExecutionListContainer } from './loyalty-program-execution-list-container/loyalty-program-execution-list-container.component';
import { LoyaltyProgramExecutionListTableView } from './loyalty-program-execution-list-table-view/loyalty-program-execution-list-table-view.component';
import { LoyaltyProgramsExecutionPageView } from './loyalty-programs-execution-page-view/loyalty-programs-execution-page-view.component';

const loyaltyProgramsExecutionConfig = [
  '$stateProvider',
  ($stateProvider: ng.ui.IStateProvider) => {
    $stateProvider.state('reports.budgets.loyaltyProgramsExecution', {
      url: '/loyalty-programs-execution',
      component: 'loyaltyProgramsExecutionPageView',
      resolve: {
        queryParams: [
          'PageService',
          function (PageService: PageService) {
            return PageService.syncUrlFilter('loyalty-programs-execution-page-view');
          },
        ],
      },
      data: {
        permissions: {
          only: 'view_loyalty_programs_execution',
        },
      },
    });
  },
];

export const loyaltyProgramsExecution = ng
  .module('contracts.loyaltyProgramsExecution', [])
  .component('loyaltyProgramsExecutionPageView', LoyaltyProgramsExecutionPageView)
  .component('loyaltyProgramExecutionListContainer', LoyaltyProgramExecutionListContainer)
  .component('loyaltyProgramExecutionListTableView', LoyaltyProgramExecutionListTableView)
  .config(loyaltyProgramsExecutionConfig).name;
