import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './terminal-transshipment-tariff-list-table-view.html?raw';

export const TerminalTransshipmentTariffListTableView = {
  bindings: {
    filterLevel: '<?',
    tariffs: '<?',
    count: '<?',
    buQueryParams: '<?',
    updateTariffs: '&?',
    saveTariff: '&?',
    deleteTariff: '&?',
    applyFilters: '&?',
    getNewTariff: '&?',
  },
  template,
  controller: [
    'GtUtils',
    'gettext',
    class {
      GtUtils: GtUtilsService;
      applyFilters: any;
      buQueryParams: QueryParams = {};
      count: number;
      deleteTariff: any;
      filterLevel = 'terminal-transshipment-tariff-list-table-view';
      getNewTariff: any;
      gettext: ng.gettext.gettextFunction;
      saveTariff: any;
      tableData: any;
      tableOptions: any;
      tariffs: any;
      constructor(GtUtils: GtUtilsService, gettext: ng.gettext.gettextFunction) {
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.tariffs = [];
        this.count = 0;
        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
        this.buQueryParams = {};
      }

      $onChanges(changes: any) {
        if (changes.tariffs || changes.count) {
          this.tableData = { rows: this.tariffs, count: this.count };
        }
      }

      getTableOptions() {
        const options: any = {
          tableClass: 'table table-responsive table-condensed main-table tariffs-table',
          tableName: 'terminal-transshipment-tariff-list-table-view.component',
          filterLevel: this.filterLevel,
          configurable: true,
          columnDefs: [] as any[],
          tabs: [],
          rowData: this.tariffs,
          applyFilters: this.applyFilters,
          templateArgs: {
            saveTariff: (tariff: any) => this.saveTariff({ tariff: tariff }),
            deleteTariff: (tariff: any) => this.deleteTariff({ tariff: tariff }),
            getNewTariff: () => this.getNewTariff(),
          },
        };

        options.columnDefs = [
          {
            columnName: 'terminal',
            title: this.gettext('Terminal'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.terminal_name }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select
                  resource-name="'logistics.terminal'"
                  ng-model="item.terminal"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </span>
            `,
          },
          {
            columnName: 'crop',
            title: this.gettext('Cargo'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.crop_title }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select
                  resource-name="'crops.crop'"
                  ng-model="item.crop"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </span>
            `,
          },
          {
            columnName: 'cost',
            title: this.gettext('Tariff'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.cost }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input
                  type="number"
                  class="form-control"
                  ng-model="item.cost"
                  placeholder="{[{ 'Tariff' | translate }]}"
                  style="height: 20px; margin-bottom: 2px;"
                  required
                />
              </span>
            `,
          },
          {
            columnName: 'status',
            title: this.gettext('Status'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.status }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <select class="form-control" ng-model="item.status">
                  <option value="valid">Valid</option>
                  <option value="invalid">Invalid</option>
                </select>
              </span>
            `,
          },
          {
            columnName: 'currency',
            title: this.gettext('Currency'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.currency_symbol }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select
                  resource-name="'finances.currency'"
                  ng-model="item.currency"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </span>
            `,
          },
          {
            columnName: 'currency_exchange',
            title: this.gettext('Rate'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.currency_exchange_rate }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select
                  resource-name="'finances.currencyexchange'"
                  ng-model="item.currency_exchange"
                  query-params="{ local_currency: item.currency }"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </span>
            `,
          },
          {
            columnName: 'additional_info',
            title: this.gettext('Additional info'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.additional_info }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <input
                  type="text"
                  class="form-control"
                  ng-model="item.additional_info"
                  placeholder="{[{ 'Additional info' | translate }]}"
                />
              </span>
            `,
          },
          {
            columnName: 'business_unit',
            title: this.gettext('Business unit'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                {[{ item.business_unit_title }]}
              </span>
              <span ng-if="$ctrl.edit || !item.id">
                <gt-resource-select
                  resource-name="'core.businessunit'"
                  ng-model="item.business_unit"
                  allow-clear="true"
                  on-open-close="args.setHovering(isOpen)"
                ></gt-resource-select>
              </span>
            `,
          },
          {
            columnName: 'author',
            title: this.gettext('Author'),
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
              <span ng-if="!$ctrl.edit && item.id">
                <div class="user-avatar" ng-if="item.author_avatar.length != 0">
                  <img ng-src="/pictures/{[{item.author_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.author_avatar.length == 0">
                  {[{ item.author_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            `,
          },
          {
            columnName: 'save',
            classExpr: "{ 'alert alert-success': $ctrl.edit }",
            cellTemplate: `
            <a style="font-size: 1em; cursor:pointer;" ng-click="$ctrl.edit = true">
              <i ng-if="!$ctrl.edit && item.id" class="fa fa-pencil-square"></i>
            </a>
            <span ng-if="$ctrl.edit || !item.id">
              <a
                class="btn btn-xs btn-success btn_success"
                ng-if="item.id"
                ng-click="args.saveTariff(item);$ctrl.edit=false"
                style="font-size:1em;"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <a
                class="btn btn-xs btn-success btn_success"
                ng-if="!item.id"
                ng-click="args.saveTariff(item)"
                style="font-size:1em;"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <a
                class="btn btn-xs"
                ng-if="item.id"
                ng-click="args.updateTariffs();$ctrl.edit=false"
                style="font-size:1em;"
              >
                <i class="fa fa-times"></i>
              </a>
              <a
                class="btn btn-xs btn-danger btn_danger"
                ng-if="item.id"
                ng-click="args.deleteTariff(item);$ctrl.edit=false"
                style="font-size:1em;"
              >
                <i class="fa fa-trash"></i>
              </a>
            </span>
            `,
            title: this.gettext(''),
          },
        ];

        return options;
      }
    },
  ],
};
