import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './disbursementbl-list-container.html?raw';

export const DisbursementblListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    'GtUtils',
    'gettext',
    'DisbursementBlService',
    'gtFilterService',
    'FinancesService',
    'CoreService',
    'DocumentsService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CoreService: CoreService;
      DisbursementBlService: any;
      DocumentsService: any;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      data: any;
      filterLevel = 'disbursementbl-list-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      savedFilterChoices: any;
      years: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        DisbursementBlService: any,
        gtFilterService: GtFilterService,
        FinancesService: FinancesService,
        CoreService: CoreService,
        DocumentsService: any,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.DisbursementBlService = DisbursementBlService;
        this.gtFilterService = gtFilterService;
        this.FinancesService = FinancesService;
        this.CoreService = CoreService;
        this.DocumentsService = DocumentsService;

        this.data = {};
        this.queryParams = {};
        this.years = this.GtUtils.getYearList();
      }

      $onInit() {
        this.queryParams = { date_predicate: 'date', ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.DisbursementBlService.getTableData(this.queryParams).then((data: any) => {
          this.data = data;
          return this.DisbursementBlService.getTotalsData(this.queryParams).then((data: any) => {
            this.data.totals = data;
            this.GtUtils.overlay('hide');
          });
        });
      }

      updateQueryParams(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      resetFilter() {
        this.queryParams = { date_predicate: 'date', ...this.initQueryParams };
        this.queryParams.start_date = undefined;
        this.queryParams.end_date = undefined;
        this.updateData();
      }

      openDisbursementblModal(disbursementbl: any) {
        return this.FinancesService.disbursementblModal(disbursementbl, {
          dblType: this.initQueryParams.dbl_type,
        }).then(() => this.updateData());
      }

      openDocModal(disbursementbl: any) {
        this.DocumentsService.attachDocumentModal(disbursementbl, 'disbursementbl').then(() =>
          this.updateData(),
        );
      }
    },
  ],
};
