import React from 'react';

import { Link } from '~/shared/lib/router';

export const NotificationsPage: React.FC = () => {
  return (
    <div>
      <h1>Notifications</h1>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};
