import React from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { Trigger } from './trigger';
import { calculateSizeOptionsMaxPage } from '../../lib';

export const EntriesRangeInfo: React.FC<{
  from: number;
  to: number;
  recordsTotal: number;
  pageSize: number;
  pageSizeOptions: number[];
  pageSizeChanged: (size: number, pageCurrent: number) => void;
  pageCurrent: number;
}> = ({ from, to, recordsTotal, pageSizeOptions, pageSizeChanged, pageSize, pageCurrent }) => {
  const maxPageSizesMapping = calculateSizeOptionsMaxPage({
    pageSizeOptions,
    recordsTotal,
    pageCurrent,
  });

  return (
    <DropdownMenu>
      <div className="flex items-center">
        <p className="mr-1 text-xs text-gray-35">Showing</p>
        <DropdownMenuTrigger>
          <Trigger from={from} to={to} recordsTotal={recordsTotal} />
        </DropdownMenuTrigger>
        <p className="text-xs text-gray-35">entries</p>
      </div>
      <DropdownMenuContent className="flex w-[120px] flex-col gap-3 px-2 py-1">
        {pageSizeOptions.map((option) => (
          <DropdownMenuItem
            key={option}
            disabled={option === pageSize}
            className="cursor-pointer px-1 py-0.5 text-xs transition duration-300 ease-in-out hover:bg-background-main-tertiary"
            onClick={() => pageSizeChanged(option, maxPageSizesMapping[option])}
          >
            1-{option}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
