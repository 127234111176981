import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

import template from './risk-position-terminal-container.html?raw';

export const RiskPositionTerminalContainer = {
  bindings: {
    initQueryParams: '<',
    filterLevel: '<',
    onUpdate: '&?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gtFilterService',
    'PhysicalPositionService',
    'ContractsService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      PhysicalPositionService: any;
      businessUnitData: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      onUpdate: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gtFilterService: GtFilterService,
        PhysicalPositionService: any,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.gtFilterService = gtFilterService;
        this.PhysicalPositionService = PhysicalPositionService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.filterLevel = '';
        this.onUpdate = () => {};

        this.businessUnitData = [];
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = { ...data };
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        this.businessUnitData = [];
        return this.PhysicalPositionService.getRiskPositionTerminalGrouping(this.queryParams).then(
          (data: any) => {
            this.GtUtils.overlay('hide');
            this.businessUnitData = data;
          },
        );
      }

      saveRiskPositionRow(terminal: any) {
        this.GtUtils.overlay('show');
        const origination = {
          id: terminal.origination_id,
          business_unit: terminal.business_unit_id,
          cargo: terminal.cargo_id,
          terminal: terminal.terminal_id,
          unconnected_volume: terminal.writable_unconnected_volume,
          dat_self_cost_t: terminal.writable_dat_self_cost_t,
          price_opposite: terminal.price_opposite,
          quality: terminal.quality,
          tax: terminal.tax,
        };
        if (origination.id) {
          return this.PhysicalPositionService.RiskPositionOrigination.update(origination, () => {
            this.GtUtils.notify(this.gettext('Object updated'));
            this.GtUtils.overlay('hide');
            this.onUpdate();
          });
        } else {
          return this.PhysicalPositionService.RiskPositionOrigination.save(origination, () => {
            this.GtUtils.overlay('hide');
            this.onUpdate();
          });
        }
      }

      createPurchaseContract(terminal: any, cargo: any, businessUnit: any) {
        if (!confirm(this.gettext(this.GtUtils.translate('Do you want to create contract?')))) {
          return;
        }
        const contract = {
          contract_number:
            'Stock-' + terminal.port_title + '-' + cargo.cargo_title + '-' + new Date().getTime(),
          contract_type: 'purchase',
          price: Math.round(terminal.fob_price * 100) / 100,
          cargo: cargo.cargo_id,
          business_unit: businessUnit.business_unit_id,
          ports: [terminal.port_id],
          shipment_terminal: terminal.terminal_id,
          from_risk_position_origination: true,
          currency: this.$rootScope.user.settings.CURRENCY_OBJECTS.USD.id,
          basis: this.$rootScope.user.settings.BASIS_OBJECTS.FOB.id,
        };

        return this.ContractsService.contractModal(contract).then((contract: any) => {
          this.ContractsService.connectToPassport({
            id: this.initQueryParams.passport,
            deal_id: contract.id,
            stage: 'contract',
            type: contract.contract_type,
            volume: contract.volume,
          }).then(() => {
            this.onUpdate();
            this.GtUtils.notify(this.gettext('Contract connected.'));
          });
        });
      }

      deleteOrigination(terminal: any) {
        if (!confirm(this.gettext('Are you sure?'))) {
          return;
        }
        this.PhysicalPositionService.RiskPositionOrigination.delete(
          { id: terminal.origination_id },
          () => {
            this.GtUtils.notify(this.gettext('Object deleted'));
            this.onUpdate();
          },
        );
      }

      isValid(terminal: any) {
        return (
          terminal.writable_unconnected_volume !== null &&
          terminal.writable_unconnected_volume !== undefined &&
          terminal.writable_dat_self_cost_t !== null &&
          terminal.writable_dat_self_cost_t !== undefined
        );
      }

      openPurchaseContractListModal(idList: any) {
        return this.ContractsService.contractListModal(
          {
            purchase_ids: idList,
          },
          {
            quickAdd: false,
            showBoth: false,
            tableName: 'from-risk-position-origination-purchase-contracts',
          },
        );
      }
    },
  ],
};
