import React from 'react';

import { GlobalSearchButton } from '~/core/global-search';
import { BurgerMenuIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { Logo } from '../logo';
import { Menu } from '../menu';

export const BurgerMenu: React.FC = () => {
  return (
    <div className="z-30">
      <DropdownMenu>
        <Tooltip content="Click to lock sidebar on the left" side="right" sideOffset={5}>
          <DropdownMenuTrigger className="mt-[5px] rounded text-text-main-primary">
            <div className="rounded p-1 transition duration-300 ease-in-out hover:bg-transparent-light">
              <BurgerMenuIcon size={20} className="text-stroke-main-strongest" />
            </div>
          </DropdownMenuTrigger>
        </Tooltip>
        <DropdownMenuContent side="bottom" sideOffset={12} className="rounded-md p-0">
          <section className="group h-[80vh] w-[220px] overflow-y-auto shadow-lg">
            <div className="relative flex h-full flex-col pb-2">
              <Logo />
              <GlobalSearchButton />
              <Menu />
            </div>
          </section>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
