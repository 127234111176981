import React from 'react';

import { cn } from '~/shared/lib/utils';

export type LinkProps = {
  href: string;
  children: React.ReactNode;
  disabled?: boolean;
};

export const Link: React.FC<LinkProps> = ({ href, children, disabled }) => {
  return (
    <a
      {...(!disabled && { href: href })}
      target="_blank"
      className={cn(
        'rounded-full border border-stroke-main-strong bg-transparent-lighter px-2 py-0.5 text-xs text-text-main-primary transition duration-300 hover:bg-transparent-medium active:bg-transparent-strong',
        disabled && 'cursor-not-allowed opacity-50',
      )}
      rel="noreferrer"
      aria-disabled={disabled}
    >
      {children}
    </a>
  );
};
