import React from 'react';

import { withRouterDevtools } from '~/shared/config';

export const TanStackRouterDevtools = !withRouterDevtools
  ? () => null
  : React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );
