import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('printButton', directive);

  function directive() {
    return {
      template: require('./print-button.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {},
    };
  }

  Controller.$inject = ['$window'];

  function Controller(this: any, $window: ng.IWindowService) {
    const vm = this;

    vm.click = click;

    ////////////////

    function click() {
      $window.print();
    }
  }
})();
