import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './consolidated-pnl-container.html?raw';

export const ConsolidatedPnlContainer = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$q',
    '$rootScope',
    '$scope',
    'gtFilterService',
    'GtUtils',
    'ConsolidatedPnlService',
    'gettext',
    'AccountsService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      AccountsService: any;
      ConsolidatedPnlService: any;
      GtUtils: GtUtilsService;
      cancelRequest: any;
      cancelRequests: any;
      chartConfigList: any;
      data: any;
      filterLevel = 'consolidated-pnl-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      monthList: any;
      passportStatusOptions: any;
      periodType: any;
      queryParams: any;
      report_year_list: any;
      rowTypeList: any;
      showAllDetails: any;
      showCell: any;
      showFiltersPerset: any;
      showVolume: any;
      showVolumeInternal: any;
      title_list: any;
      yearList: any;
      constructor(
        $q: ng.IQService,
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        ConsolidatedPnlService: any,
        gettext: ng.gettext.gettextFunction,
        AccountsService: any,
      ) {
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;
        this.ConsolidatedPnlService = ConsolidatedPnlService;
        this.AccountsService = AccountsService;
        this.gettext = gettext;
        this.queryParams = {};
        this.showCell = {};
        this.chartConfigList = [];
        this.showVolume = false;
        this.showVolumeInternal = false;
        this.showFiltersPerset = false;
        this.periodType = 'period';
        this.cancelRequests = undefined;
        this.rowTypeList = [
          { key: 1, title: 'Passports', icon: 'fa fa-exchange' },
          { key: 2, title: 'Open contracts', icon: 'fa fa-file-text' },
          { key: 3, title: 'Services', icon: 'fa fa-sack-dollar' },
          { key: 4, title: 'Spec. Accounts operations', icon: 'fa fa-bolt' },
        ];

        this.passportStatusOptions = [
          {
            value: 'Request',
            title: gettext('New'),
          },
          {
            value: 'Approved',
            title: gettext('Approved'),
          },
          {
            value: 'Processing',
            title: gettext('Processing'),
          },
          {
            value: 'cargo_partial_executed',
            title: gettext('Cargo partial executed'),
          },
          {
            value: 'cargo_executed',
            title: gettext('Cargo executed'),
          },
          {
            value: 'Executed',
            title: gettext('Executed'),
          },
          {
            value: 'Cancelled',
            title: gettext('Cancelled'),
          },
        ];

        this.initQueryParams = {
          group_by: this.$rootScope.user.settings.DEFAULT_PNL_DETALIZATION,
          margin_type: this.$rootScope.user.settings.DEFAULT_PNL_MARGIN,
          report_year_list: [],
          commodity_level: '0',
          charge_level: '0',
          row_type_list: JSON.parse(
            this.$rootScope.user.settings.PASSPORT_PNL_DEFAULT_SOURCES_TAB,
          ) || [1, 2],
        };

        this.yearList = this.GtUtils.getYearList().map((year: any) => year.id);
      }

      $onInit() {
        this.showAllDetails = !!this.$rootScope.user.settings.DEFAULT_SHOW_PNL_PASSPORTS;
        this.filterLevel = this.filterLevel || 'consolidated-pnl-container';
        this.queryParams = { ...this.initQueryParams };
        this.initPeriodParams();
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      initPeriodParams() {
        if (this.periodType === 'period') {
          this.queryParams.start_date = `01.01.${
            this.$rootScope.user.settings.DEFAULT_VALUES.crop_year - 1
          }`;
          this.queryParams.end_date = `31.12.${this.$rootScope.user.settings.DEFAULT_VALUES.crop_year}`;
          this.queryParams.report_year_list = [];
        } else if (this.periodType === 'yearsList') {
          this.report_year_list = [];
          this.toggleYear(this.$rootScope.user.settings.DEFAULT_VALUES.crop_year);
          this.toggleYear((this.$rootScope.user.settings.DEFAULT_VALUES.crop_year - 1).toString());
          this.queryParams.end_date = this.queryParams.start_date = undefined;
        }
      }

      updateData() {
        this.GtUtils.overlay('show');

        if (this.cancelRequest) {
          this.cancelRequest.resolve({ isAborted: true });
        }

        this.cancelRequest = this.$q.defer();

        const c = this.ConsolidatedPnlService.getData(this.queryParams, this.cancelRequest.promise)
          .then((data: any) => {
            this.data = data;
            this.title_list = data.title_list?.filter(this.ConsolidatedPnlService.onlyUnique) || [];
            this.title_list.sort();
            this.monthList = this.ConsolidatedPnlService.getDateRangeByYear(2020);

            this.chartConfigList = [];
            this.data.data.forEach((year: any) => {
              const monthDataList: any = [];
              year.data.forEach((quarter: any) => {
                monthDataList.push(...quarter.data);
              });
              const chartConfig = this.makePnlChartConfig(monthDataList);
              // @ts-ignore
              chartConfig.yearKey = year.year_key;
              this.chartConfigList.push(chartConfig);
            });
          })
          .finally(() => {
            this.GtUtils.overlay('hide');
          });

        return this.GtUtils.addConnection(c).$promise;
      }

      applyFilters() {
        if (
          this.queryParams.voyage_vessel_size_list?.length ||
          this.queryParams.logistic_type_list?.length
        ) {
          this.queryParams.row_type_list = [1];
        }
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      makePnlChartConfig(data: any) {
        const pnlChartConfig: any = { data: [], labels: [], series: [], options: {}, count: 0 };
        pnlChartConfig.labels = this.monthList.map((month: any) => month.title);
        pnlChartConfig.count = pnlChartConfig.labels.length;
        pnlChartConfig.series = this.title_list;
        pnlChartConfig.series.forEach((title: any, index: any) => {
          pnlChartConfig.data.push(new Array(pnlChartConfig.labels.length).fill(0));
          data.forEach((item: any) => {
            item.data?.forEach((el: any) => {
              if (el.title === title) {
                pnlChartConfig.data[index].splice(
                  parseInt(el.period_month, 10) - 1,
                  1,
                  el.margin_sum?.toFixed(2) || 0,
                );
              }
            });
          });
        });
        pnlChartConfig.options = {
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        };
        return pnlChartConfig;
      }

      toggleYear(year: any) {
        if (this.queryParams.report_year_list.includes(year)) {
          this.queryParams.report_year_list = this.queryParams.report_year_list.filter(
            (v: any) => v !== year,
          );
        } else {
          this.queryParams.report_year_list.push(year);
          this.queryParams.report_year_list.sort();
        }
      }

      togglePeriodType(periodType: any) {
        if (this.periodType === periodType) {
          return;
        }
        this.periodType = periodType;
        this.initPeriodParams();
      }

      toggleRowType(rowType: any) {
        if (this.queryParams.row_type_list.includes(rowType)) {
          this.queryParams.row_type_list = this.queryParams.row_type_list.filter(
            (v: any) => v !== rowType,
          );
        } else {
          this.queryParams.row_type_list.push(rowType);
        }
        this.applyFilters();
      }

      getPrevYearData(year: any, quarter: any, month: any, param: any) {
        const prevYear = Math.max(
          ...this.queryParams.report_year_list.filter((y: any) => y < year),
        );
        if (isFinite(prevYear)) {
          return this.data[prevYear][quarter][month][param];
        }
      }
    },
  ],
};
