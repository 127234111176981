import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').filter('cut', filter);

  function filter() {
    return function (value: any, wordwise: any, max: any, tail: any) {
      if (!value || typeof value != 'string') {
        return '';
      }

      max = parseInt(max, 10);
      if (!max) {
        return value;
      }
      if (value.length <= max) {
        return value;
      }

      value = value.substring(0, max);
      if (wordwise) {
        const lastspace = value.lastIndexOf(' ');
        if (lastspace != -1) {
          value = value.substr(0, lastspace);
        }
      }

      return value + (tail || ' …');
    };
  }
})();

(function () {
  'use strict';
  ng.module('core.legacy').filter('unique', filter);

  function filter() {
    return function (collection: any, keyname: any) {
      // we define our output and keys array;
      const output: any = [],
        keys: any = [];

      // we utilize angular's foreach function
      // this takes in our original collection and an iterator function
      ng.forEach(collection, function (item: any) {
        // we check to see whether our object exists
        const key = item[keyname];
        // if it's not already part of our keys array
        if (keys.indexOf(key) === -1) {
          // add it to our keys array
          keys.push(key);
          // push this item to our final output array
          output.push(item);
        }
      });
      // return our array which should be devoid of
      // any duplicates
      return output;
    };
  }
})();
