import * as ng from 'angular';

import { generalTransactionsContainer } from './containers/general-transactions-container/general-transactions-container.cmp';
import { transactionsContainer } from './containers/transactions-container/transactions-container.cmp';
import { FinancesService } from './finances.srv';
import { generalTransactionsPageView } from './pages/general-transactions-page-view/general-transactions-page-view.cmp';
import { transactionsPageView } from './pages/transactions-page-view/transactions-page-view.cmp';

export const financesLegacyModule = ng
  .module('finances.legacy', [])
  .component('generalTransactionsContainer', generalTransactionsContainer)
  .component('transactionsContainer', transactionsContainer)
  .component('generalTransactionsPageView', generalTransactionsPageView)
  .component('transactionsPageView', transactionsPageView)
  .service('FinancesService', FinancesService).name;
