import ng from 'angular';

(function () {
  'use strict';
  ng.module('common.crops.legacy').directive('cropsAnalyticsRow', directive);

  function directive() {
    return {
      template: require('./analytics-row.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        analyticsRow: '<',
      },
    };
  }

  function Controller() {
    ////////////////
  }
})();
