import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';
import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('core.legacy').controller('GtExportModalController', Controller);

  Controller.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    '$httpParamSerializer',
    'gettext',
    'filterLevel',
    'CoreService',
    'GtUtils',
    'resourceName',
    'extraData',
    'gtFilterService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    $window: ng.IWindowService,
    $httpParamSerializer: any,
    gettext: ng.gettext.gettextFunction,
    filterLevel: string,
    CoreService: CoreService,
    GtUtils: GtUtilsService,
    resourceName: string,
    extraData: any,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.tab = 'download';
    vm.close = close;
    vm.saveParams = saveParams;
    vm.saveCollection = saveCollection;
    vm.deleteCollection = deleteCollection;
    vm.updateParams = updateParams;
    vm.filterLevel = filterLevel;
    vm.params = [];
    vm.origParams = [];
    vm.toggleVisible = toggleVisible;
    vm.cheched = true;
    vm.overlay = false;
    vm.collection = { filter_level: filterLevel };
    vm.collections = [];
    vm.download = download;
    vm.updateData = updateData;
    vm.getResourceURL = getResourceURL;
    vm.copyURLToClipboard = copyURLToClipboard;

    activate();

    ////////////////

    function activate() {
      updateData();
      vm.extraData = extraData || {};
      vm.apiUrl = extraData.apiUrl;
    }

    function updateData() {
      return updateCollections().then(updateParams);
    }

    function updateParams() {
      if (!vm.collection?.id) {
        vm.params = [];
        vm.origParams = [];
        return GtUtils.notify(gettext('collection'), 'warning');
      }
      const resource = GtUtils.getResource(resourceName),
        params = {
          filter_level: filterLevel,
          collection: vm.collection.id,
          resource_class: vm.extraData.resourceClass,
        };
      return CoreService.getExportColumnParams(resource, params).then(function (data: any) {
        vm.params = data;
        vm.origParams = JSON.parse(JSON.stringify(data));
      });
    }

    function updateCollections() {
      return CoreService.CollectionExportColumnParams.query(
        {
          filter_level: filterLevel,
        },
        function (data: any) {
          vm.collections = data;
          if (data.length && !vm.collection.id) {
            vm.collection = data[0];
          }
        },
      ).$promise;
    }

    function saveParams(closeAfter?: any) {
      let chain = Promise.resolve();
      vm.overlay = GtUtils.overlay('show');
      vm.params
        .filter(function (item: any, index: any) {
          return !item.id || JSON.stringify(item) !== JSON.stringify(vm.origParams[index]);
        })
        .forEach(function (params: any) {
          let saveFunc = CoreService.ExportColumnParams.save;
          if (params.id) {
            saveFunc = CoreService.ExportColumnParams.update;
          }
          chain = chain.then(function () {
            return saveFunc(
              params,
              function () {
                vm.overlay = GtUtils.overlay('show');
              },
              (e: any) => GtUtils.errorClb(e),
            ).$promise;
          });
        });
      return chain.then(function () {
        vm.overlay = GtUtils.overlay('hide');
        vm.overlay = GtUtils.overlay('hide');
        if (closeAfter) {
          close(false, true);
        }
      });
    }

    function saveCollection() {
      let saveFunc;
      vm.overlay = GtUtils.overlay('show');

      if (vm.collection?.id) {
        saveFunc = CoreService.CollectionExportColumnParams.update;
      } else {
        saveFunc = CoreService.CollectionExportColumnParams.save;
      }
      vm.collection.filter_level = filterLevel;
      return saveFunc(
        vm.collection,
        async function (data: any) {
          vm.collection = data;
          vm.overlay = GtUtils.overlay('hide');
          await saveParams().then(updateParams);
        },
        (e: any) => {
          vm.overlay = GtUtils.overlay('hide');
          GtUtils.errorClb(e);
        },
      ).$promise;
    }

    function deleteCollection(collection: any) {
      if (!confirm(gettext('Are you sure you want to delete this collection?'))) {
        return;
      }

      GtUtils.overlay('show');

      CoreService.CollectionExportColumnParams.delete({ id: collection.id })
        .$promise.then(() => {
          vm.collections = vm.collections.filter((item: any) => item.id !== collection.id);

          GtUtils.overlay('hide');
          GtUtils.notify(gettext('Collection deleted successfully'));
        })
        .catch((error: any) => {
          GtUtils.overlay('hide');
          GtUtils.errorClb(error);
        });
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function toggleVisible() {
      vm.cheched = !vm.cheched;
      vm.params.forEach(function (item: any) {
        item.visible = vm.cheched;
      });
    }

    function getResourceURL(collection: any, resourceClass: string) {
      const resourceUrls: Record<string, string> = {
          'contracts.contractbase': 'contracts/contracts',
          'contracts.salecontract': 'contracts/sale-contracts',
          'contracts.purchasecontract': 'contracts/purchase-contracts',
          'contracts.servicescontract': 'contracts/services-contracts',
          'contracts.intermediatecontract': 'contracts/intermediate-contracts',
          'contracts.exportcontract': 'contracts/export-contracts',
          'contracts.consignment': 'contracts/consignments',
          'contracts.contractcharge': 'contracts/contract-charges',
          'contracts.request': 'contracts/requests',
          'contracts.generalagreement': 'contracts/general-agreements',
          'logistics.logistic': 'logistics/logistics',
          'logistics.billoflading': 'logistics/bills-of-lading',
          'logistics.customscargodeclaration': 'logistics/customs-cargo-declaration',
          'finances.finance': 'finances/finances',
          'finances.invoiceposition': 'finances/invoice-positions',
          'finances.payment': 'finances/payments',
          'finances.disbursementbl': 'finances/disbursementbl',
          'finances.reassignment': 'finances/reassignment',
          'finances.financeoffset': 'finances/offsets',
          'passports.passport': 'passports/passports',
          'reports.financeclientdetails': 'passports/passports',
          'clients.client': 'clients/clients',
          'clients.croparea': 'clients/crop-areas',
          'clients.clientupdate': 'clients/clientupdates',
          'stockexchanges.operation': 'stockexchanges/operations',
          'logistics.warehouseloss': 'logistics/warehouse-losses',
        },
        params = Object.assign(gtFilterService.getQueryParams(filterLevel), {
          resource_class: resourceClass,
        }),
        apiUrl = vm.apiUrl || `/api/${resourceUrls[resourceName]}/export_xlsx/?`,
        url = `${apiUrl}collection=${collection.id}&${$httpParamSerializer(params)}`;
      return url;
    }

    function download(collection: any, resourceClass: any) {
      $window.open(vm.getResourceURL(collection, resourceClass));
    }

    function copyURLToClipboard(collection: any, resourceClass: any) {
      GtUtils.copyToClipboard(vm.getResourceURL(collection, resourceClass));
    }

    vm.moveItem = function (position: any, newPosition: any) {
      const item = vm.params[position];
      vm.params.splice(position, 1);
      vm.params.splice(newPosition, 0, item);
      vm.params.forEach((i: any, idx: any) => (i.index = idx));
      $scope.$apply();
    };
  }
})();
