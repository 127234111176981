import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';

const tagVariants = cva('h-[20px] w-max px-2 text-xs', {
  variants: {
    variant: {
      green: 'bg-tags-background-green text-tags-text-green',
      turquoise: 'bg-tags-background-turquoise text-tags-text-turquoise',
      sky: 'bg-tags-background-sky text-tags-text-sky',
      blue: 'bg-tags-background-blue text-tags-text-blue',
      purple: 'bg-tags-background-purple text-tags-text-purple',
      pink: 'bg-tags-background-pink text-tags-text-pink',
      red: 'bg-tags-background-red text-tags-text-red',
      orange: 'bg-tags-background-orange text-tags-text-orange',
      yellow: 'bg-tags-background-yellow text-tags-text-yellow',
      gray: 'bg-tags-background-gray text-tags-text-gray',
    },
    fontWeight: {
      normal: 'font-normal',
      medium: 'font-medium',
    },
    rounded: {
      medium: 'rounded-sm',
      full: 'rounded-full',
    },
  },
  defaultVariants: {
    variant: 'gray',
    fontWeight: 'normal',
    rounded: 'medium',
  },
});

export type TagVariants = VariantProps<typeof tagVariants>;
export type TagComponent = {
  children: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  disabled?: boolean;
} & TagVariants;

export const Tag: React.FC<TagComponent> = ({
  children,
  variant,
  fontWeight,
  rounded,
  prefix,
  suffix,
  disabled,
}) => {
  return (
    <div
      className={cn(
        tagVariants({ variant, fontWeight, rounded }),
        'flex items-center gap-1',
        disabled && 'cursor-not-allowed opacity-50',
      )}
      data-testid="tag"
    >
      {prefix}
      <span className="text-xs">{children}</span>
      {suffix}
    </div>
  );
};
