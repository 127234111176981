import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';

import type { FinancesService } from '../../legacy/finances.srv';

export class OffsetService {
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  FinancesService: FinancesService;
  OffsetPositionsResource: any;
  OffsetResource: any;
  moment: any;
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    moment: any,
    $uibModal: ng.ui.bootstrap.IModalService,
    FinancesService: FinancesService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.moment = moment;
    this.FinancesService = FinancesService;
    this.OffsetResource = $resource(
      '/api/finances/offsets/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offsets-list/',
        },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offsets/predictions/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/offsets/bulk_create_or_update/',
        },
        getModalData: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offset-modal/:id/',
        },
        details: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offset-details/:id/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offsets/export_column_names/',
        },
        removeFromOffset: {
          method: 'POST',
          url: '/api/finances/offsets/:id/remove_from_offset/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offset-totals/',
        },
      },
    );
    this.OffsetPositionsResource = $resource(
      '/api/finances/offset-positions/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/offset-positions-list/',
        },
      },
    );
  }

  updateOffset(params: any) {
    return this.OffsetResource.update(params).$promise;
  }

  getOffsetPositions(params: any) {
    return this.OffsetPositionsResource.query(params).$promise;
  }

  deleteOffsetPosition(params: any) {
    return this.OffsetPositionsResource.delete(params).$promise;
  }

  offsetModal(offset: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <offset-modal offset="offset" modal-instance="$uibModalInstance">
        </offset-modal>`,
      controller: [
        '$scope',
        'offset',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          offset: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).offset = offset;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        offset: () => offset,
      },
    }).result;
  }

  saveOffset(offset: any, positions: any) {
    return this.OffsetResource.bulkCreateOrUpdate({
      item: offset,
      child_data: positions,
    }).$promise;
  }

  getOffsetTableData(params: any) {
    return this.OffsetResource.query(params).$promise;
  }

  getOffsetTotals(params: any) {
    return this.OffsetResource.totals(params).$promise;
  }

  getOffsetModalData(params: any) {
    return this.OffsetResource.get(params).$promise;
  }

  getOffsetDetails(params: any) {
    return this.OffsetResource.details(params).$promise;
  }

  deleteFromOffset(params: any) {
    return this.OffsetResource.removeFromOffset(params).$promise;
  }

  destroy(params: any) {
    return this.OffsetResource.delete(params).$promise;
  }
}
OffsetService.$inject = ['$resource', '$q', 'moment', '$uibModal', 'FinancesService'];
