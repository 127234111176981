import React from 'react';

import { componentMap } from './action-view-map';
import type { ActionBarConfig } from '../types';

export const ActionView: React.FC<ActionBarConfig> = ({ view, ...props }) => {
  const Component = componentMap[view];

  return <Component view={view} {...props} />;
};
