import template from './charts-list.html?raw';

export const ChartsList = {
  bindings: {
    hideCharts: '<',
    configs: '<',
  },
  template,
  controller: [
    class {
      configs: any;
      hideCharts: any;
      constructor() {
        this.hideCharts = {};
        this.configs = {};
      }
    },
  ],
};
