import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

export class AnalyticsService {
  $document: ng.IDocumentService;
  $resource: ng.resource.IResourceService;
  $rootScope: GtRootScopeService;
  gettext: ng.gettext.gettextFunction;
  suprsetDashboardDetailsResource: any;
  suprsetDashboardsResource: any;
  tokenResource: any;
  constructor(
    $document: ng.IDocumentService,
    $resource: ng.resource.IResourceService,
    $rootScope: GtRootScopeService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$document = $document;
    this.$resource = $resource;
    this.$rootScope = $rootScope;
    this.gettext = gettext;
    this.tokenResource = this.$resource(
      '/api/superset/guest-token/',
      {},
      { get: { method: 'GET' } },
    );
    this.suprsetDashboardsResource = this.$resource(
      '/api/superset/dashboards/',
      {},
      { get: { method: 'GET' } },
    );
    this.suprsetDashboardDetailsResource = this.$resource(
      '/api/superset/get_dashboard_by_id/',
      {},
      { get: { method: 'GET' } },
    );
  }

  getToken(id: any) {
    return this.tokenResource.get({ id }).$promise.then((response: any) => response.token);
  }

  getDashboardById(id: any) {
    return this.suprsetDashboardDetailsResource.get({ id }).$promise;
  }

  getDashboards() {
    return this.suprsetDashboardsResource.get().$promise.then((response: any) => {
      return response.result
        .filter((dashboard: any) => dashboard.status === 'published')
        .map((dashboard: any) => ({
          id: dashboard.id,
          title: dashboard.dashboard_title,
          slug: dashboard.slug,
        }));
    });
  }

  getAnalyticPageConfig(page: string) {
    switch (page) {
      case 'crm':
        return this.getCrmPagesConfig();
      case 'deals':
        return this.getDealsPagesConfig();
      case 'executions':
        return this.getExecutionsPagesConfig();
      case 'finances':
        return this.getFinancesPagesConfig();
      default:
        return {};
    }
  }

  getCrmPagesConfig() {
    return {
      class: 'page-header-main-crm-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.counterparty',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Counterparty'),
              state: 'analytic.counterparty',
              icon: 'fa fa-building',
              permissions: [],
            },
            {
              title: this.gettext('Updates'),
              state: 'analytic.updates',
              icon: 'fa fa-commenting',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }

  getDealsPagesConfig() {
    return {
      class: 'page-header-main-prices-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.contracts',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Tickets'),
              state: 'analytic.tickets',
              icon: 'fa fa-file-text-o',
              permissions: [],
            },
            {
              title: this.gettext('Contracts'),
              state: 'analytic.contracts',
              icon: 'fa fa-file-text',
              permissions: [],
            },
            {
              title: this.gettext('Passports'),
              state: 'analytic.passports',
              icon: 'fa fa-exchange',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }

  getExecutionsPagesConfig() {
    return {
      class: 'page-header-main-logistics-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.logistics',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Logistics'),
              state: 'analytic.logistics',
              icon: 'fa fa-truck',
              permissions: [],
            },
            {
              title: this.gettext('Voyages'),
              state: 'analytic.voyages',
              icon: 'fa fa-ship',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }

  getFinancesPagesConfig() {
    return {
      class: 'page-header-main-payments-tab',
      buttons: [],
      pages: [
        {
          title: this.gettext('Analytics'),
          state: 'analytic.invoices',
          icon: 'fa fa-area-chart',
          tabs: [
            {
              title: this.gettext('Invoices'),
              state: 'analytic.invoices',
              icon: 'fa fa-credit-card-alt',
              permissions: [],
            },
            {
              title: this.gettext('Charges'),
              state: 'analytic.charges',
              icon: 'fa fa-money',
              permissions: [],
            },
          ],
          permissions: ['view_analytics'],
        },
      ],
    };
  }
}

AnalyticsService.$inject = ['$document', '$resource', '$rootScope', 'gettext'];
