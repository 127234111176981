import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './logistic-details-item-view.html?raw';

export const logisticDetailsItemView = {
  bindings: {
    fields: '<?',
    tab: '<?',
    logistic: '<?',
    usesList: '<?',
    logisticsQueryParams: '<?',
    save: '&?',
    clone: '&?',
    openDocumentModal: '&?',
    openDocxModal: '&?',
    updateFields: '&?',
    getFormConfig: '&?',
    openLogisticModal: '&?',
    updateData: '&?',
    hideWizard: '<?',
    openEmailModal: '&?',
    changeTab: '&?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      fields: any;
      logistic: any;
      logisticsQueryParams: QueryParams = {};
      partialTab: any;
      showDetail: any;
      save: any;
      clone: any;
      openDocumentModal: any;
      openDocxModal: any;
      updateFields: any;
      getFormConfig: any;
      openLogisticModal: any;
      updateData: any;
      hideWizard: any;
      changeTab: any;

      constructor($rootScope: GtRootScopeService) {
        this.logistic = {};
        this.fields = [];
        this.partialTab = { tab: 'partial' };
        this.logisticsQueryParams = {};
        if ($rootScope.user.profile.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
      }
    },
  ],
};
