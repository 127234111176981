export class ContractQualityRulesService {
  $resource: ng.resource.IResourceService;
  contractQualityRulesResource: any;
  constructor($resource: ng.resource.IResourceService) {
    this.$resource = $resource;
    this.contractQualityRulesResource = $resource(
      '/api/crops/contract-quality-rules/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/crops/contract-quality-rules/bulk_create_or_update/',
        },
        delete: { method: 'DELETE' },
      },
    );
  }

  getListData(params: any) {
    return this.contractQualityRulesResource.query(params).$promise.then((data: any) => {
      return { data: data };
    });
  }

  save(item: any) {
    return this.contractQualityRulesResource.bulkCreateOrUpdate({
      item: item,
      child_data: item.positions,
    }).$promise;
  }

  delete(item: any) {
    return this.contractQualityRulesResource.delete({ id: item.id }).$promise;
  }
}
ContractQualityRulesService.$inject = ['$resource'];
