import React from 'react';

import { useMenu } from '~/core/theme/hooks/use-menu';
import { useObservableEagerState } from '~/shared/lib/state';
import { type IconComponent } from '~/shared/ui/icons';

import { NavigationButton } from './navigation-button';
import { MenuItem } from '../menu-item';
import type { SidebarMenuItem } from '../types';

export const MenuItemNavigation: React.FC<{
  item: SidebarMenuItem;
  Icon: IconComponent;
}> = ({ item, Icon }) => {
  const { menuStore } = useMenu();

  const openMenuIds = useObservableEagerState(menuStore.openMenuIds$);

  return (
    <li className="w-full">
      <NavigationButton item={item} Icon={Icon} />
      {(openMenuIds.includes(item.id) || item.isActive) && (
        <MenuItem key={item.id} item={item} isOpen={openMenuIds.includes(item.id)} />
      )}
    </li>
  );
};
