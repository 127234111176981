import type ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './reservations-modal.html?raw';

export const ReservationsModal = {
  bindings: {
    modalInstance: '<',
    item: '<?',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'ReservationsService',
    'ReservationFormFieldsService',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      ReservationFormFieldsService: any;
      ReservationsService: any;
      balanceParams: any;
      fields: any;
      fieldsForBalanceUpdate: any;
      fieldsForUpdateBalanceParams: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      item: any;
      modalInstance: any;
      promise: any;
      volume: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        ReservationsService: any,
        ReservationFormFieldsService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.ReservationsService = ReservationsService;
        this.ReservationFormFieldsService = ReservationFormFieldsService;
        this.modalInstance = undefined;

        this.form = undefined;
        this.fields = [];
        this.item = {};
        this.fieldsForBalanceUpdate = [
          'date',
          'from_stock_reserve_unit',
          'to_stock_reserve_unit',
          'business_unit',
          'warehouse',
          'commodity',
          'contract_position',
          'producer',
          'receiver',
          'batch',
        ];
        this.fieldsForUpdateBalanceParams = [
          'date',
          'from_stock_reserve_unit',
          'to_stock_reserve_unit',
          'business_unit',
          'warehouse',
          'commodity',
          'contract_position',
          'producer',
          'receiver',
          'batch',
        ];
        this.balanceParams = {};
      }
      $onInit() {
        this.item = this.item || this.initNewItem();
        this.setWatchers();
        this.$scope.$watchCollection(
          () => this.item,
          (newVal: any, oldVal: any) => {
            if (this.fieldsForBalanceUpdate.some((f: any) => newVal[f] !== oldVal[f])) {
              return this.updateBalance();
            }
            if (newVal.volume !== oldVal.volume) {
              this.checkBalanceVolume();
            }
          },
        );
        if (this.item.id) {
          return this.updateData();
        }
        return this.updateFields();
      }
      setWatchers() {
        this.$scope.$watch(
          () => this.item,
          () => this.updateBalanceParams(),
          true,
        );
        this.$scope.$watch(
          () => this.volume,
          (newVal: any, oldVal: any) => {
            if (oldVal === newVal) {
              return;
            }
            return this.checkBalanceVolume();
          },
        );
      }
      updateBalanceParams() {
        if (!this.item.warehouse || !this.item.commodity) {
          return;
        }
        return this.ReservationsService.getBalanceParams(this.item).then((data: any) => {
          if (JSON.stringify(this.updateBalance) !== JSON.stringify(data)) {
            this.balanceParams = data;
            return this.updateBalance();
          }
          this.balanceParams = data;
        });
      }
      updateBalance = () => {
        this.promise?.$cancelRequest();
        this.promise = this.ReservationsService.getBalance(this.balanceParams);
        return this.promise.$promise.then((restData: any) => {
          this.item.balance = restData.totals.volume_balance;
          this.checkBalanceVolume();
        });
      };

      checkBalanceVolume() {
        this.item.errors = {};
        if (this.item.volume > (this.item.balance || 0)) {
          this.item.errors = {
            balance: ['volume exceeds balance'],
          };
        }
      }
      initNewItem() {
        return {};
      }
      updateData() {
        this.GtUtils.overlay('show');

        return this.ReservationsService.get(this.item.id)
          .then((data: any) => {
            this.item = data;
            return this.updateFields();
          })
          .catch(() => {
            this.GtUtils.notify(this.gettext('"Reservation" doesn\'t exist'));
            this.GtUtils.goPage('logistics.reservationList');
          })
          .finally(() => this.GtUtils.overlay('hide'));
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      destroy() {
        if (
          !confirm(this.gettext('Are you sure that you want delete this "Stock Reserve Unit"?'))
        ) {
          return;
        }
        return this.ReservationsService.delete({ id: this.item.id }).then(() => {
          this.GtUtils.notify(this.gettext('"Reservation" deleted'));
          this.close('delete', true);
        });
      }

      save() {
        return this.ReservationsService.save(this.item)
          .then((data: any) => this.close(data, true))
          .catch((e: any) => this.GtUtils.errorClb(e))
          .finally(() => this.GtUtils.overlay('hide'));
      }

      openFieldsConfigModal() {
        return this.ReservationFormFieldsService.openFieldsConfigModal().then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        return this.ReservationFormFieldsService.getFields(this.item).then((fields: any) => {
          this.fields = fields;
        });
        // .then(() => this.setWatchers());
      }
    },
  ],
};
