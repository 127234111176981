import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './bank-accounts-table.html?raw';

export const BankAccountsTable = {
  bindings: {
    filterLevel: '<?',
    bankAccounts: '<?',
    bankAccountsCount: '<?',
    onUpdate: '&',
    total: '<',
    count: '<?',
  },
  template,
  controller: [
    '$timeout',
    '$rootScope',
    'gettext',
    'FinancesService',
    'gtFilterService',
    'GtUtils',
    class {
      $rootScope: GtRootScopeService;
      $timeout: ng.ITimeoutService;
      bankAccounts: any;
      count: number;
      edit: any;
      errors: any;
      filterLevel = 'bank-accounts-table';
      financesService: any;
      gettext: ng.gettext.gettextFunction;
      groupedBankAccounts: any;
      gtFilterService: GtFilterService;
      GtUtils: GtUtilsService;
      hovering: any;
      onUpdate: any;
      rows: any;
      saveDisabled: any;
      showInitialBalance: any;
      tableData: any;
      tableOptions: any;
      total: any;
      totals: any;
      view: any;
      constructor(
        $timeout: ng.ITimeoutService,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        FinancesService: FinancesService,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
      ) {
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.financesService = FinancesService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;

        this.count = 0;
        this.total = [];
        this.showInitialBalance = false;
        this.bankAccounts = [];
        this.groupedBankAccounts = {};

        this.totals = this.totals || {};
        this.view = this.$rootScope.user.settings.BANK_ACCOUNTS_DEFAULT_VIEW || 'block';
        this.edit = false;
        this.tableData = {};
      }

      $onInit() {
        this.bankAccounts = this.bankAccounts || [];
        this.filterLevel = this.filterLevel || 'bank-accounts-table';
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.bankAccounts || changes.total) {
          if (this.bankAccounts.length) {
            this.groupBankAccounts();
          }
          this.$timeout(() => {
            this.tableData = {
              rows: this.bankAccounts || [],
              count: this.count,
              total: this.total || [],
            };
            this.rows = this.tableData.rows.reduce((options: any, item: any, index: any) => {
              options[item.id] = { index: index, editable: false };
              return options;
            }, {});
          });
        }
      }

      setHovering(value: any) {
        this.hovering = value;
      }

      openBankAccountActivityModal(item: any) {
        const params = {
          payment_bank_account: item.id,
        };
        return this.financesService.bankAccountActivityModal(params, {
          quickAdd: false,
        });
      }

      openBankAccountRestsModal(item: any) {
        return this.financesService.bankAccountRestsModal(item);
      }

      destroy(item: any) {
        return this.financesService.BankAccount.delete(
          { id: item.item.id },
          () => {
            this.onUpdate().then(() => {
              item = {};
            });
          },
          (data: any) => this._error(data),
        ).$promise;
      }

      save(item: any) {
        this.saveDisabled = true;
        let hasErrors = false;

        if (item.initial_cash === undefined) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('Initial cash is required'), 'error');
        }

        if (!item.currency) {
          this.saveDisabled = false;
          hasErrors = true;
          this.GtUtils.notify(this.gettext('Currency is required'), 'error');
        }

        if (hasErrors) {
          return;
        }
        return this.financesService.BankAccount.update(
          item,
          () => {
            this.onUpdate();
            this.errors = {};
            this.saveDisabled = false;
            this.edit = false;
            item.editable = false;
            this.GtUtils.notify(this.gettext('Bank account saved'));
          },
          (data: any) => this._error(data),
        );
      }

      _error(data: Record<string, string>) {
        Object.entries(data.data).forEach(([key, value]) =>
          this.GtUtils.notify(`${this.gettext(key)}: ${this.gettext(value)}`, 'error'),
        );
      }

      groupBankAccounts() {
        this.groupedBankAccounts = this.bankAccounts.reduce(
          (groupedBankAccounts: any, account: any) => {
            groupedBankAccounts[account.owner_name] = [
              ...(groupedBankAccounts[account.owner_name] || []),
              account,
            ];
            return groupedBankAccounts;
          },
          {},
        );
        Object.keys(this.groupedBankAccounts).forEach((key) => {
          this.groupedBankAccounts[key].sort((a: any, b: any) => {
            return a.index - b.index || a.currency_symbol.localeCompare(b.currency_symbol);
          });
        });
      }

      getTableOptions() {
        const options: any = {
          tableName: this.filterLevel,
          tableClass:
            'table-condensed main-table contract-charges-table table-hover table-with-inline-add',
          filterLevel: this.filterLevel,
          applyFilters: (params: any) => {
            this.gtFilterService.updateQueryParams(params.params, this.filterLevel);
          },
          templateArgs: {
            setHovering: (value: any) => this.setHovering(value),
            save: (item: any) => this.save(item),
            destroy: (item: any) => this.destroy({ item: item }),
            openBankAccountRestsModal: (item: any) => this.openBankAccountRestsModal(item),
            openBankAccountActivityModal: (item: any) => this.openBankAccountActivityModal(item),
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
          changePageSize: true,
        };

        options.columnDefs = [
          {
            title: this.gettext('currency'),
            columnName: 'currency',
            predicate: 'currency',
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'currency',
                label: this.gettext('Currency'),
                resource: 'finances.Currency',
              },
            ],
            cellTemplate: /*html*/ `
            <span class="pull-left" ng-if="!args.rows[item.id].editable">
              {[{item.currency_symbol}]}
            </span>
            <div ng-if="args.rows[item.id].editable">
              <gt-resource-select
                ng-model="item.currency"
                placeholder="'Currency'|translate"
                resource-name="'finances.Currency'"
                on-open-close="args.setHovering(isOpen)"
                title="item.currency_symbol"
              ></gt-resource-select>
            </div>
          `,
          },
          {
            title: this.gettext('account type'),
            columnName: 'account_type',
            predicate: 'account_type',
            class: 'td-left-align',
            filters: [
              {
                type: 'select',
                predicate: 'account_type',
                label: this.gettext('Type'),
                values: {
                  cash: this.gettext('Cash'),
                  regular: this.gettext('Regular'),
                  cryptocurrency: this.gettext('Cryptocurrency'),
                },
              },
            ],
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable"> {[{ item.account_type || "---" }]} </span>
            <span ng-if="args.rows[item.id].editable">
              <select class="form-control" ng-model="item.account_type">
                <option value="cash" ng-selected="true"><translate>Cash</translate></option>
                <option value="regular" ng-selected="true"><translate>Regular</translate></option>
                <option value="cryptocurrency" ng-selected="true">
                  <translate>Cryptocurrency</translate>
                </option>
              </select>
            </span>
          `,
          },
          {
            title: this.gettext('bank name'),
            columnName: 'bank_name',
            predicate: 'bank_name_list',
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'bank_name_list',
                label: this.gettext('Bank'),
                resource: 'finances.FinanceBank',
              },
            ],
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable">
              <a ui-sref="gt.bankAccount({id: item.id})" style="word-break: break-all">
                <i class="fa fa-bank"></i>
                <span> {[{ item.bank_name }]} </span>
              </a>
            </span>
            <span ng-if="args.rows[item.id].editable">
              <input
                class="form-control gt_test_field_number"
                type="text"
                placeholder="{[{ 'Bank name'|translate }]}"
                ng-model="item.bank_name"
              />
            </span>
          `,
          },
          {
            title: this.gettext('account name'),
            columnName: 'account_name',
            predicate: 'account_name',
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'payment_info',
                label: this.gettext('Payment info'),
                resource: 'finances.PaymentInfo',
              },
            ],
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable">
              <a ui-sref="gt.bankAccount({id: item.id})" style="word-break: break-all">
                <span> <i class="fa fa-briefcase"></i> {[{ item.account_name }]} </span>
              </a>
            </span>
            <span ng-if="args.rows[item.id].editable">
              <input
                class="form-control gt_test_field_number"
                type="text"
                placeholder="{[{ 'Account name'|translate }]}"
                ng-model="item.account_name"
              />
              <gt-resource-select
                ng-model="item.payment_info"
                placeholder="'Pick accounts'|translate"
                resource-name="'finances.paymentinfo'"
                allow-clear="true"
                query-params="{ client_role_name: 'owner' }"
              ></gt-resource-select>
            </span>
          `,
          },
          {
            title: this.gettext('owner name'),
            columnName: 'owner_name',
            predicate: 'owner_name',
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'client_role',
                label: this.gettext('Owner'),
                resource: 'clients.ClientRole',
              },
            ],
            cellTemplate: /*html*/ ' <span> {[{ item.owner_name }]} </span> ',
          },
          {
            title: this.gettext('status'),
            columnName: 'status',
            predicate: 'status',
            class: 'td-left-align',
            filters: [
              {
                type: 'select',
                predicate: 'status',
                label: this.gettext('Status'),
                values: {
                  valid: this.gettext('Valid'),
                  invalid: this.gettext('Invalid'),
                },
              },
            ],
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable">
              <span
                class="label"
                ng-class="{ 'label_danger': item.status == 'invalid', 'label_success': item.status == 'valid' }"
              >
                {[{ item.status || "---" }]}
              </span>
            </span>
            <span ng-if="args.rows[item.id].editable">
              <select class="form-control" ng-model="item.status">
                <option value="valid"><translate>Valid</translate></option>
                <option value="invalid"><translate>Invalid</translate></option>
              </select>
            </span>
          `,
          },

          {
            title: this.gettext('actual cash amount'),
            columnName: 'cash',
            predicate: 'cash',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-class="{'negative-number': item.cash < 0 }">
              {[{ item.cash || 0 | number:2 }]}
            </span>
          `,
          },
          {
            title: this.gettext('main'),
            columnName: 'is_main',
            predicate: 'is_main',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <input type="checkbox"
              class="formly-field-checkbox"
              ng-disabled = "!args.rows[item.id].editable"
              ng-model="item.is_main"
            />
          `,
          },
          {
            title: this.gettext('actual cash amount, $'),
            columnName: 'cash_usd',
            predicate: 'cash_usd',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.currency_symbol != 'USD'">
              $ {[{ item.cash_USD || 0 | number:2 }]}
            </span>
          `,
          },
          {
            title: this.gettext('initial cash amount'),
            columnName: 'initial_cash',
            predicate: 'initial_cash',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable && item.initial_cash">
              {[{ item.initial_cash || 0 | number:2 }]} {[{item.currency_symbol}]}
            </span>
            <span
              ng-if="args.rows[item.id].editable && $root.user.perms.indexOf('can_change_initial_amount') == -1"
            >
              <input
                class="form-control"
                type="number"
                required="required"
                placeholder="{[{ 'Initial cash'|translate }]}"
                ng-model="item.initial_cash"
              />
            </span>
          `,
          },
          {
            title: this.gettext('initial cash amount, $'),
            columnName: 'initial_cash_usd',
            predicate: 'initial_cash_usd',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable && item.initial_cash_USD">
              <span ng-if="item.currency_symbol != 'USD'" class="label-opacity">
                $ {[{ item.initial_cash_USD || 0 | number:2}]}
              </span>
            </span>
          `,
          },
          {
            title: this.gettext('initialization date'),
            columnName: 'initial_date',
            predicate: 'initial_date',
            filters: [
              {
                type: 'daterange',
                startDateField: 'start_date',
                endDateField: 'end_date',
              },
            ],
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="!args.rows[item.id].editable">
              <i class="fa fa-calendar"></i> {[{ item.initial_date || "---" | date:'dd.MM.yy'}]}
            </span>
            <span ng-if="args.rows[item.id].editable">
              <gt-date-select date-model="item.initial_date" pull-right="false"></gt-date-select>
            </span>
          `,
          },
          {
            title: this.gettext('last operation amount'),
            columnName: 'last_operaion_amount',
            predicate: 'last_operaion_amount',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span class="label-opacity"> {[{ item.last_operation.amount || 0 | number:2}]} </span>
          `,
          },
          {
            title: this.gettext('last operation type'),
            columnName: 'last_operaion_type',
            predicate: 'last_operaion_type',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span class="label-opacity"> {[{ item.last_operation.operation_type }]} </span>
          `,
          },
          {
            title: this.gettext('last operation date'),
            columnName: 'last_operaion_date',
            predicate: 'last_operaion_date',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span class="label-opacity"> {[{ item.last_operation.date | date:'dd.MM.yy' }]} </span>
          `,
          },
          {
            title: this.gettext('additional info'),
            columnName: 'additional_info',
            predicate: 'additional_info',
            class: 'td-right-align',
            cellTemplate: /*html*/ `
            <span class="label-opacity"> {[{ item.last_operation.additional_info }]} </span>
          `,
          },
          {
            title: this.gettext('business unit'),
            columnName: 'business_unit',
            predicate: 'business_unit',
            class: 'td-left-align',
            filters: [
              {
                type: 'ui-select',
                predicate: 'business_unit',
                label: this.gettext('Business unit'),
                resource: 'core.BusinessUnit',
              },
            ],
            cellTemplate: /*html*/ `
            <span class="pull-left" ng-if="!args.rows[item.id].editable">
              {[{item.business_unit}]}
            </span>
            <span ng-if="args.rows[item.id].editable">
              <gt-resource-select
                ng-model="item.business_unit"
                placeholder="'Business unit'|translate"
                resource-name="'core.businessunit'"
                allow-clear="true"
                query-params="{ user: $root.user.id }"
              ></gt-resource-select>
            </span>
          `,
          },

          {
            title: this.gettext('Actions'),
            columnName: 'actions',
            class: 'td-left-align no-blur',
            cellTemplate: /*html*/ `
            <div ng-if="!args.rows[item.id].editable">
                <a
                  class="btn btn-xs btn-blue-border"
                  ui-sref="gt.bankAccount({id: item.id})"
                >
                  <i class="fa fa-arrow-right"></i>
                </a>
                <a
                  permission
                  permission-only="'change_bankaccount'"
                  class="btn btn-xs btn-grey-border"
                  ng-click="args.rows[item.id].editable = true"
                >
                  <i class="fa fa-pencil-square"></i>
                </a>
                <a
                  permission
                  permission-only="'change_bankaccount'"
                  class="btn btn-xs btn-grey-border"
                  ng-click="args.openBankAccountRestsModal(item)"
                >
                  <i class="fa fa-list"></i>
                </a>
                <a
                  class="btn btn-xs btn-grey-border"
                  ng-click="args.openBankAccountActivityModal(item)"
                >
                  <i class="fa fa-credit-card"></i>
                </a>
              </div>
            </div>
            <div ng-if="args.rows[item.id].editable">
                  <a
                    class="btn btn-xs btn-success btn_success col-xs-12 hover-element"
                    ng-click="args.rows[item.id].editable = false; args.save(item)"
                  >
                    <i class="fa fa-floppy-o"></i>
                  </a>
                  <a
                    class="btn btn-xs col-xs-12 hover-element"
                    ng-click="args.rows[item.id].editable = false"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                  <a
                    class="btn btn-xs btn-danger btn_danger col-xs-12 hover-element"
                    ng-click="args.destroy(item)"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="alert alert-danger" ng-repeat="(key, value) in $ctrl.errors">
                  {[{ key }]}: {[{ value[0] }]}
                </div>
              </div>
            </div>
          `,
          },
        ];

        return options;
      }
    },
  ],
};
