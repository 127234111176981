import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('accounts.legacy').component('logentriesContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      view: '<?',
    },
    template: require('./logentries-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'AccountsService', 'gtFilterService', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    AccountsService: any,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.gridOptions = {};
    vm.logEntries = [];
    vm.logEntriesCount = 0;
    vm.updateLogentries = updateLogentries;
    vm.actionNames = {
      1: 'Add',
      2: 'Change',
      3: 'Delete',
    };

    vm.$onInit = function () {
      vm.view = vm.view || 'table';
      vm.filterLevel = vm.filterLevel || 'logentries-container';
      vm.initQueryParams = vm.initQueryParams || {};
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateLogentries();
      });
      updateLogentries();
    };

    ////////////////

    function updateLogentries() {
      GtUtils.overlay('show');
      return AccountsService.LogEntry.query(vm.queryParams, function (data: any) {
        vm.logEntries = data.results;
        vm.logEntriesCount = data.count;
        if (vm.view == 'grid') {
          updateGridData();
        }
        GtUtils.overlay('hide');
      }).$promise;
    }

    function updateGridData() {
      vm.gridOptions = {
        data: [],
        enableSorting: false,
        columnDefs: [
          {
            field: 'date',
            enableColumnMenu: false,
            enableSorting: false,
            cellFilter: 'date:" HH:mm dd/MM"',
            minWidth: 60,
          },
          {
            field: 'editor',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate:
              '<div class="user-avatar" ng-if="COL_FIELD.length != 0">\
                            <img ng-src="/pictures/{{COL_FIELD}}"/>\
                          </div>\
                          <div class="no-user-avatar" ng-if="COL_FIELD.length == 0">\
                            {{ row.entity.editorName | cut:true:1:" " }}\
                          </div>',
          },
          {
            field: 'object',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate:
              '<span class="tooltip" data-tip="{{COL_FIELD}}">\
                            {{COL_FIELD}}\
                          </span>',
          },
          {
            field: 'model',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate:
              '<span class="tooltip" data-tip="{{COL_FIELD}}">\
                            {{COL_FIELD}}\
                          </span>',
          },
          {
            field: 'action',
            displayName: gettext('type'),
            enableColumnMenu: false,
            enableSorting: false,
            cellClass: 'status-cell',
            cellTemplate:
              "<span class=\"label\" ng-class=\"{ 'label_success': row.entity.action=='Add'," +
              "'label_warning': row.entity.action=='Change'," +
              "'label_danger': row.entity.action=='Delete' }\">{{COL_FIELD}}</span>",
          },
        ],
      };
      vm.logEntries.forEach(function (logEntry: any) {
        vm.gridOptions.data.push({
          date: logEntry.action_time,
          editor: logEntry.author_avatar,
          editorName: logEntry.author_first_name,
          object: logEntry.object_repr,
          model: logEntry.model_name,
          action: vm.actionNames[logEntry.action_flag] || 'Unknown',
        });
      });
    }
  }
})();
