import ng from 'angular';

import { CustomsCargoDeclarationInvoicePositionListContainer } from './customs-cargo-declaration-invoice-position-list-container/customs-cargo-declaration-invoice-position-list-container.component';
import { CustomsCargoDeclarationInvoicePositionListTableView } from './customs-cargo-declaration-invoice-position-list-table-view/customs-cargo-declaration-invoice-position-list-table-view.component';
import { CustomsCargoDeclarationInvoicePositionService } from './customs-cargo-declaration-invoice-position.service';

export const customsCargoDeclarationInvoicePosition = ng
  .module('execution.vessels.voyage.customsCargoDeclarationInvoicePosition', [])
  .component(
    'customsCargoDeclarationInvoicePositionListContainer',
    CustomsCargoDeclarationInvoicePositionListContainer,
  )
  .component(
    'customsCargoDeclarationInvoicePositionListTableView',
    CustomsCargoDeclarationInvoicePositionListTableView,
  )
  .service(
    'CustomsCargoDeclarationInvoicePositionService',
    CustomsCargoDeclarationInvoicePositionService,
  ).name;
