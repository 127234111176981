import React from 'react';

import { Link } from '~/shared/lib/router';

export const CounterpartyExecutionPage: React.FC = () => {
  return (
    <div>
      <h1>Counterparty Execution</h1>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};
