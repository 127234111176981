import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './invoice-list-container.html?raw';

export const InvoicesListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    quickAdd: '=?',
    addButton: '<?',
    createPlanButton: '@?',
    addInvoiceButton: '<?',
    addInvoiceButtonFunc: '&?',
    showPaymentsExportButton: '@?',
    view: '<?',
    simplifiedView: '<?',
    showTotals: '<?',
    readonly: '<',
    reportConfigFilterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$scope',
    '$window',
    '$filter',
    '$q',
    'GtUtils',
    'FinancesService',
    'gtFilterService',
    'DocumentsService',
    'CoreService',
    'ClientsService',
    'AccountsService',
    'gettext',
    class {
      $filter: ng.IFilterService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $q: ng.IQService;
      $window: ng.IWindowService;
      AccountsService: any;
      ClientsService: any;
      CoreService: CoreService;
      DocumentsService: any;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      activeFilterPresets: any;
      addButton: any;
      addInvoiceButton: any;
      addInvoiceButtonFunc: any;
      approvals: any;
      createPlanButton: any;
      filterLevel = 'invoices-list-container';
      finances: any;
      financesCount: number;
      financesTotal: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      inlineOrderingParams: any;
      newFinance: any;
      paidInvoicesListener: any;
      paymentsExportConfig: any;
      queryParams: QueryParams = {};
      quickAdd: any;
      reportConfig: any;
      reportConfigFilterLevel = 'invoices-list-container';
      savedFilterChoices: any;
      savedReportConfigs: any;
      selectToApprove: any;
      selectToDocument: any;
      selectToPay: any;
      selectToPayment: any;
      selectedFinances: any;
      showPaymentsExportButton: any;
      timelineChartItems: any;
      user: any;
      view: any;
      constructor(
        $rootScope: GtRootScopeService,
        $scope: ng.IScope,
        $window: ng.IWindowService,
        $filter: ng.IFilterService,
        $q: ng.IQService,
        GtUtils: GtUtilsService,
        FinancesService: FinancesService,
        gtFilterService: GtFilterService,
        DocumentsService: any,
        CoreService: CoreService,
        ClientsService: any,
        AccountsService: any,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$window = $window;
        this.$filter = $filter;
        this.$q = $q;
        this.GtUtils = GtUtils;
        this.FinancesService = FinancesService;
        this.gtFilterService = gtFilterService;
        this.DocumentsService = DocumentsService;
        this.CoreService = CoreService;
        this.ClientsService = ClientsService;
        this.AccountsService = AccountsService;
        this.gettext = gettext;

        this.quickAdd = false;
        this.selectToPayment = false;
        this.selectToDocument = false;
        this.selectToApprove = false;
        this.selectToPay = false;
        this.addButton = false;
        this.createPlanButton = null;
        this.addInvoiceButton = false;
        this.addInvoiceButtonFunc = null;
        this.showPaymentsExportButton = false;
        this.activeFilterPresets = [];
        this.inlineOrderingParams = [];
        this.selectedFinances = [];

        this.finances = [];
        this.financesCount = 0;
        this.newFinance = {};
        this.financesTotal = {};
        this.user = {};
        this.approvals = {};
        this.addInvoiceButton = true;
        this.paymentsExportConfig = '';
        this.reportConfig = null;
        this.timelineChartItems = [];
      }

      $onInit() {
        if (this.$rootScope.isDeviceMobile) {
          this.view = 'block';
        } else {
          this.view = ['list', 'block'].includes(this.$rootScope.user.profile.invoices_view)
            ? this.$rootScope.user.profile.invoices_view
            : (this.initQueryParams.view ?? 'table');
          delete this.initQueryParams.view;
        }

        this.queryParams = {
          serializer: 'table_info',
          ...this.initQueryParams,
        };

        this.paymentsExportConfig = this.FinancesService.getPaymentsExportConfig();

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.selectedFinances = this.saveSelectedFinances();
          this.queryParams = data;
          this.updateFinances();
          this.updateOrdering();
        });

        this.$rootScope.$on(
          `gt-report-config-selected_${this.filterLevel}`,
          (event: any, config: any) => {
            this.reportConfig = config ? config.column_params : null;
          },
        );

        this.$rootScope.$on(
          `gt-report-config-created_${this.filterLevel}`,
          (event: any, config: any) => {
            this.savedReportConfigs = [...this.savedReportConfigs, config];
          },
        );

        this.$rootScope.$on(
          `gt-report-config-updated_${this.filterLevel}`,
          (event: any, config: any) => {
            const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
            this.savedReportConfigs[index] = config;
          },
        );

        this.paidInvoicesListener = this.$rootScope.$on(
          'gt-invoices-paid',
          (ev: any, finances: any) => {
            this.onPayInvoices(finances);
            this.updateFinances();
            this.updateOrdering();
          },
        );

        this.$rootScope.$on('gt-invoices-approved', (ev: any, finances: any) => {
          this.onApproveInvoices(finances);
          this.updateFinances();
          this.updateOrdering();
        });

        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
        this.savedReportConfigs = this.CoreService.getSavedReportConfigs(
          this.reportConfigFilterLevel || this.filterLevel,
        );
        this.addInvoiceButtonFunc = this.addInvoiceButtonFunc || this.openFinanceModal;
      }

      $onChanges() {
        this.queryParams = {
          serializer: 'table_info',
          ...this.initQueryParams,
        };
      }

      $onDestroy() {
        this.paidInvoicesListener();
      }

      updateOrdering() {
        this.inlineOrderingParams = [
          { title: 'Issuance date', value: 'date' },
          { title: 'Date of receiving', value: 'date_of_receiving' },
          { title: 'Date of payment (plan)', value: 'date_of_execution' },
        ];
      }

      getIcon(resourceName: any) {
        return this.GtUtils.getIcon(resourceName);
      }

      applyFilters(params: any) {
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      saveSelectedFinances() {
        return this.finances.filter((finance: any) => finance.$_selected);
      }

      updateFinances() {
        this.GtUtils.overlay('show');
        this.newFinance = {
          condition: 'prepay',
          use: 'cargo',
          ...this.queryParams,
        };
        return this.FinancesService.Finance.tabInfo(this.queryParams, (data: any) => {
          if (this.queryParams.next) {
            this.finances = this.finances.concat(data.results);
            delete this.queryParams.next;
          } else {
            this.finances = data.results;
            this.setSelectedFinancesFromState();
          }
          this.timelineChartItems = data.results
            .filter(
              (item: { date: any; date_of_execution: any }) => item.date || item.date_of_execution,
            )
            .map(
              (item: {
                id: { toString: () => any };
                date: any;
                date_of_execution: any;
                number: { toString: () => any };
                clientrole_from_name: any;
                clientrole_to_name: any;
              }) => ({
                id: item.id.toString(),
                start: item.date || item.date_of_execution,
                end: item.date_of_execution || item.date,
                name: item.number.toString(),
                popupHtml: `From: ${String(item.clientrole_from_name)}<br />
                To: ${String(item.clientrole_to_name)}<br />
                <span><a href="#/payment/${String(item.id)}/"
                target="_blank">
                Details <i class="fa fa-arrow-right"></i></a></span> `,
              }),
            );
          this.financesCount = data.count;
          this.GtUtils.overlay('hide');
          return this.FinancesService.Finance.totalsInfo(this.queryParams, (data: any) => {
            this.financesTotal = data;
            this.newFinance = {
              condition: 'prepay',
              use: 'cargo',
              client_role_model: 'buyer',
              ...this.queryParams,
            };
          }).$promise;
        }).$promise;
      }

      updateInvoicesDates(data: any[]) {
        this.GtUtils.overlay('show');
        let chain = this.$q.when();

        data.forEach((item: { id: { toString: () => any }; start: any; end: any }) => {
          const finance = this.finances.find(
            (f: { id: { toString: () => any } }) => f.id.toString() === item.id.toString(),
          );

          if (finance) {
            finance.date = item.start;
            finance.date_of_execution = item.end;

            chain = chain.then(() => this.FinancesService.updateInvoice(finance));
          }
        });

        chain.then(() => {
          this.GtUtils.overlay('hide');
          this.updateFinances();
        });
      }

      setSelectedFinancesFromState() {
        this.finances.forEach((finance: any) => {
          finance.$_selected = this.selectedFinances.includes(finance);
        });
      }

      createPaymentPlan(finances: any, paymentPlanId: any) {
        this.selectToPayment = false;
        if (!finances) {
          return false;
        }
        if (!finances.length) {
          return this.GtUtils.notify(
            this.GtUtils.translate(this.gettext('Empty finances list')),
            'warning',
          );
        }

        const paymentPlan = { id: paymentPlanId, finances: [] as any[] };

        this.finances = this.updateFinancesFromSelectedList(finances, this.selectedFinances);

        this.finances
          .filter((finance: any) => finance.$_selected && finance.$_inputedNumber != 0)
          .forEach((finance: any) => {
            paymentPlan.finances.push({
              finance: finance.id,
              value: finance.$_inputedNumber,
              finance_number: finance.number,
              to_pay: finance.to_pay,
              additional_info: finance.additional_info,
              clientrole_from_name: finance.clientrole_from_name,
              clientrole_from_role: finance.clientrole_from_role,
              clientrole_from: finance.clientrole_from,
              clientrole_to_name: finance.clientrole_to_name,
              clientrole_to_role: finance.clientrole_to_role,
              clientrole_to: finance.clientrole_to,
              isOverpaid: finance.fact_amount > finance.amount,
              currency_symbol: finance.currency_symbol,
              invoicepositions:
                finance.invoicepositions_set?.map((position: any) => {
                  return {
                    quantity: position.quantity_sum,
                    subuses: position.subuses,
                    use: position.use,
                  };
                }) || [],
            });
          });

        this.selectedFinances = [];

        return this.FinancesService.paymentPlanModal(paymentPlan).then(() => this.updateFinances());
      }

      updateFinancesFromSelectedList(finances: any, selectedFinances: any) {
        selectedFinances.forEach((selectedFinance: any) => {
          const financeExists = finances.find((finance: any) => finance.id === selectedFinance.id);

          if (!financeExists) {
            finances.push(selectedFinance);
          }
        });

        return finances;
      }

      createDocument(finances: any) {
        this.selectToDocument = false;
        if (!finances.length) {
          return this.GtUtils.notify(
            this.GtUtils.translate(this.gettext('Empty finances list')),
            'warning',
          );
        }
        const objectId = finances?.[0];
        return this.DocumentsService.generateDocxModal('Finance', objectId, {
          objects_id: finances,
        });
      }

      openFinanceModal = (finance: any, data: any) => {
        return this.FinancesService.financeModal(finance, data).then((data: any) => {
          if (this.selectToPay || this.selectToPayment || this.selectToApprove) {
            if (data !== 'cancel') {
              this.updateFinances();
            }
          } else {
            this.updateFinances();
          }
        });
      };

      openDocumentModal(finance: any) {
        return this.DocumentsService.documentListModal({
          content_type: finance.content_type,
          object_id: finance.id,
        }).then(() => this.updateFinances());
      }

      openPaymentPlanModal(paymentPlan: any) {
        return this.FinancesService.paymentPlanModal(paymentPlan);
      }

      openDocxModal(finance: any) {
        return this.DocumentsService.generateDocxModal('Finance', finance.id);
      }

      openRoleModal(roleId: any, roleModel: any) {
        return this.ClientsService.roleModal({ id: roleId, model_name: roleModel });
      }

      clone(finance: any) {
        if (!confirm(this.gettext('Do you want to clone this Invoice?'))) {
          return false;
        }
        return this.FinancesService.cloneInvoice(finance).then(() => this.updateFinances());
      }

      isGreen(finance: any) {
        if (finance.invoice_position_sum == 0 && finance.invoiced_logistics == 0) {
          return true;
        }

        return (
          finance.invoice_position_sum <= finance.invoiced_logistics + 0.05 &&
          finance.invoice_position_sum >= finance.invoiced_logistics - 0.05
        );
      }

      addTableOptions(options: any) {
        this.activeFilterPresets = options.activeFilterPresets;
      }

      cancelSelection() {
        this.selectToPayment = false;
        this.selectToDocument = false;
        this.selectToApprove = false;
        this.selectToPay = false;
      }

      isCreatePaymentPlanShown() {
        return (
          this.financesCount &&
          this.createPlanButton &&
          this.view === 'table' &&
          this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_paymentplans
        );
      }

      onApproveInvoices(finances: any) {
        this.cancelSelection();
        finances.forEach((finance: any) => {
          this.AccountsService.voteApprovable('approve', finance.id, finance.content_type);
        });
      }

      onPayInvoices(finances = [] as any[]) {
        this.cancelSelection();

        finances.forEach((finance) => {
          if (finance.currency_symbol === 'USD') {
            this.payInvoice({
              amount: finance.$_inputedNumber,
              date: new Date(),
              finance: finance.id,
            });
          } else {
            this.FinancesService.getLatestExchangeObject(finance.currency)
              .then((exchange: any) => {
                const financeToCreate = {
                  amount: finance.$_inputedNumber,
                  date: new Date(),
                  finance: finance.id,
                  currency_exchange: exchange.id,
                };

                this.payInvoice(financeToCreate);
              })
              .catch((error: any) => {
                this.GtUtils.errorClb(error);
              });
          }
        });
      }

      payInvoice(finance: any) {
        this.FinancesService.Payment.save(finance)
          .$promise.then(() => {
            this.GtUtils.notify(this.gettext('Payment successfully created'));
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error);
          });
      }
    },
  ],
};
