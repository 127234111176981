import { of, switchMap } from 'rxjs';

import { singleton } from '~/shared/lib/di';
import { BehaviorSubject } from '~/shared/lib/state';

import { GlobalDialogConfig } from '../lib/types';

@singleton()
export class GlobalDialogStore {
  private readonly dialogOpenSubj = new BehaviorSubject<{ value: boolean; id?: string }>({
    value: false,
    id: undefined,
  });
  private readonly registeredConfigs = new Map<string, GlobalDialogConfig>();

  readonly dialogOpen$ = this.dialogOpenSubj.asObservable();

  readonly currentDialogConfig$ = this.dialogOpen$.pipe(
    switchMap(({ id }) => {
      if (!id) {
        return of(null);
      }
      const config = this.registeredConfigs.get(id);
      return of(config ?? null);
    }),
  );

  dialogToggled(args: { value: boolean; id?: string }) {
    this.dialogOpenSubj.next(args);
  }

  dialogConfigRegistered(config: GlobalDialogConfig) {
    this.registeredConfigs.set(config.id ?? 'default', config);
  }
}
