import React from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

import { type IconComponent, type IconName, useAllIcons, useIcon } from '../../icons';

const IconButton: React.FC<{
  Icon: IconComponent;
  iconName: IconName;
  onSelect?: (icon: IconName) => void;
}> = ({ Icon, iconName, onSelect }) => {
  return (
    <button
      key={iconName}
      className="flex cursor-pointer items-center gap-2 p-2 transition duration-300 ease-in-out hover:bg-transparent-light"
      onClick={() => onSelect?.(iconName)}
    >
      <Icon size={14} className="text-text-main-secondary" />
    </button>
  );
};

export const IconSelector: React.FC<{
  selectedIcon: IconName;
  onSelect?: (icon: IconName) => void;
}> = ({ selectedIcon, onSelect }) => {
  const allIcons = useAllIcons();
  const SelectedIcon = useIcon(selectedIcon);

  return (
    <Popover>
      <PopoverTrigger className="h-8 rounded-sm border border-stroke-main-medium px-2">
        <SelectedIcon size={16} className="text-text-main-secondary" />
      </PopoverTrigger>
      <PopoverContent className="w-[200px]">
        <div className="flex flex-wrap" data-testid="icons-list">
          {Object.entries(allIcons).map(([iconName, icon]) => (
            <IconButton
              key={iconName}
              Icon={icon}
              iconName={iconName as IconName}
              onSelect={onSelect}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
