import React from 'react';

import { ChevronLeftIcon, DetailsIcon } from '~/shared/ui/icons';
import {
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';

import type { ReorderedColumn } from '../../types';
import { DndColumnsList } from '../dnd-columns-list';

export const Properties = ({
  columns,
  columnsReordered,
  columnVisibilityChanged,
}: {
  columns: ReorderedColumn[];
  columnsReordered: (columns: ReorderedColumn[]) => void;
  columnVisibilityChanged: (column: ReorderedColumn) => void;
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState<boolean>(false);

  return (
    <DropdownMenuSub open={isSubMenuOpen} onOpenChange={setIsSubMenuOpen}>
      <DropdownMenuSubTrigger className="p-0">
        <div className="flex items-center gap-2">
          <DetailsIcon size={14} className="text-text-main-tertiary" />
          <p className="text-xs text-text-main-primary">Properties</p>
        </div>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent
          className="relative flex w-[296px] flex-col gap-0.5 p-0"
          sideOffset={10}
          alignOffset={0}
        >
          <div className="flex h-8 items-center gap-2 p-1">
            <ChevronLeftIcon size={16} className="text-text-main-tertiary" />
            <p className="text-xs font-medium text-text-main-primary">Properties</p>
          </div>
          <DropdownMenuSeparator className="bg-background-main-tertiary" />
          <Input intent="transparent" placeholder="Search" fullWidth />
          <DropdownMenuSeparator className="bg-background-main-tertiary" />
          <DndColumnsList
            columns={columns}
            onChange={columnsReordered}
            visibilityChange={columnVisibilityChanged}
          />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
