import React from 'react';

import type { IconComponent } from '~/shared/ui/icons';
import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import { ChoiceMenuContent } from './choice-menu-content';
import type { ChoiceMenu as ChoiceMenuDef, ViewConfigChanged } from '../../types';

export const ChoiceMenu: React.FC<{
  viewId: number;
  title: string;
  data: ChoiceMenuDef;
  Icon: IconComponent;
  viewConfigChanged: ViewConfigChanged;
}> = ({ viewId, title, data, Icon, viewConfigChanged }) => {
  return (
    <DropdownMenuSub>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Icon size={16} className="text-stroke-main-stronger" />
          <p className="text-xs text-text-main-primary">{title}</p>
        </div>
        <DropdownMenuSubTrigger className="p-0">
          <p className="cursor-pointer text-xs capitalize text-text-main-tertiary">
            {data.selected}
          </p>
        </DropdownMenuSubTrigger>
      </div>
      <DropdownMenuPortal>
        <DropdownMenuSubContent
          className="flex w-[200px] flex-col gap-0.5 p-1"
          sideOffset={10}
          alignOffset={0}
        >
          <ChoiceMenuContent viewId={viewId} data={data} viewConfigChanged={viewConfigChanged} />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
