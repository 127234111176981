import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('updatesTableContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      view: '<?',
      onChange: '&?',
      updateFlag: '<?',
      isDetailPage: '<?',
    },
    template: require('./updates-table-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'GtUtils',
    'ClientsService',
    'gtFilterService',
    'gettext',
    'PageService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ClientsService: any,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    PageService: PageService,
  ) {
    const vm = this;

    vm.gridOptions = {};
    vm.clientUpdates = [];
    vm.clientUpdatesCount = 0;
    vm.updateClientUpdates = updateClientUpdates;
    vm.updateClientUpdate = updateClientUpdate;
    vm.deleteClientUpdate = deleteClientUpdate;
    vm.applyFilters = applyFilters;
    vm.onUpdate = onUpdate;
    vm.goToObject = goToObject;
    vm.dropdownIsOpen = false;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'updates-table-container';
      vm.initQueryParams = vm.initQueryParams || {};
      vm.queryParams = { ...vm.initQueryParams };
      vm.view = vm.view || 'table';
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateClientUpdates();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$watch('vm.updateFlag', (newValue: any, oldValue: any) => {
        if (oldValue === newValue) {
          return;
        }
        updateClientUpdates();
      });
    };

    ////////////////

    function updateClientUpdates() {
      GtUtils.overlay('show');
      return ClientsService.ClientUpdate.listInfo(vm.queryParams, function (data: any) {
        PageService.updatePageCounters({
          queryParams: vm.queryParams,
          model: 'clients.clientupdate',
        });
        vm.clientUpdates = data.results;
        vm.clientUpdatesCount = data.count;
        GtUtils.overlay('hide');
        if (vm.view === 'grid') {
          updateGridData();
        }

        if (vm.isDetailPage) {
          const sequenceOrderCounts = vm.clientUpdates.reduce((acc: any, update: any) => {
            acc[update.sequence_order] = (acc[update.sequence_order] || 0) + 1;
            return acc;
          }, {});

          // @ts-ignore
          const duplicateFound = Object.values(sequenceOrderCounts).some((count) => count > 1);

          if (duplicateFound) {
            alert(gettext('Warning: More than one task has the same sequence order.'));
          }
        }
      }).$promise;
    }

    function applyFilters(this: any, params: any) {
      gtFilterService.updateQueryParams(params, this.filterLevel);
    }

    function deleteClientUpdate(update: any) {
      if (!confirm(gettext('Are you sure?'))) {
        return;
      }
      ClientsService.ClientUpdate.delete({ id: update.id }, function () {
        vm.onChange();
        updateClientUpdates();
      });
    }

    function updateClientUpdate(update: any) {
      update._edit = false;
      return ClientsService.ClientUpdate.update(update, function () {
        vm.onChange();
        updateClientUpdates();
      });
    }

    function onUpdate() {
      updateClientUpdates();
      vm.onChange();
    }

    function goToObject(contentType: any, objectId: any, tab: any) {
      return GtUtils.goDetails(contentType, objectId, tab);
    }

    function updateGridData() {
      vm.gridOptions = {
        data: [],
        enableSorting: false,
        columnDefs: [
          {
            field: 'date',
            enableColumnMenu: false,
            enableSorting: false,
            cellFilter: 'date:" HH:mm dd/MM"',
            minWidth: 60,
          },
          {
            field: 'editor',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: /*html*/ `<div class="user-avatar" ng-if="COL_FIELD.length != 0">
                <img ng-src="/pictures/{{COL_FIELD}}" />
              </div>
              <div class="no-user-avatar" ng-if="COL_FIELD.length == 0">
                {{ row.entity.editorName | cut:true:1:" " }}
              </div>`,
          },
          {
            field: 'comments',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: /*html*/ `<span class="tooltip" data-tip="{{COL_FIELD}}">
              {{COL_FIELD}}
            </span>`,
          },
          {
            field: 'type',
            displayName: gettext('type'),
            enableColumnMenu: false,
            enableSorting: false,
            cellClass: 'status-cell',
            cellTemplate: /*html*/ `<span
              class="label"
              ng-class="{ 'label_success': row.entity.type=='call',
              'label_warning': row.entity.type=='email',
              'label_calculate': row.entity.type=='task',
              'label_info': row.entity.type=='meet',
              'label_default': row.entity.type=='commentary' }"
              >{{COL_FIELD}}</span
            >`,
          },
        ],
      };
      vm.clientUpdates.forEach(function (update: any) {
        vm.gridOptions.data.push({
          date: update.update_time,
          editor: update.editor_avatar,
          editorName: update.editor_first_name,
          comments: update.update,
          type: update.message_type,
          status: update.contact_status,
          future_contact: update.future_contact,
        });
      });
    }
  }
})();
