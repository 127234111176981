import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { MultipassportListContainer } from './components/multipassport-list-container/multipassport-list-container.component';
import { MultipassportListPage } from './components/multipassport-list-page/multipassport-list-page.component';
import { MultipassportListTableView } from './components/multipassport-list-table-view/multipassport-list-table-view.component';
import { MultipassportModal } from './components/multipassport-modal/multipassport-modal.component';
import { MultipassportService } from './multipassport.service';

export const multipassport = ng
  .module('passports.multipassport', [])
  .service('MultipassportService', MultipassportService)
  .component('multipassportListPage', MultipassportListPage)
  .component('multipassportListContainer', MultipassportListContainer)
  .component('multipassportListTableView', MultipassportListTableView)
  .component('multipassportModal', MultipassportModal)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('abstract-passports.multipassports', {
        url: '/multipassport/list/',
        component: 'multipassportListPage',
        data: {
          pageTitle: gettext('Multipassports'),
        },
        resolve: {
          filterLevel: () => {
            return 'multipassport-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('multipassport-list-page');
            },
          ],
        },
      });
    },
  ])
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('gt.multipassport', {
        url: '/multipassport/:id',
        component: 'multipassportPageContainer',
        data: {
          pageTitle: gettext('Multipassport'),
        },
      });
    },
  ]).name;
