import ng from 'angular';

import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('company.emailTemplates').component('emailtemplateContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./emailtemplate-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'EmailTemplates', 'GtUtils', 'gettext'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    EmailTemplates: any,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.emailTemplates = [];
    vm.emailTemplatesCount = 0;
    vm.openEmailTemplateModal = openEmailTemplateModal;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'emailtemplate-container';
      vm.queryParams = { ...vm.initQueryParams };
      updateData();
      $scope.$on('emailtemplates-list__remove', function (ev: any, data: any) {
        remove(data);
      });
      $scope.$on('emailtemplates-list__update', function () {
        updateData();
      });
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateData();
      });
    };

    ////////////////

    function updateData() {
      return EmailTemplates.EmailTemplate.query(vm.queryParams, function (data: any) {
        vm.emailTemplates = data.results;
        ng.forEach(vm.emailTemplates, function (template: any) {
          template.icon = GtUtils.getIcon('clients.' + template.model_type);
        });
        vm.emailTemplatesCount = data.count;
      }).$promise;
    }

    function remove(emailtemplate: any) {
      return EmailTemplates.EmailTemplate.delete(
        {
          id: emailtemplate.id,
        },
        function () {
          GtUtils.notify(gettext('Template removed'));
          return updateData();
        },
      ).$promise;
    }

    function openEmailTemplateModal() {
      return EmailTemplates.emailTemplateModal().then(updateData);
    }
  }
})();
