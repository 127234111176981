import type ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';

import template from './client-list-page.html?raw';

export const ClientListPage = {
  template,
  bindings: {
    $transition$: '<',
    queryParams: '<?',
    filterLevel: '<?',
  },
  controller: [
    '$rootScope',
    'PageService',
    'gtFilterService',
    'ClientsService',
    'ClientFilterConfigService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      ClientFilterConfigService: any;
      ClientsService: any;
      GtUtils: GtUtilsService;
      PageService: PageService;
      filterLevel = 'client-list-page';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        $rootScope: GtRootScopeService,
        PageService: PageService,
        gtFilterService: GtFilterService,
        ClientsService: any,
        ClientFilterConfigService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$rootScope = $rootScope;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.ClientsService = ClientsService;
        this.ClientFilterConfigService = ClientFilterConfigService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
        this.gtFilterService.subscribe(this.filterLevel, (qp: Record<string, unknown>) =>
          Object.assign(this.queryParams, qp),
        );
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-crm-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
        };

        // @ts-ignore
        config.filters = this.ClientFilterConfigService.getFilterConfig(this.filterLevel);

        // @ts-ignore
        config.pages = this.ClientsService.getPagesConfig();
        return config;
      }
    },
  ],
};
