import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('signingsContractsListContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
    },
    template: require('./signings-contracts-list-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'ContractsService',
    'ClientsService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    ContractsService: ContractsService,
    ClientsService: any,
  ) {
    const vm = this;
    vm.openRoleModal = openRoleModal;
    vm.toggleFullTable = toggleFullTable;
    vm.applyFilters = applyFilters;
    vm.showFullTable = false;
    vm.contracts = [];

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'signings-contracts-list-container';
      vm.queryParams = {
        stage: 'contract',
        serializer: 'signings_report',
        ordering: '-conclusion_date',
        ...vm.initQueryParams,
      };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateContracts();
      });
      updateContracts();
    };

    function toggleFullTable() {
      vm.showFullTable = !vm.showFullTable;
      updateContracts();
    }

    ////////////////

    function applyFilters(params: any) {
      gtFilterService.updateQueryParams(params, vm.filterLevel);
    }

    function updateContracts() {
      GtUtils.overlay('show');
      return ContractsService.Contract.query(vm.queryParams, function (data: any) {
        vm.contracts = data.results;
        vm.contractsCount = data.count;
        GtUtils.overlay('hide');
      });
    }

    function openRoleModal(id: any, model: any) {
      return ClientsService.roleModal({
        id: id,
        model_name: model,
      }).$promise.then(updateContracts);
    }
  }
})();
