import React from 'react';

import { CloseIcon, ThreeDotsIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { FilterOption, FilterValue } from '../../types';
import { DynamicFilterControl } from '../filters-select';

export const FilterItemChanged: React.FC<{
  value: FilterValue;
  option: FilterOption;
  onDelete: (filter: FilterValue) => void;
  onChange: (filter: FilterValue) => void;
}> = ({ value, onDelete, option, onChange }) => {
  return (
    <div className="flex h-6 w-max items-center gap-1 rounded-sm border border-stroke-section-light bg-background-section-extraLight px-1">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex items-center gap-1">
            <p className="text-xs font-medium capitalize text-text-section-secondary">
              {value.argument}
            </p>
            <p className="text-xs font-medium text-text-section-secondary">{value.operator}</p>
            <p className="text-xs text-text-section-secondary">{value.values.join(', ')}</p>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[200px]">
          <div className="flex items-center justify-between border-b border-stroke-main-light px-2 py-1">
            <p className="text-xs font-medium text-text-main-primary">{option.title}</p>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger displayChevron={false}>
                <ThreeDotsIcon
                  size={14}
                  className="text-stroke-main-strongest"
                  data-testid="inactive-filter-add-actions"
                />
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent sideOffset={10}>
                <button
                  className="px-1 text-xs text-text-additional-danger"
                  onClick={() => onDelete(value)}
                >
                  Delete Filter
                </button>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </div>
          <div className="flex w-full flex-col gap-0.5 p-1">
            <DynamicFilterControl option={option} value={value} onChange={onChange} />
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <button onClick={() => onDelete(value)}>
        <CloseIcon size={14} className="text-stroke-section-stronger" data-testid="delete-filter" />
      </button>
    </div>
  );
};
