import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './consolidated-month-accounts-container.html?raw';

export const ConsolidatedMonthAccountsContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
    applyFilters: '&',
    dropQueryParams: '&',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gtFilterService',
    'StockExchangesService',
    'ConsolidatedPnlService',
    class {
      $scope: ng.IScope;
      ConsolidatedPnlService: any;
      GtUtils: GtUtilsService;
      applyFilters: any;
      data: any;
      detailsConf: any;
      dropQueryParams: any;
      filterLevel = '';
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      monthList: any;
      queryParams: QueryParams = {};
      stockExchangesService: any;
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gtFilterService: GtFilterService,
        StockExchangesService: any,
        ConsolidatedPnlService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gtFilterService = gtFilterService;
        this.stockExchangesService = StockExchangesService;
        this.ConsolidatedPnlService = ConsolidatedPnlService;
        this.data = {};
        this.monthList = [];
        this.detailsConf = {};
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'consolidated-month-accounts-container';
        this.queryParams = { ...this.initQueryParams, page_size: '999' };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.data = this.stockExchangesService
          .getAccountBalanceByMonth(this.queryParams)
          .then((data: any) => {
            this.data = data;
            this.data.forEach((account: any) => {
              account.data?.forEach((month: any) => {
                account[month.month_key] = month;
              });
            });
            this.monthList = this.stockExchangesService.getDateRange(
              this.queryParams.start_date,
              this.queryParams.end_date,
            );
            this.GtUtils.overlay('hide');
          });
      }

      showDetails(accountId: any, month: any) {
        this.applyFilters({
          params: {
            derivative_account_list: [accountId],
            match_month: month.monthKey,
            match_year: month.yearKey,
          },
        });
      }

      hideDetails() {
        this.dropQueryParams({ params: ['derivative_account_list', 'match_month'] });
      }
    },
  ],
};
