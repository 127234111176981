import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('company.documents.legacy').component('documentsContainer', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      addButton: '@?',
      mode: '<?',
      canApprove: '<?',
      contractType: '<?',
      onUpdate: '&?',
      emitHovering: '&?',
      deletionSource: '<?',
      downloadAll: '<?',
      readOnly: '<?',
    },
    template: require('./documents-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$rootScope',
    'DocumentsService',
    'GtUtils',
    'gtFilterService',
    'gettext',
    'CoreService',
    'PageService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    DocumentsService: any,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    CoreService: CoreService,
    PageService: PageService,
  ) {
    const vm = this;
    vm.documents = [];
    vm.documentsCount = 0;
    vm.queryParams = {};
    vm.openDocumentModal = openDocumentModal;
    vm.documentsBulkDownload = documentsBulkDownload;
    vm.updateData = updateData;
    vm.savedFilterChoices = [];
    vm.setHovering = setHovering;
    vm.hovering = false;
    vm.deletionSource = null;
    vm.addButton = false;
    vm.downloadAll = true;
    vm.readOnly = false;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'documents-list-container';
      vm.emitHovering = vm.emitHovering || (() => {});
      vm.queryParams = { ...vm.initQueryParams };
      vm.savedFilterChoices = CoreService.getSavedFilterChoices(vm.filterLevel);
      const eventListener = !vm.deletionSource
        ? 'documents-list__remove'
        : `documents-list__remove-from-${vm.deletionSource}`;
      $rootScope.$on(eventListener, function (ev: any, data: any) {
        CoreService.confirmDeletionModal(DocumentsService.Document, data.id).then((data: any) => {
          if (data == 'delete') {
            $rootScope.$broadcast('update-basis-doc');
            GtUtils.notify(gettext('Doc removed'));
            return updateData();
          }
        });
      });
      $scope.$on('documents-list__update', function () {
        updateData();
      });
      $scope.$on('documents-list__partial-update', function (ev: any, data: any) {
        return DocumentsService.Document.patch(
          {
            id: data.id,
            status: data.status,
          },
          function () {
            GtUtils.notify(gettext('Status updated'));
            return updateData();
          },
        ).$promise;
      });
      $scope.$on('gt-filter-updated_' + vm.filterLevel, (ev: any, data: any) => {
        vm.queryParams = data;
        updateData();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    };

    vm.$onChanges = function (changes: any) {
      if (changes.initQueryParams) {
        vm.queryParams = { ...vm.queryParams, ...vm.initQueryParams };
      }
    };

    ////////////////

    function setHovering(hovering: any) {
      vm.hovering = hovering;
      vm.emitHovering({ value: hovering });
    }

    function updateData() {
      return DocumentsService.queryDocuments(vm.queryParams).then(function (data: any) {
        PageService.updatePageCounters({
          queryParams: vm.queryParams,
          model: 'documents.document',
        });
        $scope.$emit('documents-container__updated', data);
        vm.documents = data.results;
        vm.documentsCount = data.count;
      });
    }

    function openDocumentModal() {
      return DocumentsService.documentModal(
        vm.mode,
        vm.queryParams.object_id,
        vm.queryParams.content_type,
      ).then(() => {
        updateData();
        $rootScope.$broadcast('update-basis-doc');
        vm.onUpdate();
      });
    }

    function documentsBulkDownload() {
      DocumentsService.bulkDownload(vm.queryParams);
    }
  }
})();
