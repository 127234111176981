import React from 'react';

import { AddButton, EditButton, SaveButton } from '~/shared/ui/components/editing';
import { CancelEditButton } from '~/shared/ui/components/editing/cancel-edit-button';

export const PageDatasetActions: React.FC<{
  editing: boolean;
  editingChanged: (value: boolean) => void;
  onSave: () => void;
}> = ({ editing, editingChanged, onSave }) => {
  return (
    <>
      {editing ? (
        <CancelEditButton onClick={() => editingChanged(false)} />
      ) : (
        <EditButton onClick={() => editingChanged(true)} />
      )}
      {editing ? <SaveButton onClick={onSave} /> : <AddButton />}
    </>
  );
};
