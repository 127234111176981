import template from './multicontract-details-logentries-tab.html?raw';

export const MulticontractDetailsLogentriesTabComponent = {
  bindings: {
    objectId: '<',
    contentType: '<?',
    filterLevel: '<?',
  },
  template,
  controller: [
    'AccountsService',
    class {
      AccountsService: any;
      contentType: any;
      filterLevel = 'multicontract-details-logentries-tab';
      logEntries: any;
      logEntriesCount = 0;
      multicontract: any;
      objectId: any;
      constructor(AccountsService: any) {
        this.AccountsService = AccountsService;
        this.multicontract = {};
        this.objectId = 0;
        this.contentType = '';
      }

      $onInit() {
        this.updateLogEntries();
      }

      updateLogEntries() {
        return this.AccountsService.LogEntry.query(
          {
            object_id: this.objectId,
            content_type: this.contentType,
          },
          (data: any) => {
            this.logEntries = data.results;
            this.logEntriesCount = data.count;
          },
        );
      }
    },
  ],
};
