import ng from 'angular';

import { ConsolidatedMarketPricesTable } from './consolidated-market-prices-table/consolidated-market-prices-table.component';
import { ConsolidatedStockMarketPricesTable } from './consolidated-stock-market-prices-table/consolidated-stock-market-prices-table.component';
import { MarketPricesTable } from './market-prices-table/market-prices-table.component';
import { MarketPricesService } from './market-prices.service';
import { StockMarketPricesPage } from './stock-market-prices-page/stock-market-prices-page.component';
import { StockMarketPricesTable } from './stock-market-prices-table/stock-market-prices-table.component';
import { StockMarketPricesService } from './stock-market-prices.service';

const stockMarketPricesConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider.state('market.derivatives', {
      url: '/derivatives',
      component: 'stockMarketPricesPage',
      data: {
        pageTitle: gettext('Derivatives'),
      },
    });
  },
];

export const stockMarketPrices = ng
  .module('stockExchange.StockMarketPricesService', [])
  .service('StockMarketPricesService', StockMarketPricesService)
  .service('MarketPricesService', MarketPricesService)
  .component('stockMarketPricesPage', StockMarketPricesPage)
  .component('stockMarketPricesTable', StockMarketPricesTable)
  .component('consolidatedStockMarketPricesTable', ConsolidatedStockMarketPricesTable)
  .component('marketPricesTable', MarketPricesTable)
  .component('consolidatedMarketPricesTable', ConsolidatedMarketPricesTable)
  .config(stockMarketPricesConfig).name;
