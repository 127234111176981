export class CoreUtils {
  cleanBeforeClone(object: any, fieldsForDelete: string[] = []) {
    [
      ...fieldsForDelete,
      'author',
      'editor',
      'author_first_name',
      'editor_first_name',
      'create_time',
      'update_time',
      'id',
    ].forEach((field) => {
      delete object[field];
    });
    return object;
  }
}
