import template from './payment-plan-finances-pagination.html?raw';

export const paymentPlanFinancesPagination = {
  bindings: {
    count: '<',
    currentPage: '=',
  },
  template,
  controller: [
    '$scope',
    class {
      $scope: ng.IScope;
      count = 0;
      currentPage: any;
      page: any;
      pageSize: any;
      pages: any;
      range: any;
      rotateNum: any;
      constructor($scope: ng.IScope) {
        this.$scope = $scope;

        this.currentPage = 1;
        this.pageSize = 25;
        this.pages = 0;
        this.range = [];
        this.rotateNum = 8;
      }

      $onInit() {
        this.count = this.count || 0;
        this.currentPage = this.currentPage || 1;
        this.$scope.$watch(
          () => this.count,
          () => this.refreshRange(),
        );
      }

      onPageChange(page: any) {
        this.currentPage = (page <= this.pages && page) || 1;
      }

      refreshRange() {
        let startPos = this.page - Math.floor(this.rotateNum / 2);
        let endPos = this.page + Math.floor(this.rotateNum / 2);
        this.pages = Math.floor(this.count / this.pageSize);

        if (this.count % this.pageSize) {
          this.pages++;
        }

        this.range = [];

        startPos = (startPos > 2 && startPos) || 2;
        endPos = (endPos < this.pages - 1 && endPos) || this.pages - 1;

        for (let i = startPos; i <= endPos; i++) {
          this.range.push(i);
        }
      }
    },
  ],
};
