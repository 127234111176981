import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

export class CostsBulkEditFieldsService {
  $rootScope: GtRootScopeService;
  $resource: ng.resource.IResourceService;
  gettext: ng.gettext.gettextFunction;
  ContractChargeResource: any;
  constructor(
    $rootScope: GtRootScopeService,
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$rootScope = $rootScope;
    this.$resource = $resource;
    this.gettext = gettext;
    this.ContractChargeResource = $resource(
      '/api/contracts/contract-charges/:id/',
      {
        id: '@id',
      },
      {
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/contracts/contract-charges/bulk_create_or_update/',
        },
      },
    );
  }

  bulkCreateOrUpdate(params: any, objects: any) {
    return this.ContractChargeResource.bulkCreateOrUpdate(params, objects).$promise;
  }

  getUpdatedObjects(objects: any, params) {
    return objects.map((item: any) => ({
      id: item.id,
      ...params,
    }));
  }

  getFields(instance: any) {
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup.push({
      wrapper: 'gt-panel',
      fieldGroup: [
        {
          key: 'contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Contract'),
            resource: 'contracts.ContractBase',
            queryParams: { stage: 'contract' },
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.passport;
            },
          },
        },
        {
          key: 'setContractNull',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Clear contract'),
            onChange: () => {
              if (instance.setContractNull) {
                instance.contract = null;
              }
            },
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.contract;
            },
          },
        },
      ],
    });
    col2.fieldGroup.push({
      wrapper: 'gt-panel',
      fieldGroup: [
        {
          key: 'passport',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
            resource: 'passports.passport',
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.contract;
            },
          },
        },
        {
          key: 'setPassportNull',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Clear passport'),
            onChange: () => {
              if (instance.setPassportNull) {
                instance.passport = null;
              }
            },
          },
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !!instance.passport;
            },
          },
        },
      ],
    });

    return {
      formName: 'costs-bulk-edit',
      fieldsDef: [col1, col2],
    };
  }
}
CostsBulkEditFieldsService.$inject = ['$rootScope', '$resource', 'gettext'];
