import type { DataTypeProps } from '~/shared/ui/data-types';

import type { Entity } from '../components';
import { type AurocompleteRepository, withRepository } from '../components';

export type CommonDataType =
  | 'multiselect'
  | 'number'
  | 'select'
  | 'string'
  | 'phone'
  | 'checkbox'
  | 'text'
  | 'label'
  | 'url'
  | 'email'
  | 'entity'
  | 'date';

export const entityAutocompletesRegistry: Record<string, React.FC<DataTypeProps<Entity>>> = {};

export const registerEntityAutocomplete = (entityName: string, repo: AurocompleteRepository) => {
  entityAutocompletesRegistry[entityName] = withRepository(repo)();
};
