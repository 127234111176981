import React from 'react';

import type { DraggableAttributes, DraggableSyntheticListeners } from '~/shared/lib/dnd';

type Context = {
  attributes: DraggableAttributes;
  listeners: DraggableSyntheticListeners;
  ref: (node: HTMLElement | null) => void;
};

export const SortableItemContext = React.createContext<Context>({
  attributes: {
    role: '',
    tabIndex: 0,
    'aria-disabled': false,
    'aria-pressed': false,
    'aria-roledescription': '',
    'aria-describedby': '',
  },
  listeners: undefined,
  ref: (node) => {
    if (node) {
      node.setAttribute('draggable', 'true');
    }
  },
});
