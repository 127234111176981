import React from 'react';

import { useObservableEagerState } from '~/shared/lib/state';

import { useGlobalSearch } from '../../hooks/use-global-search';
import { GlobalSearchDialog } from '../global-search-dialog';

export const GlobalSearchContainer: React.FC = () => {
  const { globalSearchStore } = useGlobalSearch();
  const popupOpen = useObservableEagerState(globalSearchStore.popupOpen$);
  const searchResults = useObservableEagerState(globalSearchStore.searchResults$);
  const searchText = useObservableEagerState(globalSearchStore.searchText$);

  return (
    <GlobalSearchDialog
      searchText={searchText}
      searchResults={searchResults}
      searchTextChanged={(text: string) => globalSearchStore.searchTextChanged(text)}
      open={popupOpen}
      openChanged={() => globalSearchStore.popupToggled()}
    />
  );
};
