import numberCellTemplate from './number-cell-template.html?raw';
import template from './reassignment-list-table-view.html?raw';

export const ReassignmentListTableView = {
  bindings: {
    reassignments: '<',
    count: '<',
    total: '<',
    filterLevel: '<',
    selection: '<?',
    editReassignment: '&',
    openDocxModal: '&',
    openImportExportContractsModal: '&',
    onConnect: '&?',
    addParentReassignment: '&?',
    applyFilters: '&',
    onSave: '&?',
  },
  template,
  controller: [
    'gettext',
    class {
      addParentReassignment: any;
      applyFilters: any;
      count = 0;
      editReassignment: any;
      filterLevel = '';
      gettext: ng.gettext.gettextFunction;
      numberInput: any;
      onConnect: any;
      onSave: any;
      openDocxModal: any;
      openImportExportContractsModal: any;
      reassignments: any;
      selection: any;
      tableApi: any;
      tableData: any;
      tableOptions: any;
      total: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
      }

      $onInit() {
        this.selection = this.selection || false;
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        this.reassignments = this.reassignments || [];
        this.count = this.count || 0;
        if (changes.reassignments || changes.count || changes.total) {
          this.tableData = { rows: this.reassignments, count: this.count, total: this.total };
        }
      }

      toggleSelection() {
        if (this.tableOptions.selection) {
          this.numberInput = false;
          this.selection = false;
        } else {
          this.numberInput = true;
          this.selection = true;
          this.reassignments.forEach((reassignment: any) => {
            reassignment.$_inputedNumber = reassignment.amount_to_plan;
          });
        }
        this.tableOptions = this.getTableOptions();
      }

      getReassignmentsCount() {
        return this.reassignments.length;
      }

      getTableOptions() {
        const options: any = {
          tableName: 'reassignments',
          tableClass: ['request-table'],
          filterLevel: this.filterLevel,
          selection: this.selection,
          onSelection: this.onConnect,
          configurable: true,
          applyFilters: this.applyFilters,
          templateArgs: {
            editReassignment: (reassignment: any) =>
              this.editReassignment({ reassignment: reassignment }),
            openDocxModal: (reassignment: any) =>
              this.openDocxModal({ reassignment: reassignment }),
            openImportExportContractsModal: () => this.openImportExportContractsModal(),
            onConnect: (item: any) => this.onConnect({ item: item }),
            addParentReassignment: (reassignment: any) =>
              this.addParentReassignment({ reassignment: reassignment }),
            setHovering: (value: any) => this.tableApi.setHovering(value),
            save: (reassignment: any) => this.onSave({ reassignment: reassignment }),
            getReassignmentsCount: () => this.getReassignmentsCount(),
          },
          columnDefs: [] as any[],
          tabs: [],
          selectionCellTemplate: `
            <input
              class="form-control"
              type="checkbox"
              ng-model="item._toConnect"
              ng-change="args.onConnect({ item: item })"
              style="width:20px !important;"
            />`,
        };

        options.columnDefs.push(
          {
            columnName: 'actions',
            title: this.gettext('Actions'),
            cellTemplate: `
            <a
            ng-if="!item.edit"
            ng-click="item.edit = true"
            class="btn btn-xs btn-add-dark"
          >
            <i class="fa fa-pencil-square"></i>
          </a>
          <a
            ng-if="item.edit == true"
            ng-click="item.edit = false;args.save(item)"
            class="btn btn-xs btn-success btn_success"
          >
            <i class="fa fa-floppy-o"></i>
          </a>
          <a ng-if="item.edit" class="btn btn-xs" ng-click="item.edit = false;">
            <i class="fa fa-times"></i>
          </a>

            `,
          },
          {
            columnName: 'multicontract_number',
            title: this.gettext('Multicontract No.'),
            class: 'td-left-align',
            cellTemplate: `
              <a ui-sref="gt.multicontract({id: item.multicontract_id })" ng-if="item.multicontract_number">
                #{[{ item.multicontract_number }]}
              </a>
            `,
            totalBlockTemplates: [
              /*html*/ `
              <span class="label">
                  {[{ item.total_amount_with_vat || 0 | number:3 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>amount with vat</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'number',
            title: this.gettext('Number'),
            cellTemplate: numberCellTemplate,
            totalTemplate: `
              <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate><br/>
              <label>{[{ args.getReassignmentsCount() }]} / {[{ item.total_count }]}</label>
              `,
            totalBlockTemplates: [
              /*html*/ `
                <span class="label">
                  {[{ count }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>count</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'status',
            title: this.gettext('status'),
            cellTemplate: `
            <span
              class="label"
              style="margin-left: 2px;"
              ng-class="{ 'label_default': item.status == 'done' , 'label_success': item.status == 'new', 'label_warning': item.status == 'process' } "
            >
              <translate>{[{ item.status }]}</translate>
            </span>
            `,
          },
          {
            columnName: 'parent_reassignment',
            title: this.gettext('Parent reassignment'),
            class: 'td-left-align',
            cellTemplate: `
            <span
            class="label label_warning"
            style="margin-left: 2px"
            ng-if="item.parent_reassignment"
          >
            <a ui-sref="gt.reassignment({ id: item.parent_reassignment })" target="_blank">
              <i class="fa fa-reply-all"></i> {[{ item.parent_reassignment_number | cut:true:40:'...' ||
              "---" }]}
            </a>
          </span>
          `,
          },
          {
            columnName: 'offset_number',
            title: this.gettext('Offset number'),
            class: 'td-left-align',
            cellTemplate: `
            <span
            class="label label_default"
            style="margin-left: 2px"
            ng-repeat="offset in item.offset_data"
          >
            <a ui-sref="gt.offset({ id: offset.id })" target="_blank">
              <i class="fa fa-magnet"></i> {[{ offset.number | cut:true:40:'...' || "---" }]}
            </a>
          </span>
          `,
          },
          {
            columnName: 'contract_number',
            title: this.gettext('Contract'),
            class: 'td-left-align',
            cellTemplate: `
              <a ui-sref="gt.page.contract({id: item.contract_id })">
                #{[{ item.contract_number }]}
              </a>
            `,
            totalBlockTemplates: [
              /*html*/ `
              <span class="label">
                  {[{ item.total_amount_with_vat || 0 | number:3 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>amount with vat</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'has_docs_copy',
            title: this.gettext('Document copy'),
            cellTemplate: `
              <input
                style="width: 100%;"
                type="checkbox"
                ng-if="item.edit == true"
                ng-model="item.has_docs_copy"
              >
              <span ng-if="!item.edit && item.has_docs_copy">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!item.edit && !item.has_docs_copy">
                <i class="fa fa-circle-o"></i>
              </span>
            `,
          },
          {
            columnName: 'has_docs_original',
            title: this.gettext('Document original'),
            cellTemplate: `
              <input
                style="width: 100%;"
                type="checkbox"
                ng-if="item.edit == true"
                ng-model="item.has_docs_original"
              >
              <span ng-if="!item.edit && item.has_docs_original">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!item.edit && !item.has_docs_original">
                <i class="fa fa-circle-o"></i>
              </span>
            `,
          },
          {
            columnName: 'supplier_name',
            title: this.gettext('Supplier'),
            class: 'td-left-align',
            cellTemplate: `
            <span ng-if="!item.supplier_id">
              ---
            </span>
            <span ng-if="item.supplier_id">
              <a ui-sref="gt.role({role: 'supplier', id: item.supplier_id})">
                {[{ item.supplier_name | cut:true:30:'...'  }]}
              </a>
            </span>
          `,
          },
          {
            columnName: 'distributor_name',
            title: this.gettext('Distributor'),
            class: 'td-left-align',
            cellTemplate: `
            <span ng-if="!item.distributor_id">
              ---
            </span>
            <span ng-if="item.distributor_id">
              <a ui-sref="gt.role({role: 'supplier', id: item.distributor_id})">
                {[{ item.distributor_name | cut:true:30:'...'  }]}
              </a>
            </span>
          `,
          },
          {
            columnName: 'date',
            title: this.gettext('Date'),
            cellTemplate: `
            <span ng-if="item.date">
              {[{ item.date | date: 'dd.MM.yy' }]}
            </span>
            <span ng-if="!item.date">
              ---
            </span>

          `,
          },
          {
            columnName: 'amount_with_vat',
            title: this.gettext('Amount with VAT'),
            class: 'td-right-align',
            cellTemplate: `
              {[{ item.amount_with_vat || 0 | number: 2 }]}
            `,
            totalTemplate: /*html*/ `
              <span>{[{ item.total_amount_with_vat || 0 | number:3 }]} </span>
            `,
          },
          {
            columnName: 'amount_in_offset',
            title: this.gettext('Amount in offset'),
            class: 'td-right-align',
            cellTemplate: `
              {[{ item.amount_in_offset || 0 | number: 2 }]}
            `,
            totalTemplate: /*html*/ `
              <span>{[{ item.total_amount_in_offset || 0 | number:3 }]} </span>
            `,
          },
          {
            columnName: 'balance',
            title: this.gettext('Balance'),
            class: 'td-right-align',
            cellTemplate: `
              <a
                class="tooltip"
                data-tip="{[{ 'Balance to offset'|translate }]}"
              >
                {[{ item.balance || 0 | number: 2 }]}
              </a>
            `,
            totalTemplate: /*html*/ `
              <span>{[{ item.total_balance || 0 | number:3 }]} </span>
            `,
          },
          {
            columnName: 'seeds',
            title: this.gettext('Seeds'),
            class: 'td-right-align',
            cellTemplate: `
              <input
                type="number"
                ng-if="item.edit == true"
                ng-model="item.seeds"
              >
              <a
                ng-if="item.edit != true"
                class="tooltip"
                data-tip="{[{ 'Seeds'|translate }]}"
              >
                {[{ item.seeds || 0 | number: 2 }]}
              </a>
            `,
            totalTemplate: /*html*/ `
              <span>{[{ item.total_seeds_amount || 0 | number:3 }]} </span>
            `,
            totalBlockTemplates: [
              /*html*/ `
              <span class="label">
                  {[{ item.total_seeds_amount || 0 | number:3 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>seeds amount</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'crop_protection',
            title: this.gettext('Crop protection'),
            class: 'td-right-align',
            cellTemplate: `
              <input
                style="width: 100%;"
                type="number"
                ng-if="item.edit == true"
                ng-model="item.crop_protection"
              >
              <a
                ng-if="item.edit != true"
                class="tooltip"
                data-tip="{[{ 'Crop protection'|translate }]}"
              >
                {[{ item.crop_protection || 0 | number: 2 }]}
              </a>
            `,
            totalTemplate: /*html*/ `
              <span>{[{ item.total_crop_protection_amount || 0 | number:3 }]} </span>
            `,
            totalBlockTemplates: [
              /*html*/ `
              <span class="label">
                  {[{ item.total_crop_protection_amount || 0 | number:3 }]}
                </span>
                <div class="clearfix"></div>
                <span class="property">
                  <translate>Total</translate> <translate>crop protection amount</translate>
                </span>
              `,
            ],
          },
          {
            columnName: 'reassignment_rate',
            class: 'td-right-align',
            title: this.gettext('rate'),
            cellTemplate: `
            <input
              style="width: 100%;"
              type="number"
              ng-if="item.edit == true"
              ng-model="item.reassignment_rate"
            >
            <span ng-if="item.edit != true">
              {[{ item.reassignment_rate | number: 4 }]}
            </span>
          `,
          },
          {
            columnName: 'paymentcondition_title',
            title: this.gettext('payment condition'),
            class: 'wrap-text td-left-align',
            cellTemplate: `
            {[{ item.contract_paymentcondition_title }]}
          `,
          },
          {
            columnName: 'contractoption_title',
            title: this.gettext('loyalty program'),
            class: 'td-left-align',
            cellTemplate: `
            {[{ item.contract_contractoption_title }]}
          `,
          },
          {
            columnName: 'sap_orders_data',
            class: 'td-left-align',
            title: this.gettext('Sap orders'),
            cellTemplate: `
            <div ng-repeat="sap_order in item.sap_orders_data">
              {[{ sap_order.number }]}
              <div class="clearfix"></div>
            </div>
          `,
          },
        );

        return options;
      }
    },
  ],
};
