import ng from 'angular';

import { ConsolidatedPnlContainer } from './consolidated-pnl-container/consolidated-pnl-container.component';
import { ConsolidatedPnlPage } from './consolidated-pnl-page/consolidated-pnl-page.component';
import { ConsolidatedPnlService } from './consolidated-pnl.service';

export const consolidatedPnl = ng
  .module('reports.consolidatedPnl', [])
  .service('ConsolidatedPnlService', ConsolidatedPnlService)
  .component('consolidatedPnlPage', ConsolidatedPnlPage)
  .component('consolidatedPnlContainer', ConsolidatedPnlContainer).name;
