import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';

import template from './drivers-container.html?raw';

export const DriversContainer = {
  bindings: {
    filterLevel: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'DriversService',
    'LogisticsService',
    class {
      $scope: ng.IScope;
      DriversService: any;
      LogisticsService: any;
      drivers: any;
      filterLevel = '';
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        DriversService: any,
        LogisticsService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.DriversService = DriversService;
        this.LogisticsService = LogisticsService;
      }

      $onInit() {
        this.drivers = [];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateDriversList();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      openDriverModal(driver: any) {
        this.DriversService.openDriverModal(driver).then(() => this.updateDriversList());
      }

      updateDriversList() {
        this.LogisticsService.Driver.query(this.queryParams).$promise.then(
          (data: any) => (this.drivers = data.results),
        );
      }
    },
  ],
};
