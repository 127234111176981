import ng from 'angular';

import { QuotesParsingConfigsModal } from './components/quotes-parsing-configs-modal/quotes-parsing-configs-modal.component';
import { derivatives } from './derivatives/derivatives.module';
import { operations } from './operations/operations.module';
import { stockExchanges } from './stock-exchanges/stock-exchanges.module';
import { stockMarketPrices } from './stock-market-prices/stock-market-prices.module';

export const stockExchange = ng
  .module('stockExchange', [derivatives, stockExchanges, stockMarketPrices, operations])
  .component('quotesParsingConfigsModal', QuotesParsingConfigsModal).name;
