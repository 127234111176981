import template from './settings-parsing-page.html?raw';
import type { CoreService } from '../../core.service';
import type { PageService } from '../../legacy/components/gt-page/gt-page.srv';

export const SettingsParsingPage = {
  bindings: {},
  template,
  controller: [
    'PageService',
    'CoreService',
    class {
      CoreService: CoreService;
      PageService: PageService;
      filterLevel: string;
      constructor(PageService: PageService, CoreService: CoreService) {
        this.PageService = PageService;
        this.CoreService = CoreService;

        this.filterLevel = 'settings-parsing-page-filter-level';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-pl-tab',
          buttons: [new this.PageService.buttons.Print()],
          pages: this.CoreService.getPagesConfig(),
          filters: { clicked: false },
        };
      }
    },
  ],
};
