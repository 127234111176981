import ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('common.crops.legacy').component('warehousesContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      cropsId: '<?',
    },
    template: require('./warehouses-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$window',
    '$httpParamSerializer',
    'GtUtils',
    'CropsService',
    'ReportsService',
    'gtFilterService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $window: ng.IWindowService,
    $httpParamSerializer: any,
    GtUtils: GtUtilsService,
    CropsService: any,
    ReportsService: any,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.warehouses = [];
    vm.warehousesCount = 0;
    vm.updateWarehouses = updateWarehouses;
    vm.openWarehouseModal = openWarehouseModal;
    vm.crops = [];
    vm.renderTable = false;
    vm.showAllStock = false;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'warehouses-container';
      vm.queryParams = {
        page_size: 999,
        show_all_stock: 0,
        ...vm.initQueryParams,
      };
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateWarehouses();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);

      $scope.$watch('vm.showAllStock', function (newValue: any, oldValue: any) {
        if (newValue != oldValue) {
          vm.queryParams.show_all_stock = (newValue && '1') || '0';
          gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
        }
      });
    };

    ////////////////

    function updateWarehouses(asFile?: any) {
      if (asFile) {
        return downloadXls({ ...vm.queryParams });
      }
      GtUtils.overlay('show');

      vm.renderTable = false;
      ReportsService.WarehouseVolume.query(vm.queryParams, function (data: any) {
        vm.renderTable = true;
        vm.warehouses = data.results;
        vm.warehousesCount = data.results.length;
        vm.crops = data.totals;
        GtUtils.overlay('hide');
      });
    }

    function downloadXls(params: any) {
      params.as_xls = true;
      const url = '/api/reports/warehouse/volume/?';
      $window.open(url + $httpParamSerializer(params));
    }

    function openWarehouseModal(warehouse: any, crop: any, heap: any) {
      return CropsService.warehouseModal(
        warehouse,
        crop,
        CropsService.makeDetailingCropVolumeByDays(heap),
        vm.filterLevel,
        vm.queryParams.warehouseType,
      ).then(() => updateWarehouses());
    }
  }
})();
