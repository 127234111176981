import template from './loyalty-program-list-table-view.html?raw';

export const LoyaltyProgramListTableView = {
  bindings: {
    programs: '<',
    count: '<',
    filterLevel: '<',
    openProgramModal: '&',
    cloneProgram: '&',
  },
  template,
  controller: [
    class {
      count: number;
      filterLevel = 'loyalty-program-list-table-view';
      programs: any;
      constructor() {
        this.programs = [];
        this.count = 0;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'loyalty-program-list-table-view';
        this.programs = this.programs || [];
        this.count = this.count || 0;
      }
    },
  ],
};
