export const toggleHandler = ({
  currentIds,
  updateMethod,
  id,
}: {
  currentIds: string[];
  updateMethod: (ids: string[]) => void;
  id: string;
}) => {
  const updatedIds = currentIds.includes(id)
    ? currentIds.filter((existingId) => existingId !== id)
    : [...currentIds, id];

  updateMethod(updatedIds);
};
