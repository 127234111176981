import type ng from 'angular';

import { getModalRoot } from '~/shared/ui/modal';

import type { FinancesService } from '../../legacy/finances.srv';

export class LoanService {
  $filter: ng.IFilterService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  FinancesService: FinancesService;
  LoanResource: any;
  gettext: ng.gettext.gettextFunction;
  moment: any;
  constructor(
    gettext: ng.gettext.gettextFunction,
    $filter: ng.IFilterService,
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    moment: any,
    $uibModal: ng.ui.bootstrap.IModalService,
    FinancesService: FinancesService,
  ) {
    this.gettext = gettext;
    this.$filter = $filter;
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.moment = moment;
    this.FinancesService = FinancesService;
    this.LoanResource = $resource(
      '/api/finances/loans/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/loans/',
        },
        generateNumber: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/loans/generate_number/',
        },
        total: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/loans/currency_totals/',
        },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/loans/predictions/',
        },
      },
    );
  }

  loanModal(loan: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <loan-modal loan="loan" modal-instance="$uibModalInstance">
        </loan-modal>`,
      controller: [
        '$scope',
        'loan',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          loan: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).loan = loan;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        loan: () => loan,
      },
    }).result;
  }

  saveLoan(loan: any) {
    if (loan.id) {
      return this.LoanResource.update(loan).$promise;
    }
    return this.LoanResource.save(loan).$promise;
  }

  getLoanTableData(params: any) {
    return this.LoanResource.query(params).$promise;
  }

  getLoanCurrencyTotalsData(params: any) {
    return this.LoanResource.total(params).$promise;
  }

  getLoanModalData(params: any) {
    return this.LoanResource.get(params).$promise;
  }

  getNumber(params: any) {
    return this.LoanResource.generateNumber(params).$promise;
  }

  destroy(params: any) {
    return this.LoanResource.delete(params).$promise;
  }
}
LoanService.$inject = [
  'gettext',
  '$filter',
  '$resource',
  '$q',
  'moment',
  '$uibModal',
  'FinancesService',
];
