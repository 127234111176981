import React from 'react';

import { Navigate, Outlet } from '~/shared/lib/router';

import { useAuth } from '../../hooks';

export const AuthGuard = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};
