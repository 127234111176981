import ng from 'angular';

import { PurchasePlanPage } from './purchase-plan-page/purchase-plan-page.component';
import { PurchasePlanTable } from './purchase-plan-table/purchase-plan-table.component';
import { PurchasePlanService } from './purchase-plan.service';

export const purcahsePlan = ng
  .module('reports.purchasePlan', [])
  .service('PurchasePlanService', PurchasePlanService)
  .component('purchasePlanTable', PurchasePlanTable)
  .component('purchasePlanPage', PurchasePlanPage).name;
