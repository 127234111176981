import type { GtRootScopeService } from '~/app/core/types';

import template from './notification.html?raw';

export const notification = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: [
    '$rootScope',
    '$interval',
    'NotificationService',
    class {
      $interval: any;
      $rootScope: GtRootScopeService;
      NotificationService: any;
      messagesCount = 0;
      constructor($rootScope: GtRootScopeService, $interval: any, NotificationService: any) {
        this.$rootScope = $rootScope;
        this.$interval = $interval;
        this.NotificationService = NotificationService;
      }

      $onInit() {
        this.updateData().then(() => {
          if (this.$rootScope.user.profile.refresh_notifications_delay >= 10) {
            this.$interval(() => {
              this.NotificationService.getUnreadCount().then((data: any) => {
                if (data.count > this.messagesCount) {
                  this.NotificationService.showLastMessages(data.count - this.messagesCount);
                  this.messagesCount = data.count;
                }
              });
            }, this.$rootScope.user.profile.refresh_notifications_delay * 1000);
          }
        });
      }

      updateData() {
        return this.NotificationService.getUnreadCount().then((data: any) => {
          this.messagesCount = data.count;
        });
      }
    },
  ],
};
