import type ng from 'angular';

import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';
import { getModalRoot } from '~/shared/ui/modal';

export class OperationsService {
  $resource: ng.resource.IResourceService;
  $http: ng.IHttpService;
  $uibModal: ng.ui.bootstrap.IModalService;
  ContractsService: ContractsService;
  OperationResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    $http: ng.IHttpService,
    gettext: ng.gettext.gettextFunction,
    $uibModal: ng.ui.bootstrap.IModalService,
    ContractsService: ContractsService,
  ) {
    this.$resource = $resource;
    this.$http = $http;
    this.gettext = gettext;
    this.$uibModal = $uibModal;
    this.ContractsService = ContractsService;

    this.OperationResource = this.$resource(
      '/api/stockexchanges/operations/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        listInfo: {
          url: '/api/stockexchanges/operation-list-view/',
          method: 'GET',
          isArray: false,
        },
        totals: {
          url: '/api/stockexchanges/operation-totals/',
          method: 'GET',
          isArray: false,
        },
        consolidated: {
          url: '/api/stockexchanges/consolidated-operations/',
          method: 'GET',
        },
        consolidatedTotals: {
          url: '/api/stockexchanges/consolidated-operations-totals/',
          method: 'GET',
        },
        matched: {
          url: '/api/stockexchanges/matched-operations/',
          method: 'GET',
        },
        matchedTotals: {
          url: '/api/stockexchanges/matched-operations-totals/',
          method: 'GET',
        },
        consolidatedOperationsPassport: {
          url: '/api/passports/passport-derivative-report/list/',
          method: 'GET',
        },
        consolidatedOperationsContract: {
          url: '/api/contracts/contractbase-list/derivative-report/list/',
          method: 'GET',
        },
        createRolling: {
          url: '/api/stockexchanges/operations/create_rolling/',
          method: 'POST',
        },
        excludeFromFixingPositions: {
          url: '/api/stockexchanges/operations/exclude_from_fixing_position/',
          method: 'POST',
        },
      },
    );
  }

  operationModal(operation: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: `
        <operation-modal
          operation="operation"
          modal-instance="$uibModalInstance">
        </operation-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).operation = operation;
        },
      ],
      windowClass: 'modal-template-half-width',
      size: 'sm',
      appendTo: getModalRoot(),
    }).result;
  }

  createRolling(rolling: any) {
    return this.OperationResource.createRolling(rolling).$promise;
  }

  getOperation(id: any) {
    return this.OperationResource.get({ id: id }).$promise;
  }

  saveOperation(operation: any) {
    return this.OperationResource.save(operation).$promise;
  }

  updateOperation(operation: any) {
    return this.OperationResource.update(operation).$promise;
  }

  deleteOperation(operationId: any) {
    return this.OperationResource.delete({ id: operationId }).$promise;
  }

  getOperationData(params: any) {
    return this.OperationResource.listInfo(params).$promise.then((data: any) => data);
  }

  getOperationTotals(params: any) {
    return this.OperationResource.totals(params).$promise.then((data: any) => data);
  }

  getOperationConsolidated(params: any) {
    return this.OperationResource.consolidated(params).$promise.then((data: any) => data);
  }

  getOperationConsolidatedTotals(params: any) {
    return this.OperationResource.consolidatedTotals(params).$promise.then((data: any) => data);
  }

  getOperationConsolidatedPassport(params: any) {
    return this.OperationResource.consolidatedOperationsPassport(params).$promise.then(
      (data: any) => data,
    );
  }

  getOperationConsolidatedContract(params: any) {
    return this.OperationResource.consolidatedOperationsContract(params).$promise.then(
      (data: any) => data,
    );
  }

  getOperationsMatched(params: any) {
    return this.OperationResource.matched(params).$promise.then((data: any) => data);
  }

  getOperationsMatchedTotals(params: any) {
    return this.OperationResource.matchedTotals(params).$promise.then((data: any) => data);
  }

  getFixingHedgingReportData(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/fixing-hedging-report/',
      params: params,
    }).then((data: any) => data.data);
  }

  getCommFeesGrouped(params: any) {
    return this.$http({
      method: 'GET',
      url: '/api/stockexchanges/comm-fees-totals/',
      params: params,
    }).then((data: any) => data.data);
  }

  clone(id: any) {
    return this.OperationResource.get({
      id: id,
    }).$promise.then((data: any) => {
      delete data.id;
      return data;
    });
  }

  getPassportIdByContract(data: any) {
    const item = data.item;
    if (!item?.contract) {
      return;
    }
    return this.ContractsService.getPassportIdListByContract(item.contract).then((data: any) => {
      return data?.id;
    });
  }

  checkDerivative(operation: any) {
    return this.getContractDerivativeData(operation.contract).then((contract: any) => {
      return (
        [
          {
            condition:
              operation.derivative &&
              operation.operation_type !== 'rolling' &&
              !contract.derivative_id_list?.includes(operation.derivative),
            message:
              'The derivative by the operation is different from the contract derivative.' +
              'Are you sure you want to save the operation?',
          },
          {
            condition:
              operation.derivative &&
              operation.operation_type !== 'rolling' &&
              operation.quantity > contract.notFixedLots,
            message:
              'The number of lots by operations is more then planned number.' +
              'You are sure you want to save the operation',
          },
        ]
          .filter((item) => item.condition)
          .map((item) => item.message) || []
      );
    });
  }

  getContractDerivativeData(contractId: any) {
    return this.updateDerivativePriceTotals([contractId]).then((data: any) => ({
      derivative_id_list: data.derivative_id_list,
      notFixedLots: data.not_fixed_quantity_lots,
    }));
  }

  updateDerivativePriceTotals(contractIds: any) {
    if (!contractIds) {
      return Promise.resolve({});
    }
    return this.ContractsService.Contract.derivativePriceList({
      id_list: contractIds,
    }).$promise.then((data: any) => data.results?.[0] || {});
  }

  getOperationsExportConfig() {
    return 'operations-export-config';
  }
}
OperationsService.$inject = ['$resource', '$http', 'gettext', '$uibModal', 'ContractsService'];
