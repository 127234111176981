import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './multicontract-details-info-tab.html?raw';
import type { MulticontractFormFieldsService } from '../../multicontract-form-fields.service';
import type { MulticontractService } from '../../multicontract.service';

export const MulticontractDetailsInfoTab = {
  bindings: {
    multicontractId: '<',
    filterLevel: '<?',
  },
  template,
  controller: [
    'GtUtils',
    'MulticontractService',
    'MulticontractFormFieldsService',
    'FormFieldParamsService',
    class {
      GtUtils: GtUtilsService;
      FormFieldParamsService: FormFieldParamsService;
      MulticontractFormFieldsService: MulticontractFormFieldsService;
      MulticontractService: MulticontractService;
      filterLevel = 'multicontract-item';
      multicontract: any;
      multicontractFields: any;
      multicontractId: any;
      positionFields: any;
      positions: any;
      positionsFields: any;
      showPositions: boolean;
      constructor(
        GtUtils: GtUtilsService,
        MulticontractService: MulticontractService,
        MulticontractFormFieldsService: MulticontractFormFieldsService,
        FormFieldParamsService: FormFieldParamsService,
      ) {
        this.GtUtils = GtUtils;
        this.MulticontractService = MulticontractService;
        this.MulticontractFormFieldsService = MulticontractFormFieldsService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.multicontract = {};
        this.multicontractId = 0;
        this.positions = [];
        this.positionsFields = [];
        this.multicontractFields = [];
        this.showPositions = false;
      }

      $onInit() {
        this.updateData();
      }

      updateData() {
        this.MulticontractService.getModalData({ id: this.multicontractId }).then((data: any) => {
          this.multicontract = data;
          this.positions = data.positions;
          this.getFields();
        });
      }

      getFields() {
        this.getPositionFields();
        this.getMulticontractsFields();
      }

      getPositionFields() {
        this.FormFieldParamsService.getFields(
          this.MulticontractService.getContractPositionFormConfig(
            this.positions[0],
            'multicontract-info-tab',
          ),
          this.multicontract.buisness_unit,
        )
          .then((fields: any) => {
            this.positionFields = fields;
          })
          .catch(this.GtUtils.errorClb);
      }

      getMulticontractsFields() {
        this.FormFieldParamsService.getFields(
          this.MulticontractFormFieldsService.getMulticontractFormConfig(
            this.multicontract,
            this.positions,
            'multicontract-info-tab',
          ),
          this.multicontract.buisness_unit,
        )
          .then((fields: any) => {
            this.multicontractFields = fields;
          })
          .catch(this.GtUtils.errorClb);
      }

      togglePositions() {
        this.showPositions = !this.showPositions;
      }

      openFieldsConfigModal(model: any) {
        const fieldsConfig =
          model === 'position'
            ? this.MulticontractService.getContractPositionFormConfig(
                this.positions[0],
                'multicontract-info-tab',
              )
            : this.MulticontractFormFieldsService.getMulticontractFormConfig(
                this.multicontract,
                this.positions,
                'multicontract-info-tab',
              );
        this.FormFieldParamsService.fieldsConfigModal(fieldsConfig).then(() => this.getFields());
      }
    },
  ],
};
