import React from 'react';

import { cn } from '~/shared/lib/utils';

import { type DataTypeProps } from '../../lib/types';

export const NumberRead: React.FC<DataTypeProps<number>> = ({ disabled, value }) => (
  <p className={cn('px-2 text-xs', disabled && 'opacity-50')} data-testid="number-field">
    {String(value)}
  </p>
);
