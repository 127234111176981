import React from 'react';

import type { ChoiceMenu as ChoiceMenuDef, ViewConfigChanged } from '../../types';

export const ChoiceMenuContent: React.FC<{
  viewId: number;
  data: ChoiceMenuDef;
  viewConfigChanged: ViewConfigChanged;
}> = ({ viewId, data, viewConfigChanged }) => {
  return data.options.map((option) => (
    <button
      key={option}
      className="w-max cursor-pointer rounded-sm p-1 text-left text-xs capitalize text-text-main-primary transition duration-300 ease-in-out hover:opacity-70"
      onClick={() =>
        viewConfigChanged(viewId, {
          data: {
            type: {
              ...data,
              selected: option,
            },
          },
        })
      }
    >
      {option}
    </button>
  ));
};
