import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './invoice-details-item-view.html?raw';

export const invoiceDetailsItemView = {
  bindings: {
    finance: '<',
    tab: '<',
    fields: '<',
    usesList: '<',
    financePositions: '<',
    logisticsQueryParams: '<',
    payment: '<?',
    financePositionIsValid: '&?',
    save: '&?',
    clone: '&?',
    openDocumentModal: '&?',
    openDocxModal: '&?',
    updateFields: '&?',
    getFormConfig: '&?',
    openFinanceModal: '&?',
    updateData: '&?',
    hideWizard: '<?',
    openEmailModal: '&?',
    changeTab: '&?',
    clientIdFrom: '<?',
    clientIdTo: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'FinancesService',
    class {
      FinancesService: FinancesService;
      finance: any;
      fields: any;
      usesList: any;
      financePositions: any;
      logisticsQueryParams: QueryParams = {};
      payment: any;
      financePositionIsValid: any;
      save: any;
      clone: any;
      openDocumentModal: any;
      openDocxModal: any;
      updateFields: any;
      getFormConfig: any;
      openFinanceModal: any;
      partialTab: any;
      tab: any;
      subtab: any;
      updateData: any;
      hideWizard: any;
      openEmailModal: any;
      changeTab: any;
      clientIdFrom: any;
      clientIdTo: any;
      showDetail: any;
      constructor($rootScope: GtRootScopeService, FinancesService: any) {
        this.FinancesService = FinancesService;
        this.finance = {};
        this.fields = [];
        this.payment = {};
        this.financePositionIsValid = undefined;
        this.save = undefined;
        this.clone = undefined;
        this.openDocumentModal = undefined;
        this.openDocxModal = undefined;
        this.updateFields = undefined;
        this.getFormConfig = undefined;
        this.openFinanceModal = undefined;
        this.partialTab = { tab: 'partial' };
        this.tab = '';
        this.subtab = 'logistics';
        this.logisticsQueryParams = {};
        this.updateData = undefined;
        this.hideWizard = undefined;
        this.changeTab = undefined;
        if ($rootScope.user.profile.recap) {
          this.showDetail = true;
        } else {
          this.showDetail = false;
        }
      }
      updateCurrencyExchange(data: any) {
        this.finance.currency_exchange = data;
        return this.FinancesService.Finance.update(this.finance).$promise.then(() => {
          if (this.updateData) {
            return this.updateData();
          }
        });
      }
    },
  ],
};
