import ng from 'angular';

import { analyticsModule } from './analytics/analytics.module';
import { cropsModule } from './crops';
import { customValuesModule } from './custom-fields/custom-values.module';
import { tableBulkEditModule } from './table-bulk-edit/table-bulk-edit.module';

export const commonModule = ng.module('common', [
  analyticsModule,
  cropsModule,
  customValuesModule,
  tableBulkEditModule,
]).name;
