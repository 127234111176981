import type ng from 'angular';

import type { CoreService } from '~/app/core/core.service';

export class TerminalTransshipmentTariffService {
  $resource: ng.resource.IResourceService;
  CoreService: CoreService;
  LogisticTariffResource: any;
  constructor($resource: ng.resource.IResourceService, CoreService: CoreService) {
    this.$resource = $resource;
    this.CoreService = CoreService;

    this.LogisticTariffResource = $resource(
      '/api/logistics/logistic-tariffs/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/logistic-tariffs/predictions/',
        },
      },
    );
  }

  getTariffs(params: any) {
    return this.LogisticTariffResource.query(params).$promise;
  }

  saveTariff(tariff: any) {
    if (tariff.id) {
      return this.LogisticTariffResource.update(tariff).$promise;
    }
    return this.LogisticTariffResource.save(tariff).$promise;
  }

  deleteTariff(tariff: any) {
    return this.LogisticTariffResource.delete({ id: tariff.id }).$promise;
  }

  getNewTariff(initParams: any) {
    return {
      business_unit: Object.assign([], this.CoreService.getDefaultBuList()).shift(),
      status: 'valid',
      edit: true,
      ...initParams,
    };
  }
}

TerminalTransshipmentTariffService.$inject = ['$resource', 'CoreService'];
