import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';

import { PassportConnectionShipmentPlanFormFieldsService } from './passport-connection-shipment-plan-form-field.service';
import { PassportConnectionShipmentPlanListContainer } from './passport-connection-shipment-plan-list-container/passport-connection-shipment-plan-list-container.component';
import { PassportConnectionShipmentPlanListPage } from './passport-connection-shipment-plan-list-page/passport-connection-shipment-plan-list-page.component';
import { PassportConnectionShipmentPlanListTableView } from './passport-connection-shipment-plan-list-table-view/passport-connection-shipment-plan-list-table-view.component';
import { PassportConnectionShipmentPlanModal } from './passport-connection-shipment-plan-modal/passport-connection-shipment-plan-modal.component';
import { PassportConnectionShipmentPlanService } from './passport-connection-shipment-plan.service';

export const passportConnectionShipmentPlan = ng
  .module('logistics.planning.passportConnectionShipmentPlan', [])
  .service('PassportConnectionShipmentPlanService', PassportConnectionShipmentPlanService)
  .service(
    'PassportConnectionShipmentPlanFormFieldsService',
    PassportConnectionShipmentPlanFormFieldsService,
  )
  .component('passportConnectionShipmentPlanListPage', PassportConnectionShipmentPlanListPage)
  .component(
    'passportConnectionShipmentPlanListContainer',
    PassportConnectionShipmentPlanListContainer,
  )
  .component(
    'passportConnectionShipmentPlanListTableView',
    PassportConnectionShipmentPlanListTableView,
  )
  .component('passportConnectionShipmentPlanModal', PassportConnectionShipmentPlanModal)
  .config([
    '$stateProvider',
    'gettext',
    ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
      $stateProvider.state('logistics.planningPassportConnectionShipmentPlan', {
        url: '/passport-connection-shipment-plan/list/',
        component: 'passportConnectionShipmentPlanListPage',
        data: {
          pageTitle: gettext('Shipment Plan by Passport'),
          permissions: {
            only: 'view_passportconnectionshipmentplan',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'passport-connection-shipment-plan-list-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('passport-connection-shipment-plan-list-page');
            },
          ],
        },
      });
    },
  ]).name;
