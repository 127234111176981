import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';

import type { GtFilterService } from '../../gt-filter/gt-filter.srv';

(function () {
  'use strict';

  ng.module('core.legacy').component('gtTheadTabs', {
    bindings: {
      config: '<',
      filterLevel: '<?',
      onConfigChanged: '&?',
      highlightFirstTab: '<?',
    },
    template: require('./gt-thead-tabs.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gtFilterService', 'CoreService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    gtFilterService: GtFilterService,
    CoreService: CoreService,
  ) {
    const vm = this;

    vm.filters = [];
    vm.queryParams = {};
    vm.toggleFilters = toggleFilters;
    vm.tabClick = tabClick;
    vm.openColumnParamsModal = openColumnParamsModal;
    if (vm.highlightFirstTab !== false) {
      vm.highlightFirstTab = true;
    }

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || vm.config.filterLevel;
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
      if (!vm.highlightFirstTab && vm.config?.tabs) {
        vm.queryParams.serializer = vm.config.tabs[0].serializer;
      }
    };

    vm.$onChanges = function (changes: any) {
      if (changes.config) {
        vm.filters = [];
        const columns = vm.config.columns || vm.config.columnDefs;
        columns.forEach(function (column: any) {
          if (!column.filters) {
            return;
          }
          column.filters.forEach(function (filter: any) {
            vm.filters.push(filter);
          });
        });
      }
    };

    // //////////////

    function toggleFilters() {
      if (!vm.queryParams._theadFilters) {
        vm.queryParams._theadFilters = true;
      } else {
        vm.filters.forEach(function (filter: any) {
          if (filter.type == 'daterange') {
            vm.queryParams[filter.startDateField] = undefined;
            vm.queryParams[filter.endDateField] = undefined;
          } else {
            vm.queryParams[filter.predicate] = undefined;
          }
        });
        vm.queryParams._theadFilters = false;
      }
      updateData();
    }

    function tabClick(serializer: any) {
      if (
        (!serializer && vm.config.tabs.length) ||
        (serializer && vm.queryParams.serializer == serializer)
      ) {
        serializer = vm.config.tabs[0].serializer;
      }
      vm.queryParams.serializer = serializer;
      delete vm.queryParams.ordering;
      updateData();
    }

    function openColumnParamsModal(config: any) {
      return CoreService.columnParamsModal(config).then(function (data: any) {
        vm.onConfigChanged?.({ data: data });
        return updateData();
      });
    }

    function updateData() {
      gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
    }
  }
})();
