import type ng from 'angular';
export class DerivativesService {
  $resource: ng.resource.IResourceService;
  DerivativeResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor($resource: ng.resource.IResourceService, gettext: ng.gettext.gettextFunction) {
    this.$resource = $resource;
    this.gettext = gettext;

    this.DerivativeResource = this.$resource(
      '/api/stockexchanges/derivatives/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/derivatives/predictions/',
        },
      },
    );
  }
}
DerivativesService.$inject = ['$resource', 'gettext'];
