import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('requestsPricesPageView', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./requests-prices-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['ContractsService', 'PageService'];

  function Controller(this: any, ContractsService: ContractsService, PageService: PageService) {
    const vm = this;
    vm.filterLevel = vm.filterLevel || 'requests-prices-page-view';
    vm.groupBy = null;

    vm.$onInit = function () {
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      return {
        class: 'page-header-main-prices-tab',
        buttons: [new PageService.buttons.Refresh(vm.filterLevel)],
        pages: ContractsService.getPagesConfig(),
      };
    }
  }
})();
