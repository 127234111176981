import template from './go-to-object.html?raw';
import type { CoreService } from '../../core.service';
import type { GtUtilsService } from '../../legacy/gt-utils/gt-utils.srv';

export const GoToObject = {
  bindings: {},
  template,
  controller: [
    'CoreService',
    'GtUtils',
    class {
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      objects: any;
      constructor(CoreService: CoreService, GtUtils: GtUtilsService) {
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;

        this.objects = [];
      }

      search(text: any) {
        this.objects = [];
        if (text) {
          return this.CoreService.globalSearch(text).then((data: any) => {
            Object.keys(data).forEach((model) => {
              this.objects = this.objects.concat(
                data[model].map((obj: any) => ({
                  model: model,
                  ...obj,
                })),
              );
            });
          });
        }
      }

      goToDetails(item: any) {
        return this.GtUtils.goToDetails(item.model, item.id);
      }
    },
  ],
};
