import ng from 'angular';

ng.module('core.legacy').run([
  'gettextCatalog',
  function (gettextCatalog: any) {
    /* jshint -W100 */
    gettextCatalog.setStrings('ru', {
      '0': '0',
      '1': '1',
      '2': '2',
      '3': '3',
      '2017': '2017',
      '2018': '2018',
      '2019': '2019',
      '2020': '2020',
      '2021': '2021',
      '2022': '2022',
      '2023': '2023',
      '#': '#',
      '# of containers': 'кол-во контейнеров',
      $: '$',
      '$/20dv': '$/20dv',
      '$/40dv': '$/40dv',
      '$/day': '$/день',
      '$/t': '$/т',
      '%': '%',
      '% from the deal': '% от сделки',
      '&gt; 105%': '&gt; 105%',
      '(Boat required)': '(Необходимо указать Лодку)',
      '(purchase)': '(закупка)',
      '(sale)': '(продажа)',
      '+/- 0-50': '+/- 0-50',
      '+0 0 (000)000-00-00': '+0 0 (000)000-00-00',
      '---': '---',
      '-Payment conditions value must be 1-100': 'Условия оплаты должны быть в диапазоне 1-100',
      '0%': '0%',
      '0-100': '0-100',
      '0-100%': '0-100%',
      '0-200': '0-200',
      '0-200%': '0-200%',
      '0-95%': '0-95%',
      '0000000': '0000000',
      '1) we calculate P&L passport by passport, from different perspectives: by\n                    contracts (as a plan), by execution (as a fact), by invoices (as stronger\n                    fact), etc.':
        '1) мы рассчитываем финансовый результат по паспорта, с разных углов\nпо контрактам (как план), по исполнению (как факт), по инвойсам \n(как еще более сильный факт), и так далее.',
      '1-100': '1-100',
      '100% matched': '100% совпадение',
      '1Q': '1Q',
      '1t': '1т',
      '2) we combine inside this result both physical and non physical operations by\n                    passport':
        '2) мы учитываем и физические и не физические операции \nв результате паспорта',
      '2Q': '2Q',
      '3) when you press button CONTROL, we take the most actual P&L and put it here\n                    (you can choose which one, using MARGIN_SELECTOR inside passport modal window)':
        '3) когда вы нажимаете кнопку КОНТРОЛЬ, мы делаем срез наиболее актуального ПнЛ \n(Вы можете выбрать конкретный ПнЛ паспорта, используя ПЕРЕКЛЮЧАТЕЛЬ МАРЖИ при редактировании паспорта)',
      '30-60 days': '30-60 дней',
      '3Q': '3Q',
      "4) then we take all contracts, and group them by certain criteria (origin,\n                    destination, client, etc). We know the PNL by each contract from it's share\n                    inside passport":
        '4) потом мы берем все контракты и группируем их по различным критериям\n(Происхождение, назначение, контрагент и так далее). Мы знаем ПнЛ каждого контракта по \nего удельному весу в паспорте',
      '5) and as a result you see the grouped list of passports inside certain month\n                    (depending on a business date appointed in the passport)':
        '5) как результат, вы видите группированный список паспорта по каждому месяцу \n(На месяц влияет бизнес дата паспорта)',
      '60-90 days': '60-90 дней',
      '90 and over days': '90 и больше дней',
      '95-105%': '95-105%',
      '<i class="fa fa-file-text"></i> Contracts': '<i class="fa fa-file-text"></i> Контракты',
      '<i class="fa fa-file-text-o"></i> Create ticket':
        '<i class=“fa fa-file-text-o”></i> Создать тикет',
      '<i class="fa fa-floppy-o"></i>': '<i class="fa fa-floppy-o"></i>',
      '<i class="fa fa-pencil-square"></i>': '<i class="fa fa-pencil-square"></i>',
      '<i class="fa fa-refresh"></i>': '<i class=“fa fa-refresh”></i>',
      '<i class="fa fa-times"></i>': '<i class="fa fa-times"></i>',
      '<i class="fa fa-times-circle"></i>': '<i class="fa fa-times-circle"></i>',
      '<span class="ng-scope">Apply</span>': '<span class="ng-scope">Применить</span>',
      '<span class="ng-scope">Clear</span>': '<span class="ng-scope">Очистить</span>',
      '<span class="ng-scope">Save</span>': '<span class="ng-scope">Сохранить</span>',
      '<span ng-if="!withoutEndOfExecution"> {{ month |date:\'MMM-yyyy\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>':
        '<span ng-if=“!withoutEndOfExecution”> {{ month |date:’MMM-yyyy’ }}</span>\n              <span ng-if=“withoutEndOfExecution”>N/A</span>',
      'A server error occurred. Please contact the administrator.':
        'Оой. Ошибка сервера. Свяжитесь с поддержкой.',
      ACCOUNTS: 'СЧЕТА',
      ACTIVITY: 'АКТИВНОСТЬ',
      ADD: 'ДОБАВИТЬ',
      'ADD NEW': 'ДОБАВИТЬ НОВЫЙ',
      'ADDITIONAL AGGR.': 'ДОПОЛНИТЕЛЬНОЕ СОГЛАШ.',
      'ADDITIONAL AGGREEMENTS': 'ДОПОЛН. СОГЛАШЕНИЕ',
      ALL: 'ВСЕ',
      AMOUNT: 'СУММА',
      AMOUNTS: 'СУММЫ',
      ANALYTICAL: 'АНАЛИТИЧЕСКИЙ',
      ANY: 'ЛЮБОЙ',
      'AP/AR': 'Дебет/Кредит',
      'APPROVAL CONFIGURATION': 'КОНФИГУРАЦИЯ ПОДТВЕРЖДЕНИЙ',
      APPROVE: 'ПОДТВЕРДИТЬ',
      'AS A': 'КАК',
      ASPIRATION: 'АСПИРАЦИЯ',
      AVG: 'СРЕДНЕЕ',
      'AVG flat price': 'Средняя цена',
      'AVG packing': 'Вместительность',
      'AVG price': 'СРД цена',
      'AVG rate': 'СРЕД ставка',
      'AWB number': 'AWB номер',
      Abbreviation: 'Аббревиатура',
      'Absolute value': 'Абсолютное значение',
      'Access denied!': 'Доступ запрещен!',
      Account: 'Счет',
      'Account activity': 'Счет активность',
      'Account is active': 'Счет активный',
      'Account name': 'Имя аккаунта',
      'Accounting period': 'Отчетный период',
      Accounts: 'Счета',
      'Accounts position': 'Аккаунты позиция',
      'Acct#': '№',
      Action: 'Действие',
      Actions: 'Действия',
      Active: 'Активно',
      Activity: 'Активность',
      'Actually contracted': 'Реально законтрактовано',
      Add: 'Добавить',
      'Add agreement': 'Дополнительное соглашение',
      'Add bank details': 'Добавить банковские реквизиты',
      'Add contacts': 'Добавить контакты',
      'Add costs/gains': 'Добавить затраты/доходы',
      'Add document': 'Добавить документ',
      'Add documents': 'Добавить документы',
      'Add execution': 'Добавить исполнение',
      'Add filter': 'Добавить фильтр',
      'Add finance': 'Добавить инвойс',
      'Add finance (R/W)': 'Добавить инвойс (жд)',
      'Add finance (auto)': 'Добавить инвойс (авто)',
      'Add invoice': 'Добавить инвойс',
      'Add logistic': 'Добавить транспорт',
      'Add new': 'Добавить новый',
      'Add payment': 'Добавить оплату',
      'Add template': 'Добавить шаблон',
      'Add transport': 'Добавить транспорт',
      Added: 'Добавлено',
      Additional: 'Дополнительно',
      'Additional Info': 'Дополнительная инфо',
      'Additional aggr': 'Дополнительное соглашение',
      'Additional agreement': 'Дополнительное соглашение',
      'Additional agreement saved': 'Дополнительное соглашение сохранено',
      'Additional agreements': 'Дополнит. соглашение',
      'Additional agreements date': 'Доп соглашение дата',
      'Additional alt': 'Дополнительно альт',
      'Additional costs': 'Дополнительные затраты',
      'Additional info': 'Дополнительная инфо',
      'Additional info regarding confirmation of this company':
        'Дополнительная информация про подтверждение компании',
      Address: 'Адрес',
      'Address (eng)': 'Адрес (англ)',
      'Address Eng': 'Адрес (англ)',
      'Address eng': 'Адрес (Англ.)',
      'Admin panel': 'Админ панель',
      Affiliated: 'Аффилированный',
      'Affiliated suppliers': 'Аффилированные поставщики',
      Age: 'Возраст',
      Agent: 'Посредник',
      Agreement: 'Договор',
      "Agreement doesn't exist": 'Договор не существует',
      'Agreement limit': 'Лимит договора',
      'Agreement number': 'Номер договора',
      'Agreement removed': 'Договор удален',
      'Agreement saved': 'Договор сохранен',
      'Agreement value': 'Сумма договора',
      Agreements: 'Договора',
      Agritel: 'Агрител',
      All: 'Все',
      'All Additional Agreements has originals': 'У всех дополнительных соглашений есть оригиналы',
      'All copies': 'Все копии',
      'All counterparties of your company': 'Все ваши компании',
      'All info': 'Вся информация',
      'All of the following related items will be deleted:':
        'Все из нижеследующих объектов будут удалены:',
      'All originals': 'Все оригиналы',
      'All signed': 'Все подписали',
      'All uploaded documents': 'Все загруженные документы',
      'All users of the company': 'Все пользователи',
      'All your notifications': 'Все ваши уведомления',
      'Allocate this operation to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Поместить эту операцию на определенный счет. Этот счет будет критерием соответствия продаж и закупок в Деривативы - отчет о состоянии счета',
      'Allocate this operation to certain contract':
        'Закрепить эту операцию к определенному контракту',
      'Allocate this operation to certain passport. This passport will be a criteria to match sales and purchases in Derivatives - Contract Position report':
        'Поместите эту операцию на определенный паспорт. Этот паспорт будет критерием соответствия продаж и закупок в Деривативы - Контракт Позиция',
      Allocated: 'Распределение',
      Allocation: 'Распределение',
      Allocations: 'Распределения',
      Amount: 'Сумма',
      'Amount BL+10%': 'Сумма коносаментов + 10%',
      'Amount USD': 'Сумма USD',
      'Amount With Vat': 'Сумма с НДС',
      'Amount correction': 'Корректировка суммы',
      'Amount in offset': 'Сумма в зачете',
      'Amount local': 'Сумма локал валюта',
      'Amount of discount': 'Сумма дисконта',
      'Amount of premium': 'Сумма премии',
      'Amount of premiums for the delivered goods': 'Сумма премий за доставленный товар',
      'Amount of premiums from the contracted goods': 'Сумма премий за законтрактованный товар',
      'Amount per 1 t': 'Сумма за 1 т',
      'Amount per deal execution': 'Общая сумма за сделку',
      'Amount per t': 'Сумма за 1 т',
      'Amount per whole deal': 'Сумма за всю сделку',
      'Amount to connect': 'Свободная сумма расходной накладной',
      'Amount with VAT': 'Сумма с НДС',
      'Amount without VAT': 'Сумма без НДС',
      'Amount, $': 'Сумма, $',
      Amounts: 'Суммы',
      Analytic: 'Аналитика',
      'Analytical Amount': 'Аналитическая сумма',
      'Analytical amount': 'Аналитическая сумма',
      'Analytical price': 'Аналитическая цена',
      'Analytical price different from contract price':
        'Аналитическая цена отличается от контрактной',
      Analytics: 'Аналитика',
      'And also you can make manual updates by counterparty whether you have a meeting,\n                call, email or some commentary regarding them. To a new one go directly into the\n                Counterparty - Updates':
        'А также вы можете вручную обновлять контрагента, будь то встреча, \n                звонок, email или какой-либо комментарий по ним. Для просмотра необходимо перейти \n                в Контрагент - Обновления',
      Angry: 'Злой',
      'Applied filters': 'Примененные фильтры',
      Apply: 'Применить',
      'Apply default': 'Применить по умолчанию',
      'Appoint an amount of discount or use discount in %':
        'Укажите сумму дисконта или используйте Дисконт,%',
      'Appoint it if you want to apply discount per 1 mt':
        'Отметьте, если хотите применить дисконт на 1 т',
      'Appoint to which CONTRACT you want to allocate this charge or gain':
        'Назначьте, на какой КОНТРАКТ вы хотите распределить эту плату или прибыль',
      'Appoint to which PASSPORT you want to allocate this charge or gain in cases when contract has more than one passport and charge has to be allocated to a specific one. In other cases leave these field blank':
        'Укажите, на какой ПАСПОРТ вы хотите распределить эту затрату или доход в случаях, когда контракт имеет более одного паспорта и затрата/доход должны быть распределены на конкретный паспорт. В других случаях оставьте поле пустым',
      Approval: 'Подтверждения',
      'Approval Config': 'Конфигурация подтверждения',
      'Approval Status': 'Статус подтверждения',
      'Approval in process': 'Подтв в процессе',
      'Approval report': 'Отчет по подтверждениям',
      'Approval status': 'Статус подтверждения',
      'Approval updated': 'Подтверждение обновлено',
      Approvals: 'Подтверждения',
      'Approvals report': 'Отчет по подтверждениям',
      Approve: 'Подтвердить',
      'Approve / Decline': 'Утверждено / отклонено',
      'Approve approvals': 'Подтвердить',
      Approved: 'Утверждено',
      'Approved by month': 'Подтверждено по месяцам',
      'Approved condition': 'Подтверждено с условием',
      Approvers: 'Подтверждения',
      April: 'Апрель',
      Archived: 'Архивировано',
      'Are you sure that you want delete Cert?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete Contract Charge?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete Freight?': 'Вы уверены, что хотите удалить Фрахт?',
      'Are you sure that you want delete Lost?': 'Вы уверены что хотите удалить потери?',
      'Are you sure that you want delete Position?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete Tariff?': 'Вы уверены, что хотите удалить этот тариф?',
      'Are you sure that you want delete bill of lading?':
        'Вы уверены, что вы хотите удалить коносамент?',
      'Are you sure that you want delete parsing config?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete passport?': 'Вы уверены, что хотите удалить этот паспорт?',
      'Are you sure that you want delete this Person?':
        'Вы уверены, что хотите удалить этот Контакт?',
      'Are you sure that you want delete this agreement?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete this bank operation?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete this bank?': 'Вы уверены, что хотите удалить этот банк?',
      'Are you sure that you want delete this consignment?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete this credit note?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete this disbursement bill of lading?':
        'Вы уверены, что вы хотите удалить это?',
      'Are you sure that you want delete this document?':
        'Вы уверены, что хотите удалить документ?',
      'Are you sure that you want delete this payment plan?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete this payment?':
        'Вы уверены, что хотите удалить этот платеж?',
      'Are you sure that you want delete this template?':
        'Вы уверены, что хотите удалить этот шаблон?',
      'Are you sure that you want delete this transaction?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want delete this vehicle?': 'Вы уверены, что хотите удалить это т\\с?',
      'Are you sure that you want delete this?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want delete user?': 'Вы уверены, что хотите удалить пользователя?',
      'Are you sure that you want delete?': 'Вы уверены, что хотите удалить?',
      'Are you sure that you want to delete?': 'Вы уверены, что хотите удалить это?',
      'Are you sure that you want to set cargo confirmation?':
        'Вы уверены, что хотите установить ГРУЗ Подтвержден?',
      'Are you sure that you want to set fact volume to plans?':
        'Вы уверены, что хотите установить фактический объем вместо всех плановых?',
      'Are you sure you want to delete loan?': 'Вы уверены, что хотите удалить займ?',
      'Are you sure you want to delete quote parsing config?':
        'Вы уверены, что хотите удалить это?',
      'Are you sure you want to delete the': 'Вы уверены, что хотите удалить',
      'Are you sure?': 'Вы уверены?',
      'Area, hectare': 'Площадь, га',
      'Arrival date': 'Дата поступления',
      Assets: 'Активы',
      'Assigned to': 'Ответственный',
      'Assigned to me': 'Назначены мне',
      Assignment: 'Переназначение',
      'At discharging port': 'В порту назначения',
      'At end': 'В конце',
      'At least one company is required': 'По крайней мере одна компания обязательна',
      'At loading port': 'В порту отгрузки',
      'At road': 'В пути',
      'At start': 'В начале',
      Attach: 'Прикрепить',
      'Attach contracts to passport': 'Прикрепить контракты к паспорту',
      'Attach documents': 'Прикрепить документы',
      'Attach purchase contract': 'Прикрепить закупочный контракт',
      'Attach sale contract': 'Прикрепить продажный контракт',
      'Attach stocks': 'Привязать остатки',
      'Attach to the passport': 'Прикрепить к паспорту',
      'Attach voyage': 'Прикрепить рейс',
      'Attached to passport': 'Привязано к паспорту',
      'Attaching files': 'Прикрепляю файлы',
      Attachments: 'Вложения',
      August: 'Август',
      Author: 'Автор',
      Auto: 'Авто',
      'Auto payment conditions': 'Условия оплаты (авто)',
      'Auto prepay - balance percent': 'Авто, % предоплаты (баланс)',
      'Available passports': 'Доступные паспорта',
      'Average flat price per fixed lots': 'Средняя цена за зафиксированные лоты',
      'Average flat price per hedged lots': 'Средняя цена за захеджированные лоты',
      'Average mt price per fixed lots': 'Средняя рыночная цена за зафиксированные лоты',
      'Average price': 'Средняя цена',
      'Average purchase': 'Средняя закупочная',
      'Average purchase cost': 'Сред закуп затраты',
      'Average sale': 'Средняя продажная',
      'Avеrage commodities price, $/t with VAT': 'Средняя цена товара, дол/т с НДС',
      B: 'B',
      'B/L number': 'Коносамент номер',
      "B/L's": 'Коносаменты',
      'B/Ls total volume': 'Общий объем коносаментов',
      B2B: 'B2B',
      'BAGS WEIGHT IN (KGS)': 'ВЕС ТАРЫ В (КГС)',
      BALANCE: 'БАЛАНС',
      BI: 'ПокИнв',
      'BILLS OF LADING': 'КОНОСАМЕНТЫ',
      BL: 'Коносамент',
      'BL date': 'Дата коносамента',
      'BL max date': 'Последняя дата коносамента',
      'BL volume:': 'Объем коносаментов:',
      'BL/ETA ASC': 'BL/ETA ASC',
      'BL/ETA DESC': 'BL/ETA DESC',
      BLs: 'Коносаменты',
      'BLs are invoiced': 'Коносаменты проинвойсированы',
      BOAT: 'ЛОДКА',
      BP: 'ПокОпл',
      BU: 'БЮ',
      BUDGETS: 'БЮДЖЕТЫ',
      BUYER: 'ПОКУПАТЕЛЬ',
      'B\\L': 'Коносамент',
      "B\\L's": 'Коносаменты',
      'Bad payment amount': 'Сумма плохих платежей',
      'Bags quantity': 'Количество мешков',
      'Bags weight': 'Вес тары',
      Balance: 'Баланс',
      'Balance at the beginning': 'Баланс на начало',
      'Balance at the end': 'Баланс на конец',
      'Balance invoice': 'Баланс инвойс',
      'Balance payment': 'Балансовый платеж',
      'Balance payment dates': 'Балансовый платеж даты',
      'Balance to offset': 'Баланс для зачета',
      'Balance to settlement': 'Баланс для выставления',
      'Balance volume': 'Балансовый объем',
      'Balance, Cost / Gains': 'Баланс, затраты/доходы',
      Bank: 'Банк',
      'Bank Account': 'Банковский счет',
      'Bank Account Balance': 'Банк счет баланс',
      'Bank Account Total': 'Банк счета всего',
      'Bank Accounts': 'Банковские счета',
      'Bank Comission': 'Банк комиссия',
      'Bank Eng': 'Банк англ',
      'Bank Operations': 'Банк операции',
      'Bank account': 'Банк счет',
      "Bank account doesn't exist": 'Банк счет не существует',
      'Bank account saved': 'Банк счет сохранен',
      'Bank accounts': 'Банк счета',
      'Bank code': 'Банковский код',
      'Bank details': 'Банк. детали',
      'Bank name': 'Имя банка',
      'Bank operation': 'Банк операция',
      'Bank operation removed': 'Банк операция удалена',
      'Bank operation saved': 'Банк операция сохранена',
      'Bank operations': 'Банк операции',
      'Bank removed': 'Банк удален',
      'Bank saved': 'Банк сохранен',
      Banks: 'Банки',
      Barchart: 'Барчарт',
      Base: 'База',
      'Base, days': 'База, дни',
      'Basic metrics': 'Базовые показатели',
      Basis: 'Базис',
      'Basis Docs': 'Документ',
      'Basis Opposite': 'Встречный базис',
      BasisDoc: 'Базис Док',
      Basises: 'Базисы',
      Bcc: 'Скрытая копия',
      'Because exactly this figure participates in calculation of Passport\n                  profitability.':
        'Ведь именно эта цифра участвует в расчете рентабельности \n                  Паспорта.',
      Bid: 'Продажа',
      Bids: 'Продажи',
      Big: 'Большая',
      'Bill of Lading': 'Коносамент',
      'Bill of lading': 'Коносамент',
      'Bill of lading  saved and connected to logistics':
        'Коносамент сохранен и связанный с логистикой',
      'Bill of lading number': 'Коносамент номер',
      'Bill of lading removed': 'Коносамент удален',
      'Bill of lading saved': 'Коносамент сохранен',
      'Bills of lading': 'Коносаменты',
      Birthday: 'День Рождения',
      'Bite off some volume from this Indicator to create a Ticket':
        'Откусить некоторый объем из этого Оффера для создания Тикета',
      'Black list': 'Черный список',
      'Black listed': 'В черном списке',
      Blacklisted: 'В черном списке',
      Block: 'Блок',
      Boarded: 'Погружено',
      Boat: 'Лодка',
      Body: 'Тело',
      Borrower: 'Заемщик',
      Broker: 'Брокер',
      'Broker EDRPOU code': 'Брокер ЕДРПОУ код',
      'Broker address': 'Брокер адрес',
      'Broker can signatory docs': 'Брокер подписант доки',
      'Broker can signatory docs eng': 'Брокер подписант доки англ',
      'Broker confirm': 'Брокер подтвержден',
      'Broker confirm required': 'Брокер подтвержу необх',
      'Broker confirmation': 'Брокер подтверждение',
      'Broker cost': 'Брокер затраты',
      'Broker country': 'Брокер страна',
      'Broker country eng': 'Брокер страна англ',
      'Broker email': 'Брокер почта',
      'Broker full name eng': 'Брокер полное название англ',
      'Broker full name ru': 'Брокер полное название',
      'Broker legal address': 'Брокер адрес полный',
      'Broker legal address eng': 'Брокер адрес полный англ',
      'Broker name': 'Брокер имя',
      'Broker phone': 'Брокер телефон',
      'Broker signatory': 'Брокер подписант',
      'Broker signatory eng': 'Брокер подписант аннл',
      'Broker website': 'Брокер сайт',
      Brokers: 'Брокеры',
      Budget: 'Бюджет',
      Budgets: 'Бюджеты',
      'Busines Unit': 'Бизнес юнит',
      Business: 'Бизнес',
      'Business (date)': 'Бизнес дата',
      'Business Date': 'Бизнес дата',
      'Business Unit': 'Бизнес юнит',
      'Business Units': 'Бизнес юниты',
      'Business date': 'Бизнес дата',
      'Business unit': 'Бизнес юнит',
      'Business unit title': 'Название бизнес юнита',
      'Business units': 'Бизнес юниты',
      Buy: 'Покупка',
      'Buy quantity': 'Количество покупки',
      Buyer: 'Покупатель',
      'Buyer - the real Buyer of the deal, Seller(Supplier) - your company':
        'Покупатель - настоящий Покупатель в цепочке, Продавец (Поставщик) - ваша компания',
      'Buyer - your company, Seller(Supplier) - the real Supplier of the deal':
        'Покупатель - ваша компания, Продавец (Поставщик) - настоящий Продавец в цепочке',
      'Buyer 10 invoice': 'Покупатель баланс счет',
      'Buyer 90 invoice': 'Покупатель пред счет',
      'Buyer EDRPOU code': 'Покупатель ЕДРПОУ код',
      'Buyer ITN (Individual Tax Number)': 'Покупатель индивидуальный налоговый номер',
      'Buyer Signed': 'Покупатель подписал',
      'Buyer address': 'Покупатель адрес',
      'Buyer balance invoice': 'Покупатель баланс счет',
      'Buyer bank details': 'Банковские детали покупателя',
      'Buyer can signatory docs': 'Покупатель подписант доки',
      'Buyer can signatory docs eng': 'Покупатель подписант доки англ',
      'Buyer contract': 'Покупатель контракт',
      'Buyer contract #': 'Покупатель контракт №',
      'Buyer contract status': 'Покуп контр статус',
      'Buyer country': 'Покупатель страна',
      'Buyer country eng': 'Покупатель страна англ',
      'Buyer email': 'Покупатель почта',
      'Buyer full name': 'Покупатель полное название',
      'Buyer full name eng': 'Покупатель полное название английский',
      'Buyer invoice volume': 'Покуп инвойс объем поле',
      'Buyer invoiced': 'Покупатель инвойс',
      'Buyer is required': 'Покупатель обязателен',
      'Buyer legal address': 'Покупатель адрес полный англ',
      'Buyer legal address eng': 'Покупатель адрес полный англ',
      'Buyer paid': 'Покупатель оплата',
      'Buyer passports #': 'Покупатель паспорта №',
      'Buyer phone': 'Покупатель телефон',
      'Buyer prepay invoice': 'Покупатель пред счет',
      'Buyer signatory': 'Покупатель подписант',
      'Buyer signatory eng': 'Покупатель подписант англ',
      'Buyer signed': 'Покупатель подписал',
      'Buyer tax info': 'Покупатель налоговое инфо',
      'Buyer website': 'Покупатель сайт',
      Buyers: 'Покупатели',
      CALCULATOR: 'КАЛЬКУЛЯТОР',
      CALENDAR: 'КАЛЕНДАРЬ',
      CARGO: 'ГРУЗ',
      'CASH FLOW RECREATE': 'ДЕНЕЖНЫЙ ПОТОК ПЕРЕСОЗДАТЬ',
      CASHFLOW: 'ДЕНЕЖНЫЙ ПОТОК',
      CERTS: 'СЕРТИФИКАТЫ',
      CHAIN: 'ЦЕПОЧКА',
      CHARGES: 'ЗАТРАТЫ',
      'CHECK LIST': 'ЧЕК ЛИСТ',
      'CHOOSE FAVORITES': 'ВЫБРАТЬ ИЗБРАННЫЕ',
      'CHOOSE USER': 'ВЫБРАТЬ ПОЛЬЗОВАТЕЛЯ',
      CLOSED: 'ЗАКРЫТО',
      COMMENTS: 'КОММЕНТАРИИ',
      COMMODITY: 'ТОВАР',
      COMPANIES: 'КОМПАНИИ',
      COMPANY: 'КОМПАНИЯ',
      COMPLIANCE: 'КОМПЛАЙЕНС',
      'CONCLUSION DATE': 'ДАТА ЗАКЛЮЧЕНИЯ',
      CONDITIONS: 'УСЛОВИЯ',
      CONFIG: 'КОНФИГУРАЦИЯ',
      CONFIRMATIONS: 'Подтверждения',
      CONSIGNMENTS: 'НАКЛАДНЫЕ',
      CONTACTS: 'КОНТАКТЫ',
      CONTRACT: 'КОНТРАКТ',
      'CONTRACT DOCUMENTS': 'ДОКУМЕНТЫ КОНТРАКТА',
      'CONTRACT VALUE': 'СУММА КОНТРАКТОВ',
      CONTRACTS: 'КОНТРАКТЫ',
      CONTROL: 'КОНТРОЛЬ',
      COSTS: 'ЗАТРАТЫ',
      'COSTS OF GOODS': 'СЕБЕСТОИМОСТЬ',
      COUNTERPARTIES: 'КОНТРАГЕНТЫ',
      'CP date': 'Дата чартер партии',
      'CP/Seeds Prepayments': 'CP/Seeds предоплаты',
      'CREATE A FILTER': 'СОЗДАТЬ ФИЛЬТР',
      'CREATION DATE': 'ДАТА СОЗДАНИЯ',
      CREDIT: 'КРЕДИТ',
      CRM: 'CRM',
      CTL: 'ЦТЛ',
      'CTRM system for commodity traders': 'Система CTRM для товарных трейдеров',
      CURRENCY: 'ВАЛЮТА',
      'Calc intermediate logistics': 'Расчёт промежуточной логистики',
      'Calculate auto tariff': 'Расчет авто тариф',
      'Calculate broker cost': 'Кальк брокер затрата',
      'Calculate elevator costs': 'Расчет перевалка',
      'Calculate profit and loss': 'Расчет PnL',
      'Calculate railway tariff': 'Расчет жд тариф',
      'Calculate tax profit to pay': 'Кальк налог',
      Calculator: 'Калькулятор',
      Calendar: 'Календарь',
      Call: 'Звонок',
      'Can access Admin': 'Может заходить в Админку',
      'Can confirm': 'Может подтверждать',
      Cancel: 'Отмена',
      Canceled: 'Отменено',
      Cancelled: 'Отмена',
      'Cancelled?': 'Отмена?',
      'Cannot delete {[{ $ctrl.results.object_model }]}':
        'Нельзя удалить {[{ $ctrl.results.object_model }]}',
      Capacity: 'Вместительность',
      'Capacity avg': 'Вместительность сред',
      'Capacity est': 'Вместительность ожид',
      'Capacity max': 'Вместительность макс',
      'Capacity min': 'Вместительность мин',
      Car: 'Авто',
      Cargo: 'Груз',
      'Cargo Price': 'Цена за груз',
      'Cargo all': 'Груз все',
      'Cargo brand': 'Бренд груза',
      'Cargo confirmation': 'Груз подтверждение',
      'Cargo eng': 'Груз англ',
      'Cargo executed': 'Исполнен по грузу',
      'Cargo is required': 'Груз обязателен',
      'Cargo partial executed': 'Частично исполнен',
      'Cargo receiver': 'Получатель груза',
      'Cargo shipper': 'Грузоотправитель',
      Carrier: 'Перевозчик',
      'Cars prepay - balance percent': 'Авто предоплата – процент баланса',
      Cash: 'Наличные',
      'Cash Flow': 'Денежный поток',
      'Cash Flow report': 'Денежный поток (отчет)',
      'Cash Start': 'Остаток на начало',
      'Cash flow': 'Денежный поток',
      'Cash flow recreated': 'Денежный поток воссоздан',
      Cashflow: 'Денежный поток',
      Cc: 'Копия',
      'Certificate Dates': 'Даты сертификации',
      'Certificates exist in following logistics. Do you want to override them?':
        'Сертификаты уже существуют в этих т\\с. Вы хотите переопределить их?',
      Certs: 'Сертификаты',
      Change: 'Изменить',
      'Change password': 'Поменять пароль',
      'Change the status of contract to shipment': 'Поменять статус контракта на Исполнение',
      Changed: 'Изменен',
      Charge: 'Затрата',
      'Charge amount': 'Сумма затраты',
      Charges: 'Затраты',
      'Charges / Gains': 'Затраты / Доходы',
      'Charges types': 'Типы затрат',
      Chart: 'График',
      'Charter party (date)': 'Дата чартер партии',
      'Charter party date': 'Дата чартер партии',
      Charterer: 'Перевозчик',
      'Charterer is owner': 'Чартере - владелец',
      Charts: 'Графики',
      Check: 'Отметка',
      'Check content': 'Проверить данные',
      'Check costs': 'Проверить затраты',
      'Check destination': 'Проверить назначение',
      'Check execution': 'Проверить исполнение',
      "Check if everything's allright": 'Проверьте, все ли в порядке',
      'Check invoices': 'Проверить инвойс',
      'Check list of docs is set up': 'Проверка списка документов настроена',
      'Check point of departure': 'Проверить отправку',
      'Check the position': 'Проверить позицию',
      'Check this if LOI was issued': 'Проверьте, был ли выдан LOI',
      'Check this if the counterparty in invoice is different than the one in the contract':
        'Отметьте, если контрагент инвойса отличается от контрагента указанного в контракте',
      'Check this if the purchase of certified products on iscc standards':
        'Отметьте это если закупка серт продуктов соответствует iscc стандартам',
      'Check this if this supplier is distributor.':
        'Выберите если поставщик является дистрибьютором.',
      'Check this if you don`t want to check with SINGING DAYS QUANTITY value':
        'Выберите, если не хотите проверять КОЛИЧЕСТВО ДНЕЙ ПОДПИСАНИЯ',
      'Check this if you to show basis-port-price widget':
        'Отметьте, если хотите указать несколько цен',
      'Check this if you to show derivatives widget': 'Отметьте, если хотите указать несколько цен',
      'Check this if you to show few stations': 'Отметьте это чтобы показать несколько станций',
      'Check this if you want contract name to be auto generated':
        'Отметьте, если хотите, чтобы номер контракта сгенерировался автоматически',
      'Check this if you want finance number to be auto generated':
        'Отметьте, если хотите, чтобы номер инвойса сгенерировался автоматически',
      'Check this if you want loan name to be auto generated':
        'Отметьте, если хотите, чтобы номер займа сгенерировался автоматически',
      'Check this if you want number to be auto generated':
        'Отметьте, если хотите, чтобы номер сгенерировался автоматически',
      'Check this if you want to display BLACKLIST label alongside company name':
        'Отметьте, если вы хотите отображать значок ЧЕРНЫЙ СПИСОК рядом с названием компании',
      'Check this if you want to make contract to swap type': 'СВОП контракт',
      'Check this if you want to parse this quote':
        'Отметьте, если вы хотите парсить эту котировку',
      'Check this if you want to use broker tariff in price calculate':
        'Отметьте это если хотите чтобы брокер тариф посчитался в калькуляторе',
      'Check this if you want to use derivative': 'Отметьте, если хотите использовать дериватив',
      'Check this if you want to use it as internal chain':
        'Отметьте, если хотите использовать как внутреннюю цепочку',
      'Check this if you want to use tax profit to pay in price calculate':
        'Отметьте это если хотите чтобы налог посчитался в калькуляторе',
      'Check this to avoid standard KPI evaluation process':
        'Отметьте это, чтобы избежать стандартного процесса оценки KPI',
      'Check this, if current company buy or sell in USD':
        'Убедитесь, что текущая компания покупает или продает в валюте USD',
      'Check this, if current company buy or sell only in local currency':
        'Убедитесь, что текущая компания покупает или продает только в локальной валюте',
      'Check this, if current company is an Agent and not a Producer':
        'Отметьте, если данная компания является Посредником, а не Производителем',
      'Check this, if the Supplier of Farm is an original producer':
        'Отметьте, если Поставщик или Хозяйство является Производителем',
      'Check volume': 'Проверьте объем',
      'Check warehouse': 'Проверить склад',
      'Check your personal settings': 'Проверьте персональные настройки',
      'Child commodity': 'Дочерняя культура',
      'Child crop': 'Дочерняя культура',
      Choose: 'Выбрать',
      'Choose Condition': 'Выберите условия',
      'Choose Multipassport': 'Выбрать мультипаспорт',
      'Choose a range': 'Выберите диапазон',
      'Choose a template': 'Выберите шаблон',
      'Choose approve status': 'Выбрать статус подтверждения',
      'Choose assignment': 'Выбрать переназначение',
      'Choose cargo brand': 'Выбрать бренд культуры',
      'Choose charterer': 'Выбрать перевозчика',
      'Choose commodity': 'Выбрать товар',
      'Choose compliance status': 'Выбрать статус подтверждения',
      'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up':
        'Выберите конфигурацию, чтобы запустить процесс подтверждения этого объекта. Вам нужно сначала настроить конфигурацию в разделе Админ, а здесь просто выбрать',
      'Choose consignment': 'Выберите накладную',
      'Choose contract': 'Выберите контракт',
      'Choose counterparty': 'Выберите контрагента',
      'Choose counterparty and currency': 'Выберите контрагента и валюту',
      'Choose custom status': 'Выберите пользов. статус',
      'Choose date filter': 'Фильтр по дате',
      'Choose disbursement bill of lading': 'Выбрать расходную накладную',
      'Choose from predefined list of types. To add new one click +':
        'Выберите из заранее определенного списка типов. Для добавления нового нажмите +',
      'Choose invoice': 'Выберите инвойс',
      'Choose invoice type': 'Выберите тип инвойса',
      'Choose invoicing weight': 'Выберите тоннаж инвойса',
      'Choose manager': 'Выбрать менеджера',
      'Choose margin': 'Выберите маржу',
      'Choose one or more default incoterms on which this Supplier or Buyer is selling or buying':
        'Выберите один или несколько базисов по умолчанию, на которых Поставщик или Покупатель продает или покупает',
      'Choose operation type': 'Выберите тип операции',
      'Choose option': 'Выбрать опцию',
      'Choose options': 'Выбрать опцию',
      'Choose port': 'Выбрать порт',
      'Choose prepay conditions': 'Выберите условия предоплаты',
      'Choose program type': 'Выберите тип программы',
      'Choose purchase contract': 'Выберите закупочный контракт',
      'Choose relations': 'Выберите отношение',
      'Choose resource': 'Выбрать ресурс',
      'Choose role': 'Выбрать контрагента',
      'Choose ship classification': 'Выбрать класс лодки',
      'Choose size': 'Выберите размер',
      'Choose status': 'Статус',
      'Choose tax registration': 'Выбрать налог регистрацию',
      'Choose template': 'Выберите шаблон',
      'Choose terminal': 'Выберите терминал',
      'Choose the Excel template you want to send. It will be an excel spreadsheet\n                        attached to a letter. You can choose the order and naming of columns':
        'Выберите шаблон Excel, который вы хотите отправить. Это будет таблица Excel, прикрепленная к письму. \n                        Вы можете выбрать порядок и название столбцов',
      'Choose the block, to which this template will be attached.This means, you might use it only within this block':
        'Выберите блок, к которому прикреплять шаблоны. Это означает, что вы сможете использовать их в рамках этого блока',
      'Choose the email template you want to send. It can be customized for every\n                        client':
        'Выберите шаблон email, который вы хотите отправить. Он может быть настроен для каждого \n                        контрагента',
      'Choose the file on computer': 'Выберите файл на компьютере',
      'Choose the range': 'Диапазон',
      'Choose type': 'Выберите тип',
      'Choose type of invoice': 'Выберите тип инвойса',
      'Choose vessel': 'Выберите лодку',
      'Choose who decide the option': 'Кто выбирает опцию',
      'Choose year': 'Выберите год',
      'Choosed quality list': 'Выбранный список качеств',
      'Chose a role of a counterparty to be bound to a finance':
        'Выберите роль или контрагента для того, чтобы связать с инвойсом',
      'Chose new filter...': 'Выбрать новый фильтр…',
      Cities: 'Города',
      City: 'Город',
      Cleaning: 'Чистка',
      Clear: 'Очистить',
      'Clear filters': 'Очистить фильтры',
      'Click this checkbox if you want to include all package of documents by all\n                        logistics you want to send':
        'Отметьте этот пункт, если хотите включить весь пакет документов\n                         по всей логистике, которую хотите отправить',
      Client: 'Клиент',
      'Client & cargo': 'Контрагент и товар',
      'Client Document Type': 'Тип документа',
      'Client Role': 'Роль контрагента',
      'Client author': 'Автор',
      'Client role': 'Роль контрагента',
      'Client to': 'Контрагент ',
      'Clientrole from': 'Клиент от кого',
      'Clientrole to': 'Клиент кому',
      Clients: 'Юр. лица',
      Clone: 'Клонировать',
      Close: 'Закрыть',
      'Close contract': 'Закрыть контракт',
      'Close filters': 'Закрыть фильтры',
      'Close modal?': 'Закрыть окно?',
      'Close price': 'Цена закрытия',
      Closed: 'Закрыто',
      'Closing of existing position': 'Закрытие существующей позиции',
      Code: 'Код',
      Cold: 'Холодный',
      Collateral: 'Обеспечение',
      'Collateral amount': 'Объем обеспечения',
      Collection: 'Конфигурация',
      'Collection title': 'Конфигурация название',
      'Column name': 'Название колонки',
      'Comm fees': 'Комиссии',
      'Comm fees,$': 'Комиссии, дол',
      'Comm. fees': 'Комиссии',
      Comment: 'Комментарий',
      Commentary: 'Комментарий',
      'Commentary successfully deleted.': 'Комментарий удален.',
      'Commentary successfully saved.': 'Комментарий сохранен.',
      Comments: 'Комментарии',
      Commercial: 'Коммерческий',
      'Commercial Invoice': 'Коммерческий инвойс',
      Commission: 'Комиссия',
      'Commission amount': 'Комиссия сумма',
      'Commission credit account': 'Комиссия кредит счет',
      'Commission debit account': 'Комиссия дебет счет',
      Commodities: 'Товары',
      Commodity: 'Продукт',
      'Commodity Balance': 'Баланс по грузу',
      'Commodity default': 'Продукт по умолчанию',
      'Commodity level': 'Товарный уровень',
      'Commodity opposite': 'Товар встречный',
      Companies: 'Компании',
      Company: 'Компания',
      'Company Group': 'Группа компаний',
      'Company from': 'Компания от',
      'Company group': 'Группа компаний',
      'Company groups': 'Группы компаний',
      'Company name': 'Название компании',
      'Company parameters': 'Параметры компании',
      'Company to': 'Компания кому',
      'Company with whom we have these charge or gain, not a contract counterparty':
        'Компания, с которой у нас есть эти затраты или доход, не является контрагентом по контракту',
      'Complete compliance': 'Комплаенс завершен',
      Compliance: 'Комплайенс',
      'Compliance status': 'Статус комплаенса',
      Conclusion: 'Заключение',
      'Conclusion (date)': 'Дата заключения',
      'Conclusion Date': 'Дата заключения',
      'Conclusion date': 'Дата заключения',
      'Conclusion date currency': 'Котировка на день заключения',
      'Conclusion date exchange rate': 'Курс валют на дату заключения',
      'Conclusion date flat price': 'Цена на дату заключения',
      Condition: 'Условие',
      Conditions: 'Условия',
      Configuration: 'Конфигурация',
      Configure: 'Настроить',
      'Configure fields': 'Настроить поля',
      Confirm: 'Подтвердить',
      'Confirm New Password': 'Подтвердить новый пароль',
      Confirmation: 'Подтверждение',
      'Confirmation (date)': 'Дата подтверждения',
      'Confirmation date': 'Дата подтверждения',
      'Confirmation info': 'Информация о подтверждении',
      Confirmations: 'Подтверждения',
      Confirmed: 'Подтверждено',
      Connect: 'Соединить',
      'Connect Documents': 'Связать документы',
      'Connect contract to existing passport': 'Связать контракт с существ. паспортом',
      'Connect logistics': 'Соединение с логистикой',
      'Connect to existing passport': 'Связать с существ. паспортом',
      'Connect to passport': 'Связать с паспортом',
      'Connect to purchase': 'Привязать к закупке',
      'Connect to sale': 'Привязать к продаже',
      'Connect to {[{ $root.user.settings.PASSPORT_TITLE }]}':
        'Связать с {[{ $root.user.settings.PASSPORT_TITLE }]}',
      'Connect with purchase': 'Привязать к закупке',
      'Connect with purchase contract': 'Привязать к закупочному контракту',
      'Connect with sale': 'Привязать к продаже',
      'Connect with sale contract': 'Привязать к продажному контракту',
      'Connect wrong crops?': 'Связать с разными культурами?',
      Connected: 'Связано',
      'Connected passports': 'Привязанные паспорта',
      'Connected volume': 'Привязанный объем',
      'Connecting...': 'Соединяем...',
      Connections: 'Привязки',
      'Connections updated.': 'Привязки обновлены.',
      'Consecutive voyages': 'Последовательные рейсы',
      Consignee: 'Грузополучатель',
      Consignment: 'Накладная (№)',
      'Consignment (#)': 'Номер накладной',
      'Consignment contract commodities': 'Культура накладных контракта',
      'Consignment date': 'Дата накладной',
      'Consignment number': 'Номер накладной',
      'Consignment removed': 'Накладная удалена',
      'Consignment saved': 'Накладная сохранена',
      Consignments: 'Накладные',
      Consolidated: 'Консолидированное инфо',
      'Consolidated Contract PnL': 'Консолидированный контракт PnL',
      'Consolidated PnL': 'Консолидированный ПнЛ',
      'Consolidated crop position': 'Консолидированная позиция культуры',
      Contact: 'Контакт',
      'Contact date expired': 'Дата контакта просрочена',
      'Contact first time': 'Дата первого контакта',
      'Contact info': 'Контактная информация',
      'Contact next time': 'Связаться в след раз',
      'Contact today': 'Связаться сегодня',
      Contacted: 'Контакт',
      Contacts: 'Контакты',
      Container: 'Контейнер',
      Continue: 'Продолжить',
      Contract: 'Контракт',
      'Contract #': 'Контракт №',
      'Contract - Execution': 'Контракт - Исполнение',
      'Contract Charges / Gains': 'Контракты Затраты / Доходы',
      'Contract Charges 1t': 'Контракт затраты за 1т',
      'Contract DSTU': 'Контракт ДСТУ',
      'Contract Number': 'Номер контракта',
      'Contract Option': 'Контракт опция',
      'Contract Owner': 'Контракт владелец',
      'Contract P&L': 'Контракт PnL',
      'Contract Plans': 'Планы отгрузок',
      'Contract Status': 'Контракт статус',
      'Contract Sum': 'Контракт сумма',
      'Contract amount': 'Сумма контрактов',
      'Contract can have only one warehouse': 'Контракт может иметь только один склад',
      'Contract charge': 'Затрата контракта',
      'Contract charge saved': 'Затрата контракта сохранена',
      'Contract charge updated': 'Затрата контракта обновлена',
      'Contract charges': 'Контракт затраты',
      'Contract conclusion date': 'Дата заключения контракта',
      'Contract connected.': 'Контакт прикреплен.',
      'Contract crop year': 'Контракт год урожая',
      'Contract crops': 'Контракт культуры',
      'Contract deal type': 'Тип сделки',
      'Contract details': 'Контракт детали',
      "Contract doesn't exist": 'Контракт не существует',
      'Contract executed': 'Контакт исполнен',
      'Contract executed. Containes three confirmation. Cargo - everything is ok with a delivery. Payment - everything is ok with payments. Final - everything is ok in general, and the contract might be considered as executed':
        'Контракт исполнен. Включает три показателя. Груз - когда все хорошо с доставкой. Платеж - когда все хорошо с оплатами. Финал - когда везде все хорошо и контракт можно считать выполненым',
      'Contract info': 'Контракт примеч',
      'Contract margin': 'Маржа по контрактам',
      'Contract margin differs': 'Маржа по контрактам отличается',
      'Contract notes': 'Контракт примеч',
      'Contract number': 'Номер контракта',
      'Contract numbers': 'Номера контрактов',
      'Contract option': 'Опция',
      'Contract ports': 'Контракт порты',
      'Contract price': 'Цена контракта',
      'Contract quantity': 'Контракт количество',
      'Contract removed': 'Контракт удален',
      'Contract saved': 'Контракт сохранен',
      'Contract stock (t)': 'Остатки контракт (т)',
      'Contract stock amount': 'Контракт остаток сумма',
      'Contract type': 'Тип контракта',
      'Contract updated': 'Контракт обновлен',
      'Contract value': 'Сумма контракта',
      'Contract value in UAH': 'Стоимость',
      'Contract volume': 'Контрактный объем',
      'Contract volume (t)': 'Контрактный объем (т)',
      "Contract's Costs Opposite": 'Затраты встречного контракта',
      "Contract's Flat Price": 'Контракт флэт цена',
      "Contract's Flat Price Opposite": 'Контракт цена встречная',
      "Contract's Open Volume": 'Контракт открытый объем',
      "Contract's Premium Price": 'Контракт премиум цена',
      "Contract's Premium Price Opposite": 'Контракт премиум цена встречная',
      ContractCharge: 'Контракт затрата',
      ContractQuality: 'Контракт качество',
      Contracted: 'Законтрактовано',
      Contracts: 'Контракты',
      'Contracts Execution': 'Исполнение контрактов',
      'Contracts analytical': 'Аналитика контрактов',
      'Contracts created from this stock': 'Контракты, созданные из этого остатка',
      'Contracts import': 'Контракты импорт',
      'Contracts quantity': 'Объем контрактов',
      'Contracts should have same currency.': 'У контрактов должны совпадать валюты.',
      'Contracts status updated': 'Статус контрактов обновлен',
      'Contracts total value': 'Общая сумма контрактов',
      'Contracts totals': 'Контракт тотал',
      'Contracts updated': 'Контракт обновлен',
      'Contracts value': 'Стоимость контрактов',
      Control: 'Контроль',
      'Control created': 'Контроль создан',
      'Control margin': 'Контроль маржи',
      'Controlled margin': 'Проконтролированная маржа',
      Controller: 'Проконтролировавший',
      'Controls on a page': 'Управление на странице',
      'Convert to contract': 'Преобразовать в контракт',
      Copy: 'Скопировать',
      'Copy to clipboard': 'Копировать',
      'Corr account': 'Корр аккаунт',
      'Corr account 1': 'Корр. счет 1',
      'Corr account 2': 'Корр. счет 2',
      'Corr bank': 'Банк корреспондент 1',
      'Corr bank 1': 'Банк корреспондент 2',
      'Corr bank 2': 'Корр. счет 2',
      'Corr iban': 'Корр IBAN',
      'Corr iban 1': 'Корр IBAN 1',
      'Corr iban 2': 'Корр IBAN 2',
      'Corr swift': 'Корр свифт',
      'Corr swift 1': 'Корр. SWIFT 1',
      'Corr swift 2': 'Корр. SWIFT 2',
      Cost: 'Затраты',
      'Cost invoice': 'Инвойс на затраты',
      'Cost of goods sold': 'Себестоимость',
      'Cost of production mUSD, with VAT': 'Стоимость производства USD с НДС',
      Costs: 'Затраты',
      'Costs VAT': 'Затраты НДС',
      'Costs of goods': 'Стоимость товара',
      'Costs opposite': 'Встречные затраты',
      'Costs report': 'Отчет по затратам',
      Count: 'Счетчик',
      Counterparties: 'Контрагенты',
      'Counterparties in contracts is not unique': 'Контрагенты в контракте не уникальны',
      Counterparty: 'Контрагент',
      'Counterparty amount': 'Сумма контрагента',
      'Counterparty bank': 'Банк контрагента',
      'Counterparty bank account': 'Банковский счет контрагента',
      'Counterparty business reference': 'Контрагент бизнес референс',
      'Counterparty confirmation. Completed if this Counterparty is confirmed by your organization':
        'Подтверждение контрагента. Успешно, если Контрагент подтвержден вашей компанией (в редактировании Контрагента)',
      'Counterparty confirmed': 'Контрагент подтвержден',
      'Counterparty created': 'Контрагент создан',
      'Counterparty from': 'Компания от',
      'Counterparty saved': 'Контрагент сохранен',
      'Counterparty to': 'Контрагент которому',
      Countries: 'Страны',
      Country: 'Страна',
      'Country, city, street, app': 'Страна, город, улица, номер',
      'Counts by month': 'Кол-во по месяцам',
      Create: 'Создать',
      'Create Balance Invoice': 'Создать баланс инвойса',
      'Create Contract from this Ticket. Ticket will become Executed':
        'Создать контракт из этого Тикета. Тикет станет Выполненым',
      'Create Email Template': 'Создать шаблон',
      'Create Prepayment Invoice': 'Создать предоплатный инвойс',
      'Create Screen Capture Task': 'Создать задачу захвата экрана',
      'Create Supplier': 'Создать поставщика',
      'Create Ticket': 'Создать Тикет',
      'Create a collection': 'Сохранить коллекцию',
      'Create a document': 'Создать документ',
      'Create a letter': 'Создать письмо',
      'Create a new passport': 'Создать новый паспорт',
      'Create a template': 'Создать шаблон',
      'Create an email': 'Создать письмо',
      'Create approvals for declined': 'Создать подтвержд для отмененных',
      'Create contract': 'Создать контракт',
      'Create contracts': 'Создание контрактов',
      'Create contracts from tickets': 'Создать контракты из тикетов',
      'Create date': 'Дата создания',
      'Create document': 'Создать документ',
      'Create invoice': 'Создать инвойс',
      'Create invoices': 'Создать инвойсы',
      'Create multicontract': 'Создать мультиконтракт',
      'Create operation': 'Создать операцию',
      'Create passport': 'Создать \nпаспорт',
      'Create passport with this contract': 'Создать паспорт с этим контрактом',
      'Create payment plan': 'Создать план оплат',
      'Create rolling': 'Создать роллинг',
      'Create ticket': 'Создать Тикет',
      'Create time': 'Время создания',
      'Create transport': 'Создать транспорт',
      'Create user': 'Создать пользователя',
      'Create vessel': 'Создать лодку',
      'Create voyage': 'Создать рейс',
      'Create your favorite Filter in Right Sidebar and it will appear here':
        'Создайте свой любимый фильтр в правой боковой панели и он появится здесь',
      Created: 'Создано',
      'Created via multicontract': 'Создано через мультиконтракт',
      Creation: 'Создание',
      Credit: 'Кредит',
      'Credit Account': 'Кредит счет',
      'Credit Bank Account': 'Кредит банк счет',
      'Credit Counterparty': 'Кредит контрагент',
      'Credit Finance Account': 'Кредит финанс счет',
      'Credit Finance Account (commission)': 'Кредит финанс счет (комиссия)',
      'Credit Finance account': 'Кредит финанс счет',
      'Credit Note': 'Кредит ноут',
      'Credit Notes': 'Кредит ноут',
      'Credit Report': 'Отчет по рискам',
      'Credit account': 'Кредит счет',
      'Credit bank account': 'Кредит банк счет',
      'Credit counterparty': 'Кредит контрагент',
      'Credit note': 'Кредит ноут',
      'Credit note number': 'Кредит ноут номер',
      'Credit note payments': 'Кредит ноут оплаты',
      'Credit note removed': 'Кредит ноут удален',
      'Credit note saved': 'Кредит ноут сохранен',
      'Credit note updated': 'Кредит ноут обновлен',
      'Credit notes': 'Кредит ноуты',
      'Credit report': 'Отчет по рискам',
      'Credit risk': 'Кредитный риск',
      'Creditor Balance': 'Кредитор баланс',
      Critical: 'Критично',
      Crop: 'Культура',
      'Crop DSTU': 'Культура ДСТУ',
      'Crop Groups': 'Группы товаров',
      'Crop Year': 'Год урожая',
      'Crop area': 'Посевная площадь',
      'Crop class': 'Класс',
      'Crop price created': 'Культура цена создана',
      'Crop price deleted': 'Культура цена удалена',
      'Crop prices deleted': 'Цены удалены',
      'Crop protection': 'Защита урожая',
      'Crop year': 'Год урожая',
      Ct: 'Кр',
      Currencies: 'Валюты',
      Currency: 'Валюта',
      'Currency Exchange': 'Обменный курс',
      'Currency Rate': 'Обменный курс',
      'Currency and rate': 'Валюта и курс',
      'Currency exchange': 'Обменный курс',
      'Currency is required': 'Валюта обязательна',
      'Currency opposite': 'Валюта встречная',
      'Currency rate': 'Обменный курс',
      'Current Password': 'Текущий пароль',
      'Current address': 'Фактический адрес',
      'Current derivative': 'Текущий дериватив',
      'Current flat price': 'Текущая цена',
      'Current goal': 'Текущая цель',
      'Current opposite flat price': 'Текущая встречная цена',
      'Custom filter': 'Свой фильтр',
      'Custom margin': 'Настраиваемая маржа',
      'Custom roles': 'Пользовательские роли',
      'Custom status': 'Пользовательский статус',
      'Customize a fields': 'Персонализация полей',
      'Customize fields': 'Настроить поля',
      DASHBOARD: 'ПАНЕЛЬ',
      'DAT Self cost': 'DAT себестоимость',
      'DATE RANGE': 'ПЕРИОД',
      DATES: 'ДАТЫ',
      'DBL SAP registered': 'DBL SAP зарегистрирован',
      'DBL amount': 'Расходная накладная сумма',
      'DBL are tax registered': 'Расходная накладная налог зарег',
      'DBL volume': 'Расходная накладная объем',
      DEALS: 'СДЕЛКИ',
      DEBIT: 'ДЕБЕТ',
      DECLINE: 'ОТКАЗАТЬ',
      DELIVERY: 'ДОСТАВКА',
      DERIVATIVES: 'ДЕРИВАТИВЫ',
      'DESTINATION 1ST PORT': 'ПОРТ НАЗНАЧЕНИЯ 1-Й',
      'DESTINATION FINAL': 'КОНЕЧНЫЙ ПУНКТ НАЗНАЧЕНИЯ',
      DETAILS: 'ДЕТАЛИ',
      DIFF: 'РАЗНИЦА',
      'DISBURSMENT BILL OF LADING': 'РАСХОДНАЯ НАКЛАДНАЯ',
      'DISBURSMENT BILL OF LADINGS': 'РАСХОДНЫЕ НАКЛАДНЫЕ',
      DISPATCH: 'ОТПРАВЛЕНИЕ',
      DOCS: 'ДОКИ',
      DOCUMENTS: 'ДОКУМЕНТЫ',
      'DOCUMENTS DATES': 'ДАТЫ ДОКУМЕНТОВ',
      'DUE DATE': 'ДЕДЛАЙН',
      Daily: 'Ежедневный',
      Dark: 'Темная',
      Dashboard: 'Панель',
      Data: 'Данные',
      Date: 'Дата',
      'Date date': 'Дата',
      'Date from': 'Дата с',
      'Date of Disbursement bill of lading': 'Дата расходной накладной',
      'Date of berth': 'Дата причаливания',
      'Date of berth destination': 'Дата причаливания (выгрузка)',
      'Date of berth loading': 'Дата причаливания (отгрузка)',
      'Date of certification': 'Дата сертификации',
      'Date of conduction': 'Дата проведения',
      'Date of discharging end': 'Дата выгрузки конец',
      'Date of discharging start': 'Дата выгрузки старт',
      'Date of documents withdrawal': 'Дата изъятия документов',
      'Date of execution': 'Начало выполнения',
      'Date of execution fact': 'Дата платежа (факт)',
      'Date of future contact': 'Следующий контакт дата',
      'Date of issuance': 'Дата выставления',
      'Date of last contact': 'Дата последнего контакта',
      'Date of loading end': 'Дата отгрузки конец',
      'Date of loading start': 'Дата отгрузки старт',
      'Date of notice': 'Дата нотиса',
      'Date of notice destination': 'Дата нотиса (выгрузка)',
      'Date of notice loading': 'Дата нотиса (загрузка)',
      'Date of payment': 'Дата платежа',
      'Date of payment (fact)': 'Дата платежа (факт)',
      'Date of payment (plan)': 'Дата платежа (план)',
      'Date of receiving': 'Дата получения',
      'Date of withdrawal': 'Дата списания',
      'Date range': 'Период',
      'Date range of execution': 'Период исполнения',
      'Date to': 'Дата до',
      'Date, when the company was confirmed by us first time':
        'Дата, когда компания была впервые подтверждена нами',
      'Date, when the company was officially confirmed by us':
        'Дата, когда компания была официально подтверждена нами',
      'Date, when the documents were received from the company':
        'Дата, когда документы были получены от этой компании',
      'Date, when the documents were requested from the company':
        'Дата, когда документы были запрошены у этой компании',
      'Dates are reqired': 'Даты обязательны',
      Day: 'День',
      Days: 'Дней',
      'Days at port': 'Дней в порту',
      'Dbl date': 'Расходная накладная дата',
      'Dead freight': 'Мерт фрахт',
      'Deal type': 'Тип сделки',
      Deals: 'Сделки',
      Debit: 'Дебет',
      'Debit Account': 'Дебет счет',
      'Debit Bank Account': 'Дебет банк счет',
      'Debit Counterparty': 'Дебет контрагент',
      'Debit Credit': 'Дебет кредит',
      'Debit Finance Account': 'Дебет финанс счет',
      'Debit Finance Account (commission)': 'Дебет финанс счет (комиссия)',
      'Debit Finance account': 'Дебет финанс счет',
      'Debit Note': 'Дебет ноут',
      'Debit account': 'Дебет счет',
      'Debit bank account': 'Дебет банк счет',
      'Debit counterparty': 'Дебет контрагент',
      'Debitor Balance': 'Дебитор баланс',
      December: 'Декабрь',
      Declaration: 'Декларация',
      'Declaration required': 'Декларация обязательна',
      Decline: 'Отказать',
      'Decline approvals': 'Отказано',
      Declined: 'Отвергнуто',
      Default: 'По умолчанию',
      'Default contracts view': 'Интерфейс контрактов по умолчанию',
      'Default counterparties view': 'Интерфейс контрактов по умолчанию',
      'Default deals': 'Сделки по умолчанию',
      'Default invoices view': 'Интерфейс инвойсов по умолчанию',
      'Default persons view': 'Интерфейс контактов по умолчанию',
      'Default prepayment with this counterparty. You can specify another in each contract':
        'Предоплата по умолчанию с этим контрагентом. Вы можете указать другую в каждом контракте',
      'Default vessels view': 'Интерфейс лодок по умолчанию',
      'Default voyages view': 'Интерфейс рейсов по умолчанию',
      'Default warehouse': 'Склад по умолчанию',
      Delete: 'Удалить',
      'Delete payment?': 'Удалить оплату?',
      'Delete price': 'Удалить цену',
      Deleted: 'Удалено',
      'Deleting the': 'Удаляя',
      Delivered: 'Доставлено',
      Deliverer: 'Экспедитор',
      Delivery: 'Доставка',
      'Delivery (end date)': 'Доставка (окончание)',
      'Delivery (start date)': 'Доставка (начало)',
      'Delivery Basis': 'Базис',
      'Delivery condition': 'Вид транспортировки',
      'Delivery conditions': 'Вид транспортировки',
      'Delivery dates': 'Даты доставки',
      'Delivery estimated month': 'Ожидаемый месяц доставки',
      'Delivery finished': 'Отгрузка завершилась',
      'Delivery period': 'Период поставки',
      'Delivery period sale contract': 'Период исполнения продажного контракта',
      'Delivery status': 'Статус доставки',
      'Dem rate': 'Ставка демерреджа',
      'Demmurage rate': 'Ставка дема',
      Demo: 'Демо',
      'Demo removed': 'Демо убрали',
      'Demurrage rate': 'Демерредж ставка',
      'Depart station': 'Станция отправки',
      Departed: 'Отправл',
      'Departed consignment': 'Отправлено накладные',
      'Departed real': 'Отправлено док',
      'Departed value': 'Стоимость отгруженного товара',
      'Departed volume': 'Отправленный объем',
      'Departed w/o consignment': 'Отправлено без накладных',
      'Departure Station': 'Станция отправления',
      'Departure station': 'Станция отправления',
      'Depends on a stage of passport execution. Select which margin we will display in PNL':
        'Зависит от стадии исполнения паспорта. Выберите, какую маржу мы будем отображать в PNL',
      Derivative: 'Дериватив',
      'Derivative Accounts': 'Аккаунт дериватива',
      'Derivative account': 'Дериватив счета',
      'Derivative ticker': 'Дериватив тикер',
      'Derivative title': 'Дериватив название',
      'Derivative type': 'Тип дериватива',
      Derivatives: 'Деривативы',
      'Derivatives & Rolling': 'Деривативы и Роллинг',
      'Derivatives Position': 'Позиция деривативов',
      'Derivatives margin': 'Маржа деривативов',
      Description: 'Описание',
      'Despatch rate': 'Ставка деспач',
      Destination: 'Назначение',
      'Destination 1st port': 'Порт назначения 1-й',
      'Destination Port': 'Порт назначения',
      'Destination country': 'Страна назначения',
      'Destination dem/dis': 'Назначение дем/дис',
      'Destination final port': 'Конечный порт назначения',
      'Destination port': 'Порт назначения',
      'Destination station': 'Станция назначения',
      Destinations: 'Назначения',
      Details: 'Детали',
      Diff: 'Разница',
      'Difference = 0': 'Разница = 0',
      'Difference > 0': 'Разница > 0',
      'Difference >= 0.1': 'Разница > 0.1',
      Diffs: 'Разницы',
      'Disable previous template and clear all filter before create new.':
        'Отключить предыдущий шаблон и очистить все фильтры перед созданием нового.',
      Disabled: 'Отключено',
      'Disbursement bill of lading': 'Расходная накладная',
      'Disbursement bill of lading Amount incl. VAT, UAH': 'Расходная накладная сумма, UAH с НДС',
      'Disbursement bill of lading Price incl. VAT, UAH': 'Расходная накладная цена, UAH с НДС',
      'Disbursement bill of lading Quantity, mt': 'Расходная накладная объем, т',
      'Disbursement bill of lading removed': 'Расходная накладная удалена',
      'Disbursement bill of lading saved': 'Расходная накладная создана',
      'Disbursement bl': 'Расходная накладная',
      'Disbursement bl and reassignment execution': 'Выполнение расходной накладной и переуступки',
      'Disbursement bl value': 'Расходная накладная сумма',
      'Disbursement bl volume': 'Расходная накладная объем',
      'DisbursementBL created': 'Создана расходная накладная',
      "Disbursment B/L's": 'Расходные накладные',
      'Disch rate': 'Норма выгрузки',
      Discharge: 'Выгрузка',
      Discharged: 'Выгружено',
      Discharging: 'Выгружается',
      'Discharging place': 'Точка отгрузки',
      Discount: 'Дисконт',
      'Discount %': 'Дисконт, %',
      'Discount / 1 mt': 'Дисконт/ 1 т',
      'Discount amount': 'Значение дисконта',
      'Discount per': 'Дисконт за 1 т',
      'Discount per 1 mt': 'Дисконт за 1 т',
      'Discount, %': 'Дисконт, %',
      Discussion: 'Обсуждение',
      'Disp rate': 'Ставка диспача',
      'Dispatch rate': 'Диспач ставка',
      Disport: 'Порт назначения',
      'Disselect all': 'Убрать всё',
      'Distribution by users': 'Распределение по пользователям',
      Distributor: 'Дистрибьютор',
      Distributors: 'Дистрибьюторы',
      'Distributors fact': 'Дистрибьюторы факт',
      'Distributors plan': 'Дистрибьюторы план',
      District: 'Район',
      Districts: 'Районы',
      'Do not pay': 'Не платить',
      'Do not update contracts': 'Не обновлять контракты',
      'Do not validate': 'Не сверять',
      'Do the compliance': 'Выполнить комплаенс',
      'Do you really want to delete this object ?': 'Вы уверены, что хотите удалить этот объект?',
      'Do you want delete this Request?': 'Вы уверены, что хотите удалить эту Заявку?',
      'Do you want replace sides in invoice?': 'Вы уверены, что хотите поменять стороны в инвойсе?',
      'Do you want to clone this Consignment?': 'Вы уверены, что хотитет клонировать Накладную?',
      'Do you want to clone this Contract?': 'Вы уверены, что хотитет клонировать Контракт?',
      'Do you want to clone this Disbursement bill of lading?':
        'Вы уверены, что вы хотите клонировать это?',
      'Do you want to clone this General Agreement ?':
        'Вы уверены, что хотите клонировать Договор?',
      'Do you want to clone this Invoice?': 'Вы уверены, что хотитет клонировать инвойс?',
      'Do you want to clone this Logistic?': 'Вы уверены, что хотитет клонировать Транспорт?',
      'Do you want to clone this Person?': 'Вы уверены, что хотите клонировать Контакт?',
      'Do you want to clone this Request?': 'Вы уверены, что хотите клонировать Заявку?',
      'Do you want to clone this intermedate logistic?':
        'Вы уверены, что хотитет клонировать промеж Транспорт?',
      'Do you want to clone this object?': 'Вы уверены, что хотите клонировать этот объект?',
      'Do you want to connect': 'Хотите ли вы соединить',
      'Do you want to create a control?': 'Вы уверены, что хотите создать Контроль?',
      'Do you want to create a supplier with short name  and role':
        'Вы хотите создать поставщика с коротким именем и ролью',
      'Do you want to create a user for this Person?':
        'Вы хотите создать пользователя Кабинета из этого Контакта?',
      'Do you want to create contract?': 'Вы уверены, что хотитет создать Контракт?',
      'Do you want to create mailing by  contracts from \\\n        selected  logisitc(s)?':
        'Вы хотите создать рассылки по контрактам из  \\\n       выбранных логистик?',
      'Do you want to create mailing by contracts from selected  invoice(s)?':
        'Вы хотите создать рассылки по контрактам из выбранных инвойсов?',
      'Do you want to create multicontract?': 'Вы уверены, что хотите создать Мультиконтракт?',
      'Do you want to create payment by this plan?': 'Хотите оплатить инвойсы из плана?',
      'Do you want to delete all passport connections?': 'Вы хотите удалить все привязки паспорта?',
      'Do you want to delete this mailing?': 'Вы уверены, что хотите удалить эту Рассылку?',
      'Do you want to drop  mails?': 'Хочешь сбросить  письма?',
      'Do you want to execute this passport?':
        'Вы хотите исполнить этот План Паспорта (создать контракты) ?',
      'Do you want to read all?': 'Вы действительно хотите прочитать все?',
      'Do you want to reverse contract?': 'Вы уверены, что хотите продать/купить эту Заявку?',
      'Do you want to reverse request?': 'Вы уверены, что хотите продать/купить эту Заявку?',
      'Do you want to reverse ticket?': 'Вы хотите купать/продать эту сделку?',
      'Do you want to send  mails?': 'Вы уверены, что хотите отправить рассылку?',
      'Do you want to update final volume from execution?':
        'Вы хотите обновить финальный объем из исполнения?',
      'Doc removed': 'Документ удален',
      Docs: 'Доки',
      'Docs approved': 'Доки подтверждены',
      'Docs approved by us': 'Доки подтвердили',
      'Docs received': 'Доки получены',
      'Docs requested': 'Доки запросили',
      Document: 'Документ',
      'Document ID': 'Документ ID',
      'Document copy': 'Копия документа',
      'Document original': 'Оригинал документа',
      'Document saved': 'Документ сохранен',
      'Document templates': 'Шаблоны документов',
      'Document type': 'Тип документа',
      Documentation: 'Документация',
      Documents: 'Документы',
      'Documents entry dates': 'Даты внесения документов',
      'Documents saved': 'Документы сохранены',
      'Documents. Completed if all documents per contract are received':
        'Документы. Успешно, если получены все доки по контракту',
      "Don't use for KPI": 'Не использовать для KPI',
      'Don`t use kpi check': 'Не использовать для KPI',
      Done: 'Готово',
      Download: 'Скачать',
      'Download a template': 'Скачать шаблон',
      'Download all': 'Скачать всё',
      Downloaded: 'Скачано',
      Draft: 'Драфт',
      'Drag and drop a file': 'Перетащите файл',
      'Drag and drop to reorder': 'Перетащите файл чтобы изменить порядок',
      'Drop all connections to passport': 'Сбросить все привязки к паспорту',
      'Drop connections': 'Сбросить привязки',
      'Drop selected': 'Удалить выбранное',
      Drying: 'Сушка',
      Dt: 'Дт',
      'Due date': 'Дедлайн',
      'Due diligence': 'Информация о компании',
      'E-mail': 'E-mail',
      'E.g. you have a chain from inland EXW purchase to CIF sale via several counteparties\n              - you might use two or more passport for each part of the chain.':
        'Например, у Вас есть цепь от внутренней покупки EXW до продажи CIF через несколько контрагентов\n              - можно использовать два или более паспорта для каждой части цепи.',
      'EDUCATION MODE in either company or in your personal settings is':
        'РЕЖИМ ОБУЧЕНИЯ в компании или в личных настройках',
      ENABLED: 'ВКЛЮЧЁН',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'EUR 1': 'EUR 1',
      'EUR 1 (date)': 'EUR 1 (дата)',
      'EUR 1 required': 'EUR 1 (обяз)',
      EXECUTION: 'ИСПОЛНЕНИЕ',
      'EXECUTION VALUE': 'Сумма исполнения',
      'EXPORTER CONFIRMATION': 'ЭКСПОРТЕР ПОДТВЕРЖДЕНИЕ',
      EXPORTERS: 'ЭКСПОРТЕРЫ',
      Edit: 'Редактировать',
      'Edit (admin)': 'Редактировать (админ)',
      'Edit Users': 'Редактировать пользователей',
      Editor: 'Редактор',
      'Education mode': 'РЕЖИМ ОБУЧЕНИЯ',
      Elevator: 'Элеватор',
      'Elevator Name': 'Элеватор',
      'Elevator cost': 'Элеватор затраты',
      'Elevator losts': 'Элеватор потери',
      Elevators: 'Элеваторы',
      Email: 'Почта',
      'Email Templates': 'Шаблоны рассылки',
      'Email max length': 'Максимальная длина E-mail-а',
      'Email templates': 'Шаблоны рассылки',
      'Empty finances list': 'Пустой список инвойсов',
      Enabled: 'Включено',
      End: 'Конец',
      'End date': 'Дата окончания',
      'End of execution': 'Конец выполнения',
      'End usage (date)': 'Конец использ (дата)',
      'Ending cash': 'Остаток на конец',
      English: 'English',
      'Estimated P&L': 'Ожидаемый ПнЛ',
      'Estimated Q (T)': 'Ожидаемое кол-во (т)',
      'Estimated volume of the deal for contract allocation':
        'Ожидаемый объем сделки для распределения контракта',
      'Eur.1': 'EUR 1',
      Europe: 'Европа',
      Event: 'Событие',
      'Event Subscription': 'Подписки',
      'Event subscriptions': 'Подписки',
      Exchange: 'Курс',
      'Exchange rate': 'Обменный курс',
      Exchanges: 'Курсы',
      'Exec forecasted amount': 'Ожидаемая сумма за сделку',
      'Execute contracts': 'Исполнение контрактов',
      Executed: 'Выполнено',
      'Executed - Paid': 'Исполнено - оплачено',
      'Executed qty': 'Выполненное кол-во',
      Execution: 'Исполнение',
      'Execution (end date)': 'Исполнение (конец)',
      'Execution (start date)': 'Исполнение (начало)',
      'Execution (t)': 'Исполнение (т)',
      'Execution - Invoices': 'Выполнено - Проинвойсированно',
      'Execution Dates': 'Даты исполнения',
      'Execution amount': 'Сумма по исполнению',
      'Execution date': 'Дата исполнения',
      'Execution end': 'Дата окончания исполнения',
      'Execution end date': 'Исполнение (дата конца)',
      'Execution estimated month': 'Ожидаемый месяц исполнения',
      'Execution margin': 'Маржа по исполнению',
      'Execution month': 'Месяц исполнения',
      'Execution of loyalty programs': 'Исполнение программ лояльности',
      'Execution period': 'Период исполнения',
      'Execution start': 'Дата начала исполнения',
      'Execution start date': 'Исполнение (дата начала)',
      'Execution value': 'Сумма исполнения',
      Expenses: 'Затраты',
      Expiration: 'Экспирация',
      'Expiration date of power of attorney': 'Окончание срока действия доверенности',
      Export: 'Экспорт',
      'Export Contract': 'Экспортный контаркт',
      'Export tax': 'Экспортная пошлина',
      'Export tax:': 'Экспортная пошлина:',
      Exporter: 'Экспортер',
      'Exporter address': 'Экспортер адрес',
      'Exporter can signatory docs': 'Экспортер подписант доки',
      'Exporter can signatory docs eng': 'Экспортер подписант доки англ',
      'Exporter contract (#)': 'Экспортер (№ контракта)',
      'Exporter country': 'Экспортер страна',
      'Exporter country eng': 'Экспортер страна англ',
      'Exporter email': 'Экспортер почта',
      'Exporter full name': 'Экспортер полное название',
      'Exporter full name eng': 'Экспортер полное название английский',
      'Exporter legal address': 'Экспортер адрес полный англ',
      'Exporter legal address eng': 'Экспортер адрес полный англ',
      'Exporter list': 'Список экспортеров',
      'Exporter list eng': 'Список экспортеров англ',
      'Exporter name': 'Экспортер имя',
      'Exporter phone': 'Экспортер телефон',
      'Exporter signatory': 'Экспортер подписант',
      'Exporter signatory eng': 'Экспортер подписант англ',
      'Exporter website': 'Экспортер сайт',
      Exporters: 'Экспортеры',
      'Exporters eng': 'Список экспортеров англ',
      'Exporters list': 'Список экспортеров',
      'Exporters list eng': 'Список экспортеров англ',
      Exposition: 'Результат',
      'Exposition 12,70 Cts/Bu ou -5$/T': 'Результат 12,70 Cts/Bu ou -5$/T',
      'Exposition 25,40 Cts/Bu ou -10$/T': 'Результат 25,40 Cts/Bu ou -10$/T',
      'Exposition, Mt': 'Риск, т',
      Ext: 'Ext',
      Extension: 'Пролонгация',
      'Extension (end)': 'Есть экстеншн',
      'Extension (start)': 'Пролонгация (начало)',
      FACT: 'ФАКТ',
      FILTERS: 'ФИЛЬТРЫ',
      FINANCE: 'ФИНАНСЫ',
      'FINANCE REPORTS': 'ФИНАНСОВЫЕ ОТЧЕТЫ',
      FINANCES: 'ФИНАНСЫ',
      'FOB price': 'FOB цена',
      FREIGHT: 'ФРАХТ',
      Fact: 'Факт',
      'Fact created': 'Факт создано',
      'Fact crop protection': 'Защита урожая факт',
      'Fact distributor': 'Фактический дистрибьютор',
      'Fact distributors': 'Фактические дистрибьюторы',
      'Fact seeds': 'Посевы факт',
      Fail: 'Неудачно',
      Failed: 'Провалено',
      Farm: 'Хозяйство',
      Farms: 'Хозяйства',
      Favorite: 'Избранные',
      'Favorite Filters': 'Любимые фильтры',
      'Favourite filters': 'Избранные фильтры',
      February: 'Февраль',
      'Few departure stations': 'Несколько станций отправки',
      'Few prices': 'Несколько цен',
      Field: 'Поле',
      File: 'Файл',
      "File '' is to big": 'Файл ‘’ слишком большой',
      'Fill if you want to calculate MTM by this contract':
        'Заполните, если хотите рассчитать МТМ по этому контракту',
      'Fill it if only there are some differences from standard compliance process. This will cause APPROVED WITH CONDITION status':
        'Заполните, если есть некоторые отличия от стандартного процесса комплаенса. В этом случае статус будет\nПОДТВЕРЖДЕНО С УСЛОВИЯМ',
      'Fill it only if you want this contract to be converted by a certain rate':
        'Заполните, если вы хотите, чтобы этот контракт был конвертирован по определенному курсу',
      'Fill it to display Invoice in Budget report':
        'Заполните, чтобы показать инвойс в отчете по бюджету',
      Filter: 'Фильтр',
      'Filter Set removed': 'Фильтры удален',
      'Filter Set updated': 'Фильтр обновлен',
      'Filter by': 'Фильтр по',
      'Filter by selected business units': 'Фильтр по выбранным бизнес юнитам',
      Filters: 'Фильтры',
      'Fin purpose': 'Фин цель',
      Final: 'Финал',
      'Final Amount': 'Финальная сумма',
      'Final Volume (T)': 'Финальный объем',
      'Final amount': 'Финальная сумма',
      'Final approve': 'Финальное подтверждение',
      'Final confirmation': 'Окончательное подтвержд',
      'Final volume': 'Финальный объем',
      'Final volume equals connections': 'Финальный объем равен привязкам',
      Finance: 'Финансы',
      'Finance Account': 'Финанс счета',
      'Finance Bank': 'Финансы банк',
      'Finance account': 'Финанс счет',
      'Finance accounts': 'Финанс счета',
      'Finance credit account': 'Финанс кредит счет',
      'Finance days': 'Финанс дни',
      'Finance debit account': 'Финанс дебет счет',
      "Finance doesn't exist": 'Инвойс не существует',
      'Finance offset value': 'Финансы зачет стоимость',
      'Finance passport position': 'Финансы паспорт позиция',
      'Finance reports': 'Финансовые отчеты',
      'Finances mailing': 'Рассылка инвойсов',
      'Financial purpose': 'Финансовая цель',
      Financing: 'Финансирование',
      'Find a filter': 'Найти фильтр',
      'First (validation)': 'Первый (валидация)',
      'First - select a invoices previously filtered them Second - press Create an Emails\n                Third - look at the table below, you email will be ready to preview, gently check\n                it and then send.':
        'Во-первых – выберите ранее отфильтрованные инвойсы. Во-вторых, нажмите «Создать Emails».\n                В-третьих – посмотрите на таблицу ниже, ваше письмо будет готово для предварительного просмотра,\n                проверьте данные и отправьте.',
      'First Name': 'Имя',
      'First Name is required': 'Имя обязательно',
      'First charge': 'Первая затрата',
      'First confirm date': 'Дата первого подтверждения',
      'First confirmation (date)': 'Первое подтверждение (дата)',
      'First confirmed': 'Первое подтверждение',
      'First contact': 'Перв.контакт',
      'First date must be less then Second': 'Первая дата должна быть меньше Второй',
      'First day of the week': 'Первый день недели',
      'First name': 'Имя',
      Fixed: 'Зафиксировано',
      'Fixed lots': 'Зафиксированные лоты',
      'Fixed tonnes': 'Зафиксированные тонны',
      Fixing: 'Fixing',
      'Fixing and hedging': 'Фиксинг и хеджинг',
      'Fixing/Hedging': 'Фиксинг/Хеджирование',
      Flag: 'Флаг',
      'Flat price per not fixed lots': 'Цена за незафиксированный лоты',
      'Flat price per not hedged lots': 'Цена за незахеджированные лоты',
      Fobbing: 'Фоббинг',
      'Folder number': 'Номер папки',
      Font: 'Шрифт',
      'For KPI year report': 'Для КПИ отчета (год)',
      'For example if the contract qty is 5000 and this contract has cost of 1000$ and\n                  you allocated this contract on two passports 2500 and 2500 respectively':
        'Например, если объем контракта составляет 5000 и этот контракт имеет стоимость 1000 USD,\n                   и вы распределили этот контракт на два паспорта 2500 и 2500 соответственно',
      'For purposes of filtering information by specific season':
        'Для фильтрации информации по конкретному сезону',
      Forecast: 'Прогноз',
      'Forecasted - Payment': 'Ожидание - Оплаты',
      'Foreign currency': 'Валюта',
      'Form invalid': 'Ошибка формы',
      Forward: 'Форвард',
      'Forward fact': 'Форвард факт',
      'Forward limit': 'Форвард лимит',
      Forwarder: 'Экспедитор',
      'Forwarder railway': 'Экспедитор жд',
      Forwarders: 'Экспедиторы',
      Fr: 'Пт',
      'Free tax invoice': 'Свободный налог инвойс',
      Freight: 'Фрахт',
      'Freight Info': 'Фрахт информация',
      'Freight Position': 'ПОЗИЦИЯ ФРАХТА',
      'Freight broker costs': 'Фрахт брокер затраты',
      'Freight costs': 'Фрахт затраты',
      'Freight level': 'Фрахт',
      'Freight position + MTM': 'ПОЗИЦИЯ ФРАХТА + ОЦЕНКА К РЫНКУ',
      Freights: 'Фрахты',
      From: 'От',
      'From user': 'От пользователя',
      'From which country purchased': 'Из какой страны куплено',
      'From whom': 'От кого',
      'Full amount': 'Полная стоимость',
      'Full company name, LLC': 'Полное название компании, ООО',
      'Full current address': 'Полный текущий адрес',
      'Full name': 'Полное название',
      'Full name (eng)': 'Полное название (Англ.)',
      'Full name Eng': 'Полное название Английский',
      'Full name eng': 'Полное название Английский',
      'Full name of person who signs documents by this company':
        'ФИО представителя компании, подписывающего документы',
      'Full name of the Signatory to use in requisites while generating documents':
        'Полное имя Подписанта, которое используется для генерации документов',
      'Full name to use in requisites while generating documents':
        'Полное имя, которое используется для генерации документов',
      'Future contact': 'Следующий контакт',
      'Future deleted': 'Фьючерс удален',
      'Future updated': 'Фьючерс обновлен',
      Futures: 'Фьючерсы',
      GAINS: 'ПОСТУПЛЕНИЯ',
      GENERAL: 'ОБЩЕЕ',
      'GENERAL AGREEMENT': 'ДОГОВОР',
      'GENERAL INFO': 'ОБЩАЯ ИНФОРМАЦИЯ',
      'GENERAL TOTAL': 'ОБЩИЙ ИТОГИ',
      GROUPS: 'ГРУППЫ',
      Gains: 'Поступления',
      General: 'Общее',
      'General Agreement': 'Договор',
      'General Expenses': 'Общие расходы',
      'General Transaction': 'Общая транзакция',
      'General Transactions': 'Общие транзакции',
      'General agreements': 'Рамочные договора',
      'General expense': 'Общие затраты',
      'General expenses': 'Общие затраты',
      'General info': 'Общая информация',
      'General transactions': 'Общие транзакции',
      Generate: 'Сгенерировать',
      'Generate Document': 'Сгенерировать документ',
      'Generate a document': 'Распечатать документ',
      'Generate a preview': 'Сгенерировать предпросмотр',
      'Generate an email': 'Сгенерировать письмо',
      'Generate contract name': 'Сгенерировать номер',
      'Generate document': 'Распечатать документ',
      'Generate loan name': 'Сгенерировать номер займа',
      'Generate number': 'Сгенерировать номер',
      'Go to a PNL report': 'Перейти к отчету PnL',
      'Go to details': 'Перейти к деталям',
      Goal: 'Цель',
      'Google clean': 'Очистка Google',
      'Grain Quality Check': 'Проверка качества',
      Green: 'Зеленое',
      Grey: 'Серое',
      'Gross Closed': 'Валовая по закрытым',
      'Gross Margin': 'Общая маржа',
      Group: 'Группа компаний',
      'Group by': 'Группировка по',
      'Group passports into a chain': 'Группировать паспорта в цепочку',
      'Group totals': 'Групповой тотал',
      Grouped: 'Сгруппировано',
      'Grouping criteria': 'Критерий группировки',
      Groups: 'Группы',
      HISTORY: 'ИСТОРИЯ',
      'Has Amount Per Deal Execution': 'Есть общая сумма за сделку',
      'Has Analytical amount': 'Есть аналитическая сумма',
      'Has BL': 'Есть коносамент',
      'Has Business Unit': 'Есть бизнес юнит',
      'Has Discount': 'Есть дисконт',
      'Has Exec Forecasted Amount': 'Есть ожидаемая сумма за сделку',
      'Has Final amount': 'Есть финальная сумма',
      'Has LOI': 'Есть LOI',
      'Has Our amount': 'Есть наш объем',
      'Has Payment Info': 'Есть банковская информация',
      'Has Voyage': 'Есть рейс',
      'Has additional info': 'Есть дополнительная инфо',
      'Has all docs copy of DBL': 'Есть копия доков DBL',
      'Has balance': 'Есть баланс',
      'Has balance prepay': 'Есть баланс',
      'Has balance to connect': 'Есть доступный объем к прикреплению',
      'Has basis doc': 'Есть базис документ',
      'Has broker': 'Есть брокер',
      'Has buyer contract cargo confirmation': 'У продажного контракта груз подтвержден',
      'Has buyer contract final confirmation': 'У продажного контракта финал подтвержден',
      'Has buyer contract payment confirmation': 'У продажного контракта платеж подтвержден',
      'Has cancelled invoice': 'Есть отклонен инвойс',
      'Has cancelled signature': 'Подписание отменено',
      'Has cargo confirmation': 'Груз подтверждение',
      'Has comments': 'Есть комментарии',
      'Has contract': 'Есть контракт',
      'Has contract plans': 'Распланировано',
      'Has control date': 'Есть дата контроля',
      'Has date': 'Есть дата',
      'Has date of execution': 'Есть дата исполнения',
      'Has disbursement bill of lading': 'Есть расходная накладная',
      'Has docs': 'Есть документы',
      'Has docs copy': 'Есть копия доков',
      'Has docs original': 'Есть оригинал доков',
      'Has documents': 'Есть документы',
      'Has fact crop protection': 'Есть дата исполнения',
      'Has fact seeds': 'Есть факт посев',
      'Has final confirmation': 'Финал подтвержд',
      'Has fixing': 'Есть фиксинг',
      'Has grain quality check': 'Есть проверка качества',
      'Has hedging': 'Есть хеджирование',
      'Has incoming invoice': 'Есть входящий инвойс',
      'Has invoice': 'Есть инвойс',
      'Has invoice documents': 'Есть документы на инвойс',
      'Has issuer counterparty': 'Есть контрагент выдал',
      'Has limit': 'Есть лимт',
      'Has logistic': 'Есть логистика',
      'Has no basis doc': 'Нет базис документ',
      'Has note': 'Есть примеч',
      'Has offset': 'Есть зачёт',
      'Has originals received': 'Оригиналы получены',
      'Has outgoing invoice': 'Есть исходящие инвойс',
      'Has payment': 'Есть оплата',
      'Has payment confirmation': 'Оплата подтверждение',
      'Has plan crop protection': 'Есть план защиты урожая',
      'Has plan seeds': 'Есть плановый посев',
      'Has povitive balance': 'Есть положительный баланс',
      'Has prepay': 'Есть предоплата',
      'Has purchase facts': 'Есть закупка',
      'Has reassignment': 'Есть переуступка',
      'Has receiver counterparty': 'Есть контрагент получ',
      'Has responsible': 'Есть ответственный',
      'Has sale facts': 'Есть продажа',
      'Has season': 'Есть сезон',
      'Has several passports': 'Имеет несколько паспортов',
      'Has supplier contract cargo confirmation': 'У закупочного контракта груз подтвержден',
      'Has supplier contract final confirmation': 'У закупочного контракта финал подтвержден',
      'Has supplier contract payment confirmation': 'У закупочного контракта платеж подтвержден',
      'Has volume with docs': 'Есть объем с доками',
      'Have to confirm': 'Могут подтверждать',
      'Heads of regional manager': 'Главы региональных представителей',
      'Heads of sale managers': 'Главы региональных представителей',
      Hedged: 'Захеджированно',
      'Hedged lots': 'Хеджировать лоты',
      Hedging: 'Хеджирование',
      'Here are the similar companies by name. Check if your current company is not conflicting with existing ones.':
        'Здесь показаны похожие названия компаний. Проверьте, что название текущей компании не совпадает с уже существующими.',
      'Here is the list of all passports. Passport is the full cycle of the deal from\n                beginning to the end.':
        'Вот список всех паспортов. Паспорт – это полный цикл сделки \n                от начала до конца.',
      'Here you can allocate purchase side with the sale side and GT will do everything by\n                itself: calculate profitability, consolidate costs, documents, invoices in one\n                place.':
        'Здесь вы можете распределить сторону закупки со стороной продаж и GrainTrack все сделает самостоятельно:\n                рассчитает рентабельность, консолидирует затрат, документы, инвойсы\n                в одном месте.',
      'Here you can type full title of the company with type of ownership, etc. Example - Full company name, LLC':
        'Здесь вы можете ввести полное название компании с типом собственности и т.д. Пример - полное название компании, ООО',
      Hide: 'Спрятать',
      'Hide Users': 'Спрятать пользователей',
      'Hide all': 'Спрятать всё',
      'Hide child crops': 'Спрятать дочерние культуры',
      'Hide executed': 'Спрятать исполненное',
      'Hide fixed contracts': 'Скрыть фиксированные контракты',
      'Hide full table': 'Спрятать полную таблицу',
      'Hide history': 'Спрятать историю',
      High: 'Важно',
      History: 'История',
      Hot: 'Горячий',
      Hours: 'Часы',
      'How much to take from deal?': 'Сколько откусить от сделки?',
      IBAN: 'IBAN',
      ID: 'ID',
      IMO: 'ИМО',
      INCOMING: 'ВХОДЯЩИЕ',
      INDICATORS: 'ИНДИКАТОРЫ',
      INFO: 'ИНФО',
      'INTERMEDIATE LOGISTICS': 'ПРОМЕЖУТОЧНАЯ ЛОГИСТИКА',
      'INVOICE DOCUMENTS': 'ДОКУМЕНТЫ ИНВОЙСЫ',
      INVOICED: 'ПРОИНВОЙСИРОВАННО',
      'INVOICED VALUE': 'СУММА ИНВОЙСОВ',
      INVOICES: 'ИНВОЙСЫ',
      'INVOICES LIST': 'СПИСОК ИНВОЙСОВ',
      'INVOICES POSITION': 'ПОЗИЦИЯ ИНВОЙСОВ',
      ISCC: 'ISCC',
      ITN: 'ИНН',
      'If company has more than one number': 'Если у компании больше одного номера',
      'If current company is a part of a bigger group':
        'Если текущая компания является частью более крупной группы',
      'If you have a complicated chain you will need it.':
        'Если у вас сложная цепочка, она вам понадобится.',
      'If you pick something here, information about this object will be displayed only for people from those business units':
        'Если вы выберете что-то здесь, информация об этом объекте будет отображаться только для людей из этих бизнес-юнитов',
      'If you want so set negotiation process and defined whether charge is validated or under discussion':
        'Если вы хотите установить процесс подтверждения и определить, подтверждена ли затрата или обсуждается',
      'If you want to assign some custom role to this company':
        'Если вы хотите назначить какую-то пользовательскую роль этой компании',
      'If you want to convert all financial data in passport by one rate to USD':
        'Если вы хотите конвертировать все финансовые данные в паспорте по одному курсу в USD',
      'Ignore client status': 'Игнорировать статус контрагента',
      'Ignore counterparty limits': 'Игнорировать лимит',
      'Ignore counterparty status': 'Игнорировать статус контрагента',
      'Ignore limits': 'Игнорировать лимиты',
      Imo: 'Имо',
      Import: 'Импорт',
      Imported: 'Импортировано',
      'In date': 'Дата получения',
      'In general, passport is one vessel, which can contain sale and purchases in\n                different configurations: 1 sale vs 1 purchase, 1vs3, 5vs2, whatever. Or if it is\n                inland deal - one passport is just some sale in from of some purchase even without\n                a vessel.':
        'Как правило, паспорт - это одно судно, которое может содержать продажу и закупку в разных конфигурациях:1 продажа против 1 закупки, 1 против 3, 5 против 2, что угодно.\nИли если это внутренняя сделка – один паспорт – это просто продажа с какой-то закупкой\n                даже без судна.',
      'In number': 'Входящий номер',
      'In process': 'Процесс',
      'In progress': 'В процессе',
      'In the case, if contract is PURCHASE - then EXPORTERS are chosen amont the exporters of SUPPLIER of this contractIf the contract is SALE, then EXPORTERS are chose from PURCHASE contracts, that are linked with this SALE contractvia passport. So, to add Exporters to Sale contract, at first, link this Sale contract with other Purchase contracts via Passport.':
        'Если контракт ЗАКУПОЧНЫЙ - тогда ЭКСПОРТЕРОВ выбираем из экспортеров ПОСТАВЩИКА. Если контракта ПРОДАЖНЫЙ, тогда ЭКСПОРТЕРЫ сами выбираются со всех ЗАКУПОЧНЫХ контрактов, которые связаны с ПРОДАЖНЫМ контрактов через ПАСПОРТ. Итак, для того, чтобы добавить Экспортеров в Продажный контракт свяжите этот Продажный контракт с Закупочными через Паспорт.',
      'In the case, if contract is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners':
        'Если контракт ЗАКУПОЧНЫЙ - тогда ПОКУПАТЕЛЕМ является наша компания. Ее можно выбрать из списка Владельцев',
      'In the case, if contract is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners':
        'Когда контракта ПРОДАЖНЫЙ - ПОСТАВЩИКОМ является наша компания. Ее можно выбрать из списка владельцев',
      'In the case, if general agreement is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners':
        'Если контракт ЗАКУПОЧНЫЙ - тогда ПОКУПАТЕЛЕМ является наша компания. Ее можно выбрать из списка Владельцев',
      'In the case, if general agreement is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners':
        'Когда контракта ПРОДАЖНЫЙ - ПОСТАВЩИКОМ является наша компания. Ее можно выбрать из списка владельцев',
      'In this list of notifications you have a possibility to receive instant information\n                about some actions in the system. Contracts created, invoices paid, vessel arrived,\n                etc.':
        'В этом списке уведомлений есть возможность получать мгновенную информацию\n                о некоторых действиях в системе. Созданы контракты, оплачены инвойсы, прибытие судна\n                и т.д.',
      Inactive: 'Неактивный',
      Incomes: 'Доходы',
      Incoming: 'Входящие',
      'Incoming - all invoiced issued to us (means we will spend cash). Outgoing - all invoices issued by us (means: we will receive cash)':
        'Входящие - все инвойсы, выставленные нам (означает, что мы платим). Исходящие - все инвойсы, выставленными нами (нам платят)',
      'Incoming - invoice is received from counterparty, Outgoing - invoice is issued by us':
        'Входящие – инвойс получен от контрагента, Исходящие – счет выставлен нами',
      'Incoming balance': 'Входящий баланс',
      'Incoming invoice status': 'Статус входящего инвойса',
      'Incoming invoices': 'Входящие инвойсы',
      'Incoming payments': 'Входящие оплаты',
      'Incoming volume': 'Входящий объем',
      Incoterms: 'Базис',
      Indicator: 'Индикатор',
      'Indicator crops': 'Индикатор товары',
      'Indicator saved': 'Индикатор сохранен',
      Indicators: 'Индикаторы',
      'Indicators (spot)': 'Индикаторы (спот)',
      'Individual tax number': 'Индивидуальный налоговый номер',
      Info: 'Инфо',
      'Info for traders': 'Информация для трейдеров',
      'Information about company as a tax payer': 'Информация о компании как налогоплательщике',
      'Initial amount': 'Первоначальная сумма',
      'Initial balance': 'Первоначальный баланс',
      'Initial cash': 'Первоначальная сумма',
      'Initial cash is required': 'Первоначальная сумма обязательна',
      Instance: 'Сущность',
      'Instructions sent': 'Инструкции отосланы',
      Insurer: 'Страховая',
      Insurers: 'Страховые',
      'Intake volume': 'Объем погрузки',
      'Interest amount': 'Сумма процентов',
      'Interest payment date': 'Дата оплаты процентов',
      'Interest payment date (plan)': 'Дата оплаты процентов (план)',
      'Interest rate': 'Процентная ставка',
      'Interest rate, %': 'Процентная ставка, %',
      Intermediate: 'Промежуточный',
      'Intermediate TRANSPORT': 'Промежуточный транспорт',
      'Intermediate Transport': 'Промежуточный транспорт',
      'Intermediate costs': 'Промежуточные затраты',
      'Intermediate logistics': 'Промежуточная логистика',
      'Intermediate transport': 'Промежуточный транспорт',
      'Internal Chain': 'Внутренняя цепочка',
      'Internal chain': 'Внутренняя цепочка',
      'Internal chain contracts': 'Внутрення цепочка контрактов',
      Invalid: 'Неактуально',
      'Invert filters': 'Инвертировать фильтры',
      Invoice: 'Инвойс',
      'Invoice Position configs': 'Конфигурации инвойсов',
      'Invoice Positions': 'Инвойс позиции',
      'Invoice amount': 'Инвойс сумма',
      'Invoice configuration': 'Конфигурации инвойсов',
      'Invoice date': 'Дата инвойса',
      'Invoice discount': 'Дисконт',
      'Invoice discount amount': 'Инвойс дисконт значение',
      'Invoice documents': 'Инвойс документы',
      'Invoice is paid partially': 'Инвойс оплачен частично',
      'Invoice is paid.': 'Инвойс оплачен.',
      'Invoice is unpaid': 'Инвойс не оплачен',
      'Invoice margin': 'Маржа по инвойсам',
      'Invoice number': 'Номер ин',
      'Invoice number #': 'Номер инвойса №',
      'Invoice payments': 'Инвойс оплаты',
      'Invoice percent': 'Процент',
      'Invoice position': 'Инвойс позиция',
      'Invoice positions': 'Инвойс позиции',
      'Invoice ref': 'Номер инвойса',
      'Invoice risk': 'Инвойс риск',
      'Invoice risk report': 'Отчет о риске инвойса',
      'Invoice saved': 'Инвойс сохранен',
      'Invoice status': 'Статус инвойса',
      'Invoice type': 'Тип инвойса',
      'Invoice updated': 'Инвойс обновлен',
      Invoiced: 'Выставлено счетов',
      'Invoiced (bal)': 'Выставлено (баланс)',
      'Invoiced (balance)': 'Выставлено (баланс)',
      'Invoiced - paid': 'Выставлено - оплачено',
      'Invoiced Amount': 'Сумма инвойсов',
      'Invoiced Cargo': 'Инвойсы груз',
      'Invoiced amount': 'Сумма',
      'Invoiced logistic': 'Инвойс логистика',
      'Invoiced volume': 'Выставленный объем инвойса',
      Invoices: 'Инвойсы',
      'Invoices - Payment': 'Инвойсы - Оплаты',
      'Invoices to be paid': 'Инвойсы к оплате',
      'Invoices. Completed when invoices are issued': 'Инвойсы. Завершено, когда инвойс выставлен',
      Invoicing: 'Инвойсирование',
      'Is Elevator': 'Элеватор',
      'Is Exporter': 'Экспортер',
      'Is FCA': 'FCA',
      'Is Farm': 'Хозяйство',
      'Is Internal Chain': 'Внутренняя цепочка',
      'Is Swap': 'СВОП',
      'Is a role of legal entity': 'Является ролью юридического лица',
      'Is client agent': 'Компания посредник',
      'Is client producer': 'Это контрагент-производитель',
      'Is client resident': 'Контрагент является резидентом',
      'Is distributor': 'Дистрибьютор',
      'Is extension': 'Есть экстеншн',
      'Is full invoiced': 'Полностью проинвойсированно',
      'Is fully paid': 'Полностью оплачено',
      'Is gain': 'Это доход',
      'Is linked with the group of companies': 'Связано с группой компаний',
      'Is resident': 'Резидент',
      'Is virtual': 'Виртуальный',
      Issuance: 'Выставление',
      'Issuance date': 'Дата выставления',
      'Issue invoice': 'Выставить инвойс',
      'It seems there are no autos/wagons yet': 'Пока нет транспортных средств',
      'It seems there are no entries': 'Пока нет записей',
      'It seems there are no entries yet': 'Пока нет записей',
      'It seems there are no invoice positions yet': 'Кажется, нет ни одной инвойс позиции',
      'It seems there are no month yet': 'Кажется, пока нет ни одного месяца',
      'It seems there are no positions yet': 'Кажется, нет ни одной позиции',
      "It's consisted from realized and open P&L. Both physical and derivative.":
        'Он состоял из реализуемых и открытых PnL.Оба физические и деривативы.',
      'Item was deleted': 'Объект удалён',
      January: 'Январь',
      July: 'Июль',
      June: 'Июнь',
      KPI: 'KPI',
      'KPI confirmations': 'KPI подтверждения',
      'KPI signings': 'KPI подписания',
      'KPI success': 'KPI успех',
      Kazachstan: 'Казахстан',
      'LINE UP': 'ЛАЙН-АП',
      LOADING: 'ОТГРУЗКА',
      'LOADING 2ND PORT': 'ОТГРУЗКА 2-ГО ПОРТА',
      'LOADING FINAL': 'ОТГРУЗКА ФИНАЛ',
      LOCATION: 'ГЕОГРАФИЯ',
      LOGISTICS: 'ЛОГИСТИКА',
      LOI: 'LOI',
      LONG: 'ЛОНГ',
      LOSSES: 'ПОТЕРИ',
      'LOYALITY PROGRAM': 'ПРОГРАММА ЛОЯЛЬНОСТИ',
      LTD: 'LTD',
      Language: 'Язык',
      'Last 30 Days': 'Посл. 30 дней',
      'Last 7 Days': 'Посл. 7 дней',
      'Last B/L date': 'Последняя дата коносамента',
      'Last Month': 'Прошедший месяц',
      'Last login': 'Последний вход',
      'Last name': 'Фамилия',
      'Last name is required': 'Фамилия обязательна',
      'Last price': 'Последняя цена',
      'Last update': 'Последнее обновление',
      Laycan: 'Сталийное время',
      'Laycan (end)': 'Окончание сталийного времени',
      'Laycan (start)': 'Начало сталийного времени',
      'Laycan end date': 'Окончание сталийного времени',
      'Laycan start date': 'Начало сталийного времени',
      Leased: 'Арендованные',
      Left: 'Слева',
      'Left to be shipped': 'Осталось отгрузить',
      'Left to be shipped volume': 'Осталось отгрузить объема',
      Legal: 'Юридическое',
      'Legal address': 'Юридический адрес',
      'Legal address Eng': 'Юридический адрес (англ)',
      Lender: 'Кредитор',
      'Letter of Credit No.': 'Письмо Кредит №.',
      Libor: 'Libor',
      Limit: 'Лимит',
      'Limit balance': 'Лимит баланса',
      'Limit fact': 'Лимит факт',
      'Limit must be greater or equal.': 'Лимит должен быть больше или равно.',
      'Limit plan': 'Лимит план',
      'Limit saved': 'Лимит сохранен',
      'Limit saved.': 'Лимит сохранен.',
      Limits: 'Лимиты',
      'Line Up': 'Лайн-ап',
      'Line up': 'Лайн-ап',
      Lineup: 'Лайн-ап',
      'Link with companies': 'Связь с компанией',
      'Link with group': 'Связь с группой',
      List: 'Список',
      'List filters': 'Список фильтров',
      'List of actions in a dropdown menu': 'Список действий в выпадающем меню',
      'List of business units empty': 'Список бизнес юнитов пуст',
      Lists: 'Справочники',
      Load: 'Загрузка',
      'Load rate': 'Норма погрузки',
      'Load source': 'Источник',
      Loaded: 'Отгружено',
      'Loaded on board': 'Отгружено на борт',
      'Loaded volume': 'Объем загруж',
      'Loaded/Discharged': 'Отгружено/Выгружено',
      Loading: 'Отгрузка',
      'Loading (date)': 'Отгрузка  (дата)',
      'Loading / Destination': 'Отгрузка/Выгрузка',
      'Loading 2nd port': 'Отгрузки 2-го порта',
      'Loading Port': 'Порт отгрузки',
      'Loading date': 'Дата отгрузки',
      'Loading date from': 'Дата отгрузки от',
      'Loading date to': 'Дата отгрузки до',
      'Loading dem/dis': 'Отгрузка дем/дис',
      'Loading final port': 'Отгрузка конечного порта',
      'Loading minus': 'Отгрузка в минус',
      'Loading option': 'Опцион отгрузки',
      'Loading place': 'Место отгрузки',
      'Loading plus': 'Отгрузка в плюс',
      'Loading port': 'Порт отгрузки',
      'Loading related objects': 'Загружаем связанные объекты',
      'Loading/Destination': 'Отгрузка/Назначение',
      Loadport: 'Порт отгрузки',
      'Loads without buyer': 'Отгрузки без покупателей',
      Loan: 'Займа',
      'Loan agreement': 'Договор займа',
      'Loan amount': 'Сумма займа',
      'Loan number': 'Номер займа',
      'Loan saved': 'Займ сохранен',
      'Loan updated': 'Займ обновлен',
      Loans: 'Займы',
      Local: 'Местная',
      'Local currency': 'Локал. валюта',
      Location: 'География',
      'Log out': 'Выйти',
      Logistic: 'Логистика',
      'Logistic difference with invoice': 'Логистика разница с инвойсами',
      'Logistic loaded': 'Логистика отгружено',
      'Logistic loading; date': 'Логистика отгрузка дата',
      'Logistic purchase contracts': 'Логистика закупочные контракты',
      'Logistic sale contracts': 'Логистика продажные контракты',
      'Logistic sale export contracts': 'Логистика продажные экспортные контракты',
      'Logistic season': 'Логистика сезон',
      'Logistic updated': 'Логистика обновлена',
      Logistics: 'Логистика',
      'Logistics have already connected to bill of lading.':
        'Логистика уже соединена с коносаментом.',
      'Logistics mailing': 'Рассылка логистики',
      'Logistics updated!': 'Логистика обновлена!',
      Long: 'Лонг',
      Losses: 'Потери',
      Lost: 'Потеряно',
      Lots: 'Лоты',
      'Loyalty Program': 'Программа лояльности',
      'Loyalty Programs': 'Программы лояльности',
      'Loyalty program': 'Программа лояльности',
      'LoyaltyProgram saved.': 'Программа лояльности сохранена.',
      MAIN: 'ОСНОВНОЙ',
      'MAIN APPROVERS': 'СУПЕР ПОДТВЕРЖАЮЩИЕ',
      'MAIN INFO': 'ОСНОВНАЯ ИНФОРМАЦИЯ',
      MAJORITY: 'БОЛЬШИНСТВО',
      'MANAGEMENT REPORTS': 'УПРАВЛЕНЧЕСКИЕ ОТЧЕТЫ',
      MARGIN: 'МАРЖА',
      'MARGIN CONTROL': 'КОНТРОЛЬ МАРЖИ',
      'MARK TO MARKET': 'ОТНОШЕНИЕ К РЫНКУ',
      MARKET: 'РЫНОК',
      MONTH: 'МЕСЯЦ',
      'MORE INFO': 'БОЛЬШЕ ИНФО',
      MOVEMENT: 'ПЕРЕДВИЖЕНИЕ',
      'MT Equivalent': 'Эквивалент тонн',
      MTM: 'Отношение к рынку',
      'MTM Price': 'МТМ цена',
      'MTM margin': 'Маржа МТМ',
      'MTM price': 'МТМ цена',
      'MULTISELECT FILTERS': 'МНОЖЕСТВЕННЫЕ ВЫБОР',
      'MULTISELECT OPTIONS': 'МУЛЬТИСЕЛЕКТ',
      Mail: 'Почта',
      'Mail preview': 'Превью письма',
      'Mailing Invoices': 'Рассылка инвойсы',
      'Mailing Logistics': 'Логистика для рассылки',
      'Main company settings': 'Основные настройки компании',
      'Main contact': 'Основной контакт',
      'Main contact of company?': 'Главный контакт в компании?',
      'Main option': 'Основная опция',
      'Make reassignment': 'Создать переуступку',
      'Make sure you filled out': 'Убедитесь, что вы заполнили',
      Manage: 'Управлять',
      'Management reports': 'Управленческие отчеты',
      Manager: 'Менеджер',
      March: 'Март',
      Margin: 'Маржа',
      'Margin absolute': 'Маржа абсолютная',
      'Margin control created': 'Контроль маржи создан',
      'Margin per 1t': 'Маржа за 1 т',
      'Margin relative': 'Маржа относительная',
      'Margin type from passports': 'Тип маржи из паспортов',
      'Marine Cover Policy No.': 'Политика морского страхования №.',
      'Marine Traffic': 'Marine Traffic',
      'Marine traffic': 'Marine Traffic',
      Marinetraffic: 'Marine Traffic',
      'Mark to Market': 'Оценка к рынку',
      'Mark to market': 'Оценка к рынку',
      Market: 'Рынок',
      'Market price': 'Рыночная цена',
      'Market price deleted': 'Рыночная цена удалена',
      'Market price updated': 'Рыночная цена обновлена',
      Marks: 'Отметки',
      'Master data': 'Справочники',
      'Matched Positions': 'Совпавшие позиции',
      'Matched objects:': 'Совпавшие объекты:',
      Maturity: 'Длительность',
      'Maturity date': 'Срок платежа',
      'Maturity date (plan)': 'Срок платежа (план)',
      Max: 'Макс',
      'Max alt': 'Макс альт',
      'Max bl date': 'Последняя дата коносамента',
      'Max purchase price at': 'Максимальная закупочная цена для',
      'Maximum amount of one contract': 'Максимальная сумма по одному контракту',
      'Maximum quantity of one contract': 'Максимальный объем по одному контракту',
      May: 'Май',
      Measurement: 'Измерение',
      Medium: 'Средняя',
      Meet: 'Встреча',
      Message: 'Сообщение',
      'Message Template': 'Шаблон сообщения',
      'Middle Name': 'Отчетство',
      'Middle name': 'Отчетство',
      Min: 'Мин',
      'Min alt': 'Миним альт',
      'Minimum batch of payment': 'Мин сумма оплаты',
      'Minimum value is 0': 'Минимум  0',
      Mixed: 'Смешанный',
      Mo: 'Пн',
      Model: 'Раздел',
      Monday: 'Понедельник',
      Month: 'Месяц',
      'Monthly plan': 'Месячный план',
      Movement: 'Движение',
      MultiTicket: 'Мультитикет',
      Multicontract: 'Мультиконтракт',
      "Multicontract doesn't exist": 'Мультиконтракт не существует',
      'Multicontract saved.': 'Мультиконтракт сохранен.',
      Multicontracts: 'Мультиконтракты',
      Multipassport: 'Мультипаспорт',
      'Multipassport contains several passports in itself.':
        'Мультипаспорт содержит в себе несколько паспортов.',
      Multipassports: 'Мультипаспорта',
      'Multipassports report': 'Мультипаспорта отчет',
      'Multiply amount with rate': 'Привести сумму к ставке',
      Multirequest: 'Мультитикет',
      "Multirequest doesn't exist": 'Мультитикет не существует',
      'Multirequest saved.': 'Мультитикет сохранен.',
      Multitickets: 'Мультитикеты',
      'Multi{[{ $ctrl.multicontract.stage }]}': 'Мульти{[{ $ctrl.multicontract.contract_type }]}',
      NEW: 'НОВОЕ',
      NEWSLETTERS: 'РАССЫЛКИ',
      'NEXT STEP': 'СЛЕДУЮЩИЙ ШАГ',
      'NO OF BAGS': 'БЕЗ ТАРЫ',
      NOTIFICATIONS: 'УВЕДОМЛЕНИЯ',
      NUMBER: 'НОМЕР',
      Name: 'Имя',
      'Name / Surname': 'Имя / Фамилия',
      'Name of Buyer sale contract': 'Покупатель продажного контракта',
      'Name of seller purchase contract': 'Поставщик закупочного контракта',
      'Navbar position': 'Позиция навбара',
      Navigation: 'Навигация',
      'Net Closed': 'Чистая по закрытым',
      'Net P&L': 'Чистый P&L',
      'Net P&L MTM': 'Чистый P&L MTM',
      Neutral: 'Нейтральный',
      New: 'Новое',
      'New - after creation of disbursement bill of lading. Process - partly connected to reassignment. Done - fully connected to reassignment or connected to offset.':
        'Новое - после создания расходной накладной. В Процессе - частичное соединение с переуступкой. Выполнено - полное соединения к переуступке или зачету.',
      'New - after creation of reassignment. Process - reassignment partly connected to offset. Done - reassignment fully connected to offset.':
        'Новое - после создания переуступки. В процессе - переуступка частично связана с зачетом. Готово - переуступка полностью связана с зачетом.',
      'New Name': 'Новое название',
      'New Password': 'Новый пароль',
      'New collection': 'Новая конфигурация',
      'New derivative': 'Новый дериватив',
      'New destination port name': 'Новый порт назначения',
      'New loading port name': 'Новый порт погрузки',
      'New month': 'Новый месяц',
      'New multicontract': 'Новый мультиконтракт',
      'New passport': 'Новый паспорт',
      'New report': 'Новый отчет',
      'New tariff': 'Новый тариф',
      'New template': 'Новый шаблон',
      'New title': 'Новое название',
      'New vessel name': 'Новая лодка',
      'New {[{ $root.user.settings.PASSPORT_TITLE }]}':
        'Новое {[{ $root.user.settings.PASSPORT_TITLE }]}',
      NewsLetters: 'Рассылки',
      Newsletter: 'Рассылка',
      'Newsletter of invoices gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all invoices (paid or not) and send it to a client or bank.\n                Also, you can attach all documents, related to the deal.':
        'Рассылка инвойсов дает возможность быстро сформировать письмо с таблицей Excel\n                всех инвойсов (оплаченных или нет) и отправить его контрагенту или банку.\n                Также можно приложить все документы, связанные с сделкой.',
      'Newsletter of logistics gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all vehicles/containers and send it to a client or bank. Also,\n                you can attach full package documents, related to the deal.':
        'Рассылка логистики дает возможность быстро сформировать письмо с таблицей Excel\n                всех транспортных средств/контейнеров и отправить его контрагенту или банку.\n                Кроме того, вы можете прикрепить полный пакет документов, связанных со сделкой.',
      Newsletters: 'Рассылки',
      'Newsletters by invoices': 'Рассылки по инвойсам',
      'Newsletters by logistics': 'Рассылки по логистике',
      Next: 'Следующий',
      'Next contact': 'Следующий контакт',
      'Next step': 'Следующий шаг',
      'Next steps': 'Следующие шаги',
      'Next update': 'Следующее обновление',
      No: 'Нет',
      'No additional agreements yet': 'Пока нет доп. соглашений',
      'No balance invoice': 'Нет баланс инвойса',
      'No bank accounts yet': 'Банковских счетов пока нет',
      'No bill of ladings': 'Нет коносаментов',
      'No charges': 'Нет затрат',
      'No contracts': 'Нет контрактов',
      'No data yet': 'Нет данных',
      'No emails yet': 'Нет адресов',
      'No entries yet': 'Нет записей',
      'No open position': 'Нет открытой позиции',
      'No payments provided yet': 'Платежи еще не произведены',
      'No response': 'Нет ответа',
      'No sign': 'Нет подписи',
      'No similar entries yet': 'Нет похожих',
      'No templates yet': 'Пока нет шаблонов',
      'No, take me back': 'Нет, верни меня обратно\nверните меня',
      'Not contacted': 'Нет контакта',
      'Not fixed': 'Не зафиксированно',
      'Not fixed lots': 'Незафиксированные лоты',
      'Not fixed tonnes': 'Незафиксированные тонны',
      'Not hedged': 'Не захеджированно',
      'Not invoiced': 'Не выставлено счетов',
      'Not paid': 'Не оплачено',
      'Not pay': 'Не платить',
      'Not selected': 'Не выбрано',
      'Not signed': 'Не подписан',
      Note: 'Примеч',
      'Note one': 'Примечание раз',
      'Note two': 'Примечание два',
      Notes: 'Примеч',
      'Notification time': 'Время уведомления',
      Notifications: 'Уведомления',
      Notified: 'Уведомлен',
      'Notify party': 'Уведомленная сторона',
      'Notify party 2': 'Уведомленная сторона 2',
      'Notify party 3': 'Уведомленная сторона 3',
      November: 'Ноябрь',
      Number: 'Номер',
      'Number (#)': 'Номер (№)',
      'Number of Purchase contract': 'Номер Закуп контракта',
      'Number of Purchase contract, to which this vehicle is attached to':
        'Номер Закупочного контракта, к которому это т\\с привязано',
      'Number of Sale contract': 'Номер Продаж контракта',
      'Number of Sale contract, to which this vehicle is attached to':
        'Номер Продажного контракта, к которому это т\\с привязано',
      'Number of Sale export contract, to which this vehicle is attached to':
        'Номер Продажного Экспортного контракта, к которому это т\\с привязано',
      'Number of containers': 'Кол-во контейнеров',
      'Number of contracts': 'Кол-во контрактов',
      'Number of days before notification': 'Количество дней до уведомления',
      'Number of days before notification about power of attorney expiration':
        'Количество дней до уведомления об истечении срока действия доверенности',
      'Number of days to issue an invoice': 'Количество дней до уведомления',
      OPTIONS: 'ОПЦИИ',
      OTHER: 'ДРУГОЕ',
      OUTGOING: 'ИСХОДЯЩИЕ',
      Object: 'Объект',
      'Object deleted': 'Объект удален',
      'Object has been added': 'Объект добавлен',
      'Object has been removed': 'Объект удален',
      'Object removed': 'Объект удален',
      'Object saved': 'Объект сохранен',
      'Object updated': 'Объект обновлён',
      October: 'Октябрь',
      Offer: 'Закупка',
      Offers: 'Закупки',
      Offset: 'Зачет',
      'Offset date': 'Дата зачета',
      "Offset doesn't exist": 'Зачет не существует',
      'Offset list': 'Зачет список',
      'Offset number': 'Номер зачета',
      'Offset saved': 'Зачет сохранен',
      'Offset through reassignment date': 'Зачёт из переуступки',
      Offsets: 'Зачёты',
      'On Road Volume': 'Объем в дороге',
      'On first event': 'По первому событию',
      'On road': 'В пути',
      'On road amount': 'Товар в пути (сумма)',
      'One full cargo': 'Весь объем',
      'One of the vessel voyages, which will execute this deal':
        'Один из рейсов судна, которое будет выполнять эту сделку',
      'Only complete transactions': 'Только завершенные транзакции',
      'Only my updates': 'Только мои',
      'Only users with permission secret_invoice will be able see this invoice':
        'Только пользователи со специальным разрешением могут увидеть этот инвойс',
      Open: 'Открыто',
      'Open Positions': 'Открытые позиции',
      'Open amount balance': 'Баланс открытой суммы',
      'Open balance': 'Открытый баланс',
      'Open balance (forward)': 'Открытый баланс (форвард)',
      'Open balance (spot)': 'Открытый баланс (спот)',
      'Open balance amount': 'Сумма открытого баланса',
      'Open balance of all contracts': 'Открытый баланс по всем контрактам',
      'Open contracts': 'Открытые контракты',
      'Open position': 'Открытая позиция',
      'Open profit and loss': 'Открытые прибыль и потери',
      'Open/closed': 'Открыто/закрыто',
      Operation: 'Операция',
      'Operation amount': 'Сумма операции',
      'Operation saved': 'Операция сохранена',
      'Operation type': 'Тип операции',
      'Operational profit and loss': 'Операционная прибыль и потери',
      Operations: 'Операции',
      'Opposite basis': 'Противоположный базис',
      'Opposite contract costs': 'Встречные затраты контракта',
      'Opposite costs': 'Встречные затраты',
      'Opposite counterparties': 'Противоположные контрагенты',
      'Opposite derivative': 'Встречный дериватив',
      'Opposite freight': 'Встречный фрахт',
      'Opposite passport basis costs': 'Встречные затраты паспорта',
      'Opposite passport contract costs': 'Встречные затраты контракта паспорта',
      'Opposite price': 'Встречная цена',
      'Opposite value': 'Встречная цена',
      Option: 'Опция',
      'Order by': 'Сортировать по',
      'Order sale contract': 'Ордер продажных контрактов',
      Organization: 'Организация',
      Origin: 'Происхождение',
      'Original available': 'Есть оригинал',
      'Originals received': 'Оригиналы получены',
      Other: 'Другое',
      'Other Activity': 'Другое назначение',
      'Other activities': 'Другое назначение',
      'Other activity': 'Другое назначение',
      'Our Counterparty': 'Наш контрагент',
      'Our amount': 'Наша сумма',
      'Out date': 'Дата отправки',
      'Out from country': 'Из страны',
      'Out number': 'Исходящий номер',
      'Out of approval': 'Вне согласования',
      Outgoing: 'Исходящие',
      'Outgoing Volume': 'Исходящий объем',
      'Outgoing balance': 'Исходящий баланс',
      'Outgoing invoice status': 'Статус исходящего инвойса',
      'Outgoing invoices': 'Исходящие инвойсы',
      'Outgoing payments': 'Исходящие платежи',
      'Outstanding payment': 'Просрочена дата платежа',
      Overpaid: 'Переплата',
      Own: 'Собственные',
      'Own costs': 'Собственные затраты',
      Owner: 'Владелец',
      "Owner's bank account": 'Банковский счет владельца',
      Owners: 'Владельцы',
      'P&L': 'P&L',
      'P&L Consolidated': 'P&L консолидированный',
      'P&L MTM': 'P&L MTM',
      'P&L MTM (cts/bu)': 'P&L MTM (ц/буш)',
      'P&L MTM per one lot': 'P&L MTM за один лот',
      'P&L MTM, $': 'ПнЛ Оценка к рынку, $',
      'P&L books': 'P&L бухгалтерский',
      'P&L distribution': 'Распределение PnL',
      'P&L per passport': 'ПнЛ по паспорту',
      'P/L': 'ПнЛ',
      PAID: 'ОПЛАЧЕНО',
      PARAMETERS: 'ПАРАМЕТРЫ',
      PASSPORT: 'ПАСПОРТ',
      'PASSPORT COSTS': 'ЗАТРАТЫ ПАСПОРТА',
      PASSPORTS: 'ПАСПОРТА',
      PAYMENT: 'ОПЛАТА',
      'PAYMENT CONDITIONS': 'УСЛОВИЯ ОПЛАТЫ',
      'PAYMENT ORDERS': 'ПЛАТЕЖНЫЕ ПОРУЧЕНИЯ',
      PAYMENTS: 'ПЛАТЕЖИ',
      PLANNED: 'ПЛАН',
      PLANNING: 'МЕСПЛАН',
      PLANS: 'ПЛАНЫ',
      PNL: 'ПнЛ',
      'PNL report': 'ПнЛ отчет',
      'PO number': 'PO номер',
      POD: 'ПО',
      'POD under way': 'POD в пути',
      POL: 'ПВ',
      'POL under way': 'POL в пути',
      PORTS: 'ПОРТЫ',
      POSITION: 'ПОЗИЦИЯ',
      POSITIONS: 'ПОЗИЦИИ',
      'POTENTIAL BUYERS': 'ПОТЕНЦИАЛЬНЫЕ ПОКУПАТЕЛИ',
      'POTENTIAL SUPPLIERS': 'ПОТЕНЦИАЛЬНЫЕ ПОСТАВЩИКИ',
      PRICE: 'ЦЕНА',
      PROPERTIES: 'ПОКАЗАТЕЛИ',
      PURCHASE: 'ЗАКУПКА',
      'PURCHASE ASC': 'PURCHASE ASC',
      'PURCHASE CONTRACTS': 'ЗАКУПОЧНЫЕ КОНТРАКТЫ',
      'PURCHASE COSTS': 'ЗАКУПКА ЗАТРАТЫ',
      'PURCHASE DESC': 'PURCHASE DESC',
      'PURCHASE PLANS': 'ПЛАНЫ ЗАКУПКИ',
      Packing: 'Упаковка',
      'Packing List': 'Упаковочный лист',
      'Packing is too large number, less than 6 digits required (3 for integer, 3 for decimal).':
        'В упаковке слишком большое число нужно указать менее 6 цифр (3 для целого числа, 3 для десятичного).',
      'Page filter config': 'Конфигурация фильтра страницы',
      'Page scale': 'Масштаб страницы',
      'Page size': 'Размер страницы',
      'Page url': 'Ссылка страницы',
      Paid: 'Оплачено',
      'Paid (bal)': 'Оплач (бал)',
      'Paid Cargo': 'Груз оплачено',
      'Paid amount': 'Сумма полученных оплат',
      'Paid date': 'Дата оплаты',
      'Paid volume': 'Оплаченный объем',
      'Paid volume (t)': 'Оплаченный объем (т)',
      Paper: 'Бумажная',
      'Paper trade': 'Бумажная торговля',
      Parameters: 'Параметры',
      'Parent crop': 'Родительская культура',
      'Parent reassignment': 'Источник переуступки',
      'Parse this quote': 'Спарсить эту котировку',
      Parsing: 'Парсинг',
      'Parsing config template removed': 'Шаблон парсинга котировок удалён',
      'Parsing enabled': 'Парсинг включен',
      'Partial shipment allowed': 'Частичный объем',
      Passport: 'Паспорт',
      'Passport basis costs': 'Затраты паспорта',
      'Passport business date': 'Бизнес дата паспорта',
      'Passport business date updated': 'Бизнес дата паспорта обновлена',
      'Passport conclusion date': 'Дата заключения паспорта',
      'Passport connection': 'Паспорт привязки',
      'Passport contract': 'Паспорт контракта',
      'Passport contract costs': 'Затраты контракта паспорта',
      'Passport costs': 'Затраты паспорта',
      'Passport created.': 'Паспорт создан.',
      'Passport date': 'Бизнес дата паспорта',
      "Passport doesn't exist": 'Паспорт не существует',
      'Passport filled in  manually': 'Паспорт заполнен вручную',
      'Passport info': 'Паспорт информация',
      'Passport operation': 'Операция паспорта',
      'Passport quantity': 'Паспорт количество',
      'Passport removed': 'Паспорт убран',
      'Passport saved': 'Паспорт сохранен',
      'Passport status': 'Паспорт статус',
      'Passport status updated': 'Паспорт обновлен',
      'Passport title': 'Название паспорта',
      'Passport totals': 'Итоги паспорта',
      'Passport updated': 'Паспорт обновлен',
      'Passport updated.': 'Паспорт обновлен.',
      'Passport volume updated': 'Объем паспорта обновлен',
      'Passport. Completed if contract is attached to passports on full volume':
        'Паспорт. Завершено когда контракт полностью распределен по паспортам',
      Passport_connected: 'Паспорт связано',
      Passports: 'Паспорта',
      'Passports Profit and Loss': 'Паспорта P&L',
      'Passports costs': 'Затраты паспортов',
      'Passports report': 'Отчет по паспортам',
      Password: 'Пароль',
      Past: 'Прошедшее',
      'Paste a hyperlink to DHL here to track the status of delivery':
        'Вставьте гиперссылку на DHL, чтобы отслеживать статус доставки',
      'Pay now': 'Оплатить сейчас',
      Payables: 'Кредиторка',
      Payment: 'Оплата',
      'Payment Fact': 'Платеж факт',
      'Payment Info': 'Платеж инфо',
      'Payment Plan': 'Планы оплат',
      'Payment Plans': 'Планы оплат',
      'Payment amount': 'Сумма оплат',
      'Payment bank account': 'Банковский счет платежа',
      'Payment condition': 'Условия оплаты',
      'Payment conditions': 'Условия платежа',
      'Payment conditions (%)': 'Условия платежа (%)',
      'Payment conditions (description)': 'Условия платежа (опция)',
      'Payment conditions auto (%)': 'Условия оплаты (авто)',
      'Payment conditions option': 'Условия платежа (опция)',
      'Payment conditions options': 'Условия оплаты (опции)',
      'Payment confirmation': 'Оплата подтверждение',
      'Payment date': 'Дата платежа',
      'Payment delays': 'Задержки платежей',
      'Payment info': 'Информация об оплате',
      'Payment info saved': 'Платежная информация сохранена',
      'Payment plan': 'План платежа',
      'Payment plan removed': 'План удален',
      'Payment plan saved': 'План сохранен',
      'Payment plans': 'Планы оплат',
      'Payment removed': 'Оплата удалена',
      'Payment saved': 'Оплата сохранена',
      'Payment stock (t)': 'Задолженность за отгрузку (т)',
      'Payment stock amount': 'Сумма оплат остаток',
      'Payment updated': 'Оплата обновлен',
      Payments: 'Оплаты',
      'Payments currency exchange': 'Обменный курс оплат',
      'Payments margin': 'Маржа по платежам',
      'Payments plans': 'Планы оплат',
      'Payments were created': 'Оплаты созданы',
      Penalties: 'Штрафы',
      'Penalties VAT': 'Штрафы НДС',
      Pending: 'В процессе',
      'Pending confirmation': 'Ожидает подтверждения',
      'Per 1 t': 'За 1 т',
      'Percent conditions': 'Процент условия',
      Percentage: 'Процент',
      'Percentage, %': 'Процент, %',
      Period: 'Период',
      'Period of detalization': 'Период детализации',
      Permissions: 'Права',
      Person: 'Контакт',
      'Person - main contact of the company': 'Основной контакт компании',
      'Person removed': 'Контакт убран',
      'Person saved': 'Контакт сохранен',
      'Person signatory eng': 'Подписант (англ)',
      'Person updated': 'Контакт обновлен',
      Personal: 'Персональные',
      'Personal settings': 'Персональные настройки',
      Persons: 'Контакты',
      Phone: 'Телефон',
      'Phone (additional)': 'Дополнит телефон',
      'Phone add': 'Телефон (доп)',
      'Phone additional': 'Телефон (доп)',
      Physical: 'Физическая',
      'Physical MTM': 'Физическая оценка к рынку',
      'Physical position': 'Физическая позиция',
      'Physical position + MTM': 'Физическая позиция + Оценка к рынку',
      'Physical position by months': 'Физическая позиция по месяцам',
      'Pick a bank account': 'Выбрать банковский счет',
      'Pick a borrower that will be bound to this loan':
        'Выбрать заемщика который будет привязан к займу',
      'Pick a counterparty that will be bound to this invoice':
        'Выберите роль или контрагента для того, чтобы связать с инвойсом',
      'Pick a currency': 'Выбрать валюту',
      'Pick a date': 'Выберите дату',
      'Pick a deadline': 'Выберите дедлайн',
      'Pick a distributor that will be bound to this offset':
        'Выберите дистрибьютора который будет связан с этим зачетом',
      'Pick a distributor that will be bound to this reassignment':
        'Выберите дистрибьютора который будет связан с этой переуступкой',
      'Pick a lender that will be bound to this loan':
        'Выбрать кредитора который будет привязан к займу',
      'Pick a loan agreement': 'Выбрать договор займа',
      'Pick a time': 'Выберите время',
      'Pick a voyage to which this bill of lading is related too':
        'Выберите рейс, к которому также относится этот коносамент',
      'Pick an owner that will be bound to this reassignment':
        'Выберите владельца который будет связан с этой переуступкой',
      'Pick currency in which you want to convert USD':
        'Выберите валюту, которую хотите конвертировать в USD',
      'Pick exchange rate if you want to convert invoice value to USD by certain rate':
        'Выберите обменный курс, если вы хотите конвертировать суму инвойса в USD по определенному курсу',
      'Pick from 0 to 100 to set up prepay': 'От 0 до 100 чтобы задать предоплату',
      'Pick from 0 to 200': 'Выберите что-нибудь от 0 до 200',
      'Pick from 0 to 200, use this or discount amount':
        'Выберите от 0 до 200 или используйте значение дисконта',
      'Pick here a date, when you got first contact with this company':
        'Выберите дату когда состоялся первый контакт с компанией',
      'Pick here a date, when you need to contact this company in future':
        'Выберите дату, когда необходимо в следующий раз связаться с этой компанией',
      'Pick one of the options to define the status of relations with the company':
        'Выберите одну из опций, чтобы определить статус ваших взаимоотношений с этой компанией',
      'Pick one of the options to define the status of the company':
        'Выберите одну из опций, чтобы определить статус ваших взаимоотношений с этой компанией',
      'Pick the short name for counterparty. For convenience try to avoid naming the form of ownership, e.g. LLC  etc. You can appoint it in the full name. Good example for short name is COMPANY NAME without commas, etc. It would helpful you to find this company within search':
        'Выберите короткое название контгагента. Для удобства старайтесь избегать названий с формами собственности, например, ООО, и так далее. Вы можете указать это в Полном названии. Хороший пример короткого названия - НАЗВАНИЕ КОМПАНИИ без точек, скобок и запятых. Такие названия гораздо легче будет искать',
      Place: 'Точка отгрузки',
      'Place of destination': 'Место поставки',
      'Place of storage': 'Место хранения',
      Places: 'Места',
      Plan: 'План',
      'Plan VS contracted': 'План VS законтрактовано',
      'Plan VS delivered': 'План VS доставлено',
      'Plan amount': 'Сумма плана',
      'Plan costs': 'План затрат',
      'Plan created': 'План создан',
      'Plan execution range': 'Исполнение плана',
      'Planned balance (t)': 'Плановый баланс (т)',
      'Planned balance amount': 'Планируемая балансовая сумма',
      'Planned distributor': 'Плановый дистрибьютор',
      'Planned distributors': 'Плановые дистрибьюторы',
      Planning: 'Месплан',
      Plans: 'Планы',
      'Please select at least one BL.': 'Пожалуйста, выберите минимум один коносамент.',
      'Please select at least one Disbursement Bill of Lading':
        'Пожалуйста, выберите как минимум одну расходную накладную',
      'Please select at least one invoice': 'Пожалуйста, выберите минимум один инвойс',
      'Please select at least one logistic':
        'Пожалуйста, выберите минимум одно транспортное средство',
      'Please select at least one object': 'Пожалуйста, выберите хотя бы один объект',
      'Please set date.': 'Укажите дату.',
      'Please, not the all the values related to contracts and internal objects are\n                  corrected according to the volume of allocation of certain contract.':
        'Пожалуйста, не все значения, связанные с контрактами и внутренними объектами,\n                   корректируется в соответствии с объемом распределения определенного контракта.',
      'Please, select sale export contract filter':
        'Пожалуйста, выберите продажных экспортный контракт',
      Pledge: 'Залог',
      'Pmt date': 'Дата платежа',
      PnL: 'ПнЛ',
      Port: 'Порт',
      'Port of Discharge': 'Порт выгрузки',
      'Port of Loading': 'Порт отгрузки',
      'Port of destination': 'Порт назначения',
      'Port of discharge': 'Порт выгрузки',
      'Port of loading': 'Порт отгрузки',
      Ports: 'Порты',
      'Ports list': 'Список портов',
      'Ports list eng': 'Список портов англ',
      'Ports list short name': 'Список портов короткие назв',
      Position: 'Позиция',
      'Position P&L + MTM': 'Позиция ПнЛ + Оценка к рынку',
      'Position saved': 'Позиция сохранена',
      'Position updated': 'Позиция обновлена',
      Positions: 'Позиции',
      'Potential crops': 'Потенциальные товары',
      'Potential purchase': 'Потенциальная закупка',
      Potentials: 'Потенциалы',
      Premium: 'Премия',
      'Premium balance': 'Премиум баланс',
      'Premium currency': 'Премия валюта',
      'Premium for delivered goods, Plan': 'Премия за доставленные товары, план',
      'Premium price': 'Премиум цена',
      'Premium price opposite': 'Премиум цена встречная',
      'Premium value': 'Премия',
      Prepay: 'Предоплата',
      'Prepay - amount of money, will be paid by contract, before final delivery,  Balance of money, will be paid after end of delivery':
        'Предоплата - сумма, которая будет выплачена до окончания поставки. Баланс - сумма, которая выплачена после окончания поставки',
      'Prepay - balance percent': 'Предоплата - баланс процент',
      'Prepay invoice': 'Предоплата инвойс',
      'Prepay payment dates': 'Предоплата даты оплаты',
      'Prepay volume': 'Предоплата объем',
      'Prepayment invoice': 'Счет предоплаты',
      'Preset Set Saved': 'Шаблон сохранен',
      'Preset Set Updated': 'Шаблон обновлен',
      'Preset Set removed': 'Шаблон удален',
      Preshipment: 'Препогрузка',
      'Press read if you want to tick off the notification, or press detail to go to the\n                details of the linked object':
        'Нажмите кнопку прочитать, если хотите отключить уведомление, или нажмите кнопку "Детали",\n                чтобы узнать больше о связанном объекте',
      'Press to control': 'Нажмите контроль',
      'Press to show more': 'Загрузить больше',
      Price: 'Цена',
      'Price 1t': 'Цена 1т',
      'Price currency': 'Валюта цены',
      'Price for measurement': 'Цена на измерение',
      'Price in USD': 'Цена, USD',
      'Price in USD words': 'Цена, USD прописью',
      'Price of premium': 'Премиум цена',
      'Price opposite': 'Противоположная цена',
      'Price per piece': 'Цена за шт',
      'Price per t': 'Цена за тонну',
      'Price premium': 'Премиум цена',
      'Price premium opposite': 'Премиум цена встречная',
      'Price w/o VAT, UAH': 'Цена без НДС, UAH',
      'Price with VAT, UAH': 'Цена с НДС, UAH',
      'Price with discount': 'Цена с дисконтом',
      'Price, $': 'Цена, $',
      Prices: 'Цены',
      Print: 'Печать',
      'Print a contract': 'Распечатать контракт',
      'Print a document': 'Сгенерировать документ',
      'Print an invoice': 'Распечатать инвойс',
      'Print and sign': 'Распечатать и подписать',
      Priority: 'Приоритет',
      Process: 'Процесс',
      Processed: 'В процессе',
      Processing: 'Процесс',
      'Processing...': 'В процессе...',
      Producer: 'Производитель',
      'Production Year': 'Год производства',
      Products: 'Продукты',
      Profile: 'Профиль',
      Profit: 'Прибыль',
      'Profit and Loss': 'Прибыль и потери',
      'Profit and loss': 'Прибыль и потери',
      Profitability: 'Прибыльность',
      Proforma: 'Проформа',
      'Proforma Invoice': 'Проформа инвойс',
      'Program type': 'Программа тип',
      Progress: 'Процесс',
      'Project cost, $ with VAT': 'Стоимость проекта, дол с НДС',
      'Project cost, with VAT': 'Стоимость проекта, с НДС',
      'Project status': 'Статус проекта',
      'Projected rate': 'Ожидаемая ставка',
      'Projects Status Detailed Summary': 'Статус проектов детальная сводка',
      Properties: 'Свойства',
      Property: 'Показатель',
      'Property "positions volume by execution" is disabled.':
        'Свойство "Объем позиций по исполнению" отключено.',
      'Property alternative': 'Свойство альтернативное',
      Provider: 'Поставщик',
      Purcahse: 'Закупка',
      Purchase: 'Закупка',
      'Purchase Contract': 'Закупочный контракт',
      'Purchase Currencies': 'Закупка валюты',
      'Purchase Plan': 'План закупки',
      'Purchase Price': 'Цена закупки',
      'Purchase author': 'Закупка автор',
      'Purchase contract': 'Закупочный контракт',
      'Purchase contract #': 'Закупочный контракт №',
      'Purchase contract author': 'Закупочный контракт автор',
      'Purchase contract passports': 'Паспорт закупочного контракта',
      'Purchase contracts': 'Закупочные контракты',
      'Purchase loyalty program': 'Закупка программа лояльности',
      'Purchase plan': 'План закупки',
      'Purchase plan report': 'План закупки отчёт',
      'Purchase price': 'Цена закупки',
      'Purchase price local': 'Закупочная цена локальная',
      'Purchase price wit VAT': 'Цена закупка с НДС',
      'Purchase price wit VAT on Elevator': 'Цена закупка с НДС на Элеваторе',
      'Purchase price without VAT': 'Цена закупка без НДС',
      'Purchase responsible': 'Ответственный закупка',
      'Purchase responsible for signing': 'Закупка ответственный за подписание',
      'Purchase sale manager': 'Закупка менеджер продажи',
      'Purchase this': 'Купить это',
      'Purchase to execute': 'Закупка для исполнения',
      'Purchase to invoice': 'Инвойс закупки',
      'Purchase to pay': 'Закупка к оплате',
      'Purchase totals': 'Всего по закупке',
      'Purchase value local': 'Стоимость закупки локальная валюта',
      PurchaseContract: 'Закупочный контракт',
      QTY: 'КОЛ-ВО',
      QUARTER: 'КВАРТАЛ',
      Qualities: 'Качество',
      Quality: 'Качество',
      'Quality deleted': 'Качество удалено',
      'Quality eng': 'Качество англ',
      'Quality list': 'Список качеств',
      'Quality list eng': 'Список качеств англ',
      'Quality required': 'Качеств обяз',
      'Quantitative metrics': 'Количественные метрики',
      Quantity: 'Количество',
      'Quantity Total': 'Кол-во общее',
      'Quantity Total Amount': 'Общая сумма кол-ва',
      'Quantity of measurement': 'Количество измерений',
      'Quantity of wagons by contract': 'Количество вагонов по контракту',
      'Quantity total': 'Кол-во общее',
      'Quantity total amount': 'Кол-во общая сумма',
      Quarantine: 'Карантин',
      'Quarantine required': 'Карантин обязателен',
      'Quick add': 'Быстро добавить',
      'Quick edit': 'Быстрое редакт.',
      'Quote Parsing Config': 'Конфигурация парсинга котировок',
      'Quote Parsing Config saved': 'Конфигурация парсинга котировок сохранена',
      'Quotes updated.': 'Котировки обновлены.',
      'RATES & COSTS': 'СТАВКИ и ЗАТРАТЫ',
      'READ ALL': 'ПРОЧИТАТЬ ВСЁ',
      REALIZED: 'РЕАЛИЗИРОВАН',
      REASSIGNMENT: 'ПЕРЕУСТУПКИ',
      REASSIGNMENTS: 'ПЕРЕУСТУПКИ',
      RECEIVE: 'ПОЛУЧЕНИЕ',
      'RECENTLY UPDATED': 'ПОСЛЕДНЕЕ ОБНОВЛЕНИЕ',
      RESULT: 'РЕЗУЛЬТАТ',
      RIGHTS: 'ПРАВА',
      ROE: 'ROE',
      ROS: 'ROS',
      'RW payment conditions': 'Условия оплаты (жд)',
      'RW prepay - balance percent': 'ЖД предоплата - баланс %',
      Railway: 'Ж/д',
      'Railway cost': 'ЖД затраты',
      'Railway enterance': 'Ж\\д внесение',
      'Railway losts': 'ЖД потери',
      'Railway prepay - balance percent': 'ЖД предоплата - процент баланса',
      'Railway required': 'Ж/д обяз',
      Rate: 'Ставка',
      Rates: 'Ставки',
      'Re-approval': 'Повтор подтв',
      'Re-approve': 'Повторное подтверждение',
      Reactivate: 'Активировать повторно',
      'Reactivate approving': 'Активировать повторно',
      'Real stocks': 'Реальные остатки',
      Realized: 'Реализов',
      'Realized profit and loss': 'Реализованные прибыль и потери',
      'Realized profit and loss per 1': 'Реализованный PnL за 1т',
      'Realized quantity': 'Реализованный объем',
      'Reason for rejection': 'Причины отказа',
      Reassignment: 'Переназначение',
      'Reassignment amount': 'Переуступка сумма',
      'Reassignment date': 'Переуступка дата',
      "Reassignment doesn't exist": 'Переуступки не существует',
      'Reassignment list': 'Список переуступок',
      'Reassignment number': 'Переуступка номер',
      'Reassignment positions': 'Переуступка позиции',
      'Reassignment rate': 'Переуступка ставка',
      'Reassignment saved': 'Переуступка сохранена',
      'Reassignment status': 'Переуступка статус',
      'Reassignment value': 'Переуступки сумма',
      Reassignments: 'Переуступки',
      Recall: 'Перезвонить',
      Receivables: 'Доходы',
      Received: 'Получ',
      'Received (date)': 'Дата получения',
      'Received date': 'Дата получения',
      'Received registry (port)': 'Регистр получен (порт)',
      'Received value': 'Стоимость полученного товара',
      'Received volume': 'Полученный объем',
      Receiver: 'Получатель',
      Receiving: 'Получение',
      'Receiving (date)': 'Дата получения',
      'Receiving date': 'Дата получения',
      'Receiving date from': 'Дата получения от',
      'Receiving date to': 'Дата получения до',
      'Recent confirmations': 'Последние подтверждения',
      'Recieve station': 'Станция получения',
      'Recipient type': 'Тип получателя',
      Recipients: 'Получатели',
      Reconciliation: 'Сверка',
      Recreate: 'Пересоздать',
      Red: 'Красное',
      Region: 'Область',
      Regions: 'Область',
      'Register stocks': 'Зарегистрированные остатки',
      'Registered since': 'Регистрация от',
      Registry: 'Реестр',
      'Registry received (forwarder)': 'Регистр получен (экспедитор)',
      'Registry sent (forwarder)': 'Регистр отправлен (перевозчик)',
      'Registry sent (port)': 'Регистр отправлен (порт)',
      Rejected: 'Отмена',
      'Related counterparty': 'Связанный контрагент',
      'Related counterparty from': 'Связанный контрагент от кого',
      'Related counterparty to': 'Связанный контрагент кому',
      'Related deal': 'Связанная сделка',
      'Related fees': 'Связанные комиссии',
      'Related invoice': 'Номер связанного инвойса',
      'Related invoice number': 'Номер связанного инвойса',
      Relation: 'Отношение',
      Relations: 'Отношения',
      Remains: 'Остатки',
      'Remind me before': 'Напомнить мне до',
      Remove: 'Удалить',
      Rentability: 'Рентабельность',
      'Report type': 'Тип отчета',
      Reports: 'Отчеты',
      Request: 'Заявка',
      'Request (date)': 'Дата запроса доков',
      'Request date': 'Дата заявки',
      'Request removed': 'Заявка удалена',
      'Request saved': 'Заявка сохранена',
      'Request updated': 'Заявка обновлена',
      Requested: 'Запрос',
      Required: 'Обязательно',
      Reset: 'Перезагрузить',
      'Residual volume': 'Остаточный объем',
      Resource: 'Ресурс',
      Response: 'Ответ',
      Responsibilities: 'Ответственности',
      Responsibility: 'Ответственность',
      Responsible: 'Ответственный',
      'Responsible for signing': 'Ответственный за подписание',
      Result: 'Результат',
      'Result date': 'Результат дата',
      'Result month': 'Результат месяц',
      'Result status': 'Результат статус',
      Risk: 'Риск',
      'Risk position terminal': 'Риск позиция терминал',
      'Risk reports': 'Отчеты о рисках',
      Road: 'Дорога',
      Role: 'Роль',
      Roles: 'Роли',
      'Roles, in which this Counterparty will be presented in system.You can select multiple roles, pressing CTRL (CMD)':
        'Роли, в которых этот Контрагент будет представлен в системе. Вы можете выбрать несколько ролей, нажав CTRL (CMD)',
      Rolling: 'Подвижной',
      'Rolling stock': 'Rolling состав',
      Russia: 'Россия',
      Russian: 'Русский',
      SALE: 'ПРОДАЖА',
      'SALE ASC': 'SALE ASC',
      'SALE CONTRACTS': 'ПРОДАЖНЫЕ КОНТРАКТЫ',
      'SALE COSTS': 'ПРОДАЖА ЗАТРАТЫ',
      'SALE DESC': 'SALE DESC',
      SAMPLES: 'ОБРАЗЦЫ',
      'SAP Order Purchase Contract': 'SAP заказ закупочный контракт',
      'SAP order': 'SAP номер заказа',
      'SAP order numbers': 'SAP номера заказов',
      'SAP vendor number': 'SAP номер контрагента',
      SAVE: 'СОХРАНИТЬ',
      SEARCH: 'ПОИСК',
      SELLER: 'ПРОДАВЕЦ',
      SETTINGS: 'НАСТРОЙКИ',
      SHIPMENT: 'ОТГРУЗКА',
      'SHIPMENT END': 'КОНЕЦ ОТГРУЗКИ',
      'SHIPMENT START': 'НАЧАЛО ОТГРУЗКИ',
      SHORT: 'ШОРТ',
      SI: 'ПосИнв',
      SIDES: 'СТОРОНЫ',
      SIGNATURE: 'ПОДПИСЬ',
      SIGNINGS: 'ПОДПИСАНИЯ',
      SP: 'ПосОпл',
      SPECIAL: 'ОСОБЕННОЕ',
      STATISTICS: 'СТАТИСТИКА',
      STATUS: 'СТАТУС',
      STEP: 'ШАГ',
      SUPPLIER: 'ПОСТАВЩИК',
      'SUPPLIER CONFIRMATION': 'ПОСТАВЩИК ПОДТВЕРЖДЕНИЕ',
      SWIFT: 'SWIFT',
      'SYSTEM NOTIFICATION': 'УВЕДОМЛЕНИЯ',
      Sa: 'Сб',
      Sale: 'Продажа',
      'Sale (date)': 'Продажа (дата)',
      'Sale Contract': 'Продажный контракт',
      'Sale Currencies': 'Продажа валюты',
      'Sale Price': 'Цена продажи',
      'Sale contract': 'Продажный контракт',
      'Sale contract passports': 'Паспорт продажного контракта',
      'Sale contracts': 'Продажные контракты',
      'Sale currency and rate': 'Валюта и курс',
      'Sale end execution': 'Продажа конец исполнения',
      'Sale export contract': 'Продажный экспортный контракт',
      'Sale invoice date': 'Продажный инвойс дата',
      'Sale or Purchase': 'Продажа или Закупка',
      'Sale payment date': 'Дата оплаты продажа',
      'Sale plan report': 'План продаж',
      'Sale price': 'Цена продажи',
      'Sale price (incl. VAT)': 'Цена продажи (вкл. НДС)',
      'Sale price local': 'Продажная цена локальная',
      'Sale start execution': 'Продажа начало исполнения',
      'Sale this': 'Продать это',
      'Sale to execute': 'Продажа для исполнения',
      'Sale to invoice': 'Продажа по инвойсу',
      'Sale to pay': 'Продажа до оплаты',
      'Sale totals': 'Продажа тоталы',
      SaleContract: 'Продажный контракт',
      Sales: 'Продажи',
      'Sales managers': 'Региональные представители',
      'Sample Control': 'Контроль образцов',
      'Sample In date': 'Образец дата',
      'Sample Out date': 'Образцы исходящая дата',
      'Sample received date': 'Образец дата получения',
      'Sample result date': 'Образец дата результата',
      'Sample type': 'Тип образца',
      Samples: 'Образцы',
      'Sampling Condition': 'Состояние образцов',
      'Sap Order': 'SAP номер заказа',
      'Sap Orders': 'SAP заказы',
      'Sap orders': 'Заказы SAP',
      Saturday: 'Суббота',
      Save: 'Сохранить',
      'Save current filter': 'Сохранить текущий фильтр',
      'Save filers as a preset': 'Сохранить набор фильтров как шаблон',
      'Save without diff': 'Сохранить без изменений',
      Saved: 'Сохранено',
      Seal: 'Пломба',
      Search: 'Поиск',
      'Search and press ↵ ENTER': 'Введите и нажмите ↵ ENTER',
      'Search in database': 'Поиск в базе',
      'Search permissions': 'Искать полномочия',
      Season: 'Сезон',
      'Season year': 'Сезон год',
      'Second (validation)': 'Второй (подтверждение)',
      'Second Name': 'Фамилия',
      'Second name': 'Фамилия',
      'Secret invoice': 'Секретный инвойс',
      'See all': 'Смотреть все',
      Seeds: 'Посевы',
      Select: 'Выбор',
      'Select .XLS template': 'Выберите шаблон . XLS',
      'Select All': 'Выбрать все',
      'Select Business Unit': 'Выбрать Бизнес юнит',
      'Select Email Template': 'Выберите шаблон Email',
      'Select User to be responsible': 'Выберите ответственного пользователя',
      'Select a collection': 'Выбрать коллекцию',
      'Select a document': 'Выбрать документ',
      'Select a passport': 'Выбрать паспорт',
      'Select a tags': 'Выбрать теги',
      'Select all documents': 'Выбрать все документы',
      'Select all transport': 'Выбрать весь транспорт',
      'Select any of item': 'Выберите любой элемент',
      'Select business unit': 'Выбрать Бизнес юниты',
      'Select by which contract property you want to group P&L. Default\n                            grouping can be set in Settings.':
        'Выберите, по какому свойству контракта вы хотите сгруппировать PnL. По умолчанию\n                            группировку можно установить в настройках.',
      'Select cargo': 'Выберите груз',
      'Select cargo receiver': 'Выберите грузополучателя',
      'Select cargo shipper': 'Выберите грузоотправителя',
      'Select charges and press confirm': 'Выберите затраты и нажмите подтвердить',
      'Select commodity of passport': 'Выбрать товар паспорта',
      'Select consignment': 'Выберите накладные',
      'Select consignments to create costs': 'Выберите накладные для создания затрат',
      'Select contracts for passport': 'Выбрать контракты для паспорта',
      'Select date': 'Выберите дату',
      'Select departure station': 'Выберите станцию отправления',
      'Select elevator': 'Выберите элеватор',
      'Select invoices to send': 'Выберите инвойсы для отправки',
      'Select logistics': 'Выберите логистику',
      'Select or drag and drop files': 'Выберите или перетащите файл',
      'Select property': 'Выберите значение',
      'Select purchase contract': 'Выбрать закупочный контракт',
      'Select responsible': 'Выберите ответственного',
      'Select sale contract': 'Выберите продажный контракт',
      'Select station destination': 'Выберите станцию назначения',
      'Select terminal': 'Выберите терминал',
      'Select to': 'Выбрать',
      'Select to Payment Plan': 'Запланировать',
      'Select transport': 'Выбрать транспорт',
      'Select transport to send': 'Выберите транспорт для отправки',
      'Select which margin type you want to display from passports. E.g.\n                            contract - only plan. If mixed - we will show the appropriate margin\n                            per each passport as per MARGIN SELECTOR setting.':
        'Выберите тип маржи, которую хотите отображать из паспортов.\n                            Например, контракт – только план. Если смешанный – мы покажем соответствующую маржу\n                            за каждый паспорт в соответствии с настройкой ПЕРЕМИКАТЕЛЬ МАРЖИ .',
      'Select/Deselect all': 'Выбрать/Отменить всё',
      'Select/Unselect all': 'Выбрать/Отменить',
      Selected: 'Выбранные',
      'Selfcost Amount, $': 'Себестоимость, $',
      'Selfcost per MT': 'Себестоимость за 1т',
      Sell: 'Продажа',
      'Sell quantity': 'Количество продажи',
      Seller: 'Продавец',
      Send: 'Отправить',
      'Send Screenshot': 'Отправить снимок экрана',
      'Send an invoice': 'Отправить инвойс',
      'Send business confirmation': 'Отправить бизнес подтверждение',
      'Send email': 'Отправить письмо',
      'Send selected': 'Отправить выбранные',
      Sent: 'Отправлено',
      'Sent docs to Buyers': 'Доки отправили Покупателям',
      September: 'Сентябрь',
      'Server error': 'Ошибка сервера',
      'Service invoice': 'Инвойс за услуги',
      Services: 'Услуги',
      'Set cargo confirmation': 'Установить подтверждение груза',
      'Set contract closure': 'Установить закрытие контракта',
      'Set contract final volume': 'Установить финальный объем контракта',
      'Set fact volume to plans': 'Установить фактический объем вместо плана',
      'Set if only you want to convert amount in USD by a certain rate':
        'Установить, если хотите конвертировать сумму в USD по определенному курсу',
      'Set it only if volume of charge differs from contract volume':
        'Установить, если объем стоимости отличается от объема контракта',
      'Set plan': 'Установить план',
      'Set status closed': 'Установить статус Закрыто',
      'Set the status Closed for all costs that are fully invoiced and paid':
        'Установить статус Закрыто для всех затрат, которые полностью проинвойсированы и оплачены',
      'Set voyage data': 'Обновить данные лодок',
      Settings: 'Настройки',
      'Ship classification': 'Класс лодки',
      Shipment: 'В процессе',
      'Shipment Elevator': 'Элеватор отгрузки',
      'Shipment Farm': 'Хозяйство отгрузки',
      'Shipment Plan': 'Месплан',
      'Shipment Terminal': 'Терминал отгрузки',
      'Shipment balance': 'Остаток к отгрузке',
      'Shipment days saved.': 'Дни отгрузки сохранены.',
      'Shipment elevator': 'Элеватор отгрузки',
      'Shipment farm': 'Хозяйство отгрузки',
      'Shipment from the farm': 'Отгрузка с хозяйства',
      'Shipment from the station': 'Отгрузка со станции',
      'Shipment on the farm': 'Отгрузка на хозяйство',
      'Shipment on the station': 'Отгрузка на станцию',
      'Shipment period': 'Период исполнения',
      'Shipment place': 'Место отгрузки',
      'Shipment plan deleted.': 'План отгрузки удален.',
      'Shipment plan saved.': 'План отгрузки сохранен.',
      'Shipment planned': 'Отгрузка распланирована',
      'Shipment terminal': 'Терминал отгрузки',
      'Shipment type': 'Тип отгрузки',
      'Shipment. Completed if shipment is done': 'Отгрузка. Успех - когда нужный объем исполнился',
      Shipments: 'Отгрузки',
      Shipped: 'Отгружено',
      'Shipped on board date': 'Дата погрузки на борт',
      'Shipped wagons': 'Погруженные вагоны',
      Short: 'Шорт',
      'Shortage rate': 'Шортадж ставка',
      Show: 'Показывать',
      'Show Aging': 'Показать задержки',
      'Show Facts': 'Показать факты',
      'Show MTM': 'Показать МТМ',
      'Show all': 'Показать всё',
      'Show bank balances': 'Показать банк балансы',
      'Show child crops': 'Показать дочерние культуры',
      'Show detail': 'Показать детали',
      'Show details': 'Показать детали',
      'Show full table': 'Показать полную таблицу',
      'Show history': 'Показать историю',
      'Show my': 'Показать мои',
      'Show volume': 'Показывать объем',
      'Show/hide all': 'Показать/скрыть все',
      Sides: 'Стороны',
      'Sign a contract': 'Подписать контракт',
      Signatory: 'Подписант',
      'Signatory Docs': 'Подписант документы',
      'Signatory Eng': 'Подписант английский',
      'Signatory Eng Docs': 'Подписант документов (Англ)',
      'Signatory docs': 'Подписант доки',
      'Signatory docs eng': 'Подписант доки (англ)',
      'Signatory eng': 'Подписант английский',
      'Signatory, the son of Signator': 'Подписант Подписантович',
      Signature: 'Подпись',
      'Signature stages': 'Стадии подписания',
      'Signature. Completed if all sides signed a document':
        'Подпись. Успех, когда все стороны подписали контракт',
      Signed: 'Подписано',
      Signings: 'Подписания',
      Similar: 'Похожие',
      'Since we group this report by contract, you should choose either by\n                            sales contracts or by purchase contracts':
        'Поскольку мы группируем этот отчет по контракту, необходимо выбрать\n                             или по продажным контрактам или по закупочным контрактам',
      Size: 'Размер',
      Small: 'Маленькая',
      'Something went wrong': 'Что-то пошло не так',
      'Something went wrong...': 'Что-то пошло не так…',
      'Something went wrong... try again.': 'Что-то пошло не так. Попробуйте ещё раз.',
      Source: 'Источник',
      Special: 'Специальное',
      'Specific information about freight': 'Особенная информация про этот объект',
      'Specific information about this object': 'Особенная информация про этот объект',
      Specification: 'Спецификация',
      Spot: 'Спот',
      'Spot fact': 'Спот факт',
      'Spot limit': 'Лимит спот',
      'Spot or Forward': 'Спот или форвард',
      Stage: 'Этап',
      'Stage #': 'Этап №',
      Start: 'Начало',
      'Start date': 'Дата начала',
      'Start date (plan)': 'Дата начала (план)',
      'Start end': 'Начало конца',
      'Start of execution': 'Начать исполнение',
      'Start usage (date)': 'Начало использ (дата)',
      Station: 'Станция',
      'Station Departure': 'Станция отправления',
      'Station arrival': 'Станция получения',
      'Station depart': 'Станция отправки',
      'Station departure': 'Станция отправки',
      'Station destination': 'Станция назначения',
      'Station receive': 'Станция получ',
      'Station receiving': 'Станция получ',
      Stations: 'Станции',
      Statistics: 'Статистика',
      Status: 'Статус',
      'Status depends on current progress on general agreement':
        'Статус зависит от текущего прогресса по Договору',
      'Status depends on current progress on payment plan':
        'Статус зависит от текущего прогреса по плану',
      'Status depends on current progress on request':
        'Статус зависит от текущего прогреса по Заявке',
      'Status of te deal: NEW - recently created, SHIPMENT - the execution has been started, EXECUTED - the Contract is closed and executed, CANCELLED - rejected by the manager':
        'Статус сделки: НОВОЕ - только созданное, ОТГРУЗКА: начало движения транспортных средств, ИСПОЛНЕНО - контракт выполнен в полном объеме, ОТМЕНЕНО - сделка отвергнута менеджером',
      'Status updated': 'Статус обновлен',
      'Status will be changed automatically, based on a compliance status. You need to fill out request, received and confirmed date':
        'Статус будет изменен автоматически в зависимости от статуса комплаенса. Необходимо заполнить запрос, дату получения и подтверждение',
      Statuses: 'Статусы',
      Step: 'Шаг',
      'Step saved': 'Шаг сохранен',
      Stock: 'Остатки',
      'Stock Exchange': 'Биржа',
      'Stock exchange': 'Биржевый курс',
      'Stock exchanges': 'Биржы',
      'Stock plan (t)': 'Остатки план (т)',
      'Stock plan amount': 'Остаток план сумма',
      Stocklot: 'Биржа',
      'Stocks allocation': 'Привязка остатков',
      Storage: 'Хранение',
      'Storage & cargo': 'Хранение и груз',
      'Storage days': 'Дни хранения',
      Stuff: 'Персонал',
      Su: 'Вс',
      'Sub distributor': 'Субдистрибьютор',
      'Sub reassignments': 'Двойная переуступки',
      'Sub use': 'Под назначение',
      Subject: 'Тема',
      'Submit documents': 'Подтвердить документы',
      Subscribe: 'Подписаться',
      Subuse: 'Под-назначение',
      Success: 'Успешно',
      'Success created.': 'Успешно создан.',
      'Successfully updated.': 'Успешно обновлено.',
      Summary: 'Итог',
      Sunday: 'Воскресенье',
      'Super Admin Rights': 'Права супер администратора',
      Supplier: 'Поставщик',
      'Supplier 10 invoice': 'Поставщик баланс счет',
      'Supplier 90 invoice': 'Поставщик пред счет',
      'Supplier EDRPOU code': 'Продавец ЕДРПОУ код',
      'Supplier ITN (Individual Tax Number)': 'Продавец индивидуальный налоговый номер',
      'Supplier Signed': 'Поставщик подписал',
      'Supplier address': 'Продавец адрес',
      'Supplier address eng': 'Продавец адрес англ',
      'Supplier balance invoice': 'Поставщик баланс счет',
      'Supplier bank details': 'Продавец банк. детали',
      'Supplier can signatory docs': 'Продавец подписант доки',
      'Supplier can signatory docs eng': 'Продавец подписант доки англ',
      'Supplier contract': 'Поставщик контракт',
      'Supplier contract #': 'Поставщик контракт №',
      'Supplier contract status': 'Поставщик контракт статус',
      'Supplier country': 'Продавец страна',
      'Supplier country eng': 'Продавец страна англ',
      'Supplier email': 'Продавец почта',
      'Supplier full name': 'Продавец полное название',
      'Supplier full name eng': 'Продавец полное название английский',
      'Supplier invoice volume': 'Поставщ инвойс объем поле',
      'Supplier invoiced': 'Поставщик инвойс',
      'Supplier is required': 'Поставщик обязателен',
      'Supplier legal address': 'Продавец адрес полный',
      'Supplier paid': 'Поставщик оплата',
      'Supplier passports': 'Поставщик паспорта',
      'Supplier phone': 'Продавец телефон',
      'Supplier prepay invoice': 'Поставщик пред счет',
      'Supplier signatory': 'Продавец подписант',
      'Supplier signed': 'Поставщик подписал',
      'Supplier website': 'Продавец сайт',
      Suppliers: 'Поставщики',
      Surveyor: 'Сюрвейер',
      Surveyors: 'Сюрвейеры',
      Swap: 'СВОП',
      Swift: 'Свифт',
      'System Event': 'События системы',
      'System notify': 'Системное уведомление',
      TARIFFS: 'ТАРИФЫ',
      TASKS: 'ЗАДАНИЯ',
      TEAM: 'КОМАНДА',
      TICKET: 'ТИКЕТ',
      'TICKET DOCUMENTS': 'ДОКУМЕНТЫ ТИКЕТА',
      TICKETS: 'ТИКЕТЫ',
      TITLE: 'НАЗВАНИЕ',
      TOTAL: 'ВСЕГО',
      'TOTAL INVOICE POSITIONS': 'ВСЕГО ИНВОЙС ПОЗИЦИЙ',
      TOTALS: 'ВСЕГО',
      'TRADING REPORTS': 'ТОРГОВЫЕ ОТЧЕТЫ',
      TRANSLOAD: 'ПЕРЕВАЛКА',
      TRANSPORT: 'ТРАНСПОРТ',
      'TRANSPORT DOCUMENTS': 'ТРАНСПОРТНЫЕ ДОКУМЕНТЫ',
      TURNOVER: 'ОБОРОТ',
      Table: 'Таблица',
      Tag: 'Тег',
      'Tare weight': 'Вес тары',
      Tariff: 'Тариф',
      'Tariff 20': 'Тариф 20',
      'Tariff 40': 'Тариф 40',
      Tariffs: 'Тарифы',
      Task: 'Задание',
      Tasks: 'Задания',
      Tax: 'Налог',
      'Tax info': 'Налоговая информация',
      'Tax profit to pay': 'Налог на прибыль',
      'Tax registration': 'Налог регистрация',
      'Tax status': 'Налоговый статус',
      Team: 'Команда',
      'Telegram Chat': 'Telegram чат',
      'Telegram username': 'Telegram никнейм пользователя',
      Template: 'Шаблон',
      'Template name': 'Название шаблона',
      'Template playground': 'Песочница для шаблонов',
      'Template removed': 'Шаблон удален',
      'Template saved': 'Шаблон сохранен',
      Templates: 'Шаблоны',
      'Term of proxy': 'Срок действия доверенности',
      Terminal: 'Терминал',
      'Terminal Transshipment': 'Терминал перевалки',
      'Terminal Transshipment Tariff': 'Тариф терминала перевалки',
      Terminals: 'Терминалы',
      Text: 'Текст',
      Th: 'Чт',
      'The Legal Entity for this Role will be auto created':
        'Юридическое лицо для этой роли будет создано автоматически',
      'The calculation principle is the following:': 'Принцип расчета заключается в следующем:',
      'The commission of bank': 'Комиссия банка',
      'The company name abbreviation': 'Аббревиатура компании',
      'The contract': 'Контракт',
      'The discount of VAT compensation by the deal': 'Дисконт по НДС по этой сделке',
      'The email for internal use': 'Почта для внутреннего использования',
      'The fields contract, derivative must make a unique set.':
        'Поле контракт, дериватив долен составлять уникальный набор.',
      'The fields voyage, commodity must make a unique set.':
        'Поля контракта дериватива должны составлять уникальный набор.',
      'The first name for internal use': 'Имя для внутреннего использования',
      'The form is': 'Форма',
      'The last name for internal use': 'Фамилия для внутреннего использования',
      'The main person of the company': 'Основной контакт компании',
      'The name for internal use, can be whatever you want':
        'Название для внутреннего использования, может быть каким угодно',
      'The normal loss rate of Elevator': 'Норма потерь Элеватора',
      'The normal loss rate of RW': 'Норма потерь ЖД',
      'The number on which sale price will be multiplied':
        'Процент на который будет умножена продажная цена',
      'The personal code of this company in state instances':
        'Уникальный код компании в государственных учреждениях. ЕДРПОУ, ИНН, и так далее',
      'The phone number for internal use': 'Телефон для внутреннего использования',
      'The rate of return for VAT for cargo': 'Ставка возврата НДС за груз',
      'The rate of return for VAT for costs': 'Ставка возврата НДС по затратам',
      'The same you can get on Email or Telegram. You can find the full list in Personal\n                settings.':
        'То же самое можно получить по Email или Telegram. Вы можете найти полный список\n                 в персональных настройках.',
      'The sum of delivery costs for intermediate logistics':
        'Сумма затрат на перевозку по этому промежуточному т\\с',
      'The sum of delivery costs for this vehicle': 'Сумма затрат на перевозку по этому т\\с',
      'The sum of penalties for this vehicle. Can be also a negative number':
        'Сумма штрафов по этому т/с. Может быть отрицательным числом',
      'The telegram username for internal use': 'Telegram никнейм для внутреннего использования',
      'The ticket': 'Тикет',
      'The username for internal use': 'Никнейм для внутреннего использования',
      TheadConfig: 'Конфигурация заголовка',
      Theme: 'Тема',
      'There are no selected filters to save': 'Нет выбранных фильтров для сохранения',
      'There are not undrop mailing in your set': 'В вашем наборе нет отложенных рассылок',
      'There are not unsent mailing in your set': 'Все рассылки отправлены',
      'There can be automatic updates (e.g. the system automatically posts information\n                about invoices, contracts, payments by counterparty in chronological order)':
        'Могут быть автоматические обновления (например, система автоматически публикует информацию\n                об инвойсах, контрактах, платежах контрагентов в хронологическом порядке)',
      'There is nothing to show': 'Ничего не нашли',
      'These is the Subject, your recipients see when receive letter':
        'Это Тема. Её увидят получатели',
      'These is the Title, only you will see, to identify current template':
        'Это Название. Видите его только вы, для того, что бы идентифицировать шаблон',
      'Third (validation)': 'Третье (валидация)',
      'This Month': 'Текущий месяц',
      'This actin will start voting again. Continue?':
        'Это действие снова начнет голосование. Продолжать?',
      'This action will create new ticket. It can be deleted with "Delete" button in the modal window. How much to bite off?':
        'Это создаст новый тикет. Его можно удалить с помощью кнопки "Удалить" в модальном окне. Какой объем "откусить"?',
      'This amount will change final invoice amount':
        'Эта сумма изменит окончательную сумму инвойса',
      'This cannot be undone': 'Это невозможно отменить',
      'This contract cannot be connected with this volume to passport.':
        'Этот контракт не может быть связан с этим объемом к паспорту.',
      'This contract has estimated volume': 'У этого контракта есть ожидаемый объем',
      'This contract has finalized volume': 'У этого контракта есть финальный объем',
      'This contract was made from ticket': 'Этот контракт был создан из тикета',
      'This contracts already fully connected': 'Эти контракты уже полностью связаны',
      'This email will be send on behalf of you and via your email, appointed in Settings.':
        'Это письмо будет отправлено от вашего имени и через ваш email, указанный в настройках.',
      'This field should be filled to pass compliance':
        'Это поле должно быть заполнено для проверки комплаенса',
      'This first block is combined P&L. It is based on the result you got by\n                    passports. Right after you press CONTROL button in the certain passport - its\n                    P&L will be instantly displayed here.':
        'Этот первый блок объединен в PnL. Он основан на результате, который вы получили в паспорте.\n                    Сразу после нажатия кнопки КОНТРОЛЬ в определенном паспорте –\n                    PnL будет мгновенно отображаться здесь.',
      'This invoice was made from credit note': 'Инвойс создан из кредит ноута',
      'This is bank details of our company': 'Это банковские реквизиты нашей компании',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                    example':
        'Неправильный формат числа. Попробуйте 5 или 5.5',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                example.':
        'Неправильный формат числа. Попробуйте 5 или 5.5\n                 например.',
      'This is not contract.': 'Это не контракт.',
      'This is the Message of your letter.You can use tags {{ tag }} to add some fields to template here.':
        'Это Сообщение. В рамках этой формы вы сможете использовать специальные теги  {{тег}} для того, что бы отображать  в шаблоне письма переменные блока.',
      'This is the report of passports, showing you all allocations of contracts,\n                  costs, invoices and execution between each other.':
        'Это отчет о паспортах, показывающий все связи контрактов,\n                  затрат, инвойсов и исполнение между собой.',
      'This is your operational P&L.': 'Это ваш операционный PnL.',
      'This logistic already selected.': 'Эта логистика уже выбрана.',
      'This object has voted by you already. Skipping.':
        'Вы уже проголосовали за этот объект. Пропуск.',
      'This should be filled for multilingual agreements':
        'Это должно быть заполнено для многоязычных соглашений',
      'This ticket was made from indicator': 'Этот Тикет был создан из индикатора',
      'This value will be added to your margin': 'Это значение будет прибавлено к вашей марже',
      'This will add a mark that you have no intention to pay this invoice in a nearest future':
        'Это придаст отметку о том, что вы не намерены оплачивать этот инвойс в ближайшее время',
      'Thus, you will se profitability of each part and the consolidated profitability of\n              the whole chain.':
        'Таким образом, вы увидите рентабельность каждой части и консолидированную рентабельность\n              всей цепочки.',
      Ticker: 'Тикер',
      Ticket: 'Тикет',
      'Ticket number': 'Номер тикета',
      'Ticket value': 'Тикет сумма',
      Tickets: 'Тикеты',
      Title: 'Название',
      To: 'До',
      'To allocate': 'Закрепить',
      'To be invoiced': 'Будет выставлено',
      'To be invoiced (bal)': 'Будет выставлено (баланс)',
      'To be paid': 'Будет оплачено',
      'To filter contracts for allocation': 'Фильтрация контрактов для распределения',
      'To fix': 'Зафиксировать',
      'To fix lots': 'Зафиксировать лоты',
      'To fix tonnes': 'Зафиксировать тонны',
      'To hedge': 'Захеджировать',
      'To pay': 'Оплатить',
      'To receive': 'Получить',
      'To which country delivered': 'В какую страну доставлено',
      'To whom': 'Кому',
      Today: 'Сегодня',
      Tolerance: 'Толеранс',
      'Tolerance volume': 'Объем (толеранс)',
      Top: 'Сверху',
      'Top 20 Buyers': 'Топ 20 Покупателей',
      'Top 20 Suppliers': 'Топ 20 Поставщиков',
      Total: 'Всего',
      'Total Volume': 'Всего объем',
      'Total absolute value of charge': 'Общая стоимость затраты',
      'Total amount': 'Общая сумма',
      'Total amount of all incoming invoices converted in USD':
        'Общая сумма всех входящих инвойсов, конвертированная в USD',
      'Total amount of all incoming invoices in': 'Общая сумма всех входящих инвойсов в',
      'Total amount of all outgoing invoices converted in USD':
        'Общая сумма всех исходящих инвойсов, конвертированная в USD',
      'Total amount of all outgoing invoices in': 'Общая сумма всех исходящих инвойсов',
      'Total approved': 'Всего подтверждено',
      'Total by real stocks': 'Всего реальных остатков',
      'Total cumulative amount of all contracts': 'Общая сумма по всем контрактам',
      'Total cumulative volume of all contracts': 'Общая объем по всем контрактам',
      'Total for current date': 'Сумма для текущей даты',
      'Total in USD': 'Всего в USD',
      'Total invoice position': 'Всего инвойс позиций',
      'Total invoiced (fact)': 'Всего выставлено (факт)',
      'Total invoiced (plan)': 'Всего выставлено (план)',
      'Total outgoing - Total incoming': 'Всего исходящие - всего входящие',
      'Total outgoing - Total incoming in': 'Всего исходящие - всего входящие в',
      'Total paid': 'Всего оплачено',
      'Total paid amount by all incoming invoices in':
        'Общая сумма оплат по всем входящих инвойсам в',
      'Total paid amount by all outgoing invoices in':
        'Общая сумма оплат по всем исходящим инвойсам в',
      'Total paid amount of all incoming invoices converted in USD':
        'Общая сумма оплат по всем входящих инвойсам, конвертированная в USD',
      'Total paid amount of all outgoing invoices converted in USD':
        'Общая сумма оплат по всем исходящим инвойсам, конвертированная в USD',
      'Total project plan': 'Общий план проекта',
      'Total quantity': 'Общее количество',
      'Total requested': 'Всего заявлено',
      'Total shipped': 'Всего отгружено',
      'Total value': 'Общая сумма',
      'Total volume': 'Всего объем',
      'Total volume (forward)': 'Общий объем (форвард)',
      'Total volume (spot)': 'Общий объем (спот)',
      'Total volume of the deal. Choose plan value, at first, but then substitute on delivered volume':
        'Общий тоннаж сделки. Напишите плановый объем, а когда станет известен весь поставленный объем по контрактам - исправьте для точности подсчетов',
      'Total volume plan': 'Всего объем',
      Trade: 'Трейд',
      Trader: 'Сотрудник',
      'Trader note': 'Примечание трейдера',
      'Trades in USD': 'Торги в USD',
      'Trades in local currency': 'Торги в локальной валюте',
      Trading: 'Трейдинг',
      'Trading reports': 'Торговые отчеты',
      'Transaction removed': 'Транзакция удалена',
      'Transaction sum': 'Транзакция сумма',
      Transactions: 'Транзакции',
      Transfer: 'Перевалка',
      Transhipment: 'Перевалка',
      Transload: 'Перевалка',
      'Transloaded Date': 'Дата перевалки',
      'Transloaded date': 'Дата перевалки',
      'Transloading facility': 'Место перевалки',
      Transport: 'Транспорт',
      'Transport count': 'Количество транспорта',
      'Transports count': 'Количество транспортов',
      Transshipment: 'Перевалка',
      Tu: 'Вт',
      'Turnover for the period': 'Оборот за период',
      Type: 'Тип',
      'Type a new name': 'Введите новое имя',
      'Type address': 'Напишите адрес',
      'Type address (eng)': 'Напишите адрес (англ)',
      'Type any comments': 'Напишите любой комментарий',
      'Type bank code': 'Напишите банковский код',
      'Type credit note number': 'Напишите номер кредит ноута',
      'Type days': 'Напишите дни',
      'Type destination': 'Назначение',
      'Type email of user': 'Напишите почту пользователя',
      'Type first name of user': 'Напишите имя пользователя',
      'Type full name': 'Напишите полное имя',
      'Type full name (eng)': 'Напишите полное имя (англ)',
      'Type here any specific information about this deal':
        'Напишите здесь любую дополнительную информацию по сделке',
      'Type invoice number': 'Номер',
      'Type last name of user': 'Напишите фамилию пользователя',
      'Type loan number': 'Напишите номер соглашения',
      'Type name': 'Напишите имя',
      'Type new broker': 'Напишите имя нового брокера',
      'Type new buyer': 'Напишите имя нового покупателя',
      'Type new supplier': 'Напишите имя нового поставщика',
      'Type of contracts perspective': 'Тип контракта',
      'Type of operation': 'Тип операции',
      'Type offset number': 'Написать номер зачета',
      'Type origin': 'Напишите происхождение',
      'Type packing': 'Напишите вместительность',
      'Type percent conditions': 'Напишите условия процента',
      'Type phone number of user': 'Напишите телефон пользователя',
      'Type price': 'Введите цену',
      'Type quantity': 'Количество',
      'Type rate': 'Написать ставку',
      'Type reassignment number': 'Напишите номер переуступки',
      'Type swift': 'Напишите свифт',
      'Type telegram username of user': 'Напишите telegram никнейм',
      'Type title of passport': 'Название паспорта',
      'Type username of user': 'Напишите никнейм',
      'Type value': 'Напишите значение',
      'Type volume': 'Напишите объем',
      'Type volume max': 'Напишите объем (макс)',
      'Type volume min': 'Напишите объем (мин)',
      UPDATES: 'ОБНОВЛЕНИЯ',
      USA: 'США',
      USD: 'USD',
      'USD, EUR': 'USD, EUR',
      'USD, UAH': 'USD, UAH',
      Ukraine: 'Украина',
      Ukrainian: 'Украинский',
      Unconfirmed: 'Не подтверждено',
      Unconnected: 'Несоединенные',
      'Unconnected transport': 'Несоединенный транспорт',
      Undefined: 'Неопределено',
      Undelivered: 'Не доставлено',
      'Under discussion': 'Обсуждается',
      Undistributed: 'Нераспределенное',
      'Unit price': 'Цена юнит',
      'Unloading Elevator': 'Элеватор разгрузки',
      'Unloading Farm': 'Хозяйство разгрузки',
      'Unloading Terminal': 'Терминал разгрузки',
      'Unloading elevator': 'Элеватор разгрузки',
      'Unloading farm': 'Хозяйство разгрузки',
      'Unloading place': 'Место разгрузки',
      'Unloading terminal': 'Терминал разгрузки',
      'Unpaid volume': 'Неоплаченный объем',
      Unrelevant: 'Нерелевантно',
      'Untie contracts from passport': 'Отвязать контракты от паспорта',
      Upcoming: 'На подходе',
      Update: 'Обновление',
      'Update Business Date': 'Обновить бизнес дату',
      'Update Execution Stages': 'Обновить стадии исполнения',
      'Update Execution Stages (save)': 'Обновить стадии исполнения (сохранить)',
      'Update Telegram CHAT_ID': 'Обновить Telegram CHAT_ID',
      'Update Volume from BL': 'Обновить объем из коносамента',
      'Update all  connections from execution?': 'Обновить все привязки из исполнения?',
      'Update all volumes': 'Обновить все объемы',
      'Update cargo_confirmation, payment_confirmation, final_confirmation':
        'Обновить подтверждение по грузу, оплате и финальное',
      'Update date': 'Обновить дату',
      'Update is the list of all actions performed with the counterparties.':
        'Обновление - это список всех действий, выполненных с контрагентами.',
      'Update purchase volumes': 'Обновить объемы закупки',
      'Update sale volumes': 'Обновить объемы продажи',
      'Update status': 'Обновить статус',
      'Update statuses': 'Обновить статус',
      'Update time': 'Дата обновления',
      Updated: 'Обновлено',
      'Updated.': 'Обновлено.',
      Updates: 'Обновления',
      'Updates by counterparties': 'Обновления по контрагентам',
      Updatind: 'Обновляется',
      'Updating...': 'Обновляется...',
      Upload: 'Загрузить',
      'Upload a document': 'Загрузить документ',
      'Uploading is in progress. Please wait': 'Загрузка впроцессе. Ждите',
      'Usage cost': 'Затраты на использ',
      Use: 'Использование',
      'Use intermediate logistics in execution calculation': 'Использовать промежут логистику',
      'Use this or %': 'Используйте это или процент',
      'Use this or discount %': 'Используйте это или процент дисконта',
      User: 'Пользователь',
      'User config': 'Конфигурация пользователя',
      'User removed': 'Пользователь удален',
      'User saved': 'Пользователь сохранен',
      'User to be manager for the offset': 'Пользователь ответственный за зачет',
      'User to be manager for the reassignment': 'Пользователь ответственный за переуступку',
      'User to be responsible': 'Назначьте ответственного пользователя',
      'User to be responsible for the contract': 'Пользователь ответственный за контракт',
      'User to be responsible for the deal': 'Выбрать пользователя ответственным за сделку',
      'User to be responsible_for_signing': 'Ответственный за подписание',
      'User updated': 'Пользователь обновлен',
      Username: 'Никнейм',
      Users: 'Пользователи',
      VALIDATION: 'ВАЛИДАЦИЯ',
      VALUE: 'СТОИМОСТЬ',
      VAT: 'НДС',
      'VAT amount': 'Сумма НДС',
      'VAT costs return rate': 'Возврат НДС по затратам',
      'VAT return': 'Возврат НДС',
      'VAT return rate': 'Ставка возврата НДС',
      'VAT value': 'НДС процент',
      VEHICLE: 'ТРАНСПОРТ',
      VESSEL: 'ЛОДКА',
      VESSELS: 'ЛОДКИ',
      'VET required': 'ВЕТ обяз',
      VOLUME: 'ОБЪЕМ',
      VOLUMES: 'ОБЪЕМЫ',
      VOYAGE: 'РЕЙС',
      'VOYAGE DESTINATION DATES': 'РЕЙС ДАТЫ НАЗНАЧЕНИЯ',
      'VOYAGE LOADING DATES': 'РЕЙС ДАТЫ ОТПРАВКИ',
      VOYAGES: 'РЕЙСЫ',
      Valid: 'Актуально',
      Validated: 'Подтверждено',
      Value: 'Стоимость',
      'Value - invoiced': 'Сумма - проинвойсированно',
      'Value - paid': 'Сумма - оплачено',
      'Value alt': 'Значение альт',
      'Value alternative': 'Значение альтернативное',
      'Value general': 'Значение общее',
      'Value general (forward)': 'Общая сумма (форвард)',
      'Value general (spot)': 'Общая сумма (спот)',
      'Value of charge in %. Will be converted to total amount automatically, based on contract amount':
        'Стоимость затраты в %. Преобразуется в общую сумму автоматически на основе суммы контракта',
      'Value of charge per 1 metric ton. Will be converted to total amount automatically, based on contract quantity':
        'Стоимость затраты за 1 т. Преобразуется в общую сумму автоматически на основе объема контракта',
      'Value of goods': 'Стоимость товара',
      'Value per deal': 'Значение за сделку',
      'Value per deal (forward)': 'Сумма за сделку (форвард)',
      'Value per deal (spot)': 'Сумма за сделку (спот)',
      'Value w/o VAT, UAH': 'Стоимость без НДС, UAH',
      'Value with VAT': 'Стоимость с НДС',
      'Value, UAH': 'Стоимость, UAH',
      Vat: 'НДС',
      Vehicle: 'Транспорт',
      'Vehicle (#)': 'Номер т\\с',
      'Vehicle (number)': 'Номер т\\с',
      'Vehicle (number) alt': 'Номер т\\с (прицеп)',
      'Vehicle number': 'Транспорт (номер)',
      'Vehicle removed': 'Т\\с  удалено',
      'Vehicle saved': 'Т\\с сохранено',
      'Vehicle type': 'Тип транспорта',
      'Vehicle types': 'Тип',
      'Vehicle updated': 'Т\\с обновлено',
      Vehicles: 'Транспорт',
      Vessel: 'Лодка',
      'Vessel IMO': 'IMO судна',
      'Vessel capacity': 'Вместительность лодки',
      'Vessel imo': 'Лодка ИМО',
      'Vessel info': 'Инфо по судну',
      'Vessel name': 'Название лодки',
      'Vessel year': 'Год лодки',
      Vessels: 'Лодки',
      'Vessels are loaded': 'Лодки загружены',
      Vet: 'Вет',
      'Vet enterance': 'Вет внесение',
      'Vet required': 'Вет обяз',
      Virtual: 'Виртуальный',
      Visible: 'Видимый',
      Volume: 'Объем',
      'Volume (boarded)': 'Объем (погруж)',
      'Volume (departed consignment)': 'Объем (по ТТН)',
      'Volume (departed)': 'Объем (отправ)',
      'Volume (lost)': 'Объем (потер)',
      'Volume (real)': 'Объем (зачет)',
      'Volume (received)': 'Объем (получ)',
      'Volume (t)': 'Объем (т)',
      'Volume Departed Real': 'Объем (зачетный)',
      'Volume Received': 'Объем Получ',
      'Volume With Docs (T)': 'Объем с доками (Т)',
      'Volume at end': 'Объем в конце',
      'Volume at start': 'Объем в начале',
      'Volume boarded': 'Объем погруж',
      'Volume departed': 'Объем (отправ)',
      'Volume departed by consignment': 'Объем (по ТТН)',
      'Volume departed consignment': 'Объем (по ТТН)',
      'Volume departed real': 'Объем прин',
      'Volume discharged': 'Объем отгруж',
      'Volume estimated': 'Ожидаемый объем',
      'Volume for calculations': 'Объем для подсчетов',
      'Volume in mt': 'Объем в тоннах',
      'Volume in positions from execution': 'Объем в позициях из исполнения',
      'Volume intake': 'Объем погрузки',
      'Volume intake sum': 'Объем погрузки',
      'Volume lost': 'Потерянный объем',
      'Volume max': 'Объем макс',
      'Volume min': 'Объем мин',
      'Volume must be greater than 0': 'Объем должен быть больше чем 0',
      'Volume of deal': 'Объем сделки',
      'Volume options': 'Опцион',
      'Volume per deal': 'Объем за сделку',
      'Volume per deal (forward)': 'Объем за сделку (форвард)',
      'Volume per deal (spot)': 'Объем за сделку (спот)',
      'Volume plan': 'Объем плана',
      'Volume received': 'Объем (получ)',
      'Volume received at the destination place': 'Объем, полученный в пункте назначения',
      'Volume sum from positions': 'Сумма объемов из позиций',
      'Volume, $ with VAT': 'Стоимость, дол с НДС',
      'Volume, t': 'Объем, т',
      'Volume/Goal': 'Объем/Цель',
      Volumes: 'Объемы',
      Vote: 'Голосовать',
      Voyage: 'Рейс',
      'Voyage name': 'Имя рейса',
      'Voyage status': 'Статус рейса',
      'Voyage status updated': 'Статус рейса обновлен',
      Voyages: 'Рейсы',
      W: 'Н',
      'W/O Broker': 'Без брокера',
      'W/o business unit': 'Без бизнес юнита',
      WAREHOUSE: 'СКЛАД',
      'WAREHOUSE RECEIPTS': 'СКЛАДСКИЕ КВИТАНЦИИ',
      'WITH VAT': 'С УЧЕТОМ НДС',
      Wagon: 'Вагон',
      'Wait a moment...': 'Немножечко подождите...',
      'Waiting for approval': 'Ожидает подтверждения',
      'Waiting my approval': 'Я еще не утвердил',
      Warehouse: 'Склад',
      'Warehouse Losses': 'Склад потери',
      'Warehouse Shipment': 'Отгрузки склад',
      'Warehouse Trade': 'Склад трейд',
      'Warehouse distances': 'Расстояния до складов',
      'Warehouse receipts': 'Складские квитанции',
      'Warehouse type': 'Склад тип',
      Warm: 'Теплый',
      Washout: 'Отмена',
      We: 'Ср',
      'We spread them onto two groups: past and future. If update has a due date in the\n                future - it will appear in a table on the right. If you click on its button\n                (success|fail) you will determine whether it is done or not and it will go to the\n                table on the left.':
        'Мы разделили их на две группы: прошлое и будущее. Если обновление имеет дату окончания\n                в будущем – появится в таблице справа. Если нажать на кнопку\n                (успех|неудача) вы определите, статус сделан или нет, и данные будут отображаться\n                в таблице слева.',
      Website: 'Сайт',
      Week: 'Неделя',
      'Welcome to the home of': 'Добро пожаловать в мире',
      'When all the certification have been resolved': 'Когда все сертификаты исполнены',
      'When payment was actually made': 'Когда фактически была произведена оплата',
      'When you expect payment has actually been made': 'Когда ожидается платеж',
      'When you expect payment to be made': 'Когда платеж планируется быть исполненым',
      'When you have conducted money': 'Когда провелись деньги',
      'When you have issued invoice': 'Когда выставляется счет',
      'When you have received invoice': 'Когда получен счет',
      White: 'Белая',
      'Who issued invoice': 'Кто выставил инвойс',
      'Who receives invoice': 'Кто получает инвойс',
      'Whoose option': 'Чья опция',
      'Why am i seeing this': 'Почему я это вижу',
      'Will be change automatically, based on passport execution':
        'Будет изменен автоматически, на основании исполнения паспорта',
      'Will be change automatically, based on payment. New - no payment yet, Process - paid not in full, Paid - paid in full':
        'Будет изменен автоматически, на основе оплаты. Новый - нет оплаты еще, Процесс - оплачен не полностью, Оплачен - оплачен полностью',
      'Will be changed automatically based on configuration':
        'Будет изменяться автоматически на основе конфигурации',
      'Will be filled automatically, when you set cargo execution':
        'Будет заполнен автоматически, когда вы установите выполнение груза',
      'With Passport': 'В Паспорте',
      'With buyers': 'С покупателями',
      'With contracts': 'С контрактами',
      'With currency exchange': 'С курсом',
      'With date confirmation': 'С датой подтверждения',
      'With date received': 'С датой получения',
      'With date request': 'С датой запроса',
      'With derivative': 'С деривативом',
      'With destination country': 'Есть страна назначения',
      'With disport': 'Есть порт назначения',
      'With exporters': 'С экспортерами',
      'With internal chain': 'Внутренняя цепочка',
      'With loadport': 'Есть порт отгрузки',
      'With opposite price': 'Есть встречная цена',
      'With origin country': 'Есть страна отправки',
      'With payment delay': 'С задержкой платежа',
      'With ports': 'Есть порты',
      'With roles': 'С ролью',
      'With suppliers': 'С поставщиками',
      Withdrawal: 'Списание',
      Without: 'Без',
      'Without rate': 'Без ставки',
      'Work in progress': 'Работа в процессе',
      'Write an email': 'Написать электронное письмо',
      'Write down any additional information about this vehicle)':
        'Напишите любую дополнительную информацию по этому т/с',
      'Write down any note about this vehicle)': 'Заметка про транспортное средство',
      'Write down the specific information  about this invoice':
        'Напишите специфическую информацию по этому счету',
      'Write down the specific information about this bank operation':
        'Напишите специфическую информацию по этой операции',
      'Write of volume': 'Объем списания',
      'Write some update here...': 'Напишите любое обновление здесь...',
      'Write-of volume balance': 'Открытый баланс к списанию',
      'Wrong crops': 'Разные культуры',
      'Wrong volume': 'Некорректный объем',
      Year: 'Год',
      'Year %': 'Год %',
      'Year Budget': 'Годовой бюджет',
      'Year budget': 'Годовой бюджет',
      Yellow: 'Желтое',
      Yes: 'Да',
      Yesterday: 'Вчера',
      You: 'Вы',
      'You can click on buttons to display these objects inside passport.':
        'Вы можете нажать на кнопки, чтобы отобразить эти объекты внутри паспорта.',
      'You can create and apply your own statuses for passport':
        'Вы можете создавать и применять свои собственные статусы для паспорта',
      'You can directly go to a consolidated PNL report':
        'Вы можете сразу перейти к консолидированному отчету PnL',
      'You can open information about all passports that are inside certain\n                            cell.':
        'Можно открыть информацию обо всех паспортах, которые находятся внутри определенной\n                            ячейке.',
      'You can open the information about open deals with a\n                            position':
        'Вы можете открыть информацию об открытых сделках\n                            с позицией',
      'You can pick up multiple objects, clicking with CTRL':
        'Вы можете выбрать несколько объектов, зажав CTRL',
      'You can see the quantity by each grouping criteria and\n                            passport':
        'Вы можете увидеть количество по каждому критерию группировки и\n                            паспортом',
      'You can select multiple roles, pressing CTRL':
        'Вы можете выбрать несколько ролей, нажав CTRL',
      "You can't vote for this object": 'Вы не можете голосовать за этот объект',
      'You do not have new notifications': 'У вас нет уведомлений',
      'You have a long position': 'У вас длинная позиция',
      'You have a short position': 'У вас короткая позиция',
      'You have voted for this object. Revote this?':
        'Вы проголосовали за этот объект. Переголосовать?',
      'You need to verify Google account': 'Вам нужно подтвердить свой Google аккаунт',
      'a country': 'страна',
      'a district': 'район',
      'a size': 'размер',
      'a status': 'статус',
      'a supplier to which this Exporter is affiliated':
        'выберите Поставщика, с которым аффилирован данный Экспортер',
      'a type': 'тип',
      'access admin': 'вход в админ',
      'access rights': 'права доступа',
      account: 'счет',
      'account name': 'имя аккаунта',
      actions: 'действия',
      active: 'активно',
      activity: 'активность',
      'actual cash amount': 'текущая сумма на счету',
      'actually shipped from Transport page': 'актуальные отгрузки по Реестру',
      add: 'добавить',
      'add another': 'добавить еще',
      'add costs': 'добавить затраты',
      'add stocks': 'добавить остатки',
      additional: 'дополнительно',
      'additional info': 'примечание',
      address: 'адрес',
      agent: 'посредник',
      agreement: 'договор',
      all: 'все',
      'all logistics': 'вся логистика',
      allocated: 'распределено',
      'allocated to this passport': 'соединено с паспортом',
      alt: 'альт',
      amount: 'сумма',
      'amount with vat': 'сумма с НДС',
      'amount, $': 'сумма, $',
      analytical: 'аналитический',
      'analytical amount': 'аналитическая сумма',
      'analytical amount <> amount': 'аналитическая сумма <> сумма',
      'analytical diff': 'аналитическая разница',
      'analytical price': 'аналитическая цена',
      'analytical value': 'аналитическая стоимость',
      and: 'и',
      'any of logistics': 'любая из логистик',
      approval: 'подтверждения',
      'approval config': 'конфигурация подтверждения',
      'approval configuration': 'конфигурация подтверждения',
      'approval status': 'статус подтверждения',
      approvals: 'подтверждения',
      approved: 'утверджено',
      'arrival end': 'прибытие конец',
      'arrival period': 'период прибытия',
      'arrival period end': 'период прибытия конец',
      'arrival period start': 'период прибытия начало',
      'arrival start': 'период прибытия',
      assignment: 'переназначение',
      'at port': 'в порту',
      'at road': 'в пути',
      attached: 'привязанные',
      author: 'автор',
      authors: 'авторы',
      autor: 'автор',
      available: 'доступно',
      'available for allocation': 'доступно для прикрепления',
      'available to connect': 'доступно к прикреплению',
      avg: 'срд',
      'avg charges': 'сред. затраты',
      'avg price': 'сред. цена',
      balance: 'баланс',
      'balance invoice, issued by you to Buyer': 'балансовый инвойс, выставленный нами Покупателям',
      'balance invoice, issued to you by Supplier':
        'балансовый инвойс, выставленный нам Поставщиками',
      'balance to execute': 'баланс к исполнению',
      'balance with payment': 'баланс с оплатами',
      bank: 'банк',
      'bank account': 'банк счета',
      'bank accounts': 'банк счета',
      'bank details': 'банковские реквизиты',
      'bank name': 'банк',
      bank_name: 'bank_name',
      'based on profit and loss by': 'основан на прибылях и убытках по',
      basis: 'базис',
      'basis doc': 'базис документ',
      'basis doc with copy': 'базовый документ с копией',
      'basis doc with file': 'базовый документ с файлом',
      'basis doc without copy': 'базовый документ без копии',
      'basis doc without file': 'базовый документ без файла',
      'basis of buyer contract': 'базис прод контракта',
      'basis of supplier contract': 'базис закуп контракта',
      basises: 'базисы',
      'before execution ends': 'до окончания исполнения',
      'bill of lading': 'коносамент',
      'billoflading number': 'коносамент номер',
      birthday: 'день рождения',
      'black list': 'черный список',
      block: 'блок',
      boarded: 'погружено',
      borrowers: 'заемщики',
      broker: 'брокер',
      'broker confirmation (date)': 'дата подтверждения брокера',
      'broker confirmation required': 'подтв. брокера обяз',
      'broker freight costs': 'фрахт брокер затраты',
      budget: 'бюджет',
      bulk: 'bulk',
      'business date': 'бизнес дата',
      'business unit': 'бизнес юнит',
      'business units': 'бизнес юниты',
      buyer: 'покупатель',
      'buyer balance invoices': 'выберите покупатель баланс инвойсы',
      'buyer contract': 'покупатель контракт',
      'buyer contract basis': 'покуп контракт базис',
      'buyer contracts': 'покуп. контракт',
      'buyer invoiced': 'покупателю выставили',
      'buyer invoiced balance': 'покупатель выставлено балансовый инвойс',
      'buyer managers': 'менеджеры покупателя',
      'buyer paid': 'покупатель оплатил',
      'buyer paid balance': 'покупатель оплатил балансовый инвойс',
      'buyer prepay invoices': 'покупатель предоплатные инвойсы',
      'buyer un invoiced': 'покупатель не выставлено',
      'buyer un invoiced balance': 'покупателю не выставлено балансовый инвойс',
      buyers: 'покупатели',
      by: 'by',
      'by commodities': 'по товарам',
      'by crop': 'по культуре',
      'by price': 'по цене',
      'by selected': 'по выбранным',
      calc: 'кальк',
      'cancel changes': 'отменить затраты',
      'caontacts logistic prices': 'контракт логистика цены',
      capacity: 'вместительность',
      'capacity est': 'вместительность ожид',
      'capacity max': 'вместительность макс',
      'capacity min': 'вместительность мин',
      cargo: 'груз',
      'cargo brands': 'бренды груза',
      'cargo confirm': 'груз подтвержд',
      category: 'категория',
      cd: 'гтд',
      'cd or tt': 'гтд или акт',
      cent: 'центы',
      chain: 'цепочка',
      'change a language, theme': 'поменять язык, тему',
      charge: 'затрата',
      charges: 'затраты',
      'charges per 1 mt': 'затраты за 1т',
      chart: 'график',
      charterer: 'перевозчик',
      'choose file type': 'выберите тип файла',
      city: 'город',
      class: 'класс',
      clear: 'очистить',
      'click here to expand the list of contracts inside each passport':
        'нажмите здесь, чтобы расширить список контрактов внутри каждого паспорта',
      client: 'контрагент',
      'client role from type': 'контрагент от кого',
      'client role name': 'роль контрагента',
      'client role to type': 'контрагент кому',
      'client role type': 'контрагент тип роли',
      'clientrole from': 'клиент от кого',
      'clientrole to': 'клиент кому',
      clients: 'клиенты',
      clone: 'клонировать',
      close: 'закрыть',
      'close window': 'закрыть окно',
      code: 'код',
      collection: 'коллекция',
      comment: 'коммент',
      comments: 'комментарии',
      'commisiton account': 'комиссия счета',
      commodities: 'товары',
      commodity: 'товар',
      companies: 'компании',
      company: 'компания',
      'company groups': 'группы компаний',
      completion: 'выпонение',
      'completion ratio': 'коэффициент выполнения',
      compliance: 'комплайенс',
      'compliance status': 'статус комплаенса',
      concluded: 'дата контр',
      conclusion: 'заключение',
      'conclusion date': 'дата заключения',
      condition: 'условие',
      conditions: 'условия',
      config: 'конфигурация',
      'confirmation date - the date when you confirmed the legal documents of this company':
        'дата подтверждения - дата когда вы подтвердили документы по компании',
      confirmations: 'подтверждения',
      confirmed: 'подтверждено',
      connect: 'связ объем',
      connected: 'связано',
      'consecutive voyages': 'последовательные рейсы',
      consignment: 'накладные',
      'consignment contracts': 'выберите контракты накладных',
      'consignment elevators': 'элеваторы накладных',
      'consignment number': 'номер накладной',
      consignments: 'накладные',
      consolidated: 'консолидированно',
      contact: 'контакт',
      'contact info': 'контактная информация',
      contacts: 'контакты',
      continue: 'продолжить',
      contract: 'контракт',
      'contract avg charges': 'средние затраты по контракту',
      'contract business unit': 'бизнес юнит контракта',
      'contract buyer': 'покупатель контракта',
      'contract charges/gains': 'контракт Затраты / Доходы',
      'contract commodity': 'товар контракта',
      'contract custom status': 'пользовательские статусы контрактов',
      'contract incoterms': 'базис контракта',
      'contract is confirmed by cargo': 'контракт подтвержден по отгрузке',
      'contract number': 'номер контракта',
      'contract option': 'контракт опция',
      'contract passport': 'паспорт контракта',
      'contract payment conditions': 'условия оплаты контракт',
      'contract price': 'цена контракта',
      'contract status': 'статус контракта',
      'contract supplier': 'поставщик контракта',
      'contract total charges': 'общие затраты по контракту',
      'contract type': 'тип контракта',
      'contract value': 'сумма контракта',
      'contract vat return value': 'сумма возврата ндс по контракту',
      'contract volume': 'объем контракта',
      contracts: 'контракты',
      controlled: 'проконтролировано',
      'controlled by': 'контролируется',
      copy: 'копия',
      correspondent: 'корреспондент',
      'cost invoice': 'инвойс на затраты',
      'cost of goods sold': 'себестоимость',
      costs: 'затраты',
      'costs from purchase contracts of this passport':
        'затраты из закупочных контрактов под этот паспорта',
      'costs from sale contracts of this passport':
        'затраты из продажных контрактов под этот паспорта',
      'costs from this passport': 'затраты по этому паспорту',
      'costs vat return value': 'сумма возврата ндс по затратам',
      count: 'счетчик',
      counterparties: 'контрагенты',
      counterparty: 'контрагент',
      'counterparty amount': 'сумма контрагента',
      'counterparty bank account': 'банковский счет контрагента',
      'counterpaty payment info': 'контрагент платежная информация',
      country: 'страна',
      'create template': 'создать шаблон',
      'create user': 'создать пользователя',
      created: 'создано',
      creation: 'создание',
      'creation date': 'дата создания',
      'credit accounts': 'кредит аккаунты',
      'credit notes': 'кредит ноут',
      creditor: 'кредитор',
      crop: 'культура',
      'crop class': 'класс культуры',
      'crop protection': 'защита урожая',
      'crop protection amount': 'защита урожая сумма',
      'crop year': 'год культуры',
      crops: 'культуры',
      currencies: 'валюты',
      currency: 'валюта',
      'currency & Exchange Rate': 'валюта и обменный курс',
      'currency exchange': 'обменный курс',
      'currency rate': 'курс валют',
      current: 'текущее',
      'current delay, h': 'текущая задержка, часы',
      'current position': 'текущая позиция',
      'custom clientrole': 'пользовательская роль',
      'custom status': 'пользовательский статус',
      date: 'дата',
      'date filters': 'выберите диапазон дат',
      'date joined': 'дата создания',
      'date of execution opposite': 'дата исполнения встречная',
      'date range while the proposition is actual': 'период в который данное предложение актуально',
      dates: 'даты',
      day: 'день',
      days: 'дни',
      'days of payments delay': 'дней задержки',
      'days to load': 'дней на загрузку',
      'debit accounts': 'дебет счета',
      'debit/credit': 'дебет/кредит',
      debitor: 'дебитор',
      'declaration (date)': 'декларация (дата)',
      delay: 'задержка',
      'delete a transfer': 'удалить перевалку',
      'delete agreement': 'удалить договор',
      'delete consignment': 'удалить накладную',
      'delete file': 'удалить файл',
      'delete item': 'удалить элемент',
      'delete logistic': 'удалить логистику',
      'delete permanently': 'удалить навсегда',
      delivered: 'доставлено',
      delivery: 'доставка',
      'delivery condition': 'вид транспортировки',
      'delivery conditions': 'условия поставки',
      'delivery period': 'период поставки',
      'demurrage rate': 'демерредж ставка',
      departed: 'отправлено',
      'departure station': 'станция отправления',
      'departure stations': 'станции отправления',
      derivative: 'дериватив',
      'derivative account': 'аккаунт дериватива',
      'derivative cargo': 'культура деривативов',
      derivatives: 'деривативы',
      dest: 'пункт назначения',
      destination: 'назначение',
      'destination 1st port': 'порт назначения 1-й',
      'destination 2nd port': 'порт назначения 2-й',
      'destination country': 'страна назначения',
      'destination of crop': 'назначение',
      'destination port': 'порт назначения',
      details: 'детали',
      'details of the profit and loss of': 'информация о прибылях и убытках',
      'df costs': 'мф затраты',
      'df quantity': 'мф объем',
      'disbursement bill of lading': 'расходная накладная',
      'disbursement bl': 'расходная накладная',
      disbursementBL: 'расходная накладная',
      disbursementbl: 'расходная накладная',
      'disch rate': 'норма выгрузки',
      'discharge dem/dis': 'отгрузка дем/дис',
      'discharge rate': 'норма разгрузки',
      'discharge volume': 'объем разгрузки',
      'discharge window': 'отгрузка окно',
      discharged: 'выгружено',
      discount: 'дисконт',
      'discount amount': 'значение дисконта',
      'discount mt': 'дисконт за тонну',
      'dispatch rate': 'диспач ставка',
      disport: 'порт назначения',
      'distribution by cargo': 'распределение грузов',
      distributor: 'дистрибьютор',
      distributors: 'дистрибьюторы',
      district: 'район',
      docs: 'доки',
      'docs status': 'статус документов',
      document: 'документы',
      documents: 'документы',
      done: 'готово',
      'download all': 'скачать всё',
      'download docx': 'загрузить docx',
      'download file on the computer': 'скачать файл на компьютере',
      'download pdf': 'загрузить pdf',
      'due date': 'до дат',
      'edit a transfer': 'редактировать перевалку',
      'edit changes': 'редактировать затраты',
      'edit contract': 'редктировать контракт',
      editor: 'редактор',
      editors: 'редакторы',
      elevator: 'элеватор',
      'elevator (with consignment)': 'элеватор (по накладной)',
      elevators: 'элеваторы',
      email: 'e-mail',
      'email.mail.com': 'email.mail.com',
      'empty file': 'пустой файл',
      end: 'конец',
      'end date': 'дата окончания',
      'end of execution': 'конец исполнения',
      eng: 'англ',
      est: 'ожид',
      'est.': 'ожид.',
      'estimated lots': 'количество лотов',
      'estimated volume': 'ожидаемый объем',
      eta: 'eta',
      etb: 'etb',
      etc: 'etc',
      ets: 'ets',
      event: 'событие',
      events: 'события',
      'exchange fact': 'обмен факт',
      'exchange plan': 'обменный курс план',
      'exchange rate': 'обменный курс',
      'exec forecast amount <> invoiced amount': 'ожидаемая сумма <> сумма выставленного ​​инвойса',
      'exec forecast amount <> payment amount': 'ожидаемая сумма <> сумма оплаты',
      executed: 'выполнено',
      execution: 'исполнение',
      'execution amount': 'сумма по исполнению',
      'execution month': 'месяц исполнения',
      'execution period': 'период исполнения',
      'execution price': 'цена исполнения',
      'existing vessel': 'существующая лодка',
      export: 'экспорт',
      'export / import data': 'экспорт / импорт данных',
      'export contract': 'экспортный контракт',
      'export contracts': 'экспортные контракты',
      exporter: 'экспортер',
      'exporter contract': 'экспортер контракт',
      exporters: 'экспортеры',
      fact: 'факт',
      'fact distributors': 'фактические дистрибьюторы',
      'fact suppliers/distributors': 'фактические поставщики/дистрибьюторы',
      fail: 'неудача',
      farm: 'хозяйство',
      'farm (with consignment)': 'хозяйство (по накладной)',
      farms: 'хозяйства',
      'few logistics': 'несколько логистик',
      fields: 'поля',
      'file name': 'имя файла',
      filter: 'фильтр',
      'filter invoices to send': 'фильтровать инвойсы для отправки',
      'filter transport to send': 'фильтровать транспорт для отправки',
      filters: 'фильтры',
      'fin purposes': 'выбрать цели',
      final: 'финал',
      'final amount': 'финальная сумма',
      'final amount <> amount': 'финальная сумма <> сумма',
      'final amount <> invoiced': 'финальная сумма <> проинвойсированно',
      'final confirm': 'окончательное подтвержд',
      'final volume': 'финальный объем',
      'final volume of the contract': 'финальный объем по этому контракту',
      'final volume of this contract': 'финальный объем по этому контракту',
      'final volume of this request': 'финальный объем по этому тикету',
      finance_account_title: 'finance_account_title',
      'first confirmation date - the date when you first confirmed the legal documents of this company':
        'дата первого подтверждения - дата когда вы впервые подтвердили документы по компании',
      'first contact = the date, when this company was added to the system':
        'первый контакт - дата, когда эта компания была добавлена в систему',
      'first name': 'имя',
      'first_contact was automatically generated': 'первый контакт автоматически сгенерирован',
      fixing: 'фиксинг',
      flag: 'флаг',
      for: 'для',
      forwarder: 'перевозчик',
      forwarders: 'экспедиторы',
      found: 'найдено',
      free: 'свободно',
      freight: 'фрахт',
      'freight costs': 'фрахт затраты',
      'freight opposite': 'встречный фрахт',
      from: 'от',
      'from company': 'компанию от кого',
      'from each ticket of chosen passport we will create a contract and attach it\n                        to the passport automatically':
        'из каждого тикета выбранного паспорта мы создадим контракт и добавим его\n                        в паспорт автоматически',
      'full diff': 'все изменения',
      'fully connected to passports': 'полностью привязано к паспорту',
      'future contact': 'следующий контакт',
      'future contact = the date, when you need to contact this company later (can be set while editing company':
        'следующий контакт - дата, когда нужно связаться с этой компанией повторно (можно указать при редактировании компании)',
      'futures position': 'фьючерс позиция',
      gains: 'поступления',
      'general agreement': 'рамочный договор',
      'general agreements': 'рамочные договора',
      'general expenses': 'общие затраты',
      'give the name to your collection to find it on the Download tab':
        'назовите коллекцию так, чтобы потом найти ее на вкладке Загрузить',
      'go to details': 'перейти к деталям',
      'go to the page of the item': 'перейдите на страницу элемента',
      'group by': 'группировка по',
      guarantor: 'гарант',
      h: 'ч',
      ha: 'га',
      'has logistics': 'есть логистика',
      'has negative balance': 'есть негативный баланс',
      hedging: 'хеджирование',
      'here are shown all documents that were uploaded in counterparties, contracts,\n                    invoices, passports':
        'здесь показаны все документы, которые были загружены в контрагентов, контракты,\n                    инвойсы, паспорта',
      'here are shown all suppliers, buyers, brokers, farms, and others, already grouped\n                by their roles':
        'здесь показаны все поставщики, покупатели, брокеры, хозяйства и другие, уже сгруппированные\n                по их ролям',
      hide: 'спрятать',
      'hide executed': 'спрятать выполненные',
      history: 'история',
      hours: 'часы',
      'http://website.com': 'http://website.com',
      iban: 'iban',
      id: 'id',
      'if there is more than one notify party': 'если имеется более чем одна уведомляющая сторона',
      'if there is more than two notify party': 'если имеется более двух уведомляющих сторон',
      'if you want to see more info about certain user press on his name':
        'если вы хотите увидеть больше информации об определенном пользователе нажмите на его имя',
      'if you want to start from something, try the following actions':
        'если вы хотите начать с чего-то, попробуйте следующие действия',
      imo: 'имо',
      'implied freight level': 'прогнозированная стоимость фрахта',
      'import data': 'импорт дата',
      'inc inv qty': 'inc inv qty',
      incoming: 'входящие',
      incoterms: 'базис',
      info: 'информация',
      initial: 'первоначальное',
      'initial cash amount': 'первоначальная сумма',
      'initial date': 'первоначальная дата',
      'initialization date': 'дата инициализации',
      insurer: 'страховая',
      intake: 'загрузка',
      'intermediate costs': 'промежуточные затраты',
      'intermediate logistic': 'промежуточная логистика',
      'intermediate logistics delivery costs': 'пром логистика затраты на доставку',
      'internal purchases': 'внутренние закупки',
      'internal volume': 'внутренний объем',
      invoice: 'инвойс',
      'invoice (R/W)': 'инвойс (жд)',
      'invoice (auto)': 'инвойс (авто)',
      'invoice bank account': 'банковский счет инвойса',
      'invoice client from': 'выберите контрагент от',
      'invoice client to': 'контрагент от',
      'invoice from us': 'инвойсы от нас',
      'invoice number': 'номер инвойса',
      'invoice price': 'цена инвойса',
      'invoice to us': 'инвойсы нам',
      invoiced: 'выставлено',
      'invoiced <> amount': 'проинвойсированно <> сумма',
      'invoiced <> paid': 'проинвойсировано <> оплачено',
      'invoiced cancelled': 'выставление инвойса отменено',
      'invoiced logistics': 'инвойсированные логистики',
      invoices: 'инвойсы',
      'is active': 'активен',
      'is gain': 'это доход',
      'is staff': 'персонал',
      'is superuser': 'суперпользователь',
      km: 'км',
      'last approve time': 'время последнего подтверждения',
      'last approver': 'последний подтверждающий',
      'last login': 'последний вход',
      'last name': 'фамилия',
      'last update': 'посл обнов',
      'last updated': 'обновлено',
      lenders: 'заемщики',
      'link file to a logistics': 'связь файла с логистикой',
      list: 'список',
      load: 'загрузка',
      'load into a container': 'погрузка в контейнер',
      'load rate': 'норма погрузки',
      loaded: 'отгружено',
      'loading 1st port': 'порт загрузки 1-й',
      'loading 2nd port': 'порт загрузки 2-й',
      'loading date': 'дата погрузки',
      'loading dem/dis': 'отгрузка дем/дис',
      'loading option': 'опция отгрузки',
      'loading port': 'порт отгрузки',
      'loading window': 'отгрузка окно',
      loadport: 'порт отгрузки',
      loans: 'займы',
      local: 'локал',
      location: 'местонахождение',
      logistic: 'логистика',
      logistics: 'логистика',
      'logistics by terminal': 'логистика на терминале',
      'logistics to invoice by terminal': 'логистика для инвойсирования на терминале',
      'logistics total value': 'логистика стоимость',
      'logistics with single purchase contract': 'логистика с единственным закупочным контрактом',
      long: 'длинная',
      'long net pnl mtm': 'длинный чистый pnl mtm',
      lost: 'потеряно',
      lots: 'лоты',
      'loyalty program': 'программа лояльности',
      'loyalty program from contract': 'программа лояльности из контракта',
      'loyalty program from disbursement bill of lading':
        'программа лояльности из расходной накладной',
      mailings: 'рассылки',
      'main contact': 'основной контакт',
      manager: 'менеджер',
      managers: 'менеджеры',
      'manual enter': 'ручной ввод',
      margin: 'маржа',
      'margin = value of sale contracts - value of purchase contracts  - all costs':
        'маржа = стоимость продажных контрактов - стоимость закупочных контрактов - все затраты',
      'margin = value of sale contracts - value of purchase contracts  - all costs + vat * return rate':
        'маржа = стоимость продажных контрактов - стоимость закупочных контрактов - все затраты + ндс * ставка возврата',
      'margin selector': 'переключатель маржи',
      'marine traffic': 'marine traffic',
      'mark as read': 'Отметить как прочитанное',
      'mark to market': 'рынок',
      'market zone': 'маркет зона',
      'matched + open net pnl': 'совпадение + открытый чистый PnL',
      'matched contract average purchase price':
        'Сопоставленная средняя закупочная цена по контракту',
      'matched contract average sale price': 'средняя цена продажи по согласованному контракту',
      'matched contract comm fees': 'сравненные комиссии контракта',
      'matched contract long': 'согласованный контракт длинный',
      'matched contract short': 'согласованный контракт короткий',
      'matched net pnl': 'совпадающий чистый PnL',
      max: 'макс',
      measurement: 'ед. измерения',
      message: 'сообщение',
      min: 'мин',
      'min/max': 'мин/макс',
      month: 'месяц',
      mt: 'мт',
      mtm: 'отношение к рынку',
      'mtm costs': 'мтм затраты',
      'mtm dtd price': 'mtm dtd цена',
      'mtm dtd value': 'mtm dtd стоимость',
      'mtm ltd price': 'mtm ltd цена',
      'mtm ltd value': 'mtm ltd стоимость',
      'mtm margin': 'мтм маржа',
      'mtm per 1mt': 'оценка к рынку за 1т',
      'mtm price': 'оценка к рынку цена',
      'mtm volume': 'мтм объем',
      multicontract: 'мультиконтракт',
      'multicontract purchase': 'мультиконтракт закупка',
      'multicontract sale': 'мультиконтракт продажа',
      multipassport: 'мультипаспорт',
      multirequest: 'мультитикет',
      mv: 'лодка',
      name: 'имя',
      new: 'новый',
      'new - is just created mailing, but not sent. sent - is sent. progress - we\n                        are sending it just now. failed - is failed to be sent.':
        'новый – это только что созданная рассылка, но не отправленная. отправлено – это отправлено. прогресс -\n                        мы отправляем его сейчас. неудача – не удалось отправить.',
      'new consignment': 'новая накладная',
      'new template': 'новый шаблон',
      'next approver': 'следующий подтверждающий',
      'next approvers': 'следующие подтверждающие',
      'no elevator': 'нет элеватора',
      'no farm': 'нет хозяйства',
      'no forwarder': 'нет экспедитора',
      'no port': 'нет порта',
      'no position': 'нет позиции',
      'no purchase contracts attached': 'не заключены закупочные контракты',
      'no sale contracts attached': 'не заключены продажные контракты',
      'no station dep': 'нет станций отправки',
      'no station rec': 'нет станций получения',
      'no status': 'без статуса',
      'no vessel': 'нет судна',
      'no volume': 'нет объема',
      'not allocated': 'не распределено',
      'not invoiced': 'не инвойсировано',
      note: 'примечание',
      notes: 'примеч',
      number: 'номер',
      object: 'объект',
      'object title': 'название объекта',
      'object type': 'объект тип',
      'of counterparties': 'контрагентов',
      offset: 'зачет',
      'on a page': 'на странице',
      'on road': 'в пути',
      one: 'одно',
      'one counterparty can have several roles, e.g, if you have sales and purchases with\n                one company, you should create one company and assign it with both roles (supplier\n                and buyer)':
        'один контрагент может иметь несколько ролей, например, если у вас есть продажи и закупка с\n                одной компании, вы должны создать одну компанию и назначить ей обе роли (поставщик\n                и покупателя)',
      open: 'открыто',
      'open balance': 'открытый баланс',
      'open position': 'открытая позиция',
      'open the modal window for editing of the item':
        'открыть модальное окно для редактирования элемента',
      'open volume': 'открытый объем',
      'opened positions': 'открытые позиции',
      operation: 'операция',
      opposite: 'встречная',
      'opposite basis costs': 'встречный базис расходов',
      'opposite direct costs': 'противоположные прямые затраты',
      'opposite freight level': 'встречный фрахт',
      'opposite matched average purchase price': 'встречно подобранная средняя цена закупки',
      'opposite matched average sale price': 'встречно подобранная средняя цена продажи',
      'opposite matched comm fees': 'встречно подобранные комиссии',
      'opposite matched long': 'противоположное совпадающее long',
      'opposite matched short': 'противоположное совпадающее short',
      'opposite passport contract costs': 'встречные затраты контракта паспорта',
      'opposite total value costs': 'встречная общая сумма затрат',
      'opposite value': 'встречная сумма',
      option: 'опция',
      options: 'опции',
      or: 'или',
      'or you can': 'или вы можете',
      ordering: 'сортировка',
      origin: 'происхождение',
      'origin country': 'страна отправки',
      'origin district': 'происхождение район',
      'origin of crop': 'происхождение',
      'origin region': 'происхождение область',
      original: 'оригинал',
      'original available': 'есть оригинал',
      origination: 'происхождение',
      'other activites': 'другие назначения',
      'other activities': 'другие назначения',
      'our amount': 'наша сумма',
      'our amount <> amount': 'наша сумма <> сумма',
      'out inv qty': 'out inv qty',
      outgoing: 'исходящие',
      owner: 'владелец',
      owners: 'владельцы',
      paid: 'оплачено',
      'paid $': 'оплачено $',
      'paid (plan)': 'оплачено(план)',
      'paid <> amount': 'оплачено <> сумма',
      'paper trade': 'бумажная торговля',
      parameters: 'параметры',
      'parent cargo': 'родительская культура',
      'parse active quotes': 'парсинг активных котировок',
      passport: 'паспорт',
      'passport basis costs': 'встречные затраты паспорта',
      'passport business date': 'бизнес дата паспорта',
      'passport business unit': 'бизнес юнит паспорта',
      'passport charges': 'затраты паспорта',
      'passport commodity': 'паспорт груз',
      'passport contract costs': 'затраты контракта паспорта',
      'passport costs': 'затраті паспорта',
      'passport custom status': 'пользовательский статус паспорта',
      'passport number': 'номер паспорта',
      'passport season': 'паспорт сезон',
      'passport status': 'паспорт статус',
      'passport vessel': 'лодка паспорта',
      passports: 'паспорта',
      'passports custom status': 'пользовательские статусы паспортов',
      payment: 'оплата',
      'payment bank account': 'банковский счет платежа',
      'payment condition': 'условия платежа',
      'payment condition option': 'опция условия оплаты',
      'payment conditions': 'условия платежа',
      'payment confirm': 'платеж подтвержд',
      'payment date (fact)': 'дата платежа (факт)',
      'payment date (plan)': 'дата платежа (план)',
      'payment delay': 'задержка платежа',
      payments: 'платежи',
      penalties: 'штрафы',
      per: 'за',
      'per 1': 'за 1',
      'per 1 mt': 'за 1 т',
      'per 1t': 'за 1 т',
      'per lot': 'за лот',
      'per one': 'за сделку',
      'per one deal': 'за сделку',
      'per sales contracts': 'по продажным контрактам',
      'per vehicle': 'за транспортное средство',
      'percent conditions': 'условия процент',
      percentage: 'процент',
      period: 'период',
      permissions: 'полномочия',
      phone: 'телефон',
      'phone number': 'телефонный номер',
      picture: 'аватар',
      place: 'место',
      'place of shipment / unloading': 'место отгрузки/разгрузки',
      plan: 'план',
      'plan distributors': 'плановые дистрибьюторы',
      plans: 'планы',
      port: 'порт',
      'port destination': 'порт назначения',
      'port destination country': 'страна порта назначения',
      'port loading': 'порт отгрузки',
      'port loading country': 'страна порта погрузки',
      ports: 'порты',
      'ports opposite': 'порты встречные',
      position: 'позиция',
      'pre advice': 'преадвайс',
      premium: 'премиум',
      'premium price': 'премиум цена',
      'prepay invoice, issued by you to Buyer': 'предопл инвойс, выставленный нами Покупателям',
      'prepay invoice, issued to you by Supplier': 'предопл. инвойс, выставленный нам Поставщиками',
      prepayment: 'предоплата',
      'press this icon to edit update': 'нажмите этот значок для редактирования обновления',
      price: 'цена',
      'price for current vehicle differs to contract':
        'цена за текущее транспортное средство отличается от контрактной',
      'price from transshpment from cars': 'цена от перевалки из пром логистики',
      'price of current deal + its cost + your yield (set in settings) = the sale price by which this deal should be sold to satisfy the requirements of your yield':
        'цена текущей сделки + ее затраты + ваша рентабельность (задается в настройках) = такой должна быть закупочная цена для этой продажи, чтобы удовлетворить требованиям рентабельности',
      'price of current deal + its costs = the real costs of the deal':
        'цена текущий сделки + ее затраты = реальная стоимость сделки',
      'price of current deal - its cost - your yield (set in settings) = the purchase price by which this deal should be purchased to satisfy the requirements of your yield':
        'цена текущей сделки - ее затраты - ваша рентабельность (задается в настройках) = такой должна быть продажная цена для этой закупки, чтобы удовлетворить требованиям рентабельности',
      'price of current deal - its costs = the real income of  the deal':
        'цена текущей сделки - ее затраты = реальный доход от сделки',
      print: 'напечатать',
      'production year': 'год производства',
      'profit and loss': 'прибыль и потери',
      property: 'показатель',
      'protein * volume': 'протеин * объем',
      purchase: 'закупка',
      'purchase Contr. No.': 'закупочный контракт №.',
      'purchase Contr. Volume, UAH': 'сумма закупочного контракта, UAH с НДС',
      'purchase Contr. Volume, mt': 'объем (т) закупочного контракта',
      'purchase Contract Date': 'дата',
      'purchase basis': 'базис закупки',
      'purchase contract': 'закупочный контракт',
      'purchase contract basis': 'базис закупочного контракта',
      'purchase contract number': 'номер закупочного контракта',
      'purchase contract options': 'закупочный контракт опции',
      'purchase contract responsible': 'закупочный контракт ответственный',
      'purchase contract responsibles': 'закупочный контракт ответственные',
      'purchase contract responsibles for signing':
        'закупочный контракт ответственный за подписание',
      'purchase contract suppliers': 'закупочный контракт поставщики',
      'purchase contract suppliers/distributors': 'закупочный контракт поставщики/дистрибьюторы',
      'purchase contracts': 'закупочные контракты',
      'purchase contracts logistics buyer invoices':
        'инвойсы покупателя логистики закупочного контракта',
      'purchase destinations': 'назначения закупки',
      'purchase origins': 'происхождение закупки',
      'purchase sap order': 'sap номера заказов закупки',
      'purchase supplier': 'закупка поставщик',
      'purchase suppliers': 'поставщики закупки',
      'purchases & sales': 'закупки и продажи',
      purpose: 'назначение',
      'push this button after applying filters to find a logistics you want to\n                        send by email.':
        'нажмите эту кнопку после применения фильтров, чтобы найти логистику, которую вы хотите\n                        отправить по электронной почте.',
      qty: 'кол-во',
      quality: 'качество',
      'quality (date)': 'качество (дата)',
      'quality enterance': 'качество внесение',
      quantity: 'количество',
      'quantity of containers': 'кол-во контейнеров',
      'quantity of measurement': 'кол-во измерения',
      'quantity total amount': 'кол-во общая сумма',
      'quarantine (date)': 'карантин (дата)',
      'railway (date)': 'ж\\д (дата)',
      rate: 'ставка',
      read: 'прочитано',
      real: 'реальный',
      'real capacity': 'реал. вместительность',
      'realized value': 'реализованная сумма',
      reassignment: 'переуступка',
      received: 'получено',
      'received date - the date when you received the legal documents of this company':
        'дата получения - дата когда вы получили документы по компании',
      'received volume': 'полученный объем',
      receiver: 'получатель',
      'receiver code': 'код получателя',
      receivers: 'получатели',
      'receiving date': 'дата получения',
      'receiving stations': 'станции получения',
      'refresh totals': 'перезагрузить тоталы',
      region: 'область',
      'regions, where this counterparty operate mostly':
        'выберите области, в которых компания оперирует больше всего',
      'related to': 'относится к',
      relations: 'отношения',
      'rename file': 'переименовывать файл',
      report: 'отчет',
      'request date': 'дата заявки',
      requested: 'запрос',
      'requested date - the date when you requested the legal documents of this company':
        'дата запроса - дата когда вы запросили документы по компании',
      required: 'обязательно',
      responsibilities: 'ответственности',
      responsibility: 'ответственность',
      responsible: 'ответственный',
      'responsible confirmation': 'ответственный за подтверждение',
      'responsible for compliance': 'ответственный за комплаенс',
      'responsible for confirm': 'выбрать пользователя ответственным за подтверждение',
      'responsible for signing': 'ответственный за подписание',
      'responsible signing': 'выбрать ответственного за подписание',
      responsible_for_signing: 'ответственный за подписание',
      responsibles: 'ответственные',
      'responsibles for signing': 'ответственный за подписание',
      return: 'возврат',
      risk: 'риск',
      road: 'в пути',
      'road volume': 'объем в дороге',
      role: 'роль',
      roles: 'роли',
      rolling: 'роллинг',
      s: 's',
      sailed: 'отошло',
      sale: 'продажа',
      'sale Contr. No.': 'продажный контракт №.',
      'sale Plan': 'план продаж',
      'sale and purchases of': 'продажи и закупки по',
      'sale buyer': 'продажа покупатель',
      'sale buyers': 'продажа покупатели',
      'sale commodity': 'продажный товар',
      'sale contract': 'продажный контракт',
      'sale contract basis': 'продаж контракт базис',
      'sale contract number': 'номер продажного контракта',
      'sale contracts': 'продажные контракты',
      'sale destinations': 'назначения продажи',
      'sale origins': 'происхождение продажи',
      'sale owner': 'продажа владелец',
      'sale price multiplicator': 'мультипликатор продаж цены',
      'sale sap order': 'продажа sap заказ',
      'sales manager': 'региональный представитель',
      'sales manager head': 'глава региональных представителей',
      'sap order': 'sap номер заказа',
      'sap registered': 'sap зарегистрирован',
      save: 'сохранить',
      'save a transfer': 'сохранить трансфер',
      'save and continue': 'сохранить и продолжить',
      'save and continue editing': 'сохранить и продолжить',
      'save changes': 'сохранить изменения',
      'save collection': 'сохранить конфигурация',
      season: 'сезон',
      seasons: 'сезоны',
      seeds: 'посевы',
      'seeds amount': 'посевы сумма',
      'select and rename any of the fields you want to save for an Excel file\n                donwload':
        'выберите и переименуйте любое из полей, которые вы хотите экспортировать в Эксель файл',
      'select current collection for editing or select New collection to create':
        'выберите текущую коллекцию для редактирования или нажмите Новая коллекций для создания',
      'select emails you want to send': 'выберите emails, которые вы хотите отправить',
      'self-cost': 'себестоимость',
      'self-cost per 1t': 'себестоимость за 1т',
      selfcost: 'себестоимость',
      'send a report': 'отправить отчет',
      senders: 'отправители',
      sent: 'отправл',
      service: 'услуги',
      'set a prices to evaluate a position': 'установить цены для оценки позиции',
      'shipment elevators': 'элеваторы отгрузки',
      'shipment farms': 'хозяйство отгрузки',
      'shipment period': 'период отгрузки',
      'shipment period of the contract': 'период исполнения контракта',
      'shipment period of the request': 'период исполнения заявки',
      'shipment status': 'отгрузка началась',
      'shipment terminal': 'терминал отгрузки',
      'shipment terminals': 'выберите терминалы отгрузки',
      shipped: 'отгруж',
      'shipped by logistics': 'отгружено логистикой',
      short: 'шорт',
      'short net pnl mtm': 'short net pnl mtm',
      'short/long': 'шорт/лонг',
      shortage: 'шортадж',
      'shortage fee': 'шортадж затраты',
      'shortage quantity': 'шортадж объем',
      'shortage rate': 'шортадж ставка',
      'show all stock': 'показать все остатки',
      'show expired': 'показать неактуальные',
      'show expired tariffs': 'показать неактуальные тарифы',
      sides: 'стороны',
      signature: 'подпись',
      'single document size up to 1 mb': 'размер одного документа не должен превышать 1мб',
      'single document size up to 50 mb': 'один документ до 50 мегабайт',
      'single file size up to 50 mb (depends on your SMTP provider)':
        'размер одного файла не должен превышать 50 мб (зависит от провайдера почты)',
      source: 'источник',
      start: 'начало',
      'start date': 'дата начала',
      'start of execution': 'начало исполнения',
      station: 'станция',
      'station arrival': 'станция получения',
      'station departing': 'станция отправки',
      'station departure': 'станция отправки',
      'station deps': 'станции отправления',
      'station receiving': 'станция получения',
      'station recs': 'станция получения',
      status: 'статус',
      'stowage factor': 'фактор укладки',
      submit: 'подтвердить',
      'submit documents': 'подтвердить документы',
      success: 'успешно',
      superuser: 'суперпользователь',
      supplier: 'поставщик',
      'supplier balance invoices': 'поставщик баланс инвойсы',
      'supplier contract': 'поставщик контракт',
      'supplier contract basis': 'постав контракт базис',
      'supplier contracts': 'постав. контракт',
      'supplier invoiced': 'поставщик выставил',
      'supplier invoiced balance': 'поставщик выставл. баланс',
      'supplier managers': 'менеджеры поставщика',
      'supplier paid': 'поставщику оплатили',
      'supplier paid balance': 'поставщик оплачено баланс',
      'supplier prepay invoices': 'поставщик предоплатные инвовйсы',
      'supplier un invoiced': 'поставщик не выставлено',
      'supplier un invoiced balance': 'поставщик не выставлено  баланс',
      suppliers: 'поставщики',
      'suppliers of purchase contracts logistics buyer invoices':
        'выберите поставщиков закупочных контрактов',
      'suppliers/distributors': 'поставщики/дистрибьюторы',
      surveyor: 'сюрвейер',
      swift: 'свифт',
      t: 'т',
      't/day': 'т/день',
      't/h': 'т/ч',
      tasks: 'задачи',
      'tax return registered': 'возврат налога зарегистрирован',
      'telegram username': 'telegram никнейм',
      template: 'шаблон',
      terminal: 'терминал',
      'terminal (with consignment)': 'терминал (по накладной)',
      'terminal volume': 'объем на терминале',
      terminals: 'терминалы',
      'the actual tolerance range of the contract': 'опцион по контракту',
      'the full title of the legal entity (for usage in documents)':
        'полное название юридического лица (для использования в документах)',
      'the legal address of the legal entity': 'юридический адрес юридического лица',
      'the list of quality for this deal': 'список качества по этой сделке',
      'the list of users, who approved this deal':
        'список пользователей, которые подтверждают эту сделку',
      'the number of contracts that involve this company. Click on the counter and you will see them all':
        'количество контрактов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of documents attached to this company. Click on the counter and you will see them all':
        'количество документов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of invoices that involve this company. Click on the counter and you will see them all':
        'количество инвойсов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of issued invoiced by this contract. To know more - go to contract details':
        'сумма всех выставленных инвойсов по контракту. Чтобы узнать больше - зайдите в детали контракта',
      'the number of logistics that involve this company': 'количество логистики по этой компании',
      'the number of persons added by this company. Click on the counter and you will see them all':
        'количество контактов, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the number of updates and the last one added by this company. Click on the counter and you will see them all':
        'количество обновлений, добавленное по этой компании. Кликните на счетчик, чтобы увидеть все',
      'the open balance of the contract': 'открытый баланс по контракту',
      'the person who will be appointed in documents':
        'сотрудник компании, который будет указан в документах',
      'the roles in which this legal entity will be used in the system. It could be multiple roles, e.g. Buyer and Seller':
        'роли, в которых используется данное юридическое лицо в системе. Их может быть несколько, например, Поставщик и Покупатель в рамках одного юр лица',
      'the short title of the role (for internal use)':
        'короткое название роли (для внутреннего использования)',
      'the status is changed by setting dates of confirmations of counterparty':
        'статус меняется, выставлением дат подтверждения по контрагенту',
      'the value of the deal (except charges)': 'стоимость сделки (без учета затрат)',
      'the volume, attached to this passport': 'объем, привязанный к паспорту',
      'the volume, connected to passports': 'объем, привязанный к паспортам',
      'the volume, delivered to port': 'объем, доставленный в порт',
      'the volume, departed to port': 'объем, отправленный в порт',
      'the volume, for transport from open balance calculation':
        'объем по транспорту из расчёта открытого баланса',
      'the volume, from transport by basis': 'объем логистики по базисам',
      'the volume,that is currently on the road': 'объем, в дороге',
      'this button will send all chosen emails':
        'с помощью этой кнопки можно отправить все избранные письма по электронной почте',
      'this counterparty can confirm other counterparties. You can see it in the header of the page COUNTERPARTIES - > CONFIRMATIONS':
        'контрагент может подтверждать других контрагентов',
      'this field is calculated automatically': 'это поле вычисляется автоматически',
      'this margin is chosen for P&L report by margin selector':
        'эта маржа выбирается для отчета PnL с помощью переключателя маржи',
      'this page was designed the main settings of the company. Only admin can do\n              this':
        'эта страница была разработана на основных настройках компании. Только администратор имеет\n              доступ',
      'this page was designed to manage master data of the system. Only admin can do\n                this':
        'эта страница была разработана для управления основными данными системы. Только администратор имеет\n              доступ',
      ticket: 'тикет',
      tickets: 'тикеты',
      time: 'время',
      'time, date and user, who created this deal':
        'время, дата, пользователь, который создал эту сделку',
      title: 'название',
      to: 'до',
      'to be invoiced': 'будет проинвойсировано',
      'to company': 'компания кому',
      'to deliver and sale at': 'доставки и продажи в',
      'to multicontracts': 'к мультиконтрактам',
      'to open actions menu on certain item, press on its name':
        'чтобы открыть меню действий над определенным элементом, нажмите на его название',
      'to open document press on an icon near the title':
        'чтобы открыть документ, нажмите на иконку рядом с назвой',
      'to pay': 'оплатить',
      tolerance: 'опцион',
      tonnes: 'тонны',
      total: 'всего',
      'total approve time, h': 'общее время подтверждения, часы',
      'total charges': 'всего затраты',
      'total days of payments delay': 'всего дней задержки',
      'total value': 'общая сумма',
      'total volume': 'общий объем',
      transport: 'транспорт',
      'transport open balance volume': 'открытый баланс по транспорту',
      tt: 'акт',
      turnover: 'оборот',
      type: 'тип',
      types: 'типы',
      'under each passport you will see the one costs, split on two 500 and 500.':
        'под каждым паспортом вы увидите одну стоимость, разделенную на две части 500 и 500.',
      unexecuted: 'не исполнено',
      units: 'юниты',
      'unloading elevators': 'элеваторы разгрузки',
      'unloading farms': 'хозяйство разгрузки',
      'unloading terminals': 'выберите терминалы разгрузки',
      'until execution starts': 'до начала исполнения',
      'up to 30 days': 'до 30 дн',
      update: 'обновление',
      updated: 'обновлено',
      updates: 'обновления',
      'usd price': 'цена в USD',
      use: 'цель',
      'use a checkbox to completely hide/show field from the modal or use the button\n                  Users to customize the visibility for particular person':
        'используйте чекбокс, чтобы полностью показать/скрыть поле, или используйте кнопки Пользователь для настройки доступности поля для конкретного пользователя',
      'use a checkbox to completely hide/show field from the table or use the\n                        button Users to customize the visibility for particular person':
        'используйте флажок, чтобы полностью скрыть/показать поле из таблицы или используйте \n                        кнопку Пользователи, чтобы настроить видимость для определенного человека',
      'use a filter button on the right top corner of the page to filter the\n                        list':
        'используйте кнопку фильтра в правом верхнем углу страницы для фильтрации\n                        списка',
      'use a update button on the right top corner of the page to get the latest\n                        data without refreshing the page':
        'используйте кнопку обновления в правом верхнем углу страницы, чтобы получить последние\n                        данные без обновления страницы',
      'use convertation': 'используйте конвертацию',
      'use favorite filters button above the table. You can save sets of different\n                        filters and use them in a single click. This can be managed in the filters\n                        sidebar':
        'используйте кнопку "Любимые фильтры" над таблицей. Вы можете сохранить наборы различных \n                        фильтров и использовать их в один клик. Настраивать можно\n                         с помощью боковой панели фильтров',
      user: 'пользователь',
      'user created': 'пользователь создан',
      'username / login': 'никнейм / логин',
      'users approved this counterparty': 'пользователи подтвержд контрагента',
      uses: 'использование',
      validated: 'подтверждено',
      value: 'стоимость',
      vat: 'ндс',
      'vat = VAT CARGO SALE - (VAT CARGO PURCHASE + VAT CHARGES SALE + VAT CHARGES PURCHASE )':
        'ндс = НДС груз продажи - (НДС груз закупка + НДС затраты продажа + НДС затраты закупка)',
      'vat return': 'возврат ндс',
      'vat return rate': 'ставка возврата ндс',
      'vehicle number': 'номер транспортного средства',
      vehicles: 'кол-во транс-та',
      vessel: 'лодка',
      'vet (date)': 'вет (дата)',
      virtual: 'виртуальный',
      volume: 'объем',
      'volume / free': 'объем / свободный',
      'volume departed': 'объем отправленный',
      'volume departed consignment': 'отправленный объем накладных',
      'volume discharged': 'объем отгруж',
      'volume intake': 'объем погрузки',
      'volume loaded': 'объем загруж',
      'volume lost': 'потерянный объем',
      'volume max': 'макс объем',
      'volume min': 'мин объем',
      'volume received': 'объем полученный',
      volume_freighted: 'объем фрахт',
      vote: 'голосовать',
      voyage: 'рейс',
      'voyage business unit': 'бизнес юнит рейса',
      voyages: 'рейсы',
      'w/o passport': 'без паспорта',
      'was made from this indicator': 'сделан из индикатора',
      'was made from this ticket': 'был сделан из тикета',
      website: 'вебсайт',
      with: 'с',
      'with docs': 'с документами',
      "with sale contract's passports?": 'с продажными контрактами паспортами?',
      'with selected passport?': 'с выбранным паспортом?',
      'without VAT': 'без НДС',
      'without invoice, offset, reassignment': 'без инвойса, зачёта, переуступки',
      'would require deleting the following protected related objects:':
        'может потребоваться удаление следующих защищенных связанных объектов:',
      year: 'год',
      years: 'лет',
      'you can add new one': 'вы можете добавить новый',
      'you can create a new vessel and attach it to this passport':
        'вы можете создать новую лодку и привязать её к паспорту',
      'you can download any kit of fields for Export, created on Collection tab':
        'вы можете скачать любой набор полей в Экспорте, созданные на вкладке Коллекция',
      'you can make your own configuration of export and download the table in a\n                        certain EXCEL format':
        'вы можете создать свою конфигурацию экспорта и загрузить таблицу в\n                        определенном формате EXCEL',
      'you can manage email or messenger notifications on system events':
        'вы можете управлять уведомлениями на почту или в мессенджер',
      'you can manage the columns and their ordering pressing setting icon above\n                        the table':
        'вы можете управлять столбцами и их порядком, нажимая значок настройки над \n                        таблицей',
      'you can manage your prescriptions and notifications subscriptions here':
        'вы можете управлять вашими подписками и уведомлениями здесь',
      'you can navigate in system using panel on the top ☝, or at the bottom\n                👇':
        'вы можете перемещаться в системе с помощью панели вверху ☝, или внизу\n                👇',
      'you can now click': 'теперь вы можете нажать',
      'you can print any documents from predefined template':
        'вы можете распечатать любые документы из заранее определенного шаблона',
      'you can start the whole approval process once again':
        'вы можете начать заново весь процесс утверждения',
      'you can switch different views of the table using buttons on the right top\n                        of the table':
        'вы можете переключать разные виды таблицы с помощью кнопок в правом верхнем углу\n                        таблицы',
      'you can validate the counterparty as per predefined approval configuration':
        'вы можете подтвердить контрагента по предварительно определенной конфигурации подтверждения',
      'you can validate the passport as per predefined approval configuration':
        'вы можете подтвердить паспорт по предварительно определенной конфигурации подтверждения',
      'you can write an email to a client with predefined templates':
        'вы можете написать email контрагенту с предварительно определенными шаблонами',
      'you have limit on {[{ $root.user.settings.USERS_MAX_COUNT }]} active\n              users':
        'у вас лимит - {[{ $root.user.settings.USERS_MAX_COUNT }]} активных пользователей',
      '{[{ $ctrl.contract.approval_status }]}': '{[{ $ctrl.contract.approval_status }]}',
      '{[{ $ctrl.finance.approval_status }]}': '{[{ $ctrl.finance.approval_status }]}',
      '{[{ $ctrl.multicontract.contract_type }]}': '{[{ $ctrl.multicontract.contract_type }]}',
      '{[{ $ctrl.multirequest.contract_type }]}': '{[{ $ctrl.multicontract.contract_type }]}',
      '{[{ $ctrl.passport.approval_status }]}': '{[{ $ctrl.passport.approval_status }]}',
      '{[{ $ctrl.request.approval_status }]}': '{[{ $ctrl.request.approval_status }]}',
      '{[{ $root.gtUtils.getLocalCurrency() | translate }]}':
        '{[{ $root.gtUtils.getLocalCurrency() | translate }]}',
      "{[{ 'ISCC'|translate }]}": "{[{ 'ISCC'|translate }]}",
      '{[{ client.approval_status }]}': '{[{ client.approval_status }]}',
      '{[{ consignment.object_type }]}': '{[{ consignment.object_type }]}',
      '{[{ contract.approval_status }]}': '{[{ contract.approval_status }]}',
      '{[{ contract_price.port_name }]}': '{[{ contract_price.port_name }]}',
      "{[{ derivative.month | date:'MMM,yy' }]}": "{[{ derivative.month | date:'MMM,yy' }]}",
      '{[{ finance.finance_type }]}': '{[{ finance.finance_type }]}',
      '{[{ finance.report_column_type }]}': '{[{ finance.report_column_type }]}',
      '{[{ invoice.status }]})': '{[{ invoice.status }]})',
      '{[{ item.approval_status }]}': '{[{ item.approval_status }]}',
      '{[{ item.status }]}': '{[{ item.status }]}',
      '{[{ level.confirmation_logic | uppercase }]}':
        '{[{ level.confirmation_logic | uppercase }]}',
      "{[{ month['title'] }]}": "{[{ month['title'] }]}",
      '{[{ request.approval_status }]}': '{[{ request.approval_status }]}',
      '{[{ type.title }]}': '{[{ type.title }]}',
      '{[{ vm.agreement.approval_status }]}': '{[{ vm.agreement.approval_status }]}',
      '{[{ vm.logentry.change_message }]}': '{[{ vm.logentry.change_message }]}',
      '{[{ vm.mode }]}': '{[{ vm.mode }]}',
      '{[{ vm.passport.approval_status }]}': '{[{ vm.passport.approval_status }]}',
      '{[{ vm.title }]}': '{[{ vm.title }]}',
      '{[{$ctrl.certificateRepr[certificateType]}]}':
        '{[{$ctrl.certificateRepr[certificateType]}]}',
      '{[{certificateType}]}': '{[{certificateType}]}',
      "{[{contractInfo.crop_title || '---' }]}": "{[{contractInfo.crop_title || '---' }]}",
      '{[{contractInfo.volume_sum || 0 | number: 3 }]}':
        '{[{contractInfo.volume_sum || 0 | number: 3 }]}',
      '{[{item.use}]}': '{[{item.use}]}',
      '{{ $ctrl.errors.ext }}': '{{ $ctrl.errors.ext }}',
      '{{ $ctrl.errors.resourceObjId }}': '{{ $ctrl.errors.resourceObjId }}',
      '{{ $ctrl.errors.responseResult }}': '{{ $ctrl.errors.responseResult }}',
      '{{ $ctrl.errors.templateText }}': '{{ $ctrl.errors.templateText }}',
      '{{ $ctrl.verboseName }}': '{{ $ctrl.verboseName }}',
      '{{ derivative.commodity }}': '{{ derivative.commodity }}',
      '{{ derivative.title }}': '{{ derivative.title }}',
      '{{ role.role_name }}': '{{ role.role_name }}',
      'Нas Debit/Credit Cargo': 'Есть дебиторка/кредиторка по товару',
      'Нas Debit/Credit Contract': 'Есть дебиторка/кредиторка по контракту',
      'Нas Debit/Credit Invoice': 'Есть дебиторка/кредиторка по инвойсу',
      Сourier: 'Курьер',
    });
    gettextCatalog.setStrings('ru', {
      'Waiting for approval': 'Ожидает подтверждения',
      Approved: 'Утверджено',
      'Approval in process': 'Подтв в процессе',
      Rejected: 'Забраковано',
      'Approval Config': 'Конфигурация подтверждения',
      'Approval status': 'Статус подтверждений',
      Approvals: 'Подтверждения',
      'Any of users': 'Любой из пользователей',
      'All users': 'Все пользователи',
      'Majority users': 'Большинство пользователей',
      Client: 'Юридическое лицо',
      Request: 'Заявка',
      Indicator: 'Индикатор',
      Ticket: 'Тикет',
      'Purchase Contract': 'Закупочный контракт',
      'Sale Contract': 'Продажный контракт',
      'General Agreement': 'Договор',
      Contract: 'Контракт',
      Passport: 'Паспорт',
      Finance: 'Финансы',
      'Payment Plan': 'План платежа',
      'Company group': 'Группа компаний',
      'Object type': 'Тип объекта',
      'Main approvers': 'Супер утверждающие',
      'Approval Configs': 'Конфигурации подтверждений',
      'Index (ordering)': 'Сортировка',
      Approvers: 'Подтверждающие',
      'Confirmation logic': 'Логика подтверждения',
      'Approval Config Level': 'Уровень подтверждения',
      'Approval Config Levels': 'Уровни подтверждения',
      Declined: 'Отменено',
      Comment: 'Комментарий',
      Archived: 'Архивировано',
      Approval: 'Подтверждено',
      'First name': 'Имя',
      'Middle name': 'Отчетство',
      'Last name': 'Фамилия',
      Phone: 'Телефон',
      Email: 'Эл.почта',
      Position: 'Позиция',
      'Business Units': 'Бизнес юниты',
      'Sale manager': 'Региональный представитель',
      'Sale managers': 'Региональные представители',
      Enter: 'Войти',
      'In the case of any questions, please, contact our Support Team':
        'По всем вопросам связывайтесь с Командой Поддержки',
      'Pressing the button I confirm, I have read and agree with':
        'Нажимая на кнопку ВОЙТИ и заходя на сайт, Я подтверждаю, что ознакомился с',
      'terms of use and privacy policy': 'условиями использования и конфиденциальности',
      'This field can not be changed after creation.':
        'Это поле не может быть изменено после создания',
      'Invalid login and/or password': 'Неправильный Логин и/или Пароль. Попробуйте снова',
      'You need sent three values (current, new, confirm)':
        'Вам нужно сохранить три значения (текущее, новое, подтвержд.)',
      'Wrong password.': 'Неправильный пароль',
      'New password and confirm must be the same.': 'Пароли должны совпадать',
      'Password updated.': 'Пароль обновлен',
      'There are {} cities named {}. Please, add district to it.':
        'Есть {} города/ов с именем {}. Пожалуйста, добавьте к нему район.',
      'There are no district {} for city {}.': 'Район {} для города {} отсутствует.',
      'Region matching query (title={}) does not exist':
        'Область {} соответствующий запрос ({} = {}) не существует',
      'User for this person already exist.':
        'Кабинетный пользователь уже создан для этого Контакта',
      Supplier: 'Поставщик',
      Exporter: 'Экспортер',
      Farm: 'Хозяйство',
      Elevator: 'Элеватор',
      Deliverer: 'Экспедитор',
      Bank: 'Банк',
      Owner: 'Владелец',
      Other: 'Другие',
      Surveyor: 'Сюрвейер',
      Buyer: 'Покупатель',
      Broker: 'Брокер',
      Insurer: 'Страховая',
      Hot: 'Горячий',
      Warm: 'Теплый',
      Neutral: 'Нейтральный',
      Cold: 'Холодный',
      Angry: 'Злой',
      New: 'Новое',
      'Approved condition': 'Подтверждено с условием',
      'Out of approval': 'Вне согласования',
      Pending: 'В процессе',
      'Re-approval': 'Повтор подтв',
      Big: 'Большие',
      Medium: 'Средние',
      Small: 'Маленькие',
      Call: 'Звонок',
      Meet: 'Встреча',
      Task: 'Задание',
      Commentary: 'Комментарий',
      High: 'Важно',
      Critical: 'Критично',
      Success: 'Успех',
      Fail: 'Неудачно',
      Progress: 'Прогресс',
      Cancelled: 'Отменено',
      Upcoming: 'Предстоит выполнить',
      'Additional info': 'Примечание',
      'Client Document Type': 'Тип документа',
      'Days to update client status': 'Дни до обновления статуса клиента',
      'company group': 'группа компаний',
      'Company groups': 'Группы компаний',
      Responsibility: 'Ответственность',
      responsibility: 'ответственность',
      Responsibilities: 'Ответственности',
      'Django user': 'Обычный пользователь',
      'First Name': 'Имя',
      'Second Name': 'Фамилия',
      'Middle Name': 'Отчетство',
      Companies: 'Компании',
      'Phone additional': 'Дополнит телефон',
      Birthday: 'День Рождения',
      'Main contact': 'Основной контакт',
      Relation: 'Отношения',
      person: 'контакт',
      Persons: 'Сотрудники',
      'Can create GrainTrack user for person': 'Может создавать пользователя Кабинетов',
      Name: 'Название',
      'Custom status': 'Пользовательский статус',
      Abbreviation: 'Аббревиатура',
      'Full name': 'Полное название',
      'Full name Eng': 'Полное название (Англ.)',
      Code: 'Код',
      EIC: 'EIC',
      ITN: 'ИНН',
      'Tax info': 'Налоговая информация',
      Country: 'Страна',
      Address: 'Адрес',
      'Legal address': 'Юридический адрес',
      'Legal address eng': 'Юридический адрес (англ)',
      Person: 'Контакт',
      Signatory: 'Подписант',
      'Signatory Eng': 'Подписант (Англ)',
      'Signatory Docs': 'Подписант Доки',
      'Signatory Eng Docs': 'Подписант Доки (Англ)',
      'Phone (additional)': 'Телефон (дополнительный)',
      Website: 'Веб-сайт',
      'Black list': 'Черный список',
      Documents: 'Документы',
      'First confirmation (date)': 'Первое подтверждение (дата)',
      'Term of proxy': 'Срок действия доверенности',
      'Confirmation (date)': 'Подтверждение (дата)',
      'Confirmation info': 'Подтверждение (инфо)',
      'Request (date)': 'Дата запроса доков',
      'Received (date)': 'Получено (дата)',
      'Incorporation (date)': 'Дата инкорпорации',
      Status: 'Статус',
      President: 'Президент',
      CEO: 'CEO',
      CFO: 'CFO',
      'Other executive': 'Другие менеджеры',
      'Parent company': 'Материнская компания',
      'Ultimate parent company': 'Материнская компания',
      'Ultimate beneficiary owners': 'Конечные бенефициары',
      'Activities & traded products': 'Активность & товары',
      Suppliers: 'Поставщики',
      Customers: 'Клиенты',
      'Can confirm clients': 'Может подтверждать',
      'Don`t use kpi check': 'Не использовать для KPI',
      Responsible: 'Ответственный',
      'Responsible for confirmation': 'ответственный за подтверждение',
      'Client is resident': 'Контрагент является резидентом',
      'SAP vendor number': 'SAP номер контрагента',
      Equity: 'Капитал',
      'Net income': 'Чистая прибыль',
      'Total fixed assets': 'Всего основных средств',
      Turnover: 'Оборот',
      'Total assets': 'Всего активы',
      'Annual traded volume': 'Годовой объем торгов',
      'Info for traders': 'Информация для трейдеров',
      'Contact (future)': 'Контакт (будущий)',
      City: 'Город',
      District: 'Район',
      Regions: 'Области',
      'Client crop area': 'Посевная площадь по контрагенту',
      'Cash Flow': 'Денежный поток',
      'Client transports count': 'Счетчик транспортных средств компании',
      'Regular status': 'Статус сделок',
      Active: 'Актив',
      Inactive: 'Не активный',
      Closed: 'Закрыто',
      Size: 'Размер',
      'Contact (first)': 'Контакт (первый)',
      Agent: 'Посредник',
      Producer: 'Производитель',
      Competitor: 'Конкурент',
      'Local currency': 'Локальная валюта',
      'Foreign currency': 'Валюта',
      Updates: 'Обновления',
      client: 'Юридическое лицо',
      Clients: 'Юридические лица',
      'Can view client offers': 'Просмотр индикаторов контрагентов',
      'Can view client tickets': 'Просмотр тикетов контрагентов',
      'Can view client confirmations': 'Просмотр подтверждений по контрагенту',
      'Can view client statistics': 'Просмотр статистики контрагентов',
      'Can view client persons': 'Просмотр сотрудников контрагентов',
      'Can edit date_confirmation': 'Дата подтверждения',
      'Can edit date_first_confirmation': 'Первое подтверждение (дата)',
      'Can edit status': 'Только куратор может редактировать',
      'Can edit kpi confirmation success status': 'Может редактировать KPI успех',
      'Can edit client responsible': 'Может редактировать ответственного по контрагенту',
      'Can edit client confirmation of a counterparty':
        'Может редактировать подтверждения по контрагенту',
      'Can approve client': 'Может подтверждать клиента',
      Crop: 'Культура',
      'Initial volume (t)': 'Первичный объем (т)',
      'Volume (t)': 'Объем (т)',
      'Cultivated area': 'Посевная площадь',
      'productivity of land': 'продуктивность',
      Note: 'Примеч',
      potential: 'потенциал',
      potentials: 'потенциалы',
      'Role Name': 'Имя роли',
      'Limit per one deal (spot), t': 'Лимит за сделку (спот), т',
      'Limit general (spot), t': 'Лимит общий, т',
      'Limit open balance (spot), t': 'Лимит открытый баланс (спот), т',
      'Limit unpaid volume (spot), t': 'Лимит по неоплаченному объему (спот), т',
      'Limit per one deal (spot), USD': 'Лимит за сделку (спот), дол',
      'Limit general (spot), USD': 'Лимит общий (спот), дол',
      'Limit per one deal (forward), t': 'Лимит за сделку (форвард), т',
      'Limit general (forward), t': 'Лимит общий (форвард), т',
      'Limit open balance (forward), t': 'Лимит открытый баланс (форвард), т',
      'Limit unpaid volume (forward), t': 'Лимит по неоплаченному объему (форвард), т',
      'Limit per one deal (forward), USD': 'Лимит за сделку (форвард), дол',
      'Limit general (forward), USD': 'Лимит общий (форвард), дол',
      'Affiliated suppliers': 'Аффилированные поставщики',
      Basises: 'Базисы',
      'Payment conditions': 'Условия платежа',
      'Payment conditions auto': 'Условия оплаты (авто)',
      'Payment conditions option': 'Условия платежа опция',
      'Client role': 'Контрагент роль',
      'Client roles': 'Юридические лица, роли',
      'Can change limits': 'Может изменять лимиты',
      owner: 'владелец',
      Owners: 'Владельцы',
      other: 'другое',
      Others: 'Другие',
      seller: 'продавец',
      Sellers: 'Продавцы',
      'Can view sellers': 'Просмотр списка продавцов',
      broker: 'брокер',
      Brokers: 'Брокеры',
      surveyor: 'сюрвейер',
      Surveyors: 'Сюрвейеры',
      insurer: 'страховая',
      Insurers: 'Страховые',
      bank: 'банк',
      Banks: 'Банки',
      forwarder: 'перевозчик',
      Forwarders: 'Перевозчики',
      exporter: 'экспортер',
      Exporters: 'Экспортеры',
      buyer: 'покупатель',
      Buyers: 'Покупатели',
      elevator: 'элеватор',
      Elevators: 'Элеваторы',
      Farms: 'Хозяйства',
      Update: 'Обновлено',
      Response: 'Ответ',
      Priority: 'Приоритет',
      Type: 'Тип',
      'Contact status': 'Статус контракта',
      'Is auto created': 'сгенерировано автоматически',
      Title: 'Название',
      'Title in English': 'Название на английском',
      'Client Document Types': 'Типы документов',
      'Is visible': 'видимый  ',
      'Copy is available': 'Есть копия',
      'Original is available': 'Есть оригинал',
      'Sending date': 'Дата отправки',
      'Receipt date': 'Дата получения',
      'Client Document': 'Документ',
      'Client Documents': 'Документы',
      'Commentary text': 'Комментарий',
      'Client commentary': 'Клиентский комментарий',
      'Client commentaries': 'Комментарии',
      'Can view client commentaries': 'Может просматривать клиентские комментарии',
      Area: 'Посевная площадь',
      Volume: 'Объем',
      'Crop year': 'Год урожая',
      'Grain quality check': 'Проверка качества',
      'Cargo price': 'Цена груз',
      Currency: 'Валюта',
      'Exchange rate': 'Курс',
      'Place of storage': 'Место хранения',
      'Crop Area': 'Посевная площадь',
      'Crop Areas': 'Культура, объем',
      Value: 'Показатель',
      'Value alt': 'Значение альт',
      'Title alt': 'Название альт',
      Distance: 'Расстояние',
      'Warehouse Distance': 'Расстояние',
      'Warehouse Distances': 'Расстояниe',
      Codename: 'Код название',
      'Client Role': 'Роль контрагента',
      'Client Roles': 'Роли контрагента',
      Region: 'Область',
      'Invoice Position': 'Инвойс позиция',
      Payment: 'Платеж',
      Logistic: 'Логистика',
      Consignment: 'Накладная',
      'Bill of Lading': 'Коносамент (дата)',
      Voyage: 'Рейс',
      Operation: 'Операция',
      'Bank Account': 'Банковский счет',
      created: 'создано',
      of: ' из',
      'This supplier must be a distributor, because it has opened reassignments.':
        'Этот поставщик должен являться дистрибьютором, потому что у него есть открытые переуступки',
      'This supplier must be a distributor, because it has opened offsets.':
        'Этот поставщик должен являться дистрибьютором, потому что у него есть открытые зачеты',
      'Warehouse distance can have only one warehouse.': 'У дистанции может быть только один склад',
      'Warehouse required.': 'Склад обязателен',
      'Distance should be unique by client, city and warehouse.': 'Расстояние ',
      'Delivery period': 'Период поставки',
      Shipment: 'Отгрузка',
      Executed: 'Выполнено',
      Washout: 'Вошаут',
      Process: 'Процесс',
      Valid: 'Актуально',
      invalid: 'Неактуально',
      'One full cargo': 'Весь объем',
      'Partial shipment allowed': 'Частичный объем',
      Seller: 'Продавец',
      Sale: 'Продажа',
      Purchase: 'Закупка',
      Prepayment: 'Предоплата',
      Processed: 'В процессе',
      Sent: 'Отправлено',
      Spot: 'Спот',
      Forward: 'Форвард',
      Services: 'Услуги',
      Intermediate: 'Промежуточные',
      Export: 'Экспорт',
      '20 DV': 'Тариф 20',
      '40 DV': 'Тариф 40',
      'Not selected': 'Не выбрано',
      'Loading minus': 'Отгрузка в минус',
      'Loading plus': 'Отгрузка в плюс',
      'Free tax invoice': 'Свободный налог инвойс',
      'On first event': 'По первому событию',
      'Absolute value': 'Абсолютное значение',
      Percentage: 'Процент',
      'Contract Option': 'Контракт опция',
      'Option (value)': 'Опция (стоимость)',
      'Conclusion (date)': 'Дата заключения',
      'Execution (start date)': 'Исполнение (дата начала)',
      'Execution (end date)': 'Исполнение (дата конца)',
      'Deal Type': 'Тип сделки',
      'Notify party': 'Уведомленная сторона',
      'start date': 'дата начала',
      'end date': 'дата окончания',
      'Program type': 'Тип программы',
      Crops: 'Культуры',
      'Price currency': 'Валюта',
      'Premium currency': 'Валюта',
      month: 'Месяц',
      Price: 'Цена',
      Premium: 'Премиум цена',
      Charge: 'Затрата',
      'Price per t': 'Цена за 1 т',
      'With VAT': 'с учетом НДС',
      'Price opposite': 'Цена закупки\\продажи',
      'Is gain': 'Это поступление',
      'Counterparty amount': 'Сумма контрагента',
      'Our amount': 'Наша сумма',
      'Final Amount': 'Финальная сумма',
      'Analytical Amount': 'Аналитическая сумма',
      Validated: 'Утверждено',
      'Under discussion': 'Обсуждается',
      Forecast: 'Прогноз',
      'Pending confirmation': 'Ожидает подтвержения',
      Date: 'Дата',
      Commodity: 'Продукт',
      'Opposite basis': 'Противоположный базис',
      'Costs opposite': 'Затраты встречного контракта',
      'Currency exchange': 'курс',
      'Counterparties opposite': 'Противоположный контрагент',
      'Price premium opposite': 'Премиум цена встречного контракта',
      Derivative: 'Дериватив',
      'Number of contract (#)': 'Номер контракта (№)',
      'Number of folder (#)': 'Номер папки',
      'Responsible for signing': 'Ответственный за подписание',
      'Volume with docs (t)': 'Объем с доками (Т)',
      Option: 'Опция',
      'Final volume (t)': 'Финальный объем (т)',
      Origin: 'Происхождение',
      Destination: 'Назначение',
      Origins: 'Происхождение',
      Destinations: 'Назначение',
      'Price USD': 'Цена, $',
      'Currency and Rate': 'Валюта и Курс',
      Basis: 'Базис',
      'Paper Trade': 'Бумажная торговля',
      'Custom declaration': 'ГТД',
      'Dont check kpi time different': 'Не использовать для KPI',
      'Nomination date': 'Дата номинации',
      Extention: 'Пролонгация',
      'Bill of lading (date)': 'Коносамент (дата)',
      'Transfer of ownership (date)': 'Передача права собственности',
      'Shipment type': 'Тип отгрузки',
      'Balance payment': 'Балансовый платеж',
      Forwarder: 'Перевозчик',
      Ports: 'Порты',
      'Opposite ports': 'Противоположный порт',
      'Station depart': 'Станция отправления',
      'Station receive': 'Станция получения',
      'Exchange for calculation': 'Курс (вручную)',
      'Exchange for calculation (opposite)': 'Курс (вручную)',
      'Delivery conditions': 'Вид транспортировки',
      VAT: 'НДС',
      'VAT (value)': 'НДС процент',
      'No extension': 'Без продления',
      'Preadvice (days)': 'Преадвайс (дни)',
      'Quality info': 'Качество инфо',
      Stage: 'Вид',
      'Previous status': 'Предыдущий статус',
      'Logistics costs VAT': 'Затраты по логистике (НДС)',
      'Logistics penalties VAT': 'Штрафы по логистике (НДС)',
      'Needs original documents': 'Нужны оригиналы',
      'Heads of sales manager': 'Региональный представитель',
      'Calculate execution by intermediate logistics': 'Расчет исполнения (пром. логистика)',
      'Calculate tax profit to pay': 'Кальк налог',
      'Elevator (start date)': 'Исполнение (начало)',
      'Elevator (end date)': 'Исполнение (конец)',
      'Estimated execution date': 'Ожидаемый месяц исполнения',
      'Loading option': 'Опцион отгрузки',
      Swap: 'СВОП',
      'Ignore limits': 'Игнорировать лимиты',
      Terminal: 'Терминал',
      'Few departure stations': 'Несколько станций отправки',
      ISCC: 'ISCC',
      'Minimum batch of payment': 'Мин сумма платежа',
      'Internal Chain': 'Внутренняя цепочка',
      'Ship classification': 'Класс лодки',
      'Estimated freight value': 'Прогнозированная стоимость фрахта',
      Multicontract: 'Мультиконтракты',
      'Contract been updated today': 'Обновлено сегодня',
      Season: 'Сезон',
      'vat return rate': 'ставка возврата НДС',
      Port: 'Порт (терминал)',
      Budget: 'Бюджет',
      'Conclusion date currency': 'Курс на дату заключения',
      'Conclusion date exchange rate': 'Курс валют на дату заключения',
      'Potential buyers': 'Потенциальные покупатели',
      'Potential suppliers': 'Потпнциальные поставщики',
      'Created from risk position origination': 'Создано из склада',
      'Tare Weight': 'Вес тары',
      'Quantity of measurement': 'Количество единицы измерения',
      guarantors: 'гарант',
      'Load rate': 'Норма погрузки',
      'Discharge rate': 'Норма выгрузки',
      'Dispatch rate': 'Диспач ставка',
      'Volume (min)': 'Объем (мин)',
      'Volume (max)': 'Объем (макс)',
      'Implied freight level': 'Прогнозированная стоимость фрахта',
      'Opposite freight level': 'Стоимость фрахта встречного контракта',
      'Load port': 'Порт отгрузки',
      'Discharge port': 'Порт выгрузки',
      'Arrival period start': 'Период прибытия начало',
      'Arrival period end': 'Период прибытия конец',
      'Pre-advice': 'Преадвайс',
      'Is extension': 'продление',
      Discount: 'Дисконт',
      'Discount amount': 'Значение дисконта',
      Open: 'Открыто',
      Terminals: 'Терминалы',
      'Can view contract plan': 'Просмотр плана контракта',
      'Can view contract costs': 'Просмотр затрат по контракту',
      'Can view contract logistics': 'Просмотр логистики по контрактам',
      'Can view contract payments': 'Просмотр платежей по контракту',
      'Can view contract documents': 'Просмотр документов по контракту',
      'Can view contract counterparties': 'Просмотр списка контрагентов в контракте',
      'Can view contract plan MTM calculation': 'Может просматривать контракт план МТМ расчет',
      'Can view contract activities': 'Просмотр истории по контрактам',
      'Can view contract processes': 'Просмотр этапов контракта',
      'Can view contract status': 'Просмотр статуса контракта',
      'Can confirm cargo execution of contract': 'Может подтверждать исполнение контракта (груз)',
      'Can confirm payment execution of contract':
        'Может подтверждать исполнение контракта (финансы)',
      'Can confirm final execution of contract': 'Может подтверждать исполнение контракта (финал)',
      'Can see buyer logistics payment': 'Просмотр платежей на покупателя',
      'Can see supplier logistics payment': 'Просмотр платежей на поставщика',
      'Can see payment progressbar': 'Просмотр оплат по контракту',
      'Can see costs progressbar': 'Просмотр затрат по контаркту',
      'Can see contract payments totals': 'Просмотр платежей по контракту (тоталы)',
      'Can edit kpi_signing_success': 'Может редактировать KPI успех',
      'Can change "updated today" status': 'Может менять "обновлено сегодня" статус',
      'Purchase contract': 'Закупочный контракт',
      'Purchase contracts': 'Закупочные контракты',
      'Can approve purchase contract': 'Может подтверждать закупочные контракты',
      'Can view purchase contracts of all authors':
        'Может просматривать закупочные контракты от всех авторов',
      'Can view purchase contracts of all responsibles':
        'Может просматривать закупочные контракты от всех ответственных',
      'Sale contract': 'Продажный контракт',
      'Sales contracts': 'Продажные контракты',
      'Can approve sale contract': 'Может утверждать продажные контракты',
      'Can view sale contracts of all authors':
        'Может просматривать продажные контракты от всех авторов',
      'Can view sale contracts of all responsibles':
        'Может просматривать продажные контракты от всех ответственных',
      Calculate: 'Расчетный',
      'Vat discount': 'НДС дисконт',
      '20 dv': 'Тариф 20',
      Containers_type: 'Тип контейнеров',
      'Price purchase calc': 'Цена закупки кальк',
      'Costs calc': 'Затраты расчет',
      'Calculate auto tariff price': 'Расчет авто тариф',
      'Calculate elevator costs': 'Расчет перевалка',
      'Calculate railway costs': 'Расчет жд тариф',
      'Calculate freight costs': 'Посчитать фрахт',
      'Conclusion date opposite': 'Дата заключения противопол',
      'Financing cost': 'Финанс затраты',
      'Financing days': 'Финанс дни',
      'Storage days': 'Дни хранения',
      Distributors: 'Дистрибьюторы',
      'Closing of existing position': 'Закрытие существующей позиции',
      'Estimated P&L': 'потенциальный результат',
      'Broker commission': 'Брокерская комиссия',
      'Possible buyers info': 'Потенциальные покупатели (инфо)',
      'Possible suppliers info': 'Потенциальные поставщики',
      'Possible brokers info': 'Потенциальные брокеры (инфо)',
      Strategy: 'Стратегия',
      'Trade confirmations': 'Трейдерские подтверждения',
      'Payment terms': 'Условия оплаты',
      'Price (min)': 'Цена (мин)',
      'Price (max)': 'Цена (макс)',
      'Connection to passport status': 'Статус привязки к паспорту',
      Requests: 'Заявки',
      'Can view all requests': 'Просмотр всего списка индикаторов/тикетов',
      'Can view request counterparties': 'Просмотр списка контагентов в тикете',
      'Can view request costs': 'Просмотр затрат по тикету',
      'Can view request finances': 'Просмотр финансов индикатора/тикета',
      'Can view request documents': 'Просмотр документов по заявке',
      'Can view request logistic prices': 'Просмотр расчета логистики в тикете',
      'Can view prices': 'Просмотр цен',
      'Can create ticket from offer': 'Может создать тикет из индикатора',
      'Can confirm requests': 'Может подтверждать заявки',
      Indicators: 'Индикаторы',
      'Number of agreement (#)': 'Номер договора (№)',
      'Agreement date': 'Дополнительное соглашение (дата)',
      autogenerated: 'сгенерировано',
      'Premium price': 'Премиум цена',
      'Additional agreement': 'Дополнительное соглашение',
      'Additional agreements': 'Дополнительные соглашения',
      'Select one or more basises to filter charges inside contracts':
        'Выберите один или несколько базисов, чтобы отфильтровать по ним затраты внутри контрактов',
      'Basis Document': 'Базовый документ',
      'Basis Documents': 'Базовые документы',
      'Contract Basis Doc': 'Базовый документ',
      'Active option': 'Активная опция',
      'Price premium': 'Премиум цена',
      'Margin relative, USD': 'Маржа относительная, USD',
      'Margin absolute, USD': 'Маржа абсолютная, USD',
      'For KPI year report': 'Для КПИ отчета (год)',
      Сommodity: 'Продукт',
      'Production Year': 'Год производства',
      'Purchase Plan': 'План закупки',
      'User volume': 'Пользователь объем',
      'Volume in percent': 'Объем в процентах',
      'Indication date': 'Дата индикатора',
      'A month for which the indicator price is assigned':
        'Месяц на который запланирован индикатор',
      'Cargo price, USD': 'Цена, $',
      'Used in market': 'Использовать в рынке',
      'Can mass-delete market prices': 'Может массово удалять рыночные цены',
      'Number (#)': 'Номер (№)',
      Invoice: 'Инвойс',
      Consignments: 'Накладные',
      Credit: 'Кредит',
      Amount: 'Всего',
      'Tax registration': 'Налог регистрация',
      'percent conditions': 'условия платежа',
      'General agreement': 'Договор',
      'General agreements': 'Договора',
      'Can approve general agreement': 'Может подтверждать рамочные договора',
      'Railway required': 'Ж/д обяз',
      'Vet required': 'Вет обяз',
      'Quality required': 'Качеств обяз',
      'Declaration required': 'Декларация обязательна',
      'EUR 1 required': 'Требуется EUR 1',
      'Volume in positions from execution': 'Объем по исполнению',
      'With derivative': 'С деривативом',
      Number: 'Номер',
      'Stock volume': 'Проверьте объем',
      'Quantity of estimated lots': 'Количество лотов',
      volume: 'объем',
      Commission: 'Комиссия',
      'Empty contract number': 'Пустой номер контракта',
      'Crops required.': 'Обязательно нужно указать культуру',
      'Months required.': 'Обязательно нужно указать месяц',
      'Positions required.': 'Обязательно указать позицию',
      'Term of the contract': 'Условия контракта',
      'is completed on ': 'будет завершен ',
      info: 'информация',
      t: 'т',
      'Ticket status': 'Статус тикета',
      'Is a closing of the existing position': 'Закрывает существующую позицию',
      Details: 'Детали',
      Startegy: 'Стратегия',
      'Trade confirmation': 'Трейдерское подтверждение',
      'PurchasePlan must be unique by crop in particular period':
        'План должен быть уникален по культуре и периоду',
      'First date must be less then Second': 'Первая дата должна быть ранее другой',
      'Unpaid volume is greater than limit for spot contracts':
        'Неоплаченный превышает общий лимит ({} t)',
      'Unpaid volume is greater than limit for forward contracts':
        'Неоплаченный превышает общий лимит ({} t)',
      'Volume is greater than limit per one deal for spot contracts ({} t)':
        'Объем превышает лимит по одной сделке ({} t)',
      'Volume is greater than general limit for spot contracts ({} t)':
        'Объем превышает общий лимит ({} t)',
      'Volume is greater than limit per one deal for forward contracts ({} t)':
        'Объем превышает лимит по одной сделке ({} t)',
      'Volume is greater than general limit for forward contracts ({} t)':
        'Объем превышает общий лимит ({} t)',
      'Total value is greater than limit per one deal for spot contracts ({} USD)':
        'Сумма превышает лимит по одной сделке ({} t)',
      'Total value is greater than general limit for spot contracts ({} USD)':
        'Сумма превышает общий лимит ({} t)',
      'Total value is greater than limit per one deal for forward contracts ({} USD)':
        'Сумма превышает лимит по одной сделке ({} t)',
      'Total value is greater than general limit for forward({} USD)':
        'Сумма превышает общий лимит ({} t)',
      'With this volume open balance is greater than open balance limit for spot contracts ({} t)':
        'Объем открытого баланса превышает допустимый по спот контрактам ({} t)',
      'With this volume open balance is greater than open balance limit for forward contracts ({} t)':
        'Объем открытого баланса превышает допустимый по форвард контрактам ({} t)',
      'Set consignment warehouse': 'Задать накладную склад',
      'Contract can have only one warehouse.': 'Контракт может иметь только один склад',
      'Contract already exist with number ': 'Контракт с указанным номером уже существует',
      'Contract and general agreement must have same currency.':
        'Контракты и соглашение должны иметь одинаковою валюту',
      'Shipment terminal required.': 'Необходимо заполнить поле Терминал.',
      'Supplier must be owner too': 'Поставщик также должен быть Владельцем',
      'Buyer must be owner too': 'Покупатель также должен быть Владельцем',
      'Credit limit required': 'Кредитный лимит обязателен',
      'Currency required': 'Валюта обязательна',
      'Contracts and general agreement must have same currency.':
        'Контракты и соглашение должны иметь одинаковою валюту',
      'Contract plan must be unique by month and passport.':
        'Контракт план должен быть уникальным по месяцу и паспорту',
      'Entry must be unique by month and passport.':
        'Контракт план должен быть уникальным по месяцу и паспорту',
      'In forward contracts end of execution is required.':
        'В форвардных контрактах конец исполнения обязателен',
      'In forward contracts conclusion date should be less than end of execution.':
        'В форвардных контрактах дата заключения должна начинаться раньше конца исполнения',
      'Currency exchange should be in {}.': 'Курс валюты лолжен быть в {}.',
      'Number should be positive.': 'Число должно быть положительным',
      'Please select exchange rate': 'Пожалуйста, выберите обменный курс',
      'Currency exchange rate should be in {}': 'Курс валюты должен быть в {}.',
      'Such sale plan already exists': 'Такой план продажи уже существует',
      All: 'Все',
      "File name contains the invalid characters (don't use special symbols like '<>|&=-;`/\\').":
        "Файл содержит неподдерживаемые символы(старайтесь не использовать символы по типу '<>|&=-;`/\\').",
      'Please check the data you would like to import. It seems that the spreadsheet has a lot of empty rows or to long.\nThe best way to import the registry is to copy only data to the new spreadsheet, save it and import again.\nIn case you see this message again please contact Support Manager.':
        'Пожалуйста, проверьте данные, которые хотите импортировать. В импортируемом файле много пустых рядов\nЛучший способ импортировать реестр это скопировать только данные в новый файл, сохранить и импортировать еще раз.\nВ случае, если это сообщение продолжается появляться - свяжитесь с вашим Саппорт менеджером',
      English: 'English',
      Ukrainian: 'Українська',
      Russian: 'Русский',
      Sunday: 'Воскресенье',
      Monday: 'Понедельник',
      Tuesday: 'Вторник',
      Wednesday: 'Среда',
      Thursday: 'Четверг',
      Friday: 'Пятница',
      Saturday: 'Суббота',
      'Dark theme': 'Темная тема',
      'White theme': 'Белая тема',
      'Top navbar': 'Верхний навбар',
      'Left navbar': 'Левый навбар',
      'Collapsed navbar': 'Группировка навигации',
      'Open navbar': 'Группировка навигации',
      'Sale deals': 'Продажные сделки',
      'Purchase deals': 'Закупочные сделки',
      Lineup: 'Лайнап',
      'Table view': 'Таблица',
      'List view': 'Список',
      'Block view': 'Блок',
      Allocation: 'Распределение',
      'Risk position': 'Риск позиция',
      'Derivatives position': 'Позиция дериватива',
      'Consolidated crop position': 'Консолидированная позиция культуры',
      Processing: 'Процесс',
      Unloaded: 'Отгружено',
      'Is default': 'по умолчанию',
      'Business Unit': 'Бизнес юнит',
      Author: 'Автор',
      'Last editor': 'Редактор',
      Updated: 'Обновлено',
      Created: 'Создано',
      Yes: 'Да',
      'Table name': 'Название таблицы',
      'Column name': 'Название колонки',
      Visible: 'Видимый',
      Users: 'Пользователи',
      'Form name': 'Имя формы',
      'Field name': 'Название поля',
      Required: 'Обязательное поле',
      title: 'название',
      'Filter level (table)': 'Фильтр таблица',
      Collection: 'Конфигурация',
      'New name': 'Новое название',
      'Can use export button': 'Может использовать кнопку Экспорта таблиц',
      'Can view settings': 'Просмотр настроек',
      'Can view logentry': 'Просмотр истории действий пользователей',
      'Can view users': 'Просмотр списка пользователей',
      'Can view user details': 'Просмотр деталей пользователей',
      'Can edit closed elements': 'Может редактировать закрытые элементы',
      'Can view contracts analytics': 'Просмотр аналитики в контрактах',
      'Can view logistics analytics': 'Просмотр аналитики в логистике',
      'Can view finances analytics': 'Просмотр аналитики в финансах',
      'Can view clients analytics': 'Просмотр аналитики в клиентах',
      'Can view market analytics': 'Просмотр аналитики в рынке',
      'Can view passports analytics': 'Просмотр аналитики в паспорте',
      'Can edit approval status': 'Может редактировать статус апрува',
      'Profile Owner': 'Владелец профиля',
      'Business Unit Filter': 'Фильтр по бизнес юниту',
      'Application language': 'Язык приложения',
      'First day of week': 'Первый день недели',
      'Application color scheme': 'Цветовая схема',
      'Navbar position': 'Позиция навбара',
      'Navbar grouping': 'Расширенная навигация',
      'Sale or purchase deals by default': 'Продажа или закупка по умолчанию',
      'Warehouse type by default': 'Тип склада по умолчанию',
      'Lineup report by default': 'Лайнап по умолчанию',
      'Counterpartiew view by default': 'Отображение контрагентов по умолчанию',
      'Persons view by default': 'Отображение контактов по умолчанию',
      'Tickets view by default': 'Отображение тикетов по умолчанию',
      'Contracts view by default': 'Отображение контрактов по умолчанию',
      'Invoices view by default': 'Отображение инвойсов по умолчанию',
      'Vessels view by default': 'Отображение лодок по умолчанию',
      'Voyages view by default': 'Отображение рейсов по умолчанию',
      'Position report by default': 'Отчет позиция по умолчанию',
      'User Profile': 'Профиль пользователя',
      'Filter by this unit': 'Фильтровать по этому юниту',
      'Is default in forms': 'по умолчанию',
      'Comment text': 'Текст комментария',
      Comments: 'Комментарии',
      'Custom statuses': 'Пользовательские статусы',
      'Start date': 'Дата начала',
      'End date': 'Дата окончания',
      'Is current': 'Текущее',
      Seasones: 'Сезонность',
      'Filter level': 'Уровень фильтра',
      Purpose: 'Назначение',
      'Choose one from: %(keys)s': 'Выберите один из: %(keys)s',
      "Transliteration tag translit available only with lang 'ua' or 'ru'.":
        "Тег транслитерации доступен только для языков 'ua' и 'ru'.",
      'Can not be converted value to number. Example: {{ price | to_float(3) }}, where price must be a number.':
        'Нельзя конвертировать значение в число. Пример: {{ price | to_float(3) }}, где цена должна быть числом',
      'Can not be splitted value with negative number. It should be greater than 0.':
        'Нельзя разделить число с отрицательным значением. Оно должно быть больше 0',
      'Can not split value with split_by. Example: {{ price | split_by(3) }}.':
        'Не можем разделить значение. Пример {{ price | split_by(3) }}.',
      'Syntax error: {}': 'Синтаксическая ошибка: {}',
      'Date should be in the present': 'Дата должна быть в настоящем',
      'Check volume': 'Проверьте объем',
      'Currency rate must be same as currency': 'Платеж и счет должны иметь одинаковою валюту',
      'One user can have only one default business unit':
        'Один пользователь может иметь только один Бизнес Юнит по умолчанию',
      '{} matching query ({}={}) does not exist':
        '{} соответствующий запрос ({} = {}) не существует',
      'There are more than one {} (where {}={}) in the database - {}':
        'В базе данных более одного {} (где {} = {}) - {}',
      'Title eng': 'Название (англ)',
      moisture: 'Влажность, %',
      default: 'по умолчанию',
      'Code name': 'Код название',
      Quality: 'Показатель качества',
      Qualities: 'Показатели качества',
      'Use in templates': 'Использование в шаблонах',
      Tariff: 'Тариф',
      'Crop quality': 'Качество зерна',
      'Crop qualities': 'Показатели качества зерна',
      'Can choose CropQuality': 'Можно выбрать качество культуры',
      Packing: 'Упаковка',
      'container 20 dv, t': 'контейнер, 20',
      'container 40 dv, t': 'контейнер, 40',
      'Packing wagon, t': 'Вагон',
      'Packing car, t': 'Авто',
      'Prices section': 'Секция цен',
      'Is included in position': 'включить в позицию',
      'Charge-off ratio': 'Списание ставка',
      'Default VAT return in contracts for this cargo': 'Возврат НДС по умолчанию для этого товара',
      'Warehouse entry': 'Склад',
      'Warehouse entries': 'Склады',
      Plan: 'План',
      'Crop can not be a parent to itself.': 'Культура не может быть родительской сама для себя',
      'Disbursement bill of lading': 'Расходная накладная',
      'Document title': 'Название документа',
      'Document status': 'Статус документов',
      'Document group name': 'Тег',
      document: 'документ',
      Payments: 'Платежи',
      Reassignment: 'Переназначение',
      Offset: 'Зачет',
      'Disbursement BL': 'Расходная накладная',
      'Bill Of Lading': 'Коносамент ',
      Model: 'Раздел',
      'Choose the block, to which this template will be attached. This means, you might use it only within this block':
        'Выберите блок, к которому прикреплять шаблоны. Это означает, что вы сможете использовать их в рамках этого блока',
      'These is the Title, only you will see, to identify current template':
        'Это Название. Видите его только вы, для того, что бы идентифицировать шаблон',
      'Attach file in docx format to use it when generate a document from object; you also might use tags from the hint':
        'Загрузите файл в формате .docx и используйте его чтобы сгенерировать документ со свойствами объекта; можно использовать теги из подсказок',
      'Document template': 'Шаблон документа',
      'Document templates': 'Шаблоны документов',
      'Unsupported file extension. Valid extensions: ':
        'Неподдерживаемое расширения файла. Поддерживаемые расширения:',
      'Logistics mailing': 'Рассылка логистики',
      'Finances mailing': 'Рассылка (инвойсы)',
      Logistics: 'Логистика',
      Failed: 'Провалено',
      Subject: 'Тема',
      Body: 'Тело письма',
      'Email template group name': 'Тег',
      'Email Template': 'Шаблон рассылки',
      'Email Templates': 'Шаблоны рассылки',
      'Email template': 'Шаблон рассылки',
      'Export params collection': 'Параметры экспорта',
      Mailing: 'Рассылка',
      'Mailing status': 'Статус отправки',
      'Template error: {}': 'Шаблон ошибки: {}',
      'Email sent successfully! Response: {}': 'Письмо успешно отправлено! Ответ: {}',
      'No permissions for changing of finance account': 'Нет прав на изменение финанс аккаунта',
      'Object can not be a child of itself.': 'Объект не может быть родительским сам для себя',
      '{} matching query (id={}) does not exist in the database.':
        '{} соответствующий запрос ({} = {}) не существует в базе данных',
      Paid: 'Оплачено',
      Done: 'Готово',
      Invalid: 'Неактуально',
      'Accrued interest': 'Начисленные проценты',
      'Accrued taxes': 'Начисленные налоги',
      'Subordinated loan': 'Субординированный кредит',
      "Shareholder's equity": 'Собственный капитал:',
      "Shareholder's capital": 'Акционерный капитал',
      'Legal reserve': 'Обязательный резерв',
      Prepay: 'Предоплата',
      Balance: 'Баланс',
      Cargo: 'Зерно',
      Costs: 'Затраты',
      'General expenses': 'Общие затраты',
      Gains: 'Поступления',
      Incoming: 'Входящие',
      Outgoing: 'Исходящие',
      Expense: 'Расходы',
      Income: 'Доход',
      Debit: 'Дебет',
      'Will be used in calculator': 'Будет использовано в калькуляторе',
      'Tariff Type': 'Тип тарифа',
      Auto: 'Авто',
      Railway: 'Ж/д',
      Freight: 'Фрахт',
      'Chose specific tariff type.': 'Выберите тип тарифа',
      Storage: 'Хранение',
      Receiving: 'Приемка',
      Drying: 'Сушка',
      Cleaning: 'Очистка',
      Loading: 'Отгрузка',
      'Chose specific tariff to map it with this charge.':
        'Выберите тип тарифа для того чтобы связать его с этой затратой',
      'Choose the type of counterparty, to which this charge will be\n            linked to.':
        'Выберите тип контрагента, к которому эта затрата будет привязана',
      'Use this charge as passport_multiplicator for passport':
        'Используйте эту затрату как passport_multiplicator  для паспорта',
      'Finance account': 'Финанс счет',
      Charges: 'Затраты',
      'General expenseses': 'Общие затраты',
      'Other activity': 'Другое назначение',
      'Other activities': 'Другое назначение',
      'Invoice #': 'Номер инвойса',
      'Date of receiving': 'Дата получения',
      'Date of certification': 'Дата сертификации',
      'Date of payment (plan)': 'Дата платежа (план)',
      'Date of payment': 'Дата платежа',
      'Date of withdrawal': 'Дата выдачи (документов)',
      'Counterpaty payment info': 'Контрагента платеж информация',
      Condition: 'Условие',
      'Own money': 'Собственные средства',
      'Amount USD': 'Сумма, $',
      'Payment rate': 'Курс оплаты',
      'Delivery status': 'Отгрузка статус',
      'Cargo not delivered': 'Груз не доставлен',
      'Role from': 'От кого',
      'Role to': 'Кому',
      'Not pay': 'Не платить',
      'Secret Invoice': 'Секретный инвойс',
      'Don`t validate counterparties': 'Не валидировать контрагентов',
      'Related credit note': 'Связ кредит ноут',
      Invoices: 'Инвойсы',
      'Can see and create secret invoice': 'Просмотр секретных инвойсов',
      'Commission USD': 'Комиссия дол',
      'Trade Rate': 'Обменный курс',
      'Bank operation': 'Банк операция',
      CreditNote: 'Кредит ноут',
      'Debit finance account': 'Дебет финанс счет',
      'Credit finance account': 'Кредит финанс счет',
      'Commission debit finance account': 'Комиссия дебет финанс счет',
      'Commission credit finance account': 'Комиссия кредит финанс счет',
      'Payment status': 'Статус оплаты',
      'No single vessel with charter party date': 'Ни одной лодки с датой чартер партии',
      PaymentPlan: 'План оплат',
      'Can approve Payment plan': 'Может подтверждать планы оплат',
      'Invoice value': 'Инвойс сумма',
      'Payment Plan Finance': 'План оплаты инвойс',
      'Symbol/Code': 'Символ/Код',
      'Is local': 'Локальная',
      Currencies: 'Валюты',
      Rate: 'Ставка',
      'Load source': 'Загрузить источник',
      'Weighted AVG from payments': 'Средневзвешенный курс по платежам',
      'payment condition': 'условия платежа',
      Account: 'Аккаунт',
      'Bank name Eng': 'Полное название (Англ)',
      'Bank code': 'Банк код',
      IBAN: 'IBAN',
      Swift: 'Свифт',
      'Address Eng': 'Юридический адрес (англ)',
      'Corr bank': 'Банк-корреспондент',
      'Corr IBAN': 'Корр. IBAN',
      'Corr swift': 'Корр свифт',
      'Corr account': 'Корр аккаунт',
      'Corr bank 1': 'Банк корреспондент 1',
      'Corr IBAN 1': 'Корр IBAN 1',
      'Corr SWIFT 1': 'Корр. SWIFT 1',
      'Corr Account 1': 'Корр. счет 1',
      'Corr bank 2': 'Банк корреспондент 2',
      'Corr IBAN 2': 'Корр IBAN 2',
      'Corr SWIFT 2': 'Корр. SWIFT 2',
      'Corr Account 2': 'Корр. счет 2',
      'Account currency': 'Валюта счета',
      'payment information': 'платежная информация',
      'Payment information': 'Платежная информация',
      Cash: 'Наличные',
      Regular: 'Обычный',
      'Account type': 'Тип счета',
      'Bank name': 'Банк',
      'Account name': 'Имя аккаунта',
      'Initial cash': 'первоначальная сумма',
      'Account initial date': 'Дата создания аккаунта',
      'Payment Info': 'Платеж инфо',
      'Bank account': 'Банковский счет',
      'Bank accounts': 'Банк счета',
      'Can change initial amount': 'Может менять первоначальную сумму',
      Passive: 'Пассив',
      'Payment type': 'Метод оплаты',
      'Finance Account': 'Финанс счета',
      'Can configurate finblock entries': 'Может конфигурировать записи финблока',
      Description: 'Описание',
      'Debit account': 'Дебет счет',
      'Credit account': 'Кредит счет',
      Use: 'Использование',
      InvoicePosition: 'Инвойс позиция',
      Quantity: 'Количество',
      'Transation date': 'Дата транзакции',
      'From account': 'Со счета',
      'From counterparty': 'От контрагента',
      'To counterparty': 'К контрагенту',
      'To account': 'На счет',
      'Invoice position': 'Инвойс позиция',
      'Credit bank account': 'Кредит банк счет',
      'Debit bank account': 'Дебет банк счет',
      'Operation Type': 'Тип операции',
      Counterparty: 'Контрагент',
      'Related counterparty': 'Связанный контрагент',
      'Credit note #': 'Кредит ноут №',
      Liabilities: 'Пассивы',
      Role: 'Роль',
      'Secret credit note': 'Секретный кредит ноут',
      'credit note': 'кредит ноут',
      'Credit notes': 'Кредит ноуты',
      'Credit note': 'Кредит ноут',
      'credit note position': 'кредит ноут позиция',
      'Credit note positions': 'Кредит ноут позиции',
      Month: 'Месяц',
      'Budget Item': 'Бюджет',
      'Has docs copy': 'Есть копия доков',
      'Has docs original': 'Есть оригинал доков',
      'Tax return registered': 'Возврат НДС зарегистрирован',
      'SAP registered': 'SAP зарегистрирован',
      'Amount correction': 'Корректировка суммы',
      'Reassignment rate': 'Переуступка ставка',
      'Parent Reassignment': 'Переуступка (основная)',
      Reassignments: 'Переназначение',
      'Disbursement bill or lading': 'Расходная накладная',
      'Reassignment amount': 'Переуступка сумма',
      'Reassignment Position': 'Переназначение',
      'Reassignment Positions': 'Переуступка позиции',
      Offsets: 'Зачеты',
      'Finance offset': 'Зачет',
      DisbursementBL: 'Расходная накладная',
      'Offset Position': 'Позиция зачета',
      'Offset Positions': 'Позиции Зачета',
      'Finance Purpose': 'Финансовая цель',
      'Finance Purposes': 'Финансовые цели',
      Lender: 'Кредитор',
      Borrower: 'Заемщик',
      'Margin, %': 'Маржа, %',
      'Libor, %': 'Libor, %',
      'Related fees': 'Связанные комиссии',
      Notes: 'Примеч',
      'Collateral, %': 'Обеспечение, %',
      'Finance purpose': 'Финансовая цель',
      'Related deal': 'Связанная сделка',
      'Destination country': 'Страна назначения',
      'Start date (plan)': 'Дата начала (план)',
      'Maturity date (plan)': 'Срок платежа (план)',
      'Maturity date': 'Срок платежа',
      'Interest payment date (plan)': 'Дата оплаты процентов (план)',
      'Interest payment date': 'Дата оплаты процентов',
      Loan: 'Займ',
      Loans: 'Займы',
      'Finance Bank': 'Финансы банк',
      'Finance Banks': 'Финансы банки',
      'Invoice Position Config': 'Настройка инвойс позиций',
      'inv.': 'инв',
      '"Use" filter value is empty or improper.': 'Значение «Use» фильтра пустое или неправильное.',
      AVG: 'СРЕДНЕЕ',
      Save: 'Сохранить',
      Delete: 'Удалить',
      'created new Invoice': 'Создано новый инвойс',
      'Invoice date': 'Дата инвойса',
      'Rate must be greater than 0': 'Ставка должна быть больше 0',
      'Amount must be not 0': 'Сумма не должна быть 0',
      'Too long value': 'Слишком длинная сумма',
      "You can't to pay more than invoice's amount. PAYMENT_DOES_NOT_EXCEED_INVOICE is True":
        'Вы не можете оплатить больше суммы инвойса. Включена настройка PAYMENT_DOES_NOT_EXCEED_INVOICE',
      'Contracts in positions must be with the same counterparties.':
        'Контракты в инвойс позиции должны содержать одинаковых контрагентов',
      'Plan amount sum exceeds invoice amount.': 'Плановая сумма превышает объем инвойса',
      'This supplier is not a distributor.': 'Этот поставщик не является дистрибьютором',
      'You can`t edit Invoice Position with closed contract.':
        'Нельзя редактировать Инвойс позицию, когда контракт закрыт',
      'You can`t change use of Invoice Position with use costs or gains.':
        'Нельзя изменить назначение инвойс позиции при назначении доходы/затраты',
      "Wrong use 'costs' with invoice type 'outgoing'":
        'Неправильное использование назначения затраты с типом инвойса исходящий',
      "Wrong use 'gains' with invoice type 'incoming'":
        'Неправильное использование назначения доходы с типом инвойса входящий',
      'Duplicated scenarios.': 'Дублированные сценарии',
      '{role} {name} is not approved (or approved condition)':
        '{role} {name} не подтверждена (или подтверждена с условием)',
      Countries: 'Страны',
      'Phone code': 'Телефонный код',
      Districts: 'Районы',
      Delivery: 'Доставка',
      'Nothing to delete': 'Нечего удалять',
      'File has already been imported': 'Текущее состояние уже импортировано',
      FAS: 'FAS',
      CFR: 'CFR',
      EXW: 'EXW',
      CPT: 'CPT',
      CIF: 'CIF',
      FOB: 'FOB',
      DDP: 'DDP',
      DAP: 'DAP',
      CIP: 'CIP',
      DAT: 'DAT',
      FCA: 'FCA',
      Handysize: 'Хэндисайз',
      Handymax: 'Хэндимакс',
      Seawaymax: 'Сивэймакс',
      Aframax: 'Афрамакс',
      Suezmax: 'Суэцмакс',
      Panamax: 'Панамакс',
      'Post-Panamax': 'Пост-панамакс',
      Malaccamax: 'Малаккамакс',
      Capesize: 'Кэйпсайз',
      'Volume (depart, t)': 'Объем отправленый ГТД. (т)',
      'Vehicle types': 'Тип транспорта',
      'Vehicle (number)': 'Транспорт (номер)',
      'Vehicle (number) alt': 'Номер т\\с (прицеп)',
      Revision: 'Ревизия',
      Imported: 'Импортировано',
      'File ID': 'Файл ID',
      'English name': 'Англ. название',
      'Full name (eng)': 'Полное название (Англ.)',
      'Bank details': 'Банковские реквизиты',
      'Bank details changed': 'Банковские реквизиты (изм.)',
      'Acceptance certificate': 'Сертификат о приемке',
      'Logistic bill of lading': 'Логистика коносамент',
      'Logistic bills of lading': 'Логистика коносаменты',
      Car: 'Авто',
      Wagon: 'Вагон',
      Container: 'Контейнер',
      Vessel: 'Лодка',
      'Volume received': 'Объем (полученный)',
      'Volume departed': 'Объем (отправленый)',
      'Volume departed consignment': 'Объем (по ТТН)',
      'Volume departed real': 'Объем принятый',
      'Volume boarded': 'Объем погруженый',
      Main: 'Главная',
      'Loading (date)': 'Отгрузка (дата)',
      'Start usage (date)': 'Начало использ (дата)',
      'End usage (date)': 'Конец использ (дата)',
      'Consignment (#)': 'Накладная (№)',
      'Volume (depart consignment, t)': 'Объем отправленный по ттн. (т)',
      'Volume (received real, t)': 'Объем (полученный реальный (т)',
      'Crop (class)': 'Культура (класс)',
      'Exporter contract (#)': 'Экспортер (номер контракта)',
      'Receiving (date)': 'Приемка (дата)',
      'Sale (date)': 'Продажа (дата)',
      'Volume (receive, t)': 'Объем полученный (т)',
      'Volume (lost, t)': 'Объем (потери, т)',
      'Buyer 90 invoice': 'Покупатель (90%, №)',
      'Buyer 10 invoice': 'Покупатель (10%, №)',
      'Railway (date)': 'ЖД (дата)',
      'Vet (date)': 'Вет (дата)',
      'Quarantine (date)': 'карантин (дата)',
      'Quarantine required': 'Карантин обязателен',
      'Quality (date)': 'Качество (дата)',
      'Declaration (date)': 'Декларация (дата)',
      'Broker confirmation (date)': 'Подтверждение брокера (дата)',
      'Broker confirmation required': 'подтверждение брокера обязательно',
      'EUR 1 (date)': 'EUR 1 (дата)',
      'No balance invoice': 'Нет баланс инвойса',
      'Supplier 90 invoice': 'Поставщик (90%, №)',
      'Supplier 10 invoice': 'Поставщик (10%, №)',
      Penalties: 'Штрафы',
      'Costs in USD': 'Затраты',
      'Penalties in USD': 'Штрафы',
      'Usage cost': 'Затраты на использ',
      'Sale price multiplicator': 'Мультипликатор продажной цены',
      'Loading place': 'Место отгрузки',
      'Discharging place': 'Точка отгрузки',
      'Payment date': 'Дата платежа',
      'Costs vat': 'Затраты НДС',
      'Penalties vat': 'Штрафы НДС',
      'Is FCA': 'FCA',
      'Sale payment conditions': 'Продажа условия платежа',
      'Purchase payment conditions': 'Закупка условия платежа',
      'Result month': 'Результат за месяц',
      'Season year': 'Сезон год',
      'Invoice position for costs': 'Инвойс позиция для затрат',
      'Buyer invoice volume field': 'Покуп инвойс объем поле',
      'Supplier invoice volume field': 'Поставщ инвойс объем поле',
      'Loaded on board': 'Отгружено на борт',
      'Purpose invoicing': 'Цель инвойсирования',
      'Supplier invoiced': 'Поставщик выставил инвойс',
      'Supplier paid': 'Инвойсы от поставщика оплачены',
      'Buyer invoiced': 'Покупателю выставлено инвойс',
      'Buyer paid': 'Покупатель оплатил',
      'Transloader invoiced': 'Перевалка проинвойсирована',
      'Transloader paid': 'Перевалка оплачена',
      Transport: 'Транспорт',
      'Can view logistic certs': 'Просмотр сертификатов по логистике',
      'Can view logistics purchase price': 'Просмотр закупочной цене в транспорте',
      'Can view logistics sale price': 'Просмотр продажной цены в транспорте',
      'Can view logistics finance tab': 'Просмотр вкладки Логистика',
      'Can view logistics road tab': 'Просмотр таба дорога логистики',
      'Can view logistics quality tab': 'Просмотр таба качества логистики',
      'Can view logistics full tab': 'Просмотр полного таба логистики',
      'Can view logistics rentability': 'Просмотр рентабельность в транспорте',
      'Terminal Transshipment': 'перевалка на терминале',
      'Date from': 'Дата с',
      deliverer: 'экспедитор',
      'Departure station': 'Станция отправления',
      'Destination station': 'Станция назначения',
      farm: 'хозяйство',
      'Daily tariff': 'Дневной тариф',
      'Transportation tariff': 'Тариф',
      'Transportation tariffs': 'Тарифы',
      'Can view Tariffs': 'Просмотр списка логистических тарифов',
      'Can change Tariffs': 'Может изменять тарифы',
      Station: 'Станция',
      'Price in USD': 'Цена, $',
      'Transshipment tariff': 'Перевалка тариф',
      'Transshipment tariffs': 'Перевалка тарифы',
      Freights: 'Фрахты',
      'Basis code name for calculation purposes': 'Код базиса для калькуляции',
      'Premium bonus percentage': 'Процент бонуса премии',
      basis: 'базис',
      'Name (short)': 'Короткое название',
      'Prices priority': 'Приоритет цен',
      port: 'порт',
      IMO: 'ИМО',
      Age: 'Возраст',
      Flag: 'Флаг',
      'Marine Traffic link': 'Marine Traffic',
      Vessels: 'Лодки',
      Loaded: 'Отгружено',
      Discharging: 'Выгружается',
      Discharged: 'Выгружено',
      'Voyage name': 'Имя рейса',
      'Charter party (date)': 'Дата чартер партии',
      'Freighted volume (t)': 'Фрахт объем',
      'Sale contracts': 'Продажные контракты',
      'Date of loading start': 'Дата отгрузки старт',
      'Date of loading end': 'Дата отгрузки конец',
      'Date of notice loading': 'Дата нотиса (загрузка)',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'Loading port': 'Порт отгрузки',
      'Destination port': 'Порт назначения',
      'Disch rate': 'Норма выгрузки',
      'Date of discharging start': 'Дата выгрузки старт',
      'Date of discharging end': 'Дата выгрузки старт',
      'Date of notice destination': 'Дата нотиса (выгрузка)',
      'Shortage rate': 'Шортадж ставка',
      'Demurrage rate': 'Дата выгрузки конец',
      'Freight costs': 'Фрахт затраты',
      'Freight broker costs': 'Фрахт брокер затраты',
      'Loading dem/dis': 'Отгрузка дем/дис',
      'Destination dem/dis': 'Выгрузка дем/дис',
      'Additional costs': 'Дополнительные затраты',
      'Delivery condition': 'Вид транспортировки',
      'Loaded volume (t)': 'Объем загруж',
      'Volume discharged': 'Объем отгруженый',
      'Name (long)': 'Название длинное',
      terminal: 'порт (терминал)',
      Direction: 'Направление',
      station: 'станция',
      Stations: 'Станции',
      'Logistic loss': 'Логистика потери',
      'Logistic losses': 'Логистика потери',
      'Place of destination': 'Порт назначения',
      'Places of destination': 'Порт назначения',
      'Can create bills of lading from logistics': 'Может создавать коносаменты с логистики',
      'Ship classification code name for idintification purposes':
        'Код имени класс судна для внутреннего назначения',
      'Ships classification': 'Класс лодки',
      Vet: 'Вет',
      Quarantine: 'Карантин',
      Declaration: 'Декларация',
      'EUR.1': 'Евро 1',
      Document: 'Документ',
      'Type of certificate document': 'Тип сертификата',
      'Logistic Certificate Document': 'Сертификаты логистики документ',
      'Logistic Certificate Documents': 'Сертификаты логистики документы',
      'Transloaded date': 'Дата перевалки',
      'Transloaded volume': 'Объем перевалки',
      'Intermodal Transport': 'Промежуточный транспорт',
      'Intermodal Transports': 'Промежуточный транспорт',
      'Cargo Receiver': 'Получатель груза',
      'Receiver code': 'Код получателя',
      'Shipment Shedule': 'План отгрузки',
      'Shipment Shedules': 'Планы отгрузок',
      CTL: 'ЦТЛ',
      Own: 'Собственные',
      Leased: 'Арендованные',
      'Shipment Schedule': 'План отгрузок',
      'Cargo Shipper': 'Грузоотправитель',
      'rolling stock type': 'тип подвижного состава',
      'Shipment Shedule Position': 'План отгрузок позиция',
      'Shipment Shedule Positions': 'План отгрузок позиция',
      'Shipment Schedule Position': 'План отгрузок позиция',
      Day: 'День',
      Shipped: 'Отгружено',
      'Shipment Schedule Position Day': 'План отгрузок день',
      'Shipment Schedule Position Days': 'План отгрузок дни',
      'Date of issuance': 'Дата выставления',
      Import: 'Импорт',
      Vehicles: 'Транспортные средства',
      Warehouse: 'Склад',
      'Duplicated Logistic Ids: {}, Values: {}': 'Дублированные т/с ID: {}, значения: {}',
      "You can't import with executed contracts: {}":
        'Нельзя импортировать с контрактом, у которого статус "Выполнено" {} ',
      'Required field.': 'Обязательное поле',
      'Consignments deficiency.': 'Дефицит накладных',
      'Google Drive Import': 'Импорт Google Drive',
      'Google Drive Clean': 'Очистка из Google Drive',
      'Booking confirmation': 'Подтверждение бронирования',
      'has its docs cut-off date on': 'установлено дедлайн по документам на ',
      'Plans plan, start, end dates must be in one         month':
        'Дата начала и окончания должны быть в пределах         месяца',
      'Elevator tariff must be unique with crop, charge, date from.':
        'Тариф элеватора должен быть уникальным для продукта, затраты и даты',
      'Supplier nineteen invoice number field does not match with number from imported file':
        'Значение номера инвойса на предоплату от поставщика не совпадает с номером из импортированного файла',
      'Buyer nineteen invoice number field does not match with number from imported file':
        'Значение номера инвойса на предоплату покупателю не совпадает с номером из импортированного файла',
      'Supplier ten invoice number field does not match with number from imported file':
        'Значение номера балансового инвойса от поставщика не совпадает с номером из импортированного файла',
      'Buyer ten invoice number field does not match with number from imported file':
        'Значение номера балансового инвойса покупателю не совпадает с номером из импортированного файла',
      'No sale contract specified for sale date': 'Не выбран продажный контракт для даты продажи',
      'Logistic with this acceptance certificate already exists.':
        'Логистика с прием сертификатом уже сущесствует',
      'Loss should have one warehouse.': 'У потерь должен быть один склад',
      'Object should have one warehouse.': 'Объект должен содержать только один склад',
      'Loss should have same business unit as contract':
        'Потеря должна иметь тот же бизнес юнит что и контракт',
      'Bill of lading must have at least one contract.':
        'Коносамент должен содержать хотя бы один контракт',
      'Disbursement bill of lading can have only logistic or intermediatelogistic, not both.':
        'Расходная накладная может иметь либо логистику либо промежуточную логистику, но не обе одновременно',
      'Contract is required.': 'Контракт обязателен',
      'Can not load logistic to itself.': 'Невозможно подгрузить т/с',
      'Logistics are looped.': 'Логистика зациклена',
      "We can't import finance {} w/o contract {}. You can turn it off with LOGISTICS_IMPORT_CHECK_FINANCES option":
        'Не может импортировать инвойс {} без контракта {}. Можно выключить проверку в настройках LOGISTICS_IMPORT_CHECK_FINANCES',
      "Volume departed ({}t) greater than supplier contract's volume ({}t) (option included). Set {} to False to prevent this validation":
        'Отправленный объем ({}t) превышает объем закупочного контракта ({}t) (даже с учетом опциона)',
      "Logistic.loading_date ({}) can't be earlier than (purchase) contract.date_of_execution ({}) and later than contract.end_of_execution ({})":
        'Дата отгрузки тс ({}) cне должна быть раньше даты начала контракта ({}) и позже его даты окончания ({})',
      'With lookup by vessel vessel_name and buyer_name must be set':
        'Для сопоставления по лодке название лодки и имя покупателя должно быть заполнено',
      'Approval created': 'Согласование создано',
      'Approval updated': 'Согласование обновлено',
      'Client created': 'Создан контрагент',
      'Client status changed': 'Статус клиента изменен',
      'Client approving finished': 'Подтверждение контрагента завершено',
      'ClientCommentary created': 'Комментарий по клиенту создан',
      'Purchase contract created': 'Создан закупочный контракт',
      'Purchase contract approving finished': 'Согласование закупочного контракта финализировано',
      'Request created': 'Тикет создан',
      'Request updated': 'Тикет обновлен',
      'Request saved': 'Тикет сохранен',
      'Request approving started': 'Подтверждение тикета началось',
      'Request approving finished': 'Подтверждение контрагента завершено',
      'Sale contract created': 'Создан контракт на продажу',
      'Sale contract created in usd': 'Создан контракт на продажу в дол.',
      'Sale contract approving finished': 'Согласование контракта на продажу финализировано',
      'General Agreement approving finished': 'Согласование Общего соглашения финализировано',
      'Consignment created': 'Накладная создана',
      'Comment created': 'Создан комментарий',
      'Approver commented request': 'Подтверждающий прокомментировал тикет',
      'Passport custom status changed': 'Уведомления на изменение кастомного статуса паспорта',
      'Passport created': 'Паспорт создан',
      'Passport updated': 'Пасспорт обновлен',
      'Passport approving finished': 'Подтверждение паспорта завершено',
      'Finance approving finished': 'Согласование Инвойса финализировано',
      'Finance created': 'Инвойс создан',
      'Finance updated': 'Инвойс обновлен',
      'PaymentPlan created': 'План оплат создан',
      'PaymentPlan updated': 'План оплат обновлен',
      'PaymentPlan approving finished': 'План оплаты инвойс',
      'Voyage created': 'Рейс создан',
      'Voyage updated': 'Рейс обновлен',
      'Voyage ETA today': 'ETA лодок сегодня',
      'Bill Of Lading created': 'Коносамент создан',
      'Logistic import finished': 'Импорт логистики завершен',
      'Logistic created': 'Логистика создана',
      'Append objects url to body text': 'Добавлять гиперссылку объекта к телу письма',
      'Include Approve/Decline action buttons': 'Добавлять кнопки Подтвердить/Отклонить',
      "Header values can't contain newlines": 'Заголовки не могут содержать новые строки',
      Manager: 'Менеджер',
      Create: 'Создано',
      Edit: 'Редактировать',
      'Final confirmation': 'Финальное подтверждение',
      'Custom status change': 'Пользовательские статусы',
      Info: 'Инфо',
      Warning: 'Внимание',
      Error: 'Срочно',
      'Company code': 'Код',
      Roles: 'Роли',
      user: 'пользователь',
      phone: 'телефон',
      'New contracts': 'Новые контракты',
      Home: 'Домой',
      'Shipment period': 'Период исполнения',
      was: 'был',
      'Following users have to approve object №':
        'Следующие пользователи должны согласовать объект',
      'Following users have to approve invoice №':
        'Следующие пользователи должны согласовать инвойс',
      'Approval required for the ticket №': 'Необходимо согласование по тикету',
      'Approval required for the passport': 'Подтверждение необходимо для этого паспорта',
      'Approval required for the contract №': 'Подтверждение необходимо для этого контракта №',
      'GrainTrack. Approval required for the contract №':
        'Грейнтрек. Подтверждение необходимо для этого контракта №',
      'Approval required for the client': 'Необходимо согласование по клиенту',
      'Approval required for the invoice №': 'Подтверждение необходимо для этого инвойса №',
      'Approval required for the object №': 'Подтверждение необходимо для этого объекта №',
      'Approval updated for the ticket №': 'Согласование обновлено по тикету',
      'Approval updated for the passport': 'Подтверждение обновлено для паспорта',
      'Approval updated for the client': 'Обновление по согласованию клиента',
      'Approval updated for the invoice №': 'Подтверждение обновлено для этого инвойса №',
      'Approval updated for the object №': 'Подтверждение обновлено для этого объекта №',
      'Approver commented on the request №': 'Подтверждающий прокомментировал тикет №',
      'GrainTrack. Final approve for the client':
        'Грейнтрек. Финальное подтверждение для контрагента',
      'created a new сompany': 'создана новая компания',
      'was commented by user': 'прокомментировано пользователем',
      'GrainTrack. Сomment was created in the  client':
        'Грейнтрек. Кто-то прокомментировал контргента',
      'Ticket №': 'Тикет',
      'Contract №': 'Контракт',
      'commented by user': 'прокомментировано пользователем',
      'GrainTrack. Сomment was created in the request №':
        'Грейнтрек. Кто-то прокомментировал тикет',
      'GrainTrack. Сomment was created in the  passport':
        'Грейнтрек. Кто-то прокомментировал паспорт',
      'GrainTrack. Сomment was created in the contract №':
        'Грейнтрек. Кто-то прокомментировал контракт №',
      'GrainTrack. Сomment was created in the  invoice №':
        'Грейнтрек. Кто-то прокомментировал инвойс №',
      'GrainTrack. Сomment was created in the  object №':
        'Грейнтрек. Кто-то прокомментировал объект',
      'GrainTrack. Final approve for the invoice №':
        'Грейнтрек. Финальное подтверждение для инвойса №',
      'created new invoice': 'Создано новый инвойс',
      'GrainTrack. Final approve for the passport':
        'Грейнтрек. Финальное подтверждение для паспорта',
      'created new contract': 'Создан новый контракт',
      'GrainTrack. Final approve for the contract №':
        'GrainTrack. Финальное подтвердение по контракту',
      'after unloading the auto, the balance after the GCD.':
        'после разгрузки авто, баланс после ГТД',
      'GrainTrack. Final approve for the ticket №':
        'Грейнтрек. Финальное подтверждение для тикета №',
      'GrainTrack. Started approving for the ticket №':
        'Грейнтрек. Началось подтверждение для тикета №',
      'Created a new ticket №': 'создан новый тикет',
      'has edited ticket': 'тикет изменен',
      'created new USD contract': 'Создан новый контракт в дол.',
      'created a new task': 'создана новая задача',
      'updated a task': 'Обновить задание',
      'Volume Departed': 'Объем (отправленый)',
      'Volume Received': 'Объем полученный',
      'Volume Departed Real': 'Объем (отправленый)',
      'Volume property': 'Показатель',
      'Current progress': 'Текущий прогресс',
      'Details of trade confirmation': 'Детали трейдерского подтверждения',
      'Transport fact': 'Факт по транспорту',
      'Charge fact': 'Затрата факт',
      'Docs fact': 'Док факт',
      'Offers warehouse': 'Оффер как склад',
      'Warehouse deviation, %': 'Отклонение качества, %',
      'Custom margin': 'Настраиваемая маржа',
      'credit period (days)': 'кредитный период',
      'credit percent': 'процент по кредиту',
      'permanent costs': 'постоянные затраты',
      taxes: 'налоги',
      'vat return rate for costs': 'Ставка возврата НДС для затрат',
      Multipassport: 'Мультипаспорт',
      Multipassports: 'Мультипаспорта',
      'Multipassport ': 'Мультипаспорт',
      'Derivative account': 'Дериватив аккаунт',
      'margin selector': 'переключатель маржи',
      tiket: 'тикет',
      contract: 'контракт',
      execution: 'исполнение',
      invoice: 'инвойс',
      payment: 'оплата',
      'Execution costs from invoices': 'Учитывать инвойсы для затрат в марже по исполнению',
      'Business (date)': 'Бизнес (дата)',
      Passports: 'Паспорта',
      'Can view passport result': 'Просмотр результата в паспорте',
      'This fact is done': 'Факт выполнен',
      'Passport and contract have different cargo': 'У паспорта и контракта разные культуры',
      'Ticket margin': 'Маржа по тикетам',
      'Ticket margin per ton': 'Маржа по тикетам за тонну',
      'Contract margin': 'Маржа по контрактам',
      'Contract margin per ton': 'Маржа по контрактам за тонну',
      'Execution margin': 'Маржа по исполнению',
      'Execution margin per ton': 'Маржа по исполнению за тонну',
      'Invoice margin': 'Маржа по инвойсам',
      'Invoice margin per ton': 'Маржа по инвойсам за тонну',
      'Payment margin': 'Марда платежа',
      'Payment margin per ton': 'Маржа по оплатам за тонну',
      'Margin Control': 'Контроль маржи',
      'Margin Controls': 'Контроль маржи',
      'This contract cannot be connected with this volume to passport.':
        'Контракт не может быть привязан с этим объемом к паспорту',
      'The number of passports is not specified, or more than five.':
        'Количество паспортов не указано, или больше пяти',
      'Coming to load': 'Прибывает на погрузку',
      'At load port / Under loading': 'Порт отгрузки',
      'Sailed / under way to discharge': 'Отчалил / в пути на разгрузку',
      'At discharging port / under discharge': 'В порту назначения / разгружается',
      'W/o status': 'Без статуса',
      'This number is input by hand': 'Это число введено вручную',
      'Cash Flows': 'Денежный поток',
      'Can see cashflow recreation modal': 'Может смотреть кешфло',
      'Can view reports': 'Просмотр отчетов',
      'Can view debit-credit reports': 'Просмотр отчета дебет кредит',
      'Can view position report': 'Просмотр отчета по позиции',
      'Can view position origination report': 'Может просматривать позицию ориджинейшн',
      'Can view position physical report': 'Просмотр отчета по позиции',
      'Can view confirmations report': 'Просмотр отчета по подтверждениям',
      'Can view signings report': 'Просмотр отчета по подписаниям',
      'Can view kpiconfirmations report': 'Просмотр отчета по KPI подтверждениям',
      'Can view kpisignings report': 'Просмотр отчета по KPI подписаниям',
      'Can view balance report': 'Может просматривать отчет баланс',
      'Can view budgets report': 'Просмотр отчета бюджеты',
      'Can view purchase plan report': 'Просмотр отчета по плану закупке',
      'Can view lineup report': 'Просмотр отчета по лайнапу',
      'Can view futures position report': 'Может просматривать фьючерс позицию отчет',
      'Can view certificate dates report': 'Может просматривать отчет по датам сертификации',
      'Can view execution of loyalty programs report': 'Просмотр отчета по программам лояльности',
      'Can view Projects Status Detailed Summary Report':
        'Может просматривать Сводный отчет по статусам проэктов',
      'Can view P&L report': 'Просмотр ПНЛ отчета',
      'Can view P&L per passport report': 'Просмотр отчета ПнЛ по паспорту',
      'Can view P&L mark to market report': 'Просмотр отчета ПнЛ МТМ',
      'Can view forward client status mtm report':
        'Может просматривать отчет мтм по форвардным сделкам ',
      'Staff report': 'Отчет по пользователям',
      User: 'Пользователь',
      'Contracts count': 'Контрактов',
      'Indicators count': 'Индикаторов',
      'Tickets count': 'Тикетов',
      'Clients count': 'Контрагентов',
      'Roles count': 'Ролей',
      'Logistics count': 'Транспорта',
      'Plans count': 'План счет',
      'Invoices count': 'Инвойсов',
      'Payments count': 'Платежей',
      'Passports count': 'Паспортов',
      'Potentials count': 'Счетчик потенциалов',
      'Updates count': 'Обновлений',
      'Tasks count': 'Заданий',
      'On site seconds': 'Секунд на сайте',
      TOTAL: 'ВСЕГО',
      'Invalid date format, use YYYY-MM-DD': 'Неправильный формат даты, используйте ГГГГ-ММ-ДД',
      'Contract number': 'Номер контракта',
      Signed: 'Подписан',
      Daily: 'Ежедневный',
      Action: 'действие',
      Object: 'Объект',
      'usd/t': 'дол/т',
      'cent/b': 'центы/бушели',
      'eur/t': 'евро/т',
      Futures: 'Фьючерсы',
      Barchart: 'Барчат',
      Agritel: 'Агритель',
      Ticker: 'Тикер',
      'Stock Exchange': 'Биржевый курс',
      'Expiration date': 'Дата экспирации',
      'A date date until which the derivative is valid':
        'Дата до которой действительно значение дериватива',
      'Derivative type': 'Тип дериватива',
      'Derivative month': 'Месяц дериватива',
      'A month when derivative will be relevant': 'Месяц когда фьючерс будет релевантен',
      'Lot size': 'Размер лота',
      'Point value': 'Позиция точки',
      'Service Charge': 'Комиссия за услуги',
      'Exch Fees': 'Комиссии обмена',
      'NFA Fees': 'NFA комиссия ',
      'cost per lot': 'Затраты за 1 лот',
      'Initial amount': 'Первоначальная сумма',
      'Price date': 'Дата цены',
      'A date for which the price is assigned': 'Дата на которую назначена цена',
      Long: 'Лонг',
      Short: 'Шорт',
      Put: 'Put',
      Fixing: 'Fixing',
      Rolling: 'Подвижной',
      Hedging: 'Хеджирование',
      'Paper trade': 'Бумажная торговля',
      'Deal type': 'Тип сделки',
      'Option type': 'Тип опции',
      'Operation type': 'Тип операции',
      'Derivative Account': 'Дериватив аккаунт',
      'Quantity of lots': 'Количество лотов',
      'Business date': 'Бизнес дата',
      'Virtual operation': 'Виртуальная операция',
      'Tariff correction': 'Коррекция тарифа ',
      'Consolidated type': 'Тип консолидации',
      Resource: 'Загрузить источник',
      'Parsing enabled': 'Парсинг включен',
      'Quotes Parsing Config': 'Конфигуратор парсинга котировок',
      'Quotes Parsing Configs': 'Конфигуратор парсинга котировок',
      Measurement: 'Единица измерения',
      Measurements: 'Единицы измерения',
      coefficient: 'коэффициент',
      'Measurement Crop': 'Единица измерения товара',
      'Measurement Crops': 'Единицы измерения товара',
      'There can be only one chosen StockMarketPrice per day and derivative':
        'Может быть выбрана только одна цена, дериватив и биржа в день',
      'There is no parsers for this resource': 'Для этого ресурса нет парсинга',
      'Parsing error. No quote for this resource and ticker: ':
        'Ошибка парсинга. Нет котировки для этого ресурса и тикера',
      'In process': 'Процесс',
      'Exporter approval (docs sent buyer)': 'Экспортер подтв (доки отправили)',
      Additional: 'Примечание',
      Cancellation: 'Отмена',
      'Abstract Stages': 'Абстрактные стадии',
      'Signature (draft)': 'Подпись (драфт)',
      'We signed': 'Мы подписали',
      'Both signed': 'Подписан',
      'All signed': 'Все подписали',
      'Originals received': 'Оригиналы получены',
      'Cargo confirmation': 'Подтверждение груза',
      'Payment confirmation': 'Подтверждение платежа',
      Graintrack: 'Грейнтрек',
      'Graintrack. Admin panel': 'Грейнтрек. Панель администратора',
      'Welcome,': 'Добро пожаловать,',
      Documentation: 'Документация',
      'Change password': 'Поменять пароль',
      'Log out': 'Выйти',
      'To EXPORT something, first, choose the format of the file and then press SUBMIT button. This will save the file on your local drive ':
        'Для того чтобы ЭКСПОРТИРОВАТЬ  что-то, выберите формат файла и нажмите кнопку ПОДТВЕРДИТЬ. После этого файл будет сохранен у вас на компьютере',
      Submit: 'Подтвердить',
      'Require to have a business unit for validation logistics, logistics contracts and logistic invoices':
        'Бизнес юнит обязателен для валидации логистики, контрактов, инвойсов',
      "Below is a preview of data to be imported. If you are satisfied with the results, click 'Confirm import'":
        'Это предварительный просмотр того, что вы собираетесь импортировать. Просмотрите внимательно, все ли вгрузилось как нужно и подтвердите действие',
      'Confirm import': 'Подтверждение импорта',
      'This importer will import the following fields: ': 'Шапка файла:',
      'You have not Business Unit. Make sure you have at least one business unit.':
        'У вас нет Бизнес юнита. Убедитесь, что есть хотя бы один и возвращайтесь',
      Errors: 'Ошибки',
      'Line number': 'Номер строки',
      'Some rows failed to validate': 'Некоторые строки не удалось проверить',
      'Please correct these errors in your data where possible, then reupload it using the form above.':
        'Пожалуйста, исправьте эти ошибки в своих данных, где это возможно, а затем повторно загрузите их, используя форму выше.',
      Row: 'Ряд',
      'Non field specific': 'Не зависит от поля',
      Preview: 'Предварительный просмотр',
      application: 'заявка',
      model: 'модель',
      Add: 'Добавить',
      Change: 'Изменить',
      View: 'Просмотр',
      'Last update': 'Последнее обновление',
      'Receiving date': 'Дата получения',
      'Loading date': 'Дата отгрузки',
      'Second name': 'Фамилия',
      'Conclusion date': 'Дата заключения',
      Line: 'Линия',
      Bar: 'Столбец',
      Alphabet: 'Алфавит',
      Smart: 'Смарт',
      'By numbers': 'Числ',
      'Broker confirm': 'Брокер подтвержден',
      'N/A': 'N/A',
      'Consignment number, vehicle number': 'Номер накладной, номер тс',
      'Consignment number, vehicle number, loading date':
        'Номер накладной, номер тс, дата отгрузки',
      'Vehicle number, loading date': 'Номер тс, дата отгрузки',
      KG: 'КГ',
      T: 'Т',
      OR: 'ИЛИ',
      AND: 'И',
      'Short name': 'Короткое название',
      'Short title': 'Короткое название',
      Boat: 'Судно',
      Forbid: 'Запрещено',
      Warn: 'Предупр',
      Contracts: 'Контракты',
      'Carbon Copy emails, comma separated': 'Копия, email - через запятую',
      'Blind Carbon Copy emails, comma separated': 'Скрытая копия, email - разделять запятой',
      'Uses for recipients filter': 'Используется для поиска получателей',
      'SMTP port. Not secure - 25, GMail - 587 or read documentation':
        'Порт SMTP. Небезопасный - 25, GMail - 587, детали в документации',
      'SMTP usermane. Email sender': 'SMTP логин. Отправитель Email',
      "SMTP password. Stores encrypted. Don't edit.":
        'Пароль SMTP. Хранится в зашифрованном виде. Не редактировать',
      'Check and remove duplicates from our database':
        'Проверьте и удалите дубликаты из нашей базы',
      'Create qualition on import': 'Создавать качество логистики при импорте',
      'Check if logistic volume sum less, than contract volume. YES - prevent import, NO - notification only':
        'Отметьте, когда объем логистики меньше, чем объем контракта. ДА- не импортировать, НЕТ - только уведомлять',
      'Check if logistic volume departed less, than contract volume and prevent import':
        'Отметьте, когда объем логистики меньше, чем объем контракта и вы хотите предотвращать импорт',
      'Trying to find the buyer_contract by related vessel and buyer names':
        'Пытаемся сопоставить продажный контракт по названию лодки и имени покупателя',
      'Creates deliverers on import': 'Создает экспедитора при импорте',
      'Creates stations on import': 'Создает станции при импорте',
      'Use resource of the NBU to fetch currency': 'Использовать парсинг валюты с НБУ',
      'Use resource of the RBC to fetch currency': 'Использовать парсинг валюты с РБК',
      'Use resource of the nationalbank.kz to fetch currency':
        'Использовать парсинг валюты с nationalbank.kz',
      'Parse quotes configures in quote parsing configs.':
        'Парсинг котировок настраивается в конфигах парсинга котировок.',
      'Use resource of the Interbank (buy) to fetch currency':
        'Использовать парсинг валюты с Межбанка (покупка)',
      'Use resource of the Interbank (sale) to fetch currency':
        'Использовать парсинг валюты с Межбанка (продажа)',
      'Use resource of the ecb.europe to fetch currency': 'Использовать парсинг валюты с ECB',
      'Use resource of the cbr.ru to fetch currency': 'Использовать парсинг валюты с CBR',
      'Use resource of the NBU to fetch currency for a next day':
        'Использовать парсинг валюты с НБУ',
      'Logistic Google Drive file ID':
        'Проверьте настройку LOGISTIC_FILE_GOOGLE_ID в НАСТРОЙКАХ ОРГАНИЗАЦИИ',
      'Intermediate logistic Google Drive file ID': 'Промежуточная логистика Google Drive file ID',
      'Show opened passports in conslidated profit and loss':
        'Показывать детализацию паспортов в отчете ПНЛ',
      'Show VAT everywhere': 'Использовать НДС',
      'Show return on sales': 'Использовать рентабельность продаж',
      'Show return on equity': 'Использовать рентабельность собственного капитала',
      'Show margin by tickets in passport results': 'Показать маржу в паспорте по тикетам',
      'Show margin by contracts in passport results': 'Показать маржу в паспорте по контрактам',
      'Show margin by logistics in passport results': 'Показать маржу в паспорте по логистике',
      'Show margin by invoices in passport results': 'Показать маржу в паспорте по инвойсам',
      'Show margin by payments in passport results': 'Показать маржу в паспорте по оплатам',
      'offer role': 'роль',
      'Tariff auto active': 'Авто тариф актив',
      'Tariff rw active': 'ЖД тариф актив',
      'Elevator active': 'Перевалка актив',
      'Freight active': 'Фрахт актив',
      'Approval expiration time, in hours': 'Срок действия утверждения, в часах',
      'Allow only the same crops or child crops': 'Разрешать только одинаковые культуры',
      'Allow connect not more than contract volume':
        'Не разрешать привязывать больше чем контрактный объем',
      'Enable filter for consolidated table ': 'Включить фильтр для консолидированной таблицы ',
      'Change view of FOB position "Load" modal':
        'Изменить отображение ФОБ позиции Погрузка в судно',
      "Logistic's loading_date must be between date_of_execution and end_of_execution its supplier_contract":
        'Дата отгрузки тс должна быть в периоде исполнения контракта',
      'Prevents Logistics Import while there are duplicated records in the DB by LOGISTICS_MATCHING_FIELDS':
        'Предотвращает импорт логистики при наличии дублированных записей в Базе Данных с помощью LOGISTICS_MATCHING_FIELDS',
      'Skip import unchanged rows (may work incorrectly with some kind of fields like qualities or related objects)':
        'Пропустить импорт неизмененных строк (может некорректно работать с некоторыми полями, такими как качества или связанные объекты)',
      'Unique logistics acceptance certificate': 'Уникальная логистика прием сертификат',
      'Dont validate the same counterparties from contracts in invoice positions':
        'Не валидировать на одинаковых контрагентов из контрактов в инвойс позициях',
      'Enable inline add in tables': 'Включить встроенное добавление в таблицы',
      'Require for terminal in purchase contract for certain basises':
        'Обязательно указать терминал в контракте купли-продажи для указанного базисов',
      'Use costs for execution from invoices':
        'Использовать инвойсы для затрат в марже по исполнению',
      'Suppress request_saved event for back_to_back':
        'Не отсылать уведомление про создание тикета при бэкенда ту бэкенда сделках',
      'Require for destination with basies CIF, FOB, CFR':
        'Сделать обязательным поле “назначение” для CIF, FOB, CFR',
      'Document file format to download from template': 'Формат документа для загрузки из шаблона',
      'Simplify main tables removing tabs': 'Упростить основные таблицы, убрав вкладки',
      'Removing negotiation from charges': 'Убрать переговорный процесс в затратах',
      'Models in the %(name)s application': 'Models in the %(name)s application',
      '%(name)s': '%(name)s',
      "You don't have permission to edit anything.": 'У вас нет разрешения изменять это',
      'Recent Actions': 'Последние действия',
      'My Actions': 'Мои действия',
      'None available': 'Недоступно',
      'Unknown content': 'Неизвестные данные',
    });
    gettextCatalog.setStrings('uk', {
      '0': '0',
      '1': '1',
      '2': '2',
      '3': '3',
      '2017': '2017',
      '2018': '2018',
      '2019': '2019',
      '2020': '2020',
      '2021': '2021',
      '2022': '2022',
      '2023': '2023',
      '#': '№',
      '# of containers': '№ контейнерів',
      $: 'usd',
      '$/20dv': 'usd/20dv',
      '$/40dv': 'usd/40dv',
      '$/day': 'usd/день',
      '$/t': 'usd/т',
      '%': '%',
      '% from the deal': '% від угоди',
      '&gt; 105%': '&gt; 105%',
      '(Boat required)': '(Потрібен корабель)',
      '(purchase)': '(закупівля)',
      '(sale)': '(продаж)',
      '+/- 0-50': '+/- 0-50',
      '+0 0 (000)000-00-00': '+0 0 (000)000-00-00',
      '---': '---',
      '-Payment conditions value must be 1-100': 'Умови оплати мають бути в діапазоні 1-100',
      '0%': '0%',
      '0-100': '0-100',
      '0-100%': '0-100%',
      '0-200': '0-200',
      '0-200%': '0-200%',
      '0-95%': '0-95%',
      '0000000': '0000000',
      '1) we calculate P&L passport by passport, from different perspectives: by\n                    contracts (as a plan), by execution (as a fact), by invoices (as stronger\n                    fact), etc.':
        '1) ми розраховуємо PnL по паспорту для різних перспектив: \n                    за контрактами (як план), за виконанням (як факт),за інвойсами(як більш вагомий факт)\n                     і іншими.',
      '1-100': '1-100',
      '100% matched': '100% точне співпадіння',
      '1Q': '1 кв',
      '1t': '1 т',
      '2) we combine inside this result both physical and non physical operations by\n                    passport':
        '2) в цьому результаті ми враховуємо як фізичні, так і нефізичні операції \n                    за паспортом',
      '2Q': '2 кв',
      '3) when you press button CONTROL, we take the most actual P&L and put it here\n                    (you can choose which one, using MARGIN_SELECTOR inside passport modal window)':
        '3) коли ви натискаєте кнопку «КОНТРОЛЬ», ми беремо найактуальніший PnL і розміщуємо його тут \n                    (Ви можете вибрати конкретний PnL паспорта, використовуючи ПЕРЕМИКАЧ МАРЖІ при редагуванні паспорта)\n\\',
      '30-60 days': '30-60 днів',
      '3Q': '3 кв',
      "4) then we take all contracts, and group them by certain criteria (origin,\n                    destination, client, etc). We know the PNL by each contract from it's share\n                    inside passport":
        '4) потім ми беремо всі контракти і групуємо їх за певними критеріями (походження,\n                     призначення, контрагент і т.д.). Ми знаємо PnL по кожному контракту\n                     з його часткою у паспорті',
      '5) and as a result you see the grouped list of passports inside certain month\n                    (depending on a business date appointed in the passport)':
        '5) як результат, ви бачите згрупований список паспортів для певного місяця \n                    (в залежності від бізнес дати, встановленої в паспорті)',
      '60-90 days': '60-90 днів',
      '90 and over days': '90 і більше днів',
      '95-105%': '95-105%',
      '<i class="fa fa-file-excel-o"></i> Download':
        '<i class=“fa fa-file-excel-o”></i> Завантаження',
      '<i class="fa fa-file-text"></i> Contracts': '<span class="ng-scope">Контракти</span>',
      '<i class="fa fa-file-text-o"></i> Create ticket':
        '<i class="fa fa-file-text-o"></i> Створити тікет',
      '<i class="fa fa-filter"></i> <translate>Apply</translate>':
        '<span class="ng-scope">Застосувати</span>',
      '<i class="fa fa-floppy-o"></i>': '<i class="fa fa-floppy-o"></i>',
      '<i class="fa fa-pencil-square"></i>': '<i class="fa fa-pencil-square"></i>',
      '<i class="fa fa-refresh"></i>': '<i class="fa fa-times"></i>',
      '<i class="fa fa-times"></i>': '<i class="fa fa-times"></i>',
      '<i class="fa fa-times-circle"></i>': '<i class="fa fa-times-circle"></i>',
      '<span class="ng-scope">Apply</span>': '<span class="ng-scope">Застосувати</span>',
      '<span class="ng-scope">Clear</span>': '<span class="ng-scope">Очистити</span>',
      '<span class="ng-scope">Save</span>': '<span class="ng-scope">Зберегти</span>',
      '<span ng-if="!withoutEndOfExecution"> {{ month |date:\'MMM-yyyy\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>':
        '<span ng-if="!withoutEndOfExecution"> {{ місяць | дата :\'ммм-рррр\' }}</span>\n              <span ng-if="withoutEndOfExecution">N/A</span>',
      'A server error occurred. Please contact the administrator.':
        'Сталася помилка сервера. Будь ласка, зверніться до підримки.',
      ACCOUNTING: 'ОБЛІК',
      ACCOUNTS: 'РАХУНКИ',
      ACTIVITY: 'АКТИВНІСТЬ',
      ADD: 'ДОДАТИ',
      'ADD NEW': 'ДОДАТИ НОВИЙ',
      'ADD POSITION': 'ДОДАТИ ПОЗИЦІЮ',
      'ADDITIONAL AGGR.': 'ДОДАТКОВА УГОДА.',
      'ADDITIONAL AGGREEMENTS': 'ДОДАТКОВІ УГОДИ',
      AGREEMENTS: 'УГОДИ',
      ALL: 'ВСІ',
      AMOUNT: 'СУМА',
      AMOUNTS: 'СУМИ',
      ANALYTICAL: 'АНАЛІТИЧНИЙ',
      ANY: 'БУДЬ-ЯКИЙ',
      'AP/AR': 'Дебет/Кредит',
      'APPROVAL CONFIGURATION': 'КОНФІГУРАЦІЯ ПІДТВЕРДЖЕННЯ',
      APPROVE: 'ПІДТВЕРДИТИ',
      'AS A': 'ЯК',
      ASPIRATION: 'АСПІРАЦІЯ',
      AVG: 'СЕРЕДНЄ',
      'AVG flat price': 'Середня фіксована ціна',
      'AVG packing': 'Середня упаковка',
      'AVG price': 'Середня ціна',
      'AVG rate': 'Середня ставка',
      'AWB number': 'AWB номер',
      Abbreviation: 'Скорочена назва',
      'Absolute value': 'Абсолютное значение',
      'Access denied!': 'Доступ заборонено!',
      Account: 'Рахунок',
      'Account activity': 'Активність рахунку',
      'Account connection': 'Підключення аккаунту',
      'Account details': 'Деталі аккаунту',
      'Account is active': 'Аккаунт активний',
      'Account name': 'Назва рахунку',
      'Account period': 'Період обліку',
      'Account type': 'Тип рахунку',
      Accounting: 'Облік',
      'Accounting period': 'Звітний період',
      Accounts: 'Рахунки',
      'Accounts position': 'Позиція рахунка',
      'Acct#': '№',
      Action: 'Дія',
      Actions: 'Дії',
      Active: 'Активний',
      Activity: 'Активність',
      'Actual date': 'Поточна дата',
      'Actually contracted': 'Реально законтрактовано',
      Add: 'Додати',
      'Add agreement': 'Додати угоду',
      'Add bank details': 'Додати банківські реквізити',
      'Add contacts': 'Додати контакти',
      'Add contracts': 'Додати контракти',
      'Add costs/gains': 'Додати витрати/доходи',
      'Add document': 'Додати документ',
      'Add documents': 'Додати документи',
      'Add execution': 'Додати виконання',
      'Add filter': 'Додати фільтр',
      'Add finance': 'Додати інвойс',
      'Add finance (R/W)': 'Додати інвойс (залізниця)',
      'Add finance (auto)': 'Додати інвойс (авто)',
      'Add invoice': 'Додати інвойс',
      'Add invoices': 'Додати інвойси',
      'Add logistic': 'Додати логістику',
      'Add new': 'Додати новий',
      'Add new template': 'Додати новий шаблон',
      'Add payment': 'Додати оплату',
      'Add quality': 'Додати якість',
      'Add quality rule template': 'Додати шаблон дисконту якості',
      'Add template': 'Додати шаблон',
      'Add transport': 'Додати транспорт',
      'Add/update report config to table': 'Додати/оновити конфігурацію звіту до таблиці',
      Added: 'Додано',
      Additional: 'Додатково',
      'Additional Info': 'Додаткова інформація',
      'Additional aggr': 'Додаткова угода',
      'Additional agreement': 'Додаткова угода',
      'Additional agreement saved': 'Додаткову угоду збережено',
      'Additional agreements': 'Додаткові угоди',
      'Additional agreements date': 'Дата додаткових угод',
      'Additional alt': 'Додатковий (альтернативний)',
      'Additional costs': 'Додаткові витрати',
      'Additional currency': 'Додаткова валюта',
      'Additional doc one': 'Додатковий документ №1',
      'Additional doc three': 'Додатковий документ №3',
      'Additional doc two': 'Додатковий документ №2',
      'Additional document one': 'Додатковий документ №1',
      'Additional document three': 'Додатковий документ №3',
      'Additional document two': 'Додатковий документ №2',
      'Additional exchange rate': 'Додатковий курс обміну',
      'Additional info': 'Додаткова інформація',
      'Additional info for screenshot': 'Додаткові деталі для знімку екрану',
      'Additional info regarding confirmation of this company':
        'Додаткова інформація щодо статусу підтвердження цієї компанії',
      'Additional info updated': 'Додаткова інформація оновлена',
      Address: 'Адреса',
      'Address (eng)': 'Адреса (англ)',
      'Address Eng': 'Адреса (англ)',
      'Address eng': 'Адреса (англ)',
      Addresses: 'Адреси',
      'Admin panel': 'Адмін панель',
      Affiliated: 'Приєднаний',
      'Affiliated suppliers': 'Приєднані постачальники',
      Age: 'Вік',
      Agent: 'Посередник',
      Agreement: 'Угода',
      "Agreement doesn't exist": 'Угоди не існує',
      'Agreement limit': 'Ліміт угоди',
      'Agreement number': 'Номер угоди',
      'Agreement removed': 'Угоду видалено',
      'Agreement saved': 'Угоду збережено',
      'Agreement value': 'Сума угоди',
      Agreements: 'Угоди',
      Agritel: 'Агрітель',
      All: 'Всі',
      'All Additional Agreements has originals': 'Всі додаткові угоди мають оригінали',
      'All copies': 'Всі копії',
      'All counterparties of your company': 'Всі контрагенти вашої компанії',
      'All info': 'Вся інформація',
      'All of the following related items will be deleted:':
        "Всі наступні пов'язані об'єкти будуть видалені:",
      'All originals': 'Всі оригінали',
      'All signed': 'Всі підписали',
      'All uploaded documents': 'Всі завантажені документи',
      'All users of the company': 'Всі користувачі компанії',
      'All your notifications': 'Всі ваші повідомлення',
      'Allocate this operation to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Закріпити цю операцію до певного рахунку. Цей рахунок буде критерієм відповідності продажів і закупівлі у деривативах - звіт про позицію рахунка',
      'Allocate this operation to certain contract': 'Закріпити цю операцію до певного контракту',
      'Allocate this operation to certain passport. This passport will be a criteria to match sales and purchases in Derivatives - Contract Position report':
        'Закріпити цю операцію до певного паспорта. Цей паспорт стане критерієм для відповідності продажів і закупівлі у Деривативах - звіті про позиції контракту',
      'Allocate this passport to certain account. This account will be a criteria to match sales and purchases in Derivatives - Account Position report':
        'Призначте цей паспорт певному рахунку. Цей рахунок буде критерієм відповідності продажів і закупівлі у деривативах - звіт про позицію рахунка',
      Allocated: 'Розподілено',
      Allocation: 'Розподіл',
      Allocations: 'Розподіли',
      Amount: 'Сума',
      'Amount $': 'Сума, usd',
      'Amount BL+10%': 'Сума коносаментів + ​​10%',
      'Amount USD': 'Сума, USD',
      'Amount With Vat': 'Сума з ПДВ',
      'Amount alternative': 'Альтернативна сума',
      'Amount correction': 'Корекція суми',
      'Amount in offset': 'Сума у ​​заліку',
      'Amount local': 'Локальна сума',
      'Amount of discount': 'Сума дисконту',
      'Amount of premium': 'Сума премії',
      'Amount of premiums for the delivered goods': 'Сума премій за поставлений товар',
      'Amount of premiums from the contracted goods': 'Розмір премій від законтрактованих товарів',
      'Amount per 1 t': 'Сума за 1 т',
      'Amount per deal execution': 'Загальна сума за угоду',
      'Amount per t': 'Сума за 1 т',
      'Amount per whole deal': 'Сума за всю угоду',
      'Amount reconciliation': 'Звірка суми',
      'Amount to connect': 'Вільна сума видаткової накладної',
      'Amount trader': 'Сума трейдера',
      'Amount with VAT': 'Сума з ПДВ',
      'Amount withdrawn': 'Знята сума',
      'Amount without VAT': 'Сума без ПДВ',
      'Amount, $': 'Сума, usd',
      'Amount_local no vat': 'Сума у локальній, без ПДВ',
      Amounts: 'Суми',
      Analytic: 'Аналітичний',
      Analytical: 'Аналітичний',
      'Analytical Amount': 'Аналітична сума',
      'Analytical P&L MTM': 'Аналітичний ПНЛ МТМ',
      'Analytical Price': 'Аналітична ціна',
      'Analytical amount': 'Аналітична сума',
      'Analytical parent': 'Аналітичний батько',
      'Analytical parent commodity': 'Аналітична батьківська культура',
      'Analytical price': 'Аналітична ціна',
      'Analytical price different from contract price':
        'Аналітична ціна відрізняється від контрактної ціни',
      'Analytical result': 'Аналітична результат',
      'Analytical-Plan-Fact PnL': 'Аналітичний План-Факт ПнЛ',
      Analytics: 'Аналітичні',
      'And also you can make manual updates by counterparty whether you have a meeting,\n                call, email or some commentary regarding them. To a new one go directly into the\n                Counterparty - Updates':
        'І також ви можете вручну оновлювати контрагента незалежно від того, чи маєте ви зустріч,\n                дзвінок, email або якийсь коментар щодо нього. До оновлень перейдіть в \n                Контрагент - Оновлення',
      Angry: 'Злий',
      'Applied filters': 'Застосовані фільтри',
      Apply: 'Застосувати',
      'Apply default': 'Застосувати за замовчуванням',
      'Appoint an amount of discount or use discount in %':
        'Вкажіть суму дисконту або використайте відсоток дисконту',
      'Appoint it if you want to apply discount per 1 mt':
        'Позначте, якщо бажаєте застосувати дисконт на 1 т',
      'Appoint to which CONTRACT you want to allocate this charge or gain':
        'Призначте, на який КОНТРАКТ Ви хочете закріпити цю витрату або дохід',
      'Appoint to which PASSPORT you want to allocate this charge or gain in cases when contract has more than one passport and charge has to be allocated to a specific one. In other cases leave these field blank':
        "Призначте, до якого ПАСПОРТУ ви хочете закріпити цю витрату або дохід у випадках,коли контракт має більше одного паспорта і витрату/дохід необхідно зв'язати із конкретним паспортом. У інших випадках залиште ці поля порожніми",
      Approval: 'Підтвердження',
      'Approval Config': 'Конфігурація підтвердження',
      'Approval Status': 'Статус підтвердження',
      'Approval configuration': 'Конфігурація підтвердження',
      'Approval in process': 'Підтвердження в процесі',
      'Approval report': 'Звіт про затвердження',
      'Approval status': 'Статус підтвердження',
      'Approval updated': 'Підтвердження оновлено',
      Approvals: 'Підтвердження',
      'Approvals reactivated': 'Оновлено підтвердження',
      'Approvals report': 'Звіт про погодження',
      Approve: 'Підтвердити',
      'Approve / Decline': 'Підтвердити / Відхилити',
      'Approve approvals': 'Підтвердити',
      Approved: 'Підтверджено',
      'Approved by month': 'Підтверджено за місяцями',
      'Approved condition': 'Підтверджено з умовою',
      Approvers: 'Підтвердження',
      April: 'Квітень',
      Archived: 'Архів',
      'Are you sure that you want copy quality rules for all purchase facts?':
        'Ви впевнені, що бажаєте скопіювати правила якості для всіх фактів закупівлі?',
      "Are you sure that you want delete 'Custom cargo declaration'?":
        'Ви впевнені, що хочете видалити «Вантажну митну декларацію»?',
      'Are you sure that you want delete Cert?': 'Ви впевнені, що бажаєте видалити Сертифікат?',
      'Are you sure that you want delete Contract Charge?':
        'Ви впевнені, що бажаєте видалити Витрати за контрактом?',
      'Are you sure that you want delete Freight?': 'Ви впевнені, що бажаєте видалити Фрахт?',
      'Are you sure that you want delete Lost?': 'Ви впевнені, що бажаєте видалити Витрати?',
      'Are you sure that you want delete Position?': 'Ви впевнені, що бажаєте видалити Позицію?',
      'Are you sure that you want delete Tariff?': 'Ви впевнені, що бажаєте видалити Тариф?',
      'Are you sure that you want delete a template?': 'Ви впевнені, що бажаєте видалити шаблон?',
      'Are you sure that you want delete bill of lading?':
        'Ви впевнені, що бажаєте видалити коносамент?',
      'Are you sure that you want delete parsing config?': 'Ви впевнені, що бажаєте видалити це?',
      'Are you sure that you want delete passport?': 'Ви впевнені, що бажаєте видалити паспорт?',
      'Are you sure that you want delete this Person?':
        'Ви впевнені, що бажаєте видалити цей Контакт?',
      'Are you sure that you want delete this agreement?':
        'Ви впевнені, що бажаєте це видалити угоду?',
      'Are you sure that you want delete this bank operation?':
        'Ви впевнені, що бажаєте видалити банківську операцію?',
      'Are you sure that you want delete this bank?': 'Ви впевнені, що бажаєте видалити банк?',
      'Are you sure that you want delete this consignment?':
        'Ви впевнені, що бажаєте видалити квитанцію?',
      'Are you sure that you want delete this credit note?':
        'Ви впевнені, що бажаєте це видалити кредит-ноту?',
      'Are you sure that you want delete this disbursement bill of lading?':
        'Ви впевнені, що бажаєте видалити видаткову накладну?',
      'Are you sure that you want delete this document?':
        'Ви впевнені, що бажаєте видалити документ?',
      'Are you sure that you want delete this payment plan?':
        'Ви впевнені, що бажаєте це видалити цей план оплати?',
      'Are you sure that you want delete this payment?': 'Ви впевнені, що бажаєте видалити оплату?',
      'Are you sure that you want delete this template?':
        'Ви впевнені, що бажаєте видалити шаблон?',
      'Are you sure that you want delete this transaction?':
        'Ви впевнені, що хочете вилучити цю операцію?',
      'Are you sure that you want delete this vehicle?':
        'Ви впевнені, що бажаєте це видалити транспортний засіб?',
      'Are you sure that you want delete this?': 'Ви впевнені, що бажаєте видалити це?',
      'Are you sure that you want delete user?': 'Ви впевнені, що бажаєте видалити користувача?',
      'Are you sure that you want delete?': 'Ви впевнені, що бажаєте видалити?',
      'Are you sure that you want to create Contract Charges?':
        'Ви впевнені, що бажаєте видалити Витрату контракту?',
      'Are you sure that you want to delete?': 'Ви впевнені, що бажаєте видалити це?',
      'Are you sure that you want to set cargo confirmation?':
        'Ви впевнені, що бажаєте встановити підтвердження вантажу?',
      'Are you sure that you want to set fact volume to plans?':
        "Ви впевнені, що бажаєте встановити фактичний об'єм замість планового?",
      'Are you sure you want to delete loan?': 'Ви впевнені, що бажаєте видалити позику?',
      'Are you sure you want to delete quote parsing config?':
        'Ви впевнені, що бажаєте видалити це?',
      'Are you sure you want to delete the': 'Ви впевнені, що бажаєте видалити',
      'Are you sure?': 'Ви впевнені?',
      'Area, hectare': 'Площа, га',
      'Arrival date': 'Дата прибуття',
      Aspiration: 'Аспірація',
      Assets: 'Активи',
      'Assigned to': 'Призначено',
      'Assigned to me': 'Призначено мені',
      Assignment: 'Завдання',
      'At discharging port': 'У порту розвантаження',
      'At end': 'В кінці',
      'At least one company is required': 'Потрібна хоча б одна компанія',
      'At loading port': 'У порту завантаження',
      'At road': 'В дорозі',
      'At start': 'На початку',
      Attach: 'Приєднати',
      'Attach contracts to passport': 'Приєднати контракти до паспорта',
      'Attach documents': 'Додані документи',
      'Attach purchase': 'Приєднати закупівлю',
      'Attach purchase contract': 'Приєднати закупівельний контракт',
      'Attach purchase ticket': 'Приєднати закупівельний тікет',
      'Attach sale': 'Приєднати продаж',
      'Attach sale contract': 'Приєднати продажний контракт',
      'Attach sale ticket': 'Приєднати продажний тікет',
      'Attach stocks': 'Приєднати залишки',
      'Attach ticket': 'Приєднати тікет',
      'Attach to the passport': 'Приєднати до паспорта',
      'Attach voyage': 'Приєднати рейс',
      'Attached to passport': 'Приєднано до паспорту',
      'Attaching files': 'Приєднання файлів',
      Attachments: 'Вкладення',
      August: 'Серпень',
      Author: 'Автор',
      Auto: 'Авто',
      'Auto generated': 'Автостворені',
      'Auto payment conditions': 'Умови автооплати',
      'Auto prepay - balance percent': 'Автопередоплата - відсоток балансу',
      'Available in bot': 'Доступно в боті',
      'Available passports': 'Доступні паспорти',
      'Average flat price per fixed lots': 'Середня фіксована ціна за фіксовані лоти',
      'Average flat price per hedged lots': 'Середня фіксована ціна за хеджовані лоти',
      'Average mt price per fixed lots': 'Середня ціна за 1т за фіксовані лоти',
      'Average price': 'Середня ціна',
      'Average purchase': 'Середня закупівля',
      'Average purchase cost': 'Середні витрати закупівлі',
      'Average sale': 'Середній продаж',
      'Avеrage commodities price, $/t with VAT': 'Середня ціна товару, usd/т з ПДВ',
      B: 'B',
      'B/L': 'Коносамент',
      'B/L date': 'Дата коносаменту',
      'B/L number': 'Номер коносаменту',
      "B/L's": 'Коносаменти',
      'B/Ls total volume': "Загальний об'єм коносаментів",
      B2B: 'B2B',
      'BAGS WEIGHT IN (KGS)': 'ВАГА ТАРИ (КГ)',
      BALANCE: 'БАЛАНС',
      BI: 'BI',
      'BILLS OF LADING': 'КОНОСАМЕНТИ',
      BL: 'Коносамент',
      'BL date': 'Дата коносаменту',
      'BL max date': 'Остання дата коносаменту',
      'BL volume:': "Об'єм коносаментів:",
      'BL/ETA ASC': 'BL/ETA ASC',
      'BL/ETA DESC': 'BL/ETA DESC',
      BLs: 'Коносаменти',
      'BLs are invoiced': 'Виставлені інвойси коносаментів',
      BOAT: 'КОРАБЕЛЬ',
      BP: 'BP',
      BU: 'Бізнес-юніт',
      BUDGETS: 'БЮДЖЕТИ',
      BUYER: 'ПОКУПЕЦЬ',
      'B\\L': 'Коносамент',
      "B\\L's": 'Коносаменти',
      'Bad payment amount': 'Неправильна сума платежу',
      'Bags quantity': 'Кількість мішків',
      'Bags weight': 'Вага мішків',
      Balance: 'Баланс',
      'Balance at the beginning': 'Баланс на початок',
      'Balance at the end': 'Баланс на кінець',
      'Balance invoice': 'Інвойс балансу',
      'Balance payment': 'Оплата балансу',
      'Balance payment dates': 'Дати оплати балансу',
      'Balance to offset': 'Баланс для заліку',
      'Balance to settlement': 'Баланс до розрахунку',
      'Balance volume': "Об'єм балансу",
      'Balance, Cost / Gains': 'Баланс, витрати/дохід',
      Bank: 'Банк',
      'Bank Account': 'Банківський рахунок',
      'Bank Account Balance': 'Баланс банківського рахунку',
      'Bank Account Total': 'Загальна сума банківського рахунку',
      'Bank Accounts': 'Банківські рахунки',
      'Bank Comission': 'Комісія банку',
      'Bank Eng': 'Банк (англ)',
      'Bank Operations': 'Банківські операції',
      'Bank Transactions': 'Банківські транзакції',
      'Bank account': 'Банківський рахунок',
      "Bank account doesn't exist": 'Банківського рахунку не існує',
      'Bank account saved': 'Банківський рахунок збережено',
      'Bank accounts': 'Банківські рахунки',
      'Bank client': 'Банк контрагента',
      'Bank code': 'Код банку',
      'Bank data check': 'Перевірка банківських даних',
      'Bank data is checked': 'Банківські дані перевірено',
      'Bank details': 'Банківські реквізити',
      'Bank name': 'Назва банку',
      'Bank operation': 'Банківська операція',
      'Bank operation removed': 'Банківська операція видалена',
      'Bank operation saved': 'Банківська операція збережена',
      'Bank operations': 'Банківські операції',
      'Bank removed': 'Банк видалений',
      'Bank saved': 'Банк збережено',
      'Bank transaction': 'Банківська транзакція',
      'Bank transaction commission': 'Комісія за банківські операції',
      'Bank transaction saved.': 'Банківська транзакція збережена.',
      Banks: 'Банки',
      Barchart: 'Барчарт',
      Base: 'База',
      'Base, days': 'База, днів',
      'Basic metrics': 'Базові показники',
      Basis: 'Базис',
      'Basis Docs': 'Документ',
      'Basis Opposite': 'Зустрічний базис',
      BasisDoc: 'Базис документ',
      Basises: 'Базиси',
      Bcc: 'Прихована копія',
      'Because exactly this figure participates in calculation of Passport\n                  profitability.':
        'Тому що саме ця цифра бере участь у розрахунку паспортної \n                  прибутковості.',
      Bid: 'Заявка',
      Bids: 'Бід',
      Big: 'Великий',
      'Bill of Lading': 'Коносамент',
      'Bill of lading': 'Коносамент',
      'Bill of lading  saved and connected to logistics':
        'Коносамент збережено та підключено до логістики',
      'Bill of lading number': 'Номер коносаменту',
      'Bill of lading removed': 'Коносамент видалено',
      'Bill of lading saved': 'Коносамент збережено',
      BillOfLading: 'Коносамент',
      'Bills of lading': 'Коносамент',
      Birthday: 'День народження',
      'Bite off some volume from this Indicator to create a Ticket':
        'Використати певний об’єм від Індикатора, щоб створити Тікет',
      'Black list': 'Чорний список',
      'Black listed': 'Чорний список',
      Blacklisted: 'Чорний список',
      Block: 'Блок',
      Boarded: 'Завантажено',
      Boat: 'Корабель',
      Body: 'Текст',
      Borrower: 'Позичальник',
      Bot: 'Бот',
      'Both calculations': 'Обидва розрахунки',
      Bots: 'Боти',
      Broker: 'Брокер',
      'Broker EDRPOU code': 'Брокер код ЄДРПОУ',
      'Broker address': 'Брокер адреса',
      'Broker can signatory docs': 'Брокер може підписувати документи',
      'Broker can signatory docs eng': 'Брокер може підписувати документи (англ)',
      'Broker confirm': 'Брокерське підтвердження',
      'Broker confirm required': 'Потрібне підтвердження брокера',
      'Broker confirmation': 'Брокерське підтвердження',
      'Broker cost': 'Брокерські витрати',
      'Broker country': 'Брокер країна',
      'Broker country eng': 'Брокер країна (англ)',
      'Broker email': 'Брокер email',
      'Broker full name eng': 'Брокер повна назва (англ)',
      'Broker full name ru': 'Брокер повна назва',
      'Broker legal address': 'Брокер юридична адреса',
      'Broker legal address eng': 'Брокер юридична адреса (англ)',
      'Broker name': 'Назва брокера',
      'Broker phone': 'Брокер телефон',
      'Broker signatory': 'Підписант брокера',
      'Broker signatory eng': 'Підписант брокера (англ)',
      'Broker website': 'Брокер веб-сайт',
      Brokerage: 'Брокерський',
      Brokers: 'Брокери',
      Budget: 'Бюджет',
      Budgets: 'Бюджети',
      'Busines Unit': 'Бізнес-юніт',
      Business: 'Бізнес',
      'Business (date)': 'Бізнес (дата)',
      'Business Date': 'Бізнес дата',
      'Business Unit': 'Бізнес-юніт',
      'Business Units': 'Бізнес-юніти',
      'Business confirmation': 'Бізнес підтвердження',
      'Business date': 'Бізнес дата',
      'Business unit': 'Бізнес-юніт',
      'Business unit title': 'Назва бізнес-юніта',
      'Business units': 'Бізнес-юніти',
      Buy: 'Покупка',
      'Buy quantity': 'Кількість покупки',
      Buyer: 'Покупець',
      'Buyer - the real Buyer of the deal, Seller(Supplier) - your company':
        'Покупець - реальний Покупець угоди, Продавець (Постачальник) - Ваша компанія',
      'Buyer - your company, Seller(Supplier) - the real Supplier of the deal':
        'Покупець - Ваша компанія, Продавець (Постачальник) - реальний Постачальник угоди',
      'Buyer 10 invoice': '10 інвойс покупця',
      'Buyer 90 invoice': '90 інвойс покупця',
      'Buyer EDRPOU code': 'Код ЄДРПОУ покупця',
      'Buyer ITN (Individual Tax Number)': 'Покупець ІПН (індивідуальний податковий номер)',
      'Buyer Signed': 'Покупець підписав',
      'Buyer address': 'Адреса покупця',
      'Buyer balance invoice': 'Інвойс балансу покупця',
      'Buyer bank details': 'Банківські реквізити покупця',
      'Buyer can signatory docs': 'Покупець може підписувати документи',
      'Buyer can signatory docs eng': 'Покупець може підписувати документи (англ)',
      'Buyer contract': 'Контракт покупця',
      'Buyer contract #': 'Контракт покупця №',
      'Buyer contract ISCC': 'Контракт покупця ISCC',
      'Buyer contract status': 'Статус контракту покупця',
      'Buyer country': 'Країна покупця',
      'Buyer country eng': 'Країна покупця (англ)',
      'Buyer email': 'Покупець email',
      'Buyer full name': 'Повна назва покупця',
      'Buyer full name eng': 'Повна назва покупця (англ)',
      'Buyer invoice volume': "Об'єм інвойсу покупця",
      'Buyer invoiced': 'Виставлено інвойс покупцю',
      'Buyer is required': 'Необхідний покупець',
      'Buyer legal address': 'Покупець юридична адреса',
      'Buyer legal address eng': 'Покупець юридична адреса (англ)',
      'Buyer paid': 'Покупець сплатив',
      'Buyer passports #': 'Паспорт покупця №',
      'Buyer payment info': 'Інфо про оплату покупця',
      'Buyer phone': 'Телефон покупця',
      'Buyer prepay invoice': 'Інвойс на передоплату покупця',
      'Buyer signatory': 'Підписант покупця',
      'Buyer signatory eng': 'Підписант покупця (англ)',
      'Buyer signed': 'Покупець підписав',
      'Buyer tax info': 'Покупець податкова інфо',
      'Buyer website': 'Покупець веб-сайт',
      'Buyer/Owner': 'Покупець/Власник',
      Buyers: 'Покупці',
      'By analytical price': 'По аналітичній ціні',
      'By contract price': 'По ціні контракту',
      CALCULATOR: 'КАЛЬКУЛЯТОР',
      CALENDAR: 'КАЛЕНДАР',
      CARGO: 'ВАНТАЖ',
      'CASH FLOW RECREATE': 'КЕШ-ФЛО ВІДНОВИТИ',
      CASHFLOW: 'КЕШ-ФЛО',
      'CASHFLOW + BALANCES': 'КЕШ-ФЛО + БАЛАНСИ',
      CCD: 'CCD',
      CERTS: 'СЕРТИФІКАТИ',
      CHAIN: 'ЛАНЦЮГ',
      CHARGES: 'ВИТРАТИ',
      'CHECK LIST': 'ЧЕК-ЛИСТ',
      'CHOOSE FAVORITES': 'ВИБРАТИ УЛЮБЛЕНІ',
      'CHOOSE USER': 'ВИБЕРІТЬ КОРИСТУВАЧА',
      CLOSED: 'ЗАКРИТО',
      COMMENTS: 'КОМЕНТАРІ',
      COMMISSIONS: 'КОМІСІЇ',
      COMMODITY: 'ТОВАР',
      COMPANIES: 'КОМПАНІЇ',
      COMPANY: 'КОМПАНІЯ',
      COMPLIANCE: 'КОМПЛАЄНС',
      'CONCLUSION DATE': 'ДАТА УКЛАДАННЯ',
      CONDITIONS: 'УМОВИ',
      CONFIG: 'КОНФІГУРАЦІЯ',
      CONFIRMATIONS: 'ПІДТВЕРДЖЕННЯ',
      CONSIGNMENTS: 'СКЛАДСЬКІ КВИТАНЦІЇ',
      CONTACTS: 'КОНТАКТИ',
      'CONTRACT DOCUMENTS': 'ДОКУМЕНТИ КОНТРАКТУ',
      'CONTRACT VALUE': 'СУМА КОНТРАКТІВ',
      CONTRACTS: 'КОНТРАКТИ',
      CONTROL: 'КОНТРОЛЬ',
      COSTS: 'ВИТРАТИ',
      'COSTS OF GOODS': 'СОБІВАРТІСТЬ',
      COUNTERPARTIES: 'КОНТРАГЕНТИ',
      'CP date': 'Дата чартер партії',
      'CP/Seeds Prepayments': 'Передоплата чартер партії/посівів',
      'CREATE A FILTER': 'СТВОРИТИ ФІЛЬТР',
      CREATED: 'СТВОРЕНО',
      'CREATION DATE': 'ДАТА СТВОРЕННЯ',
      CREDIT: 'КРЕДИТ',
      CRM: 'CRM',
      CTL: 'CTL',
      'CTRM system for commodity traders': 'Система CTRM для товарних трейдерів',
      CURRENCY: 'ВАЛЮТА',
      'Cached value should be only number, string, array or object':
        'Збережене значення повинно бути лише числом, рядком, масивом або об’єктом',
      'Calc intermediate logistics': 'Розрахунок проміжної логістики',
      'Calculate auto tariff': 'Розрахувати тариф ТЗ',
      'Calculate broker cost': 'Розрахувати брокерські витрати',
      'Calculate elevator costs': 'Розрахувати витрати елеватора',
      'Calculate profit and loss': 'Розрахунок PnL',
      'Calculate railway tariff': 'Розрахувати тариф на залізницю',
      'Calculate tax profit to pay': 'Розрахувати податковий прибуток до сплати',
      Calculator: 'Калькулятор',
      Calendar: 'Календар',
      Call: 'Дзвінок',
      'Can access Admin': 'Має адмін доступ',
      'Can confirm': 'Може підтвердити',
      Cancel: 'Скасувати',
      'Cancel contract': 'Скасувати контракт',
      'Cancel indicator': 'Скасувати індикатор',
      'Cancel ticket': 'Скасувати тікет',
      Canceled: 'Скасовано',
      Cancelled: 'Скасовано',
      'Cancelled?': 'Скасувати?',
      'Cannot delete {[{ $ctrl.results.object_model }]}':
        'Неможливо видалити {[{ $ctrl.results.object_model }]}',
      Capacity: 'Місткість',
      'Capacity avg': 'Місткість середня',
      'Capacity est': 'Місткість очікувана',
      'Capacity max': 'Місткість макс',
      'Capacity min': 'Місткість мін',
      Car: 'ТЗ',
      Cargo: 'Вантаж',
      'Cargo Price': 'Ціна за вантаж',
      'Cargo all': 'Весь вантаж',
      'Cargo brand': 'Бренд вантажу',
      'Cargo confirmation': 'Підтвердження вантажу',
      'Cargo customs declaration': 'Вантажна митна декларація',
      'Cargo details': 'Деталі вантажу',
      'Cargo eng': 'Вантаж (англ)',
      'Cargo executed': 'Виконаний за вантажем',
      'Cargo fully paid': 'Вантаж повністю оплачений',
      'Cargo is required': 'Потрібен вантаж',
      'Cargo partial executed': 'Частково виконаний за вантажем',
      'Cargo receiver': 'Отримувач вантажу',
      'Cargo shipper': 'Вантажовідправник',
      Carrier: 'Перевізник',
      'Cars prepay - balance percent': 'Авто по передоплаті - відсоток балансу',
      Cash: 'Кошти',
      'Cash Flow': 'Кеш-фло',
      'Cash Flow (mixed)': 'Кеш-фло (мікс)',
      'Cash Flow report': 'Звіт Кеш-фло',
      'Cash Start': 'Початкова сума',
      'Cash flow': 'Кеш-фло',
      'Cash flow recreated': 'Кеш-фло відновлено',
      Cashback: 'Кешбек',
      'Cashback amount': 'Сума кешбеку',
      Cashflow: 'Кеш-фло',
      Cc: 'Копія',
      'Certificate Dates': 'Дати сертифікатів',
      'Certificates exist in following logistics. Do you want to override them?':
        'Сертифікати вже існують у цій логістиці. Ви бажаєте їх замінити?',
      Certs: 'Сертифікати',
      'Chain order': 'Ланцюговий порядок',
      Change: 'Змінити',
      'Change password': 'Змінити пароль',
      'Change the status of contract to shipment': 'Змінити статус контракту на відвантаження',
      Changed: 'Змінено',
      Charge: 'Витрата',
      'Charge amount': 'Сума витрати',
      Charges: 'Витрати',
      'Charges / Gains': 'Витрати / Доходи',
      'Charges types': 'Типи витратів',
      Chart: 'Графік',
      'Charter party (date)': 'Договір чартеру (дата)',
      'Charter party date': 'Договір чартеру (дата)',
      Charterer: 'Фрахтувальник',
      'Charterer is owner': 'Фрахтувальник є власником',
      Charts: 'Графіки',
      Chats: 'Чат',
      Check: 'Перевірити',
      'Check content': 'Перевірте вміст',
      'Check costs': 'Перевірте витрати',
      'Check destination': 'Перевірте пункт призначення',
      'Check execution': 'Перевірте виконання',
      'Check fixing & hedging': 'Перевірте Фіксинг та Хеджування',
      "Check if everything's allright": 'Перевірте, чи все гаразд',
      'Check if you have deleted the expense from the passport/contract':
        'Перевірте, чи видалили витрату з паспорту/контракту',
      'Check invoices': 'Перевірте інвойс',
      'Check list of docs is set up': 'Перевірка списку документів налаштована',
      'Check point of departure': 'Перевірте пункт відправлення',
      'Check preview and send an email': 'Переглянути перед надсиланням та надіслати email',
      'Check the hedging': 'Перевірте хеджування',
      'Check the position': 'Перевірте позицію',
      'Check this if LOI was issued': 'Перевірте, чи було видано LOI',
      'Check this if counterparty has criminal cases':
        'Позначте, якщо у контрагента є кримінальні справи',
      'Check this if counterparty has sanctions': 'Позначте, якщо контрагент має санкції',
      'Check this if counterparty has tax debt': 'Позначте, якщо контрагент має податковий борг',
      'Check this if the counterparty in invoice is different than the one in the contract':
        'Позначте, якщо контрагент в інвойсі відрізняється від контрагента в контракті',
      'Check this if the purchase of certified products on iscc standards':
        'Позначте, якщо купуєте сертифіковану продукцію за стандартами iscc',
      'Check this if this supplier is distributor.':
        'Позначте, якщо цей постачальник є дистриб’ютором.',
      'Check this if you don`t want to check with SINGING DAYS QUANTITY value':
        'Позначте, якщо не бажаєте перевіряти КІЛЬКІСТЬ ДНІВ ПІДПИСАННЯ',
      'Check this if you to show basis-port-price widget':
        'Позначте, якщо ви бажаєте відобразити віджет базис-порт-ціна',
      'Check this if you to show derivatives widget': 'Позначте, якщо хочете вказати декілька цін',
      'Check this if you to show few stations': 'Позначте, якщо бажаєте показати декілька станцій',
      'Check this if you want agreement number to be auto generated':
        'Позначте, якщо бажаєте, щоб номер угоди генерувався автоматично',
      'Check this if you want calculate complex percent':
        'Позначте, якщо ви хочете обчислити складний відсоток',
      'Check this if you want calculate simple percent':
        'Позначте, якщо ви хочете обчислити простий відсоток',
      'Check this if you want contract name to be auto generated':
        'Позначте, якщо бажаєте , щоб номер контракту був автоматично згенерований',
      'Check this if you want finance number to be auto generated':
        'Позначте, якщо бажаєте , щоб номер інвойсу автоматично згенеровався',
      'Check this if you want loan name to be auto generated':
        'Позначте, якщо бажаєте , щоб номер позики автоматично згенеровався',
      'Check this if you want name to be auto generated':
        'Позначте це, якщо ви хочете, щоб назва була автоматично згенерована',
      'Check this if you want number to be auto generated':
        'Позначте, якщо бажаєте, щоб номер генерувався автоматично',
      'Check this if you want to display BLACKLIST label alongside company name':
        'Позначте ,якщо бажаєте відображати позначку ЧОРНИЙ СПИСОК поряд з назвою компанії',
      'Check this if you want to make contract to swap type':
        'Позначте, бажаєте укласти СВОП контракт',
      'Check this if you want to parse this quote':
        'Позначте, якщо ви хочете парсувати це котирування',
      'Check this if you want to use broker tariff in price calculate':
        'Позначте, якщо бажаєте використовувати брокерський тариф при розрахунку ціни',
      'Check this if you want to use derivative':
        'Позначте, якщо бажаєте використовувати дериватив',
      'Check this if you want to use it as internal chain':
        'Позначте, якщо бажаєте використовувати як внутрішній ланцюг',
      'Check this if you want to use match with commodity parent':
        'Позначте це, якщо бажаєте використовувати відповідність з батьківським товаром',
      'Check this if you want to use tax profit to pay in price calculate':
        'Позначте, якщо бажаєте використовувати податок на прибуток в розрахунку ціни',
      'Check this if you want to use volume position as aspiration':
        'Позначте, якщо бажаєте використовувати об’єм позиції як аспірацію',
      'Check this to avoid standard KPI evaluation process':
        'Позначте це, щоб уникнути стандартного процесу оцінювання KPI',
      'Check this, if current company buy or sell in USD':
        'Перевірте, якщо поточна компанія купує або продає у валюті USD',
      'Check this, if current company buy or sell only in local currency':
        'Перевірте це, якщо поточна компанія купує або продає лише в локальній валюті',
      'Check this, if current company is an Agent and not a Producer':
        'Позначте, якщо компанія є Посередником, а не Виробником',
      'Check this, if the Supplier of Farm is an original producer':
        'Позначте, якщо Постачальник чи Господарство є Виробником',
      'Check volume': "Перевірте об'єм",
      'Check warehouse': 'Перевірте склад',
      'Check your personal settings': 'Перевірити особисті налаштування',
      'Child commodity': 'Дочірня культура',
      'Child crop': 'Дочірня культура',
      Choose: 'Вибрати',
      'Choose Condition': 'Виберіть умову',
      'Choose Invoicing Amount': 'Виберіть суму інвойсу',
      'Choose Multipassport': 'Виберіть мультипаспорт',
      'Choose Supplier': 'Вибрати постачальника',
      'Choose a preset of report config': 'Виберіть попередню конфігурацію звіту',
      'Choose a range': 'Виберіть діапазон',
      'Choose a template': 'Виберіть шаблон',
      'Choose approve status': 'Виберіть статус підтвердження',
      'Choose assignment': 'Виберіть завдання',
      'Choose cargo brand': 'Виберіть бренд вантажу',
      'Choose charterer': 'Виберіть фрахтувальника',
      'Choose commodity': 'Виберіть товар',
      'Choose compliance status': 'Виберіть статус комплаєнсу',
      'Choose configuration to launch the approval process by this object. You need first to set up configuration an Admin section, and here just pick it up':
        "Виберіть конфігурацію для запуску процесу затвердження цього об'єкта. Спочатку необхідно налаштувати конфігурацію в розділі «Адмін», а тут просто вибрати її",
      'Choose consignment': 'Виберіть накладну',
      'Choose contract': 'Виберіть контракт',
      'Choose counterparty': 'Виберіть контрагента',
      'Choose counterparty and currency': 'Виберіть контрагента та валюту',
      'Choose custom status': 'Виберіть спеціальний статус',
      'Choose date filter': 'Виберіть фільтр дати',
      'Choose disbursement bill of lading': 'Виберіть видаткову накладну',
      'Choose from predefined list of types. To add new one click +':
        'Виберіть з попередньо визначеного списку типів. Щоб додати новий, натисніть +',
      'Choose invoice': 'Виберіть інвойс',
      'Choose invoice assignment': 'Виберіть призначення інвойсу',
      'Choose invoice date': 'Виберіть дату інвойсу',
      'Choose invoice type': 'Виберіть тип інвойсу',
      'Choose invoicing weight': 'Виберіть вагу для виставлення інвойсу',
      'Choose manager': 'Виберіть менеджера',
      'Choose margin': 'Виберіть маржу',
      'Choose one or more default incoterms on which this Supplier or Buyer is selling or buying':
        'Виберіть один або кілька базисів, за якими цей Постачальник або Покупець продає або купує',
      'Choose operation type': 'Виберіть тип операції',
      'Choose option': 'Виберіть опцію',
      'Choose options': 'Виберіть опції',
      'Choose port': 'Виберіть порт',
      'Choose prepay conditions': 'Виберіть умови передплати',
      'Choose product': 'Виберіть товар',
      'Choose program type': 'Виберіть тип програми',
      'Choose purchase contract': 'Виберіть закупівельний контракт',
      'Choose relations': 'Виберіть відносини',
      'Choose resource': 'Виберіть ресурс',
      'Choose role': 'Виберіть роль',
      'Choose ship classification': 'Виберіть класифікацію корабля',
      'Choose size': 'Виберіть розмір',
      'Choose status': 'Виберіть статус',
      'Choose tax registration': 'Виберіть податкову реєстрацію',
      'Choose template': 'Виберіть шаблон',
      'Choose terminal': 'Виберіть термінал',
      'Choose the Excel template you want to send. It will be an excel spreadsheet\n                        attached to a letter. You can choose the order and naming of columns':
        'Виберіть шаблон Excel, який ви хочете надіслати. Він буде представляти собою електронну таблицю Excel, \n                        прикріплену до листа. Ви можете вибрати порядок та назву стовпців',
      'Choose the block, to which this template will be attached.This means, you might use it only within this block':
        'Виберіть блок, до якого буде додано цей шаблон. Це означає, що ви зможете використовувати його лише у межах цього блоку',
      'Choose the email template you want to send. It can be customized for every\n                        client':
        'Виберіть шаблон email, який ви хочете надіслати. Він може бути налаштований для кожного \n                        контрагента окремо',
      'Choose the file on computer': "Виберіть файл на комп'ютері",
      'Choose the range': 'Виберіть діапазон',
      'Choose type': 'Виберіть тип',
      'Choose type of invoice': 'Виберіть тип інвойсу',
      'Choose type of logistic': 'Виберіть тип логістики',
      'Choose usage': 'Виберіть використання',
      'Choose vehicle subtype': 'Виберіть підтип ТЗ',
      'Choose vessel': 'Виберіть корабель',
      'Choose who decide the option': 'Виберіть, хто вибирає опцію',
      'Choose year': 'Виберіть рік',
      'Choosed quality list': 'Виберіть список якості',
      'Chose a role of a counterparty to be bound to a finance':
        "Виберіть роль контрагента, щоб зв'язати з інвойсом",
      'Chose new filter...': 'Виберіть новий фільтр...',
      Cities: 'Міста',
      City: 'Місто',
      'Clean All': 'Очистити все',
      Cleaning: 'Очищення',
      Clear: 'Очистити',
      'Clear filters': 'Очистити фільтри',
      'Click this checkbox if you want to include all package of documents by all\n                        logistics you want to send':
        'Позначте цей пункт, якщо бажаєте включити весь пакет документів\n                         у всю логістику, яку бажаєте надіслати',
      Client: 'Контрагент',
      'Client & cargo': 'Контрагент & вантаж',
      'Client Document Type': 'Тип документа контрагента',
      'Client Role': 'Роль контрагента',
      'Client author': 'Автор',
      'Client chats': 'Клієнтські чати',
      'Client data check': 'Перевірка даних контрагента',
      'Client data is checked': 'Дані контрагента перевіряються',
      'Client from': 'Контрагент від',
      'Client role': 'Роль контрагента',
      'Client template': 'Шаблон контрагента',
      'Client to': 'Контрагент до',
      "Client's Template": 'Шаблон контрагента',
      'Clientrole from': 'Контрагент (роль) від',
      'Clientrole to': 'Контрагент (роль) до',
      Clients: 'Контрагенти',
      Clone: 'Клонувати',
      Close: 'Закрити',
      'Close contract': 'Закрити контракт',
      'Close filters': 'Закрити фільтри',
      'Close modal?': 'Закрити модальне вікно?',
      'Close price': 'Ціна закриття',
      Closed: 'Закритий',
      'Closing contract': 'Закриття контракту',
      'Closing of existing position': 'Закриття існуючої позиції',
      Code: 'Код',
      Coefficient: 'Коефіцієнт',
      Cold: 'Холодний',
      Collateral: 'Забезпечення',
      'Collateral amount': 'Сума забезпечення',
      Collection: 'Конфігурація',
      'Collection title': 'Назва конфігурації',
      'Column name': 'Назва стовпця',
      'Comm Fees': 'Комісійні збори',
      'Comm fees': 'Комісійні збори',
      'Comm fees,$': 'Комісії, usd',
      'Comm. fees': 'Комісії',
      Comment: 'Коментар',
      Commentary: 'Коментар',
      'Commentary successfully deleted.': 'Коментар успішно видалено.',
      'Commentary successfully saved.': 'Коментар успішно збережено.',
      Comments: 'Коментарі',
      Commercial: 'Комерційний',
      'Commercial Invoice': 'Комерційний інвойс',
      Commission: 'Комісія',
      'Commission amount': 'Сума комісії',
      'Commission credit account': 'Рахунок зарахування комісій',
      'Commission debit account': 'Рахунок списання комісій',
      Commissions: 'Комісії',
      Commodities: 'Товари',
      Commodity: 'Товар',
      'Commodity Balance': 'Баланс по товару',
      'Commodity default': 'Товар за замовчуванням',
      'Commodity level': 'Рівень товарообігу',
      'Commodity opposite': 'Товар зустрічний',
      Companies: 'Компанії',
      Company: 'Компанія',
      'Company Group': 'Група компаній',
      'Company from': 'Компанія від',
      'Company group': 'Група компаній',
      'Company groups': 'Групи компаній',
      'Company name': 'Назва компанії',
      'Company parameters': 'Параметри компанії',
      'Company to': 'Компанія до',
      'Company with whom we have these charge or gain, not a contract counterparty':
        'Компанія, з якою у нас є ці витрати або дохід, не є контрагентом за контрактом',
      'Complete compliance': 'Комплаєнс завершено',
      Completed: 'Завершені',
      'Complex percent': 'Складний відсоток',
      Compliance: 'Комплаєнс',
      'Compliance KPI': 'Комплаєнс KPI',
      'Compliance status': 'Статус комплаєнсу',
      Conclusion: 'Укладання',
      'Conclusion (date)': 'Укладання (дата)',
      'Conclusion Date': 'Укладання дата',
      'Conclusion date': 'Дата укладання',
      'Conclusion date currency': 'Валюта дати укладання',
      'Conclusion date exchange rate': 'Курс валют на дату укладання',
      'Conclusion date flat price': 'Фіксована ціна на дату укладання',
      Condition: 'Умова',
      Conditions: 'Умови',
      'Config title': 'Назва конфігурації',
      Configs: 'Конфігурація',
      Configuration: 'Конфігурація',
      Configure: 'Конфігурація',
      'Configure fields': 'Налаштувати поля',
      Confirm: 'Підтвердити',
      'Confirm New Password': 'Підтвердити новий пароль',
      Confirmation: 'Підтвердження',
      'Confirmation (date)': 'Підтвердження (дата)',
      'Confirmation date': 'Дата підтвердження',
      'Confirmation info': 'Інформація про підтвердження',
      Confirmations: 'Підтвердження',
      Confirmed: 'Підтверджено',
      Connect: "З'єднати",
      'Connect Documents': "З'єднати документи",
      'Connect contract to existing passport': "З'єднати контракт з існуючим паспортом",
      'Connect logistics': "З'єднати логістику",
      'Connect to existing passport': "З'єднати до існуючого паспорта",
      'Connect to passport': 'З’єднати до паспорта',
      'Connect to purchase': "З'єднати до закупівлі",
      'Connect to sale': "З'єднати до продажу",
      'Connect to {[{ $root.user.settings.PASSPORT_TITLE }]}':
        "З'єднати з {[{ $root.user.settings.PASSPORT_TITLE }]}",
      'Connect with purchase': "З'єднати із закупівлею",
      'Connect with purchase contract': "З'єднати до закупівельного контракту",
      'Connect with sale': "З'єднати із продажем",
      'Connect with sale contract': "З'єднати до продажного контракту",
      'Connect wrong crops?': "З'єднати неправильні культури?",
      Connected: "З'єднаний",
      'Connected passports': "Зв'язані паспорти",
      'Connected volume': "З'єднаний об'єм",
      'Connecting...': "З'єднуємо...",
      Connections: "Зв'язки",
      'Connections updated.': "Зв'язки оновлені.",
      'Consecutive voyages': 'Послідовні рейси',
      Consignee: 'Вантажоодержувач',
      Consignment: 'Накладна',
      'Consignment (#)': 'Накладна (№)',
      'Consignment contract commodities': 'Товар накладних контракту',
      'Consignment date': 'Дата накладної',
      'Consignment number': 'Номер накладної',
      'Consignment removed': 'Накладну видалено',
      'Consignment saved': 'Накладну збережено',
      Consignments: 'Складські квитанції',
      Consolidated: 'Консолідований',
      'Consolidated Contract PnL': 'Консолідований PnL контракту',
      'Consolidated PnL': 'Консолідований PnL',
      'Consolidated crop position': 'Консолідована позиція культури',
      Contact: 'Контакт',
      'Contact date expired': 'Дата контакту неактуальна',
      'Contact first time': 'Перший контакт',
      'Contact info': 'Контактна інформація',
      'Contact next time': "Зв'яжіться наступного разу",
      'Contact today': "Зв'язатися сьогодні",
      Contacted: "Зв'язався",
      Contacts: 'Контакти',
      Container: 'Контейнер',
      Continue: 'Продовжити',
      Contract: 'Контракт',
      'Contract #': 'Контракт №',
      'Contract - Execution': 'Контракт - Виконання',
      'Contract Charges / Gains': 'Контрактні Витрати/Доходи',
      'Contract Charges 1t': 'Витрати контракту за 1 т',
      'Contract DSTU': 'Контракт ДСТУ',
      'Contract Number': 'Номер контракту',
      'Contract Option': 'Опція контракту',
      'Contract Owner': 'Власник контракту',
      'Contract P&L': 'PnL контракту',
      'Contract P&L MTM': 'Контракт PnL MTM',
      'Contract Plans': 'Плани контракту',
      'Contract Price': 'Ціна контракту',
      'Contract ROS': 'Контракт ROS',
      'Contract Status': 'Статус контракту',
      'Contract Sum': 'Сума контракту',
      'Contract amount': 'Сума контракту',
      'Contract can have only one warehouse': 'Контракт може мати тільки один склад',
      'Contract charge': 'Витрата за контрактом',
      'Contract charge saved': 'Витрата за контрактом збережено',
      'Contract charge updated': 'Витрата за контрактом оновлено',
      'Contract charges': 'Контрактні витрати',
      'Contract conclusion date': 'Дата укладання контракту',
      'Contract connected.': "Контракт з'єднано.",
      'Contract creation': 'Створення контракту',
      'Contract crop year': 'Контракт рік урожаю',
      'Contract crops': 'Культура контракту',
      'Contract deal type': 'Тип угоди',
      'Contract details': 'Деталі контракту',
      "Contract doesn't exist": 'Контракт не існує',
      'Contract executed': 'Контракт виконано',
      'Contract executed. Containes three confirmation. Cargo - everything is ok with a delivery. Payment - everything is ok with payments. Final - everything is ok in general, and the contract might be considered as executed':
        'Контракт виконано. Він містить три підтвердження. Вантаж - все гаразд з доставкою. Оплата - все гаразд з платежами. Фінал - все в цілому гаразд , і контракт можна вважати виконаним',
      'Contract info': 'Інформація про контракт',
      'Contract margin': 'Маржа контракту',
      'Contract margin differs': 'Маржа за контрактами відрізняється',
      'Contract notes': 'Примітки до контракту',
      'Contract number': 'Номер контракту',
      'Contract numbers': 'Номери контрактів',
      'Contract option': 'Опція контракту',
      'Contract passports': 'Паспорти контракту',
      'Contract ports': 'Порт контракту',
      'Contract price': 'Ціна контракту',
      'Contract quantity': 'Кількість контракту',
      'Contract removed': 'Контракт видалено',
      'Contract result': 'Результат контракту',
      'Contract saved': 'Контракт збережено',
      'Contract status': 'Статус контракту',
      'Contract stock (t)': 'Залишки контракту (т)',
      'Contract stock amount': 'Сума залишків контракту',
      'Contract type': 'Тип контракту',
      'Contract updated': 'Контракт оновлено',
      'Contract value': 'Сума контракту',
      'Contract value in UAH': 'Сума контракту в UAH',
      'Contract value no VAT': 'Сума контракту без ПДВ',
      'Contract volume': "Об'єм контракту",
      'Contract volume (t)': "Об'єм контракту (т)",
      "Contract's Costs Opposite": 'Зустрічні витрати контракту',
      "Contract's Flat Price": 'Фіксована ціна контракту',
      "Contract's Flat Price Opposite": 'Зустрічна фіксована ціна контракту',
      "Contract's Open Volume": "Відкритий об'єм контракту",
      "Contract's Premium Price": 'Преміум ціна контракту',
      "Contract's Premium Price Opposite": 'Зустрічна преміум ціна контракту',
      ContractCharge: 'Витрати за контрактом',
      ContractQuality: 'Якість контракту',
      Contracted: 'Законтрактовано',
      Contracts: 'Контракти',
      'Contracts Execution': 'Виконання контрактів',
      'Contracts analytical': 'Аналітика контрактів',
      'Contracts control created': 'Створено контроль за контрактами',
      'Contracts created from this stock': 'Контракти, створені з цього залишку',
      'Contracts import': 'Імпорт контрактів',
      'Contracts quantity': "Об'єм контрактів",
      'Contracts should have same currency.': 'Контракти повинні мати однакову валюту.',
      'Contracts status updated': 'Статус контрактів оновлений',
      'Contracts total value': 'Загальна сума контрактів',
      'Contracts totals': 'Всього контрактів',
      'Contracts updated': 'Контракти оновлено',
      'Contracts value': 'Суми контрактів',
      Control: 'Контроль',
      'Control created': 'Контроль створено',
      'Control margin': 'Контроль маржі',
      'Controlled margin': 'Проконтрольована маржа',
      Controller: 'Контролер',
      'Controls on a page': 'Управління на сторінці',
      'Convert to contract': 'Перетворити на контракт',
      'Convert to ticket': 'Перетворити на тікет',
      Copy: 'Копія',
      'Copy to clipboard': 'Скопіювати',
      'Corr account': 'Кореспондентський рахунок',
      'Corr account 1': 'Кореспондентський рахунок 1',
      'Corr account 2': 'Кореспондентський рахунок 2',
      'Corr bank': 'Банк-кореспондент',
      'Corr bank 1': 'Банк-кореспондент 1',
      'Corr bank 2': 'Банк-кореспондент 2',
      'Corr iban': 'IBAN банку-кореспондента',
      'Corr iban 1': 'IBAN 1 банку-кореспондента',
      'Corr iban 2': 'IBAN 2 банку-кореспондента',
      'Corr swift': 'SWIFT банку-кореспондента',
      'Corr swift 1': 'SWIFT 1 банку-кореспондента',
      'Corr swift 2': 'SWIFT 2 банку-кореспондента',
      Correspondent: 'Кореспондент',
      Cost: 'Витрати',
      'Cost invoice': 'Інвойс на витрати',
      'Cost of goods sold': 'Собівартість реалізованих товарів',
      'Cost of production mUSD, with VAT': 'Вартість виробництва USD, з ПДВ',
      'Cost-Contract counterparty difference': 'Витрати-Контракт різниця за контрагентами',
      'Cost-Invoice counterparty difference': 'Витрати-Інвойс різниця за контрагентами',
      'Cost/gain': 'Витрати/дохід',
      Costs: 'Витрати',
      'Costs VAT': 'Витрати ПДВ',
      'Costs amount': 'Сума витрат',
      'Costs invoices': 'Інвойси на витрати',
      'Costs of goods': 'Собівартість',
      'Costs opposite': 'Зустрічні витрати',
      'Costs report': 'Звіт про витрати',
      'Costs/gains': 'Витрати/дохід',
      Count: 'Лічильник',
      Counterparties: 'Контрагенти',
      'Counterparties in contracts is not unique': 'Контрагенти в контрактах не унікальні',
      Counterparty: 'Контрагент',
      'Counterparty amount': 'Сума контрагента',
      'Counterparty bank': 'Банк контрагента',
      'Counterparty bank account': 'Банківський рахунок контрагента',
      'Counterparty business reference': 'Контрагент бізнес референс',
      'Counterparty confirmation. Completed if this Counterparty is confirmed by your organization':
        'Підтвердження контрагента. Завершено, якщо цей контрагент підтвердиться вашою організацією',
      'Counterparty confirmed': 'Контрагент підтвердив',
      'Counterparty created': 'Створено контрагента',
      'Counterparty creation': 'Створення контрагента',
      'Counterparty from': 'Контрагент від',
      'Counterparty saved': 'Контрагента збережено',
      'Counterparty to': 'Контрагент до',
      'Counterparty type': 'Тип контрагента',
      Countries: 'Країни',
      Country: 'Країна',
      'Country, city, street, app': 'Країна, місто, вулиця, офіс',
      'Counts by month': 'Кількість по місяцям',
      Create: 'Створити',
      'Create Balance Invoice': 'Створити баланс інвойсу',
      'Create Contract from this Ticket. Ticket will become Executed':
        'Створити Контракт з цього Тікета. Тікет буде вважатися Виконаним',
      'Create Email Template': 'Створити шаблон Email',
      'Create Prepayment Invoice': 'Створити інвойс передплати',
      'Create Screen Capture Task': 'Створити завдання для знімка екрану',
      'Create Supplier': 'Створити постачальника',
      'Create Ticket': 'Створити тікет',
      'Create Ticket from this Ticket': 'Створити Тікет на основі цього Тікета',
      'Create a collection': 'Створити конфігурацію',
      'Create a document': 'Створити документ',
      'Create a letter': 'Створити лист',
      'Create a new passport': 'Створити новий пароль',
      'Create a template': 'Створити шаблон',
      'Create an email': 'Створити email',
      'Create approvals for declined': 'Створити підтвердження для скасованих',
      'Create contract': 'Створити контракт',
      'Create contracts': 'Створити контракти',
      'Create contracts from tickets': 'Створити контракти з тікетів',
      'Create control': 'Створити контроль',
      'Create date': 'Дата створення',
      'Create document': 'Створити документ',
      'Create invoice': 'Створити інвойс',
      'Create invoices': 'Створити інвойси',
      'Create multicontract': 'Створити мультиконтракт',
      'Create new ticket': 'Створити новий тікет',
      'Create operation': 'Створити операцію',
      'Create passport': 'Створити паспорт',
      'Create passport with this contract': 'Створити паспорт з цим контрактом',
      'Create payment plan': 'Створити план оплат',
      'Create rolling': 'Створити ролінг',
      'Create ticket': 'Створити тікет',
      'Create time': 'Час створення',
      'Create transport': 'Створити транспорт',
      'Create user': 'Створити користувача',
      'Create vessel': 'Створити корабель',
      'Create voyage': 'Створити рейс',
      'Create your favorite Filter in Right Sidebar and it will appear here':
        'Створіть свій улюблений фільтр на правій бічній панелі, і він з’явиться тут',
      Created: 'Створено',
      'Created date': 'Дата створення',
      'Created via agreement': 'Створено за допомогою угоди',
      'Created via multicontract': 'Створено за допомогою мультиконтракту',
      'Creating invoices': 'Створення інвойсів',
      Creation: 'Створення',
      Credit: 'Кредит',
      'Credit Account': 'Кредит рахунок',
      'Credit Bank Account': 'Кредит банківського рахунку',
      'Credit Counterparty': 'Кредит контрагента',
      'Credit Finance Account': 'Кредит фінансового рахунку',
      'Credit Finance Account (commission)': 'Кредит фінансового рахунку (комісія)',
      'Credit Finance account': 'Кредит фінансового рахунку',
      'Credit Note': 'Кредит-нота',
      'Credit Notes': 'Кредит-ноти',
      'Credit Report': 'Звіт ризиків',
      'Credit account': 'Кредит рахунок',
      'Credit bank account': 'Кредит банківського рахунку',
      'Credit counterparty': 'Кредит контрагента',
      'Credit note': 'Кредит-нота',
      'Credit note number': 'Кредит-нота номер',
      'Credit note payments': 'Кредит-нота платежів',
      'Credit note removed': 'Кредит-ноту видалено',
      'Credit note saved': 'Кредит-ноту збережено',
      'Credit note updated': 'Кредит-ноту оновлено',
      'Credit notes': 'Кредит-ноти',
      'Credit report': 'Звіт ризиків',
      'Credit risk': 'Кредитний ризик',
      'Creditor Balance': 'Баланс кредиторів',
      Criminal: 'Кримінальна діяльність',
      Critical: 'Критичний',
      Crop: 'Культура',
      'Crop DSTU': 'Культура ДСТУ',
      'Crop Groups': 'Групи культур',
      'Crop Year': 'Рік врожаю',
      'Crop area': 'Посівна площа',
      'Crop class': 'Клас культури',
      'Crop price created': 'Ціна культури створена',
      'Crop price deleted': 'Ціну культури видалено',
      'Crop prices deleted': 'Ціни культури видалено',
      'Crop protection': 'Захист посівів',
      'Crop year': 'Рік врожаю',
      Cryptocurrency: 'Криптовалюта',
      Ct: 'Ct',
      Currencies: 'Валюти',
      Currency: 'Валюта',
      'Currency Exchange': 'Обмін валюти',
      'Currency Rate': 'Курс валюти',
      'Currency alternative': 'Валютна альтернатива',
      'Currency and rate': 'Валюта та курс',
      'Currency exchange': 'Обмін валюти',
      'Currency is required': 'Потрібна валюта',
      'Currency opposite': 'Валюта зустрічна',
      'Currency rate': 'Курс валюти',
      'Current Password': 'Поточний пароль',
      'Current address': 'Поточний адрес',
      'Current derivative': 'Поточний дериватив',
      'Current flat price': 'Поточна ціна',
      'Current goal': 'Поточна ціль',
      'Current opposite flat price': 'Поточна зустрічна ціна',
      'Custom cargo declaration removed': 'Митна вантажна декларація видалена',
      'Custom filter': 'Фільтр користувача',
      'Custom margin': 'Налаштування маржі',
      'Custom roles': 'Спеціальні ролі',
      'Custom status': 'Спеціальний статус',
      'Custom statuses': 'Спеціальні статуси',
      'Customize a fields': 'Налаштування полів',
      'Customize fields': 'Налаштування полів',
      Customs: 'Митниця',
      'Customs Broker': 'Митний брокер',
      'Customs broker': 'Митний брокер',
      'Customs declaration': 'Митна декларація',
      'Customs declarations': 'Митні декларації',
      DASHBOARD: 'ДАШБОРД',
      'DAT Self cost': 'DAT собівартість',
      'DATE RANGE': 'ДІАПАЗОН ДАТ',
      DATES: 'ДАТИ',
      'DATES & NOTES': 'ДАТИ & ПРИМІТКИ',
      'DBL SAP registered': 'DBL SAP зареєстровано',
      'DBL amount': 'Видаткова накладна сума',
      'DBL are tax registered': 'Видаткові накладні - зареєстровані для оподаткування',
      'DBL volume': "Видаткова накладна об'єм",
      DEALS: 'УГОДИ',
      DEBIT: 'ДЕБІТ',
      DECLINE: 'ВІДХИЛИТИ',
      DELAYS: 'ЗАТРИМКИ',
      DELIVERY: 'ДОСТАВКА',
      DERIVATIVES: 'ДЕРИВАТИВИ',
      'DESTINATION 1ST PORT': 'МІСЦЕ ПРИЗНАЧЕННЯ - 1-Й ПОРТ',
      'DESTINATION FINAL': 'КІНЦЕВИЙ ПУНКТ ПРИЗНАЧЕННЯ',
      DETAILS: 'ДЕТАЛІ',
      DIFF: 'РІЗНИЦЯ',
      'DIFF margin (ROS)': 'Різниця маржі (ROS)',
      'DISBURSEMENT B/L': 'ВИДАТКОВА НАКЛАДНА',
      'DISBURSMENT BILL OF LADING': 'ВИДАТКОВА НАКЛАДНА',
      'DISBURSMENT BILL OF LADINGS': 'ВИДАТКОВІ НАКЛАДНІ',
      DISPATCH: 'ВІДПРАВЛЕННЯ',
      DO: 'DO',
      DOCS: 'ДОКУМЕНТИ',
      DOCUMENTS: 'ДОКУМЕНТИ',
      'DOCUMENTS DATES': 'ДАТИ ДОКУМЕНТІВ',
      'DUE DATE': 'ТЕРМІН ВИКОНАННЯ',
      Daily: 'Щодня',
      Dark: 'Темна',
      Dashboard: 'Дашборд',
      Data: 'Дані',
      'Data updated': 'Дані оновлено',
      Date: 'Дата',
      'Date date': 'Дата',
      'Date from': 'Дата від',
      'Date of Disbursement bill of lading': 'Дата видаткової накладної',
      'Date of berth': 'Дата причалу',
      'Date of berth destination': 'Дата причалу (розвантаження)',
      'Date of berth loading': 'Дата причалу (завантаження)',
      'Date of certification': 'Дата сертифікації',
      'Date of conduction': 'Дата проведення',
      'Date of discharging end': 'Дата завершення розвантаження',
      'Date of discharging start': 'Дата початку розвантаження',
      'Date of documents withdrawal': 'Дата вилучення документів',
      'Date of execution': 'Дата виконання',
      'Date of execution fact': 'Дата виконання факт',
      'Date of future contact': 'Дата наступного контакту',
      'Date of issuance': 'Дата видачі',
      'Date of last contact': 'Дата останнього контакту',
      'Date of loading end': 'Дата завершення завантаження',
      'Date of loading start': 'Дата початку завантаження',
      'Date of notice': 'Дата повідомлення',
      'Date of notice destination': 'Повідомлення про дату місця призначення',
      'Date of notice loading': 'Повідомлення про дату завантаження',
      'Date of payment': 'Дата оплати',
      'Date of payment (fact)': 'Дата оплати (факт)',
      'Date of payment (plan)': 'Дата оплати (план)',
      'Date of receiving': 'Дати отримання',
      'Date of withdrawal': 'Дата вилучення',
      'Date range': 'Діапазон дат',
      'Date range of execution': 'Діапазон дат виконання',
      'Date to': 'Дати до',
      'Date, when the company was confirmed by us first time':
        'Дата, коли компанія була підтверджена вперше',
      'Date, when the company was officially confirmed by us':
        'Дата, коли компанія була офіційно підтверджена нами',
      'Date, when the documents were received from the company':
        'Дата, коли документи були отримані від компанії',
      'Date, when the documents were requested from the company':
        'Дата, коли документи були замовлені у компанії',
      'Dates are reqired': "Дати обов'язкові",
      Day: 'День',
      Days: 'Дні',
      'Days at port': 'Дні в порту',
      'Days before ending status': 'Днів до завершення статусу',
      "Days to set 'ending status'(end of execution)":
        'Дні до встановлення «фінального статусу» (кінець виконання)',
      Dbl: 'ВН',
      'Dbl date': 'Дата ВН',
      'Dead freight': 'Мертвий фрахт',
      'Deal type': 'Тип угоди',
      Deals: 'Угоди',
      Debit: 'Дебет',
      'Debit Account': 'Дебет рахунок',
      'Debit Bank Account': 'Дебет банківського рахунку',
      'Debit Counterparty': 'Дебет контрагента',
      'Debit Credit': 'Дебет - кредит',
      'Debit Finance Account': 'Дебет фінансового рахунку',
      'Debit Finance Account (commission)': 'Дебет фінансового рахунку (комісія)',
      'Debit Finance account': 'Дебет фінансового рахунку',
      'Debit Note': 'Дебет-нота',
      'Debit account': 'Дебет рахунок',
      'Debit bank account': 'Дебет банківського рахунку',
      'Debit counterparty': 'Дебет контрагента',
      'Debitor Balance': 'Баланс дебітора',
      Debts: 'Заборгованість',
      December: 'Грудень',
      Declaration: 'Декларація',
      'Declaration date': 'Дата декларації',
      'Declaration number': 'Номер декларації',
      'Declaration required': 'Потрібна декларація',
      'Declaration type': 'Тип декларації',
      Decline: 'Відхилено',
      'Decline approvals': 'Відхилити підтвердження',
      Declined: 'Відхилено',
      Default: 'За замовчуванням',
      'Default contracts view': 'Інтерфейс контрактів',
      'Default counterparties view': 'Інтерфейс контрагентів',
      'Default deals': 'Угоди за замовчуванням',
      'Default invoices view': 'Інтерфейс інвойсів',
      'Default passports view': 'Інтерфейс паспортів',
      'Default persons view': 'Інтерфейс контактів',
      'Default prepayment with this counterparty. You can specify another in each contract':
        'Передоплата за замовчуванням з цим контрагентом. Ви можете вказати іншу передоплату у кожному контракті',
      'Default vessels view': 'Інтерфейс кораблів',
      'Default voyages view': 'Інтерфейс рейсів',
      'Default warehouse': 'Склад за замовчуванням',
      Delay: 'Затримка',
      'Delayed payment': 'Затриманий платіж',
      Delete: 'Видалити',
      'Delete payment?': 'Видалити платіж?',
      'Delete price': 'Видалити ціну',
      Deleted: 'Видалено',
      'Deleting the': 'Видаляючи',
      Delivered: 'Доставлений',
      Deliverer: 'Експедитор',
      Delivery: 'Доставка',
      'Delivery (end date)': 'Доставка (кінцева дата)',
      'Delivery (start date)': 'Доставка (дата початку)',
      'Delivery Basis': 'Базис',
      'Delivery addresses': 'Адреси доставки',
      'Delivery condition': 'Умови доставки',
      'Delivery conditions': 'Умови доставки',
      'Delivery dates': 'Дати доставки',
      'Delivery estimated month': 'Очікуваний місяць доставки',
      'Delivery finished': 'Доставка завершена',
      'Delivery period': 'Термін поставки',
      'Delivery period sale contract': 'Період виконання продажного контракту',
      'Delivery status': 'Статус доставки',
      'Dem rate': 'Ставка демереджу',
      'Demmurage rate': 'Ставка демереджа',
      Demo: 'Демо',
      'Demo removed': 'Демо вилучено',
      Demurrage: 'Демереджа',
      'Demurrage rate': 'Ставка демереджа',
      Density: 'Щільність',
      'Depart station': 'Станція відправлення',
      Departed: 'Відправлено',
      'Departed consignment': 'Відправлена накладна',
      'Departed minus': 'Відправлено в мінус',
      'Departed plus': 'Відправлено в плюс',
      'Departed real': 'Відправлено',
      'Departed value': 'Вартість відправлення',
      'Departed volume': "Відправлений об'єм",
      'Departed w/o consignment': 'Відправлено без накладної',
      'Departure Station': 'Станції відправлення',
      'Departure station': 'Станції відправлення',
      'Depends on a stage of passport execution. Select which margin we will display in PNL':
        'Залежить від етапу оформлення паспорта. Виберіть, яку маржу ми будемо відображати в PnL',
      Derivative: 'Дериватив',
      'Derivative Accounts': 'Дериватив рахунки',
      'Derivative account': 'Рахунок деривативу',
      'Derivative mode': 'Режим деривативу',
      'Derivative ticker': 'Тікер деривативу',
      'Derivative title': 'Назва деривативу',
      'Derivative type': 'Тип деривативу',
      Derivatives: 'Деривативи',
      'Derivatives & Rolling': 'Деривативи та Ролінг',
      'Derivatives Position': 'Позиція деривативу',
      'Derivatives list': 'Список деривативів',
      'Derivatives margin': 'Маржа деривативів',
      Description: 'Опис',
      'Despatch rate': 'Despatch ставка',
      Destination: 'Призначення',
      'Destination 1st port': 'МІСЦЕ ПРИЗНАЧЕННЯ - 2-Й ПОРТ',
      'Destination Port': 'Порт призначення',
      'Destination country': 'Країна призначення',
      'Destination dem/dis': 'Призначення dem/dis',
      'Destination final port': 'Кінцевий порт призначення',
      'Destination port': 'Порт призначення',
      'Destination station': 'Станція призначення',
      Destinations: 'Призначення',
      'Detailed by responsible': 'Розглянуто відповідальним',
      Details: 'Деталі',
      'Did not happen': 'Не відбулася подія',
      Diff: 'Різниця',
      'Diff Plan | Fact': 'Різниця План | Факт',
      'Diff analytical': 'Аналітична різниця',
      'Difference = 0': 'Різниця = 0',
      'Difference > 0': 'Різниця > 0',
      'Difference >= 0.1': 'Різниця >= 0.1',
      Diffs: 'Різниці',
      'Disable previous template and clear all filter before create new.':
        'Відключити попередній шаблон і очистити весь фільтр перед створенням нового.',
      Disabled: 'Вимкнено',
      'Disbursement bill of lading': 'Видаткова накладна',
      'Disbursement bill of lading Amount incl. VAT, UAH':
        'Сума видаткової накладної (в т.ч. ПДВ) ,UAH',
      'Disbursement bill of lading Price incl. VAT, UAH':
        'Ціна видаткової накладної (в т.ч. ПДВ) ,UAH',
      'Disbursement bill of lading Quantity, mt': "Об'єм видаткової накладної, т",
      'Disbursement bill of lading removed': 'Видаткову накладну видалено',
      'Disbursement bill of lading saved': 'Видаткову накладну збережено',
      'Disbursement bl': 'Видаткова накладна',
      'Disbursement bl and reassignment execution': 'Виконання видаткової накладної та переуступки',
      'Disbursement bl value': 'Сума видаткової накладної',
      'Disbursement bl volume': "Об'єм видаткової накладної",
      'DisbursementBL created': 'Створено видаткову накладну',
      "Disbursment B/L's": 'Видаткові накладні',
      'Disch rate': 'Норма розвантаження',
      Discharge: 'Розвантаження',
      Discharged: 'Розвантажений',
      Discharging: 'Розвантажується',
      'Discharging place': 'Місце розвантаження',
      Discount: 'Дисконт',
      'Discount %': 'Дисконт %',
      'Discount / 1 mt': 'Дисконт / 1 т',
      'Discount amount': 'Розмір дисконту',
      'Discount calculation, %': 'Розрахунки дисконту, %',
      'Discount per': 'Дисконт за 1 т',
      'Discount per 1 mt': 'Дисконт за 1 т',
      'Discount, %': 'Дисконт, %',
      Discussion: 'Обговорення',
      'Disp rate': 'Ставка диспача',
      'Dispatch rate': 'Ставка диспача',
      'Disponent owner': 'Власник чартеру',
      Disport: 'Порт призначення',
      'Disselect all': 'Зняти відмітку з усіх',
      'Distribution by users': 'Розподіл за користувачами',
      Distributor: "Дистриб'ютор",
      Distributors: "Дистриб'ютори",
      'Distributors fact': "Факт дистриб'юторів",
      'Distributors plan': "План дистриб'юторів",
      District: 'Район',
      Districts: 'Райони',
      'Divide invoice amount for contracts': 'Розподіл суми інвойсу за контрактами',
      'Divide invoice amount for passports': 'Розподілити суму інвойсу на паспорти',
      'Do not pay': 'Не платити',
      'Do not update contracts': 'Не оновлювати контракти',
      'Do not use in Freight position': 'Не використовувати в позиціях фрахту',
      'Do not validate': 'Не перевіряти',
      'Do the compliance': 'Виконати комплаєнс',
      'Do you really want to delete this object ?': 'Ви справді бажаєте видалити об’ єкт?',
      'Do you want delete this Request?': 'Бажаєте видалити Тікет?',
      'Do you want replace sides in invoice?': 'Бажаєте замінити сторони в інвойсі?',
      'Do you want to clone this Consignment?': 'Бажаєте клонувати Накладну?',
      'Do you want to clone this Contract?': 'Бажаєте клонувати Контракт?',
      'Do you want to clone this Disbursement bill of lading?':
        'Бажаєте клонувати Видаткову накладну?',
      'Do you want to clone this General Agreement ?': 'Бажаєте клонувати угоду?',
      'Do you want to clone this Invoice?': 'Бажаєте клонувати Інвойс?',
      'Do you want to clone this Logistic?': 'Бажаєте клонувати Логістику?',
      'Do you want to clone this Person?': 'Бажаєте клонувати Контакт?',
      'Do you want to clone this Request?': 'Бажаєте клонувати Тікет?',
      'Do you want to clone this intermedate logistic?': 'Бажаєте клонувати проміжну логістику?',
      'Do you want to clone this object?': "Бажаєте клонувати цей об'єкт?",
      'Do you want to connect': "Бажаєте з'єднати",
      'Do you want to create a control?': 'Бажаєте створити Контроль?',
      'Do you want to create a supplier with short name  and role':
        'Ви хочете створити постачальника з короткою назвою  та роллю',
      'Do you want to create a user for this Person?':
        'Бажаєте створити користувача для цього контакту?',
      'Do you want to create contract?': 'Бажаєте створити контракт?',
      'Do you want to create mailing by  contracts from \\\n        selected  logisitc(s)?':
        'Бажаєте створити розсилку за контрактами з \\\n        обраною  логістикою?',
      'Do you want to create mailing by contracts from selected  invoice(s)?':
        'Бажаєте створити розсилку за контрактами з обраних інвойсів?',
      'Do you want to create multicontract?': 'Бажаєте створити мультиконтракт?',
      'Do you want to create payment by this plan?': 'Бажаєте створити оплату за цим планом?',
      'Do you want to create ticket?': 'Бажаєте створити тікет?',
      'Do you want to delete all passport connections?':
        "Бажаєте видалити всі паспортні з'єднання?",
      'Do you want to delete this mailing?': 'Бажаєте вилучити цю розсилку?',
      'Do you want to drop  mails?': 'Бажаєте скинути листи?',
      'Do you want to execute this passport?': 'Бажаєте оформити паспорт?',
      'Do you want to read all?': 'Бажаєте прочитати все?',
      'Do you want to reverse contract?': 'Бажаєте змінити контракт?',
      'Do you want to reverse request?': 'Хочете зробити бек-ту-бек тікет?',
      'Do you want to reverse ticket?': 'Бажаєте повернути тікет?',
      'Do you want to send  mails?': 'Бажаєте відправити листи?',
      'Do you want to update final volume from execution?':
        "Бажаєте оновити фінальний об'єм з виконання?",
      'Do you want to update logistics by discount?':
        'Вам потрібно оновити логістику із дисконтом?',
      'Do you want to update purchase price of logistics from indicator?':
        'Ви бажаєте оновити закупівельну ціну логістики з індикатора?',
      'Do you want to update status of logistics with limits?':
        'Вам потрібно оновити статус логістики з врахуванням лімітів?',
      'Doc removed': 'Документ видалено',
      Docs: 'Документи',
      'Docs approved': 'Документи підтверджено',
      'Docs approved by us': 'Підтверджені документи',
      'Docs received': 'Документи отримано',
      'Docs requested': 'Документи запит',
      Document: 'Документ',
      'Document ID': 'Документ ID',
      'Document copy': 'Копія документа',
      'Document one': 'Документ №1',
      'Document original': 'Оригінал документа',
      'Document saved': 'Документ збережено',
      'Document templates': 'Шаблони документів',
      'Document three': 'Документ №3',
      'Document two': 'Документ №2',
      'Document type': 'Тип документа',
      Documentation: 'Документація',
      Documents: "З'єднати документи",
      'Documents check-list': 'Перелік документів',
      'Documents dates': 'Дати документів',
      'Documents entry dates': 'Дати введення документів',
      'Documents saved': 'Документи збережено',
      'Documents. Completed if all documents per contract are received':
        'Документи. Заповнюється, якщо отримано всі документи за контрактом',
      "Don't use for KPI": 'Не використовувати KPI',
      'Don`t use kpi check': 'Не використовувати перевірку KPI',
      Done: 'Виконано',
      Download: 'Завантажити',
      'Download a template': 'Завантажити шаблон',
      'Download all': 'Завантажити все',
      Downloaded: 'Завантажено',
      Draft: 'Драфт',
      'Drag and drop a file': 'Перетягніть і скиньте файл',
      'Drag and drop to reorder': 'Перетягніть, щоб змінити порядок',
      'Driver first name': 'Ім’я водія',
      'Driver last name': 'Прізвище водія',
      'Driver middle name': 'По батькові водія',
      'Driver passport seria & number': 'Серія та номер паспорта водія',
      'Driver phone number': 'Номер телефону водія',
      'Driver`s first name': 'Ім’я водія',
      'Driver`s last name': 'Прізвище водія',
      'Driver`s middle name': 'По-батькові водія',
      'Drop all connections to passport': "Скинути всі прив'язки до паспорта",
      'Drop connections': "Скинути прив'язки",
      'Drop selected': 'Відмінити вибір',
      Drying: 'Сушіння',
      Dt: 'Dt',
      'Due date': 'Термін виконання',
      'Due diligence': 'Належна перевірка',
      'E-mail': 'E-mail',
      'E.g. you have a chain from inland EXW purchase to CIF sale via several counteparties\n              - you might use two or more passport for each part of the chain.':
        'Наприклад, у Вас є ланцюг від внутрішньої покупки EXW до продажу CIF через кількох контрагентів \n              - можна використовувати два або більше паспортів для кожної частини ланцюга.',
      'EDUCATION MODE in either company or in your personal settings is':
        'РЕЖИМ НАВЧАННЯ в компанії або в особистих налаштуваннях',
      ENABLED: 'УВІМКНЕНО',
      ENTER: 'Введіть',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'EUR 1': 'EUR 1',
      'EUR 1 (date)': 'EUR 1 (дата)',
      'EUR 1 required': 'Потрібен 1 EUR',
      EXECUTION: 'ВИКОНАННЯ',
      'EXECUTION VALUE': 'СУМА ВИКОНАННЯ',
      EXPORT: 'ЕКСПОРТ',
      'EXPORTER CONFIRMATION': 'ПІДТВЕРДЖЕННЯ ЕКСПОРТЕРА',
      EXPORTERS: 'ЕКСПОРТЕРИ',
      'Economic activities code': 'КВЕД',
      Edit: 'Редагувати',
      'Edit (admin)': 'Редагування (адмін)',
      'Edit Users': 'Редагувати користувачів',
      Editor: 'Редактор',
      'Education mode': 'Режим навчання',
      Elevator: 'Елеватор',
      'Elevator Name': 'Назва елеватора',
      'Elevator cost': 'Витрати елеватора',
      'Elevator losts': 'Елеватор втрати',
      Elevators: 'Елеватори',
      Email: 'Email',
      'Email Templates': 'Шаблони Email',
      'Email max length': 'Максимальна довжина Email',
      'Email preview': 'Попередній перегляд Email',
      'Email templates': 'Шаблони Email',
      Emails: 'Emails',
      'Empty finances list': 'Пустий список інвойсів',
      Enabled: 'Увімкнено',
      'Enabled only if one export contract is added':
        'Доступно лише у разі додавання одного експортного контракту',
      'Enabled only if one sale contract is added':
        'Активно лише при одному доданому продажному контракті',
      End: 'Кінець',
      'End balance': 'Кінцевий баланс',
      'End date': 'Кінцева дата',
      'End of execution': 'Кінець виконання',
      'End usage (date)': 'Кінець використання (дата)',
      Ending: 'Закінчення',
      'Ending cash': 'Залишок на кінець',
      'Ending status': 'Фінальний статус',
      Eng: 'Англ',
      English: 'English',
      'Entry date / Exit date': 'Дата в’їзду / Дата виїзду',
      'Estimated P&L': 'Очікуваний PnL',
      'Estimated Q (T)': 'Очікувана кількість (т)',
      'Estimated receiving (date)': 'Прогнозована дата отримання',
      'Estimated volume of the deal for contract allocation':
        "Очікуваний об'єм угоди для розподілу контракту",
      'Eur.1': 'Eur 1',
      Europe: 'Європа',
      Event: 'Подія',
      'Event Subscription': 'Підписка на подію',
      'Event subscriptions': 'Підписки на події',
      Exact: 'Визначений',
      Exchange: 'Обмін',
      'Exchange rate': 'Курс обміну',
      'Exchange rate alternative': 'Додатковий курс обміну',
      Exchanges: 'Курси',
      'Exclude from positions': 'Не враховувати у позиціях',
      'Exec forecasted amount': 'Очікувана сума за угоду',
      'Execute contracts': 'Виконання контрактів',
      Executed: 'Виконано',
      'Executed - Paid': 'Виконано - Оплачено',
      'Executed qty': 'Виконана кількість',
      Execution: 'Виконання',
      'Execution (end date)': 'Виконання (дата завершення)',
      'Execution (start date)': 'Виконання (дата початку)',
      'Execution (t)': 'Виконання (т)',
      'Execution - Invoices': 'Виконання - Інвойси',
      'Execution Dates': 'Дати виконання',
      'Execution amount': 'Сума виконання',
      'Execution date': 'Дата виконання',
      'Execution end': 'Кінець виконання',
      'Execution end date': 'Кінцева дата виконання',
      'Execution estimated month': 'Очікуваний місяць виконання',
      'Execution margin': 'Маржа виконання',
      'Execution month': 'Місяць виконання',
      'Execution of loyalty programs': 'Виконання програм лояльності',
      'Execution period': 'Термін виконання',
      'Execution start': 'Початок виконання',
      'Execution start date': 'Дата початку виконання',
      'Execution value': 'Сума виконання',
      Expenses: 'Витрати',
      Expiration: 'Експірація',
      'Expiration date of power of attorney': 'Закінчення терміну дії довіреності',
      'Expiration end': 'Закінчення строку дії',
      'Expiration start': 'Початок строку дії',
      Expired: 'Закінчився термін',
      Expiring: 'Термін закінчується',
      Export: 'Експорт',
      'Export Contract': 'Експортний контракт',
      'Export contract': 'Експортний контракт',
      'Export invoice': 'Інвойс для експорту',
      'Export invoiced': 'Виставлено експортний інвойс',
      'Export tax': 'Податок на експорт',
      'Export tax:': 'Податок на експорт:',
      Exporter: 'Експортер',
      'Exporter address': 'Адреса експортера',
      'Exporter can signatory docs': 'Експортер може підписувати документи',
      'Exporter can signatory docs eng': 'Експортер може підписувати документи (англ)',
      'Exporter contract (#)': 'Експортер (контракт №)',
      'Exporter country': 'Експортер країна',
      'Exporter country eng': 'Експортер країна (англ)',
      'Exporter email': 'Експортер email',
      'Exporter full name': 'Повна назва експортера',
      'Exporter full name eng': 'Повна назва експортера (англ)',
      'Exporter legal address': 'Юридична адреса експортера',
      'Exporter legal address eng': 'Юридична адреса експортера (англ)',
      'Exporter list': 'Список експортерів',
      'Exporter list eng': 'Список експортерів (англ)',
      'Exporter name': 'Назва експортера',
      'Exporter phone': 'Телефон експортера',
      'Exporter signatory': 'Підписант експортера',
      'Exporter signatory eng': 'Підписант експортера (англ)',
      'Exporter website': 'Експортер веб-сайт',
      Exporters: 'Експортери',
      'Exporters eng': 'Експортери (англ)',
      'Exporters list': 'Список експортерів',
      'Exporters list eng': 'Список експортерів (англ)',
      Exposition: 'Результат',
      'Exposition 12,70 Cts/Bu ou -5$/T': 'Результат 12,70 Cts/Bu ou -5$/T',
      'Exposition 25,40 Cts/Bu ou -10$/T': 'Результат 25,40 Cts/Bu ou -10$/T',
      'Exposition, Mt': 'Ризик, т',
      Ext: 'Ext',
      Extension: 'Пролонгація',
      'Extension (end)': 'Пролонгація (кінець)',
      'Extension (start)': 'Пролонгація (початок)',
      'Extra actions/buttons': 'Додаткові дії/кнопки',
      FACT: 'ФАКТ',
      FILTERS: 'ФІЛЬТРИ',
      FINANCE: 'ФІНАНСИ',
      'FINANCE REPORTS': 'ФІНАНСОВІ ЗВІТИ',
      FINANCES: 'ФІНАНСИ',
      'FOB price': 'FOB ціна',
      FREIGHT: 'ФРАХТ',
      'FUNDS DISTRIBUTION': 'РОЗПОДІЛ КОШТІВ',
      Fact: 'Факт',
      'Fact created': 'Створено факт',
      'Fact crop protection': 'Факт захист урожаю',
      'Fact distributor': "Факт дистриб'ютора",
      'Fact distributors': "Факт дистриб'юторів",
      'Fact seeds': 'Факт посіву',
      Facts: 'Факти',
      Fail: 'Невдача',
      Failed: 'Помилка',
      'Failure reasons': 'Причини невдачі',
      Farm: 'Господарство',
      Farms: 'Господарства',
      Favorite: 'Улюблений',
      'Favorite Filters': 'Улюблені фільтри',
      'Favorite filter': 'Улюблений фільтр',
      'Favourite filters': 'Улюблені фільтри',
      February: 'Лютий',
      'Fee amount': 'Сума комісії',
      'Few departure stations': 'Декілька станцій відправлення',
      'Few prices': 'Декілька цін',
      Field: 'Поле',
      File: 'Файл',
      "File '' is to big": 'Файл занадто великий',
      'Fill by template': 'Заповнити за шаблоном',
      'Fill if you want to calculate MTM by this contract':
        'Заповніть, якщо ви хочете розрахувати MTM за цим контрактом',
      'Fill it if only there are some differences from standard compliance process. This will cause APPROVED WITH CONDITION status':
        'Заповніть, якщо тільки є деякі відмінності від стандартного процесу комплаєнсу. У цьому випадку статус буде \nПІДТВЕРДЖЕНО З УМОВОЮ',
      'Fill it only if you want this contract to be converted by a certain rate':
        'Заповніть, якщо ви хочете, щоб цей контракт був конвертований за певним курсом',
      'Fill it to display Invoice in Budget report':
        'Заповніть, щоб показати інвойс у звіті про бюджет',
      Filter: 'Фільтр',
      'Filter Set removed': 'Фільтр видалено',
      'Filter Set updated': 'Фільтр оновлено',
      'Filter by': 'Фільтрувати за',
      'Filter by selected business units': 'Фільтр за вибраними бізнес-юнітами',
      Filters: 'Фільтри',
      'Fin purpose': 'Фінансова ціль',
      Final: 'Фінал',
      'Final Amount': 'Фінальна сума',
      'Final Volume (T)': "Фінальний об'єм (т)",
      'Final amount': 'Фінальна сума',
      'Final approve': 'Фінальне підтвердження',
      'Final confirmation': 'Фінальне підтвердження',
      'Final volume': "Фінальний об'єм",
      'Final volume equals connections': "Фінальний об'єм дорівнює з’єднанням",
      Finance: 'Фінанси',
      'Finance Account': 'Фінансовий рахунок',
      'Finance Bank': 'Фінанси банк',
      'Finance account': 'Фінансовий рахунок',
      'Finance accounts': 'Фінансові рахунки',
      'Finance credit account': 'Кредит фінансового рахунку',
      'Finance days': 'Фінансові дні',
      'Finance debit account': 'Дебет фінансового рахунку',
      "Finance doesn't exist": 'Фінансів не існує',
      'Finance offset value': 'Фінанси залік вартість',
      'Finance passport position': 'Позиція фінансів паспорта',
      'Finance reports': 'Фінансові звіти',
      'Finances mailing': 'Розсилка інвойсів',
      'Financial purpose': 'Фінансова ціль',
      Financing: 'Фінансування',
      'Find a filter': 'Знайти фільтр',
      'First (validation)': 'Перша (валідація)',
      'First - select a invoices previously filtered them Second - press Create an Emails\n                Third - look at the table below, you email will be ready to preview, gently check\n                it and then send.':
        'По-перше – виберіть інвойси, які попередньо відфільтрували. По-друге – натисніть «Створити Emails». \n                По-третє – подивіться на таблицю нижче, ваш лист буде готовий для попереднього перегляду, \n                перевірте дані та відправте.',
      'First Name': "Ім'я",
      'First Name is required': 'Потрібно вказати ім’я',
      'First charge': 'Перша витрата',
      'First confirm date': 'Дата першого підтвердження',
      'First confirmation (date)': 'Перше підтвердження (дата)',
      'First confirmed': 'Перше підтвердження',
      'First contact': 'Перший контакт',
      'First date must be less then Second': 'Перша дата має бути меншою, ніж друга',
      'First day of the week': 'Перший день тижня',
      'First name': "Ім'я",
      Fixed: 'Зафіксовано',
      'Fixed lots': 'Зафіксовані лоти',
      'Fixed price bonus': 'Фіксований бонус за ціною',
      'Fixed tonnes': 'Зафіксовані тонни',
      Fixing: 'Фіксинг',
      'Fixing and hedging': 'Фіксинг та Хеджування',
      'Fixing position': 'Фіксинг позиція',
      'Fixing/Hedging': 'Фіксинг/Хеджування',
      Flag: 'Прапор',
      'Flat price per not fixed lots': 'Фіксована ціна за нефіксовані лоти',
      'Flat price per not hedged lots': 'Фіксована ціна за нехеджовані лоти',
      Fobbing: 'Фобінг',
      'Folder number': 'Номер папки',
      Font: 'Шрифт',
      'For KPI year report': 'Для звіту КПІ за рік',
      'For example if the contract qty is 5000 and this contract has cost of 1000$ and\n                  you allocated this contract on two passports 2500 and 2500 respectively':
        "Наприклад, якщо об'єм контракту становить 5000 і цей контракт має вартість 1000 usd,\n                   і ви розподілили цей контракт на два паспорти 2500 і 2500 відповідно",
      'For purposes of filtering information by specific season':
        'Для фільтрації інформації за конкретним сезоном',
      Forecast: 'Прогноз',
      'Forecasted - Payment': 'Очікування - Оплати',
      'Foreign currency': 'Валюта',
      'Form invalid': 'Форма недійсна',
      Forward: 'Форвард',
      'Forward fact': 'Форвард факт',
      'Forward limit': 'Форвард ліміт',
      Forwarder: 'Експедитор',
      'Forwarder contract': 'Контракт з експедитором',
      'Forwarder railway': 'Експедитор залізниця',
      Forwarders: 'Експедитори',
      Fr: 'Fr',
      'Free days EWRP': 'Вільні дні EWRP',
      'Free days JCC': 'Вільні дні JCC',
      'Free tax invoice': 'Інвойс без податків',
      Freight: 'Фрахт',
      'Freight Info': 'Інформація про фрахт',
      'Freight Position': 'Позиція фрахту',
      'Freight VAT': 'Фрахт ПДВ',
      'Freight broker costs': 'Вартість брокера фрахту',
      'Freight costs': 'Фрахт витрати',
      'Freight level': 'Фрахт',
      'Freight position + MTM': 'Позиція фрахту + МТМ',
      Freights: 'Фрахти',
      From: 'Від',
      'From user': 'Від користувача',
      'From which country purchased': 'З якої країни закупили',
      'From whom': 'Від кого',
      'Full amount': 'Повна сума',
      'Full company name, LLC': 'Повна назва компанії, ТОВ',
      'Full current address': 'Повна поточна адреса',
      'Full name': 'Повна назва',
      'Full name (eng)': 'Повна назва (англ.)',
      'Full name Eng': 'Повна назва (англ.)',
      'Full name eng': 'Повна назва (англ.)',
      'Full name of person who signs documents by this company':
        'ПІБ особи, яка підписує документи від імені цієї компанії',
      'Full name of the Signatory to use in requisites while generating documents':
        'ПІБ підписанта, яке слід використовувати в реквізитах при генерації документів',
      'Full name to use in requisites while generating documents':
        'Для використання в реквізитах при створенні документів',
      'Future contact': 'Наступний контакт',
      'Future deleted': "Ф'ючерс видалено",
      'Future updated': "Ф'ючерс оновлено",
      Futures: "Ф'ючерси",
      GAINS: 'ДОХОДИ',
      GENERAL: 'ЗАГАЛЬНЕ',
      'GENERAL AGREEMENT': 'УГОДА',
      'GENERAL INFO': 'ЗАГАЛЬНА ІНФОРМАЦІЯ',
      'GENERAL TOTAL': 'ЗАГАЛЬНА СУМА',
      Gains: 'Доходи',
      General: 'Загальне',
      'General Agreement': 'Угода',
      'General Expenses': 'Загальні витрати',
      'General Transaction': 'Загальна транзакція',
      'General Transactions': 'Загальні транзакції',
      'General agreement': 'Рамкова угода',
      'General agreements': 'Рамкова угода',
      'General expense': 'Загальні витрати',
      'General expenses': 'Загальні витрати',
      'General info': 'Загальна інформація',
      'General transactions': 'Загальні операції',
      Generate: 'Створити',
      'Generate Document': 'Створити документ',
      'Generate a document': 'Створити документ',
      'Generate a preview': 'Згенерувати попередній перегляд',
      'Generate agreement number': 'Згенерувати номер угоди',
      'Generate an email': 'Створити email',
      'Generate contract name': 'Створити назву контракту',
      'Generate document': 'Створити документ',
      'Generate loan name': 'Створити назву позики',
      'Generate name': 'Згенерувати назву',
      'Generate number': 'Згенерувати номер',
      'Get counterparty data': 'Отримати дані контрагента',
      'Go to a PNL report': 'Перейти до звіту PnL',
      'Go to details': 'Перейти до деталей',
      Goal: 'Ціль',
      'Google clean': 'Очистка Google',
      'Grain Quality Check': 'Перевірка якості',
      Green: 'Зелена',
      Grey: 'Сіра',
      'Gross Closed': 'Брутто закрито',
      'Gross Margin': 'Валова маржа',
      Group: 'Група',
      'Group by': 'Групувати за',
      'Group passports into a chain': 'Згрупуйте паспорти в ланцюг',
      'Group totals': 'Загальні суми групи',
      Grouped: 'Згруповані',
      Grouping: 'Групування',
      'Grouping criteria': 'Критерії групування',
      Groups: 'Групи',
      HISTORY: 'ІСТОРІЯ',
      HO: 'HO',
      'Has Amount Per Deal Execution': 'Має суму за виконання угоди',
      'Has Analytical amount': 'Має аналітичну суму',
      'Has BL': 'Має коносамент',
      'Has Business Unit': 'Має бізнес-юніт',
      'Has Discount': 'Має дисконт',
      'Has Exec Forecasted Amount': 'Має очікувану сума за угоду',
      'Has Final amount': 'Має фінальну суму',
      'Has LOI': 'Має LOI',
      'Has Our amount': 'Має нашу суму',
      'Has Payment Info': 'Має інформацію про оплату',
      'Has Voyage': 'Має рейс',
      'Has additional info': 'Має додаткову інформацію',
      'Has all docs copy of DBL': 'Має всі копії документів видаткової накладної',
      'Has amount reconciliation': 'Має звірку суми',
      'Has balance': 'Має баланс',
      'Has balance prepay': 'Має передоплату балансу',
      'Has balance to connect': "Має баланс для з'єднання",
      'Has basis doc': 'Має базис документу',
      'Has broker': 'Має брокера',
      'Has buyer contract cargo confirmation': 'У продажного контракту вантаж підтверджений',
      'Has buyer contract final confirmation': 'У продажного контракту є кінцеве підтвердження',
      'Has buyer contract payment confirmation': 'У продажного контракту оплата підтверджена',
      'Has cancelled invoice': 'Має скасований інвойс',
      'Has cancelled signature': 'Підписання скасовано',
      'Has cargo confirmation': 'Має підтвердження вантажу',
      'Has comments': 'Має коментарі',
      'Has contract': 'Має контракт',
      'Has contract plans': 'Має план контракту',
      'Has control date': 'Має дату контролю',
      'Has date': 'Має дату',
      'Has date of execution': 'Має дату виконання',
      'Has destination agent': 'Є агент в країні призначення',
      'Has destination intermediate agent': 'Є агент-посередник в країні призначення',
      'Has diff limit': 'Має обмеження за лімітом',
      'Has disbursement bill of lading': 'Має видаткову накладну',
      'Has docs': 'Має документи',
      'Has docs copy': 'Має копію документів',
      'Has docs original': 'Має оригінал документів',
      'Has documents': 'Має документи',
      'Has export invoice': 'Має інвойс експорту',
      'Has export logistics': 'Має логістику експорту',
      'Has fact crop protection': 'Є факт захисту врожаю',
      'Has fact seeds': 'Має факт посів',
      'Has final confirmation': 'Має остаточне підтвердження',
      'Has fixing': 'Має фіксінг',
      'Has grain quality check': 'Має перевірку якості',
      'Has hedging': 'Має хеджування',
      'Has incoming invoice': 'Має вхідний інвойс',
      'Has invoice': 'Має інвойс',
      'Has invoice documents': 'Має документи на інвойс',
      'Has issuer counterparty': 'Видав контрагенту',
      'Has limit': 'Має ліміт',
      'Has loading agent': 'Є агент в країні завантаження',
      'Has loading intermediate agent': 'Є агент-посередник в країні завантаження',
      'Has logistic': 'Має логістику',
      'Has no basis doc': 'Немає базису документа',
      'Has note': 'Має ноту',
      'Has offset': 'Має залік',
      'Has originals received': 'Оригінали отримано',
      'Has outgoing invoice': 'Має вихідний інвойс',
      'Has own purchase price': 'Має власну закупівельну ціну',
      'Has own sale price': 'Має власну продажну ціну',
      'Has passport or contract': 'Має паспорт або контракт',
      'Has payment': 'Має оплату',
      'Has payment confirmation': 'Має підтвердження оплати',
      'Has plan crop protection': 'Є план захисту врожаю',
      'Has plan seeds': 'Має план посів',
      'Has povitive balance': 'Є позитивний баланс',
      'Has prepay': 'Має передплату',
      'Has purchase facts': 'Має факти закупівлі',
      'Has reassignment': 'Має переуступку',
      'Has receiver counterparty': 'Має контрагента-одержувача',
      'Has responsible': 'Має відповідальність',
      'Has sale facts': 'Має факт продажу',
      'Has sale internal': 'Має внутрішню угоду на продаж',
      'Has season': 'Має сезон',
      'Has several passports': 'Має декілька паспортів',
      'Has supplier contract cargo confirmation': 'У закупівельного контракту вантаж підтверджено',
      'Has supplier contract final confirmation': 'У контракту закупівлі є кінцеве підтвердження',
      'Has supplier contract payment confirmation':
        'У закупівельного контракту оплата підтверджена',
      'Has volume reconciliation': 'Має звірку об’ємів',
      'Has volume with docs': "Має об'єм з документами",
      'Have to confirm': 'Потрібно підтвердити',
      'Heads of regional manager': 'Керівники відділу продажів',
      'Heads of sale managers': 'Керівники відділу продажів',
      Hedged: 'Захеджовано',
      'Hedged lots': 'Хеджовані лоти',
      Hedging: 'Хеджування',
      'Help buttons expanded': 'Розгорнути кнопки допомоги',
      'Here are the similar companies by name. Check if your current company is not conflicting with existing ones.':
        'Показані подібні компанії за назвою. Перевірте, чи Ваша поточна компанія не збігається з існуючими.',
      'Here is the list of all passports. Passport is the full cycle of the deal from\n                beginning to the end.':
        'Ось список всіх паспортів. Паспорт - це повний цикл угоди від початку до кінця.',
      'Here you can allocate purchase side with the sale side and GT will do everything by\n                itself: calculate profitability, consolidate costs, documents, invoices in one\n                place.':
        'Тут ви можете розподілити сторону закупівлі із стороною продажу і GrainTrack все зробить cамостійно: \n                розрахує рентабельність, консолідує витрати, документи, інвойси \n                в одному місці.',
      'Here you can type full title of the company with type of ownership, etc. Example - Full company name, LLC':
        'Тут ви можете ввести повну назву компанії з типом власності і т.д. Наприклад - повна назва компанії, ТОВ',
      Hide: 'Сховати',
      'Hide Users': 'Сховати користувачів',
      'Hide all': 'Сховати все',
      'Hide child crops': 'Сховати дочірні культури',
      'Hide executed': 'Сховати виконано',
      'Hide fixed contracts': 'Сховати фіксовані контракти',
      'Hide full table': 'Сховати всю таблицю',
      'Hide history': 'Сховати історію',
      'Hide inactive accounts': 'Приховати неактивні рахунки',
      'Hide passport without accounts': 'Приховати паспорт без відповідних рахунків',
      High: 'Високий',
      History: 'Історія',
      Hot: 'Гарячий',
      Hours: 'Години',
      'How much to take from deal?': 'Скільки взяти з угоди?',
      IBAN: 'IBAN',
      ID: 'ID',
      IMO: 'IMO',
      IN: 'Вхід',
      INCOMING: 'ВХІДНИЙ',
      INDICATORS: 'ІНДИКАТОРИ',
      INFO: 'ІНФО',
      'INITIAL DATE': 'ПОЧАТКОВА ДАТА',
      INTERMEDIATE: 'ПРОМІЖНА',
      'INTERMEDIATE LOGISTICS': 'ПРОМІЖНА ЛОГІСТИКА',
      'INVOICE DOCUMENTS': 'ІНВОЙС ДОКУМЕНТИ',
      'INVOICE POSITIONS': 'ІНВОЙС ПОЗИЦІЇ',
      INVOICED: 'ВИСТАВЛЕНИЙ ІНВОЙС',
      'INVOICED VALUE': 'СУМА ІНВОЙСІВ',
      INVOICES: 'ІНВОЙСИ',
      'INVOICES LIST': 'ПЕРЕЛІК ІНВОЙСІВ',
      'INVOICES POSITION': 'ПОЗИЦІЯ ІНВОЙСІВ',
      ISCC: 'ISCC',
      ITN: 'ІПН',
      'If company has more than one number': 'Якщо компанія має більше одного номера',
      'If current company is a part of a bigger group':
        'Якщо поточна компанія входить до складу більшої групи',
      'If you have a complicated chain you will need it.':
        'Якщо у вас складний ланцюг, то вам знадобиться це.',
      'If you pick something here, information about this object will be displayed only for people from those business units':
        'Якщо ви виберете щось тут, інформація про цей об’єкт буде відображатися лише для людей з цих бізнес-юнітів',
      'If you want fill by template': 'Якщо ви бажаєте заповнити за допомогою шаблону',
      'If you want so set negotiation process and defined whether charge is validated or under discussion':
        'Якщо ви бажаєте встановити процес підтвердження і визначити, що витрата підтверджується або обговорюється',
      'If you want to assign some custom role to this company':
        'Якщо ви хочете призначити певну персональну роль для цієї компанії',
      'If you want to convert all financial data in passport by one rate to USD':
        'Якщо ви хочете конвертувати всі фінансові дані в паспорті за одним курсом в USD',
      'Ignore client status': 'Ігнорувати статус контрагента',
      'Ignore counterparty limits': 'Ігнорувати ліміти контрагента',
      'Ignore counterparty status': 'Ігнорувати статус контрагента',
      'Ignore limits': 'Ігнорувати ліміти',
      Imo: 'Imo',
      Import: 'Імпорт',
      Imported: 'Імпортовано',
      In: 'Вхід',
      'In date': 'Дата отримання',
      'In general, passport is one vessel, which can contain sale and purchases in\n                different configurations: 1 sale vs 1 purchase, 1vs3, 5vs2, whatever. Or if it is\n                inland deal - one passport is just some sale in from of some purchase even without\n                a vessel.':
        'Зазвичай паспорт є одним кораблем, який може містити продаж і закупівлю в різних конфігураціях:\n                1 продаж проти 1 закупівлі, 1 проти 3, 5 проти 2, що завгодно. \n                Або якщо це внутрішня угода - один паспорт - це просто продажа з якоїсь закупівлі \n                навіть без корабля.',
      'In number': 'Вхідний номер',
      'In process': 'В процесі',
      'In progress': 'В процесі',
      'In the case, if contract is PURCHASE - then EXPORTERS are chosen amont the exporters of SUPPLIER of this contractIf the contract is SALE, then EXPORTERS are chose from PURCHASE contracts, that are linked with this SALE contractvia passport. So, to add Exporters to Sale contract, at first, link this Sale contract with other Purchase contracts via Passport.':
        "У випадку, якщо контракт Є ЗАКУПІВЕЛЬНИЙ - тоді ЕКСПОРТЕРІВ вибираємо з експортерів ПОСТАЧАЛЬНИКА.\nЯкщо контракт ПРОДАЖНИЙ, тоді ЕКСПОРТЕРИ самі вибираються з усіх ЗАКУПІВЕЛЬНИХ контрактів, які пов'язані з ПРОДАЖНИМ контрактом через ПАСПОРТ.\nОтже, для того, щоб додати Експортерів до Продажного контракту, зв'яжіть цей Продажний контракт із Закупівельними через Паспорт.",
      'In the case, if contract is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners':
        'Якщо контракт ЗАКУПІВЕЛЬНИЙ - тоді ПОКУПЦЕМ є наша компанія. Її можна вибрати зі списку Власників',
      'In the case, if contract is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners':
        'Коли контракт ПРОДАЖНИЙ - ПОСТАЧАЛЬНИКОМ є наша компанія. Її можна вибрати зі списку власників',
      'In the case, if general agreement is PURCHASE - then the BUYER is our company. It is chosen from the list of Owners':
        'Якщо контракт ЗАКУПІВЕЛЬНИЙ - тоді ПОКУПЦЕМ є наша компанія. Її можна вибрати зі списку Власників',
      'In the case, if general agreement is SALE - then the SUPPLIER is our company. It is chosen from the list of Owners':
        'Коли контракт ПРОДАЖНИЙ - ПОСТАЧАЛЬНИКОМ є наша компанія. Її можна вибрати зі списку власників',
      'In this list of notifications you have a possibility to receive instant information\n                about some actions in the system. Contracts created, invoices paid, vessel arrived,\n                etc.':
        'У цьому списку повідомлень є можливість отримувати миттєву інформацію \n                про деякі дії в системі. Створені контракти, оплачені інвойси, прибуття корабля\n                і т.д.',
      Inactive: 'Неактивний',
      'Include in amount': 'Включити в суму',
      'Included in amount': 'Включено в суму',
      Incomes: 'Доходи',
      Incoming: 'Вхідний',
      'Incoming (date)': 'Вхідний (дата)',
      'Incoming - all invoiced issued to us (means we will spend cash). Outgoing - all invoices issued by us (means: we will receive cash)':
        'Вхідний - всі видані нам інвойси (означає, що ми витратимо кошти). Вихідні - всі виставлені нами рахунки (означає, що ми отримаємо кошти)',
      'Incoming - invoice is received from counterparty, Outgoing - invoice is issued by us':
        'Вхідний - інвойс отримується від контрагента, Вихідний - рахунок виставляється нами',
      'Incoming balance': 'Вхідний баланс',
      'Incoming invoice status': 'Статус вхідного інвойсу',
      'Incoming invoices': 'Вхідні інвойси',
      'Incoming payments': 'Вхідні оплати',
      'Incoming volume': "Вхідний об'єм",
      Incoterms: 'Базис',
      Indicator: 'Індикатор',
      'Indicator creation': 'Створення індикатора',
      'Indicator crops': 'Індикатор культури',
      'Indicator price': 'Ціна індикатора',
      'Indicator saved': 'Індикатор збережено',
      Indicators: 'Індикатори',
      'Indicators (spot)': 'Індикатори (спот)',
      'Individual tax number': 'Індивідуальний податковий номер',
      Info: 'Інфо',
      'Info for traders': 'Інформація для трейдерів',
      'Information about company as a tax payer': 'Інформація про компанію як платника податків',
      'Initial amount': 'Початкова сума',
      'Initial balance': 'Початковий баланс',
      'Initial cash': 'Початкова сума',
      'Initial cash is required': 'Потрібна початкова сума',
      'Initial date': 'Початкова дата',
      Initiator: 'Ініціатор',
      Instance: 'Сутність',
      'Instructions sent': 'Інструкції відправлено',
      'Insurance status': 'Статус страхування',
      Insurer: 'Страховик',
      Insurers: 'Страховики',
      'Intake volume': "Об'єм завантаження",
      'Interest amount': 'Сума відсотків',
      'Interest payment date': 'Дата сплати відсотків',
      'Interest payment date (plan)': 'Дата сплати відсотків (план)',
      'Interest rate': 'Процентна ставка',
      'Interest rate, %': 'Процентна ставка, %',
      Intermediate: 'Проміжний',
      'Intermediate TRANSPORT': 'Проміжний ТРАНСПОРТ',
      'Intermediate Transport': 'Проміжний транспорт',
      'Intermediate buyer': 'Проміжний покупець',
      'Intermediate costs': 'Проміжні витрати',
      'Intermediate logistics': 'Проміжна логістика',
      'Intermediate transport': 'Проміжний транспорт',
      'Internal Chain': 'Внутрішній ланцюг',
      'Internal chain': 'Внутрішній ланцюг',
      'Internal chain contracts': 'Внутрішній ланцюг контрактів',
      Invalid: 'Недійсний',
      'Invert filters': 'Інвертувати фільтри',
      Invoice: 'Інвойс',
      'Invoice Assignment': 'Призначення інвойсу',
      'Invoice Position configs': 'Конфігурації позиції інвойсу',
      'Invoice Positions': 'Інвойс позиції',
      'Invoice amount': 'Сума інвойсу',
      'Invoice configuration': 'Конфігурація інвойсу',
      'Invoice creation': 'Створення інвойсу',
      'Invoice date': 'Дата інвойсу',
      'Invoice discount': 'Дисконт за інвойсом',
      'Invoice discount amount': 'Сума дисконту за інвойсом',
      'Invoice documents': 'Документи інвойсу',
      'Invoice is already fully paid': 'Інвойс вже повністю оплачено',
      'Invoice is overpaid': 'Сума переплати по інвойсу',
      'Invoice is paid partially': 'Інвойс сплачено частково',
      'Invoice is paid.': 'Інвойс сплачено.',
      'Invoice is unpaid': 'Інвойс не оплачено',
      'Invoice margin': 'Інвойс маржа',
      'Invoice number': 'Номер інвойса',
      'Invoice number #': 'Номер інвойса №',
      'Invoice payments': 'Оплата інвойса',
      'Invoice percent': 'Відсотки за інвойсом',
      'Invoice position': 'Інвойс позиція',
      'Invoice positions': 'Інвойс позиції',
      'Invoice ref': 'Номер інвойса',
      'Invoice risk': 'Ризик інвойсу',
      'Invoice risk report': 'Звіт про ризик інвойсу',
      'Invoice saved': 'Інвойс збережено',
      'Invoice status': 'Статус інвойсу',
      'Invoice type': 'Тип інвойса',
      'Invoice updated': 'Інвойс оновлено',
      'Invoice was successfully created': 'Інвойс був успішно створений',
      'Invoice-Contract counterparty difference':
        'Різниця між інвойсом та контрактом стосовно контрагента',
      Invoiced: 'Виставлено інвойс',
      'Invoiced (bal)': 'Виставлено інвойс (баланс)',
      'Invoiced (balance)': 'Виставлено інвойс (баланс)',
      'Invoiced - paid': 'Виставлено - сплачено',
      'Invoiced Amount': 'Виставлена ​​сума інвойсу',
      'Invoiced Cargo': 'Вантаж за виставленим інвойсом',
      'Invoiced amount': 'Сума виставленого інвойсу',
      'Invoiced logistic': 'Логістика за виставленим інвойсом',
      'Invoiced volume': "Виставлений об'єм інвойсу",
      Invoices: 'Інвойси',
      'Invoices - Payment': 'Інвойси - Оплати',
      'Invoices risk': 'Ризик по інвойсам',
      'Invoices to be paid': 'Інвойс, який потрібно сплатити',
      'Invoices. Completed when invoices are issued': 'Інвойси. Завершено, коли виставлено інвойс',
      Invoicing: 'Виставлення інвойсів',
      'Invoicing costs': 'Виставлення витрат',
      'Is Elevator': 'Елеватор',
      'Is Exporter': 'Експортер',
      'Is FCA': 'FCA',
      'Is Farm': 'Господарство',
      'Is Internal Chain': 'Внутрішній ланцюг',
      'Is Swap': 'Своп',
      'Is a role of legal entity': 'Є роллю контрагента',
      'Is client agent': 'Контрагент посередник',
      'Is client producer': 'Це контрагент-виробник',
      'Is client resident': 'Є контрагентом-резидентом',
      'Is distributor': "Дистриб'ютор",
      'Is extension': 'Продовження',
      'Is full invoiced': 'Повністю виставлено інвойс',
      'Is fully paid': 'Повністю сплачено',
      'Is gain': 'Це дохід',
      'Is internal chain': 'Внутрішній ланцюг',
      'Is linked with the group of companies': "Пов'язаний з групою компаній",
      'Is liquid': 'Є рідиною',
      'Is main deal': 'Є основною угодою',
      'Is passport cost': 'Витрати паспорта',
      'Is resident': 'Резидент',
      'Is virtual': 'Віртуальний',
      Issuance: 'Видача',
      'Issuance date': 'Дата видачі',
      'Issuance fee': 'Комісія за видачу',
      'Issue invoice': 'Виставити інвойс',
      'It seems there are no autos/wagons yet': 'Здається, що ще немає транспортних засобів',
      'It seems there are no entries': 'Здається, немає записів',
      'It seems there are no entries yet': 'Здається, записів ще немає',
      'It seems there are no invoice positions yet': 'Здається, ще немає інвойс позиції',
      'It seems there are no month yet': 'Здається, поки немає жодного місяця',
      'It seems there are no positions yet': 'Здається, ще немає позицій',
      "It's consisted from realized and open P&L. Both physical and derivative.":
        'Він складався з реалізованих і відкритих PnL.Обидва фізичні та деривативи.',
      'Item was deleted': "Об'єкт видалено",
      January: 'Січень',
      Joined: 'Приєднано',
      July: 'Липень',
      June: 'Червень',
      KPI: 'KPI',
      'KPI confirmations': 'KPI підтвердження',
      'KPI signings': 'KPI підписи',
      'KPI success': 'KPI успішно',
      Kazachstan: 'Казахстан',
      'LINE UP': 'LINE UP',
      LOADING: 'ЗАВАНТАЖЕННЯ',
      'LOADING 2ND PORT': 'ЗАВАНТАЖЕННЯ 2-ГО ПОРТУ',
      'LOADING FINAL': 'ЗАВАНТАЖЕННЯ ФІНАЛ',
      LOCATION: 'РОЗТАШУВАННЯ',
      LOGISTICS: 'ЛОГІСТИКА',
      LOI: 'LOI',
      LONG: 'ДОВГИЙ',
      LOSSES: 'ВТРАТИ',
      'LOYALITY PROGRAM': 'ПРОГРАМА ЛОЯЛЬНОСТІ',
      LTD: 'LTD',
      Language: 'Мова',
      'Last 30 Days': 'За останні 30 днів',
      'Last 7 Days': 'За останні 7 днів',
      'Last B/L date': 'Дата останнього коносамента',
      'Last Month': 'Останній місяць',
      'Last login': 'Останній вхід',
      'Last name': 'Прізвище',
      'Last name is required': 'Потрібно вказати прізвище',
      'Last price': 'Кінцева ціна',
      'Last update': 'Останнє оновлення',
      Laycan: 'Сталійний час',
      'Laycan (end)': 'Кінець сталійного часу',
      'Laycan (start)': 'Початок сталійного часу',
      'Laycan end date': 'Кінець сталійного часу',
      'Laycan start date': 'Початок сталійного часу',
      Leased: 'Орендований',
      Left: 'Зліва',
      'Left to be shipped': 'Залишено для відвантаження',
      'Left to be shipped volume': "Залишилося відвантажити об'єм",
      Legal: 'Юридичний',
      'Legal address': 'Юридична адреса',
      'Legal address Eng': 'Юридична адреса (англ)',
      Lender: 'Кредитор',
      'Letter of Credit No.': 'Лист Кредит №.',
      Libor: 'Libor',
      'License number': 'Номер ліцензії',
      Limit: 'Ліміт',
      'Limit balance': 'Баланс ліміту',
      'Limit fact': 'Факт ліміту',
      'Limit must be greater or equal.': 'Ліміт має бути більшим або однаковим.',
      'Limit plan': 'План ліміту',
      'Limit saved': 'Ліміт збережено',
      'Limit saved.': 'Ліміт збережено.',
      'Limit value': 'Граничний показник',
      Limits: 'Ліміти',
      'Line Up': 'Line Up',
      'Line up': 'Line up',
      Lineup: 'Line up',
      'Link with companies': "Зв'язок з компаніями",
      'Link with group': "Зв'язок з групою",
      List: 'Список',
      'List filters': 'Список фільтрів',
      'List of actions in a dropdown menu': 'Список дій у розгорнутому меню',
      'List of business units empty': 'Список бізнес-юнітів порожній',
      Lists: 'Довідники',
      Load: 'Завантаження',
      'Load rate': 'Норма завантаження',
      'Load source': 'Джерело',
      Loaded: 'Завантажено',
      'Loaded on board': 'Завантажено на борт',
      'Loaded volume': "Завантажений об'єм",
      'Loaded/Discharged': 'Завантажено/розвантажено',
      Loading: 'Завантаження',
      'Loading (date)': 'Завантаження (дата)',
      'Loading / Destination': 'Завантаження / Розвантаження',
      'Loading 2nd port': 'Завантаження 2-го порту',
      'Loading Port': 'Порт завантаження',
      'Loading address (text)': 'Адреса завантаження',
      'Loading date': 'Дата завантаження',
      'Loading date from': 'Дата завантаження від',
      'Loading date to': 'Дата завантаження до',
      'Loading dem/dis': 'Завантаження dem/dis',
      'Loading final port': 'Завантаження кінцевого порту',
      'Loading minus': 'Завантаження в мінус',
      'Loading option': 'Опція завантаженнґ',
      'Loading place': 'Місце завантаження',
      'Loading plus': 'Завантаження в плюс',
      'Loading port': 'Порт завантаження',
      'Loading related objects': "Завантаження пов'язаних об'єктів",
      'Loading/Destination': 'Завантаження / Розвантаження',
      Loadport: 'Порт завантаження',
      'Loads without buyer': 'Завантаження без покупців',
      Loan: 'Позика',
      'Loan agreement': 'Договір позики',
      'Loan amount': 'Сума позики',
      'Loan number': 'Номер позики',
      'Loan saved': 'Позику збережено',
      'Loan updated': 'Позику оновлено',
      Loans: 'Позики',
      Local: 'Локальна',
      'Local currency': 'Локальна валюта',
      Location: 'Розташування',
      'Log out': 'Вийти',
      Logistic: 'Логістика',
      'Logistic difference with invoice': 'Логістична різниця з інвойсом',
      'Logistic loaded': 'Логістика завантажено',
      'Logistic loading; date': 'Дата завантаження логістики',
      'Logistic purchase contracts': 'Логістика закупівельних контрактів',
      'Logistic sale contracts': 'Логістика продажні контракти',
      'Logistic sale export contracts': 'Логістика продажні експортні контракти',
      'Logistic season': 'Логістика сезон',
      'Logistic type': 'Тип логістики',
      'Logistic types': 'Типи логістики',
      'Logistic updated': 'Логістика оновлена',
      'Logistic volume': "Об'єм логістики",
      Logistics: 'Логістика',
      'Logistics cost': 'Витрати логістики',
      'Logistics costs VAT': 'Витрати логістики (ПДВ)',
      'Logistics have already connected to bill of lading.':
        'Логістика вже підключена до коносаменту.',
      'Logistics mailing': 'Розсилка логістики',
      'Logistics updated!': 'Логістика оновлена!',
      Long: 'Довгий',
      'Long lots': 'Довгі лоти',
      Losses: 'Втрати',
      Lost: 'Втрачено',
      Lots: 'Лоти',
      Low: 'Низький',
      'Loyalty Program': 'Програма лояльності',
      'Loyalty Programs': 'Програми лояльності',
      'Loyalty program': 'Програма лояльності',
      'LoyaltyProgram saved.': 'Програма лояльності збережена.',
      MAIN: 'ОСНОВНИЙ',
      'MAIN APPROVERS': 'СУПЕР ПОГОДЖЕННЯ',
      'MAIN INFO': 'ОСНОВНА ІНФОРМАЦІЯ',
      MAJORITY: 'БІЛЬШІСТЬ',
      'MANAGEMENT REPORTS': 'УПРАВЛІНСЬКІ ЗВІТИ',
      MARGIN: 'МАРЖА',
      'MARGIN CONTROL': 'КОНТРОЛЬ МАРЖІ',
      'MARK TO MARKET': 'ОЦІНКА РИНКУ',
      MARKET: 'РИНОК',
      MATCHED: 'ЗІСТАВЛЕНІ',
      MONTH: 'МІСЯЦЬ',
      'MORE INFO': 'БІЛЬШЕ ІНФОРМАЦІЇ',
      MOVEMENT: 'ПЕРЕСУВАННЯ',
      'MT Equivalent': 'Еквівалент МТ',
      MTM: 'MTM',
      'MTM P&L': 'MTM PnL',
      'MTM Price': 'Ціна MTM',
      'MTM margin': 'маржа MTM',
      'MTM price': 'Ціна MTM',
      MULTICONTRACTS: 'МУЛЬТИКОНТРАКТИ',
      'MULTISELECT FILTERS': 'МУЛЬТИ-ФІЛЬТРИ',
      'MULTISELECT OPTIONS': 'МНОЖИННІ ВАРІАНТИ\nопції',
      MULTITICKETS: 'МУЛЬТИТІКЕТ',
      Mail: 'Лист',
      'Mail preview': 'Попередній перегляд листа',
      'Mailing Invoices': 'Розсилка інвойсів',
      'Mailing Logistics': 'Розсилка логістики',
      'Mailing address': 'Адреса відправки',
      'Main company settings': 'Основні налаштування компанії',
      'Main contact': 'Основний контакт',
      'Main contact of company?': 'Основний контакт компанії?',
      'Main deal': 'Основна угода',
      'Main option': 'Основна опція',
      'Main ticket': 'Основний тікет',
      'Make reassignment': 'Створити переуступку',
      'Make sure you filled out': 'Переконайтеся, що ви заповнили',
      Manage: 'Керувати',
      'Manage check-list': 'Управління чек-листом',
      'Manage costs/gains': 'Управління витрати/дохід',
      'Management reports': 'Управлінські звіти',
      Manager: 'Менеджер',
      March: 'Березень',
      Margin: 'Маржа',
      'Margin (Analytical - Contract)': 'Маржа ( Аналітичний - Контракт)',
      'Margin (Plan - Fact)': 'Маржа (План - Факт)',
      'Margin absolute': 'Маржа абсолютна',
      'Margin control created': 'Контроль маржі створено',
      'Margin per 1t': 'Маржа на 1т',
      'Margin relative': 'Відносна маржа',
      'Margin selector': 'Перемикач маржі',
      'Margin type from passports': 'Тип маржі з паспортів',
      'Marine Cover Policy No.': 'Політика морського страхування №.',
      'Marine Traffic': 'MarineTraffic',
      'Marine traffic': 'MarineTraffic',
      Marinetraffic: 'MarineTraffic',
      'Mark to Market': 'Оцінка ринку',
      'Mark to market': 'Оцінка ринку',
      Market: 'Ринок',
      'Market price': 'Ринкова ціна',
      'Market price (MTM)': 'Ринкова ціна (MTM)',
      'Market price deleted': 'Ринкова ціна видалена',
      'Market price updated': 'Ринкова ціна оновлена',
      Marks: 'Позначки',
      'Master data': 'Довідники',
      'Matched P&L': 'Підібраний PnL',
      'Matched Positions': 'Підібрані позиції',
      'Matched objects:': "Підібрані об'єкти:",
      Maturity: 'Погашення',
      'Maturity date': 'Дата погашення',
      'Maturity date (plan)': 'Дата погашення (план)',
      Max: 'Макс',
      'Max alt': 'Макс',
      'Max bl date': 'Остання дата коносаменту',
      'Max purchase price at': 'Максимальна закупівельна ціна',
      'Maximum amount of one contract': 'Максимальна сума одного контракту',
      'Maximum quantity of one contract': "Максимальна об'єм одного контракту",
      May: 'Травень',
      Measurement: 'Вимірювання',
      Medium: 'Середній',
      Meet: 'Зустріч',
      Message: 'Повідомлення',
      'Message Template': 'Шаблон повідомлення',
      'Middle Name': 'По батькові',
      'Middle name': 'По батькові',
      Min: 'Мін',
      'Min alt': 'Мін',
      'Minimum batch of payment': 'Мінімальна сума оплати',
      'Minimum value is 0': 'Мінімальна вартість - 0',
      Mixed: 'Змішані',
      Mo: 'ПН',
      Model: 'Зразок',
      Monday: 'Понеділок',
      Month: 'Місяць',
      'Monthly plan': 'Місячний план',
      Movement: 'Пересування',
      MultiTicket: 'Мультитікет',
      Multicontract: 'Мультиконтракт',
      "Multicontract doesn't exist": 'Мультиконтракт не існує',
      'Multicontract saved.': 'Мультиконтракт збережено.',
      Multicontracts: 'Мультиконтракти',
      Multipassport: 'Мультипаспорт',
      'Multipassport contains several passports in itself.':
        'Мультипаспорт містить в собі кілька паспортів.',
      'Multipassport saved.': 'Мультипаспорт збережено.',
      Multipassports: 'Мультипаспорти',
      'Multipassports report': 'Звіт мультипаспорта',
      'Multiple connection': 'Множинне з’єднання',
      'Multiple contracts': 'Мультиконтракти',
      'Multiply amount with rate': 'Помножте суму на ставку',
      Multirequest: 'Мультитікет',
      "Multirequest doesn't exist": 'Мультитікету не існує',
      'Multirequest saved.': 'Мультитікет збережено.',
      Multitickets: 'Мультитікети',
      'Multi{[{ $ctrl.multicontract.stage }]}': 'Multi{[{ $ctrl.multicontract.stage }]}',
      NDF: 'NDF',
      NEW: 'НОВИЙ',
      NEWSLETTERS: 'РОЗСИЛКИ',
      'NEXT STEP': 'НАСТУПНИЙ КРОК',
      'NO OF BAGS': 'БЕЗ ТАРИ',
      NOTIFICATIONS: 'ПОВІДОМЛЕННЯ',
      NUMBER: 'НОМЕР',
      Name: "Ім'я",
      'Name / Surname': "Ім'я/Прізвище",
      'Name of Buyer sale contract': 'Покупець продажного контракту',
      'Name of seller purchase contract': 'Постачальник закупівельного контракту',
      'Navbar position': 'Позиція панелі навігації',
      Navigation: 'Навігація',
      'Needs approval': 'Необхідне підтвердження',
      'Net Closed': 'Net Closed',
      'Net P&L': 'Чистий PnL',
      'Net P&L MTM': 'Чистий PnL MTM',
      'Net closed P&L': 'Закритий PnL',
      Neutral: 'Нейтральний',
      New: 'Новий',
      'New - after creation of disbursement bill of lading. Process - partly connected to reassignment. Done - fully connected to reassignment or connected to offset.':
        "Новий - після створення видаткової накладної. У процесі - частково з'єднаний з переуступкою. Виконано - повністю з'єднаний до переуступки або заліку.",
      'New - after creation of reassignment. Process - reassignment partly connected to offset. Done - reassignment fully connected to offset.':
        "Новий - після створення переуступки. У процесі – переуступка частково пов'язана із заліком. Виконано - переуступка повністю пов'язана із заліком.",
      'New Name': 'Нова назва',
      'New Password': 'Новий пароль',
      'New collection': 'Нова конфігурації',
      'New derivative': 'Новий дериватив',
      'New destination port name': 'Нова назва порту призначення',
      'New loading port name': 'Нова назва порту завантаження',
      'New month': 'Новий місяць',
      'New multicontract': 'Новий мультиконтракт',
      'New passport': 'Новий паспорт',
      'New report': 'Новий звіт',
      'New tariff': 'Новий тариф',
      'New task': 'Нове завдання',
      'New template': 'Новий шаблон',
      'New title': 'Нова назва',
      'New update': 'Нове оновлення',
      'New vessel name': 'Нова назва корабля',
      'New {[{ $root.user.settings.PASSPORT_TITLE }]}':
        'Новий {[{ $root.user.settings.PASSPORT_TITLE }]}',
      NewsLetters: 'Розсилки',
      Newsletter: 'Розсилка',
      'Newsletter of invoices gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all invoices (paid or not) and send it to a client or bank.\n                Also, you can attach all documents, related to the deal.':
        "Розсилка інвойсів дає можливість швидко сформувати лист із таблицею Excel \n                всіх інвойсів (сплачених чи ні) та відправити його контрагенту або банку. \n                Також, ви можете додати всі документи, пов'язані з угодою.",
      'Newsletter of logistics gives you a possibility to quickly form a letter with an\n                excel spreadsheet of all vehicles/containers and send it to a client or bank. Also,\n                you can attach full package documents, related to the deal.':
        "Розсилка логістики дає можливість швидко сформувати лист з таблицею Excel \n                всіх транспортних засобів / контейнерів і відправити його контрагенту або банку. \n                Крім того, ви можете додати всі документи, пов'язані з угодою.",
      Newsletters: 'Розсилки',
      'Newsletters by invoices': 'Розсилки за інвойсами',
      'Newsletters by logistics': 'Розсилки за логістикою',
      Next: 'Наступний',
      'Next contact': 'Наступний контакт',
      'Next step': 'Наступний крок',
      'Next steps': 'Наступні кроки',
      'Next update': 'Наступне оновлення',
      No: 'Ні',
      'No additional agreements yet': 'Додаткових угод немає',
      'No balance invoice': 'Інвойс без балансу',
      'No bank accounts yet': 'Ще немає банківських рахунків',
      'No bill of ladings': 'Немає коносамента',
      'No charges': 'Немає витрат',
      'No contracts': 'Немає контрактів',
      'No data yet': 'Даних ще немає',
      'No emails yet': 'Немає emails',
      'No entries yet': 'Записів ще немає',
      'No group': 'Немає групи',
      'No logistic to update': 'Немає логістики для оновлення',
      'No open position': 'Немає відкритої позиції',
      'No open position in passport': 'В паспорті немає відкритої позиції',
      'No payments provided yet': 'Поки що немає платежів',
      'No requests': 'Немає запитів',
      'No response': 'Немає відповіді',
      'No sign': 'Немає підпису',
      'No similar entries yet': 'Схожих записів ще немає',
      'No templates yet': 'Шаблонів ще немає',
      'No, take me back': 'Ні, поверни мене назад',
      'Not contacted': "Не зв'язався",
      'Not fixed': 'Не фіксовано',
      'Not fixed lots': 'Не фіксовані лоти',
      'Not fixed tonnes': 'Нефіксовані тонни',
      'Not hedged': 'Не хеджований',
      'Not insured': 'Без страхування',
      'Not invoiced': 'Не виставлено інвойс',
      'Not paid': 'Не сплачено',
      'Not pay': 'Не платити',
      'Not selected': 'Не вибрано',
      'Not signed': 'Не підписано',
      Note: 'Примітка',
      'Note one': 'Примітка перша',
      'Note two': 'Примітка друга',
      Notes: 'Примітки',
      'Notification time': 'Час повідомлення',
      Notifications: 'Повідомлення',
      Notified: 'Повідомлено',
      'Notify party': 'Повідомляюча сторона',
      'Notify party 2': 'Повідомляюча сторона 2',
      'Notify party 3': 'Повідомляюча сторона 3',
      November: 'Листопад',
      Number: 'Номер',
      'Number (#)': 'Номер №',
      'Number of Purchase contract': 'Номер закупівельного контракту',
      'Number of Purchase contract, to which this vehicle is attached to':
        "Номер закупівельного контракт, до якого цей транспортний засіб прив'язано",
      'Number of Sale contract': 'Номер продажного контракту',
      'Number of Sale contract, to which this vehicle is attached to':
        "Номер продажного контракту, до якого цей транспортний засіб прив'язано",
      'Number of Sale export contract, to which this vehicle is attached to':
        "Номер продажного експортного контракту, до якого цей транспортний засіб прив'язано",
      'Number of containers': 'Кількість контейнерів',
      'Number of contracts': 'Кількість контрактів',
      'Number of days before notification': 'Кількість днів до повідомлення',
      'Number of days before notification about power of attorney expiration':
        'Кількість днів до повідомлення про закінчення терміну дії довіреності',
      'Number of days to issue an invoice': 'Кількість днів до видачі інвойсу',
      OPEN: 'ВІДКРИТИ',
      OPTIONS: 'ОПЦІЇ',
      ORDER: 'ЗАМОВЛЕННЯ',
      OTHER: 'ІНШЕ',
      OUT: 'ВИХІД',
      OUTGOING: 'ВИХІДНИЙ',
      Object: "Об'єкт",
      'Object deleted': "Об' єкт видалено",
      'Object has been added': 'Об’єкт додано',
      'Object has been removed': "Об' єкт видалено",
      'Object removed': "Об' єкт видалено",
      'Object saved': "Об' єкт збережено",
      'Object updated': "Об'єкт оновлено",
      October: 'Жовтень',
      Offer: 'Оффер',
      Offers: 'Оффери',
      Offset: 'Залік',
      'Offset date': 'Дата заліку',
      "Offset doesn't exist": 'Заліку не існує',
      'Offset list': 'Залік список',
      'Offset number': 'Залік номер',
      'Offset saved': 'Залік збережено',
      'Offset through reassignment date': 'Залік з переуступки',
      Offsets: 'Заліки',
      'Old Cash Flow': 'Кеш-фло (старий звіт)',
      'On Road Volume': "Об'єм в дорозі",
      'On first event': 'За першою подією',
      'On road': 'В дорозі',
      'On road amount': 'Товар у дорозі (сума)',
      'One full cargo': 'Весь вантаж',
      'One of the vessel voyages, which will execute this deal':
        'Один із рейсів корабля, який виконає цю угоду',
      'Only complete transactions': 'Тільки завершені транзакції',
      'Only my updates': 'Тільки мої оновлення',
      'Only users with permission secret_invoice will be able see this invoice':
        'Тільки користувачі із спеціальним дозволом можуть побачити цей інвойс',
      Open: 'Відкритий',
      'Open P&L': 'ВІдкритий PnL',
      'Open Positions': 'Відкриті позиції',
      'Open amount balance': 'Баланс відкритої суми',
      'Open balance': 'Відкритий баланс',
      'Open balance (forward)': 'Відкритий баланс (форвард)',
      'Open balance (spot)': 'Відкритий баланс (спот)',
      'Open balance amount': 'Сума відкритого балансу',
      'Open balance of all contracts': 'Відкритий баланс всіх контрактів',
      'Open contracts': 'Відкриті контракти',
      'Open position': 'Відкрита позиція',
      'Open profit and loss': 'Відкритий прибуток і збиток',
      'Open/closed': 'Відкрито/закрито',
      Operation: 'Операція',
      'Operation amount': 'Сума операції',
      'Operation deleted': 'Операцію видалено',
      'Operation saved': 'Операцію збережено',
      'Operation type': 'Тип операції',
      'Operational profit and loss': 'Операційні прибутки та збитки',
      Operations: 'Операції',
      'Opposite basis': 'Зустрічний базис',
      'Opposite contract costs': 'Зустрічні витрати контракту',
      'Opposite costs': 'Зустрічні витрати',
      'Opposite counterparties': 'Протилежні контрагенти',
      'Opposite derivative': 'Зустрічний дериватив',
      'Opposite freight': 'Зустрічний фрахт',
      'Opposite passport basis costs': 'Зустрічні витрати паспорта',
      'Opposite passport contract costs': 'Зустрічні витрати контракту паспорта',
      'Opposite price': 'Зустрічна ціна',
      'Opposite value': 'Протилежне значення',
      Option: 'Опціон',
      'Order by': 'Сортувати за',
      'Order sale contract': 'Ордер продажних контрактів',
      Organization: 'Організація',
      Origin: 'Походження',
      'Original available': 'Оригінал доступний',
      'Originals received': 'Оригінали отримані',
      Other: 'Інше',
      'Other Activity': 'Інше призначення',
      'Other activities': 'Інше призначення',
      'Other activity': 'Інше призначення',
      'Our Counterparty': 'Наш контрагент',
      'Our amount': 'Наша сума',
      Out: 'Вихід',
      'Out date': 'Дата відправки',
      'Out from country': 'З країни',
      'Out number': 'Вихідний номер',
      'Out of approval': 'Без погодження',
      Outgoing: 'Вихідний',
      'Outgoing (date)': 'Вихідний (дата)',
      'Outgoing Volume': "Вихідний об'єм",
      'Outgoing balance': 'Вихідний баланс',
      'Outgoing invoice status': 'Статус вихідного інвойсу',
      'Outgoing invoices': 'Вихідні інвойси',
      'Outgoing payments': 'Вихідні платежі',
      'Outgoing/Incoming': 'Вихідні/Вхідні',
      'Outstanding payment': 'Прострочено дату платежу',
      Overdue: 'Просрочені',
      Overpaid: 'Переплата',
      'Overpaid directions': 'Напрямки переплат',
      Overpaids: 'Переплати',
      Own: 'Власні',
      'Own costs': 'Власні витрати',
      Owner: 'Власник',
      'Owner equals Disponent': 'Власник є Диспонент',
      'Owner info': 'Інформація про власника',
      "Owner's bank account": 'Банківський рахунок власника',
      Owners: 'Власники',
      'P&L': 'PnL',
      'P&L Consolidated': 'PnL консолідований',
      'P&L MTM': 'PnL MTM',
      'P&L MTM (cts/bu)': 'PnL MTM (cts/bu)',
      'P&L MTM per one lot': 'PnL MTM за одну партію',
      'P&L MTM, $': 'PnL MTM, usd',
      'P&L books': 'Бухгалтерський PnL',
      'P&L details': 'PnL деталі',
      'P&L distribution': 'Розподіл PnL',
      'P&L per passport': 'PnL за паспортом',
      'P/L': 'PnL',
      PAID: 'СПЛАЧЕНО',
      PARAMETERS: 'НАЛАШТУВАННЯ',
      'PASSPORT COSTS': 'ВИТРАТИ ПАСПОРТА',
      PASSPORTS: 'ПАСПОРТИ',
      PAYMENT: 'ОПЛАТА',
      'PAYMENT CONDITIONS': 'УМОВИ ОПЛАТИ',
      'PAYMENT ORDERS': 'ПЛАТІЖНІ ДОРУЧЕННЯ',
      PAYMENTS: 'ОПЛАТИ',
      PCD: 'PCD',
      'PICK DYNAMIC RANGE': 'ДИНАМІЧНИЙ ДІАПАЗОН',
      PLAN: 'ПЛАН',
      PLANNED: 'ПЛАН',
      PLANNING: 'ПЛАНУВАННЯ',
      PLANS: 'ПЛАНИ',
      PNL: 'PNL',
      'PNL report': 'Звіт PNL',
      'PO number': 'номером замовлення',
      POD: 'Порт доставки',
      'POD under way': 'POD в дорозі',
      POL: 'Порт відправки',
      'POL under way': 'POL в дорозі',
      PORTS: 'ПОРТИ',
      POSITION: 'ПОЗИЦІЯ',
      POSITIONS: 'ПОЗИЦІЇ',
      'POTENTIAL BUYERS': 'ПОТЕНЦІЙНІ ПОКУПЦІ',
      'POTENTIAL SUPPLIERS': 'ПОТЕНЦІЙНІ ПОСТАЧАЛЬНИКИ',
      POTENTIALS: 'ПОТЕНЦІІАЛИ',
      PRICE: 'ЦІНА',
      PRODUCT: 'ТОВАР',
      PROPERTIES: 'ПОКАЗНИКИ',
      PURCHASE: 'ЗАКУПІВЛЯ',
      'PURCHASE ASC': 'ЗАКУПІВЛЯ ASC',
      'PURCHASE CONTRACTS': 'ЗАКУПІВЕЛЬНІ КОНТРАКТИ',
      'PURCHASE COSTS': 'ВИТРАТИ ЗАКУПІВЛІ',
      'PURCHASE DESC': 'ЗАКУПІВЛЯ DESC',
      'PURCHASE PLANS': 'ПЛАНИ ЗАКУПІВЛІ',
      Packing: 'Упаковка',
      'Packing List': 'Пакувальний лист',
      'Packing is too large number, less than 6 digits required (3 for integer, 3 for decimal).':
        'У упаковці занадто велике число, потрібно вказати менше 6 цифр (3 для цілого числа, 3 для десяткового).',
      'Page filter config': 'Конфігурація фільтра сторінки',
      'Page scale': 'Масштаб сторінки',
      'Page size': 'Розмір сторінки',
      'Page url': 'Посилання сторінки',
      Paid: 'Сплачено',
      'Paid (bal)': 'Сплачено (баланс)',
      'Paid Cargo': 'Сплачено вантаж',
      'Paid amount': 'Сума сплачена',
      'Paid date': 'Дата оплати',
      'Paid volume': "Сплачений об'єм",
      'Paid volume (t)': "Сплачений об'єм (т)",
      Paper: 'Paper',
      'Paper trade': 'Paper trade',
      Parameters: 'Налаштування',
      Parent: '«Батьківська» культура',
      'Parent commodity': 'Батьківський товар',
      'Parent crop': '«батьківська» культура',
      'Parent reassignment': 'Джерело переуступки',
      'Parse this quote': 'Спарсить це котирування',
      Parsing: 'Парсинг',
      'Parsing config template removed': 'Шаблон парсингу котирувань видалено',
      'Parsing enabled': 'Парсинг увімкнено',
      'Partial shipment allowed': 'Дозволяється часткове відвантаження',
      Passport: 'Паспорт',
      'Passport P&L': 'Паспорт PnL',
      'Passport and/or contracts have different currencies':
        'Паспорт та/або контракти мають різні валюти',
      'Passport basis costs': 'Витрати паспорта',
      'Passport business date': 'Бізнес дата паспорта',
      'Passport business date updated': 'Бізнес дату паспорта оновлено',
      'Passport charge': 'Витрати паспорта',
      'Passport conclusion date': 'Дата укладання паспорта',
      'Passport connection': "Паспорт з'єднання",
      'Passport contract': 'Паспорт контракту',
      'Passport contract costs': 'Витрати контракту паспорта',
      'Passport costs': 'Витрати паспорта',
      'Passport created.': 'Створено паспорт.',
      'Passport date': 'Дата паспорта',
      "Passport doesn't exist": 'Паспорта не існує',
      'Passport filled in  manually': 'Паспорт заповнено вручну',
      'Passport have no export contract or more then one':
        'Паспорт не має експортного контракту або має більше одного',
      'Passport have no sale contract or more then one':
        'У паспорта немає продажного контракту або їх більше, ніж один',
      'Passport info': 'Інформація про паспорт',
      'Passport operation': 'Операція паспорта',
      'Passport quantity': "Об'єм паспорта",
      'Passport removed': 'Паспорт видалено',
      'Passport saved': 'Паспорт збережено',
      'Passport status': 'Статус паспорта',
      'Passport status updated': 'Статус паспорта оновлено',
      'Passport title': 'Назва паспорта',
      'Passport totals': 'Всього по паспорту',
      'Passport updated': 'Паспорт оновлено',
      'Passport updated.': 'Паспорт оновлено.',
      'Passport volume updated': "Об'єм паспорта оновлений",
      'Passport. Completed if contract is attached to passports on full volume':
        "Паспорт. Завершено, якщо контракт додається до паспортів в повному об'ємі",
      Passport_connected: "Паспорт з'єднано",
      Passports: 'Паспорти',
      'Passports (contract and direct)': 'Паспорти (контрактні та прямі)',
      'Passports Profit and Loss': 'PnL паспортів',
      'Passports costs': 'Витрати паспортів',
      'Passports position': 'Позиція паспортів',
      'Passports report': 'Звіт про паспорти',
      Password: 'Пароль',
      Past: 'Минуле',
      'Paste a hyperlink to DHL here to track the status of delivery':
        'Вставте гіперпосилання до DHL, щоб відстежувати статус доставки',
      Pay: 'Сплата',
      'Pay now': 'Сплатити зараз',
      Payables: 'Кредиторська заборгованість',
      Payment: 'Платіж',
      'Payment Fact': 'Факт оплати',
      'Payment Info': 'Інформація про оплату',
      'Payment Plan': 'Плани оплат',
      'Payment Plans': 'Плани оплат',
      'Payment amount': 'Сума оплат',
      'Payment bank account': 'Банківський рахунок платежу',
      'Payment condition': 'Умова оплати',
      'Payment conditions': 'Умови оплати',
      'Payment conditions (%)': 'Умови оплати (%)',
      'Payment conditions (description)': 'Умови оплати (опис)',
      'Payment conditions auto (%)': 'Умови оплати (авто, %)',
      'Payment conditions option': 'Опція умов оплати',
      'Payment conditions options': 'Опції умов оплати',
      'Payment confirmation': 'Підтвердження оплати',
      'Payment date': 'Дата оплати',
      'Payment delays': 'Затримки платежів',
      'Payment info': 'Інформація про оплату',
      'Payment info saved': 'Платіжну інформацію збережено',
      'Payment plan': 'План оплати',
      'Payment plan creation': 'Створення плану оплати',
      'Payment plan removed': 'План оплати видалено',
      'Payment plan saved': 'План оплати збережено',
      'Payment plans': 'Плани оплат',
      'Payment removed': 'Оплату видалено',
      'Payment saved': 'Оплату збережено',
      'Payment stock (t)': 'Заборгованість за відвантаження (т)',
      'Payment stock amount': 'Сума оплат залишок',
      'Payment successfully created': 'Оплату успішно створено',
      'Payment terms': 'Терміни оплати',
      'Payment updated': 'Оплату оновлено',
      Payments: 'Платежі',
      'Payments currency exchange': 'Обмінний курс оплати',
      'Payments margin': 'Маржа платежів',
      'Payments plans': 'Плани оплат',
      'Payments were created': 'Оплата створено',
      Penalties: 'Штрафи',
      'Penalties VAT': 'Штрафи з ПДВ',
      Pending: 'В очікуванні',
      'Pending confirmation': 'Очікує підтвердження',
      'Per 1 t': 'За 1 т',
      'Percent conditions': 'Умови відсоток',
      'Percent, %': 'Відсоток, %',
      Percentage: 'Відсоток',
      'Percentage, %': 'Відсоток, %',
      Period: 'Період',
      'Period of detalization': 'Період деталізації',
      'Period of execution': 'Період виконання',
      'Periodic customs declaration': 'Періодична митна декларація',
      Permissions: 'Права користувача',
      Person: 'Контакт',
      'Person - main contact of the company': 'Основний контакт компанії',
      'Person removed': 'Контакт видалено',
      'Person saved': 'Контакт збережено',
      'Person signatory eng': 'Підписант (англ)',
      'Person updated': 'Контакт оновлено',
      Personal: 'Особисті',
      'Personal settings': 'Персональні налаштування',
      Persons: 'Контакти',
      Phone: 'Телефон',
      'Phone (additional)': 'Телефон (додатковий)',
      'Phone add': 'Додані телефони',
      'Phone additional': 'Телефон додатковий',
      Physical: 'Фізична',
      'Physical + Derivatives margin': 'Фізична маржа та деривативи',
      'Physical MTM': 'Фізична МТМ',
      'Physical position': 'Фізична позиція',
      'Physical position + MTM': 'Фізична позиція + MTM',
      'Physical position by months': 'Фізична позиція за місяцями',
      'Pick a bank account': 'Виберіть банківський рахунок',
      'Pick a borrower that will be bound to this loan':
        "Виберіть позичальника, якого буде пов'язано з цією позикою",
      'Pick a counterparty that will be bound to this invoice':
        "Виберіть контрагента, який буде пов'язаний з цим інвойсом",
      'Pick a currency': 'Виберіть валюту',
      'Pick a date': 'Виберіть дату',
      'Pick a deadline': 'Виберіть дедлайн',
      'Pick a distributor that will be bound to this offset':
        "Виберіть дистриб'ютор, який буде пов'язаний з цим заліком",
      'Pick a distributor that will be bound to this reassignment':
        "Виберіть дистриб'ютора, який буде пов'язаний з цією переуступкою",
      'Pick a lender that will be bound to this loan':
        "Виберіть кредитора, якого буде пов'язаний з цією позикою",
      'Pick a loan agreement': 'Виберіть договір позики',
      'Pick a time': 'Виберіть час',
      'Pick a voyage to which this bill of lading is related too':
        'Виберіть рейс, до якого також відноситься цей коносамент',
      'Pick accounts': 'Виберіть рахунки',
      'Pick an owner that will be bound to this reassignment':
        "Виберіть власника, який буде пов'язаний з цією переуступкою",
      'Pick currency in which you want to convert USD':
        'Оберіть валюту, яку бажаєте конвертувати в USD',
      'Pick exchange rate if you want to convert invoice value to USD by certain rate':
        'Виберіть обмінний курс, якщо ви хочете конвертувати суму інвойсу в USD за певним курсом',
      'Pick from 0 to 100 to set up interest rate':
        'Виберіть від 0 до 100 для встановлення процентної ставки',
      'Pick from 0 to 100 to set up loan percentage':
        'Виберіть від 0 до 100 для встановлення відсотку кредиту',
      'Pick from 0 to 100 to set up prepay': 'Виберіть від 0 до 100, щоб встановити передоплату',
      'Pick from 0 to 200': 'Виберіть від 0 до 200',
      'Pick from 0 to 200 to increase or decrease the amount. Less than 100 - decreases, more than 100 - increases':
        'Виберіть від 0 до 200 для збільшення або зменшення суми. Значення менше 100 зменшує суму, значення більше 100 збільшує суму',
      'Pick from 0 to 200, use this or discount amount':
        'Виберіть від 0 до 200, використовуйте це або суму дисконта',
      'Pick here a date, when you got first contact with this company':
        'Виберіть дату, коли Ви вперше контактували з цією компанією',
      'Pick here a date, when you need to contact this company in future':
        "Виберіть дату, коли потрібно зв'язатися з цією компанією в майбутньому",
      'Pick one of the options to define the status of relations with the company':
        'Виберіть одну із опцій, щоб визначити статус відносин з компанією',
      'Pick one of the options to define the status of the company':
        'Виберіть один із варіантів визначення статусу компанії',
      'Pick one of the options to define the type of the company':
        'Оберіть один із варіантів, щоб визначити тип компанії',
      'Pick the short name for counterparty. For convenience try to avoid naming the form of ownership, e.g. LLC  etc. You can appoint it in the full name. Good example for short name is COMPANY NAME without commas, etc. It would helpful you to find this company within search':
        'Виберіть скорочену назву контрагента. Для зручності намагайтеся уникати назви форми власності (наприклад, ТОВ, тощо). Ви можете вказати це в Повній назві. Гарний приклад короткої назви - НАЗВА КОМПАНІЇ без крапок, дужок та ком. Це допоможе Вам знайти цю компанію в пошуку',
      Place: 'Місце',
      'Place of destination': 'Місце призначення',
      'Place of storage': 'Місце зберігання',
      Places: 'Місця',
      Plan: 'План',
      'Plan VS contracted': 'План VS законтрактовано',
      'Plan VS delivered': 'План VS доставлено',
      'Plan amount': 'Сума плану',
      'Plan costs': 'План витрат',
      'Plan created': 'План створено',
      'Plan execution range': 'Норма плану виконання',
      'Plan payments': 'План оплат',
      'Plan value': 'Плановий показник',
      'Planned Expenses': 'Плановані витрати',
      'Planned balance (t)': 'Плановий баланс (т)',
      'Planned balance amount': 'Планова сума балансу',
      'Planned distributor': "Планований дистриб'ютор",
      'Planned distributors': "Планові дистриб'ютори",
      Planning: 'Планування',
      Plans: 'Плани',
      'Please delete the cost in the contract to change the invoice':
        'Будь ласка, видаліть витрату у контракті, щоб змінити інвойс',
      'Please select a contract': 'Будь ласка, виберіть контракт',
      'Please select a contract from the left': 'Будь ласка, виберіть контракт зліва',
      'Please select at least one BL.': 'Будь ласка, виберіть принаймні один коносамент.',
      'Please select at least one Disbursement Bill of Lading':
        'Будь ласка, виберіть принаймні одну витратну накладну',
      'Please select at least one invoice': 'Будь ласка, виберіть принаймні один інвойс',
      'Please select at least one logistic': 'Будь ласка, виберіть хоча б одну логістику',
      'Please select at least one object': "Будь ласка, виберіть хоча б один об'єкт",
      'Please set date.': 'Будь ласка, встановіть дату.',
      'Please set export contract': 'Будь ласка, виберіть експортний контракт',
      'Please set invoice': 'Будь ласка, виберіть інвойс',
      'Please, not the all the values related to contracts and internal objects are\n                  corrected according to the volume of allocation of certain contract.':
        "Будь ласка, не всі значення, пов'язані з контрактами та внутрішніми об'єктами,\n                   виправляються відповідно до об'єму розподілу певного контракту.",
      'Please, select export contract': 'Будь ласка, виберіть контракт на експорт',
      'Please, select purchase contract': 'Будь ласка, виберіть закупівельний контракт',
      'Please, select purchase prepay invoice':
        'Будь ласка, виберіть передплатний інвойс на закупівлю',
      'Please, select sale contract': 'Будь ласка, виберіть продажний контракт',
      'Please, select sale export contract filter':
        'Будь ласка, виберіть продажний експортний контракт',
      'Please, select sale prepay invoice': 'Будь ласка, виберіть передплатний інвойс на продаж',
      Pledge: 'Застава',
      'Pmt date': 'Дата оплати',
      PnL: 'PnL',
      Port: 'Порт',
      'Port of Discharge': 'Порт розвантаження',
      'Port of Loading': 'Порт завантаження',
      'Port of destination': 'Порт призначення',
      'Port of discharge': 'Порт розвантаження',
      'Port of loading': 'Порт завантаження',
      Ports: 'Порти',
      'Ports list': 'Список портів',
      'Ports list eng': 'Список портів (англ)',
      'Ports list short name': 'Скорочена назва списку портів',
      'Ports opposite': 'Порти зустрічні',
      Position: 'Позиція',
      'Position P&L + MTM': 'Позиція PnL + MTM',
      'Position excluded': 'Позиція виключена',
      'Position saved': 'Позицію збережено',
      'Position updated': 'Позицію оновлено',
      Positions: 'Позиції',
      'Positon of person who signs documents by this company':
        'Позиція особи, яка підписує документи цієї компанії',
      Potencial: 'Потенційний',
      'Potential crops': 'Потенційні товари',
      'Potential purchase': 'Потенційна закупівля',
      Potentials: 'Потенціали',
      Premium: 'Преміум',
      'Premium balance': 'Преміум баланс',
      'Premium currency': 'Преміум-валюта',
      'Premium for delivered goods, Plan': 'Премія за доставлені товари, План',
      'Premium price': 'Преміум ціна',
      'Premium price opposite': 'Преміум ціна зустрічна',
      'Premium value': 'Преміум вартість',
      Prepay: 'Передоплата',
      'Prepay - amount of money, will be paid by contract, before final delivery,  Balance of money, will be paid after end of delivery':
        'Передоплата - сума грошей, яка буде сплачена за контрактом, до кінцевої доставки. Баланс – сума, яка буде виплачена після закінчення доставки',
      'Prepay - balance percent': 'Передоплата - відсоток балансу',
      'Prepay invoice': 'Інвойс передплати',
      'Prepay payment dates': 'Дати передплати',
      'Prepay volume': "Передплачений об'єм",
      'Prepayment invoice': 'Інвойс передплати',
      'Preset Set Saved': 'Шаблон збережено',
      'Preset Set Updated': 'Шаблон оновлено',
      'Preset Set removed': 'Шаблон видалено',
      Preshipment: 'Предзавантаження',
      'Press read if you want to tick off the notification, or press detail to go to the\n                details of the linked object':
        'Натисніть кнопку прочитати, якщо ви бажаєте вимкнути повідомлення, або натисніть кнопку "деталі", \n                щоб дізнатися більше про пов\'язаний об\'єкт',
      'Press to control': 'Натисніть контроль',
      'Press to show more': 'Натисніть, щоб показати більше',
      'Press ↵ ENTER to': 'Натисніть ↵ ENTER',
      Preview: 'Попередній перегляд',
      Price: 'Ціна',
      'Price (with costs, without VAT)': 'Ціна (з витратами, без ПДВ)',
      'Price 1t': 'Ціна за 1т',
      'Price currency': 'Валюта ціни',
      'Price discount': 'Дисконт на ціну',
      'Price estimated:': 'Прогнозована ціна:',
      'Price for measurement': 'Ціна на вимірювання',
      'Price in USD': 'Ціни в USD',
      'Price in USD words': 'Ціни в USD (прописом)',
      'Price indicative': 'Ціна орієнтовна',
      'Price no VAT': 'Ціна без ПДВ',
      'Price of premium': 'Преміум ціна',
      'Price opposite': 'Зустрічна ціна',
      'Price options': 'Опції цін',
      'Price per one': 'Ціна за одиницю',
      'Price per piece': 'Ціна за штуку',
      'Price per t': 'Ціна за т',
      'Price premium': 'Преміум ціна',
      'Price premium opposite': 'Преміум ціна зустрічна',
      'Price w/o VAT, UAH': 'Ціна без ПДВ, UAH',
      'Price with VAT': 'Ціна з ПДВ',
      'Price with VAT, UAH': 'Ціна з ПДВ, UAH',
      'Price with discount': 'Ціна з дисконтом',
      'Price without Vat': 'Ціна без ПДВ',
      'Price, $': 'Ціна, usd',
      Prices: 'Ціни',
      Print: 'Друкувати',
      'Print a contract': 'Роздрукувати контракт',
      'Print a document': 'Надрукуйте документ',
      'Print an invoice': 'Роздрукувати інвойс',
      'Print and sign': 'Роздрукувати та підписати',
      Priority: 'Пріоритет',
      Process: 'Процес',
      Processed: 'Оброблено',
      Processing: 'Обробка',
      'Processing...': 'Обробка….',
      Producer: 'Виробник',
      Product: 'Товар',
      'Production Year': 'Рік виробництва',
      Products: 'Продукти',
      Profile: 'Профіль',
      Profit: 'Прибуток',
      'Profit and Loss': 'PnL',
      'Profit and loss': 'PnL',
      Profitability: 'Прибутковість',
      Proforma: 'Проформа',
      'Proforma Invoice': 'Проформа інвойс',
      'Proforma invoice': 'Проформа-інвойс',
      'Program type': 'Тип програми',
      Progress: 'Прогрес',
      'Project cost, $ with VAT': 'Вартість проекту, usd з ПДВ',
      'Project cost, with VAT': 'Вартість проекту, з ПДВ',
      'Project status': 'Стан проекту',
      'Projected rate': 'Очікувана ставка',
      'Projects Status Detailed Summary': 'Статус проектів Детальний підсумок',
      Properties: 'Показники',
      Property: 'Показник',
      'Property "positions volume by execution" is disabled.':
        "Властивість «об'єм позицій за виконанням» відключена.",
      'Property alternative': 'Властивість альтернативна',
      Provider: 'Постачальник',
      Purcahse: 'Закупівля',
      Purchase: 'Закупівля',
      'Purchase Contract': 'Закупівельний контракт',
      'Purchase Contract #': 'Закупівельний контракт №',
      'Purchase Currencies': 'Закупівля валюти',
      'Purchase Init Price': 'Початкова ціна покупки',
      'Purchase KPI': 'Закупівля KPI',
      'Purchase Plan': 'План закупівлі',
      'Purchase Price': 'Ціна закупівлі',
      'Purchase amount': 'Сумма закупівлі',
      'Purchase author': 'Закупівля автор',
      'Purchase contract': 'Закупівельний контракт',
      'Purchase contract #': 'Закупівельний контракт №',
      'Purchase contract author': 'Закупівельний контракт автор',
      'Purchase contract deal type': 'Тип закупівельного контракту',
      'Purchase contract passports': 'Паспорт закупівельного контракту',
      'Purchase contract payment conditions option': 'Опція умов оплати закупівельного контракту',
      'Purchase contract status': 'Статус закупівельного контракту',
      'Purchase contract statuses': 'Статуси закупівельних контрактів',
      'Purchase contracts': 'Закупівельні контракти',
      'Purchase init price': 'Початкова ціна покупки',
      'Purchase loyalty program': 'Закупівля програма лояльності',
      'Purchase plan': 'План закупівлі',
      'Purchase plan report': 'План закупівлі звіт',
      'Purchase price': 'Ціна закупівлі',
      'Purchase price differs': 'Закупівельна ціна різна',
      'Purchase price local': 'Закупівельна ціна локальна',
      'Purchase price wit VAT': 'Ціна закупівлі з ПДВ',
      'Purchase price wit VAT on Elevator': 'Ціна закупівлі з ПДВ на елеватор',
      'Purchase price without VAT': 'Ціна закупівлі без ПДВ',
      'Purchase responsible': 'Відповідальний по закупівлі',
      'Purchase responsible for signing': 'Закупівля відповідальний за підпис',
      'Purchase sale manager': 'Закупівля менеджер з продажу',
      'Purchase status': 'Закупівля статус',
      'Purchase this': 'Придбати це',
      'Purchase to execute': 'Закупівля для виконання',
      'Purchase to invoice': 'Інвойс закупівлі',
      'Purchase to pay': 'Закупівля до оплати',
      'Purchase totals': 'Всього закупівлі',
      'Purchase value local': 'Вартість закупівлі локальна валюта',
      PurchaseContract: 'Закупівельний контракт',
      Purchases: 'Закупівля',
      'Purchases (Analytical - Contract)': 'Закупівля ( Аналітичний - Контракт)',
      'Purchases (Plan - Fact)': 'Закупівля ( План - Факт)',
      QTY: 'КІЛЬКІСТЬ',
      QUARTER: 'КВАРТАЛ',
      QUOTATIONS: 'ПРОПОЗИЦІЇ',
      Qualities: 'Якості',
      Quality: 'Якість',
      'Quality Rules': 'Правила якості',
      'Quality deleted': 'Якість видалено',
      'Quality eng': 'Якісь (англ)',
      'Quality list': 'Список якості',
      'Quality list eng': 'Список якості (англ)',
      'Quality required': 'Потрібна якість',
      'Quality rule Config': 'Конфігурація правил якості',
      'Quality rule templates': 'Шаблони дисконтів якості',
      'Quality rules are not included': 'Правила якості не включені',
      'Quantitative metrics': 'Кількісні метрики',
      Quantity: 'Кількість',
      'Quantity Total': 'Кількість всього',
      'Quantity Total Amount': "Загального сума об'єму",
      'Quantity of measurement': 'Кількість вимірювань',
      'Quantity of wagons by contract': 'Кількість вагонів за контрактом',
      'Quantity rule Config': 'Конфігуратор Правил якості',
      'Quantity total': 'Загальна кількість',
      'Quantity total amount': 'Загальна сума',
      Quarantine: 'Карантин',
      'Quarantine required': 'Потрібен карантин',
      'Quick Edit': 'Швидке редагування',
      'Quick add': 'Швидке додавання',
      'Quick edit': 'Швидке редагування',
      Quit: 'Завершити',
      Quotations: 'Пропозиції',
      'Quote Parsing Config': 'Конфігурація парсингу котирувань',
      'Quote Parsing Config saved': 'Конфігурація парсингу котирувань збережено',
      'Quotes updated.': 'Котирування оновлено.',
      'RATES & COSTS': 'ТАРИФИ ТА ВИТРАТИ',
      'READ ALL': 'ПРОЧИТАТИ ВСЕ',
      REALIZED: 'РЕАЛІЗОВАНО',
      REASSIGNMENT: 'ПЕРЕУСТУПКА',
      REASSIGNMENTS: 'ПЕРЕУСТУПКИ',
      RECEIVE: 'ОТРИМАТИ',
      'RECENTLY UPDATED': 'НЕЩОДАВНО ОНОВЛЕНО',
      RECIPIENTS: 'ОТРИМУВАЧІ',
      RESULT: 'РЕЗУЛЬТАТ',
      RIGHTS: 'ПРАВА',
      ROE: 'ROE',
      ROS: 'ROS',
      'RW payment conditions': 'Умови оплати',
      'RW prepay - balance percent': 'Передоплата залізниці - відсоток балансу',
      Railway: 'Залізниця',
      'Railway cost': 'Витрати залізниці',
      'Railway enterance': 'Залізничний транспорт',
      'Railway losts': 'Залізничні втрати',
      'Railway prepay - balance percent': 'Залізничні передоплати - відсоток балансу',
      'Railway required': 'Потрібна залізниця',
      Range: 'Діапазон',
      Rate: 'Ставка',
      Rates: 'Ставки',
      'Re-approval': 'Повторне підтвердження',
      'Re-approve': 'Повторне підтвердження',
      Reactivate: 'Активувати повторно',
      'Reactivate approving': 'Відновлення підтвердження',
      Ready: 'Готово',
      'Real stocks': 'Реальні залишки',
      Realized: 'Реалізовано',
      'Realized profit and loss': 'Реалізований прибуток і збиток',
      'Realized profit and loss per 1': 'Реалізований прибуток і збиток за 1 рік',
      'Realized quantity': "Реалізований об'єм",
      'Reason for rejection': 'Причина відхилення',
      Reassignment: 'Переуступка',
      'Reassignment amount': 'Сума переуступки',
      'Reassignment date': 'Дата переуступки',
      "Reassignment doesn't exist": 'Переуступки не існує',
      'Reassignment list': 'Список переуступок',
      'Reassignment number': 'Номер переуступки',
      'Reassignment positions': 'Позиція переуступки',
      'Reassignment rate': 'Ставка переуступки',
      'Reassignment saved': 'Переуступку збережено',
      'Reassignment status': 'Статус переуступки',
      'Reassignment value': 'Сума переуступки',
      Reassignments: 'Переуступки',
      Recall: 'Нагадати',
      Recap: 'Підсумок',
      Receivables: 'Доходи',
      Received: 'Отримано',
      'Received (date)': 'Отримано (дата)',
      'Received date': 'Отримано (дата)',
      'Received registry (port)': 'Отриманий реєстр (порт)',
      'Received value': 'Отримана вартість',
      'Received volume': "Отриманий об'єм",
      Receiver: 'Одержувач',
      Receiving: 'Отримання',
      'Receiving (date)': 'Отримання (дата)',
      'Receiving date': 'Отримання (дата)',
      'Receiving date from': 'Дата отримання від',
      'Receiving date to': 'Дата отримання до',
      'Recent confirmations': 'Останні підтвердження',
      'Recieve station': 'Станція отримання',
      'Recipient type': 'Тип одержувача',
      Recipients: 'Одержувачі',
      Reconciliation: 'Звірка',
      Recreate: 'Відновити',
      Red: 'Червоне',
      Region: 'Регіон',
      Regions: 'Регіони',
      'Register stocks': 'Зареєстровані залишки',
      'Registered since': 'Зареєстрований з',
      Registry: 'Реєстр',
      'Registry received (forwarder)': 'Реєстр отриманий (експедитор)',
      'Registry sent (forwarder)': 'Реєстр відправлено (експедитор)',
      'Registry sent (port)': 'Реєстр відправлено (порт)',
      Regular: 'Стандартний',
      Rejected: 'Відхилено',
      'Related counterparty': "Пов'язаний контрагент",
      'Related counterparty from': "Пов'язаний контрагент від",
      'Related counterparty to': "Пов'язаний контрагент до",
      'Related deal': "Пов'язана угода",
      'Related fees': "Пов'язані комісії",
      'Related invoice': "Номер пов'язаного інвойсу",
      'Related invoice number': "Номер пов'язаного інвойсу",
      Relation: 'Відношення',
      Relations: 'Відносини',
      Remains: 'Залишки',
      'Remind me before': 'Нагадати мені до',
      Remove: 'Видалено',
      Rentability: 'Рентабельність',
      'Report config': 'Конфігурація звіту',
      'Report config created': 'Конфігурацію звіту створено',
      'Report config deleted': 'Конфігурацію звіту видалено',
      'Report config updated': 'Конфігурацію звіту оновлено',
      'Report grouping': 'Групування звітів',
      'Report type': 'Тип звіту',
      Reports: 'Звіти',
      Request: 'Заявка',
      'Request (date)': 'Заявка (дата)',
      'Request date': 'Дата заявки',
      'Request removed': 'Тікет видалено',
      'Request saved': 'Тікет збережено',
      'Request updated': 'Тікет оновлено',
      Requested: 'Заявка',
      Required: "Обов'язково",
      Reset: 'Очищення фільтру',
      'Reset values': 'Скинути суму',
      'Residual volume': "Кінцевий об'єм",
      Resource: 'Ресурс',
      Response: 'Відповідь',
      Responsibilities: 'Відповідальність',
      Responsibility: 'Відповідальність',
      Responsible: 'Відповідальний',
      'Responsible for signing': 'Відповідальний за підпис',
      Result: 'Результат',
      'Result date': 'Дата результату',
      'Result month': 'Результат за місяць',
      'Result status': 'Результат статус',
      Risk: 'Ризик',
      'Risk position terminal': 'Ризик позиція термінал',
      'Risk reports': 'Звіти про ризики',
      Road: 'В дорозі',
      Role: 'Роль',
      Roles: 'Ролі',
      'Roles, in which this Counterparty will be presented in system.You can select multiple roles, pressing CTRL (CMD)':
        'Ролі, в яких цей контрагент буде представлений в системі.можна вибрати кілька ролей, натиснувши CTRL (CMD)',
      Rolling: 'Rolling',
      'Rolling stock': 'Rolling склад',
      Russia: 'Росія',
      Russian: 'Русский',
      SALE: 'ПРОДАЖ',
      'SALE ASC': 'ПРОДАЖ ASC',
      'SALE CONTRACTS': 'ПРОДАЖНІ КОНТРАКТИ',
      'SALE COSTS': 'ПРОДАЖ ВИТРАТИ',
      'SALE DESC': 'ПРОДАЖ DESC',
      SAMPLES: 'ЗРАЗКИ',
      'SAP Order Purchase Contract': 'SAP замовлення закупівельний контракт',
      'SAP number': 'SAP номер',
      'SAP order': 'SAP ордер',
      'SAP order numbers': 'SAP номери замовлень',
      'SAP vendor number': 'SAP номер постачальника',
      SAVE: 'ЗБЕРЕГТИ',
      SEARCH: 'ПОШУК',
      SELLER: 'ПРОДАВЕЦЬ',
      SERVICES: 'ПОСЛУГИ',
      SETTINGS: 'НАЛАШТУВАННЯ',
      SHIPMENT: 'ВІДВАНТАЖЕННЯ',
      'SHIPMENT END': 'КІНЕЦЬ ВІДВАНТАЖЕННЯ',
      'SHIPMENT START': 'ПОЧАТОК ВІДВАНТАЖЕННЯ',
      SHORT: 'КОРОТКИЙ',
      SI: 'SI',
      SIDES: 'СТОРОНИ',
      SIGNATURE: 'ПІДПИС',
      SIGNINGS: 'ПІДПИСАННЯ',
      SP: 'SP',
      SPECIAL: 'СПЕЦІАЛЬНИЙ',
      STATISTICS: 'СТАТИСТИКА',
      STATUS: 'СТАТУС',
      STEP: 'КРОК',
      SUPPLIER: 'ПОСТАЧАЛЬНИК',
      'SUPPLIER CONFIRMATION': 'ПІДТВЕРДЖЕННЯ ПОСТАЧАЛЬНИКА',
      SWIFT: 'SWIFT',
      'SYSTEM NOTIFICATION': 'ПОВІДОМЛЕННЯ',
      'SYSTEM USERS': 'СИСТЕМНІ КОРИСТУВАЧІ',
      Sa: 'Сб',
      Sale: 'Продаж',
      'Sale (date)': 'Продаж (дата)',
      'Sale Contract': 'Продажний контракт',
      'Sale Contract #': 'Продажний контракт #',
      'Sale Currencies': 'Продаж валюти',
      'Sale Init Price': 'Початкова ціна продажу',
      'Sale KPI': 'Продаж KPI',
      'Sale Price': 'Ціна продажу',
      'Sale contract': 'Продажний контракт',
      'Sale contract deal type': 'Тип продажного контракту',
      'Sale contract passports': 'Паспорт продажного контракту',
      'Sale contract status': 'Статус продажного контракту',
      'Sale contract statuses': 'Статуси продажних контрактів',
      'Sale contract volume': "Об'єм продажу за контрактом",
      'Sale contracts': 'Продажні контракти',
      'Sale currency and rate': 'Продаж валюти і курсу',
      'Sale discount more then purchase': 'Дисконт на продаж більший, ніж на закупівлю',
      'Sale discount value': 'Сума дисконту на продаж',
      'Sale end execution': 'Продаж кінець виконання',
      'Sale export contract': 'Продажний експортний контракт',
      'Sale init price': 'Початкова ціна продажу',
      'Sale invoice date': 'Дата інвойсу на продаж',
      'Sale or Purchase': 'Продаж або Закупівля',
      'Sale payment date': 'Дата оплати продажу',
      'Sale plan report': 'Звіт про план продажу',
      'Sale price': 'Ціна продажу',
      'Sale price (incl. VAT)': 'Ціна продажу (у т.ч. ПДВ)',
      'Sale price differs': 'Продажна ціна різна',
      'Sale price discount': 'Дисконт на ціну продажу',
      'Sale price local': 'Ціна продажу локальна',
      'Sale start execution': 'Продаж початок виконання',
      'Sale status': 'Статус продажу',
      'Sale this': 'Продати це',
      'Sale to execute': 'Продаж до виконання',
      'Sale to invoice': 'Продаж по інвойсу',
      'Sale to pay': 'Продаж до сплати',
      'Sale total value': 'Загальна суму продажу',
      'Sale total value discounted': 'Загальна суму продажу з урахуванням дисконту',
      'Sale totals': 'Всього продажу',
      SaleContract: 'Продажний контракт',
      Sales: 'Продажі',
      'Sales (Plan - Fact)': 'Продаж ( План - Факт)',
      'Sales amount': 'Сума продажу',
      'Sales execution': 'Виконання продажу',
      'Sales managers': 'Регіональні представники',
      'Sales payment conditions': 'Умови оплати продажного контракту',
      'Sales price': 'Ціна продажу',
      'Sample Control': 'Контроль зразків',
      'Sample In date': 'Зразок дата',
      'Sample Out date': 'Зразки вихідна дата',
      'Sample received date': 'Зразок дата отримання',
      'Sample result date': 'Зразок дата результату',
      'Sample type': 'Тип зразка',
      Samples: 'Зразки',
      'Sampling Condition': 'Стан зразків',
      Sanctions: 'Санкції',
      'Sap Order': 'SAP замовлення',
      'Sap Orders': 'SAP замовлення',
      'Sap orders': 'SAP замовлення',
      Saturday: 'Субота',
      Save: 'Зберегти',
      'Save current filter': 'Зберегти поточний фільтр',
      'Save filers as a preset': 'Зберегти набір фільтрів як шаблон',
      'Save without diff': 'Зберегти без різниці',
      Saved: 'Збережено',
      'Screenshots configs': 'Конфігурації знімків екрану',
      Seal: 'Пломба',
      Search: 'Пошук',
      'Search and press ↵ ENTER': 'Знайдіть і натисніть ↵ ENTER',
      'Search in database': 'Пошук в базі даних',
      'Search permissions': 'Права доступу для пошуку',
      Season: 'Сезон',
      'Season year': 'Рік сезону',
      Seasons: 'Сезони',
      'Second (validation)': 'Друга (валідація)',
      'Second Name': 'Прізвище',
      'Second name': 'Прізвище',
      'Secret invoice': 'Секретний інвойс',
      'See all': 'Переглянути все',
      Seeds: 'Посіви',
      Select: 'Виберіть',
      'Select .XLS template': 'Виберіть шаблон .XLS',
      'Select All': 'Вибрати все',
      'Select Business Unit': 'Виберіть бізнес-юніт',
      'Select Email Template': 'Виберіть шаблон Email',
      'Select Passport for costs': 'Виберіть Паспорт для витрат',
      'Select User to be responsible': 'Виберіть відповідального користувача',
      'Select a collection': 'Виберіть конфігурацію',
      'Select a document': 'Виберіть документ',
      'Select a passport': 'Виберіть паспорт',
      'Select a tags': 'Виберіть теги',
      'Select all documents': 'Виберіть усі документи',
      'Select all transport': 'Виберіть весь транспорт',
      'Select any of item': 'Виберіть будь-який елемент',
      'Select business unit': 'Виберіть бізнес-юніт',
      'Select by which contract property you want to group P&L. Default\n                            grouping can be set in Settings.':
        'Виберіть, за якою власністю контракту бажаєте згруповувати PnL. За замовчуванням\n                            групування можна встановити в налаштуваннях.',
      'Select cargo': 'Виберіть вантаж',
      'Select cargo receiver': 'Виберіть вантажоприймача',
      'Select cargo shipper': 'Виберіть вантажовідправника',
      'Select charges and press confirm': 'Виберіть витрати та натисніть підтвердити',
      'Select commodity of passport': 'Виберіть товар паспорта',
      'Select consignment': 'Виберіть квитанції',
      'Select consignments to create costs': 'Виберіть квитанції для створення витрат',
      'Select contracts for passport': 'Виберіть контракти для паспорта',
      'Select date': 'Виберіть дату',
      'Select departure station': 'Виберіть станцію відправлення',
      'Select elevator': 'Виберіть елеватор',
      'Select invoices to send': 'Виберіть інвойс для відправки',
      'Select logistics': 'Виберіть декілька логістик',
      'Select or drag and drop files': 'Виберіть або перетягніть файли',
      'Select property': 'Виберіть показник',
      'Select purchase contract': 'Виберіть закупівельний контракт',
      'Select responsible': 'Виберіть відповідального',
      'Select sale contract': 'Виберіть продажний контракт',
      'Select station destination': 'Виберіть станцію призначення',
      'Select terminal': 'Виберіть термінал',
      'Select the charge': 'Виберіть витрату',
      'Select to': 'Вибрати',
      'Select to Approve': 'Обрати для підтвердження',
      'Select to Pay': 'Вибрати для оплати',
      'Select to Payment Plan': 'Виберіть план оплати',
      'Select transport': 'Виберіть транспорт',
      'Select transport to send': 'Виберіть транспорт для відправки',
      'Select which margin type you want to display from passports. E.g.\n                            contract - only plan. If mixed - we will show the appropriate margin\n                            per each passport as per MARGIN SELECTOR setting.':
        'Виберіть тип маржі, яку бажаэте показувати з паспортів. \n                            Наприклад, контракт - тільки план. Якщо змішати - ми покажемо відповідну маржу \n                            за кожним паспортом із налаштуванням ПЕРЕМИКАЧ МАРЖІ .',
      'Select/Deselect all': 'Вибрати/Зняти виділення з усіх',
      'Select/Unselect all': 'Вибрати/Скасувати',
      Selected: 'Вибрано',
      'Selected groups': 'Вибрані групи',
      'Selected more than one forwarder.': 'Вибрано більше одного експедитора.',
      'Selfcost Amount, $': 'Собівартість, usd',
      'Selfcost per MT': 'Собівартість за 1т',
      Sell: 'Продаж',
      'Sell quantity': 'Кількість продажу',
      Seller: 'Продавець',
      Send: 'Відправити',
      'Send Screenshot': 'Надіслати скріншот',
      'Send an invoice': 'Відправити інвойс',
      'Send business confirmation': 'Надіслати бізнес підтвердження',
      'Send email': 'Відправити email',
      'Send selected': 'Відправити вибране',
      Sent: 'Відправити',
      'Sent docs to Buyers': 'Відправлено документи покупцям',
      September: 'Вересень',
      'Server error': 'Помилка сервера',
      'Service Contract': 'Сервісний контракт',
      'Service invoice': 'Інвойс за послуги',
      ServiceContract: 'Сервісний контракт',
      Services: 'Послуги',
      'Set cargo confirmation': 'Встановити підтвердження вантажу',
      'Set contract closure': 'Встановити закриття договору',
      'Set contract final volume': "Встановити фінальний об'єм контракту",
      'Set export contract to sale logistics':
        'Встановити експортний контракт для логістики продажу',
      'Set fact volume to plans': "Встановити фактичний об'єм замість плану",
      'Set if only you want to convert amount in USD by a certain rate':
        'Встановити, якщо бажаєте конвертувати суму в USD за певним курсом',
      'Set it only if volume of charge differs from contract volume':
        "Встановити, якщо об'єм вартості відрізняється від об'єму контракту",
      'Set plan': 'Встановити план',
      'Set sale contract to purchase logistics':
        'Встановити продажний контракт для логістики закупівельних контрактів',
      'Set status closed': 'Встановити статус закрито',
      'Set the status Closed for all costs that are fully invoiced and paid':
        'Встановіть статус Закрито для всіх витрат, які повністю виставлені та оплачені',
      'Set voyage data': 'Встановити дані рейсу',
      Settings: 'Налаштування',
      Share: 'Поділитися',
      'Ship classification': 'Класифікація корабля',
      Shipment: 'Відвантаження',
      'Shipment Elevator': 'Елеватор відвантаження',
      'Shipment Farm': 'Господарство відвантаження',
      'Shipment Plan': 'План відвантаження',
      'Shipment Terminal': 'Термінал відвантаження',
      'Shipment balance': 'Баланс відвантаження',
      'Shipment days saved.': 'Кількість днів відвантаження збережено.',
      'Shipment elevator': 'Елеватор відвантаження',
      'Shipment farm': 'Господарство відвантаження',
      'Shipment from the farm': 'Відвантаження з господарств',
      'Shipment from the station': 'Відвантаження зі станції',
      'Shipment on the farm': 'Завантаження на господарство',
      'Shipment on the station': 'Завантаження на станцію',
      'Shipment period': 'Період відвантаження',
      'Shipment place': 'Місце відвантаження',
      'Shipment plan deleted.': 'План відвантаження видалено.',
      'Shipment plan saved.': 'План відвантаження збережено.',
      'Shipment planned': 'Відвантаження заплановане',
      'Shipment terminal': 'Термінал відвантаження',
      'Shipment type': 'Тип відвантаження',
      'Shipment. Completed if shipment is done':
        'Відвантаження. Завершено, якщо відправлення виконано',
      Shipments: 'Відвантаження',
      Shipped: 'Відвантажені',
      'Shipped on board date': 'Дата завантаження на борт',
      'Shipped wagons': 'Відвантажені вагони',
      Short: 'Короткий',
      'Short lots': 'Короткі лоти',
      'Shortage rate': 'Коефіцієнт дефіциту',
      Show: 'Показати',
      'Show Aging': 'Показати затримки',
      'Show Facts': 'Показати факти',
      'Show MTM': 'Показати MTM',
      'Show all': 'Показати все',
      'Show bank balances': 'Показати банківські баланси',
      'Show bank transaction': 'Показати банківську транзакцію',
      'Show child crops': 'Показати дочірні культури',
      'Show debts by plan date': 'Показати заборгованість за датою плану',
      'Show detail': 'Показати деталі',
      'Show details': 'Показати деталі',
      'Show full table': 'Показати повну таблицю',
      'Show history': 'Показати історію',
      'Show info': 'Показати інформацію',
      'Show initial balance': 'Показати початковий баланс',
      'Show my': 'Показати мої',
      'Show past periods debts': 'Показати борги минулих періодів',
      'Show volume': "Показати об'єм",
      'Show wizard': 'Показати wizard',
      'Show/hide all': 'Показати/сховати все',
      Sides: 'Сторони',
      'Sign a contract': 'Підписати контракт',
      Signatory: 'Підписант',
      'Signatory Docs': 'Підписані документи',
      'Signatory Eng': 'Підписант (англ)',
      'Signatory Eng Docs': 'Підписані документи (англ)',
      'Signatory Eng Position': 'Посада підписанта (англ)',
      'Signatory Position': 'Посада підписанта',
      'Signatory docs': 'Підписані документи',
      'Signatory docs eng': 'Підписані документи (англ)',
      'Signatory eng': 'Підписант (англ)',
      'Signatory, the son of Signator': 'Підписант Підписантович',
      Signature: 'Підпис',
      'Signature stages': 'Етапи підпису',
      'Signature. Completed if all sides signed a document':
        'Підпис. Завершено, якщо всі сторони підписали документ',
      Signed: 'Підписано',
      Signings: 'Підписи',
      'Signings KPI': 'Підписи KPI',
      'Signings status': 'Статус підписання',
      Similar: 'Схожі',
      'Simple percent': 'Простий відсоток',
      'Simple system': 'Проста система',
      'Simplified system': 'Спрощена система',
      'Since we group this report by contract, you should choose either by\n                            sales contracts or by purchase contracts':
        'Оскільки ми групуємо цей звіт за контрактом, необхідно вибрати\n                             або за продажними контрактами або за закупівельними контрактами',
      Size: 'Розмір',
      Small: 'Малий',
      'Something went wrong': 'Щось пішло не так',
      'Something went wrong...': 'Щось пішло не так...',
      'Something went wrong... try again.': 'Щось пішло не так... спробуйте ще раз.',
      Source: 'Джерело',
      Special: 'Спеціальні',
      'Specific information about freight': 'Конкретна інформація про фрахт',
      'Specific information about this object': "Конкретна інформація про цей об'єкт",
      Specification: 'Специфікація',
      Spot: 'Спот',
      'Spot fact': 'Спот факт',
      'Spot limit': 'Ліміт споту',
      'Spot or Forward': 'Спот або Форвард',
      Stage: 'Етап',
      'Stage #': 'Етап №',
      Start: 'Початок',
      'Start balance': 'Початковий баланс',
      'Start date': 'Дата початку',
      'Start date (plan)': 'Дата початку (план)',
      'Start end': 'Кінцева дата',
      'Start of execution': 'Початок виконання',
      'Start usage (date)': 'Початок використання (дата)',
      Station: 'Станція',
      'Station Departure': 'Станції відправлення',
      'Station arrival': 'Станція прибуття',
      'Station depart': 'Станція відправлення',
      'Station departure': 'Станції відправлення',
      'Station destination': 'Станція призначення',
      'Station receive': 'Станція отримує',
      'Station receiving': 'Станція отримання',
      Stations: 'Станції',
      Statistics: 'Статистика',
      Status: 'Статус',
      'Status depends on current progress on general agreement':
        'Статус залежить від поточного прогресу за угодою',
      'Status depends on current progress on payment plan':
        'Статус залежить від поточного виконання плану платежів',
      'Status depends on current progress on request':
        'Стан залежить від поточного стану виконання заявки',
      'Status of te deal: NEW - recently created, SHIPMENT - the execution has been started, EXECUTED - the Contract is closed and executed, CANCELLED - rejected by the manager':
        'Статус угоди: НОВА - нещодавно створена, ВІДВАНТАЖЕННЯ - розпочато виконання, ВИКОНАНО - контракт закрито і виконано, СКАСОВАНО - відхилено менеджером',
      'Status updated': 'Статус оновлено',
      'Status will be changed automatically, based on a compliance status. You need to fill out request, received and confirmed date':
        'Статус буде змінено автоматично, виходячи зі статусу комплаєнсу. Потрібно заповнити запит, дату отримання і підтвердження',
      Statuses: 'Статуси',
      Step: 'Крок',
      'Step saved': 'Крок збережено',
      Stock: 'Залишки',
      'Stock Exchange': 'Біржовий курс',
      'Stock exchange': 'Фондова біржа',
      'Stock exchanges': 'Фондові біржі',
      'Stock plan (t)': 'Залишки план (т)',
      'Stock plan amount': 'Залишок план сума',
      Stocklot: 'Біржа',
      Stocks: 'Запаси',
      'Stocks allocation': "Прив'язка залишків",
      Storage: 'Зберігання',
      'Storage & cargo': 'Зберігання та вантаж',
      'Storage days': 'Дні зберігання',
      Stuff: 'Персонал',
      Su: 'Нд',
      'Sub distributor': "Суб-дистриб'ютор",
      'Sub reassignments': 'Подвійна переуступка',
      'Sub use': 'Під використання',
      Subject: 'Тема',
      'Submit documents': 'Підтвердити документи',
      Subscribe: 'Підписатися',
      Subuse: 'Cубкористування',
      Success: 'Успішно',
      'Success created.': 'Успішно створено.',
      'Successfully updated.': 'Успішно оновлено.',
      Summary: 'Підсумок',
      Sunday: 'Неділя',
      'Super Admin Rights': 'Права суперадміністратора',
      Supplier: 'Постачальник',
      'Supplier 10 invoice': 'Постачальник 10 інвойс',
      'Supplier 90 invoice': 'Постачальник 90 інвойс',
      'Supplier EDRPOU code': 'Код ЄДРПОУ постачальника',
      'Supplier ITN (Individual Tax Number)': 'ІПН постачальника (індивідуальний податковий номер)',
      'Supplier Signed': 'Постачальник підписав',
      'Supplier address': 'Адреса постачальника',
      'Supplier address eng': 'Адреса постачальника (англ)',
      'Supplier balance invoice': 'Інвойс балансу постачальника',
      'Supplier bank details': 'Реквізити банку-постачальника',
      'Supplier can signatory docs': 'Постачальник може підписати документи',
      'Supplier can signatory docs eng': 'Постачальник може підписати документи (англ)',
      'Supplier contract': 'Контракт постачальника',
      'Supplier contract #': 'Контракт постачальника №',
      'Supplier contract ISCC': 'Контракт постачальника ISCC',
      'Supplier contract status': 'Статус контракту постачальника',
      'Supplier country': 'Країна постачальника',
      'Supplier country eng': 'Країна постачальника (англ)',
      'Supplier email': 'Постачальник email',
      'Supplier full name': 'Повна назва постачальника',
      'Supplier full name eng': 'Повна назва постачальника (англ)',
      'Supplier invoice volume': "Об'єм інвойсу постачальника",
      'Supplier invoiced': 'Постачальник виставив інвойс',
      'Supplier is required': 'Потрібен постачальник',
      'Supplier legal address': 'Юридична адреса постачальника',
      'Supplier paid': 'Постачальник сплатив',
      'Supplier passports': 'Паспорти постачальників',
      'Supplier payment info': 'Інфо про оплату постачальника',
      'Supplier phone': 'Телефон постачальника',
      'Supplier prepay invoice': 'Інвойс постачальника на передоплату',
      'Supplier signatory': 'Підписант постачальника',
      'Supplier signed': 'Постачальник підписав',
      'Supplier website': 'Веб-сайт постачальника',
      'Supplier/Owner': 'Постачальник/Власник',
      Suppliers: 'Постачальники',
      'Supply chain': 'Ланцюг постачання',
      Surveyor: 'Сюрвеєр',
      Surveyors: 'Сюрвеєри',
      Swap: 'Своп',
      Swift: 'Swift',
      'System Event': 'Системна подія',
      'System notify': 'Системне повідомлення',
      TARIFFS: 'ТАРИФИ',
      TASKS: 'ЗАВДАННЯ',
      TEAM: 'КОМАНДА',
      TEMPLATES: 'ШАБЛОНИ',
      'TICKET DOCUMENTS': 'ДОКУМЕНТИ ТІКЕТУ',
      TICKETS: 'ТІКЕТИ',
      TITLE: 'НАЗВА',
      TOTAL: 'ВСЬОГО',
      'TOTAL INVOICE POSITIONS': 'ЗАГАЛЬНІ ПОЗИЦІЇ ІНВОЙСІВ',
      TOTALS: 'ВСЬОГО',
      'TRADING REPORTS': 'ТОРГОВІ ЗВІТИ',
      TRANSLOAD: 'ПЕРЕВАЛКА',
      TRANSPORT: 'ТРАНСПОРТ',
      'TRANSPORT DOCUMENTS': 'ДОКУМЕНТИ ТРАНСПОРТУ',
      TURNOVER: 'ОБОРОТ',
      Table: 'Таблиця',
      Tag: 'Тег',
      'Tare weight': 'Маса тари',
      Tariff: 'Тариф',
      'Tariff 20': 'Тариф 20',
      'Tariff 40': 'Тариф 40',
      Tariffs: 'Тарифи',
      Task: 'Завдання',
      Tasks: 'Завдання',
      Tax: 'Податок',
      'Tax debt': 'Борг за податками',
      'Tax info': 'Інформація про податок',
      'Tax profit to pay': 'Податок на прибуток до сплати',
      'Tax registration': 'Реєстрація податку',
      'Tax status': 'Податковий статус',
      Team: 'Команда',
      Telegram: 'Telegram',
      'Telegram Chat': 'Telegram чат',
      'Telegram username': 'Нікнейм Telegram',
      Template: 'Шаблон',
      'Template deleted': 'Шаблон видалено',
      'Template name': 'Назва шаблону',
      'Template playground': 'Пісочниця для шаблонів',
      'Template removed': 'Шаблон видалено',
      'Template saved': 'Шаблон збережено',
      Templates: 'Шаблони',
      'Term of proxy': 'Строк дії довіреності',
      Terminal: 'Термінал',
      'Terminal Transshipment': 'Термінал перевалки',
      'Terminal Transshipment Tariff': 'Тариф на термінал перевалки',
      Terminals: 'Термінали',
      Text: 'Текст',
      Th: 'Чт',
      'The Legal Entity for this Role will be auto created':
        'Контрагент для цієї ролі буде створена автоматично',
      'The calculation principle is the following:': 'Принцип розрахунку полягає в наступному:',
      'The commission of bank': 'Комісія банку',
      'The company name abbreviation': 'Абревіатура назви компанії',
      'The contract': 'Контракт',
      'The discount of VAT compensation by the deal': 'Дисконт з ПДВ по угоді',
      'The email for internal use': 'Еmail для внутрішнього використання',
      'The fields contract, derivative must make a unique set.':
        'Поля контракту, деривативу повинні складати унікальний набір.',
      'The fields voyage, commodity must make a unique set.':
        'Поля рейсу, товару повинні складати унікальний набір.',
      'The first name for internal use': 'Перша назва для внутрішнього використання',
      'The form is': 'Форма',
      'The last name for internal use': 'Прізвище для внутрішнього користування',
      'The main person of the company': 'Головна особа компанії',
      'The name for internal use, can be whatever you want':
        'Назва для внутрішнього використання, може бути будь-яка',
      'The normal loss rate of Elevator': 'Норма втрат Елеватора',
      'The normal loss rate of RW': 'Норма втрат Залізниці',
      'The number on which sale price will be multiplied':
        'Число, на яке буде помножена продажна ціна',
      'The personal code of this company in state instances':
        'Персональний код цієї компанії в державних інстанціях',
      'The phone number for internal use': 'Номер телефону для внутрішнього використання',
      'The rate of return for VAT for cargo': 'Ставка повернення ПДВ за вантаж',
      'The rate of return for VAT for costs': 'Ставка повернення ПДВ за витрати',
      'The same you can get on Email or Telegram. You can find the full list in Personal\n                settings.':
        'Те ж саме можна отримати по Email або Telegram. Ви можете знайти повний список\n                 у персональних налаштуваннях.',
      'The sum of delivery costs for intermediate logistics':
        'Сума витрат на доставку проміжної логістики',
      'The sum of delivery costs for this vehicle':
        'Сума витрат на доставку цього транспортного засобу',
      'The sum of penalties for this vehicle. Can be also a negative number':
        'Сума штрафів за цей транспортний засіб. Це також може бути негативне число',
      'The telegram username for internal use': 'Нікнейм telegram для внутрішнього використання',
      'The ticket': 'Тікет',
      'The username for internal use': 'Нікнейм для внутрішнього використання',
      TheadConfig: 'Конфігурація заголовка',
      Theme: 'Тема',
      'There are no selected filters to save': 'Немає вибраних фільтрів для збереження',
      'There are not undrop mailing in your set': 'У вашому наборі немає відкладених розсилок',
      'There are not unsent mailing in your set': 'У вашому наборі немає невідправлених розсилок',
      'There can be automatic updates (e.g. the system automatically posts information\n                about invoices, contracts, payments by counterparty in chronological order)':
        'Можуть бути автоматичні оновлення (наприклад, система автоматично публікує інформацію\n                про інвойси, контракти, платежі контрагентом у хронологічному порядку)',
      'There is no purchase contracs assigned to this passport':
        'До цього паспорта не прив’язано жодних контрактів на закупівлю',
      'There is nothing to show': 'Нічого не знайшли',
      'These is the Subject, your recipients see when receive letter':
        'Це Тема. Її побачать одержувачі листів',
      'These is the Title, only you will see, to identify current template':
        'Це назва, яку бачитимете лише Ви, щоб ідентифікувати шаблон',
      'Third (validation)': 'Третя (валідація)',
      'This Month': 'Цього місяця',
      'This actin will start voting again. Continue?':
        'Ця дія знову почне голосування. Продовжити?',
      'This action will create new ticket. It can be deleted with "Delete" button in the modal window. How much to bite off?':
        'Ця дія створить новий тікет. Його можна видалити кнопкою «Видалити» у модальному вікні. Який об\'єм "відкусити"?',
      'This amount will change final invoice amount': 'Ця сума змінить остаточну суму інвойсу',
      'This cannot be undone': 'Це неможливо скасувати',
      'This contract cannot be connected with this volume to passport.':
        "Цей контракт не може бути з'єднаний з цим об'ємом паспорта.",
      'This contract has estimated volume': "Цей контракт має очікуваний об'єм",
      'This contract has finalized volume': "Цей контракт має кінцевий об'єм",
      'This contract was made from ticket': 'Цей контракт був зроблений із тікета',
      'This contracts already fully connected': "Ці контракти вже з'єднані",
      'This email will be send on behalf of you and via your email, appointed in Settings.':
        'Цей лист буде відправлений від вашого імені і через ваш email, вказаний в налаштуваннях.',
      'This field should be filled to pass compliance':
        'Це поле необхідно заповнити, щоб пройти комплаєнс',
      'This first block is combined P&L. It is based on the result you got by\n                    passports. Right after you press CONTROL button in the certain passport - its\n                    P&L will be instantly displayed here.':
        "Цей перший блок об'єднаний PnL. Він базується на результатах, які ви отримали за паспортами. \n                    Після того, як ви натиснете кнопку КОНТРОЛЬ в певному паспорті - \n                    PnL буде миттєво відображатися тут.",
      'This invoice was made from credit note': 'Цей інвойс був створений з кредит-ноти',
      'This is bank details of our company': 'Це банківські реквізити нашої компанії',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                    example':
        'Ви ввели некоректний номер. Введіть номеру форматі 5 або 5.5\n                    наприклад',
      'This is not a valid float number. Type value in format 5 or 5.5 for\n                example.':
        'Ви ввели некоректний номер. Введіть номеру форматі 5 або 5.5\n                    наприклад.',
      'This is not contract.': 'Це не контракт.',
      'This is the Message of your letter.You can use tags {{ tag }} to add some fields to template here.':
        'Це повідомлення. Ви можете використовувати спеціальні теги {{tag}}, щоб додати деякі поля до шаблону.',
      'This is the report of passports, showing you all allocations of contracts,\n                  costs, invoices and execution between each other.':
        "Це звіт про паспорти, який показує всі зв'язки контрактів, \n                  витрат, інвойси і виконання між собою.",
      'This is your operational P&L.': 'Це ваш операційний PnL.',
      'This logistic already selected.': 'Ця логістика вже обрана.',
      'This object has voted by you already. Skipping.':
        "За цей об'єкт ви вже проголосували. Пропуск.",
      'This should be filled for multilingual agreements':
        'Потрібно заповнити для багатомовних угод',
      'This ticket was made from': 'Цей тікет був створений з',
      'This ticket was made from indicator': 'Цей тікет був зроблений з індикатора',
      'This value will be added to your margin': 'Ця сума буде додана до вашої маржі',
      'This will add a mark that you have no intention to pay this invoice in a nearest future':
        'Це додасть позначку про те, що ви не маєте наміру сплачувати цей інвойс найближчим часом',
      'Thus, you will se profitability of each part and the consolidated profitability of\n              the whole chain.':
        'Таким чином, ви побачите рентабельність кожної частини та консолідовану рентабельність\n               усього ланцюга.',
      Ticker: 'Тікет',
      Ticket: 'Тікет',
      'Ticket ROS': 'Тікет ROS',
      'Ticket creation': 'Створення тікета',
      'Ticket number': 'Номер тікета',
      'Ticket value': 'Тікет сума',
      Tickets: 'Тікети',
      'Tickets margin': 'Маржа тікетів',
      Title: 'Назва',
      'Title can not be empty': 'Назва не може бути порожньою',
      'Title must be unique': 'Назва має бути унікальною',
      To: 'До',
      'To allocate': 'Закріпити',
      'To be invoiced': 'Для виставлення інвойсу',
      'To be invoiced (bal)': 'Для виставлення інвойсу (баланс)',
      'To be paid': 'До сплати',
      'To be shipped': 'Буде відвантажуватись',
      'To filter contracts for allocation': 'Фільтрувати контракти для розподілу',
      'To fix': 'Зафіксувати',
      'To fix lots': 'Зафіксувати лоти',
      'To fix tonnes': 'Зафіксувати тонни',
      'To hedge': 'Захеджувати',
      'To make them appear add email or employees with emails to related\n                            counterparty':
        "Щоб вони з’явилися, додайте email або співробітників із email до пов'язаного\n                            контрагента",
      'To pay': 'Сплатити',
      'To receive': 'Отримати',
      'To which country delivered': 'В яку країну доставлено',
      'To whom': 'Кому',
      Today: 'Сьогодні',
      Tolerance: 'Толеранс',
      'Tolerance minus': 'Толеранс в мінус',
      'Tolerance plus': 'Толеранс в плюс',
      'Tolerance volume': "Об'єм толерансу",
      'Tolerance, %': 'Толеранс, %',
      Top: 'Зверху',
      'Top 20 Buyers': 'Топ-20 покупців',
      'Top 20 Suppliers': 'Топ-20 постачальників',
      Topic: 'Тема',
      Total: 'Всього',
      'Total P&L': 'Всього PnL',
      'Total Volume': "Загальний об'єм",
      'Total absolute value of charge': 'Загальна вартість витрати',
      'Total amount': 'Загальна сума',
      'Total amount of all incoming invoices converted in USD':
        'Загальна сума всіх вхідних інвойсів, конвертована в USD',
      'Total amount of all incoming invoices in': 'Загальна сума всіх вхідних інвойсів у',
      'Total amount of all outgoing invoices converted in USD':
        'Загальна сума всіх вихідних інвойсів, конвертована в USD',
      'Total amount of all outgoing invoices in': 'Загальна сума всіх вихідних інвойсів у',
      'Total approved': 'Всього підтверджено',
      'Total by real stocks': 'Всього реальних залишків',
      'Total cumulative amount of all contracts': 'Загальний сума за всіма контрактами',
      'Total cumulative volume of all contracts': "Загальний об'єм за всіма контрактами",
      'Total for current date': 'Сума для поточної дати',
      'Total in USD': 'Всього в USD',
      'Total invoice position': 'Загальна позиція інвойсу',
      'Total invoiced (fact)': 'Всього виставлено інвойсів (факт)',
      'Total invoiced (plan)': 'Всього виставлено інвойсів (план)',
      'Total margin': 'Загальна маржа',
      'Total outgoing - Total incoming': 'Всього вихідних - Всього вхідних',
      'Total outgoing - Total incoming in': 'Всього вихідних - всього вхідних',
      'Total paid': 'Всього сплачено',
      'Total paid amount by all incoming invoices in':
        'Загальна сплачена сума за всіма вхідними інвойсами в',
      'Total paid amount by all outgoing invoices in':
        'Загальна сплачена сума по всіх вихідних інвойсах в',
      'Total paid amount of all incoming invoices converted in USD':
        'Загальна сплачена сума всіх вхідних інвойсів, конвертованих у USD',
      'Total paid amount of all outgoing invoices converted in USD':
        'Загальна сплачена сума всіх вихідних інвойсів, конвертованих у USD',
      'Total project plan': 'Загальний план проекту',
      'Total quantity': 'Загальна кількість',
      'Total requested': 'Всього тікетів',
      'Total selected': 'Загальна кількість вибраних',
      'Total shipped': 'Всього відвантажено',
      'Total value': 'Загальна сума',
      'Total volume': "Загальний об'єм",
      'Total volume (forward)': "Загальний об'єм (форвард)",
      'Total volume (spot)': "Загальний об'єм (спот)",
      'Total volume of the deal. Choose plan value, at first, but then substitute on delivered volume':
        "Загальний об'єм угоди. Виберіть спочатку сума плану, але потім замініть на поставлений об'єм",
      'Total volume plan': "Загальний об'єм план",
      Totals: 'Всього',
      Trade: 'Торгівля',
      'Trade date': 'Дата угоди',
      Trader: 'Трейдер',
      'Trader note': 'Примітка трейдера',
      'Traders amount': 'Сума трейдера',
      'Traders discount value': 'Сума дисконту для трейдерів',
      'Traders volume': "Об'єм трейдерів",
      'Trades in USD': 'Торги в USD',
      'Trades in local currency': 'Торги в локальній валюті',
      Trading: 'Трейдинг',
      'Trading reports': 'Торгові звіти',
      Transaction: 'Транзакція',
      'Transaction (date)': 'Транзакція (дата)',
      'Transaction Date': 'Дата транзакції',
      'Transaction removed': 'Транзакцію видалено',
      'Transaction sum': 'Сума транзакції',
      Transactions: 'Транзакції',
      Transfer: 'Перевалка',
      Transhipment: 'Перевалка',
      Transit: 'Транзит',
      Transload: 'Перевалка',
      'Transloaded Date': 'Дата перевалки',
      'Transloaded date': 'Дата перевалки',
      'Transloading facility': 'Місце перевалки',
      Transport: 'Транспорт',
      'Transport costs per t': 'Транспортні витрати на т',
      'Transport costs per unit': 'Транспортні витрати на одиницю',
      'Transport count': 'Кількість транспорту',
      'Transports count': 'Кількість транспортів',
      Transshipment: 'Перевалка',
      Tu: 'Вт',
      'Turnover for the period': 'Оборот за період',
      Type: 'Тип \nввести',
      'Type a new name': 'Введіть нову назву',
      'Type address': 'Введіть адресу',
      'Type address (eng)': 'Введіть адресу (англ)',
      'Type any comments': 'Введіть будь- які коментарі',
      'Type bank code': 'Введіть код банку',
      'Type credit note number': 'Введіть номер кредитної ноти',
      'Type days': 'Введіть дні',
      'Type destination': 'Введіть призначення',
      'Type email of user': 'Введіть email користувача',
      'Type first name of user': "Введіть ім' я користувача",
      'Type full name': 'Введіть повну назву',
      'Type full name (eng)': 'Введіть повну назву (англ)',
      'Type here any specific information about this deal':
        'Введіть тут будь-яку інформацію про цю угоду',
      'Type invoice number': 'Введіть номер інвойсу',
      'Type last name of user': 'Введіть прізвище користувача',
      'Type loan number': 'Введіть номер позики',
      'Type name': 'Введіть назву',
      'Type new broker': 'Введіть нового брокера',
      'Type new buyer': 'Введіть нового покупця',
      'Type new supplier': 'Введіть нового постачальника',
      'Type number': 'Тип номера',
      'Type of contract': 'Тип контракту',
      'Type of contracts perspective': 'Тип контракту',
      'Type of operation': 'Тип операції',
      'Type offset number': 'Введіть номер заліку',
      'Type origin': 'Введіть походження',
      'Type packing': 'Тип упаковки',
      'Type percent conditions': 'Введіть відсоткові умови',
      'Type phone number of user': 'Введіть номер телефону користувача',
      'Type price': 'Введіть ціну',
      'Type quantity': 'Введіть кількість',
      'Type rate': 'Введіть ставку',
      'Type reassignment number': 'Введіть номер переуступки',
      'Type swift': 'Введіть SWIFT',
      'Type telegram username of user': 'Введіть нікнейм Telegram',
      'Type title of passport': 'Введіть назву паспорта',
      'Type title of template': 'Введіть назву шаблону',
      'Type username of user': 'Введіть нікнейм',
      'Type value': 'Введіть суму',
      'Type volume': "Введіть об'єм",
      'Type volume max': "Введіть об'єм макс",
      'Type volume min': "Введіть об'єм мін",
      UPDATED: 'ОНОВЛЕНО',
      UPDATES: 'ОНОВЛЕННЯ',
      'UPLOADED DOCUMENTS': 'ЗАВАНТАЖЕНІ ДОКУМЕНТИ',
      USA: 'USD',
      USD: 'USD',
      'USD, EUR': 'USD, EUR',
      'USD, UAH': 'USD, UAH',
      Ukraine: 'Україна',
      Ukrainian: 'Українська',
      Unconfirmed: 'Непідтверджено',
      Unconnected: "Нез'єднані",
      'Unconnected transport': "Незв'язаний транспорт",
      Undefined: 'Невизначено',
      Undelivered: 'Недоставлено',
      'Under discussion': 'Обговорюється',
      Undistributed: 'Нерозподілений',
      'Unit price': 'Ціна юніт',
      'Unload costs per t': 'Витрати на розвантаження на т',
      'Unload costs per unit': 'Витрати на розвантаження за одиницю',
      'Unloading Elevator': 'Розвантаження елеватора',
      'Unloading Farm': 'Розвантаження господарства',
      'Unloading Terminal': 'Розвантаження Термінал',
      'Unloading elevator': 'Елеватор розвантаження',
      'Unloading farm': 'Господарство розвантаження',
      'Unloading place': 'Місце розвантаження',
      'Unloading terminal': 'Термінал розвантаження',
      'Unpaid Cargo': 'Несплачений вантаж',
      'Unpaid amount': 'Несплачена сума',
      'Unpaid volume': "Неоплачений об'єм",
      Unrelevant: 'Нерелевантні',
      'Unselected groups': 'Невибрані групи',
      'Untie contracts from passport': "Відв'язати контракти від паспорта",
      Upcoming: 'Майбутній',
      Update: 'Оновлення',
      'Update Business Date': 'Оновити бізнес дату',
      'Update Execution Stages': 'Оновити етапи виконання',
      'Update Execution Stages (save)': 'Оновити етапи виконання (зберегти)',
      'Update Telegram CHAT_ID': 'Оновити Telegram chat id',
      'Update Volume from BL': "Оновити об'єм з коносаменту",
      'Update all  connections from execution?': "Оновити всі зв'язки з виконання?",
      'Update all volumes': "Оновити всі об'єми",
      'Update cargo_confirmation, payment_confirmation, final_confirmation':
        'Оновити вантажне підтвердження, підтвердження оплати, кінцеве підтвердження',
      'Update date': 'Дата оновлення',
      'Update is the list of all actions performed with the counterparties.':
        'Оновлення — це список всіх дій, які виконуються з контрагентами.',
      'Update purchase volumes': "Оновити об'єми закупівлі",
      'Update sale volumes': "Оновити об'єми продажу",
      'Update status': 'Оновити статус',
      'Update statuses': 'Оновлення статусів',
      'Update time': 'Дата оновлення',
      Updated: 'Оновлено',
      'Updated date': 'Дата оновлення',
      'Updated.': 'Оновлено.',
      Updates: 'Оновлення',
      'Updates by counterparties': 'Оновлення по контрагентам',
      Updatind: 'Оновлення',
      'Updating...': 'Вивантаження...',
      Upload: 'Завантажити',
      'Upload a document': 'Завантажити документ',
      'Uploading is in progress. Please wait': 'Триває вивантаження. Будь ласка, зачекайте',
      'Usage cost': 'Вартість використання',
      Use: 'Використовувати',
      'Use commodity parent match': 'Використовуйте відповідність за батьківським товаром',
      'Use duval': 'Використовуйте Duval',
      'Use escalations': 'Використовуйте ескалації',
      'Use for price': 'Використовуйте для ціни',
      'Use for volume': "Використовуйте для об'єму",
      'Use in position': 'Використовуйте в позиції',
      'Use intermediate logistics in execution calculation':
        'Використовувати проміжну логістику в розрахунку виконання',
      'Use open price': 'Використовуйте відкриту ціну',
      'Use this or %': 'Використовуйте це або %',
      'Use this or discount %': 'Користуйтеся цим або дисконтом %',
      User: 'Користувач',
      'User config': 'Конфігурація користувача',
      'User removed': 'Користувач видалений',
      'User saved': 'Користувача збережено',
      'User to be manager for the offset': 'Користувач відповідальний за залік',
      'User to be manager for the reassignment': 'Користувач відповідальний за переуступку',
      'User to be responsible': 'Відповідальний користувач',
      'User to be responsible for the contract': 'Користувач, відповідальний за контракт',
      'User to be responsible for the deal': 'Користувач, відповідальний за угоду',
      'User to be responsible_for_signing': 'Користувач, відповідальний за підписання',
      'User updated': 'Користувач оновлений',
      Username: 'Нікнейм',
      Users: 'Користувачі',
      VALIDATION: 'ВАЛІДАЦІЯ',
      VALUE: 'ЗНАЧЕННЯ',
      VAT: 'ПДВ',
      'VAT amount': 'сума ПДВ',
      'VAT costs return rate': 'Повернення ПДВ за витратами',
      'VAT return': 'Повернення ПДВ',
      'VAT return rate': 'Ставка повернення ПДВ',
      'VAT value': 'Вартість ПДВ',
      VEHICLE: 'ТРАНСПОРТ',
      VESSEL: 'КОРАБЕЛЬ',
      VESSELS: 'КОРАБЛІ',
      'VET required': 'Необхідний Vet',
      VOLUME: "ОБ'ЄМ",
      VOLUMES: "ОБ'ЄМИ",
      VOYAGE: 'РЕЙС',
      'VOYAGE DESTINATION DATES': 'РЕЙС ДАТИ ПРИЗНАЧЕННЯ',
      'VOYAGE LOADING DATES': 'РЕЙС ДАТИ ЗАВАНТАЖЕННЯ',
      VOYAGES: 'РЕЙСИ',
      Valid: 'Дійсний',
      Validated: 'Підтверджено',
      Value: 'Значення',
      'Value - invoiced': 'Сума - виставлено інвойс',
      'Value - paid': 'Сума - сплачено',
      'Value alt': 'Значення альтернативне',
      'Value alternative': 'Інше значення',
      'Value general': 'Загальна вартість',
      'Value general (forward)': 'Загальна вартість (форвард)',
      'Value general (spot)': 'Загальна вартість (спот)',
      'Value no VAT': 'Сума без ПДВ',
      'Value of charge in %. Will be converted to total amount automatically, based on contract amount':
        'Вартість витрати у %. Буде автоматично розраховано на загальну суму на основі суми контракту',
      'Value of charge per 1 metric ton. Will be converted to total amount automatically, based on contract quantity':
        "Вартість витрати за 1 т. Буде автоматично розраховано в загальну суму на основі об'єму контракту",
      'Value of goods': 'Вартість товару',
      'Value per deal': 'Вартість за угоду',
      'Value per deal (forward)': 'Вартість за угоду (форвартд)',
      'Value per deal (spot)': 'Вартість за угоду (спот)',
      'Value w/o VAT, UAH': 'Вартість без ПДВ, UAH',
      'Value with VAT': 'Вартість з ПДВ',
      'Value, UAH': 'Вартість, UAH',
      Vat: 'ПДВ',
      Vehicle: 'Транспортний засіб',
      'Vehicle (#)': 'Транспортний засіб (№)',
      'Vehicle (number)': 'Транспортний засіб (номер)',
      'Vehicle (number) alt': 'Транспортний засіб (причіп)',
      'Vehicle number': 'Номер транспортного засобу',
      'Vehicle removed': 'Видалено транспортний засіб',
      'Vehicle saved': 'Збережено транспортний засіб',
      'Vehicle subtypes': 'Підтипи ТЗ',
      'Vehicle type': 'Тип транспортного засобу',
      'Vehicle types': 'Типи транспортних засобів',
      'Vehicle updated': 'Оновлено транспортний засіб',
      Vehicles: 'Транспортні засоби',
      Vessel: 'Корабель',
      'Vessel IMO': 'Корабель IMO',
      'Vessel capacity': 'Місткість корабля',
      'Vessel imo': 'Корабель imo',
      'Vessel info': 'Інформація про корабель',
      'Vessel name': 'Назва корабля',
      'Vessel size': 'Розмір судна',
      'Vessel sizes': 'Розміри судна',
      'Vessel with such name and IMO already exists. Are you sure you want to save it?':
        'Судно з такою назвою та ІМО вже існує. Ви впевнені, що хочете зберегти його?',
      'Vessel year': 'Рік корабля',
      Vessels: 'Кораблі',
      'Vessels are loaded': 'Завантажені кораблі',
      Vet: 'Vet',
      'Vet enterance': 'Vet внесення',
      'Vet required': 'Необхідний Vet',
      Virtual: 'Віртуальний',
      Visible: 'Наявний',
      Volume: "Об'єм",
      'Volume (boarded)': "Об'єм завантажений",
      'Volume (departed consignment)': "Об'єм (відправлений по накладній)",
      'Volume (departed)': "Об'єм (відправлений)",
      'Volume (lost)': "Об'єм (втрачений)",
      'Volume (real)': "Об'єм (реальний)",
      'Volume (received)': "Об'єм (отриманий)",
      'Volume (t)': "Об'єм (т)",
      'Volume Departed': 'Відправлений обʼєм',
      'Volume Departed Real': 'Обʼєм заліковий',
      'Volume Received': "Об'єм отриманий",
      'Volume With Docs (T)': "Об'єм по документам (т)",
      'Volume at end': "Об'єм в кінці",
      'Volume at start': "Об'єм на початку",
      'Volume boarded': "Об'єм завантажений",
      'Volume departed': "Об'єм відправлений",
      'Volume departed by consignment': 'Відправлений обʼєм за ТТН',
      'Volume departed consignment': 'Об’єм (за ТТН)',
      'Volume departed real': 'Обʼєм заліковий',
      'Volume discharged': "Об'єм розвантажений",
      'Volume discount': 'Дисконт за обʼємом',
      'Volume discounted': "Об'єм із дисконтом",
      'Volume estimated': "Очікуваний об'єм",
      'Volume for calculations': "Об'єм для розрахунків",
      'Volume in mt': "Об'єм, т",
      'Volume in positions from execution': "Об'єм в позиціях від виконання",
      'Volume intake': "Об'єм завантаження",
      'Volume intake sum': "Об'єм завантаження",
      'Volume lost': "Втрачений об'єм",
      'Volume max': "Об'єм макс",
      'Volume min': "Об'єм мін",
      'Volume must be greater than 0': "Об'єм повинен бути більшим за 0",
      'Volume of deal': "Об'єм угоди",
      'Volume options': "Об'єм опції",
      'Volume per deal': "Об'єм за одну угоду",
      'Volume per deal (forward)': "Об'єм за одну угоду (форвард)",
      'Volume per deal (spot)': "Об'єм за одну угоду (спот)",
      'Volume plan': "План об'єму",
      'Volume received': "Об'єм отриманий",
      'Volume received at the destination place': "Об'єм, отриманий у місці призначення",
      'Volume reconciliation': 'Звірка обʼємів',
      'Volume sum from positions': "Сума об'єму з позицій",
      'Volume trader': "Об'єм трейдера",
      'Volume, $ with VAT': "Об'єм, usd з ПДВ",
      'Volume, t': "Об'єм, т",
      'Volume/Goal': "Об'єм/ціль",
      Volumes: "Об'єми",
      Vote: 'Голосувати',
      Voyage: 'Рейс',
      'Voyage name': 'Назва рейсу',
      'Voyage status': 'Статус рейсу',
      'Voyage status updated': 'Стан рейсу оновлено',
      Voyages: 'Рейси',
      W: 'W',
      'W/O Broker': 'Без брокера',
      'W/o business unit': 'Без бізнес юніта',
      WAREHOUSE: 'СКЛАД',
      'WAREHOUSE RECEIPTS': 'СКЛАДСЬКІ КВИТАНЦІЇ',
      'WE BUY': 'МИ КУПУЄМО',
      'WE SELL': 'МИ ПРОДАЄМО',
      'WITH VAT': 'З ПДВ',
      Wagon: 'Вагон',
      Wait: 'Зачекайте',
      'Wait a moment...': 'Зачекайте хвилину...',
      Waiting: 'Очікування',
      'Waiting for approval': 'В очікуванні підтвердження',
      'Waiting my approval': 'Очікує мого підтвердження',
      Warehouse: 'Склад',
      'Warehouse Losses': 'Складські втрати',
      'Warehouse Shipment': 'Відвантаження зі складу',
      'Warehouse Trade': 'Складська торгівля',
      'Warehouse distances': 'Відстань до складу',
      'Warehouse receipts': 'Складські квитанції',
      'Warehouse type': 'Тип складу',
      Warm: 'Теплий',
      Washout: 'Відміна',
      We: 'Ми',
      'We spread them onto two groups: past and future. If update has a due date in the\n                future - it will appear in a table on the right. If you click on its button\n                (success|fail) you will determine whether it is done or not and it will go to the\n                table on the left.':
        "Ми розподіляємо їх на дві групи: минуле і майбутнє. Якщо оновлення має дату закінчення\n                в майбутньому - з'явиться в таблиці справа. Якщо ви натиснете на кнопку \n                (успішно|невдача) ви визначите, статус зроблено або ні, і дані будуть відображатись \n                у таблиці зліва.",
      Website: 'Веб-сайт',
      Week: 'Тиждень',
      'Welcome to the home of': 'Ласкаво просимо у світі',
      'When all the certification have been resolved': 'Коли всі сертифікації будуть вирішені',
      'When payment was actually made': 'Коли оплата була фактично зроблена',
      'When you expect payment has actually been made':
        'Коли очікуєте, що оплата фактично буде здійснена',
      'When you expect payment to be made': 'Коли очікуєте, що оплата буде здійснена',
      'When you have conducted money': 'Коли провели гроші',
      'When you have issued invoice': 'Коли виставили інвойс',
      'When you have received invoice': 'Коли отримали інвойс',
      White: 'Біла',
      'Who issued invoice': 'Хто створив інвойс',
      'Who receives invoice': 'Хто отримує інвойс',
      'Whoose option': 'Яка опція',
      'Why am i seeing this': 'Чому я це бачу',
      'Will be change automatically, based on passport execution':
        'Змінюється автоматично, на основі виконання паспорта',
      'Will be change automatically, based on payment. New - no payment yet, Process - paid not in full, Paid - paid in full':
        'Зміни за оплатою будуть автоматичні. Нові - ще не оплачені, Процес - не повністю оплачені, Сплачені - сплачені в повному обсязі',
      'Will be changed automatically based on configuration':
        'Буде автоматично змінено на основі налаштування',
      'Will be filled automatically, when you set cargo execution':
        'Заповнюється автоматично, коли ви встановлюєте виконання вантажу',
      'With Passport': 'З паспортом',
      'With buyers': 'З покупцями',
      'With contracts': 'З контрактами',
      'With contracts voyage': 'З рейсами за контрактами',
      'With currency exchange': 'З обміном валюти',
      'With date confirmation': 'З датою підтвердження',
      'With date received': 'З датою отримання',
      'With date request': 'З датою заявки',
      'With derivative': 'З деривативом',
      'With destination country': 'З країною призначення',
      'With disport': 'З портом призначення',
      'With exporters': 'З експортерами',
      'With internal chain': 'З внутрішнім ланцюгом',
      'With loadport': 'З портом завантаження',
      'With opposite price': 'З протилежною ціною',
      'With origin country': 'З країною походження',
      'With payment delay': 'Із затримкою оплати',
      'With ports': 'З портами',
      'With roles': 'З ролями',
      'With suppliers': 'З постачальниками',
      'With voyage': 'З рейсом',
      Withdrawal: 'Списання',
      Without: 'Без',
      'Without a status': 'Без статусу',
      'Without rate': 'Без ставки',
      Wizard: 'Wizard',
      'Work in progress': 'Робота в процесі',
      'Write an email': 'Створити email',
      'Write down any additional information about this vehicle)':
        'Напишіть будь-яку додаткову інформацію про цей транспортний засіб',
      'Write down any note about this vehicle)':
        'Напишіть будь-яку примітку про цей транспортний засіб',
      'Write down the specific information  about this invoice':
        'Напишіть конкретну інформацію про цей інвойс',
      'Write down the specific information about this bank operation':
        'Запишіть конкретну інформацію про цю банківську операцію',
      'Write of volume': "Об'єм списання",
      'Write some update here...': 'Написати оновлення тут...',
      'Write-of volume balance': 'Відкритий баланс до списання',
      'Wrong crops': 'Неправильні культури',
      'Wrong volume': "Неправильний об'єм",
      Year: 'Рік',
      'Year %': 'Рік, %',
      'Year Budget': 'Річний бюджет',
      'Year budget': 'Річний бюджет',
      Yellow: 'Жовтий',
      Yes: 'Так',
      Yesterday: 'Вчора',
      You: 'Ви',
      'You can click on buttons to display these objects inside passport.':
        "Ви можете натиснути на кнопки, щоб показати ці об'єкти всередині паспорта.",
      'You can create and apply your own statuses for passport':
        'Ви можете створити і застосувати власні статуси для паспорта',
      'You can directly go to a consolidated PNL report':
        'Ви можете безпосередньо перейти до консолідованого звіту PnL',
      'You can open information about all passports that are inside certain\n                            cell.':
        'Можна відкрити інформацію про всі паспорти, які знаходяться всередині певної \n                            комірки.',
      'You can open the information about open deals with a\n                            position':
        'Ви можете відкрити інформацію про відкриті угоди \n                            з позицією',
      'You can pick up multiple objects, clicking with CTRL':
        "Ви можете вибрати кілька об'єктів, натиснувши CTRL",
      'You can see the quantity by each grouping criteria and\n                            passport':
        'Ви можете побачити кількість за кожним критерієм групування та \n                            паспортом',
      'You can select multiple roles, pressing CTRL':
        'Можна вибрати декілька ролей, натиснувши CTRL',
      "You can't edit approved clients": 'Ви не можете редагувати затверджених клієнтів',
      "You can't vote for this object": "Ви не можете голосувати за цей об'єкт",
      'You do not have new notifications': 'У вас немає нових повідомлень',
      'You have a long position': 'У вас довга позиція',
      'You have a short position': 'У вас коротка позиція',
      'You have voted for this object. Revote this?':
        "Ви проголосували за цей об'єкт. Переголосуйте?",
      'You need to verify Google account': 'Вам потрібно підтвердити обліковий запис Google',
      'a country': 'країна',
      'a district': 'район',
      'a size': 'розмір',
      'a status': 'статус',
      'a supplier to which this Exporter is affiliated':
        "постачальника, з яким пов'язаний цей експортер",
      'a type': 'тип',
      'access admin': 'вхід в адмін',
      'access rights': 'права доступу',
      account: 'рахунок',
      'account name': 'рахунок',
      'account type': 'тип рахунку',
      actions: 'дії',
      active: 'активно',
      activity: 'активність',
      'actual cash amount': 'поточна сума на рахунку',
      'actual cash amount, $': 'фактична сума коштів, $',
      'actually shipped from Transport page': 'актуальні відвантаження за реєстром',
      add: 'додати',
      'add another': 'додати інше',
      'add costs': 'додати витрату',
      'add stock': 'додати запаси',
      'add stocks': 'додати залишки',
      additional: 'додатково',
      'additional info': 'примітка',
      address: 'адреса',
      admin: 'адмін',
      agent: 'посередник',
      agreement: 'угода',
      all: 'всі',
      'all logistics': 'вся логістика',
      allocated: 'розподілено',
      'allocated to this passport': "з'єднано з паспортом",
      alt: 'додатково',
      alternative: 'додатковий',
      amount: 'cума',
      'amount to connect': 'сума для з’єднання',
      'amount type': 'тип суми',
      'amount with vat': 'сума з ПДВ',
      'amount, $': 'сума, USD',
      analytical: 'аналітичний',
      'analytical amount': 'аналітична сума',
      'analytical amount <> amount': 'аналітична сума <> сума',
      'analytical diff': 'аналітична різниця',
      'analytical price': 'аналітична ціна',
      'analytical value': 'аналітична вартість',
      and: 'і',
      'any of logistics': 'будь-яка логістика',
      apply: 'застосувати',
      approval: 'підтвердження',
      'approval config': 'конфігурація підтвердження',
      'approval configuration': 'конфігурація підтвердження',
      'approval status': 'статус підтвердження',
      approvals: 'підтвердження',
      approved: 'підтверджено',
      'arrival end': 'закінчення прибуття',
      'arrival period': 'період прибуття',
      'arrival period end': 'закінчення періоду прибуття',
      'arrival period start': 'початок періоду прибуття',
      'arrival start': 'початок прибуття',
      assignment: 'призначення',
      'at port': 'в порту',
      'at road': 'в дорозі',
      attached: "з'єднано",
      author: 'автор',
      authors: 'автори',
      autor: 'авто',
      available: 'доступні',
      'available for allocation': 'доступні для з’єднання',
      'available to connect': "доступні для з'єднання",
      avg: 'середнє',
      'avg charges': 'середні витрати',
      'avg fixing': 'середній фіксінг',
      'avg price': 'середня ціна',
      balance: 'баланс',
      'balance invoice, issued by you to Buyer': 'інвойс балансу, виданий вами для покупця',
      'balance invoice, issued to you by Supplier': 'інвойс балансу, виданий вам постачальником',
      'balance to execute': 'баланс, який слід виконати',
      'balance with payment': 'баланс з оплатою',
      bank: 'банк',
      'bank account': 'банківський рахунок',
      'bank accounts': 'банківські рахунки',
      'bank details': 'банківські реквізити',
      'bank name': 'назва банку',
      bank_name: 'назва банку',
      'based on profit and loss by': 'на основі прибутків і збитків по',
      basis: 'базис',
      'basis doc': 'базовий документ',
      'basis doc with copy': 'базовий документ із копією',
      'basis doc with file': 'базовий документ із файлом',
      'basis doc without copy': 'базовий документ без копії',
      'basis doc without file': 'базовий документ без файлу',
      'basis of buyer contract': 'базис контракту покупця',
      'basis of supplier contract': 'базис контракту постачальника',
      basises: 'базиси',
      'before execution ends': 'до завершення виконання',
      berthing: 'berthing',
      'bill of lading': 'коносамент',
      'billoflading number': 'коносамент номер',
      birthday: 'день народження',
      'black list': 'чорний список',
      block: 'блок',
      boarded: 'завантажено',
      borrowers: 'позичальники',
      broker: 'брокер',
      'broker confirmation (date)': 'підтвердження брокера (дата)',
      'broker confirmation required': 'необхідне підтвердження брокера',
      'broker freight costs': 'витрати брокера фрахту',
      budget: 'бюджет',
      bulk: 'загальне',
      'business date': 'бізнес дата',
      'business unit': 'бізнес-юніти',
      'business units': 'бізнес-юніти',
      businessunit: 'бізнес-юніт',
      buyer: 'покупець',
      'buyer balance invoices': 'інвойс балансу покупця',
      'buyer contract': 'контракт покупця',
      'buyer contract basis': 'базис контракту покупця',
      'buyer contracts': 'контракти покупця',
      'buyer invoiced': 'інвойс покупця',
      'buyer invoiced balance': 'інвойс балансу покупця',
      'buyer managers': 'менеджери покупців',
      'buyer paid': 'покупець сплатив',
      'buyer paid balance': 'покупець сплатив баланс',
      'buyer prepay invoices': 'інвойс на передоплату покупця',
      'buyer un invoiced': 'покупець не виставив інвойс',
      'buyer un invoiced balance': 'покупець не виставив інвойс балансу',
      buyers: 'покупці',
      by: 'за',
      'by commodities': 'за товарами',
      'by crop': 'за культурою',
      'by currencies': 'за валютами',
      'by default': 'за замовчуванням',
      'by price': 'за ціною',
      'by selected': 'за обраним',
      calc: 'обчислення',
      cancel: 'скасувати',
      'cancel changes': 'скасувати зміни',
      cancelled: 'скасовано',
      'caontacts logistic prices': 'ціни на логістику контрактів',
      capacity: 'місткість',
      'capacity est': 'місткість очікувана',
      'capacity max': 'місткість макс',
      'capacity min': 'місткість мін',
      cargo: 'вантаж',
      'cargo brands': 'бренди вантажу',
      'cargo confirm': 'підтвердження вантажу',
      cargo_executed: 'виконаний за вантажем',
      category: 'категорія',
      cd: 'cd',
      'cd or tt': 'cd or tt',
      cent: 'цент',
      chain: 'ланцюг',
      'change a language, theme': 'змінити мову, тему',
      charge: 'витрата',
      charges: 'витрати',
      'charges per 1 mt': 'витрати за 1т',
      chart: 'графік',
      charterer: 'фрахтувальник',
      'choose file type': 'виберіть тип файла',
      'choose one or multiple recipients': 'вибрати один або кілька одержувачів',
      city: 'місто',
      class: 'клас',
      clear: 'очистити',
      'click here to expand the list of contracts inside each passport':
        'натисніть тут,щоб розширити перелік контрактів у кожному паспорті',
      client: 'контрагент',
      'client doc': 'документ контрагента',
      'client role from type': 'контрагент від',
      'client role name': 'назва ролі контрагента',
      'client role to type': 'контрагент до',
      'client role type': 'тип ролі контрагента',
      'clientrole from': 'роль контрагента від',
      'clientrole to': 'роль контрагента до',
      clients: 'контрагенти',
      clone: 'клонувати',
      close: 'закрити',
      'close window': 'закрити вікно',
      code: 'код',
      'coefficient of payments delay': 'коефіцієнт затримки оплати',
      collection: 'конфігурація',
      comment: 'коментар',
      comments: 'коментарі',
      'commisiton account': 'комісія рахунку',
      commodities: 'товари',
      commodity: 'товар',
      companies: 'компанії',
      company: 'компанія',
      'company groups': 'групи компаній',
      'company.legacy': 'компанія. історія',
      complete: 'завершити',
      completion: 'виконання',
      'completion ratio': 'коефіцієнт виконання',
      compliance: 'комплаєнсу',
      'compliance status': 'статус комплаєнсу',
      concluded: 'дата укладання',
      conclusion: 'укладання',
      'conclusion date': 'дата укладання',
      'conclusion price': 'ціна при підписанні',
      condition: 'умова',
      conditions: 'умови',
      config: 'конфігурація',
      'confirmation date - the date when you confirmed the legal documents of this company':
        'дата підтвердження - дата, коли Ви підтвердили юридичні документи цієї компанії',
      confirmations: 'підтвердження',
      confirmed: 'підтверджено',
      connect: "з'єднати",
      connected: "з'єднано",
      'consecutive voyages': 'наступний рейс',
      consignment: 'накладні',
      'consignment contracts': 'виберіть контракти накладних',
      'consignment elevators': 'накладні елеватори',
      'consignment number': 'номер накладної',
      consignments: 'складські квитанції',
      consolidated: 'консолідований',
      contact: 'контакт',
      'contact info': 'контактна інформація',
      contacts: 'контакти',
      continue: 'продовжити',
      contract: 'контракт',
      'contract avg charges': 'середні витрати за контрактом',
      'contract business unit': 'бізнес-юніт контракту',
      'contract buyer': 'покупець контракту',
      'contract charges/gains': 'контрактні витрати/доходи',
      'contract commodity': 'товар контракту',
      'contract custom status': 'статус за контрактом',
      'contract incoterms': 'базис контракту',
      'contract is confirmed by cargo': 'контракт підтверджено вантажем',
      'contract number': 'номер контракту',
      'contract option': 'опція контракту',
      'contract passport': 'паспорт контракту',
      'contract payment conditions': 'умови оплати за контрактом',
      'contract price': 'ціна контракту',
      'contract status': 'статус контракту',
      'contract supplier': 'постачальник контракту',
      'contract total charges': 'загальні витрати контракту',
      'contract type': 'тип контракту',
      'contract value': 'сума контракту',
      'contract vat return value': 'вартість повернення ПДВ за контрактом',
      'contract volume': "об'єм контракту",
      contracts: 'контракти',
      'contracts passport custom status': 'спеціальний статус паспорту контрактів',
      'contracts passport vessel': 'паспорт контракту для коробля',
      'contracts passport voyage': 'паспорт контракту для рейсу',
      controlled: 'контролюється',
      'controlled by': 'контролюється',
      'converted price': 'конвертована ціна',
      copy: 'копія',
      correspondent: 'кореспондент',
      'cost invoice': 'інвойс витрат',
      'cost of goods sold': 'собівартість реалізованих товарів',
      costs: 'витрати',
      'costs amount': 'сума витрат',
      'costs from purchase contracts of this passport':
        'витрати з продажних контрактів під цей паспорт',
      'costs from sale contracts of this passport': 'витрати з продажних контрактів цього паспорта',
      'costs from this passport': 'витрати з цього паспорта',
      'costs invoices': 'інвойси на витрати',
      'costs opposite': 'зустрічні витрати',
      'costs vat return value': 'витрати на вартість повернення ПДВ',
      count: 'кількість',
      counterparties: 'контрагенти',
      counterparty: 'контрагент',
      'counterparty amount': 'сума контрагента',
      'counterparty bank account': 'банківський рахунок контрагента',
      'counterpaty payment info': 'інформація про оплату контрагента',
      country: 'країна',
      'create template': 'створити шаблон',
      'create user': 'створити користувача',
      created: 'створено',
      'created from': 'створено з',
      creation: 'створено',
      'creation date': 'дата створення',
      'credit accounts': 'кредит рахунок',
      'credit notes': 'кредит-ноти',
      creditor: 'кредитор',
      critical: 'критичний',
      crop: 'культура',
      'crop class': 'клас культури',
      'crop protection': 'захист посівів',
      'crop protection amount': 'захист посівів сума',
      'crop year': 'рік врожаю',
      crops: 'культури',
      'ctr price': 'ціна',
      currencies: 'валюти',
      currency: 'валюта',
      'currency & Exchange Rate': 'валюта і обмінний курс',
      'currency exchange': 'обмін валют',
      'currency rate': 'курс валют',
      current: 'поточний',
      'current delay, h': 'поточна затримка, годин',
      'current position': 'поточної позиція',
      'custom clientrole': 'роль контрагента',
      'custom status': 'статус користувача',
      customs: 'митниця',
      'customs cargo declarations': 'вантажні митні декларації',
      'data checked': 'перевірені дані',
      date: 'дата',
      'date filters': 'фільтри дат',
      'date joined': 'дата реєстрації',
      'date of execution opposite': 'зустрічна дата виконання',
      'date range while the proposition is actual': 'термін, коли пропозиція актуальна',
      dates: 'дати',
      day: 'день',
      days: 'днів',
      'days of payments delay': 'днів затримки платежів',
      'days to load': 'днів для завантаження',
      'debit accounts': 'дебетні рахунки',
      'debit/credit': 'дебет / кредит',
      debitor: 'дебітор',
      'declaration (date)': 'декларація (дата)',
      delay: 'затримка',
      delete: 'видалити',
      'delete a transfer': 'видалити перевалку',
      'delete agreement': 'видалити угоду',
      'delete consignment': 'видалити накладну',
      'delete file': 'видалити файл',
      'delete item': 'видалити елемент',
      'delete logistic': 'видалити логістику',
      'delete permanently': 'видалити назавжди',
      'delete report congif': 'видалити конфігурацію звіту',
      delivered: 'доставлено',
      delivery: 'доставка',
      'delivery condition': 'вид транспортування',
      'delivery conditions': 'умови доставки',
      'delivery period': 'період доставки',
      delivery_period: 'термін поставки',
      'demurrage rate': 'ставка demurrage',
      departed: 'відправлений',
      'departure station': 'станція відправлення',
      'departure stations': 'станції відправлення',
      derivative: 'дериватив',
      'derivative account': 'рахунок дериватива',
      'derivative cargo': 'культура деривативів',
      derivatives: 'деривативи',
      'derivatives margin': 'маржа деривативів',
      dest: 'призначення',
      destination: 'призначення',
      'destination 1st port': 'порт призначення 1-й',
      'destination 2nd port': 'порт призначення 2-й',
      'destination country': 'країна призначення',
      'destination of crop': 'призначення культури',
      'destination port': 'порт призначення',
      details: 'деталі',
      'details of the profit and loss of': 'деталі PnL',
      'df costs': 'витрати',
      'df quantity': "об'єм",
      'did not happen': 'не відбулось',
      'disbursement bill of lading': 'видаткова накладна',
      'disbursement bl': 'видаткова накладна',
      disbursementBL: 'видаткова накладна',
      disbursementbl: 'видаткова накладна',
      'disch rate': 'норма розвантаження',
      'discharge dem/dis': 'розвантаження dem/dis',
      'discharge rate': 'норма розвантаження',
      'discharge volume': "об'єм розвантаження",
      'discharge window': 'вікно розвантаження',
      discharged: 'розвантажений',
      discount: 'дисконт',
      'discount amount': 'розмір дисконту',
      'discount mt': 'дисконт за тонну',
      'discount value': 'сума дисконту',
      'dispatch rate': 'норма відправлення',
      'disponent owner': 'диспонент-власник',
      disport: 'порт призначення',
      'distribution by cargo': 'розподіл за вантажем',
      distributor: "дистриб'ютор",
      distributors: "дистриб'ютори",
      district: 'район',
      docs: 'документи',
      'docs status': 'статус документів',
      document: 'документ',
      documents: 'документи',
      done: 'виконано',
      'download all': 'завантажити все',
      'download docx': 'завантажити docx',
      'download file on the computer': "завантажити файл на комп'ютер",
      'download pdf': 'завантажити pdf',
      'due date': 'термін виконання',
      edit: 'редагувати',
      'edit a transfer': 'редагування перевалки',
      'edit changes': 'редагування зміни',
      'edit contract': 'редагувати контракт',
      editor: 'редактор',
      editors: 'редактори',
      elevator: 'елеватор',
      'elevator (with consignment)': 'елеватор (за накладною)',
      elevators: 'елеватори',
      email: 'email',
      'email.mail.com': 'email.mail.com',
      emails: 'emails',
      'empty file': 'порожній файл',
      end: 'кінець',
      'end balance': 'кінцевий баланс',
      'end date': 'кінцева дата',
      'end of execution': 'кінець виконання',
      eng: 'англ',
      est: 'очікуваний',
      'est.': 'очікуваний.',
      'estimated lots': 'очікувані лоти',
      'estimated volume': "очікуваний об'єм",
      eta: 'eta',
      etb: 'etc',
      etc: 'etc',
      ets: 'ets',
      event: 'подія',
      events: 'події',
      'events by the system': 'події системи',
      'exchange fact': 'факт обмінного курсу',
      'exchange plan': 'план обмінного курсу',
      'exchange rate': 'обмінний курс',
      'exec forecast amount <> invoiced amount': 'очікувана сума <> сума виставлено інвойсу',
      'exec forecast amount <> payment amount': 'очікувана сума <> сума платежу',
      executed: 'виконано',
      execution: 'виконання',
      'execution amount': 'сума виконання',
      'execution month': 'місяць виконання',
      'execution period': 'термін виконання',
      'execution price': 'ціна виконання',
      'existing vessel': 'існуючий корабель',
      expense: 'загальні витрати',
      export: 'експорт',
      'export / import data': 'експорт / імпорт даних',
      'export contract': 'експортний контракт',
      'export contracts': 'експортні контракти',
      exporter: 'експортер',
      'exporter contract': 'контракт експортера',
      exporters: 'експортери',
      fact: 'факт',
      'fact distributors': "факти дистриб'юторів",
      'fact suppliers/distributors': "факти постачальників / дистриб'юторів",
      fail: 'невдача',
      'fail or did not happen': 'не вдалося або не відбулося',
      failed: 'помилка',
      'failure reason': 'причина невдачі',
      farm: 'господарство',
      'farm (with consignment)': 'господарство (за накладною)',
      farms: 'господарства',
      'few logistics': 'декілька логістик',
      fields: 'поля',
      'file name': 'назва файлу',
      'fill by template': 'заповнити за шаблоном',
      filter: 'фільтр',
      'filter invoices to send': 'фільтрувати інвойси для відправлення',
      'filter transport to send': 'транспорт фільтру для відправки',
      filters: 'фільтри',
      'fin purposes': 'фінансова ціль',
      final: 'фінальний',
      'final amount': 'фінальна сума',
      'final amount <> amount': 'фінальна сума <> сума',
      'final amount <> invoiced': 'фінальна сума <> виставленого інвойсу',
      'final buyer': 'кінцевий покупець',
      'final confirm': 'фінальне підтвердження',
      'final volume': "фінальний об'єм",
      'final volume of the contract': "фінальний об'єм контракту",
      'final volume of this contract': "фінальний об'єм цього контракту",
      'final volume of this request': "фінальний об'єм цього тікету",
      'finance type': 'тип фінансування',
      finance_account_title: 'назва фінансового рахунку',
      'financing - estimated opposite payment date':
        'фінансування - прогнозна дата зустрічна оплати',
      'financing - interest rate': 'фінансування - процентна ставка',
      'financing - loan percentage': 'фінансування - відсоток кредиту',
      'financing - payment days': 'фінансування - дні оплат',
      'first confirmation date - the date when you first confirmed the legal documents of this company':
        'перша дата підтвердження - дата, коли ви вперше підтвердили юридичні документи цієї компанії',
      'first contact = the date, when this company was added to the system':
        'перший контакт = дата, коли ця компанія була додана в систему',
      'first name': "ім'я",
      'first_contact was automatically generated': 'перший контакт був автоматично згенерований',
      fixing: 'фіксинг',
      flag: 'прапор',
      'folder number': 'номер папки',
      for: 'для',
      'for limits': 'для лімітів',
      forwarder: 'експедитор',
      'forwarder contracts': 'контракти з експедиторами',
      forwarders: 'експедитори',
      found: 'знайдено',
      free: 'вільний',
      freight: 'фрахт',
      'freight costs': 'фрахт витрати',
      'freight opposite': 'зустрічний фрахт',
      from: 'від',
      'from company': 'від компанії',
      'from each ticket of chosen passport we will create a contract and attach it\n                        to the passport automatically':
        'з кожного тікета вибраного паспорта ми створимо контракт і додамо його\n                        в паспорт автоматично',
      'full diff': 'повна різниця',
      'full invoiced': 'повністю проінвойсовано',
      'full paid': 'повністю сплачено',
      'fully connected to passports': "повністю з'єднаний з паспортами",
      'future contact': 'наступний контакт',
      'future contact = the date, when you need to contact this company later (can be set while editing company':
        "наступний контакт - дата, коли потрібно зв'язатися з цією компанією повторно (можна вказати під час редагування компанії)",
      'futures position': "ф'ючерсна позиція",
      gains: 'доходи',
      'general agreement': 'рамкова угода',
      'general agreements': 'рамкова угода',
      'general expense': 'загальні витрати',
      'general expenses': 'загальні витрати',
      generalexpenses: 'загальні витрати',
      'give the name to your collection to find it on the Download tab':
        'дайте назву конфігурації, щоб знайти її на вкладці «Завантаження»',
      'go to details': 'перейти до деталей',
      'go to the page of the item': 'перейдіть на сторінку елемента',
      'group by': 'групувати за',
      grouping: 'групування',
      groups: 'групи',
      guarantor: 'гарант',
      h: 'год',
      ha: 'га',
      'has logistics': 'має логістику',
      'has negative balance': 'має негативний баланс',
      hedging: 'хеджування',
      'here are shown all documents that were uploaded in counterparties, contracts,\n                    invoices, passports':
        'тут показані всі документи, які були завантажені в контрагентів, контракти, \n                    інвойси, паспорти',
      'here are shown all suppliers, buyers, brokers, farms, and others, already grouped\n                by their roles':
        'тут показані всі постачальники, покупці, брокери, господарства та інші, уже згруповані за їхніми ролями',
      'hidden from freight': 'прихований від фрахту',
      hide: 'сховати',
      'hide executed': 'сховати виконане',
      high: 'високий',
      history: 'історія',
      hours: 'годин',
      'http://website.com': 'http://website.com',
      iban: 'iban',
      id: 'id',
      'if there is more than one notify party': 'якщо є більше однієї повідомляючої сторони',
      'if there is more than two notify party': 'якщо є більше двох повідомляючих сторін',
      'if you want to see more info about certain user press on his name':
        "якщо ви хочете побачити більше інформації про певного користувача натисніть на його ім'я",
      'if you want to start from something, try the following actions':
        'якщо ви хочете з чогось почати, спробуйте наступні дії',
      imo: 'imo',
      'implied freight level': 'прогнозована вартість фрахту',
      import: 'імпорт',
      'import data': 'імпорт даних',
      'import google drive': 'імпортувати з Google Drive',
      'inactive<translate></translate>': 'неактивно<translate></translate>',
      'inc inv qty': 'inc inv qty',
      incoming: 'вхідний',
      'incoming balance': 'вхідний баланс',
      incoterms: 'базис',
      indicator: 'індикатор',
      'individual entrepreneur': 'фізична особа-підприємець',
      info: 'інфо',
      initial: 'початковий',
      'initial cash amount': 'початкова сума',
      'initial cash amount, $': 'початкова сума готівки, $',
      'initial date': 'початкова дата',
      'initialization date': 'дата ініціалізації',
      initiator: 'ініціатор',
      'inline edit': 'внесення змін на місці',
      insured: 'застрахований',
      insurer: 'страховик',
      intake: 'завантаження',
      intermediate: 'проміжний',
      'intermediate charges': 'проміжні витрати',
      'intermediate costs': 'проміжні витрати',
      'intermediate logistic': 'проміжна логістика',
      'intermediate logistics delivery costs': 'витрати на доставку проміжної логістики',
      'internal purchases': 'внутрішня закупівля',
      'internal volume': "внутрішній об'єм",
      invoice: 'інвойс',
      'invoice (R/W)': 'інвойс (залізниця)',
      'invoice (auto)': 'інвойс (авто)',
      'invoice bank account': 'банківський рахунок інвойсу',
      'invoice client from': 'виставити інвойс контрагенту від',
      'invoice client to': 'виставити інвойс контрагенту до',
      'invoice from us': 'інвойс від нас',
      'invoice number': 'номер інвойсу',
      'invoice position': 'інвойс позиція',
      'invoice price': 'ціна інвойсу',
      'invoice to us': 'інвойс для нас',
      'invoice type': 'тип інвойсу',
      invoiced: 'виставлений інвойс',
      'invoiced <> amount': 'виставлено інвойс <> сума',
      'invoiced <> paid': 'виставлено інвойс <> сплачено',
      'invoiced cancelled': 'виставлення інвойсу скасовано',
      'invoiced logistics': 'логістика за інвойсом',
      invoices: 'інвойси',
      'is active': 'активний',
      'is gain': 'це дохід',
      'is sap registered': 'sap зареєстровано',
      'is staff': 'співробітник',
      'is superuser': 'суперкористувач',
      'is tax return registered': 'зареєстровано повернення податків',
      'issuance fee': 'комісія за видачу',
      km: 'км',
      language: 'мова',
      'last 10 days': 'останні 10 днів',
      'last 30 days': 'останні 30 днів',
      'last 7 days': 'останні 7 днів',
      'last approve time': 'час останнього підтвердження',
      'last approver': 'останній підтверджувач',
      'last login': 'останній вхід',
      'last month': 'останній місяць',
      'last name': 'прізвище',
      'last operation amount': 'сума останньої операції',
      'last operation date': 'остання дата операції',
      'last operation type': 'останні тип операції',
      'last update': 'останнє оновлення',
      'last updated': 'останнє оновлення',
      'last week': 'минулий тиждень',
      'legal entity': 'юридична особа',
      lenders: 'кредитори',
      'link file to a logistics': "зв'язок файлу з логістикою",
      list: 'список',
      load: 'завантаження',
      'load into a container': 'завантажити в контейнер',
      'load rate': 'норма завантаження',
      loaded: 'завантажений',
      'loading 1st port': 'завантаження 1-го порту',
      'loading 2nd port': 'завантаження 2-го порту',
      'loading date': 'дата завантаження',
      'loading dem/dis': 'завантаження dem/dis',
      'loading option': 'опція завантаження',
      'loading port': 'порт завантаження',
      'loading window': 'завантаження вікна',
      loadport: 'порт завантаження',
      loans: 'позики',
      local: 'локальний',
      location: 'розподіл',
      logistic: 'логістика',
      'logistic type': 'тип логістики',
      'logistic(s) have been successfully linked to the sales contract':
        'логістика успішно була зв’язана з контрактом продажу',
      logistics: 'логістика',
      'logistics by terminal': 'логістика за терміналом',
      'logistics to invoice by terminal': 'інвойс логістики за терміналом',
      'logistics total value': 'загальна вартість логістики',
      'logistics with single purchase contract': 'логістика з єдиним контрактом закупівлі',
      long: 'довгий',
      'long net pnl mtm': 'довгий чистий pnl mtm',
      lost: 'втрачено',
      lots: 'лотів',
      low: 'низький',
      'loyalty program': 'програми лояльності',
      'loyalty program from contract': 'програма лояльності за контрактом',
      'loyalty program from disbursement bill of lading':
        'програма лояльності за видатковою накладною',
      mailings: 'розсилка',
      'main contact': 'основний контакт',
      'make document': 'створити документ',
      manager: 'менеджер',
      managers: 'менеджери',
      'manual enter': 'введення вручну',
      margin: 'маржа',
      'margin = value of sale contracts - value of purchase contracts  - all costs':
        'маржа = вартість продажних контрактів - вартість закупівельних контрактів - всі витрати',
      'margin = value of sale contracts - value of purchase contracts  - all costs + vat * return rate':
        'маржа = вартість продажних контрактів - вартість закупівельних контрактів - всі витрати + пдв * ставка повернення',
      'margin by month': 'маржа по місяцях',
      'margin selector': 'перемикач маржі',
      'margin with MTM': 'маржа з МТМ',
      'marine traffic': 'marine traffic',
      'mark as read': 'позначити як прочитане',
      'mark to market': 'оцінка ринку',
      'market zone': 'ринкова зона',
      'matched + open net pnl': 'збіг + відкритий чистий pnl',
      'matched contract average purchase price':
        'відповідає середньозваженій ціні покупки контракту',
      'matched contract average sale price': 'відповідає середньозваженій ціні покупки закупівлі',
      'matched contract comm fees': 'підібрані комісії за контрактом',
      'matched contract long': 'підібраний контракт довгий',
      'matched contract short': 'підібраний контракт короткий',
      'matched net pnl': 'підібраний чистий pnl',
      max: 'макс',
      measurement: 'вимірювання',
      medium: 'середній',
      message: 'повідомлення',
      min: 'мін',
      'min payment / not invoiced': 'мін оплата / не виставлено інвойс',
      'min/max': 'мін/макс',
      month: 'місяць',
      mt: 'т',
      mtm: 'mtm',
      'mtm costs': 'витрати mtm',
      'mtm dtd price': 'ціна mtm dtd',
      'mtm dtd value': 'вартість mtm dtd',
      'mtm freight': 'фрахт mtm',
      'mtm ltd price': 'ціна mtm ltd',
      'mtm ltd value': 'вартість mtm ltd',
      'mtm margin': 'маржа mtm',
      'mtm passport margin': 'маржа паспорту mtm',
      'mtm per 1mt': 'за 1 т mtm',
      'mtm price': 'ціна mtm',
      'mtm volume': "об'єм mtm",
      multicontract: 'мультиконтракт',
      'multicontract purchase': 'мультиконтракт закупівля',
      'multicontract sale': 'мультиконтракт продаж',
      multipassport: 'мультипаспорт',
      multirequest: 'мультитікет',
      mv: 'mv',
      name: 'назва',
      new: 'новий',
      'new - is just created mailing, but not sent. sent - is sent. progress - we\n                        are sending it just now. failed - is failed to be sent.':
        'новий - тільки що створена розсилка, але не відправлена. відправлено - це відправлено. прогрес - \n                        ми відправляємо його зараз. невдача - не вдалося відправити.',
      'new Cashflow': 'кеш-фло',
      'new consignment': 'нова накладна',
      'new template': 'новий шаблон',
      'next approver': 'наступний підтверджувач',
      'next approvers': 'наступні підтверджуючі',
      'no data': 'немає даних',
      'no elevator': 'немає елеватора',
      'no farm': 'немає господарства',
      'no forwarder': 'немає експедитора',
      'no port': 'немає порту',
      'no position': 'немає позиції',
      'no purchase contracts attached': 'не укладено закупівельних контрактів',
      'no purchase tickets attached': 'поки що відсутні закупівельні тікети',
      'no sale contracts attached': 'поки що відсутні продажні контракти',
      'no sale tickets attached': 'поки що відсутні продажні тікети',
      'no station dep': 'немає станцій відправлення',
      'no station rec': 'немає станцій отримання',
      'no status': 'без статусу',
      'no vessel': 'без корабля',
      'no volume': "без об'єму",
      'not allocated': "не з'єднано",
      'not approved': 'не підтверджено',
      'not done': 'не виконано',
      'not insured': 'не застраховано',
      'not invoiced': 'не виставлено інвойс',
      'not signed': 'не підписано',
      note: 'примітка',
      notes: 'примітки',
      notice: 'повідомлення',
      number: 'номер',
      object: "об'єкт",
      'object title': 'назва об’ єкту',
      'object type': "тип об'єкту",
      'of counterparties': 'контрагентів',
      'of the': 'від',
      offset: 'залік',
      on: 'про',
      'on a page': 'на сторінці',
      'on behalf of': 'від імені',
      'on road': 'в дорозі',
      one: 'один',
      'one counterparty can have several roles, e.g, if you have sales and purchases with\n                one company, you should create one company and assign it with both roles (supplier\n                and buyer)':
        'один контрагент може мати кілька ролей, наприклад, якщо у вас є продаж і закупівля з однією компанією, \n                ви повинні створити одну компанію і призначити їй обидві ролі \n                (постачальник і покупець)',
      open: 'відкритий',
      'open balance': 'відкритий баланс',
      'open contracts': 'відкриті контракти',
      'open position': 'відкрита позиція',
      'open the modal window for editing of the item':
        'відкрити модальне вікно для редагування елемента',
      'open volume': "відкритий об'єм",
      'opened positions': 'відкритих позицій',
      operation: 'операція',
      opposite: 'протилежний',
      'opposite basis costs': 'зустрічний базис витрат',
      'opposite direct costs': 'протилежні прямі витрати',
      'opposite freight level': 'зустрічний фрахт',
      'opposite matched average purchase price': 'зустрічно підібрана середня ціна закупівлі',
      'opposite matched average sale price': 'зустрічно підібрана середня ціна продажу',
      'opposite matched comm fees': 'зустрічно підібрані комісії',
      'opposite matched long': 'протилежно підібрано довгий',
      'opposite matched short': 'протилежно підібрано короткий',
      'opposite passport contract costs': 'зустрічні витрати контракта паспорта',
      'opposite price': 'зустрічна ціна',
      'opposite total value costs': 'зустрічна загальна сума витрат',
      'opposite value': 'зустрічна сума',
      'opposite volume': 'зустрічний обʼєм',
      option: 'опція',
      options: 'опції',
      or: 'або',
      'or select manually': 'або вибрати вручну',
      'or you can': 'або ви можете',
      ordering: 'впорядкування',
      origin: 'походження',
      'origin country': 'країна походження',
      'origin district': 'район походження',
      'origin of crop': 'походження культури',
      'origin region': 'регіон походження',
      original: 'оригінал',
      'original available': 'оригінал доступний',
      origination: 'походження',
      'other activites': 'інше призначення',
      'other activities': 'інше призначення',
      otheractivity: 'інше призначення',
      'our amount': 'наша сума',
      'our amount <> amount': 'наша сума <> сума',
      'out inv qty': 'out inv qty',
      outgoing: 'вихідний',
      'outgoing balance': 'вихідний баланс',
      owner: 'власник',
      'owner name': 'ім’я власника',
      owners: 'власники',
      paid: 'сплачено',
      'paid $': 'сплачено $',
      'paid (plan)': 'сплачено (план)',
      'paid <> amount': 'сплачено <> сума',
      'paid date': 'дата оплати',
      'paper trade': 'paper trade',
      parameters: 'параметри',
      'parent cargo': 'батьківська культура',
      'parse active quotes': 'парсинг активних котирувань',
      passport: 'паспорт',
      'passport MTM': 'паспорт MTM',
      'passport basis costs': 'витрати паспорта',
      'passport business date': 'бізнес дата паспорта',
      'passport business unit': 'бізнес-юніт паспорта',
      'passport charges': 'витрати паспорта',
      'passport commodity': 'товар паспорта',
      'passport contract costs': 'витрати контракту паспорта',
      'passport costs': 'витрати паспорта',
      'passport custom status': 'статус користувача паспорта',
      'passport number': 'номер паспорта',
      'passport season': 'сезон паспорта',
      'passport status': 'статус паспорта',
      'passport vessel': 'корабель паспорта',
      'passport voyage': 'рейс паспорта',
      passports: 'паспорти',
      'passports custom status': 'статуси користувача паспортів',
      payment: 'платіж',
      'payment bank account': 'банківський рахунок платежу',
      'payment commission': 'комісія за оплату',
      'payment condition': 'умова оплати',
      'payment condition option': 'опція умови оплати',
      'payment conditions': 'умови оплати',
      'payment confirm': 'оплата підтверджена',
      'payment date': 'дата оплати',
      'payment date (fact)': 'день оплати (факт)',
      'payment date (plan)': 'день оплати (план)',
      'payment days': 'дні оплати',
      'payment delay': 'відстрочка платежу',
      'payment info': 'інфо про оплату',
      'payment type': 'тип оплати',
      'payment type: {[{detail_row.payment_type}]}': 'тип оплати: {[{detail_row.payment_type}]}',
      payments: 'оплати',
      penalties: 'штрафи',
      pending: 'очікується',
      per: 'за',
      'per 1': 'за 1',
      'per 1 mt': 'за 1 т',
      'per 1t': 'за 1 т',
      'per lot': 'за лот',
      'per one': 'за одну',
      'per one deal': 'за одну угоду',
      'per sales contracts': 'за продажними контрактами',
      'per unit': 'за одиницю',
      'per vehicle': 'на один транспортний засіб',
      'percent conditions': 'відсоткові умови',
      percentage: 'відсоток',
      period: 'період',
      periodic: 'періодичний',
      permissions: 'повноваження',
      phone: 'телефон',
      'phone number': 'номер телефону',
      'physical person': 'фізична особа',
      picture: 'аватар',
      place: 'місце',
      'place of shipment / unloading': 'місце відвантаження / розвантаження',
      plan: 'план',
      'plan distributors': "план дистриб'юторів",
      plans: 'плани',
      port: 'порт',
      'port destination': 'порт призначення',
      'port destination country': 'країна порту призначення',
      'port loading': 'порт завантаження',
      'port loading country': 'порт країни завантаження',
      ports: 'порти',
      'ports opposite': 'порти зустрічні',
      position: 'позиція',
      'pre advice': 'преадвайс',
      'prefix for outgoing invoices': 'префікс №  для вихідних інвойсів',
      premium: 'преміум',
      'premium price': 'преміум ціна',
      'prepay invoice, issued by you to Buyer': 'інвойс на передоплату, виставлений вами Покупцю',
      'prepay invoice, issued to you by Supplier':
        'інвойс передплати, виставлений вам Постачальником',
      prepayment: 'передоплата',
      'press this icon to edit update': 'натисніть цей значок, щоб редагувати оновлення',
      preview: 'попередній перегляд',
      price: 'ціна',
      'price for current vehicle differs to contract':
        'ціна на поточний транспортний засіб відрізняється від контрактної',
      'price from transshpment from cars': 'ціна від перевалки з авто',
      'price includes VAT': 'ціна включає ПДВ',
      'price of current deal + its cost + your yield (set in settings) = the sale price by which this deal should be sold to satisfy the requirements of your yield':
        'ціна поточної угоди + її вартість + Ваша рентабельність (встановлюється в параметрах) = ціна продажу, за якою ця угода повинна бути продана, щоб задовольнити вимоги рентабельності',
      'price of current deal + its costs = the real costs of the deal':
        'ціна поточної угоди + її витрати = реальна вартість угоди',
      'price of current deal - its cost - your yield (set in settings) = the purchase price by which this deal should be purchased to satisfy the requirements of your yield':
        'ціна поточної угоди - її вартість - Ваша рентабельність (встановлюється в параметрах) = закупівельна ціна, за якою ця угода повинна бути придбана ,щоб задовольнити вимоги рентабельності',
      'price of current deal - its costs = the real income of  the deal':
        'ціна поточної угоди - її витрати = реальний дохід від угоди',
      print: 'друкувати',
      process: 'процес',
      product: 'товар',
      'production year': 'рік виробництва',
      'profit and loss': 'прибуток і збитки',
      progress: 'прогрес',
      property: 'показник',
      'protein * volume': "протеїн*об'єм",
      purchase: 'закупівля',
      'purchase Contr. No.': 'закупівельний контракт №.',
      'purchase Contr. Volume, UAH': 'сума закупівельного контракту, UAH з ПДВ',
      'purchase Contr. Volume, mt': "об'єм (т) закупівельного контракту",
      'purchase Contract Date': 'дата закупівельного контракту',
      'purchase basis': 'базис закупівлі',
      'purchase contract': 'закупівельний контракт',
      'purchase contract author': 'автор закупівельного контракту',
      'purchase contract basis': 'базис закупівельного контракту',
      'purchase contract buyer': 'покупець закупівельного контракту',
      'purchase contract custom status': 'спеціальний статус закупівельного контракту',
      'purchase contract number': 'номер закупівельного контракту',
      'purchase contract options': 'закупівельний контракт опції',
      'purchase contract payment condition option': 'опція умови оплати закупівельного контракту',
      'purchase contract responsible': 'закупівельний контракт відповідальний',
      'purchase contract responsibles': 'закупівельний контракт відповідальні',
      'purchase contract responsibles for signing':
        'відповідальний за підписання закупівельного контракту',
      'purchase contract supplier': 'постачальник закупівельного контракту',
      'purchase contract suppliers': 'постачальники закупівельного контракту',
      'purchase contract suppliers/distributors':
        "закупівельний контракт постачальників/дистриб'юторів",
      'purchase contracts': 'закупівельні контракти',
      'purchase contracts logistics buyer invoices':
        'інвойс покупця логістики закупівельного контракту',
      'purchase destinations': 'місце призначення закупівлі',
      'purchase invoice prepay': 'передоплата за інвойсом на закупівлю',
      'purchase origins': 'закупівля походження',
      'purchase sap order': 'закупівля sap номери замовлень',
      'purchase supplier': 'постачальник закупівель',
      'purchase suppliers': 'постачальники закупівель',
      'purchase ticket': 'закупівельний тікет',
      'purchases & sales': 'закупівля та продаж',
      purpose: 'призначення',
      'push this button after applying filters to find a logistics you want to\n                        send by email.':
        'натисніть цю кнопку після застосування фільтрів, щоб знайти логыстику, яку ви бажаєте\n                         відправити email.',
      qty: "об'єм",
      quality: 'якість',
      'quality (date)': 'якість (дата)',
      'quality enterance': 'якість внесення',
      quantity: 'кількість',
      'quantity of containers': "об'єм контейнерів",
      'quantity of measurement': "об'єм вимірювання",
      'quantity total amount': "загального сума об'єму",
      'quarantine (date)': 'карантин (дата)',
      'railway (date)': 'залізниця (дата)',
      rate: 'ставка',
      read: 'прочитано',
      ready: 'готово',
      real: 'реальний',
      'real capacity': 'реальна місткість\n\nРеальний потенціал',
      'realized value': 'реалізована вартість',
      reassignment: 'переуступка',
      receipt: 'отримання',
      received: 'отримано',
      'received date - the date when you received the legal documents of this company':
        'дата отримання - дата, коли Ви отримали юридичні документи по компанії',
      'received volume': "отриманий об'єм",
      receiver: 'одержувач',
      'receiver code': 'код отримувача',
      receivers: 'одержувачі',
      'receiving date': 'дата отримання',
      'receiving stations': 'станція отримання',
      refresh: 'оновити',
      'refresh totals': 'оновити підсумкові дані',
      region: 'регіон',
      'regions, where this counterparty operate mostly':
        'регіони, де переважно працює даний контрагент',
      'related invoice': 'пов’язаний інвойс',
      'related to': "пов'язані з",
      relations: 'відносини',
      'rename file': 'перейменувати файл',
      report: 'звіт',
      'report column type': 'тип стовпця звіту',
      'request date': 'дата заявка',
      requested: 'запрошені',
      'requested date - the date when you requested the legal documents of this company':
        'дата запиту - дата, коли ви запросили юридичні документи по компанії',
      required: "обов'язкові",
      responsibilities: 'відповідальность',
      responsibility: 'відповідальність',
      responsible: 'відповідальний',
      'responsible confirmation': 'відповідальне підтвердження',
      'responsible for compliance': 'відповідальний за комплаєнс',
      'responsible for confirm': 'відповідальний за підтвердження',
      'responsible for signing': 'відповідальний за підпис',
      'responsible signing': 'відповідальний за підпис',
      responsible_for_signing: 'відповідальний за підпис',
      responsibles: 'відповідальні',
      'responsibles for signing': 'відповідальні за підписання',
      rests: 'залишки',
      'rests groupping': 'групування залишків',
      return: 'повернення',
      risk: 'ризик',
      road: 'в дорозі',
      'road volume': "об'єм в дорозі",
      role: 'роль',
      roles: 'ролі',
      rolling: 'ролінг',
      s: 's',
      sailed: 'відчалив',
      sale: 'продаж',
      'sale Contr. No.': 'продажний контракт №.',
      'sale Plan': 'план продажу',
      'sale and purchases of': 'продажу та закупівлі',
      'sale buyer': 'покупець продажу',
      'sale buyers': 'покупці продажу',
      'sale commodity': 'товар продажу',
      'sale contract': 'продажний контракт',
      'sale contract basis': 'базис продажного контракту',
      'sale contract buyer': 'покупець продажного контракту',
      'sale contract commodity': 'товар продажного контракту',
      'sale contract number': 'номер продажного контракту',
      'sale contract supplier': 'постачальник продажного контракту',
      'sale contracts': 'продажні контракти',
      'sale destinations': 'пункт призначення продажу',
      'sale invoice prepay': 'передоплата за інвойсом на продаж',
      'sale origins': 'походження продажу',
      'sale owner': 'власник продажу',
      'sale price multiplicator': 'мультиплікатор ціни продажу',
      'sale sap order': 'продаж sap замовлення',
      'sale ticket': 'продажний тікет',
      'sales manager': 'регіональний представник',
      'sales manager head': 'керівник відділу продажів',
      'sap order': 'sap номер замовлення',
      'sap registered': 'sap зареєстровано',
      save: 'зберегти',
      'save a transfer': 'зберегти перевалку',
      'save and continue': 'зберегти та продовжити',
      'save and continue editing': 'зберегти та продовжити редагування',
      'save changes': 'зберегти зміни',
      'save collection': 'зберегти конфігурацію',
      'save report congif': 'зберегти конфігурацію звіту',
      season: 'сезон',
      seasons: 'сезони',
      seeds: 'посіви',
      'seeds amount': 'посіви сума',
      'select a season for a position': 'вибрати сезон для позиції',
      'select and rename any of the fields you want to save for an Excel file\n                donwload':
        'виберіть і перейменувайте будь-які поля, які ви хочете зберегти для файлу Excel \n                завантаження',
      'select current collection for editing or select New collection to create':
        'виберіть поточну конфігурацію для редагування або виберіть Нову конфігурацію для створення',
      'select emails you want to send':
        'виберіть emails , які хочете відправити, які хочете відправити',
      'select one from templates for sending': 'вибрати один з шаблонів для відправки',
      'select one or multiple to display a position':
        'виберіть одну або декілька позицій для відображення',
      'select single option to display child commodities':
        'виберіть один варіант для відображення дочірніх товарів',
      'self-cost': 'собівартість',
      'self-cost per 1t': 'собівартість за 1т',
      selfcost: 'собівартість',
      'send a report': 'відправити звіт',
      senders: 'відправники',
      sent: 'відправлено',
      service: 'послуга',
      set: 'встановити',
      'set a prices to evaluate a position': 'встановити ціни для оцінки позиції',
      shipment: 'відвантаження',
      'shipment elevators': 'відвантаження елеваторів',
      'shipment farms': 'відвантаження господарства',
      'shipment period': 'період виконання',
      'shipment period of the contract': 'період відвантаження контракту',
      'shipment period of the request': 'період відвантаження тікета',
      'shipment status': 'статус відвантаження',
      'shipment terminal': 'термінал відвантаження',
      'shipment terminals': 'термінали відвантаження',
      shipped: 'відвантажено',
      'shipped by logistics': 'відвантажено логістикою',
      short: 'шорт',
      'short net pnl mtm': 'короткий чистий pnl mtm',
      'short/long': 'короткий/довгий',
      shortage: 'шортадж',
      'shortage fee': 'shortage витрати',
      'shortage quantity': "shortage об'єм",
      'shortage rate': 'shortage ставка',
      'show all stock': 'показати всі залишки',
      'show expired': 'показати неактуальні',
      'show expired tariffs': 'показати неактуальні тарифи',
      'side from': 'сторона від',
      'side to': 'сторона кому',
      sides: 'сторони',
      signature: 'підпис',
      signed: 'підписаний',
      simple: 'проста',
      'single document size up to 1 mb': 'розмір одного документа до 1 мб',
      'single document size up to 50 mb': 'розмір одного документа до 50 мб',
      'single file size up to 50 mb (depends on your SMTP provider)':
        'розмір одного файлу до 50 мб (залежить від постачальника SMTP)',
      source: 'джерело',
      start: 'початок',
      'start balance': 'початковий баланс',
      'start date': 'дата початку',
      'start of execution': 'початок виконання',
      station: 'станція',
      'station arrival': 'станція отримання',
      'station departing': 'станція відправлення',
      'station departure': 'станція відправлення',
      'station deps': 'станція відправлення',
      'station receiving': 'станція отримання',
      'station recs': 'станція отримання',
      status: 'статус',
      'stowage factor': 'коефіцієнт укладання',
      submit: 'підтвердити',
      'submit documents': 'підтвердити документи',
      subtype: 'підтип',
      subuse: 'підвикористання',
      subuses: 'підвикористання',
      success: 'успішно',
      superuser: 'суперкористувача',
      supplier: 'постачальник',
      'supplier balance invoices': 'інвойс балансу постачальників',
      'supplier contract': 'контракт постачальника',
      'supplier contract basis': 'базис контракту постачальника',
      'supplier contracts': 'контракти постачальників',
      'supplier invoiced': 'інвойс постачальника',
      'supplier invoiced balance': 'інвойс балансу постачальника',
      'supplier managers': 'менеджери постачальників',
      'supplier paid': 'постачальник оплатив',
      'supplier paid balance': 'сплачений постачальником залишок',
      'supplier prepay invoices': 'інвойси постачальника на передоплату',
      'supplier un invoiced': 'постачальник не виставив інвойс',
      'supplier un invoiced balance': 'постачальник не виставив інвойс балансу',
      suppliers: 'постачальники',
      'suppliers of purchase contracts logistics buyer invoices':
        'виберіть постачальників закупівельних контрактів',
      'suppliers/distributors': "постачальники/дистриб'ютори",
      surveyor: 'сюрвеєр',
      swift: 'swift',
      t: 'т',
      't/day': 'т/ добу',
      't/h': 'т/год',
      table: 'таблиця',
      tasks: 'завдання',
      'tax return registered': 'повернення податків зареєстровано',
      telegram: 'telegram',
      'telegram username': 'нікнейм Telegram',
      template: 'шаблон',
      terminal: 'термінал',
      'terminal (with consignment)': 'термінал (за накладною)',
      'terminal volume': "об'єм терміналу",
      terminals: 'термінали',
      'the actual tolerance range of the contract': 'фактичний толеранс контракту',
      'the full title of the legal entity (for usage in documents)':
        'повна назва юридичної особи (для використання в документах)',
      'the legal address of the legal entity': 'юридична адреса контрагента',
      'the list of quality for this deal': 'перелік якості для цієї угоди',
      'the list of users, who approved this deal': 'список користувачів, які підтвердили цю угоду',
      'the number of contracts that involve this company. Click on the counter and you will see them all':
        'кількість контрактів, що стосуються цієї компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of documents attached to this company. Click on the counter and you will see them all':
        'кількість документів, що додаються до цієї компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of invoices that involve this company. Click on the counter and you will see them all':
        'кількість інвойсів, що стосуються цієї компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of issued invoiced by this contract. To know more - go to contract details':
        'кількість виставлених інвойсів, які були оформлені за цим контрактом. Щоб дізнатися більше - перейдіть до деталей контракту',
      'the number of logistics that involve this company':
        'кількість логістики, що включає ця компанія',
      'the number of persons added by this company. Click on the counter and you will see them all':
        'кількість контактів, додана по цій компанії. Натисніть на лічильник, щоб переглянути все',
      'the number of updates and the last one added by this company. Click on the counter and you will see them all':
        'кількість оновлень і останнє, що додається цією компанією. Натисніть на лічильник, щоб переглянути все',
      'the open balance of the contract': 'відкритий баланс контракту',
      'the person who will be appointed in documents': 'особа, яка буде призначена в документах',
      'the roles in which this legal entity will be used in the system. It could be multiple roles, e.g. Buyer and Seller':
        'ролі, які буде використовувати в системі контрагент. Може бути кілька ролей (наприклад, Покупець і Продавець)',
      'the short title of the role (for internal use)':
        'коротка назва ролі (для внутрішнього використання)',
      'the status is changed by setting dates of confirmations of counterparty':
        'зміна статусу шляхом встановлення дати підтвердження контрагента',
      'the value of the deal (except charges)': 'сума угоди (за винятком витрат)',
      'the volume, attached to this passport': "об'єм, прикріплений до цього паспорта",
      'the volume, connected to passports': "об'єм, пов'язаний з паспортами",
      'the volume, delivered to port': "об'єм, доставлений до порту",
      'the volume, departed to port': "об'єм, відправлений до порту",
      'the volume, for transport from open balance calculation':
        "об'єм транспорту з розрахунку відкритого балансу",
      'the volume, from transport by basis': "об'єм логістики по базису",
      'the volume,that is currently on the road': "об'єм, який зараз у дорозі",
      'this button will send all chosen emails':
        'за допомогою цієї кнопки можна відправити всі вибрані листи електронною поштою',
      'this counterparty can confirm other counterparties. You can see it in the header of the page COUNTERPARTIES - > CONFIRMATIONS':
        'цей контрагент може підтвердити інших контрагентів',
      'this field is calculated automatically': 'це поле обчислюється автоматично',
      'this margin is chosen for P&L report by margin selector':
        'ця маржа вибирається для звіту PnL за допомогою перемикача маржі',
      'this month': 'цього місяця',
      'this page was designed the main settings of the company. Only admin can do\n              this':
        'на цій сторінці були розроблені основні налаштування компанії. Тільки адміністратор має \n              доступ',
      'this page was designed to manage master data of the system. Only admin can do\n                this':
        'ця сторінка була розроблена для управління основними даними системи. Тільки адміністратор має \n              доступ',
      'this week': 'цього тижня',
      ticket: 'тікет',
      tickets: 'тікети',
      time: 'час',
      'time, date and user, who created this deal':
        'час, дата та користувач, який створив цю угоду',
      title: 'назва',
      to: 'до',
      'to be invoiced': 'буде проінвестовано',
      'to company': 'до компанії',
      'to deliver and sale at': 'доставки та продажі в',
      'to multicontracts': 'до мультиконтрактів',
      'to open actions menu on certain item, press on its name':
        'щоб відкрити меню дій над певним елементом, натисніть на його назву',
      'to open document press on an icon near the title':
        'щоб відкрити документ, натисніть на значок біля заголовка',
      'to pay': 'сплатити',
      tolerance: 'толеранс',
      tomorrow: 'завтра',
      tones: 'тони',
      tonnes: 'тонн',
      topic: 'тема',
      total: 'всього',
      'total approve time, h': 'загальний час підтвердження, годин',
      'total charges': 'всього витрат',
      'total days of payments delay': 'кількість днів затримки платежів',
      'total value': 'загальна сума',
      'total value discounted': 'загальна сума з дисконтом',
      'total volume': "загальний об'єм",
      transaction: 'транзакція',
      'transaction date': 'дата транзакції',
      transit: 'транзит',
      transport: 'транспорт',
      'transport open balance volume': 'обсяг відкритого балансу транспорту',
      tt: 'акт',
      turnover: 'оборот',
      'turnover groupping': 'групування обороту',
      type: 'тип',
      types: 'типи',
      'under each passport you will see the one costs, split on two 500 and 500.':
        'під кожним паспортом ви побачите одну вартість, розділену на дві частини 500 і 500.',
      unexecuted: 'невиконано',
      units: 'юніти',
      'unloading elevators': 'елеватори розвантаження',
      'unloading farms': 'господарства розвантаження',
      'unloading terminals': 'термінали розвантаження',
      'until execution starts': 'до початку виконання',
      'up to 30 days': 'до 30 днів',
      update: 'оновлено',
      updated: 'оновлено',
      updates: 'оновлення',
      'updates and events': 'оновлення та події',
      'usd price': 'ціна usd',
      use: 'використання',
      'use a checkbox to completely hide/show field from the modal or use the button\n                  Users to customize the visibility for particular person':
        'скористайтеся прапорцем, щоб повністю приховати/показати поле від модального вікна або \n                  скористайтеся кнопкою Користувачі, щоб налаштувати права доступу для конкретної особи',
      'use a checkbox to completely hide/show field from the table or use the\n                        button Users to customize the visibility for particular person':
        'скористайтеся прапорцем, щоб повністю приховати/показати поле з таблиці, або \n                        скористайтеся кнопкою Користувачі, щоб налаштувати права доступу для конкретної особи',
      'use a filter button on the right top corner of the page to filter the\n                        list':
        'використовуйте кнопку фільтра у правому верхньому куті сторінки, щоб відфільтрувати \n                        список',
      'use a update button on the right top corner of the page to get the latest\n                        data without refreshing the page':
        'скористайтеся кнопкою оновлення у правому верхньому куті сторінки, щоб отримати \n                        найновіші дані без оновлення сторінки',
      'use convertation': 'використовуйте конвертацію',
      'use favorite filters button above the table. You can save sets of different\n                        filters and use them in a single click. This can be managed in the filters\n                        sidebar':
        'використовуйте кнопку улюблених фільтрів над таблицею. Ви можете зберігати нові набори\n                         різних фільтрів і використовувати їх одним натисканням. Налаштовувати можна\n                         за допомогою бічної панелі фільтрів',
      'use unload costs': 'використати витрати на розвантаження',
      user: 'користувача',
      'user created': 'користувача створено',
      'username / login': 'нікнейм / логін',
      'users approved this counterparty': 'користувачі підтвердили контрагента',
      uses: 'використання',
      validated: 'перевірений',
      value: 'значення',
      vat: 'пдв',
      'vat = VAT CARGO SALE - (VAT CARGO PURCHASE + VAT CHARGES SALE + VAT CHARGES PURCHASE )':
        'пдв = ПДВ вантаж продажу - (ПДВ вантаж закупівлі + ПДВ витрати продажу + ПДВ витрати закупівлі)',
      'vat return': 'повернення ПДВ',
      'vat return rate': 'ставка повернення ПДВ',
      'vehicle number': 'номер транспортного засобу',
      vehicles: 'транспортні засоби',
      vessel: 'корабель',
      'vessel owner': 'власник корабля',
      'vessel size': 'розмір судна',
      'vet (date)': 'vet (дата)',
      virtual: 'віртуальний',
      volume: "об'єм",
      'volume / free': "об'єм / вільний",
      'volume departed': "об'єм відправлений",
      'volume departed consignment': 'об’єм (за ТТН)',
      'volume discharged': "об'єм розвантажений",
      'volume intake': "об'єм завантаження",
      'volume loaded': "об'єм завантажений",
      'volume lost': "об'єм втрачений",
      'volume max': "об'єм максимальний",
      'volume min': "об'єм мінімальний",
      'volume received': "об'єм отриманий",
      volume_freighted: "об'єм фрахт",
      'volumes by month': 'кількість по місяцям',
      vote: 'голосувати',
      voyage: 'рейс',
      'voyage business unit': 'бізнес юніт рейсу',
      voyages: 'рейси',
      'w/o passport': 'без паспорта',
      'was made from this indicator': 'зроблено з індикатора',
      'was made from this ticket': 'зроблено із тікета',
      washout: 'вошаут',
      website: 'веб-сайт',
      with: 'з',
      'with docs': 'з документами',
      "with sale contract's passports?": 'з паспортами продажних контрактів?',
      'with selected passport?': 'з обраним паспортом?',
      'without VAT': 'без ПДВ',
      'without invoice, offset, reassignment': 'без інвойсу, заліку, переуступки',
      wizard: 'wizard',
      'would require deleting the following protected related objects:':
        "необхідно видалити наступні захищені пов'язані об'єкти:",
      'write offs': 'списання',
      write_offs: 'списання',
      year: 'рік',
      years: 'років',
      yes: 'так',
      yesterday: 'вчора',
      'you can add new one': 'ви можете додати новий',
      'you can create a new vessel and attach it to this passport':
        "можна створити новий корабель і прив'язати його до цього паспорта",
      'you can download any kit of fields for Export, created on Collection tab':
        'ви можете завантажити будь-який набір полів для експорту, створений на вкладці конфігурація',
      'you can make your own configuration of export and download the table in a\n                        certain EXCEL format':
        'ви можете зробити власну конфігурацію експорту і завантажити таблицю \n                        в певному форматі EXCEL',
      'you can manage email or messenger notifications on system events':
        'ви можете керувати повідомленнями email або месенджера про системні події',
      'you can manage the columns and their ordering pressing setting icon above\n                        the table':
        'ви можете керувати стовпцями та їх упорядкуванням, натиснувши значок налаштування \n                        над таблицею',
      'you can manage your prescriptions and notifications subscriptions here':
        'ви можете керувати вашими підписками та повідомленнями тут',
      'you can navigate in system using panel on the top ☝, or at the bottom\n                👇':
        'навігацію в системі можна здійснювати за допомогою панелі вгорі☝ або внизуі\n                👇',
      'you can now click': 'тепер ви можете натиснути',
      'you can print any documents from predefined template':
        'ви можете надрукувати будь-які документи з попередньо визначеного шаблону',
      'you can start the whole approval process once again':
        'можна почати весь процес підтвердження ще раз',
      'you can switch different views of the table using buttons on the right top\n                        of the table':
        'ви можете перемикати різні види таблиці за допомогою кнопок у правій верхній частині\n                        таблиці',
      'you can validate the counterparty as per predefined approval configuration':
        'ви можете підтвердити контрагента за попередньо визначеною конфігурацією підтвердження',
      'you can validate the passport as per predefined approval configuration':
        'ви можете підтвердити паспорт за попередньо визначеною конфігурацією підтвердження',
      'you can write an email to a client with predefined templates':
        'ви можете написати email контрагенту з попередньо визначеними шаблонами',
      'you have limit on {[{ $root.user.settings.USERS_MAX_COUNT }]} active\n              users':
        'у Вас є ліміт - {[{ $root.user.settings.USERS_MAX_COUNT }]}\n              активних користувачів',
      '{[{ $ctrl.contract.approval_status }]}': '{[{ $ctrl.contract.approval_status }]}',
      '{[{ $ctrl.finance.approval_status }]}': '{[{ $ctrl.finance.approval_status }]}',
      '{[{ $ctrl.multicontract.contract_type }]}': '{[{ $ctrl.multicontract.contract_type }]}',
      '{[{ $ctrl.multirequest.contract_type }]}': '{[{ $ctrl.multirequest.contract_type }]}',
      '{[{ $ctrl.passport.approval_status }]}': '{[{ $ctrl.passport.approval_status }]}',
      '{[{ $ctrl.request.approval_status }]}': '{[{ $ctrl.request.approval_status }]}',
      '{[{ $root.gtUtils.getLocalCurrency() | translate }]}':
        '{[{ $root.gtUtils.getLocalCurrency() | translate }]}',
      "{[{ 'ISCC'|translate }]}": "{[{ 'ISCC'|translate }]}",
      '{[{ client.approval_status }]}': '{[{ client.approval_status }]}',
      '{[{ consignment.object_type }]}': '{[{ consignment.object_type }]}',
      '{[{ contract.approval_status }]}': '{[{ contract.approval_status }]}',
      '{[{ contract_price.port_name }]}': '{[{ contract_price.port_name }]}',
      "{[{ derivative.month | date:'MMM,yy' }]}": "{[{ derivative.month | date:'MMM,yy' }]}",
      '{[{ detail_row.finance_type }]}': '{[{ detail_row.finance_type }]}',
      '{[{ detail_row.report_column_type }]}': '{[{ detail_row.report_column_type }]}',
      '{[{ finance.finance_type }]}': '{[{ finance.finance_type }]}',
      '{[{ finance.report_column_type }]}': '{[{ finance.report_column_type }]}',
      '{[{ invoice.status }]})': '{[{ invoice.status }]})',
      '{[{ item.approval_status }]}': '{[{ item.approval_status }]}',
      '{[{ item.request_status }]}': '{[{ item.request_status }]}',
      '{[{ item.status }]}': '{[{ item.status }]}',
      '{[{ level.confirmation_logic | uppercase }]}':
        '{[{ level.confirmation_logic | uppercase }]}',
      "{[{ month['title'] }]}": "{[{ month['title'] }]}",
      '{[{ request.approval_status }]}': '{[{ request.approval_status }]}',
      '{[{ type.title }]}': '{[{ type.title }]}',
      '{[{ vm.agreement.approval_status }]}': '{[{ vm.agreement.approval_status }]}',
      '{[{ vm.logentry.change_message }]}': '{[{ vm.logentry.change_message }]}',
      '{[{ vm.mode }]}': '{[{ vm.mode }]}',
      '{[{ vm.passport.approval_status }]}': '{[{ vm.passport.approval_status }]}',
      '{[{ vm.title }]}': '{[{ vm.title }]}',
      '{[{$ctrl.certificateRepr[certificateType]}]}':
        '{[{$ctrl.certificateRepr[certificateType]}]}',
      '{[{certificateType}]}': '{[{certificateType}]}',
      "{[{contractInfo.crop_title || '---' }]}": "{[{contractInfo.crop_title || '---' }]}",
      '{[{contractInfo.open_balance_sum || 0 | number:\n                                        3}]}':
        '{[{contractInfo.open_balance_sum || 0 | number:\n                                        3}]}',
      '{[{contractInfo.volume_sum || 0 | number: 3 }]}':
        '{[{contractInfo.volume_sum || 0 | number: 3 }]}',
      '{[{group.title}]}': '{[{group.title}]}',
      '{[{item.use}]}': '{[{item.use}]}',
      '{[{row.client}]}': '{[{row.client}]}',
      '{[{row.currency}]}': '{[{row.currency}]}',
      '{{ $ctrl.errors.ext }}': '{{ $ctrl.errors.ext }}',
      '{{ $ctrl.errors.resourceObjId }}': 'ctrl.errors.resourceObjId }}',
      '{{ $ctrl.errors.responseResult }}': '{{ $ctrl.errors.responseResult }}',
      '{{ $ctrl.errors.templateText }}': '{{ $ctrl.errors.templateText }}',
      '{{ $ctrl.verboseName }}': '{{ $ctrl.verboseName }}',
      '{{ derivative.commodity }}': '{{ derivative.commodity }}',
      '{{ derivative.title }}': '{{ derivative.title }}',
      '{{ role.role_name }}': '{{ role.role_name }}',
      'Нas Debit/Credit Cargo': 'Є Дебет/Кредит по товару',
      'Нas Debit/Credit Contract': 'Є Дебет/Кредит по контракту',
      'Нas Debit/Credit Invoice': 'Є Дебет/Кредит по інвойсу',
      Сourier: "Кур'єр",
      сashback: 'кешбек',
      '✅Approve/❌Decline': '✅Підтвердити / ❌Відхилити',
      '💬 Add comment': '💬 Додати коментар',
      '🔗 Open in browser': '🔗 Відкрити у браузері',
    });
    gettextCatalog.setStrings('uk', {
      'Waiting for approval': 'Очікування підтвердження',
      Approved: 'Підтверджено',
      'Approval in process': 'Підтвердження в процесі',
      Rejected: 'Відхилено',
      'Approval Config': 'Конфігурація підтвердження',
      'Approval status': 'Статус підтвердження',
      Approvals: 'Затвердження',
      'Any of users': 'Будь-хто з користувачів',
      'All users': 'Всі користувачі',
      'Majority users': 'Більшість користувачів',
      Client: 'Клієнт',
      Request: 'Заявка',
      Indicator: 'Індикатор',
      Ticket: 'Тікет',
      'Purchase Contract': 'Закупівельний контракт',
      'Sale Contract': 'Продажний контракт',
      'Services contract': 'Контракт на послуги',
      'Intermediate contract': 'Проміжний контракт',
      'Export contract': 'Експортний контракт',
      'General Agreement': 'Договір',
      Contract: 'Контракт',
      Passport: 'Паспорт',
      Finance: 'Фінанси',
      'Payment Plan': 'План оплати',
      'Company group': 'Група компаній',
      'Object type': 'Тип обʼєкту',
      'Main approvers': 'Супер підтвердження',
      'Is active': 'Активний',
      'Approval Configs': 'Конфігурації підтвердження',
      'Index (ordering)': 'Індекс (впорядкування)',
      Approvers: 'Підтверджують',
      'Confirmation logic': 'Логіка підтвердження',
      'Is ready for vote condition': 'Готовий до голосування',
      'Need related objects approvals': 'Необхідне погодження пов’язаних об’єктів',
      'Without condition': 'Без умов',
      'Approval Config Level': 'Рівень підтвердження',
      'Approval Config Levels': 'Рівні підтвердження',
      Declined: 'Відхилено',
      Comment: 'Коментар',
      Archived: 'Архівовано',
      Approval: 'Підтверджено',
      'First name': 'Імʼя',
      'Middle name': 'По батькові',
      'Last name': 'Прізвище',
      Phone: 'Телефон',
      Email: 'Email',
      Position: 'Позиція',
      'Business Units': 'Бізнес-юніти',
      'Sale manager': 'Регіональні представники',
      'Sale managers': 'Регіональний представник',
      'Google Authentication required. Authentication...':
        'Потрібна аутентифікація Google. Аутентифікація…',
      Enter: 'Увійти',
      'The reset link is broken or already used':
        'Посилання для скидання пароля недійсне або вже використане',
      'In the case of any questions, please, contact our Support Team':
        'У разі виникнення будь-яких питань, будь ласка, звʼяжіться з нашою Службою підтримки',
      'Forgot password': 'Забули пароль',
      'Pressing the button I confirm, I have read and agree with':
        'Натискаючи кнопку, ПІДТВЕРДЖУЮ, я прочитав і згоден з',
      'terms of use and privacy policy': 'умови використання та політика конфіденційності',
      "You're receiving this email because you requested a password reset for your user account":
        'Вам надіслано цей лист, оскільки ви зробили запит на скидання пароля для вашого облікового запису користувача',
      'Please go to the following page and choose a new password:':
        'Будь ласка, перейдіть за посиланням нижче і створіть новий пароль:',
      'Your username, in case you’ve forgotten:':
        'Якщо ви забули свій логін, він буде наданий нижче:',
      'Thanks for using our site!': 'Дякуємо за використання нашого сайту!',
      "Password reset link sent to user's email":
        'Посилання для зміни пароля надіслано на електронну адресу користувача',
      "You're receiving this notification because you requested a password reset for your user.":
        'Вам надіслано цей лист, оскільки ви зробили запит на скидання пароля для вашого облікового запису користувача.',
      "This request doesn't exist anymore.": 'Цей запит більше не існує.',
      '{approval.user.get_full_name()}, voted this approval already.':
        '{approval.user.get_full_name()}, ви вже голосували за це.',
      '{user.get_full_name()}, you are not allowed to vote this approval.':
        '{user.get_full_name()}, вам не дозволено голосувати за це затвердження.',
      'Thank you, {user.get_full_name()}, you Approved the object.':
        'Дякую, {user.get_full_name()}, ви Погодили об’єкт.',
      'Thank you, {user.get_full_name()}, you Declined the object.':
        'Дякую, {user.get_full_name()}, ви Відхилили об’єкт.',
      'This field can not be changed after creation.': 'Це поле не можна змінити після створення.',
      'Invalid login and/or password': 'Невірний логін та/або пароль. Спробуйте знову',
      'You need sent three values (current, new, confirm)':
        'Потрібно надіслати три значення (поточне, нове, підтвердження)',
      'Wrong password.': 'Неправильний пароль.',
      'New password and confirm must be the same.':
        'Новий пароль і підтвердження мають співпадати.',
      'Password updated.': 'Пароль оновлено.',
      'You should approve this item': 'Ви повинні погодити цей елемент',
      'Approval requested.': 'Запит на погодження.',
      'Google Drive logged in successfully. Try again':
        'Успішний вхід в Google Drive. Спробуйте ще раз',
      'Enter new password': 'Введіть новий пароль',
      'Password reset unsuccessful': 'Спроба скидання пароля завершилася невдачею',
      'There are {} cities named {}. Please, add district to it.':
        'Є {} місто із назвою {}. Будь ласка, додайте район.',
      'There are no district {} for city {}.': 'Немає району {} для міста {}.',
      'Region matching query (title={}) does not exist':
        'Запит на відповідність регіону (title={}) не існує',
      'Not found company with this code': 'Компанію з таким кодом не знайдено',
      'set vkursi credentials': 'встановити облікові дані Vkursi',
      'bad YouControl key': 'недійсний ключ YouControl',
      'User for this person already exist.': 'Кабінет користувачу вже створено для цього контакту.',
      Supplier: 'Постачальник',
      Exporter: 'Експортер',
      Farm: 'Господарство',
      Elevator: 'Елеватор',
      Deliverer: 'Експедитор',
      Bank: 'Банк',
      Owner: 'Власник',
      Other: 'Інше',
      Surveyor: 'Сюрвеєр',
      Buyer: 'Покупець',
      Broker: 'Брокер',
      Insurer: 'Страховик',
      'Customs broker': 'Митний брокер',
      Hot: 'Гарячий',
      Warm: 'Теплий',
      Neutral: 'Нейтральний',
      Cold: 'Холодний',
      Angry: 'Злий',
      New: 'Новий',
      'Approved condition': 'Підтверджено з умовою',
      'Out of approval': 'Без схвалення',
      Pending: 'В очікуванні',
      'Re-approval': 'Повторне підтвердження',
      Big: 'Великий',
      Medium: 'Середній',
      Small: 'Маленький',
      Call: 'Дзвінок',
      Meet: 'Зустріч',
      Task: 'Завдання',
      Commentary: 'Коментар',
      Low: 'Низький',
      High: 'Високий',
      Critical: 'Критичний',
      Success: 'Успіх',
      Fail: 'Невдача',
      Progress: 'Прогрес',
      Cancelled: 'Скасовано',
      Upcoming: 'Майбутній',
      'Additional info': 'Додаткова інформація',
      'Client Document Type': 'Тип документа',
      'Days to update client status': 'Дні до оновлення статусу контрагента',
      'company group': 'група компаній',
      'Company groups': 'Групи компаній',
      Responsibility: 'Обовʼязок',
      responsibility: 'обовʼязок',
      Responsibilities: 'Відповідальність',
      'Django user': 'Користувач Django',
      'First Name': 'Імʼя',
      'Second Name': 'Прізвище',
      'Middle Name': 'По батькові',
      Companies: 'Компанії',
      'Phone additional': 'Додатковий телефон',
      Birthday: 'День народження',
      'Main contact': 'Основний контакт',
      Relation: 'Відношення',
      Verified: 'Перевірено',
      person: 'контакт',
      Persons: 'Контакти',
      'Can create GrainTrack user for person':
        'Можна створити користувача GrainTrack для співробітника',
      'Can verify person': 'Може перевірити особу',
      Name: 'Назва',
      'Related counterparties': 'Пов’язані контрагенти',
      'Custom status': 'Статус користувача',
      Abbreviation: 'Абревіатура',
      'Full name': 'Повна назва',
      'Full name Eng': 'Повна назва (англ.)',
      Code: 'Код',
      EIC: 'EIC',
      ITN: 'ІПН',
      'Tax info': 'Податкова інформація',
      Country: 'Країна',
      Address: 'Адреса',
      'Legal address': 'Юридична адреса',
      'Legal address eng': 'Юридична адреса (англ.)',
      Person: 'Співробітник',
      Signatory: 'Підписант',
      'Signatory Eng': 'Підписант (англ.)',
      'Signatory Docs': 'Підписант документів',
      'Signatory Eng Docs': 'Підписант документів (англ.)',
      'Phone (additional)': 'Телефон (додатковий)',
      Website: 'Веб-сайт',
      'Black list': 'Чорний список',
      'Company data is checked': 'Дані компанії перевірені',
      'Bank data is checked': 'Банківські дані перевірені',
      Documents: 'Документи',
      'First confirmation (date)': 'Перше підтвердження (дата)',
      'Term of proxy': 'Строк дії довіреності',
      'Days before deadline to notify': 'Днів до кінцевого терміну для повідомлення',
      'Confirmation (date)': 'Підтвердження (дата)',
      'Confirmation info': 'Інформація про підтвердження',
      'Request (date)': 'Заявка (дата)',
      'Received (date)': 'Отримано (дата)',
      'Incorporation (date)': 'Інкорпорація (дата)',
      Status: 'Статус',
      President: 'Президент',
      CEO: 'CEO',
      CFO: 'CFO',
      'Other executive': 'Інші виконавці',
      'Parent company': 'Материнська компанія',
      'Ultimate parent company': 'Кінцева материнська компанія',
      'Ultimate beneficiary owners': 'Кінцеві бенефіціарні власники',
      'Activities & traded products': 'Активність & товари',
      Suppliers: 'Постачальники',
      Customers: 'Клієнти',
      'Can confirm clients': 'Підтвердження контрагента',
      'Don`t use kpi check': 'Не використовувати перевірку KPI',
      Responsible: 'Відповідальний',
      'Responsible for confirmation': 'Відповідальний за підтвердження',
      'Client is resident': 'Контрагент є резидентом',
      'SAP vendor number': 'SAP номер контрагента',
      Equity: 'Капітал',
      'Net income': 'Чистий прибуток',
      'Total fixed assets': 'Всього основного капіталу',
      Turnover: 'Оборот',
      'Total assets': 'Всього активів',
      'Annual traded volume': 'Річний обсяг торгів',
      'Info for traders': 'Інформація для трейдерів',
      'Contact (future)': 'Контакт (майбутній)',
      City: 'Місто',
      District: 'Район',
      Regions: 'Регіони',
      'Client crop area': 'Посівна площа контрагента',
      'Cash Flow': 'Кеш-фло',
      'Client transports count': 'Лічильник транспортних засобів контрагента',
      'Regular status': 'Статус угод',
      Potencial: 'Потенційний',
      Active: 'Активний',
      Inactive: 'Неактивний',
      Closed: 'Закритий',
      Size: 'Розмір',
      'Contact (first)': 'Контакт (перший)',
      Agent: 'Посередник',
      Producer: 'Виробник',
      Competitor: 'Конкурент',
      'Local currency': 'Локальна валюта',
      'Foreign currency': 'Іноземна валюта',
      'Own cunterparty': 'Власний контрагент',
      'outgoing invoice prefix': 'префікс вихідного інвойсу',
      Template: 'Шаблон',
      'Signatory position': 'Посада підписанта',
      'Signatory position eng': 'Посада підписанта (англ.)',
      'Code of economic activities': 'КВЕД',
      'Counterparty type': 'Тип контрагента',
      'legal entity': 'юридична особа',
      'physical person': 'фізична особа',
      'individual entrepreneur': 'фізична особа-підприємець',
      'Mailing address': 'Адреса розсилки',
      'Types of activity': 'Види діяльності',
      Updates: 'Оновлення',
      client: 'контрагент',
      Clients: 'Контрагенти',
      'Can view client offers': 'Можна переглядати оффер контрагентів',
      'Can view client tickets': 'Можна переглядати тікети контрагентів',
      'Can view client confirmations': 'Можна переглядати підтвердження по контрагенту',
      'Can view client statistics': 'Можна переглядати статистику контрагентів',
      'Can view client persons': 'Можна переглядати співробітників контрагентів',
      'Can edit date_confirmation': 'Можна редагувати дату підтвердження',
      'Can edit date_first_confirmation': 'Можна редагувати дату першого підтвердження',
      'Can edit status': 'Лише куратор може редагувати статус',
      'Can edit kpi confirmation success status':
        'Можна редагувати статус успішного підтвердження KPI',
      'Can edit client responsible': 'Можна редагувати відповідального по контрагенту',
      'Can edit client confirmation of a counterparty':
        'Можна редагувати підтвердження по контрагенту',
      'Can approve client': 'Можна підтверджувати контрагента',
      'Can reactivate approval config': 'Можна повторно активувати конфігурацію підтвердження',
      'Has sanctions': 'Є санкції',
      'Has criminal cases': 'Має кримінальні справи',
      'Has tax debt': 'Має податковий борг',
      Crop: 'Культура',
      'Initial volume (t)': 'Початковий обʼєм (т)',
      'Volume (t)': 'Обʼєм (т)',
      'Cultivated area': 'Посівна площа',
      'productivity of land': 'продуктивність землі',
      Note: 'Примітка',
      potential: 'потенціал',
      potentials: 'потенціали',
      'Role Name': 'Імʼя ролі',
      'Limit per one deal (spot), t': 'Ліміт на одну угоду (спот), т',
      'Limit general (spot), t': 'Ліміт загальний (спот), т',
      'Limit open balance (spot), t': 'Ліміт відкритого балансу (спот), т',
      'Limit unpaid volume (spot), t': 'Ліміт неоплаченого Обʼєму (спот), т',
      'Limit per one deal (spot), USD': 'Ліміт на одну угоду (спот), USD',
      'Limit general (spot), USD': 'Ліміт загальний (спот), USD',
      'Limit per one deal (forward), t': 'Ліміт на одну угоду (форвард), т',
      'Limit general (forward), t': 'Ліміт загальний (форвард), т',
      'Limit open balance (forward), t': 'Ліміт відкритого балансу (форвард), т',
      'Limit unpaid volume (forward), t': 'Ліміт неоплаченого обʼєму (форвард), т',
      'Limit per one deal (forward), USD': 'Ліміт на одну угоду (форвард), USD',
      'Limit general (forward), USD': 'Ліміт загальний (форвард), USD',
      'Affiliated suppliers': 'Афілійовані постачальники',
      Basises: 'Базиси',
      'Payment conditions': 'Умови оплати',
      'Payment conditions auto': 'Умови оплати авто',
      'Payment conditions option': 'Опція умов оплати',
      'Client role': 'Контрагент роль',
      'Client roles': 'Ролі контрагентів',
      'Can change limits': 'Має повноваження змінювати ліміти',
      owner: 'власник',
      Owners: 'Власники',
      other: 'інший',
      Others: 'Інші',
      seller: 'продавець',
      Sellers: 'Продавці',
      'Can view sellers': 'Можна переглядати продавців',
      broker: 'брокер',
      Brokers: 'Брокери',
      surveyor: 'сюрвеєр',
      Surveyors: 'Сюрвеєри',
      insurer: 'страховик',
      Insurers: 'Страховики',
      bank: 'банк',
      Banks: 'Банки',
      forwarder: 'експедитор',
      Forwarders: 'Експедитори',
      exporter: 'експортер',
      Exporters: 'Експортери',
      buyer: 'покупець',
      Buyers: 'Покупці',
      elevator: 'елеватор',
      Elevators: 'Елеватори',
      Farms: 'Господарства',
      Update: 'Оновлення',
      Response: 'Відповідь',
      Priority: 'Пріоритет',
      Type: 'Тип',
      'Contact status': 'Статус контакту',
      'Is auto created': 'Згенеровано',
      'Responsible users': 'Відповідальні користувачі',
      Initiator: 'Ініціатор',
      'Notification date (+time)': 'Дата сповіщення (+час)',
      'Can change responsible': 'Можливо змінити відповідального',
      Title: 'Назва',
      'Update topic': 'Тема оновлень',
      'Update topics': 'Теми оновлень',
      'Update failure reason': 'Причина невдалого оновлення',
      'Update failure reasons': 'Причини невдалого оновлення',
      'Title in English': 'Назва (англ.)',
      'Client Document Types': 'Типи документів',
      'Is visible': 'Видно',
      'Copy is available': 'Копія отримана',
      'Original is available': 'Оригінал отримано',
      'Sending date': 'Дата відправлення',
      'Receipt date': 'Дата отримання',
      'Client Document': 'Документ контрагента',
      'Client Documents': 'Документи контрагентів',
      'Commentary text': 'Коментар',
      'Client commentary': 'Коментар контрагента',
      'Client commentaries': 'Коментарі контрагентів',
      'Can view client commentaries': 'Можна переглядати коментарі контрагентів',
      Area: 'Площа',
      Volume: 'Обʼєм',
      'Crop year': 'Рік врожаю',
      'Grain quality check': 'Перевірка якості',
      'Cargo price': 'Вартість вантажу',
      Currency: 'Валюта',
      'Exchange rate': 'Курс обміну валют',
      'Place of storage': 'Місце зберігання',
      'Crop Area': 'Посівна площа',
      'Crop Areas': 'Посівні площі',
      Value: 'Показник',
      'Value alt': 'Значення альтернативне',
      'Title alt': 'Назва альтернативна',
      Distance: 'Відстань',
      'Warehouse Distance': 'Відстань до складу',
      'Warehouse Distances': 'Відстань до складу',
      Codename: 'Код назва',
      'Client Role': 'Роль контрагента',
      'Client Roles': 'Ролі контрагента',
      Calendar: 'Календар',
      Calendars: 'Календарі',
      'Cargo Brand': 'Бренд вантажу',
      'Cargo Brands': 'Бренди вантажів',
      'Name Eng': 'Назва (англ)',
      Region: 'Регіон',
      Customs: 'Митниця',
      Customses: 'Митниці',
      'Invoice Position': 'Інвойс позиція',
      Payment: 'Платіж',
      Logistic: 'Логістика',
      Consignment: 'Квитанція',
      'Bill of Lading': 'Коносамент',
      'Disbursement Bill of Lading': 'Видаткова накладна',
      'Contract Charges': 'Витрати контракту',
      Voyage: 'Рейс',
      Operation: 'Операція',
      Control: 'Контроль',
      'Bank Account': 'Банківський рахунок',
      created: 'створений',
      updated: 'оновлено',
      'This supplier must be a distributor, because it has opened reassignments.':
        'Цей постачальник повинен бути дистрибʼютором, тому що він має відкриті переуступки.',
      'This supplier must be a distributor, because it has opened offsets.':
        'Цей постачальник повинен бути дистрибʼютором, тому що він відкрив заліки.',
      'Warehouse distance can have only one warehouse.':
        'Складська дистанція може мати тільки один склад.',
      'Warehouse required.': 'Потрібен склад.',
      'Distance should be unique by client, city and warehouse.':
        'Відстань має бути унікальною для контрагента, міста та складу.',
      'Delivery period': 'Термін поставки',
      Shipment: 'Відвантаження',
      'Cargo executed': 'Виконаний вантаж',
      Executed: 'Виконано',
      Washout: 'Вошаут',
      Process: 'Процес',
      Valid: 'Дійсний',
      invalid: 'недійсний',
      'One full cargo': 'Один повний вантаж',
      'Partial shipment allowed': 'Дозволяється часткове відвантаження',
      Seller: 'Продавець',
      Sale: 'Продаж',
      Purchase: 'Закупівля',
      Prepayment: 'Передоплата',
      Processed: 'Оброблено',
      Sent: 'Відправлений',
      Spot: 'Спот',
      Forward: 'Форвард',
      Brokerage: 'Брокерські послуги',
      Services: 'Послуги',
      Intermediate: 'Проміжний',
      Export: 'Експорт',
      '20 DV': 'Тариф 20 DV',
      '40 DV': 'Тариф 40 DV',
      'Not selected': 'Не вибрано',
      'Loading minus': 'Завантаження в мінус',
      'Loading plus': 'Завантаження в плюс',
      'Free tax invoice': 'Інвойс без податків',
      'On first event': 'За першою подією',
      'Absolute value': 'Абсолютне значення',
      Percentage: 'Відсоток',
      'Contract Option': 'Опція контракту',
      'Option (value)': 'Опціон (сума)',
      'Conclusion (date)': 'Дата укладання',
      'Execution (start date)': 'Виконання (дата початку)',
      'Execution (end date)': 'Виконання (кінцева дата)',
      'Deal Type': 'Тип угоди',
      'Notify party': 'Повідомлення про партію',
      'Use type': 'Тип використання',
      'According to contract template': 'Згідно з шаблоном контракту',
      'start date': 'дата початку',
      'end date': 'кінцева дата',
      'Program type': 'Тип програми',
      'Main program': 'Основна програма',
      Crops: 'Культури',
      'Price currency': 'Валюта ціни',
      'Premium currency': 'Преміум валюта',
      month: 'місяць',
      Price: 'Ціна',
      Premium: 'Преміум',
      Charge: 'Витрати',
      'Price per t': 'Ціна за т',
      'With VAT': 'З ПДВ',
      'Trader note': 'Примітка трейдера',
      'Price opposite': 'Зустрічна ціна',
      'Is gain': 'Це надходження',
      'Counterparty amount': 'Сума контрагента',
      'Our amount': 'Наша сума',
      'Final Amount': 'Остаточна сума',
      'Analytical Amount': 'Аналітична сума',
      Validated: 'Перевірено',
      'Under discussion': 'Обговорюється',
      Forecast: 'Прогноз',
      'Pending confirmation': 'Очікує підтвердження',
      Planned: 'Планування',
      Date: 'Дата',
      Commodity: 'Товар',
      'ContractCharge by default': 'Витрати за контрактом за замовчуванням',
      'ContractCharge by default rules':
        'Автоматичне нарахування витрат за контрактом за замовчуванням',
      'Opposite basis': 'Протилежний базис',
      'Costs opposite': 'Витрати зустрічного контракту',
      'Currency exchange': 'Обмін валюти',
      'Counterparties opposite': 'Зустрічний контрагент',
      'Execution date opposite': 'Дата укладання зустрічна',
      'Price premium opposite': 'Преміум ціна зустрічного контракту',
      Derivative: 'Дериватив',
      'Number of contract (#)': 'Номер контакту (#)',
      'Number of folder (#)': 'Номер папки (#)',
      'Responsible for signing': 'Відповідальний за підпис',
      'Volume with docs (t)': 'Обʼєм по документам (т)',
      Option: 'Опціон',
      'Final volume (t)': 'Кінцевий обʼєм (т)',
      Origin: 'Походження',
      Destination: 'Призначення',
      Origins: 'Походження',
      Destinations: 'Призначення',
      'Conclusion price': 'Ціна при підписанні',
      'Analytical price': 'Аналітична ціна',
      'Price USD': 'Ціна USD',
      'Currency and Rate': 'Валюта та курс',
      Basis: 'Базис',
      'Paper Trade': 'Paper Trade',
      'Payment conditions options': 'Опції умов оплати',
      'Custom declaration': 'Митне декларування',
      'Dont check kpi time different': 'Не використовувати для KPI',
      'Expiration start': 'Початок експірації',
      'Expiration end': 'Кінцева дата',
      'Nomination date': 'Дата номінації',
      Extention: 'Пролонгація',
      'Bill of lading (date)': 'Коносамент (дата)',
      'Transfer of ownership (date)': 'Перехід права власності (дата)',
      'Shipment type': 'Тип відвантаження',
      'Prepay payment': 'Умова передплати',
      'Balance payment': 'Платіжний баланс',
      'Broker contacts': 'Контакти брокера',
      Forwarder: 'Експедитор',
      Ports: 'Порти',
      'Opposite ports': 'Протилежні порти\nзустрічний',
      'Station depart': 'Станція відправлення',
      'Station receive': 'Станція отримання',
      'Exchange for calculation': 'Розрахувати курс',
      'Exchange for calculation (opposite)': 'Розрахувати курс (протилежний)',
      'Delivery conditions': 'Умови доставки',
      VAT: 'ПДВ',
      'VAT (value)': 'ПДВ (сума)',
      'No extension': 'Без продовження',
      'Preadvice (days)': 'Преадвайс (дні)',
      'Quality info': 'Якість інфо',
      Stage: 'Вид',
      'Previous status': 'Попередній статус',
      'Logistics costs VAT': 'Витрати по логістиці (ПДВ)',
      'Logistics penalties VAT': 'Штрафи по логістиці (ПДВ)',
      'Needs original documents': 'Потрібні оригінали документів',
      'Regional managers': 'Регіональні менеджери',
      'Heads of sales manager': 'Регіональні представники',
      'Calculate execution by intermediate logistics': 'Розрахувати виконання (проміжна логістика)',
      'Calculate tax profit to pay': 'Розрахувати податок',
      'Elevator (start date)': 'Елеватор (дата початку)',
      'Elevator (end date)': 'Елеватор (кінцева дата)',
      'Estimated execution date': 'Очікувана дата виконання',
      'Loading option': 'Опціон завантаження',
      Swap: 'Своп',
      'Ignore limits': 'Ігнорувати ліміти',
      'Ignore client status': 'Ігнорувати статус контрагента',
      Terminal: 'Термінал',
      'Few departure stations': 'Кілька станцій відправлення',
      ISCC: 'ISCC',
      'Minimum batch of payment': 'Мінімальна сума платежу',
      'Internal Chain': 'Внутрішній ланцюг',
      'Ship classification': 'Класифікація судна',
      'Estimated freight value': 'Очікувана вартість фрахту',
      'Estimated freight VAT value': 'Приблизна сума фрахт ПДВ',
      'Estimated freight VAT': 'Приблизна фрахт ПДВ',
      Multicontract: 'Мультиконтракт',
      'Contract been updated today': 'Контракт оновлено сьогодні',
      Season: 'Сезон',
      'vat return rate': 'ставка повернення ПДВ',
      Port: 'Порт',
      Budget: 'Бюджет',
      'Conclusion date currency': 'Валюта на момент укладання',
      'Conclusion date exchange rate': 'Курс обміну на момент укладання',
      'Potential buyers': 'Потенційні покупці',
      'Potential suppliers': 'Потенційні постачальники',
      'Created from risk position origination': 'Створено зі складу',
      'Tare Weight': 'Вага тари',
      'Quantity of measurement': 'Кількість одиниці виміру',
      'price of measurement': 'ціна за вимірювання',
      'Quantity of containers': 'Кількість контейнерів',
      guarantors: 'гаранти',
      'Load rate': 'Норма завантаження',
      'Discharge rate': 'Норма розвантаження',
      'Demmurage rate': 'Ставка демереджа',
      'Dispatch rate': 'Ставка диспача',
      'Volume (min)': 'Обʼєм (мін.)',
      'Volume (max)': 'Обʼєм (макс.)',
      'Implied freight level': 'Передбачуваний фрахт вантажу',
      'Opposite freight level': 'Зустрічний фрахт',
      'Load port': 'Порт завантаження',
      'Discharge port': 'Порт розвантаження',
      'Arrival period start': 'Початок періоду прибуття',
      'Arrival period end': 'Кінець періоду прибуття',
      'Pre-advice': 'Преадвайс',
      'Is extension': 'Продовження',
      Discount: 'Дисконт',
      'Discount amount': 'Розмір дисконту',
      'Discount 1 mt': 'Дисконт / 1 т',
      'Sampling Condition': 'Умова відбору зразків',
      'Counterparty business reference': 'Бізнес-референс контрагента',
      'Loan percentage': 'Відсоток кредиту',
      'Interest rate': 'Процентна ставка',
      'Estimated opposite payment date': 'Прогнозна дата зустрічної оплати',
      'Payment days': 'Дні оплати',
      'Fixed price bonus': 'Фіксований бонус до ціни',
      'Use open price': 'Використати відкриту ціну',
      'Ending status': 'Фінальний статус',
      Open: 'Відкритий',
      Ending: 'Закінчення',
      Expiring: 'Еспірація',
      Expired: 'Закінчено',
      "Days to set 'ending status'(end of execution)":
        'Дні до встановлення «фінального статусу» (кінець виконання)',
      'Certification schemes': 'Сертифікаційні схеми',
      'Vehicle restrictions': 'Обмеження для ТЗ',
      Terminals: 'Термінали',
      'Ignore allocation': 'Ігнорувати розподіл',
      'Can view contract plan': 'Перегляд план контракту',
      'Can view contract costs': 'Перегляд витрат за контрактом',
      'Can view contract logistics': 'Перегляд логістики за контрактами',
      'Can view contract payments': 'Перегляд контрактних платежів',
      'Can view contract documents': 'Перегляд документів за контрактом',
      'Can view contract counterparties': 'Перегляд контрагентів за контрактом',
      'Can view contract plan MTM calculation': 'Перегляд контрактного плану, розрахунок MTM',
      'Can view contract activities': 'Перегляд історії за контрактами',
      'Can view contract processes': 'Перегляд етапів контракту',
      'Can view contract status': 'Перегляд статусу контракту',
      'Can confirm cargo execution of contract': 'Може підтверджувати виконання контракту (вантаж)',
      'Can confirm payment execution of contract':
        'Може підтверджувати виконання контракту (інвойс)',
      'Can confirm final execution of contract': 'Може підтверджувати виконання контракту (фінал)',
      'Can see buyer logistics payment': 'Перегляд оплат логістики покупцем',
      'Can see supplier logistics payment': 'Перегляд оплат логістики постачальником',
      'Can see payment progressbar': 'Перегляд оплат за контрактом',
      'Can see costs progressbar': 'Перегляд витрат за контрактом',
      'Can see contract payments totals': 'Перегляд загальної суму платежів за контрактом',
      'Can edit kpi_signing_success': 'Можна редагувати успіх підписання kpi',
      'Can change "updated today" status': 'Можна змінити статус «оновлено сьогодні»',
      'Can ignore client compliance status': 'Можна ігнорувати статус комплаєнса контрагента',
      'Can ignore excess contract volume': 'Ігнорувати надлишковий обʼєм контракту',
      'Can set voyage info for contract': 'Можна встановити інформацію про рейс для контракту',
      'Purchase contract': 'Закупівельний контракт',
      'Purchase contracts': 'Закупівельні контракти',
      'Can approve purchase contract': 'Може підтверджувати закупівельні контракти',
      'Can view purchase contracts of all authors':
        'Можна переглядати закупівельні контракти від усіх авторів',
      'Can view purchase contracts of all responsibles':
        'Можна переглядати закупівельні контракти від усіх відповідальних осіб',
      'Sale contract': 'Продажний контракт',
      'Sales contracts': 'Продажні контракти',
      'Can approve sale contract': 'Може підтверджувати продажний контракт',
      'Can view sale contracts of all authors':
        'Можна переглядати продажні контракти від усіх авторів',
      'Can view sale contracts of all responsibles':
        'Можна переглядати продажні контракти від усіх відповідальних осіб',
      'Services contracts': 'Контракти на послуги',
      'Intermediate contracts': 'Проміжні контракти',
      'Export contracts': 'Експортні контракти',
      Calculate: 'Розрахувати',
      'Vat discount': 'Знижка з ПДВ',
      '20 dv': 'Тариф 20 dv',
      Containers_type: 'Тип_контейнерів',
      'Price purchase calc': 'Ціна закупівлі розрахунок',
      'Costs calc': 'Розрахунок витрат',
      'Calculate auto tariff price': 'Розрахувати авто тариф',
      'Calculate elevator costs': 'Розрахувати затрати на елеватор',
      'Calculate railway costs': 'Розрахувати тариф залізниці',
      'Calculate freight costs': 'Розрахувати фрахт',
      'Conclusion date opposite': 'Зустрічна дата укладання',
      'Financing cost': 'Витрати фінансування',
      'Financing days': 'Дні фінансування',
      'Storage days': 'Дні зберігання',
      Distributors: 'Дистрибʼютори',
      'Closing of existing position': 'Закриття існуючої позиції',
      'Estimated P&L': 'Очікуваний PnL',
      'Broker commission': 'Брокерська комісія',
      'Possible buyers info': 'Інфо про потенційних покупців',
      'Possible suppliers info': 'Інфо про потенційних постачальників',
      'Possible brokers info': 'Інфо про потенційних брокерів',
      Strategy: 'Стратегія',
      'Trade confirmations': 'Трейдерські підтвердження',
      'Payment terms': 'Терміни оплати',
      'Price (min)': 'Ціна (мін.)',
      'Price (max)': 'Ціна (макс.)',
      'Connection to passport status': 'Зʼєднання до статусу паспорта',
      'Crop protection': 'Захист посівів',
      Seeds: 'Посіви',
      Requests: 'Заявки\nтікети',
      'Can view all requests': 'Перегляд всіх тікетів',
      'Can view request counterparties': 'Перегляд заявки контрагентів',
      'Can view request costs': 'Перегляд витрат по тікету',
      'Can view request finances': 'Перегляд фінансування тікету',
      'Can view request documents': 'Перегляд документів тікету',
      'Can view request logistic prices': 'Перегляд ціни логістики в тікету',
      'Can view prices': 'Переглянути ціни',
      'Can create ticket from offer': 'Можна створити тікет із пропозиції',
      'Can approve ticket or contract': 'Може погодити тікет або контракт',
      'Can cancel ticket or contract': 'Можна скасувати тікет або контракт',
      'Can confirm requests': 'Може підтверджувати тікет',
      'Restrict ticket creation from expired indicator':
        'Обмежити створення тікетів при закінченні терміну дії індикатора',
      Tickets: 'Тікети',
      Indicators: 'Індикатори',
      ContractPrice: 'Контрактна ціна',
      'Number of agreement (#)': 'Номер угоди (#)',
      'Agreement date': 'Дата угоди',
      autogenerated: 'згенеровано',
      'Premium price': 'Преміум ціна',
      'Additional agreement': 'Додаткова угода',
      'Additional agreements': 'Додаткові угоди',
      'Select one or more basises to filter charges inside contracts':
        'Виберіть один або кілька базисів, щоб відфільтрувати витрати всередині контрактів',
      'Basis Document': 'Базис документу',
      'Basis Documents': 'Базис документів',
      'Contract Basis Doc': 'Базис контракту',
      'Active option': 'Активна опція',
      'Price premium': 'Преміум ціна',
      'Margin relative, USD': 'Відносна маржа, USD',
      'Margin absolute, USD': 'Маржа абсолютна, USD',
      'For KPI year report': 'До звіту КПІ (рік)',
      Сommodity: 'Продукт',
      'Production Year': 'Рік виробництва',
      'Purchase Plan': 'План закупівлі',
      'User volume': 'Обʼєм користувача',
      'Volume in percent': 'Обʼєм у відсотках',
      'Indication date': 'Індифікатор дата',
      'A month for which the indicator price is assigned':
        'Місяць, на який призначається вказати ціну',
      'Cargo price, USD': 'Вартість вантажу, USD',
      'Used in market': 'Використовується на ринку',
      'Can mass-delete market prices': 'Може масово видаляти ринкові ціни',
      'Number (#)': 'Номер (#)',
      Invoice: 'Інвойс',
      Consignments: 'Складські квитанції',
      Credit: 'Кредит',
      'Main deal': 'Основна угода',
      'Buyer payment info': 'Інфо про оплату покупця',
      'Supplier payment info': 'Інфо про оплату постачальника',
      Amount: 'Всього',
      'Tax registration': 'Реєстрація податку',
      'percent conditions': 'відсоткові умови',
      'Buyer signator': 'Підписант покупця',
      'Supplier signator': 'Підписант постачальника',
      'General agreement': 'Генеральна угода',
      'General agreements': 'Генеральні угоди',
      'Can approve general agreement': 'Має повноваження підтвердження генеральної угоди',
      'Railway required': 'Потрібна залізниця',
      'Vet required': 'Необхідний Vet',
      'Quality required': 'Потрібна якість',
      'Declaration required': 'Потрібна декларація',
      'EUR 1 required': 'Потрібен 1 EUR',
      'Partial executed': 'Частково виконано',
      'Volume in positions from execution': 'Обʼєм по виконанню',
      'With derivative': 'Із деривативом',
      Number: 'Номер',
      'Stock volume': 'Обʼєм запасів',
      'Quantity of estimated lots': 'Кількість очікуваних лотів',
      Margin: 'Маржа',
      'Margin per ton': 'Маржа за тонну',
      Mtm: 'Mtm',
      'Physical margin': 'Фізична маржа',
      'Derivative margin': 'Маржа деривативу',
      'Total margin': 'Загальна маржа',
      'Total value': 'Загальна сума',
      volume: 'обʼєм',
      'Mrket price': 'Ринкова ціна',
      Commission: 'Комісія',
      'Is liquid': 'Є рідиною',
      'Use escalations': 'Використайте ескалацію',
      Start: 'Старт',
      End: 'Кінець',
      'Auto apply': 'Автозастосування',
      'Auto applying:': 'Автозастосувати:',
      'Handly applying:': 'Застосувати вручну:',
      Any: 'Будь-який',
      'Export Contract': 'Контракт на експорт',
      'Base contract': 'Основний контракт',
      Consignee: 'Одержувач',
      'Delivery terminal': 'Термінал доставки',
      'Delivery address': 'Адреса доставки',
      'Contract consignee': 'Одержувач за контрактом',
      'Contract consignees': 'Одержувачі за контрактом',
      'Empty contract number': 'Порожній номер контракту',
      'Crops required.': 'Необхідні культури.',
      'Months required.': 'Потрібні місяці.',
      'Positions required.': 'Необхідні посади.',
      'Term of the contract': 'Строк дії договору',
      'is completed on ': 'завершено на ',
      info: 'інфо',
      t: 'т',
      'Ticket status': 'Статус тікета',
      'Is a closing of the existing position': 'Закриває існуючу позицію',
      Details: 'Деталі',
      Startegy: 'Стратегія',
      'Trade confirmation': 'Трейдерське підтвердження',
      'PurchasePlan must be unique by crop in particular period':
        'План закупівлі має бути унікальним для культури в певний період',
      'First date must be less then Second': 'Перша дата має бути меншою за другу',
      'is not approved (or approved condition)': 'не підтверджено (або стан погоджено)',
      'The volume of current indicator is exceeded. ': 'Об’єм поточного індикатора перевищено. ',
      'Can`t be empty': 'Не може бути порожнім',
      'has status - closed': 'має статус «закрито»',
      'Unpaid volume is greater than limit for spot contracts':
        'Неоплачений обʼєм перевищує ліміт для спот контрактів',
      'Unpaid volume is greater than limit for forward contracts':
        'Неоплачений обʼєм перевищує ліміт для форвардних контрактів',
      'Volume is greater than limit per one deal for spot contracts ({} t)':
        'Обʼєм перевищує ліміт на одну угоду для спот контрактів ({} t)',
      'Volume is greater than general limit for spot contracts ({} t)':
        'Обʼєм перевищує загальний ліміт для спот контрактів ({} t)',
      'Volume is greater than limit per one deal for forward contracts ({} t)':
        'Обʼєм перевищує ліміт на одну угоду для форвардних контрактів ({} t)',
      'Volume is greater than general limit for forward contracts ({} t)':
        'Обʼєм перевищує загальний ліміт для форвардних контрактів ({} t)',
      'Total value is greater than limit per one deal for spot contracts ({} USD)':
        'Загальна вартість більше, ніж ліміт на одну угоду за спот контрактами ({} USD)',
      'Total value is greater than general limit for spot contracts ({} USD)':
        'Загальна вартість більше, ніж загальний ліміт для спот контрактів ({} USD)',
      'Total value is greater than limit per one deal for forward contracts ({} USD)':
        'Загальна вартість більше, ніж ліміт на одну угоду для форвардних контрактів ({} USD)',
      'Total value is greater than general limit for forward({} USD)':
        'Загальна вартість перевищує загальний ліміт для форварду ({} USD)',
      'With this volume open balance is greater than open balance limit for spot contracts ({} t)':
        'Обʼєм відкритого балансу перевищує ліміт відкритого балансу для спот контрактів ({} t)',
      'With this volume open balance is greater than open balance limit for forward contracts ({} t)':
        'Обʼєм відкритого балансу перевищує ліміт відкритого балансу для форвардних контрактів ({} t)',
      'Set consignment warehouse': 'Встановити склад для квитанції',
      'Contract can have only one warehouse.': 'Контракт може мати тільки один склад.',
      'Contract already exist with number ': 'Контракт із таким номером вже існує ',
      'Contract in multicontract must have unique commodity and price.':
        'Контракт в мультиконтракті повинен мати унікальний товар і ціну.',
      'Contract and general agreement must have same currency.':
        'Контракт і генеральна угода повинні мати однакову валюту.',
      'Shipment terminal required.': 'Необхідний термінал відвантаження.',
      'Supplier must be owner too': 'Постачальник також повинен бути власником',
      'Buyer must be owner too': 'Покупець також повинен бути власником',
      'Credit limit required': 'Необхідний кредитний ліміт',
      'Currency required': 'Необхідна валюта',
      'Contracts and general agreement must have same currency.':
        'Контракти та генеральна угода повинні мати однакову валюту.',
      'Contract plan must be unique by month and passport.':
        'План контракту повинен бути унікальним за місяцем і паспортом.',
      'Entry must be unique by month and passport.':
        'Контракт повинен бути унікальним за місяцем і паспортом.',
      'Entry must be unique by contract and derivative.':
        'Запис повинен бути унікальним за контрактом і деривативом.',
      'In forward contracts end of execution is required.':
        'У форвардних контрактах кінець виконання є обовʼязковим.',
      'In forward contracts conclusion date should be less than end of execution.':
        'У форвардних контрактах дата укладення повинна бути меншою за кінцеву дату виконання.',
      'Currency exchange should be in {}.': 'Обмін валюти має бути {}.',
      'Number should be positive.': 'Число має бути додатним.',
      'Must include either a contract or a passport, fill in at least one field.':
        'Необхідно додати або контракт, або паспорт, заповніть принаймні одне поле.',
      'Must include a contract or a passport, fill in just one field.':
        'Необхідно додати контракт або паспорт, заповніть лише одне поле.',
      'Please select exchange rate': 'Будь ласка, виберіть валютний курс',
      'Currency exchange rate should be in {}': 'Курс обміну валют має бути в {}',
      'Such sale plan already exists': 'Такий план продажу вже існує',
      'conclusion date can`t be in the past': 'дата укладання не може бути в минулому часі',
      'conclusion date can`t be less then "Expiration start"':
        'дата завершення не може бути менше, ніж «Початок терміну дії»',
      'conclusion date can`t be greater then "Expiration end"':
        'дата укладання не може бути пізнішою, ніж «Дата завершення терміну»',
      'A ticket cannot be created with an unconfirmed indicator':
        'Неможливо створити тікет із непідтвердженого індикатора',
      'Cannot create ticket from expired indicator':
        'Неможливо створити тікет із простроченого індикатора',
      'Contract volume can`t be less than contract executed volume':
        'Обʼєм контракту не може бути меншим за виконаний обʼєм контракту',
      'No contract': 'Без контракту',
      'No charges': 'Без витрат',
      'charge don`t have calculation': 'немає розрахунку для витрат',
      'Key {key} does not exist': 'Ключ {key} не існує',
      'The number of objects is not specified.': 'Кількість об’єктів не вказана.',
      'Contract purpose filter': 'Фільтр за ціллю контракту',
      All: 'Всі',
      "File name contains the invalid characters (don't use special symbols like '<>|&=-;`/\\').":
        "Назва файлу містить неприпустимі символи (не використовуйте спеціальні символи, як-от '<>|&=-;`/\\').",
      'Please check the data you would like to import. It seems that the spreadsheet has a lot of empty rows or to long.\nThe best way to import the registry is to copy only data to the new spreadsheet, save it and import again.\nIn case you see this message again please contact Support Manager.':
        'Перевірте дані, які ви хочете імпортувати. Здається, що таблиця має багато порожніх або довгих рядків. \nНайкращий спосіб імпортувати реєстр — скопіювати тільки дані в нову таблицю, зберегти їх і імпортувати знову.\nЯкщо ви бачите це повідомлення знову, будь ласка, звʼяжіться з менеджером підтримки.',
      'Custom values': 'Спеціальні значення',
      English: 'Англійська',
      Ukrainian: 'Українська',
      Russian: 'Російська',
      Sunday: 'Неділя',
      Monday: 'Понеділок',
      Tuesday: 'Вівторок',
      Wednesday: 'Середа',
      Thursday: 'Четвер',
      Friday: 'Пʼятниця',
      Saturday: 'Субота',
      'Dark theme': 'Темна тема',
      'White theme': 'Біла тема',
      'Top navbar': 'Верхня панель навігації',
      'Left navbar': 'Ліва панель навігації',
      'Collapsed navbar': 'Згорнута панель навігації',
      'Open navbar': 'Відкрита панель навігації',
      'Sale deals': 'Продажні угоди',
      'Purchase deals': 'Закупівельні угоди',
      Lineup: 'Lineup',
      'Table view': 'Вид таблиці',
      'List view': 'Перегляд списку',
      'Block view': 'Перегляд блоків',
      Enabled: 'Увімкнено',
      Disabled: 'Вимкнено',
      Allocation: 'Розподіл',
      'Risk position': 'Позиція ризику',
      'Derivatives position': 'Позиція деривативу',
      'Consolidated crop position': 'Консолідована позиція культури',
      Processing: 'Обробка',
      Unloaded: 'Розвантажено',
      'Contract tolerance': 'Толеранс контракту',
      'Ticket tolerance': 'Толеранс тікета',
      'Indicator tolerance': 'Толеранс індикатора',
      'Is default': 'За умовчанням',
      'Business Unit': 'Бізнес-юніт',
      Year: 'Рік',
      Years: 'Роки',
      'Type Of Activity': 'Вид діяльності',
      'Types Of Activity': 'Види діяльності',
      Author: 'Автор',
      'Last editor': 'Останній редактор',
      Updated: 'Оновлено',
      Created: 'Створено',
      Yes: 'Так',
      'Site setting': 'Налаштування сайту',
      'Table name': 'Назва таблиці',
      'Column name': 'Назва стовпчика',
      'New title': 'Нова назва',
      Visible: 'Наявний',
      Users: 'Користувачі',
      'Columns Set Config': 'Конфігурація стовпців',
      'Form name': 'Назва форми',
      'Field name': 'Назва поля',
      Required: 'Обовʼязковий',
      title: 'назва',
      'Filter level (table)': 'Фільтр рівня (таблиця)',
      Collection: 'Конфігурація',
      'New name': 'Нова назва',
      'Can use export button': 'Можна використовувати кнопку експорту',
      'Can view settings': 'Можна переглядати налаштування',
      'Can view logentry': 'Перегляд даних про вхід',
      'Can view users': 'Перегляд списку користувачів',
      'Can view user details': 'Перегляд даних користувачів',
      'Can edit closed elements': 'Можна редагувати закриті елементи',
      'Can view contracts analytics': 'Перегляд аналітики контрактів',
      'Can view logistics analytics': 'Перегляд аналітики логістики',
      'Can view finances analytics': 'Перегляд аналітики фінансів',
      'Can view clients analytics': 'Перегляд аналітики контрагентів',
      'Can view market analytics': 'Перегляд аналітики ринку',
      'Can view passports analytics': 'Перегляд аналітики паспортів',
      'Can edit approval status': 'Можна редагувати статус підтвердження',
      'Can view playground templates': 'Перегляд шаблону пісочниці',
      'Profile Owner': 'Профіль власника',
      'Business Unit Filter': 'Фільтр бізнес-юніт',
      'Application language': 'Мова програми',
      'First day of week': 'Перший день тижня',
      'Application color scheme': 'Схема кольорів системи',
      'Application font': 'Шрифт програми',
      'Navbar position': 'Позиція панелі навігації',
      'Navbar grouping': 'Групування панелі навігації',
      'Sale or purchase deals by default': 'Продаж або закупівля за замовчуванням',
      'Sale or purchase general agreement by default':
        'Генеральна угода продаж або закупівля за замовчуванням',
      'Warehouse type by default': 'Тип складу за замовчуванням',
      'Lineup report by default': 'Звіт Lineup за замовчуванням',
      'Counterpartiew view by default': 'Перегляд контрагента за замовчуванням',
      'Persons view by default': 'Перегляд контактів за замовчуванням',
      'Tickets view by default': 'Перегляд тікетів за замовчуванням',
      'Contracts view by default': 'Перегляд контрактів за замовчуванням',
      'Contract tab by default': 'Вкладка контракту за замовчуванням',
      'Invoices view by default': 'Перегляд інвойсів за замовчуванням',
      'Vessels view by default': 'Перегляд корабля за замовчуванням',
      'Passports view by default': 'Перегляд паспортів за замовчуванням',
      'Voyages view by default': 'Перегляд рейсів за замовчуванням',
      'Position report by default': 'Звіт про позицію за замовчуванням',
      Wizard: 'Wizard',
      Recap: 'Підсумок',
      'Help buttons expanded': 'Розгорнути кнопки допомоги',
      'Simplified system': 'Спрощена система',
      'The length of the message than browser mail client can accept':
        'Довжина повідомлення, яку може прийняти поштовий клієнт браузера',
      'User Profile': 'Профіль користувача',
      'Filter by this unit': 'Фільтр за цим юнітом',
      'Is default in forms': 'За замовчуванням у формах',
      'Comment text': 'Текст коментаря',
      Comments: 'Коментарі',
      'Custom statuses': 'Статуси користувачів',
      'Start date': 'Дата початку',
      'End date': 'Кінцева дата',
      'Is current': 'Поточний',
      Seasones: 'Сезонність',
      'Filter level': 'Рівень фільтра',
      'Ext Data': 'Зовнішні дані',
      Purposes: 'Цілі',
      Boolean: 'Вибір Так/Ні',
      String: 'Рядок',
      Text: 'Текст',
      Choice: 'Вибір',
      'Multiple choices': 'Множинний вибір',
      Precision: 'Точність',
      'Custom field': 'Користувацьке поле',
      'Custom fields': 'Користувацькі поля',
      'Custom field`s choice value': 'Значення вибору користувацького поля',
      Purpose: 'Ціль',
      'Date value': 'Дата',
      'Text value': 'Текст',
      'Boolean value': 'Логічне значення',
      'String value': 'Рядкове значення',
      'Choice value': 'Варіант значення',
      'Choice values': 'Значення вибору',
      'Object`s custom field`s value': 'Значення спеціального поля об’єкта',
      'Object`s custom field`s values': 'Значення спеціальних полів об’єкта',
      'Target field name for a value to be used in':
        'Назва поля для значення, яке буде використане',
      'Lower bound of the range': 'Мінімальне значення толерансу',
      'Upper bound of the range': 'Максимальне значення толерансу',
      'Value to be applied within the range': 'Значення, яке буде застосовано в межах толерансу',
      'Indicates if the value is a percentage': 'Вказівка на те, що значення відсоткове',
      RangeRule: 'Правила толерансу',
      RangeRules: 'Правила толерансу',
      'Choose one from: %(keys)s': 'Виберіть один із: %(keys)s',
      'Select positions which you want to import': 'Виберіть позиції для імпорту',
      'Please select positions': 'Будь-ласка, виберіть позиції',
      "We couldn't find your GT Bot. Please contact with your Support Manager.":
        'Ми не змогли знайти вашого GT бота. Будь ласка, зв’яжіться з менеджером підтримки.',
      '{ telegram_chat.user.profile.user.get_full_name() }, you already have a chat with us. \nIs it you?':
        '{ telegram_chat.user.profile.user.get_full_name() },  вас вже є активний чат з нами. Це ви?',
      'Hello, {telegram_chat.user.profile.user.get_full_name()}, you have been successfully authenticated.\nNow you can use our GT Bot to receive notifications.':
        'Привіт, {telegram_chat.user.profile.user.get_full_name()}! Ви успішно пройшли аутентифікацію.\nТепер ви можете використовувати бота GT для отримання сповіщень.',
      'Thank you for your answer. Chat is Active.\nFeel free to ask your Support Manager any questions.':
        'Дякую за вашу відповідь. Чат активний.\nЯкщо виникнуть будь-які питання, будь ласка, зверніться до менеджера.',
      'Chat deactivated, contact with your Support Manager':
        'Чат деактивовано.Будь ласка, зв’яжіться з менеджером підтримки',
      'Not found': 'Не знайдено',
      'Wrong credentials or api key': 'Невірні облікові дані або ключ API',
      'Finished with status {}: {}': 'Завершено зі статусом {}: {}',
      'Client connection timeout': 'Перевищено час очікування підключення клієнта',
      "Transliteration tag translit available only with lang 'ua' or 'ru'.":
        'Тег транслітерації доступний лише з мовою «ua» або «ru».',
      'Can not be converted value to number. Example: {{ price | to_float(3) }}, where price must be a number.':
        'Неможливо перетворити значення на число. Приклад: {{ price | to_float(3) }}, де ціна має бути числом.',
      'Can not be splitted value with negative number. It should be greater than 0.':
        'Неможливо розділити значення на від’ємне число. Воно має бути більше 0.',
      'Can not split value with split_by. Example: {{ price | split_by(3) }}.':
        'Неможливо розділити значення . Приклад: {{ price | split_by(3) }}.',
      'Syntax error: {}': 'Синтаксична помилка: {}',
      'Date should be in the present': 'Дата повинна бути в сьогоденні',
      'Check volume': 'Перевірити обʼєм',
      'Currency rate must be same as currency': 'Платіж та рахунок повинні мати однакову валюту',
      'One user can have only one default business unit':
        'Один користувач може мати лише однин бізнес-юніт за умовчанням',
      'Wrong content type': 'Неправильний тип вмісту',
      'Crop year({crop_year}) should be in this list: {crop_years_list}':
        'Рік урожаю({crop_year}) має бути в цьому списку: {crop_years_list}',
      'Year must be an integer': 'Рік має бути цілим числом',
      'Year must be in 1984-2123 interval': 'Рік має бути в інтервалі 1984-2123',
      'Only one year can be default. Current ':
        'Лише один рік може бути за замовчуванням. Поточний ',
      'Lower bound and upper bound must be positive integers':
        'Нижня та верхня межі мають бути додатними цілими числами',
      'Lower bound must be less than upper bound.': 'Нижня межа повинна бути меншою, ніж верхня.',
      'The range you want to create ({}-{}) overlaps with an existing range ({}-{}).':
        'Діапазон, який ви хочете створити ({}-{}), збігається з існуючим діапазоном ({}-{}).',
      'Percentage value must be between 0 and 100.': 'Значення відсотка має бути від 0 до 100.',
      '{} matching query ({}={}) does not exist': '{} відповідний запит ({}={}) не існує',
      'There are more than one {} (where {}={}) in the database - {}':
        'У базі даних є більше одного {} (де {}={}) - {}',
      'This quality absents in crop quality': 'Ця якість відсутня в якості врожаю',
      'Title eng': 'Назва (англ.)',
      'Is positive': 'Є позитивним',
      moisture: 'волога',
      trash: 'сміття',
      default: 'за замовчуванням',
      'Code name': 'Код назва',
      'code name for calculation duval': 'кодова назва для розрахунку duval',
      Quality: 'Якість',
      Qualities: 'Якості',
      'Use in templates': 'Використання в шаблонах',
      Tariff: 'Тариф',
      'Crop quality': 'Якість врожаю',
      'Crop qualities': 'Якості врожаю',
      'Can choose CropQuality': 'Можна вибрати якість урожаю',
      Packing: 'Упаковка',
      'container 20 dv, t': 'контейнер 20 dv, т',
      'container 40 dv, t': 'контейнер 40 dv, т',
      'Packing wagon, t': 'Вагон, т',
      'Packing car, t': 'Авто, т',
      'Prices section': 'Розділ цін',
      'Is included in position': 'Включити до позиції',
      'Charge-off ratio': 'Списання ставки',
      'Default VAT return in contracts for this cargo':
        'Повернення ПДВ за умовчанням у контрактах на цей вантаж',
      'Fetch derivatives': 'Отримати деривативи',
      'Is analytic parent': 'Є аналітичний',
      'HS code': 'HS код',
      'Warehouse entry': 'Склад',
      'Warehouse entries': 'Склади',
      'Parse until date': 'Парсити до дати',
      Limit: 'Ліміт',
      Plan: 'План',
      'Price discount': 'Дисконт на ціну',
      'Use for volume': 'Використати для об’єму',
      'Use for price': 'Використовуйте для ціни',
      'Use duval': 'Використовуйте Duval',
      'From value': 'Із значення',
      'To value': 'До значення',
      Coefficient: 'Коефіцієнт',
      'Crop can not be a parent to itself.': 'Культура не може бути материнською сама для себе.',
      'More than two with same quality and commodity.':
        'Більше двох однакових за якістю та товаром.',
      'Intermediate logistic': 'Проміжна логістика',
      'Bill of landing': 'Коносамент',
      'Contract charge': 'Витрати контракту',
      'Disbursement bill of lading': 'Видаткова накладна',
      Vehicle: 'Транспортний засіб',
      Driver: 'Водій',
      'Document title': 'Назва документа',
      'Document status': 'Статус документа',
      'Document group name': 'Назва групи документів',
      'Checklist point': 'Пункт чеклисту',
      document: 'документ',
      Payments: 'Платежі',
      Reassignment: 'Переуступка',
      Offset: 'Залік',
      'Disbursement BL': 'Видаткова накладна',
      'Bill Of Lading': 'Коносамент',
      Model: 'Зразок',
      'Choose the block, to which this template will be attached. This means, you might use it only within this block':
        'Виберіть блок, до якого буде прикріплений цей шаблон. Це означає, щоможна використовувати його лише в цьому блоці',
      'These is the Title, only you will see, to identify current template':
        'Це назва, яку бачитимете лише Ви, щоб ідентифікувати шаблон',
      'Attach file in docx format to use it when generate a document from object; you also might use tags from the hint':
        "Завантажте файл у форматі .docx і використовуйте його для створення документа з властивостями об'єкта; можна використовувати теги з підказок",
      'Download file name template': 'Завантажити шаблон',
      'Document template': 'Шаблон документа',
      'Document templates': 'Шаблони документів',
      'Is main': 'Основний',
      'Owner content type': 'Тип вмісту для власника',
      'View {title} document checklists': 'Переглянути чек-листи документів {title}',
      'View {title} documents': 'Перегляд документів {title}',
      'Unsupported file extension. Valid extensions: ':
        'Розширення файлу не підтримується. Допустимі розширення: ',
      'Logistics mailing': 'Розсилка логістики',
      'Finances mailing': 'Розсилка інвойсів',
      Logistics: 'Логістика',
      'Bill of lading': 'Коносамент',
      Failed: 'Невдалий',
      Subject: 'Тема',
      Body: 'Тіло листа',
      'Email template group name': 'Тег',
      'Email Template': 'Шаблон email',
      'Email Templates': 'Шаблони email',
      'Email template': 'Шаблон email',
      'Export params collection': 'Параметри експорту',
      Mailing: 'Розсилка',
      'Mailing status': 'Статус розсилки',
      'Template error: {}': 'Помилка шаблону: {}',
      'Email sent successfully! Response: {}': 'Лист успішно відправлений! Відповідь: {}',
      'No permissions for changing of finance account':
        'Немає дозволу на зміну фінансового рахунку',
      'Object can not be a child of itself.': "Об'єкт може бути дочірним сам собі.",
      '{} matching query (id={}) does not exist in the database.':
        '{} відповідний запит (id={}) не існує в базі даних.',
      Paid: 'Сплачений',
      Canceled: 'Скасовано',
      Done: 'Виконано',
      Ready: 'Готово',
      Blocked: 'Заблоковано',
      Invalid: 'Недійсний',
      'Accrued interest': 'Нараховані відсотки',
      'Accrued taxes': 'Нараховані податки',
      'Subordinated loan': 'Субординований борг',
      "Shareholder's equity": 'Власний капітал',
      "Shareholder's capital": 'Акціонерний капітал',
      'Legal reserve': "Обов'язковий резерв",
      Prepay: 'Передоплата',
      Balance: 'Баланс',
      Cargo: 'Вантаж',
      Costs: 'Витрати',
      'General expenses': 'Загальні витрати',
      Gains: 'Доходи',
      Incoming: 'Вхідні',
      Outgoing: 'Вихідний',
      Expense: 'Витрати',
      Income: 'Дохід',
      Debit: 'Дебіт',
      GET: 'Отримати',
      POST: 'POST',
      HTML: 'HTML',
      XML: 'XML',
      JSON: 'JSON',
      CSV: 'CSV',
      'Will be used in calculator': 'Буде використано в калькуляторі',
      'Use Type': 'Тип використання',
      Direct: 'Прямі',
      General: 'Загальні',
      'Tariff Type': 'Тип тарифу',
      Auto: 'Авто',
      Railway: 'Залізниця',
      Freight: 'Фрахт',
      Financing: 'Фінансування',
      Insurance: 'Страхування',
      Unloading: 'Розвантаження',
      'Chose specific tariff type.': 'Виберіть тип тарифу.',
      Storage: 'Зберігання',
      Receiving: 'Отримання',
      Drying: 'Сушіння',
      Cleaning: 'Очищення',
      Loading: 'Завантаження',
      'Chose specific tariff to map it with this charge.':
        "Виберіть тип тарифу, щоб зв'язати його з цією витратою.",
      'Choose the type of counterparty, to which this charge will be\n            linked to.':
        "Виберіть тип контрагента, до якого ця витрата буде прив'язана.",
      'Use this charge as passport_multiplicator for passport':
        'Використовуйте цю витрату як паспорт_мультиплікатор для паспорта',
      'Finance account': 'Фінансовий рахунок',
      'Include in financing': 'Включити у фінансування',
      'Is analytic use': 'Аналітичне використання',
      'HS-Code': 'Код HS',
      Charges: 'Витрати',
      'General expenseses': 'Загальні витрати',
      'Other activity': 'Інше призначення',
      'Other activities': 'Інші види діяльності',
      'Invoice #': 'Інвойс №',
      'Date of receiving': 'Дата отримання',
      'Date of certification': 'Дата сертифікації',
      'Date of payment (plan)': 'Дата оплати (план)',
      'Date of payment': 'Дата оплати',
      'Date of withdrawal': 'Дата вилучення',
      'Number of days for payment': 'Кількість днів для оплати',
      'Counterpaty payment info': 'Інфо про оплату контрагента',
      Condition: 'Умова',
      'Own money': 'Власні кошти',
      'Amount USD': 'Сума, USD',
      'Additional currency': 'Додаткова валюта',
      'Additional exchange rate': 'Додатковий курс обміну',
      'Payment rate': 'Курс оплати',
      'Delivery status': 'Статус доставки',
      'Cargo not delivered': 'Вантаж не доставлений',
      'Role from': 'Роль від',
      'Role to': 'Роль до',
      'Not pay': 'Не платити',
      'Secret Invoice': 'Секретний інвойс',
      'Don`t validate counterparties': 'Не перевіряти контрагентів',
      'Related credit note': 'Пов’язаний кредит ноут',
      'Marine Cover Policy No.': 'Номер морського полісу страхування.',
      'Letter of Credit No.': 'Номер листа акредитиву.',
      Assignment: 'Завдання',
      'Commercial Invoice': 'Комерційний інвойс',
      'Proforma Invoice': 'Проформа інвойс',
      'Debit Note': 'Дебет-нота',
      'Credit Note': 'Кредит-нота',
      'Packing List': 'Пакувальний лист',
      'Related invoice': 'Пов’язаний інвойс',
      Marks: 'Відмітки',
      'PO number': 'PO номер',
      'Note one': 'Примітка 1',
      'Note two': 'Примітка 2',
      Invoices: 'Інвойси',
      'Can see and create secret invoice': 'Можна переглядати та створювати секретні інвойси',
      'Amount alternative': 'Сума альтернативна',
      'Currency alternative': 'Альтернативна валюта',
      'Exchange rate alternative': 'Альтернативний курс обміну валют',
      'Commission USD': 'Комісія, USD',
      'Trade Rate': 'Обмінний курс',
      'Bank operation': 'Банківська операція',
      CreditNote: 'Кредит ноут',
      'Debit finance account': 'Дебит фінансового рахунку',
      'Credit finance account': 'Кредит фінансового рахунку',
      'Commission debit finance account': 'Комісія дебит фінансового рахунку',
      'Commission credit finance account': 'Комісія кредит фінансового рахунку',
      'Payment status': 'Статус оплати',
      'No single vessel with charter party date': 'Жодного корабля з датою чартеру',
      PaymentPlan: 'План оплати',
      'Can approve Payment plan': 'Має повноваження підтверджувати Плани оплат',
      'Invoice value': 'Інвойс сума',
      'Payment Plan Finance': 'План оплати Фінанси',
      'Symbol/Code': 'Символ/код',
      'Is local': 'Локальна',
      Currencies: 'Валюти',
      Rate: 'Курс обміну',
      'Load source': 'Завантажити джерело',
      'Weighted AVG from payments': 'Середньозважений курс по платежам',
      'Max retries count': 'Максимальна кількість повторних спроб',
      'Retry interval step in minutes': 'Інтервалу повтору у хвилинах',
      'Currency parser retry policy': 'Політика повтору парсера валюти',
      'Next parse rule': 'Наступне правило парсингу',
      'Parse rule title': 'Назви правил парсингу',
      'Parse data type': 'Тип даних для парсингу',
      'Parse rule': 'Правило парсингу',
      'Response parse arguments': 'Аргументи парсингу відповіді',
      'Currency parse rule': 'Парсити правило для валюти',
      'Currency parse rules': 'Парсити правила для валют',
      'Retry policy': 'Політика повторної спроби',
      'Parser label': 'Лейбл парсера',
      'Request url': 'URL-запит',
      'Request method': 'Метод запиту',
      'Request headers': 'Назва запиту',
      'Request body': 'Тіло запиту',
      'Parse Time': 'Час парсингу',
      'Is the reverse course': 'Зворотній курс',
      'Currency parser': 'Парсер валют',
      'Currency parsers': 'Парсери валют',
      Days: 'Дні',
      'payment condition': 'умова оплати',
      'Invoice type': 'Тип інвойсу',
      'Balance payments': 'Балансові платежі',
      Account: 'Рахунок',
      'Bank name Eng': 'Назва банку (англ)',
      'Bank code': 'Код банку',
      IBAN: 'IBAN',
      Swift: 'Swift',
      'Contact info': 'Контакт інфо',
      'Address Eng': 'Юридична адреса (англ)',
      'Corr bank': 'Банк-кореспондент',
      'Corr IBAN': 'IBAN банку-кореспондента',
      'Corr swift': 'Swift банку-кореспондента',
      'Corr account': 'Рахунок банку-кореспондента',
      'Corr bank 1': 'Банк-кореспондент 1',
      'Corr IBAN 1': 'IBAN 1 банку-кореспондента',
      'Corr SWIFT 1': 'SWIFT 1 банку-кореспондента',
      'Corr Account 1': 'Рахунок 1 банку-кореспондента',
      'Corr bank 2': 'Банк-кореспондент 2',
      'Corr IBAN 2': 'IBAN 2 банку-кореспондента',
      'Corr SWIFT 2': 'SWIFT 2 банку-кореспондента',
      'Corr Account 2': 'Рахунок 2 банку-кореспондента',
      'Account currency': 'Валюта рахунку',
      'payment information': 'інформація про оплату',
      'Payment information': 'Інформація про оплату',
      Cash: 'Готівка',
      Regular: 'Звичайний',
      Cryptocurrency: 'Криптовалюта',
      'Account type': 'Тип рахунку',
      'Bank name': 'Назва банку',
      'Account name': 'Назва рахунку',
      'Initial cash': 'Початкова сума',
      'Account initial date': 'Дата створення рахунку',
      'Payment Info': 'Інформація про оплату',
      'Bank account': 'Банківський рахунок',
      'Bank accounts': 'Банківські рахунки',
      'Can change initial amount': 'Можлива зміна початкової суми',
      Passive: 'Пасив',
      'Payment type': 'Тип оплати',
      'Finance Account': 'Фінансовий рахунок',
      'Can configurate finblock entries': 'Можна налаштувати записи фінблока',
      Description: 'Опис',
      'Debit account': 'Дебет рахунок',
      'Credit account': 'Кредит рахунок',
      Use: 'Використання',
      InvoicePosition: 'Інвойс позиція',
      Quantity: 'Кількість',
      'Transation date': 'Дата транзакції',
      'From account': 'З рахунку',
      'From counterparty': 'Від контрагента',
      'To counterparty': 'До контрагента',
      'To account': 'На рахунок',
      'Invoice position': 'Інвойс позиція',
      'Credit bank account': 'Кредит банківський рахунок',
      'Debit bank account': 'Дебет банківський рахунок',
      'Use convertation': 'Використати конвертацію',
      'Transaction number #': 'Номер транзакції №',
      'Transaction name': 'Назва транзакції',
      'Simple fee': 'Проста комісія',
      'Complex fee': 'Складна комісія',
      'Operation Type': 'Тип операції',
      Counterparty: 'Контрагент',
      'Related counterparty': 'Пов’язаний контрагент',
      'Issuance Fee Type': 'Тип комісії за видачу',
      'Issuance Fee Persent': 'Відсоток комісії за видачу',
      'Cash back': 'Кешбек',
      'Cachback amount': 'Сума кешбеку',
      'Issuance Fee': 'Комісія за видачу',
      'Credit note #': 'Кредит ноут  #',
      Liabilities: "Зобов'язання",
      Role: 'Роль',
      'Secret credit note': 'Секретний кредит ноут',
      'credit note': 'кредит ноут',
      'Credit notes': 'Кредит ноути',
      'Credit note': 'Кредит ноут',
      'credit note position': 'кредит ноут позиція',
      'Credit note positions': 'Кредит ноут позиції',
      Month: 'Місяць',
      'Budget Item': 'Бюджет',
      'Has docs copy': 'Отримано копію документів',
      'Has docs original': 'Отримано оригінал документів',
      'Tax return registered': 'Податкову декларацію зареєстровано',
      'SAP registered': 'SAP зареєстровано',
      'Amount correction': 'Виправлення суми',
      'Reassignment rate': 'Ставка переуступки',
      'Parent Reassignment': 'Переуступка (основна)',
      Reassignments: 'Переуступка',
      'Disbursement bill or lading': 'Видаткова накладна',
      'Reassignment amount': 'Сума переуступки',
      'Reassignment Position': 'Переуступка позиція',
      'Reassignment Positions': 'Переуступка позиції',
      Offsets: 'Заліки',
      'Finance offset': 'Фінансовий залік',
      DisbursementBL: 'Видаткова накладна',
      'Amount available': 'Доступна сума',
      'Offset Position': 'Позиція заліку',
      'Offset Positions': 'Позиціїї заліку',
      'Finance Purpose': 'Фінансова ціль',
      'Finance Purposes': 'Фінансова цілі',
      Lender: 'Кредитор',
      Borrower: 'Позичальник',
      'Margin, %': 'Маржа, %',
      'Libor, %': 'Libor, %',
      'Related fees': "Пов'язані комісії",
      Notes: 'Примітки',
      'Collateral, %': 'Застава, %',
      'Finance purpose': 'Фінансова мета',
      'Related deal': "Пов'язана угода",
      'Destination country': 'Країна призначення',
      'Start date (plan)': 'Дата початку (план)',
      'Maturity date (plan)': 'Дата погашення (план)',
      'Maturity date': 'Дата погашення',
      'Interest payment date (plan)': 'Дата виплати відсотків (план)',
      'Interest payment date': 'Дата виплати відсотків',
      Loan: 'Позика',
      Loans: 'Позики',
      'Bank Group': 'Група банків',
      'Finance Bank': 'Фінансовий банк',
      'Finance Banks': 'Фінансові банки',
      'Finance Bank Group': 'Фінансова група банків',
      'Finance Bank Groups': 'Фінансові групи банків',
      'Invoice Position Config': 'Конфігурація позиції інвойса',
      'Actual date': 'Фактична дата',
      mount: 'сума',
      'Bank Account`s rests': 'Залишки на банківському рахунку',
      'contr.': 'контр.',
      'inv.': 'інв.',
      '"Use" filter value is empty or improper.':
        'Значення фільтра "Використовувати" порожнє або неправильне.',
      AVG: 'AVG (середнє значення)',
      Save: 'Зберегти',
      Delete: 'Видалити',
      'Save as new': 'Зберегти як новий',
      'Save and add another': 'Зберегти та додати новий',
      'Save and continue editing': 'Зберегти та продовжити редагування',
      'Test parser': 'Перевірка парсера',
      'created new Invoice': 'створив новий інвойс',
      'Invoice date': 'Інвойс дата',
      'Rate must be greater than 0': 'Ставка має бути більше 0',
      'You cant create rate for default currency':
        'Для валюти за замовчуванням не можна створити курс',
      'Amount must be not 0': 'Сума не може бути 0',
      'Too long value': 'Занадто довге значення',
      "You can't to pay more than invoice's amount. PAYMENT_DOES_NOT_EXCEED_INVOICE is True":
        'Ви не можете сплатити більше суми інвойсу. Увімкнено налаштування PAYMENT_DOES_NOT_EXCEED_INVOICE',
      'Contracts in positions must be with the same counterparties.':
        'Контракти в інвойс позиції повинні містити однакових контрагентів.',
      'Invoice position with this use must have contract_id or passport_id':
        'Інвойс позиція із цим використанням повинна мати contract_id або passport_id',
      'Plan amount sum exceeds invoice amount.': 'Сума плану перевищує суму рахунку.',
      'Payment plan date cannot be earlier than issuance date':
        'Дата планованої оплати не може бути раніше дати видачі',
      'This supplier is not a distributor.': "Цей постачальник не є дистриб'ютором.",
      'You can`t edit Invoice Position with closed contract.':
        'Не можна редагувати позицію інвойсу за закритим контрактом.',
      'You can`t change use of Invoice Position with use costs or gains.':
        'Не можна змінити призначення інвойс позиції за допомогою доходів або витрат.',
      "Wrong use 'costs' with invoice type 'outgoing'":
        'Неправильне використання «витрат» із типом інвойсу «вихідний»',
      "Wrong use 'gains' with invoice type 'incoming'":
        'Неправильне використання "доходів" із типом інвойсу вхідний',
      'Duplicated scenarios.': 'Дубльовані сценарії.',
      'Warehouse receipts have different volume with disbursement':
        'Складські квитанції мають різний обʼєм з ВН',
      'Warehouse receipts have different date with disbursement':
        'Складські квитанції мають різні дати з ВН',
      'Warehouse receipts have different terminal with disbursement':
        'Складські квитанції мають різний термінал з ВН',
      'You can`t create Finance with negative Full or Invoiced amount.':
        'Ви не можете створити фінансування з від’ємною повною або виставленою сумою.',
      '{role} {name} is not approved (or approved condition)':
        '{role} {name} не підтверджена (або підтверджена з умовою)',
      '{role} {name} has status - closed': '{role} {name} має статус «закрито»',
      'Main Bank Account for this currency already exists':
        'Основний банківський рахунок для цієї валюти вже існує',
      'The Bank Account is required. The bank account of the owner-company\n                     must be indicated on each invoice. Please, check your invoices.':
        'Необхідний банківський рахунок. Рахунок власника компанії \n                     повинен бути вказаний в кожному інвойсі. Будь ласка, перевірте свої інвойси.',
      "Amount by client can't be less zerro.\n                     Please, check Payment Plan positions.":
        'Сума від клієнта не може бути менше нуля.\n                     Будь ласка, перевірте позиції Плану оплат.',
      weekend: 'вихідні',
      'sat-sun': 'сб-нд',
      'fri-sat': 'пт-сб',
      'sun-mon': 'нд-пн',
      Countries: 'Країни',
      'Phone code': 'Телефонний код',
      Districts: 'Райони',
      Delivery: 'Виконання',
      Weighing: 'Зважування',
      Rerouting: 'Перерозподіл',
      Actual: 'Дійсний',
      Addresses: 'Адреси',
      'Nothing to delete': 'Нічого видаляти',
      'File has already been imported': 'Файл уже імпортовано',
      'Found several vehicles with the save attributes.Please delete duplicates before import: {}':
        'Знайдено кілька транспортних засобів з однаковими характеристиками. Будь ласка, перед імпортом видаліть дублікати: {}',
      FAS: 'FAS',
      CFR: 'CFR',
      EXW: 'EXW',
      CPT: 'CPT',
      CIF: 'CIF',
      FOB: 'FOB',
      DDP: 'DDP',
      DAP: 'DAP',
      CIP: 'CIP',
      DAT: 'DAT',
      FCA: 'FCA',
      Handysize: 'Handysize',
      Handymax: 'Handymax',
      Seawaymax: 'Seawaymax',
      Aframax: 'Aframax',
      Suezmax: 'Suezmax',
      Panamax: 'Panamax',
      'Post-Panamax': 'Post-Panamax',
      Malaccamax: 'Malaccamax',
      Capesize: 'Capesize',
      'Volume (depart, t)': 'Обʼєм (відправлений, т)',
      'Volume (board, t)': 'Обʼєм (завантажений, т)',
      'Vehicle types': 'Тип транспортного засобу',
      'Vehicle (number)': 'Транспортний засіб (номер)',
      'Vehicle (number) alt': 'Транспортний засіб (причіп)',
      Revision: 'Ревізія',
      Imported: 'Імпортовано',
      'File ID': 'ID файлу',
      'English name': 'Назва (англ.)',
      'Full name (eng)': 'Повна назва (англ.)',
      'Bank details': 'Банківські реквізити',
      'Bank details changed': 'Змінено банківські реквізити',
      'Acceptance certificate': 'Сертифікат прийняття',
      'Logistic bill of lading': 'Логістика коносамента',
      'Logistic bills of lading': 'Логістика коносаментів',
      Car: 'Авто',
      Wagon: 'Вагон',
      Container: 'Контейнер',
      Vessel: 'Корабель',
      'Volume received': 'Обʼєм отриманий',
      'Volume departed': 'Обʼєм відправлений',
      'Volume departed consignment': 'Обʼєм завантажений',
      'Volume departed real': 'Обʼєм заліковий',
      'Volume boarded': 'Обʼєм завантажений (на борту)',
      Main: 'Головна',
      'Loading (date)': 'Завантаження (дата)',
      'Start usage (date)': 'Початок використання (дата)',
      'End usage (date)': 'Кінець використання (дата)',
      'Consignment (#)': 'Накладна (#)',
      'Volume (depart consignment, t)': 'Обʼєм (відправлена накладна по ттн, т)',
      'Volume (received real, t)': 'Обʼєм (отриманий реальний, т)',
      'Volume (gross, t)': 'Обʼєм (брутто, т)',
      'Crop (class)': 'Культура (клас)',
      'Exporter contract (#)': 'Контракт з експортером (#)',
      'Receiving (date)': 'Отримання (дата)',
      'Sale (date)': 'Продаж (дата)',
      'Volume (receive, t)': 'Обʼєм (отримано, т)',
      'Volume (lost, t)': 'Обʼєм (втрачено, т)',
      'Volume (trader, t)': 'Обʼєм (торговець, т)',
      'Buyer 90 invoice': 'Покупець (90%, №)',
      'Buyer 10 invoice': 'Покупець (10%, №)',
      'Railway (date)': 'Залізниця (дата)',
      'Vet (date)': 'Vet (дата)',
      'Quarantine (date)': 'Карантин (дата)',
      'Quarantine required': 'Потрібен карантин',
      'Quality (date)': 'Якість (дата)',
      'Declaration (date)': 'Декларація (дата)',
      'Broker confirmation (date)': 'Підтвердження брокера (дата)',
      'Broker confirmation required': 'Потрібне підтвердження брокера',
      'EUR 1 (date)': '1 EUR (дата)',
      'Additional document one': 'Додаткова документ (1)',
      'Additional document two': 'Додаткова документ (2)',
      'Additional document three': 'Додаткова документ (3)',
      'No balance invoice': 'Інвойс без балансу',
      'Supplier 90 invoice': 'Постачальник (90%, №)',
      'Supplier 10 invoice': 'Постачальник (10%, №)',
      Penalties: 'Штрафи',
      'Costs in USD': 'Витрати ,USD',
      'Penalties in USD': 'Штрафи , USD',
      'Usage cost': 'Вартість використання',
      'Purchase init price': 'Початкова ціна покупки',
      'Sale init price': 'Початкова ціна продажу',
      'Sale rader amount': 'Сума продажу торговця',
      'Sale price multiplicator': 'Мультиплікатор ціни продажу',
      'Loading place': 'Місце завантаження',
      'Discharging place': 'Місце розвантаження',
      'Payment date': 'Дата оплати',
      'Costs vat': 'Витрати ПДВ',
      'Penalties vat': 'Штрафи ПДВ',
      'Is FCA': 'FCA',
      'Sale payment conditions': 'Продаж умови платежу',
      'Purchase payment conditions': 'Закупівля умови платежу',
      'Result month': 'Результат за місяць',
      'Season year': 'Сезон рік',
      'Invoice position for costs': 'Інвойс позиція для витрат',
      'Cargo brand': 'Бренд вантажу',
      'BAGS WEIGHT IN (KGS)': 'ВАГА ТАРИ (КГ)',
      'NO OF BAGS': 'БЕЗ ТАРИ',
      'Estimated receiving (date)': 'Прогнозована дата отримання',
      'Unload costs per t': 'Вартість розвантаження на 1 тонну',
      'Unload costs unit': 'Одиниця витрат на розвантаження',
      'Use unload costs': 'Використати витрати розвантаження',
      'Forwarder contract': 'Контракт на перевезення',
      'Buyer invoice volume field': 'Обʼєм інвойсу покупця',
      'Supplier invoice volume field': 'Обʼєм інвойсу постачальника',
      'Loaded on board': 'Завантажено на борт',
      'Purpose invoicing': 'Цільове виставлення інвойсу',
      'Supplier invoiced': 'Постачальник виставив інвойс',
      'Supplier paid': 'Постачальник сплатив',
      'Buyer invoiced': 'Покупець виставив інвойс',
      'Buyer paid': 'Покупець сплатив',
      'Transloader invoiced': 'Перевалка інвойс',
      'Transloader paid': 'Перевалка оплачена',
      'Vehicle type': 'Тип ТЗ',
      'Driver first name': 'Імʼя водія',
      'Driver last name': 'Прізвище водія',
      'Driver license number': 'Водійське посвідчення',
      'Driver middle name': 'По батькові водія',
      'Driver passport seria & number': 'Серія та номер паспорту водія',
      'Driver phone number': 'Номер телефону водія',
      'Deliverer in port': 'Експедитор в порту',
      Transport: 'Транспорт',
      'Can view logistic certs': 'Перегляд сертифікатів логістики',
      'Can view logistics purchase price': 'Перегляд закупівельної ціни у транспорті',
      'Can view logistics sale price': 'Перегляд продажної ціни у транспорті',
      'Can view logistics finance tab': 'Перегляд вкладки фінансування логістики',
      'Can view logistics export tab': 'Можливість переглядати вкладку експорту логістики',
      'Can view logistics road tab': 'Перегляд вкладки дорога логістики',
      'Can view logistics quality tab': 'Перегляд вкладки якості логістики',
      'Can view logistics full tab': 'Перегляд вкладки повної логістики',
      'Can view logistics rentability': 'Перегляд рентабельністі логістики',
      'Terminal Transshipment': 'Термінал перевалки',
      'Date from': 'Дата з',
      deliverer: 'експедитор',
      'Departure station': 'Станція відправлення',
      'Destination station': 'Станція призначення',
      farm: 'господарство',
      'Daily tariff': 'Добовий тариф',
      'Transportation tariff': 'Транспортний тариф',
      'Transportation tariffs': 'Транспортні тарифи',
      'Can view Tariffs': 'Перегляд тарифів',
      'Can change Tariffs': 'Можлива зміна тарифів',
      Station: 'Станція',
      'Price in USD': 'Ціна USD',
      'Transshipment tariff': 'Перевалка тариф',
      'Transshipment tariffs': 'Перевалка тарифи',
      Freights: 'Фрахти',
      'Basis code name for calculation purposes': 'Код базису для розрахунків',
      'Premium bonus percentage': 'Відсоток преміум бонусу',
      basis: 'базис',
      'Name (short)': 'Назва (скорочено)',
      'Prices priority': 'Пріоритет цін',
      port: 'порт',
      IMO: 'IMO',
      Age: 'Вік',
      'Owner info': 'Дані власника',
      Flag: 'Прапор',
      'Capacity avg': 'Середня вмістимість',
      'Capacity min': 'Мін. вмістимість',
      'Capacity max': 'Макс. вмістимість',
      'Marine Traffic link': 'Посилання на Marine Traffic',
      'vessel size': 'розмір судна',
      Vessels: 'Кораблі',
      'POL under way': 'POL в процесі',
      'At loading_port': 'В порту погрузки',
      Loaded: 'Завантажено',
      'POD under way': 'POD в процесі',
      'At discharging port': 'В порту розвантаження',
      Discharging: 'Розвантажується',
      Discharged: 'Розвантажений',
      'Voyage name': 'Назва рейсу',
      'Charter party (date)': 'Чартер-партія (дата)',
      'Freighted volume (t)': 'Фрахт обʼєм (т)',
      'Disponent owner': 'Диспонент-власник',
      Charterer: 'Фрахтувальник',
      'Laycan (start date)': 'Laycan (початкова дата)',
      'Laycan (end date)': 'Laycan (кінцева дата)',
      'Extension (start date)': 'Продовження (початкова дата)',
      'Extension (end date)': 'Продовження (кінцева дата)',
      'Sale contracts': 'Продажні контракти',
      'Date of loading start': 'Дата початку завантаження',
      'Date of loading end': 'Дата завершення завантаження',
      'Date of notice loading': 'Повідомлення про дату завантаження',
      'Date of berth loading': 'Дата завантаження на причал',
      ETA: 'ETA',
      ETB: 'ETB',
      ETC: 'ETC',
      ETS: 'ETS',
      'Loading port': 'Порт завантаження',
      'Load rate intermediate': 'Проміжна норма завантаження',
      'Date of loading start intermediate': 'Початкова дата проміжного завантаження',
      'Date of loading end intermediate': 'Кінцева дата проміжного завантаження',
      'Date of notice loading intermediate': 'Повідомлення про дату проміжного завантаження',
      'Date of berth loading intermediate': 'Дата проміжного завантаження на причал',
      'Loaded volume (t) intermediate': "Проміжний завантажений об'єм (т)",
      'ETA intermediate': 'ETA проміжна',
      'ETB intermediate': 'ETB проміжна',
      'ETC intermediate': 'ETC проміжна',
      'ETS intermediate': 'ETS проміжна',
      'Loading port intermediate': 'Порт завантаження (проміжний)',
      'Disch rate intermediate': 'Норма розвантаження проміжна',
      'Date of discharging start intermediate': 'Дата початку розвантаження (проміжна)',
      'Date of discharging end intermediate': 'Дата завершення розвантаження (проміжна)',
      'Date of notice destination intermediate': 'Повідомлення про дату розвантаження (проміжна)',
      'Date of berth destination intermediate': 'Дата проміжного розвантаження на причал',
      'Volume discharged intermediate': 'Обʼєм розвантажений (проміжний)',
      'Destination port': 'Порт призначення',
      'Disch rate': 'Норма розвантаження',
      'Date of discharging start': 'Дата початку розвантаження',
      'Date of discharging end': 'Дата завершення розвантаження',
      'Date of notice destination': 'Повідомлення про дату розвантаження',
      'Date of berth destination': 'Дата причалу (розвантаження)',
      'Shortage rate': 'Shortage ставка',
      'Demurrage rate': 'Demurrage ставка',
      'Freight costs': 'Фрахт витрати',
      'Freight info': 'Інформація про фрахт',
      'Freight broker costs': 'Фрахт брокерські витрати',
      'Loading dem/dis': 'Завантаження dem/dis',
      'Destination dem/dis': 'Призначення dem/dis',
      'Additional costs': 'Додаткові витрати',
      'Consecutive voyages': 'Послідовні рейси',
      'Do not use in Freight position': 'Не використовувати фрахт позицію',
      'Delivery condition': 'Умови доставки',
      'Intermediate delivery condition': 'Проміжні умови доставки',
      'Destination delivery condition': 'Умови доставки в пункт призначення',
      'Intermediate destination delivery condition':
        'Умова доставки до проміжного пункту призначення',
      'Free days JCC': 'Безкоштовні дні JCC',
      'Free days EWRP': 'Безкоштовні дні EWRP',
      'Volume intake (t)': 'Об’єм надходження (т)',
      'Capacity est': 'Орієнтовна місткість',
      'Tolerance, %': 'Толеранс, %',
      'Loaded volume (t)': 'Завантажений обʼєм (т)',
      'Volume discharged': 'Обʼєм розвантажений',
      charterer: 'фрахтувальник',
      'Voyage Few Commodity': 'Рейс з декількома товарами',
      'Use as cost': 'Використати як витрату',
      'Voyage Tariff': 'Тариф на рейс',
      'Voyage Tariffs': 'Тарифи на рейси',
      'Name (long)': 'Назва (повна)',
      terminal: 'термінал',
      Direction: 'Напрямок',
      station: 'станція',
      Stations: 'Станції',
      'Logistic loss': 'Логістичні втрати',
      'Logistic losses': 'Логістичні втрати',
      'Place of destination': 'Місце призначення',
      'Places of destination': 'Місця призначення',
      'Unloading volume (t)': 'Об’єм розвантаження (т)',
      Carrier: 'Перевізник',
      LOI: 'LOI',
      'Can create bills of lading from logistics': 'Можна створювати коносаменти з логістики',
      'Ship classification code name for idintification purposes':
        'Код класифікації корабля для ідентифікації',
      'Ships classification': 'Класифікація кораблів',
      Vet: 'Vet',
      Quarantine: 'Карантин',
      Declaration: 'Декларація',
      'EUR.1': '1 EUR',
      'Broker confirmation': 'Підтвердження брокера',
      'Document one': 'Документ один',
      'Document two': 'Документ два',
      'Document three': 'Документ три',
      Document: 'Документ',
      'Type of certificate document': 'Тип сертифіката',
      'Logistic Certificate Document': 'Сертифікат логістики',
      'Logistic Certificate Documents': 'Сертифікати логістики',
      'Transloaded date': 'Перевалка дата',
      'Transloaded volume': 'Обʼєм перевалки',
      'Intermodal Transport': 'Проміжний транспорт',
      'Intermodal Transports': 'Проміжний транспорт',
      'Sample In date': 'Дата отримання зразка',
      'Sample in no.': 'Номер зразка.',
      'Sample type': 'Тип зразка',
      Preshipment: 'Попереднє відвантаження',
      Stocklot: 'Залишкова партія',
      'Sample out date': 'Дата видачі зразків',
      'Sample out from': 'Зразок із',
      'Quantity (MT)': "Об'єм (т)",
      Received_date: 'Дата отримання',
      receiver: 'отримувач',
      Courier: 'Кур’єр',
      'AWB No.': 'AWB номер.',
      'Result status': 'Статус виконання',
      Undefined: 'Невизначено',
      Result_date: 'Дата виконання',
      'Reason for rejection': 'Причина відхилення',
      'Cargo Receiver': 'Отримувач вантажу',
      'Receiver code': 'Код отримувача',
      'Shipment Shedule': 'Графік відвантаження',
      'Shipment Shedules': 'Графік відвантаження',
      CTL: 'CTL',
      Own: 'Власний',
      Leased: 'Орендовано',
      'Shipment Schedule': 'Графік відвантаження',
      'Cargo Shipper': 'Вантажовідправник',
      'rolling stock type': 'тип Rolling складу',
      'Shipment Shedule Position': 'Позиція графіка відвантаження',
      'Shipment Shedule Positions': 'Позиції графіка відвантаження',
      'Shipment Schedule Position': 'Позиція графіка відвантаження',
      Day: 'День',
      Requested: 'Запрошено',
      Shipped: 'Відправлено',
      'Shipment Schedule Position Day': 'День позиції графіка відвантаження',
      'Shipment Schedule Position Days': 'Дні позицій графіка відвантаження',
      'Vehicle subtype': 'Тип транспортного засобу',
      'Date of issuance': 'Дата видачі',
      'Border Crossing Date': 'Дата перетину кордону',
      Overdue: 'Протермінований',
      'Declaration Type': 'Декларація (тип)',
      Import: 'Імпорт',
      Transit: 'Транзит',
      'Chose specific declaration type.': 'Виберіть конкретний тип декларації.',
      'Periodic customs declaration': 'Періодична митна декларація',
      'cargo customs declaration': 'митна декларація',
      'Chose specific stage': 'Виберіть етап',
      'Write offs %': 'Списання відсотків',
      'Costs amount': 'Сумма витрати',
      'Driver first name (ENG)': 'Імʼя водія (анг)',
      'Driver last name (ENG)': 'Прізвище водія (анг)',
      'Driver passport seria & number (Ukrainian)': 'Серія та номер паспорта водія (укр.)',
      'Driver license expiration date': 'Посвідчення водія, дійсний до',
      'Driver passport expiration date': 'Паспорт водія, дійсний до',
      'Driver passport issue date': 'Дата видачі паспорта водія',
      Drivers: 'Водії',
      Trailer: 'Причіп',
      'Vehicle registration number': 'Реєстраційний номер ТЗ',
      'Vehicle brand': 'Марка ТЗ',
      'Vehicle model': 'Модель ТЗ',
      'Vehicle technical passport number': 'Номер техпаспорту ТЗ',
      'Vehicle length': 'Довжина ТЗ',
      'Vehicle width': 'Ширина ТЗ',
      'Vehicle altitude': 'Висота ТЗ',
      'Vehicle unladen weight': 'Вага без навантаження',
      'Vehicle maximum weight': 'Загальна к-ть секцій, шт',
      'Vehicle total number of sections': 'Загальна к-ть секцій ТЗ',
      'Vehicle general frame': 'Загальна місткість ємності',
      Vehicles: 'Транспортні засоби',
      Warehouse: 'Склад',
      'Duplicated Logistic Ids: {}, Values: {}': 'Дубльований трансопрт: {}, значення: {}',
      "You can't import with executed contracts: {}":
        'Не можна імпортувати із виконаного контракту: {}',
      "Can't convert to float value: '{}'. ZERO_ON_CONVERSE_UNITS_ERROR={}":
        "Не вдається перетворити на десяткове число: '{}'. ZERO_ON_CONVERSE_UNITS_ERROR = {}",
      'Fix validation errors': 'Виправити помилки валідації',
      'Required field.': "Обов'язкове поле.",
      'value should be greather then 0': 'сума повинна бути більша за 0',
      'Consignments deficiency.': 'Дефіцит накладних.',
      'Google Drive Import': 'Імпорт Google Диск',
      'Google Drive Clean': 'Очистка Google Диск',
      'Booking confirmation': 'Підтвердження бронювання',
      'has its docs cut-off date on': 'має кінцеву дату для документів',
      'Plans plan, start, end dates must be in one         month':
        'Дата початку та кінця повинні бути в межах         місяця',
      'Elevator tariff must be unique with crop, charge, date from.':
        'Тариф елеватора має бути унікальним із зазначенням культури, витрат та дати.',
      'Supplier nineteen invoice number field does not match with number from imported file':
        'Значення номера інвойсу на передоплату постачальника не збігається з номером імпортованого файлу',
      'Buyer nineteen invoice number field does not match with number from imported file':
        'Значення номера інвойсу на передоплату покупцю не збігається з номером імпортованого файлу',
      'Supplier ten invoice number field does not match with number from imported file':
        'Значення номера балансового інвойсу постачальника не збігається з номером імпортованого файлу',
      'Buyer ten invoice number field does not match with number from imported file':
        'Значення номера балансового інвойсу покупця не збігається з номером імпортованого файлу',
      'No sale contract specified for sale date': 'У продажному контрактні не вказана дата продажу',
      'You cannot amend logistic if balance invoice is settled':
        'Ви не можете вносити зміни в логістику, якщо балансовий інвойс - сплачено',
      'Logistic with this acceptance certificate already exists.':
        'Логістика із цим сертифікатом приймання вже існує.',
      'CCD with this acceptance certificate already exists.':
        'ВМД з цим сертифікатом прийняття вже існує.',
      'Loss should have one warehouse.': 'Збиток повинен мати один склад.',
      'Object should have one warehouse.': "Об'єкт повинен мати один склад.",
      'Loss should have same business unit as contract':
        'Збитки повинні мати  той же бізнес-юніт, що і контракт',
      'Bill of lading must have at least one contract.':
        'В коносаменті має бути вказано хоча б один контракт.',
      'Disbursement bill of lading can have only logistic or intermediatelogistic, not both.':
        'Видаткова накладна може мати або логістику, або проміжну логістику, а не обидва одночасно.',
      'Contract is required.': 'Потрібен контракт.',
      'End date must be after start date.': 'Дата закінчення повинна бути після дати початку.',
      'Can not load logistic to itself.': 'Неможливо завантажити логістику.',
      'Logistics are looped.': 'Логістика зациклена.',
      'Can`t connect to contract more volume it has':
        'З’єднання з контрактом неможливе через перевищення його ліміту обʼєму',
      'This invoice exceeds logistic volume.': 'Цей інвойс перевищує обʼєм логістики.',
      'You shoud set export contract list filter':
        'Ви повинні встановити фільтр для списку експортних контрактів',
      "We can't import finance {} w/o contract {}. You can turn it off with LOGISTICS_IMPORT_CHECK_FINANCES option":
        'Не може імпортувати інвойс {} без контракту {}. Ви можете вимкнути перевірку в налаштуваннях LOGISTICS_IMPORT_CHECK_FINANCES',
      "Volume departed ({}t) greater than supplier contract's volume ({}t) (option included). Set {} to False to prevent this validation":
        'Надісланий обʼєм ({}t) перевищує обʼєм контракту постачальника ({}t) (навіть з урахуванням опціону). Установіть для {} статус "Помилковий" , щоб запобігти цій перевірці',
      "Logistic.loading_date ({}) can't be earlier than (purchase) contract.date_of_execution ({}) and later than contract.end_of_execution ({})":
        'Дата завантаження транспортного засобу ({}) не може бути раніше дати початку закупівельного контракту ({}) і пізніше його дати закінчення ({})',
      'With lookup by vessel vessel_name and buyer_name must be set':
        'Для пошуку за кораблем необхідно вказати назву судна та ім’я покупця',
      'webhook filled': 'вебхук заповнено',
      No: 'No',
      'available in bot': 'використовувати у боті',
      'Approval created': 'Погодження створено',
      'Approval resent request': 'Запит на повторне погодження',
      'Approval updated': 'Погодження оновлено',
      'Client created': 'Контрагент створено',
      'Client updated': 'Контрагент оновлення',
      'Client status changed': 'Статус контрагента змінено',
      'Client approving finished': 'Підтвердження контрагента завершено',
      'ClientCommentary created': 'Створено коментар по контрагенту',
      'Client proxy deadline today': 'Дедлайн для клієнтського проксі сьогодні',
      'Client Update notification at notify time':
        'Сповіщення про оновлення клієнта в обрану годину',
      'Client Update created': 'Створено',
      'Client Update updated': 'Оновлено',
      'Client deleted': 'Контрагент видалення',
      'contractbase volume_estimated_open changed':
        'змінено прогнозований відкритий обʼєм на основі контракту',
      'contractbase ending_status changed': 'змінено фінальний статус контракту',
      'contractbase final_volume clicked': 'натиснуто фінальний обʼєм контракту',
      'Purchase contract created': 'Створено закупівельний контракт',
      'Purchase contract xlsx exported': 'Закупівельний контракт експортований у форматі XLSX',
      'Purchase contract approving finished': 'Завершено погодження закупівельного контракту',
      'Purchase contract updated': 'Закупівельний контракт оновлено',
      'Purchase contract deleted': 'Закупівельний контракт видалення',
      "Ticket volume exceeds indicator's free volume":
        'Обʼєм тікета перевищує вільний обʼєм індикатора',
      "Few price volume exceeds indicator's free volume":
        'Обʼєм декількох позицій перевищує обʼєм індикатора',
      'Request created': 'Тікет створено',
      'Request updated': 'Тікет оновлено',
      'Request canceled': 'Тікет скасовано',
      'Request saved': 'Тікет збережено',
      'Request approving started': 'Розпочато підтвердження тікету',
      'Request first approving finished': 'Тікет перше погодження завершено',
      'Request approving finished': 'Завершено підтвердження тікету',
      'Sale contract created': 'Створено продажний контракт',
      'Sale contract xlsx exported': 'Продажний контракт експортований у форматі XLSX',
      'Sale contract created in usd': 'Продажний контракт USD створено',
      'Sale contract approving finished': 'Завершено погодження продажного контракту',
      'Sale contract updated': 'Продажний контракт оновлено',
      'Sale contract deleted': 'Продажний контракт видалення',
      'Services contract created': 'Контракт на послуги створено',
      'Services contract approving finished': 'Завершено погодження контракту на послуги',
      'Services contract updated': 'Контракт на послуги оновлено',
      'General Agreement created': 'Генеральна угода створена',
      'General Agreement xlsx exported': 'Генеральна угода xlsx експортована',
      'General Agreement approving finished': 'Завершено погодження генеральної угоди',
      'Consignment created': 'Квитанцію створено',
      'Consignment updated': 'Складську ввитанцію оновлення',
      'Consignment deleted': 'Складську ввитанцію видалення',
      'Prices Position Indicator(MTM price) created': 'Ціна позиції (МТМ ціна) створення',
      'Prices Position Indicator(MTM price) updated': 'Ціна позиції (МТМ ціна) оновлення',
      'Prices Position Indicator(MTM price) deleted': 'Ціна позиції (МТМ ціна) видалення',
      'Export contract created': 'Експортний контракт створення',
      'Export contract updated': 'Експортний контракт видалення',
      'Export contract approving finished': 'Завершено затвердження експортного контракту',
      'Export contract approving started': 'Початок затвердження експортного контракту',
      'Intermediate contract created': 'Проміжний контракт створення',
      'Intermediate contract updated': 'Проміжний контракт оновлення',
      'Intermediate contract approving finished': 'Завершено затвердження проміжного контракту',
      'Intermediate contract approving started': 'Початок затвердження проміжного контракту',
      'Comment created': 'Коментар створено',
      'Approver commented request': 'Підтверджувач прокоментував тікет',
      'Control created': 'Контроль створено',
      'Contract charge created': 'Витрата контракту створена',
      'Contract charge updated': 'Витрата контракту оновлена',
      'Contract charge deleted': 'Витрата видалення',
      'Commodity created': 'Товар створення',
      'Commodity updated': 'Товар оновлення',
      'Commodity deleted': 'Товар видалення',
      'Document uploaded': 'Документ завантажено',
      'Passport custom status changed': 'Статус паспорта змінено',
      'Passport created': 'Паспорт створено',
      'Passport updated': 'Паспорт оновлено',
      'Passport approving finished': 'Погодження паспорта завершено',
      'Control Passport P&L': 'Контроль Паспорт PnL',
      'Passport margin changed (distinct to "Margin control")':
        'Змінено маржу паспорта (відрізняється від «контролю маржі»)',
      'Passport deleted': 'Паспорт видалення',
      'Finance approving finished': 'Погодження інвойсу завершено',
      'Finance created': 'Інвойс створено',
      'Finance updated': 'Інвойс оновлено',
      'Finance deleted': 'Інвойс видалення',
      'Payment created': 'Створено оплату',
      'Payment updated': 'Оплата оновлена',
      'Payment deleted': 'Оплата видалення',
      'PaymentPlan created': 'План оплати створено',
      'PaymentPlan updated': 'План оплати оновлено',
      'Disbusment BLS created': 'Видаткова накладна створена',
      'Disbusment BLS updated': 'Видаткова накладна оновлена',
      'Disbusment BLS deleted': 'Видаткова накладна видалення',
      'PaymentPlan approving finished': 'Підтвердження плану оплати завершено',
      'Voyage created': 'Рейс створено',
      'Voyage updated': 'Рейс оновлено',
      'Voyage ETA today': 'Очікуваний час прибуття рейсу, сьогодні',
      'VoyageFewCommodity volume_intake updated':
        'Оновлено обʼєм прийому вантажу для кількох товарів рейсу',
      'Bill Of Lading created': 'Коносамент створено',
      'Bill Of Lading updated': 'Коносамент створений',
      'Logistic import finished': 'Імпорт логістики завершено',
      'Logistic created': 'Логістика створена',
      'Logistic updated': 'Логістика завантаження',
      'Logistic deleted': 'Логістика видалення',
      'Logistic uploaded': 'Логістику завантажено',
      'Logistic canceled': 'Логістику скасовано',
      'Logistic create qualities finished': 'Логістика створена з урахуванням вимог до якості',
      'Port created': 'Порт створення',
      'Port updated': 'Порт оновлення',
      'Port deleted': 'Порт видалення',
      'Terminal created': 'Термінал створення',
      'Terminal updated': 'Термінал оновлення',
      'Terminal deleted': 'Термінал видалення',
      'Warehouse loss created': 'Втрати на складі створення',
      'Warehouse loss updated': 'Втрати на складі оновлення',
      'Warehouse loss deleted': 'Втрати на складі видалення',
      'Operation created': 'Операція створена',
      'User custom event': 'Спеціальна подія користувача',
      'Customs cargo declaration created': 'МД створення',
      'Customs cargo declaration updated': 'МД оновлення',
      'Customs cargo declaration deleted': 'МД видалення',
      'Notification bot': 'Бот для Нотифікацій',
      'Client bot': 'Клієнт бот',
      '💬 Add comment': '💬 Додати коментар',
      '✅Approve/❌Decline': '✅Approve/❌Відхилити',
      '🔗 Open in browser': '🔗 Відкрити в браузері',
      'Enable/Disable subscription': 'Увімкнути/Вимкнути нотифікацію',
      'Append objects url to body text':
        "Додайте гіперпосилання об'єкта (URL-адресу) до основного тексту",
      'Include Approve/Decline action buttons': 'Додати кнопки Підтвердити/Відхилити',
      'POST http request with template as application/json':
        'POST запит HTTP з використанням шаблону у форматі application/json',
      'Invalid JSON: {e}': 'Неправильний JSON: {e}',
      "Header values can't contain newlines": 'Заголовки не можуть містити нові рядки',
      'Telegram bot': 'Telegram бот',
      'Enable/Disable chat': 'Увімкнути/вимкнути підписку',
      'Read-only field, will be filled automatically':
        'Поле лише для читання, буде заповнено автоматично',
      'User agreement': 'Угода з користувачем',
      'Chat language': 'Мова програми',
      'Chat user role': 'Роль учасника чату',
      Manager: 'Менеджер',
      'Clients bot': 'Бот для клієнтів',
      'Clients bots': 'Боти для клієнтів',
      'Notifications bot': 'Бот для повідомлень',
      'Notifications bots': 'Боти для повідомлень',
      Create: 'Створити',
      Edit: 'Редагувати',
      'Final confirmation': 'Остаточне погодження',
      'Custom status change': 'Зміна статусу',
      Info: 'Інфо',
      Warning: 'Увага',
      Error: 'Помилка',
      'Quality comment': 'Коментар по якості',
      'Upload instruction URL': 'URL інструкцій',
      'Upload instruction text': 'Завантажити інструкції',
      'report url': 'url звіту',
      'User with this username does not exist.':
        'Кабінет користувачу вже створено для цього контакту.',
      'Multiple users with this username exist.':
        'Існують кілька користувачів з таким же ім’ям користувача.',
      'User does not exist': 'Користувач не існує',
      'Multiple users with this Telegram username exist':
        'З цим нікнеймом Telegram існує декілька користувачів',
      Default: 'За замовчуванням',
      Authorization: 'Авторизація',
      'Telegram user name': 'Нікнейм Telegram',
      'Phone number': 'Номер телефону',
      'Wrong phone number format!': 'Невірно введено номер телефону!',
      Language: 'Мова',
      Lookup: 'Пошук',
      'Current client': 'Поточний контрагент',
      Notifications: 'Сповіщення',
      'My notifications': 'Мої сповіщення',
      'Subscribe notifications': 'Підписка на сповіщення',
      'Plus for price': 'Плюс до ціни',
      Operations: 'Операція',
      'Create application': 'Створити заявку',
      'Please, input number!!': 'Будь ласка введіть числове значення (т)!!',
      'Upload volume greater register volume more then +15%':
        'Вага вивантаження перевищує граничну вагу (до +15%) попередньо зареєстрованого ТЗ',
      'Open contract volume less then you try to upload':
        'Відкритий обʼєм контракта менше ніж обʼєм вивантаження',
      'Volume can`t be less ZERO!!': 'Обʼєм не може бути менше нуля!!',
      "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_received or 0)) %}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}":
        "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_received or 0)) %}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%} залишок: {{volume_format | format(volume | float)}}{{_('mt')}}",
      'Transport upload': 'Вивантаження транспорту',
      'Create client': 'Створити контрагента',
      'Company code': 'Код компанії',
      Roles: 'Ролі',
      'The Opened Ticket': 'Відкритий тікет',
      'Open contract volume less then you try to register':
        'Відкритий обʼєм контракта менше ніж ви реєструєте для відправки',
      'You try to register too big volume': 'Надмірно великий обʼєм',
      'The expected delivery date is not included in the delivery period according to the contract':
        'ОЧІКУВАНА ДАТА ПОСТАВКИ НЕ ВКЛАДАЄТЬСЯ В ПЕРІОД ПОСТАВКИ ЗГІДНО КОНТРАКТУ',
      "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_departed or 0))%}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%}rest: {{volume_format | format(volume | float)}}{{_('mt')}}":
        "№{{instance.contract_number or instance.id}} ({{instance.conclusion_date.strftime('%d-%m-%Y')}}) {% set volume =  (instance.volume-(instance.volume_departed or 0))%}{% set volume_format = 1000 * volume % 1000 == 0 and '%.0f' or '%.3f'%} залишок: {{volume_format | format(volume | float)}}{{_('mt')}}",
      '"The car number"': '"Номер авто"',
      'was entered in an invalid format, the number of characters of the car number must be 8, the letters are Cyrillic!':
        'введено у неправильному форматі, кількість символів номеру авто повинна бути 8, літери повинні бути кирилицею!',
      '"The trailer number"': '"Номер причіпа"',
      'was entered in an invalid format, the number of characters of the trailer number must be 8 or less':
        'введено в невірному форматі, кількість символів номеру причепа має бути 8 або менше',
      '"Driver first name"': '"Імʼя водія"',
      'contains not alphabetical symbols!': 'містить символи, що не є літерами алфавіту!',
      '"Driver middle name"': '"По батькові водія"',
      '"Driver last name"': '"Прізвище водія"',
      'Driver phone number (+380...)': 'Номер телефону водія (+380...)',
      '"Driver phone number"': 'Номер телефону водія',
      'Phone number must start with "+" and contains 8-14 digits only':
        'Номер телефону має починатись з "+" і містити тільки цифри (від 8 до 14 цифр)',
      'Estimated delivery date': 'Очікувана дата доставки',
      'Transport order': 'Реєстрація транспорту',
      Duration: 'Тривалість',
      '1 day': '1 день',
      '3 days': '3 дні',
      '5 days': '5 днів',
      '7 days': '7 днів',
      '10 days': '10 днів',
      '14 days': '14 днів',
      'The Fixed Ticket': 'Фіксована заявка',
      user: 'користувач',
      phone: 'телефон',
      Reports: 'Звіти',
      'Sale report': 'Звіт по продажах',
      'Delivery report': 'Звіт про доставку',
      'Payments report': 'Звіт про оплати',
      'Not approved tickets': 'Не підтверджені заявки',
      'You have no waiting for approve tickets!!!':
        'Немає тікетів, що очікують на підтвердження!!!',
      'Rejected tickets': 'Відхилені заявки',
      'You have no rejected tickets!!!': 'Немає відхилених заявок!!!',
      'New contracts': 'Нові контракти (без виконання)',
      'You have no new contracts!!!': 'Немає нових контрактів (без виконання)!!!',
      'Contracts in process': 'Контракти в процессі виконання',
      'You have no contracts in process!!!': 'Немає контрактів в процессі виконання!!!',
      'Executed contracts': 'Виконані контракти',
      'You have no executed contracts!!!': 'Немає виконаних контрактів!!!',
      'Contracts rests': 'Залишки по контрактах',
      'Not implemented yet': 'У процесі розробки',
      Reconciliation: 'Звірка',
      Settings: 'Налаштування',
      'Accept agreement': 'Прийняти угоду',
      'Decline agreement': 'Відхилити угоду',
      'Something went wrong:\n': 'Щось пішло не так:\n',
      'Please accept the user agreement.': 'Будь ласка, прийміть угоду користувача.',
      'You have registered as an authorized person supplier ':
        'Ви зареєструвались як довірена особа постачальника ',
      'Unfortunately, we were unable to identify your account.':
        'На жаль, нам не вдалося ідентифікувати вас.',
      'Please contact your manager for additional information.':
        'Будь ласка, зверніться до вашого менеджера для отримання додаткової інформації.',
      'For continue you must fill username in your telegram account':
        'Для продовження необїідно заповнити username в вашому Telegram аккаутні',
      Back: 'Назад',
      'Build report': 'Сформувати звіт',
      Clear: 'Очистити',
      Home: 'Початок',
      Next: 'Наступний(a)',
      Prev: 'Попередній(я)',
      FIND: 'ПОШУК',
      'Clear Lookup': 'Скасувати пошук',
      'Please, input: ': 'Будь ласка, введіть: ',
      'Please, select: ': 'Будь ласка, виберіть: ',
      'Please, choose: ': 'Будь ласка, оберіть: ',
      Mo: 'Пн',
      Tu: 'Вт',
      We: 'Ср',
      Th: 'Чт',
      Fr: 'Пт',
      Sa: 'Сб',
      Su: 'Нд',
      'Something went wrong': 'Щось пішло не так',
      'Please, make your choice:': 'Будь ласка, зробіть вибір:',
      'Error occurred': 'Сталася помилка сервера. Будь ласка, зверніться до підримки',
      'Something went wrong:': 'Щось пішло не так:',
      'Please fill all fields': 'Будь ласка, заповніть всі поля',
      'A new {} has not been created. Reason:': 'Новий {} не був створений. Причина:',
      'Report config': 'Види звітів',
      'can`t get report url': 'не вдається отримати URL-адресу звіту',
      'Can`t get report file by url at bot`s report_config': 'Помилка отримання звіту з адреси',
      'Set params for report': 'Введіть параметри звіту',
      'Shipment terminal': 'Термінал відвантаження',
      'Shipment period': 'Період відвантаження',
      Totals: 'Разом',
      'Open balance (by upload)': 'Відкритий баланс (за вивантаженням)',
      'Open balance (by register)': 'Відкритий баланс (за реєстром)',
      'Transport sent': 'Транспорт відправлено',
      'Transport uploaded': 'Транспорт вивантажено',
      'Percent execution': 'Процент виконнання',
      'by the': 'від',
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`' -":
        "ґйцукенгшщзхїфівапролджєячсмитьбюҐЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮʼ' -",
      abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890:
        'ґйцукенгшщзхїфівапролджєячсмитьбюҐЙЦУКЕНГШЩЗХЇФІВАПРОЛДЖЄЯЧСМИТЬБЮ1234567890',
      'Incorrect value': 'Некоректне значення',
      'START:': 'ПОЧАТОК:',
      SUCCESS: 'УСПІШНО',
      FAIL: 'НЕВДАЧА',
      'Input your comment, please:': 'Будь ласка, введіть свій коментар:',
      'Comment was added.': 'Коментар додано.',
      'Comment was`t added.': 'Коментар не було додано.',
      'Instance not found. Please contact with your Support Manager.':
        'Екземпляр не знайдено. Будь ласка, зверніться до вашого менеджера.',
      '✅ Approve': '✅ Погодити',
      '❌ Decline': '❌ Відхилено',
      Approve: 'Підтвердити',
      Decline: 'Відхилити',
      'Chat not found. Please contact with your Support Manager':
        'Чат не знайдено. Будь ласка, зверніться до вашого менеджера',
      'Chat was deactivated. Please contact with your Support Manager.':
        'Чат відключено. Будь ласка, зв’яжіться з вашим менеджером.',
      '{telegram_chat.user.profile.user.get_full_name()}, you already have a chat with us. \nIs it you?':
        '{telegram_chat.user.profile.user.get_full_name()}, у вас вже є чат з нами . \nЦе ви?',
      'Invalid action.': 'Невірна дія.',
      was: 'був',
      'Following users have to approve object №': "Наступні користувачі мають погодити об'єкт №",
      'Following users have to approve invoice №': 'Наступні користувачі мають погодити інвойс №',
      'Approval required for the ticket №': 'Необхідне погодження для тікета №',
      'Approval required for the passport': 'Необхідне погодження для паспорта',
      'Approval required for the contract №': 'Необхідне погодження для контракту №',
      'GrainTrack. Approval required for the contract №':
        'GrainTrack. Необхідне погодження для контракту №',
      'Approval required for the client': 'Потрібне погодження контрагента',
      'Approval required for the invoice №': 'Погодження оновлено для інвойсу №',
      'Approval required for the object №': "Погодження оновлено для об'єкта №",
      'Approval updated for the ticket №': 'Погодження оновлено для тікета №',
      'Approval updated for the passport': 'Погодження оновлено для паспорту',
      'Approval updated for the client': 'Оновлення за згодою контрагента',
      'Approval updated for the invoice №': 'Підтвердження оновлено для цього інвойсу №',
      'Approval updated for the object №': "Підтвердження оновлено для цього об'єкта №",
      'Approver commented on the request №': 'Підтверджувач прокоментував тікет №',
      'GrainTrack. Final approve for the client':
        'GrainTrack. Остаточне погодження для контрагента',
      'created a new сompany': 'створено нову компанію',
      'was commented by user': 'було прокоментовано користувачем',
      'GrainTrack. Сomment was created in the  client':
        'GrainTrack. Коментар створено в контрагенту',
      'Ticket №': 'Тікет №',
      'Contract №': 'Контракт №',
      'commented by user': 'прокоментовано користувачем',
      'GrainTrack. Сomment was created in the request №':
        'GrainTrack. Коментар створений у тікету №',
      'GrainTrack. Сomment was created in the  passport':
        'GrainTrack. Коментар створено в паспорті',
      'GrainTrack. Сomment was created in the contract №':
        'GrainTrack. Коментар створено в контракті №',
      'GrainTrack. Сomment was created in the  invoice №':
        'GrainTrack. Коментар створено в інвойсі №',
      'GrainTrack. Сomment was created in the  object №':
        "GrainTrack. Коментар створено в об'єкті №",
      'GrainTrack. Final approve for the invoice №':
        'GrainTrack. Остаточне погодження для інвойсу №',
      'created new invoice': 'створено новий інвойс',
      'GrainTrack. Final approve for the passport': 'GrainTrack. Остаточне погодження для паспорта',
      'created new contract': 'створено новий контракт',
      'GrainTrack. Final approve for the contract №':
        'GrainTrack. Остаточне погодження контракту №',
      'after unloading the auto, the balance after the GCD.':
        'після розвантаження авто, баланс після НСД.',
      'GrainTrack. Final approve for the ticket №': 'GrainTrack. Остаточне погодження тікета №',
      'GrainTrack. Started approving for the ticket №': 'GrainTrack. Розпочато погодження тікета №',
      'Created a new ticket №': 'Створено новий тікет №',
      'has edited ticket': 'тікет змінений',
      'created new USD contract': 'створено новий контракт USD',
      'created a new task': 'створено нове завдання',
      'updated a task': 'оновлено завдання',
      'Field cannot contain spaces.': 'Поле не може містити пробілів.',
      'Cargo partial executed': 'Вантаж виконано частково',
      'Volume Departed': 'Обʼєм відправлений',
      'Volume Received': 'Обʼєм отриманий',
      'Volume Departed Real': 'Обʼєм заліковий',
      'Volume property': 'Показник обʼєму',
      'Current progress': 'Поточний прогрес',
      'Details of trade confirmation': 'Деталі трейдерського підтвердження',
      'Transport fact': 'Транспорт факт',
      'Charge fact': 'Витрата факт',
      'Docs fact': 'Документи факт',
      'Offers warehouse': 'Оффер для складу',
      'Warehouse deviation, %': 'Відхилення якості, %',
      'Custom margin': 'Налаштування маржі',
      'credit period (days)': 'період кредитування (днів)',
      'credit percent': 'відсоток за кредитом',
      'permanent costs': 'постійні витрати',
      taxes: 'податки',
      'vat return rate for costs': 'ставка повернення ПДВ на витрати',
      Multipassport: 'Мультипаспорт',
      Multipassports: 'Мультипаспорти',
      'Multipassport ': 'Мультипаспорт ',
      'Derivative account': 'Дериватив рахунок',
      'margin selector': 'перемикач маржі',
      tiket: 'тікет',
      contract: 'контракт',
      'contract analytical': 'аналітичний контракт',
      execution: 'виконання',
      invoice: 'інвойс',
      payment: 'оплата',
      'logistic type': 'тип логістики',
      'Execution costs from invoices': 'Враховувати інвойси по витратам у маржі за виконанням',
      'Business (date)': 'Бізнес (дата)',
      'Sap order': 'SAP ордер',
      'Volume position as aspiration': 'Обʼєм позиції як аспірація',
      'Use commodity parent match': 'Використовуйте відповідність за батьківським товаром',
      Passports: 'Паспорти',
      'Can view passport result': 'Перегляд результату паспорта',
      'Can view passport P&L': 'Перегляд PnL паспортів',
      'Can view passport recap': 'Перегляд Довідки паспорта',
      'Can view passport costs&gains': 'Перегляд витрат/доходів за паспортами',
      'Can view passport costs of goods': 'Перегляд собівартості за паспортами',
      'Can view passport execution': 'Перегляд виконання за паспортами',
      'Can view passport invoices': 'Перегляд інвойсів за паспортами',
      'Is insured': 'Застрахований',
      'This fact is done': 'Факт виконано',
      'Chain order': 'Послідовне замовлення',
      'Passport and contract have different cargo': 'Паспорт і контракт мають різні культури',
      'Ticket margin': 'Маржа тікету',
      'Ticket margin per ton': 'Маржа тікету за тонну',
      'Contract analitical margin': 'Контрактна аналітична маржа',
      'Contract margin': 'Маржа контракту',
      'Contract margin per ton': 'Маржа контракту за тонну',
      'Execution margin': 'Маржа виконання',
      'Execution margin per ton': 'Маржа виконання за тонну',
      'Invoice margin': 'Маржа інвойса',
      'Invoice margin per ton': 'Маржа інвойса за тонну',
      'Payment margin': 'Маржа платежу',
      'Payment margin per ton': 'Маржа платежу за тонну',
      'Margin Control': 'Контроль маржі',
      'Margin Controls': 'Контроль маржі',
      'Contract No.': 'Контракт №.',
      'Business unit': 'Бізнес-юніт',
      'Conclusion Date': 'Дата укладання',
      'Buyer country': 'Країна покупця',
      'Buyer ITN': 'ІПН Покупця',
      'Supplier country': 'Країна постачальника',
      'Supplier ITN': 'ІПН Постачальника',
      'Price per t, USD': 'Ціна за т, USD',
      'Currency Symbol USD': 'Символ валюти USD',
      'Price per t, local': 'Ціна за т',
      'Currency Symbol Local': 'Символ валюти',
      'Contract volume': "Об'єм контракту",
      'Incoterms Basis': 'Базис Інкотермс',
      'Derivatives margin split per contracts': 'Поділ маржі по деривативах на контракти',
      'Total Value of contracts/Total Passport margin in USD':
        'Загальна вартість контрактів/Загальна маржа паспорта в USD',
      'Total Value of contracts/Total Passport margin in local':
        'Загальна вартість контрактів/Загальна маржа паспорта',
      'Costs Plan in USD': 'План витрат, USD',
      'Costs, Invoice amount in USD': 'Витрати, сума інвойсу, USD',
      'Cost Amount per t in USD': 'Сума витрат на т в USD',
      'Invoice Costs Number': '№ Інвойса на витрату',
      'Invoice Date': 'Дата інвойсу',
      'Info about costs payments':
        'Інформація про платежі Витрати (Дата, Тип рахунку, Сума оплати)',
      'Amount in USD': 'Сума в USD',
      'Executed in MT': 'Виконано МТ',
      'Execution Value/Margin in USD': 'Вартість виконання/Маржа в USD',
      'Execution Value/Margin in local': 'Вартість виконання/Маржа',
      'BL DETAILS': 'ДЕТАЛІ',
      'self-cost': 'собівартість',
      'Invoiced Value/Margin in USD': 'Вартість інвойсів/Маржа в USD',
      'Invoiced Value/Margin in local': 'Вартість інвойсів/Маржа',
      'Invoice for cargo number.': '№ Інвойсу на вантаж.',
      'Info about cargo payments': 'Інформація про платежі Товару (Дата, Тип рахунку, Сума оплати)',
      'Info about cargo payments Non-cash': 'Інформація про безготівкові платежі за вантаж',
      'Qty MT': 'Кількість тонн',
      'Non-cash amount': 'Всього р/р',
      'Non-cash paid': 'Сплачений на р/р',
      'Payments in USD': 'Платежі в USD',
      'Payments in local': 'Платежі',
      'Non-cash payments': 'Платежі на р/р',
      'This contract cannot be connected with this volume to passport.':
        "Цей контракт не може бути зв'язаний із цим обʼємом до паспорта.",
      'Wrong contract purpose': 'Не вірна ціль контракту',
      'The number of passports is not specified, or more than five.':
        "Кількість паспортів не вказана, або більше п'яти.",
      'Coming to load': 'Прибуває на завантаження',
      'At load port / Under loading': 'У порту завантаження',
      'Sailed / under way to discharge': 'Відчалив / у дорозі на розвантаження',
      'At discharging port / under discharge': 'У порту призначення / розвантажується',
      'W/o status': 'Без статусу',
      'This number is input by hand': 'Це число вводиться вручну',
      'Cash Flows': 'Кеш-фло',
      'Can see cashflow recreation modal': 'Можна переглядати кеш-фло',
      'Can view reports': 'Перегляд звітів',
      'Can view debit-credit reports': 'Перегляд звіту по дебету та кредиту',
      'Can view position report': 'Перегляд звіту по позиціям',
      'Can view position origination report': 'Перегляда звіту про визначення позиції',
      'Can view position physical report': 'Перегляд звіту по позиціям',
      'Can view confirmations report': 'Перегляд звіту про підтвердження',
      'Can view signings report': 'Перегляд звіту про підписи',
      'Can view kpiconfirmations report': 'Перегляд звіту про підтвердження KPI',
      'Can view kpisignings report': 'Перегляд звіту про підписання KPI',
      'Can view balance report': 'Перегляд звіту про баланс',
      'Can view budgets report': 'Перегляд звіту про бюджет',
      'Can view purchase plan report': 'Перегляд звіту про план закупівлі',
      'Can view lineup report': 'Перегляд звіту Lineup',
      'Can view futures position report': "Перегляд звіту про ф'ючерсну позицію",
      'Can view certificate dates report': 'Перегляд звіту про дати сертифікатів',
      'Can view execution of loyalty programs report':
        'Перегляд звіту про виконання програм лояльності',
      'Can view Projects Status Detailed Summary Report':
        'Можна переглядати детальний підсумковий звіт про стан проектів',
      'Can view P&L report': 'Перегляд звіту PnL',
      'Can view P&L per passport report': 'Перегляд звіту PnL за паспортом',
      'Can view P&L mark to market report': 'Перегляд звіту PnL до ринкового звіту',
      'Can view forward client status mtm report': 'Можна переглядати звіт mtm форвардних угод',
      'Can view freight in trading report': 'Можливість перегляду фрахту у торговельному звіті',
      'Can view fixing/hedging in trading report': 'Перегляд звіту фіксації/хеджування',
      'Can view invoice risk in report': 'Перегляд звіту по інвойс ризикам',
      'Can view credit risk in report': 'Перегляд звіту ризиків',
      'Can view approvals report': 'Перегляд звіту погодження',
      'Can view cost report': 'Перегляд звіту витрат',
      'Can view activity in management reports': 'Перегляд звіту активність',
      'Can view reconciliation reports': 'Перегляд звіту звірки',
      'Staff report': 'Звіт по користувачам',
      User: 'Користувач',
      'Contracts count': 'Контрактів',
      'Indicators count': 'Індикаторів',
      'Tickets count': 'Кількість тікетів',
      'Clients count': 'Кількість контрагентів',
      'Roles count': 'Ролей',
      'Logistics count': 'Транспорту',
      'Plans count': 'Розрахунок плану',
      'Invoices count': 'Інвойсів',
      'Payments count': 'Платежів',
      'Passports count': 'Паспортів',
      'Potentials count': 'Розрахунок потенціалу',
      'Updates count': 'Оновлення',
      'Tasks count': 'Завдання',
      'On site seconds': 'Секунд на сайті',
      'Group By': 'Групувати за',
      'Loading / Destination': 'Місце завантаження / Призначення',
      'Vessel name': 'Назва корабля',
      TOTAL: 'ВСЬОГО',
      'Invalid date format, use YYYY-MM-DD': 'Недійсний формат дати, використовуйте РРРР-ММ-ДД',
      'VAT option': 'Є ПДВ',
      'VAT value': 'ПДВ (сума)',
      'Contract number': 'Номер контракту',
      'Date of execution': 'Дата виконання',
      'End of execution': 'Доставка (дата закінчення)',
      'Contract type': 'Тип контракту',
      'Tolerance volume': 'Толеранс, обʼєм',
      'Contract value': 'Сумма контракту',
      'Value no VAT': 'Без ПДВ',
      'Price no VAT': 'Ціна без ПДВ',
      'Tolerance plus': 'Толеранс, плюс',
      'Tolerance minus': 'Толеранс, мінус',
      'Departed plus': 'Відправлено, обʼєм',
      'Departed minus': 'Відправлено, мінус',
      'Departed value': 'Відправлено, сума',
      'Received value': 'Отримано, сума',
      'Shipment balance': 'Баланс доставки',
      'Invoiced Cargo': 'Інвойсований вантаж',
      'Paid Cargo': 'Сплачений вантаж',
      'Unpaid Cargo': 'Не сплачений вантаж',
      Signed: 'Підписано',
      'Wrong date format': 'Формат дати некоректний',
      'Start date should be less the end date': 'Дата початку повинна бути менше за кінцеву дату',
      'Upload date': 'Дата вивантаження',
      'Vehicle number': 'Номер авто',
      'Consignment number': 'Номер ТТН',
      'Declared volume': 'Обʼєм заявлений',
      'Volume accepted': 'Обʼєм прийнятий',
      'Price with discount': 'Ціна з дисконтом',
      'Amount with discount': 'Сума з дисконтом',
      Debts: 'Заборгованість',
      'Period totals data': 'Загалом за період',
      'TOTAL data': 'ВСЬОГО',
      '⚠️ ATTENTION! The "REST HOOKS" feature is being phased out. Please transition all hooks to "Event Subscriptions". This feature will be removed in version 52.23.0. ⚠️':
        '⚠️ УВАГА! Функцію «REST HOOKS» припиняють. Будь ласка, перейдіть до використання «Підписок на події» («Event Subscriptions»). Ця функція буде видалена у версії 52.23.0. ⚠️',
      Once: 'Один раз',
      'Once a day': 'Раз на добу',
      Hourly: 'Кожну годину',
      Daily: 'Кожен день',
      Weekly: 'Кожного тижня',
      Monthly: 'Щомісячно',
      Yearly: 'Кожного року',
      'Started execution': 'Початок виконання',
      'Failed execution': 'Невдале виконання',
      Stopped: 'Зупинено',
      Unknown: 'Невідомо',
      January: 'Січень',
      February: 'Лютий',
      March: 'Березень',
      April: 'Квітень',
      May: 'Травень',
      June: 'Червень',
      July: 'Липень',
      August: 'Серпень',
      September: 'Вересень',
      October: 'Жовтень',
      November: 'Листопад',
      December: 'Грудень',
      '---': '—',
      'Activate system event': 'Активувати системну подію',
      'Set contacts not updated': 'Показати контакти, які не були оновлені',
      'Reset tickets approvals': 'Скинути підтвердження тікетів',
      'Set voyage data': 'Встановити дані рейсу',
      'Close paid contractcharges': 'Закрити оплачені витрати за контрактом',
      'Update contracts ending status': 'Оновити статусти завершення контрактів',
      'Update clients statuses': 'Оновити статус контрагентів',
      'Update clients regular statuses': 'Оновити статус контрагентів',
      'Update currencies rates': 'Оновити курси валют',
      'Create quotes': 'Створити пропозиції',
      'Generate staff report': 'Сформувати звіт про персонал',
      'Update voyages statuses': 'Оновити статуси рейсів',
      'Update passports status': 'Оновити статус паспорта',
      'Create margin P&L control': 'Створити контроль маржі PnL',
      'Update passports business dates': 'Оновити бізнес-дати паспортів',
      'Parsing sstockmarket prices': 'Парсинг цін з фондового ринку',
      'Finances data sample preparing ready': 'Готові дані з фінансів для формування зразка',
      'Passports data sample preparing ready': 'Готові дані з паспортів для формування зразка',
      'Payment plans data sample preparing ready':
        'Готові дані з планів оплат для формування зразка',
      'Payments data sample preparing ready': 'Готові дані з оплат для формування зразка',
      Action: 'Дія',
      Object: 'Об’єкт',
      'Date and time at which this job is scheduled to be executed next.':
        'Дата і час наступного виконання цієї роботи.',
      'Scheduled task': 'Запланована задача',
      'Scheduled tasks': 'Заплановані задачі',
      Periodicity: 'Періодичність',
      'Repeat week day': 'День тижня для повторення',
      'Month repeat': 'Повторювати щомісяця',
      'On days': 'У дні',
      'On months': 'У місяцях',
      'Total run time of this job (in seconds).': 'Загальний час виконання (у секундах)',
      'Details of exception that occurred during job execution (if any).':
        'Деталі винятку, який виник під час виконання роботи (якщо такий є).',
      'Traceback of exception that occurred during job execution (if any).':
        'Відстеження винятку, який виник під час виконання роботи (якщо такий є).',
      'This field can`t be empty': 'Будь ласка, заповніть це поле',
      'screenshot name': 'назва знімка екрана',
      'telegram chat': 'telegram чат',
      Scale: 'Масштаб',
      'Comment for screenshot': 'Коментар до скріншоту',
      'pdf report attachment': 'вкладення звіту у форматі pdf',
      'This field must be unique.': 'Це поле повинно бути унікальним.',
      'usd/t': 'usd/т',
      'cent/b': 'цент/бушель',
      'eur/t': 'eur/т',
      Futures: "Ф'ючерси",
      Barchart: 'Гістограма',
      Agritel: 'Агрітель',
      Ticker: 'Тікер',
      'Stock Exchange': 'Біржовий курс',
      'Expiration date': 'Дата експірації',
      'A date date until which the derivative is valid': 'Дата, до якої діє дериватив',
      'Derivative type': 'Тип деривативу',
      'Derivative mode': 'Режим деривативів',
      'Derivative month': 'Місяць деривативу',
      'A month when derivative will be relevant': 'Місяць, коли дериватив буде актуальним',
      'Lot size': 'Розмір лоту',
      'Point value': 'Значення пункту',
      'Service Charge': 'Комісія за послуги',
      'Exch Fees': 'Комісії за обмін',
      'NFA Fees': 'Комісія NFA',
      'cost per lot': 'вартість 1 лоту',
      'Initial amount': 'Початкова сума',
      'Price date': 'Дата ціни',
      'A date for which the price is assigned': 'Дата, на яку призначена ціна',
      Long: 'Довгий',
      Short: 'Короткий',
      Put: 'Поставити',
      Fixing: 'Закріплення',
      Rolling: 'Rolling',
      Hedging: 'Хеджування',
      'Paper trade': 'Paper Trading',
      'Deal type': 'Тип угоди',
      'Option type': 'Тип опціону',
      'Operation type': 'Тип операції',
      'Derivative Account': 'Дериватив рахунок',
      'Quantity of lots': 'Кількість лотів',
      'Business date': 'Бізнес дата',
      'Virtual operation': 'Віртуальна операція',
      'use in position': 'включити в позицію',
      'Tariff correction': 'Корекція тарифу',
      'Charge per mt': 'Витратв за 1т',
      'Consolidated type': 'Тип консолідації',
      'Exit date': 'Дата виїзду',
      Resource: 'Завантажити джерело',
      'Parsing enabled': 'Парсинг включений',
      'Quotes Parsing Config': 'Конфігуратор парсингу котирувань',
      'Quotes Parsing Configs': 'Конфігуратор парсингу котирувань',
      Measurement: 'Одиниця виміру',
      Measurements: 'Одиниці виміру',
      coefficient: 'коефіцієнт',
      'Measurement Crop': 'Одиниця виміру культури',
      'Measurement Crops': 'Одиниці виміру культури',
      'There can be only one chosen StockMarketPrice per day and derivative':
        'Може бути обрана тільки одна ціна фондового ринку та деривативу',
      'There is no parsers for this resource': 'Для цього ресурсу немає парсерів',
      'Parsing error. No quote for this resource and ticker: ':
        'Помилка аналізу. Немає ролі для цього ресурсу та тікета: ',
      'In process': 'В процесі',
      'Exporter approval (docs sent buyer)':
        'Підтвердження експортера (документи відправлено покупцеві)',
      Additional: 'Примітка',
      Cancellation: 'Анулювання',
      'Abstract Stages': 'Анотація стадії',
      'Signature (draft)': 'Підпис (чернетка)',
      'We signed': 'Ми підписали',
      'Both signed': 'Підписано',
      'All signed': 'Усі підписали',
      'Originals received': 'Оригінали отримані',
      'Cargo confirmation': 'Підтвердження вантажу',
      'Payment confirmation': 'Підтвердження оплати',
      Graintrack: 'Graintrack',
      'Graintrack. Admin panel': 'Graintrack. Панель адміністратора',
      'Welcome,': 'Ласкаво просимо,',
      Documentation: 'Документація',
      'Change password': 'Змінити пароль',
      'Log out': 'Вийти',
      'To EXPORT something, first, choose the format of the file and then press SUBMIT button. This will save the file on your local drive ':
        "Для ЕКСПОРТУ потрібно вибрати формат файлу та натиснути кнопку ПІДТВЕРДИТИ. Файл буде збережено на комп'ютері ",
      Submit: 'Підтвердити',
      'Require to have a business unit for validation logistics, logistics contracts and logistic invoices':
        'Необхідно мати бізнес-юніт для перевірки логістики, контрактів та інвойсів',
      "Below is a preview of data to be imported. If you are satisfied with the results, click 'Confirm import'":
        'Нижче наведено попередній перегляд даних для імпорту. Будь ласка,перевірте завантажені дані. Якщо ви задоволені результатами, натисніть «Підтвердити імпорт»',
      'Confirm import': 'Підтвердити імпорт',
      'This importer will import the following fields: ': 'Цей імпортер імпортує такі поля: ',
      'You have not Business Unit. Make sure you have at least one business unit.':
        'У вас немає Бізнес-юніта. Переконайтеся, що у вас є принаймні один бізнес-підрозділ.',
      Errors: 'Помилки',
      'Line number': 'Номер рядка',
      'Some rows failed to validate': 'Деякі рядки не вдалося перевірити',
      'Please correct these errors in your data where possible, then reupload it using the form above.':
        'Будь ласка, виправте ці помилки у своїх даних, де це можливо. Потім повторно завантажте їх за допомогою форми вище.',
      Row: 'Рядок',
      'Non field specific': 'Не залежить від поля',
      Preview: 'Попередній перегляд',
      application: 'заявка',
      model: 'модель',
      Add: 'Додати',
      Change: 'Змінити',
      View: 'Переглянути',
      'Last update': 'Останнє оновлення',
      'Receiving date': 'Дата отримання',
      'Loading date': 'Дата завантаження',
      'Second name': 'Прізвище',
      'Conclusion date': 'Дата укладання',
      Line: 'Лінія',
      Bar: 'Стовпець',
      Alphabet: 'Алфавіт',
      Smart: 'Смарт',
      'By numbers': 'Числовий',
      'Broker confirm': 'Брокерське підтвердження',
      'N/A': 'N/A',
      'P&L': 'PnL',
      'Physical position + MTM': 'Фізична позиція + MTM',
      'Physical position by months': 'Фізична позиція за місяцями',
      'Position P&L + MTM': 'Позиція PnL + MTM',
      Table: 'Таблиця',
      Block: 'Блок',
      'Consignment number, vehicle number': 'Номер накладної, номер ТЗ',
      'Consignment number, vehicle number, loading date':
        'Номер накладної, номер ТЗ, дата завантаження',
      'Vehicle number, loading date': 'Номер транспортного засобу, дата завантаження',
      'Vehicle number, receiving date, custom status':
        'Номер т/з,  дата отримання, спеціальний статус',
      'Vehicle number, consignment number, receiving date, custom status':
        'Номер т/з, номер накладної, дата отримання, спеціальний статус',
      'Vehicle number, loading date, consignment number if exists':
        'Номер ТЗ, дата завантаження та номер накладної (ТТН), якщо така існує',
      KG: 'кг',
      T: 'т',
      OR: 'або',
      AND: 'і',
      'Short name': "Коротке ім'я",
      'Short title': 'Коротка назва',
      Boat: 'Корабель',
      Forbid: 'Заборонити',
      Warn: 'Попередити',
      Contracts: 'Контракти',
      YouControl: 'YouControl',
      Vkursi: 'Vkursi',
      'Fixing position': 'Фіксинг позиція',
      'Passports position': 'Паспорт позиція',
      'Accounts position': 'Позиція рахунків',
      'Calculate by passport operations': 'Розрахунок за операціями за паспортом',
      'Calculate by contract operations': 'Розрахунок за операціями за контрактом',
      'enables the education mode in the system for new users':
        'включає режим навчання в системі для нових користувачів',
      'Carbon Copy emails, comma separated': 'Копія, email - через кому',
      'Blind Carbon Copy emails, comma separated': 'Прихована копія, email - розділяти комою',
      'Carbon Copy emails, comma separated for logistics newsletter':
        'Email-адреси копій для розсилки логістики, розділені комами',
      'Carbon Copy emails, comma separated for invoice newsletter':
        'Email-адреси копій для розсилки інвойсів, розділені комами',
      'Uses for recipients filter': 'Використовується для пошуку одержувачів',
      'SMTP port. Not secure - 25, GMail - 587 or read documentation':
        'порт SMTP. Небезпечно – 25, GMail – 587 ,деталі в документації',
      'Use TLS encryption. Turn off if your SMTP provider does not support it':
        'Використовуйте шифрування TLS. Вимкніть його, якщо ваш постачальник SMTP не підтримує цю функцію',
      'Use SSL encryption. Turn on if TLS does not work.':
        'Використовуйте шифрування SSL. Ввімкніть його, якщо TLS не працює.',
      'SMTP usermane. Email sender': 'Логін SMTP. Відправник електронної пошти',
      "SMTP password. Stores encrypted. Don't edit.":
        'Пароль SMTP. Зберігається в зашифрованому вигляді. Не редагуйте.',
      'translate latin vehicle numbers to cyrillic': 'перетворити латинські номери т/з на кирилицю',
      'Auto create export custom declaration': 'Автоматичне створення експортної митної декларації',
      'default quantity days value from receiving date for search vehicles':
        'за замовчуванням кількість днів від дати отримання для пошуку т/з',
      'Check if this CONTRACT has this FINANCE': 'Перевірте, чи цей КОНТРАКТ містить ФІНАНСИ',
      'Check and remove duplicates from our database':
        'Перевірте та видаліть збіги із нашої бази даних',
      'Create qualition on import': 'Створити якість при імпорті',
      'Check if logistic volume sum less, than contract volume. YES - prevent import, NO - notification only':
        'Відмітьте, коли обʼєм логістики менший, ніж обʼєм контракту. ТАК - заборонити імпорт, НІ - лише сповіщення',
      'Check if logistic volume departed less, than contract volume and prevent import':
        'Відмітьте, коли логістичний обʼєм відправлення менший, ніж контрактний обʼєм, і бажаєте заборонити імпорт',
      'Trying to find the buyer_contract by related vessel and buyer names':
        'Спроба знайти покупця _ контракту за назвами корабля і покупця',
      'Trying to find the contract by commodity and price':
        'Намагаючись знайти контракт за товаром і ціною',
      'Creates deliverers on import': 'Створює експедитора під час імпорту',
      'Creates stations on import': 'Створює станції під час імпорту',
      'Use resource of the NBU to fetch currency': 'Використовувати парсинг валюти з НБУ',
      'Use resource of the RBC to fetch currency': 'Використовувати парсинг валюти з RBC',
      'Use resource of the nationalbank.kz to fetch currency':
        'Використовувати парсинг валюти з nationalbank.kz',
      'Parse quotes configures in quote parsing configs.':
        'Парсинг котирувань налаштовується у конфігах парсингу котирувань.',
      'Use resource of the Interbank (buy) to fetch currency':
        'Використовувати парсинг валюти міжбанк (купівля)',
      'Use resource of the Interbank (sale) to fetch currency':
        'Використовувати парсинг валюти міжбанк (продажа)',
      'Use resource of the ecb.europe to fetch currency': 'Використовувати парсинг валюти з ECB',
      'Use resource of the cbr.ru to fetch currency': 'Використовувати парсинг валюти з CBR',
      'Use resource of the nbp.pl to fetch currency':
        'Використання ресурсу nbp.pl для отримання валюти',
      'Use resource of the mnb.hu to fetch currency':
        'Використовувати ресурс mnb.hu для отримання курсу валют',
      'Use resource of the NBU to fetch currency for a next day':
        'Використовувати парсинг валюти з НБУ',
      'Use resource of the BANKOFCANADA to CAD rate':
        'Використання ресурсу курсу BANKOFCANADA до CAD',
      'enables default value for VAT': 'активує значення за замовчуванням для ПДВ',
      'Allow create customs declaration for sale c   ontracts':
        'Дозволити створення митної декларації із продажних контрактів',
      'Check to apply purchase status contract filter in warehouse':
        'Перевірте, чи встановлений фільтр за статусом закупівельного контракту в складі',
      'Allow to create tickets even if client is not approved':
        'Дозволити створювати тікети, якщо контрагент не погоджений',
      'Disable changing crop when create ticket from indicator':
        'Заборонити зміну товару при створенні тікета з індикатора',
      'Do not let to change conclusion date when create contracts':
        'Заборонити можливість зміни дати укладання при створенні контрактів',
      'Logistic Google Drive file ID':
        'Перевірте налаштування LOGISTIC_FILE_GOOGLE_ID в НАСТРОЙКАХ ОРГАНІЗАЦІЇ',
      'Intermediate logistic Google Drive file ID': 'Проміжна логістика Google Drive file ID',
      'Show opened quality in logistics table': 'Показати відкриту якість в таблиці логістики',
      'Show opened passports in conslidated profit and loss':
        'Відображати відкриті паспорти в звіті PnL',
      'Show only recent year in the PNL by default':
        'За замовчуванням показувати лише останній рік у звіті PnL',
      'Group passport pnl report by commodities': 'Згрупувати звіт паспорт PnL за товари',
      'Show VAT everywhere': 'Відображати ПДВ',
      'Show return on sales': 'Відображати рентабельність продажів',
      'Show return on equity': 'Відображати рентабельність власного капіталу',
      'Show insurance in passport facts': 'Відображати страхування в паспорті',
      'Show insurance in passport PASSPORT_FACTS_USE_CHAIN_ORDER':
        'Показати страховку в паспорті PASSPORT_FACTS_USE_CHAIN_ORDER',
      'Use chain order in passport facts': 'Використовувати порядок ланцюжка в фактах паспорта',
      'Show margin by tickets in passport results':
        'Відображати маржу за тікетами в результатах паспорта',
      'Show margin by analytical in passport results':
        'Відображати маржу - аналітичну в результатах паспорта',
      'Show margin by contracts in passport results':
        'Відображати маржу за контрактами в результатах паспорта',
      'Show margin by logistics in passport results':
        'Відображати маржу за логістикою в результатах паспорта',
      'Show margin by invoices in passport results':
        'Відображати маржу за інвойсами в результатах паспорта',
      'Show margin by payments in passport results':
        'Відображати маржу по виплатах в результатах паспорта',
      'offer role': 'пропонувати роль',
      'Tariff auto active': 'Авто тариф актив',
      'Tariff rw active': 'Залізниця тариф актив',
      'Elevator active': 'Елеватор актив',
      'Freight active': 'Фрахт актив',
      'Approval expiration time, in hours': 'Термін дії схвалення, години',
      'Define how to call "allocation"': 'Визначити, як викликати «розподіл»',
      'Allow only the same crops or child crops': 'Дозволити тільки однакові чи дочірні культури',
      'Allow connect not more than contract volume':
        'Дозволити з’ єднання не більше, ніж об’єм контракту',
      'Enable filter for consolidated table ': 'Увімкнути фільтр для зведеної таблиці ',
      'Change view of FOB position "Load" modal':
        'Змінити відображення позиції FOB Завантаження в судно',
      'To prevent logistic from amendment when balance invoice':
        'З метою унеможливлення змін у логістиці після сплати балансового інвойсу',
      'validate if counterparty is approved before creating general agreement':
        'перевірка наявності погодженого статусу контрагента перед створенням загальної угоди',
      'To put approval config by default if user is main approver':
        'Автоматично встановити конфігурацію погодження за замовчуванням, якщо користувач обраний головним схвалювачем',
      "Logistic's loading_date must be between date_of_execution and end_of_execution its supplier_contract":
        'Дата завантаження транспортного засобу має бути в періоді виконання контракту',
      'Prevents Logistics Import while there are duplicated records in the DB by LOGISTICS_MATCHING_FIELDS':
        'Запобігає імпорту логістики за наявності в базі даних повторюваних записів за LOGISTICS_MATCHING_FIELDS',
      'Process vehicle numbers and keep only LOGISTIC_IMPORT_VEHICLE_NUMBER_LENGHT symbols':
        'Обробити номери транспортних засобів і залишити лише символи з довжиною LOGISTIC_IMPORT_VEHICLE_NUMBER_LENGH',
      'Length of vehicle number': 'Довжина номеру транспортного засобу',
      'Skip import unchanged rows (may work incorrectly with some kind of fields like qualities or related objects)':
        'Пропустити імпорт постійних рядків (може некоректно працювати з деякими полями, такими як якості або пов’язані об’єкти)',
      'Unique logistics acceptance certificate': 'Унікальний логістичний акт приймання',
      'Check if adding bank details is required to create a counterparty':
        'Позначте, якщо необхідно додати банківські реквізити під час створення контрагента',
      'Check if adding documents is required to create an invoice':
        'Позначте, якщо необхідно додати документи під час створення інвойсу',
      'Invoice position contract or passport required':
        'Необхідний контракт або паспорт для інвойс позиції',
      'Passport is required for export contracts in finance position':
        'Для експортних контрактів у інвойс позиції потрібен паспорт',
      'Disable invoice positions for editing for cancelled or executed contracts':
        'Вимкнути можливість редагування інвойс позицій для скасованих або виконаних контрактів',
      'Dont validate the same counterparties from contracts in invoice positions':
        'Не перевіряти однакових контрагентів із контрактів у позиціях інвойсу',
      'Always check invoice numbers for duplicates':
        'Завжди перевіряти наявність дублікатів номерів інвойсів',
      'Enable inline add in tables': 'Включити вбудоване додавання в таблиці',
      'Enable quick modals to create objects':
        'Увімкнути швидкі модальні вікна для створення об’єктів',
      'Require for terminal in purchase contract for certain basises':
        "Обов'язково вказати термінал у закупівельного контракту для зазначеного базису",
      'Use costs for execution from invoices': 'Використовувати інвойси для витрат',
      'removes contracts pages, leaves only general agreements':
        'видаляє сторінки з контрактами, залишає тільки загальні угоди',
      'Suppress request_saved event for back_to_back':
        'Не надсилати повідомлення про створення тікета',
      'Require for destination with basies CIF, FOB, CFR':
        "Обов'язковий для призначення з базисом CIF, FOB, CFR",
      'Document file format to download from template':
        'Формат файлу документа для завантаження з шаблону',
      'Simplify main tables removing tabs': 'Спростити основні таблиці, видаливши вкладки',
      'Removing negotiation from charges': 'Забрати переговорний процес у витратах',
      'On purchase contracts page shows tab "CP/Seeds Prepayments"':
        'На сторінці закупівельних контрактів відображається вкладка «CP/Seeds передоплати»',
      'Show invoice positions in passport by default"':
        'Показувати інвойс позиції у паспорті за замовчуванням',
      'Default tab on derivatives report': 'Вкладка за замовчуванням у звіті про деривативи',
      'Models in the %(name)s application': 'Models in the %(name)s application',
      '%(name)s': '%(name)s',
      "You don't have permission to edit anything.": 'Не маєте дозволу на редагування.',
      'Recent Actions': 'Останні дії',
      'My Actions': 'Мої дії',
      'None available': 'Недоступний',
      'Unknown content': 'Невідомі дані',
    });
    /* jshint +W100 */
  },
]);
