import React from 'react';

import { createFileRoute } from '~/shared/lib/router';

export const Route = createFileRoute(
  '/_auth/_sidebar/deals/passports/_passport/$passportId/contracts',
)({
  component: () => (
    <div>Hello /_auth/_sidebar/deals/passports/_passport/$passportId/contracts!</div>
  ),
});
