import type ng from 'angular';

import type { FormFieldParamsService } from '~/app/core/components/form-field-params/form-field-params.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '~/app/core/types';
import type { FinancesService } from '~/app/finances/legacy/finances.srv';

import template from './invoice-details-item-container.html?raw';

export const invoiceDetailsItemContainer = {
  bindings: {
    finance: '<',
    clientFrom: '<',
    clientTo: '<',
    filterLevel: '<',
    updateConfigWithFilterLevel: '&?',
  },
  template,
  controller: [
    '$scope',
    '$state',
    '$rootScope',
    '$stateParams',
    'gtFilterService',
    'DocumentsService',
    'ClientsService',
    'FinancesService',
    'FormFieldParamsService',
    'InvoiceFormFieldsService',
    'GtUtils',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      ClientsService: any;
      DocumentsService: any;
      FinancesService: FinancesService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      InvoiceFormFieldsService: any;
      activeTabs: any;
      clientIdFrom: any;
      clientIdTo: any;
      fields: any;
      filterLevel = 'payments-page-view';
      finance: any;
      financePositions: any;
      form: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      hideWizard: any;
      invoice: any;
      logisticsQueryParams: QueryParams = {};
      payment: any;
      tab: any;
      updateConfigWithFilterLevel: any;
      updating: any;
      usesList: any;
      constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $rootScope: GtRootScopeService,
        $stateParams: ng.ui.IStateParamsService,
        gtFilterService: GtFilterService,
        DocumentsService: any,
        ClientsService: any,
        FinancesService: FinancesService,
        FormFieldParamsService: FormFieldParamsService,
        InvoiceFormFieldsService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.gtFilterService = gtFilterService;
        this.DocumentsService = DocumentsService;
        this.ClientsService = ClientsService;
        this.FinancesService = FinancesService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.InvoiceFormFieldsService = InvoiceFormFieldsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.invoice = {};

        this.activeTabs = [];
        this.tab = [''];
        this.payment = {};
        this.financePositions = [];
        this.usesList = [];

        this.form = undefined;
        this.fields = [];
        this.logisticsQueryParams = {};
        if (this.$rootScope.user.profile.wizard) {
          this.hideWizard = false;
        } else {
          this.hideWizard = true;
        }
      }

      $onInit() {
        this.logisticsQueryParams = { invoice: this.finance.id };
        this.tab = this.$stateParams.tab || ['info', 'positions'];
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (_: any, data: any) => {
          if (data.tab) {
            this.tab = data.tab;
          }
        });
        if (this.$stateParams.tab) {
          this.gtFilterService.updateQueryParams({ tab: this.$stateParams.tab }, this.filterLevel);
        }
        this.$scope.$watch('this.finance.discount', () => {
          if (this.finance.full_amount) {
            this.finance.amount = this.finance.full_amount * (this.finance.discount / 100);
            this.finance.amount = Math.round(this.finance.amount * 100) / 100;
          }
        });
        this.$scope.$watch('this.finance.full_amount', (fullAmount: any) => {
          if (fullAmount) {
            this.finance.amount = fullAmount * (this.finance.discount / 100);
            this.finance.amount = Math.round(this.finance.amount * 100) / 100;
          }
        });
        this.$scope.$watch('this.finance.discount_amount', () => {
          if (this.finance.full_amount && this.finance.discount_amount != 0) {
            this.finance.amount =
              this.finance.full_amount - (this.finance.discount_amount * 1 || 0);
            this.finance.amount = Math.round(this.finance.amount * 100) / 100;
          }
        });
        this.$scope.$on('finance-position-table__change-full-amount', (ev: any, total: any) => {
          this.finance.full_amount = total.newFullAmount;
          this.finance.totalQuantityTotalAmount = total.totalQuantityTotalAmount;
          if (this.finance.full_amount && this.finance.interest_rate) {
            this.finance.totalInterestRateAmount =
              (this.finance.full_amount * (this.finance.interest_rate / 100)) / 360;
          }
        });
        this.updateData();
      }

      updateData() {
        this.GtUtils.overlay('show');
        return this.FinancesService.Finance.get(
          { id: this.finance.id },
          (data: any) => {
            this.finance = data;
            this.getClientIdFrom();
            this.getClientIdTo();
            this.$rootScope.pageTitle = this.$rootScope.pageTitle + ' #' + this.finance.number;
            this.gtFilterService.updateQueryParams({}, this.filterLevel);
            this.updating = false;
            this.updateConfigWithFilterLevel({
              invoice: this.finance,
              filterLevel: this.filterLevel,
            });
            this.payment = {
              finance: this.finance.id,
              bank_account: this.finance.bank_account,
              currency_exchange: this.finance.currency_exchange,
              amount: parseFloat(this.finance.to_pay),
              finance_amount_USD: this.finance.amount,
            };
            this.FinancesService.InvoicePosition.get(
              { invoice: this.finance.id, page_size: 100 },
              (data: any) => {
                this.financePositions = data.results;
                const result = this.FinancesService.getUsesList(this.financePositions);
                this.usesList = result.usesList;
                this.financePositions = result.financePositions;
              },
            );
            this.updateFields();
            this.GtUtils.overlay('hide');
            return this.finance;
          },
          () => {
            this.GtUtils.overlay('hide');
            this.GtUtils.notify(this.gettext("Finance doesn't exist"));
            this.$state.go('finances.invoices.list');
          },
        ).$promise;
      }

      financePositionIsValid() {
        return this.financePositions.forEach((position: any) => this.checkPositionByUse(position));
      }

      checkPositionByUse(invoicePosition: any) {
        const requiredFields = this.FinancesService.getRequiredFields(invoicePosition);
        return requiredFields.every((field: any) =>
          this.FinancesService.validatePositionField(field, invoicePosition),
        );
      }

      clone() {
        return this.FinancesService.cloneInvoice(this.finance).then(() => this.updateData());
      }

      openFinanceModal(finance: any, data: any) {
        return this.FinancesService.financeModal(finance, data).then(() => this.updateData());
      }

      openDocumentModal() {
        return this.DocumentsService.documentModal('Finance', this.finance.id).then(() =>
          this.updateData(),
        );
      }

      openDocxModal() {
        return this.DocumentsService.generateDocxModal('Finance', this.finance.id);
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => {
            this.fields = fields;
            this.GtUtils.overlay('hide');
          })
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        return this.InvoiceFormFieldsService.getFields(this.finance, this.financePositions);
      }
      openEmailModal() {
        return this.ClientsService.sendEmailModal('Finance', this.finance.id);
      }

      getClientIdFrom() {
        return this.ClientsService.ClientRole.get({
          id: this.finance.clientrole_from,
        }).$promise.then((data: any) => {
          this.clientIdFrom = data.client;
        });
      }

      getClientIdTo() {
        return this.ClientsService.ClientRole.get({
          id: this.finance.clientrole_to,
        }).$promise.then((data: any) => {
          this.clientIdTo = data.client;
        });
      }

      changeTab(tab: any) {
        const index = this.activeTabs.indexOf(tab);
        if (index === -1) {
          if (this.activeTabs.length >= 1) {
            this.activeTabs.shift();
          }
          this.activeTabs.push(tab);
        } else {
          this.activeTabs.splice(index, 1);
        }
        this.gtFilterService.updateQueryParams({ tab: this.activeTabs }, this.filterLevel);

        if (!this.$rootScope.user.profile.wizard) {
          this.hideWizard = true;
        }
      }
    },
  ],
};
