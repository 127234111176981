import ng from 'angular';

import { consignments } from './consignments/consignments.module';
import { WarehousesRestContainer } from './containers/warehouses-rest-container/warehouses-rest-container.component';
import { potentials } from './potentials/potentials.module';
import { reservationModule } from './reservation/reservation.module';
import { transportationStorageExpenseModule } from './transportation-storage-expense/transportation-storage-expense.module';
import { warehouseModule } from './warehouse/module';
import { warehouseBalanceModule } from './warehouse-balance/warehouse-balance.module';
import { MovementsModule } from './warehouse-commodity-batch-movements/warehouse-commodity-batch-movements.module';
import { WarehouseContentTableView } from './warehouse-content/warehouse-content-table-view/warehouse-content-table-view.component';
import { warehouseLosses } from './warehouse-losses/warehouse-losses.module';
import { WarehouseRestContentTableView } from './warehouse-rest-content/warehouse-content-table-view/warehouse-rest-content-table-view.component';
import { warehouseShipment } from './warehouse-shipment/warehouse-shipment.module';

export const warehouse = ng
  .module('execution.warehouse', [
    warehouseLosses,
    consignments,
    potentials,
    warehouseShipment,
    warehouseModule,
    warehouseBalanceModule,
    MovementsModule,
    transportationStorageExpenseModule,
    reservationModule,
  ])
  .component('warehouseContentTableView', WarehouseContentTableView)
  .component('warehouseRestContentTableView', WarehouseRestContentTableView)
  .component('warehousesRestContainer', WarehousesRestContainer).name;
