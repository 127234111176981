import React from 'react';

import { DatePicker } from '~/shared/ui/kit/date-picker';

import type { FilterTypeProps } from '../types';

export const DateFilter: React.FC<FilterTypeProps> = ({ filterValue, onChange }) => {
  return (
    <DatePicker
      mode="single"
      selected={new Date(filterValue.values[0])}
      onSelect={(input) => onChange({ ...filterValue, values: [input ? input.toString() : ''] })}
    />
  );
};
