import ng from 'angular';

import type { CoreService } from '~/app/core/core.service';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService } from '~/app/core/types';

(function () {
  'use strict';
  ng.module('crm.clients.legacy').component('newClientUpdate', {
    bindings: {
      clientUpdate: '<?',
      filterLevel: '<?',
      onChange: '&?',
    },
    template: require('./new-client-update.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });
  Controller.$inject = [
    '$scope',
    '$rootScope',
    'gtFilterService',
    'ClientsService',
    'CoreService',
    'NotificationService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $rootScope: GtRootScopeService,
    gtFilterService: GtFilterService,
    ClientsService: any,
    CoreService: CoreService,
    NotificationService: any,
  ) {
    const vm = this;
    vm.save = save;

    vm.$onInit = function () {
      vm.original = { ...vm.clientUpdate };
      vm.clientUpdate.contact_status = 'upcoming';
      vm.clientUpdate.priority = 'medium';
      vm.clientUpdate.responsible_users = [$rootScope.user.id];
      if (!vm.clientUpdate.updates_entity_content_type && vm.clientUpdate.modelName) {
        CoreService.getModelContentType(vm.clientUpdate.modelName).then(
          (ct: any) => (vm.clientUpdate.updates_entity_content_type = ct.id),
        );
      }
      ClientsService.ClientUpdate.getSubjectContentTypes().$promise.then((data: any) => {
        vm.contentTypeChoices = data.contenttype_choices;
        updateSubjectContentTypeAndResource();
      });
      vm.setSubjectContentTypeAndResource = setSubjectContentTypeAndResource;
      vm.subscribe = subscribe;
    };

    vm.$onChanges = function (changes: any) {
      if (changes.clientUpdate) {
        vm.clientUpdate.contact_status = vm.clientUpdate.contact_status || 'upcoming';
        vm.clientUpdate.priority = vm.clientUpdate.priority || 'medium';
        vm.clientUpdate.responsible_users = vm.clientUpdate.responsible_users || [
          $rootScope.user.id,
        ];
        updateSubjectContentTypeAndResource();
      }
    };
    function updateSubjectContentTypeAndResource() {
      if (vm.clientUpdate && vm.contentTypeChoices && vm.clientUpdate.updates_entity_content_type) {
        vm.setSubjectContentTypeAndResource(
          vm.contentTypeChoices
            .filter((v: any) => v.id === vm.clientUpdate.updates_entity_content_type)
            .pop(),
        );
      }
    }

    ////////////////
    function setSubjectContentTypeAndResource() {
      const contentType = vm.contentTypeChoices.find(
        (obj: any) => obj.key === vm.clientUpdate.updates_entity_title,
      );

      if (vm.clientUpdate.updates_entity_content_type !== contentType.id) {
        vm.clientUpdate.updates_entity_id = undefined;
        vm.clientUpdate.updates_entity_content_type = contentType.id;
      }
      vm.updatesEntityResource = contentType.resource_name;
      vm.updatesEntityFilters = contentType.filters || {};
    }

    function updateData() {
      if (vm.filterLevel) {
        gtFilterService.updateQueryParams({}, vm.filterLevel);
      }
      vm.onChange();
    }

    function save() {
      return ClientsService.ClientUpdate.save(vm.clientUpdate, function () {
        updateData();
        vm.clientUpdate = {
          contact_status: 'upcoming',
          priority: 'medium',
          is_autocreated: false,
          ...vm.original,
        };
      }).$promise;
    }

    function subscribe() {
      return NotificationService.openEventSubscriptionModal({
        user: $rootScope.user.id,
        email: $rootScope.user.email,
        action: 'clientupdate_created',
        filters: [
          { field: 'content_updates_entity', value: vm.clientUpdate.updates_entity_id },
          {
            field: 'updates_entity_content_type',
            value: vm.clientUpdate.updates_entity_content_type,
          },
        ],
      });
    }
  }
})();
