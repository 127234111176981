import type ng from 'angular';
import type { IFieldArray } from 'AngularFormly';

import { getModalRoot } from '~/shared/ui/modal';

export class FormFieldParamsService {
  $log: ng.ILogService;
  $q: ng.IQService;
  $uibModal: ng.ui.bootstrap.IModalService;
  AccountsService: any;
  fieldParamsOrig: any;
  formFieldParams: any;
  constructor(
    $uibModal: ng.ui.bootstrap.IModalService,
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $log: ng.ILogService,
    $injector: ng.auto.IInjectorService,
  ) {
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.$log = $log;
    this.AccountsService = $injector.get<any>('AccountsService');
    this.fieldParamsOrig = undefined;
    this.formFieldParams = $resource(
      '/api/core/form-field-params/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
        applyToAll: {
          method: 'POST',
          url: '/api/core/form-field-params/apply_to_all_business_units/',
        },
      },
    );
  }

  fieldsConfigModal(formConfig: any, extraData?: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      appendTo: getModalRoot(),
      template: `
        <form-field-params-modal form-name="formName" fields="fields" extra-data="extraData" modal-instance="$uibModalInstance">
        </form-field-params-modal>`,
      controller: [
        '$scope',
        'formName',
        'fields',
        'extraData',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          formName: any,
          fields: any,
          extraData: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).formName = formName;
          ($scope as any).fields = fields;
          ($scope as any).extraData = extraData;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-three-thirds-width',
      size: 'sm',
      resolve: {
        formName: () => formConfig.formName,
        fields: () => this.getFlatFields(formConfig),
        extraData: () => extraData,
      },
    }).result;
  }

  getFormFieldParams(formName: any, businessUnit: any, fields: any) {
    const fieldParamsList: any = [];
    const userIds: any = [];
    const businessUnitId = businessUnit ? businessUnit.id : 'undefined';
    return this.AccountsService.User.query({ page_size: 9999 }).$promise.then((usersData: any) => {
      userIds.push(...usersData.results.map((user: any) => user.id));
      return this.formFieldParams
        .query({ form_name: formName, business_unit: businessUnitId })
        .$promise.then((data: any) => {
          Object.keys(fields).forEach((k) => {
            const field = fields[k];
            let fieldParams = data.filter((params: any) => params.field_name === field.key).shift();
            fieldParams = fieldParams || {
              field_name: field.key,
              form_name: formName,
              visible: this.AccountsService.getSetting('NEW_FIELD_VISIBILITY'),
              required: false,
              users: userIds,
              isNew: !field.templateOptions.required,
            };
            if (field.templateOptions) {
              fieldParams.initRequired = field.templateOptions.required;
              fieldParams.required = fieldParams.required || field.templateOptions.required;
              fieldParams.visible = fieldParams.required || fieldParams.visible;
              fieldParams.title = field.templateOptions.label || field.key;
              fieldParamsList.push(fieldParams);
            }
          });

          this.fieldParamsOrig = JSON.parse(JSON.stringify(fieldParamsList));
          return fieldParamsList;
        });
    });
  }

  saveParamsForAllBusinessUnits(params) {
    return this.formFieldParams.applyToAll(params).$promise;
  }

  saveParams(params: any, businessUnit: any) {
    let chain = this.$q.when();
    const businessUnitId = businessUnit.id !== 'undefined' ? businessUnit.id : null;
    params
      .filter(
        (item: any, i: any) => JSON.stringify(item) !== JSON.stringify(this.fieldParamsOrig[i]),
      )
      .forEach((item: any) => {
        const saveFunc = item.id ? this.formFieldParams.update : this.formFieldParams.save;
        item.business_unit = businessUnitId;
        chain = chain.then(() => saveFunc(item).$promise);
      });
    return chain;
  }

  getFlatFields(formConfig: any) {
    const fieldsDef = formConfig.fieldsDef;
    const fields = {};
    const fieldsList: any = [];
    Object.keys(fieldsDef).forEach((col) => {
      Object.keys(fieldsDef[col].fieldGroup).forEach((fgWrapper) => {
        Object.keys(fieldsDef[col].fieldGroup[fgWrapper].fieldGroup).forEach((fg) => {
          const field = fieldsDef[col].fieldGroup[fgWrapper].fieldGroup[fg];
          if (field?.key) {
            fields[field.key] = fieldsDef[col].fieldGroup[fgWrapper].fieldGroup[fg];
          }
        });
      });
    });
    Object.keys(fields).forEach((k) => fieldsList.push(fields[k]));
    return fieldsList;
  }

  prepareFlatFields(flatFields: any, fieldsParams: any, newFieldVisibility: any) {
    flatFields.forEach((field: any) => this.prepareField(field, fieldsParams, newFieldVisibility));
    return flatFields;
  }
  prepareField(field: any, fieldsParams: any, newFieldVisibility: any) {
    const fieldParams = fieldsParams[field.key];
    if (fieldParams) {
      field.templateOptions.label = fieldParams.new_title || field.templateOptions.label;

      if (fieldParams.hide) {
        field.hideExpression = () => true;
      } else {
        field.hideExpression = field.hideExpression || (() => false);
      }

      field.templateOptions.required = field.templateOptions.required || fieldParams.required;
    }

    field.templateOptions.initRequired =
      field.templateOptions.initRequired ?? field.templateOptions.required;

    if (!field.hideExpression) {
      field.hideExpression = () => !newFieldVisibility;
      if (field.templateOptions.initRequired) {
        field.hideExpression = () => false;
      }
    }
  }

  getFields(formConfig: any, businessUnit?: any, params?: any): Promise<IFieldArray> {
    let when;
    if (params) {
      when = this.$q.when(params);
    } else {
      when = this.getParams(formConfig.formName, businessUnit);
    }
    let newFieldVisibility: any = undefined;
    if (params) {
      newFieldVisibility = false;
    }
    return when
      .then((fieldsParams: any) => this.prepareFields(formConfig, fieldsParams, newFieldVisibility))
      .then((fields: any) => this.resolveDefaultValues(fields));
  }

  getParams(formName: any, businessUnit: any) {
    const fields = {};
    const _businessUnit = businessUnit || 'undefined';
    return this.formFieldParams
      .query({ form_name: formName, business_unit: _businessUnit, serializer: 'light' })
      .$promise.then((data: any) => {
        data.forEach((i: any) => (fields[i.field_name] = i));
        return fields;
      });
  }

  getContractPriceWidgetFields(formName: any, businessUnit?: string, fieldsParams?: any) {
    const priceWidgetFields: any = [
      'derivatives',
      'measurement',
      'additional_info',
      'price',
      'basis',
      'port',
      'volume',
      'date_of_execution',
      'end_of_execution',
      'price_premium',
      'indicator',
    ];
    let chain = this.$q.when((fieldsParams && { ...fieldsParams }) || undefined);
    if (!fieldsParams) {
      chain = this.getParams(formName, businessUnit ?? 'undefined');
    }
    return chain.then((params: any) => {
      return priceWidgetFields.reduce(
        (result, fieldName) => [
          ...result,
          params[fieldName] || {
            field_name: fieldName,
            form_name: formName,
            visible: true,
            required: fieldName === 'cargo',
          },
        ],
        [],
      );
    });
  }

  hideAfterCheck(field: any, condition: any) {
    if (condition) {
      field.hideExpression = () => true;
    }
  }

  prepareFields(formConfig: any, params: any, newFieldVisibility: any) {
    if (newFieldVisibility === undefined) {
      newFieldVisibility = this.AccountsService.getSetting('NEW_FIELD_VISIBILITY');
    }
    this.getFlatFields(formConfig).forEach((field) =>
      this.prepareField(field, params, newFieldVisibility),
    );
    return Object.assign([], formConfig.fieldsDef);
  }

  resolveDefaultValues(fieldsDef: any) {
    const fields = this.getFlatFields({ fieldsDef: fieldsDef });
    const promises = fields
      .filter((field) => field.defaultValueResolve)
      .map((field) =>
        field.defaultValueResolve().then((value: any) => {
          field.defaultValue = value;
          delete field.defaultValueResolve;
        }),
      );
    return this.$q.all(promises).then(() => fieldsDef);
  }

  isRequiredCurrencyExchangeField(formName: any, businessUnit: any) {
    const businessUnitId = businessUnit || 'undefined';
    return this.formFieldParams
      .query({ form_name: formName, business_unit: businessUnitId, serializer: 'light' })
      .$promise.then(
        (data: any) =>
          data.find((field: any) => {
            return field.field_name === 'currency_exchange';
          })?.required,
      );
  }
}
FormFieldParamsService.$inject = ['$uibModal', '$resource', '$q', '$log', '$injector'];
