import React from 'react';

import { useLocation } from '~/shared/lib/router';

import { MenuItemNavigation } from '../menu-item-navigation';
import { getMenuList } from '../menu-list';

export const MenuList = () => {
  const { pathname } = useLocation();

  const menuList = React.useMemo(() => getMenuList(pathname), [pathname]);

  return (
    <nav className="w-full pt-3">
      <ul className="flex flex-col gap-1 px-2" data-testid="menu-list">
        {menuList.map((item) => (
          <MenuItemNavigation key={item.id} item={item} Icon={item.icon} />
        ))}
      </ul>
    </nav>
  );
};
