import ng from 'angular';

import { ConsolidatedContractPnlContainer } from './consolidated-contract-pnl-container/consolidated-contract-pnl-container.component';
import { ConsolidatedContractPnlPage } from './consolidated-contract-pnl-page/consolidated-contract-pnl-page.component';
import { ConsolidatedContractPnlTableView } from './consolidated-contract-pnl-table-view/consolidated-contract-pnl-table-view.component';
import { ConsolidatedContractPnlService } from './consolidated-contract-pnl.service';

export const consolidatedContractPnl = ng
  .module('reports.consolidatedContractPnl', [])
  .service('ConsolidatedContractPnlService', ConsolidatedContractPnlService)
  .component('consolidatedContractPnlPage', ConsolidatedContractPnlPage)
  .component('consolidatedContractPnlTableView', ConsolidatedContractPnlTableView)
  .component('consolidatedContractPnlContainer', ConsolidatedContractPnlContainer).name;
