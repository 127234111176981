import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

import template from './fact-intermediate-list-container.html?raw';

export const FactIntermediateListContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<',
    predictionsQueryParams: '<',
    callFrom: '<',
  },
  template,
  controller: [
    '$scope',
    'GtUtils',
    'gettext',
    'gtFilterService',
    'FactIntermediateListService',
    class {
      $scope: ng.IScope;
      FactIntermediateListService: any;
      GtUtils: GtUtilsService;
      callFrom: any;
      count: number;
      filterLevel = 'fact-intermediate-list-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      list: any;
      predictionsQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        FactIntermediateListService: any,
      ) {
        this.$scope = $scope;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.FactIntermediateListService = FactIntermediateListService;

        this.list = [];
        this.count = 0;
      }

      $onInit() {
        this.initQueryParams = this.initQueryParams || {};
        this.predictionsQueryParams = this.predictionsQueryParams || {};
        this.queryParams = {
          page_size: 20,
          ...this.initQueryParams,
          ...this.getParamsByContractType(),
        };

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      applyFilters(params: any) {
        params = Object.assign(params, this.initQueryParams);
        this.gtFilterService.updateQueryParams(params, this.filterLevel);
      }

      updateData() {
        this.list = [];
        this.GtUtils.overlay('show');
        return this.FactIntermediateListService.query(this.queryParams).then((data: any) => {
          this.list = data.results;
          this.list.unshift(this.getNewItem());
          this.count = data.count;
          this.GtUtils.overlay('hide');
        });
      }

      getNewItem() {
        return {
          contract: this.predictionsQueryParams.contractId,
          passport: this.initQueryParams.passport,
          contract_deal_type: 'intermediate',
        };
      }

      getParamsByContractType() {
        const params = {};
        if (this.callFrom == 'contracts') {
          // @ts-ignore
          params.by_passports_from_contract = this.predictionsQueryParams.contractId;
        }
        return params;
      }

      saveItem(item: any) {
        return this.FactIntermediateListService.save(item).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }

      destroy(item: any) {
        if (!confirm(this.gettext('Are you sure that you want delete?'))) {
          return false;
        }
        return this.FactIntermediateListService.delete({ id: item }).then(
          () => this.updateData(),
          (error: any) => this.GtUtils.errorClb(error),
        );
      }
    },
  ],
};
