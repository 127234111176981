import ng from 'angular';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtConfigurableColumns', directive);

  directive.$inject = ['$timeout'];

  function directive($timeout: ng.ITimeoutService) {
    const directive = {
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      link: { post: link },
      restrict: 'A',
      scope: {
        tableId: '<',
        rebuildEvent: '<',
      },
    };
    return directive;

    function link(scope: any, element: any) {
      scope.$on(scope.vm.rebuildEvent, function () {
        $timeout(function () {
          reorderRows(scope.vm, element);
        }, 1000);
      });
    }

    function reorderRows(vm: any, element: any) {
      const orderedNames = getOrderedColumnNames(vm, element);
      element
        .find('thead')
        .find('tr')
        .each(function (_idx: any, el: any) {
          reorderColumns(orderedNames, el, 'th');
        });
    }

    function getOrderedColumnNames(vm: any, element: any) {
      const titleThs = element.find('thead').find('tr').first().find('th');
      let columnsList: any = [];

      titleThs.each(function (_idx: any, el: any) {
        const columnName = el.attributes.getNamedItem('column-name').value;
        const params = vm.getParamsByName(columnName);
        columnsList.push(params);
      });
      columnsList = columnsList.sort(function (a, b) {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      });
      return columnsList.map(function (obj) {
        return obj.columnName;
      });
    }

    function reorderColumns(orderedNames: any, el: any, colTag: any) {
      const namedElements = {};
      const newParent = ng.element(el).clone();
      // @ts-ignore
      newParent.innerHTML = '';
      colTag = colTag || 'td';

      ng.element(el)
        .find(colTag)
        // @ts-ignore
        .each(function (idx: any, col: any) {
          const nameAttr = col.attributes.getNamedItem('column-name');
          if (!nameAttr) {
            throw new Error('Column must have column-name arrtibute');
          }
          namedElements[nameAttr.value] = col;
          col.remove();
        });

      orderedNames.forEach(function (columnName: any) {
        el.append(ng.element(namedElements[columnName]).clone()[0]);
      });
    }
  }

  Controller.$inject = ['$scope', '$timeout'];

  function Controller(this: any, $scope: ng.IScope, $timeout: ng.ITimeoutService) {
    const vm = this;
    vm.params = undefined;
    vm.paramsByName = {};
    vm.getParamsByName = getParamsByName;

    activate();

    /////////////

    function activate() {
      updateParams();
    }

    function updateParams() {
      return $timeout(function () {
        vm.params = [
          {
            tableName: '',
            columnName: 'status',
            index: -1,
            show: 1,
          },
        ];

        vm.paramsByName = {};
        vm.params.forEach(function (item: any) {
          vm.paramsByName[item.columnName] = item;
        });
      }, 100);
    }

    function getParamsByName(name: any) {
      return vm.paramsByName[name] || { index: 0, show: 1, columnName: name };
    }
  }
})();
