import React from 'react';

import { dateFormat as dateFormatFn } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';
import type { DataTypeProps } from '~/shared/ui/data-types/lib';
import { messageIntent } from '~/shared/ui/data-types/lib';
import { DatePicker } from '~/shared/ui/kit/date-picker';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

export const DateWrite: React.FC<DataTypeProps<Date> & { dateFormat?: string }> = ({
  value,
  disabled,
  message,
  valueChanged,
  dateFormat = 'dd/MM/yy',
}) => {
  return disabled ? (
    <p className="text-xs text-text-main-primary opacity-70">{dateFormatFn(value, dateFormat)}</p>
  ) : (
    <Popover>
      <PopoverTrigger
        className={cn(
          'h-full w-full px-2 text-left text-xs text-text-main-tertiary',
          message && messageIntent[message.type],
        )}
      >
        {value ? dateFormatFn(value, dateFormat) : <span>Choose a date</span>}
      </PopoverTrigger>
      <PopoverContent className="h-full w-full border-none p-0">
        <DatePicker mode="single" selected={value} onSelect={valueChanged} required />
      </PopoverContent>
    </Popover>
  );
};
