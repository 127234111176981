import React from 'react';

import { GlobalDialogContainer } from '~/core/global-dialog';
import { GlobalSearchContainer } from '~/core/global-search';
import { ToastContainer } from '~/shared/lib/notify';

import { AppInner } from './app-inner';

import './globals.css';

export const App: React.FC = () => {
  return (
    <>
      <AppInner />
      <ToastContainer position="bottom-right" theme="colored" />
      <GlobalSearchContainer />
      <GlobalDialogContainer />
    </>
  );
};
