import ng from 'angular';

import type { PageService } from '~/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '~/app/deals/contracts/legacy/contracts.srv';

export const requestDetailsPage = {
  bindings: {},
  template: require('./request-details-page.html?raw'),
  controller: [
    '$stateParams',
    '$state',
    'PageService',
    'RequestFilterConfigService',
    'GtUtils',
    'ContractsService',
    'gtFilterService',
    'gettext',
    class {
      $state: ng.ui.IStateService;
      $stateParams: ng.ui.IStateParamsService;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      PageService: PageService;
      RequestFilterConfigService: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      onRequestUpdated: any;
      request: any;
      updating: any;
      constructor(
        $stateParams: ng.ui.IStateParamsService,
        $state: ng.ui.IStateService,
        PageService: PageService,
        RequestFilterConfigService: any,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
        gtFilterService: GtFilterService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.PageService = PageService;
        this.GtUtils = GtUtils;
        this.ContractsService = ContractsService;
        this.RequestFilterConfigService = RequestFilterConfigService;
        this.gtFilterService = gtFilterService;
        this.gettext = gettext;

        this.request = {};
        this.onRequestUpdated;
        this.filterLevel = 'request-side-list';
        this.updating = true;
      }

      $onInit() {
        this.request = { id: this.$stateParams.id };
        const config = this.PageService.getConfig();
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            filters: this.RequestFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            filters: this.RequestFilterConfigService.getFilterConfig(this.filterLevel),
            pages: [],
          });
        }
      }

      updateConfigWithFilterLevel(request: any, filterLevel: string) {
        const config = this.PageService.getConfig();
        let isOpen;

        if (ng.equals(config, {}) || config.sidebar?.is_open) {
          isOpen = true;
        } else {
          isOpen = false;
        }
        if (config.is_detail_page) {
          this.PageService.updateConfig({
            sidebar: {
              verboseName: `${request.stage}s`,
              modelName: 'contracts.request',
              instance: request,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
          });
        } else {
          this.PageService.setConfig({
            is_detail_page: true,
            sidebar: {
              verboseName: `${request.stage}s`,
              modelName: 'contracts.request',
              instance: request,
              filterLevel: filterLevel,
              is_open: isOpen,
            },
            filters: this.RequestFilterConfigService.getFilterConfig(filterLevel),
            pages: [],
          });
        }
      }
    },
  ],
};
