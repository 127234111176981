import React from 'react';

import { SelectWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const SelectCellWrite = <T,>({
  value,
  cellTemplateContext,
  valueChanged,
}: CellTypeProps<T>) => {
  return (
    <SelectWrite
      value={String(value)}
      cellTemplateContext={cellTemplateContext}
      valueChanged={(value) => valueChanged?.(value as T[keyof T])}
    />
  );
};
