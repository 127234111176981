import template from './quality-rule-templates-list-table-view.html?raw';

export const QualityRuleTemplatesListTableView = {
  bindings: {
    templates: '<',
    openTemplateModal: '&',
  },
  template,
  controller: [
    'gettext',
    class {
      gettext: ng.gettext.gettextFunction;
      openTemplateModal: any;
      tableData: any;
      tableOptions: any;
      templates: any;
      constructor(gettext: ng.gettext.gettextFunction) {
        this.gettext = gettext;
      }

      $onInit() {
        this.tableData = { rows: this.templates };
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.templates) {
          this.tableData = { rows: this.templates };
        }
      }

      getTableOptions() {
        return {
          tableClass: 'main-table request-table quality_rule_templates_table',
          tableAltClass: 'main-table request-table quality_rule_templates_table',
          alignColHeight: true,
          rowData: this.templates,
          templateArgs: {
            openTemplateModal: (item: any) => this.openTemplateModal({ item }),
          },
          columnDefs: [
            {
              columnName: 'title',
              title: this.gettext('title'),
              class: 'td-left-align',
              cellTemplate: `
              <a class="btn btn-xs btn-blue-border" ng-click="args.openTemplateModal(item)">
                  <i class="fa fa-pencil-square"></i>
              </a>
              <div ng-if="item.title" style="line-height: 2; display: inline-block; margin-left: 5px;" >{[{ item.title }]}</div>
              `,
            },
            {
              columnName: 'create_time',
              title: this.gettext('create time'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.create_time">
                  <i class="fa fa-clock-o"></i> {[{ ::item.create_time | date:'HH:mm' ||
                  '---' }]} - <i class="fa fa-calendar"></i> {[{ ::item.create_time |
                  date:'dd.MM' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'update_time',
              title: this.gettext('update time'),
              class: 'td-left-align',
              cellTemplate: `
                <span ng-if="item.update_time">
                  <i class="fa fa-clock-o"></i> {[{ ::item.update_time | date:'HH:mm' ||
                  '---' }]} - <i class="fa fa-calendar"></i> {[{ ::item.update_time |
                  date:'dd.MM' || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'editor',
              title: this.gettext('editor'),
              class: 'td-left-align',
              cellTemplate: `
                <span
                  class="tooltip"
                  data-tip="{[{ 'last updated' | translate }]} - {[{item.update_time | date:'dd.MM.yy HH:mm'}]} - {[{ item.editor_first_name }]} {[{ item.editor_last_name }]}, {[{ 'created' | translate }]} - {[{ item.author_first_name }]} {[{ item.author_last_name }]}"
                >
                  <div
                    class="user-avatar"
                    ng-if="item.editor_first_name && item.editor_avatar.length"
                  >
                    <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="!item.editor_avatar.length">
                    {[{ item.editor_first_name | cut:true:1:' ' }]}
                  </div>
                  {[{ item.editor_first_name }]}
                </span>
              `,
            },
          ],
        };
      }
    },
  ],
};
