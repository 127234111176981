import React from 'react';

import { TextFieldWrite } from '~/shared/ui/data-types';

import type { FilterTypeProps } from '../types';

export const TextFieldFilter: React.FC<FilterTypeProps> = ({ filterValue, onChange }) => {
  return (
    <TextFieldWrite
      value={filterValue.values[0]}
      intent="default"
      valueChanged={(input) => onChange({ ...filterValue, values: [input ?? ''] })}
    />
  );
};
