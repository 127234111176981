import ng from 'angular';

(function () {
  'use strict';
  ng.module('execution.legacy').component('tariffsTable', {
    bindings: {
      tariffs: '<',
      count: '<',
      filterLevel: '<?',
      onDelete: '&',
      onSave: '&',
    },
    template: require('./tariffs-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'gettext'];

  function Controller(this: any, $scope: ng.IScope, gettext: ng.gettext.gettextFunction) {
    const vm = this;

    vm.transType = null;
    vm.saveTariff = saveTariff;
    vm.deleteTariff = deleteTariff;

    vm.$onInit = function () {
      vm.tariffs = vm.tariffs || [];
      vm.filterLevel = vm.filterLevel || 'tariffs-table';
      vm.theadConfig = getTheadConfig();
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
      });
    };

    vm.$onChanges = function () {
      vm.transType = getTransType();
      vm.theadConfig = getTheadConfig();
    };

    ////////////////

    function saveTariff(tariff: any) {
      vm.onSave({ tariff: tariff });
    }

    function deleteTariff(tariff: any) {
      vm.onDelete({ tariff: tariff });
    }

    function getTransType() {
      return (
        vm.tariffs
          .map(function (tariff: any) {
            return tariff.transportation_type;
          })
          .reduce(function (a: any, b: any) {
            if (a == 0) {
              return b;
            }
            return a === b ? a : null;
          }, 0) || null
      );
    }

    function getTheadConfig() {
      const config: any = { tabs: [], columns: [] };

      config.columns = [
        {
          title: gettext('Commodity'),
          columnName: 'crop',
          predicate: 'crop',
          filters: [
            {
              type: 'ui-select',
              predicate: 'crop',
              label: gettext('crop'),
              resource: 'crops.Crop',
            },
          ],
        },
      ];

      if (vm.transType == 'railway' || vm.transType == 'auto') {
        config.columns.push({
          title: gettext('Forwarder'),
          columnName: 'forwarder',
          predicate: 'deliverer',
          filters: [
            {
              type: 'ui-select',
              predicate: 'deliverer',
              label: gettext('forwarder'),
              resource: 'clients.Deliverer',
            },
          ],
        });
      }

      if (vm.transType == 'broker' || vm.transType == 'surveyor' || vm.transType == 'elevator') {
        config.columns.push({
          title: gettext(vm.transType),
          columnName: 'type',
          predicate: vm.transType,
          filters: [
            {
              type: 'ui-select',
              predicate: vm.transType,
              label: gettext('Select' + vm.transType),
              resource: 'clients.' + vm.transType,
            },
          ],
        });
      }

      if (vm.transType == 'railway') {
        config.columns.push({
          title: gettext('Station depart'),
          columnName: 'station_depart',
          predicate: 'departure_station',
          filters: [
            {
              type: 'ui-select',
              predicate: 'departure_station',
              label: gettext('Depart station'),
              resource: 'logistics.Station',
            },
          ],
        });
      }

      if (vm.transType == 'railway') {
        config.columns.push({
          title: gettext('Station receive'),
          columnName: 'station_recive',
          predicate: 'destination_station',
          filters: [
            {
              type: 'ui-select',
              predicate: 'destination_station',
              label: gettext('Recieve station'),
              resource: 'logistics.Station',
            },
          ],
        });
      }

      if (vm.transType == 'railway') {
        config.columns.push({
          title: gettext('From'),
          columnName: 'from',
          predicate: 'elevator__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'elevator',
              label: gettext('elevator'),
              resource: 'clients.Elevator',
            },
          ],
        });
      }

      if (vm.transType == 'railway') {
        config.columns.push({
          title: gettext('To'),
          columnName: 'to',
          predicate: 'port__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'port',
              label: gettext('port'),
              resource: 'logistics.Port',
            },
            {
              type: 'ui-select',
              predicate: 'terminal',
              label: gettext('terminal'),
              resource: 'logistics.terminal',
            },
          ],
        });
      }

      if (vm.transType == 'auto') {
        config.columns.push({
          title: gettext('City'),
          columnName: 'city',
        });
      }

      if (vm.transType == 'auto') {
        config.columns.push({
          title: gettext('From'),
          columnName: 'from',
          predicate: 'elevator__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'elevator',
              label: gettext('elevator'),
              resource: 'clients.Elevator',
            },
            {
              type: 'ui-select',
              predicate: 'farm',
              label: gettext('farm'),
              resource: 'clients.farm',
            },
          ],
        });
      }

      if (vm.transType == 'auto') {
        config.columns.push({
          title: gettext('To'),
          columnName: 'to',
          predicate: 'port__name',
          filters: [
            {
              type: 'ui-select',
              predicate: 'port',
              label: gettext('port'),
              resource: 'logistics.Port',
            },
            {
              type: 'ui-select',
              predicate: 'terminal',
              label: gettext('terminal'),
              resource: 'logistics.terminal',
            },
            {
              type: 'ui-select',
              predicate: 'elevator',
              label: gettext('elevator'),
              resource: 'clients.Elevator',
            },
          ],
        });
      }

      config.columns.push(
        {
          title: gettext('Tariff'),
          columnName: 'tariff',
          predicate: 'cost',
          filters: [
            {
              type: 'text',
              predicate: 'min_price',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_price',
              addon: gettext('Max'),
            },
          ],
        },
        {
          title: gettext('Charge'),
          columnName: 'charge',
          predicate: 'charge',
          filters: [
            {
              type: 'ui-select',
              predicate: 'charge',
              label: gettext('charge'),
              resource: 'finances.charge',
            },
          ],
        },
      );

      if (vm.transType == 'broker' || vm.transType == 'surveyor' || vm.transType == 'elevator') {
        config.columns.push(
          {
            title: gettext('Currency rate'),
            columnName: 'currency_rate',
          },
          {
            title: gettext('Daily'),
            columnName: 'is_daily',
          },
          {
            title: gettext('Date from'),
            columnName: 'date_from',
          },
        );
      }

      config.columns.push(
        {
          title: gettext('VAT'),
          columnName: 'vat',
        },
        {
          title: gettext('Info'),
          columnName: 'info',
        },
        {
          title: gettext('Business unit'),
          columnName: 'business_unit',
          predicate: 'business_unit',
          filters: [
            {
              type: 'ui-select',
              predicate: 'business_unit',
              label: gettext('business unit'),
              resource: 'core.BusinessUnit',
            },
          ],
        },
        {
          title: gettext('Update'),
          columnName: 'update',
          predicate: 'update_time',
          class: 'update_time',
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
        },
        {
          title: gettext('Author'),
          columnName: 'author',
          predicate: 'author',
          filters: [
            {
              type: 'ui-select',
              predicate: 'author',
              label: gettext('autor'),
              resource: 'auth.User',
            },
          ],
        },
        {
          title: gettext(''),
          columnName: 'save',
        },
      );

      return config;
    }
  }
})();
