import { fromColumnParamsListData } from '~/features/data-view-fields/services/mappers/data-properties-dto';
import {
  CustomField,
  coreColumnParamsList,
  coreCustomFieldChoicesList,
  coreCustomFieldsList,
  coreCustomValuesCreate,
  coreCustomValuesList,
  coreCustomValuesPartialUpdate,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { ColumnDef, mapControlNameFromFieldType } from '~/shared/ui/components/data-grid';

import {
  ChangedUserValue,
  DataViewProperties,
  DataViewPropertiesDTO,
  UpdateUserValueDTO,
  UserColumnsParams,
} from '../lib';

// wtf
@singleton()
export class DataViewFieldsRepository {
  async getDataViewColumns(params: UserColumnsParams): Promise<ColumnDef[]> {
    let res;

    try {
      res = await coreCustomFieldsList(params);
    } catch (err) {
      throw new Error('Error fetching user columns', { cause: err });
    }

    return DataViewFieldsRepository.mapCustomFields(res.results);
  }

  async getProperties(dto: DataViewPropertiesDTO): Promise<DataViewProperties[]> {
    let res;

    try {
      res = await coreColumnParamsList({ tableName: dto.tableName });
    } catch (err) {
      throw new Error('Error fetching user columns', { cause: err });
    }

    return fromColumnParamsListData(res);
  }

  async updateDataViewValue(dto: UpdateUserValueDTO): Promise<void> {
    return DataViewFieldsRepository.saveUpdatedDataViewFields(dto);
  }

  static mapCustomFields(res: CustomField[]): ColumnDef[] {
    return (res ?? []).map((column) => ({
      accessorKey: column.key as ColumnDef['accessorKey'],
      id: String(column.id),
      header: column.title,
      dataType: mapControlNameFromFieldType[column.field_type],
      cellTemplateContext: {
        options: column.choices.map((choice) => ({
          value: choice.title,
          label: choice.title,
        })),
      },
    }));
  }

  static async getChoiceId(fieldId: number, value: string): Promise<number> {
    const choices = await coreCustomFieldChoicesList({ field: fieldId });
    const choice = choices.results.find((choice) => choice.title === value);
    if (!choice) {
      throw new Error(`Choice with value "${value}" not found for field ID ${fieldId}`);
    }
    return choice.id;
  }

  static async getMultiChoiceId(fieldId: number, values: string[]): Promise<number[]> {
    const choices = await coreCustomFieldChoicesList({ field: fieldId });
    return values.map((value) => {
      const choice = choices.results.find((choice) => choice.title === value);
      if (!choice) {
        throw new Error(`Choice with value "${value}" not found for field ID ${fieldId}`);
      }
      return choice.id;
    });
  }

  static getFieldValueKey(fieldType: string): string {
    const fieldTypeMapping: Record<string, string> = {
      number: 'value_number',
      text: 'value_text',
      string: 'value_string',
      choice: 'value_choice',
      date: 'value_date',
      multiple_choices: 'value_multiple_choices',
    };

    return fieldTypeMapping[fieldType] || 'value_string';
  }

  static async saveUpdatedDataViewFields(dto: UpdateUserValueDTO): Promise<void> {
    const customFields = await coreCustomFieldsList({ purposeModel: dto.purposeModel });
    const dtoEntities: ChangedUserValue[] = [];

    const getChangedValue = async (fieldId: number, value: ChangedUserValue['changedValue']) => {
      if (typeof value === 'object' && 'value' in value) {
        return await this.getChoiceId(fieldId, value.value);
      } else if (
        Array.isArray(value) &&
        value.every(
          (item): item is { label: string; value: string } =>
            typeof item === 'object' && 'value' in item,
        )
      ) {
        return await this.getMultiChoiceId(
          fieldId,
          value.map((item) => item.value),
        );
      } else {
        return value;
      }
    };

    await this.processDtoEntities(
      dto.changedValue,
      customFields.results,
      dtoEntities,
      getChangedValue,
    );
    await this.updateDtoValues(dtoEntities, dto.purposeModelId);
  }

  static async processDtoEntities(
    dto: UpdateUserValueDTO['changedValue'],
    customFields: CustomField[],
    dtoEntities: ChangedUserValue[],
    getChangedValue: (
      fieldId: number,
      value: ChangedUserValue['changedValue'],
    ) => Promise<ChangedUserValue['changedValue']>,
  ): Promise<void> {
    for (const field of customFields) {
      if (dto[field.key]) {
        const changedValue = await getChangedValue(field.id, dto[field.key]);
        dtoEntities.push({
          field,
          objectId: dto.id,
          changedValue,
          valueId: field.id,
        });
      }
    }
  }

  static async updateDtoValues(
    dtoEntities: ChangedUserValue[],
    purposeModelId: number,
  ): Promise<void> {
    for (const entity of dtoEntities) {
      const customValues = await coreCustomValuesList({
        objectId: entity.objectId,
        purpose: purposeModelId,
      });
      const fieldValueKey = this.getFieldValueKey(entity.field.field_type);
      const updatedCustomValue = customValues.results.find(
        (value) => value.field === entity.field.id,
      );

      if (updatedCustomValue) {
        const requestBody = {
          object_id: entity.objectId,
          [fieldValueKey]: entity.changedValue,
          purpose: purposeModelId,
          field: entity.field.id,
          field_id: entity.field.id,
        };

        await coreCustomValuesPartialUpdate({ id: updatedCustomValue.id, requestBody });
      } else {
        const requestBody = {
          object_id: entity.objectId,
          [fieldValueKey]: entity.changedValue,
          purpose: purposeModelId,
          field: entity.field.id,
        };

        await coreCustomValuesCreate({ requestBody });
      }
    }
  }
}
