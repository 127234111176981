import type { CoreService } from '~/app/core/core.service';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';

import template from './controls-table.html?raw';

export const ControlsTable = {
  bindings: {
    filterLevel: '<',
    controlsFilterLevel: '<?',
    controlsObjectId: '<?',
    modelName: '<?',
  },
  template,
  controller: [
    'ControlsService',
    'CoreService',
    'GtUtils',
    'gettext',
    class {
      ControlsService: any;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      controlConfig: any;
      controls: any;
      controlsObjectId: any;
      filterLevel = 'controls-table';
      gettext: ng.gettext.gettextFunction;
      modelId: any;
      modelName: any;
      newControl: any;
      showAllControls: any;
      constructor(
        ControlsService: any,
        CoreService: CoreService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.ControlsService = ControlsService;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.showAllControls = false;
      }

      $onInit() {
        this.updateControls();
      }

      updateControls() {
        this.getControlConfig();
        if (this.modelName) {
          this.CoreService.getModelContentTypeId().then((data: any) => {
            this.modelId = data.results.find((i: any) => i.model === this.modelName).id;
            this.getControls();
          });
        } else {
          this.getControls();
        }
      }

      getControlConfig() {
        this.ControlsService.getControlConfig({
          filter_level: this.filterLevel,
        }).then((data: any) => {
          this.controlConfig = data.results?.[0] || [];
          this.newControl = this.ControlsService.getNewControl(this.controlConfig.id);
        });
      }

      getControls() {
        const params = { filter_level: this.filterLevel, page_size: 5 };
        if (this.modelId) {
          // @ts-ignore
          params.content_type = this.modelId;
          // @ts-ignore
          params.object_id = this.controlsObjectId;
        }

        this.ControlsService.getControls(params).then((data: any) => {
          this.controls = data.results || [];
          this.sortControlsDesc();
        });
      }

      sortControlsDesc() {
        this.controls.sort((a: any, b: any) => {
          const dateA = new Date(a.last_create_time).getTime();
          const dateB = new Date(b.last_create_time).getTime();
          return dateB - dateA;
        });
      }

      saveControl(item: any) {
        if (!confirm(this.GtUtils.translate(this.gettext('Do you want to create a control?')))) {
          return;
        }

        if (this.controlsObjectId && this.modelId) {
          item.content_type = this.modelId;
          item.object_id = this.controlsObjectId;
        }

        this.ControlsService.saveControl(item)
          .then(() => this.updateControls())
          .catch((e: any) => this.GtUtils.errorClb(e));
      }

      toggleControlsList() {
        this.showAllControls = !this.showAllControls;
      }
    },
  ],
};
