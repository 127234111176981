import React from 'react';

import type { FilterOption, FilterValue, Preset, SortingOption, SortingValue } from '../../types';
import { FilterItemChanged } from '../filter-item-changed';
import { FilterItemDisabled } from '../filter-item-disabled';
import { FiltersPresetSelector } from '../filters-preset-selector';
import { FiltersSelect } from '../filters-select';
import { FiltersSortSelector } from '../filters-sort-selector';

type PresetChanges = {
  changed: FilterValue[];
  deleted: FilterValue[];
  ordering: boolean;
  dirty: boolean;
};

export const FiltersLine: React.FC<{
  preset: Preset;
  presetOptions: Preset[];
  sortingOptions: SortingOption[];
  filtersOptions: FilterOption[];
  onChange: (filterSet: Preset) => void;
  onSave: (filterSet: Preset) => void;
  onDelete: (filterSet: Preset) => void;
}> = ({ preset, presetOptions, sortingOptions, filtersOptions, onChange, onSave, onDelete }) => {
  const filterOptionsMap = React.useMemo(
    () =>
      filtersOptions.reduce<Record<FilterOption['argument'], FilterOption>>(
        (acc, filter) => ({ ...acc, [filter.argument]: filter }),
        {},
      ),
    [filtersOptions],
  );

  const presetChanges = React.useMemo(() => {
    const changes: PresetChanges = { changed: [], deleted: [], ordering: false, dirty: false };

    if (!preset.id) {
      return changes;
    }

    const savedPreset = presetOptions.find((presetOp) => presetOp.id === preset.id);

    changes.changed =
      preset.filters.filter(
        (currentFilter) =>
          JSON.stringify(currentFilter) !==
          JSON.stringify(savedPreset?.filters.find((f) => f.argument === currentFilter.argument)),
      ) ?? [];

    changes.deleted =
      savedPreset?.filters.filter(
        (savedFilter) => !preset.filters.find((f) => f.argument === savedFilter.argument),
      ) ?? [];

    changes.ordering = preset.sorting?.argument !== savedPreset?.sorting?.argument;
    changes.dirty = changes.changed.length > 0 || changes.deleted.length > 0 || changes.ordering;

    return changes;
  }, [preset, presetOptions]);

  const sortChangedHandler = React.useCallback(
    (sorting?: SortingValue) => {
      onChange({ ...preset, sorting });
    },
    [preset, onChange],
  );

  const filterDeletedHandler = React.useCallback(
    (filter: FilterValue) => {
      onChange({
        ...preset,
        filters: preset.filters.filter((f) => f.argument !== filter.argument),
      });
    },
    [preset, onChange],
  );

  const filterAddedHandler = React.useCallback(
    (filter: FilterValue) => {
      onChange({ ...preset, filters: [...preset.filters, filter] });
    },
    [preset, onChange],
  );

  const presetResetHandler = React.useCallback(() => {
    onChange(presetOptions.find((presetOp) => presetOp.id === preset.id) ?? preset);
  }, [presetOptions, preset, onChange]);

  return (
    <ul className="flex items-center justify-between gap-3 border-y border-stroke-main-medium px-4">
      <div className="flex items-center gap-1">
        <li className="flex items-center">
          <div className="border-r border-stroke-main-medium py-2 pr-3">
            <FiltersSortSelector
              sorting={preset.sorting}
              sortingOptions={sortingOptions}
              onChange={sortChangedHandler}
            />
          </div>
          <div className="pl-3">
            <FiltersPresetSelector
              preset={preset}
              presetOptions={presetOptions}
              onSelect={onChange}
              onSave={onSave}
              onDelete={onDelete}
            />
          </div>
        </li>
        <li className="flex items-center gap-1">
          {presetChanges.changed.map((filter) => (
            <FilterItemChanged
              key={filter.argument}
              option={filterOptionsMap[filter.argument]}
              value={filter}
              onDelete={filterDeletedHandler}
              onChange={filterAddedHandler}
            />
          ))}
          {presetChanges.deleted.map((filter) => (
            <FilterItemDisabled
              key={filter.argument}
              value={filter}
              option={filterOptionsMap[filter.argument]}
              onDelete={filterDeletedHandler}
            />
          ))}
          <FiltersSelect
            filterValues={preset.filters}
            filterOptions={filtersOptions}
            onChange={filterAddedHandler}
          />
        </li>
      </div>
      <li>
        <button className="text-xs font-medium text-text-main-light" onClick={presetResetHandler}>
          Reset
        </button>
      </li>
    </ul>
  );
};
