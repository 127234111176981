import React from 'react';

import { ArrowBottomIcon } from '~/shared/ui/icons';
import { DropdownMenu, DropdownMenuTrigger } from '~/shared/ui/kit/dropdown-menu';

import { FilterItemOptions } from './filter-item-options';
import type { FilterOption, FilterValue } from '../../types';

export const FilterItemDisabled: React.FC<{
  value: FilterValue;
  option: FilterOption;
  onDelete: (filter: FilterValue) => void;
}> = ({ value, option, onDelete }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex h-6 items-center gap-1 rounded-sm border border-stroke-main-medium bg-background-main-primary px-1">
          <p className="text-xs font-medium capitalize text-text-main-tertiary">{option.title}</p>
          <ArrowBottomIcon size={14} className="text-stroke-main-stronger" />
        </div>
      </DropdownMenuTrigger>
      <FilterItemOptions option={option} value={value} onDelete={onDelete} />
    </DropdownMenu>
  );
};
