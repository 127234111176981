import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';

export const SalePlanReportTableView = {
  bindings: {
    filterLevel: '<',
    data: '<',
    totals: '<',
    onSave: '&',
    onDelete: '&',
  },
  template: '<gt-table-new options="$ctrl.tableOptions" data="$ctrl.tableData"></gt-table-new>',
  controller: [
    'gettext',
    'gtFilterService',
    'SalePlanReportService',
    class {
      data: any;
      filterLevel = 'sale-plan-report-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      onDelete: any;
      onSave: any;
      tableData: any;
      tableOptions: any;
      totals: any;
      constructor(gettext: ng.gettext.gettextFunction, gtFilterService: GtFilterService) {
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;

        this.tableData = {};
      }

      $onInit() {
        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.data || changes.totals) {
          this.tableData = { rows: this.data.results, count: this.data.count, total: this.totals };
        }
      }

      cloneData(data: any) {
        Object.assign(this.tableData.rows[0], data, {
          status: 'forecast',
          author: undefined,
          author_avatar: undefined,
          author_first_name: undefined,
          create_time: undefined,
          editor: undefined,
          editor_avatar: undefined,
          editor_first_name: undefined,
          update_time: undefined,
          id: undefined,
          invoiced_amount: undefined,
          payments: undefined,
          invoiced: undefined,
          payments_amount_sum: undefined,
          finances: [],
        });
      }

      getTableOptions() {
        return {
          tableName: 'market-prices',
          tableClass: ['table-hover', 'contract-charges-table'],
          filterLevel: this.filterLevel,
          applyFilters: (args: any) =>
            this.gtFilterService.updateQueryParams(args.params, this.filterLevel),
          templateArgs: {
            save: (item: any) => this.onSave({ item: item }),
            delete: (item: any) => this.onDelete({ item: item }),
            cloneData: (item: any) => this.cloneData(item),
          },
          configurable: true,
          columnDefs: [
            {
              columnName: 'commodity',
              title: this.gettext('Commodity'),
              class: 'td-left-align',
              cellTemplate: `
                <div ng-if="!args.rows[item.id].editable && item.id">
                <span class="label-strong"> {[{ item.commodity_title }]} </span>
                </div>
                <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                  <gt-resource-select
                    ng-model="item.commodity"
                    placeholder="'Commodity'|translate"
                    resource-name="'crops.Crop'"
                    allow-clear="true"
                    required
                  ></gt-resource-select>
                </div> 
              `,
              totalTemplate:
                ' <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>',
            },
            {
              columnName: 'productionYear',
              title: this.gettext('Production Year'),
              predicate: 'production_year',
              class: 'td-left-align',
              cellTemplate: `
                <div ng-if="!args.rows[item.id].editable && item.id">
                  <i class="fa fa-calendar"></i> {[{item.production_year | date:'yyyy'}]}
                </div>
                <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                  <gt-date-select
                    min-view="'year'"
                    start-view="'year'"
                    date-model="item.production_year"
                    placeholder=" 'Production Year' | translate"
                    use-watch="true"
                  ></gt-date-select>
                </div>
              `,
            },
            {
              columnName: 'startDate',
              title: this.gettext('Execution (start date)'),
              class: 'td-left-align',
              cellTemplate: `
                <div ng-if="!args.rows[item.id].editable && item.id">
                  <i class="fa fa-calendar nums"></i> {[{item.start_date | date:'dd/MM/yy'}]}
                </div>
                <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                  <gt-date-select
                    date-model="item.start_date"
                    placeholder="'Execution (start date)' | translate"
                    use-watch="true"
                  ></gt-date-select>
                </div>
              `,
            },
            {
              columnName: 'endDate',
              class: 'td-left-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                <i class="fa fa-calendar nums"></i> {[{item.end_date | date:'dd/MM/yy'}]}
              </div>
              <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                <gt-date-select
                  date-model="item.end_date"
                  placeholder="'Execution (end date)' | translate"
                  use-watch="true"
                ></gt-date-select>
              </div>
            `,
              title: this.gettext('Execution (end date)'),
            },
            {
              columnName: 'volume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id" class="td-right-align">
                <span class="label-strong">{[{ item.volume || 0 | number:2 }]} </span> <span>t</span>
              </div>
              <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                <input type="number" class="form-control" ng-model="item.volume" />
              </div>
            `,
              title: this.gettext('Volume (t)'),
              totalTemplate:
                '<span class="label-strong">{[{ item.volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              columnName: 'price',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                <span class="label-strong">{[{ item.price || 0 | number: 2 }]}</span> 
              </div>
              <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                <input type="number" class="form-control" ng-model="item.price" />
              </div>
            `,
              title: this.gettext('Price'),
            },
            {
              columnName: 'currencyAndRate',
              class: 'td-left-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                <span ng-if="item.currency_symbol"> {[{ item.currency_symbol }]} </span>
                <span ng-if="item.currency_exchange_rate"> {[{ item.currency_exchange_rate || '---' | number:6 }]}</span>
              </div>
              <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
                <gt-resource-select
                  ng-model="item.currency"
                  placeholder="'Currency'|translate"
                  resource-name="'finances.currency'"
                  allow-clear="false"
                ></gt-resource-select>
                <gt-resource-select
                  ng-model="item.currency_exchange"
                  placeholder="'Rate'|translate"
                  resource-name="'finances.currencyexchange'"
                  allow-clear="true"
                  query-params="{local_currency: item.currency}"
                  ng-disable="!item.currency"
                ></gt-resource-select>
              </div> `,
              title: this.gettext('currency & Exchange Rate'),
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.currency_symbol }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Plan amount'),
              columnName: 'amount',
              class: 'td-right-align',
              cellTemplate: `
                <div ng-if="!args.rows[item.id].editable && item.id" class="td-right-align">
                 {[{ item.amount || 0 | number:2 }]}
                </div>
              `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.amount || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Contract volume (t)'),
              columnName: 'contracsTrueVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.contracts_true_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.contracts_true_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Contract amount'),
              columnName: 'contracsTotalValue',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.contracts_total_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.contracts_total_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Execution (t)'),
              columnName: 'contracsTransportOpenBalanceVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.contracts_transport_open_balance_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.contracts_transport_open_balance_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Execution amount'),
              columnName: 'contracsTransportOpenBalanceValue',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.contracts_transport_open_balance_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.contracts_transport_open_balance_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Paid volume (t)'),
              columnName: 'contracsPaymentVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.contracts_payment_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.contracts_payment_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Paid amount'),
              class: 'td-right-align',
              columnName: 'contracsPaymentValue',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.contracts_payment_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.contracts_payment_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Stock plan (t)'),
              columnName: 'balanceTransportVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_transport_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.balance_transport_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Stock plan amount'),
              columnName: 'balanceTransportValue',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_transport_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.balance_transport_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Contract stock (t)'),
              columnName: 'balanceContractVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_contract_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.balance_contract_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Contract stock amount'),
              columnName: 'balanceContractValue',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_contract_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.balance_contract_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Planned balance (t)'),
              columnName: 'balancePaymentVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_payment_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.balance_payment_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Planned balance amount'),
              columnName: 'balancePaymentValue',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_payment_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.balance_payment_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Payment stock (t)'),
              columnName: 'balanceTransportPaymentVolume',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_transport_payment_volume || 0 | number:2 }]}
              </div>
            `,
              totalTemplate:
                '<span class="label-strong">{[{ item.balance_transport_payment_volume || 0 | number:2 }]} </span> <span>t</span>',
            },
            {
              title: this.gettext('Payment stock amount'),
              columnName: 'balanceTransportPaymentValue',
              class: 'td-right-align',
              cellTemplate: `
              <div ng-if="!args.rows[item.id].editable && item.id">
                 {[{ item.balance_transport_payment_value || 0 | number:2 }]}
              </div>
            `,
              totalTemplate: `<span class="label-strong" ng-repeat="currency in item.currency_list">
                {[{ currency.balance_transport_payment_value || 0 | number:2 }]}
                <div class="clearfix"></div>
              </span>`,
            },
            {
              title: this.gettext('Save'),
              columnName: 'save',
              classExpr: "{ 'edit': args.rows[item.id].editable || !item.id }",
              cellTemplate: `
                <div ng-if="!args.rows[item.id].editable && item.id">
                <a class="btn btn-xs btn-add-dark hover-element">
                  <i
                    class="fa fa-pencil-square"
                    ng-click="args.rows[item.id].editable = true;"
                  ></i>
                  </a>
                  <a class="btn btn-xs btn-add-dark hover-element">
                  <i
                    class="fa fa-files-o"
                    ng-click="args.cloneData(item); args.scrollToTop()"
                ></i>
                </a>
                  <a
                    ng-if="item.id && item.contracts_id_list.length > 0"
                    class="btn btn-xs btn-add-dark hover-element"
                    ui-sref="reports.statistics({ contract_id_list: item.contracts_id_list, contract_type: 'sale', title: 'Statistics' })"
                    target="_blank"
                  >
                    <i class="fa fa-arrow-right"></i>
                 </a>
                </div>
                <div  ng-if="args.rows[item.id].editable || !item.id" class="edit">
                  <div class="input-group-btn">
                    <a
                      class="btn btn-xs btn-success btn_success"
                      ng-click="args.save(item); args.rows[item.id].editable = false;"
                    >
                      <i class="fa fa-floppy-o"></i> <translate>Save</translate>
                    </a>
                  </div>
                  <div ng-if="item.id" class="input-group-btn">
                    <a class="btn btn-xs" ng-click="args.rows[item.id].editable=false" >
                      <i class="fa fa-times"></i>
                    </a>
                  </div>
                  <div ng-if="item.id" class="input-group-btn">
                    <a
                      class="btn btn-xs btn-danger btn_danger"
                      ng-click="args.delete(item); args.rows[item.id].editable = false"
                      
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </div> `,
            },
          ],
        };
      }
    },
  ],
};
