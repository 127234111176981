import ng from 'angular';

import { EventSubscriptionList } from './event-subscription-list/event-subscription-list.component';
import { EventSubscriptionModal } from './event-subscription-modal/event-subscription-modal.component';
import { notification } from './notification/notification.component';
import { notificationsPageView } from './notifications-page-view/notifications-page-view.component';
import { NotificationService } from './notifications.service';
import { ScreenCaptureTaskModal } from './screen-capture-task-modal/screen-capture-task-modal.component';
import { templatePlayground } from './template-playground/template-playground.component';
import { templatePlaygroundPage } from './template-playground-page/template-playground-page.component';

const notificationsConfig = [
  '$stateProvider',
  'NotificationProvider',
  'gettext',
  (
    $stateProvider: ng.ui.IStateProvider,
    NotificationProvider: any,
    gettext: ng.gettext.gettextFunction,
  ) => {
    $stateProvider
      .state('settings.notifications', {
        url: '/notifications',
        component: 'notificationsPageView',
        data: {
          pageTitle: gettext('Notifications'),
        },
      })
      .state('settings.templatePlayground', {
        url: '/template-playground',
        component: 'templatePlaygroundPage',
        data: {
          pageTitle: gettext('Template playground'),
        },
      });

    NotificationProvider.setOptions({
      positionX: 'left',
    });
  },
];

export const notifications = ng
  .module('settings.notifications', [])
  .service('NotificationService', NotificationService)
  .component('notificationsPageView', notificationsPageView)
  .component('notification', notification)
  .component('eventSubscriptionList', EventSubscriptionList)
  .component('eventSubscriptionModal', EventSubscriptionModal)
  .component('templatePlaygroundPage', templatePlaygroundPage)
  .component('templatePlayground', templatePlayground)
  .component('screenCaptureTaskModal', ScreenCaptureTaskModal)
  .config(notificationsConfig).name;
