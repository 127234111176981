import React from 'react';

import { ActionBar, type ActionBarConfig } from '~/shared/ui/components/action-bar';

export const InlineEditToolbar: React.FC<{ config: ActionBarConfig[] }> = ({ config }) => {
  return (
    <ActionBar
      title={
        <span className="text-xs font-medium text-text-main-primary">Inline Editing Mode</span>
      }
      config={config}
    />
  );
};
