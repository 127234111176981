import React from 'react';

import { CloseEyeIcon } from '~/shared/ui/icons';
import { Switch } from '~/shared/ui/kit/switch';

export const TitleIcons: React.FC<{
  isShow: boolean;
  showChanged: () => void;
}> = ({ isShow, showChanged }) => {
  return (
    <section className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <CloseEyeIcon size={14} className="text-stroke-main-stronger" />
        <p className="text-xs text-text-main-primary">Show Title Icons</p>
      </div>
      <Switch
        size="small"
        checked={isShow}
        onCheckedChange={showChanged}
        data-testid="title-icons-switch"
      />
    </section>
  );
};
