import type ng from 'angular';

import type { GtRootScopeService } from '~/app/core/types';

import template from './client-list-table-view.html?raw';

export const ClientListTableView = {
  bindings: {
    clients: '<',
    count: '<',
    filterLevel: '<',
    activeFilterPresets: '<',
    applyFilters: '&',
    openClientModal: '&',
    openClientDetails: '&',
    openEmailModal: '&',
    approveClient: '&',
    createConfirmationTask: '&',
    getQueryParams: '&',
    openDocxModal: '&',
    cloneClient: '&',
  },
  template,
  controller: [
    '$rootScope',
    'AccountsService',
    'gettext',
    'ClientFormFieldsService',
    class {
      $rootScope: GtRootScopeService;
      AccountsService: any;
      ClientFormFieldsService: any;
      activeFilterPresets: any;
      applyFilters: any;
      clientRoles: any;
      clients: any;
      cloneClient: any;
      count = 0;
      createConfirmationTask: any;
      filterLevel = 'client-list-table-view';
      getQueryParams: any;
      gettext: ng.gettext.gettextFunction;
      openClientDetails: any;
      openClientModal: any;
      openDocxModal: any;
      openEmailModal: any;
      tableData: any;
      tableOptions: any;
      constructor(
        $rootScope: GtRootScopeService,
        AccountsService: any,
        gettext: ng.gettext.gettextFunction,
        ClientFormFieldsService: any,
      ) {
        this.$rootScope = $rootScope;
        this.AccountsService = AccountsService;
        this.gettext = gettext;
        this.ClientFormFieldsService = ClientFormFieldsService;

        this.clients = this.clients || [];
        this.count = this.count || 0;

        this.tableOptions = {};
        this.tableData = {};
        this.clientRoles = [];
      }

      $onInit() {
        this.clientRoles = [
          'buyer',
          'supplier',
          'exporter',
          'broker',
          'deliverer',
          'surveyor',
          'insurer',
          'farm',
          'elevator',
          'bank',
          'owner',
          'other',
        ];
        this.setTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.activeFilterPresets) {
          this.setTableOptions();
        }

        if (changes.clients || changes.count) {
          this.tableData = { rows: this.clients, count: this.count };
        }
      }

      setTableOptions() {
        const options: any = {
          tableName: 'clients',
          tableAltClass: 'sticky-table-first-td-ultra-condensed',
          activeFilterPresets: this.activeFilterPresets,
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          templateArgs: {
            clientRoles: this.clientRoles,
            $rootScope: this.$rootScope,
            openClientModal: (client: any, tab: any) =>
              this.openClientModal({ client: client, tab: tab }),
            openClientDetails: (client: any, tab: any) =>
              this.openClientDetails({ client: client, tab: tab }),
            openEmailModal: (client: any) => this.openEmailModal({ client: client }),
            createConfirmationTask: (client: any) =>
              this.createConfirmationTask({ client: client }),
            approvalAction: (action: any, clientId: any, clientType: any) =>
              this.AccountsService.voteApprovable(action, clientId, clientType).then(() =>
                this.applyFilters(),
              ),
            getQueryParams: () => this.getQueryParams(),
            openDocxModal: (client: any) => this.openDocxModal({ client: client }),
            cloneClient: (client: any, tab: any) => this.cloneClient({ client: client, tab: tab }),
          },
          columnDefs: [] as any[],
          tabs: [] as any[],
          configurable: true,
          showMore: true,
          changePageSize: true,
        };

        options.tabs = [
          {
            serializer: 'table_info',
            title: this.gettext('Info'),
            icon: 'fa-info-circle',
            columns: [
              'title',
              'company_group_title',
              'role',
              'custom_role',
              'affiliated',
              'related_counterparties',
              'company_code',
              'status',
              'approval_status',
              'regular_status',
              'custom_status',
              'phone',
              'business_units',
              'address',

              'stuff',
              'updates',
              'last_update',
              'relations',
              'docs',
              'contracts',
              'invoices',
              'logistics',
              'notes',
              'create_time',
              'update_time',
              'author',
              'editor',
              'country_name',
              'region',
              'district',
              'city',
              'current_address',
              'person',
              'phone_additional',
              'email',
              'website',
              'sap_vendor_number',
              'abbreviation',
              'voyages',
              'renewal_date',
              'predictions_order',
              'warehouses',
              'payment_condition_options',
            ],
          },
          {
            serializer: 'table_legal',
            title: this.gettext('Legal'),
            icon: 'fa-gavel',
            columns: [
              'title',
              'company_group_title',
              'role',
              'custom_role',
              'affiliated',
              'related_counterparties',
              'company_code',
              'status',
              'approval_status',
              'phone',
              'business_units',
              'term_of_proxy',
              'days_before_deadline_to_notify',
              'client_data_is_checked',
              'bank_data_is_checked',
              'full_name',
              'full_name_eng',
              'address',
              'address_eng',
              'person_signatory',
              'person_signatory_eng',
              'person_signatory_docs',
              'person_signatory_eng_docs',
              'country_name',
              'region',
              'district',
              'city',
              'current_address',
              'person',
              'phone_additional',
              'email',
              'website',

              'stuff',
              'updates',
              'relations',
              'docs',
              'notes',
              'create_time',
              'update_time',
              'warehouses',
            ],
          },
          {
            serializer: 'table_dates',
            title: this.gettext('Confirmation'),
            icon: 'fa-calendar-o',
            columns: [
              'title',
              'company_group_title',
              'role',
              'custom_role',
              'warehouses',
              'affiliated',
              'related_counterparties',
              'company_code',
              'status',
              'approval_status',
              'phone',
              'business_units',
              'country_name',
              'region',
              'district',
              'city',
              'current_address',
              'person',
              'phone_additional',
              'email',
              'website',
              'address',

              'approvals',
              'can_confirm',
              'renewal_date',
              'date_request',
              'date_received',
              'date_first_confirmation',
              'date_confirmation',
              'first_contact',
              'future_contact',

              'stuff',
              'updates',
              'relations',
              'docs',
              'notes',
              'create_time',
              'update_time',
              'author',
              'editor',
            ],
          },
          {
            serializer: 'table_special',
            title: this.gettext('Special'),
            icon: 'fa-info-circle',
            columns: [
              'title',
              'company_group_title',
              'role',
              'custom_role',
              'warehouses',
              'affiliated',
              'related_counterparties',
              'company_code',
              'status',
              'approval_status',
              'phone',
              'business_units',
              'country_name',
              'region',
              'district',
              'city',
              'current_address',
              'person',
              'phone_additional',
              'email',
              'website',
              'address',

              'special',
              'local_currency',
              'foreign_currency',
              'basises',
              'size',

              'stuff',
              'updates',
              'relations',
              'docs',
              'notes',
              'create_time',
              'update_time',
              'author',
              'editor',
            ],
          },
          {
            serializer: 'table_limits',
            title: this.gettext('Risk'),
            icon: 'fa-bolt',
            columns: [
              'title',
              'company_group_title',
              'role',
              'custom_role',
              'warehouses',
              'affiliated',
              'related_counterparties',
              'company_code',
              'status',
              'approval_status',
              'phone',
              'business_units',
              'country_name',
              'region',
              'district',
              'city',
              'current_address',
              'person',
              'phone_additional',
              'email',
              'website',
              'address',

              'limit_one_deal_volume_spot',
              'limit_general_volume_spot',
              'limit_open_balance_volume_spot',
              'limit_one_deal_in_usd_spot',
              'limit_general_in_usd_spot',
              'limit_one_deal_volume_forward',
              'limit_general_volume_forward',
              'limit_open_balance_volume_forward',
              'limit_one_deal_in_usd_forward',
              'limit_general_in_usd_forward',

              'stuff',
              'updates',
              'relations',
              'docs',
              'notes',
              'create_time',
              'update_time',
              'author',
              'editor',
            ],
          },
        ];

        if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_potentials) {
          options.tabs.push({
            serializer: 'table_potentials',
            title: this.gettext('Potentials'),
            icon: 'fa-square',
            columns: [
              'title',
              'status',
              'warehouses',
              'region',
              'district',
              'city',
              'crop_area',
              'cash_flow',
              'assets',
              'pledge',
              'transports_count',
              'warehouse_distances',
              'person',
              'phone',
              'crop_areas',
              'notes',
              'last_clientupdate',
              'next_clientupdate',
              'business_units',
            ],
          });
        }
        options.columnDefs = [
          {
            columnName: 'title',
            title: this.gettext('Title'),
            predicate: 'name',
            showAlways: true,
            class: 'name td-left-align',
            hint: this.gettext('the short title of the role (for internal use)'),
            cellTemplate: /*html*/ `
              <div class="space-between">
                <ul class="nav navbar-nav item-settings-dropdown">
                  <li>
                    <a
                      class="dropdown-toggle user-dropdown-btn long-title"
                      ui-sref="gt.page.client({id: item.id})"
                    >
                      <i class="fa fa-building"></i> {[{ item.name | cut:true:150:' '}]}
                      <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                      <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                    </a>
                  </li>
                </ul>

                <ul
                  class="nav navbar-nav item-settings-dropdown actions-btn pull-right"
                  ng-mouseenter="args.setHovering(true)"
                  ng-mouseleave="args.setHovering(false)"
                >
                  <li>
                    <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                      <i class="fa fa-ellipsis hide-on-hover"></i>
                      <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                    </a>
                    <ul class="dropdown-menu wrapped-title">
                      <li>
                        <a
                          class="btn btn-xs col-xs-12"
                          ui-sref="gt.page.client({id: item.id})"
                        >
                          <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                        </a>
                      </li>
                      <li>
                        <a class="btn btn-xs col-xs-12" ng-click="args.openClientModal(item)">
                          <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                        </a>
                      </li>
                      <li>
                        <a class="btn btn-xs col-xs-12" ng-click="args.cloneClient(item)">
                          <i class="fa fa-files-o"></i> <translate>Clone</translate>
                        </a>
                      </li>
                      <li>
                        <a class="btn btn-xs col-xs-12" ng-click="args.openEmailModal(item)">
                          <i class="fa fa-envelope"></i> <translate>Email</translate>
                        </a>
                      </li>
                      <li ng-if="item.approval_config">
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('approve', item.id, item.content_type );"
                        >
                          <i class="fa fa-thumbs-up"></i> <translate>Approve</translate>
                        </a>
                      </li>
                      <li ng-if="item.approval_config">
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-click="args.approvalAction('decline', item.id, item.content_type);"
                        >
                          <i class="fa fa-thumbs-down"></i> <translate>Decline</translate>
                        </a>
                      </li>
                      <li ng-if="item.approval_config">
                        <a
                          class="btn btn-xs col-xs-12"
                          ng-disabled="item.approval_status != 'rejected'"
                          ng-click="args.approvalAction('reactivate', item.id, item.content_type );"
                        >
                          <i class="fa fa-refresh"></i>
                          <translate>Reactivate approving</translate>
                        </a>
                      </li>
                      <li>
                        <a class="btn btn-xs aligned-btn" ng-click="args.openDocxModal(item)">
                          <i class="fa fa-file" aria-hidden="true"></i> +
                          <translate>Template</translate>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>

                <span
                  class="label label_default pull-right"
                  ng-if="item.producer"
                  style="margin-left: 2px"
                >
                  <translate>Producer</translate>
                </span>
              </div>
            `,
          },

          {
            columnName: 'company_group_title',
            title: this.gettext('Company group'),
            predicate: 'company_group_title',
            class: 'company_code td-left-align',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.company_group_title"
                style="margin-left: 2px;"
              >
                <i class="fa fa-briefcase"></i> {[{ item.company_group_title | cut:true:25:' '}]}
              </span>
            `,
          },
          {
            columnName: 'related_counterparties',
            title: this.gettext('Related companies'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span ng-if="item.related_counterparties && item.related_counterparties.length <= 3">
                <a ng-repeat="company in item.related_counterparties" ng-click="args.openClientModal(company)">
                <i class="fa fa-chain"></i> {[{ company.name | cut:true:30:' ...' }]}
                </a>
              </span>
              <span class="btn-link"
                  ng-if="item.related_counterparties && item.related_counterparties.length > 3"
                  ng-click="item.companiesExpanded = !item.companiesExpanded"
                >
                  <i class="fa fa-chain"></i> {[{item.related_counterparties.length}]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.companiesExpanded">
                  <a ng-repeat="company in item.related_counterparties" ng-click="args.openClientModal(company)">
                  <i class="fa fa-chain"></i> {[{ company.name | cut:true:30:' ...' }]}
                    <div class="clearfix"></div>
                  </a>
                </span>
              <span ng-if="!item.related_counterparties.length"> --- </span>
            `,
          },
          {
            columnName: 'affiliated',
            title: this.gettext('Affiliated'),
            class: 'company_code td-left-align small-font-cell',
            cellTemplate: /*html*/ `
               <span ng-if="item.supplier_names && item.supplier_names.length <= 3">
                <span ng-repeat="name in item.supplier_names">
                  <i class="fa fa-bookmark-o }]}"></i>
                  {[{ name | cut:true:35:' ' }]}
                </span>
              </span>
              <span class="btn-link"
                ng-if="item.supplier_names && item.supplier_names.length > 3"
                ng-click="item.suppliersExpanded = !item.suppliersExpanded"
              >
                <i class="fa fa-bookmark-o"></i> {[{item.supplier_names.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.suppliersExpanded">
                <span class="smaller-label" ng-repeat="name in item.supplier_names">
                  <i class="fa fa-bookmark-o"></i>
                  {[{ name | cut:true:35:' ' }]}
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.supplier_names"> --- </span>


              <span ng-if="item.exporter_names && item.exporter_names.length <= 3">
                <span ng-repeat="name in item.exporter_names track by $index">
                  <i class="fa fa-shield }]}"></i>
                  {[{ name | cut:true:35:' ' }]}
                </span>
              </span>
              <span class="btn-link"
                ng-if="item.exporter_names && item.exporter_names.length > 3"
                ng-click="item.exportersExpanded = !item.exportersExpanded"
              >
                <i class="fa fa-shield"></i> {[{item.exporter_names.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.exportersExpanded">
                <span class="smaller-label" ng-repeat="name in item.exporter_names track by $index">
                  <i class="fa fa-shield"></i>
                  {[{ name | cut:true:35:' ' }]}
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.exporter_names"> --- </span>


            `,
          },
          {
            columnName: 'role',
            title: this.gettext('Role'),
            class: 'role td-left-align small-font-cell',
            hint: this.gettext(
              'the roles in which this legal entity will be used in the system. It could be multiple roles, e.g. Buyer and Seller',
            ),
            cellTemplate: /*html*/ `
              <div ng-if="item.$_edit">
                <select multiple ng-model="item.role_names">
                  <option ng-repeat="role in args.clientRoles" value="{[{ role }]}">
                    {[{ role | translate }]}
                  </option>
                </select>
              </div>
              <span ng-if="!item.$_edit && item.role_names && item.role_names.length <= 3">
                <span ng-repeat="role_name in item.role_names track by $index">
                  <i class="fa {[{ $root.gtUtils.getIcon('clients.' + role_name) }]}"></i>
                  {[{ role_name | translate }]}
                </span>
              </span>

              <span class="btn-link"
                ng-if="!item.$_edit && item.role_names && item.role_names.length > 3"
                ng-click="item.roleNamesExpanded = !item.roleNamesExpanded"
              >
                <i class="fa fa-list"></i> {[{item.role_names.length}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.roleNamesExpanded">
                <span class="smaller-label" ng-repeat="role_name in item.role_names track by $index">
                  <i class="fa {[{ $root.gtUtils.getIcon('clients.' + role_name) }]}"></i>
                  {[{ role_name | translate }]}
                  <div class="clearfix"></div>
                </span>
                <span ng-if="item.custom_clientroles_name_list">
                </span>
              </span>
              <span ng-if="!item.$_edit && !item.role_names"> --- </span>


            `,
          },
          {
            columnName: 'custom_role',
            title: this.gettext('Custom Role'),
            class: 'role td-left-align small-font-cell',
            hint: this.gettext(
              'the custom roles in which this legal entity will be used in the system',
            ),
            cellTemplate: /*html*/ `
              <span class="btn-link"
                ng-if="!item.$_edit && item.custom_clientroles_name_list && item.custom_clientroles_name_list.length > 3"
                ng-click="item.customRoleNamesExpanded = !item.customRoleNamesExpanded"
              >
                <i class="fa fa-list"></i> {[{item.custom_clientroles_name_list.length}]}
              </span>
              <span ng-if="item.custom_clientroles_name_list && item.custom_clientroles_name_list.length <= 3">
              <span
                  class="smaller-label"
                  ng-repeat="role_name in item.custom_clientroles_name_list track by $index"
                >
                  {[{ role_name | translate }]}
                  <span ng-if="item.custom_clientroles_name_list.length > 1">, </span>
                </span>
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.customRoleNamesExpanded">
                <span ng-if="item.custom_clientroles_name_list">
                <span
                    class="smaller-label"
                    ng-repeat="role_name in item.custom_clientroles_name_list track by $index"
                  >
                    {[{ role_name | translate }]}
                    <div class="clearfix"></div>
                  </span>
                </span>
              </span>
              <span ng-if="!item.$_edit && !item.custom_clientroles_name_list"> --- </span>
            `,
          },
          {
            columnName: 'company_code',
            title: this.gettext('Code'),
            predicate: 'company_code',
            class: 'company_code td-left-align',
            cellTemplate: /*html*/ `
              <div ng-if="item.$_edit">
                <input
                  class="form-control"
                  type="text"
                  ng-model="item.company_code"
                  placeholder="{[{ 'Code' | translate }]}"
                />
              </div>
                <span ng-if="item.company_code && !item.$_edit">
                {[{ item.company_code }]}
                </span>
            `,
          },
          {
            columnName: 'custom_status',
            title: this.gettext('custom status'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.custom_status_title"
                class="label"
                ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
              >
                {[{ item.custom_status_title }]}
              </span>
            `,
          },
          {
            columnName: 'voyages',
            title: this.gettext('Voyages'),
            class: 'td-center-align',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.ho_num_of_voyage">
                HO - {[{ item.ho_num_of_voyage }]}
              </span>
              <div class="clearfix"></div>
              <span
                ng-if="item.do_num_of_voyages">
                DO - {[{ item.do_num_of_voyages }]}
              </span>
            `,
          },
          {
            columnName: 'status',
            title: this.gettext('Compliance status'),
            predicate: 'status',
            class: 'status td-left-align',
            hint: this.gettext(
              'the status is changed by setting dates of confirmations of counterparty',
            ),
            cellTemplate: /*html*/ `
              <span ng-if="item.status">
                <span
                  class="label"
                  ng-class="{
                    'label_success':item.status == 'Approved' || item.status == 'Approved_condition',
                    'label_calculate':item.status == 'Pending',
                    'label-default':item.status == 'Rejected',
                    'label_default':item.status == 'New',
                    'label_warning':item.status == 'Re-approval'
                  }"
                >
                  <span
                    ng-if="item.date_confirmation"
                  >
                    <i class="fa fa-check-circle"></i>
                  </span>
                  <span
                    ng-if="item.status != 'Approved_condition' && item.status != 'Out_of_approval'"
                    >{[{ item.status | translate }]}</span
                  >
                  <span ng-if="item.status == 'Approved_condition'">
                    <translate>Approved condition</translate>
                  </span>
                  <span class="label-opacity" ng-if="item.status == 'Out_of_approval'">
                    <translate>Out of approval</translate>
                  </span>
                  <span
                    class="tooltip"
                    ng-if="item.confirmation_info"
                    data-tip="{[{ item.confirmation_info }]}"
                  >
                    <i class="fa fa-info-circle"></i>
                  </span>
                </span>
              </span>
              <span class="label-opacity" ng-if="!item.status"> --- </span>
              <div class="clearfix"></div>
              <span class="label label_danger" ng-if="item.blacklisted">
                <i class="fa fa-exclamation-triangle"></i> <translate>black list</translate>
              </span>
            `,
          },
          {
            columnName: 'approval_status',
            title: this.gettext('Approval status'),
            class: 'status td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.approval_status">
                <span
                  class="label"
                  ng-class="{ 'label_success':item.approval_status == 'approved', 'label_danger': item.approval_status == 'rejected', 'label_warning': item.approval_status == 'process', 'label_default': item.approval_status == 'wait' }"
                >
                  <span ng-if="item.approval_status == 'approved'">
                    <translate>Approved</translate>
                  </span>
                  <span ng-if="item.approval_status == 'wait'">
                    <translate>Waiting for approval</translate>
                  </span>
                  <span ng-if="item.approval_status == 'process'">
                    <translate>Approval in process</translate>
                  </span>
                  <span ng-if="item.approval_status == 'rejected'">
                    <translate>Rejected</translate>
                  </span>
                </span>
              </span>
            `,
          },
          {
            columnName: 'regular_status',
            title: this.gettext('Status'),
            class: 'status td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.regular_status">
                <span
                  ng-class="{ 'label label_danger': item.regular_status == 'inactive'}"
                >
                  <span ng-if="item.regular_status == 'potencial'">
                  <i class="fa-solid fa-square-check"></i> <translate>Potencial</translate>
                  </span>
                  <span ng-if="item.regular_status == 'active'">
                  <i class="fa-solid fa-square-check"></i> <translate>Active</translate>
                  </span>
                  <span ng-if="item.regular_status == 'inactive'">
                  <i class="fa-solid fa-moon"></i> <translate>Inactive</translate>
                  </span>
                  <span ng-if="item.regular_status == 'closed'">
                  <i class="fa-solid fa-moon"></i> <translate>Closed</translate>
                  </span>
                </span>
              </span>
            `,
          },
          {
            columnName: 'phone',
            title: this.gettext('Phone'),
            class: 'phone td-left-align',
            cellTemplate: /*html*/ `
              <div ng-if="item.$_edit">
                <input
                  class="form-control"
                  type="tel"
                  ng-model="item.phone"
                  placeholder="{[{ 'Phone' | translate }]}"
                />
              </div>
                <a class="btn-link" href="tel:{[{ item.phone }]}" ng-if="item.phone && !item.$_edit">
                  <i class="fa fa-phone-square"></i> {[{ item.phone }]}
                </a>
                <span class="label-opacity" ng-if="!item.phone && !item.$_edit">
                  <i class="fa fa-phone-square"></i> ---
                </span>
            `,
          },
          {
            columnName: 'business_units',
            title: this.gettext('Business units'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
                <span ng-if="item.business_units && item.business_units.length <= 3">
                  <span ng-repeat="bu in item.business_units">
                    {[{ bu.title | cut:true:30:' ...' }]}
                  </span>
                </span>

                <span class="btn-link"
                  ng-if="item.business_units && item.business_units.length > 3"
                  ng-click="item.businesUnitsExpanded = !item.businesUnitsExpanded"
                >
                  <i class="fa fa-home"></i> {[{item.business_units.length}]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.businesUnitsExpanded">
                  <span ng-repeat="bu in item.business_units">
                    {[{ bu.title | cut:true:30:' ...' }]}
                    <div class="clearfix"></div>
                  </span>
                </span>
              <span ng-if="!item.business_units.length"> --- </span>
            `,
          },
          {
            columnName: 'client_data_is_checked',
            title: this.gettext('Client data check'),
            predicate: 'client_data_is_checked',
            class: 'status td-left-align',

            cellTemplate: /*html*/ `
              <span class="label label_success" ng-if="item.client_data_is_checked">
                <i class="fa fa-check"></i> <translate>data checked</translate>
              </span>
            `,
          },
          {
            columnName: 'bank_data_is_checked',
            title: this.gettext('Bank data check'),
            predicate: 'bank_data_is_checked',
            class: 'status td-left-align',

            cellTemplate: /*html*/ `
              <span class="label label_success" ng-if="item.bank_data_is_checked">
                <i class="fa fa-check"></i> <translate>data checked</translate>
              </span>
            `,
          },
          {
            columnName: 'payment_condition_options',
            title: this.gettext('Payment condition options'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.payment_conditions_option_title">
                {[{ item.payment_conditions_option_title }]}
              </span>
            `,
          },
          {
            columnName: 'full_name',
            title: this.gettext('Full name'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext('the full title of the legal entity (for usage in documents)'),
            cellTemplate: /*html*/ `
              <span ng-if="item.full_name" data-tip="{[{ item.full_name }]}">
                <i class="fa fa-building"></i> {[{ item.full_name | cut:true:25:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'full_name_eng',
            title: this.gettext('Full name eng'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext('the full title of the legal entity (for usage in documents)'),
            cellTemplate: /*html*/ `
              <span ng-if="item.full_name_eng" data-tip="{[{ item.full_name_eng }]}">
                <i class="fa fa-building"></i>{[{ item.full_name_eng | cut:true:25:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'address',
            title: this.gettext('Address'),
            class: 'small-font-cell td-left-align',
            hint: this.gettext('the legal address of the legal entity'),
            cellTemplate: /*html*/ `
              <span ng-if="item.address" data-tip="{[{ item.address }]}">
                <i class="fa fa-location-arrow"></i> {[{ item.address | cut:true:25:' ...' }]}
              </span>
            `,
          },
          {
            columnName: 'address_eng',
            title: this.gettext('Address eng'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext('the full title of the legal entity (for usage in documents)'),
            cellTemplate: /*html*/ `
              <span ng-if="item.address_eng" data-tip="{[{ item.address_eng }]}">
                <i class="fa fa-location-arrow"></i> {[{ item.address_eng | cut:true:25:' ...' }]}
              </span>
            `,
          },
          {
            columnName: 'person_signatory',
            title: this.gettext('Signatory'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext('the person who will be appointed in documents'),
            cellTemplate: /*html*/ `
              <span
                ng-if="item.person_signatory"

                data-tip="{[{ item.person_signatory }]}"
              >
                <i class="fa fa-user"></i> {[{ item.person_signatory | cut:true:25:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'person_signatory_eng',
            title: this.gettext('Signatory eng'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext('the person who will be appointed in documents'),
            cellTemplate: /*html*/ `
              <span
                ng-if="item.person_signatory_eng"

                data-tip="{[{ item.person_signatory_eng }]}"
              >
                <i class="fa fa-user"></i> {[{ item.person_signatory_eng | cut:true:25:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'person_signatory_docs',
            title: this.gettext('Signatory docs'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext(
              'Full name of the Signatory to use in requisites while generating documents',
            ),
            cellTemplate: /*html*/ `
              <span
                ng-if="item.person_signatory_docs"

                data-tip="{[{ item.person_signatory_docs }]}"
              >
                <i class="fa fa-user"></i> {[{ item.person_signatory_docs | cut:true:25:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'person_signatory_eng_docs',
            title: this.gettext('Signatory docs eng'),
            class: 'highlighted small-font-cell td-left-align',
            hint: this.gettext('Full name to use in requisites while generating documents'),
            cellTemplate: /*html*/ `
              <span
                ng-if="item.person_signatory_eng_docs"

                data-tip="{[{ item.person_signatory_eng_docs }]}"
              >
                <i class="fa fa-user"></i> {[{ item.person_signatory_eng_docs | cut:true:25:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'approvals',
            title: this.gettext('Approvals'),
            class: 'highlighted',
            hint: this.gettext('users approved this counterparty'),
            cellTemplate: /*html*/ `
              <div class="inside-table-items" ng-repeat="approval in item.approvals">
                <div ng-if="approval.approved || approval.declined">
                  <div class="user-avatar" ng-if="approval.user_avatar">
                    <img ng-src="/pictures/{[{approval.user_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="!approval.user_avatar">
                    {[{ approval.user_name | cut:true:1:' ' }]}
                  </div>
                  <i ng-if="approval.approved" class="fa fa-check-circle"></i>
                  <span ng-if="approval.declined" class="negative-number">
                    <i class="fa fa-times-circle"></i>
                  </span>
                </div>
              </div>
            `,
          },
          {
            columnName: 'can_confirm',
            title: this.gettext('Can confirm'),
            class: 'highlighted',
            hint: this.gettext(
              'this counterparty can confirm other counterparties. You can see it in the header of the page COUNTERPARTIES - > CONFIRMATIONS',
            ),
            cellTemplate: /*html*/ `
              <i
                class="fa"
                ng-class="{
                  'fa-check-circle': item.can_confirm,
                  'fa-circle-o': !item.can_confirm
                }"
              ></i>
            `,
          },

          {
            columnName: 'renewal_date',
            title: this.gettext('Renewal date'),
            cellTemplate: /*html*/ `
              <span ng-if="item.renewal_date">
                <i class="fa fa-calendar-o"></i> {[{item.renewal_date | date: 'dd MMMM yy' }]}
              </span>
            `,
          },
          {
            columnName: 'date_request',
            title: this.gettext('Requested'),
            class: 'highlighted',
            hint: this.gettext(
              'requested date - the date when you requested the legal documents of this company',
            ),
            cellTemplate: /*html*/ `
              <span ng-if="item.date_request">
                <i class="fa fa-calendar-o"></i> {[{ item.date_request | date: 'dd MMMM yy' }]}
              </span>
            `,
          },
          {
            columnName: 'date_received',
            title: this.gettext('Received'),
            class: 'highlighted',
            hint: this.gettext(
              'received date - the date when you received the legal documents of this company',
            ),
            cellTemplate: /*html*/ `
              <span ng-if="item.date_received">
                <i class="fa fa-calendar-o"></i> {[{ item.date_received | date: 'dd MMMM yy' }]}
              </span>
            `,
          },
          {
            columnName: 'date_first_confirmation',
            title: this.gettext('First confirmed'),
            class: 'highlighted',
            hint: this.gettext(
              'first confirmation date - the date when you first confirmed the legal documents of this company',
            ),
            cellTemplate: /*html*/ `
              <span ng-if="item.date_first_confirmation">
                <i class="fa fa-calendar-o"></i> {[{ item.date_first_confirmation | date: 'dd MMMM yy'
                }]}
              </span>
            `,
          },
          {
            columnName: 'date_confirmation',
            title: this.gettext('Confirmed'),
            class: 'highlighted',
            hint: this.gettext(
              'confirmation date - the date when you confirmed the legal documents of this company',
            ),
            cellTemplate: /*html*/ `
              <span ng-if="item.date_confirmation">
                <i class="fa fa-calendar-o"></i> {[{ item.date_confirmation | date: 'dd MMMM yy' }]}
              </span>
              <span
                ng-if="item.confirmation_info"
                class="tooltip"
                data-tip="{[{ item.confirmation_info }]}"
              >
                <i class="fa fa-info-circle"></i>
              </span>
            `,
          },
          {
            columnName: 'first_contact',
            title: this.gettext('First contact'),
            class: 'highlighted',
            hint: this.gettext(
              'first contact = the date, when this company was added to the system',
            ),
            cellTemplate: /*html*/ `
              <span ng-if="item.first_contact">
                <i class="fa fa-calendar"></i> {[{ item.first_contact | date:'dd MMMM yy' || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'future_contact',
            title: this.gettext('Future contact'),
            hint: this.gettext(
              'future contact = the date, when you need to contact this company later (can be set while editing company',
            ),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span ng-if="item.future_contact && (item.future_contact > item.current_server_time)">
                <i class="fa fa-calendar"></i> {[{ item.future_contact | date:'dd MMMM yy' || "---" }]}
                - <i class="fa fa-clock-o"></i> {[{ item.future_contact | date: 'HH:mm' || "---" }]}
              </span>
              <span
                class="label label_danger"
                ng-if="item.future_contact && (item.future_contact <= item.current_server_time)"
              >
                <i class="fa fa-exclamation-triangle"></i> <i class="fa fa-calendar"></i> {[{
                item.future_contact | date:'dd MMMM yy' || "---" }]} -
                <i class="fa fa-clock-o"></i> {[{ item.future_contact | date: 'HH:mm' || "---" }]}
              </span>
            `,
          },
          {
            columnName: 'term_of_proxy',
            title: this.gettext('Term of proxy'),
            predicate: 'term_of_proxy',
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.term_of_proxy"
                ng-class="{
                  'label label_danger': (item.term_of_proxy - $root.serverTime) / 1000 / 60 / 60 / 24 <= item.days_before_deadline_to_notify
                }"
              >
                <i class="fa fa-calendar-o"></i> {[{ item.term_of_proxy | date: 'dd MMMM yy' }]}
              </span>
            `,
          },
          {
            key: 'days_before_deadline_to_notify',
            type: 'gt-select',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Remind me before'),
              options: this.ClientFormFieldsService.getDaysOptions('No notifications'),
              hint: this.gettext('Number of days before notification'),
            },
          },
          {
            columnName: 'country_name',
            title: this.gettext('Country'),
            predicate: 'country_name',
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.country_name">
                <i class="fa fa-location-arrow"></i> {[{ item.country_name | translate }]}
              </span>
            `,
          },
          {
            columnName: 'predictions_order',
            title: this.gettext('Predictions order'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.predictions_order">
                {[{ item.predictions_order }]}
              </span>
            `,
          },
          {
            columnName: 'region',
            title: this.gettext('Region'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-repeat="region_title in item.region_titles">
                <i class="fa fa-location-arrow"></i> {[{ region_title }]}
                <div class="clearfix"></div>
              </span>
            `,
          },
          {
            columnName: 'district',
            title: this.gettext('District'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.district_title">
                <i class="fa fa-map-pin"></i> {[{ item.district_title }]} {[{
                item.district_phone_code}]}
              </span>
            `,
          },
          {
            columnName: 'city',
            title: this.gettext('City'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.city"> <i class="fa fa-map-marker"></i> {[{ item.city_title }]} </span>
            `,
          },
          {
            columnName: 'current_address',
            title: this.gettext('Current address'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span
                ng-if="item.current_address"
              >
                <i class="fa fa-location-arrow"></i> {[{ item.current_address | cut:true:35:' ...'
                }]}
              </span>
            `,
          },
          {
            columnName: 'person',
            title: this.gettext('Person'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.person">
                <i class="fa fa-user"></i> {[{ item.person | cut:true:35:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'phone_additional',
            title: this.gettext('Phone add'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
                <a
                  ng-if="item.phone_additional"
                  class="btn-link"
                  href="tel:{[{ item.phone_additional }]}"
                >
                  <i class="fa fa-phone-square"></i> {[{ item.phone_additional }]}
                </a>
                <span class="label-opacity" ng-if="!item.phone_additional">
                  <i class="fa fa-phone-square"></i> ---
                </span>
            `,
          },
          {
            columnName: 'email',
            title: this.gettext('E-mail'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
            <div ng-if="item.$_edit">
                <input
                  class="form-control"
                  type="email"
                  ng-model="item.email"
                  placeholder="{[{ 'Email' | translate }]}"
                />
              </div>
              <span ng-if="item.email && !item.$_edit">
                <i class="fa fa-envelope"></i> {[{ item.email }]}
              </span>
            `,
          },
          {
            columnName: 'website',
            title: this.gettext('Website'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.website">
                <i class="fa fa-external-link"></i>
                <a href="https://{[{item.website}]}">{[{ item.website }]}</a>
              </span>
            `,
          },
          {
            columnName: 'sap_vendor_number',
            title: this.gettext('SAP number'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.sap_vendor_number">
               {[{ item.sap_vendor_number }]}
              </span>
            `,
          },
          {
            columnName: 'abbreviation',
            title: this.gettext('Abbreviation'),
            class: 'small-font-cell td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.abbreviation">
               {[{ item.abbreviation }]}
              </span>
            `,
          },
          {
            columnName: 'stuff',
            title: this.gettext('Stuff'),
            class: 'td-left-align',
            hint: this.gettext(
              'the number of persons added by this company. Click on the counter and you will see them all',
            ),
            cellTemplate: /*html*/ `
              <a
                class="modal-btn"
                ng-click="args.openClientDetails(item, 'info')"
                ng-class="{ 'label-opacity': !item.persons_count}"
              >
                <i class="fa fa-users"></i> {[{ item.persons_count }]}
              </a>
            `,
          },
          {
            columnName: 'updates',
            title: this.gettext('Updates'),
            class: 'td-left-align updates nowrap',
            hint: this.gettext(
              'the number of updates and the last one added by this company. Click on the counter and you will see them all',
            ),
            hideExpr: () => !this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_updates,
            cellTemplate: /*html*/ `
            <a
                class="modal-btn"
                ng-click="args.openClientDetails(item, 'updates')"
                ng-if="item.updates_count"
              >
                <i
                  class="fa"
                  ng-class="{
                    'fa-phone-square': item.last_update.message_type=='call',
                    'fa-envelope': item.last_update.message_type=='email',
                    'fa-map-marker': item.last_update.message_type=='meet',
                    'fa-commenting': item.last_update.message_type=='commentary',
                    'fa-tasks': item.last_update.message_type=='task',
                  }"
                ></i>
                <span>
                  {[{ item.updates_count }]}
                </span>
              </a>
              <a
                ng-if="!item.updates_count"
                class="label-opacity modal-btn"
                ng-click="args.openClientDetails(item, 'updates')"
              >
                <i class="fa fa-commenting"></i> 0
              </a>
            `,
          },
          {
            columnName: 'last_update',
            title: this.gettext('last update'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.last_update.update_time" class="smaller-label pull-right">
            <span
              am-time-ago="item.last_update.update_time"
              am-full-date-threshold="30"
              am-full-date-format="DD/MM/YY HH:mm"
            >
            </span>
          </span>
            `,
          },
          {
            columnName: 'relations',
            title: this.gettext('Relations'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span
                class="label"
                ng-class="{
                  'label_danger': item.relations=='Hot',
                  'label_warning': item.relations=='Warm',
                  'label_default': item.relations == 'Neutral',
                  'label_info': item.relations=='Cold',
                  'label_calculate': item.relations == 'Angry'
                }"
                ng-if="item.relations"
              >
                <i class="fa fa-tachometer"></i> {[{ item.relations | translate}]}
              </span>
            `,
          },
          {
            columnName: 'docs',
            title: this.gettext('Docs'),
            class: 'td-left-align',
            hint: this.gettext(
              'the number of documents attached to this company. Click on the counter and you will see them all',
            ),
            hideExpr: () => !this.$rootScope.user.settings.SYSTEM_BLOCKS.block_company_documents,
            cellTemplate: /*html*/ `
              <a
                class="modal-btn"
                ng-click="args.openClientDetails(item, 'info')"
                ng-class="{ 'label-opacity': !item.documents_count }"
              >
                <i class="fa fa-upload"></i> {[{ item.documents_count }]}
              </a>
            `,
          },
          {
            columnName: 'contracts',
            title: this.gettext('Contracts'),
            predicate: 'contracts_count',
            class: 'td-left-align',
            hint: this.gettext(
              'the number of contracts that involve this company. Click on the counter and you will see them all',
            ),
            cellTemplate: /*html*/ `
              <a
                class="modal-btn"
                ng-click="args.openClientDetails(item, 'contracts')"
                ng-class="{ 'label-opacity': !item.contracts_count }"
              >
                <i class="fa fa-file-text"></i> {[{ item.contracts_count }]}
              </a>
            `,
          },
          {
            columnName: 'invoices',
            title: this.gettext('Invoices'),
            class: 'td-left-align',
            hint: this.gettext(
              'the number of invoices that involve this company. Click on the counter and you will see them all',
            ),
            cellTemplate: /*html*/ `
              <a
                class="modal-btn"
                ng-click="args.openClientDetails(item, 'finances')"
                ng-class="{ 'label-opacity': !item.invoices_count }"
              >
                <i class="fa fa-credit-card-alt"></i> {[{ item.invoices_count }]}
              </a>
            `,
          },
          {
            columnName: 'logistics',
            title: this.gettext('Logistics'),
            class: 'td-left-align',
            hint: this.gettext('the number of logistics that involve this company'),
            hideExpr: () => !this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_logistics,
            cellTemplate: /*html*/ `
              <a
                class="modal-btn"
                ng-click="args.openClientDetails(item, 'transport')"
                ng-class="{ 'label-opacity': !item.logistics_count }"
              >
                <i class="fa fa-truck"></i> {[{ item.logistics_count }]}
              </a>
            `,
          },
          {
            columnName: 'notes',
            title: this.gettext('Notes'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span
                class="small-font-cell smaller-label"
                ng-if="item.additional_info"
              >
                <i class="fa fa-comment"></i> {[{item.additional_info | cut:true:35:' ...'}]}
              </span>
              <div class="clearfix"></div>
              <span ng-if="item.commentary" class="small-font-cell smaller-label" data-tip="{[{item.commentary}]}">
                <i class="fa fa-comment"></i> {[{item.commentary | cut:true:35:' ...'}]}
              </span>
            `,
          },
          {
            columnName: 'special',
            title: this.gettext('Special'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span class="label label_default" ng-if="item.agent">
                <i class="fa fa-suitcase"></i> <translate>agent</translate>
              </span>
            `,
          },
          {
            columnName: 'local_currency',
            title: this.gettext('Local currency'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span class="bigger-label">
                <i
                  class="fa"
                  ng-class="{ 'fa-check-circle': item.local_currency, 'fa-circle-o': !item.local_currency }"
                ></i>
              </span>
            `,
          },
          {
            columnName: 'foreign_currency',
            title: this.gettext('Foreign currency'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span class="bigger-label">
                <i
                  class="fa"
                  ng-class="{ 'fa-check-circle': item.foreign_currency, 'fa-circle-o': !item.foreign_currency }"
                ></i>
              </span>
            `,
          },
          {
            columnName: 'basises',
            title: this.gettext('Basises'),
            class: 'highlighted small-font-cell',
            cellTemplate: /*html*/ `
              <span ng-repeat="basis_name in item.basis_names">{[{ basis_name }]} </span>
            `,
          },
          {
            columnName: 'size',
            title: this.gettext('Size'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span class="label label_default" ng-if="item.size">
                {[{ item.size | translate }]}
              </span>
            `,
          },
          {
            columnName: 'limit_one_deal_volume_spot',
            title: this.gettext('Volume per deal (spot)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.max_volume_spot"
                ng-class="{'label-opacity': item.limit_one_deal_volume_spot == 0 }"
              >
                {[{ item.limit_one_deal_volume_spot || 0 | number:2 }]}
              </span>
              <div ng-if="item.max_volume_spot">
                {{ item.max_volume_spot || 1 | number: 0 }} / {{ item.limit_one_deal_volume_spot || 0
                | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_general_volume_spot',
            title: this.gettext('Total volume (spot)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.total_volume_spot_spot"
                ng-class="{'label-opacity': item.limit_general_volume_spot == 0 }"
              >
                {[{ item.limit_general_volume_spot || 0 | number:2 }]}
              </span>
              <div ng-if="item.total_volume_spot">
                {{ item.total_volume_spot || 1 | number: 0 }} / {{ item.limit_general_volume_spot ||
                0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_open_balance_volume_spot',
            title: this.gettext('Open balance (spot)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.open_balance_total_spot"
                ng-class="{'label-opacity': item.limit_open_balance_volume_spot == 0 }"
              >
                {[{ item.limit_open_balance_volume_spot || 0 | number:2 }]}
              </span>
              <div ng-if="item.open_balance_total_spot">
                {{ item.open_balance_total_spot || 1 | number: 0 }} / {{
                item.limit_open_balance_volume_spot || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_one_deal_in_usd_spot',
            title: this.gettext('Value per deal (spot)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.max_total_value_spot"
                ng-class="{'label-opacity': item.limit_one_deal_in_usd_spot == 0 }"
              >
                {[{ item.limit_one_deal_in_usd_spot || 0 | number:2 }]}
              </span>
              <div ng-if="item.max_total_value_spot">
                {{ item.max_total_value_spot || 1 | number: 0 }} / {{ item.limit_one_deal_in_usd_spot
                || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_general_in_usd_spot',
            title: this.gettext('Value general (spot)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.total_value_spot"
                ng-class="{'label-opacity': item.limit_general_in_usd_spot == 0 }"
              >
                {[{ item.limit_general_in_usd_spot || 0 | number:2 }]}
              </span>
              <div ng-if="item.total_value_spot">
                {{ item.total_value_spot || 1 | number: 0 }} / {{ item.limit_general_in_usd_spot || 0
                | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_one_deal_volume_forward',
            title: this.gettext('Volume per deal (forward)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.max_volume_forward"
                ng-class="{'label-opacity': item.limit_one_deal_volume_forward == 0 }"
              >
                {[{ item.limit_one_deal_volume_forward || 0 | number:2 }]}
              </span>
              <div ng-if="item.max_volume_forward">
                {{ item.max_volume_forward || 1 | number: 0 }} / {{
                item.limit_one_deal_volume_forward || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_general_volume_forward',
            title: this.gettext('Total volume (forward)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.total_volume_forward"
                ng-class="{'label-opacity': item.limit_general_volume_forward == 0 }"
              >
                {[{ item.limit_general_volume_forward || 0 | number:2 }]}
              </span>
              <div ng-if="item.total_volume_forward">
                {{ item.total_volume_forward || 1 | number: 0 }} / {{
                item.limit_general_volume_forward || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_open_balance_volume_forward',
            title: this.gettext('Open balance (forward)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.open_balance_total_forward"
                ng-class="{'label-opacity': item.limit_open_balance_volume_forward == 0 }"
              >
                {[{ item.limit_open_balance_volume_forward || 0 | number:2 }]}
              </span>
              <div ng-if="item.open_balance_total_forward">
                {{ item.open_balance_total_forward || 1 | number: 0 }} / {{
                item.limit_open_balance_volume_forward || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_one_deal_in_usd_forward',
            title: this.gettext('Value per deal (forward)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.max_total_value_forward"
                ng-class="{'label-opacity': item.limit_one_deal_in_usd_forward == 0 }"
              >
                {[{ item.limit_one_deal_in_usd_forward || 0 | number:2 }]}
              </span>
              <div ng-if="item.max_total_value_forward">
                {{ item.max_total_value_forward || 1 | number: 0 }} / {{
                item.limit_one_deal_in_usd_forward || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'limit_general_in_usd_forward',
            title: this.gettext('Value general (forward)'),
            class: 'highlighted',
            cellTemplate: /*html*/ `
              <span
                ng-if="!item.total_value_forward"
                ng-class="{'label-opacity': item.limit_general_in_usd_forward == 0 }"
              >
                {[{ item.limit_general_in_usd_forward || 0 | number:2 }]}
              </span>
              <div ng-if="item.total_value_forward">
                {{ item.total_value_forward || 1 | number: 0 }} / {{
                item.limit_general_in_usd_forward || 0 | number: 0 }}
              </div>
            `,
          },
          {
            columnName: 'create_time',
            title: this.gettext('Created'),
            predicate: 'create_time',
            class: 'update_time td-left-align nowrap small-font-cell',
            cellTemplate: /*html*/ `
              <span class="updated_time">
              <i class="fa fa-clock-o"></i>
                <span
                  am-time-ago="item.create_time"
                  am-full-date-threshold="30"
                  am-full-date-format="DD/MM/YY HH:mm"
                >
                </span>
              </span>
            `,
          },
          {
            columnName: 'author',
            title: this.gettext('Createor'),
            predicate: 'create_time',
            class: 'update_time td-left-align nowrap small-font-cell',
            cellTemplate: /*html*/ `
              <span>
                <div
                  class="user-avatar"
                  ng-if="item.author_first_name && item.author_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.author_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.author_avatar.length == 0">
                  {[{ item.author_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.author_first_name }]}
              </span>
            `,
          },
          {
            columnName: 'update_time',
            title: this.gettext('Updated'),
            predicate: 'update_time',
            class: 'update_time td-left-align nowrap small-font-cell',
            cellTemplate: /*html*/ `
              <span>
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.editor_avatar.length == 0">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.editor_first_name }]}
              </span>
            `,
          },
          {
            columnName: 'editor',
            title: this.gettext('Editor'),
            predicate: 'update_time',
            class: 'update_time td-left-align nowrap small-font-cell',
            cellTemplate: /*html*/ `
              <span class="tooltip" data-tip="{[{ item.editor_first_name }]} {[{ item.editor_last_name }]}">
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.editor_avatar.length == 0">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
                {[{ item.editor_first_name }]}
              </span>
            `,
          },
          {
            columnName: 'crop_area',
            title: this.gettext('Crop area'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.crop_area > 0">
              <span>{[{ item.crop_area | number:3 }]}</span>
              <translate>ha</translate>
            </span>
            <span ng-if="item.crop_area == 0">---</span>
            `,
          },
          {
            columnName: 'cash_flow',
            title: this.gettext('Cash flow'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.cash_flow">
              <span>{[{ item.cash_flow }]}</span>
            </span>
            `,
          },
          {
            columnName: 'assets',
            title: this.gettext('Assets'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.assets">
              <span>{[{ item.assets }]}</span>
            </span>
            `,
          },
          {
            columnName: 'pledge',
            title: this.gettext('Pledge'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.pledge">
              <span>{[{ item.pledge }]}</span>
            </span>
            `,
          },
          {
            columnName: 'transports_count',
            title: this.gettext('Transports count'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
            <span ng-if="item.transports_count > 0">
              <span>{[{ item.transports_count }]}</span>
            </span>
            <span ng-if="item.transports_count == 0">---</span>
            `,
          },
          {
            columnName: 'warehouse_distances',
            title: this.gettext('Warehouse distances'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span ng-if="item.warehouse_distances.length">
                <span ng-repeat="distance in item.warehouse_distances">
                  {[{ distance.to_name | cut:true:30:' ...' }]} - {[{ distance.distance }]}
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.warehouse_distances.length"> --- </span>
            `,
          },
          {
            columnName: 'crop_areas',
            title: this.gettext('Potentials'),
            class: 'td-left-align small-font-cell',
            cellTemplate: /*html*/ `
              <span ng-if="item.crop_areas.length">
                <span ng-repeat="area in item.crop_areas">
                   {[{ area.area_sum }]} <translate>ha</translate>
                   {[{ area.crop_title | cut:true:30:' ...' }]} - {[{ area.volume_sum }]} {[{ args.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  <div class="clearfix"></div>
                </span>
              </span>
              <span ng-if="!item.crop_areas.length"> --- </span>
            `,
          },
          {
            columnName: 'last_clientupdate',
            title: this.gettext('Last update'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
             <span
              class="updated_time"
              ng-class="{ 'label-opacity': !item.clientupdate_last_create_time}"
             >
              <i class="fa fa-clock-o"></i>
              {[{ item.clientupdate_last_create_time | date:'HH:mm dd/MMM/yy '}]}
                <span ng-if="!item.clientupdate_last_create_time">---</span>
              </span>
            `,
          },
          {
            columnName: 'next_clientupdate',
            title: this.gettext('Next contact'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
             <span
              class="updated_time"
              ng-class="{ 'label-opacity': !item.clientupdate_next_future_contact}"
             >
              <i class="fa fa-clock-o"></i>
              {[{ item.clientupdate_next_future_contact | date:'HH:mm dd/MMM/yy '}]}
                <span ng-if="!item.clientupdate_next_future_contact">---</span>
              </span>
            `,
          },
          {
            columnName: 'warehouses',
            title: this.gettext('Warehouse'),
            class: 'td-left-align',
            cellTemplate: /*html*/ `
              <span ng-if="item.warehouses_list.length <= 3">
                <span ng-repeat="warehouse in item.warehouses_list track by $index">
                  <i class="fa {[{ $root.gtUtils.getIcon('warehouses.' + warehouse.type) }]}"></i>
                  <a ui-sref="logistics.warehouseDetails(warehouse)">
                    <span class="label label-default">{[{ warehouse.title}]}</span>
                  </a>
                </span>

              </span>
              <span ng-if="item.warehouses_list.length > 3" class="btn-link">
                <span ng-click="item.warehousesExpanded = !item.warehousesExpanded">
                  <i class="fa fa-warehouse"></i> {[{item.warehouses_list.length}]}
                  <ul ng-if="item.warehousesExpanded" class="inside-table-ul">
                    <li ng-repeat="warehouse in item.warehouses_list track by $index" class="smaller-label">
                      <div class="clearfix"></div>
                      <span class="smaller-label">
                        <a class="btn-link smaller-label pull-left" ui-sref="logistics.warehouseDetails(warehouse)">
                        <i class="fa {[{ $root.gtUtils.getIcon('warehouses.' + warehouse.type) }]}"></i>
                          {[{ warehouse.title}]}
                        </a>
                      </span>
                    </li>
                  </ul>
                </span>

              </span>
            `,
          },
        ];

        options.tabs = this.filterTabs(options.tabs);

        return this.ClientFormFieldsService.getCustomFieldTableColumns().then((columns: any) => {
          options.columnDefs.push(...columns);
          options.tabs.forEach((tab) => {
            tab.columns.push(...columns.map((column: any) => column.columnName));
          });
          this.tableOptions = options;
        });
      }

      filterTabs(tabs: any) {
        if (this.$rootScope.user.settings.SIMPLE_TABLES) {
          tabs = tabs.filter((item: any) => {
            return ![
              'table_legal',
              'table_dates',
              'table_special',
              'table_limits',
              'table_potentials',
            ].includes(item.serializer);
          });
        }

        if (!this.$rootScope.user.settings.SYSTEM_BLOCKS.block_crm_risk) {
          tabs = tabs.filter((item: any) => {
            return !['table_limits'].includes(item.serializer);
          });
        }

        return tabs;
      }
    },
  ],
};
