import ng from 'angular';

export const GtTableSticky = ($timeout: ng.ITimeoutService, $window: ng.IWindowService) => {
  return {
    restrict: 'A',
    scope: {
      gtTableStickyNew: '<',
    },
    link($scope: ng.IScope, $elem: any) {
      if (!($scope as any).gtTableStickyNew.tableHeight) {
        return false;
      }
      // copypaste below from so
      const elem = $elem[0];

      $scope.$watch('gtTableStickyNew.watchValue', transformTable);
      // eslint-disable-next-line sonarjs/deprecation
      ng.element($window).bind('resize', transformTable);

      function transformTable() {
        if (!($scope as any).gtTableStickyNew.watchValue) {
          return false;
        }
        ng.element(elem.querySelectorAll('thead, tbody, tfoot')).css('display', '');
        $timeout(function () {
          ng.forEach(elem.querySelectorAll('tr:first-child th'), function (thElem: any, i: any) {
            const tdElems = elem.querySelector(
              'tbody tr:first-child td:nth-child(' + (i + 1) + ')',
            );
            const tfElems = elem.querySelector(
              'tfoot tr:first-child td:nth-child(' + (i + 1) + ')',
            );

            const columnWidth = tdElems ? tdElems.offsetWidth : thElem.offsetWidth;
            if (tdElems) {
              tdElems.style.width = columnWidth + 'px';
            }
            if (thElem) {
              thElem.style.width = columnWidth + 'px';
            }
            if (tfElems) {
              tfElems.style.width = columnWidth + 'px';
            }
          });

          ng.element(elem.querySelectorAll('thead, tfoot')).css('display', 'block');
          ng.element(elem.querySelectorAll('tbody')).css({
            display: 'block',
            height: ($scope as any).gtTableStickyNew.tableHeight || 'inherit',
            overflow: 'auto',
          });

          const tbody = elem.querySelector('tbody');
          let scrollBarWidth = tbody.offsetWidth - tbody.clientWidth;
          if (scrollBarWidth > 0) {
            scrollBarWidth -= 2;
            const lastColumn = elem.querySelector('tbody tr:first-child td:last-child');
            lastColumn.style.width = lastColumn.offsetWidth - scrollBarWidth + 'px';
          }
        });
      }
    },
  };
};
GtTableSticky.$inject = ['$timeout', '$window'];
