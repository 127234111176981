import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { QueryParams } from '~/app/core/types';

import template from './client-invoice-payment-reconciliation-report-container.html?raw';

export const ClientInvoicePaymentReconciliationReportContainer = {
  bindings: {
    filterLevel: '<?',
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'ClientInvoicePaymentReconciliationReportService',
    class {
      $scope: ng.IScope;
      Service: any;
      filterLevel: string;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      reportData: any;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        ClientInvoicePaymentReconciliationReportService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.initQueryParams = {};
        this.filterLevel = '';
        this.Service = ClientInvoicePaymentReconciliationReportService;
        this.reportData = [];
      }

      $onInit() {
        this.filterLevel =
          this.filterLevel || 'client-invoice-payment-reconciliation-report-container';
        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on(`gt-filter-updated_${this.filterLevel}`, (ev: any, data: any) => {
          this.queryParams = data;
          this.updateData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateData() {
        this.Service.getReportData(this.queryParams).then((reportData: any) => {
          this.reportData = reportData;
        });
      }

      downloadXlsxReport() {
        this.Service.downloadXlsxReport(this.queryParams);
      }
    },
  ],
};
