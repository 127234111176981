import type ng from 'angular';

import type { GtFilterService } from '~/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '~/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '~/app/core/types';

export const RiskPositionOriginationContainer = {
  bindings: {
    filterLevel: '<',
    onUpdate: '&?',
    initQueryParams: '<?',
  },
  template: require('./risk-position-origination-container.html?raw'),
  controller: [
    '$scope',
    'gtFilterService',
    'PhysicalPositionService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      GtUtils: GtUtilsService;
      PhysicalPositionService: any;
      businessUnitData: any;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      onUpdate: any;
      queryParams: QueryParams = {};
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        PhysicalPositionService: any,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.PhysicalPositionService = PhysicalPositionService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.filterLevel = '';
        this.onUpdate = () => {};

        this.businessUnitData = [];
        this.queryParams = {};
        this.initQueryParams = {};
      }

      $onInit() {
        this.queryParams = { ...this.initQueryParams };
        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev: any, data: any) => {
          this.queryParams = { ...data };
          this.updateTableData();
        });
        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
      }

      updateTableData() {
        this.GtUtils.overlay('show');
        this.businessUnitData = [];
        return this.PhysicalPositionService.getRiskPositionOriginationGrouping(
          this.queryParams,
        ).then((data: any) => {
          this.GtUtils.overlay('hide');
          this.businessUnitData = data;
        });
      }

      saveRiskPositionStock(terminal: any) {
        this.GtUtils.overlay('show');
        const origination = {
          id: terminal.riskpositionstock_id,
          business_unit: terminal.business_unit_id,
          cargo: terminal.cargo_id,
          terminal: terminal.terminal_id,
          volume_real_stocks: terminal.volume_real_stocks,
        };

        if (origination.id) {
          this.PhysicalPositionService.RiskPositionStock.update(origination, () => {
            this.GtUtils.notify(this.gettext('Object updated'));
            this.onUpdate();
            this.GtUtils.overlay('hide');
          });
        } else {
          this.PhysicalPositionService.RiskPositionStock.save(origination, () => this.onUpdate());
          this.GtUtils.overlay('hide');
        }
      }

      isValid(terminal: any) {
        return terminal.volume_real_stocks !== null && terminal.volume_real_stocks !== undefined;
      }
    },
  ],
};
